import { useEffect } from "react";
import { Tag } from "@blueprintjs/core";
import { observer } from "mobx-react";

import { TCellRendererProps } from "../types";

import styles from "./CommentsCell.module.scss";

const CommentsCell = (props: TCellRendererProps) => {
  const { data } = props;
  const catalogItem = data?.catalogItem;
  const attachment = catalogItem ? catalogItem.latestVersion?.attachment : undefined;

  useEffect(() => {
    if (attachment && !attachment.annotationList.fetchedAnnotationsAmount) {
      attachment.annotationList.fetchAnnotationsAmount();
    }
  }, [attachment]);

  if (!catalogItem || !attachment) {
    return null;
  }

  const commentsAmount = attachment.annotationList.annotations.length || attachment.annotationList.annotationsAmount;

  if (!commentsAmount) {
    return null;
  }

  return (
    <div className={styles.commentsCell}>
      <Tag minimal className={styles.commentsCellTag}>
        {commentsAmount}
      </Tag>
    </div>
  );
};

export default observer(CommentsCell);
