import { Checkbox } from "@blueprintjs/core";
import { observer } from "mobx-react";
import { isAlive } from "mobx-state-tree";

import { TCellRendererProps } from "@components/Table";
import { IPropertyDefinition } from "@store/PropertyDefinitionStore";

const AutoAddCell = (cellProps: TCellRendererProps<IPropertyDefinition>) => {
  const { data } = cellProps;
  const isBlockAlive = isAlive(data);

  return isBlockAlive ? (
    <Checkbox data-testid={`default_${data.label}`} checked={data.autoAdd} onChange={() => data.toggleAutoAdd()} />
  ) : null;
};

export default observer(AutoAddCell);
