import { useMemo } from "react";
import { ButtonGroup, Icon, IconName, MaybeElement, Position, Tooltip } from "@blueprintjs/core";
import { observer } from "mobx-react";

import ShadingHiddenLineIconDark from "@assets/icons/hoopsIcons/dark/shading_hidden_line_dark.svg?react";
import ShadingShadedIconDark from "@assets/icons/hoopsIcons/dark/shading_shaded_dark.svg?react";
import ShadingShadedWithLinesIconDark from "@assets/icons/hoopsIcons/dark/shading_shaded_with_lines_dark.svg?react";
import ShadingWireframeIconDark from "@assets/icons/hoopsIcons/dark/shading_wireframe_dark.svg?react";
import ShadingXrayIconDark from "@assets/icons/hoopsIcons/dark/shading_x_ray_dark.svg?react";
import ShadingHiddenLineIconLight from "@assets/icons/hoopsIcons/light/shading_hidden_line_light.svg?react";
import ShadingShadedIconLight from "@assets/icons/hoopsIcons/light/shading_shaded_light.svg?react";
import ShadingShadedWithLinesIconLight from "@assets/icons/hoopsIcons/light/shading_shaded_with_lines_light.svg?react";
import ShadingWireframeIconLight from "@assets/icons/hoopsIcons/light/shading_wireframe_light.svg?react";
import ShadingXrayIconLight from "@assets/icons/hoopsIcons/light/shading_x_ray_light.svg?react";
import { Button } from "@components/Button";
import { Popover } from "@components/Popover";
import { ThemedCustomIcon } from "@components/Shared/LegacyCustomIcon/ThemedCustomIcon";
import appStore from "@store/AppStore";

import styles from "../HoopsMenu.module.scss";

type HoopsMenuRenderModeButtonGroupProps = {
  viewer: Communicator.WebViewer;
  renderMode: Communicator.DrawMode;
};

type RenderMode = {
  name: string;
  description?: string;
  mode: Communicator.DrawMode;
  icon: IconName | MaybeElement;
};

const renderModes: RenderMode[] = [
  {
    name: "Wireframe",
    mode: Communicator.DrawMode.Wireframe,
    icon: <ThemedCustomIcon large lightIcon={<ShadingWireframeIconLight />} darkIcon={<ShadingWireframeIconDark />} />,
  },
  {
    name: "Hidden line",
    mode: Communicator.DrawMode.HiddenLine,
    icon: <ThemedCustomIcon large lightIcon={<ShadingHiddenLineIconLight />} darkIcon={<ShadingHiddenLineIconDark />} />,
  },
  {
    name: "Shaded",
    mode: Communicator.DrawMode.Shaded,
    icon: <ThemedCustomIcon large lightIcon={<ShadingShadedIconLight />} darkIcon={<ShadingShadedIconDark />} />,
  },
  {
    name: "Shaded with lines",
    mode: Communicator.DrawMode.WireframeOnShaded,
    icon: <ThemedCustomIcon large lightIcon={<ShadingShadedWithLinesIconLight />} darkIcon={<ShadingShadedWithLinesIconDark />} />,
  },
  {
    name: "Gooch shading",
    mode: Communicator.DrawMode.Gooch,
    icon: <Icon icon="lightbulb" size={20} />,
  },
  {
    name: "X-ray",
    mode: Communicator.DrawMode.XRay,
    icon: <ThemedCustomIcon large lightIcon={<ShadingXrayIconLight />} darkIcon={<ShadingXrayIconDark />} />,
  },
];

const HoopsMenuRenderModeButtonGroup = ({ viewer, renderMode }: HoopsMenuRenderModeButtonGroupProps) => {
  const handleRenderModeChanged = (drawMode: Communicator.DrawMode) => {
    appStore.env.attachmentViewer?.setRenderMode(drawMode);
    viewer.view.setDrawMode(drawMode);
  };

  const renderModeMenu = (
    <ButtonGroup large>
      {renderModes.map(entry => (
        <Tooltip position={Position.TOP} hoverOpenDelay={500} key={entry.name} content={entry.name}>
          <Button
            className={styles.hoopsToolbarButtonAndButtonGroup}
            icon={entry.icon}
            active={renderMode === entry.mode}
            onClick={() => handleRenderModeChanged(entry.mode)}
            e2eIdentifiers={["select-render-mode", entry.name]}
          />
        </Tooltip>
      ))}
    </ButtonGroup>
  );

  const renderModeEntry = useMemo(() => renderModes.find(e => e.mode === renderMode), [renderMode]);

  return (
    <Popover content={renderModeMenu} placement={Position.TOP}>
      <Tooltip position={Position.TOP} content={`Render mode: ${renderModeEntry?.name}`}>
        <Button className={styles.hoopsToolbarButtonAndButtonGroup} icon={renderModeEntry?.icon} e2eIdentifiers="open-render-mode-menu" />
      </Tooltip>
    </Popover>
  );
};

export default observer(HoopsMenuRenderModeButtonGroup);
