import { PropsWithChildren } from "react";
import { Checkbox } from "@blueprintjs/core";

import "./WithCheckbox.scss";

interface WithCheckboxProps extends PropsWithChildren {
  checked: boolean;
}

export const WithCheckbox = ({ checked, children }: WithCheckboxProps) => {
  return (
    <div className="with-checkbox">
      {children}
      <Checkbox
        checked={checked}
        onClick={e => {
          e.stopPropagation();
        }}
      />
    </div>
  );
};
