import { CreateCatalogItemDialogType, LeadPeriod, leadPeriodLabel } from "@components/CreateCatalogItemDialog/types";
import { getPluralOrSingularString } from "@utilities";

export const renderIcon = (type: CreateCatalogItemDialogType) => {
  switch (type) {
    case CreateCatalogItemDialogType.CSV:
      return "th";
    case CreateCatalogItemDialogType.CAD:
      return "cube";
    case CreateCatalogItemDialogType.Block:
      return "diagram-tree";
    default:
      return "edit";
  }
};

export const renderText = (type: CreateCatalogItemDialogType) => {
  switch (type) {
    case CreateCatalogItemDialogType.CSV:
      return "Multiple parts from CSV";
    case CreateCatalogItemDialogType.CAD:
      return "From CAD files";
    case CreateCatalogItemDialogType.Block:
      return "From Modeling blocks";
    default:
      return "Manually";
  }
};

export function periodToText(periodStr: string): string {
  if (!/^p/i.test(periodStr)) {
    console.warn("Invalid period format");
    return "";
  }

  const matchedFields = periodStr.substring(1).match(/(?<count>[0-9]*)(?<period>Y|M|W|D)/i);

  if (matchedFields?.groups) {
    const { count, period } = matchedFields.groups as { count?: number; period?: LeadPeriod };

    if (count && period) {
      return `${count} ${getPluralOrSingularString(count, leadPeriodLabel[period])}`;
    }
  }

  return "";
}
