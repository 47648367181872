import { useCallback, useContext, useEffect } from "react";

import { BACKSPACE_KEY, DELETE_KEY, ESCAPE_KEY } from "@constants/keys";
import appStore from "@store/AppStore";
import { isInputFocused } from "@utilities";
import { isMetaKey } from "@utilities/Keyboard";

import DragToSelectContext from "./DragToSelectContext";
import { TDragToSelectContext } from "./types";

interface IUseDragToSelectParams {
  allIds: string[];
  onDelete?(ids: string[]): void;
}
export const useDragToSelect = (params: IUseDragToSelectParams): TDragToSelectContext => {
  const { allIds, onDelete } = params;
  const ctx = useContext(DragToSelectContext);

  const keyUpHandler = useCallback(
    (e: KeyboardEvent) => {
      if (e.key === BACKSPACE_KEY || e.key === DELETE_KEY) {
        onDelete?.(appStore.ui.selectedIds);
      }
      if (e.key === ESCAPE_KEY) {
        ctx.resetSelection();
      }
    },
    [ctx, onDelete]
  );

  const keyDownHandler = useCallback(
    (e: KeyboardEvent) => {
      if (e.key === "a" && isMetaKey(e) && !isInputFocused()) {
        e.preventDefault();
        (document.activeElement as HTMLElement)?.blur();
        appStore.ui.setSelectedIds(allIds);
      }
    },
    [allIds]
  );

  useEffect(() => {
    window.addEventListener("keyup", keyUpHandler);
    window.addEventListener("keydown", keyDownHandler);

    return () => {
      window.removeEventListener("keyup", keyUpHandler);
      window.removeEventListener("keydown", keyDownHandler);
    };
  }, [keyUpHandler, keyDownHandler]);

  return ctx;
};
