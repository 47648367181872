import { useEffect, useRef, useState } from "react";
import { Intent, Menu } from "@blueprintjs/core";
import { useWorkspace } from "@hooks/useWorkspace";
import { observer } from "mobx-react";

import { Button } from "@components/Button";
import { MenuItem } from "@components/MenuItem";
import { MenuItemDelete } from "@components/MenuItems";
import { Popover } from "@components/Popover";
import appStore from "@store/AppStore";
import { getPluralOrSingularString } from "@utilities";

import { TCellRendererProps } from "../types";

import "./ActionsCell.scss";

const ActionsCell = (props: TCellRendererProps) => {
  const { registerRowDragger } = props;
  const [isOpen, setIsOpen] = useState(false);
  const block = props.data?.block;
  const buttonRef = useRef<HTMLButtonElement>(null);
  const workspace = useWorkspace();
  const activeTable = workspace.bomTablesMap.get(appStore.env.activeBomTableId || "");

  useEffect(() => {
    buttonRef.current && registerRowDragger(buttonRef.current, undefined, undefined, true);
  }, [registerRowDragger, buttonRef]);

  if (!activeTable || !block) {
    return null;
  }

  const handleRemoveRow = () => {
    if (activeTable.previewBlockId === block.id) {
      activeTable.setPreviewBlockId();
    }
    if (activeTable.selectedRows.includes(block.id)) {
      activeTable.removeSelectedRows();
    } else {
      activeTable.removeRow(block.id);
    }
  };

  const handleDetachBlock = () => {
    workspace.detachBlock(block);
  };

  const handleAttachBlock = () => {
    workspace.attachBlock(block);
  };

  const handleDeleteBlock = () => {
    workspace.deleteBlock(block);
  };

  const rowString = activeTable.selectedRows.includes(block.id) ? getPluralOrSingularString(activeTable.selectedRows.length, "row") : "row";

  const getActionsMenu = () => (
    <Menu>
      <MenuItem icon="minus" text={`Remove ${rowString}`} onClick={handleRemoveRow} e2eIdentifiers="remove-row" />
      {block.isDetachedBlock ? (
        <MenuItem icon="layout-hierarchy" text="Attach to tree" onClick={handleAttachBlock} e2eIdentifiers="attach-to-tree" />
      ) : (
        <MenuItem
          icon="th-list"
          text="Detach from tree"
          disabled={block === workspace.rootBlock || !!block.children?.length}
          onClick={handleDetachBlock}
          e2eIdentifiers="attach-from-tree"
        />
      )}
      <MenuItemDelete text="Delete block" onDelete={handleDeleteBlock} e2eIdentifiers="delete-block" />
    </Menu>
  );

  // hack to prevent row selection on actions cell click
  // https://stackoverflow.com/a/63968681
  const handleRefEvents = (ref: HTMLDivElement | null) => {
    if (ref) {
      ref.onclick = (e: MouseEvent) => {
        setIsOpen(!isOpen);
        e.stopPropagation();
      };
    }
  };

  return (
    <div ref={handleRefEvents} className="actions-cell">
      <Popover isOpen={isOpen} onClose={() => setIsOpen(false)} canEscapeKeyClose placement="bottom-start" content={getActionsMenu()}>
        <Button
          buttonRef={buttonRef}
          small
          minimal
          icon="drag-handle-vertical"
          intent={Intent.NONE}
          e2eIdentifiers={["menuexpand", block.id, block.label]}
        />
      </Popover>
    </div>
  );
};

export default observer(ActionsCell);
