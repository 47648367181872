import { useCallback, useState } from "react";
import { Position, Slider, Tooltip } from "@blueprintjs/core";
import { observer } from "mobx-react";

import ExplodeIconDark from "@assets/icons/hoopsIcons/dark/explode_dark.svg?react";
import ExplodeIconLight from "@assets/icons/hoopsIcons/light/explode_light.svg?react";
import { Button } from "@components/Button";
import { Popover } from "@components/Popover";
import { LegacyCustomIcon } from "@components/Shared/LegacyCustomIcon/LegacyCustomIcon";
import appStore from "@store/AppStore";

import styles from "../HoopsMenu.module.scss";

type HoopsMenuExplodeButtonProps = {
  viewer: Communicator.WebViewer;
};

const HoopsMenuExplodeButton = ({ viewer }: HoopsMenuExplodeButtonProps) => {
  const [explodeFraction, setExplodeFraction] = useState(0);

  const handleExplodeValueChanged = useCallback(
    async (val: number) => {
      setExplodeFraction(val);
      if (!viewer.explodeManager.getActive()) {
        await viewer.explodeManager.start();
      }
      // Convert from percentage
      viewer.explodeManager.setMagnitude(val / 100.0);
    },
    [viewer]
  );

  const explodeMenu = (
    <div className={styles.explodeSliderContainer}>
      <Slider min={0} max={200} value={explodeFraction} onChange={handleExplodeValueChanged} labelValues={[]} labelRenderer={false} />
    </div>
  );

  return (
    <Popover content={explodeMenu} placement={Position.TOP}>
      <Tooltip position={Position.TOP} hoverOpenDelay={500} content="Explode">
        <Button
          className={styles.hoopsToolbarButtonAndButtonGroup}
          icon={<LegacyCustomIcon large icon={appStore.env.themeIsDark ? <ExplodeIconDark /> : <ExplodeIconLight />} />}
          e2eIdentifiers={[appStore.env.themeIsDark ? "explode-dark" : "explode-light"]}
        />
      </Tooltip>
    </Popover>
  );
};

export default observer(HoopsMenuExplodeButton);
