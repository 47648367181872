import { createElement, HTMLAttributes, PropsWithChildren, ReactElement, Ref, useEffect, useRef, useState } from "react";
import { Tooltip } from "@blueprintjs/core";
import { TextColor, TextVariant } from "@ui/Text/Text.types";
import { getElementClass, getElementTag, isTruncated } from "@ui/Text/Text.utils";
import classNames from "classnames";

import { Button } from "@components/Button";

import styles from "./Text.module.scss";

type Props = {
  variant?: TextVariant;
  color?: string | TextColor;
  clamp?: number;
  nowrap?: boolean;
  ref?: Ref<HTMLElement>;
  showReadMoreButton?: boolean;
  disableTooltip?: boolean;
  disableAlignmentWrapper?: boolean;
} & HTMLAttributes<HTMLHeadingElement> &
  HTMLAttributes<HTMLLabelElement> &
  HTMLAttributes<HTMLSpanElement>;

type TextProps = PropsWithChildren<Props>;

export const Text = ({
  children,
  nowrap,
  showReadMoreButton,
  disableTooltip,
  disableAlignmentWrapper,
  variant = TextVariant.Body,
  color,
  clamp,
  className,
  ...restProps
}: TextProps) => {
  const [tooltipText, setTooltipText] = useState<string | ReactElement>("");
  const [showAllText, setShowAllText] = useState(false);

  const commonStyles = { color: color || undefined, WebkitLineClamp: clamp };
  const ellipsisClass = clamp && clamp > 0 && !showAllText ? styles.ellipsis : {};
  const noWrapClass = nowrap ? styles.nowrap : {};
  const commonClasses = classNames(styles.text, className, ellipsisClass, noWrapClass);

  const ref = useRef<HTMLElement>(null);

  useEffect(() => {
    const showTooltip = isTruncated(ref.current ?? null);
    if (showTooltip) {
      setTooltipText(children as ReactElement);
    }
  }, [clamp, children, ref.current?.innerText]);

  const renderTextWithWrapper = () => {
    const elementTag = getElementTag(variant);
    const elementClass = getElementClass(variant);
    const element = createElement(
      elementTag,
      { ...restProps, ref: ref, className: classNames(elementClass, commonClasses), style: commonStyles },
      children
    );

    return disableAlignmentWrapper ? element : <span className={styles.verticalAlignmentWrapper}>{element}</span>;
  };

  const retVal = renderTextWithWrapper();

  const onShowMore = () => {
    setShowAllText(true);
    setTooltipText("");
  };

  const onShowLess = () => {
    setShowAllText(false);
    setTooltipText(children as ReactElement);
  };

  const renderWithTooltip = (children: ReactElement) => {
    return (
      <Tooltip content={tooltipText} hoverOpenDelay={400}>
        {children}
      </Tooltip>
    );
  };

  if (tooltipText && showReadMoreButton && !disableTooltip) {
    return (
      <div className={styles.textShowMoreContainer}>
        {renderWithTooltip(retVal)}
        <Button onClick={onShowMore} e2eIdentifiers="read-more" underline minimal>
          Read more
        </Button>
      </div>
    );
  } else if (tooltipText && !disableTooltip) {
    return renderWithTooltip(retVal);
  } else if (showAllText) {
    return (
      <div className={styles.textShowMoreContainer}>
        {retVal}
        <Button onClick={onShowLess} e2eIdentifiers="show-less" underline minimal>
          Show less
        </Button>
      </div>
    );
  }

  return retVal;
};
