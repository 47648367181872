import { useEffect } from "react";
import { NonIdealState, PopoverPosition, Spinner } from "@blueprintjs/core";
import { useInfiniteScrollFetch } from "@hooks/useInfiniteScrollFetch";
import { observer } from "mobx-react";

import { InfiniteScrollAnchor } from "@components/InfiniteScrollAnchor";
import { RelativeDateWithTooltip } from "@components/RelativeDateWithTooltip";
import { UnknownUserTag } from "@components/Reports/Editor/Extentions/Mention/MentionTag/UnknownUserTag";
import UserInfo from "@components/UserInfo/UserInfo";
import appStore from "@store/AppStore";
import { IBlock } from "@store/BlockStore";
import { EFeedEntityType, IFeed } from "@store/FeedStore";
import Text, { TextVariant } from "src/ui/Text";

import { getActionText, renderAttachmentExtraContent, renderBlockExtraContent } from "./utils";

import styles from "./HistoryPanel.module.scss";

type HistoryPanelProps = {
  block: IBlock;
};

const HistoryPanel = ({ block }: HistoryPanelProps) => {
  const { loadMoreAnchorRef, scrollContainerRef } = useInfiniteScrollFetch(
    () => block.fetchActionHistory(true),
    block.ui.isFetchingActionHistory
  );

  useEffect(() => {
    block.clearActionHistory();
    block.fetchActionHistory(false);
  }, [block]);

  if (block.ui.isFetchingActionHistory && !block.actionHistoryFeed.length) {
    return <NonIdealState icon={<Spinner size={30} />} />;
  }

  // TODO: move the be the first to start a discussion to the comments panel
  const renderNoChanges = () => {
    if (!block.actionHistoryFeed.length) {
      return <NonIdealState icon="chat" iconSize={20} title="No  history yet" />;
    }

    return null;
  };

  const renderExtraContent = (i: IFeed) => {
    switch (i.referenceType) {
      case EFeedEntityType.ATTACHMENT:
        return renderAttachmentExtraContent(block, i);
      case EFeedEntityType.BLOCK:
        return renderBlockExtraContent(i);
      default:
        return null;
    }
  };

  const renderFeedItems = (feedItems: IFeed[]) => {
    return feedItems.map(feedItem => {
      const user = appStore.orgModel.info.userWithId(feedItem.createdBy);

      return (
        <div key={feedItem.id} className={styles.historyPanelItem}>
          <div className={styles.historyPanelItemOwnerHeader}>
            {user ? <UserInfo user={user} size="small" /> : <UnknownUserTag showAvatar />}
            <RelativeDateWithTooltip
              variant={TextVariant.BodyDimmed}
              epochTime={feedItem.createdAt}
              popoverPosition={PopoverPosition.TOP}
            />
          </div>
          <div className={styles.historyPanelItemContent}>
            <Text variant={TextVariant.BodyDimmed}>{getActionText(feedItem)}</Text>
            {renderExtraContent(feedItem)}
          </div>
        </div>
      );
    });
  };

  return (
    <div className={styles.historyPanel} ref={scrollContainerRef}>
      {renderFeedItems(block.actionHistoryFeed)}
      <InfiniteScrollAnchor hasMoreItems={block.ui.hasMoreActionHistoryFeedItems} loadMoreAnchorRef={loadMoreAnchorRef} />
      {renderNoChanges()}
    </div>
  );
};

export default observer(HistoryPanel);
