import { BlueprintIcon } from "@ui/BlueprintIcon";
import { Text, TextVariant } from "@ui/Text";
import { observer } from "mobx-react";

import styles from "./UnknownUserTag.module.scss";

interface IUnknownUserTagProps {
  showAvatar?: boolean;
  avatarOnly?: boolean;
}

const UnknownUserTag = (props: IUnknownUserTagProps) => {
  const { showAvatar, avatarOnly } = props;

  return (
    <div className={styles.unknownUserTag}>
      {(showAvatar || avatarOnly) && <BlueprintIcon icon="person" />}
      {!avatarOnly && <Text variant={TextVariant.Button1Underline}>Unknown user</Text>}
    </div>
  );
};

export default observer(UnknownUserTag);
