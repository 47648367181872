import { Intent } from "@blueprintjs/core";
import { toArray } from "@rollup-io/engineering";
import { applySnapshot, cast, flow, IAnyModelType, Instance, SnapshotIn, SnapshotOut, types } from "mobx-state-tree";
import { Socket } from "socket.io-client";

import { showApiErrorToast, showToast } from "@components/UiLayers/toaster";
import { UserRole } from "@rollup-api/api/authTypes";
import { TImportsListResponse } from "@rollup-api/api/imports";
import { Import, ImportInProgressStatuses, ImportStatus, OrganizationModuleType, Profile } from "@rollup-api/models";
import { Integration, IntegrationProvider } from "@rollup-api/models/integrations";
import { OrganizationSettingsModel } from "@rollup-api/models/organizationSettings/organizationSettings.model";
import { ProfileSortKeys } from "@rollup-api/models/profiles/ProfileSortKeys";
import { GetProfilesRequestDto, ProfileStatuses } from "@rollup-api/models/profiles/profilesRequestDto.model";
import { SortOrders } from "@rollup-api/SortOrders";
import { NoReturnGenerator, SimpleGenerator } from "@rollup-types/typeUtils";
import { AttachmentModuleStore } from "@store/AttachmentModuleStore";
import { CatalogItemModuleStore } from "@store/CatalogItem/CatalogItemModuleStore";
import { CustomUnitModuleStore } from "@store/CustomUnitModuleStore";
import { IImport, IImportMobxType, ImportStore } from "@store/ImportStore";
import { PartNumberSchemaModuleStore } from "@store/PartNumberSchemaModuleStore";
import { UploadModuleStore } from "@store/UploadModuleStore";
import { filterEnumArray } from "@utilities";
import { convertTimestamp } from "@utilities/Date";
import { rollupClient } from "src/core/api";
import { IWorkspaceListItem } from "src/services/WorkspaceService";

import { OrganizationService } from "../services/OrganizationService";

import appStore from "./AppStore";
import { OrgInfoStore } from "./OrgInfoStore";

export const OrganizationStore = types
  .model("Organization", {
    info: types.optional(OrgInfoStore, {}),
    workspacesList: types.array(types.frozen<IWorkspaceListItem>()),
    integrations: types.map(types.frozen<Integration>()),
    uploads: types.optional(UploadModuleStore, {}),
    settings: types.maybeNull(types.frozen<OrganizationSettingsModel>()),
    importsMap: types.map<IImportMobxType>(types.late((): IAnyModelType => ImportStore)),
    hasMoreImports: types.optional(types.boolean, false),
    catalogItems: types.optional(CatalogItemModuleStore, {}),
    partNumberSchemas: types.optional(PartNumberSchemaModuleStore, {}),
    attachments: types.optional(AttachmentModuleStore, {}),
    customUnits: types.optional(CustomUnitModuleStore, {}),
  })
  .actions(self => ({
    fetchOrgData: flow(function* fetchOrgData(): SimpleGenerator<IOrganizationSnapshotIn | undefined> {
      try {
        const organization = yield OrganizationService.FetchOrganization([
          OrganizationModuleType.CatalogItems,
          OrganizationModuleType.CatalogItemVersions,
          OrganizationModuleType.CatalogItemReferences,
          OrganizationModuleType.Workspaces,
          OrganizationModuleType.PartNumberSchemas,
          OrganizationModuleType.Attachments,
          OrganizationModuleType.CustomUnits,
        ]);
        if (!organization) {
          showToast("Couldn't load organization", "danger", "info-sign");
          return;
        }
        return organization;
      } catch (e) {
        console.error(e);
        showToast("Error loading organization", "danger", "info-sign");
      }
    }),
    fetchOrgMembers: flow(function* fetchOrgMembers(statuses: ProfileStatuses[]): SimpleGenerator<Profile[]> {
      const dto: GetProfilesRequestDto = {
        take: 1000,
        skip: 0,
        sortedBy: ProfileSortKeys.name,
        sortOrder: SortOrders.ASC,
        statuses,
      };

      try {
        const profiles = yield rollupClient.organizations.retrieveProfiles(dto);

        if (!profiles) {
          console.warn(`Error loading organization members for ${self.info.id}, ${profiles}`);
          showApiErrorToast("Error loading organization members", new Error());
          return [];
        }

        return profiles.map((p: Profile) => ({
          ...p,
          roles: filterEnumArray(UserRole, p.roles ?? []) as UserRole[],
        }));
      } catch (error) {
        console.warn(error);
        showApiErrorToast("Error loading organization members", new Error());
        return [];
      }
    }),
    fetchSettings: flow(function* fetchSettings(): SimpleGenerator<OrganizationSettingsModel | undefined> {
      try {
        return yield rollupClient.organizationSettings.retrieve();
      } catch (error) {
        console.error(error);
        showApiErrorToast("Error fetching organization settings");
      }
    }),
  }))
  .actions(self => ({
    loadOrganization: flow(function* (): NoReturnGenerator<
      [IOrganizationSnapshotIn | undefined, Profile[], Profile[], OrganizationSettingsModel | undefined]
    > {
      const [orgData, orgMembers, invitedUsers, settings = {}] = yield Promise.all([
        self.fetchOrgData(),
        self.fetchOrgMembers([ProfileStatuses.ACTIVE]),
        self.fetchOrgMembers([ProfileStatuses.INVITED]),
        self.fetchSettings(),
      ]);
      if (orgData) {
        const orgModelSnapshot: IOrganizationSnapshotIn = {
          ...orgData,
          settings,
          info: { ...orgData.info, orgMembers, invitedUsers },
        };
        applySnapshot(self, orgModelSnapshot);
      }
    }),
  }))
  .views(self => ({
    get imports() {
      return toArray<IImport>(self.importsMap);
    },
    get importsInProgress(): IImport[] {
      return toArray<IImport>(self.importsMap, (_key, i) => {
        if (i.status === ImportStatus.PendingApproval) {
          return i.createdBy === appStore.userModel?.id;
        } else {
          return ImportInProgressStatuses.includes(i.status);
        }
      });
    },
    get sortedWorkspaces() {
      return self.workspacesList?.slice().sort((a, b) => {
        return new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime();
      });
    },
    get connectedProviders(): Map<IntegrationProvider, Integration> {
      const providers = new Map<IntegrationProvider, Integration>();
      for (const [, integration] of self.integrations) {
        providers.set(integration.provider, integration);
      }
      return providers;
    },
    hasProvider(provider: IntegrationProvider): boolean {
      return this.connectedProviders?.has(provider);
    },
    isValidWorkspaceId(workspaceId: string): boolean {
      return self.workspacesList?.some(w => w.id === workspaceId) ?? false;
    },
  }))
  .actions(self => {
    return {
      updateSettings: flow(function* updateSettings(settings: OrganizationSettingsModel) {
        try {
          const result: OrganizationSettingsModel = yield rollupClient.organizationSettings.update(settings);
          self.settings = result;
          showToast("Organization settings updated", "success");
        } catch (error) {
          console.error(error);
          showApiErrorToast("Error updating organization settings");
        }
      }),
      fetchImports: flow(function* fetchImports(loadMore?: boolean) {
        if (self.importsMap.size && !loadMore) {
          return;
        }

        const res: TImportsListResponse = yield rollupClient.imports.retrieveList({
          take: 100,
          skip: self.importsMap.size,
        });

        res.imports.forEach(i => {
          self.importsMap.set(i.id, {
            ...i,
            createdAt: convertTimestamp(i.createdAt),
            updatedAt: convertTimestamp(i.updatedAt),
          });
        });

        self.hasMoreImports = res.hasMore;
      }),
      addExistingImport(dto: Import) {
        if (!dto.id || self.importsMap.has(dto.id)) {
          return undefined;
        }

        self.importsMap.put({
          ...dto,
          createdAt: convertTimestamp(dto.updatedAt),
          updatedAt: convertTimestamp(dto.createdAt),
        });
      },
      updateConnectionProviderMetadata(provider: IntegrationProvider, metadata: any) {
        if (self.hasProvider(provider)) {
          const integration = self.connectedProviders.get(provider);
          if (integration) {
            self.integrations.set(provider, { ...integration, metadata });
          }
        }
      },
      setWorkspacesList(workspaces: IWorkspaceListItem[]) {
        self.workspacesList = cast(workspaces);
      },
      addWorkspaceToList(workspace: IWorkspaceListItem) {
        if (self.workspacesList) {
          if (!self.workspacesList.find(w => w.id === workspace.id)) {
            self.workspacesList.push(workspace);
          }
        } else {
          self.workspacesList = cast([workspace]);
        }
      },
      updateWorkspaceLabel(id: string, label: string) {
        if (!self.workspacesList?.length) {
          return false;
        }
        const workspaceIndex = self.workspacesList.findIndex(w => w.id === id);
        if (workspaceIndex >= 0) {
          const existingItem = self.workspacesList[workspaceIndex];
          self.workspacesList[workspaceIndex] = { ...existingItem, label, updatedAt: new Date().toISOString() };
          return true;
        }
        return false;
      },
      removeWorkspaceFromList(id: string) {
        const workspace = self.workspacesList?.find(w => w.id === id);
        if (workspace) {
          self.workspacesList.remove(workspace);
        }
      },
      fetchIntegrations: flow(function* fetchIntegrations() {
        try {
          const { status, data } = yield rollupClient.integrations.getAll();

          if (status !== 200) {
            throw new Error();
          }

          data.forEach((i: Integration) => self.integrations.set(i.provider, i));
        } catch (err) {
          console.warn(err);
          showApiErrorToast("Could not get integrations", new Error());
          return false;
        }
        return true;
      }),
      setIntegrationData(provider: IntegrationProvider, data: Integration) {
        self.integrations.set(provider, data);
      },
      deleteIntegration: flow(function* deleteIntegration(id: string) {
        try {
          const { status, data } = yield rollupClient.integrations.delete(id);

          if (status !== 200) {
            throw new Error();
          }

          self.integrations.delete(data.provider);

          showToast("Integration deleted");
        } catch (err) {
          showApiErrorToast("Could not delete integration", new Error());
          return false;
        }
        return true;
      }),
      createIntegration: flow(function* createIntegration(integration: Integration) {
        if (!integration) {
          showToast("Could not create integration");
          return false;
        }

        // Attempt to close any popup windows created by the integration connection
        appStore.settingsModel?.integrations?.find(i => i.slug === integration.provider)?.closeIntegrationWindow();
        self.integrations.set(integration.provider, integration);
        if (integration.provider === IntegrationProvider.Google) {
          try {
            const driveList = yield rollupClient.integrations.listDrives();
            if (driveList.data?.length) {
              const { name, id } = driveList.data[0];
              const setDriveQuery = yield rollupClient.integrations.setTeamDrive(id);
              if (setDriveQuery.data) {
                self.integrations.set(integration.provider, setDriveQuery.data);
              }
              showToast(`Connected Google Workspaces for team drive ${name}`, Intent.PRIMARY, "cloud", undefined, 3000);
            }
          } catch (error) {
            console.warn(error);
            showApiErrorToast("Could not connect Google Workspace", error as Error);
          }
        } else {
          showToast("Integration created");
        }
        return true;
      }),
    };
  })
  .actions(self => ({
    getWorkspaceLabelById: flow(function* (workspaceId: string): Generator<any, string | undefined, any> {
      yield appStore.pullOrgWorkspaces();
      const workspace = self.workspacesList?.find(w => w.id === workspaceId);
      if (workspace) {
        return workspace.label;
      }
    }),
  }));

export interface IOrganization extends Instance<typeof OrganizationStore> {}

export interface IOrganizationSnapshotIn extends SnapshotIn<typeof OrganizationStore> {}

export interface IOrganizationSnapshotOut extends SnapshotOut<typeof OrganizationStore> {}

export const subscribeToOrgEvents = (socket: Socket) => {
  socket.on("createIntegration", (integration?: Integration) => {
    if (integration) {
      appStore.orgModel.createIntegration(integration);
    }
  });
};
