import { useCallback } from "react";
import { Position, Tooltip } from "@blueprintjs/core";
import { observer } from "mobx-react";

import HomeIconDark from "@assets/icons/hoopsIcons/dark/home_dark.svg?react";
import HomeIconLight from "@assets/icons/hoopsIcons/light/home_light.svg?react";
import { Button } from "@components/Button";
import { LegacyCustomIcon } from "@components/Shared/LegacyCustomIcon/LegacyCustomIcon";
import appStore from "@store/AppStore";

import styles from "../HoopsMenu.module.scss";

type HoopsMenuHomeButtonProps = {
  viewer: Communicator.WebViewer;
};

const HoopsMenuHomeButton = ({ viewer }: HoopsMenuHomeButtonProps) => {
  const resetCamera = useCallback(() => {
    viewer.view.resetCamera();
    appStore.env.attachmentViewer?.setViewOrientation(-1);
  }, [viewer]);

  return (
    <Tooltip position={Position.TOP} content="Home view">
      <Button
        className={styles.hoopsToolbarButtonAndButtonGroup}
        icon={<LegacyCustomIcon large icon={appStore.env.themeIsDark ? <HomeIconDark /> : <HomeIconLight />} />}
        onClick={resetCamera}
        e2eIdentifiers="reset-camera"
      />
    </Tooltip>
  );
};

export default observer(HoopsMenuHomeButton);
