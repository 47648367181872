import appStore from "@store/AppStore";
import { IBlock } from "@store/BlockStore";

import { NodeInfo } from "./TableComponent/types";

const fillBlockListAndMap = (block: IBlock, list: Array<NodeInfo>, map: Map<string, IBlock>, path: string[]) => {
  if (map.has(block.id)) {
    return;
  }

  const newPath = [...path, block.id];
  list.push({ block, path: newPath });
  map.set(block.id, block);

  for (const childNode of block.validatedChildren) {
    fillBlockListAndMap(childNode, list, map, newPath);
  }
};

// TODO: This only fills in the list of blocks that are in the tree. It does not fill in detached blocks.
// We need to decide whether that is the appropriate behavior.
export const blockNodeList = (): NodeInfo[] => {
  const rootBlock = appStore.workspaceModel?.rootBlock;
  if (!rootBlock || typeof rootBlock !== "object") {
    return [];
  }
  const list = new Array<NodeInfo>();
  const map = new Map<string, IBlock>();

  fillBlockListAndMap(rootBlock, list, map, []);
  return list;
};
