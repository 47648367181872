import { ChangeEvent, useRef, useState } from "react";
import useFileUploadMessage from "@hooks/useFileUploadMessage";

import AddAttachmentMenu from "@components/AddAttachmentMenu/AddAttachmentMenu";
import { Button } from "@components/Button";
import { Popover } from "@components/Popover";
import { IntegrationLinkData } from "@rollup-api/models/integrations";
import { ReferenceLinkData } from "@rollup-api/models/references/references.model";
import { ALLOWED_FILE_UPLOAD_SIZE, validateFiles } from "@utilities";

import AttachedLinkDialog from "../AttachedLinkDialog/AttachedLinkDialog";

import styles from "./AddAttachmentButton.module.scss";

type AddAttachmentButtonProps = {
  onFileUpload: ((file: FileList) => void) | ((file: FileList) => Promise<void>);
  onAttachLink: (linkName: string, linkUrl: string) => void;
  onAttachIntegration: (data: IntegrationLinkData) => void;
  onAttachReferenceLink: (data: ReferenceLinkData) => void;
  multiple?: boolean;
};

export const AddAttachmentButton = (props: AddAttachmentButtonProps) => {
  const [isAttachedLinkDialogOpen, setIsAttachedLinkDialogOpen] = useState(false);
  const { onFileUpload, onAttachLink, onAttachIntegration, multiple } = props;
  const { onAttachReferenceLink } = props;
  const { showSizeErrorMessage, showFormatErrorMessage } = useFileUploadMessage();
  // create a ref
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleFileInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.currentTarget?.files?.length) {
      const allowedFiles = validateFiles(
        e.currentTarget?.files,
        [],
        ALLOWED_FILE_UPLOAD_SIZE,
        showSizeErrorMessage,
        showFormatErrorMessage
      );
      onFileUpload?.(allowedFiles);
      e.target.value = "";
    }
  };

  const handleAttachLinkSubmit = (linkName: string, linkUrl: string) => {
    onAttachLink?.(linkName, linkUrl);
  };

  return (
    <div className={styles.addAttachmentButton}>
      <AttachedLinkDialog
        isOpen={isAttachedLinkDialogOpen}
        onSubmit={handleAttachLinkSubmit}
        onCancel={() => setIsAttachedLinkDialogOpen(false)}
        onClose={() => setIsAttachedLinkDialogOpen(false)}
      />
      <input
        ref={fileInputRef}
        multiple={multiple}
        type="file"
        className={styles.addAttachmentButtonHiddenInput}
        onChange={handleFileInputChange}
        aria-label="Upload file"
      />
      <Popover
        placement="bottom-start"
        hasBackdrop
        content={
          <AddAttachmentMenu
            onFileUpload={() => fileInputRef?.current?.click()}
            onAttachLink={() => setIsAttachedLinkDialogOpen(true)}
            onAttachIntegration={onAttachIntegration}
            onAttachReferenceLink={onAttachReferenceLink}
          />
        }
      >
        <Button minimal alignText="center" icon="plus" e2eIdentifiers="add-attachment">
          Add attachment
        </Button>
      </Popover>
    </div>
  );
};

export default AddAttachmentButton;
