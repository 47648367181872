import sortBy from "lodash/sortBy";

import { findLongestPrefix } from "./TextUtil";

export type KiCadLayerType = "jumper" | "mixed" | "power" | "signal" | "user";
export type GerberLayerType = "copper" | "soldermask" | "silkscreen" | "solderpaste" | "drill" | "outline" | "drawing" | null;
export type GerberSideType = "top" | "bottom" | "inner" | "all" | null;

export type GerberLayer = {
  side: GerberSideType;
  type: GerberLayerType;
  isMonochrome?: boolean;
  filename: string;
  url: string;
};

export type PcbLayer = {
  ordinal: number;
  sourceType: "kicad" | "gerber";
  name: string;
  type: KiCadLayerType | GerberLayerType;
  side?: GerberSideType;
  filename?: string;
  isMonochrome?: boolean;
  hidden?: boolean;
  url?: string;
};

export function processGerberMetadata(input: GerberLayer[]): PcbLayer[] | null {
  if (!input || !Array.isArray(input) || !input.length) {
    return null;
  }

  const sortedInput = sortBy(input, "filename");
  const prefix = findLongestPrefix(sortedInput.map(l => l.filename));

  const layers = new Array<PcbLayer>();
  const ordinals = getOrdinalsFromGerberLayers(sortedInput);
  sortedInput.forEach((l, index) => {
    if (l.filename) {
      layers.push({
        sourceType: "gerber",
        ordinal: ordinals[index],
        name: l.filename.slice(prefix.length).replace(".svg", ""),
        hidden: false,
        isMonochrome: true,
        ...l,
      });
    }
  });

  return layers;
}

// TODO: Clean up this function when we have proper support for theming
// Copied from KiCAD theme file. We can improve this later.
const KICAD_COLOR_MAP = {
  anchor: "rgb(255, 38, 226)",
  aux_items: "rgb(255, 255, 255)",
  b_adhes: "rgb(0, 0, 132)",
  b_crtyd: "rgb(38, 233, 255)",
  b_fab: "rgb(88, 93, 132)",
  b_mask: "rgba(2, 255, 238, 0.400)",
  b_paste: "rgba(0, 194, 194, 0.902)",
  b_silks: "rgb(232, 178, 167)",
  background: "rgb(0, 16, 35)",
  cmts_user: "rgb(89, 148, 220)",
  conflicts_shadow: "rgba(255, 0, 5, 0.502)",
  copper: {
    b: "rgb(77, 127, 196)",
    f: "rgb(200, 52, 52)",
    in1: "rgb(127, 200, 127)",
    in10: "rgb(237, 124, 51)",
    in11: "rgb(91, 195, 235)",
    in12: "rgb(247, 111, 142)",
    in13: "rgb(167, 165, 198)",
    in14: "rgb(40, 204, 217)",
    in15: "rgb(232, 178, 167)",
    in16: "rgb(242, 237, 161)",
    in17: "rgb(237, 124, 51)",
    in18: "rgb(91, 195, 235)",
    in19: "rgb(247, 111, 142)",
    in2: "rgb(206, 125, 44)",
    in20: "rgb(167, 165, 198)",
    in21: "rgb(40, 204, 217)",
    in22: "rgb(232, 178, 167)",
    in23: "rgb(242, 237, 161)",
    in24: "rgb(237, 124, 51)",
    in25: "rgb(91, 195, 235)",
    in26: "rgb(247, 111, 142)",
    in27: "rgb(167, 165, 198)",
    in28: "rgb(40, 204, 217)",
    in29: "rgb(232, 178, 167)",
    in3: "rgb(79, 203, 203)",
    in30: "rgb(242, 237, 161)",
    in4: "rgb(219, 98, 139)",
    in5: "rgb(167, 165, 198)",
    in6: "rgb(40, 204, 217)",
    in7: "rgb(232, 178, 167)",
    in8: "rgb(242, 237, 161)",
    in9: "rgb(141, 203, 129)",
  },
  cursor: "rgb(255, 255, 255)",
  drc_error: "rgba(215, 91, 107, 0.800)",
  drc_exclusion: "rgba(255, 255, 255, 0.800)",
  drc_warning: "rgba(255, 208, 66, 0.800)",
  dwgs_user: "rgb(194, 194, 194)",
  eco1_user: "rgb(180, 219, 210)",
  eco2_user: "rgb(216, 200, 82)",
  edge_cuts: "rgb(208, 210, 205)",
  f_adhes: "rgb(132, 0, 132)",
  f_crtyd: "rgb(255, 38, 226)",
  f_fab: "rgb(175, 175, 175)",
  f_mask: "rgba(216, 100, 255, 0.400)",
  f_paste: "rgba(180, 160, 154, 0.902)",
  f_silks: "rgb(242, 237, 161)",
  footprint_text_invisible: "rgb(132, 132, 132)",
  grid: "rgb(132, 132, 132)",
  grid_axes: "rgb(194, 194, 194)",
  locked_shadow: "rgba(255, 38, 226, 0.502)",
  margin: "rgb(255, 38, 226)",
  pad_plated_hole: "rgb(194, 194, 0)",
  pad_through_hole: "rgb(227, 183, 46)",
  page_limits: "rgb(132, 132, 132)",
  plated_hole: "rgb(26, 196, 210)",
  ratsnest: "rgba(0, 248, 255, 0.349)",
  user_1: "rgb(194, 194, 194)",
  user_2: "rgb(89, 148, 220)",
  user_3: "rgb(180, 219, 210)",
  user_4: "rgb(216, 200, 82)",
  user_5: "rgb(194, 194, 194)",
  user_6: "rgb(89, 148, 220)",
  user_7: "rgb(180, 219, 210)",
  user_8: "rgb(216, 200, 82)",
  user_9: "rgb(232, 178, 167)",
  via_blind_buried: "rgb(187, 151, 38)",
  via_hole: "rgb(227, 183, 46)",
  via_micro: "rgb(0, 132, 132)",
  via_through: "rgb(236, 236, 236)",
  worksheet: "rgb(200, 114, 171)",
};

export function getColorFromOrdinal(ordinal: number) {
  // Ugly hack to get the color for inner layers (1 - 30) instead of an even more giant switch statement
  if (ordinal >= 1 && ordinal <= 30) {
    const indexString = `in${ordinal}`;
    return (KICAD_COLOR_MAP.copper as any)[indexString] as string;
  }

  switch (ordinal) {
    case 0:
      return KICAD_COLOR_MAP.copper.f;
    case 31:
      return KICAD_COLOR_MAP.copper.b;
    case 32:
      return KICAD_COLOR_MAP.b_adhes;
    case 33:
      return KICAD_COLOR_MAP.f_adhes;
    case 34:
      return KICAD_COLOR_MAP.b_paste;
    case 35:
      return KICAD_COLOR_MAP.f_paste;
    case 36:
      return KICAD_COLOR_MAP.b_silks;
    case 37:
      return KICAD_COLOR_MAP.f_silks;
    case 38:
      return KICAD_COLOR_MAP.b_mask;
    case 39:
      return KICAD_COLOR_MAP.f_mask;
    case 40:
      return KICAD_COLOR_MAP.dwgs_user;
    case 41:
      return KICAD_COLOR_MAP.cmts_user;
    case 42:
      return KICAD_COLOR_MAP.eco1_user;
    case 43:
      return KICAD_COLOR_MAP.eco2_user;
    case 44:
      return KICAD_COLOR_MAP.edge_cuts;
    case 45:
      return KICAD_COLOR_MAP.margin;
    case 46:
      return KICAD_COLOR_MAP.b_crtyd;
    case 47:
      return KICAD_COLOR_MAP.f_crtyd;
    case 48:
      return KICAD_COLOR_MAP.b_fab;
    case 49:
      return KICAD_COLOR_MAP.f_fab;
  }
  return "black";
}

function getOrdinalsFromGerberLayers(layers: GerberLayer[]) {
  let innerCounter = 1;
  let userCounter = 40;

  // We can have multiple inner or user layers, so we keep a counter of those
  return layers.map(layer => {
    switch (layer.type) {
      case "copper":
        switch (layer.side) {
          case "top":
            return 0;
          case "bottom":
            return 31;
          case "inner":
            return innerCounter++;
          default:
            return 0;
        }
      case "soldermask":
        switch (layer.side) {
          case "top":
            return 39;
          case "bottom":
            return 38;
          default:
            return 39;
        }
      case "solderpaste":
        switch (layer.side) {
          case "top":
            return 35;
          case "bottom":
            return 34;
          default:
            return 35;
        }
      case "silkscreen":
        switch (layer.side) {
          case "top":
            return 37;
          case "bottom":
            return 36;
          default:
            return 37;
        }
      case "drill":
        return 9;
      case "outline":
        return 44;
      case "drawing":
        return userCounter++;
      default:
        return 0;
    }
  });
}
