import { DialogBody, Intent, Tag } from "@blueprintjs/core";
import { observer } from "mobx-react";
import moment from "moment/moment";

import { Button } from "@components/Button";
import { DialogLegacy } from "@components/Dialog";
import { IImport } from "@store/ImportStore";
import { downloadJsonObject, statusIntent, statusLabel } from "@utilities";
import Text, { TextVariant } from "src/ui/Text";

import "./LogsDialog.scss";

type Props = {
  importEntity?: IImport;
  onClose: () => void;
};

const LogsDialog = (props: Props) => {
  const { importEntity, onClose } = props;

  const handleDownload = () => {
    if (importEntity?.logs) {
      downloadJsonObject(importEntity?.logs, `import-logs-${importEntity?.createdAt}`);
    }
  };

  return (
    <DialogLegacy
      className="logs-dialog"
      icon="import"
      isCloseButtonShown
      title="Import logs"
      onClose={onClose}
      canEscapeKeyClose
      canOutsideClickClose
      isOpen={!!importEntity}
    >
      <DialogBody className="logs-dialog--content">
        {importEntity?.logs.map(logItem => (
          <div className="logs-dialog--item" key={`${logItem.timestamp}`}>
            <div>
              <Text variant={TextVariant.Caption}>{moment(logItem.timestamp).format("YYYY-MM-DD HH:mm:ss")}</Text>
              <div>{logItem.activity}</div>
            </div>
            <Tag className="logs-dialog--tag" large round intent={statusIntent(logItem.status)}>
              {statusLabel(logItem.status)}
            </Tag>
          </div>
        ))}
      </DialogBody>
      <div className="logs-dialog--footer">
        <Button outlined large onClick={onClose} e2eIdentifiers="close-logs-dialog">
          Close
        </Button>
        <Button large intent={Intent.PRIMARY} disabled={!importEntity?.logs} onClick={handleDownload} e2eIdentifiers="download-logs">
          Download logs
        </Button>
      </div>
    </DialogLegacy>
  );
};

export default observer(LogsDialog);
