import { observer } from "mobx-react";

import GoogleSheetsIcon from "@assets/icons/thirdParty/google_sheets.svg?react";
import { IntegrationProvider } from "@rollup-api/models";
import { DataSource } from "@rollup-api/models/data-sources";

interface IDataSourceIconProps {
  dataSource: DataSource;
}

const DataSourceIcon = (props: IDataSourceIconProps) => {
  const { dataSource } = props;

  const renderGoogleFileIcon = (mimeType: string) => {
    switch (mimeType) {
      case "application/vnd.google-apps.spreadsheet":
        return <GoogleSheetsIcon />;
    }
  };

  switch (dataSource.provider) {
    case IntegrationProvider.Google:
      return renderGoogleFileIcon(dataSource.metadata.mimeType);
  }
};

export default observer(DataSourceIcon);
