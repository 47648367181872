import { ReactNode, useEffect, useRef, useState } from "react";
import { PopoverInteractionKind, PopoverProps } from "@blueprintjs/core";
import { useClosePopoverOnClickOutside } from "@hooks/useClosePopoverOnClickOutside";
import { observer } from "mobx-react";

import { Popover } from "@components/Popover";
import { IAnnotationList } from "@store/AnnotationListStore";
import appStore from "@store/AppStore";
import { StoreType } from "@store/types";

import { EntityAnnotationList } from "./EntityAnnotationList";
import { EntityAnnotationListHeader } from "./EntityAnnotationListHeader";

interface IEntityAnnotationListPopoverProps {
  annotationList: IAnnotationList;
  storeType: StoreType;
  children: ReactNode;
  entityId: string;
  entityIdsWithComments: string[];
  popoverProps?: PopoverProps;
  className?: string;
  popoverClassName?: string;
  lastFocusedCommentId?: string;
  isFocusingComment?: boolean;
  disableOpenOnTargetClick?: boolean;
  isOpen?: boolean;
  onFocusComment?(): void;
  onClose?(): void;
  onInteraction?(nextOpenState: boolean): void;
}

const EntityAnnotationListPopover = (props: IEntityAnnotationListPopoverProps) => {
  const { annotationList, storeType, children, lastFocusedCommentId, onFocusComment } = props;
  const { isFocusingComment, className, popoverClassName, popoverProps, onInteraction } = props;
  const { disableOpenOnTargetClick, isOpen, entityId, entityIdsWithComments } = props;
  const { activeCommentPopoverEntityId, setActiveCommentPopoverEntityId } = appStore.ui;
  const [isEditingComment, setIsEditingComment] = useState(false);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const popoverRef = useRef<HTMLDivElement>(null);
  const interactionKind =
    annotationList.hasComment && !isFocusingComment && !isEditingComment ? PopoverInteractionKind.HOVER : PopoverInteractionKind.CLICK;
  const commentCount = annotationList.annotations[0]?.commentCount ?? 0;
  const isActiveCommentPopover = activeCommentPopoverEntityId === entityId;

  // this allows the user to close the popover by clicking outside even when
  // interactionKind is set to HOVER. This is useful when the users just add a first comment,
  // since hover out logic doesn't work if the cursor is not initially hovering the popover
  useClosePopoverOnClickOutside({
    popoverRef,
    setPopoverOpen: setIsPopoverOpen,
    disabled: interactionKind !== PopoverInteractionKind.HOVER || !isPopoverOpen,
  });

  useEffect(() => {
    setIsPopoverOpen(isActiveCommentPopover);
  }, [isActiveCommentPopover]);

  useEffect(() => {
    // clear activeCommentPopoverEntityId when the popover is closed
    if (!isPopoverOpen && isActiveCommentPopover) {
      setActiveCommentPopoverEntityId("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPopoverOpen]);

  useEffect(() => {
    if (isOpen !== undefined) {
      setIsPopoverOpen(isOpen);
    }
  }, [isOpen]);

  const handleInteraction = (nextOpenState: boolean) => {
    if (interactionKind === PopoverInteractionKind.HOVER) {
      setIsPopoverOpen(nextOpenState);
      onInteraction?.(nextOpenState);
    } else {
      if (nextOpenState && disableOpenOnTargetClick) {
        // we purposefully ignore when nextOpenState if true, so that the popover
        // doesn't open when user clicks on the target
        return;
      }
      setIsPopoverOpen(nextOpenState);
      onInteraction?.(nextOpenState);
    }
    if (isEditingComment) {
      setIsEditingComment(false);
    }
  };

  const handleCancel = () => {
    if (commentCount === 0) {
      setIsPopoverOpen(false);
    }
  };

  const renderPopoverContent = () => {
    return (
      <div>
        {!!commentCount && (
          <EntityAnnotationListHeader
            entityId={entityId}
            entityIdsWithComments={entityIdsWithComments}
            onClose={() => setIsPopoverOpen(false)}
          />
        )}
        <EntityAnnotationList
          annotationList={annotationList}
          storeType={storeType}
          onEditingComment={setIsEditingComment}
          lastFocusedCommentId={lastFocusedCommentId}
          onFocusComment={onFocusComment}
          // autoFocus caused an odd bug with the scrollbar in req table, reported in this GH comment:
          // https://github.com/rollup-io/rollup-web/pull/3862#issuecomment-2296204928
          disableAutoFocus={interactionKind === PopoverInteractionKind.HOVER}
          onCancel={handleCancel}
        />
      </div>
    );
  };

  return (
    <Popover
      className={className}
      popoverRef={popoverRef}
      popoverClassName={popoverClassName}
      content={renderPopoverContent()}
      isOpen={isPopoverOpen}
      onInteraction={handleInteraction}
      interactionKind={interactionKind}
      {...popoverProps}
    >
      {children}
    </Popover>
  );
};

export default observer(EntityAnnotationListPopover);
