import { MouseEvent } from "react";
import classNames from "classnames";
import { observer } from "mobx-react";

import { Button } from "@components/Button";
import { IE2EIdentifiers } from "@utilities/E2EUtils";

import "./PlusButton.scss";

interface IPlusButtonProps {
  className?: string;
  e2eIdentifiers?: IE2EIdentifiers;
  onClick(e: MouseEvent): void;
}

const PlusButton = (props: IPlusButtonProps) => {
  const { className, e2eIdentifiers = "add", onClick } = props;

  return (
    <Button className={classNames("plus-button", className)} onClick={onClick} e2eIdentifiers={e2eIdentifiers} small minimal highlight>
      <div className="plus-button--circle">+</div>
    </Button>
  );
};

export default observer(PlusButton);
