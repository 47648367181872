import { ReactNode } from "react";
import { Classes } from "@blueprintjs/core";
import classNames from "classnames";

import styles from "./TabTitle.module.scss";

type TabTitleProps = {
  title: ReactNode;
  count?: number;
};

export const TabTitle = ({ title, count }: TabTitleProps) => {
  const renderCounter = () => {
    if (count) {
      return (
        <div className={styles.tabTitleCounter}>
          <div className={classNames(styles.tabTitleCounterCallout, Classes.CALLOUT, Classes.INTENT_PRIMARY)}>{count}</div>
        </div>
      );
    }

    return null;
  };

  return (
    <div className={styles.tabTitle}>
      <div>{title}</div>
      {renderCounter()}
    </div>
  );
};

export type { TabTitleProps };
