import { useState } from "react";

import ImportsTable from "@components/Imports/Table/ImportsTable";
import appStore from "@store/AppStore";

const SettingsImports = () => {
  const imports = appStore.orgModel?.imports || [];
  const [loading, setLoading] = useState(false);

  const handleLoadImports = (loadMore?: boolean) => {
    setLoading(true);
    appStore.orgModel?.fetchImports(loadMore).finally(() => setLoading(false));
  };

  return (
    <div>
      <ImportsTable loading={loading} title="Organization imports" imports={imports} onLoadMore={() => handleLoadImports(true)} />
    </div>
  );
};

export default SettingsImports;
