import { mathInstance, MathResult, toNumber } from "@rollup-io/engineering";
import isEqual from "lodash/isEqual";
import moment from "moment";

import { rollupClient } from "src/core/api";

import { getCurrencyUnit, getUnitType, UnitType } from "./Units";

export enum FormatType {
  NUMBER = "Number",
  NUMBER_WITH_COMMAS = "Number with commas",
  ENGINEERING_NOTATION = "Engineering notation",
  EXPONENTIAL_NOTATION = "Exponential notation",
  PERCENTAGE = "Percentage",
  CURRENCY = "Currency",
}

// USD -> currency, number, scientific
// unit-less -> number, scientific-notation, percentage
// physical-units (kg, m) -> number, scientific-notation

// Should this be a Map or Array instead?
export const allowedFormatTypesByUnitType: Record<UnitType, FormatType[]> = {
  [UnitType.CURRENCY]: [
    FormatType.NUMBER,
    FormatType.NUMBER_WITH_COMMAS,
    FormatType.ENGINEERING_NOTATION,
    FormatType.EXPONENTIAL_NOTATION,
    FormatType.CURRENCY,
  ],
  [UnitType.UNITLESS]: [
    FormatType.NUMBER,
    FormatType.NUMBER_WITH_COMMAS,
    FormatType.ENGINEERING_NOTATION,
    FormatType.EXPONENTIAL_NOTATION,
    FormatType.PERCENTAGE,
  ],
  [UnitType.PHYSICAL]: [FormatType.NUMBER, FormatType.NUMBER_WITH_COMMAS, FormatType.ENGINEERING_NOTATION, FormatType.EXPONENTIAL_NOTATION],
};

export function isAllowedFormatTypeByUnitType(unitType: UnitType | undefined | null, formatType: FormatType): boolean {
  // If "unitType" is unknown to us, then we will consider it unitless from formatting POV.
  return allowedFormatTypesByUnitType[unitType ?? UnitType.UNITLESS].includes(formatType);
}

export function joinAndTrim(values: (string | number | undefined)[], separator = "") {
  return values.join(separator).trim();
}

export function validateNumber(value: string | number) {
  const numberAsString = value.toString();
  return isNaN(Number(numberAsString)) ? parseFloat(numberAsString.replace(/,/g, "")) : Number(numberAsString);
}

export function formatStringFromMathResult(
  result: MathResult | undefined,
  formatType: FormatType = FormatType.NUMBER_WITH_COMMAS,
  unit?: string
) {
  if (!result) {
    return "";
  }
  try {
    const value = toNumber(result, unit);
    if (unit) {
      return formatString(value, unit, formatType);
    } else {
      const intrinsicUnit = result.formatUnits();
      return formatString(value, intrinsicUnit, formatType);
    }
  } catch (err) {
    console.warn(err);
    return "Error";
  }
}

export function formatString(value: string | number | undefined, unit: string | undefined, formatType: FormatType | undefined): string {
  if (value === undefined || value === null) {
    return "";
  }
  if (formatType === undefined) {
    return value.toString();
  }

  const unitType = getUnitType(unit);
  const isAllowed = isAllowedFormatTypeByUnitType(unitType, formatType);
  const type = isAllowed ? formatType : FormatType.NUMBER_WITH_COMMAS;

  switch (type) {
    case FormatType.NUMBER: {
      return joinAndTrim([value, unit], " ");
    }
    case FormatType.NUMBER_WITH_COMMAS: {
      return joinAndTrim([validateNumber(value).toLocaleString(), unit], " ");
    }
    case FormatType.ENGINEERING_NOTATION: {
      return joinAndTrim([mathInstance.format(value, { notation: "engineering" }), unit], " ");
    }
    case FormatType.EXPONENTIAL_NOTATION: {
      return joinAndTrim([mathInstance.format(value, { notation: "exponential" }), unit], " ");
    }
    case FormatType.PERCENTAGE: {
      return validateNumber(value).toLocaleString(undefined, { style: "percent", maximumFractionDigits: 5 });
    }
    case FormatType.CURRENCY: {
      return validateNumber(value).toLocaleString("en-US", {
        style: "currency",
        currency: getCurrencyUnit(unit ?? "")?.name,
      });
    }
    default: {
      return joinAndTrim([validateNumber(value).toLocaleString(), unit], " ");
    }
  }
}

export function formatFileSize(sizeInBytes: number): string {
  if (!isFinite(sizeInBytes)) {
    return "";
  }
  if (sizeInBytes >= 1e12) {
    return `${(sizeInBytes / 1e12).toFixed(2)} TB`;
  } else if (sizeInBytes >= 1e9) {
    return `${(sizeInBytes / 1e9).toFixed(1)} GB`;
  } else if (sizeInBytes >= 1e6) {
    return `${(sizeInBytes / 1e6).toFixed(1)} MB`;
  } else if (sizeInBytes >= 1e3) {
    return `${(sizeInBytes / 1e3).toFixed(1)} kB`;
  } else {
    return `${sizeInBytes} B`;
  }
}

export async function isHoopsFile(url: string) {
  try {
    // Fetch magic number for attachment by using Range header
    const magicNumber = await rollupClient.attachments.getMagicNumber(url, 8);
    if (magicNumber?.byteLength !== 8) {
      console.warn("File is empty or non-existent");
      return false;
    }
    // Fixed HOOPS SCS file magic number. For now, just print to info if it doesn't match
    const hoopsNumber = new Int32Array([90, 1]);
    const fileNumber = new Int32Array(magicNumber);
    if (!isEqual(fileNumber, hoopsNumber)) {
      console.warn(`HOOPS file had unexpected magic number of [${fileNumber.join(",")}]`);
    }
    return true;
  } catch (err) {
    console.warn(err);
    return false;
  }
}

export const formatDate = (date?: number | string | Date, short?: boolean) => {
  if (!date) {
    return "";
  }

  return moment(date).format(`MMMM D, YYYY${short ? "" : ", h:mm a"}`);
};
