import { CustomUnit, ICreateCustomUnitDto, IUpdateCustomUnitDto } from "@rollup-api/models/customUnits";
import { trackSegmentEvent } from "src/lib/Segment";

import { HttpClient, ParentClient } from "../client";

export class CustomUnits extends HttpClient {
  public constructor(parent: ParentClient) {
    super(parent);
  }

  private get endpoint() {
    return `custom-units`;
  }

  public create = (dto: ICreateCustomUnitDto) => {
    trackSegmentEvent("custom-unit:create", { id: dto.id });
    return this.instance.post<CustomUnit>(this.endpoint, dto);
  };

  public update = (id: string, dto: IUpdateCustomUnitDto) => {
    trackSegmentEvent("custom-unit:update", { id, dto });
    return this.instance.patch<CustomUnit>(`${this.endpoint}/${id}`, dto);
  };

  public delete = (id: string) => {
    trackSegmentEvent("custom-unit:delete", { id });
    return this.instance.delete<CustomUnit>(`${this.endpoint}/${id}`);
  };

  public reorder = (id: string, destinationId: string) => {
    trackSegmentEvent("custom-unit:reorder", { id });
    return this.instance.patch(`${this.endpoint}/${id}/reorder`, { destinationId });
  };
}
