import { ReportPageNavItem } from "@components/Reports/Editor/Extentions/DashNavigation/types";
import { RollupEditorType } from "@rollup-types/editor";
import { IReport } from "@store/ReportsStore";

export const getReportLabelsPath = (report: IReport): string[] => {
  if (!report.parentReport) {
    return [];
  }

  const path: string[] = [];

  const getParentReportLabel = (sourceReport: IReport) => {
    path.unshift(sourceReport.displayedLabel);
    const parentReport = sourceReport.parentReport as IReport;
    if (parentReport) {
      getParentReportLabel(parentReport);
    }
  };

  if (report.parentReport) {
    getParentReportLabel(report.parentReport);
  }

  return path;
};

export const isReportBlockType = (type: RollupEditorType | ReportPageNavItem): type is RollupEditorType => {
  return Object.values(RollupEditorType).includes(type as RollupEditorType);
};

export const getMockedTreeEntity = (report: IReport) => {
  const mockId = `${report.id}-mock`;

  return {
    path: [...report.path, mockId],
    report: null,
    mockId,
  };
};

export const isInputFocused = () => {
  const proseMirrorFocused = document.activeElement?.classList.contains("ProseMirror");
  const inputFocused = document.activeElement?.tagName === "INPUT";
  const textAreaFocused = document.activeElement?.tagName === "TEXTAREA";
  return proseMirrorFocused || inputFocused || textAreaFocused;
};
