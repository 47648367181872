import { DialogBody as BPDialogBody, DialogBodyProps } from "@blueprintjs/core";
import classNames from "classnames";
import { observer } from "mobx-react";

import "./DialogBody.scss";

interface IDialogBodyProps extends DialogBodyProps {}

const DialogBody = (props: IDialogBodyProps) => {
  const { className = "", ...rest } = props;
  return <BPDialogBody className={classNames("dialog-body", className)} {...rest} />;
};

export default observer(DialogBody);
