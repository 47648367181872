import { MouseEvent } from "react";
import { MaybeElement } from "@blueprintjs/core/src/common/props";
import { IconName } from "@blueprintjs/icons";
import { observer } from "mobx-react";

import FeatureRightSidebarButton from "@components/FeatureRightSidebar/FeatureRightSidebarButton";
import appStore from "@store/AppStore";

import styles from "./FeatureRightSidebar.module.scss";

export interface IFeatureSidePanel<T extends string> {
  key: T;
  icon: IconName | MaybeElement;
  label: string;
}

interface IFeatureRightSidebarProps<T extends string> {
  activeSidePanelKey: T;
  pinnedSidePanelKeys: T[];
  sidePanels: IFeatureSidePanel<T>[];
  disablePinning?: boolean;
  onChangeActiveSidePanel(panel: T): void;
  onTogglePinnedSidePanel(panel: T): void;
  onHideSidePanel(): void;
  onClose?(): void;
}

const FeatureRightSidebar = <T extends string>(props: IFeatureRightSidebarProps<T>) => {
  const { activeSidePanelKey, pinnedSidePanelKeys, sidePanels, disablePinning } = props;
  const { onChangeActiveSidePanel, onTogglePinnedSidePanel, onClose, onHideSidePanel } = props;
  const { isNavPaneOpen, isSubNavPaneOpen, toggleNavPane } = appStore.env;

  const isActive = (panel: T) => activeSidePanelKey === panel;

  const handleToggleSidePanel = (panel: T) => {
    const pinnedSidePanel = pinnedSidePanelKeys.find(key => key !== panel);
    if (activeSidePanelKey === panel && !pinnedSidePanel) {
      onHideSidePanel();
    } else if (activeSidePanelKey === panel && pinnedSidePanel) {
      onChangeActiveSidePanel(pinnedSidePanel);
    } else {
      if (activeSidePanelKey === undefined && isNavPaneOpen && isSubNavPaneOpen) {
        toggleNavPane();
      }
      onChangeActiveSidePanel(panel);
    }
    if (isActive(panel)) {
      onClose?.();
    }
  };

  const handlePinPanel = (e: MouseEvent, key: T) => {
    e.stopPropagation();
    onTogglePinnedSidePanel(key);
  };

  return (
    <div className={styles.featureRightSidebar}>
      {sidePanels.map(({ key, icon, label }) => {
        return (
          <FeatureRightSidebarButton<T>
            key={key}
            sidePanelKey={key}
            active={isActive(key)}
            pinned={pinnedSidePanelKeys.includes(key)}
            icon={icon}
            label={label}
            onClick={() => handleToggleSidePanel(key)}
            onPinPanel={e => handlePinPanel(e, key)}
            disablePinning={disablePinning}
          />
        );
      })}
    </div>
  );
};

export default observer(FeatureRightSidebar);
