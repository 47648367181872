import { observer } from "mobx-react";

import { UserTooltip } from "@components/UserTooltip";
import { IUser } from "@store/UserStore";

interface IUserTagProps {
  user: IUser;
  className?: string;
  labelClassName?: string;
}

const UserTag = (props: IUserTagProps) => {
  const { user, className, labelClassName } = props;
  return (
    <UserTooltip user={user}>
      <div className={className}>
        <span className={labelClassName}>{user.displayName}</span>
      </div>
    </UserTooltip>
  );
};

export default observer(UserTag);
