import { useState } from "react";
import { Divider } from "@blueprintjs/core";
import classNames from "classnames";
import { observer } from "mobx-react";

import Comment from "@components/Modeling/ModelingFrame/ModelBlock/Comments/Comment";
import CommentEditor from "@components/Modeling/ModelingFrame/ModelBlock/Comments/CommentEditor";
import CommentThreadRunner from "@components/Modeling/ModelingFrame/ModelBlock/Comments/CommentThreadRunner";
import ShowMoreButton from "@components/Modeling/ModelingFrame/ModelBlock/Comments/ShowMoreButton";
import SimpleCommentEditor from "@components/SimpleCommentEditor/SimpleCommentEditor";
import UserInfo from "@components/UserInfo/UserInfo";
import { TemporalDirection } from "@rollup-api/models/comments/commentGetThreadedRequestDto.model";
import appStore from "@store/AppStore";
import { CommentDisplayParentType, IComment } from "@store/CommentStore";
import { ICommentThread } from "@store/CommentThreadStore";
import { IUser } from "@store/UserStore";
import { moveToAnnotation } from "@utilities/AnnotationUtils";

import CommentHeader from "./CommentHeader";

import styles from "./CommentThread.module.scss";

export enum CommentThreadDisplayStyle {
  Sidebar = "sidebar",
  Panel = "panel",
}

interface CommentThreadProps {
  thread: ICommentThread;
  displayStyle: CommentThreadDisplayStyle;
  isInsideAnnotationContainer?: boolean;
  onChange?: () => void;
}

const CommentThread = ({ thread, displayStyle, isInsideAnnotationContainer, onChange }: CommentThreadProps) => {
  const [isReplying, setIsReplying] = useState(false);
  const [isReplyBeingEdited, setIsReplyBeingEdited] = useState(false);
  const [replyContent, setReplyContent] = useState<string>("");

  const parentComment = thread.parentComment;
  const childComments = thread.childComments ?? [];
  const threadWorkspaceId = parentComment?.workspaceId;

  const isPanelStyle = displayStyle === CommentThreadDisplayStyle.Panel;
  const isSidebarStyle = displayStyle === CommentThreadDisplayStyle.Sidebar;
  const useSimpleEditor = thread.parentComment?.displayParentType === CommentDisplayParentType.Annotation;

  const handleReply = (to: IUser, mentionTo: boolean) => {
    if (mentionTo) {
      setReplyContent(
        '<p><mention-tag-component data-id="user:' + to.id + '" data-label="' + to.name + '"></mention-tag-component>\u00A0</p>'
      );
    }

    setIsReplying(true);
  };

  const handleReplyOnThreadClick = () => {
    setIsReplying(true);
  };

  const handleThreadAreaClick = () => {
    if (parentComment?.displayParentType === CommentDisplayParentType.Annotation && !isInsideAnnotationContainer) {
      moveToAnnotation(parentComment.displayParentId);
    }
  };

  const handleConfirm = async (value: string, attachmentIds: string[], publishAttachmentsToBlock: boolean) => {
    setReplyContent("");
    setIsReplying(false);
    thread.addChildComment(value, attachmentIds, publishAttachmentsToBlock);
    onChange?.();
  };

  const handleCancelReply = () => {
    setReplyContent("");
    setIsReplying(false);
  };

  const handleShowPreviousReplies = () => {
    thread.fetchChildren();
  };

  const showReplySection = isReplying || (isPanelStyle && childComments.length > 0);

  const showRunner = (index: number) => {
    return isSidebarStyle && (index !== childComments.length - 1 || showReplySection);
  };

  const renderChildComments = (childComments: IComment[]) => {
    return (
      <>
        <div className={classNames({ [styles.childComments]: isPanelStyle })}>
          {childComments.map((childComment, index) => (
            <div className={styles.childComments} key={childComment.id}>
              <Comment
                key={childComment.id}
                comment={childComment}
                showUser
                onReply={handleReply}
                showRunner={showRunner(index)}
                shortEditor={isSidebarStyle}
                simpleEditor={useSimpleEditor}
                hideReplyButton={isInsideAnnotationContainer}
              />
              {isPanelStyle && <Divider />}
            </div>
          ))}
        </div>
      </>
    );
  };

  const renderReplySection = () => {
    return (
      <div className={styles.newReply}>
        <div className={classNames({ [styles.beingEdited]: isReplyBeingEdited })}>
          {appStore.userModel && <UserInfo user={appStore.userModel} size="small" avatarOnly />}
        </div>

        {useSimpleEditor ? (
          <SimpleCommentEditor
            onConfirm={text => handleConfirm(text, [], false)}
            onCancel={handleCancelReply}
            content=""
            editable
            resetOnConfirm
            showUnfocusedPlaceholder={isPanelStyle}
          />
        ) : (
          <CommentEditor
            workspaceId={threadWorkspaceId}
            onConfirm={handleConfirm}
            onCancel={handleCancelReply}
            onBeingEdited={setIsReplyBeingEdited}
            content={replyContent}
            resetOnConfirm
            placeholder="Leave a reply..."
            autoEditOnFocus
            isEditing={isReplying}
            short={isSidebarStyle}
          />
        )}
      </div>
    );
  };

  const renderPanelStyle = () => {
    return (
      <>
        {parentComment && (
          <CommentHeader
            userId={parentComment.createdBy}
            createdAt={parentComment.createdAt}
            updatedAt={parentComment.updatedAt}
            formattedUpdatedAt={parentComment.formattedUpdatedAt}
          />
        )}
        <div className={styles.panelStyleWrapper}>
          <CommentThreadRunner first />
          <div className={styles.comments}>
            <div className={styles.panelStyleCommentsSection} onClick={handleReplyOnThreadClick}>
              {parentComment && <Comment comment={parentComment} onReply={handleReply} showUser={false} simpleEditor={useSimpleEditor} />}
              {childComments.length > 0 && <Divider />}
              {thread.remainingChildCommentsCount > 0 && (
                <ShowMoreButton
                  remainingNextSetOfCommenters={thread.remainingChildCommentsCommenters}
                  remainingCommentsCount={thread.remainingChildCommentsCount}
                  temporalDirection={TemporalDirection.Older}
                  showChildren
                  onShowMore={handleShowPreviousReplies}
                />
              )}
              {childComments.length > 0 && renderChildComments(childComments)}
            </div>
            {showReplySection && renderReplySection()}
          </div>
        </div>
      </>
    );
  };

  const renderSideBarStyle = () => {
    return (
      <div className={styles.sidebarStyleWrapper}>
        <div className={classNames({ [styles.clickArea]: !isInsideAnnotationContainer })} onClick={handleThreadAreaClick}>
          {parentComment && (
            <Comment
              comment={parentComment}
              onReply={handleReply}
              showUser
              showRunner
              shortEditor
              simpleEditor={useSimpleEditor}
              hideReplyButton={isInsideAnnotationContainer}
            />
          )}
          {thread.remainingChildCommentsCount > 0 && (
            <ShowMoreButton
              remainingNextSetOfCommenters={thread.remainingChildCommentsCommenters}
              remainingCommentsCount={thread.remainingChildCommentsCount}
              temporalDirection={TemporalDirection.Older}
              showChildren
              onShowMore={handleShowPreviousReplies}
            />
          )}
          {renderChildComments(childComments)}
        </div>
        {showReplySection && renderReplySection()}
      </div>
    );
  };

  return (
    <div
      className={classNames(styles.commentThread, {
        [styles.rightPanelWrapper]: isSidebarStyle,
        [styles.hoverWrapper]: !isInsideAnnotationContainer && isSidebarStyle,
        [styles.replyingBackground]: isSidebarStyle && showReplySection,
      })}
      onMouseEnter={() => appStore.ui.setHoveredCommentAnnotationId(thread.parentComment?.displayParentId)}
      onMouseLeave={() => appStore.ui.setHoveredCommentAnnotationId(undefined)}
    >
      {isPanelStyle ? renderPanelStyle() : renderSideBarStyle()}
    </div>
  );
};

export default observer(CommentThread);
