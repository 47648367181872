import { MouseEvent, ReactNode } from "react";
// eslint-disable-next-line no-restricted-imports
import { MenuItem as BPMenuItem, MenuItemProps, PopoverPosition, TooltipProps } from "@blueprintjs/core";
import classNames from "classnames";
import { observer } from "mobx-react";

import { Tooltip } from "@components/Tooltip";
import { createDataTestId, ElementType, IE2EIdentifiersParam } from "@utilities/E2EUtils";

import styles from "./MenuItem.module.scss";

interface IMenuItemProps extends MenuItemProps, IE2EIdentifiersParam {
  leftElement?: ReactNode;
  rightElement?: ReactNode;
  tooltip?: string;
  tooltipProps?: Omit<TooltipProps, "content">;
  description?: string;
  onClick?(e: MouseEvent<HTMLElement>): void;
}

const MenuItem = (props: IMenuItemProps) => {
  const {
    className,
    leftElement,
    rightElement,
    e2eIdentifiers,
    popoverProps,
    tooltip = "",
    tooltipProps,
    active,
    onClick,
    ...restProps
  } = props;

  const renderMenuItemWithDesc = (text: ReactNode, description: ReactNode) => {
    return (
      <div>
        <div>{text}</div>
        <div className={styles.description}>{description}</div>
      </div>
    );
  };

  return (
    <Tooltip
      className={classNames(styles.menuItemContainer, className, {
        [styles.menuItemContainerWithRightElement]: !!rightElement,
        [styles.active]: active,
      })}
      content={tooltip}
      position={PopoverPosition.TOP}
      disabled={!tooltip}
      {...tooltipProps}
    >
      <div className={styles.menuItemContainerContent} onClick={onClick}>
        {leftElement}
        <BPMenuItem
          {...createDataTestId(ElementType.MenuItem, e2eIdentifiers)}
          {...restProps}
          text={props.description ? renderMenuItemWithDesc(props.text, props.description) : props.text}
          className={styles.menuItem}
          popoverProps={{
            // Improve the UX of submenus by setting a consistent hover open/close delay
            hoverCloseDelay: 200,
            hoverOpenDelay: 200,
            // Disabling "openOnTargetFocus" for all menu items to avoid the bug that happens
            // when clicking on a menu item that has children elements. For reference:
            // https://linear.app/rollup/issue/ENG-1678/search-bar-disappears-with-click-interaction
            openOnTargetFocus: false,
            ...popoverProps,
          }}
        />
        {rightElement}
      </div>
    </Tooltip>
  );
};

export default observer(MenuItem);
