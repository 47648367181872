import { ReactNode } from "react";
import classNames from "classnames";
import { observer } from "mobx-react";

import MenuPane from "@router/components/Panes/MenuPane";
import SplitPaneGroup, { ISplitPaneGroupProps } from "@router/components/Panes/SplitPaneGroup";
import { INavLinkAsMenuItem } from "@router/navigation/NavLinkAsMenuItem";
import appStore from "@store/AppStore";

import "./FeatureLayout.scss";

interface ISidePanelOnHoverProps {
  children: ISplitPaneGroupProps["children"];
  minSize: number;
  maxSize: number;
  isSidePanelOpen: boolean;
  paneWidth: number;
  menuHovered?: boolean;
  dynamicPanelContent?: ReactNode;
  hoverMenuMenuItems?: INavLinkAsMenuItem[];
  title?: string;
  topElement?: ReactNode;
  className?: string;
  menuClassName?: string;
  onPaneWidthChange(value: number): void;
  onHoverMenu?(): void;
  onUnhoverMenu?(): void;
}

const FeatureLayout = (props: ISidePanelOnHoverProps) => {
  const { children, dynamicPanelContent, menuClassName, isSidePanelOpen } = props;
  const { hoverMenuMenuItems, topElement, minSize, maxSize, paneWidth } = props;
  const { menuHovered, onPaneWidthChange, onHoverMenu, onUnhoverMenu } = props;

  const handleDragFinished = (newSize: number) => {
    onPaneWidthChange(newSize);
  };

  return (
    <div className="feature-layout--container">
      {!isSidePanelOpen && (
        <MenuPane
          open
          minimal
          menuItems={hoverMenuMenuItems}
          topElement={topElement}
          onMouseEnter={onHoverMenu}
          onMouseLeave={onUnhoverMenu}
          className={classNames("feature-layout", menuClassName, { visible: !isSidePanelOpen && menuHovered })}
          style={{ width: menuHovered ? appStore.env.paneWidth.subNav : minSize }}
          hideScroll
        >
          <div className="menu-content-main">{dynamicPanelContent}</div>
        </MenuPane>
      )}
      <SplitPaneGroup
        onDragFinished={handleDragFinished}
        size={isSidePanelOpen ? paneWidth : undefined}
        className="feature-layout--split-pane"
        minSize={minSize}
        maxSize={maxSize}
        allowResize={isSidePanelOpen}
      >
        {children}
      </SplitPaneGroup>
    </div>
  );
};

export default observer(FeatureLayout);
