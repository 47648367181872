import { ButtonProps, Tooltip } from "@blueprintjs/core";
import { Editor } from "@tiptap/core";

import { Button } from "@components/Button";

import { EditorProperty, editorPropertyDataMap } from "./CommentEditorPropertyButton.utils";

export type EditorPropertyButtonProps = {
  editor: Editor;
  name: EditorProperty;
} & ButtonProps;

export const CommentEditorPropertyButton = ({ name, editor, ...btnProps }: EditorPropertyButtonProps) => {
  const handleClick = () => {
    switch (name) {
      case EditorProperty.BulletList:
        return editor.chain().focus().toggleBulletList().run();
      case EditorProperty.Citation:
        return editor.chain().focus().toggleBlockquote().run();
      case EditorProperty.CodeBlock:
        return editor.chain().focus().toggleCodeBlock().run();
      case EditorProperty.Image:
        return;
      case EditorProperty.OrderedList:
        return editor.chain().focus().toggleOrderedList().run();
      default:
        return editor.chain().focus().toggleMark(name).run();
    }
  };

  const editorPropertyData = editorPropertyDataMap[name];

  return (
    <Tooltip content={editorPropertyData.tooltip} position="top" hoverOpenDelay={400}>
      <Button
        icon={editorPropertyData.icon}
        active={editor.isActive(name)}
        onClick={handleClick}
        e2eIdentifiers={["comment-editor-action", name]}
        {...btnProps}
        minimal
      />
    </Tooltip>
  );
};
