import { ChangeEvent, KeyboardEvent, memo } from "react";
import { CustomCellEditorProps } from "ag-grid-react";

import { ENTER_KEY, ESCAPE_KEY } from "@constants/keys";

import styles from "./PositiveIntegerCellEditor.module.scss";

const preventSymbols = ["e", ".", ",", "-"];

const PositiveIntegerCellEditor = memo((cellEditor: CustomCellEditorProps) => {
  const { value = "", api, onValueChange } = cellEditor;

  const handleCommit = () => {
    api.stopEditing();
  };

  const handleCancel = () => {
    api.stopEditing(true);
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    onValueChange(e.target.value);
  };

  const handleKeyUp = (e: KeyboardEvent<HTMLInputElement>) => {
    if (preventSymbols.includes(e.key)) {
      e.preventDefault();
      return;
    }

    if (e.key === ENTER_KEY) {
      handleCommit();
    } else if (e.key === ESCAPE_KEY) {
      handleCancel();
    }
  };

  return (
    <input
      onKeyDown={handleKeyUp}
      onBlur={handleCommit}
      autoFocus
      onFocus={e => e.target.select()}
      className={styles.positiveIntegerCellEditor}
      pattern="^[0-9]*$"
      type="number"
      min={0}
      value={value}
      onChange={handleChange}
    />
  );
});

PositiveIntegerCellEditor.displayName = "PositiveIntegerCellEditor";

export default PositiveIntegerCellEditor;
