import { useState } from "react";
import { Handle, NodeProps, Position, XYPosition } from "reactflow";
import { Tooltip } from "@blueprintjs/core";
import classNames from "classnames";
import { observer } from "mobx-react";

import { ScalarExpressionEditor } from "@components/Shared";
import { IPropertyInstance } from "@store/PropertyInstanceStore";

import { CustomNodeType } from "../types";

import "./PropertyNode.scss";

/** Type defs. */
type PropertyNodeData = {
  type: CustomNodeType;
  id: string;
  propertyInstance: IPropertyInstance;
  active: boolean;
  onEditClicked?: (id: string, position: XYPosition) => void;
};
type PropertyNodeProps = NodeProps<PropertyNodeData>;

/** Main function. */
function PropertyNode(props: PropertyNodeProps) {
  const [editing, setEditing] = useState(false);
  const {
    targetPosition,
    sourcePosition,
    xPos,
    yPos,
    data: { propertyInstance, type, active, onEditClicked },
  } = props;

  const handleEditClicked = () => {
    setEditing(true);
    onEditClicked?.(props.data.id, { x: xPos, y: yPos });
  };

  const tooltipContent = editing ? propertyInstance.path : propertyInstance.parentBlock.path;

  return (
    <div className={classNames("property-node", { active: active || editing, editing })}>
      {type !== CustomNodeType.INPUT && <Handle type="target" position={targetPosition || Position.Left} className="invisible" />}
      <div className="content" onClick={handleEditClicked}>
        <Tooltip disabled={!tooltipContent} content={tooltipContent} placement="top">
          <div className="subtitle">{propertyInstance.parentBlock.label + (editing ? `:${propertyInstance.label} =` : "")}</div>
        </Tooltip>
        {editing ? (
          <div className="content--editor">
            <ScalarExpressionEditor
              propertyInstance={propertyInstance}
              onBlur={() => setEditing(false)}
              autoFocus
              showRollupInfo={false}
              enableWrappingOnFocus
            />
          </div>
        ) : (
          <div className="content--title">
            <span className="content--title--label">{propertyInstance.label}</span> <span>&nbsp;=&nbsp;</span>
            <span className="content--title--value">{propertyInstance.numericText}</span>
          </div>
        )}
      </div>
      {type !== CustomNodeType.OUTPUT && <Handle type="source" position={sourcePosition || Position.Right} className="invisible" />}
    </div>
  );
}

/** Exports. */
export type { PropertyNodeData, PropertyNodeProps };
export default observer(PropertyNode);
