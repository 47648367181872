import { ImportStatus } from "@rollup-api/models";
import appStore from "@store/AppStore";

const useImportsCheck = () => {
  const checkForUnsavedChanges = (event: BeforeUnloadEvent) => {
    const unfinishedImports = appStore.workspaceModel?.imports.filter(i => i.status === ImportStatus.PendingApproval) || [];

    if (unfinishedImports.length) {
      event.preventDefault();
      return false;
    }
  };

  return {
    checkForUnsavedChanges,
  };
};

export default useImportsCheck;
