import {} from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

/** Type defs. */
interface WithRouterProps {
  location: ReturnType<typeof useLocation>;
  params: Record<string, string>;
  navigate: ReturnType<typeof useNavigate>;
}

/** Main function. */
const withRouter = <Props extends WithRouterProps>(Component: React.ComponentType<React.PropsWithChildren<Props>>) => {
  // eslint-disable-next-line react/display-name
  return (props: Omit<Props, keyof WithRouterProps>) => {
    const location = useLocation();
    const params = useParams();
    const navigate = useNavigate();

    return <Component {...(props as Props)} location={location} params={params} navigate={navigate} />;
  };
};

/** Exports. */
export type { WithRouterProps };
export { withRouter };
