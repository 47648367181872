import { IconName } from "@blueprintjs/core";
import capitalize from "lodash/capitalize";

import { RollupEditorType } from "@rollup-types/editor";

export const reportBlockHeadingTypes = [
  RollupEditorType.h1,
  RollupEditorType.h2,
  RollupEditorType.h3,
  RollupEditorType.h4,
  RollupEditorType.h5,
  RollupEditorType.h6,
];

export const blockTypeIcons: Record<RollupEditorType, IconName> = {
  [RollupEditorType.ul]: "properties",
  [RollupEditorType.p]: "paragraph",
  [RollupEditorType.ol]: "numbered-list",
  [RollupEditorType.checklist]: "form",
  [RollupEditorType.quote]: "citation",
  [RollupEditorType.h1]: "header-one",
  [RollupEditorType.h2]: "header-two",
  [RollupEditorType.h3]: "header-three",
  [RollupEditorType.h4]: "header",
  [RollupEditorType.h5]: "header",
  [RollupEditorType.h6]: "header",
  [RollupEditorType.image]: "media",
  [RollupEditorType.code]: "code",
  [RollupEditorType.hr]: "minus",
  [RollupEditorType.table]: "th",
};

export const getTypeLabel = (type: RollupEditorType) => {
  switch (type) {
    case RollupEditorType.p:
      return "Paragraph";
    case RollupEditorType.ol:
      return "Numbered list";
    case RollupEditorType.ul:
      return "Bullet list";
    case RollupEditorType.h1:
      return "Header 1";
    case RollupEditorType.h2:
      return "Header 2";
    case RollupEditorType.h3:
      return "Header 3";
    case RollupEditorType.h4:
      return "Header 4";
    case RollupEditorType.h5:
      return "Header 5";
    case RollupEditorType.h6:
      return "Header 6";
    default:
      return capitalize(type);
  }
};

const allowedReportBlockTypes = [
  RollupEditorType.p,
  RollupEditorType.ul,
  RollupEditorType.ol,
  RollupEditorType.checklist,
  RollupEditorType.quote,
  RollupEditorType.h1,
  RollupEditorType.h2,
  RollupEditorType.h3,
  RollupEditorType.h4,
  RollupEditorType.h5,
  RollupEditorType.h6,
];

export const getAllowedTypes = (type: string): RollupEditorType[] => {
  switch (type) {
    case RollupEditorType.code:
    case RollupEditorType.image:
    case RollupEditorType.table:
    case RollupEditorType.hr:
      return [];
    default:
      return allowedReportBlockTypes;
  }
};
