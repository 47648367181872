import { ButtonGroup } from "@blueprintjs/core";

import { AnchorButton } from "@components/AnchorButton";
import InterfaceComponent from "@components/Modeling/ModelingFrame/ModelBlock/Interfaces/InterfaceComponent";
import { GenericSortableList } from "@components/Shared";
import { IBlock } from "@store/BlockStore";

/** Type defs. */
type InterfacesTabPanelProps = {
  block: IBlock;
};

/** Main function. */
function InterfacesTabPanel({ block }: InterfacesTabPanelProps) {
  const handleNewInterface = () => block.addNewInterface();

  return (
    <div className="component--interfaces-tab-panel">
      <GenericSortableList
        items={block.validatedInterfaces}
        onDragEnd={block.moveInterface}
        renderItem={(item, dragging, dragListeners) => (
          <InterfaceComponent dragListeners={dragListeners} isDragging={dragging} interface={item} block={block} />
        )}
      />
      <ButtonGroup>
        <AnchorButton
          minimal
          icon="small-plus"
          alignText="left"
          onClick={handleNewInterface}
          text="Interface"
          e2eIdentifiers="add-new-interface"
        />
      </ButtonGroup>
    </div>
  );
}

/** Exports. */
export type { InterfacesTabPanelProps };
export default InterfacesTabPanel;
