import { cast, flow, Instance, types } from "mobx-state-tree";

import { showApiErrorToast, showToast } from "@components/UiLayers/toaster";
import { UserRole } from "@rollup-api/api/authTypes";
import { Profile } from "@rollup-api/models/profiles";
import { rollupClient } from "src/core/api";

import appStore from "./AppStore";
import { PrescribedFeatureFlag } from "./FeatureFlagsStore";
import { IUser, IUserSnapshotIn, UserStore } from "./UserStore";

export const OrgInfoStore = types
  .model("OrgInfoStore", {
    id: types.optional(types.identifier, ""),
    subscriptionId: types.optional(types.string, ""), // ID used in Stigg to store the organization's subscription
    slug: types.optional(types.string, ""),
    name: types.optional(types.string, ""),
    description: types.optional(types.string, ""),
    featureFlags: types.array(types.frozen<PrescribedFeatureFlag>()),
    logoUrl: types.maybeNull(types.optional(types.string, "")),
    allowedDomains: types.optional(types.frozen<string[]>(), []),
    orgMembers: types.array(UserStore),
    invitedUsers: types.array(UserStore),
  })
  .views(self => ({
    userWithId(id: string): IUser | undefined {
      const members = self.orgMembers;
      return members.find(u => u.id === id);
    },
  }))
  .actions(self => {
    return {
      setName(name: string) {
        self.name = name;
      },
      setDescription(description: string) {
        self.description = description;
      },
      setAllowedDomains(allowedDomains: string[]) {
        self.allowedDomains = allowedDomains;
      },
      setLogoUrl(url: string) {
        self.logoUrl = url;
      },
      setOrgUsers(orgUsers: IUserSnapshotIn[]) {
        self.orgMembers = cast(orgUsers);
      },
      setInvitedUsers(invitedUsers: IUserSnapshotIn[]) {
        self.invitedUsers = cast(invitedUsers);
      },
      removeOrgUserById: flow(function* removeOrgUser(orgUserId: string): Generator<any, boolean, any> {
        const message = "Error removing profile";

        const userToRemove: IUser = self.userWithId(orgUserId) as IUser;
        if (userToRemove) {
          try {
            const res = yield rollupClient.organizations.removeMember(orgUserId);
            if (res.status === 200) {
              {
                self.orgMembers.remove(userToRemove);
                return true;
              }
            } else {
              showApiErrorToast(message, new Error());
            }
          } catch (err) {
            showApiErrorToast(message, err as Error);
            console.warn(err);
          }
        }
        return false;
      }),
      getUserProfile: flow(function* getUserProfile(id: string): Generator<any, Profile | null, any> {
        // check if the user profile is already in the store
        const existingProfile = self.orgMembers.find(u => u.id === id);
        if (existingProfile) {
          return existingProfile as unknown as Profile;
        }
        try {
          const { data: profile, status } = yield rollupClient.organizations.retrieveProfileById(id);
          if (status !== 200 || !profile) {
            console.debug("Could not find user profile in getUserProfile");
            showToast("Could not find user profile", "danger", "info-sign");
            return null;
          }
          return profile;
        } catch (error) {
          console.debug("Could not find user profile in getUserProfile", error);
          return null;
        }
      }),
      changeUserRoleById: flow(function* changeUserRole(orgUserId: string, role: UserRole): Generator<any, boolean, any> {
        const message = "Error updating profile";

        const userToChange: IUser = self.userWithId(orgUserId) as IUser;
        if (userToChange) {
          try {
            const res = yield rollupClient.organizations.setMemberRole(orgUserId, role);
            if (res.status === 200) {
              userToChange.setRole(role);
              return true;
            } else {
              showApiErrorToast(message, new Error());
              return false;
            }
          } catch (err) {
            showApiErrorToast(message, err as Error);
            console.warn(err);
          }
        }
        return false;
      }),
    };
  })
  .actions(self => ({
    afterCreate() {
      if (self.featureFlags?.length) {
        appStore.env.featureFlags.enforceFlags(self.featureFlags);
      }
    },
    uploadLogo: flow(function* uploadLogo(files: FileList): Generator<any, void, any> {
      try {
        const res = yield rollupClient.organizations.uploadLogo(files[0]);
        if (res.data?.logoUrl) {
          showToast("Logo uploaded", "success", "info-sign");
          const suffix = res.data.ETag ? `?v=${res.data.ETag.replaceAll('"', "")}` : "";
          self.setLogoUrl(res.data.logoUrl + suffix);
        } else {
          showToast("Error uploading logo", "warning", "info-sign");
        }
      } catch (error: any) {
        // Show an error toast
        showToast("File upload error: " + error.message, "warning", "info-sign");
        console.debug(JSON.stringify(error));
      }
    }),
  }))
  .views(self => ({
    get memberCount() {
      return self.orgMembers.length;
    },
    get userInfoSummaries() {
      return self.orgMembers.map(member => ({
        name: member.name ?? "",
        avatarUrl: member.avatarUrl ?? "",
        id: member.id ?? "",
      }));
    },
  }))
  .views(self => ({
    userInfoSummariesOfIds(userIds: string[]) {
      return self.userInfoSummaries.filter(u => userIds.includes(u.id));
    },
  }));

export interface IOrgInfoStore extends Instance<typeof OrgInfoStore> {}
