import { MenuDivider } from "@blueprintjs/core";
import { observer } from "mobx-react";

import { IntegrationLinkData, IntegrationProvider } from "@rollup-api/models/integrations";
import appStore from "@store/AppStore";

import { GitHubMenuSection } from "./GitHubMenuSection";
import { GoogleWorkspaceMenuSection } from "./GoogleWorkspaceMenuSection";

export const IntegrationsMenuSection = observer(
  ({ onAttachIntegration }: { onAttachIntegration?: (data: IntegrationLinkData) => void }) => {
    const hasIntegrations = appStore.orgModel.integrations?.size;

    if (!hasIntegrations) {
      return null;
    }

    return (
      <>
        <MenuDivider title="Integrations" />
        {appStore.orgModel.hasProvider(IntegrationProvider.GitHub) && <GitHubMenuSection onAttachLink={onAttachIntegration} />}
        {appStore.orgModel.hasProvider(IntegrationProvider.Google) && <GoogleWorkspaceMenuSection onAttachLink={onAttachIntegration} />}
      </>
    );
  }
);
