import { flow, getParent, getParentOfType, getType, IAnyModelType, Instance, IType, SnapshotIn, SnapshotOut, types } from "mobx-state-tree";
import { v4 as uuidV4 } from "uuid";

import { CreateAnnotationDto, CreateCommentDto } from "@rollup-api/models";
import { deleteAnnotation } from "@rollup-api/utils";
import { AnnotationListStore } from "@store/AnnotationListStore";
import { CommentDisplayParentType } from "@store/CommentStore";
import { CommentThreadStore, ICommentThread, ICommentThreadMobxType } from "@store/CommentThreadStore";
import { LocationData3DStore } from "@store/LocationData3DStore";
import { StoreType } from "@store/types";
import { rollupClient } from "src/core/api";

import appStore from "./AppStore";

export const AnnotationStore = types
  .model("Annotation", {
    id: types.optional(types.identifier, () => uuidV4()),
    parentType: types.enumeration(Object.values(StoreType)),
    parentId: types.string,
    createdBy: types.string,
    updatedBy: types.maybeNull(types.string),
    createdAt: types.optional(types.number, Date.now()),
    updatedAt: types.optional(types.number, Date.now()),
    workspaceId: types.maybeNull(types.string),
    posted: types.optional(types.boolean, true),
    commentThread: types.safeReference<ICommentThreadMobxType>(types.late((): IAnyModelType => CommentThreadStore)),
    fetchingComments: types.optional(types.boolean, false),
    firstFetch: types.optional(types.boolean, true),
    topCommentingUsers: types.optional(types.array(types.string), []),
    isExpanded: types.optional(types.boolean, false),

    locationData3D: types.maybe(LocationData3DStore),
    // locationDataECAD ...
    // locationDataPDF ...
    // locationDataReport ...
  })
  .volatile(() => ({
    lastFocusedCommentId: undefined as string | undefined,
  }))
  .actions(self => ({
    setIsBehindView(isBehindView: boolean) {
      if (self.locationData3D) {
        self.locationData3D.isBehindView = isBehindView;
      }
    },
    setViewId(viewId: string) {
      if (self.locationData3D) {
        self.locationData3D.viewId = viewId;
      }
    },
    updateScreenPosition(x: number, y: number) {
      if (self.locationData3D) {
        self.locationData3D.pin2dPositionX = x;
        self.locationData3D.pin2dPositionY = y;
      }
    },
    setIsHidden(hidden: boolean) {
      if (self.locationData3D) {
        self.locationData3D.isHidden = hidden;
      }
    },
    setIsExpanded(expanded: boolean) {
      if (expanded) {
        const parent = getParentOfType(self, AnnotationListStore);
        parent.setAllAnnotationsExpanded(false);
      }
      self.isExpanded = expanded;
    },
    setLastFocusedCommentId(id: string) {
      self.lastFocusedCommentId = id;
    },
  }))
  .actions(self => ({
    delete() {
      if (self.posted) {
        deleteAnnotation(self.id);
      }
    },
  }))
  .actions(self => ({
    post: flow(function* post(): Generator<any, boolean, any> {
      try {
        const createAnnotationDto: CreateAnnotationDto = {
          id: self.id,
          parentType: self.parentType,
          parentId: self.parentId,
          locationData3D: self.locationData3D,
          workspaceId: self.workspaceId ?? undefined,
        };

        if (createAnnotationDto.locationData3D?.view?.cloudFileId) {
          createAnnotationDto.locationData3D.view.cloudFileId = self.id;
        }

        self.posted = true;
        const { status } = yield rollupClient.annotations.create(createAnnotationDto);
        if (status !== 201) {
          console.warn(`Error posting annotation ${self.id}`);
          return false;
          self.posted = false;
        }
        return true;
      } catch (error) {
        console.warn(`Error posting annotation ${self.id}`, error);
        self.posted = false;
        return false;
      }
    }),
  }))
  .actions(self => ({
    addNewComment: flow(function* addComment(text: string): Generator<any, string, any> {
      // If self already has a commentThread and a parent comment, add a child
      if (self.commentThread?.parentComment?.id) {
        return yield self.commentThread.addChildComment(text, [], false);
      }

      // Otherwise, create a new commentThread and a parent comment
      const parentEntity = getParent(self, 3) as any; // first parent is the annotations array in the AnnotationListStore, second parent is the AnnotationListStore, third parent is the parent model itself, e.g. BlockStore, AttachmentStore, ReportStore etc.
      const parentAnnotationList = getParentOfType(self, AnnotationListStore);

      const dto: CreateCommentDto = {
        ...(self.workspaceId && { workspaceId: self.workspaceId }),
        parentType: getType(parentEntity).name as StoreType,
        parentId: parentEntity.id,
        displayParentType: CommentDisplayParentType.Annotation,
        displayParentId: self.id,
        parentCommentId: self.commentThread?.parentComment?.id,
        text,
      };

      const newCommentId = yield parentAnnotationList.commentThreadList.addThreadAndParentComment(dto);

      // Set the commentThread if it's not already set
      if (!self.commentThread) {
        const thread = parentAnnotationList.commentThreadList.commentThreadOfTheCommentWithId(newCommentId);
        if (thread) {
          self.commentThread = thread;
        }
      }

      // Post the annotation if it's not already posted
      if (!self.posted) {
        self.post();
      }

      return newCommentId;
    }),
    setCommentThread(thread: ICommentThread) {
      self.commentThread = thread;
    },
    setThumbnail(thumbnail: string) {
      if (self.locationData3D) {
        self.locationData3D.thumbnail = thumbnail;
      }
      // TODO: consider the case when the user was quick on posting the first comment. If this is the case, we should make an explicit call to update the annotation with the thumbnail.
    },
  }))
  .views(self => ({
    get user() {
      return appStore.orgModel?.info?.orgMembers.find(user => user.id === self.createdBy);
    },
    get formattedCreatedAt() {
      return new Date(self.createdAt).toLocaleDateString("en-us", {
        year: "numeric",
        month: "short",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
      });
    },
    get formattedUpdatedAt() {
      return new Date(self.updatedAt).toLocaleDateString("en-us", {
        year: "numeric",
        month: "short",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
      });
    },
    get commentCount(): number {
      return self.commentThread?.commentCount ?? 0;
    },
    get hasComment(): boolean {
      return this.commentCount > 0;
    },
  }));

export interface IAnnotation extends Instance<typeof AnnotationStore> {}
export interface IAnnotationSnapshotIn extends SnapshotIn<typeof AnnotationStore> {}
interface IAnnotationSnapshotOut extends SnapshotOut<typeof AnnotationStore> {}
export interface IAnnotationMobxType extends IType<IAnnotationSnapshotIn, IAnnotationSnapshotOut, IAnnotation> {}
