import axios, { AxiosProgressEvent, AxiosResponse } from "axios";
import debounce from "lodash/debounce";

import {
  Attachment,
  AttachmentType,
  AttachmentVersionInfo,
  CreateAttachmentDto,
  OpenGraphResponseModel,
  UpdateAttachmentDto,
  UploadFileLinkDto,
} from "@rollup-api/models/cloudStorage";
import { IntegrationLinkData } from "@rollup-api/models/integrations";
import { ReferenceLinkData } from "@rollup-api/models/references/references.model";
import { getMimeType, searchZipFile, zippedBoardExtensions, zippedCadExtensions } from "@utilities";
import { getFileChunksAmount } from "@utilities/FileUpload";
import { rollupClient } from "src/core/api";

import { HttpClient, ParentClient } from "./client";

export type TUploadLinkResult = {
  blockId: string;
  fileSize: number;
  fileType: string;
  id: string;
  label: string;
  name: string;
  uploadId: string;
  urlList: Array<string>;
};

export type TUploadLinkResponse = {
  uploadId: string;
  id: string;
  type?: string;
  urlList: string[];
  numParts: number;
};

export type TUploadTag = {
  ETag: string;
  PartNumber: number;
};

export type TUploadTags = Array<TUploadTag>;

export class CloudStorage extends HttpClient {
  public constructor(parent: ParentClient) {
    super(parent);
  }

  private get endpoint() {
    return "cloud-storage";
  }

  public uploadFileChunk = async (
    fileChunk: Blob,
    url: string,
    onUpload: (tag: string) => void,
    type?: string,
    onError?: () => void,
    abortSignal?: AbortSignal,
    onProgress?: (ev: AxiosProgressEvent) => void
  ) => {
    try {
      const r = await axios.put(url, fileChunk, {
        headers: { "Content-Type": type ?? "application/octet-stream" },
        signal: abortSignal,
        // Limit to 4 updates per chunk per second
        onUploadProgress: onProgress ? debounce(onProgress, 250, { leading: true, trailing: true }) : undefined,
      });
      return onUpload(r.headers.etag?.replace(/"/g, ""));
    } catch {
      onError && onError();
    }
  };

  public completeFileUploadByLinkId = (
    id: string,
    data?: { uploadId: string; tags: TUploadTags; success?: boolean },
    abortSignal?: AbortSignal,
    workspaceId?: string | null,
    skipCadConversion?: boolean
  ) => {
    return this.instance.post<Attachment>(
      `${this.endpoint}/upload-complete/${id}`,
      {
        success: true,
        skipCadConversion,
        ...(workspaceId && { workspaceId }),
        ...data,
      },
      { signal: abortSignal }
    );
  };

  public chunkFileUrlUpload = async (
    urlList: string[],
    file: File,
    numParts: number,
    id: string,
    uploadId: string,
    workspaceId?: string | null,
    skipCadConversion?: boolean
  ) => {
    if (!urlList.length) {
      return;
    }

    const type = getMimeType(file);
    const tags: Array<TUploadTag> = [];
    const uploadedPromises: Promise<void>[] = [];
    const bytesPerChunk = Math.ceil(file.size / numParts);

    urlList.forEach((url, index) => {
      const partNumber = index + 1;
      const fileChunk: Blob = file.slice(index * bytesPerChunk, Math.min(file.size, partNumber * bytesPerChunk));
      const uploadUrlPromise = this.uploadFileChunk(
        fileChunk,
        url,
        tag =>
          tags.push({
            ETag: tag,
            PartNumber: partNumber,
          }),
        type
      );
      uploadedPromises.push(uploadUrlPromise);
    });

    await Promise.all(uploadedPromises);
    return this.completeFileUploadByLinkId(id, { uploadId, tags }, undefined, workspaceId, skipCadConversion);
  };

  public singleFileUrlUpload = async (
    url: string,
    file: File,
    id: string,
    type?: string,
    workspaceId?: string | null,
    abortSignal?: AbortSignal,
    onProgress?: (ev: AxiosProgressEvent) => void,
    skipCadConversion?: boolean
  ) => {
    if (!url) {
      return;
    }

    await axios.put(url, file, {
      headers: { "Content-Type": type ?? "application/octet-stream" },
      signal: abortSignal,
      // With single-chunk uploads we can increase progress updates to 10 per second
      onUploadProgress: onProgress ? debounce(onProgress, 100, { leading: true, trailing: true }) : undefined,
    });
    return this.completeFileUploadByLinkId(id, undefined, abortSignal, workspaceId, skipCadConversion);
  };

  public getFileUploadLinks = async (
    { blockId, label, attachmentId, workspaceId }: CreateAttachmentDto,
    file: File
  ): Promise<TUploadLinkResponse> => {
    let mimeType: string | undefined;
    const matchingExtension = await searchZipFile(file, [...zippedCadExtensions, ...zippedBoardExtensions]);

    if (matchingExtension && zippedCadExtensions.includes(matchingExtension)) {
      mimeType = "model/zip";
    } else if (matchingExtension && zippedBoardExtensions.includes(matchingExtension)) {
      mimeType = "board/zip";
    } else {
      mimeType = getMimeType(file);
    }
    const numParts = getFileChunksAmount(file.size);

    const body = {
      blockId,
      attachmentId,
      workspaceId,
      label: label || file.name,
      fileSize: file.size,
      type: "file",
      name: file.name,
      numParts,
      mimeType,
    };

    const uploadLinkResult = await this.instance.post<TUploadLinkResult>(`${this.endpoint}/upload-link`, body);
    const { uploadId, id, urlList } = uploadLinkResult.data;

    return {
      uploadId,
      id,
      type: mimeType,
      urlList,
      numParts,
    };
  };

  public uploadFile = async (
    { blockId, label, attachmentId, workspaceId }: CreateAttachmentDto,
    file: File,
    skipCadConversion?: boolean
  ): Promise<AxiosResponse<Attachment> | undefined> => {
    const { numParts, id, uploadId, urlList, type } = await this.getFileUploadLinks(
      {
        blockId,
        label,
        attachmentId,
        workspaceId,
      },
      file
    );

    if (numParts === 1) {
      return this.singleFileUrlUpload(urlList[0], file, id, type, workspaceId, undefined, undefined, skipCadConversion);
    } else {
      return this.chunkFileUrlUpload(urlList, file, numParts, id, uploadId, workspaceId, skipCadConversion);
    }
  };

  public uploadLink = async (blockId: string, linkName: string, linkUrl: string, workspaceId?: string) => {
    const dto: UploadFileLinkDto = {
      blockId,
      name: linkName,
      label: linkName,
      url: linkUrl,
      type: AttachmentType.url,
      workspaceId,
    };
    return this.instance.post<{ url: string }>(`${this.endpoint}/upload-link`, dto);
  };

  public uploadReferenceLink = async (blockId: string, data: ReferenceLinkData) => {
    const { entityId, entityType, label, workspaceId } = data;
    const dto: UploadFileLinkDto = {
      blockId,
      workspaceId,
      name: data.label,
      type: AttachmentType.reference,
      reference: `${entityType}:${entityId}`,
      label,
    };
    return this.instance.post<{ url: string }>(`${this.endpoint}/upload-link`, dto);
  };

  public uploadIntegrationLink = async (blockId: string, data: IntegrationLinkData) => {
    const { name, url, mimeType, metadata, workspaceId } = data;
    const dto: UploadFileLinkDto = {
      blockId,
      workspaceId,
      name,
      label: name,
      url,
      mimeType,
      type: AttachmentType.integration,
      metadata,
    };
    return this.instance.post<{ url: string }>(`${this.endpoint}/upload-link`, dto);
  };

  public generateDownloadLink = (id: string, converted = false, workspaceId?: string | null) => {
    return this.instance.get<{ url: string; message?: string }>(`${this.endpoint}/download/${id}`, {
      params: { workspaceId, converted, token: rollupClient.auth.accessToken },
    });
  };

  public retryConversion = (id: string, workspaceId?: string | null) => {
    return this.instance.post<{ url: string }>(`${this.endpoint}/retry-conversion/${id}`, { workspaceId });
  };

  public getMagicNumber = async (url: string, numBytes = 8) => {
    try {
      const res = await axios.get(url, {
        responseType: "arraybuffer",
        headers: {
          Range: `bytes=0-${numBytes - 1}`,
        },
      });
      return res.data as ArrayBuffer;
    } catch (error) {
      console.warn(error);
      return undefined;
    }
  };

  public getFileLink = (fileId: string, workspaceId?: string | null, preview?: boolean, version?: number, nodeId?: string) => {
    const url = new URL(`${rollupClient.auth.getParentUrl()}/cloud-storage/redirect/${fileId}`);

    if (workspaceId) {
      url.searchParams.set("workspaceId", workspaceId);
    }
    if (preview) {
      url.searchParams.set("preview", "true");
    }
    if (version) {
      url.searchParams.set("version", version.toString());
    }
    if (nodeId) {
      url.searchParams.set("nodeId", nodeId);
    }
    return url.toString();
  };

  public getThumbnailUrl = (fileId: string, version: number, workspaceId?: string | null) => {
    let baseUrl = `${rollupClient.auth.getParentUrl()}/cloud-storage/thumbnail/${fileId}?version=${version}`;

    if (workspaceId) {
      baseUrl += `&workspaceId=${workspaceId}`;
    }

    return baseUrl;
  };

  public getMetadata = (id: string, fillUrls?: boolean, workspaceId?: string | null) => {
    return this.instance.get<Attachment>(`${this.endpoint}/metadata/${id}`, { params: { fillUrls, workspaceId } });
  };

  public retrieveList = () => {
    return this.instance.get<Attachment[]>(`${this.endpoint}`);
  };

  public retrieveVersionList = (id: string, workspaceId?: string | null) => {
    return this.instance.get<AttachmentVersionInfo[]>(`${this.endpoint}/version-list/${id}`, { params: { workspaceId } });
  };

  public update = (id: string, dto: UpdateAttachmentDto) => {
    return this.instance.patch<Attachment>(`${this.endpoint}/${id}`, dto);
  };

  public delete = (id: string, workspaceId?: string | null) => {
    return this.instance.delete<Attachment>(`${this.endpoint}/${id}`, { params: { workspaceId } });
  };

  public getOpenGraphData = async (linkAttachmentUrl: string): Promise<OpenGraphResponseModel | undefined> => {
    try {
      const response = await this.instance.get<OpenGraphResponseModel>(`${this.endpoint}/info`, {
        params: { url: linkAttachmentUrl },
      });
      return response.data;
    } catch (err) {
      console.warn(err);
      return;
    }
  };
}
