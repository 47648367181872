import { Label } from "@blueprintjs/core";
import { Select } from "@blueprintjs/select";
import classNames from "classnames";

import { Button } from "@components/Button";
import StatusTag from "@components/StatusTag";
import { CatalogItemStatus } from "@rollup-api/models/catalogItem/catalogItemDtos";

import styles from "./CatalogItemStatusSelect.module.scss";

type Props = {
  onStatusChange: (status: CatalogItemStatus) => void;
  status?: CatalogItemStatus | null;
  disabled?: boolean;
};

const CatalogItemStatusSelect = (props: Props) => {
  const { status, onStatusChange, disabled } = props;

  return (
    <Label>
      <span className={styles.catalogItemStatusSelectLabel}>Status</span>
      <Select<CatalogItemStatus>
        disabled={disabled}
        filterable={false}
        items={Object.values(CatalogItemStatus)}
        popoverProps={{ minimal: true }}
        onItemSelect={onStatusChange}
        itemRenderer={(item, { handleClick }) => (
          <div
            className={classNames(styles.catalogItemStatusSelectItem, {
              [styles.catalogItemStatusSelectItemSelected]: item === status,
            })}
            key={item}
            onClick={handleClick}
          >
            <StatusTag status={item} />
          </div>
        )}
      >
        <Button
          disabled={disabled}
          fill
          alignText="left"
          className={styles.catalogItemStatusSelectButton}
          text={status ? <StatusTag status={status} /> : "Select status"}
          rightIcon="chevron-down"
          e2eIdentifiers="select-status-button"
        />
      </Select>
    </Label>
  );
};

export default CatalogItemStatusSelect;
