import { NonIdealState, NonIdealStateProps } from "@blueprintjs/core";
import { observer } from "mobx-react-lite";

import "./ThumbnailPlaceholder.scss";

type IThumbnailPlaceholderProps = Pick<NonIdealStateProps, "icon" | "title">;

const ThumbnailPlaceholder = (props: IThumbnailPlaceholderProps) => {
  const { icon, title } = props;

  return (
    <div className="thumbnail-placeholder">
      <NonIdealState icon={icon} title={title} />
    </div>
  );
};

export default observer(ThumbnailPlaceholder);
