import EmojiPicker, { EmojiClickData, Theme } from "emoji-picker-react";
import { observer } from "mobx-react";

import { Button } from "@components/Button";
import { Popover } from "@components/Popover";
import appStore from "@store/AppStore";
import { IReport } from "@store/ReportsStore";

import "./EmojiHolder.scss";

type Props = {
  report: IReport;
};

const EmojiHolder = (props: Props) => {
  const { report } = props;
  const { icon } = report;

  const setEmoji = (icon: string) => {
    report.update({ icon });
  };

  const theme = appStore.env.themeIsDark ? Theme.DARK : Theme.LIGHT;

  const emojiPickerContent = () => {
    return (
      <div>
        <div className="emoji-holder--header items-center flex justify-between">
          <span>Emoji</span>
          {icon && (
            <Button minimal onClick={() => setEmoji("")} e2eIdentifiers="remove">
              Remove
            </Button>
          )}
        </div>
        <EmojiPicker skinTonesDisabled theme={theme} height={320} width={400} onEmojiClick={(e: EmojiClickData) => setEmoji(e.emoji)} />
      </div>
    );
  };

  return (
    <Popover popoverClassName="emoji-holder--popover" className="emoji-holder" placement="bottom-start" content={emojiPickerContent()}>
      {icon ? (
        <div className="emoji-holder--emoji">{icon}</div>
      ) : (
        <div className="emoji-holder--row">
          <Button minimal icon="emoji" text="Add icon" className="emoji-holder--button" e2eIdentifiers="open-emoji-picker" />
        </div>
      )}
    </Popover>
  );
};

export default observer(EmojiHolder);
