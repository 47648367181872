import { observer } from "mobx-react";

import { AnchorButton } from "@components/AnchorButton";
import { ILocationData3D } from "@store/LocationData3DStore";
import { trackSegmentEvent } from "src/lib/Segment";
import { Text, TextVariant } from "src/ui/Text";

import { hacktivateCustomView } from "../Modeling/ModelingFrame/HoopsViewer/utils";

import "./AnnotationViewButton3D.scss";

interface SelectViewButtonProps {
  locationData3D?: ILocationData3D;
}

const AnnotationViewButton3D = ({ locationData3D }: SelectViewButtonProps) => {
  if (!locationData3D) {
    return null;
  }
  const handleViewClick = async (): Promise<void> => {
    trackSegmentEvent("annotation:navigate", { annotationId: locationData3D.viewId });
    if (locationData3D.viewId) {
      await hacktivateCustomView(locationData3D.viewId, locationData3D.recreated, locationData3D.view?.isolatedNodeId || undefined);
    }
  };

  return (
    <AnchorButton minimal onClick={handleViewClick} className="annotation-view-button" e2eIdentifiers="view">
      <Text variant={TextVariant.Body}>
        <span className="button-content">Go to associated view</span>
      </Text>
    </AnchorButton>
  );
};

export default observer(AnnotationViewButton3D);
