import { CSSProperties, ReactNode, useEffect, useRef } from "react";
import SplitPane, { SplitPaneProps } from "react-split-pane";
import classNames from "classnames";

import styles from "./SplitPaneGroup.module.scss";

interface ISplitPaneGroupProps extends SplitPaneProps {
  useCapturing?: boolean;
  hideResizer?: boolean;
  children: [ReactNode, ReactNode];
}

/**
 * Component uses <SplitPane> under the hood, it accepts no more than 2 children.
 * This component sets some useful defaults for global usage.
 */
function SplitPaneGroup({
  children,
  className,
  primary,
  useCapturing,
  hideResizer,
  onDragStarted,
  onDragFinished,
  onChange,
  ...rest
}: ISplitPaneGroupProps) {
  const ref = useRef<SplitPaneRef>(null);
  const fixedPaneStyle: CSSProperties = {
    left: undefined,
    right: undefined,
    position: undefined,
    height: undefined,
  };
  const flexPaneStyle: CSSProperties = {
    display: "flex",
    flexDirection: "column",
    flex: "1 1 0%",
    position: undefined,
    overflow: "hidden",
  };
  const hiddenResizerStyle: CSSProperties = {
    width: "0px",
  };

  const handleDragStart = () => {
    ref.current?.pane1.classList.add("dragging");
    ref.current?.pane2.classList.add("dragging");
    onDragStarted?.();
  };

  const handleDragEnd = (newSize: number) => {
    ref.current?.pane1.classList.remove("dragging");
    ref.current?.pane2.classList.remove("dragging");
    onDragFinished?.(newSize);
  };

  useEffect(() => {
    const onMouseUp = () => {
      if (ref.current?.state.active) {
        ref.current.setState({ active: false });
      }
    };

    if (useCapturing) {
      // When the mouse is released over the Hoops Web Viewer, SplitPane cannot detect it.
      // This happens probably because under the hood Hoops Web Viewer catches the mouse event
      // during the bubbling phase and stops its propagation. The solution here is to catch
      // the mouseup event during the capturing phase.
      // More about this issue can be found on: https://linear.app/rollup/issue/ENG-1942/adjusting-the-side-panel-has-a-weird-behavior
      document.addEventListener("mouseup", onMouseUp, { capture: true });

      return () => document.removeEventListener("mouseup", onMouseUp, { capture: true });
    }
  }, [useCapturing]);

  return (
    // TODO: Migrate away from react-split-pane, as it has not been updated in 3 years
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    <SplitPane
      ref={ref}
      className={classNames(styles.splitPaneGroup, className)}
      split="vertical"
      primary={primary}
      style={primary === "first" ? fixedPaneStyle : flexPaneStyle}
      resizerStyle={hideResizer ? hiddenResizerStyle : undefined}
      pane2Style={primary === "second" ? fixedPaneStyle : flexPaneStyle}
      onDragStarted={handleDragStart}
      onDragFinished={handleDragEnd}
      onChange={onChange}
      {...rest}
    >
      {children}
    </SplitPane>
  );
}

/** Exports. */
export type { ISplitPaneGroupProps };
export default SplitPaneGroup;

// Needed to manually patch types as they are not in sync with actual object
type SplitPaneRef = SplitPane & {
  pane1: HTMLDivElement;
  pane2: HTMLDivElement;
};
