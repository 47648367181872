import { toArray } from "@rollup-io/engineering";
import { AxiosResponse } from "axios";
import { destroy, flow, Instance, SnapshotIn, SnapshotOut, types } from "mobx-state-tree";

import { showApiErrorToast } from "@components/UiLayers/toaster";
import {
  AnalysisInput,
  AnalysisOutput,
  CodeBlock,
  CreateAnalysisInputDto,
  CreateAnalysisOutputDto,
  CreateCodeBlockDto,
  toAnalysisInputSnapshot,
  toAnalysisOutputSnapshot,
  toCodeBlockSnapshot,
} from "@rollup-api/models/code-blocks";
import { ExecutionEnvironmentType } from "@rollup-api/models/execution-environments";
import { AnalysisInputStore, IAnalysisInput, IAnalysisInputMobxType } from "@store/Analysis/AnalysisInputStore";
import { AnalysisOutputStore, IAnalysisOutput, IAnalysisOutputMobxType } from "@store/Analysis/AnalysisOutputStore";
import { CodeBlockStore, ICodeBlock, ICodeBlockMobxType } from "@store/Analysis/CodeBlockStore";
import appStore from "@store/AppStore";
import { getDefaultVariableName } from "@utilities/ExecutionEnvironments";
import { findNextAvailableName } from "@utilities/TextUtil";

import { rollupClient } from "../../core/api";

export const AnalysisModuleStore = types
  .model("AnalysisModuleStore", {
    codeBlockMap: types.map<ICodeBlockMobxType>(CodeBlockStore),
    analysisInputMap: types.map<IAnalysisInputMobxType>(AnalysisInputStore),
    analysisOutputMap: types.map<IAnalysisOutputMobxType>(AnalysisOutputStore),
  })
  .views(self => ({
    get codeBlocks(): ICodeBlock[] {
      return toArray<ICodeBlock>(self.codeBlockMap);
    },
    get analysisInputs(): IAnalysisInput[] {
      return toArray<IAnalysisInput>(self.analysisInputMap);
    },
    get analysisOutputs(): IAnalysisOutput[] {
      return toArray<IAnalysisOutput>(self.analysisOutputMap);
    },
  }))
  .actions(self => ({
    deleteCodeBlock(id: string, notify = true) {
      const block = self.codeBlockMap.get(id);

      if (notify) {
        rollupClient.analysisModule.codeBlocks.delete(id).catch((err: Error) => {
          showApiErrorToast("Error deleting code block", err);
        });
      }

      if (appStore.env.activeCodeBlockId === id) {
        appStore.env.clearActiveCodeBlock();
      }
      destroy(block);
      return true;
    },
    deleteAnalysisInput(id: string, notify = true) {
      const input = self.analysisInputMap.get(id);
      if (!input) {
        return false;
      }

      if (notify) {
        rollupClient.analysisModule.analysisInputs.delete(id).catch((err: Error) => {
          showApiErrorToast("Error deleting code input", err);
        });
      }
      destroy(input);
      return true;
    },
    deleteAnalysisOutput(id: string, notify = true) {
      const output = self.analysisOutputMap.get(id);
      if (!output) {
        return false;
      }

      if (notify) {
        rollupClient.analysisModule.analysisOutputs.delete(id).catch((err: Error) => {
          showApiErrorToast("Error deleting code output", err);
        });
      }
      destroy(output);
      return true;
    },
  }))
  .actions(self => ({
    addExistingCodeBlock(codeBlock: CodeBlock) {
      if (!codeBlock.id || self.codeBlockMap.has(codeBlock.id)) {
        return false;
      }
      self.codeBlockMap.put(toCodeBlockSnapshot(codeBlock));
      return true;
    },
    createCodeBlock: flow(function* createCodeBlock(
      dto: CreateCodeBlockDto = { label: "New code block", type: ExecutionEnvironmentType.Python }
    ): Generator<any, ICodeBlock | undefined, any> {
      // Ensure the label is unique per workspace
      dto.label = findNextAvailableName(
        dto.label,
        self.codeBlocks.map(block => block.label)
      );
      try {
        const res: AxiosResponse<CodeBlock> = yield rollupClient.analysisModule.codeBlocks.create(dto);
        if (res.status === 201) {
          const newBlock = toCodeBlockSnapshot(res.data);
          return self.codeBlockMap.put(newBlock);
        } else {
          showApiErrorToast("Error creating code block", new Error());
          return undefined;
        }
      } catch (err) {
        console.warn(err);
        showApiErrorToast("Error creating code block", err as Error);
        return undefined;
      }
    }),
    createAnalysisInput: flow(function* createAnalysisInput(block: ICodeBlock) {
      const dto: CreateAnalysisInputDto = {
        label: getDefaultVariableName(block.type, false),
        codeBlockId: block.id,
      };
      dto.label = findNextAvailableName(
        dto.label,
        block.inputs?.map(i => i.label)
      );

      try {
        const res = yield rollupClient.analysisModule.analysisInputs.create(dto);
        if (res.status === 201) {
          const input = toAnalysisInputSnapshot(res.data);
          self.analysisInputMap.put(input);
          block.inputs.push(input.id);
        } else {
          showApiErrorToast("Error creating code input", new Error());
        }
      } catch (err) {
        console.warn(err);
        showApiErrorToast("Error creating code input", err as Error);
      }
    }),
    addExistingAnalysisInput(analysisInput: AnalysisInput) {
      if (!analysisInput?.codeBlockId) {
        return false;
      }

      const block = self.codeBlockMap.get(analysisInput.codeBlockId);
      if (!block) {
        return false;
      }

      const inputSnapshot = toAnalysisInputSnapshot(analysisInput);
      self.analysisInputMap.put(inputSnapshot);
      block.inputs.push(inputSnapshot.id);
      return true;
    },
    createAnalysisOutput: flow(function* createAnalysisOutput(block: ICodeBlock) {
      const dto: CreateAnalysisOutputDto = {
        label: getDefaultVariableName(block.type, true),
        codeBlockId: block.id,
      };
      dto.label = findNextAvailableName(
        dto.label,
        block.outputs?.map(i => i.label)
      );

      try {
        const res = yield rollupClient.analysisModule.analysisOutputs.create(dto);
        if (res.status === 201) {
          const output = toAnalysisOutputSnapshot(res.data);
          self.analysisOutputMap.put(output);
          block.outputs.push(output.id);
        } else {
          showApiErrorToast("Error creating code output", new Error());
        }
      } catch (err) {
        console.warn(err);
        showApiErrorToast("Error creating code output", err as Error);
      }
    }),
    addExistingAnalysisOutput(analysisOutput: AnalysisOutput) {
      if (!analysisOutput?.codeBlockId) {
        return false;
      }

      const block = self.codeBlockMap.get(analysisOutput.codeBlockId);
      if (!block) {
        return false;
      }

      const outputSnapshot = toAnalysisOutputSnapshot(analysisOutput);
      self.analysisOutputMap.put(outputSnapshot);
      block.outputs.push(outputSnapshot.id);
      return true;
    },
    // TODO: Reordering of blocks, inputs and outputs
  }));

export interface IAnalysisModule extends Instance<typeof AnalysisModuleStore> {}

export interface IAnalysisModuleSnapshotIn extends SnapshotIn<typeof AnalysisModuleStore> {}

export interface IAnalysisModuleSnapshotOut extends SnapshotOut<typeof AnalysisModuleStore> {}
