import { Icon, Intent, Position } from "@blueprintjs/core";
import { observer } from "mobx-react";

import { Tooltip } from "@components/Tooltip";

const StatusIcon = ({ intent, tooltip, size }: { intent: Intent; tooltip?: string; size?: number }) => {
  return (
    <Tooltip content={tooltip ?? ""} disabled={!tooltip} position={Position.BOTTOM}>
      <Icon icon="full-circle" intent={intent} size={size ?? 12} className="status-icon" />
    </Tooltip>
  );
};

export default observer(StatusIcon);
