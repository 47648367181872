import { CreateDataLinkDto, CreateDataSourceDto, DataLink, DataSource, UpdateDataSourceDto } from "@rollup-api/models/data-sources";

import { HttpClient, ParentClient } from "../client";

export interface DataSourcesTestResult {
  success: boolean;
  elapsedTime?: number;
  message?: string;
}

export interface DataSourcesValueResult {
  success: boolean;
  message?: string;
  data?: {
    range?: string;
    majorDimension?: "ROWS" | "COLUMNS";
    values?: string | string[] | string[][];
  };
}

export class DataSources extends HttpClient {
  public constructor(parent: ParentClient) {
    super(parent);
  }

  private get endpoint() {
    return `workspace/${this.parent.workspaceId}/data-sources`;
  }

  public create = (dto: CreateDataSourceDto) => {
    return this.instance.post<DataSource>(`${this.endpoint}`, dto);
  };

  public get = (id: string) => {
    return this.instance.get<DataSource>(`${this.endpoint}/${id}`);
  };

  public getAll = () => {
    return this.instance.get<DataSource[]>(`${this.endpoint}`);
  };

  public update = (id: string, dto: UpdateDataSourceDto) => {
    return this.instance.patch<DataSource>(`${this.endpoint}/${id}`, dto);
  };

  public delete = (id: string) => {
    return this.instance.delete<DataSource>(`${this.endpoint}/${id}`);
  };

  public test = (id: string) => {
    return this.instance.get<DataSourcesTestResult>(`${this.endpoint}/${id}/test`);
  };

  public getValue = (id: string, query: string) => {
    return this.instance.get<DataSourcesValueResult>(`${this.endpoint}/${id}/value`, { params: { query } });
  };

  public refresh = (id: string, ttl?: number) => {
    return this.instance.post<DataLink[]>(`${this.endpoint}/${id}/refresh`, undefined, { params: { ttl } });
  };

  public createDataLink = (dto: CreateDataLinkDto) => {
    return this.instance.post<DataLink>(`${this.endpoint}/links`, dto);
  };

  public deleteDataLink = (id: string) => {
    return this.instance.delete<DataLink>(`${this.endpoint}/links/${id}`);
  };

  public deleteUnusedDataLinks = () => {
    return this.instance.delete<string[]>(`${this.endpoint}/links`);
  };

  public getAllDataLinks = () => {
    return this.instance.get<DataLink[]>(`${this.endpoint}/links`);
  };

  public getDataLink = (id: string, ttl?: number) => {
    return this.instance.get<DataLink>(`${this.endpoint}/links/${id}`, { params: { ttl } });
  };
}
