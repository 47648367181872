import { useState } from "react";
import { Classes } from "@blueprintjs/core";
import {
  closestCenter,
  DndContext,
  DragEndEvent,
  DragOverEvent,
  DragOverlay,
  DragStartEvent,
  PointerSensor,
  SensorOptions,
  TouchSensor,
  UniqueIdentifier,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { SyntheticListenerMap } from "@dnd-kit/core/dist/hooks/utilities";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import { SortableContext, verticalListSortingStrategy } from "@dnd-kit/sortable";
import classNames from "classnames";
import { observer } from "mobx-react";

import appStore from "@store/AppStore";
import { snapFixedSizeObjectToCenter } from "@utilities/DndModifiers";

import GenericDraggableItem, { RenderFunction, SortableItem } from "./GenericDraggableItem";

import "./SortableList.scss";

export interface SortableItemComponent {
  dragListeners: SyntheticListenerMap;
  isDragging?: boolean;
}

interface SortableListProps<T extends SortableItem> {
  items: T[];
  onDragEnd: (activeId: string, overId: string) => void;
  renderItem: RenderFunction<T>;
  defaultHeight?: number;
}

export const GenericSortableList = observer(<T extends SortableItem>(props: SortableListProps<T>) => {
  const [draggingId, setDraggingId] = useState<UniqueIdentifier>();
  const [overId, setOverId] = useState<UniqueIdentifier>();
  const height = props.defaultHeight ?? 45;

  const groupList = props.items.map(i => (
    <GenericDraggableItem key={i.id} item={i} renderFunction={props.renderItem} overId={overId} draggingId={draggingId} />
  ));

  const options: SensorOptions = {
    activationConstraint: {
      distance: 15,
    },
  };
  const sensors = useSensors(useSensor(PointerSensor, options), useSensor(TouchSensor, options));

  const handleDragOver = ({ over }: DragOverEvent) => {
    setOverId(over?.id);
  };

  const handleDragStart = (event: DragStartEvent) => {
    const { active } = event;
    setDraggingId(active.id);
  };

  const handleDragEnd = (event: DragEndEvent) => {
    setDraggingId(undefined);
    setOverId(undefined);
    const { active, over } = event;
    if (typeof over?.id === "string" && typeof active?.id === "string" && active.id !== over.id) {
      props.onDragEnd(active.id, over.id);
    }
  };

  const activeItem = props.items.find(i => i.id === draggingId);
  const snapCollapsedPropToCursor = snapFixedSizeObjectToCenter(0, height);

  return (
    <DndContext
      modifiers={[restrictToVerticalAxis, snapCollapsedPropToCursor]}
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
      onDragOver={handleDragOver}
    >
      <SortableContext items={props.items} strategy={verticalListSortingStrategy}>
        {groupList}
        <DragOverlay>
          {activeItem && (
            <div className={classNames("sortable-list-drag-placeholder", { [Classes.DARK]: appStore.env.themeIsDark })}>
              {props.renderItem(activeItem, true)}
            </div>
          )}
        </DragOverlay>
      </SortableContext>
    </DndContext>
  );
});
