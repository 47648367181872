import { IRequestTrialExtensionDto } from "@rollup-api/models/subscriptions/subcriptions.dto";

import { HttpClient, ParentClient } from "./client";

export class Subscriptions extends HttpClient {
  public constructor(parent: ParentClient) {
    super(parent);
  }

  private get endpoint() {
    return `subscription-plans`;
  }

  public requestTrialExtension = (dto: IRequestTrialExtensionDto) => {
    return this.instance.post(`${this.endpoint}/trial-extension-request`, { ...dto, duration: "1 month" });
  };
}
