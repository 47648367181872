import { toArray } from "@rollup-io/engineering";
import { destroy, IAnyModelType, Instance, types } from "mobx-state-tree";
import { v4 as uuidv4 } from "uuid";

import appStore from "@store/AppStore";
import { EFileUploadStatus, IUploadStore, IUploadStoreMobxType, UploadStore } from "@store/UploadStore";
import { getMimeType } from "@utilities";

export const UploadModuleStore = types
  .model("UploadModuleStore", {
    uploadFilesMap: types.map<IUploadStoreMobxType>(types.late((): IAnyModelType => UploadStore)),
  })
  .views(self => ({
    get uploadFiles(): IUploadStore[] {
      return toArray<IUploadStore>(self.uploadFilesMap);
    },
  }))
  .views(self => ({
    get uploadQueueBusy(): boolean {
      return self.uploadFiles.some(
        (uploadFile: IUploadStore) => uploadFile.status === EFileUploadStatus.UPLOADING || uploadFile.status === EFileUploadStatus.PAUSED
      );
    },
    get uploadQueueFailed(): boolean {
      return self.uploadFiles.some((uploadFile: IUploadStore) => uploadFile.status === EFileUploadStatus.FAILED);
    },
  }))
  .actions(self => ({
    removeFileUploadStore(uploadStore: IUploadStore) {
      self.uploadFilesMap.delete(uploadStore.id);
      destroy(uploadStore);
    },
  }))
  .actions(self => ({
    addNewFileUpload({
      blockId,
      workspaceId,
      files,
      onUpload,
      attachmentId,
      disableAddToBlock,
      removeOnUpload,
      skipCadConversion,
    }: {
      blockId?: string;
      workspaceId?: string;
      files: FileList;
      onUpload?: (attachmentId: string, file: File) => void;
      attachmentId?: string;
      disableAddToBlock?: boolean;
      removeOnUpload?: boolean;
      skipCadConversion?: boolean;
    }) {
      Array.from(files).forEach((file: File, index: number) => {
        const canStartUpload = !index && !self.uploadQueueBusy;
        const newFileUpload = self.uploadFilesMap.put({
          id: uuidv4(),
          label: file.name,
          block: blockId,
          type: getMimeType(file),
          attachmentId,
          workspaceId,
          removeOnUpload,
          skipCadConversion,
        });
        appStore.ui.showUploadQueueWindow();
        newFileUpload.pendingUpload(file, onUpload);
        if (canStartUpload) {
          newFileUpload.startUpload(disableAddToBlock);
        }
      });
    },
    triggerNextUpload() {
      const pendingNext = self.uploadFiles.find(f => f.status === EFileUploadStatus.PENDING);
      if (pendingNext) {
        pendingNext.startUpload();
      }
    },
    retryUploadQueue() {
      const firstFailedFile = self.uploadFiles.find(f => f.status === EFileUploadStatus.FAILED);

      if (firstFailedFile) {
        firstFailedFile.retryUploadChunks();
      }
    },
    cleanFileUploadStore() {
      self.uploadFiles.forEach((uploadStore: IUploadStore) => {
        if (
          uploadStore.status === EFileUploadStatus.UPLOADING ||
          uploadStore.status === EFileUploadStatus.FAILED ||
          uploadStore.status === EFileUploadStatus.PAUSED
        ) {
          uploadStore.cancelUpload();
        } else {
          self.removeFileUploadStore(uploadStore);
        }
      });
    },
  }));

export interface IUploadModuleStore extends Instance<typeof UploadModuleStore> {}
