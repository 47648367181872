import { useCallback, useState } from "react";
import { useParams } from "react-router-dom";
import { Menu, PopoverInteractionKind, PopoverPosition, Position } from "@blueprintjs/core";
import { Editor } from "@tiptap/core";
import { observer } from "mobx-react";

import { MenuItem } from "@components/MenuItem";
import { MenuItemDelete } from "@components/MenuItems";
import { Popover } from "@components/Popover";
import ReportTagContextMenu from "@components/Reports/Editor/Extentions/Mention/MentionTag/ReportTag/ReportTagContextMenu";
import { MentionMode } from "@components/Reports/Editor/Extentions/Mention/types";
import { ReportPreview } from "@components/Reports/ReportPreview";
import { Tooltip } from "@components/Tooltip";
import { useAppNavigate } from "@router/hooks";
import { IReport } from "@store/ReportsStore";
import { createCopyToClipboardHandler } from "@utilities/TextUtil";

import "./ReportTag.scss";

interface IReportTagProps {
  report: IReport;
  reportLink: string;
  editor: Editor;
  mode?: MentionMode;
  showContextMenu?: boolean;
  usePlaceholder?: boolean;
  updateAttributes(attributes: Record<string, any>): void;
  onDelete?(): void;
  onUpdate?(content: string): void;
}

const ReportTag = (props: IReportTagProps) => {
  const { report, reportLink, editor, mode = MentionMode.Mention } = props;
  const { showContextMenu, usePlaceholder, onDelete } = props;
  const { updateAttributes: updateAttributesProps, onUpdate } = props;
  const [activeMenuMode, setActiveMenuMode] = useState(mode ?? MentionMode.Mention);
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);
  const { reportId } = useParams();
  const isSelfReference = report.id === reportId;

  const { navigateToReport } = useAppNavigate();

  const handleDelete = () => {
    editor.commands.focus();
    onDelete?.();
    editor.commands.blur();
  };

  const updateAttributes = useCallback(
    (attrs: Record<string, any>) => {
      updateAttributesProps(attrs);
      const content = editor.getHTML();
      onUpdate?.(content);
    },
    [editor, onUpdate, updateAttributesProps]
  );

  const handleInteraction = useCallback(
    (nextOpenState: boolean) => {
      if (!nextOpenState) {
        updateAttributes({ showContextMenu: false, mode: activeMenuMode });
      }
    },
    [activeMenuMode, updateAttributes]
  );

  const handleTurnIntoLink = useCallback(() => {
    setIsTooltipOpen(false);
    updateAttributes({ mode: MentionMode.Link });
  }, [updateAttributes]);
  const handleTurnIntoMention = useCallback(() => {
    setIsTooltipOpen(false);
    updateAttributes({ mode: MentionMode.Mention });
  }, [updateAttributes]);
  const handleTurnIntoPreview = useCallback(() => {
    setIsTooltipOpen(false);
    updateAttributes({ mode: MentionMode.Preview });
  }, [updateAttributes]);

  const handleBlockClicked = useCallback(() => {
    if (report) {
      navigateToReport(report.id);
    }
  }, [report, navigateToReport]);

  const handleMenuConfirmation = useCallback(() => handleInteraction(false), [handleInteraction]);

  const renderLabelWithIcon = () => {
    return (
      <>
        <span className="report-tag--icon">{report.displayedIcon}</span>
        <span className="report-tag--label">{report.label || "Untitled"}</span>
      </>
    );
  };

  const renderLabel = () => {
    switch (showContextMenu ? activeMenuMode : mode) {
      case MentionMode.Link:
        return <span className="report-tag--link">{reportLink}</span>;
      case MentionMode.Mention:
        return renderLabelWithIcon();
      case MentionMode.Preview:
        return <ReportPreview report={report} menuContent={renderMenuContent()} usePlaceholder={usePlaceholder} large />;
    }
  };

  const renderMenuContent = () => {
    const copyToClipboard = createCopyToClipboardHandler(reportLink, "report link");

    return (
      <Menu className="report-tag--menu">
        <MenuItem
          text="Turn into mention"
          icon="reset"
          disabled={mode === MentionMode.Mention}
          e2eIdentifiers="turn-into-mention"
          onClick={handleTurnIntoMention}
        />
        <MenuItem
          text="Turn into link"
          icon="reset"
          disabled={mode === MentionMode.Link}
          e2eIdentifiers="turn-into-link"
          onClick={handleTurnIntoLink}
        />
        <Tooltip
          content={isSelfReference ? "Cannot use mention preview for self-reference mentions" : ""}
          disabled={!isSelfReference}
          position={PopoverPosition.RIGHT}
        >
          <MenuItem
            text="Turn into preview"
            icon="reset"
            disabled={mode === MentionMode.Preview || isSelfReference}
            e2eIdentifiers="turn-into-preview"
            onClick={handleTurnIntoPreview}
          />
        </Tooltip>
        <MenuItem text="Copy link" icon="duplicate" e2eIdentifiers="copy-link" onClick={copyToClipboard} />
        {onDelete && <MenuItemDelete onDelete={handleDelete} />}
      </Menu>
    );
  };

  return (
    <>
      <Popover
        popoverClassName="report-tag--paste-popover"
        isOpen={showContextMenu}
        onInteraction={handleInteraction}
        position={PopoverPosition.TOP_LEFT}
        content={
          <ReportTagContextMenu
            activeMode={activeMenuMode}
            isSelfReference={isSelfReference}
            onPasteAsLink={handleTurnIntoLink}
            onPasteAsMention={handleTurnIntoMention}
            onPasteAsPreview={handleTurnIntoPreview}
            onChangeActiveMode={setActiveMenuMode}
            onConfirm={handleMenuConfirmation}
          />
        }
      >
        {/* dummy anchor so that the popover is not repositioned when the label changes size */}
        <div />
      </Popover>
      <Tooltip
        popoverClassName="report-tag--tooltip-popover"
        hoverOpenDelay={300}
        hoverCloseDelay={200}
        isOpen={showContextMenu ? false : isTooltipOpen}
        onInteraction={setIsTooltipOpen}
        interactionKind={PopoverInteractionKind.HOVER}
        placement={Position.BOTTOM}
        disabled={mode === MentionMode.Preview}
        content={<ReportPreview report={report} menuContent={renderMenuContent()} />}
      >
        <div className="report-tag" onClick={handleBlockClicked}>
          {renderLabel()}
        </div>
      </Tooltip>
    </>
  );
};

export default observer(ReportTag);
