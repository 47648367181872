import { Tabs as BPTabs, TabsProps } from "@blueprintjs/core";
import classNames from "classnames";
import { observer } from "mobx-react";

import styles from "./Tabs.module.scss";

interface ITabsProps extends TabsProps {}

const Tabs = (props: ITabsProps) => {
  return <BPTabs {...props} className={classNames(styles.tabs, props.className)}></BPTabs>;
};

export default observer(Tabs);
