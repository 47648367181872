import { MouseEvent, PropsWithChildren, ReactNode, useCallback, useEffect } from "react";
import { IconName, Intent, MaybeElement } from "@blueprintjs/core";
import classNames from "classnames";

import { Button } from "@components/Button";
import { DialogLegacy } from "@components/Dialog";
import { DialogBody } from "@components/DialogBody";
import { DialogFooter } from "@components/DialogFooter";
import { IE2EIdentifiers, mergeE2EIdentifiers } from "@utilities/E2EUtils";

import "./ConfirmationDialog.scss";

interface ConfirmationDialogProps {
  title: string;
  description?: string | ReactNode;
  actionButtonText: string;
  icon?: IconName | MaybeElement;
  intent?: Intent;
  disabled?: boolean;
  isOpen: boolean;
  cancelE2EIdentifiers?: IE2EIdentifiers;
  confirmE2EIdentifiers?: IE2EIdentifiers;
  onCancel(): void;
  onClose(): void;
  onConfirm(event: MouseEvent | KeyboardEvent): void;
}

// If isOpen is undefined, the dialog will be controlled (opened) by the child component. Otherwise, it will be controlled by the parent.
const ConfirmationDialog = (props: PropsWithChildren<ConfirmationDialogProps>) => {
  const { isOpen, title, description, actionButtonText, intent } = props;
  const { disabled, cancelE2EIdentifiers = "cancel", confirmE2EIdentifiers = "confirm", onCancel, onClose, onConfirm } = props;

  const handleKeyDown = useCallback(
    (e: KeyboardEvent) => {
      if (e.key === "Escape") {
        onCancel();
      } else if (e.key === "Enter") {
        onConfirm(e);
      }
    },
    [onCancel, onConfirm]
  );

  useEffect(() => {
    if (isOpen) {
      document.addEventListener("keydown", handleKeyDown);
    }

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [handleKeyDown, isOpen]);

  return (
    <DialogLegacy className="confirmation-dialog" isOpen={isOpen} title={title} onClose={onClose} lazy canEscapeKeyClose>
      {description && <DialogBody>{description}</DialogBody>}
      <DialogFooter className={classNames("confirmation-dialog--footer", { ["confirmation-dialog--footer-equal-padding"]: !description })}>
        <Button
          className="confirmation-dialog--button"
          onClick={onCancel}
          minimal
          outlined
          e2eIdentifiers={mergeE2EIdentifiers("confirmation-dialog", cancelE2EIdentifiers)}
          large
        >
          Cancel
        </Button>
        <Button
          className="confirmation-dialog--button"
          onClick={onConfirm}
          intent={intent}
          e2eIdentifiers={mergeE2EIdentifiers("confirmation-dialog", confirmE2EIdentifiers)}
          disabled={disabled}
          large
        >
          {actionButtonText}
        </Button>
      </DialogFooter>
    </DialogLegacy>
  );
};

export default ConfirmationDialog;
