import { useState } from "react";
import { Icon } from "@blueprintjs/core";
import { ItemRenderer, Suggest2 } from "@blueprintjs/select";
import { observer } from "mobx-react";

import { MenuItem } from "@components/MenuItem";
import appStore from "@store/AppStore";
import { IPropertyInstance } from "@store/PropertyInstanceStore";
import { AutoCompleteEntry, getPropertyInstanceById } from "@utilities";

import "./SearchBar.scss";

type TSearchBarProps = {
  onItemSelect: (onItemSelect: IPropertyInstance) => void;
};

const MaxAutoCompleteResults = 8;

const SearchBar = (props: TSearchBarProps) => {
  const [inputText, setInputText] = useState<string>("");

  const handleChange = (item: AutoCompleteEntry) => {
    const linkedPropertyValue = getPropertyInstanceById(item.id);
    props.onItemSelect(linkedPropertyValue as IPropertyInstance);
  };

  const queryAutoCompleteList = (query: string, items: AutoCompleteEntry[]) => {
    const fuse = appStore.workspaceModel?.autoCompleteOptions.fuse;
    if (!query.length) {
      return items.slice(0, MaxAutoCompleteResults);
    }

    return fuse?.search(query, { limit: MaxAutoCompleteResults }).map(r => r.item) ?? [];
  };

  const renderAutoCompleteItem: ItemRenderer<AutoCompleteEntry> = (entry: AutoCompleteEntry, { handleClick, modifiers }) =>
    !entry || !modifiers.matchesPredicate ? null : (
      <MenuItem label={entry.label} key={entry.id} onClick={handleClick} text={entry.path} e2eIdentifiers={["autocomplete", entry.label]} />
    );

  const handleRenderInputValue = () => inputText;
  const handleChangeInputValue = (query: string) => setInputText(query);

  return (
    <div className="search-bar">
      <Suggest2<AutoCompleteEntry>
        items={appStore.workspaceModel?.autoCompleteOptions.entries || []}
        itemListPredicate={queryAutoCompleteList}
        inputValueRenderer={handleRenderInputValue}
        itemRenderer={renderAutoCompleteItem}
        onItemSelect={handleChange}
        onQueryChange={handleChangeInputValue}
        inputProps={{
          placeholder: "Search for property...",
          leftIcon: <Icon size={12} icon="search" />,
          className: "search-bar-input",
        }}
        popoverProps={{
          minimal: true,
          position: "bottom-left",
          popoverClassName: "max-menu-class",
        }}
        noResults={<MenuItem disabled text="No results" e2eIdentifiers="no-results" />}
        fill
        resetOnQuery
        resetOnClose={false}
        resetOnSelect={false}
      />
    </div>
  );
};

export default observer(SearchBar);
