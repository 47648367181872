import { KeyboardEvent } from "react";
import classNames from "classnames";
import { observer } from "mobx-react";

import { EditorContent } from "@components/EditorContent";
import { useDocumentEditor } from "@components/Reports/Editor/useDocumentEditor";
import { ENTER_KEY } from "@constants/keys";
import { RollupEditorType } from "@rollup-types/editor";
import { IReportBlock } from "@store/ReportBlockStore";

import EditorBubbleMenu from "../EditorBubbleMenu";
import PreventModEnter from "../Extentions/PreventModEnter";
import { RollupInputRules } from "../Extentions/RollupInputRules";
import RollupPlaceholder from "../Extentions/RollupPlaceholder";
import { TEditorBlockProps } from "../types";
import { focusReportBlockById, getDocumentEditorClassNames } from "../utils";

import "./OrderListItem.scss";

// TODO need to migrate to using IDocumentBlock. Not relevant atm, because requirements
//  don't use this block type
interface IOrderListItem extends Omit<TEditorBlockProps, "documentBlock"> {
  reportBlock: IReportBlock;
}

const OrderListItemEditor = (props: IOrderListItem) => {
  const { reportBlock, editable = true, eventHandlers, disableDashNavigation, onUpdate, onEditorReady } = props;
  const { onFocus, onBlur, onEnterKeyDown, onBackspaceKeyDown, onArrowDownKey, onArrowUpKey } = eventHandlers;

  const placeholderExtension = disableDashNavigation ? [] : [RollupPlaceholder];
  const { editor, onBubbleMenuHide, onBubbleMenuShow } = useDocumentEditor({
    blockId: reportBlock.id,
    content: reportBlock.label,
    editable,
    extensions: [...placeholderExtension, PreventModEnter, RollupInputRules.configure({ reportBlockId: reportBlock.id })],
    disableDashNavigation,
    onUpdate,
    onFocus,
    onBlur,
    onEditorReady,
  });

  const handleKeyDownEvents = (e: KeyboardEvent<HTMLDivElement>) => {
    if (e.key === ENTER_KEY && !editor?.getText()) {
      reportBlock.updateText("");
      reportBlock.updateType(RollupEditorType.p);
      focusReportBlockById(reportBlock.id);
      return;
    }
    onEnterKeyDown(e);
    onBackspaceKeyDown(e);
    onArrowUpKey(e);
    onArrowDownKey(e);
  };

  const commonClassNames = getDocumentEditorClassNames({
    annotationList: reportBlock.annotationList,
  });

  return (
    <div data-order={`${reportBlock.orderListNumber()}.`} key={reportBlock.id} className="report-editor--order-item">
      <EditorBubbleMenu onHide={onBubbleMenuHide} onShow={onBubbleMenuShow} editor={editor} />
      <EditorContent
        id={reportBlock.id}
        onKeyDown={handleKeyDownEvents}
        className={classNames("report-editor--order", commonClassNames)}
        editor={editor}
        e2eIdentifiers="order-list-item"
      />
    </div>
  );
};

export default observer(OrderListItemEditor);
