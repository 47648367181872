import { useCallback, useState } from "react";

import { showApiErrorToast } from "@components/UiLayers/toaster";
import { Attachment } from "@rollup-api/models";

import { rollupClient } from "../core/api";

interface IUseFileUploadParams {
  blockId?: string;
  workspaceId?: string | null;
  onUploadStart?(file: File): void;
  onUploadSuccess?(link: string, attachment: Attachment): void;
}
export const useSingleFileUpload = (params: IUseFileUploadParams) => {
  const { blockId, workspaceId, onUploadStart, onUploadSuccess } = params;
  const [isLoading, setIsLoading] = useState(false);
  const [fileLink, setFileLink] = useState<string>();

  const uploadFile = (files: FileList) => {
    const file = files[0];
    if (file) {
      setIsLoading(true);
      onUploadStart?.(file);
      rollupClient.attachments
        .uploadFile({ blockId, label: "Block file", workspaceId }, files[0])
        .then(r => {
          if (r?.data.id) {
            const link = rollupClient.attachments.getFileLink(r?.data.id, workspaceId);
            setFileLink(link);
            onUploadSuccess?.(link, r?.data);
          }
        })
        .catch(err => showApiErrorToast("Unable to upload image", err))
        .finally(() => setIsLoading(false));
    }
  };

  const onClearDraggedFileLink = useCallback(() => setFileLink(undefined), []);

  return { isLoading, fileLink, handleFileUpload: uploadFile, onClearDraggedFileLink };
};
