import { IconName } from "@blueprintjs/icons";

import { ICustomIconName } from "@components/CustomIcon";

import { Color } from "./colors";

export enum IconSource {
  Blueprint = "Blueprint",
  DevIcon = "DevIcon",
  Custom = "Custom",
}

export interface IBlueprintIcon {
  source: IconSource.Blueprint;
  name: IconName;
  color?: Color;
}

export interface IDevIcon {
  source: IconSource.DevIcon;
  name: string;
  color?: Color;
}

export interface ICustomIcon {
  source: IconSource.Custom;
  name: ICustomIconName;
}

export type IIcon = IBlueprintIcon | IDevIcon | ICustomIcon;

export type IIconSourceMap = Partial<Record<IconSource, IIcon[]>>;
