import { Tooltip } from "@components/Tooltip";

import "./TruncatedLabel.scss";

type Props = {
  text: string;
  limit: number;
};

const TruncatedLabel = (props: Props) => {
  const { text, limit } = props;

  return (
    <Tooltip content={text} disabled={text.length <= limit} popoverClassName="truncated-label--tooltip">
      <span>{text.length > limit ? text.slice(0, limit) + "..." : text}</span>
    </Tooltip>
  );
};

export default TruncatedLabel;
