import { observer } from "mobx-react-lite";

import {
  isAttachmentGithubRepository,
  isAttachmentGoogleDriveFile,
  isFileAttachment,
  isReferenceAttachment,
  isUrlAttachment,
} from "@components/Modeling/ModelingFrame/ModelBlock/Attachments/utils";
import appStore from "@store/AppStore";

import AttachmentDetailsFile from "./AttachmentDetailsFile";
import AttachmentDetailsGithub from "./AttachmentDetailsGithub";
import AttachmentDetailsGoogle from "./AttachmentDetailsGoogle";
import AttachmentDetailsReference from "./AttachmentDetailsReference";
import AttachmentDetailsLink from "./AttachmentDetailsUrl";

const AttachmentDetails = () => {
  const attachmentDetailsId = appStore.ui.attachmentDetails;
  const workspaceAttachment = attachmentDetailsId ? appStore.workspaceModel?.attachments.get(attachmentDetailsId) : undefined;
  const organizationAttachment = attachmentDetailsId ? appStore.orgModel?.attachments.get(attachmentDetailsId) : undefined;
  const attachment = workspaceAttachment || organizationAttachment;

  if (!attachment) {
    return null;
  }

  const onClose = () => appStore.ui.setAttachmentDetails();

  if (isFileAttachment(attachment)) {
    return <AttachmentDetailsFile attachment={attachment} isOpen onClose={onClose} />;
  } else if (isUrlAttachment(attachment)) {
    return <AttachmentDetailsLink attachment={attachment} isOpen onClose={onClose} />;
  } else if (isAttachmentGoogleDriveFile(attachment)) {
    return <AttachmentDetailsGoogle attachment={attachment} isOpen onClose={onClose} />;
  } else if (isAttachmentGithubRepository(attachment)) {
    return <AttachmentDetailsGithub attachment={attachment} isOpen onClose={onClose} />;
  } else if (isReferenceAttachment(attachment)) {
    return <AttachmentDetailsReference attachment={attachment} isOpen onClose={onClose} />;
  }

  return null;
};

export default observer(AttachmentDetails);
