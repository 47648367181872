import { Card, Position } from "@blueprintjs/core";
import { observer } from "mobx-react";

import { Tooltip } from "@components/Tooltip";
import { ExecutionArtifact } from "@rollup-api/models/execution-environments";
import { openImage } from "@utilities/image";

import styles from "./ExecutionArtifactThumbnail.module.scss";

const ExecutionArtifactThumbnail = ({ artifact, size = 128 }: { artifact: ExecutionArtifact; size?: number }) => {
  return (
    <Tooltip content={artifact.filename} disabled={!artifact.filename} position={Position.BOTTOM}>
      <Card
        compact
        className={styles.executionArtifactThumbnail}
        elevation={1}
        interactive
        onClick={() => openImage(artifact.dataUri, artifact.filename)}
      >
        <img src={artifact.dataUri} alt="Embedded image" style={{ width: size }} />
      </Card>
    </Tooltip>
  );
};

export default observer(ExecutionArtifactThumbnail);
