import { BlueprintIcon } from "@ui/BlueprintIcon";

import styles from "./CommentHeader.module.scss";

export const CommentHeader = () => {
  return (
    <div className={styles.commentHeader}>
      <BlueprintIcon icon="comment" />
    </div>
  );
};
