import isNull from "lodash/isNull";
import omitBy from "lodash/omitBy";

import { EntityModelBase } from "@rollup-api/shared/entityModelBase";
import { IAnalysisInputSnapshotIn } from "@store/Analysis/AnalysisInputStore";
import { convertTimestamp } from "@utilities";

export class AnalysisInput extends EntityModelBase {
  public readonly codeBlockId!: string;
  public readonly label!: string;
  public readonly value!: string;
  public readonly unit?: string;
  public readonly orderIndex?: number;
}

export function toAnalysisInputSnapshot(analysisInput: AnalysisInput): IAnalysisInputSnapshotIn {
  const input = omitBy(analysisInput, isNull) as AnalysisInput;
  return {
    ...input,
    codeBlock: analysisInput.codeBlockId,
    createdAt: convertTimestamp(input.createdAt),
    updatedAt: convertTimestamp(input.updatedAt),
  };
}
