import { useEffect, useState } from "react";
import { NonIdealState, Overlay, Spinner } from "@blueprintjs/core";
import { observer } from "mobx-react";

import { OrganizationListEntry } from "@rollup-api/models/organizations";
import appStore from "@store/AppStore";
import { rollupClient } from "src/core/api";

import OrgIcon from "./OrgIcon";
import OrgSelectDropdownButton from "./OrgSelectDropdownButton";

import "./OrgSelectDropdown.scss";

const OrgSelectDropdown = () => {
  const [orgList, setOrgList] = useState<OrganizationListEntry[]>([]);
  const [isSwitching, setIsSwitching] = useState(false);
  const { info } = appStore.orgModel;

  useEffect(() => {
    if (info.id) {
      rollupClient.organizations.retrieveAll().then(res => {
        if (res.data) {
          setOrgList(res.data);
        }
      });
    }
  }, [info.id]);

  const renderContent = () => {
    if (!appStore.orgModel?.info.id) {
      return (
        <div className="org-logo-static">
          <Spinner size={24} />
          Loading organization
        </div>
      );
    } else if (!orgList || orgList.length <= 1) {
      return (
        <div className="org-logo-static">
          <OrgIcon org={appStore.orgModel.info} />
          {appStore.orgModel?.info?.name}
        </div>
      );
    }

    return <OrgSelectDropdownButton orgList={orgList} setIsSwitching={setIsSwitching} />;
  };

  return (
    <>
      <Overlay isOpen={isSwitching}>
        <NonIdealState title="Switching organization">
          <Spinner size={64} />
        </NonIdealState>
      </Overlay>
      {renderContent()}
    </>
  );
};

export default observer(OrgSelectDropdown);
