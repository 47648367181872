import { CSSProperties } from "react";
import classNames from "classnames";
import devIconInfoList from "devicon/devicon.json";
import { observer } from "mobx-react-lite";

import { Color } from "@rollup-types/colors";
import { IDevIconVersion } from "@rollup-types/devicon";
import { getColorClassName } from "@utilities/Color";

import "devicon/devicon.min.css";
import styles from "./DevIcon.module.scss";

export const devIconNamesList = devIconInfoList.map(iconInfo => iconInfo.name);

const getIconVersion = (name: string): IDevIconVersion | undefined => {
  const devIcon = devIconInfoList.find(icon => icon.name === name);
  if (!devIcon) {
    return undefined;
  } else if (devIcon.versions.font.includes("plain")) {
    return "plain";
  } else if (devIcon.versions.font.includes("original")) {
    return "original";
  }
  return devIcon.versions.font[0] as IDevIconVersion;
};

interface IDevIconProps {
  icon: string;
  color?: Color;
  size?: number;
  colored?: boolean;
  className?: string;
  style?: CSSProperties;
  onClick?(): void;
}

const DevIcon = (props: IDevIconProps) => {
  const { style, icon, color, size, onClick } = props;
  const version = getIconVersion(icon);

  const devIconClassName = `devicon-${icon.toLowerCase()}-${version}${props.colored ? " colored" : ""}`;
  const colorClassName = getColorClassName(color);
  return (
    <i
      className={classNames(styles.devIcon, devIconClassName, props.className, colorClassName)}
      onClick={onClick}
      style={{ fontSize: size ?? 14, ...style }}
    />
  );
};

export default observer(DevIcon);
