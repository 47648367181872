import { Icon } from "@blueprintjs/core";
import { NodeViewWrapper } from "@tiptap/react";

import AttachmentPopup from "@components/AttachmentPopup";
import { Popover } from "@components/Popover";

import "./ImageCreateComponent.scss";

type Props = {
  onLinkChange: (url: string) => void;
  workspaceId: string;
};

export const getImageCreateComponent = () => "<image-creator></image-creator>";

const ImageCreateComponent = (props: Props) => (
  <NodeViewWrapper className="image-create">
    <Popover
      className="image-create--popover"
      content={<AttachmentPopup workspaceId={props.workspaceId} hideRemove onLinkChange={props.onLinkChange} />}
    >
      <div className="image-create--button">
        <Icon className="image-create--icon" icon="media" size={16} />
        Add an image
      </div>
    </Popover>
  </NodeViewWrapper>
);

export default ImageCreateComponent;
