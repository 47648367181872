import { EditableText, H3, Position, Tooltip } from "@blueprintjs/core";
import { useWorkspace } from "@hooks/useWorkspace";
import { observer } from "mobx-react";

import { AnchorButton } from "@components/AnchorButton";
import BlockTypeIcon from "@components/BlockTypeIcon";
import IconSelector from "@components/IconSelector/IconSelector";
import { MultiplicityInput } from "@components/MultiplicityInput";
import PartNumber from "@components/PartNumber";
import { Popover } from "@components/Popover";
import { IconSource } from "@rollup-types/icons";
import { IBlock } from "@store/BlockStore";

import BlockTypeSelectorMenu from "../BlockTypeSelectorMenu";

import styles from "./BlockViewHeader.module.scss";

type Props = {
  block: IBlock;
};

const BlockViewHeader = (props: Props) => {
  const { block } = props;
  const workspace = useWorkspace();

  const handleAddNewSchema = (label: string, schema: string, leadingZeroes?: number) => {
    workspace.partNumberSchemas.addPartNumberSchema(label, schema, leadingZeroes, workspace.id).then(newSchema => {
      if (newSchema) {
        block.setPartNumber(newSchema);
      }
    });
  };

  return (
    <div className={styles.blockViewHeader}>
      <div className={styles.blockViewHeaderTopContainer}>
        <IconSelector
          iconSources={[IconSource.Blueprint, IconSource.Custom]}
          currentIcon={block.iconView}
          tooltip="Set block icon"
          onChange={block.setIcon}
          hideColorPicker
        />
        <Popover content={<BlockTypeSelectorMenu block={block} />} position={Position.BOTTOM_LEFT}>
          <Tooltip content="Set block type">
            <AnchorButton icon={<BlockTypeIcon block={block} />} e2eIdentifiers="show-block-type-selector-menu" />
          </Tooltip>
        </Popover>
        {block.multiplicityIsEnabled ? (
          <MultiplicityInput className={styles.multiplicityInput} block={block} />
        ) : (
          <Tooltip content="Add multiplicity">
            <AnchorButton
              className="block-add-button add-mult-button"
              minimal
              alignText="left"
              icon="small-plus"
              onClick={block.enableMultiplicity}
              e2eIdentifiers="enable-multiplicity"
            />
          </Tooltip>
        )}
      </div>
      <div>
        <H3 className="mb-2 flex-1">
          <EditableText
            key={`${block.id}-${block.label}`}
            placeholder="Block Label"
            defaultValue={block.label}
            confirmOnEnterKey
            onConfirm={block.setLabel}
          />
        </H3>
        <PartNumber
          onUpdateFromString={block.updatePartNumber}
          onUpdateFromSchema={block.setPartNumber}
          partNumberSchemas={workspace.partNumberSchemas.values}
          onSchemaDelete={workspace.partNumberSchemas.deletePartNumberSchema}
          partNumber={block.partNumber || ""}
          onAddNewSchema={handleAddNewSchema}
        />
      </div>
    </div>
  );
};

export default observer(BlockViewHeader);
