import { isValidElement } from "react";
import { observer } from "mobx-react";

import { Text } from "src/ui/Text";

import { InputBoxProps } from "./InputBox";

import "./Placeholder.scss";

type IPlaceholderProps = Pick<InputBoxProps, "placeholder"> & { onClick: () => void };

const Placeholder = ({ placeholder, onClick }: IPlaceholderProps) => {
  if (isValidElement(placeholder)) {
    return <div onClick={onClick}>{placeholder}</div>;
  } else if (typeof placeholder === "function") {
    return <>{placeholder(onClick)}</>;
  }

  return (
    <Text onClick={onClick} className="placeholder">
      {placeholder}
    </Text>
  );
};

export default observer(Placeholder);
