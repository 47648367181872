// collection ids are taken from the BEB account;
export const PROBLEM_COLLECTION_ID = "a66154a3-bcb8-4752-8769-97f081048290";
export const QUESTIONS_COLLECTION_ID = "27c44a78-5a16-489f-b5a0-e2821c14a6b8";
export const FEEDBACK_COLLECTION_ID = "775dd3b1-69e2-4632-83eb-cda069b942fc";

export enum EFeedbackType {
  PROBLEM = QUESTIONS_COLLECTION_ID,
  QUESTION = PROBLEM_COLLECTION_ID,
  FEEDBACK_AND_REQUEST = FEEDBACK_COLLECTION_ID,
}
