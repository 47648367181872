import { GitHubDetails, GitHubItemType, IssueDetails, PullRequestDetails, RepoDetails } from "@rollup-api/models";

export const isRepoDetails = (githubDetails?: GitHubDetails): githubDetails is RepoDetails => {
  return githubDetails?.type === GitHubItemType.REPO;
};

export const isPullRequestDetails = (githubDetails?: GitHubDetails): githubDetails is PullRequestDetails => {
  return githubDetails?.type === GitHubItemType.PULL_REQUEST;
};

export const isIssueDetails = (githubDetails?: GitHubDetails): githubDetails is IssueDetails => {
  return githubDetails?.type === GitHubItemType.ISSUE;
};
