import { cast, IAnyModelType, Instance, IType, SnapshotIn, SnapshotOut, types } from "mobx-state-tree";
import { Socket } from "socket.io-client";

import { IComplexTag } from "@components/TagsContainer/TagsContainer.types";
import { IUpdateDataSinkDto, IUpdateDataSinkEntryDto } from "@rollup-api/models/data-sinks";
import { DataSink, DataSinkEntry } from "@rollup-api/models/data-sinks/data-sink.model";
import { IIcon } from "@rollup-types/icons";
import appStore from "@store/AppStore";
import { convertTimestamp } from "@utilities";
import { applySanitizedSnapshot } from "@utilities/MobxUtils";
import { enhanceStringTags } from "@utilities/TagUtils";

import { DataSinkEntryStore } from "./DataSinkEntryStore";

export enum DataSinkEvents {
  CreateDataSink = "createDataSink",
  CreateDataSinkEntry = "createDataSinkEntry",
  UpdateDataSink = "updateDataSink",
  UpdateDataSinkEntry = "updateDataSinkEntry",
  DeleteDataSink = "deleteDataSink",
  DeleteDataSinkEntry = "deleteDataSinkEntry",
}

export const DataSinkStore = types
  .model("DataSink", {
    id: types.identifier,
    serial: types.number,
    label: types.string,
    description: types.string,
    tags: types.optional(types.string, ""),
    icon: types.maybeNull(types.frozen<IIcon>()),
    entries: types.array(types.safeReference(types.late((): IAnyModelType => DataSinkEntryStore))),
    createdAt: types.optional(types.number, Date.now()),
    updatedAt: types.optional(types.number, Date.now()),
    createdBy: types.optional(types.string, ""),
    updatedBy: types.optional(types.string, ""),
  })
  .views(self => ({
    get entryIds(): string[] {
      return self.entries.map(e => e.id);
    },
    get visibleId(): string {
      return `SNK-${self.serial}`;
    },
    get tagsArray(): string[] {
      return self.tags.split(",").filter(Boolean);
    },
    get enhancedTags(): IComplexTag[] {
      return enhanceStringTags(this.tagsArray);
    },
  }))
  .actions(self => ({
    patch(update: IUpdateDataSinkDto) {
      applySanitizedSnapshot(self, update, ["id", "entries", "createdAt", "createdBy"]);
    },
    addEntry(id: string) {
      self.entries.push(id);
    },
    removeEntry(id: string) {
      self.entries = cast(self.entries.filter(e => e && e.id !== id).map(e => e?.id));
    },
  }));

export function subscribeToDataSinkEvents(socket: Socket) {
  socket.on(DataSinkEvents.CreateDataSink, (data: { workspaceId: string; dataSink: DataSink }) => {
    if (data.dataSink?.id && data.workspaceId === appStore.workspaceModel?.id) {
      appStore.workspaceModel.dataConnection.addDataSink(data.dataSink);
    }
  });

  socket.on(DataSinkEvents.UpdateDataSink, (data: { id: string; updateDataSinkDto: IUpdateDataSinkDto; dataSink: DataSink }) => {
    const dto = { ...data.updateDataSinkDto, updatedAt: convertTimestamp(data.dataSink.updatedAt), updatedBy: data.dataSink.updatedBy };
    const dataSink = appStore.workspaceModel?.dataConnection.dataSinkMap?.get(data.id);
    dataSink?.patch(dto);
  });

  socket.on(DataSinkEvents.DeleteDataSink, (data: { workspaceId: string; id: string }) => {
    if (data.id && data.workspaceId === appStore.workspaceModel?.id) {
      appStore.workspaceModel.dataConnection.deleteDataSink(data.id, true);
    }
  });

  socket.on(DataSinkEvents.CreateDataSinkEntry, (data: { workspaceId: string; dataSinkEntry: DataSinkEntry }) => {
    if (data.dataSinkEntry?.id && data.workspaceId === appStore.workspaceModel?.id) {
      appStore.workspaceModel.dataConnection.addDataSinkEntry(data.dataSinkEntry);
    }
  });

  socket.on(
    DataSinkEvents.UpdateDataSinkEntry,
    (data: { id: string; updateDataSinkDto: IUpdateDataSinkEntryDto; dataSinkEntry: DataSinkEntry }) => {
      const dto = {
        ...data.updateDataSinkDto,
        updatedAt: convertTimestamp(data.dataSinkEntry.updatedAt),
        updatedBy: data.dataSinkEntry.updatedBy,
      };
      const dataSinkEntry = appStore.workspaceModel?.dataConnection.dataSinkEntryMap?.get(data.id);
      dataSinkEntry?.patch(dto);
    }
  );

  socket.on(DataSinkEvents.DeleteDataSinkEntry, (data: { workspaceId: string; id: string }) => {
    if (data.id && data.workspaceId === appStore.workspaceModel?.id) {
      appStore.workspaceModel.dataConnection.deleteDataSinkEntry(data.id, true);
    }
  });
}

export interface IDataSink extends Instance<typeof DataSinkStore> {}
export interface IDataSinkSnapshotIn extends SnapshotIn<typeof DataSinkStore> {}
interface IDataSinkSnapshotOut extends SnapshotOut<typeof DataSinkStore> {}
export interface IDataSinkMobxType extends IType<IDataSinkSnapshotIn, IDataSinkSnapshotOut, IDataSink> {}
