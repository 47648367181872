import { Outlet, useNavigate } from "react-router-dom";
import { useBool } from "@hooks/useBool/useBool";
import { useWorkspace } from "@hooks/useWorkspace";
import { observer } from "mobx-react";

import { CreateDocumentButton } from "@components/CreateDocumentButton";
import { FeatureLayout } from "@components/FeatureLayout";
import MenuPane from "@router/components/Panes/MenuPane";
import { useMenuPane } from "@router/components/Panes/useMenuPane";
import appStore from "@store/AppStore";
import { EPane } from "@store/PaneWidthStore";
import AnalysisSidebar from "src/Modules/Analysis/AnalysisSidebar/AnalysisSidebar";

import "./AnalysisLayout.scss";

function AnalysisLayout() {
  const navigate = useNavigate();
  const workspace = useWorkspace();
  const { isSubNavPaneOpen, toggleSubNavPane } = appStore.env;
  const { open, toggle, minSize, maxSize } = useMenuPane({ isOpen: isSubNavPaneOpen, toggleIsOpen: toggleSubNavPane });
  const [menuHovered, { set: hoverMenu, unset: unhoverMenu }] = useBool();

  const handleCreateNewBlock = async () => {
    const newDoc = await workspace.analysis.createCodeBlock();
    if (newDoc) {
      appStore.env.setActiveCodeBlock(newDoc.id);
      navigate(newDoc.id);
    }
  };

  const renderCreateButton = (text = "New Code block") => (
    <CreateDocumentButton text={text} onClick={handleCreateNewBlock} e2eIdentifiers="create-code-block" />
  );

  const renderCollapsedMenuContent = (text?: string) => (
    <div className="analysis-layout--collapsed-content">
      {renderCreateButton(text)}
      <AnalysisSidebar minimal />
    </div>
  );

  return (
    <FeatureLayout
      paneWidth={appStore.env.paneWidth.subNav}
      menuHovered={menuHovered}
      dynamicPanelContent={<AnalysisSidebar />}
      minSize={minSize}
      maxSize={maxSize}
      topElement={renderCreateButton(menuHovered ? undefined : "")}
      onPaneWidthChange={(newSize: number) => appStore.env.paneWidth.updatePaneWidth(EPane.SUB_NAV, newSize)}
      onHoverMenu={hoverMenu}
      onUnhoverMenu={unhoverMenu}
      isSidePanelOpen={open}
    >
      <MenuPane
        open={open}
        toggle={toggle}
        title="Analysis"
        topElement={renderCreateButton()}
        collapsedContent={renderCollapsedMenuContent("")}
        onMouseEnter={hoverMenu}
        onMouseLeave={unhoverMenu}
      >
        <AnalysisSidebar />
      </MenuPane>

      <Outlet />
    </FeatureLayout>
  );
}

export default observer(AnalysisLayout);
