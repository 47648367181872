import { CreateReportBlockDto, CreateReportBlocksDto, ReportBlock, ReportBlockUpdateDto } from "@rollup-api/models";
import { IReportBlock } from "@store/ReportBlockStore";

import { trackSegmentEvent } from "../../Segment";

import { HttpClient, ParentClient } from "./client";

export class ReportBlocks extends HttpClient {
  public constructor(parent: ParentClient) {
    super(parent);
  }

  private get endpoint() {
    return `workspace/${this.parent.workspaceId}/report-blocks`;
  }

  public create = (dto: CreateReportBlockDto) => {
    trackSegmentEvent("report-block:create", { id: dto.id, type: dto.type });
    return this.instance.post<IReportBlock>(`${this.endpoint}`, dto);
  };

  public bulkCreate = (dto: CreateReportBlocksDto) => {
    trackSegmentEvent("report-block:bulk-create");
    return this.instance.post<IReportBlock>(`${this.endpoint}/bulk-create`, dto);
  };

  public update = (id: string, dto: ReportBlockUpdateDto) => {
    trackSegmentEvent("report-block:update", { id, dto });
    return this.instance.patch<ReportBlock>(`${this.endpoint}/${id}`, dto);
  };

  public reorder = (id: string, destinationId: string) => {
    trackSegmentEvent("report-block:reorder", { id });
    return this.instance.patch(`${this.endpoint}/${id}/reorder`, { destinationId });
  };

  public bulkReorder = (ids: string[], destinationId: string) => {
    trackSegmentEvent("report-block:bulk-reorder", { numBlocks: ids.length });
    return this.instance.put(`${this.endpoint}/reorder`, { destinationId, ids });
  };

  public delete = (id: string) => {
    trackSegmentEvent("report-block:delete", { id });
    return this.instance.delete<ReportBlock>(`${this.endpoint}/${id}`);
  };
}
