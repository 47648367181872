import { useEffect, useState } from "react";
import { Menu, MenuDivider } from "@blueprintjs/core";
import { CommandProps } from "@tiptap/core";
import { observer } from "mobx-react";

import { MenuItem } from "@components/MenuItem";
import { MenuItemDelete } from "@components/MenuItems";
import { Switch } from "@components/Switch";

import { TH_NODE } from "./constants";
import { TNavProps } from "./types";

type TReplaceStep = {
  from: number;
  to: number;
};

const HorizontalNav = (props: TNavProps) => {
  const { editor, menuRef, onUpdate } = props;
  const [isTableHeader, setIsTableHeader] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setIsTableHeader(!!editor?.state.selection.ranges.some(r => r.$from.node().type.name === TH_NODE));
    }, 0);
  }, [editor]);

  const handleDelete = () => {
    editor?.chain().focus().deleteColumn().setTextSelection(0).run();
    onUpdate();
  };

  const insertLeft = () => {
    editor?.chain().focus().addColumnBefore().setTextSelection(0).run();
    onUpdate();
  };

  const insertRight = () => {
    editor?.chain().focus().addColumnAfter().setTextSelection(0).run();
    onUpdate();
  };

  const toggleHeader = () => {
    editor?.chain().focus().toggleHeaderCell().setTextSelection(0).run();
    menuRef.current?.setState({ isOpen: false });
    onUpdate();
  };

  const handleDuplicate = () => {
    if (!editor) {
      return;
    }

    // sort reverse selection ranges to fill table in back order so the rest of the affected cells keep the same position
    const selection = [...editor.state.selection.ranges].sort((a, b) => b.$from.pos - a.$from.pos);
    let steps: TReplaceStep[] = [];

    editor
      ?.chain()
      .focus()
      .addColumnAfter()
      .command((commandProps: CommandProps) => {
        // sort reverse steps to fill table in back order so the rest of the affected cells keep the same position
        steps = commandProps.tr.steps.reverse() as unknown as TReplaceStep[];
        return true;
      })
      .run();

    if (steps.length && selection?.length) {
      steps.forEach((step: TReplaceStep, index: number) => {
        const position = { anchorCell: step.from, headCell: step.to };
        const content = selection[index].$from.node().toJSON().content[0];
        editor?.chain().focus().setCellSelection(position).insertContent(content).run();
      });
    }

    onUpdate();
  };

  const handleClear = () => {
    editor?.commands.deleteSelection();
  };

  return (
    <Menu>
      <Switch
        className="table-button--switch"
        checked={isTableHeader}
        label="Header Column"
        onChange={toggleHeader}
        alignIndicator="right"
        e2eIdentifiers="header-column"
      />
      <MenuDivider />
      <MenuItem icon="arrow-left" text="Insert Left" onClick={insertLeft} e2eIdentifiers="insert-left" />
      <MenuItem icon="arrow-right" text="Insert Right" onClick={insertRight} e2eIdentifiers="insert-right" />
      <MenuItem icon="duplicate" text="Duplicate" onClick={handleDuplicate} e2eIdentifiers="duplicate" />
      <MenuItem icon="cross" text="Clear" onClick={handleClear} e2eIdentifiers="clear" />
      <MenuItemDelete onDelete={handleDelete} />
    </Menu>
  );
};

export default observer(HorizontalNav);
