import { KeyboardEvent, useState } from "react";
import { Divider, InputGroup, Menu, Position, Tooltip } from "@blueprintjs/core";
import { observer } from "mobx-react";

import BookmarkSavedIconDark from "@assets/icons/bookmark_saved_dark.svg?react";
import BookmarkSavedIconLight from "@assets/icons/bookmark_saved_light.svg?react";
import SaveCustomViewIconDark from "@assets/icons/hoopsIcons/dark/save_custom_view_dark.svg?react";
import SaveCustomViewIconLight from "@assets/icons/hoopsIcons/light/save_custom_view_light.svg?react";
import { Button } from "@components/Button";
import { MenuItem } from "@components/MenuItem";
import { MenuItemDelete } from "@components/MenuItems";
import { Popover } from "@components/Popover";
import { LegacyCustomIcon } from "@components/Shared/LegacyCustomIcon/LegacyCustomIcon";
import appStore from "@store/AppStore";
import { IView } from "@store/ViewStore";
import { Text, TextVariant } from "src/ui/Text";

import { hacktivateCustomView } from "../../utils";

import styles from "../HoopsMenu.module.scss";

const HoopsMenuSaveCustomViewButton = () => {
  const [newViewName, setNewViewName] = useState<string>("");
  const [oldViewNewName, setOldViewNewName] = useState<string>("");

  const handleLoadView = async (viewUniqueId: string, recreated: boolean, isolatedNodeId?: number) => {
    await hacktivateCustomView(viewUniqueId, recreated, isolatedNodeId);
  };

  const handleNewViewNameKeyDown = async (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key !== "Enter") return;
    if (newViewName) {
      await appStore.env.activeAttachment?.saveNewView(newViewName);
      setNewViewName("");
    }
  };

  const handleOldViewNewNameKeyDown = async (e: KeyboardEvent<HTMLInputElement>, id: string) => {
    if (e.key === "Enter") {
      if (oldViewNewName) {
        await appStore.env.activeAttachment?.updateViewName(id, oldViewNewName);
        appStore.env.activeAttachment?.populateHoopsViews();
      }
      setOldViewNewName("");
    }
  };

  const handleDeleteView = async (id: string) => {
    await appStore.env.activeAttachment?.deleteView(id);
  };

  const oldViewMenuItem = (view: IView) => {
    return (
      <MenuItem
        key={view.id}
        icon={<LegacyCustomIcon icon={appStore.env.themeIsDark ? <BookmarkSavedIconDark /> : <BookmarkSavedIconLight />} />}
        onClick={() => handleLoadView(view.uniqueId, view.recreated, view.isolatedNodeId || undefined)}
        text={view.name}
        e2eIdentifiers={[view.name]}
      >
        <div className="add-save-view-label">
          <Text variant={TextVariant.Caption}>Edit view name</Text>
          <InputGroup
            className="header-nav--input"
            value={oldViewNewName}
            placeholder={view.name}
            autoFocus
            onChange={e => setOldViewNewName(e.target.value)}
            onKeyDown={e => handleOldViewNewNameKeyDown(e, view.id)}
            content={view.name}
          />
        </div>
        <Divider />
        {/* TODO: implement copy link */}
        {/* <MenuItem icon="duplicate" text="Copy link" /> */}
        <MenuItemDelete text="Delete view" onDelete={() => handleDeleteView(view.id)} />
      </MenuItem>
    );
  };

  const saveNewViewMenu = (
    <Menu>
      <MenuItem icon="floppy-disk" text="Save current view" style={{ width: "100%" }} e2eIdentifiers="save-current-view">
        <div className="add-save-view-label">
          <Text variant={TextVariant.Caption}>Enter view label</Text>
          <InputGroup
            className="header-nav--input"
            value={newViewName}
            placeholder=""
            autoFocus
            onChange={e => setNewViewName(e.target.value)}
            onKeyDown={e => handleNewViewNameKeyDown(e)}
          />
        </div>
      </MenuItem>
      {appStore.env.activeAttachment && appStore.env.activeAttachment.views.length > 0 && <Divider />}
      {appStore.env.activeAttachment?.views.map((view: IView) => oldViewMenuItem(view))}
    </Menu>
  );

  return (
    <Popover content={saveNewViewMenu} placement={Position.TOP}>
      <Tooltip position={Position.TOP} hoverOpenDelay={500} content="Save custom view">
        <Button
          className={styles.hoopsToolbarButtonAndButtonGroup}
          icon={<LegacyCustomIcon large icon={appStore.env.themeIsDark ? <SaveCustomViewIconDark /> : <SaveCustomViewIconLight />} />}
          e2eIdentifiers="save-view"
        />
      </Tooltip>
    </Popover>
  );
};

export default observer(HoopsMenuSaveCustomViewButton);
