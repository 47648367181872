import { ReactElement } from "react";
import type { IconName, TreeNodeInfo } from "@blueprintjs/core";
import { PropertyDataType } from "@rollup-io/engineering";

import { Icon } from "@components/Icon";
import type { IBlock } from "@store/BlockStore";

import getTreeNodeLabel from "./TreeNodeLabel";

/**
 * Util function to convert a Block type to TreeNodeProps type, with option to limit childNode depth.
 *
 *  1. (depth === -1) => unlimited
 *  2. (depth === 0) => single level
 *  3. (depth > 0) => multi level
 *
 */
function transformBlockToTreeNodeProps(block: IBlock, activeBlockId: string | undefined, depth = -1, menu?: ReactElement) {
  const node: TreeNodeInfo<IBlock> = {
    id: block.id,
    icon: <Icon icon={block.iconView} />,
    isExpanded: !block.parentBlock || (block.ui.isExpanded ?? false),
    hasCaret: depth !== 0 && !!block.parentBlock && block.validatedChildren.length > 0,
    isSelected: block.id === activeBlockId,
    label: getTreeNodeLabel(block),
    nodeData: block,
    secondaryLabel: menu,
  };

  if (block.validatedChildren && depth !== 0) {
    node.childNodes = [];
    for (const b of block.validatedChildren) {
      node.childNodes.push(transformBlockToTreeNodeProps(b, activeBlockId, depth - 1));
    }
  }

  return node;
}

export const getPropTypeIcon = (dataType?: PropertyDataType): IconName => {
  switch (dataType) {
    case PropertyDataType.scalar:
      return "numerical";
    case PropertyDataType.string:
      return "citation";
    default:
      return "blank";
  }
};

/** Exports. */
export { transformBlockToTreeNodeProps };
