import { forwardRef, Fragment, MouseEvent, useEffect, useImperativeHandle, useState } from "react";
import { Menu, MenuDivider } from "@blueprintjs/core";
import { SuggestionProps } from "@tiptap/suggestion";
import groupBy from "lodash/groupBy";

import { MenuItem } from "@components/MenuItem";
import { getInfoFromId, getTitleFromType, mentionTypeToEntityTypeMap } from "@components/Reports/Editor/Extentions/Mention/MentionUtils";
import { IMentionItem, MentionType } from "@components/Reports/Editor/Extentions/Mention/types";
import appStore from "@store/AppStore";
import { trackSegmentEvent } from "src/lib/Segment";

import MentionItem from "./MentionItem";

import "./MentionList.scss";

export type MentionListProps = Pick<SuggestionProps<IMentionItem>, "text" | "items" | "command" | "query">;

export type MentionListRef = {
  onKeyDown: (props: { event: KeyboardEvent }) => boolean;
};

const MentionList = forwardRef<MentionListRef, MentionListProps>((props, ref) => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  // Reset selected item every time the query changes
  useEffect(() => setSelectedIndex(0), [props.items]);
  const selectedItem = props.items.at(selectedIndex);

  const handleCallAction = (item: IMentionItem) => {
    trackSegmentEvent(`mention:${item.type}`, { label: item.label });
    const entityType = mentionTypeToEntityTypeMap[item.type];
    if (entityType) {
      const { id } = getInfoFromId(item.id);
      appStore.env.addRecentEntity({ id, type: entityType });
    }
    props.command(item);
  };

  useImperativeHandle(ref, () => ({
    onKeyDown: ({ event }) => {
      if (event.key === "ArrowUp") {
        setSelectedIndex((selectedIndex + props.items.length - 1) % props.items.length);
        return true;
      }

      if (event.key === "ArrowDown") {
        setSelectedIndex((selectedIndex + 1) % props.items.length);
        return true;
      }

      if (event.key === "Enter") {
        if (selectedItem) {
          handleCallAction(selectedItem);
        }

        event.stopPropagation();
        return true;
      }

      event.stopPropagation();
      return false;
    },
  }));

  if (!props.items?.length) {
    return (
      <Menu>
        <MenuItem disabled text="No results found" e2eIdentifiers="no-results" />
      </Menu>
    );
  }

  const renderMentionItems = () => {
    const groupedItems = groupBy(props.items, item => item.type) as Record<MentionType, IMentionItem[]>;
    const itemTypes = Object.keys(groupedItems) as MentionType[];

    return itemTypes.map(type => {
      return (
        <Fragment key={type}>
          <MenuDivider key={type} title={getTitleFromType(type)} />
          {groupedItems[type].map(item => (
            <MentionItem key={item.id} item={item} selectedItem={selectedItem} onClick={handleCallAction} />
          ))}
        </Fragment>
      );
    });
  };

  const handleMouseDown = (e: MouseEvent) => {
    // This will prevent the grid from quitting mode when clicking on the mention list
    e.preventDefault();
    e.stopPropagation();
  };

  return (
    <Menu className="mention-list" onMouseDown={handleMouseDown}>
      {renderMentionItems()}
    </Menu>
  );
});
MentionList.displayName = "MentionList";

export default MentionList;
