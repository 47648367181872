import { FormEvent, useCallback, useEffect, useRef, useState } from "react";
import { Classes, InputGroup, Intent, NavbarDivider, PopoverPosition, Position, Tooltip } from "@blueprintjs/core";
import { Editor } from "@tiptap/core";
import Image from "@tiptap/extension-image";
import Link from "@tiptap/extension-link";
import { Placeholder } from "@tiptap/extension-placeholder";
import Underline from "@tiptap/extension-underline";
import { EditorOptions, Extension, useEditor } from "@tiptap/react";
import { StarterKit } from "@tiptap/starter-kit";
import classNames from "classnames";
import { observer } from "mobx-react";

import AttachmentPopup from "@components/AttachmentPopup";
import { Button } from "@components/Button";
import { DialogLegacy } from "@components/Dialog";
import { EditorContent } from "@components/EditorContent";
import CommentAttachmentsList from "@components/Modeling/ModelingFrame/ModelBlock/Comments/CommentAttachmentsList";
import { EditorProperty } from "@components/Modeling/ModelingFrame/ModelBlock/Comments/CommentEditorPropertyButton.utils";
import { Popover } from "@components/Popover";
import { ImageAsset } from "@components/Reports/Editor/Extentions/ImageAsset";
import { getImageAssetComponent } from "@components/Reports/Editor/Extentions/ImageAsset/components/ImageAssetComponent";
import { getMentionExtensions } from "@components/Reports/Editor/Extentions/Mention/MentionSuggestion";
import { showApiErrorToast } from "@components/UiLayers/toaster";
import UploadFileButton from "@components/UploadFileButton/UploadFileButton";
import { Keys } from "@constants/keys";
import appStore from "@store/AppStore";
import { CommentParent, IComment } from "@store/CommentStore";
import { IWorkspace } from "@store/WorkspaceStore";
import { getWorkspaceById } from "@utilities";
import { isEmpty } from "@utilities/TipTap";
import { rollupClient } from "src/core/api";

import { CommentEditorPropertyButton } from "./CommentEditorPropertyButton";
import { useLinkDialog, useOnClickOutside } from "./commentHooks";

import "./CommentEditor.scss";

type CommentEditorProps = {
  workspaceId?: string | null;
  className?: string;
  comment?: IComment;
  content: EditorOptions["content"];
  readonly?: boolean;
  isEditing?: boolean;
  autoEditOnFocus?: boolean;
  onConfirm?: (value: string, attachmentList: string[], publishAttachmentsToBlock: boolean) => void;
  onCancel?: () => void;
  onBeingEdited?: (isEditing: boolean) => void;
  resetOnConfirm?: boolean | undefined;
  fileList?: string[];
  placeholder?: string;
  isExpanded?: boolean;
  short?: boolean;
  draggedImageLink?: string;
  onClearDraggedImageLink?(): void;
};

const CommentEditor = ({
  workspaceId,
  className,
  comment,
  content,
  readonly = false,
  isEditing = false,
  autoEditOnFocus = false,
  onConfirm,
  onCancel,
  onBeingEdited,
  resetOnConfirm,
  fileList,
  placeholder: unfocusedPlaceholder,
  isExpanded: defaultExpandedState = false,
  short = false,
  draggedImageLink,
  onClearDraggedImageLink,
}: CommentEditorProps) => {
  const [isFocused, setIsFocused] = useState(false);
  const [isExpanded, setIsExpanded] = useState(defaultExpandedState);
  const [attachmentList, setAttachmentList] = useState<string[]>([]);
  const [publishAttachmentsToBlock, setPublishAttachmentsToBlock] = useState(true);
  const [isAddImagePopoverOpen, setIsAddImagePopoverOpen] = useState(false);
  const [workspace, setWorkspace] = useState<IWorkspace | null>();
  const [commentParent, setCommentParent] = useState<CommentParent>();
  const [isSendingDisabled, setIsSendingDisabled] = useState(false);

  const { activeBlock } = appStore.env;
  const { ui: blockUi } = activeBlock || {};
  const placeholder = !readonly && isFocused ? `Press ${Keys.mod} + Enter to save` : unfocusedPlaceholder;
  const editable = !readonly && ((autoEditOnFocus && isFocused) || isEditing);

  const ref = useRef<HTMLDivElement>(null);
  const linkRef = useRef<HTMLInputElement>(null);
  const linkDialog = useLinkDialog();

  useEffect(() => {
    if (editable) {
      setIsFocused(true);
    }
  }, [editable]);

  useEffect(() => {
    if (isAddImagePopoverOpen) {
      blockUi?.setBlockViewFileDropZoneEnabled(false);
    }

    return () => {
      blockUi?.setBlockViewFileDropZoneEnabled(true);
    };
  }, [blockUi, isAddImagePopoverOpen]);

  useEffect(() => {
    if (workspaceId) {
      getWorkspaceById(workspaceId).then(setWorkspace);
    }
  }, [workspaceId]);

  useEffect(() => {
    if (!commentParent) {
      comment?.getThreadListParent().then(parent => setCommentParent(parent));
      return;
    }
    if (!comment) {
      setCommentParent(appStore.env.activeBlock);
    }
    if (comment) {
      setPublishAttachmentsToBlock(comment.publishAttachmentsToBlock);
    }
  }, [comment, commentParent]);

  const resetEditor = (editor: Editor) => {
    if (!comment) {
      editor?.commands.clearContent();
      setAttachmentList([]);
    }
    setIsFocused(false);
    onCancel?.();
  };

  const getFilteredHTML = (editor: Editor | null) => {
    return editor?.getHTML().replace(/<p><\/p>$/, "");
  };

  const handleConfirm = (editor: Editor | null) => {
    const filteredHTML = getFilteredHTML(editor);
    if (editor && filteredHTML) {
      // Remove empty paragraph if it is the last item in the document.
      onConfirm?.(filteredHTML, attachmentList, publishAttachmentsToBlock);
      if (resetOnConfirm) {
        resetEditor(editor);
      }
      editor.commands.blur();
      setIsExpanded(defaultExpandedState);
      setIsFocused(false);
    }
  };

  const editor = useEditor(
    {
      extensions: [
        StarterKit,
        Image.configure({
          HTMLAttributes: {
            class: "comment-image",
          },
        }),
        ImageAsset,
        Placeholder.configure({
          showOnlyWhenEditable: false,
          placeholder,
        }),
        Extension.create({
          addKeyboardShortcuts: () => ({
            Escape: ({ editor }) => {
              resetEditor(editor);
              return true;
            },
            "Mod-Enter": ({ editor }) => {
              if (editor.isEmpty) {
                return true;
              } else {
                handleConfirm(editor as Editor);
                return true;
              }
            },
          }),
        }),
        ...(workspace ? getMentionExtensions({ workspace }) : []),
        Underline,
        Link.configure({ openOnClick: false }),
      ],
      content,
      editable,
    },
    [workspace]
  );

  useEffect(() => {
    if (!editor) return;

    const placeholderExtension = editor.extensionManager.extensions.find(extension => extension.name === Placeholder.name);
    if (placeholderExtension) {
      placeholderExtension.options.placeholder = placeholder;
      editor.view.dispatch(editor.state.tr);
    }
  }, [editor, placeholder]);

  useEffect(() => {
    editor?.setEditable(editable);
  }, [editor, editable]);

  useEffect(() => {
    if (editor) {
      onBeingEdited?.(editor.isEditable);
    }
  }, [editor, editor?.isEditable, onBeingEdited]);

  // Check and populate attachment list
  useEffect(() => {
    if (fileList?.length) {
      setAttachmentList(fileList);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // Reason for using setTimeout for this is to take the editor command
    // outside the lifecycle method (useEffect). For further details
    // see: https://github.com/ueberdosis/tiptap/issues/3764
    setTimeout(() => {
      editor?.commands.setContent(content);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [content]);

  const insertImage = useCallback(
    (url: string) => {
      editor?.chain().focus().insertContent(getImageAssetComponent(url, "comment-image")).run();
      editor?.commands.enter();
      editor?.commands.focus("end");
    },
    [editor]
  );

  const replaceImageUrl = (oldUrl: string, newUrl: string) => {
    const currentContent = editor?.getHTML();
    if (currentContent) {
      const updatedContent = currentContent.replace(oldUrl, newUrl);
      editor?.commands.setContent(updatedContent, false);
    }
  };

  useEffect(() => {
    if (draggedImageLink) {
      insertImage(draggedImageLink);
      onClearDraggedImageLink?.();
    }
  }, [draggedImageLink, insertImage, onClearDraggedImageLink]);

  // Check if an image or an url is pasted
  useEffect(() => {
    const handlePaste = (event: ClipboardEvent) => {
      const item = event.clipboardData?.items[0];
      if (!item) return;
      if (item.type.includes("image")) {
        // if an image is copied
        const blob = item.getAsFile();
        if (blob) {
          // Show the pasted image without waiting for the upload to complete
          setIsSendingDisabled(true);
          const tempUrl = URL.createObjectURL(blob);
          insertImage(tempUrl);

          let url: string;
          rollupClient.attachments
            .uploadFile({ label: "Block image" }, blob)
            .then((r: any) => {
              url = rollupClient.attachments.getFileLink(r?.data.id, r?.data.workspaceId);
              // Replace the pasted image url with the uploaded image url
              replaceImageUrl(tempUrl, url);
              setIsSendingDisabled(false);
            })
            .catch(e => {
              showApiErrorToast("Unable to upload image", e);
              replaceImageUrl(tempUrl, "");
            });
        }
      } else if (item.type === "text/plain") {
        // if a possible url is copied
        const pastedText = event.clipboardData.getData("text/plain");
        const url = pastedText.match(/(https?:\/\/[^\s]+)/g)?.[0];
        if (!url) return;

        if (/\.(gif|jpe?g|png)$/i.test(url)) {
          insertImage(url);
        } else {
          editor
            ?.chain()
            .focus()
            .extendMarkRange("link")
            .setLink({ href: url, target: "_blank" })
            .command(({ tr }) => {
              tr.insertText(url);
              return true;
            })
            .run();
        }
      }
    };

    ref.current?.addEventListener("paste", handlePaste);
    const currentRef = ref.current;

    return () => {
      currentRef?.removeEventListener("paste", handlePaste);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref.current]);

  // Click outside logic
  useOnClickOutside(ref, () => {
    const filteredHTML = getFilteredHTML(editor);
    if (!filteredHTML) {
      setIsFocused(false);
      onCancel?.();
    }
  });

  // Logic for expanding the editor
  useEffect(() => {
    if (editable) {
      setIsExpanded(true);
      setIsFocused(true);
      // Reason for using setTimeout for this is to take the editor command
      // outside of the lifecycle method (useEffect). For further details
      // see: https://github.com/ueberdosis/tiptap/issues/3764
      setTimeout(() => {
        editor?.commands.focus("end");
      });
    } else {
      setIsExpanded(defaultExpandedState);
    }
  }, [defaultExpandedState, editor, editable]);

  if (!editor) {
    return null;
  }

  const addAttachment = (attachmentId: string) => {
    setAttachmentList(prev => [...prev, attachmentId]);
  };

  const removeAttachmentFromList = (attachmentId: string) => {
    setAttachmentList(prev => prev.filter(id => id !== attachmentId));
  };

  const handleAddLinkSubmit = (event: FormEvent<HTMLFormElement>, editor: Editor) => {
    event.preventDefault();
    editor
      .chain()
      .focus()
      .extendMarkRange("link")
      .setLink({ href: linkDialog.link, target: "_blank" })
      .command(({ tr }) => {
        tr.insertText(linkDialog.text);
        return true;
      })
      .run();
    editor.chain().focus();
    linkDialog.toggleOpen();
  };

  const handleAddImageSubmit = (imageUrl: string) => {
    insertImage(imageUrl);
    setIsAddImagePopoverOpen(false);
  };

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleLinkUnset = (editor: Editor) => {
    editor.chain().focus().unsetLink().run();
    linkDialog.toggleOpen();
  };

  const getSelectedText = (editor: Editor) => {
    const { from, to, empty } = editor.state.selection;
    if (empty) {
      return null;
    }
    return editor.state.doc.textBetween(from, to, " ");
  };

  const createFileUploadStore = (files: FileList) => {
    appStore.orgModel.uploads.addNewFileUpload({ files: files, onUpload: addAttachment, workspaceId: workspace?.id });
  };

  const handleLinkClick = () => {
    // Leaving this handler untouched as it is.
    if (!editor) return;
    linkDialog.toggleOpen();
    const isActive = editor?.isActive("link");
    const text = getSelectedText(editor);

    // Something is stealing the focus so i had to force it here.
    // Adding "autoFocus" to the "<InputGroup />" didn't work.
    setTimeout(() => {
      linkRef.current?.focus();
    }, 0);

    if (text) {
      linkDialog.setText(text);
      return;
    }

    if (isActive) {
      editor?.commands.extendMarkRange("link");
      const href = editor?.getAttributes("link").href;
      linkDialog.setLink(href);
      linkDialog.setText(getSelectedText(editor) || "");
      return;
    }
  };

  const editorContent = () => (
    <>
      <EditorContent editor={editor} e2eIdentifiers="comment-editor" />
      {editor?.isEditable && attachmentList.length > 0 && (
        <CommentAttachmentsList
          workspaceId={workspace?.id}
          attachmentIds={attachmentList}
          readonly={false}
          allowDeletion
          onDelete={removeAttachmentFromList}
          showPublishCheckbox
          publishAttachmentsToBlock={publishAttachmentsToBlock}
          onPublishChange={setPublishAttachmentsToBlock}
        />
      )}
    </>
  );

  const flexibleButtons = () => (
    <>
      <CommentEditorPropertyButton name={EditorProperty.Link} editor={editor} onClick={handleLinkClick} />
      <UploadFileButton
        text=""
        icon="paperclip"
        active={!!attachmentList.length}
        onChange={createFileUploadStore}
        e2eIdentifiers="upload-comment-attachment"
      />
      <Popover
        isOpen={isAddImagePopoverOpen}
        onInteraction={setIsAddImagePopoverOpen}
        content={<AttachmentPopup workspaceId={workspaceId} onLinkChange={url => handleAddImageSubmit(url)} />}
        position={PopoverPosition.BOTTOM}
      >
        <CommentEditorPropertyButton name={EditorProperty.Image} editor={editor} />
      </Popover>
      <NavbarDivider />
      <CommentEditorPropertyButton name={EditorProperty.BulletList} editor={editor} />
      <CommentEditorPropertyButton name={EditorProperty.OrderedList} editor={editor} />
      <NavbarDivider />
      <CommentEditorPropertyButton name={EditorProperty.Citation} editor={editor} />
      <NavbarDivider />
      <CommentEditorPropertyButton name={EditorProperty.Code} editor={editor} />
      <CommentEditorPropertyButton name={EditorProperty.CodeBlock} editor={editor} />
    </>
  );

  const moreButtons = () => (
    <>
      <Popover
        popoverClassName={Classes.POPOVER_DISMISS}
        position={Position.TOP}
        content={<div className="comment-editor--toolbar--editing-buttons">{flexibleButtons()}</div>}
      >
        <Button icon="more" minimal e2eIdentifiers="more-editing-buttons" />
      </Popover>
    </>
  );

  const toolbar = () => (
    <div className="comment-editor--toolbar">
      <div className="comment-editor--toolbar--editing-buttons">
        <CommentEditorPropertyButton name={EditorProperty.Bold} editor={editor} />
        <CommentEditorPropertyButton name={EditorProperty.Italic} editor={editor} />
        <CommentEditorPropertyButton name={EditorProperty.Underline} editor={editor} />
        <CommentEditorPropertyButton name={EditorProperty.Strike} editor={editor} />
        <NavbarDivider />

        {short ? moreButtons() : flexibleButtons()}
      </div>
      <Tooltip content={`Press ${Keys.mod} + Enter to save`}>
        <Button
          icon="send-message"
          disabled={isEmpty(editor) || isSendingDisabled}
          minimal
          intent={editor?.isEditable ? Intent.PRIMARY : Intent.NONE}
          onClick={() => handleConfirm(editor)}
          e2eIdentifiers="comment-editor-send"
        />
      </Tooltip>
    </div>
  );

  if (readonly) {
    return (
      <div ref={ref} className={classNames("comment-editor", className)}>
        {editorContent()}
      </div>
    );
  }

  return (
    <>
      {/* Add link dialog */}
      <DialogLegacy
        shouldReturnFocusOnClose
        isOpen={linkDialog.open}
        onClose={() => {
          linkDialog.toggleOpen();
          editor?.chain().focus();
        }}
        title="Add link"
        isCloseButtonShown
      >
        <form className="pt-6 px-6 space-y-2" onSubmit={event => handleAddLinkSubmit(event, editor)}>
          <InputGroup
            large
            required
            id="link-label-input"
            placeholder="Text"
            onChange={linkDialog.handleTextChange}
            value={linkDialog.text}
          />
          <InputGroup
            inputRef={linkRef}
            autoFocus
            large
            required
            id="link-label-input"
            placeholder="example.com"
            onChange={linkDialog.handleLinkChange}
            value={linkDialog.link}
          />
          <div className="flex items-center justify-end gap-3 pt-3">
            <Button
              text="Unset"
              intent="none"
              minimal
              onClick={() => handleLinkUnset(editor)}
              e2eIdentifiers={["comment-editor", "unset"]}
            />
            <Button type="submit" text="Save" intent="primary" minimal e2eIdentifiers={["comment-editor", "submit"]} />
          </div>
        </form>
        <div></div>
      </DialogLegacy>

      <div
        ref={ref}
        onClick={handleFocus}
        className={classNames("comment-editor", className, {
          ["comment-editor--editable"]: editor?.isEditable,
          ["comment-editor--readonly"]: !editor?.isEditable && unfocusedPlaceholder,
        })}
      >
        {editorContent()}
        {isExpanded && toolbar()}
      </div>
    </>
  );
};

export default observer(CommentEditor);
