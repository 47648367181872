import { useEffect, useState } from "react";
import { useSearchParamArray } from "@hooks/useSearchParam/useSearchParam";
import { observer } from "mobx-react";

import { WorkspaceSelector } from "@components/WorkspaceSelector";
import appStore from "@store/AppStore";

const RollupEventsWorkspaceSelector = () => {
  const [selectedWorkspace, setSelectedWorkspace] = useState<{ id: string; label: string }>({
    id: "",
    label: "",
  });

  const [searchParams, _, setParamValues] = useSearchParamArray(["ws", "msg"]);
  const { workspacesList } = appStore.orgModel;

  useEffect(() => {
    const getWsLabelAndLoadTemp = async () => {
      if (searchParams.ws) {
        const wsLabel = await appStore.orgModel.getWorkspaceLabelById(searchParams.ws);
        if (wsLabel) {
          setSelectedWorkspace({
            id: searchParams.ws,
            label: wsLabel,
          });
        } else {
          // this is not a valid workspace
          setParamValues({ ws: "", msg: "" });
        }
      } else {
        setSelectedWorkspace({
          id: "",
          label: "All workspaces",
        });
      }
    };
    getWsLabelAndLoadTemp();
  }, [searchParams.ws, setParamValues, workspacesList]);

  const handleWorkspaceChange = async (workspaceId: string) => {
    if (workspaceId !== searchParams.ws) {
      setParamValues({ ws: workspaceId, msg: "" });
      appStore.env.inboxSettings.setSelectedWorkspaceId(workspaceId);
    }
  };

  return <WorkspaceSelector showAllWorkspacesOption onChange={handleWorkspaceChange} selectedWorkspaceId={selectedWorkspace.id} />;
};

export default observer(RollupEventsWorkspaceSelector);
