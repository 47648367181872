import ChildBlockList from "@components/Modeling/ModelingFrame/ModelBlock/ChildBlockList";
import { IBlock } from "@store/BlockStore";

import "./ChildBlocksTabPanel.scss";

type ChildBlocksTabPanelProps = {
  block: IBlock;
};

function ChildBlocksTabPanel({ block }: ChildBlocksTabPanelProps) {
  return (
    <div className="child-blocks-tab-panel">
      <ChildBlockList block={block} />
    </div>
  );
}

export type { ChildBlocksTabPanelProps };
export default ChildBlocksTabPanel;
