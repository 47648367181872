import { Handle, NodeProps, Position } from "reactflow";
import { Tooltip } from "@blueprintjs/core";
import { observer } from "mobx-react";

import { MultiplicityTag } from "@components/MultiplicityTag";
import appStore from "@store/AppStore";
import { IBlock } from "@store/BlockStore";

import { CustomNodeType } from "../types";

import "./BlockFlowNode.scss";

/** Type defs. */
type BlockFlowNodeData = {
  block: IBlock;
};

type BlockFlowNodeProps = NodeProps<BlockFlowNodeData>;

/** Main function. */
function BlockFlowNode(props: BlockFlowNodeProps) {
  const {
    targetPosition,
    sourcePosition,
    data: { block },
  } = props;

  const type =
    block === appStore.workspaceModel?.rootBlock
      ? CustomNodeType.INPUT
      : block.children?.length
        ? CustomNodeType.DEFAULT
        : CustomNodeType.OUTPUT;

  return (
    <div className="component--block-flow-node">
      {type !== CustomNodeType.INPUT && <Handle type="target" position={targetPosition || Position.Top} className="invisible" />}
      <Tooltip targetTagName="div" content={block.label} placement="top">
        <div className="content flex">
          <div className="truncate">{block.label}</div>
          {!!block.multiplicity && (
            <span className="ml-1">
              <MultiplicityTag multiplicity={block.multiplicity} />
            </span>
          )}
        </div>
      </Tooltip>
      {type !== CustomNodeType.OUTPUT && <Handle type="source" position={sourcePosition || Position.Bottom} className="invisible" />}
    </div>
  );
}

/** Exports. */
export type { BlockFlowNodeData, BlockFlowNodeProps };
export default observer(BlockFlowNode);
