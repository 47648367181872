import { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { InputGroup, Intent, Menu } from "@blueprintjs/core";
import { useWorkspace } from "@hooks/useWorkspace";
import { observer } from "mobx-react";

import { Button } from "@components/Button";
import { BlockIcon, CustomIcon, ModuleMonochromeIcon } from "@components/CustomIcon";
import { Icon } from "@components/Icon";
import { MenuItem } from "@components/MenuItem";
import { Popover } from "@components/Popover";
import appStore from "@store/AppStore";
import { BlockType, IBlock } from "@store/BlockStore";

import "./BomTableCreateNewPopover.scss";

interface IBomTableCreateNewPopoverProps {
  minimal?: boolean;
}

const BomTableCreateNewPopover = (props: IBomTableCreateNewPopoverProps) => {
  const { minimal } = props;
  const [searchValue, setSearchValue] = useState("");
  const navigate = useNavigate();
  const workspace = useWorkspace();
  const { blocks } = appStore.workspaceModel ?? {};
  const productBlocks = useMemo(() => blocks?.filter(block => block.type.includes(BlockType.Product)) || [], [blocks]);
  const filteredBlocks = searchValue
    ? productBlocks.filter(block => block.label.toLowerCase().includes(searchValue.toLowerCase()))
    : productBlocks;

  const handleNewBomTable = () => {
    appStore.ui.showEditBomTableDialog();
  };

  const handleAddNew = async (title: string, selectedBlocks: IBlock[]) => {
    const newBomTableId = await workspace.createBomTable(
      title,
      selectedBlocks.map(b => b.id)
    );
    if (newBomTableId) {
      navigate(`/workspaces/${appStore.workspaceModel?.id}/pdm/bom/${newBomTableId}`);
      appStore.env.setActiveBomTable(newBomTableId);
      setSearchValue("");
    }
  };

  return (
    <Popover
      className="w-full"
      placement="bottom"
      content={
        <Menu>
          <MenuItem
            icon={<CustomIcon icon={ModuleMonochromeIcon.Bom} />}
            onClick={handleNewBomTable}
            text="Create from scratch"
            e2eIdentifiers="create-from-scratch"
          />
          <MenuItem icon={<CustomIcon icon={BlockIcon.Part} />} text="Create from product" e2eIdentifiers="create-from-product">
            <div className="mb-1">Search</div>
            <InputGroup
              className="mb-2"
              rightElement={
                searchValue ? <Button minimal icon="cross" onClick={() => setSearchValue("")} e2eIdentifiers="clear-search" /> : undefined
              }
              leftIcon="search"
              value={searchValue}
              onChange={e => setSearchValue(e.target.value)}
            />
            {filteredBlocks.map(b => (
              <MenuItem
                icon={<Icon icon={b.iconView} />}
                key={b.id}
                onClick={() => handleAddNew(`${b.label} - BOM`, [b])}
                text={b.label}
                e2eIdentifiers={[b.label]}
              />
            ))}
          </MenuItem>
        </Menu>
      }
    >
      <Button
        className="bom-table-create-new-popover--button"
        fill
        intent={Intent.PRIMARY}
        icon="small-plus"
        text={minimal ? "" : "New BOM"}
        e2eIdentifiers="create-new-bom"
      />
    </Popover>
  );
};

export default observer(BomTableCreateNewPopover);
