import { ReactElement } from "react";
import { AnalysisOutput, DataLink, DataSinkEntry, PropertyInstance } from "@rollup-io/engineering";

export enum ExpressionReferenceType {
  AnalysisOutput = "AnalysisOutput",
  DataLink = "DataLink",
  DataSinkEntry = "DataSinkEntry",
  PropertyInstance = "PropertyInstance",
}

export interface IPropertyExpressionReference {
  type: ExpressionReferenceType.PropertyInstance;
  item: PropertyInstance;
}

export interface IDataLinkExpressionReference {
  type: ExpressionReferenceType.DataLink;
  item: DataLink;
}

export interface IDataSinkEntryExpressionReference {
  type: ExpressionReferenceType.DataSinkEntry;
  item: DataSinkEntry;
}

export interface IAnalysisOutputExpressionReference {
  type: ExpressionReferenceType.AnalysisOutput;
  item: AnalysisOutput;
}

export type IExpressionReference =
  | IAnalysisOutputExpressionReference
  | IDataLinkExpressionReference
  | IDataSinkEntryExpressionReference
  | IPropertyExpressionReference;

export interface IExpressionReferenceData {
  type: ExpressionReferenceType;
  label: string;
  value: string;
  icon?: ReactElement;
  onClick?(id: string): void;
}
