export enum ERequirementsTableColumn {
  ACTIONS = "Actions",
  ID = "ID",
  LEVEL = "Level",
  TITLE = "Title",
  FUNCTIONAL_TYPE = "Type",
  COMMENTS = "Comments",
  DESCRIPTION = "Description",
  RATIONALE = "Rationale",
  LINKED_BLOCK = "Linked Block",
  LINKED_PROPERTY = "Linked Property",
  METHOD = "Verif. Method",
  VERIFICATION = "Verification",
  SUCCESS_CRITERIA = "Success Criteria",
  VERIFICATION_STATUS = "Verif. Status",
  COMMENT = "Comment",
  NOTES = "Notes",
}

export const HEADER_ROW_HEIGHT = 32;
export const RATIONALE_PLACEHOLDER = "Write rationale";
export const CELL_EDIT_FOCUS = "ag-edit-focus";

export const defaultColDef = {
  editable: false,
  autoHeight: true,
  suppressMovable: false,
  singleClickEdit: true,
  lockPinned: true,
  resizable: true,
};
