import { useEffect, useState } from "react";
import { Menu, MenuDivider } from "@blueprintjs/core";
import { observer } from "mobx-react";

import { MenuItem } from "@components/MenuItem";
import { MenuItemDelete } from "@components/MenuItems";
import { Switch } from "@components/Switch";

import { TH_NODE } from "./constants";
import { TNavProps } from "./types";

const VerticalNav = (props: TNavProps) => {
  const { editor, menuRef, onUpdate } = props;
  const [isTableHeader, setIsTableHeader] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setIsTableHeader(!!editor?.state.selection.ranges.some(r => r.$from.node().type.name === TH_NODE));
    }, 0);
  }, [editor]);

  const handleDelete = () => {
    editor?.chain().focus().deleteRow().setTextSelection(0).run();
    onUpdate();
  };

  const insertAbove = () => {
    editor?.chain().focus().addRowBefore().setTextSelection(0).run();
    onUpdate();
  };

  const insertBelow = () => {
    editor?.chain().focus().addRowAfter().setTextSelection(0).run();
    onUpdate();
  };

  const toggleHeader = () => {
    editor?.chain().focus().toggleHeaderCell().setTextSelection(0).run();
    menuRef.current?.setState({ isOpen: false });
    onUpdate();
  };

  const handleClear = () => {
    editor?.commands.deleteSelection();
    onUpdate();
  };

  const handleDuplicate = () => {
    const selectedContent = editor?.state.selection.content().content.toJSON();
    editor?.commands.insertContentAt(editor?.state.selection.$head.pos, selectedContent);
    onUpdate();
  };

  return (
    <Menu>
      <Switch
        className="table-button--switch"
        checked={isTableHeader}
        label="Header Row"
        onChange={toggleHeader}
        alignIndicator="right"
        e2eIdentifiers="header-row"
      />
      <MenuDivider />
      <MenuItem icon="arrow-up" text="Insert Above" onClick={insertAbove} e2eIdentifiers="inset-above" />
      <MenuItem icon="arrow-down" text="Insert Below" onClick={insertBelow} e2eIdentifiers="inset-belo" />
      <MenuItem icon="duplicate" text="Duplicate" onClick={handleDuplicate} e2eIdentifiers="duplicate" />
      <MenuItem icon="cross" text="Clear" onClick={handleClear} e2eIdentifiers="clear" />
      <MenuItemDelete onDelete={handleDelete} />
    </Menu>
  );
};

export default observer(VerticalNav);
