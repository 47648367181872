import { useEffect, useRef } from "react";
import { Divider, Intent } from "@blueprintjs/core";
import { useBool } from "@hooks/useBool/useBool";
import { useUpdateEditorContent } from "@hooks/useUpdateEditorContent";
import Link from "@tiptap/extension-link";
import { Placeholder } from "@tiptap/extension-placeholder";
import { Editor, EditorOptions, Extension, useEditor } from "@tiptap/react";
import { StarterKit } from "@tiptap/starter-kit";
import { observer } from "mobx-react";
import { useOnClickOutside } from "usehooks-ts";

import { Button } from "@components/Button";
import { EditorContent } from "@components/EditorContent";
import { getMentionExtensions } from "@components/Reports/Editor/Extentions/Mention/MentionSuggestion";
import { showApiErrorToast } from "@components/UiLayers/toaster";
import { Keys } from "@constants/keys";
import appStore from "@store/AppStore";
import { rollupClient } from "src/core/api";
import { Text, TextVariant } from "src/ui/Text";

import "./SimpleCommentEditor.scss";

type SimpleCommentEditorProps = {
  content: EditorOptions["content"];
  editable: EditorOptions["editable"];
  showUnfocusedPlaceholder?: boolean;
  showFocusedPlaceholder?: boolean;
  resetOnConfirm?: boolean | undefined;
  hideCloseButton?: boolean;
  hideToolbar?: boolean;
  disableAutoFocus?: boolean;
  onConfirm?: (value: string) => void;
  onCancel?: () => void;
  onDraftComment?: (isDraftCommentPresent: boolean) => void;
  onChange?: (value: string) => void;
  onLeave?: () => void;
};

function SimpleCommentEditor(props: SimpleCommentEditorProps) {
  const { content, editable, showUnfocusedPlaceholder, showFocusedPlaceholder, resetOnConfirm } = props;
  const { hideCloseButton, hideToolbar, disableAutoFocus, onConfirm, onCancel } = props;
  const { onDraftComment, onChange, onLeave } = props;
  const [editorActive, { set: openEditor, unset: closeEditor }] = useBool(false);

  const ref = useRef<HTMLDivElement>(null);
  // Click outside logic
  useOnClickOutside(ref, () => {
    onLeave?.();
  });

  const getFilteredHTML = (editor: Editor | null): string => {
    // Remove empty paragraph if it is the last item in the document.
    return editor?.getHTML().replace(/<p><\/p>$/, "") || "";
  };

  const handleConfirm = (editor: Editor | null) => {
    if (editor) {
      const filteredHTML = getFilteredHTML(editor);
      onConfirm?.(filteredHTML);
      onDraftComment?.(false);
      editor.commands.blur();
      if (resetOnConfirm) {
        editor.commands.clearContent();
      }
    }
  };

  const editor = useEditor(
    {
      extensions: [
        StarterKit,
        Placeholder.configure({
          showOnlyWhenEditable: true,
          placeholder: showFocusedPlaceholder ? `Press ${Keys.mod} + Enter to save.` : undefined,
        }),
        Extension.create({
          addKeyboardShortcuts: () => ({
            Escape: ({ editor }) => {
              editor.commands.clearContent();
              if (showUnfocusedPlaceholder) {
                closeEditor();
              }
              return true;
            },
            "Mod-Enter": ({ editor }) => {
              if (editor.isEmpty) {
                return true;
              } else {
                handleConfirm(editor as Editor);
                return true;
              }
            },
          }),
        }),
        Link.configure({ openOnClick: false }),
        ...(appStore.workspaceModel ? getMentionExtensions({ workspace: appStore.workspaceModel }) : []),
      ],
      content,
      editable,
      onUpdate: () => {
        if (getFilteredHTML(editor) !== "") {
          onDraftComment?.(true);
        } else {
          onDraftComment?.(false);
        }
        onChange?.(getFilteredHTML(editor));
      },
    },
    [editable]
  );

  useUpdateEditorContent({ content, editor });

  const handleFocus = (editor: Editor | null) => {
    editor?.commands.focus();
  };

  useEffect(() => {
    // autoFocus prop does't work as intended
    if (editor && !disableAutoFocus) {
      handleFocus(editor);
    }
  }, [disableAutoFocus, editor]);

  // Check if an image or an url is pasted
  useEffect(() => {
    const handlePaste = (event: ClipboardEvent) => {
      const item = event.clipboardData?.items[0];
      if (!item) return;

      if (item.type.includes("image")) {
        // if an image is copied
        const blob = item.getAsFile();

        if (blob) {
          let url: string;
          rollupClient.attachments
            .uploadFile({ label: "Block image" }, blob)
            .then((r: any) => {
              url = rollupClient.attachments.getFileLink(r?.data.id);
              editor?.chain().setImage({ src: url }).run();
            })
            .catch(e => showApiErrorToast("Unable to upload image", e));
        }
      } else if (item.type === "text/plain") {
        // if a possible url is copied
        const pastedText = event.clipboardData.getData("text/plain");
        const url = pastedText.match(/(https?:\/\/[^\s]+)/g)?.[0];
        if (!url) return;

        if (/\.(gif|jpe?g|png)$/i.test(url)) {
          editor?.chain().setImage({ src: url }).run();
        } else {
          editor
            ?.chain()
            .focus()
            .extendMarkRange("link")
            .setLink({ href: url, target: "_blank" })
            .command(({ tr }) => {
              tr.insertText(url);
              return true;
            })
            .run();
        }
      }
    };

    ref.current?.addEventListener("paste", handlePaste);
    const currentRef = ref.current;

    return () => {
      currentRef?.removeEventListener("paste", handlePaste);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref.current]);

  if (!editor) {
    return null;
  }

  if (!editable) {
    return (
      <div className="simple-comment-editor--editor">
        <EditorContent editor={editor} className="simple-comment-editor" e2eIdentifiers="simple-comment-editor" />
      </div>
    );
  }

  const handleCancelClick = () => {
    editor.commands.clearContent();
    closeEditor();
    onCancel?.();
  };

  const renderPlaceholder = () => {
    return (
      <div className="simple-comment-editor--placeholder">
        <Text variant={TextVariant.Body} onClick={openEditor}>
          Add your comment...
        </Text>
      </div>
    );
  };

  const renderEditor = () => (
    <div ref={ref} className="simple-comment-editor--wrapper">
      <div className="simple-comment-editor--editing">
        <div className="simple-comment-editor--editor">
          <EditorContent editor={editor} e2eIdentifiers="simple-comment-editor" />
        </div>
        {!hideCloseButton && <Button minimal icon="cross" onClick={handleCancelClick} e2eIdentifiers="simple-comment-editor-cancel" />}
      </div>
      {!hideToolbar && (
        <>
          <Divider />
          <div className="simple-comment-editor--toolbar">
            <Button
              minimal
              intent={Intent.PRIMARY}
              onClick={() => handleConfirm(editor)}
              icon="send-message"
              e2eIdentifiers="simple-comment-editor-send"
            />
          </div>
        </>
      )}
    </div>
  );

  return (
    <div onClick={() => handleFocus(editor)} className="simple-comment-editor">
      {!showUnfocusedPlaceholder || editorActive ? renderEditor() : renderPlaceholder()}
    </div>
  );
}

export default observer(SimpleCommentEditor);
