import { useState } from "react";
import { Divider, Intent } from "@blueprintjs/core";
import { observer } from "mobx-react";

import { Button } from "@components/Button";
import appStore from "@store/AppStore";
import { Text, TextVariant } from "src/ui/Text";

import PasswordConfigs from "./PasswordConfigs";
import PrimaryConfigs from "./PrimaryConfigs";
import ProfileConfig from "./ProfileConfig";
import UserDetailsConfig from "./UserDetailsConfig";

import "./SettingsAccount.scss";

const SettingsAccount = () => {
  const [name, setName] = useState(appStore.userModel?.name ?? "");
  const [jobTitle, setJobTitle] = useState(appStore.userModel?.jobTitle ?? "");
  const [department, setDepartment] = useState(appStore.userModel?.department ?? "");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmitClicked = () => {
    if (name.length > 2) {
      setIsSubmitting(true);
      appStore.pushProfile({ name, jobTitle, department }).finally(() => setIsSubmitting(false));
    }
  };

  return (
    <div className="settings-layout--content">
      <div className="settings-layout--header">
        <Text variant={TextVariant.H1}>Account settings</Text>
      </div>
      <ProfileConfig />
      <PrimaryConfigs preferredName={name} setPreferredName={setName} />
      <Divider />
      <UserDetailsConfig department={department} jobTitle={jobTitle} setJobTitle={setJobTitle} setDepartment={setDepartment} />
      <Button
        text="Update"
        large
        className="settings-layout--save"
        intent={Intent.PRIMARY}
        onClick={handleSubmitClicked}
        loading={isSubmitting}
        e2eIdentifiers={["settings-account", "submit"]}
      />
      <PasswordConfigs />
    </div>
  );
};

/** Exports. */
export default observer(SettingsAccount);
