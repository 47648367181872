import Avatar from "@components/Avatar/Avatar";
import { IntegrationCallout } from "@components/IntegrationCallout/IntegrationCallout";
import { Integration } from "@rollup-api/models/integrations";

import "./IntegrationCallout.module.scss";
import styles from "./GithubIntegrationCallout.module.scss";

export const GitHubIntegrationCallout = ({ integration }: { integration: Integration }) => {
  const { metadata } = integration;

  const integrationTargetElement = (
    <>
      <Avatar source={metadata?.avatarUrl} size={20} shape="square" />
      <a className={styles.githubIntegrationCallout} target="_blank" href={`https://github.com/${metadata.name}`} rel="noreferrer">
        {metadata.name}
      </a>
    </>
  );

  return <IntegrationCallout integration={integration} integrationTargetElement={integrationTargetElement} />;
};
