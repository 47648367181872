import { observer } from "mobx-react";

import { RichTextEditor } from "@components/RichTextEditor";

import { RequirementDocumentBlockRow } from "../RequirementDocumentBlockRow";

import styles from "./RequirementDocumentBlockNoteRow.module.scss";

interface IRequirementDocumentBlockNoteRowProps {
  note: string;
  isAddingNote: boolean;
  onChangeAddingNote(value: boolean): void;
  onChange(value: string): void;
}

const RequirementDocumentBlockNoteRow = (props: IRequirementDocumentBlockNoteRowProps) => {
  const { note, isAddingNote, onChangeAddingNote, onChange } = props;

  const handleCommit = (note: string) => {
    onChange(note);
    onChangeAddingNote(false);
  };

  return (
    <RequirementDocumentBlockRow>
      <RichTextEditor
        className={styles.requirementDocumentBlockNoteRowNote}
        content={note}
        placeholder="Add a note..."
        autoFocus={isAddingNote}
        onCommit={handleCommit}
        onCancel={() => onChangeAddingNote(false)}
      />
    </RequirementDocumentBlockRow>
  );
};

export default observer(RequirementDocumentBlockNoteRow);
