import { Intent } from "@blueprintjs/core";

import { DataSourcesValueResult } from "@rollup-api/api/modeling";
import { isValidNumber, joinStrings } from "@utilities";

export abstract class DataSourceValueUtils {
  public static getHelperText(query: string, isLoading: boolean, queryResult?: DataSourcesValueResult, error?: Error | null) {
    if (!query) {
      return undefined;
    } else if (isLoading) {
      return "Loading...";
    } else if (queryResult?.success) {
      return "Success";
    } else if (queryResult?.message) {
      return queryResult.message;
    } else if (error) {
      return error.message;
    }
    return "Unknown error";
  }

  public static getIntent(queryResult?: DataSourcesValueResult): Intent {
    if (queryResult?.success) {
      return Intent.SUCCESS;
    } else if (queryResult && !queryResult.success) {
      return Intent.DANGER;
    }
    return Intent.NONE;
  }

  public static getColumnIndex(column: string) {
    let total = 0;
    for (const c of column.toUpperCase()) {
      // Multiply the total by 26 [A-Z] to shift the digits to the left
      total *= 26;
      // Add the value of the current character in range [1, 26]
      total += c.charCodeAt(0) - "A".charCodeAt(0) + 1;
    }
    // Indexing starts from 0 instead of 1
    return total - 1;
  }

  public static getColumnLabel(index: number) {
    let label = "";
    while (index >= 0) {
      // Convert the remainder to a character
      label += String.fromCharCode((index % 26) + "A".charCodeAt(0));
      // Move to next character
      index = Math.floor(index / 26) - 1;
    }
    // reverse the string to get the correct label
    return label.split("").reverse().join("");
  }

  public static getDimensions(queryResult?: DataSourcesValueResult) {
    const values = queryResult?.data?.values;
    if (!values) {
      return undefined;
    }

    if (!Array.isArray(values)) {
      return [];
    }

    const dims = [values.length];
    let rowLength = -1;
    for (const row of values) {
      if (Array.isArray(row)) {
        rowLength = Math.max(rowLength, row.length);
      }
    }

    if (rowLength !== -1) {
      dims.push(rowLength);
    }
    return dims;
  }

  public static padData(data: string[][], dimensions: number[]) {
    const [rows, cols] = dimensions;
    if (!rows || !cols) {
      return data;
    }

    const paddedData: string[][] = [];
    for (let i = 0; i < rows; i++) {
      const row = data[i] || [];
      const paddedRow = [...row];
      for (let j = row.length; j < cols; j++) {
        paddedRow.push("");
      }
      paddedData.push(paddedRow);
    }
    return paddedData;
  }

  public static getStartingCell(queryResult?: DataSourcesValueResult) {
    const rangeString = queryResult?.data?.range;
    if (!rangeString) {
      return undefined;
    }

    return rangeString?.split("!")?.[1]?.split(":")?.[0];
  }

  public static getCellLabels(referenceCell: string, rowOffset = 0, colOffset = 0) {
    const cellNameRegex = /(?<refCol>[a-zA-Z]+)(?<refRow>\d+)/gm;
    const match = cellNameRegex.exec(referenceCell);
    if (!match) {
      return undefined;
    }
    const { refRow, refCol } = match.groups || {};

    if (!refRow || !refCol) {
      return undefined;
    }

    const column = DataSourceValueUtils.getColumnLabel(DataSourceValueUtils.getColumnIndex(refCol) + colOffset);
    const row = +refRow + rowOffset;
    return { row, column };
  }

  public static getRangeDimensions(rangeString: string) {
    const rangeRegex = /.*!?(?<colA>[a-zA-Z]{1,2})(?<rowA>\d+):(?<colB>[a-zA-Z]{1,2})(?<rowB>\d+)$/gm;
    const match = rangeRegex.exec(rangeString);
    if (!match?.groups) {
      return undefined;
    }
    let { colA, rowA, colB, rowB } = match.groups;

    if (!colA || !rowA || !colB || !rowB) {
      return undefined;
    }

    let numColumns = DataSourceValueUtils.getColumnIndex(colB) - DataSourceValueUtils.getColumnIndex(colA) + 1;

    if (numColumns <= 0) {
      // Swap the values if the end row is before the start column
      const temp = colA;
      colA = colB;
      colB = temp;
      numColumns = DataSourceValueUtils.getColumnIndex(colB) - DataSourceValueUtils.getColumnIndex(colA) + 1;
    }

    const numRows = +rowB - +rowA + 1;
    if (numRows <= 0) {
      // Swap the values if the end row is before the start column
      const temp = rowA;
      rowB = rowA;
      rowA = temp;
      numColumns = +rowB - +rowA + 1;
    }

    const startCell = { row: +rowA, column: colA };
    const endCell = { row: +rowB, column: colA };

    return { startCell, endCell, numRows, numColumns };
  }

  public static parseQueryResult(queryResult?: DataSourcesValueResult): string | number {
    const data = queryResult?.data?.values;
    if (!data) {
      return "";
    }

    if (Array.isArray(data)) {
      if (!data.length) {
        return "";
      }
      const isNestedArray = Array.isArray(data[0]);
      if (!isNestedArray) {
        return joinStrings(data as string[], " ");
      }
      return joinStrings(data[0], " ");
    }
    return isValidNumber(data) ? Number(data) : data;
  }
}
