import { useState } from "react";
import { InputGroup, Menu, MenuDivider } from "@blueprintjs/core";
import { useWorkspace } from "@hooks/useWorkspace";
import { observer } from "mobx-react";

import { Button } from "@components/Button";
import { MenuItem } from "@components/MenuItem";
import { BomMetaColumn } from "@rollup-api/models";
import appStore from "@store/AppStore";
import { getBomColumnLabelFromMetaColumn, getBomMetaColumns } from "@utilities";

import "./CreateNewColumnNav.scss";

const CreateNewColumnNav = () => {
  const workspace = useWorkspace();
  const table = workspace.bomTablesMap.get(`${appStore.env.activeBomTableId}`);
  const [searchPropertyValue, setSearchPropertyValue] = useState("");
  const [searchStatusValue, setSearchStatusValue] = useState("");

  const propertyDefinitions =
    workspace.propertyDefinitions.filter(d => {
      return (
        !table?.propertyColumnIds.includes(d.id) &&
        (searchPropertyValue ? d.label.toLowerCase().includes(searchPropertyValue.toLowerCase()) : true)
      );
    }) || [];

  const statusDefinitions =
    workspace.statusDefinitions.filter(d => {
      return (
        !table?.statusColumnIds.includes(d.id) &&
        (searchStatusValue ? d.label.toLowerCase().includes(searchStatusValue.toLowerCase()) : true)
      );
    }) || [];

  const handleAddPropertyColumn = (id?: string | undefined) => {
    if (table) {
      if (id) {
        setSearchPropertyValue("");
        table.addColumn({ propertyDefinition: id });
      } else {
        workspace.addNewPropertyDefinition("New property").then(propertyDefinition => {
          if (propertyDefinition) {
            table.addColumn({ propertyDefinition: propertyDefinition.id });
          }
        });
      }
    }
  };

  const handleCreatePropertyColumn = () => {
    workspace.addNewPropertyDefinition("New property").then(propertyDefinition => {
      propertyDefinition && table?.addColumn({ propertyDefinition: propertyDefinition.id });
    });
  };

  const handleAddStatusColumn = (id?: string | undefined) => {
    if (table) {
      if (id) {
        setSearchStatusValue("");
        table.addColumn({ statusDefinition: id });
      } else {
        workspace.addNewBomStatusDefinition(table.id).then(statusDefinition => {
          statusDefinition && table.addColumn({ statusDefinition: statusDefinition.id });
        });
      }
    }
  };

  const handleToggleColumn = (metaColumn: BomMetaColumn) => {
    if (table?.metaColumns.includes(metaColumn)) {
      const columnId = table?.columns.find(c => c.metaColumn === metaColumn)?.id;
      columnId && table.removeColumn(columnId);
    } else {
      table?.addColumn({ metaColumn });
    }
  };

  return (
    <Menu>
      <MenuDivider title="Statuses" />
      <MenuItem icon="new-layer" text="Create new Status" onClick={() => handleAddStatusColumn()} e2eIdentifiers="add-new-status" />
      <MenuItem icon="new-layer" text="Add existing Status" e2eIdentifiers="add-existing-status">
        <div className="mb-1">Search</div>
        <InputGroup
          placeholder="Start typing name..."
          className="mb-2"
          rightElement={
            searchStatusValue ? (
              <Button minimal icon="cross" onClick={() => setSearchStatusValue("")} e2eIdentifiers="clear-search-status" />
            ) : undefined
          }
          leftIcon="search"
          value={searchStatusValue}
          onChange={e => setSearchStatusValue(e.target.value)}
        />
        <div className="create-new-column-nav--sub-menu">
          {statusDefinitions.map(d => (
            <MenuItem key={d.id} text={d.label} onClick={() => handleAddStatusColumn(d.id)} e2eIdentifiers={[d.label]} />
          ))}
        </div>
      </MenuItem>
      <MenuDivider title="Workspace Properties" />
      <MenuItem icon="new-layer" text="Create new Property" onClick={handleCreatePropertyColumn} e2eIdentifiers="add-new-property" />
      <MenuItem icon="new-layer" text="Add existing Property" e2eIdentifiers="add-existing-property">
        <div className="mb-1">Search</div>
        <InputGroup
          placeholder="Start typing name..."
          className="mb-2"
          rightElement={
            searchPropertyValue ? (
              <Button minimal icon="cross" onClick={() => setSearchPropertyValue("")} e2eIdentifiers="clear-search-property" />
            ) : undefined
          }
          leftIcon="search"
          value={searchPropertyValue}
          onChange={e => setSearchPropertyValue(e.target.value)}
        />
        <div className="create-new-column-nav--sub-menu">
          {propertyDefinitions.map(d => (
            <MenuItem key={d.id} text={d.label} onClick={() => handleAddPropertyColumn(d.id)} e2eIdentifiers={[d.label]} />
          ))}
        </div>
      </MenuItem>
      <MenuDivider title="BOM Properties" />
      <MenuItem icon="new-layer" text="Add existing Property" e2eIdentifiers="add-meta-column">
        {getBomMetaColumns().map(metaColumn => (
          <MenuItem
            roleStructure="listoption"
            selected={table?.metaColumns.includes(metaColumn)}
            key={metaColumn}
            text={getBomColumnLabelFromMetaColumn(metaColumn)}
            onClick={e => {
              e.stopPropagation();
              handleToggleColumn(metaColumn);
            }}
            e2eIdentifiers={[getBomColumnLabelFromMetaColumn(metaColumn)]}
          />
        ))}
      </MenuItem>
    </Menu>
  );
};

export default observer(CreateNewColumnNav);
