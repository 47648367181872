import { useState } from "react";
import { NumericInput } from "@blueprintjs/core";
import classNames from "classnames";
import { observer } from "mobx-react";

import { Button } from "@components/Button";
import { IBlock } from "@store/BlockStore";

import styles from "./MultiplicityInput.module.scss";

type Props = {
  block: IBlock;
  small?: boolean;
  fill?: boolean;
  hideClose?: boolean;
  autoFocus?: boolean;
  hideButtons?: boolean;
  className?: string;
  onBlur?(): void;
};

const MultiplicityInput = (props: Props) => {
  const [value, setValue] = useState(props.block.multiplicity?.toString() ?? "1");
  const { block, small, fill = false, hideClose, autoFocus, hideButtons, className } = props;

  const handleClearClick = () => {
    block.clearMultiplicity();
  };

  const renderCloseButton = () => {
    if (hideClose) {
      return;
    }

    return (
      <Button className="block-add-button" minimal alignText="left" icon="small-cross" onClick={handleClearClick} e2eIdentifiers="close" />
    );
  };

  const handleValueChange = (value: number, valueAsString: string) => {
    if (value) {
      block.setMultiplicity(value);
    }

    setValue(valueAsString.replace(".", ""));
  };

  return (
    <NumericInput
      allowNumericCharactersOnly
      inputClassName={classNames({ [styles.multiplicityInputInput]: small })}
      className={classNames(styles.multiplicityInput, className)}
      value={value}
      large={false}
      onValueChange={handleValueChange}
      minLength={3}
      min={1}
      buttonPosition={hideButtons ? "none" : "right"}
      stepSize={1}
      fill={fill}
      autoFocus={autoFocus}
      rightElement={renderCloseButton()}
      onBlur={props.onBlur}
    />
  );
};

export default observer(MultiplicityInput);
