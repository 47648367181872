import { Divider, NonIdealState } from "@blueprintjs/core";
import { observer } from "mobx-react";

import BlockPreviewCard from "@components/BomTables/Table/Components/BlockPreview/BlockPreviewCard";
import ProjectManagementStatusGroupItem from "@components/ProjectManagement/ProjectManagementStatusGroupItem";
import { IBlock } from "@store/BlockStore";
import { StatusType } from "@store/StatusDefinitionStore";
import { IStatusInstance } from "@store/StatusInstanceStore";
import { Text, TextVariant } from "src/ui/Text";

import "./ProjectManagement.scss";

type IProjectManagementProps = {
  block: IBlock;
  className?: string;
};

const ProjectManagement = (props: IProjectManagementProps) => {
  const { block, className } = props;

  const renderLinks = (links: IStatusInstance[]) => {
    return (
      <div>
        <div className="project-management--description-title">Links</div>
        <div>{links.filter(link => link.urlValue).map(renderStatus)}</div>
      </div>
    );
  };

  const renderStatus = (statusInstance: IStatusInstance) => (
    <ProjectManagementStatusGroupItem
      gridClassName="project-management--status-grid"
      key={statusInstance.statusDefinition?.id}
      statusDefinition={statusInstance.statusDefinition}
      block={block}
      readOnly
    />
  );

  const renderContent = () => {
    if (!block.statusInstances?.length && !block.description) {
      return (
        <NonIdealState
          iconSize={20}
          icon="search-template"
          title="No project management details"
          description="This block doesn’t have any project management details"
        />
      );
    }

    const urlStatuses: IStatusInstance[] = block.statusInstances?.filter(status => status.statusDefinition.type === StatusType.url) ?? [];
    const otherStatuses: IStatusInstance[] = block.statusInstances?.filter(status => status.statusDefinition.type !== StatusType.url) ?? [];

    return (
      <>
        <div className="project-management--meta">
          {block.description && (
            <div>
              <Text variant={TextVariant.Body} clamp={3} showReadMoreButton>
                {block.description}
              </Text>
            </div>
          )}
          {!!urlStatuses.length && renderLinks(urlStatuses)}
        </div>
        <Divider />
        <div className="project-management--status-container">{otherStatuses.map(renderStatus)}</div>
      </>
    );
  };

  return (
    <BlockPreviewCard title="Description" className={className}>
      {renderContent()}
    </BlockPreviewCard>
  );
};

export default observer(ProjectManagement);
