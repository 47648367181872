import { IconName } from "@blueprintjs/core";
import JSZip from "jszip";

import { IAttachment } from "@store/AttachmentStore";

export enum FilePreviewType {
  None,
  HoopsViewer,
  BoardViewer,
  VideoPlayer,
}

export interface FileType {
  extensions: string[];
  mimeType: string;
  previewType: FilePreviewType;
}

const ecadBoardTypes: FileType[] = [
  {
    extensions: ["gbr", "gb", "geb", "gerber"],
    mimeType: "application/vnd.gerber",
  },
  {
    extensions: ["kicad_pcb"],
    mimeType: "application/vnd.kicad_pcb",
  },
  {
    extensions: ["pcbdoc"],
    mimeType: "application/vnd.altium.pcb",
  },
].map(({ extensions, mimeType }) => ({ extensions, mimeType, previewType: FilePreviewType.BoardViewer }));

export const hoopsTypes: FileType[] = [
  {
    extensions: ["3mf"],
    mimeType: "model/3mf",
  },
  {
    extensions: ["sat", "sab"],
    mimeType: "model/acis",
  },
  {
    extensions: ["ipt", "iam"],
    mimeType: "model/inventor",
  },
  {
    extensions: ["model", "session", "dlv", "exp"],
    mimeType: "model/catia",
  },
  {
    extensions: ["catpart", "catdrawing", "catproduct", "catshape", "cgr"],
    mimeType: "model/catia",
  },
  {
    extensions: ["3dxml"],
    mimeType: "model/catia",
  },
  {
    extensions: ["dae"],
    mimeType: "model/collada",
  },
  {
    extensions: ["fbx"],
    mimeType: "model/fbx",
  },
  {
    extensions: ["gltf", "glb"],
    mimeType: "model/gltf",
  },
  {
    extensions: ["igs", "iges"],
    mimeType: "model/iges",
  },
  {
    extensions: ["prt"],
    mimeType: "model/nx",
  },
  {
    extensions: ["x_b", "x_t", "xmt", "xmt_txt"],
    mimeType: "model/para",
  },
  {
    extensions: ["rvt", "rfa"],
    mimeType: "model/revit",
  },
  {
    extensions: ["dwg"],
    mimeType: "image/vnd.dwg",
  },
  {
    extensions: ["3dm"],
    mimeType: "model/rhino",
  },
  {
    extensions: ["asm", "par", "pwd", "psm"],
    mimeType: "model/solidedge",
  },
  {
    extensions: ["stl"],
    mimeType: "model/stl",
  },
  {
    extensions: ["obj"],
    mimeType: "model/obj",
  },
  {
    extensions: ["prc"],
    mimeType: "model/prc",
  },
  {
    extensions: ["skp"],
    mimeType: "model/sketchup",
  },
  {
    extensions: ["hsf"],
    mimeType: "model/hsf",
  },
  {
    extensions: ["arc", "mf1", "unv", "pkg"],
    mimeType: "model/ideas",
  },
  {
    extensions: ["jt"],
    mimeType: "model/jt",
  },
  {
    extensions: ["vda"],
    mimeType: "model/vda",
  },
  {
    extensions: ["nwd"],
    mimeType: "model/nwd",
  },
  {
    extensions: ["ifc", "ifczip"],
    mimeType: "model/ifc",
  },
  {
    extensions: ["u3d"],
    mimeType: "model/u3d",
  },
  {
    extensions: ["sldasm", "sldprt"],
    mimeType: "model/solidworks",
  },
  {
    extensions: ["sldasm", "sldprt"],
    mimeType: "model/vnd.solidworks.sldprt",
  },
  {
    extensions: ["stp", "step", "stp.z"],
    mimeType: "model/stp",
  },
  {
    extensions: ["stp", "step", "stp.z"],
    mimeType: "model/step",
  },
  {
    extensions: ["scs"],
    mimeType: "model/scs",
  },
].map(({ extensions, mimeType }) => ({ extensions, mimeType, previewType: FilePreviewType.HoopsViewer }));

export const zippedCadExtensions = ["catproduct", "sldasm"];
export const zippedBoardExtensions = ["gbr", "gb", "geb", "gerber"];

const supportedTypes = [...ecadBoardTypes, ...hoopsTypes];

export function getMimeType(file: File) {
  if (!file) {
    return undefined;
  }

  // Use existing mime type if it exists (and isn't just the default)
  if (file?.type && file.type !== "application/octet-stream") {
    return file.type;
  }

  // Defer to extension if it exists
  const index = file?.name?.lastIndexOf(".");
  if (!index || index < 0) {
    return undefined;
  }
  const extension = file.name.slice(index + 1).toLowerCase();
  if (!extension) {
    return undefined;
  }

  return supportedTypes.find(t => t.extensions?.includes(extension))?.mimeType;
}

export function isHoopsType(file: File) {
  const mimeType = getMimeType(file);
  if (!mimeType) {
    return undefined;
  }
  return hoopsTypes.find(t => t.mimeType === mimeType)?.previewType === FilePreviewType.HoopsViewer;
}

export function getPreviewType(attachment?: IAttachment) {
  if (!attachment) {
    return FilePreviewType.None;
  }
  const mimeType = attachment.fileType;
  if (!mimeType) {
    return FilePreviewType.None;
  }
  if (mimeType === "model/zip") {
    return FilePreviewType.HoopsViewer;
  } else if (mimeType === "board/zip") {
    return FilePreviewType.BoardViewer;
  }

  return supportedTypes.find(t => t.mimeType === mimeType)?.previewType ?? FilePreviewType.None;
}

export function getPreviewIcon(previewType: FilePreviewType): IconName {
  switch (previewType) {
    case FilePreviewType.HoopsViewer:
      return "cube";
    case FilePreviewType.BoardViewer:
      return "control";
    case FilePreviewType.VideoPlayer:
      return "video";
  }
  return "blank";
}

export async function searchZipFile(file: File, extension: string | string[]) {
  // Only check against extension for now
  if (!file?.name?.endsWith(".zip")) {
    return undefined;
  }

  const validExtensions = (Array.isArray(extension) ? extension : [extension]).map(e => {
    if (!e.startsWith(".")) {
      return `.${e}`.toLowerCase();
    } else {
      return e.toLowerCase();
    }
  });

  try {
    const zipFile = await JSZip.loadAsync(file);
    if (!zipFile?.files) {
      return undefined;
    }

    for (const f in zipFile.files) {
      const file = zipFile.files[f];
      const fileName = file?.name?.toLowerCase();
      if (!fileName) {
        continue;
      }
      for (const ext of validExtensions) {
        if (fileName.endsWith(ext)) {
          console.debug(`Found ${ext} file in zip: ${file.name}`);
          return ext.replace(/^\./, "");
        }
      }
    }
  } catch (err) {
    console.error(err);
  }
  return undefined;
}

export function downloadJsonObject(data: Record<any, any>, fileName: string) {
  const element = document.createElement("a");
  element.setAttribute("href", "data:application/json;charset=utf-8," + encodeURIComponent(JSON.stringify(data, null, 2)));
  element.setAttribute("download", `${fileName}.json`);
  element.style.display = "none";
  document.body.appendChild(element);
  element.click();
  document.body.removeChild(element);
}
