import { NonIdealState, NonIdealStateIconSize } from "@blueprintjs/core";
import { useSearchParam } from "@hooks/useSearchParam/useSearchParam";
import { observer } from "mobx-react";

import { InboxView } from "@components/Inbox/utils/enums";
import { Loading } from "@components/Loading";
import appStore from "@store/AppStore";
import { IRollupEvent, RollupEventLoadStatus } from "@store/RollupEventsStore";

import RollupEventSummary from "./RollupEventSummary";

import "./RollupEventsList.scss";

interface RollupEventsListProps {
  rollupEventList?: IRollupEvent[];
  onMarkAs: () => void;
  onMove: () => void;
}

const RollupEventsList = ({ rollupEventList, onMarkAs, onMove }: RollupEventsListProps) => {
  const [viewParam] = useSearchParam("view");

  if (!rollupEventList) {
    return null;
  }

  const renderNoMessages = (view?: InboxView) => {
    switch (view) {
      case InboxView.Inbox:
        return (
          <NonIdealState
            icon="inbox"
            iconSize={NonIdealStateIconSize.EXTRA_SMALL}
            title="Inbox is empty"
            description={
              <div className="rollup-events-list--non-ideal-state">
                You don't have any notifications in your inbox.
                {/* TODO: Bring back the button and add functionality once the inbox docs are ready. */}
                {/* <Button outlined e2eIdentifiers="inbox-docs-cta-button">
                  Inbox docs
                </Button> */}
              </div>
            }
          />
        );
      case InboxView.Mentions:
        return (
          <NonIdealState
            icon="inbox"
            iconSize={NonIdealStateIconSize.EXTRA_SMALL}
            title="Mentions is empty"
            description="You don't have any notifications in which you are mentioned."
          />
        );
      case InboxView.Archive:
        return (
          <NonIdealState
            icon="inbox"
            iconSize={NonIdealStateIconSize.EXTRA_SMALL}
            title="Archive is empty"
            description="You don't have any archived notifications."
          />
        );
      default:
        return null;
    }
  };

  const fetchStatus = appStore.userModel?.rollupEvents?.fetchStatus;

  if (fetchStatus === RollupEventLoadStatus.loading) {
    return (
      <div className="rollup-events-list">
        <Loading size={32} />
      </div>
    );
  }

  // TODO: display error message
  if (fetchStatus === RollupEventLoadStatus.error || fetchStatus === RollupEventLoadStatus.notLoaded) {
    return null;
  }

  if (fetchStatus === RollupEventLoadStatus.loaded && rollupEventList.length === 0) {
    return <div className="rollup-events-list">{renderNoMessages(viewParam as InboxView)}</div>;
  }

  const renderInboxEventsList = () => {
    return (
      <>
        {rollupEventList
          .filter(rollupEvent => rollupEvent.isArchived === false)
          .map(rollupEvent => (
            <RollupEventSummary key={rollupEvent.id} rollupEvent={rollupEvent} onMarkAs={onMarkAs} onMove={onMove} />
          ))}
      </>
    );
  };

  // TODO: Same as inbox for now until fully implemented
  const renderMentionsEventsList = () => {
    return (
      <>
        {rollupEventList
          .filter(rollupEvent => rollupEvent.isArchived === false)
          .map(rollupEvent => (
            <RollupEventSummary key={rollupEvent.id} rollupEvent={rollupEvent} onMarkAs={onMarkAs} onMove={onMove} />
          ))}
      </>
    );
  };

  const renderArchiveEventsList = () => {
    return (
      <>
        {rollupEventList
          .filter(rollupEvent => rollupEvent.isArchived === true)
          .map(rollupEvent => (
            <RollupEventSummary key={rollupEvent.id} rollupEvent={rollupEvent} onMarkAs={onMarkAs} onMove={onMove} />
          ))}
      </>
    );
  };

  const renderList = () => {
    switch (viewParam) {
      case InboxView.Inbox:
        return renderInboxEventsList();
      case InboxView.Mentions:
        return renderMentionsEventsList();
      case InboxView.Archive:
        return renderArchiveEventsList();
      default:
        return null;
    }
  };

  return <div className="rollup-events-list">{renderList()}</div>;
};

export default observer(RollupEventsList);
