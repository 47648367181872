import { Tag, Tooltip } from "@blueprintjs/core";
import classNames from "classnames";
import { observer } from "mobx-react";

import styles from "./MultiplicityTag.module.scss";

interface IMultiplicityTagProps {
  multiplicity: number;
  active?: boolean;
}

const MultiplicityTag = (props: IMultiplicityTagProps) => {
  const { multiplicity, active } = props;

  return (
    <Tooltip content="Block multiplicity">
      <Tag minimal className={classNames(styles.multiplicityTag, { [styles.active]: active })}>
        x{multiplicity}
      </Tag>
    </Tooltip>
  );
};

export default observer(MultiplicityTag);
