import { io, Socket } from "socket.io-client";

import { subscribeToCodeBlockEvents } from "@store/Analysis/CodeBlockStore";
import appStore from "@store/AppStore";
import { subscribeToAttachmentsEvents } from "@store/AttachmentStore";
import { subscribeToAttributeEvents } from "@store/AttributeStore";
import { subscribeToBlockEvents } from "@store/BlockStore";
import { subscribeToBomColumnEvents } from "@store/BomTable/BomColumnStore";
import { subscribeToBomTableEvents } from "@store/BomTable/BomTableStore";
import { subscribeToCatalogItemReferenceEvents } from "@store/CatalogItem/CatalogItemReferenceStore";
import { subscribeToCatalogItemEvents } from "@store/CatalogItem/CatalogItemStore";
import { subscribeToCatalogItemVersionEvents } from "@store/CatalogItem/CatalogItemVersionStore";
import { subscribeToCommentEvents } from "@store/CommentStore";
import { subscribeToCustomUnitEvents } from "@store/CustomUnitModuleStore";
import { subscribeToDataSinkEvents } from "@store/DataConnection/DataSinkStore";
import { subscribeToBlockFeed } from "@store/FeedStore";
import { subscribeToImportEvents } from "@store/ImportStore";
import { subscribeToIntegrationEvents } from "@store/IntegrationStore";
import { subscribeToInterfaceEvents } from "@store/InterfaceStore";
import { subscribeToOrgEvents } from "@store/OrganizationStore";
import { subscribeToPartNumberSchemasEvents } from "@store/PartNumberSchemaStore";
import { subscribeToPropertyDefinitionEvents } from "@store/PropertyDefinitionStore";
import { subscribeToPropertyInstanceEvents } from "@store/PropertyInstanceStore";
import { subscribeToReportBlockEvents } from "@store/ReportBlockStore";
import { subscribeToReportEvents } from "@store/ReportsStore";
import { subscribeToRequirementBlockEvents, subscribeToRequirementsPageEvents } from "@store/RequirementsStore";
import { subscribeToStatusDefinitionEvents } from "@store/StatusDefinitionStore";
import { subscribeToStatusInstanceEvents } from "@store/StatusInstanceStore";
import { subscribeToStatusOptionEvents } from "@store/StatusOptionStore";
import { subscribeToTableViewConfigsEvents } from "@store/TableViewConfigStore";
import { EntityType } from "@store/types";
import { subscribeToDataSourceEvents, subscribeToWorkspaceEvents } from "@store/WorkspaceStore";
import { rollupClient } from "src/core/api";

export interface MessageAck {
  success: boolean;
  data?: any;
  error?: string;
}

export enum PresenceState {
  Editing = "editing",
  Active = "active",
  Idle = "idle",
}

export interface PresenceInfo {
  userId: string;
  clientId: string;
  state: PresenceState;
  entityType: EntityType;
  entityId: string;
}

export interface IPresenceResponse {
  latency: number;
  users: PresenceInfo[];
}

export abstract class RealtimeService {
  abstract subscribeToChanges(room: string): void;

  abstract unsubscribeFromChanges(room: string): void;

  // Allows for adding a handler for a specific event. This allows components to listen to specific events.
  // Returns a function to remove the handler.
  abstract addEventHandler<T>(event: string, handler: (data: T) => void, replaceExisting?: boolean): () => void;

  abstract updatePresence(
    workspaceId: string | undefined,
    entityId: string,
    type?: EntityType,
    state?: PresenceState
  ): Promise<IPresenceResponse>;

  abstract get clientId(): string;
}

export class MockRealtimeService extends RealtimeService {
  subscribeToChanges(_room: string): void {}

  unsubscribeFromChanges(_room: string): void {}

  addEventHandler<T>(_event: string, _handler: (data: T) => void) {
    return () => {};
  }

  get clientId() {
    return "mock";
  }

  async updatePresence(
    _workspaceId: string | undefined,
    _entityId: string,
    _type?: EntityType,
    _state?: PresenceState
  ): Promise<IPresenceResponse> {
    return { latency: NaN, users: [] };
  }
}

export class WebsocketRealtimeService extends RealtimeService {
  private realtimeSocket?: Socket;

  constructor() {
    super();
    this.initWebSocket();
  }

  get clientId() {
    return this.realtimeSocket?.id ?? "";
  }

  private initWebSocket() {
    const realtimeAddress = rollupClient.url.replace(/^http/, "ws") ?? "ws://localhost:9001";
    this.realtimeSocket = io(`${realtimeAddress}/workspace`);
    this.realtimeSocket.on("connect", () => {
      console.debug(`Connected to realtime WebSocket ${realtimeAddress}`);
      if (appStore.orgModel?.info?.id) {
        this.subscribeToChanges(`organization/${appStore.orgModel?.info?.id}`);
      }
      if (appStore.workspaceModel?.id) {
        this.subscribeToChanges(`workspace/${appStore.workspaceModel?.id}`);
      }
    });

    this.realtimeSocket.on("RELOAD", data => {
      if (appStore.workspaceModel?.id && data?.workspace === appStore.workspaceModel.id) {
        appStore.loadWorkspaceFromDatabase(appStore.workspaceModel.id, true);
      }
    });

    // Realtime updates of atomic actions, based on their message pattern names in the backend
    subscribeToAttachmentsEvents(this.realtimeSocket);
    subscribeToWorkspaceEvents(this.realtimeSocket);
    subscribeToDataSourceEvents(this.realtimeSocket);
    subscribeToBlockEvents(this.realtimeSocket);
    subscribeToPartNumberSchemasEvents(this.realtimeSocket);
    subscribeToPropertyDefinitionEvents(this.realtimeSocket);
    subscribeToPropertyInstanceEvents(this.realtimeSocket);
    subscribeToStatusDefinitionEvents(this.realtimeSocket);
    subscribeToStatusInstanceEvents(this.realtimeSocket);
    subscribeToStatusOptionEvents(this.realtimeSocket);
    subscribeToInterfaceEvents(this.realtimeSocket);
    subscribeToAttributeEvents(this.realtimeSocket);
    subscribeToRequirementsPageEvents(this.realtimeSocket);
    subscribeToRequirementBlockEvents(this.realtimeSocket);
    subscribeToReportEvents(this.realtimeSocket);
    subscribeToReportBlockEvents(this.realtimeSocket);
    subscribeToCommentEvents(this.realtimeSocket);
    subscribeToTableViewConfigsEvents(this.realtimeSocket);
    subscribeToBomTableEvents(this.realtimeSocket);
    subscribeToBomColumnEvents(this.realtimeSocket);
    subscribeToOrgEvents(this.realtimeSocket);
    subscribeToImportEvents(this.realtimeSocket);
    subscribeToIntegrationEvents(this.realtimeSocket);
    subscribeToBlockFeed(this.realtimeSocket);
    subscribeToCatalogItemEvents(this.realtimeSocket);
    subscribeToCatalogItemVersionEvents(this.realtimeSocket);
    subscribeToCatalogItemReferenceEvents(this.realtimeSocket);
    subscribeToCodeBlockEvents(this.realtimeSocket);
    subscribeToDataSinkEvents(this.realtimeSocket);
    subscribeToCustomUnitEvents(this.realtimeSocket);
  }

  private sendMessage(action: string, data: any) {
    return new Promise<MessageAck>(resolve => {
      if (this.realtimeSocket?.connected) {
        data.token = rollupClient.auth.accessToken;
        this.realtimeSocket.emit(action, data, (ack: MessageAck) => {
          resolve(ack);
        });
      } else {
        resolve({ success: false, error: "Not connected" });
      }
    });
  }

  public async unsubscribeFromChanges(room: string) {
    if (room) {
      const { success, error } = await this.sendMessage("unsub", { room });
      if (success) {
        console.debug(`Unsubscribed from room ${room}`);
      } else {
        console.error(error);
      }
    }
  }

  public async subscribeToChanges(room: string) {
    if (room) {
      const { success, error } = await this.sendMessage("sub", { room });
      if (success) {
        console.debug(`Subscribed to room: ${room}`);
      } else {
        console.error(error);
      }
    }
  }

  public addEventHandler<T>(event: string, handler: (data: T) => void, replaceExisting = true) {
    if (replaceExisting) {
      this.realtimeSocket?.off(event);
    }
    this.realtimeSocket?.on(event, handler);
    // Return a function to remove the handler, so that the component can clean up when it unmounts
    return () => this.realtimeSocket?.off(event, handler);
  }

  public override async updatePresence(
    workspaceId: string | undefined,
    entityId: string,
    entityType?: EntityType,
    state?: PresenceState
  ): Promise<IPresenceResponse> {
    if (workspaceId) {
      const start = performance.now();
      const { success, data, error } = await this.sendMessage("presence", {
        workspace: workspaceId,
        entityId,
        entityType,
        state,
      });
      if (success) {
        const end = performance.now();
        const dt = end - start;
        return { latency: dt, users: data };
      } else {
        console.error(error);
      }
    }
    return { latency: NaN, users: [] };
  }
}
