import { IconName, Menu } from "@blueprintjs/core";
import { PropertyDataType } from "@rollup-io/engineering";
import { observer } from "mobx-react";
import { isAlive } from "mobx-state-tree";

import { Button } from "@components/Button";
import { MenuItem } from "@components/MenuItem";
import { Popover } from "@components/Popover";
import { TCellRendererProps } from "@components/Table";
import appStore from "@store/AppStore";
import { FeatureFlag } from "@store/FeatureFlagsStore";
import { IPropertyDefinition } from "@store/PropertyDefinitionStore";

import { PROPERTY_DATA_TYPE_ICONS_MAP } from "../constants";

import "./TypeCell.scss";

const TypeCell = ({ data: propertyDefinition }: TCellRendererProps<IPropertyDefinition>) => {
  if (!propertyDefinition || !isAlive(propertyDefinition)) {
    return null;
  }

  const getDataTypes = () => {
    const dataTypes = Object.keys(PropertyDataType);

    if (appStore.env.featureFlags.enabled(FeatureFlag.ARRAY_PROPERTY_TYPE)) {
      return dataTypes;
    } else {
      return dataTypes.filter((dataType: string) => dataType !== PropertyDataType.array);
    }
  };

  const getMenuContent = () => (
    <Menu className="type-cell--menu">
      {getDataTypes().map((property: string) => (
        <MenuItem
          key={property}
          text={property}
          icon={PROPERTY_DATA_TYPE_ICONS_MAP[property as PropertyDataType] as IconName}
          disabled={propertyDefinition.dataType === property}
          active={propertyDefinition.dataType === property}
          onClick={() => propertyDefinition.setDataType(property as PropertyDataType)}
          e2eIdentifiers={["type-cel", "menu-item", property]}
        />
      ))}
    </Menu>
  );

  return (
    <div className="type-cell">
      <Popover content={getMenuContent()} placement="bottom" matchTargetWidth>
        <Button
          icon={PROPERTY_DATA_TYPE_ICONS_MAP[propertyDefinition.dataType] as IconName}
          minimal
          text={propertyDefinition.dataType}
          rightIcon="chevron-down"
          e2eIdentifiers={["type", propertyDefinition.label]}
        />
      </Popover>
    </div>
  );
};

export default observer(TypeCell);
