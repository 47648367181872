import { useState } from "react";
import { DialogBody, InputGroup } from "@blueprintjs/core";
import { observer } from "mobx-react";

import { Button } from "@components/Button";
import { DialogLegacy } from "@components/Dialog";
import { Text, TextVariant } from "src/ui/Text";

import styles from "./AttachedLinkDialog.module.scss";

interface AttachedLinkDialogProps {
  isOpen: boolean;
  onCancel?: () => void;
  onClose?: () => void;
  onSubmit: (linkName: string, linkUrl: string) => void;
}

export const AttachedLinkDialog = ({ isOpen, onCancel, onClose, onSubmit }: AttachedLinkDialogProps) => {
  const [linkName, setLinkName] = useState("");
  const [linkUrl, setLinkUrl] = useState("");

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === "Enter") {
      handleSubmit();
    }
  };

  const isFormValid = () => {
    const urlRegex = /^(https?):\/\/[^\s/$.?#].[^\s]*$/i;
    return linkName.length > 0 && linkUrl.length > 0 && urlRegex.test(linkUrl);
  };

  const clearFields = () => {
    setLinkName("");
    setLinkUrl("");
  };

  const handleSubmit = () => {
    if (isFormValid()) {
      onSubmit(linkName, linkUrl);
      handleClose();
    }
  };

  const handleClose = () => {
    onClose?.();
    clearFields();
  };

  const handleCancel = () => {
    onCancel?.();
    clearFields();
  };

  return (
    <div>
      <DialogLegacy isOpen={isOpen} onClose={handleClose} title={<Text variant={TextVariant.H3}>Add link</Text>} style={{ width: "456px" }}>
        <DialogBody>
          <div className={styles.attachedLinkDialogContent}>
            <InputGroup
              placeholder="Enter link name..."
              large
              onChange={e => setLinkName(e.target.value)}
              value={linkName}
              onKeyDown={handleKeyDown}
              fill
            />
            <InputGroup
              placeholder="Enter link URL..."
              large
              onChange={e => setLinkUrl(e.target.value)}
              value={linkUrl}
              onKeyDown={handleKeyDown}
              fill
            />
          </div>
          <div className={styles.attachedLinkDialogButtons}>
            <Button onClick={handleCancel} large outlined e2eIdentifiers={["attached-link-dialog", "cancel"]}>
              Cancel
            </Button>
            <Button
              onClick={handleSubmit}
              large
              intent="primary"
              disabled={!isFormValid()}
              e2eIdentifiers={["attached-link-dialog", "submit"]}
            >
              Save link
            </Button>
          </div>
        </DialogBody>
      </DialogLegacy>
    </div>
  );
};

export default observer(AttachedLinkDialog);
