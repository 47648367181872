import { useEffect, useRef } from "react";
import katex from "katex";

import "./LatexElement.scss";
import "katex/dist/katex.min.css";

export const LatexElement = ({ children, source }: { children?: any; source?: string }) => {
  const spanRef = useRef(null);

  const sourceValue = source ?? children ?? "";

  useEffect(() => {
    const element = spanRef.current;
    if (element) {
      katex.render(sourceValue, element, { throwOnError: false });
    }
  }, [sourceValue]);
  return <span className="latex-element" ref={spanRef} />;
};
