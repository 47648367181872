import { ChangeEvent, useEffect, useRef, useState } from "react";
import { InputGroup, Intent, MenuDivider } from "@blueprintjs/core";
import { observer } from "mobx-react";

import { MenuItem } from "@components/MenuItem";
import { MenuItemDelete } from "@components/MenuItems";
import GlobalStatus from "@components/Modeling/ModelingFrame/ModelBlock/Statuses/GlobalStatus";
import appStore from "@store/AppStore";
import { IStatusDefinition, StatusType } from "@store/StatusDefinitionStore";

import "./HeaderNav.scss";

interface IHeaderNavParams {
  statusDefinition: IStatusDefinition;
  hideErase?: boolean;
  onDelete: () => void;
  onHide?: () => void;
  onRemove?: () => void;
  onStatusTypeChange?: () => void;
}

const HeaderStatusNav = (props: IHeaderNavParams) => {
  const { statusDefinition, onDelete, onRemove, hideErase, onStatusTypeChange, onHide } = props;
  const [nameInputValue, setNameInputValue] = useState<string>("");
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => setNameInputValue(statusDefinition.label), [statusDefinition]);

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => setNameInputValue(event.target.value);

  const handleInputFocus = (event: ChangeEvent<HTMLInputElement>) => event.target.select();

  const updateStatusDefinitionLabel = () => {
    const trimmedValue = nameInputValue.trim();

    if (trimmedValue) {
      statusDefinition.setLabel(trimmedValue);
    } else {
      setNameInputValue(statusDefinition.label);
    }
  };

  const handleClearAllStatusInstances = () => appStore.workspaceModel?.clearStatusInstancesByDefinition(statusDefinition);

  const handleChangeStatusType = (statusType: StatusType) => {
    appStore.workspaceModel?.migrateStatusType(statusDefinition, statusType);
    onStatusTypeChange?.();
  };

  const renderStatusTypeNav = () =>
    Object.values(StatusType).map((statusType: StatusType) => (
      <MenuItem
        key={statusType}
        text={statusType}
        icon={GlobalStatus.TypeIcon(statusType)}
        disabled={statusDefinition.type === statusType}
        selected={statusDefinition.type === statusType}
        onClick={() => handleChangeStatusType(statusType)}
        e2eIdentifiers={[statusType]}
      />
    ));

  const handleNameInputKeyUp = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      updateStatusDefinitionLabel();
    }
  };

  return (
    <>
      <p className="header-nav--label">Name</p>
      <InputGroup
        className="header-nav--input"
        onKeyUp={handleNameInputKeyUp}
        inputRef={inputRef}
        onBlur={updateStatusDefinitionLabel}
        value={nameInputValue}
        autoFocus
        onFocus={handleInputFocus}
        onChange={handleInputChange}
      />
      <MenuDivider title="Data type" />
      {renderStatusTypeNav()}
      {onHide && <MenuItem icon="eye-off" text="Hide" onClick={onHide} e2eIdentifiers="hide" />}
      <MenuDivider />
      {onRemove && <MenuItem intent={Intent.WARNING} icon="th-disconnect" text="Remove" onClick={onRemove} e2eIdentifiers="remove" />}
      <MenuItemDelete onDelete={onDelete} />
      {!hideErase && <MenuItem icon="eraser" text="Clear All" onClick={handleClearAllStatusInstances} e2eIdentifiers="clear-all" />}
    </>
  );
};

export default observer(HeaderStatusNav);
