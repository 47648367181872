/**
 * Makes sure that the field is no longer optional in the object type
 */
export type WithRequired<T, K extends keyof T> = T & {
  [_ in K]: {};
};

/**
 * Type guard to check if a field exists in an object and if it does, it narrows
 * down the object type so that the field is no longer optional.
 */
export const isFieldDefined =
  <T extends object, K extends keyof T = any>(key: K) =>
  (obj: T): obj is WithRequired<T, K> => {
    return obj[key] !== undefined && obj[key] !== null;
  };

export const isDefined = <T>(value?: T): value is T => {
  return value !== undefined;
};
