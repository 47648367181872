import { Annotation, CreateAnnotationDto, UpdateAnnotationDto } from "@rollup-api/models";

import { HttpClient, ParentClient } from "./client";

export class Annotations extends HttpClient {
  public constructor(parent: ParentClient) {
    super(parent);
  }

  private get endpoint() {
    return "annotations";
  }

  public create = (dto: CreateAnnotationDto) => {
    return this.instance.post<Annotation>(this.endpoint, dto);
  };

  public retrieve = (id: string) => {
    return this.instance.get<Annotation>(`${this.endpoint}/${id}`);
  };

  public retrieveList = () => {
    return this.instance.get<Annotation[]>(this.endpoint);
  };

  public retrieveByParent = (parentId: string) => {
    return this.instance.get<Annotation[]>(`${this.endpoint}/parent/${parentId}`, { params: { take: 9999, skip: 0 } });
  };

  public getThumbnail = (id: string) => {
    return this.instance.get<string>(`${this.endpoint}/thumbnail/${id}`, { responseType: "blob" });
  };

  public setThumbnail = (id: string, thumbnail: string) => {
    return this.instance.patch<string>(`${this.endpoint}/thumbnail/${id}`, { thumbnail });
  };

  public update = (id: string, dto: UpdateAnnotationDto) => {
    return this.instance.patch<Annotation>(`${this.endpoint}/${id}`, dto);
  };

  public delete = (id: string) => {
    return this.instance.delete<Annotation>(`${this.endpoint}/${id}`);
  };
}
