import { useCallback, useEffect } from "react";
import { NonIdealState, Spinner } from "@blueprintjs/core";
import { useInfiniteScrollFetch } from "@hooks/useInfiniteScrollFetch";
import { useListenToCreateTransactionEvent } from "@hooks/useListenToCreateTransactionEvent";
import classNames from "classnames";
import { observer } from "mobx-react";

import { Button } from "@components/Button";
import { InfiniteScrollAnchor } from "@components/InfiniteScrollAnchor";
import CommentThread, { CommentThreadDisplayStyle } from "@components/Modeling/ModelingFrame/ModelBlock/Comments/CommentThread";
import { isCommentTransaction, Transaction } from "@rollup-api/models/transactions";
import appStore from "@store/AppStore";
import { EBlockTab } from "@store/BlockStore";
import { ICommentFeed } from "@store/CommentFeedStore";
import { EFeedActionType } from "@store/FeedStore";

import styles from "./CommentsPanel.module.scss";

type CommentsPanelProps = {
  commentFeed: ICommentFeed;
  isFetchingComments: boolean;
  additionalParentIds: string[];
  className?: string;
  onClearCommentHistory(): void;
};

const CommentsPanel = (props: CommentsPanelProps) => {
  const { commentFeed, isFetchingComments, className, onClearCommentHistory } = props;
  const { additionalParentIds } = props;
  const hasComments = commentFeed.commentThreadList.threads[0];

  const fetchComments = useCallback(
    (force?: boolean) => {
      commentFeed.fetch({ additionalParentIds, force });
    },
    [commentFeed, additionalParentIds]
  );

  const handleCreateTransaction = useCallback(
    (transaction: Transaction) => {
      if (isCommentTransaction(transaction) && additionalParentIds.includes(transaction.parentId)) {
        if (transaction.method === EFeedActionType.CREATE) {
          commentFeed.handleCreateCommentTransaction(transaction);
        } else if (transaction.method === EFeedActionType.DELETE) {
          commentFeed.handleDeleteCommentTransaction(transaction);
        } else if (transaction.method === EFeedActionType.UPDATE) {
          commentFeed.handleUpdateCommentTransaction(transaction);
        }
      }
    },
    [additionalParentIds, commentFeed]
  );

  useListenToCreateTransactionEvent(handleCreateTransaction);

  const { loadMoreAnchorRef, scrollContainerRef } = useInfiniteScrollFetch(() => fetchComments(true), isFetchingComments);

  useEffect(() => {
    onClearCommentHistory();
    fetchComments();
  }, [onClearCommentHistory, fetchComments]);

  const renderNonIdealStateAction = () => {
    return (
      <Button
        onClick={() => {
          appStore.env.setCurrentBlockTab(EBlockTab.COMMENTS);
        }}
        icon="comment"
        large
        minimal
        e2eIdentifiers="open-discussion-btn"
      >
        Open discussions
      </Button>
    );
  };

  const renderNoChanges = (hideButton?: boolean) => {
    return (
      <NonIdealState
        icon="chat"
        iconSize={20}
        action={hideButton ? undefined : renderNonIdealStateAction()}
        title="No comments yet"
        description={hideButton ? "" : "Be the first to start the discussion"}
      />
    );
  };

  const renderContent = () => {
    if (isFetchingComments && !hasComments) {
      return <NonIdealState icon={<Spinner size={30} />} />;
    } else if (!isFetchingComments && !hasComments) {
      return renderNoChanges(true);
    }

    return (
      <>
        {commentFeed.commentThreadList.threads.map(thread => (
          <CommentThread key={thread.parentComment?.id} thread={thread} displayStyle={CommentThreadDisplayStyle.Sidebar} />
        ))}
        <InfiniteScrollAnchor hasMoreItems={commentFeed.hasMoreCommentFeedItems} loadMoreAnchorRef={loadMoreAnchorRef} />
        {!commentFeed.commentThreadList && renderNoChanges()}
      </>
    );
  };

  return (
    <div className={classNames(styles.commentsPanelCommentThreadList, className)} ref={scrollContainerRef}>
      {renderContent()}
    </div>
  );
};

export default observer(CommentsPanel);
