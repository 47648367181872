import { CreateOauthAppDto, OauthApp as OauthAppModel } from "../models/oauthApp";
import { ExtendedOauthAppModel } from "../models/oauthApp/extendedOauthApp.model";
import { ExtendedCreateOauthAppDto } from "../models/oauthApp/oauthAppExtendedCreateDto.model";
import { GetOauthAppsRequestDto } from "../models/oauthApp/oauthAppsRequestDto.model";
import { GetOauthAppsResponseDto } from "../models/oauthApp/oauthAppsResponseDto.model";
import { UpdateOauthAppDto } from "../models/oauthApp/oauthAppUpdateDto.model";

import { HttpClient, ParentClient } from "./client";

export type OauthAppsList = {
  count: number;
  oauthConnections: OauthAppModel[];
};

export class OauthApp extends HttpClient {
  public constructor(parent: ParentClient) {
    super(parent);
  }

  private readonly endpoint: string = "/oauth-connection";

  public create = (dto: CreateOauthAppDto) => {
    return this.instance.post<OauthAppModel>(`${this.endpoint}`, dto);
  };

  public extendedCreate = async (dto: ExtendedCreateOauthAppDto): Promise<ExtendedOauthAppModel | undefined> => {
    try {
      const newExtendedOauthApp = await this.instance.post<{ oauthConnection: ExtendedOauthAppModel }>(
        `${this.endpoint}/extendedCreate`,
        dto
      );
      return newExtendedOauthApp.data.oauthConnection;
    } catch (err) {
      console.error(err);
      return;
    }
  };

  public update = (id: string, dto: UpdateOauthAppDto) => {
    return this.instance.patch<OauthAppModel>(`${this.endpoint}/${id}`, dto);
  };

  public rotateSecret = (id: string) => {
    return this.instance.post<{ oauthApps: OauthAppModel[] }>(`${this.endpoint}/${id}/rotate-secret`);
  };

  public retrieveList = async (dto: GetOauthAppsRequestDto): Promise<GetOauthAppsResponseDto | undefined> => {
    try {
      const res = await this.instance.get<GetOauthAppsResponseDto>(`${this.endpoint}`, { params: dto });
      return res.data;
    } catch (err) {
      console.error(err);
      return;
    }
  };

  public retrieveById = async (id: string): Promise<ExtendedOauthAppModel | undefined> => {
    try {
      const res = await this.instance.get<ExtendedOauthAppModel>(`${this.endpoint}/${id}`);
      return res.data;
    } catch (err) {
      console.error(err);
      return;
    }
  };

  public delete = (id: string) => {
    return this.instance.delete<OauthAppModel>(`${this.endpoint}/${id}`);
  };
}
