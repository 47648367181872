import { types } from "mobx-state-tree";

import { ThumbnailStatus } from "@rollup-api/models/catalogItem/catalogItemDtos";

export const CatalogItemVersionMetaDataStore = types
  .model("CatalogItemVersionMetaData", {
    nodeId: types.maybe(types.string),
    // When we introduce parts mapping we stopped importing part instances & import only part definitions,
    // while part definition doesn't have its own picture we have to use first available instance id for
    // generating picture
    pictureNodeId: types.maybe(types.string),
    thumbnailStatus: types.optional(types.enumeration(Object.values(ThumbnailStatus)), ThumbnailStatus.Ready),
  })
  .actions(self => ({
    setThumbnailStatus(status: ThumbnailStatus) {
      self.thumbnailStatus = status;
    },
  }));
