import { useEffect, useState } from "react";
import { ButtonGroup, IconName, MaybeElement, Position, Tooltip } from "@blueprintjs/core";
import { observer } from "mobx-react";

import MeasureAngleIconDark from "@assets/icons/hoopsIcons/dark/measure_angle_dark.svg?react";
import MeasureEdgeIconDark from "@assets/icons/hoopsIcons/dark/measure_edge_dark.svg?react";
import MeasureTwoPlanesIconDark from "@assets/icons/hoopsIcons/dark/measure_two_planes_dark.svg?react";
import MeasureTwoPointsIconDark from "@assets/icons/hoopsIcons/dark/measure_two_points_dark.svg?react";
import MeasureAngleIconLight from "@assets/icons/hoopsIcons/light/measure_angle_light.svg?react";
import MeasureEdgeIconLight from "@assets/icons/hoopsIcons/light/measure_edge_light.svg?react";
import MeasureTwoPlanesIconLight from "@assets/icons/hoopsIcons/light/measure_two_planes_light.svg?react";
import MeasureTwoPointsIconLight from "@assets/icons/hoopsIcons/light/measure_two_points_light.svg?react";
import { Button } from "@components/Button";
import { Popover } from "@components/Popover";
import { LegacyCustomIcon } from "@components/Shared/LegacyCustomIcon/LegacyCustomIcon";
import appStore from "@store/AppStore";
import { trackSegmentEvent } from "src/lib/Segment";

import HoopsMenuDeleteAllMeasurementsButton from "./HoopsMenuDeleteAllMeasurementsButton";
import HoopsMenuHideShowMeasurementsButton from "./HoopsMenuHideShowMeasurementsButton";

import styles from "../HoopsMenu.module.scss";

type HoopsMenuMeasureButtonGroupProps = {
  viewer: Communicator.WebViewer;
};

type MeasureMode = {
  name: string;
  tooltip: string;
  description?: string;
  operatorId: Communicator.OperatorId;
  lightIcon: IconName | MaybeElement;
  darkIcon: IconName | MaybeElement;
};

const noMeasureMode: MeasureMode = {
  name: "Not measuring",
  tooltip: "none",
  operatorId: Communicator.OperatorId.None,
  lightIcon: <LegacyCustomIcon large icon={<MeasureEdgeIconLight />} />,
  darkIcon: <LegacyCustomIcon large icon={<MeasureEdgeIconDark />} />,
};

const measureModes: MeasureMode[] = [
  {
    name: "Measure edge",
    tooltip: "edge",
    operatorId: Communicator.OperatorId.MeasureEdgeLength,
    lightIcon: <LegacyCustomIcon large icon={<MeasureEdgeIconLight />} />,
    darkIcon: <LegacyCustomIcon large icon={<MeasureEdgeIconDark />} />,
  },
  {
    name: "Measure point to point",
    tooltip: "distance between two points",
    operatorId: Communicator.OperatorId.MeasurePointPointDistance,
    lightIcon: <LegacyCustomIcon large icon={<MeasureTwoPointsIconLight />} />,
    darkIcon: <LegacyCustomIcon large icon={<MeasureTwoPointsIconDark />} />,
  },
  {
    name: "Measure angle between faces",
    tooltip: "angle between two faces",
    operatorId: Communicator.OperatorId.MeasureFaceFaceAngle,
    lightIcon: <LegacyCustomIcon large icon={<MeasureAngleIconLight />} />,
    darkIcon: <LegacyCustomIcon large icon={<MeasureAngleIconDark />} />,
  },
  {
    name: "Measure distance between faces",
    tooltip: "distance between two faces",
    operatorId: Communicator.OperatorId.MeasureFaceFaceDistance,
    lightIcon: <LegacyCustomIcon large icon={<MeasureTwoPlanesIconLight />} />,
    darkIcon: <LegacyCustomIcon large icon={<MeasureTwoPlanesIconDark />} />,
  },
];

const HoopsMenuMeasureButtonGroup = ({ viewer }: HoopsMenuMeasureButtonGroupProps) => {
  const [activeMeasureMode, setActiveMeasureMode] = useState<MeasureMode>(noMeasureMode);

  const measureModeMenu = (
    <ButtonGroup large>
      {measureModes.map(entry => (
        <Tooltip position={Position.TOP} hoverOpenDelay={500} key={entry.name} content={entry.name}>
          <Button
            className={styles.hoopsToolbarButtonAndButtonGroup}
            icon={appStore.env.themeIsDark ? entry.darkIcon : entry.lightIcon}
            onClick={() => handleClick(entry)}
            active={appStore.env.attachmentViewer?.activeCustomOperatorId === entry.operatorId}
            e2eIdentifiers={["measure-mode", entry.name]}
          />
        </Tooltip>
      ))}
      <HoopsMenuHideShowMeasurementsButton viewer={viewer} />
      <HoopsMenuDeleteAllMeasurementsButton viewer={viewer} />
    </ButtonGroup>
  );

  const handleClick = (entry: MeasureMode) => {
    trackSegmentEvent("hoops:measure", { measureMode: entry.tooltip });
    // empty the operators in the operator manager
    viewer.operatorManager.clear();
    // if this measure operator is already active in the store, deactivate it
    if (appStore.env.attachmentViewer?.activeCustomOperatorId === entry.operatorId) {
      // reset all the operators to the standard ones
      appStore.env.attachmentViewer?.standardOperatorIds.forEach(op => viewer.operatorManager.push(op));
      appStore.env.attachmentViewer?.setActiveCustomOperator(0);
      return;
    }

    // otherwise, activate it in the store and the viewer
    appStore.env.attachmentViewer?.setActiveCustomOperator(entry.operatorId);
    viewer.operatorManager.push(Communicator.OperatorId.Navigate);
    viewer.operatorManager.push(entry.operatorId);
  };

  const { activeCustomOperatorId } = appStore.env.attachmentViewer ?? {};

  useEffect(() => {
    const entry = measureModes.find(entry => entry.operatorId === activeCustomOperatorId);
    if (entry) {
      setActiveMeasureMode(entry);
    } else {
      setActiveMeasureMode(noMeasureMode);
    }
  }, [activeCustomOperatorId]);

  return (
    <Popover content={measureModeMenu} placement={Position.TOP}>
      <Tooltip position={Position.RIGHT} content={`Measure mode: ${activeMeasureMode.tooltip}`}>
        <Button
          className={styles.hoopsToolbarButtonAndButtonGroup}
          icon={appStore.env.themeIsDark ? activeMeasureMode.darkIcon : activeMeasureMode.lightIcon}
          active={activeMeasureMode.operatorId !== Communicator.OperatorId.None}
          onClick={() => appStore.env.attachmentViewer?.deactivateAnnotationMode(viewer)}
          e2eIdentifiers="deactivate-annotation-mode"
        />
      </Tooltip>
    </Popover>
  );
};

export default observer(HoopsMenuMeasureButtonGroup);
