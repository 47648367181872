import Avatar from "@components/Avatar/Avatar";
import { IntegrationCallout } from "@components/IntegrationCallout/IntegrationCallout";
import { Integration } from "@rollup-api/models/integrations";

import styles from "./GoogleWorkspaceIntegrationCalout.module.scss";

export const GoogleWorkspaceIntegrationCallout = ({ integration }: { integration: Integration }) => {
  const { metadata } = integration;

  if (!metadata?.teamDriveId) {
    return <IntegrationCallout integration={integration} />;
  }

  const integrationTargetElement = (
    <>
      <Avatar source={metadata.teamDriveBackgroundImageLink} size={20} shape="square" />
      <a
        className={styles.googleWorkspaceIntegrationCallout}
        target="_blank"
        href={`https://drive.google.com/drive/folders/${metadata.teamDriveId}`}
        rel="noreferrer"
      >
        {metadata.teamDriveName ?? "Unknown drive"}
      </a>
    </>
  );

  return <IntegrationCallout integration={integration} integrationTargetElement={integrationTargetElement} />;
};
