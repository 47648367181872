import { MouseEvent, useEffect, useState } from "react";
import { Card, ContextMenu, EditableText, Intent } from "@blueprintjs/core";
import { useBool } from "@hooks/useBool/useBool";
import { GitHubQOFactory } from "@queries/GitHubQOFactory";
import { useQuery } from "@tanstack/react-query";
import { observer } from "mobx-react";

import { Button } from "@components/Button";
import { RepoStatusIcon } from "@components/Modeling/ModelingFrame/ModelBlock/Attachments/RepoStatusIcon";
import { AttachmentStatusIndicator } from "@components/Modeling/ModelingFrame/ModelBlock/Uploads/FileUploadCard/FileUploadCard";
import { Popover } from "@components/Popover";
import UserInfo from "@components/UserInfo/UserInfo";
import { AttachmentStatus } from "@rollup-api/models/cloudStorage";
import { OpenGraphSummary } from "@rollup-api/models/cloudStorage/OpenGraphResponseModel";
import appStore from "@store/AppStore";
import { IAttachment } from "@store/AttachmentStore";
import { IBlock } from "@store/BlockStore";
import { EFileUploadStatus } from "@store/UploadStore";
import { Text } from "src/ui/Text";

import { AttachmentCardActionRow } from "./AttachmentCardActionRow";
import { useAttachmentFunctions } from "./attachmentHooks";
import AttachmentIcon from "./AttachmentIcon";
import { AttachmentInfo } from "./AttachmentInfo";
import AttachmentThumbnail from "./AttachmentThumbnail";
import { AttachmentContextMenu } from "./ContextMenu";
import { getGitHubRepoMetadata, isAttachmentGithubRepository, isAttachmentRegularLink, parseOpenGraphData } from "./utils";

import "./AttachmentCard.scss";

export type AttachmentCardProps = {
  block: IBlock;
  attachment: IAttachment;
};

const AttachmentCard = ({ block, attachment }: AttachmentCardProps) => {
  const { handleConfirm, handleRetry, handleClick } = useAttachmentFunctions({
    attachment,
    block,
    onOpenDetailsDialog: () => appStore.ui.setAttachmentDetails(attachment.id),
  });
  const [editingLabel, { set: openEditingLabel, unset: closeEditingLabel }] = useBool();
  const [openGraphSummary, setOpenGraphSummary] = useState<OpenGraphSummary>();
  const url = attachment.linkAttachmentUrl;
  const isGitHubRepo = isAttachmentGithubRepository(attachment);
  const githubRepoMetadata = isGitHubRepo && url ? getGitHubRepoMetadata(url) : undefined;

  const { data: repoStatus } = useQuery(GitHubQOFactory.createGetStatusQO(githubRepoMetadata));

  useEffect(() => {
    const getUrlAttachmentDetails = async () => {
      if (isAttachmentRegularLink(attachment)) {
        const ogSummary = await parseOpenGraphData(attachment);
        setOpenGraphSummary(ogSummary);
      }
    };
    getUrlAttachmentDetails();
  }, [attachment]);

  const handleLabelEditing = (label: string) => {
    handleConfirm(label);
    closeEditingLabel();
  };

  const stopPropagation = (e: MouseEvent) => e.stopPropagation();

  const renderContextMenu = () => {
    return (
      <AttachmentContextMenu
        attachment={attachment}
        block={block}
        onOpenEditingLabel={openEditingLabel}
        onOpenDetailsDialog={() => appStore.ui.setAttachmentDetails(attachment.id)}
      />
    );
  };

  return (
    <ContextMenu content={renderContextMenu()}>
      <Card className="attachment-card" onClick={handleClick}>
        <AttachmentThumbnail attachment={attachment} />
        <div className="attachment-actions">
          <AttachmentCardActionRow>
            <AttachmentIcon attachment={attachment} />
            {editingLabel ? (
              <div onClick={stopPropagation}>
                <EditableText
                  className="attachment-card--editable-text"
                  maxLength={255}
                  minWidth={20}
                  defaultValue={attachment.label}
                  isEditing={editingLabel}
                  placeholder="Untitled"
                  onConfirm={handleLabelEditing}
                  onCancel={closeEditingLabel}
                  selectAllOnFocus
                  confirmOnEnterKey
                />
              </div>
            ) : (
              <>
                <div className="attachment-actions--title-row">
                  <Text clamp={1}>{openGraphSummary?.title || attachment.label || attachment.name || "Untitled"}</Text>
                  {repoStatus && <RepoStatusIcon status={repoStatus} />}
                </div>
                {attachment.uploadingNewVersion && (
                  <div className="attachment-status--indicator">
                    <AttachmentStatusIndicator status={EFileUploadStatus.UPLOADING} />
                  </div>
                )}
                {attachment.status === AttachmentStatus.ConversionFailed && (
                  <Button
                    className="attachment-actions--reload"
                    minimal
                    onClick={handleRetry}
                    icon="reset"
                    e2eIdentifiers={["attachment-card", "reset"]}
                  />
                )}
              </>
            )}
          </AttachmentCardActionRow>
          <AttachmentCardActionRow>
            <AttachmentInfo size={attachment.formattedFileSize} date={attachment.formattedCreatedAt} type={attachment.type} />
            <div className="attachment-actions--info-right" onClick={stopPropagation}>
              <UserInfo user={attachment.user} size="extra-small" avatarOnly />
              <Popover placement="right-start" content={renderContextMenu()}>
                <Button minimal intent={Intent.NONE} icon="more" e2eIdentifiers={["attachment-context-menu"]} />
              </Popover>
            </div>
          </AttachmentCardActionRow>
        </div>
      </Card>
    </ContextMenu>
  );
};
export default observer(AttachmentCard);
