import { useEffect, useMemo, useRef, useState } from "react";
import { Classes } from "@blueprintjs/core";
import { observer } from "mobx-react";
import { isAlive } from "mobx-state-tree";

import { scalarExpressionEditorInputClassName } from "@components/Shared";
import appStore from "@store/AppStore";

import { BlockPropertyListItem } from "../../Block/BlockPropertyListItem";
import FloatingWindow, { FLOATING_WINDOW_HEADER_HEIGHT } from "../FloatingWindow";

import "./PinnedPropertyWindow.scss";

const propertyRowHeight = 38;
const rowPadding = 10;
const minHeight = FLOATING_WINDOW_HEADER_HEIGHT + propertyRowHeight;

export const PinnedPropertyWindow = () => {
  const { pinnedPropertiesVisible, hidePinnedPropertiesWindow, pinnedProperties } = appStore.env;
  const { propertyInstances } = appStore.workspaceModel ?? {};
  const [height, setHeight] = useState(minHeight);
  const ref = useRef<HTMLDivElement>(null);

  const validProperties = useMemo(() => {
    return pinnedProperties?.filter(p => isAlive(p)) ?? [];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pinnedProperties, propertyInstances]);

  useEffect(() => {
    if (ref.current) {
      setHeight(ref.current.offsetHeight + FLOATING_WINDOW_HEADER_HEIGHT + rowPadding);
    }
  }, [pinnedPropertiesVisible, validProperties.length]);

  return (
    <FloatingWindow
      minSize={{ width: 560, height: minHeight }}
      defaultSize={{ width: 560, height: height }}
      id="pinnedProps"
      title="Pinned Properties"
      className="pinned-property-window"
      open={pinnedPropertiesVisible}
      onClose={hidePinnedPropertiesWindow}
      cancel={`.${scalarExpressionEditorInputClassName}`}
    >
      <div ref={ref}>
        {!pinnedProperties?.length ? (
          <p className={Classes.TEXT_MUTED}>A list of pinned property instances will be displayed here.</p>
        ) : (
          <div className="property-list">
            {validProperties.map(p => (
              <BlockPropertyListItem key={p.id} block={p.parentBlock} propertyInstance={p} pinnedMode />
            ))}
          </div>
        )}
      </div>
    </FloatingWindow>
  );
};

export default observer(PinnedPropertyWindow);
