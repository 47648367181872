import classNames from "classnames";
import { observer } from "mobx-react";

import appStore from "@store/AppStore";
import { IReport } from "@store/ReportsStore";
import { getReportById } from "@utilities/Properties";

import "./ReportsTreeMinimal.scss";

interface IReportsTreeMinimalProps {
  reports: IReport[];
}

const getRootReport = (report: IReport): IReport => {
  if (report.parentReport) {
    return getRootReport(report.parentReport);
  }
  return report;
};

const ReportsTreeMinimal = (props: IReportsTreeMinimalProps) => {
  const { reports } = props;
  const { activeReportId } = appStore.env;
  const activeReport = activeReportId ? getReportById(activeReportId) : undefined;
  const activeRootReport = activeReport ? getRootReport(activeReport) : undefined;

  return (
    <div className="reports-tree-minimal">
      {reports.map(report => (
        <div
          key={report.id}
          className={classNames("reports-tree-minimal--report", {
            "reports-tree-minimal--report-active": report.id === activeRootReport?.id,
            "reports-tree-minimal--report-default-icon": !report.icon,
          })}
        >
          {report.displayedIcon}
        </div>
      ))}
    </div>
  );
};

export default observer(ReportsTreeMinimal);
