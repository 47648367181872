import { useEffect, useState } from "react";
import { Checkbox } from "@blueprintjs/core";
import { observer } from "mobx-react";

import { IWorkspace } from "@store/WorkspaceStore";
import { getWorkspaceById } from "@utilities";

import CommentAttachment from "./CommentAttachment";

import "./CommentAttachmentsList.scss";

export interface CommentAttachmentsListProps {
  workspaceId?: string | null;
  attachmentIds: string[];
  readonly?: boolean;
  allowDeletion: boolean;
  showPublishCheckbox?: boolean;
  publishAttachmentsToBlock?: boolean;
  onDelete?: (deletedAttachmentId: string) => void;
  handleDetach?: () => void;
  onPublishChange?: (publishAttachmentsToBlock: boolean) => void;
}

const CommentAttachmentsList = ({
  workspaceId,
  attachmentIds,
  readonly = true,
  allowDeletion,
  showPublishCheckbox = false,
  publishAttachmentsToBlock,
  onDelete,
  handleDetach,
  onPublishChange,
}: CommentAttachmentsListProps) => {
  const [workspace, setWorkspace] = useState<IWorkspace | null>();

  useEffect(() => {
    if (workspaceId) {
      getWorkspaceById(workspaceId).then(setWorkspace);
    }
  }, [workspaceId]);

  return (
    <div className="comment-attachment-list">
      {attachmentIds.map(attachmentId => {
        const att = workspace?.getAttachmentById(attachmentId);
        if (!att) {
          return null;
        }
        return (
          <CommentAttachment
            key={attachmentId}
            attachment={att}
            readonly={readonly}
            allowDeletion={allowDeletion}
            onDelete={onDelete}
            handleDetach={handleDetach}
          />
        );
      })}
      {showPublishCheckbox && (
        <Checkbox
          label="Add to Attachments as well"
          checked={publishAttachmentsToBlock}
          onChange={e => onPublishChange?.(e.target.checked)}
        />
      )}
    </div>
  );
};

export default observer(CommentAttachmentsList);
