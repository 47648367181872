import { useEffect } from "react";
import noop from "lodash/noop";

interface IUseTimeoutParams {
  delay: number;
  disabled?: boolean;
  onTimeout?(): void;
}

export const useTimeout = (params: IUseTimeoutParams) => {
  const { onTimeout = noop, delay, disabled } = params;

  useEffect(() => {
    const timeout = disabled ? undefined : setTimeout(onTimeout, delay);
    return () => {
      clearTimeout(timeout);
    };
  }, [onTimeout, delay, disabled]);
};
