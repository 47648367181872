import { CustomerPortal as StiggCustomerPortal } from "@stigg/react-sdk";
import { observer } from "mobx-react";

import styles from "./Usage.module.scss";

const Usage = () => {
  return (
    <div className={styles.usage}>
      <StiggCustomerPortal hiddenSections={["paymentDetails", "invoices"]} />
    </div>
  );
};

export default observer(Usage);
