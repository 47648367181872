import { useLocation, useNavigate } from "react-router-dom";

import appStore from "@store/AppStore";
import { ModelingActiveTab } from "@store/EnvironmentStore";
import { filterArray } from "@utilities/ArrayUtils";

/** Type defs. */
interface CommandPaletteAction {
  id: string;
  queryString: string;
  label: string;
  group?: string;
  disabled?: boolean;
  hidden?: boolean;
  onSelect: () => void;
  path?: string | undefined;
}

/** Main function. */
function fixedActions(navigate: ReturnType<typeof useNavigate>, location: ReturnType<typeof useLocation>) {
  const goToSettingsActions = filterArray<CommandPaletteAction>([
    {
      id: "user-account-settings",
      label: "User account settings",
      queryString: "user account settings",
      disabled: /settings$/.test(location.pathname),
      onSelect: () => navigate("/settings"),
    },
    {
      id: "notification-settings",
      label: "Notification settings",
      queryString: "notification settings",
      disabled: location.pathname?.includes("notifications"),
      onSelect: () => navigate("/settings/notifications"),
    },
    {
      id: "general-settings",
      label: "General settings",
      queryString: "general settings",
      disabled: location.pathname?.includes("general"),
      onSelect: () => navigate("/settings/general"),
    },
    {
      id: "members-settings",
      label: "Members settings",
      queryString: "members settings",
      disabled: location.pathname?.includes("members"),
      onSelect: () => navigate("/settings/members"),
    },
    {
      id: "plan-and-billing-settings",
      label: "Plan and billing settings",
      queryString: "plan and billing settings",
      disabled: location.pathname?.includes("plan-and-billing"),
      onSelect: () => navigate("/settings/plan-and-billing"),
    },
    {
      id: "marketplace-settings",
      label: "Marketplace settings",
      queryString: "marketplace settings",
      disabled: location.pathname?.includes("marketplace"),
      onSelect: () => navigate("/settings/marketplace"),
    },
    {
      id: "active-connections-settings",
      label: "Active connections settings",
      queryString: "active connections settings",
      disabled: location.pathname?.includes("active-connections"),
      onSelect: () => navigate("/settings/active-connections"),
    },
    {
      id: "execution-environments-settings",
      label: "Execution environments",
      queryString: "execution environments",
      disabled: location.pathname?.includes("execution-environments"),
      onSelect: () => navigate("/settings/execution-environments"),
    },
  ]);

  const actions: CommandPaletteAction[] = [
    {
      id: "blocks-view",
      label: "Blocks view",
      queryString: "blocks",
      disabled: appStore.env.modelingLeftActiveTab === ModelingActiveTab.model,
      onSelect: () => appStore.env.setModelingLeftActiveTab(ModelingActiveTab.model),
    },
    {
      id: "sidebar",
      label: "Toggle sidebar",
      queryString: "sidebar",
      onSelect: () => appStore.env.toggleLeftSidebar(),
    },
    {
      id: "theme",
      label: "Toggle theme",
      queryString: "theme dark light",
      onSelect: () => appStore.env.toggleTheme(),
    },
    {
      id: "requirements",
      label: "Requirements page",
      queryString: "requirements page",
      disabled: location.pathname?.includes("Requirements"),
      onSelect: () => navigate("/Requirements"),
    },
    {
      id: "modeling",
      label: "Modeling page",
      queryString: "modeling page",
      disabled: location.pathname?.includes("Modeling"),
      onSelect: () => navigate("/Modeling"),
    },
    {
      id: "settings-dialog",
      label: "Settings",
      queryString: "settings",
      disabled: location.pathname?.includes("settings"),
      onSelect: () => navigate("/settings"),
    },
    {
      id: "debugger-dialog",
      label: "Feature flags dialog",
      queryString: "feature flags",
      disabled: appStore.ui.developerDialogEnabled,
      hidden: !appStore.env.developerMode,
      onSelect: appStore.ui.showDeveloperDialog,
    },
    {
      id: "feedback-dialog",
      label: "Leave feedback",
      queryString: "leave feedback",
      onSelect: () => appStore.feedback.show(),
    },
    {
      id: "bug-recording",
      label: "Record a bug / Issue report",
      queryString: "record a bug issue report",
      onSelect: appStore.feedback.startRecording,
    },
    {
      id: "add-new-block",
      label: "Add new block",
      queryString: "add new block",
      disabled: !appStore.env.activeBlock || !appStore.env.activeBlock.replicated || !appStore.workspaceModel,
      onSelect: () => appStore.env.activeBlock?.addNewBlock("New Block"),
    },
    {
      id: "add-new-property",
      label: "Add new property",
      queryString: "add new property",
      disabled: !appStore.env.activeBlock || !appStore.env.activeBlock.replicated || !appStore.workspaceModel,
      onSelect: () => appStore.env.activeBlock?.addNewProperty(),
    },
    {
      id: "add-new-group",
      label: "Add new group",
      queryString: "add new group",
      disabled: !appStore.env.activeBlock || !appStore.env.activeBlock.replicated || !appStore.workspaceModel,
      onSelect: () => appStore.env.activeBlock?.addNewPropertyGroup(),
    },
    {
      id: "table-view",
      label: "Table view",
      queryString: "table",
      disabled: appStore.env.modelingLeftActiveTab === ModelingActiveTab.table,
      onSelect: () => appStore.env.setModelingLeftActiveTab(ModelingActiveTab.table),
    },
    {
      id: "flow-view",
      label: "Flow view",
      queryString: "flow",
      disabled: appStore.env.modelingLeftActiveTab === ModelingActiveTab.flow,
      onSelect: () => appStore.env.setModelingLeftActiveTab(ModelingActiveTab.flow),
    },
    { id: "invite-new-users", label: "Invite new users", queryString: "invite new users", onSelect: appStore.inviteNewUser.show },
    ...goToSettingsActions,
  ];

  // Optional actions
  if (appStore.workspaceModel) {
    actions.push({
      id: "deleteWorkspace",
      label: "Delete current workspace",
      queryString: "delete current workspace",
      disabled: !appStore.userModel?.isAdmin,
      onSelect: () =>
        appStore.ui.showDeleteConfirmationDialog({
          label: appStore.workspaceModel!.label,
          idList: [appStore.workspaceModel!.id],
        }),
    });
  }

  return actions;
}

/** Exports. */
export type { CommandPaletteAction };
export { fixedActions };
