import { Checkbox, Icon } from "@blueprintjs/core";
import { PropertyDataType } from "@rollup-io/engineering";
import { Text, TextVariant } from "@ui/Text";
import capitalize from "lodash/capitalize";
import { observer } from "mobx-react";

import { Dropdown, IComplexSelectOption } from "@components/Shared/Dropdown";
import { Tooltip } from "@components/Tooltip";
import appStore from "@store/AppStore";
import { FeatureFlag } from "@store/FeatureFlagsStore";
import { IPropertyDefinition, UncertaintySymmetry, UncertaintyType } from "@store/PropertyDefinitionStore";

import "./PropertyDefinitionDetails.scss";

const ROLLUP_TOOLTIP = "Rollups aggregate the sum of all property instances in child blocks. Common examples are mass rollups";
const BLOCK_DEFAULT_TOOLTIP = "When selected, all blocks created in this workspace will have this property instantiated by default";
const UNCERTAINTY_TYPE_TOOLTIP = "Relative for percentages, absolute for numeric absolutes";
const UNCERTAINTY_SYMMETRY_TOOLTIP =
  "Apply same uncertainty to both ends (Symmetrical) or individual uncertainties to each end (Asymmetrical)";

interface IPropertyDefinitionProps {
  propertyDefinition: IPropertyDefinition;
}

const uncertaintyTypeOptions = Object.values(UncertaintyType).map(type => ({ label: capitalize(type), value: type }));
const uncertaintySymmetryOptions = Object.values(UncertaintySymmetry).map(type => ({ label: capitalize(type), value: type }));
const propertyDataTypeOptions = Object.values(PropertyDataType)
  .filter(type => type !== PropertyDataType.array || appStore.env.featureFlags.enabled(FeatureFlag.ARRAY_PROPERTY_TYPE))
  .map(type => ({ label: capitalize(type), value: type }));

const PropertyDefinitionDetails = (props: IPropertyDefinitionProps) => {
  const { propertyDefinition } = props;

  const renderInfoIcon = (tooltip: string = "") => (
    <Tooltip content={tooltip}>
      <Icon className="property-definition-details--icon" icon="info-sign" size={14} />
    </Tooltip>
  );

  const renderLabel = (label: string, tooltip?: string) => (
    <div className="property-definition-details--card-label-cell">
      <Text variant={TextVariant.Body}>{label}</Text>
      {tooltip && renderInfoIcon(tooltip)}
    </div>
  );

  return (
    <div className="property-definition-details">
      <div className="property-definition-details--card">
        <Text variant={TextVariant.Caption}>DEFINITION</Text>
        <div className="property-definition-details--card-grid">
          {renderLabel("Data type")}
          <Dropdown<IComplexSelectOption<PropertyDataType>>
            options={propertyDataTypeOptions}
            selected={propertyDataTypeOptions.find(({ value }) => value === propertyDefinition.dataType)}
            onSelect={({ value }) => propertyDefinition.setDataType(value, true)}
            buttonProps={{ className: "property-definition-details--dropdown-btn", outlined: true }}
            e2eIdentifiers="data-type"
          />
          {renderLabel("Rollup", ROLLUP_TOOLTIP)}
          <Checkbox checked={propertyDefinition.autoRollupChildren} onClick={() => propertyDefinition.toggleAutoRollupChildren(true)} />
          {renderLabel("Block default", BLOCK_DEFAULT_TOOLTIP)}
          <Checkbox checked={propertyDefinition.autoAdd} onClick={() => propertyDefinition.toggleAutoAdd(true)} />
        </div>
      </div>
      <div className="property-definition-details--card">
        <Text variant={TextVariant.Caption}>UNCERTAINTY</Text>
        <div className="property-definition-details--card-grid">
          {renderLabel("Type", UNCERTAINTY_TYPE_TOOLTIP)}
          <Dropdown<IComplexSelectOption<UncertaintyType>>
            options={uncertaintyTypeOptions}
            selected={uncertaintyTypeOptions.find(({ value }) => value === propertyDefinition.uncertaintyType)}
            onSelect={({ value }) => propertyDefinition.setUncertaintyType(value, true)}
            buttonProps={{ className: "property-definition-details--dropdown-btn", outlined: true }}
            e2eIdentifiers="uncertainty-type"
          />
          {renderLabel("Symmetry", UNCERTAINTY_SYMMETRY_TOOLTIP)}
          <Dropdown<IComplexSelectOption<UncertaintySymmetry>>
            options={uncertaintySymmetryOptions}
            selected={uncertaintySymmetryOptions.find(({ value }) => value === propertyDefinition.uncertaintySymmetry)}
            onSelect={({ value }) => propertyDefinition.setUncertaintySymmetry(value, true)}
            buttonProps={{ className: "property-definition-details--dropdown-btn", outlined: true }}
            e2eIdentifiers="uncertainty-symmetry"
          />
        </div>
      </div>
    </div>
  );
};

export default observer(PropertyDefinitionDetails);
