import { JSX, useState } from "react";
import { ItemPredicate } from "@blueprintjs/select";
import { BlueprintIcon } from "@ui/BlueprintIcon";
import { observer } from "mobx-react";

import { MultiSelect } from "@components/MultiSelect";
import MultiSelectMenuItem from "@components/MultiSelect/MultiSelectMenuItem";

import styles from "./CompanyDomainsMultiSelect.module.scss";
interface ICompanyDomainsMultiSelectProps {
  selectedDomains: string[];
  allowedDomains: string[];
  inputId?: string;
  setSelectedDomains(domains: string[]): void;
  onCreateNewDomain(domain: string): boolean;
}

const CompanyDomainsMultiSelect = (props: ICompanyDomainsMultiSelectProps): JSX.Element => {
  const { selectedDomains, allowedDomains, inputId, setSelectedDomains, onCreateNewDomain } = props;
  const [isPopoverOpen, setIsPopoverOpen] = useState<boolean>(false);
  const [passInputId, setPassInputId] = useState<boolean>(true);

  const handleItemSelect = (item: string) => {
    if (!allowedDomains.includes(item)) {
      const isValid = onCreateNewDomain(item);
      if (!isValid) {
        return;
      }
    }
    if (selectedDomains.includes(item)) {
      setSelectedDomains(selectedDomains.filter(domain => domain !== item));
    } else {
      setSelectedDomains(selectedDomains.concat(item));
    }
  };

  const filterDomain: ItemPredicate<string> = (query, domain, _index, exactMatch) => {
    const normalizedDomain = domain.toLowerCase();
    const normalizedQuery = query.toLowerCase();

    return exactMatch ? normalizedDomain === normalizedQuery : normalizedDomain.includes(normalizedQuery);
  };

  const onInteraction = (state: boolean) => {
    setIsPopoverOpen(state);
    if (state) {
      // do not pass id if popover is open, otherwise when the user clicks on
      // the label to close the it will be reopened back again
      setPassInputId(false);
    }
  };

  return (
    <MultiSelect<string>
      className={styles.companyDomainsMultiSelect}
      tagRenderer={value => value}
      itemPredicate={filterDomain}
      createNewItemFromQuery={query => query}
      onItemSelect={handleItemSelect}
      onRemove={handleItemSelect}
      selectedItems={selectedDomains}
      items={allowedDomains}
      tagInputProps={{
        inputProps: { id: passInputId ? inputId : undefined },
        rightElement: <BlueprintIcon icon={isPopoverOpen ? "caret-up" : "caret-down"} />,
      }}
      popoverProps={{ onInteraction, onClosed: () => setPassInputId(true) }}
      itemRenderer={(item, itemProps) => (
        <MultiSelectMenuItem text={item} onClick={itemProps.handleClick} selected={selectedDomains.includes(item)} e2eIdentifiers={item} />
      )}
    />
  );
};

export default observer(CompanyDomainsMultiSelect);
