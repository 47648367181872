import { MouseEvent } from "react";
import { useFavorite } from "@hooks/useFavorite";
import { observer } from "mobx-react";

import { Button } from "@components/Button";
import { FavoriteIcon } from "@components/FavoriteIcon";
import { EntityType } from "@store/types";

interface IFavoriteButtonProps {
  entityId: string;
  entityType: EntityType;
  className?: string;
}

const FavoriteButton = (props: IFavoriteButtonProps) => {
  const { className, entityId, entityType } = props;
  const { isFavorite, e2eIdentifiers, tooltip, handleToggleFavorite } = useFavorite({ entityId, entityType });

  const handleDoubleClick = (e: MouseEvent) => {
    // avoids editing tree item label on double-click
    e.stopPropagation();
  };

  return (
    <Button
      className={className}
      icon={<FavoriteIcon isFavorite={isFavorite} />}
      tooltip={tooltip}
      e2eIdentifiers={e2eIdentifiers}
      onClick={handleToggleFavorite}
      onDoubleClick={handleDoubleClick}
      small
      minimal
    />
  );
};

export default observer(FavoriteButton);
