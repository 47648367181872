import { useCallback, useEffect } from "react";

import { Transaction } from "@rollup-api/models/transactions";
import appStore from "@store/AppStore";

export const useListenToCreateTransactionEvent = (onCreateTransaction: (transaction: Transaction) => void) => {
  const handleCreateTransaction = useCallback(
    ({ transaction }: { transaction: Transaction }) => {
      onCreateTransaction(transaction);
    },
    [onCreateTransaction]
  );

  useEffect(() => {
    const removeEventHandler = appStore.realtimeService.addEventHandler("createTransaction", handleCreateTransaction, false);

    return () => {
      removeEventHandler();
    };
  }, [handleCreateTransaction]);
};
