import { NavLinkBackgroundColor } from "./NavLinkAsMenuItem.types";

export const getActiveBgColorClassName = (bgColor?: NavLinkBackgroundColor) => {
  switch (bgColor) {
    case NavLinkBackgroundColor.Cerulean:
      return "navlink-menu-item--active-bg-cerulean";
    case NavLinkBackgroundColor.Green:
      return "navlink-menu-item--active-bg-green";
    case NavLinkBackgroundColor.Gray:
      return "navlink-menu-item--active-bg-gray";
    case NavLinkBackgroundColor.Indigo:
      return "navlink-menu-item--active-bg-indigo";
    case NavLinkBackgroundColor.Lime:
      return "navlink-menu-item--active-bg-lime";
    case NavLinkBackgroundColor.Red:
      return "navlink-menu-item--active-bg-red";
    default:
      return "navlink-menu-item--active-bg-default";
  }
};

export const getHoverBgColorClassName = (bgColor?: NavLinkBackgroundColor) => {
  switch (bgColor) {
    case NavLinkBackgroundColor.Cerulean:
      return "navlink-menu-item--hover-bg-cerulean";
    case NavLinkBackgroundColor.Green:
      return "navlink-menu-item--hover-bg-green";
    case NavLinkBackgroundColor.Gray:
      return "navlink-menu-item--hover-bg-gray";
    case NavLinkBackgroundColor.Indigo:
      return "navlink-menu-item--hover-bg-indigo";
    case NavLinkBackgroundColor.Lime:
      return "navlink-menu-item--hover-bg-lime";
    case NavLinkBackgroundColor.Red:
      return "navlink-menu-item--hover-bg-red";
    default:
      return "navlink-menu-item--hover-bg-default";
  }
};
