import { useCallback, useEffect, useState } from "react";
import { HTMLTable } from "@blueprintjs/core";
import { Select } from "@blueprintjs/select";
import { observer } from "mobx-react";
import csv from "papaparse";

import { Button } from "@components/Button";
import { CatalogItemCsvColumn } from "@components/CatalogItems/Table/types";
import { showApiErrorToast } from "@components/UiLayers/toaster";
import { CatalogItemCsvColumnMap } from "@rollup-api/models/catalogItem/catalogItemDtos";
import { SelectItem } from "src/ui/Select/Item/SelectItem";
import { Text } from "src/ui/Text";

import { catalogItemColumnNameMap, CELL_WIDTH } from "../../constants";

import "./HeaderPanel.scss";

type Props = {
  file: File | null;
  columnsMap: CatalogItemCsvColumnMap;
  onChange: (columnsMap: CatalogItemCsvColumnMap) => void;
};

const handleParsingError = (err: Error) => {
  showApiErrorToast("Error parsing CSV file", err);
};

const HeadersPanel = (props: Props) => {
  const [headers, setHeaders] = useState<string[]>([]);
  const [error, setError] = useState(false);
  const [values, setValues] = useState<Record<string, string>[]>([]);
  const { file, columnsMap, onChange } = props;
  const availableHeaders = Object.keys(columnsMap) as CatalogItemCsvColumn[];

  const handleCompleteParsing = useCallback((results: csv.ParseResult<Record<string, string>>) => {
    setHeaders(results.meta.fields || []);

    if (!results.data.length) {
      setError(true);
    } else {
      setValues(results.data.slice(0, 6));
    }
  }, []);

  const parseFile = useCallback(
    (f: File) => {
      csv.parse(f, {
        header: true,
        skipEmptyLines: true,
        complete: handleCompleteParsing,
        error: handleParsingError,
      });
    },
    [handleCompleteParsing]
  );

  useEffect(() => {
    if (file) {
      parseFile(file);
    } else {
      setValues([]);
      setHeaders([]);
    }
  }, [file, parseFile]);

  const setColumnValue = (column: CatalogItemCsvColumn, value: string) => {
    onChange({ ...columnsMap, [column]: value });
  };

  const renderColumnSelect = (h: string) => {
    const column = h as CatalogItemCsvColumn;
    return (
      <td width={CELL_WIDTH} key={h} className="header-panel--select-cell">
        <Select<string>
          disabled={error}
          filterable={false}
          items={headers}
          popoverContentProps={{ className: "header-panel--select" }}
          popoverProps={{ minimal: true }}
          onItemSelect={value => setColumnValue(column, value)}
          itemRenderer={(item, { handleClick }) => (
            <SelectItem slug={item} key={item} label={item} onClick={handleClick} active={columnsMap[column] === item} />
          )}
        >
          <Button
            fill
            alignText="left"
            text={columnsMap[column] || "Select column"}
            rightIcon="double-caret-vertical"
            e2eIdentifiers={[columnsMap[column] || "Select column"]}
          />
        </Select>
      </td>
    );
  };

  const renderColumnHeader = (header: CatalogItemCsvColumn) => <th key={header}>{catalogItemColumnNameMap[header]}</th>;

  const renderRow = (value: Record<string, string>) => {
    if (Object.values(columnsMap).every(v => !v)) {
      return null;
    }

    const key = Object.values(value).join("-");
    return (
      <tr key={key}>
        {availableHeaders.map((header: CatalogItemCsvColumn) => {
          const valueKey = columnsMap[header];

          return (
            <td width={CELL_WIDTH} key={header}>
              {value[valueKey] || ""}
            </td>
          );
        })}
      </tr>
    );
  };

  return (
    <div className="header-panel">
      <Text className="header-panel--title">Select columns to map</Text>
      {error && <p className="header-panel--error">Selected file does not have enough data, please pick another one.</p>}
      <div className="header-panel--content">
        <HTMLTable className="header-panel--table">
          <thead>
            <tr>{availableHeaders.map(renderColumnHeader)}</tr>
            <tr>{availableHeaders.map(renderColumnSelect)}</tr>
          </thead>
          <tbody>{values.map(renderRow)}</tbody>
        </HTMLTable>
      </div>
    </div>
  );
};

export default observer(HeadersPanel);
