import { ComponentType } from "react";
import { CustomCellRendererProps } from "ag-grid-react";
import { observer } from "mobx-react";
import { isAlive } from "mobx-state-tree";
import { IAnyStateTreeNode } from "mobx-state-tree/dist/internal";

import { TCellRendererProps } from "@components/Table";
import { getCurrencyUnit } from "@utilities/Units";

export const withTreeNodeAlive = <T extends IAnyStateTreeNode>(Component: ComponentType<TCellRendererProps<T>>) =>
  function WithEntityAlive(cellProps: TCellRendererProps<IAnyStateTreeNode>) {
    const { data: treeNode } = cellProps;

    return isAlive(treeNode) ? <Component {...cellProps} /> : null;
  };

export const currencyCellValueFormatter = (unit: string, value: string) => {
  const currencyUnit = getCurrencyUnit(unit);
  if (currencyUnit) {
    return `${currencyUnit.shorthand}${value}`;
  } else {
    return `${value} ${unit}`;
  }
};

// some fields in CustomCellRendererProps differ from the fields in ValueGetterParams,
// so we need to map the fields to make it compatible
const mapRendererPropsToValueGetterParams = (props: CustomCellRendererProps) => {
  const getValue = (colKey: string) => {
    return props.api.getCellValue({ rowNode: props.node, colKey });
  };
  return { ...props, getValue, column: props.column!, colDef: props.colDef! };
};

const getFieldValue = <TData = any, TValue = any>(props: CustomCellRendererProps<TData, TValue>) => {
  const { colDef } = props;

  if (typeof colDef?.valueGetter === "function") {
    const valueGetterParams = mapRendererPropsToValueGetterParams(props);
    // since the valueGetter is being called inside an observer, mobx will make ag-grid re-render the cell
    return colDef.valueGetter(valueGetterParams);
  }

  return props.value;
};

/**
 * The valueGetter needs to be called from within an observer, otherwise some changes
 * done via real-time updates (websocket) may not trigger ag-grid updates, causing
 * the cell renderer to have stale data
 */
export const withObservedValueGetter = <TData = any, TValue = any, TAddProps = any>(
  CellRenderer: ComponentType<CustomCellRendererProps<TData, TValue> & TAddProps>
) => {
  return observer((props: CustomCellRendererProps<TData, TValue> & TAddProps) => {
    return <CellRenderer {...props} value={getFieldValue(props)} />;
  });
};
