import { useEffect } from "react";
import { observer } from "mobx-react";

import appStore from "@store/AppStore";

import "./BirdEatsBug.scss";

const BirdEatsBug = () => {
  useEffect(() => {
    if (window.birdeatsbug) {
      window.birdeatsbug?.setOptions({
        hooks: {
          beforeRecording: appStore.feedback.hide,
          beforeScreenshot: appStore.feedback.hide,
          afterScreenshot: (e: any) => {
            e.then((a: any) => appStore.feedback.setScreenshotUrl(a.screenshot.dataUrl));
            appStore.feedback.show();
          },
          afterRecording: (e: any) => {
            e.then((a: any) => appStore.feedback.setRecordingSessionData(JSON.stringify(a)));
            appStore.feedback.show();
          },
        },
      });
    }
  }, []);

  return null;
};

export default observer(BirdEatsBug);
