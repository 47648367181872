import { JSX, MouseEvent, useState } from "react";
import { Collapse, CollapseProps, Icon, MaybeElement } from "@blueprintjs/core";
import type { IconName } from "@blueprintjs/icons";
import { observer } from "mobx-react";

import { Button } from "@components/Button";
import { IE2EIdentifiers } from "@utilities/E2EUtils";

import "./CollapsibleSection.scss";

export type CollapsibleActionProps = {
  label?: string;
  onClick?: () => void;
  icon?: IconName | MaybeElement;
  e2eIdentifiers?: IE2EIdentifiers;
};

export type CollapsibleSectionProps = {
  title: string;
  isOpen?: boolean;
  onToggle?: (isOpen: boolean) => void;
  action?: CollapsibleActionProps;
  children: JSX.Element;
  collapseProps?: Omit<CollapseProps, "children" | "isOpen">;
  defaultOpen?: boolean;
};

function CollapsibleSection({ title, isOpen, defaultOpen, onToggle, action, children, collapseProps }: CollapsibleSectionProps) {
  const [openInternalState, setOpenInternalState] = useState(defaultOpen);
  const isControlled = isOpen !== undefined;

  const handleOpenClicked = () => {
    if (!isControlled) {
      setOpenInternalState(!openInternalState);
    } else {
      onToggle?.(!isOpen);
    }
  };

  const handleActionClicked = (ev: MouseEvent<HTMLElement>) => {
    ev.stopPropagation();
    action?.onClick && action?.onClick();
  };

  const openState = isControlled ? isOpen : openInternalState;

  return (
    <div className="collapsible-section">
      <div className="collapsible-section--header" onClick={handleOpenClicked}>
        <Icon icon={openState ? "caret-down" : "caret-right"} />
        <h3>{title}</h3>
        {action && (
          <Button
            minimal
            text={action.label}
            className="collapsible-section--action"
            onClick={handleActionClicked}
            icon={action.icon}
            e2eIdentifiers={action.e2eIdentifiers ?? "collapsible-section-action"}
          />
        )}
      </div>
      <Collapse isOpen={isControlled ? isOpen : openInternalState} {...collapseProps}>
        {children}
      </Collapse>
    </div>
  );
}

export default observer(CollapsibleSection);
