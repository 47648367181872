import { ReactNode, useEffect, useRef, useState } from "react";
import { Menu } from "@blueprintjs/core";
import { useClickOutside } from "@hooks/useClickOutside/useClickOutside";
import classNames from "classnames";
import { observer } from "mobx-react";

import { Button } from "@components/Button";
import FilterChoicesTag from "@components/Filter/FilterChoicesTag";
import FilterMenu from "@components/Filter/FilterMenu";
import { FilterChoices, FilterOptionList, FilterType, TypeFilterType, UserFilterType } from "@components/Filter/types";
import RollupEventsInboxActionButton from "@components/Inbox/RollupEventsInboxActionButton";
import RollupEventsWorkspaceSelector from "@components/Inbox/RollupEventsList/RollupEventsWorkspaceSelector";
import { DATE_FILTER_OPTIONS, STATUS_FILTER_OPTIONS } from "@components/Inbox/utils/constants";
import dateFilterResolver, { DateRangeString } from "@components/Inbox/utils/dateFilterResolver";
import { MenuItem } from "@components/MenuItem";
import { Popover } from "@components/Popover";
import UserInfo from "@components/UserInfo/UserInfo";
import appStore from "@store/AppStore";
import { RollupEventStatus } from "@store/RollupEventsStore";

import "./RollupEventsFilter.scss";

interface RollupEventsFilterProps {
  onChange?: (filterChoices: FilterChoices) => void;
}

const RollupEventsFilter = ({ onChange }: RollupEventsFilterProps) => {
  const [activeFilterType, setActiveFilterType] = useState<FilterType>();
  const [isFilterMenuOpen, setIsFilterMenuOpen] = useState<boolean>(false);

  const [userFilterOptions, setUserFilterOptions] = useState<FilterOptionList<UserFilterType>>({
    title: "Originator",
    list: appStore.orgModel.info.orgMembers.map(member => {
      return { name: member.name ?? "", avatarUrl: member.avatarUrl ?? "", userId: member.id ?? "" };
    }),
    filterField: "name",
    keyField: "userId",
    searchBox: true,
    multiSelect: true,
    pluralCountWord: "users",
    renderer: item => {
      return <UserInfo size="extra-small" userName={item.name} avatarUrl={item.avatarUrl} />;
    },
    selectedItems: [],
    selectedKeys: [],
  });
  const [timeFilterOptions, setTimeFilterOptions] = useState<FilterOptionList<string>>({
    title: "Time",
    list: DATE_FILTER_OPTIONS,
    selectedItems: [],
    selectedKeys: [],
  });
  const [statusFilterOptions, setStatusFilterOptions] = useState<FilterOptionList<string>>({
    title: "Status",
    list: STATUS_FILTER_OPTIONS,
    selectedItems: [],
    selectedKeys: [],
  });
  const [typeFilterOptions, setTypeFilterOptions] = useState<FilterOptionList<TypeFilterType>>({
    title: "Type",
    list: [
      // TODO: Add the values in when the API supports them
      { label: "Mention", value: "mention" },
      { label: "Task", value: "task" },
      { label: "Block changes", value: undefined },
      { label: "Attachment changes", value: undefined },
      { label: "Report changes", value: undefined },
    ],
    filterField: "label",
    keyField: "value",
    renderer: item => {
      return <>{item.label}</>;
    },
    selectedItems: [],
    selectedKeys: [],
  });

  useEffect(() => {
    let timeFilterItems: DateRangeString | undefined;
    if (timeFilterOptions.selectedKeys.length > 0) {
      timeFilterItems = dateFilterResolver(timeFilterOptions.selectedKeys[0]);
    }
    const currentFilterChoices: FilterChoices = {
      originators: userFilterOptions.selectedKeys,
      startDate: timeFilterItems?.startDateTime,
      endDate: timeFilterItems?.endDateTime,
      status: statusFilterOptions.selectedKeys.map(status => status.toLowerCase()) as RollupEventStatus[],
    };
    onChange?.(currentFilterChoices);
  }, [
    userFilterOptions.selectedKeys,
    timeFilterOptions.selectedKeys,
    statusFilterOptions.selectedKeys,
    typeFilterOptions.selectedKeys,
    onChange,
  ]);

  useEffect(() => {
    if (!isFilterMenuOpen) {
      setActiveFilterType(undefined);
    }
  }, [isFilterMenuOpen]);

  const mainFilterMenuRef = useRef<HTMLElement>(null);
  useClickOutside(mainFilterMenuRef, () => {
    setIsFilterMenuOpen(false);
  });

  const refWrapper = (node: ReactNode) => {
    return <span ref={mainFilterMenuRef}>{node}</span>;
  };

  const originatorFilterMenu = () => {
    return (
      <FilterMenu
        options={userFilterOptions}
        onChange={(selectedItems, selectedKeys) => handleSelect(selectedItems, selectedKeys, "originator")}
      />
    );
  };
  const timeFilterMenu = () => {
    return (
      <FilterMenu
        options={timeFilterOptions}
        onChange={(selectedItems, selectedKeys) => handleSelect(selectedItems, selectedKeys, "time")}
      />
    );
  };
  const statusFilterMenu = () => {
    return (
      <FilterMenu
        options={statusFilterOptions}
        onChange={(selectedItems, selectedKeys) => handleSelect(selectedItems, selectedKeys, "status")}
      />
    );
  };
  const typeFilterMenu = () => {
    return (
      <FilterMenu
        options={typeFilterOptions}
        onChange={(selectedItems, selectedKeys) => handleSelect(selectedItems, selectedKeys, "type")}
      />
    );
  };

  // Choose what to display in the filter menu
  const filterMenuContent = () => {
    if (!userFilterOptions || !timeFilterOptions || !statusFilterOptions || !typeFilterOptions) return null;
    switch (activeFilterType) {
      case undefined:
        return (
          <Menu>
            <MenuItem icon="person" text="Originator" onClick={() => setActiveFilterType("originator")} e2eIdentifiers="originator" />
            {!timeFilterOptions.selectedKeys.length && (
              <MenuItem icon="time" text="Time" onClick={() => setActiveFilterType("time")} e2eIdentifiers="time" />
            )}
            {!statusFilterOptions.selectedKeys.length && (
              <MenuItem icon="inbox" text="Status" onClick={() => setActiveFilterType("status")} e2eIdentifiers="status" />
            )}
            {!typeFilterOptions.selectedKeys.length && (
              <MenuItem icon="clip" text="Type" onClick={() => setActiveFilterType("type")} e2eIdentifiers="type" />
            )}
          </Menu>
        );
      case "originator":
        return originatorFilterMenu();
      case "time":
        return timeFilterMenu();
      case "status":
        return statusFilterMenu();
      case "type":
        return typeFilterMenu();
      default:
        break;
    }
  };

  const handleSelect = (selectedItems: string[] | UserFilterType[] | TypeFilterType[], selectedKeys: string[], filterType: FilterType) => {
    switch (filterType) {
      case "originator":
        setUserFilterOptions({ ...userFilterOptions, selectedKeys: selectedKeys, selectedItems: selectedItems as UserFilterType[] });
        return;
      case "time":
        setTimeFilterOptions({ ...timeFilterOptions, selectedKeys: selectedKeys, selectedItems: selectedItems as string[] });
        break;
      case "status":
        setStatusFilterOptions({ ...statusFilterOptions, selectedKeys: selectedKeys, selectedItems: selectedItems as string[] });
        break;
      case "type":
        setTypeFilterOptions({ ...typeFilterOptions, selectedKeys: selectedKeys, selectedItems: selectedItems as TypeFilterType[] });
        break;
      default:
        break;
    }
    if (selectedKeys.length > 0) {
      setIsFilterMenuOpen(false);
    }
  };

  const clearFilters = (filterType: FilterType | "all") => {
    switch (filterType) {
      case "originator":
        setUserFilterOptions({ ...userFilterOptions, selectedKeys: [], selectedItems: [] });
        break;
      case "time":
        setTimeFilterOptions({ ...timeFilterOptions, selectedKeys: [], selectedItems: [] });
        break;
      case "status":
        setStatusFilterOptions({ ...statusFilterOptions, selectedKeys: [], selectedItems: [] });
        break;
      case "type":
        setTypeFilterOptions({ ...typeFilterOptions, selectedKeys: [], selectedItems: [] });
        break;
      default:
        setUserFilterOptions({ ...userFilterOptions, selectedKeys: [], selectedItems: [] });
        setTimeFilterOptions({ ...timeFilterOptions, selectedKeys: [], selectedItems: [] });
        setStatusFilterOptions({ ...statusFilterOptions, selectedKeys: [], selectedItems: [] });
        setTypeFilterOptions({ ...typeFilterOptions, selectedKeys: [], selectedItems: [] });
        break;
    }
  };

  const handleFilterButtonClick = () => {
    setIsFilterMenuOpen(isFilterMenuOpen => !isFilterMenuOpen);
  };

  const areAnyFiltersSelected = () => {
    return (
      userFilterOptions.selectedKeys.length > 0 ||
      timeFilterOptions.selectedKeys.length > 0 ||
      statusFilterOptions.selectedKeys.length > 0 ||
      typeFilterOptions.selectedKeys.length > 0
    );
  };

  const renderFilterButton = () => {
    // check if any filters are selected
    return (
      <Popover placement="bottom-start" isOpen={isFilterMenuOpen} content={refWrapper(filterMenuContent())}>
        {areAnyFiltersSelected() ? (
          <Button
            className="filters-button"
            active={isFilterMenuOpen}
            large
            icon="add"
            minimal
            onClick={handleFilterButtonClick}
            e2eIdentifiers={["has-filter", "show-filter"]}
          />
        ) : (
          <Button
            className="filters-button"
            active={isFilterMenuOpen}
            large
            icon="add"
            text="Filter"
            outlined
            onClick={handleFilterButtonClick}
            e2eIdentifiers={["has-no-filter", "show-filter"]}
          />
        )}
      </Popover>
    );
  };

  const renderClearFiltersButton = () => {
    return (
      <Button
        className="filters-button"
        large
        rightIcon="cross"
        text="Clear filters"
        outlined
        onClick={() => clearFilters("all")}
        e2eIdentifiers="clear-filters"
      />
    );
  };

  return (
    <div className="filter">
      <div>
        <RollupEventsWorkspaceSelector />
        <div>
          {areAnyFiltersSelected() ? renderClearFiltersButton() : renderFilterButton()}
          <RollupEventsInboxActionButton />
        </div>
      </div>
      <div
        className={classNames({
          hidden: !areAnyFiltersSelected(),
        })}
      >
        <Popover placement="bottom-start" content={refWrapper(originatorFilterMenu())}>
          <FilterChoicesTag
            title="Originator"
            choices={userFilterOptions.selectedItems}
            filterField={userFilterOptions.filterField}
            pluralCountWord={userFilterOptions.pluralCountWord}
            onClose={() => clearFilters("originator")}
          />
        </Popover>
        <Popover placement="bottom-start" content={refWrapper(timeFilterMenu())}>
          <FilterChoicesTag title="Time" choices={timeFilterOptions.selectedItems} onClose={() => clearFilters("time")} />
        </Popover>
        <Popover placement="bottom-start" content={refWrapper(statusFilterMenu())}>
          <FilterChoicesTag title="Status" choices={statusFilterOptions.selectedItems} onClose={() => clearFilters("status")} />
        </Popover>
        <Popover placement="bottom-start" content={refWrapper(typeFilterMenu())}>
          <FilterChoicesTag
            title="Type"
            choices={typeFilterOptions.selectedItems}
            filterField={typeFilterOptions.filterField}
            onClose={() => clearFilters("type")}
          />
        </Popover>
        {areAnyFiltersSelected() && renderFilterButton()}
      </div>
    </div>
  );
};

export default observer(RollupEventsFilter);
