import { useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import { NonIdealState } from "@blueprintjs/core";
import { observer } from "mobx-react";

import { CustomIcon, ModelingIcon } from "@components/CustomIcon";
import { FeatureRightSidebar, IFeatureSidePanel } from "@components/FeatureRightSidebar";
import { BoardViewer } from "@components/Modeling/ModelingFrame/BoardViewer/BoardViewer";
import HoopsViewer from "@components/Modeling/ModelingFrame/HoopsViewer/HoopsViewer";
import ModelFrameToolbar from "@components/Modeling/ModelingFrame/ModelFrameToolbar";
import BlockView from "@router/components/BlockView/BlockView";
import BlockViewRightSidePanels from "@router/components/BlockView/BlockViewRightSidePanels";
import Pane from "@router/components/Panes/Pane";
import NavLinkAsButton from "@router/navigation/NavLinkAsButton";
import appStore from "@store/AppStore";
import { BlockSidePanel } from "@store/SidePanelStore";

import styles from "./ActiveBlockIndex.module.scss";
const getSidePanels = (activeSidePanel: BlockSidePanel): IFeatureSidePanel<BlockSidePanel>[] => [
  { key: BlockSidePanel.HistoryAndComments, icon: "history", label: "History and Comments" },
  {
    key: BlockSidePanel.Programmatics,
    icon: <CustomIcon icon={ModelingIcon.Programmatics} active={activeSidePanel === BlockSidePanel.Programmatics} />,
    label: "Programmatics",
  },
  { key: BlockSidePanel.PropertyDetails, icon: "detection", label: "Property Details" },
];

/** Main function. */
function ActiveBlockIndex() {
  const { blockId, workspaceId } = useParams();
  const { hash } = useLocation();
  const block = appStore.env.activeBlock;
  const storeActiveBlockId = block?.id;
  const { activePropertyInstance } = appStore.env;
  const { activeBlockSidePanel, pinnedBlockSidePanels, setBlockSidePanel, hideBlockSidePanel, togglePinnedBlockSidePanel } =
    appStore.env.sidePanel;

  useEffect(() => {
    if (!!blockId && blockId !== storeActiveBlockId) {
      appStore.env.setActiveBlockById(blockId);
    }
  }, [blockId, storeActiveBlockId]);

  useEffect(() => {
    if (hash) {
      appStore.env.setActiveBlockEntityByHash(hash);
    }
  }, [hash]);

  if (!block) {
    return (
      <NonIdealState
        title="ERROR"
        action={<NavLinkAsButton minimal intent="primary" to={`/workspaces/${workspaceId}/modeling/blocks`} text="Back to Blocks" end />}
        description={`Can't load block with ID: ${blockId}`}
      />
    );
  }

  if (appStore.env.activeAttachment) {
    if (appStore.env.isHoopsPreviewMode) {
      return <HoopsViewer block={block} attachment={appStore.env.activeAttachment} />;
    } else if (appStore.env.isBoardPreviewMode) {
      return <BoardViewer block={block} attachment={appStore.env.activeAttachment} />;
    }
  }

  return (
    <div className={styles.activeBlockIndex}>
      <Pane topElement={<ModelFrameToolbar block={block} />}>
        <BlockView block={block} />
      </Pane>
      <BlockViewRightSidePanels block={block} activeSidePanel={activeBlockSidePanel} activePropertyInstance={activePropertyInstance} />
      <FeatureRightSidebar<BlockSidePanel>
        activeSidePanelKey={activeBlockSidePanel}
        pinnedSidePanelKeys={pinnedBlockSidePanels}
        sidePanels={getSidePanels(activeBlockSidePanel)}
        onClose={block.clearActionHistory}
        onTogglePinnedSidePanel={togglePinnedBlockSidePanel}
        onChangeActiveSidePanel={setBlockSidePanel}
        onHideSidePanel={hideBlockSidePanel}
      />
    </div>
  );
}

/** Exports. */
export default observer(ActiveBlockIndex);
