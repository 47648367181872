import { Category } from "../models/strapi/category";
import { StrapiEntitiesResponse } from "../models/strapi/entity";
import { StoreListing } from "../models/strapi/storeListing";

import { HttpClient, ParentClient } from "./client";

export class Strapi extends HttpClient {
  public constructor(parent: ParentClient) {
    super(parent);
  }

  private readonly endpoint: string = "strapi";

  public retrieveList = async (): Promise<StrapiEntitiesResponse<StoreListing> | undefined> => {
    try {
      const res = await this.instance.get<StrapiEntitiesResponse<StoreListing>>(this.endpoint);
      return res.data;
    } catch (err) {
      console.error(err);
      return;
    }
  };

  public retrieveCategories = async (): Promise<StrapiEntitiesResponse<Category> | undefined> => {
    try {
      const res = await this.instance.get<StrapiEntitiesResponse<Category>>(`${this.endpoint}/categories`);
      return res.data;
    } catch (err) {
      console.error(err);
      return;
    }
  };
}
