import { DialogBody, Intent } from "@blueprintjs/core";
import { BlueprintIcon } from "@ui/BlueprintIcon";
import classNames from "classnames";
import { observer } from "mobx-react";

import { Button } from "@components/Button";
import { DialogLegacy } from "@components/Dialog";
import AssetImage from "@components/Shared/AssetImage/AssetImage";
import UploadFileButton from "@components/UploadFileButton/UploadFileButton";
import appStore from "@store/AppStore";

import "./LogoBackgroundDialog.scss";

interface ILogoBackgroundDialogProps {
  isOpen: boolean;
  logoUrl: string;
  onClose(): void;
}

const LogoBackgroundDialog = (props: ILogoBackgroundDialogProps) => {
  const { isOpen, logoUrl, onClose } = props;

  return (
    <DialogLegacy isOpen={isOpen} onClose={onClose} title="Logo Background">
      <DialogBody className="logo-background-dialog--body">
        <div className="logo-background-dialog--top-container">
          <div className="logo-background-dialog--logos-row">
            <div className="logo-background-dialog--logo-container">
              <AssetImage
                className={classNames("logo-background-dialog--logo", "logo-background-dialog--logo-dark")}
                alt="logo"
                src={logoUrl}
              />
              <span>Dark</span>
            </div>
            <div className="logo-background-dialog--logo-container">
              <AssetImage
                className={classNames("logo-background-dialog--logo", "logo-background-dialog--logo-light")}
                alt="logo"
                src={logoUrl}
              />
              <span>Light</span>
            </div>
          </div>
          <div className="logo-background-dialog--info-msg-container">
            <BlueprintIcon icon="small-info-sign" />
            <span>
              Recommendation: prefer using PNG files with a filled background to avoid problems when displaying on light and dark themes.
            </span>
          </div>
        </div>
        <div className="logo-background-dialog--btns-container">
          <UploadFileButton
            large
            className="logo-background-dialog--upload-button"
            text="Upload company logo"
            icon="media"
            onChange={appStore.orgModel?.info?.uploadLogo}
            e2eIdentifiers="upload-company-logo"
          />
          <Button
            className="logo-background-dialog--save-button"
            intent={Intent.PRIMARY}
            text="Save"
            large
            e2eIdentifiers="save"
            onClick={onClose}
          />
        </div>
      </DialogBody>
    </DialogLegacy>
  );
};

export default observer(LogoBackgroundDialog);
