import { UseQueryOptions } from "@tanstack/react-query";

import { IListTransactionsParams, ITransactionsResponse } from "@rollup-api/api/transactions";

import { QueryKeys, rollupClient } from "../core/api";

interface IGetTransactionsOptions extends Omit<UseQueryOptions<ITransactionsResponse>, "queryKey" | "queryFn"> {}

export abstract class TransactionsQOFactory {
  public static createGetTransactionsQO(
    params: IListTransactionsParams,
    options?: IGetTransactionsOptions
  ): UseQueryOptions<ITransactionsResponse> {
    const queryKey = [QueryKeys.Transactions, ...(params.referenceIds ?? []), params.endTime, options?.enabled];
    return {
      queryKey,
      queryFn: async (): Promise<ITransactionsResponse> => {
        const res = await rollupClient.transactions.list(params);
        return res.data;
      },
      enabled: options?.enabled ?? true,
    };
  }
}
