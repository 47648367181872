import { Button } from "@components/Button";
import { TCellRendererProps } from "@components/Table";
import { Invoice } from "@rollup-api/models/invoices/invoice.model";

interface IInvoiceTableDownloadCellProps extends TCellRendererProps<Invoice> {}

export const InvoiceTableDownloadCell = (props: IInvoiceTableDownloadCellProps) => {
  const { data: invoice } = props;

  const downloadInvoice = () => {
    window.open(invoice.pdfUrl, "_blank");
  };

  return <Button icon="download" onClick={downloadInvoice} e2eIdentifiers="download-invoice" minimal />;
};
