import moment, { MomentInput, unitOfTime } from "moment";

// Converts string-based timestamps (from the backend) to numerical timestamps
export function convertTimestamp(timestamp?: string) {
  return timestamp ? moment(timestamp).valueOf() : Date.now();
}

export function convertTimestampToDate(timestamp: string) {
  return timestamp ? moment(timestamp).toDate() : new Date();
}

export function toTimestamp(date: Date | number) {
  return moment(date).format();
}

export const getFormattedDate = (date: MomentInput = new Date(), seconds?: boolean): string => {
  if (seconds) {
    return moment(date).format("YYYY-MM-DD HH:mm:ss");
  }
  return moment(date).format("YYYY-MM-DD HH:mm");
};

export const getAbsoluteDateTime = (timestamp: number | string): string => {
  const date = moment(timestamp);
  return date.format("MMM DD, YYYY, hh:mm A");
};

export const humanReadableDateTime = (timestamp: number | string): string => {
  const historyDate = moment(timestamp);
  const nowUtc = moment();
  const diffSeconds = nowUtc.diff(historyDate, "seconds");
  const diffHours = nowUtc.diff(historyDate, "hours");
  if (diffSeconds < 60) {
    return "just now";
  }
  if (diffSeconds < 180) {
    return "a few minutes ago";
  }
  if (diffHours < 24) {
    return moment(timestamp).fromNow();
  }
  if (diffHours >= 24 && diffHours < 48) {
    return "yesterday";
  }
  return moment(timestamp).fromNow();
};

export const daysLeftToDate = (date: Date) => {
  const futureDate = moment(date);
  const nowUtc = moment();
  return futureDate.diff(nowUtc, "days");
};

export const isBefore = (aTimestamp: string | number, bTimestamp: string | number): boolean => {
  const aDate = moment(aTimestamp);
  const bDate = moment(bTimestamp);
  return aDate.isBefore(bDate);
};

export const isAfter = (aTimestamp: string | number, bTimestamp: string | number): boolean => {
  const aDate = moment(aTimestamp);
  const bDate = moment(bTimestamp);
  return aDate.isAfter(bDate);
};

export const diffTimestamps = (t1: string, t2: string, unitOfTime: unitOfTime.Diff): number => {
  const d1 = moment(t1);
  const d2 = moment(t2);
  return d1.diff(d2, unitOfTime);
};

// Sort from newest to oldest
export const sortByUpdated = <T extends { updatedAt: string | number }>(a: T, b: T): number => {
  if (isBefore(a.updatedAt, b.updatedAt)) {
    return 1;
  } else if (isAfter(a.updatedAt, b.updatedAt)) {
    return -1;
  }
  return 0;
};

// Sort from newest to oldest
export const sortByCreated = <T extends { createdAt: string | number }>(a: T, b: T): number => {
  if (isBefore(a.createdAt, b.createdAt)) {
    return 1;
  } else if (isAfter(a.createdAt, b.createdAt)) {
    return -1;
  }
  return 0;
};

export interface IObjectWithDateStrings {
  createdAt?: string;
  updatedAt?: string;
}

export interface IObjectWithDates {
  createdAt?: number;
  updatedAt?: number;
}

export const convertDateStringsToTimestamp = <T extends IObjectWithDateStrings>(
  obj: T
): Omit<T, "createdAt" | "updatedAt"> & IObjectWithDates => {
  const createdAt = obj.createdAt ? { createdAt: convertTimestamp(obj.createdAt) } : {};
  const updatedAt = obj.updatedAt ? { updatedAt: convertTimestamp(obj.updatedAt) } : {};

  return {
    ...obj,
    ...createdAt,
    ...updatedAt,
  };
};
