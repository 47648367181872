import { ChangeEvent, useCallback, useEffect, useRef, useState } from "react";
import { GridApi, IRowNode } from "ag-grid-community";
import classNames from "classnames";

import { CELL_EDIT_FOCUS } from "@components/Requirements/RequirementsTable/constants";
import { useCellAutoHeight } from "@components/Table/hooks/useCellAutoHeight";

type TextCellEditorProps<T> = {
  returnPlaceholder?: string;
  defaultValue?: string;
  WrapperTag?: "h3" | "h4" | "div" | "span";
  className?: string;
  singleLine?: boolean;
  node: IRowNode<T>;
  api: GridApi<T>;
  isValid?(value: string): boolean;
  onBlur?(): void;
  onValueChange(value: string): void;
};

const INVALID_CLASS = "ag-edit-focus-invalid";

const SimpleTextCellEditor = <T,>(props: TextCellEditorProps<T>) => {
  const { singleLine, defaultValue, returnPlaceholder = "", WrapperTag = "span", className = "" } = props;
  const { node, api, onBlur, isValid, onValueChange } = props;
  const [value, setValue] = useState(defaultValue || "");
  const inputRef = useRef<HTMLInputElement>(null);
  const { containerRef, handleChange, stabilizeCaret } = useCellAutoHeight({ value, setValue, isValid, node, api });

  useEffect(() => {
    onValueChange(value || returnPlaceholder);
  }, [onValueChange, returnPlaceholder, value]);

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;

    if (isValid) {
      const validated = isValid(newValue);
      const classList = inputRef.current?.parentElement?.classList;
      validated ? classList?.remove(INVALID_CLASS) : classList?.add(INVALID_CLASS);
    }

    setValue(newValue);
  };

  const handleFocus = useCallback(() => {
    const parentNode = (singleLine ? inputRef : containerRef).current?.parentNode;

    if (parentNode) {
      (parentNode as HTMLElement).classList.add(CELL_EDIT_FOCUS);
    }
  }, [containerRef, singleLine]);

  useEffect(() => {
    handleFocus();
    if (!singleLine) {
      stabilizeCaret();
    }
  }, [handleFocus, singleLine, stabilizeCaret]);

  useEffect(() => {
    if (!singleLine) {
      stabilizeCaret();
    }
  }, [singleLine, stabilizeCaret, value]);

  const handleBlur = () => {
    const parentNode = singleLine ? inputRef.current?.parentNode : containerRef.current?.parentNode;

    if (parentNode) {
      (parentNode as HTMLElement).classList.remove(CELL_EDIT_FOCUS);
    }
    onBlur?.();
  };

  const elementProps = {
    onBlur: handleBlur,
    onFocus: handleFocus,
    className: classNames({
      "ag-text-field-input": true,
      "ag-text-field-input-invalid": !isValid,
      [className]: !!className,
    }),
  };

  return singleLine ? (
    <input autoFocus ref={inputRef} type="text" {...elementProps} onChange={handleInputChange} value={value} />
  ) : (
    // TODO migrate to TextArea (https://linear.app/rollup/issue/ENG-3249/[texteditorcell]-use-textarea-instead-of-wrappertag)
    <WrapperTag ref={containerRef} onInput={handleChange} contentEditable suppressContentEditableWarning {...elementProps}>
      {value}
    </WrapperTag>
  );
};

export default SimpleTextCellEditor;
