import { JSX, ReactNode } from "react";
import { PopoverProps, PopupKind } from "@blueprintjs/core";
import { MaybeElement } from "@blueprintjs/core/src/common/props";
import type { IconName } from "@blueprintjs/icons";
import classNames from "classnames";
import { observer } from "mobx-react";

import { Button } from "@components/Button";
import { IButtonProps } from "@components/Button/Button";
import { Popover } from "@components/Popover";
import { IE2EIdentifiersParam } from "@utilities/E2EUtils";

import styles from "./PopupMenu.module.scss";

interface IPopupMenuProps extends IE2EIdentifiersParam {
  content: string | JSX.Element;
  buttonText?: string;
  buttonIcon?: IconName | MaybeElement;
  className?: string;
  children?: ReactNode | ReactNode[];
  popoverProps?: PopoverProps;
  buttonProps?: Omit<IButtonProps, "e2eIdentifiers">;
  inactive?: boolean;
  disabled?: boolean;
  onOpening?(node: HTMLElement): void;
  onClosing?(node: HTMLElement): void;
}

const PopupMenu = (props: IPopupMenuProps) => {
  const { buttonText, buttonIcon, popoverProps, content, className } = props;
  const { buttonProps, e2eIdentifiers, inactive, disabled, onOpening, onClosing } = props;
  const { className: btnClassName, ...restButtonProps } = buttonProps ?? {};

  return (
    <div className={classNames(styles.popupMenu, className)}>
      <Popover
        content={content}
        className={styles.popupMenuPopover}
        onOpening={onOpening}
        onClosing={onClosing}
        placement="bottom-start"
        popupKind={PopupKind.MENU}
        disabled={disabled}
        hasBackdrop
        {...popoverProps}
      >
        <Button
          minimal
          text={buttonText}
          icon={buttonIcon}
          className={classNames(styles.popupMenuButton, btnClassName, {
            [styles.popupMenuButtonInactive]: inactive,
            [styles.buttonIconText]: buttonProps?.rightIcon,
          })}
          e2eIdentifiers={e2eIdentifiers}
          disabled={disabled}
          {...restButtonProps}
        />
      </Popover>
    </div>
  );
};

export default observer(PopupMenu);
