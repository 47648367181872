import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { NonIdealState } from "@blueprintjs/core";
import { observer } from "mobx-react";

import { FeatureRightSidebar, IFeatureSidePanel } from "@components/FeatureRightSidebar";
import ReportHeader from "@components/Reports/Header";
import { FeatureRightSidePanels } from "@components/Requirements/FeatureRightSidePanels";
import appStore from "@store/AppStore";
import { FeatureSidePanel } from "@store/SidePanelStore";

import ReportPage from "./Page/ReportPage";

import "./ModelFrame.scss";
import styles from "./ReportsFrame.module.scss";

const getSidePanels = (): IFeatureSidePanel<FeatureSidePanel>[] => [{ key: FeatureSidePanel.Comments, icon: "comment", label: "Comments" }];

function ReportsFrame() {
  const { reportId } = useParams();
  const report = appStore.workspaceModel?.reportsMap?.get(reportId || "");
  const { activeKnowledgebaseSidePanel, pinnedKnowledgebaseSidePanels, setKnowledgebaseSidePanel } = appStore.env.sidePanel;
  const { hideKnowledgebaseSidePanel, togglePinnedKnowledgebaseSidePanel } = appStore.env.sidePanel;

  useEffect(() => {
    if (report) {
      appStore.env.setActiveReport(report.id);
      report.fetchReportBlockComments();
    }
  }, [report]);

  if (!reportId) {
    return null;
  } else if (!report) {
    return (
      <NonIdealState title="Knowledgebase" description={`Can't open page with ID: ${reportId}`} className="min-h-[100%] h-[auto] p-6" />
    );
  }

  return (
    <div className={styles.reportsFrame}>
      <div className={styles.reportsFrameMainContent}>
        <ReportHeader report={report} />
        <ReportPage report={report} />
      </div>
      <FeatureRightSidePanels
        commentFeed={report.commentFeed}
        childrenIds={report.reportBlocks}
        activeSidePanel={activeKnowledgebaseSidePanel}
        pinnedSidePanels={pinnedKnowledgebaseSidePanels}
        onClearCommentHistory={report.clearCommentHistory}
      />
      <FeatureRightSidebar<FeatureSidePanel>
        activeSidePanelKey={activeKnowledgebaseSidePanel}
        pinnedSidePanelKeys={pinnedKnowledgebaseSidePanels}
        sidePanels={getSidePanels()}
        onTogglePinnedSidePanel={togglePinnedKnowledgebaseSidePanel}
        onChangeActiveSidePanel={setKnowledgebaseSidePanel}
        onHideSidePanel={hideKnowledgebaseSidePanel}
        disablePinning
      />
    </div>
  );
}

/** Exports. */
export default observer(ReportsFrame);
