import { MouseEvent } from "react";
import { Icon } from "@blueprintjs/core";
import { observer } from "mobx-react";

import { MenuItem } from "@components/MenuItem";
import { IE2EIdentifiersParam } from "@utilities/E2EUtils";

interface IMultiSelectMenuItemProps extends IE2EIdentifiersParam {
  text: string;
  disabled?: boolean;
  selected?: boolean;
  hideTickIcon?: boolean;
  onClick?(event: MouseEvent<HTMLElement>): void;
}

const MultiSelectMenuItem = (props: IMultiSelectMenuItemProps) => {
  const { text, disabled, hideTickIcon, selected } = props;
  const { e2eIdentifiers, onClick } = props;

  return (
    <div onClick={onClick}>
      <MenuItem
        text={text}
        disabled={disabled}
        selected={selected}
        e2eIdentifiers={e2eIdentifiers}
        rightElement={
          <Icon
            className="menu-item-option--tick-icon"
            style={{ display: hideTickIcon ? "none" : "initial", opacity: Number(selected) }}
            icon="small-tick"
          />
        }
      />
    </div>
  );
};

export default observer(MultiSelectMenuItem);
