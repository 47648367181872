import { AxiosResponse } from "axios";

import { EFeedEntityType } from "@store/FeedStore";

import { Transaction } from "../models/transactions";

import { HttpClient, ParentClient } from "./client";

export interface ITransactionsResponse {
  transactions: Transaction[];
  hasMore: boolean;
}

export interface IListTransactionsParams {
  parentId?: string;
  parentIds?: string[];
  referenceIds?: string[];
  endTime?: Date;
  referenceTypes?: EFeedEntityType[];
}

export class Transactions extends HttpClient {
  public constructor(parent: ParentClient) {
    super(parent);
  }

  private get endpoint() {
    return "transactions";
  }

  public list = (params?: IListTransactionsParams): Promise<AxiosResponse<ITransactionsResponse>> => {
    return this.instance.get<ITransactionsResponse>(this.endpoint, {
      params,
    });
  };
}
