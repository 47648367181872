import { observer } from "mobx-react";

import { Button } from "@components/Button";
import { Text, TextVariant } from "src/ui/Text";

import "./FilterChoicesTag.scss";

interface FilterChoicesTagProps<T extends Record<string, string> | string> {
  title: string;
  choices: T[];
  filterField?: keyof T;
  pluralCountWord?: string;
  onClick?: () => void;
  onClose?: () => void;
}

const FilterChoicesTag = <T extends Record<string, string> | string>({
  title,
  choices,
  filterField,
  pluralCountWord,
  onClick,
  onClose,
}: FilterChoicesTagProps<T>) => {
  if (choices.length === 0) {
    return null;
  }

  const onCloseClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent> | React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.stopPropagation();
    onClose?.();
  };

  return (
    <div className="filter-choices-tag" onClick={onClick}>
      <Text variant={TextVariant.Body}>{title}:</Text>
      {choices.length === 1 && (
        <>
          <Text variant={TextVariant.BodyDimmed}>is</Text>
          {typeof choices[0] === "string" ? (
            <Text variant={TextVariant.Body}>{choices[0]}</Text>
          ) : (
            <Text variant={TextVariant.Body}>{choices[0][filterField as keyof T] as string}</Text>
          )}
        </>
      )}
      {choices.length > 1 && (
        <>
          <Text variant={TextVariant.BodyDimmed}>is any of</Text>
          <Text variant={TextVariant.Body}>
            {choices.length} {pluralCountWord}
          </Text>
        </>
      )}
      <Button icon="cross" minimal onClick={onCloseClick} e2eIdentifiers={["filter-choices-tag", "close"]} />
    </div>
  );
};

export default observer(FilterChoicesTag);
