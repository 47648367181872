import { Fragment } from "react";
import { BlueprintIcon } from "@ui/BlueprintIcon";
import { Text, TextVariant } from "@ui/Text";
import { observer } from "mobx-react";

import { Button } from "@components/Button";
import MoreOptionsMenu from "@components/MoreOptionsMenu/MoreOptionsMenu";
import ReportActionsMenu from "@components/ReportActionsMenu";
import ReportsSidebarContextMenu from "@components/Reports/ReportContextMenu";
import { Tooltip } from "@components/Tooltip";
import { useAppNavigate } from "@router/hooks";
import appStore from "@store/AppStore";
import { IReport } from "@store/ReportsStore";
import { createCopyToClipboardHandler } from "@utilities";

import styles from "./ReportHeader.module.scss";

type Props = {
  report: IReport;
};

const ReportHeader = (props: Props) => {
  const { report } = props;
  const { navigateToReport } = useAppNavigate();

  const handleCopyToClipboard = createCopyToClipboardHandler(window.location.href, "report link");

  const renderPathContent = (pathReport?: IReport) => {
    const isActive = pathReport?.id === report.id;

    return (
      <div className={styles.reportHeaderPathContent}>
        <span className={styles.reportHeaderPathIcon}>{pathReport?.displayedIcon}</span>
        <Text variant={TextVariant.Body}>{pathReport?.displayedLabel}</Text>
        {isActive && (
          <MoreOptionsMenu
            content={<ReportsSidebarContextMenu onDelete={appStore.ui.showDeleteReportConfirmationDialog} report={report} />}
            buttonProps={{ className: styles.reportHeaderContexMenuBtn }}
            e2eIdentifiers="show-menu"
          />
        )}
      </div>
    );
  };

  const renderPath = () => {
    return report.path.map((id: string) => {
      const pathReport = appStore.workspaceModel?.reportsMap.get(id);
      const isActive = id === report.id;

      if (isActive) {
        return (
          <div key={id} className={styles.reportHeaderActive}>
            {renderPathContent(pathReport)}
          </div>
        );
      }

      return (
        <Fragment key={id}>
          <Button
            onClick={() => navigateToReport(id)}
            active={id === report.id}
            e2eIdentifiers="report-header-path-btn"
            minimal
            className={styles.reportHeaderPathBtn}
          >
            {renderPathContent(pathReport)}
          </Button>
          <BlueprintIcon icon="chevron-right" />
        </Fragment>
      );
    });
  };

  return (
    <div className={styles.reportHeader}>
      <div className={styles.reportHeaderSection}>{renderPath()}</div>
      <div className={styles.reportHeaderSection}>
        <Tooltip content="Copy link to clipboard">
          <Button icon="link" e2eIdentifiers="copy-to-clipboard" onClick={handleCopyToClipboard} minimal />
        </Tooltip>
        <MoreOptionsMenu content={<ReportActionsMenu report={report} />} e2eIdentifiers="report-more-btn" />
      </div>
    </div>
  );
};

export default observer(ReportHeader);
