import { ApiKey, CreateApiKeyDto } from "../models/apiKeys";
import { GetApiKeysRequestDto } from "../models/apiKeys/apiKeysRequestDto.model";
import { GetApiKeysResponseDto } from "../models/apiKeys/apiKeysResponseDto.model";
import { UpdateApiKeyDto } from "../models/apiKeys/apiKeyUpdateDto";

import { HttpClient, ParentClient } from "./client";

export type ApiKeysList = {
  count: number;
  apiKeys: ApiKey[];
};

export class ApiKeys extends HttpClient {
  public constructor(parent: ParentClient) {
    super(parent);
  }

  private readonly endpoint: string = "/organization/api-keys";

  public create = (dto: CreateApiKeyDto) => {
    return this.instance.post<ApiKey>(`${this.endpoint}`, dto);
  };

  public retrieveList = async (dto: GetApiKeysRequestDto): Promise<GetApiKeysResponseDto | undefined> => {
    try {
      const res = await this.instance.get<GetApiKeysResponseDto>(`${this.endpoint}`, { params: dto });
      return res.data;
    } catch (err) {
      console.error(err);
      return;
    }
  };

  public retrieveById = async (id: string): Promise<ApiKey | undefined> => {
    try {
      const res = await this.instance.get<ApiKey>(`${this.endpoint}/${id}`);
      return res.data;
    } catch (err) {
      console.error(err);
      return;
    }
  };

  public update = (id: string, dto: UpdateApiKeyDto) => {
    return this.instance.patch<ApiKey>(`${this.endpoint}/${id}`, dto);
  };

  public delete = (id: string) => {
    return this.instance.delete<ApiKey>(`${this.endpoint}/${id}`);
  };
}
