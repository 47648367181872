import { ICellRendererParams } from "ag-grid-community";
import { observer } from "mobx-react";
import { isAlive } from "mobx-state-tree";

import StatusTypeCheckEditor from "@components/ProjectManagement/StatusTypeCheckEditor";
import StatusTypeDateEditor from "@components/ProjectManagement/StatusTypeDateEditor";
import StatusTypeMentionEditor from "@components/ProjectManagement/StatusTypeMentionEditor";
import StatusTypeMultiSelectEditor from "@components/ProjectManagement/StatusTypeMultiSelectEditor";
import StatusTypeNumberEditor from "@components/ProjectManagement/StatusTypeNumberEditor";
import StatusTypeTextEditor from "@components/ProjectManagement/StatusTypeTextEditor";
import appStore from "@store/AppStore";
import { IBlock } from "@store/BlockStore";
import { IStatusDefinition, StatusType } from "@store/StatusDefinitionStore";

const StatusCell = (props: ICellRendererParams<{ block: IBlock }> & { statusDefinition: IStatusDefinition | null }) => {
  const { statusDefinition } = props;

  if (!props.data?.block || !isAlive(props.data?.block)) {
    return null;
  }

  const statusDefinitionProxy = appStore.workspaceModel?.statusDefinitions.find(i => i.id === statusDefinition?.id);

  if (!statusDefinitionProxy) {
    return null;
  }

  const editorProps = {
    statusDefinition: statusDefinitionProxy,
    block: props.data?.block,
  };

  const renderComponent = () => {
    switch (statusDefinitionProxy.type) {
      case StatusType.mention:
        return <StatusTypeMentionEditor placeholder="" {...editorProps} />;
      case StatusType.multiSelect:
      case StatusType.singleSelect:
        return (
          <div className="status-cell--multi">
            <StatusTypeMultiSelectEditor
              disabled
              placeholder=""
              singleSelect={StatusType.singleSelect === statusDefinitionProxy.type}
              {...editorProps}
            />
          </div>
        );
      case StatusType.number:
        return <StatusTypeNumberEditor hidePlaceholder {...editorProps} />;
      case StatusType.date:
        return <StatusTypeDateEditor hidePlaceholder {...editorProps} />;
      case StatusType.check:
        return <StatusTypeCheckEditor {...editorProps} />;
      default:
        return <StatusTypeTextEditor hidePlaceholder {...editorProps} />;
    }
  };

  return (
    <div data-testid={`${editorProps.block.label}_${editorProps.statusDefinition.label}_${editorProps.statusDefinition.id}`}>
      {renderComponent()}
    </div>
  );
};

export default observer(StatusCell);
