import { Checkout as StiggCheckout } from "@stigg/react-sdk";
import { Text, TextVariant } from "@ui/Text";

import { Button } from "@components/Button";
import { ISelectedPlanData } from "@router/elements/settings/SettingsPlanAndBilling/types";
import { getCssVar } from "@utilities/StyleUtils";

import "./Checkout.scss";

interface ICheckoutProps {
  selectedPlan: ISelectedPlanData;
  onGoBack(): void;
}

export const Checkout = (props: ICheckoutProps) => {
  const { selectedPlan, onGoBack } = props;

  // Note: Stripe renders some forms inside an iFrame, which makes it impossible
  // for us to style it via CSS. We need to customize Stigg's theme via React
  // so we get the styling we want for Stripe's forms.
  const paymentInputBackgroundColor = getCssVar("--bg-color-level3");

  return (
    <div className="checkout">
      <div className="checkout--header">
        <Button className="checkout--go-back-button" icon="arrow-left" onClick={onGoBack} e2eIdentifiers="go-back-to-billing" minimal>
          Back
        </Button>
        <Text variant={TextVariant.H1}>Checkout</Text>
      </div>
      <StiggCheckout
        planId={selectedPlan.plan.id}
        preferredBillingPeriod={selectedPlan.selectedBillingPeriod}
        onCheckoutCompleted={() => Promise.resolve()}
        theme={{ paymentInputBackgroundColor, textColor: "yellow", primary: "red" }}
      />
    </div>
  );
};
