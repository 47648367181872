import { PropsWithChildren } from "react";
import { observer } from "mobx-react";

import CreateCatalogItemDialog from "@components/CreateCatalogItemDialog";
import DeleteCatalogItemDialog from "@components/DeleteCatalogItemDialog";
import DeleteCatalogItemsDialog from "@components/DeleteCatalogItemsDialog";
import { AddNewCadFile } from "@components/Dialog";
import CreateNewCatalogItemVersionDialog from "@components/Dialog/CreateNewCatalogItemVersionDialog";
import CreateReportTemplateDialog from "@components/Dialog/CreateReportTemplate";
import DeleteReportConfirmation from "@components/Dialog/DeleteReportConfirmation";
import DeleteWorkspaceConfirmationDialog from "@components/Dialog/DeleteWorkspaceConfirmation";
import DialogAddNewWorkspace from "@components/Dialog/DialogAddNewWorkspace";
import { DialogEditBomTable } from "@components/Dialog/DialogEditBomTable";
import ReportTemplatesDialog from "@components/Dialog/ReportTemplates/ReportTemplates";
import { ReviewImportDialog } from "@components/Dialog/ReviewImportDialog";
import ErrorBoundary from "@components/ErrorBoundary/ErrorBoundary";
import FeedbackDialog from "@components/FeedbackDialog";
import { FloatingWindowManager } from "@components/FloatingWindows";
import FreeTrialWrapper from "@components/FreeTrial/FreeTrialWrapper";
import InviteNewUserDialog from "@components/InviteNewUserDialog/InviteNewUserDialog";
import AttachmentDetails from "@components/Modeling/ModelingFrame/ModelBlock/Attachments/AttachmentDetails";
import { CommandPaletteWrapper } from "@components/Shared";
import { SignUpDialog } from "@components/SignUpDialog/SignUpDialog";
import StiggProvider from "@providers/StiggProvider";
import appStore from "@store/AppStore";
import { FeatureFlag } from "@store/FeatureFlagsStore";
import DragToSelectProvider from "src/providers/DragToSelect";

/** Main function. */
function AppWrapper({ children }: PropsWithChildren<{}>) {
  const { activeReportId } = appStore.env;
  const report = activeReportId ? appStore.workspaceModel?.reportsMap.get(activeReportId) : undefined;

  return (
    <ErrorBoundary logErrors>
      <StiggProvider>
        <FreeTrialWrapper>
          {/* Dialogs */}
          <AddNewCadFile />
          <ReportTemplatesDialog />
          <CreateCatalogItemDialog />
          <DeleteCatalogItemDialog />
          <DeleteCatalogItemsDialog />
          <CreateNewCatalogItemVersionDialog />
          <ReviewImportDialog />
          <CreateReportTemplateDialog />
          <DeleteWorkspaceConfirmationDialog />
          <AttachmentDetails />
          <DialogAddNewWorkspace isOpen={appStore.ui.showAddNewWorkspaceDialog} onClose={appStore.ui.toggleAddNewWorkspaceDialog} />
          {appStore.env.featureFlags.enabled(FeatureFlag.BOM) && (
            <DialogEditBomTable
              isOpen={appStore.ui.editBomTableDialogEnabled}
              existingTableId={appStore.ui.editingBomTableId}
              onClose={appStore.ui.hideEditBomTableDialog}
            />
          )}
          {report && (
            <DeleteReportConfirmation
              isOpen={appStore.ui.deleteReportConfirmationDialogEnabled}
              onClose={appStore.ui.hideDeleteReportConfirmationDialog}
              report={report}
            />
          )}
          <FeedbackDialog />
          {appStore.inviteNewUser.visible && <InviteNewUserDialog />}
          {appStore.ui.signUpDialogEnabled && <SignUpDialog />}

          {/* Others */}
          <CommandPaletteWrapper workspace={appStore.workspaceModel} />

          {/* we avoid wrapping all modals to prevent drag to select provider from re-render */}
          <DragToSelectProvider>
            {/* Children */}
            <>{children}</>
          </DragToSelectProvider>
          <FloatingWindowManager />
        </FreeTrialWrapper>
      </StiggProvider>
    </ErrorBoundary>
  );
}

/** Exports. */
export default observer(AppWrapper);
