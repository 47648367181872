import { IRollupEvent } from "@store/RollupEventsStore";

export const getEventName = (rollupEvent: IRollupEvent): string => {
  switch (rollupEvent.entityType) {
    case "comment": {
      switch (rollupEvent.action) {
        case "mentionedInComment": {
          return "Mentioned by";
        }
        default:
          return "Comment from ";
      }
    }
    default:
      return "Action from ";
  }
};
