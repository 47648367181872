import { MarkerType } from "reactflow";
import { Colors } from "@blueprintjs/core";

import ReferenceNode from "@components/CatalogItemPreview/Components/RelationsCard/Nodes/ReferenceNode";

import CatalogItemNode from "./Nodes/CatalogItemNode";
import FileNode from "./Nodes/FileNode";
import { RelationNodeTypes } from "./types";

export const nodeTypes = {
  [RelationNodeTypes.File]: FileNode,
  [RelationNodeTypes.CatalogItem]: CatalogItemNode,
  [RelationNodeTypes.Reference]: ReferenceNode,
};

export const edgeDefaults = (markerOrient?: string) => ({
  type: "default",
  style: { stroke: Colors.GRAY1 },
  markerEnd: {
    type: MarkerType.ArrowClosed,
    width: 25,
    height: 20,
    strokeWidth: 0,
    color: Colors.GRAY1,
    ...(markerOrient && { markerOrient }),
  },
  interactionWidth: 0,
});

export const defaultViewport = { x: 125, y: 100, zoom: -1 };

export const NODE_WIDTH = 140;
export const NODE_WIDTH_PADDING = 48;
export const NODE_HEIGHT = 100;
