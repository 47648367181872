import { useCallback, useEffect, useState } from "react";
import { HTMLTable, Icon, InputGroup, Intent, Menu, MenuDivider, PopoverPosition, Tab, TabId } from "@blueprintjs/core";
import { observer } from "mobx-react";

import { AnchorButton } from "@components/AnchorButton";
import { Button } from "@components/Button";
import { DeleteConfirmationDialog } from "@components/DeleteConfirmationDialog";
import { Loading } from "@components/Loading";
import { MenuItem } from "@components/MenuItem";
import { MenuItemDelete } from "@components/MenuItems";
import { Popover } from "@components/Popover";
import { RelativeDateWithTooltip } from "@components/RelativeDateWithTooltip";
import { Tabs } from "@components/Tabs";
import { TabTitle } from "@components/TabTitle";
import { showToast } from "@components/UiLayers/toaster";
import UserInfo from "@components/UserInfo/UserInfo";
import { getRoleName, isAdmin, UserPermission, UserRole } from "@rollup-api/api/authTypes";
import { Profile } from "@rollup-api/models/profiles";
import { ProfileSortKeys } from "@rollup-api/models/profiles/ProfileSortKeys";
import { GetProfilesRequestDto, ProfileStatuses } from "@rollup-api/models/profiles/profilesRequestDto.model";
import { SortOrders } from "@rollup-api/SortOrders";
import appStore from "@store/AppStore";
import { copyToClipboard } from "@utilities";
import { rollupClient } from "src/core/api";
import { Text, TextVariant } from "src/ui/Text";

import "./SettingsMembers.scss";
enum FetchStatus {
  NotLoaded = "notLoaded",
  Fetching = "fetching",
  Loaded = "loaded",
  Error = "error",
}

/** Main function. */
const SettingsMembers = () => {
  const [paginatedProfiles, setPaginatedProfiles] = useState<Profile[]>([]);
  const [paginatedInvitedProfiles, setPaginatedInvitedProfiles] = useState<Profile[]>([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [activeTab, setActiveTab] = useState<TabId>("all");
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [memberFetchStatus, setMemberFetchStatus] = useState<FetchStatus>(FetchStatus.NotLoaded);
  const [itemToDelete, setItemToDelete] = useState<Profile>();

  const fetchProfiles = async (
    statuses: Array<ProfileStatuses>,
    take: number,
    skip: number,
    sortedBy: ProfileSortKeys,
    sortOrder: SortOrders,
    sTerm?: string
  ) => {
    const dto: GetProfilesRequestDto = {
      statuses,
      take,
      skip,
      sortedBy,
      sortOrder,
      search: sTerm || undefined,
    };
    setMemberFetchStatus(FetchStatus.Fetching);
    const profilesResponse: Profile[] | undefined = await rollupClient.organizations.retrieveProfiles(dto);
    if (profilesResponse) {
      if (statuses.includes(ProfileStatuses.ACTIVE)) {
        setPaginatedProfiles(profilesResponse);
      } else if (statuses.includes(ProfileStatuses.INVITED)) {
        setPaginatedInvitedProfiles(profilesResponse);
      }
      setMemberFetchStatus(FetchStatus.Loaded);
    } else {
      setMemberFetchStatus(FetchStatus.Error);
    }
  };

  const refreshLists = useCallback(() => {
    if (activeTab === "invited") {
      fetchProfiles([ProfileStatuses.INVITED], 1000, 0, ProfileSortKeys.name, SortOrders.DESC, searchTerm);
    } else {
      fetchProfiles([ProfileStatuses.ACTIVE], 1000, 0, ProfileSortKeys.name, SortOrders.DESC, searchTerm);
    }
  }, [activeTab, searchTerm]);

  useEffect(() => {
    refreshLists();
  }, [refreshLists]);

  const removeUser = async (member: Profile) => {
    if (appStore.orgModel && appStore.userModel?.hasPermission(UserPermission.DeleteUsers)) {
      if (await appStore.orgModel.info.removeOrgUserById(member.id)) {
        showToast(`${member.name} has been removed from the organization.`, "success", "info-sign");
        refreshLists();
        return;
      }
    }
    showToast("Couldn't perform operation.", Intent.DANGER, "error");
  };

  const makeUserAdmin = async (member: Profile) => {
    if (appStore.userModel?.hasPermission(UserPermission.PromoteUsers)) {
      if (await appStore.orgModel.info.changeUserRoleById(member.id, UserRole.Admin)) {
        showToast(`${member.name} has been moved to the Administrators group.`, "success", "info-sign");
        refreshLists();
        return;
      }
      showToast("Couldn't perform operation.", Intent.DANGER, "error");
    }
  };

  const makeUserUser = async (member: Profile) => {
    if (appStore.userModel?.hasPermission(UserPermission.PromoteUsers)) {
      if (await appStore.orgModel.info.changeUserRoleById(member.id, UserRole.User)) {
        showToast(`${member.name} has been moved to the Users group.`, "success", "info-sign");
        refreshLists();
        return;
      }
      showToast("Couldn't perform operation.", Intent.DANGER, "error");
    }
  };

  const handleCopyToClipboardClick = async (value: string): Promise<void> => {
    try {
      await copyToClipboard(value);
      showToast("Copied email info to clipboard", "success", "info-sign");
    } catch (err) {
      return;
    }
  };

  const renderUserRoleField = (currentUser: Profile) => {
    if (!currentUser) {
      return;
    }
    // Display role and change role menu
    if (appStore.userModel?.hasPermission(UserPermission.PromoteUsers) && currentUser.active) {
      return (
        <Popover
          placement="bottom"
          content={
            <Menu className="promote-demote-menu">
              <>
                {isAdmin(currentUser) && (
                  <MenuItem text="Make a user" onClick={() => makeUserUser(currentUser)} e2eIdentifiers="make-a-user" />
                )}
                {!isAdmin(currentUser) && (
                  <MenuItem
                    text="Make an administrator"
                    onClick={() => makeUserAdmin(currentUser)}
                    e2eIdentifiers="make-an-administrator"
                  />
                )}
              </>
            </Menu>
          }
        >
          <Button e2eIdentifiers="promote-demote-user-button" minimal rightIcon="chevron-down">
            <Text variant={TextVariant.Body}>{getRoleName(currentUser)}</Text>
          </Button>
        </Popover>
      );
    }
    // Display role only
    return <Text variant={TextVariant.Body}>{getRoleName(currentUser)}</Text>;
  };

  const removeUserMenuOption = (currentUser: Profile) => {
    if (appStore.userModel?.hasPermission(UserPermission.DeleteUsers) && currentUser.active) {
      return (
        <MenuItemDelete
          onDelete={() => {
            setItemToDelete(currentUser);
            setIsDeleteDialogOpen(true);
          }}
        />
      );
    }
  };

  // TODO: Complete implementation once BE is done
  // const resendInvitationMenuOption = (currentUser: Profile) => {
  //   if (appStore.userModel?.hasPermission(UserPermission.InviteUsers) && !currentUser.active) {
  //     return <MenuItem text="Resend invitation" disabled onClick={() => console.debug("Resend invitation not implemented yet")} />;
  //   }
  // };

  // TODO: Complete implementation once BE is done
  // const withdrawInvitationMenuOption = (currentUser: Profile) => {
  //   if (appStore.userModel?.hasPermission(UserPermission.InviteUsers) && !currentUser.active) {
  //     return (
  //       <MenuItem
  //         text="Withdraw invitation"
  //         disabled
  //         intent={Intent.DANGER}
  //         onClick={() => console.debug("Withdraw invitation not implemented yet")}
  //       />
  //     );
  //   }
  // };

  const renderMoreOptionsButton = (currentUser: Profile) => {
    return (
      <Popover
        placement="bottom-end"
        content={
          <Menu>
            {/* TODO: Uncomment once BE is done */}
            {/* {resendInvitationMenuOption(currentUser)} */}
            <MenuItem
              icon="duplicate"
              text="Copy email"
              onClick={() => {
                if (currentUser.email) {
                  handleCopyToClipboardClick(currentUser.email);
                }
              }}
              e2eIdentifiers="copy-email"
            />
            <MenuDivider />
            {removeUserMenuOption(currentUser)}
            {/* TODO: Uncomment once BE is done */}
            {/* {withdrawInvitationMenuOption(currentUser)} */}
          </Menu>
        }
      >
        <AnchorButton minimal rightIcon="more" e2eIdentifiers="show-menu" />
      </Popover>
    );
  };

  const renderMemberList = (members: Profile[]) => {
    if (memberFetchStatus === FetchStatus.Fetching && members.length === 0) {
      return (
        <div className="loading-box">
          <Loading size={32} />
        </div>
      );
    }

    return (
      <>
        <DeleteConfirmationDialog
          titleItem="user"
          descriptionItem="this user"
          disabled={!appStore.userModel?.hasPermission(UserPermission.PromoteUsers)}
          isOpen={isDeleteDialogOpen}
          onClose={() => setIsDeleteDialogOpen(false)}
          onCancel={() => setIsDeleteDialogOpen(false)}
          onConfirm={() => {
            itemToDelete && removeUser(itemToDelete);
            setIsDeleteDialogOpen(false);
          }}
        />
        <div className="members-area">
          <HTMLTable compact className="min-w-full">
            <thead>
              <tr>
                <th>
                  <Text variant={TextVariant.H5}>User</Text>
                </th>
                <th>
                  <Text variant={TextVariant.H5}>Last active</Text>
                </th>
                <th>
                  <Text variant={TextVariant.H5}>Organization permission</Text>
                </th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {!members.length ? (
                <tr>
                  <td colSpan={3} className="message">
                    <Text variant={TextVariant.Caption}>You don't have any members meeting the filter criteria</Text>
                  </td>
                </tr>
              ) : (
                members.map((currentUser: Profile) => (
                  <tr key={currentUser.id}>
                    <td>
                      <UserInfo userName={currentUser.name} email={currentUser.email} avatarUrl={currentUser.avatarUrl} />
                    </td>
                    <td>
                      {currentUser.lastActiveTime && (
                        <RelativeDateWithTooltip
                          popoverPosition={PopoverPosition.TOP}
                          variant={TextVariant.Body}
                          epochTime={currentUser.lastActiveTime}
                        />
                      )}
                    </td>
                    <td>{renderUserRoleField(currentUser)}</td>
                    <td>{renderMoreOptionsButton(currentUser)}</td>
                  </tr>
                ))
              )}
            </tbody>
          </HTMLTable>
        </div>
      </>
    );
  };

  return (
    <div className="settings-layout--content">
      <div className="settings-layout--header">
        <Text variant={TextVariant.H1}>Members</Text>
        <div className="settings-layout--header--right-section">
          <InputGroup
            className="settings-layout--search"
            large
            value={searchTerm}
            onChange={e => setSearchTerm(e.target.value)}
            placeholder="Search for member..."
            leftElement={<Icon icon="search" />}
          />
          <Button
            large
            intent="primary"
            icon="new-person"
            className="settings-layout--cta"
            onClick={() => {
              appStore.inviteNewUser.show();
            }}
            disabled={!appStore.userModel?.hasPermission(UserPermission.InviteUsers)}
            e2eIdentifiers="invite-new-user"
          >
            Invite member
          </Button>
        </div>
      </div>
      <Tabs id="members-tabs" selectedTabId={activeTab} onChange={newTabId => setActiveTab(newTabId)} large>
        <Tab id="all" title={<TabTitle title="All" />} panel={renderMemberList(paginatedProfiles.filter(profile => profile.active))} />
        <Tab
          id="administrators"
          title={<TabTitle title="Administrators" />}
          panel={renderMemberList(paginatedProfiles.filter(profile => isAdmin(profile) && profile.active))}
        />
        <Tab
          id="users"
          title={<TabTitle title="Users" />}
          panel={renderMemberList(paginatedProfiles.filter(profile => !isAdmin(profile) && profile.active))}
        />
        <Tab id="invited" title={<TabTitle title="Invited" />} panel={renderMemberList(paginatedInvitedProfiles)} />
      </Tabs>
    </div>
  );
};

/** Exports. */
export default observer(SettingsMembers);
