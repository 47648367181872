import { useCallback, useEffect, useRef, useState } from "react";
import { useOnMount } from "@hooks/useOnMount";
import { useSetActiveAttachmentFromHash } from "@hooks/useSetActiveAttachmentFromHash";
import { observer } from "mobx-react";
import { useWindowSize } from "usehooks-ts";

import CatalogItemPreview from "@components/CatalogItemPreview";
import CatalogItemTableBar from "@components/CatalogItems/TableBar";
import { calcDefaultSize, calcMaxSize } from "@components/CatalogItems/utils";
import { FILE_FORMATS, FILE_IMPORT_LIMIT } from "@components/CreateCatalogItemDialog/constants";
import { CreateCatalogItemDialogType } from "@components/CreateCatalogItemDialog/types";
import FileDropZone from "@components/FileDropZone";
import SplitPaneGroup from "@router/components/Panes/SplitPaneGroup";
import appStore from "@store/AppStore";

import CatalogItemsTable from "./Table/CatalogItemsTable";
import { leftPaneMinWidth, topPaneMinHeight } from "./constants";

import styles from "./CatalogItemsFrame.module.scss";

/** Main function. */
function CatalogItemsFrame() {
  const ref = useRef<HTMLDivElement>(null);
  const { height: windowHeight, width: windowWidth } = useWindowSize();
  const previewOpen = !!appStore.ui.catalogItemPreviewId || !!appStore.ui.catalogItemReferencePreviewId;
  const [panel2Size, setPanel2Size] = useState<number>();
  const { isCatalogItemTableVerticalSplit, catalogItemTableSize, setCatalogItemTableSize } = appStore.env;
  const { setCatalogItemPreviewId } = appStore.ui;
  const { catalogItems } = appStore.orgModel;

  useSetActiveAttachmentFromHash(appStore.orgModel.attachments);

  const updatePanel2Size = useCallback(
    (newSize: number) => {
      if (ref.current) {
        const newPanel2Size = isCatalogItemTableVerticalSplit ? ref.current.offsetWidth - newSize : ref.current.offsetHeight - newSize;
        setPanel2Size(newPanel2Size);
      }
    },
    [isCatalogItemTableVerticalSplit]
  );

  useOnMount(() => {
    setCatalogItemPreviewId(catalogItems.catalogItems[0]?.id);
  });

  useEffect(() => {
    if (panel2Size === undefined && catalogItemTableSize > 0 && ref.current) {
      updatePanel2Size(catalogItemTableSize);
    }
  }, [catalogItemTableSize, panel2Size, updatePanel2Size]);

  const handleDragFinished = (newSize: number) => {
    setCatalogItemTableSize(newSize);
    updatePanel2Size(newSize);
  };

  const calcSize = (): number | undefined => {
    if (ref.current && panel2Size !== undefined) {
      const containerWidth = ref.current.offsetWidth;
      const containerHeight = ref.current.offsetHeight;

      if (!previewOpen) {
        return isCatalogItemTableVerticalSplit ? containerWidth : containerHeight;
      }

      const size = isCatalogItemTableVerticalSplit ? containerWidth - panel2Size : containerHeight - panel2Size;
      const maxSize = calcMaxSize(ref.current, isCatalogItemTableVerticalSplit);
      return Math.min(size, maxSize);
    }
  };

  const handleFileDrop = (fileList: FileList) => {
    appStore.ui.setCatalogItemCreationModalTab(CreateCatalogItemDialogType.CAD);
    appStore.ui.setCadFileUploadData({ fileList });
  };

  return (
    <div ref={ref} className={styles.catalogItemsFrame}>
      <SplitPaneGroup
        onDragFinished={handleDragFinished}
        defaultSize={
          isCatalogItemTableVerticalSplit
            ? calcDefaultSize(windowWidth, catalogItemTableSize, previewOpen)
            : calcDefaultSize(windowHeight, catalogItemTableSize, previewOpen)
        }
        size={calcSize()}
        minSize={isCatalogItemTableVerticalSplit ? leftPaneMinWidth : topPaneMinHeight}
        maxSize={ref.current ? calcMaxSize(ref.current, isCatalogItemTableVerticalSplit) : undefined}
        split={isCatalogItemTableVerticalSplit ? "vertical" : "horizontal"}
        resizerClassName={styles.catalogItemsFrameResizer}
      >
        <div className={styles.catalogItemsFrameTopContainer}>
          <CatalogItemTableBar />
          <FileDropZone sizeLimit={FILE_IMPORT_LIMIT} format={FILE_FORMATS} onChange={handleFileDrop}>
            <CatalogItemsTable />
          </FileDropZone>
        </div>
        <CatalogItemPreview />
      </SplitPaneGroup>
    </div>
  );
}

/** Exports. */
export default observer(CatalogItemsFrame);
