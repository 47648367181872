import { Position, Tooltip } from "@blueprintjs/core";
import { observer } from "mobx-react";

import SelectIconDark from "@assets/icons/hoopsIcons/dark/select_dark.svg?react";
import SelectIconLight from "@assets/icons/hoopsIcons/light/select_light.svg?react";
import { Button } from "@components/Button";
import { LegacyCustomIcon } from "@components/Shared/LegacyCustomIcon/LegacyCustomIcon";
import appStore from "@store/AppStore";

import styles from "../HoopsMenu.module.scss";

type HoopsMenuSelectButtonProps = {
  viewer: Communicator.WebViewer;
};

const HoopsMenuSelectButton = ({ viewer }: HoopsMenuSelectButtonProps) => {
  const handleClick = () => {
    // reset all the operators to the standard ones
    viewer.operatorManager.clear();
    appStore.env.attachmentViewer?.standardOperatorIds.forEach(op => viewer.operatorManager.push(op));
    appStore.env.attachmentViewer?.setActiveCustomOperator(0);

    appStore.env.attachmentViewer?.deactivateAnnotationMode(viewer);
  };

  return (
    <Tooltip position={Position.RIGHT} hoverOpenDelay={500} content="Select">
      <Button
        className={styles.hoopsToolbarButtonAndButtonGroup}
        onClick={handleClick}
        icon={<LegacyCustomIcon large icon={appStore.env.themeIsDark ? <SelectIconDark /> : <SelectIconLight />} />}
        e2eIdentifiers="hoops-menu-select-button"
      />
    </Tooltip>
  );
};

export default observer(HoopsMenuSelectButton);
