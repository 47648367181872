import { Edge, Node, Position } from "reactflow";
import { ElkNode } from "elkjs/lib/elk-api";
import { DirectedGraph } from "graphology";

import { CatalogItemNodeData } from "@components/CatalogItemPreview/Components/RelationsCard/Nodes/CatalogItemNode/CatalogItemNode";
import { elk } from "@components/Modeling/ModelingFrame/FlowView/elk";
import { CatalogItemChildRef } from "@rollup-api/models/catalogItem/catalogItemDtos";
import appStore from "@store/AppStore";
import { ICatalogItem } from "@store/CatalogItem/CatalogItemStore";
import { ICatalogItemVersion } from "@store/CatalogItem/CatalogItemVersionStore";

import { edgeDefaults, NODE_HEIGHT, NODE_WIDTH, NODE_WIDTH_PADDING } from "./constants";
import { CustomNodeType, RelationData, RelationNodeTypes, TreeNodeData } from "./types";

export const getFileRelationData = (catalogItem: ICatalogItem, version: ICatalogItemVersion): RelationData => {
  const attachmentId = version.attachmentId;
  const nodeId = version?.metaData?.nodeId;
  const edges: Edge[] = [];
  const nodes: Node[] = [];

  if (attachmentId) {
    nodes.push({
      id: attachmentId,
      type: RelationNodeTypes.File,
      position: { x: 0, y: 0 },
      sourcePosition: Position.Right,
      data: { catalogItem, activeVersion: version.id, type: CustomNodeType.INPUT },
    });

    const childReferences = nodeId ? appStore.orgModel.catalogItems.getChildRefs(attachmentId, nodeId) : [];
    const attachmentParts = appStore.orgModel.catalogItems.getAttachmentParts(attachmentId);
    const currentVersionIndex = attachmentParts.findIndex(v => v.catalogItem.id === catalogItem.id);

    attachmentParts.map((v: ICatalogItemVersion, index: number) => {
      const relatedIndex = index - currentVersionIndex;
      const versionCatalogItemId = v.catalogItem.id;
      const isCurrentItem = versionCatalogItemId === catalogItem.id;
      const yPosition = isCurrentItem ? 0 : relatedIndex * 50;
      const versionNodeId = v.metaData?.nodeId;

      nodes.push({
        id: versionCatalogItemId,
        type: RelationNodeTypes.CatalogItem,
        position: { x: 200, y: yPosition },
        sourcePosition: Position.Right,
        targetPosition: Position.Left,
        data: { catalogItem: v.catalogItem },
      });
      edges.push({
        id: `edge-${v.id}-${versionNodeId}`,
        source: attachmentId,
        target: versionCatalogItemId,
        ...edgeDefaults(),
      });
    });
    childReferences.map((reference: CatalogItemChildRef, index: number) => {
      const yPosition = index * 100;
      const yAligner = (childReferences.length * 100) / 2;
      nodes.push({
        id: `${reference.refId}${index}`,
        type: RelationNodeTypes.Reference,
        targetPosition: Position.Left,
        position: { x: 500, y: yPosition - yAligner },
        data: { reference },
      });
      edges.push({
        id: `edge-${reference.refId}-${index}`,
        source: catalogItem.id,
        target: `${reference.refId}${index}`,
        ...edgeDefaults,
      });
    });
  }

  return { edges, nodes };
};

export function fillHierarchicalGraph(catalogItem: ICatalogItem, graph: DirectedGraph<TreeNodeData>) {
  if (!graph) {
    return false;
  }

  if (!graph.hasNode(catalogItem.id)) {
    graph.addNode(catalogItem.id, { catalogItem });
  }

  for (const child of catalogItem.validatedChildren) {
    graph.addNode(child.id, { catalogItem: child });
    graph.addEdge(catalogItem.id, child.id);
    fillHierarchicalGraph(child, graph);
  }

  for (const reference of catalogItem.references) {
    graph.addNode(reference.nodeId, { catalogItem, reference: reference });
    graph.addEdge(catalogItem.id, reference.nodeId);
  }

  return true;
}

const handleGraphUpdate = (generatedGraph: ElkNode, inputGraph: DirectedGraph<TreeNodeData>): RelationData => {
  if (!inputGraph || !generatedGraph?.children) {
    return { nodes: [], edges: [] };
  }

  const nodes: Node<CatalogItemNodeData>[] = [];

  for (const elkNode of generatedGraph.children) {
    const { id, width, height } = elkNode;
    const nodeAttributes = inputGraph.getNodeAttributes(elkNode.id);
    const { catalogItem, reference } = nodeAttributes;

    if (!catalogItem) {
      return { nodes: [], edges: [] };
    }

    const data: TreeNodeData = {
      catalogItem,
      reference,
    };

    nodes.push({
      id,
      type: reference ? RelationNodeTypes.Reference : RelationNodeTypes.CatalogItem,
      width,
      height,
      data,
      targetPosition: Position.Top,
      sourcePosition: Position.Bottom,
      position: { x: elkNode.x ?? 0, y: elkNode.y ?? 0 },
    });
  }

  const edges: Edge[] = [];

  if (generatedGraph.edges) {
    for (const elkEdge of generatedGraph.edges) {
      const { id, sources, targets } = elkEdge;
      const source = sources?.[0];
      const target = targets?.[0];
      if (source && target) {
        edges.push({ id, source, target, ...edgeDefaults("90") });
      }
    }
  }

  return { nodes, edges };
};

export const getTreeRelationData = async (catalogItem: ICatalogItem): Promise<RelationData> => {
  const inputGraph = new DirectedGraph<TreeNodeData>();
  fillHierarchicalGraph(catalogItem, inputGraph);

  const measureContext = document.createElement("canvas").getContext("2d");
  if (measureContext) {
    measureContext.font = "16px sans-serif";
  }

  let width = NODE_WIDTH;

  const graph: ElkNode = {
    id: "root",
    children: inputGraph.mapNodes((id, node) => {
      if (measureContext) {
        width = measureContext.measureText(node.catalogItem.name).width;
        width = Math.ceil(width) + NODE_WIDTH_PADDING;
      }
      return { id, width, height: NODE_HEIGHT };
    }),
    edges: inputGraph.mapEdges((id, _attr, source, target) => {
      return {
        id,
        sources: [source],
        targets: [target],
      };
    }),
  };

  const graphResult = await elk.layout(graph, { layoutOptions: { "elk.algorithm": "mrtree", "elk.direction": "down" } });
  return handleGraphUpdate(graphResult, inputGraph);
};
