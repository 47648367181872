import { Block, BlockExtendedModel, BlockUpdateDto, CreateBlockDto } from "@rollup-api/models/block";
import { trackSegmentEvent } from "src/lib/Segment";

import { HttpClient, ParentClient } from "../client";

export class Blocks extends HttpClient {
  public constructor(parent: ParentClient) {
    super(parent);
  }

  private get endpoint() {
    return `workspace/${this.parent.workspaceId}/blocks`;
  }

  public create = (dto: CreateBlockDto) => {
    trackSegmentEvent("block:create", { id: dto.id, label: dto.label, numProperties: dto.instances?.length ?? 0 });
    return this.instance.post<Block>(`${this.endpoint}`, dto);
  };

  public retrieveWithWorkspace = (id: string, workspaceId: string) => {
    return this.instance.get<Block>(`workspace/${workspaceId}/blocks/${id}`);
  };

  public retrieveAllWithWorkspace = (workspaceId: string) => {
    return this.instance.get<BlockExtendedModel[]>(`workspace/${workspaceId}/blocks`);
  };

  public update = (id: string, dto: BlockUpdateDto) => {
    trackSegmentEvent("block:update", { id, dto });
    return this.instance.patch<Block>(`${this.endpoint}/${id}`, dto);
  };

  public delete = (id: string) => {
    trackSegmentEvent("block:delete", { id });
    return this.instance.delete<Block>(`${this.endpoint}/${id}`);
  };

  public reorder = (id: string, destinationId: string) => {
    trackSegmentEvent("block:reorder", { id });
    return this.instance.patch(`${this.endpoint}/${id}/reorder`, { destinationId });
  };

  public setPartNumber = (id: string, schemaId: string) => {
    const dto = { schemaId };
    return this.instance.patch<string>(`${this.endpoint}/${id}/set-part-number`, dto);
  };

  public reparent = (id: string, parentBlockId: string, orderIndex?: number) => {
    trackSegmentEvent("block:reparent", { id });
    const dto = { parentBlock: parentBlockId, orderIndex };
    return this.instance.patch(`${this.endpoint}/${id}/reparent`, dto);
  };

  public duplicate = (id: string, parentBlockId: string, orderIndex?: number) => {
    trackSegmentEvent("block:duplicate", { id });
    const dto = { parentBlock: parentBlockId, orderIndex };
    return this.instance.post(`${this.endpoint}/${id}/duplicate`, dto);
  };
}
