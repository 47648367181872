import { ColumnMovedEvent, RowDragEndEvent } from "ag-grid-community";
import { observer } from "mobx-react";

import { FormattedTable } from "@components/Modeling/FormattedTable";
import { usePropertyDefinitionTableColDef } from "@components/Modeling/PropertyDefinition/usePropertyDefinitionTableColDef";
import appStore from "@store/AppStore";
import { IPropertyDefinition } from "@store/PropertyDefinitionStore";

import { EPropertyDefinitionsColumn } from "./constants";

import "./PropertyDefinitionTable.scss";

const getValidationErrorMessage = (label: string, definitions: IPropertyDefinition[]) => {
  const existingValues = definitions.map((i: IPropertyDefinition) => i.label);
  if (!label) {
    return "Cannot add a property with an empty name";
  } else if (existingValues.some((existingLabel: string) => label.toLowerCase() === existingLabel.toLowerCase())) {
    return "A property with this name already exists";
  }
  return "";
};

const PropertyDefinitionTable = () => {
  const propertyDefinitions = appStore.workspaceModel?.propertyDefinitions ?? [];

  const handleAddNewRow = (label: string) => {
    appStore.workspaceModel?.addNewPropertyDefinition(label);
  };

  const handleColumnMoved = (e: ColumnMovedEvent<IPropertyDefinition>) => {
    const columnsOrder = e.api.getAllGridColumns().map(c => c.getColId()) as EPropertyDefinitionsColumn[];
    appStore.env.columnsOrder.updatePropertyDefinitionTableColumnsOrder(columnsOrder);
  };

  const onRowDragEnd = (e: RowDragEndEvent<IPropertyDefinition>) => {
    const targetIndex = e.overIndex;
    const nodeId = e.node.data?.id;
    const targetId = propertyDefinitions[targetIndex]?.id;

    if (nodeId && targetId) {
      appStore.workspaceModel?.movePropertyDefinition(nodeId, targetId);
    }
  };

  const columnDefs = usePropertyDefinitionTableColDef(propertyDefinitions);

  return (
    <FormattedTable<IPropertyDefinition>
      className="property-definition-table"
      title="Property Definitions"
      description="These are the property definitions for this workspace."
      addNewLabel="Add new property"
      defaultDragText="Property Definition row"
      columnDefs={columnDefs}
      rowData={propertyDefinitions}
      onRowDragEnd={onRowDragEnd}
      onColumnMoved={handleColumnMoved}
      getValidationErrorMessage={(label: string) => getValidationErrorMessage(label, propertyDefinitions)}
      onAddNewRow={handleAddNewRow}
    />
  );
};

export default observer(PropertyDefinitionTable);
