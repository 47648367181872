import { Select2 } from "@blueprintjs/select";
import { useBool } from "@hooks/useBool/useBool";

import { SelectItem } from "src/ui/Select/Item/SelectItem";

import { CREATE_PROPERTY_SELECT_ITEMS } from "./constants";
import { CreatePropertySelectAnchor } from "./CreatePropertySelectAnchor";
import { CreatePropertyInputType } from "./types";

import "./CreatePropertySelect.scss";

type CreatePropertySelectProps = {
  onChange: (type: CreatePropertyInputType) => void;
  activeType: CreatePropertyInputType;
};

export const CreatePropertySelect = ({ onChange, activeType }: CreatePropertySelectProps) => {
  const [selectOpen, { set: setSelectOpen, unset: setSelectClosed }] = useBool();

  const selectedItem = CREATE_PROPERTY_SELECT_ITEMS.find(({ slug }) => slug === activeType);

  return (
    <Select2
      items={CREATE_PROPERTY_SELECT_ITEMS}
      activeItem={selectedItem}
      onItemSelect={({ slug }) => onChange(slug)}
      itemRenderer={(item, { handleClick, handleFocus, modifiers }) => (
        <SelectItem key={item.slug} {...item} onClick={handleClick} onFocus={handleFocus} active={modifiers.active} />
      )}
      popoverProps={{ minimal: true, onClose: setSelectClosed, onOpening: setSelectOpen }}
      filterable={false}
      className="select-input"
    >
      <CreatePropertySelectAnchor label={selectedItem?.label} open={selectOpen} />
    </Select2>
  );
};
