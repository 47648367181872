import { CreatePropertyDefinitionDto, PropertyDefinition, PropertyDefinitionUpdateDto } from "@rollup-api/models/propertyDefinition";
import { trackSegmentEvent } from "src/lib/Segment";

import { HttpClient, ParentClient } from "../client";

export class PropertyDefinitions extends HttpClient {
  public constructor(parent: ParentClient) {
    super(parent);
  }

  private get endpoint() {
    return `workspace/${this.parent.workspaceId}/property-definitions`;
  }

  public create = (dto: CreatePropertyDefinitionDto) => {
    trackSegmentEvent("property-definition:create", { id: dto.id, label: dto.label });
    return this.instance.post<PropertyDefinition>(this.endpoint, dto);
  };

  public update = (id: string, dto: PropertyDefinitionUpdateDto) => {
    trackSegmentEvent("property-definition:update", { id, label: dto.label });
    return this.instance.patch<PropertyDefinition>(`${this.endpoint}/${id}`, dto);
  };

  public reorder = (id: string, destinationId: string) => {
    trackSegmentEvent("property-definition:reorder", { id, destinationId });
    return this.instance.patch(`${this.endpoint}/${id}/reorder`, { destinationId });
  };

  public delete = (id: string) => {
    trackSegmentEvent("property-definition:delete", { id });
    return this.instance.delete<PropertyDefinition>(`${this.endpoint}/${id}`);
  };
}
