import { Intent, NonIdealState, Tooltip } from "@blueprintjs/core";
import classNames from "classnames";
import { observer } from "mobx-react";

import JpegIcon from "@assets/icons/mimetypes/image/jpeg.svg?react";
import { AnchorButton } from "@components/AnchorButton";
import { Button } from "@components/Button";
import FileDropZone from "@components/FileDropZone";
import AttachmentIcon from "@components/Modeling/ModelingFrame/ModelBlock/Attachments/AttachmentIcon";
import { AttachmentStatus } from "@rollup-api/models/cloudStorage";
import { useAppNavigate } from "@router/hooks";
import appStore from "@store/AppStore";
import { IAttachment } from "@store/AttachmentStore";
import { IBlock } from "@store/BlockStore";
import { getAssetLink } from "@utilities/LinkUtils";

import BlockPreviewCard from "./BlockPreviewCard";

import "./RelatedAttachments.scss";

interface IRelatedAttachmentsProps {
  block: IBlock;
  className?: string;
}

interface IBomAttachmentRowPrams {
  attachment?: IAttachment;
  defaultThumbnailUrl?: string;
}

const RelatedAttachments = (props: IRelatedAttachmentsProps) => {
  const { block, className } = props;
  const { navigateToAttachment } = useAppNavigate();

  const handleView = (attachment: IAttachment) => {
    appStore.env.setActiveAttachment(attachment);
    navigateToAttachment(attachment.id);
  };

  const handleSetDedicatedAttachment = (attachment: IAttachment) => {
    block.setDedicatedAttachment(attachment);
  };

  const renderAttachmentRow = (params: IBomAttachmentRowPrams) => {
    const { attachment, defaultThumbnailUrl } = params;
    const id = attachment?.id ?? defaultThumbnailUrl;
    const previewUrl = attachment?.previewUrl ?? defaultThumbnailUrl ?? "";
    const isDefaultThumbnailRow = !attachment && defaultThumbnailUrl;
    const label = attachment ? attachment.label || attachment.name : "Default thumbnail";
    const isActiveRow = (!block.dedicatedAttachment && isDefaultThumbnailRow) || block.dedicatedAttachment?.id === attachment?.id;

    const onSetDedicatedAttachment = () => {
      if (isDefaultThumbnailRow) {
        block.removeDedicatedAttachment();
      } else if (attachment) {
        handleSetDedicatedAttachment(attachment);
      }
    };

    return (
      <Tooltip
        key={id}
        className="related-attachments--popover-target"
        popoverClassName="related-attachments--popover"
        position="left"
        content={renderPreview(previewUrl)}
        disabled={!previewUrl}
        minimal
      >
        <div
          className={classNames("related-attachments--item", {
            ["related-attachments--item-active"]: isActiveRow,
          })}
        >
          <div className="related-attachments--item-label-container">
            {attachment && <AttachmentIcon attachment={attachment} />}
            {defaultThumbnailUrl && <JpegIcon />}
            <span className="related-attachments--item-label">{label}</span>
          </div>
          <div className="related-attachments--item-buttons-container">
            {previewUrl && (
              <Button
                className={classNames("related-attachments--item-icon", {
                  ["related-attachments--item-icon-pinned"]: isActiveRow,
                })}
                minimal
                icon="paperclip"
                onClick={onSetDedicatedAttachment}
                e2eIdentifiers="set-dedicated-attachment"
              />
            )}
            {attachment?.status === AttachmentStatus.Converted && (
              <AnchorButton
                icon="cube"
                minimal
                small
                intent={Intent.NONE}
                onClick={() => handleView(attachment)}
                e2eIdentifiers="view-attachment"
              />
            )}
          </div>
        </div>
      </Tooltip>
    );
  };

  const renderPreview = (imageUrl: string) => {
    return (
      <div className="related-attachments--preview">
        <div className="related-attachments--item-img" style={{ backgroundImage: `url(${getAssetLink(imageUrl)})` }} />
      </div>
    );
  };

  const renderContent = () => {
    if (!block.attachments?.length && !block.pmImageUrl) {
      return (
        <NonIdealState
          iconSize={20}
          icon="search-template"
          title="No related attachments"
          description="This block doesn’t have attachments"
        />
      );
    }

    return (
      <>
        {renderAttachmentRow({ defaultThumbnailUrl: block.pmImageUrl })}
        {block.attachments?.map(attachment => renderAttachmentRow({ attachment }))}
      </>
    );
  };

  return (
    <BlockPreviewCard title="Attachments" className={className}>
      <div className="related-attachments--items">
        <FileDropZone onChange={files => appStore.workspaceModel?.initiateFileUploadOnDrop(files, block.id)}>
          {() => <div className="related-attachments--file-drop-zone-container">{renderContent()}</div>}
        </FileDropZone>
      </div>
    </BlockPreviewCard>
  );
};

export default observer(RelatedAttachments);
