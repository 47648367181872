import { InputGroup } from "@blueprintjs/core";
import { observer } from "mobx-react";

import { IBlock } from "@store/BlockStore";
import { IBomColumn } from "@store/BomTable/BomColumnStore";
import { IBomTable } from "@store/BomTable/BomTableStore";
import { getSubtotalColumnValue } from "@utilities";

import BlockPreviewCard from "./BlockPreviewCard";

import "./BomProperties.scss";

interface IBomPropertiesProps {
  block: IBlock;
  table: IBomTable;
  className?: string;
}

const BomProperties = (props: IBomPropertiesProps) => {
  const { table, block, className } = props;

  const renderBomProperty = (column: IBomColumn) => (
    <div className="bom-properties--item" key={column.id}>
      {column.label} <InputGroup disabled value={getSubtotalColumnValue(block) as string} fill />
    </div>
  );

  return (
    <BlockPreviewCard title="BOM" className={className}>
      {table.relatedProperties.map(renderBomProperty)}
    </BlockPreviewCard>
  );
};

export default observer(BomProperties);
