import ReportsFrame from "@components/Reports/ReportsFrame";
import Pane from "@router/components/Panes/Pane";

import styles from "./ReportIndex.module.scss";

export const REPORT_PANE = "REPORT_PANE";

function ReportIndex() {
  return (
    <Pane className={styles.reportIndex} containerClassName={styles.reportIndexContent} id={REPORT_PANE}>
      <ReportsFrame />
    </Pane>
  );
}

export default ReportIndex;
