import { useNavigate } from "react-router-dom";

import { showApiErrorToast } from "@components/UiLayers/toaster";
import { AttachmentType } from "@rollup-api/models";
import appStore from "@store/AppStore";
import { IAttachment } from "@store/AttachmentStore";
import { IBlock } from "@store/BlockStore";
import { FilePreviewType, getMimeType, getPreviewType, getWorkspaceById } from "@utilities";
import { downloadFromUrl } from "@utilities/downloadFromUrl";
import { showFileBrowser } from "@utilities/FileUpload";
import { rollupClient } from "src/core/api";
import { trackSegmentEvent } from "src/lib/Segment";

interface IUseAttachmentFunctions {
  attachment?: IAttachment;
  block?: IBlock;
  onOpenDetailsDialog?(): void;
}

/**
 * Hooks.
 * This is specific to "attachments". NOT FOR GENERAL USE!!!
 */
export function useAttachmentFunctions({ attachment, onOpenDetailsDialog }: IUseAttachmentFunctions) {
  const navigate = useNavigate();

  const handleView = () => {
    if (!attachment) {
      return;
    }
    const attachmentIdLink = `#aid=${attachment.id}`;
    trackSegmentEvent("attachment:view", { url: `${window.location.href}${attachmentIdLink}` });
    navigate(attachmentIdLink);
  };

  return {
    handleClick: () => {
      if (!attachment?.workspaceId) {
        return;
      }
      appStore.env.setActiveAttachment(attachment);
      switch (attachment.type) {
        case AttachmentType.reference: {
          const referenceUrl = attachment.getReferenceUrl(attachment.workspaceId);
          if (referenceUrl) {
            trackSegmentEvent("attachment:view", { url: referenceUrl });
            navigate(referenceUrl);
            return;
          }
          break;
        }
        case AttachmentType.url:
        case AttachmentType.integration:
          if (attachment.linkAttachmentUrl) {
            trackSegmentEvent("attachment:view", { url: attachment.linkAttachmentUrl });
            window.open(attachment.linkAttachmentUrl);
            return;
          }
          break;
        case AttachmentType.file: {
          const previewType = getPreviewType(attachment);
          if (previewType !== FilePreviewType.None) {
            handleView();
            return;
          }
          break;
        }
      }
      onOpenDetailsDialog?.();
    },

    handleConfirm: (value: string) => {
      if (attachment && value !== attachment.label) {
        attachment.setLabel(value);
      }
    },

    handleDownload: async () => {
      if (!attachment) {
        return;
      }

      if (attachment.linkAttachmentUrl) {
        window.open(attachment.linkAttachmentUrl, "_blank");
        return;
      }

      try {
        const downloadLink = await rollupClient.attachments.generateDownloadLink(attachment.id, false, attachment.workspaceId!);
        if (downloadLink?.status == 200 && downloadLink.data?.url) {
          downloadFromUrl(downloadLink.data.url);
        } else {
          showApiErrorToast(`Cannot download file: ${downloadLink.data?.message ?? "Unknown error"}`);
        }
      } catch (err) {
        console.warn(err);
      }
    },

    handleView,

    handleRetry: async () => {
      if (!attachment) {
        return;
      }
      await rollupClient.attachments.retryConversion(attachment.id, attachment.workspaceId);
    },

    handleDelete: async () => {
      if (!attachment) {
        return;
      } else if (attachment.workspaceId) {
        const workspace = await getWorkspaceById(attachment.workspaceId);
        workspace?.deleteAttachment(attachment);
      } else {
        appStore.orgModel.attachments.delete(attachment);
      }
    },

    handleAddVersion: async (propsFile?: File) => {
      if (!attachment) {
        return;
      }
      const file = propsFile || (await showFileBrowser());
      if (file) {
        if (getMimeType(file) === attachment.fileType) {
          attachment.addNewVersion(file);
        } else {
          showApiErrorToast("File type mismatch");
        }
      }
    },
  };
}
