import { Icon } from "@blueprintjs/core";

type Props = {
  onClick: () => void;
};

const AddColumnButton = (props: Props) => (
  <div className="add-column-button" onMouseUp={props.onClick}>
    <Icon size={15} icon="plus" />
  </div>
);

export default AddColumnButton;
