import { RefObject } from "react";
import { useNavigate } from "react-router-dom";
import { FormGroup, Intent, Menu, MenuDivider } from "@blueprintjs/core";
import kebabCase from "lodash/kebabCase";
import { observer } from "mobx-react";
import { getSnapshot } from "mobx-state-tree";

import { CustomIcon, ModuleMonochromeIcon } from "@components/CustomIcon";
import { DocumentNameInput } from "@components/DocumentNameInput";
import { MenuItem } from "@components/MenuItem";
import { MenuItemDelete } from "@components/MenuItems";
import TruncatedLabel from "@components/TruncatedLabel";
import { showToast } from "@components/UiLayers/toaster";
import { UserPermission } from "@rollup-api/api/authTypes";
import { useAppNavigate } from "@router/hooks";
import NavLinkAsMenuItem from "@router/navigation/NavLinkAsMenuItem";
import appStore from "@store/AppStore";
import { copyToClipboard, downloadJsonObject } from "@utilities";
import { rollupClient } from "src/core/api";
import { IWorkspaceListItem } from "src/services/WorkspaceService";

import "./WorkspaceMenu.scss";

export const WORKSPACE_NAME_LIMIT = 32;

type Props = {
  fileInputRef: RefObject<HTMLInputElement>;
};

/** Main function. */
function WorkspaceMenu(props: Props) {
  const appNavigate = useAppNavigate();
  const navigate = useNavigate();
  const { fileInputRef } = props;
  const recentlyUpdated = appStore.orgModel.sortedWorkspaces.slice(0, 5);

  const handleExportClicked = async () => {
    if (!appStore.workspaceModel) {
      return null;
    }
    try {
      const result = await rollupClient.workspaces.retrieve(appStore.workspaceModel.id, true);
      downloadJsonObject(result.data, kebabCase(appStore.workspaceModel.label));
    } catch (err) {
      console.error(err);
    }
  };

  const handleDuplicateClicked = async () => {
    try {
      const newId = await appStore.duplicateWorkspace();
      if (newId) {
        appNavigate.navigateToWorkspace(newId);
      }
    } catch (err) {
      console.error(err);
      showToast("Error while duplicating workspace", "danger", "error");
    }
  };

  const handleImportClicked = () => {
    fileInputRef.current?.click();
  };

  const handleViewAll = () => {
    navigate("/settings/workspaces");
  };

  const renderWorkspaceListItem = (workspace: IWorkspaceListItem) => (
    <NavLinkAsMenuItem
      key={workspace.id}
      icon={<CustomIcon icon={ModuleMonochromeIcon.Workspace} />}
      text={TruncatedLabel({ text: workspace.label, limit: WORKSPACE_NAME_LIMIT })}
      selected={workspace.id === appStore.workspaceModel?.id}
      to={`/workspaces/${workspace.id}`}
      e2eIdentifiers={["workspace_menu", workspace.label]}
    />
  );

  return (
    <Menu>
      {appStore.workspaceModel ? (
        <>
          <MenuDivider title="Current workspace" />
          <div className="w-full">
            <FormGroup inline className="w-full p-2 m-0" contentClassName="w-full">
              <DocumentNameInput
                defaultValue={appStore.workspaceModel.label}
                onUpdate={appStore.workspaceModel.setLabel}
                inputGroupProps={{ maxLength: WORKSPACE_NAME_LIMIT, placeholder: "Workspace Name" }}
              />
            </FormGroup>
            <MenuItemDelete
              disabled={!appStore.workspaceModel || !appStore.userModel?.hasPermission(UserPermission.DeleteWorkspaces)}
              text={TruncatedLabel({ text: `Delete ${appStore.workspaceModel?.label}`, limit: WORKSPACE_NAME_LIMIT })}
              onDelete={() =>
                appStore.ui.showDeleteConfirmationDialog({
                  idList: [appStore.workspaceModel!.id],
                  label: appStore.workspaceModel!.label,
                })
              }
              e2eIdentifiers="delete-workspace"
            />
          </div>
        </>
      ) : null}
      {!!appStore.env.recentlyViewedWorkspaces.length && (
        <>
          <MenuDivider title="Recently viewed" />
          {appStore.env.recentlyViewedWorkspaces.map(renderWorkspaceListItem)}
        </>
      )}
      {!!recentlyUpdated.length && (
        <>
          <MenuDivider title="Recently updated" />
          {recentlyUpdated.map(renderWorkspaceListItem)}
        </>
      )}
      <MenuDivider />
      <MenuItem icon="more" text="View all workspaces" onClick={handleViewAll} e2eIdentifiers="view-all-workspaces" />
      <MenuDivider title="Actions" />
      <MenuItem icon="add" text="New workspace" onClick={appStore.ui.toggleAddNewWorkspaceDialog} e2eIdentifiers="new-workspace" />
      <MenuItem icon="import" text="Import workspace" onClick={handleImportClicked} e2eIdentifiers="import-workspace" />
      <MenuItem
        icon="export"
        text={TruncatedLabel({ text: `Export ${appStore.workspaceModel?.label}`, limit: WORKSPACE_NAME_LIMIT })}
        disabled={!appStore.workspaceModel}
        onClick={handleExportClicked}
        e2eIdentifiers="export-workspace"
      />
      <MenuItem
        icon="duplicate"
        text="Duplicate workspace"
        disabled={!appStore.workspaceModel}
        onClick={handleDuplicateClicked}
        e2eIdentifiers="duplicate-workspace"
      />
      <>
        <MenuDivider title="Debugging" />
        <MenuItem
          icon="clipboard"
          disabled={false}
          text={<>Copy Model </>}
          intent={Intent.NONE}
          onClick={() => copyToClipboard(getSnapshot(appStore))}
          e2eIdentifiers="copy-model"
        />
      </>
    </Menu>
  );
}

/** Exports. */
export default observer(WorkspaceMenu);
