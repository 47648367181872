import { cast, types } from "mobx-state-tree";

import { ECustomUnitsColumn } from "@components/Modeling/CustomUnits/constants";
import { EPropertyDefinitionsColumn } from "@components/Modeling/PropertyDefinition/constants";
import { EStatusesColumn } from "@components/Modeling/StatusesTable/constants";
import { ERequirementsTableColumn } from "@components/Requirements/RequirementsTable/constants";

export const ColumnsOrderStore = types
  .model("ColumnsOrder", {
    customUnitsTable: types.array(types.enumeration("ECustomUnitsColumn", Object.values(ECustomUnitsColumn))),
    propertyDefinitionTable: types.array(types.enumeration("EPropertyDefinitionColumn", Object.values(EPropertyDefinitionsColumn))),
    projectStatusesTable: types.array(types.enumeration("EStatusesColumn", Object.values(EStatusesColumn))),
    requirementsTable: types.array(types.enumeration("ERequirementsTableColumn", Object.values(ERequirementsTableColumn))),
  })
  .actions(self => {
    return {
      updateCustomUnitsTableColumnsOrder(columns: ECustomUnitsColumn[]) {
        self.customUnitsTable = cast(columns);
      },
      updatePropertyDefinitionTableColumnsOrder(columns: EPropertyDefinitionsColumn[]) {
        self.propertyDefinitionTable = cast(columns);
      },
      updateProjectStatusesTableColumnsOrder(columns: EStatusesColumn[]) {
        self.projectStatusesTable = cast(columns);
      },
      updateRequirementsTableColumnsOrder(columns: ERequirementsTableColumn[]) {
        self.requirementsTable = cast(columns);
      },
    };
  });
