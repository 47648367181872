import { observer } from "mobx-react";

import SmartAnnotation from "@components/SmartAnnotation/SmartAnnotation";
import { IAnnotation } from "@store/AnnotationStore";
import { moveToAnnotation } from "@utilities/AnnotationUtils";

interface SmartAnnotationsProps {
  annotations: IAnnotation[];
}

const SmartAnnotations = ({ annotations }: SmartAnnotationsProps) => {
  return (
    <>
      {annotations.map(annotation => (
        <SmartAnnotation key={annotation.id} annotation={annotation} onMoveToAnnotation={moveToAnnotation} />
      ))}
    </>
  );
};

export default observer(SmartAnnotations);
