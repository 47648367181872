import { Component, createRef, FocusEvent, FocusEventHandler } from "react";
import { Classes, Intent, NumericInput, NumericInputProps } from "@blueprintjs/core";
import classNames from "classnames";
import debounce from "lodash/debounce";
import { observer } from "mobx-react";

import { showToast } from "@components/UiLayers/toaster";
import { ENTER_KEY, ESCAPE_KEY } from "@constants/keys";

import "./ConfirmableNumericInput.scss";

export interface ConfirmableNumericInputProps extends NumericInputProps {
  numberFormatter?: (val: number) => string;
  tryConfirmValueFromString: (
    valueString: string
  ) => boolean | Promise<boolean> | { success: boolean; message?: string } | Promise<{ success: boolean; message?: string }>;
  onValueConfirmed?: (value: number) => boolean | Promise<boolean>;
  value?: number | string;
  blurredValue?: number | string;
  autoApplyDelay?: number;
  disabled?: boolean;
  id?: string;
  placeholder?: string;
  className?: string;
  fill?: boolean;
  intent?: Intent;
  onBlur?: FocusEventHandler<HTMLInputElement>;
  onFocus?: FocusEventHandler<HTMLInputElement>;
  showButtons?: boolean;
  isMargin?: boolean;
  applyValueOnChange?: boolean;
}

interface ConfirmableNumericInputState {
  inputIsFocused: boolean;
  spinnerPressed: boolean;
  currentInput: string;
  intent?: Intent;
}

class ConfirmableNumericInput extends Component<ConfirmableNumericInputProps, ConfirmableNumericInputState> {
  public state: ConfirmableNumericInputState = {
    currentInput: "",
    inputIsFocused: false,
    spinnerPressed: false,
    intent: undefined,
  };

  private static TempIntentSuccessDuration = 250;
  private static TempIntentWarningDuration = 750;
  private static SpinnerDebounceDuration = 500;

  private intentClearHandle: any;
  private autoApplyHandle: any;
  private readonly inputRef: React.RefObject<NumericInput>;

  constructor(props: ConfirmableNumericInputProps) {
    super(props);
    this.inputRef = createRef<NumericInput>();
  }

  private clearIntent = () => {
    this.setState({ intent: undefined });
  };

  private get isStringInput() {
    return typeof this.props.value === "string";
  }

  private formatValue(value?: number | string) {
    if (typeof value === "number") {
      if (this.props.numberFormatter) {
        return this.props.numberFormatter(value as number);
      } else {
        return value.toString();
      }
    } else {
      return value ?? "";
    }
  }

  private setAutoApplyTimeout = () => {
    if (this.props.autoApplyDelay && !this.props.applyValueOnChange) {
      clearTimeout(this.autoApplyHandle);
      this.autoApplyHandle = setTimeout(this.applyInput, this.props.autoApplyDelay);
    }
  };

  private setTemporaryIntent = (intent: Intent) => {
    this.setState({ intent });

    if (this.intentClearHandle) {
      clearTimeout(this.intentClearHandle);
    }
    const duration =
      intent == Intent.SUCCESS ? ConfirmableNumericInput.TempIntentSuccessDuration : ConfirmableNumericInput.TempIntentWarningDuration;
    this.intentClearHandle = setTimeout(this.clearIntent, duration);
  };

  private debouncedApplyInput = debounce(async (inputValue: number) => {
    const value = this.props.value;

    if (!this.isStringInput && this.props.onValueConfirmed && inputValue !== undefined && value !== inputValue) {
      const success = await this.props.onValueConfirmed(inputValue);
      if (success) {
        setTimeout(() => {
          this.setTemporaryIntent(Intent.SUCCESS);
          this.setState({
            spinnerPressed: false,
            currentInput: this.formatValue(this.props.value),
          });
        });
      }
    }
  }, ConfirmableNumericInput.SpinnerDebounceDuration);

  private applyInput = async (value: string = this.state.currentInput) => {
    const previousValue = this.props.value;
    const parseResult = await this.props.tryConfirmValueFromString(value);
    const parseSuccess = typeof parseResult === "object" ? parseResult.success : parseResult;
    const parseMessage = typeof parseResult === "object" ? parseResult.message : "";
    clearTimeout(this.autoApplyHandle);

    // Timeout needed in order to ensure the props value is updated
    // If the property type is a string, do not perform validation
    if (!this.isStringInput) {
      setTimeout(() => {
        if (parseSuccess && previousValue !== this.props.value) {
          this.setTemporaryIntent(Intent.SUCCESS);
        } else if (!parseSuccess) {
          this.setTemporaryIntent(Intent.WARNING);
          if (parseMessage) {
            showToast(parseMessage, "warning", "refresh", "", 2500);
          }
        }
        this.setState({ currentInput: this.formatValue(this.props.value) });
      });
    }

    return parseSuccess;
  };

  private handleSpinnerClicked = (value: number) => {
    this.setState({
      spinnerPressed: true,
      currentInput: this.formatValue(value),
    });
    this.debouncedApplyInput(value);
    clearTimeout(this.autoApplyHandle);
  };

  private handleBlur = () => {
    if (!this.props.applyValueOnChange) {
      this.applyInput();
    }
    this.setState({ inputIsFocused: false });
  };

  private handleFocus = (e: FocusEvent<HTMLInputElement>) => {
    this.props.onFocus?.(e);
    const stringValue = this.formatValue(this.props.value);

    this.setState({
      inputIsFocused: true,
      currentInput: stringValue,
    });
  };

  private handleValueChanged = (_value: number, valueString: string) => {
    this.setAutoApplyTimeout();
    this.setState({ currentInput: valueString });
    if (this.props.applyValueOnChange) {
      this.applyInput(valueString);
    }
  };

  private handleKeyDown = async (ev: React.KeyboardEvent) => {
    if (ev.key === ENTER_KEY) {
      if (!this.props.applyValueOnChange) {
        if (await this.applyInput()) {
          this.inputRef?.current?.inputElement?.blur();
        }
      } else {
        this.inputRef?.current?.inputElement?.blur();
      }
    } else if (ev.key === ESCAPE_KEY) {
      this.setState({ currentInput: this.formatValue(this.props.value) });
    }
  };

  render() {
    // Just to remove specific props
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { value, blurredValue, numberFormatter, tryConfirmValueFromString, onValueConfirmed, autoApplyDelay, onFocus, ...otherProps } =
      this.props;

    const currentValue = this.state.inputIsFocused ? value : (blurredValue ?? value);

    const showTempValue = this.state.inputIsFocused || this.state.spinnerPressed;
    let formattedValue: string | undefined;
    if (typeof currentValue === "number") {
      formattedValue = isFinite(currentValue) ? this.formatValue(currentValue) : "NaN";
    } else {
      formattedValue = currentValue;
    }

    return (
      <NumericInput
        disabled={this.props.disabled}
        id={this.props.id ? this.props.id : ""}
        ref={this.inputRef}
        onFocus={this.handleFocus}
        onBlur={this.props.onBlur ? this.props.onBlur : this.handleBlur}
        fill={this.props.fill}
        placeholder={this.props.placeholder ? this.props.placeholder : "value"}
        step={undefined}
        onKeyDown={this.handleKeyDown}
        locale="en-US"
        selectAllOnFocus
        asyncControl
        className={classNames("confirmable-numeric-input", Classes.INPUT, {
          "confirmable-numeric-input--focused": this.state.inputIsFocused,
        })}
        intent={this.state.intent ?? this.props.intent}
        buttonPosition={this.props.showButtons ? (this.isStringInput ? "none" : "right") : "none"}
        allowNumericCharactersOnly={false}
        onValueChange={this.handleValueChanged}
        onButtonClick={this.handleSpinnerClicked}
        inputClassName="confirmable-numeric-input--input"
        {...otherProps}
        value={showTempValue ? this.state.currentInput : formattedValue}
      />
    );
  }
}

export default observer(ConfirmableNumericInput);
