import { AxiosResponse } from "axios";
import Cookies from "js-cookie";
import { jwtDecode, JwtPayload } from "jwt-decode";

import { PasswordValidationResult, PublicOrgRto } from "@rollup-api/models";
import { createNewLogger } from "@utilities/LoggingUtils";
import { rollupClient } from "src/core/api";

import { TokenError, UserPermission, UserRole } from "./authTypes";
import { HttpClient, IAxiosRequestConfigWithCustomData, ParentClient } from "./client";

const userPermissions = [UserPermission.InviteUsers];
const adminPermissions = [
  ...userPermissions,
  UserPermission.PromoteUsers,
  UserPermission.DeleteUsers,
  UserPermission.DeleteWorkspaces,
  UserPermission.UpdateOrganization,
];
const ownerPermissions = [...adminPermissions, UserPermission.DeleteOrganization];

const logger = createNewLogger("auth");

function getPermissions(roles?: UserRole[]): UserPermission[] {
  if (!roles) {
    return [];
  }
  const permissions = new Set<UserPermission>();
  for (const role of roles) {
    switch (role) {
      case UserRole.Owner:
        ownerPermissions.forEach(p => permissions.add(p));
        break;
      case UserRole.Admin:
        adminPermissions.forEach(p => permissions.add(p));
        break;
      case UserRole.User:
        userPermissions.forEach(p => permissions.add(p));
        break;
    }
  }
  return Array.from(permissions);
}

interface ITokenResponse {
  success: boolean;
  token?: string;
  message?: string;
}

export interface AuthClaims {
  userId: string;
  sessionId?: string;
  orgId?: string;
  slug?: string;
  roles?: UserRole[];
  permissions?: UserPermission[];
}

export const isVercel = (hostname = window.location.hostname) => hostname.endsWith("rollup.vercel.app");

const MAX_REFRESH_TOKEN_RETRIES = 2;

export class Auth extends HttpClient {
  // Tokens within 10 seconds of expiry considered invalid
  private static TokenExpiryBuffer = 10;
  // Docs tokens expire after 30 days, so a buffer of 1 day is fine
  private static DocsTokenExpiryBuffer = 3600 * 24;
  private token?: string;
  private isAuthQueued = false;
  private refreshTokenRetries = 0;
  private refreshAccessTokenPromise: Promise<ITokenResponse> = Promise.resolve({ success: false });

  public constructor(parent: ParentClient) {
    super(parent, "/auth");
  }

  private readonly endpoint: string = "";

  public get accessToken() {
    return this.token;
  }

  public refreshTokenIfNecessary(): Promise<ITokenResponse> {
    if (this.checkToken(this.token)) {
      return Promise.resolve({ success: true, token: this.token });
    }

    if (this.isAuthQueued) {
      console.debug("Access token is invalid, waiting for refresh");
    } else {
      console.debug("Access token is invalid, refreshing");
      this.refreshAccessTokenPromise = this.refreshAccessToken();
    }
    return this.refreshAccessTokenPromise;
  }

  private static GenerateTokenClaims(token: string): AuthClaims | undefined {
    if (!token) {
      return undefined;
    }
    try {
      const decodedJwt = jwtDecode<any>(token);
      if (!decodedJwt?.sub) {
        console.warn("Malformed access token payload");
        return undefined;
      }
      const orgClaims = decodedJwt["https://stytch.com/organization"];
      const sessionClaims = decodedJwt["https://stytch.com/session"];
      if (!orgClaims || !sessionClaims) {
        return undefined;
      }

      const { organization_id: orgId, slug } = orgClaims;
      const { roles, id: sessionId } = sessionClaims;
      const userId = decodedJwt.sub;
      const permissions = getPermissions(roles);
      return { orgId, slug, userId, roles, permissions, sessionId };
    } catch (err) {
      console.warn("Problem decoding access token");
    }
    return undefined;
  }

  public get tokenClaims(): AuthClaims | undefined {
    if (!this.token) {
      return undefined;
    }
    return Auth.GenerateTokenClaims(this.token);
  }

  private static tokenExpiryInSeconds(token?: string) {
    if (!token) {
      return -1;
    }

    const exp = jwtDecode<JwtPayload>(token)?.exp;
    if (!exp) {
      return -1;
    }
    return exp - Date.now() / 1000;
  }

  private setToken(token: string) {
    const truncatedToken = `${token.slice(0, 5)}...${token.slice(-5)}`;
    console.debug(`Setting access token to ${truncatedToken}`);
    this.instance.defaults.headers["Authorization"] = `Bearer ${token}`;
    this.token = token;
    sessionStorage.setItem("access_token", token);

    // TODO: set path to a fixed route /assets/secure
    try {
      const backendUrl = new URL(rollupClient.url);
      const domain = backendUrl.hostname?.replace(/^api/, "");
      if (!isVercel()) {
        Cookies.set("rollup_token", token, { sameSite: "strict", secure: true, domain });
      }
    } catch (error) {
      console.warn("URL is invalid");
    }
  }

  public checkToken(token?: string, expiryBuffer = Auth.TokenExpiryBuffer) {
    const expiry = Auth.tokenExpiryInSeconds(token);
    return expiry > expiryBuffer;
  }

  public checkTokenSlug(token: string) {
    const hostname = window.location.hostname;
    // Localhost and vercel preview URLs are allowed to use any slug
    if (hostname === "localhost" || isVercel()) {
      return true;
    }

    const claims = Auth.GenerateTokenClaims(token);
    return claims?.slug && hostname.toLowerCase().startsWith(`${claims.slug.toLowerCase()}.`);
  }

  public clearToken() {
    delete this.instance.defaults.headers["Authorization"];
    this.token = undefined;
    sessionStorage.removeItem("access_token");
  }

  public refreshAccessToken = async (): Promise<ITokenResponse> => {
    const token = sessionStorage.getItem("access_token");
    if (token && this.checkToken(token) && this.checkTokenSlug(token)) {
      this.setToken(token);
      return { success: true };
    } else {
      logger.debug("Token is not valid:", token);
      return this.getToken();
    }
  };

  private async getToken(): Promise<ITokenResponse> {
    try {
      this.isAuthQueued = true;
      const res = await this.instance.get<{
        accessToken?: string;
        message?: TokenError;
      }>(`${this.endpoint}/refresh`, {
        withCredentials: true,
        skipTokenCheck: true, // we're inside the token check logic, let's not re-run it
      } as IAxiosRequestConfigWithCustomData);
      const token = res.data?.accessToken;
      if (res.status === 200 && token) {
        logger.debug("Refreshed access token");
        this.setToken(token);
        this.isAuthQueued = false;
        this.refreshTokenRetries = 0;
        return { token, success: true };
      } else {
        logger.debug("Failed to refresh token:", res.data?.message ?? "unknown error");
      }
    } catch (err) {
      logger.debug("Failed to refresh token:", err);
    }
    if (this.refreshTokenRetries < MAX_REFRESH_TOKEN_RETRIES) {
      this.refreshTokenRetries++;
      return this.getToken();
    }
    this.clearToken();
    this.isAuthQueued = false;
    return { success: false, message: "unknown error" };
  }

  public exchangeSession = async (orgId: string) => {
    try {
      const res = await this.instance.post<{
        success: boolean;
        message?: TokenError;
      }>(`${this.endpoint}/exchange/${orgId}`, undefined, {
        withCredentials: true,
      });
      if (res.status === 200) {
        // Ensure the previous access token doesn't get used for the new organization
        this.clearToken();
        console.debug(`Exchanged session for new organization ${orgId}`);
        return { success: true };
      }
    } catch (err) {
      console.debug(err);
    }
    this.clearToken();
    return { success: false, message: "unknown error" };
  };

  public getDocsToken = async () => {
    const token = localStorage.getItem("rollup_docs_token");
    if (token && this.checkToken(token, Auth.DocsTokenExpiryBuffer)) {
      return token;
    }
    try {
      const res = await this.instance.get<{ docsAccessToken: string }>(`${this.endpoint}/get-docs-token`);
      if (res.status === 200 && res.data?.docsAccessToken) {
        localStorage.setItem("rollup_docs_token", res.data?.docsAccessToken);
        return res.data?.docsAccessToken;
      }
    } catch (err) {
      console.warn(err);
    }
    return undefined;
  };

  public checkOrg = async (name: string) => {
    return this.instance.get<PublicOrgRto>(`${this.endpoint}/check-org/${name}`);
  };

  public getParentUrl = () => this.parent.url + this.parent.apiPrefix;

  public checkPasswordStrength = (password: string, email: string): Promise<AxiosResponse<PasswordValidationResult>> => {
    return this.instance.post(`${this.endpoint}/passwords/strength_check`, { email_address: email, password });
  };
}
