import { MutableRefObject, ReactNode, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { createPortal } from "react-dom";
import {
  closestCenter,
  CollisionDetection,
  DndContext,
  DragEndEvent,
  DragOverEvent,
  DragOverlay,
  getFirstCollision,
  MeasuringStrategy,
  PointerSensor,
  pointerWithin,
  rectIntersection,
  SensorOptions,
  TouchSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { DragStartEvent } from "@dnd-kit/core/dist/types";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import { arrayMove, SortableContext, verticalListSortingStrategy } from "@dnd-kit/sortable";
import classNames from "classnames";
import isEqual from "lodash/isEqual";
import { observer } from "mobx-react";

import { TContainerProps } from "@components/Modeling/ModelingFrame/ModelBlock/Properties/PropertyContainer";

import Item, { TItemRendererCallBack } from "./Components/Item";
import { DroppableContainer, SortableContainer, SortableItem } from "./Components";

import "./SortableLinkedLists.module.scss";
import styles from "./SortableLinkedLists.module.scss";

type Items = Record<string, string[]>;

enum EDragType {
  CONTAINER = "container",
  ITEM = "item",
}

interface Props {
  items: Items;
  multiSelect?: boolean;
  renderItem: TItemRendererCallBack;
  renderContainer?(containerProps: TContainerProps): ReactNode;
  noGap?: boolean;
  wrapperRef?: MutableRefObject<HTMLDivElement | null>;
  placeholder?: (containerId: string, isActive?: boolean) => any;
  disabledContainers?: string[];
  defaultSelectedIds?: string[];
  onContainerDragEnd?: (sourceId: string, targetId: string) => void;
  onItemDragEnd?: (sourceId: string, targetId: string, parentId: string) => void;
  onMultipleItemsDragEnd?: (sourceIds: string[], targetId: string) => void;
}

const SortableLinkedLists = (props: Props) => {
  const {
    defaultSelectedIds,
    items: initialItems,
    renderItem,
    disabledContainers,
    multiSelect = false,
    noGap = false,
    wrapperRef,
    onMultipleItemsDragEnd,
    onItemDragEnd,
  } = props;
  const [items, setItems] = useState<Items>(initialItems);
  const [containers, setContainers] = useState(Object.keys(items) as string[]);
  const [activeId, setActiveId] = useState<string | null>(null);
  const [isDropAbove, setIsDropAbove] = useState<boolean>(false);
  const [overId, setOverId] = useState<string | null>(null);
  const [containerOverId, setContainerOverId] = useState<string | undefined | null>(null);
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const lastOverId = useRef<string | null>(null);
  const recentlyMovedToNewContainer = useRef(false);
  const [draggingType, setDraggingType] = useState<string>("");
  const isSortingContainer = activeId ? containers.includes(activeId) : false;

  useEffect(() => {
    // prevent from blocks re-render while dragging on new selections passed
    if (activeId || !defaultSelectedIds || isEqual(defaultSelectedIds, selectedIds)) {
      return;
    }

    setSelectedIds(defaultSelectedIds);
  }, [activeId, defaultSelectedIds, selectedIds]);

  useEffect(() => {
    setItems(initialItems);
    setContainers(Object.keys(initialItems) as string[]);
  }, [initialItems]);

  const resetActiveIds = () => {
    setContainerOverId(null);
    setOverId(null);
    setActiveId(null);
  };

  const collisionDetectionStrategy: CollisionDetection = useCallback(
    args => {
      if (activeId && activeId in items) {
        return closestCenter({
          ...args,
          droppableContainers: args.droppableContainers.filter(container => container.id in items),
        });
      }

      const pointerIntersections = pointerWithin(args);
      const intersections = pointerIntersections.length > 0 ? pointerIntersections : rectIntersection(args);
      let overId = getFirstCollision(intersections, "id");

      if (overId != null) {
        if (overId in items) {
          const containerItems = items[overId];

          if (containerItems.length > 0) {
            overId = closestCenter({
              ...args,
              droppableContainers: args.droppableContainers.filter(
                container => container.id !== overId && containerItems.includes(`${container.id}`)
              ),
            })[0]?.id;
          }
        }

        lastOverId.current = `${overId}`;
        return [{ id: overId }];
      }

      if (recentlyMovedToNewContainer.current) {
        lastOverId.current = activeId;
      }

      return lastOverId.current ? [{ id: lastOverId.current }] : [];
    },
    [activeId, items]
  );

  const [clonedItems, setClonedItems] = useState<Items | null>(null);
  const options: SensorOptions = { activationConstraint: { distance: 15 } };
  const sensors = useSensors(useSensor(PointerSensor, options), useSensor(TouchSensor, options));

  const findContainer = useCallback(
    (id: string) => {
      return id in items ? id : Object.keys(items).find((key: string) => items[key].includes(id));
    },
    [items]
  );

  const getContainerIndex = (id: string): number => Object.keys(items).indexOf(id);

  const initialContainer = useMemo(() => {
    return activeId ? findContainer(activeId) : null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeId]);

  const getIndex = (id: string) => {
    const container = findContainer(id);
    return container ? items[container].indexOf(id) : -1;
  };

  const onDragCancel = () => {
    if (clonedItems) {
      setItems(clonedItems);
    }

    resetActiveIds();
    setClonedItems(null);
  };

  const handleSelect = (id: string) => {
    setSelectedIds((selectedIds: string[]) => {
      if (selectedIds.includes(id)) {
        return selectedIds.filter(value => value !== id);
      }

      if (!selectedIds.length || findContainer(id) !== findContainer(selectedIds[0])) {
        return [id];
      }

      return selectedIds.concat(id);
    });
  };

  const handleDragStart = (dragStartEvent: DragStartEvent) => {
    const { active } = dragStartEvent;
    // clear selected items if we start dragging unselected one
    setSelectedIds(selected => (selected.includes(`${active.id}`) ? selected : []));
    // set drag type container or item
    setDraggingType(active.data.current?.type || "");
    setActiveId(`${active.id}`);
    setClonedItems(items);
  };

  const handleDragOver = ({ active, over }: DragOverEvent) => {
    const overId = over?.id;

    if (overId == null || active.id in items) {
      setOverId(null);
      setContainerOverId(`${overId}`);
      return;
    }

    setOverId(`${overId}`);
    const overContainer = findContainer(`${overId}`);
    const activeContainer = findContainer(`${active.id}`);

    if (!overContainer || !activeContainer) {
      return;
    }

    const overItems = items[overContainer];
    const overIndex = overItems.indexOf(`${overId}`);

    if (activeContainer !== overContainer) {
      let newIndex: number;

      if (overId in items) {
        newIndex = overItems.length + 1;
      } else {
        const isBelowOverItem =
          over && active.rect.current.translated && active.rect.current.translated.top > over.rect.top + over.rect.height;
        const modifier = isBelowOverItem ? 0 : 1;
        newIndex = overIndex >= 0 ? overIndex + modifier : overItems.length + 1;
      }

      setIsDropAbove(newIndex < overIndex);
      recentlyMovedToNewContainer.current = true;
    } else {
      const activeIndex = overItems.indexOf(`${active.id}`);
      setIsDropAbove(overIndex < activeIndex);
    }
  };

  const handleDragEnd = ({ active, over }: DragEndEvent) => {
    const activeId = `${active.id}`;

    if (activeId in items && over?.id) {
      setContainers(containers => {
        const activeIndex = containers.indexOf(activeId);
        const overIndex = containers.indexOf(`${over.id}`);
        props.onContainerDragEnd && props.onContainerDragEnd(activeId, `${over.id}`);
        return arrayMove(containers, activeIndex, overIndex);
      });
    }

    const activeContainer = findContainer(activeId);
    const overId = over ? `${over.id}` : undefined;

    if (!activeContainer || !overId || !initialContainer) {
      resetActiveIds();
      setSelectedIds([]);
      return;
    }

    const ids: string[] = selectedIds.length ? [activeId, ...selectedIds.filter((id: string) => id !== activeId)] : [activeId];

    const overContainer = findContainer(overId);

    if (overContainer) {
      const overItems = items[overContainer];
      const overIndex = overItems.indexOf(overId);
      const activeIndex = overItems.indexOf(activeId);
      const newItems = arrayMove(overItems, activeIndex, overIndex);
      const newActiveIndex = newItems.indexOf(activeId);

      if (activeIndex !== overIndex) {
        setItems(items => ({
          ...items,
          [overContainer]: arrayMove(items[overContainer], activeIndex, overIndex),
        }));

        setItems((items: Items) => ({
          ...items,
          [initialContainer]: items[initialContainer].filter((id: string) => !ids.includes(id)),
          [activeContainer]: items[activeContainer].filter((id: string) => !ids.includes(id)),
          [overContainer]: [
            ...newItems.slice(0, newActiveIndex + 1),
            ...ids.filter(id => id !== active.id),
            ...newItems.slice(newActiveIndex + 1, newItems.length),
          ],
        }));
      }

      if (!draggingType) {
        if (multiSelect) {
          const ids = selectedIds.length ? selectedIds : [`${active.id}`];

          if (ids.includes(`${over?.id}`)) {
            resetActiveIds();
            return;
          }

          onMultipleItemsDragEnd && onMultipleItemsDragEnd(ids, `${over?.id}`);
        } else {
          onItemDragEnd && onItemDragEnd(`${active.id}`, `${over?.id}`, overContainer);
        }
      }
    }

    resetActiveIds();
  };

  useEffect(() => {
    requestAnimationFrame(() => {
      recentlyMovedToNewContainer.current = false;
    });
  }, [items]);

  const renderPlaceholder = (containerId: string) => {
    if (items[containerId].length) {
      return null;
    }

    const isActiveItemMove = activeId && !draggingType;
    return props.placeholder ? props.placeholder(containerId, !!isActiveItemMove) : <h4 style={{ margin: 0, height: 32 }}>No items</h4>;
  };

  const renderSortableItemDragOverlay = (id: string) => <Item key={id} value={id} dragOverlay renderItem={renderItem} />;

  const renderContainer = (containerProps: TContainerProps) => {
    if (props.renderContainer) {
      return props.renderContainer(containerProps);
    }
    return containerProps.children;
  };

  const renderContainerDragOverlay = (containerId: string) => (
    <SortableContainer isDragging renderContainer={renderContainer} id={containerId}>
      {renderPlaceholder(containerId)}
      {items[containerId].map((item: string) => (
        <Item key={item} value={item} renderItem={renderItem} />
      ))}
    </SortableContainer>
  );

  const renderDragOverlay = () => {
    if (!activeId) {
      return null;
    }

    return containers.includes(activeId) ? renderContainerDragOverlay(activeId) : renderSortableItemDragOverlay(activeId);
  };

  return (
    <DndContext
      sensors={sensors}
      modifiers={[restrictToVerticalAxis]}
      collisionDetection={collisionDetectionStrategy}
      measuring={{ droppable: { strategy: MeasuringStrategy.Always } }}
      onDragStart={handleDragStart}
      onDragOver={handleDragOver}
      onDragEnd={handleDragEnd}
      onDragCancel={onDragCancel}
    >
      <SortableContext items={containers} strategy={verticalListSortingStrategy}>
        {containers.map((containerId, index: number) => (
          <DroppableContainer
            renderContainer={renderContainer}
            disabled={disabledContainers?.includes(containerId) && !!draggingType}
            key={containerId}
            id={containerId}
            isOverByItem={draggingType !== EDragType.CONTAINER && containerId === overId}
            isOver={draggingType === EDragType.CONTAINER && containerId === containerOverId}
            newItemAbove={getContainerIndex(activeId || "") > index}
            items={items[containerId]}
          >
            <SortableContext items={items[containerId]} strategy={verticalListSortingStrategy}>
              {renderPlaceholder(containerId)}
              <div className={classNames(styles.sortableLinkedListsSortableItemsContainer, { "grid gap-y-2": !noGap })} ref={wrapperRef}>
                {items[containerId].map((id: string, index: number) => {
                  return (
                    <SortableItem
                      onSelect={multiSelect ? handleSelect : undefined}
                      selected={multiSelect ? selectedIds.includes(id) : undefined}
                      handle
                      disabled={isSortingContainer}
                      key={`${id}--${index}`}
                      id={id}
                      index={index}
                      newItemAbove={isDropAbove}
                      isOver={overId === id && overId !== activeId}
                      renderItem={renderItem}
                      containerId={containerId}
                      getIndex={getIndex}
                    />
                  );
                })}
              </div>
            </SortableContext>
          </DroppableContainer>
        ))}
      </SortableContext>
      {createPortal(<DragOverlay>{renderDragOverlay()}</DragOverlay>, document.body)}
    </DndContext>
  );
};

export default observer(SortableLinkedLists);
