import { Tooltip } from "@blueprintjs/core";
import { observer } from "mobx-react";

import { Button } from "@components/Button";
import appStore from "@store/AppStore";
import { HoopsTreePanelState } from "@store/EnvironmentStore";

import styles from "./FloatingExpandHoopsTreeButton.module.scss";

const FloatingExpandHoopsTreeButton = () => {
  const handleClick = () => {
    appStore.env.setHoopsTreePanelState(HoopsTreePanelState.Open);
  };

  if (appStore.env.hoopsTreePanelState === HoopsTreePanelState.Open) {
    return null;
  }

  return (
    <div className={styles.floatingExpandHoopsTreeButton}>
      <Tooltip content="Expand model tree">
        <Button outlined icon="double-chevron-right" onClick={handleClick} e2eIdentifiers="expand-hoops-tree-button" />
      </Tooltip>
    </div>
  );
};

export default observer(FloatingExpandHoopsTreeButton);
