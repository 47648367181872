import { observer } from "mobx-react";
import { isAlive } from "mobx-state-tree";

import { TCellRendererProps } from "@components/Table";
import RichTextCell from "@components/Table/CellRenderers/RichTextCell";
import { IPropertyDefinition } from "@store/PropertyDefinitionStore";

const DescriptionCell = (cellProps: TCellRendererProps<IPropertyDefinition>) => {
  const { data } = cellProps;
  const isBlockAlive = isAlive(data);

  return isBlockAlive && data.description ? (
    <RichTextCell
      className="property-definition-table--rich-text"
      content={data.description}
      e2eIdentifiers={["description", data.label]}
    />
  ) : null;
};

export default observer(DescriptionCell);
