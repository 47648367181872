import { ReactNode } from "react";
import { createRoot } from "react-dom/client";
import { ActionProps, IconName, Intent, OverlayToaster, Position, ProgressBar } from "@blueprintjs/core";
import { datadogRum } from "@datadog/browser-rum";

import { Button } from "@components/Button";
import appStore from "@store/AppStore";
import { FeatureFlag } from "@store/FeatureFlagsStore";
import { copyToClipboard } from "@utilities";

import { trackSegmentEvent } from "../../lib/Segment";

import "./toaster.scss";

const DEFAULT_TIMEOUT_ERROR = 2000;
const DEFAULT_TIMEOUT_INFO = 1000;

/** Singleton toaster instance. Create separate instances for different propertyAutocompleteOptions. */
export let AppToaster: OverlayToaster;

// Workaround for React 18 deprecation of ReactDOM.render
const toasterElement = document.getElementById("toaster")!;
const toasterRoot = createRoot(toasterElement);

toasterRoot.render(
  <OverlayToaster
    className="recipe-toaster"
    position={Position.BOTTOM_LEFT}
    maxToasts={5}
    ref={instance => {
      if (instance) {
        AppToaster = instance;
      }
    }}
  />
);

export let MiddleAppToaster: OverlayToaster;
const toasterMidElement = document.getElementById("toaster-mid")!;
const toasterMidRoot = createRoot(toasterMidElement);

toasterMidRoot.render(
  <OverlayToaster
    className="recipe-toaster"
    position={Position.BOTTOM}
    maxToasts={5}
    ref={instance => {
      if (instance) {
        MiddleAppToaster = instance;
      }
    }}
  />
);

const displayToast = (
  toaster: OverlayToaster,
  message: ReactNode,
  intent: Intent,
  icon?: IconName,
  clipboardContent?: string | object,
  timeout?: number,
  onDismiss?: () => void
) => {
  let action: ActionProps | undefined = undefined;
  if (clipboardContent) {
    action = {
      icon: "clipboard",
      onClick: () => copyToClipboard(clipboardContent),
    };
  }

  if (!timeout) {
    timeout = intent === Intent.WARNING || intent === Intent.DANGER ? DEFAULT_TIMEOUT_ERROR : DEFAULT_TIMEOUT_INFO;
  }

  if (intent === Intent.DANGER) {
    trackSegmentEvent("red-toast", { message });
  }

  toaster.show({
    message,
    intent,
    icon,
    action,
    timeout,
    onDismiss,
  });
};

export const showToast = (
  message: ReactNode,
  intent: Intent = Intent.NONE,
  icon?: IconName,
  clipboardContent?: string,
  timeout?: number,
  onDismiss?: () => void
) => {
  displayToast(AppToaster, message, intent, icon, clipboardContent, timeout, onDismiss);
};

export const showTimeoutToast = ({
  message,
  intent = Intent.NONE,
  progressIntent = Intent.PRIMARY,
  icon,
  timeout,
  actionButtonLabel,
  onActionButtonClick,
}: {
  message: string;
  intent?: Intent;
  progressIntent?: Intent;
  icon?: IconName;
  timeout: number;
  actionButtonLabel: string;
  onActionButtonClick?: () => void;
}) => {
  let progress = 1;
  let dismissed = false;

  const handleDismiss = () => {
    dismissed = true;
    clearInterval(intervalId);
  };

  const actionButton = () => {
    return (
      <Button
        text={actionButtonLabel}
        minimal
        onClick={() => {
          dismissed = true;
          clearInterval(intervalId);
          onActionButtonClick?.();
          AppToaster.dismiss(toastKey);
        }}
        e2eIdentifiers="timeout-toaster-action-button"
      />
    );
  };

  const progressBar = (progress: number) => {
    return (
      <>
        <div className="timeout-toaster--message-and-action-button">
          {message}
          {actionButton()}
        </div>
        <div className="timeout-toaster--progress">
          <ProgressBar intent={progressIntent} value={progress} animate={false} stripes={false} />
        </div>
      </>
    );
  };

  const toastKey = AppToaster.show({
    message: progressBar(progress),
    intent,
    icon,
    timeout,
    onDismiss: handleDismiss,
  });

  const intervalId = setInterval(() => {
    if (dismissed) {
      return;
    }
    progress -= 0.02;
    if (progress <= 0) {
      dismissed = true;
      clearInterval(intervalId);
      AppToaster.dismiss(toastKey);
    } else if (!dismissed) {
      AppToaster.show(
        {
          message: progressBar(progress),
          intent,
          icon,
          timeout,
          onDismiss: handleDismiss,
        },
        toastKey
      );
    }
  }, timeout / 50);
};

export const showMiddleToast = (
  message: ReactNode,
  intent: Intent = Intent.NONE,
  icon?: IconName,
  clipboardContent?: string,
  timeout?: number
) => {
  displayToast(MiddleAppToaster, message, intent, icon, clipboardContent, timeout);
};

export const showApiErrorToast = (message: ReactNode, err?: Error) => {
  if (err) {
    datadogRum.addError(err);
  }
  if (!appStore.env.featureFlags.enabled(FeatureFlag.SUPPRESS_ERROR_TOASTS)) {
    displayToast(AppToaster, message, Intent.DANGER, "warning-sign", err?.stack);
  }
};
