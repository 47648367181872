import { Attachment, PartNumberSchema, Workspace } from "@rollup-api/models";
import { CatalogItem } from "@rollup-api/models/catalogItem/catalogItem.model";
import { CatalogItemReference } from "@rollup-api/models/catalogItem/catalogItemReference.model";
import { CatalogItemVersion } from "@rollup-api/models/catalogItem/catalogItemVersion.model";
import { CustomUnit } from "@rollup-api/models/customUnits";

export enum OrganizationModuleType {
  CatalogItems = "catalog-items",
  CatalogItemVersions = "catalog-item-versions",
  CatalogItemReferences = "catalog-item-references",
  Workspaces = "workspaces",
  PartNumberSchemas = "part-number-schemas",
  Attachments = "attachments",
  CustomUnits = "custom-units",
}

export class Organization {
  public readonly id!: string;
  public readonly slug!: string;
  public readonly description?: string;
  public readonly name!: string;
  public readonly logoUrl?: string;
  public readonly allowedDomains?: string[];
  public readonly catalogItems?: CatalogItem[];
  public readonly catalogItemVersions?: CatalogItemVersion[];
  public readonly catalogItemReferences?: CatalogItemReference[];
  public readonly customUnits?: CustomUnit[];
  public readonly partNumberSchemas?: PartNumberSchema[];
  public readonly workspaces?: Workspace[];
  public readonly attachments?: Attachment[];
}

export class OrganizationListEntry {
  public readonly id!: string;
  public readonly slug!: string;
  public readonly description?: string;
  public readonly name!: string;
  public readonly logoUrl?: string;
}
