export enum ExecutionEnvironmentType {
  Python = "python3",
  Node = "node",
  Bun = "bun",
  Matlab = "matlab",
  Bash = "bash",
  Julia = "julia",
}

export enum ExecutionEnvironmentStatus {
  Pending = "pending",
  Creating = "creating",
  Ready = "ready",
  Error = "error",
}

export enum ExecutionStatus {
  Success = "success",
  Failure = "failure",
  Warning = "warning",
  Timeout = "timeout",
}
