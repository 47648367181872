import { useEffect, useState } from "react";
import { NonIdealState, Pre, Tab, Tabs } from "@blueprintjs/core";
import sortBy from "lodash/sortBy";
import { observer } from "mobx-react";

import { ExecutionArtifactThumbnail } from "@components/Analysis";
import { ExecutionResult } from "@rollup-api/models/execution-environments";
import { ICodeBlock } from "@store/Analysis/CodeBlockStore";

import "./CodeBlocksFooter.scss";

export type CodeBlocksFooterProps = {
  result?: ExecutionResult;
  codeBlock: ICodeBlock;
};

enum FooterTab {
  Summary = "summary",
  Output = "output",
  Artifacts = "artifacts",
  Error = "error",
}

function CodeBlocksFooter({ result, codeBlock }: CodeBlocksFooterProps) {
  const [currentTab, setCurrentTab] = useState<FooterTab>(FooterTab.Summary);

  useEffect(() => {
    if (result?.stderr) {
      setCurrentTab(FooterTab.Error);
    } else if (result?.stdout) {
      setCurrentTab(FooterTab.Output);
    } else {
      setCurrentTab(FooterTab.Summary);
    }
  }, [result]);

  const summaryPanel = result ? (
    <div className="code-block-footer--summary">
      <Pre>
        {result.status === "success" ? `${codeBlock.label} executed successfully` : `Execution failed with exit code ${result.exitCode}`}
        {result.message && (
          <>
            <br />
            {result.message}
          </>
        )}
        <br />
        Execution time: {result.executionTime.toFixed(1)} ms
      </Pre>
    </div>
  ) : (
    <NonIdealState title="Press Run to execute code" />
  );
  const stdoutPanel = (
    <div className="code-block-footer--stdout">
      {result?.stdout ? (
        <Pre>{result?.stdout}</Pre>
      ) : (
        <NonIdealState title="No output" description="The code did not produce any console output." />
      )}
    </div>
  );

  // TODO: Clean up this interface
  const artifactsPanel = (
    <div className="code-block-footer--artifacts">
      {sortBy(result?.artifacts, "filename")?.map((artifact, index) => (
        <ExecutionArtifactThumbnail artifact={artifact} key={`${artifact.filename}-${index}`} />
      ))}
    </div>
  );

  const stderrPanel = (
    <div className="code-block-footer--stderr">
      {result?.stderr ? (
        <Pre>{result?.stderr}</Pre>
      ) : (
        <NonIdealState title="No errors" description="The code did not produce any error output." />
      )}
    </div>
  );

  return (
    <Tabs className="code-block-footer" selectedTabId={currentTab} onChange={tabId => setCurrentTab(tabId as FooterTab)}>
      <Tab id={FooterTab.Summary} icon="info-sign" title="Summary" panel={summaryPanel} />
      <Tab id={FooterTab.Output} icon="console" title="Output log" panel={stdoutPanel} disabled={result?.stdout === undefined} />
      <Tab id={FooterTab.Error} icon="error" title="Error log" panel={stderrPanel} disabled={result?.stderr === undefined} />
      <Tab id={FooterTab.Artifacts} icon="media" title="Artifacts" panel={artifactsPanel} disabled={!result?.artifacts?.length} />
    </Tabs>
  );
}

export default observer(CodeBlocksFooter);
