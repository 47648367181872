import { ColumnState } from "ag-grid-community";
import { types } from "mobx-state-tree";

import { CatalogItemTableColumn } from "@components/CatalogItems/Table/types";
import { ERequirementsTableColumn } from "@components/Requirements/RequirementsTable/constants";

export const ColumnsSizeStore = types
  .model("ColumnsSize", {
    requirementsTable: types.map(types.number),
    catalogItemsTable: types.map(types.number),
  })
  .views(self => ({
    get getCatalogItemsColumnsState(): ColumnState[] {
      const result: ColumnState[] = [];

      for (const key of self.catalogItemsTable.keys()) {
        result.push({ colId: key, width: self.catalogItemsTable.get(key) });
      }

      return result;
    },
  }))
  .actions(self => {
    return {
      updateRequirementsTableColumnSize(column: ERequirementsTableColumn, width: number) {
        self.requirementsTable.set(column, width);
      },
      updateCatalogItemTableColumnSize(column: CatalogItemTableColumn, width: number) {
        self.catalogItemsTable.set(column, width);
      },
    };
  });
