import { showApiErrorToast } from "../Components/UiLayers/toaster";
import { formatFileSize } from "../Utilities";

const useFileUploadMessage = () => {
  const showFormatErrorMessage = (file: File) => {
    showApiErrorToast(`${file.name} format is not supported!`);
  };

  const showSizeErrorMessage = (file: File, limit: number) => {
    showApiErrorToast(`${file.name} size is too big, ${formatFileSize(file.size)}! Allowed size: ${formatFileSize(limit)}`);
  };

  return {
    showFormatErrorMessage,
    showSizeErrorMessage,
  };
};

export default useFileUploadMessage;
