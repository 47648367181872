import { Text, TextVariant } from "@ui/Text";
import { observer } from "mobx-react";

import { Button } from "@components/Button";
import { useAttachmentFunctions } from "@components/Modeling/ModelingFrame/ModelBlock/Attachments/attachmentHooks";
import { UnknownTag } from "@components/Reports/Editor/Extentions/Mention/MentionTag/UnknownTag";
import appStore from "@store/AppStore";

import styles from "./AttachmentCell.module.scss";

type Props = {
  attachmentId: string;
};

const AttachmentCell = (props: Props) => {
  const { attachmentId } = props;
  const workspaceAttachment = appStore.workspaceModel?.attachments.get(attachmentId);
  const organizationAttachment = appStore.orgModel?.attachments.get(attachmentId);
  const attachment = workspaceAttachment || organizationAttachment;

  const { handleDownload } = useAttachmentFunctions({ attachment });

  if (!attachment) {
    return (
      <td>
        <div className={styles.attachmentCellNotFound}>
          <UnknownTag />
          <Text variant={TextVariant.Caption}>Not found</Text>
        </div>
      </td>
    );
  }

  return (
    <td>
      <Button onClick={handleDownload} minimal large icon="document" e2eIdentifiers={[attachment.name]}>
        {attachment.name}
      </Button>
    </td>
  );
};

export default observer(AttachmentCell);
