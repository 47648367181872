import { v4 as uuidv4 } from "uuid";

import { IComment } from "@store/CommentStore";

export type CommentThread = {
  id: string;
  parentComment: IComment;
  childComments: IComment[];
  remainingChildCommentsCount: number;
  remainingChildCommentsCommenters: string[];
};

export enum CommentLocationType {
  Root = "root",
  Annotation = "annotation",
}

export const getCommentThreads = (
  parentCommentsList: IComment[],
  childCommentsList: IComment[],
  remainingChildCommentsPerParent: { [key: string]: number },
  uniqueNextSetOfChildCommentersPerParent: { [key: string]: string[] }
): CommentThread[] => {
  const parentComments = [...parentCommentsList];
  const childComments = [...childCommentsList];
  const threads: CommentThread[] = [];

  // Sort comments by date ascending
  parentComments.sort((a, b) => a.createdAt - b.createdAt);
  childComments.sort((a, b) => a.createdAt - b.createdAt);

  // Create a comment thread for each parent comment
  for (const parentComment of parentComments) {
    const thread: CommentThread = {
      id: uuidv4(),
      parentComment,
      childComments: [],
      remainingChildCommentsCount: remainingChildCommentsPerParent[parentComment.id]
        ? remainingChildCommentsPerParent[parentComment.id]
        : 0,
      remainingChildCommentsCommenters: uniqueNextSetOfChildCommentersPerParent[parentComment.id],
    };
    // Assign related child comments to the thread
    const currentChildComments = childComments.filter(childComment => childComment.parentCommentId === parentComment.id);
    thread.childComments = currentChildComments;

    threads.push(thread);
  }

  return threads;
};
