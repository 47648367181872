import { Icon } from "@blueprintjs/core";
import { observer } from "mobx-react";

import AssetImage from "@components/Shared/AssetImage/AssetImage";
import { OrganizationListEntry } from "@rollup-api/models";
import { IOrgInfoStore } from "@store/OrgInfoStore";

const OrgIcon = ({ org }: { org: OrganizationListEntry | IOrgInfoStore }) => {
  return <Icon icon={org?.logoUrl ? <AssetImage height={24} src={org?.logoUrl} alt="org-logo" /> : null} />;
};

export default observer(OrgIcon);
