import { Workspace, WorkspacesDto, WorkspaceUpdateDto } from "@rollup-api/models";

import { HttpClient, ParentClient } from "./client";

export class Workspaces extends HttpClient {
  public constructor(parent: ParentClient) {
    super(parent);
  }

  private readonly endpoint: string = "/workspace";

  public retrieve = (id: string, fullExport = false, modules?: string) => {
    return this.instance.get<Workspace>(`${this.endpoint}/${id}`, { params: { fullExport, modules } });
  };

  public retrieveList = (filters?: string[]) => {
    let queries = "";

    if (!filters) {
      filters = ["id", "label", "updatedAt", "createdAt", "createdBy"];
    }
    queries += `&filters=${JSON.stringify(filters)}`;

    return this.instance.get<Workspace[]>(`${this.endpoint}?${queries}`);
  };

  public create = (dto: WorkspacesDto) => {
    return this.instance.post<Workspace>(`${this.endpoint}`, dto);
  };

  public import = (data: any) => {
    return this.instance.post<Workspace>(`${this.endpoint}/import`, data);
  };

  public update = (id: string, dto: WorkspaceUpdateDto) => {
    return this.instance.patch<Workspace>(`${this.endpoint}/${id}`, dto);
  };

  public delete = (id: string) => {
    return this.instance.delete<Workspace>(`${this.endpoint}/${id}`);
  };

  public deleteMany = (ids: string[]) => {
    return this.instance.delete<{ deletedWorkspaces: string[] }>(this.endpoint, { data: { ids } });
  };

  public copy = (id: string) => {
    return this.instance.post<Workspace>(`${this.endpoint}/${id}/copy`);
  };
}
