import { Tab, Tabs } from "@blueprintjs/core";
import { observer } from "mobx-react";

import gitInfo from "@assets/git-info.json";
import { AnchorButton } from "@components/AnchorButton";
import { Button } from "@components/Button";
import { showToast } from "@components/UiLayers/toaster";
import appStore from "@store/AppStore";
import { copyToClipboard } from "@utilities";
import { Text, TextVariant } from "src/ui/Text";

import RollupEventsItem from "./RollupEventsItem";

import "./RollupEventsPopover.scss";

const RollupEventsPopover = () => {
  const handleMarkAsReadClicked = async () => {
    try {
      await copyToClipboard(gitInfo);
      showToast("Copied commit info to clipboard", "success", "info-sign");
    } catch (err) {
      return;
    }
  };

  const AllPanel = (
    <Text variant={TextVariant.H3}>
      {appStore.userModel?.rollupEvents?.topRollupEventsTotal?.map((notification, index) => (
        <RollupEventsItem
          key={notification.id}
          rollupEvent={notification}
          lastItem={index === appStore.userModel?.rollupEvents?.topRollupEventsTotal?.length}
        />
      ))}
    </Text>
  );

  const MentionsPanel = (
    <Text variant={TextVariant.H3}>
      {appStore.userModel?.rollupEvents?.topRollupEventsMention?.map((notification, index) => (
        <RollupEventsItem
          key={notification.id}
          rollupEvent={notification}
          lastItem={index === appStore.userModel?.rollupEvents?.topRollupEventsMention?.length}
        />
      ))}
    </Text>
  );

  const ArchivePanel = (
    <Text variant={TextVariant.H3}>
      {appStore.userModel?.rollupEvents?.topRollupEventsArchive?.map((notification, index) => (
        <RollupEventsItem
          key={notification.id}
          rollupEvent={notification}
          lastItem={index === appStore.userModel?.rollupEvents?.topRollupEventsArchive?.length}
        />
      ))}
    </Text>
  );

  const SettingsPanel = <Text variant={TextVariant.H3}>Settings tab placeholder</Text>;

  const unreadInboxEventsCount = appStore.userModel?.rollupEvents?.unreadInboxRollupEventCount || undefined;
  // TODO: implement mention count correctly once BE is ready (currently the same as inbox)
  const unreadMentionEventsCount = appStore.userModel?.rollupEvents?.unreadInboxRollupEventCount || undefined;
  const unreadArchiveEventsCount = appStore.userModel?.rollupEvents?.unreadArchiveRollupEventCount || undefined;

  return (
    <div className="notifications-popover">
      <div className="notifications-popover--header">
        <Text variant={TextVariant.H3}>Notifications</Text>
        <AnchorButton minimal onClick={handleMarkAsReadClicked} e2eIdentifiers="mark-all-as-read">
          Mark all as read
        </AnchorButton>
      </div>
      <div className="notifications-popover--tabs">
        <Tabs id="TabsExample" renderActiveTabPanelOnly>
          <Tab id="all" title="All" panel={AllPanel} tagContent={unreadInboxEventsCount} />
          <Tab id="mentions" title="Mentions" panel={MentionsPanel} tagContent={unreadMentionEventsCount} />
          <Tab id="archive" title="Archive" panel={ArchivePanel} tagContent={unreadArchiveEventsCount} />
          <Tabs.Expander />
          <Tab id="settings" title={<Button icon="cog" minimal e2eIdentifiers="settings-panel" />} panel={SettingsPanel} />
        </Tabs>
      </div>
    </div>
  );
};

export default observer(RollupEventsPopover);
