import assignIn from "lodash/assignIn";
import { IAnyModelType, Instance, IType, SnapshotIn, SnapshotOut, types } from "mobx-state-tree";
import { Socket } from "socket.io-client";

import { CreateStatusInstanceDto, StatusInstanceUpdateDto } from "@rollup-api/models/statusInstance";
import { convertTimestampToDate, FormatScalarValue, ScalarParser, toTimestamp } from "@utilities";
import { getMultiSelectValue, getUrlValue, getUserValues, IUrl } from "@utilities/StatusInstance";
import { rollupClient } from "src/core/api";

import appStore from "./AppStore";
import { BlockStore } from "./BlockStore";
import { IStatusDefinition, StatusDefinitionStore, StatusType } from "./StatusDefinitionStore";
import { IStatusOption } from "./StatusOptionStore";
import { IUser } from "./UserStore";

export const StatusInstanceStore = types
  .model("StatusInstance", {
    id: types.identifier,
    parentBlock: types.safeReference(types.late((): IAnyModelType => BlockStore)),
    statusDefinition: types.safeReference(StatusDefinitionStore),
    value: types.maybeNull(types.string),
  })
  .actions(self => ({
    patch(update: StatusInstanceUpdateDto) {
      // Prevent updating of fixed properties
      const invalidFields = ["id", "parentBlock", "statusDefinition"];
      const updateKeys = Object.keys(update);
      for (const field of invalidFields) {
        if (updateKeys.includes(field)) {
          return false;
        }
      }

      try {
        assignIn(self, update);
        return true;
      } catch (err) {
        console.warn(err);
        return false;
      }
    },
    migrateType(type: StatusType) {
      if (self.statusDefinition?.type === type) {
        return true;
      }

      // TODO: Handle type migration
      self.value = "";
    },
    setValue(value: string, notify = true) {
      if (value === self.value) {
        return;
      }

      self.value = value;
      if (notify) {
        // TODO parentBlock is always "undefined" when changing project status
        rollupClient.statusInstances.update(self.id, { value });
      }
    },
  }))
  .views(self => ({
    get numericValue() {
      return ScalarParser.parse(self.value ?? "");
    },
    get checkedValue() {
      return self.value === "true";
    },
    get userValue(): IUser | undefined {
      return appStore.orgModel?.info?.orgMembers?.find(u => u.id === self.value);
    },
    get dateValue() {
      if (self.value) {
        return convertTimestampToDate(self.value);
      }
      return new Date();
    },
    get selectValue(): IStatusOption | undefined {
      return self.statusDefinition?.statusOptions?.find(o => o.id === self.value);
    },
    get multiSelectValues(): IStatusOption[] {
      return getMultiSelectValue(self.statusDefinition as IStatusDefinition, self.value || "");
    },
    get userValues(): IUser[] {
      return getUserValues(self.value || "");
    },
    get urlValue() {
      return getUrlValue(self.value);
    },
  }))
  .actions(self => ({
    setValueFromString(value: string) {
      self.setValue(value);
      return true;
    },
    setValueFromNumber(value: number) {
      self.setValue(FormatScalarValue(value));
      return true;
    },
    setValueFromBoolean(value: boolean) {
      self.setValue(value ? "true" : "false");
      return true;
    },
    setValueFromUser(user: IUser) {
      self.setValue(user.id);
      return true;
    },
    setValueFromURL(url: IUrl) {
      self.setValue(JSON.stringify(url));
    },
    setValueFromUserList(users: IUser[], notify = true) {
      const idArray = users.map(o => o.id);
      self.setValue(JSON.stringify(idArray), notify);
    },
    removeUserFromList(user: IUser, notify = true) {
      this.setValueFromUserList(
        self.userValues.filter(o => o.id !== user.id),
        notify
      );
    },
    addUserToList(user: IUser) {
      if (self.userValues.find(({ id }) => id === user.id)) {
        return false;
      }
      this.setValueFromUserList([...self.userValues, user]);
    },
    clearUserList() {
      this.setValueFromUserList([]);
    },
    setValueFromDate(date: Date) {
      self.setValue(toTimestamp(date));
      return true;
    },
    setValueFromOption(option: IStatusOption) {
      if (option.statusDefinition === self.statusDefinition) {
        self.setValue(option.id);
        return true;
      } else {
        return false;
      }
    },
    setValueFromOptionList(options: IStatusOption[], notify = true) {
      const idArray = options.filter(o => o.statusDefinition === self.statusDefinition).map(o => o.id);
      self.setValue(JSON.stringify(idArray), notify);
    },
    removeOption(option: IStatusOption, notify = true) {
      this.setValueFromOptionList(
        self.multiSelectValues.filter(o => o.id !== option.id),
        notify
      );
    },
    addOptionToList(option: IStatusOption) {
      if (option.statusDefinition !== self.statusDefinition) {
        return false;
      }
      if (self.multiSelectValues.find(o => o.id === option.id)) {
        return false;
      }
      this.setValueFromOptionList([...self.multiSelectValues, option]);
    },
    clearOptionList() {
      this.setValueFromOptionList([]);
    },
  }));

export function subscribeToStatusInstanceEvents(socket: Socket) {
  socket.on("createStatusInstance", (data: { workspaceId: string; createStatusInstanceDto: CreateStatusInstanceDto; userId: string }) => {
    if (
      data.createStatusInstanceDto?.id &&
      data.createStatusInstanceDto.parentBlock &&
      data.createStatusInstanceDto.statusDefinition &&
      data.workspaceId === appStore.workspaceModel?.id
    ) {
      const parentBlock = appStore.workspaceModel.blockMap.get(data.createStatusInstanceDto.parentBlock);
      const statusDefinition = appStore.workspaceModel.statusDefinitionMap.get(data.createStatusInstanceDto.statusDefinition);
      if (parentBlock && statusDefinition) {
        const { value, id } = data.createStatusInstanceDto;
        appStore.workspaceModel.addStatusInstance(parentBlock, statusDefinition, value, id, false);
      }
    }
  });

  socket.on("deleteStatusInstance", (data: { workspaceId: string; id: string; userId: string }) => {
    if (data.id && data.workspaceId === appStore.workspaceModel?.id) {
      const instance = appStore.workspaceModel.statusInstanceMap.get(data.id);
      if (instance) {
        appStore.workspaceModel.deleteStatusInstance(instance, false);
      }
    }
  });

  socket.on(
    "updateStatusInstance",
    (data: { workspaceId: string; id: string; updateStatusInstanceDto: StatusInstanceUpdateDto; userId: string }) => {
      if (data.id && data.workspaceId === appStore.workspaceModel?.id) {
        const instance = appStore.workspaceModel.statusInstanceMap.get(data.id);
        instance?.patch(data.updateStatusInstanceDto);
      }
    }
  );
}

export interface IStatusInstance extends Instance<typeof StatusInstanceStore> {}
export interface IStatusInstanceSnapshotIn extends SnapshotIn<typeof StatusInstanceStore> {}
interface IStatusInstanceSnapshotOut extends SnapshotOut<typeof StatusInstanceStore> {}
export interface IStatusInstanceMobxType extends IType<IStatusInstanceSnapshotIn, IStatusInstanceSnapshotOut, IStatusInstance> {}
