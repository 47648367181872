import { CustomCellRendererProps } from "ag-grid-react";
import { observer } from "mobx-react";

import RichTextCell from "@components/Table/CellRenderers/RichTextCell";
import { withObservedValueGetter } from "@utilities/AgGridUtils";
import { mergeE2EIdentifiers } from "@utilities/E2EUtils";

import styles from "./TextCellRenderer.module.scss";

interface ITextCellRendererProps extends CustomCellRendererProps<any, string> {
  useRichText?: boolean;
  e2eIdentifiers?: string[];
}

export const TextCellRenderer = (props: ITextCellRendererProps) => {
  const { useRichText, value, e2eIdentifiers } = props;

  if (useRichText) {
    return <RichTextCell content={value ?? ""} e2eIdentifiers={mergeE2EIdentifiers(e2eIdentifiers, value ?? "")} />;
  }

  return <p className={styles.textCellRenderer}>{value}</p>;
};

export default withObservedValueGetter(observer(TextCellRenderer));
