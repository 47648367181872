import { Component } from "react";
import {
  Code,
  Collapse,
  Divider,
  EditableText,
  FormGroup,
  H5,
  IconName,
  Intent,
  Menu,
  MenuDivider,
  NumericInput,
  PopoverInteractionKind,
} from "@blueprintjs/core";
import { unitIsValid } from "@rollup-io/engineering";
import { observer } from "mobx-react";

import { Button } from "@components/Button";
import { MenuItem } from "@components/MenuItem";
import { MenuItemDelete } from "@components/MenuItems";
import { Popover } from "@components/Popover";
import { SortableItemComponent } from "@components/Shared";
import { SafeTooltip } from "@components/SmallComponents";
import { AttributeDataType } from "@rollup-api/models/attribute";
import { IAttribute } from "@store/AttributeStore";
import { IInterface } from "@store/InterfaceStore";
import { plusMinusIcon } from "@utilities/CIcon";

import "./InterfaceAttributeComponent.scss";

export interface InterfaceAttributeComponentProps extends SortableItemComponent {
  attribute: IAttribute;
  interface: IInterface;
}

class InterfaceAttributeComponent extends Component<InterfaceAttributeComponentProps> {
  private handleDataTypeChanged = (dataType: AttributeDataType) => {
    this.props.attribute?.setDataType(dataType);
  };

  marginEditText = () => {
    const attribute = this.props.attribute;
    const enabled = attribute.dataType === AttributeDataType.scalar || attribute.dataType === AttributeDataType.array;

    const unitLabel = enabled ? <span className="attribute-uncertainty-unit">{attribute.unit}</span> : undefined;

    return (
      <FormGroup inline label="Margin" disabled={attribute.locked || !enabled}>
        <NumericInput
          className="attribute-uncertainty-input"
          maxLength={36}
          fill={false}
          disabled={attribute.locked || !enabled}
          placeholder="value"
          value={attribute.uncertaintyValue}
          selectAllOnFocus
          allowNumericCharactersOnly={false}
          rightElement={unitLabel}
          leftIcon={plusMinusIcon}
          onValueChange={attribute.setUncertaintyValue}
        />
      </FormGroup>
    );
  };

  private unitInput() {
    return (
      <EditableText
        className="interface-attribute-unit"
        key={`${this.props.attribute.id}-${this.props.attribute.unit}`}
        maxLength={20}
        multiline={false}
        disabled={this.props.attribute.locked}
        placeholder="unit"
        defaultValue={this.props.attribute.unit}
        selectAllOnFocus={false}
        intent={unitIsValid(this.props.attribute.unit) ? Intent.NONE : Intent.WARNING}
        confirmOnEnterKey
        onConfirm={this.props.attribute.setUnit}
      />
    );
  }

  private valueInput() {
    if (this.props.attribute.dataType === AttributeDataType.string) {
      return (
        <NumericInput
          maxLength={36}
          key={`${this.props.attribute.id}-${this.props.attribute.stringValue}`}
          defaultValue={this.props.attribute.stringValue}
          className="attribute-string-input"
          placeholder="Enter string"
          disabled={this.props.attribute.locked}
          selectAllOnFocus={false}
          buttonPosition="none"
          allowNumericCharactersOnly={false}
          onValueChange={(_valueAsNumber, valueAsString) => this.props.attribute.setStringValue(valueAsString)}
        />
      );
    } else {
      const value = Number(this.props.attribute.stringValue);
      return (
        <NumericInput
          maxLength={36}
          fill={false}
          placeholder="Enter value"
          defaultValue={value}
          selectAllOnFocus
          allowNumericCharactersOnly={false}
          rightElement={this.unitInput()}
          className="attribute-numeric-input"
          onValueChange={(_valueAsNumber: number, valueAsString: string) => this.props.attribute.setStringValue(valueAsString)}
        />
      );
    }
  }

  private static DataTypeIcon = (dataType: AttributeDataType): IconName => {
    switch (dataType) {
      case AttributeDataType.scalar:
        return "numerical";
      case AttributeDataType.array:
        return "array-numeric";
      case AttributeDataType.string:
        return "italic";
      default:
        return "blank";
    }
  };

  private renderDataTypeItem = (dataType: AttributeDataType) => {
    return (
      <MenuItem
        key={dataType}
        className="attribute-data-type-menu-item"
        icon={InterfaceAttributeComponent.DataTypeIcon(dataType)}
        text={dataType}
        onClick={() => this.handleDataTypeChanged(dataType)}
        active={dataType === this.props.attribute?.dataType}
        e2eIdentifiers={[dataType]}
      />
    );
  };

  render() {
    const attribute = this.props.attribute;
    const isCollapsed = this.props.attribute.ui.isCollapsed || this.props.isDragging;

    const attributeTypeMenu = (
      <Menu>
        <MenuDivider title="Numeric" />
        {this.renderDataTypeItem(AttributeDataType.scalar)}
        {this.renderDataTypeItem(AttributeDataType.array)}
        <MenuDivider title="Text" />
        {this.renderDataTypeItem(AttributeDataType.string)}
      </Menu>
    );

    const attributeMenu = (
      <Menu>
        <MenuDivider title="Actions" />
        <MenuItem
          text={attribute.locked ? "Unlock" : "Lock"}
          intent={attribute.locked ? Intent.SUCCESS : Intent.WARNING}
          icon={attribute.locked ? "lock" : "unlock"}
          onClick={attribute.toggleLocked}
          e2eIdentifiers="toggle-lock"
        />
        <MenuItemDelete onDelete={() => this.props.interface.deleteAttribute(attribute)} />
      </Menu>
    );

    return (
      <div className="interface-attribute-component dnd-drag-handle-item">
        <FormGroup inline>
          <Popover
            inheritDarkTheme
            interactionKind={PopoverInteractionKind.CLICK}
            hoverCloseDelay={500}
            placement="left-start"
            content={attributeMenu}
          >
            <Button
              {...this.props.dragListeners}
              className="interface-attribute-menu-button"
              minimal
              intent={Intent.NONE}
              icon="drag-handle-vertical"
              e2eIdentifiers="open-attribute-menu"
            />
          </Popover>
          <EditableText
            defaultValue={attribute.label}
            confirmOnEnterKey
            onConfirm={attribute.setLabel}
            placeholder="Name"
            className="attribute-label-input"
            intent={attribute.label ? Intent.NONE : Intent.WARNING}
          />
          <Popover
            interactionKind={PopoverInteractionKind.CLICK}
            placement="bottom"
            content={attributeTypeMenu}
            popoverClassName="attribute-data-type-menu"
          >
            <Button
              className="attribute-data-type-menu-item"
              minimal
              intent={Intent.PRIMARY}
              icon={InterfaceAttributeComponent.DataTypeIcon(attribute.dataType)}
              e2eIdentifiers="open-attribute-type-menu"
            />
          </Popover>
          {this.valueInput()}
          <SafeTooltip content="Attribute Documentation">
            <Button
              onClick={attribute.ui.toggleIsCollapsed}
              minimal
              className="drop-down-button"
              rightIcon={isCollapsed ? "chevron-right" : "chevron-down"}
              e2eIdentifiers="toggle-is-collapsed"
            />
          </SafeTooltip>
        </FormGroup>
        <Collapse isOpen={!isCollapsed} keepChildrenMounted>
          {this.marginEditText()}
          <FormGroup inline label="Margin min" className="margin-form-group">
            <Code>{`${attribute.lowerBound} ${attribute.unit}`}</Code>
          </FormGroup>
          <FormGroup inline label="Margin max" className="margin-form-group">
            <Code>{`${attribute.upperBound} ${attribute.unit}`}</Code>
          </FormGroup>
          <H5>Description</H5>
          <EditableText
            key={`${attribute.id}-${attribute.description}`}
            defaultValue={attribute.description}
            multiline
            maxLines={6}
            confirmOnEnterKey
            onConfirm={attribute.setDescription}
            placeholder="Description"
            className="attribute-description-input"
          />
          <Divider />
        </Collapse>
      </div>
    );
  }
}

export default observer(InterfaceAttributeComponent);
