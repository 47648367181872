import { useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import { Alignment, HTMLDivProps, Icon, Intent, Menu, MenuDivider } from "@blueprintjs/core";
import { PropertyDataType } from "@rollup-io/engineering";
import classNames from "classnames";
import { observer } from "mobx-react";

import BlockPropertyListRow from "@components/Block/BlockPropertyListItem/BlockPropertyListRow";
import { Button } from "@components/Button";
import { MenuItem } from "@components/MenuItem";
import { MenuItemDelete } from "@components/MenuItems";
import { Popover } from "@components/Popover";
import { ScalarExpressionEditor, SortableItemComponent } from "@components/Shared";
import ConfirmableNumericInput from "@components/Shared/ConfirmableNumericInput/ConfirmableNumericInput";
import { Switch } from "@components/Switch";
import appStore from "@store/AppStore";
import { IBlock } from "@store/BlockStore";
import { IPropertyInstance } from "@store/PropertyInstanceStore";
import { FormatType, getIdFromHash, getUnitType, isAllowedFormatTypeByUnitType } from "@utilities";

import { PresenceList } from "../../Header/PresenceList/PresenceList";
import PropertyDefinitionMenu from "../../Shared/PropertyDefinitionMenu/PropertyDefinitionMenu";
import { CopyLinkMenuItem } from "../../SmallComponents";

import PropertyTypeMenuButton from "./PropertyTypeMenuButton";

import styles from "./BlockPropertyListItem.module.scss";

/** Type defs. */
type BlockPropertyListItemProps = Partial<SortableItemComponent> & {
  propertyInstance: IPropertyInstance;
  block: IBlock;
  pinnedMode?: boolean;
  showDescription?: boolean;
  enableWrappingOnFocus?: boolean;
  wrapperProps?: HTMLDivProps;
};

/** Main function. */
function BlockPropertyListItem(props: BlockPropertyListItemProps) {
  const { propertyInstance, block, dragListeners, pinnedMode, isDragging, wrapperProps, showDescription, enableWrappingOnFocus } = props;
  const [isEditingDescription, setIsEditingDescription] = useState(false);
  const propertyDefinition = propertyInstance?.propertyDefinition;
  const { hash } = useLocation();
  const hashId = getIdFromHash(hash);

  const handleInputFocus = () => {
    appStore.env.setActivePropertyInstance(propertyInstance);
  };

  const renderInput = () => {
    switch (propertyDefinition?.dataType) {
      case PropertyDataType.scalar: {
        return (
          <ScalarExpressionEditor
            propertyInstance={propertyInstance}
            onFocus={handleInputFocus}
            enableWrappingOnFocus={enableWrappingOnFocus}
            allowMatchingTargetWidth
          />
        );
      }
      case PropertyDataType.string: {
        return (
          <ConfirmableNumericInput
            value={propertyInstance.value}
            tryConfirmValueFromString={propertyInstance.setStringValue}
            disabled={propertyInstance.locked}
            onFocus={handleInputFocus}
            placeholder="String value"
          />
        );
      }
      default: {
        return null;
      }
    }
  };

  const { formatType, unit, setFormatType } = propertyDefinition ?? {};

  const formattingMenuItems = useMemo(
    () =>
      [
        { label: "Number", icon: "array-numeric", formatType: FormatType.NUMBER },
        { label: "Number with commas", icon: "floating-point", formatType: FormatType.NUMBER_WITH_COMMAS },
        { label: "Engineering notation", icon: "calculator", formatType: FormatType.ENGINEERING_NOTATION },
        { label: "Exponential notation", icon: "calculator", formatType: FormatType.EXPONENTIAL_NOTATION },
        { label: "Currency", icon: "dollar", formatType: FormatType.CURRENCY },
        { label: "Percentage", icon: "percentage", formatType: FormatType.PERCENTAGE },
      ].map(({ label, formatType: _formatType }) => {
        if (!propertyDefinition) {
          return null;
        }
        if (isAllowedFormatTypeByUnitType(getUnitType(unit), _formatType)) {
          return (
            <MenuItem
              // icon={icon as IconName} // TODO - Removing it until further clarified.
              key={label}
              onClick={() => setFormatType?.(_formatType)}
              text={label}
              selected={_formatType === formatType}
              e2eIdentifiers={["formatting-menu", label]}
            />
          );
        }
      }),
    [formatType, propertyDefinition, setFormatType, unit]
  );

  const { pinnedProperties } = appStore.env;

  const pinnedMenuItem = useMemo(() => {
    if (pinnedProperties?.includes(propertyInstance)) {
      return (
        <MenuItem
          icon="unpin"
          text="Unpin property"
          onClick={() => appStore.env.unpinPropertyInstance(propertyInstance)}
          e2eIdentifiers="unpin-property"
        />
      );
    } else {
      return (
        <MenuItem
          icon="pin"
          text="Pin property"
          onClick={() => appStore.env.pinPropertyInstance(propertyInstance)}
          e2eIdentifiers="pin-property"
        />
      );
    }
  }, [pinnedProperties, propertyInstance]);

  if (!propertyDefinition) {
    return null;
  }

  const viewers = appStore.workspaceViewers.filter(viewer => viewer.entity === propertyInstance);

  return (
    <div
      {...wrapperProps}
      className={classNames(
        styles.blockPropertyListItem,
        "dnd-drag-handle-item entry-xd",
        {
          [styles.blockPropertyListItemDragging]: isDragging,
          [styles.newInstance]: propertyInstance.id === hashId,
        },
        wrapperProps?.className
      )}
    >
      <div className={styles.blockPropertyListItemPresence}>
        <PresenceList viewers={viewers} />
      </div>
      {/* 1. Section: Actions - Drag-handle-menu and Type-menu. */}
      <div className="flex gap-x-0.5 items-center item-menus">
        {/* Drag-handle menu. */}
        {/* TODO extract to separate component*/}
        <div data-testid={`expandMenuDiv_${propertyDefinition.label}`}>
          <Popover
            placement="left-start"
            content={
              <Menu>
                {block.propertyGroups && (
                  <>
                    <MenuItem e2eIdentifiers="definition" text="Definition">
                      <PropertyDefinitionMenu propertyDefinition={propertyDefinition} />
                    </MenuItem>
                    {!!block.propertyGroups.length && (
                      <>
                        <MenuDivider title="Move to Group" />
                        {block.propertyGroups.map(item => (
                          <MenuItem
                            key={item.id}
                            icon="group-objects"
                            text={item.label}
                            disabled={false}
                            selected={propertyInstance.propertyGroup === item.id}
                            onClick={() => propertyInstance.setGroup(item.id)}
                            e2eIdentifiers="move-to-group"
                          />
                        ))}
                      </>
                    )}
                    {propertyInstance.effectivePropertyGroup && (
                      <>
                        <MenuDivider />
                        <MenuItem
                          icon="ungroup-objects"
                          text="Remove from group"
                          onClick={propertyInstance.clearGroup}
                          e2eIdentifiers="remove-from-group"
                        />
                      </>
                    )}
                  </>
                )}
                <MenuDivider title="Views" />
                <MenuItem text="Formatting" icon="numerical" e2eIdentifiers="formatting">
                  {formattingMenuItems}
                </MenuItem>
                <MenuDivider title="Actions" />
                <div className={styles.lockEntry}>
                  <Icon icon="lock" />
                  <Switch
                    className={styles.lockSwitch}
                    label="Lock value"
                    alignIndicator={Alignment.RIGHT}
                    checked={propertyInstance.locked}
                    onChange={propertyInstance.toggleLocked}
                    e2eIdentifiers="lock-value"
                  />
                </div>
                <CopyLinkMenuItem node={propertyInstance} />
                <MenuItem
                  disabled={!!propertyDefinition.description || isEditingDescription}
                  onClick={() => setIsEditingDescription(true)}
                  text="Add description"
                  e2eIdentifiers="add-new-description"
                  icon="citation"
                />
                {pinnedMenuItem}
                <MenuItem
                  icon="graph"
                  text="Dependency graph"
                  intent={Intent.NONE}
                  onClick={appStore.env.showDependencyGraphWindow}
                  e2eIdentifiers="dependency-graph"
                />
                <MenuItem
                  icon="detection"
                  text="Details"
                  intent={Intent.NONE}
                  onClick={() => appStore.env.showPropertyDetailsViewer(propertyInstance)}
                  e2eIdentifiers="property-details"
                />
                <MenuDivider />
                <MenuItemDelete onDelete={() => block.deletePropertyInstance(propertyInstance)} />
              </Menu>
            }
          >
            <div {...dragListeners}>
              <Button
                minimal
                intent={Intent.NONE}
                icon={pinnedMode ? "more" : "drag-handle-vertical"}
                onClick={() => appStore.env.setActivePropertyInstance(propertyInstance)}
                e2eIdentifiers={["block-property-list-item", "more"]}
              />
            </div>
          </Popover>
        </div>

        <PropertyTypeMenuButton
          propertyInstance={propertyInstance}
          dataType={propertyDefinition.dataType}
          onDataTypeChange={propertyDefinition.setDataType}
        />
      </div>
      {/* 2. Section: Label and Smart Input. */}
      <BlockPropertyListRow
        block={block}
        propertyInstance={propertyInstance}
        showDescription={showDescription}
        isEditingDescription={isEditingDescription}
        pinnedMode={pinnedMode}
        renderInput={renderInput}
        onStartEditing={() => setIsEditingDescription(true)}
        onFinishEditing={() => setIsEditingDescription(false)}
      />
    </div>
  );
}

/** Exports. */
export type { BlockPropertyListItemProps };
export default observer(BlockPropertyListItem);
