import CatalogItemsFrame from "@components/CatalogItems/CatalogItemsFrame";
import Pane from "@router/components/Panes/Pane";

import "./CatalogItemsIndex.scss";

export const CATALOG_ITEMS_PANE = "CATALOG_ITEMS_PANE";

/** Main function. */
function CatalogItemsIndex() {
  return (
    <Pane className="catalog-items-index" id={CATALOG_ITEMS_PANE}>
      <CatalogItemsFrame />
    </Pane>
  );
}

/** Exports. */
export default CatalogItemsIndex;
