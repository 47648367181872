import { JSX } from "react";
import { FormGroup, InputGroup, NonIdealStateProps, TextArea } from "@blueprintjs/core";
import classNames from "classnames";
import { observer } from "mobx-react";

import { Dropdown } from "@components/Shared/Dropdown";
import { EStepFieldType, IStepField } from "@components/Shared/StepPanel/StepPanelTypes";
import { Text, TextVariant } from "src/ui/Text";

import "./StepPanel.scss";

interface IStepPanelProps {
  active: boolean;
  fields: IStepField[];
  nonIdealStateProps?: NonIdealStateProps;
}

const StepPanel = (props: IStepPanelProps): JSX.Element => {
  const { fields, active, nonIdealStateProps } = props;

  const renderStepField = (field: IStepField) => {
    switch (field.type) {
      case EStepFieldType.Input:
        return (
          <InputGroup
            className={classNames("step-panel--input", { ["step-panel--input-inactive"]: !active })}
            value={field.value ?? ""}
            onChange={e => field.onChange(e.target.value)}
            placeholder={field.placeholder ?? "Enter here"}
            required={field.required}
            disabled={!active}
            large
          />
        );
      case EStepFieldType.Dropdown:
        return (
          <Dropdown
            buttonProps={{ className: classNames({ ["step-panel--dropdown-inactive"]: !active }) }}
            popupMenuClassName="step-panel--dropdown-popup-menu"
            selected={field.selected}
            options={field.options}
            placeholder={field.placeholder}
            e2eIdentifiers={field.e2eIdentifiers}
            onSelect={field.onSelect}
            disabled={!active}
            nonIdealStateProps={nonIdealStateProps}
          />
        );
      case EStepFieldType.TextArea:
        return (
          <TextArea
            className={classNames("step-panel--text-area", { ["step-panel--text-area-inactive"]: !active })}
            value={field.value ?? ""}
            onChange={e => field.onChange(e.target.value)}
            placeholder={field.placeholder ?? "Enter here"}
            required={field.required}
            large
            rows={3}
            autoResize={false}
            disabled={!active}
          />
        );
    }
  };

  return (
    <div className="step-panel">
      {fields.map(field => (
        <FormGroup
          label={
            <Text className="step-panel--label" variant={TextVariant.Label}>
              {field.required ? field.name + "*" : field.name}
            </Text>
          }
          key={field.name}
        >
          {renderStepField(field)}
        </FormGroup>
      ))}
    </div>
  );
};

export default observer(StepPanel);
