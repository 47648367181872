import { SortOrders } from "../../SortOrders";

import { ProfileSortKeys } from "./ProfileSortKeys";

export enum ProfileStatuses {
  ACTIVE = "active",
  PENDING = "pending",
  INVITED = "invited",
}

export class GetProfilesRequestDto {
  public readonly statuses?: Array<ProfileStatuses>;
  public readonly take?: number;
  public readonly skip?: number;
  public readonly sortedBy?: keyof typeof ProfileSortKeys;
  public readonly sortOrder?: keyof typeof SortOrders;
  public readonly search?: string;
}
