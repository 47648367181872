import { MouseEvent } from "react";
import { Intent } from "@blueprintjs/core";
import { flow, Instance, IType, SnapshotIn, SnapshotOut, types } from "mobx-state-tree";
import { Socket } from "socket.io-client";

import { showToast } from "@components/UiLayers/toaster";
import { allowedIntegrations, Integration, IntegrationProvider } from "@rollup-api/models/integrations";
import appStore from "@store/AppStore";
import { rollupClient } from "src/core/api";
import { trackSegmentEvent } from "src/lib/Segment";

const SUMMARY_MAX_LENGTH = 147;

export const IntegrationStore = types
  .model("Integration", {
    id: types.identifier,
    name: types.string,
    description: types.maybeNull(types.string),
    shortSummary: types.maybeNull(types.string),
    ctaText: types.maybeNull(types.string),
    ctaUrl: types.maybeNull(types.string),
    headerImages: types.maybeNull(types.array(types.string)),
    logo: types.maybeNull(types.string),
    lightThemeLogo: types.maybeNull(types.string),
    publisher: types.maybeNull(types.string),
    publisherLink: types.maybeNull(types.string),
    documentationLink: types.maybeNull(types.string),
    isActive: types.maybeNull(types.boolean),
    isNative: types.maybeNull(types.boolean),
    types: types.maybeNull(types.array(types.string)),
    categories: types.maybeNull(types.array(types.string)),
    slug: types.maybeNull(types.string),
  })
  .volatile(() => ({
    connectWindow: null as Window | null,
    isAuthorized: undefined as boolean | undefined,
  }))
  .views(self => ({
    get isConnected(): boolean {
      if (!self.slug) {
        return false;
      }
      return appStore.orgModel.connectedProviders.has(self.slug as IntegrationProvider);
    },
    get connection() {
      if (!self.slug) {
        return undefined;
      }
      return appStore.orgModel.connectedProviders.get(self.slug as IntegrationProvider);
    },
  }))
  .actions(self => ({
    connect: flow(function* disconnect() {
      const segmentDto = { integration: self.name };
      trackSegmentEvent("integration:connect", segmentDto);
      let url = self.ctaUrl || "";
      const integrationSlug = self.slug as IntegrationProvider;
      if (self.isNative && allowedIntegrations.includes(integrationSlug as IntegrationProvider)) {
        try {
          const { data } = yield rollupClient.integrations.getProviderRedirectUrl(integrationSlug);
          url = data.redirectUrl;
          self.connectWindow = open(url, "_blank");
        } catch (error) {
          showToast("Could not initialize integration", Intent.DANGER);
        }
      } else {
        open(url, "_blank", "popup=true");
      }
    }),
    disconnect: flow(function* disconnect() {
      const segmentDto = { integration: self.name };
      if (self.connection) {
        yield appStore.orgModel.deleteIntegration(self.connection?.id);
        trackSegmentEvent("integration:disconnect", segmentDto);
        return;
      }
    }),
    reauthorize: flow(function* reauthorize() {
      const segmentDto = { integration: self.name };
      if (self.connection) {
        trackSegmentEvent("integration:reauthorize", segmentDto);
        let url = self.ctaUrl || "";
        const integrationSlug = self.slug as IntegrationProvider;
        if (self.isNative && allowedIntegrations.includes(integrationSlug)) {
          try {
            const { data } = yield rollupClient.integrations.getProviderRedirectUrl(integrationSlug, self.connection.id);
            url = data.redirectUrl;
            self.connectWindow = open(url, "_blank");
          } catch (error) {
            showToast("Could not initialize integration", Intent.DANGER);
          }
        } else {
          open(url, "_blank", "popup=true");
        }
        return;
      }
    }),
    refreshAuthStatus: flow(function* refreshAuthStatus() {
      self.isAuthorized = undefined;
      try {
        if (self.connection) {
          const res = yield rollupClient.integrations.get(self.connection?.id, true);
          self.isAuthorized = res.data?.isAuthorized ?? false;
        } else {
          self.isAuthorized = false;
        }
      } catch (error) {
        self.isAuthorized = false;
        console.warn(error);
      }
    }),
    // TODO replace with connect/disconnected
    onClick: flow(function* onClick(event: MouseEvent<HTMLElement>) {
      event.stopPropagation();
      const segmentDto = { integration: self.name };

      if (self.connection) {
        yield appStore.orgModel.deleteIntegration(self.connection?.id);
        trackSegmentEvent("integration:disconnect", segmentDto);
        return;
      }

      trackSegmentEvent("integration:connect", segmentDto);
      let url = self.ctaUrl || "";
      const integrationSlug = self.slug as IntegrationProvider;
      if (self.isNative && allowedIntegrations.includes(integrationSlug)) {
        try {
          const { data } = yield rollupClient.integrations.getProviderRedirectUrl(integrationSlug);
          url = data.redirectUrl;
          self.connectWindow = open(url, "_blank");
        } catch (error) {
          showToast("Could not initialize integration", Intent.DANGER);
        }
      } else {
        open(url, "_blank", "popup=true");
      }
    }),
    closeIntegrationWindow() {
      const window = self.connectWindow;
      if (window) {
        window.close();
        self.connectWindow = null;
        return true;
      }
      return false;
    },
  }))
  .views(self => ({
    get logoUrl(): string {
      let logo = self.logo || "";
      if (!appStore.env.themeIsDark && self.lightThemeLogo) {
        logo = self.lightThemeLogo;
      }

      return `${import.meta.env.VITE_STRAPI_API_URL}${logo}`;
    },
    get buttonLabel(): string {
      if (!self.isActive) {
        return "Coming soon";
      }

      if (self.isConnected) {
        return "Disconnect";
      }

      return self.ctaText || "Connect";
    },
    get summary(): string {
      const text = self.shortSummary || "";

      if (text.length > SUMMARY_MAX_LENGTH) {
        return `${text.slice(0, SUMMARY_MAX_LENGTH)}...`;
      }

      return text;
    },
    get buttonIntent(): Intent | undefined {
      if (!self.isActive) {
        return undefined;
      }

      return Intent.PRIMARY;
    },
  }));

export interface IIntegration extends Instance<typeof IntegrationStore> {}
interface IIntegrationSnapshotIn extends SnapshotIn<typeof IntegrationStore> {}
interface IIntegrationSnapshotOut extends SnapshotOut<typeof IntegrationStore> {}
export interface IIntegrationMobxType extends IType<IIntegrationSnapshotIn, IIntegrationSnapshotOut, IIntegration> {}

export function subscribeToIntegrationEvents(socket: Socket) {
  socket.on("createOnshapeConnection", (data: Integration) => {
    appStore.orgModel?.createIntegration(data);
  });

  socket.on("revokeOnshapeConnection", (data: Integration) => {
    appStore.orgModel?.deleteIntegration(data.provider);
  });
}
