import { useState } from "react";
import { Checkbox, Classes } from "@blueprintjs/core";
import classNames from "classnames";
import { observer } from "mobx-react";

import CreateNewInput from "@components/Shared/CreateNewInput/CreateNewInput";
import { useAppNavigate } from "@router/hooks";
import type { IBlock } from "@store/BlockStore";
import { getLabelsPath } from "@utilities/Block";

import { DialogLegacy, DialogProps } from "./index";

import "./DialogAddNewChildBlock.scss";

/**
 * Dialog for adding new child block to block.
 */

/** Type defs. */
type DialogAddNewChildBlockProps = DialogProps & {
  block: IBlock | null | undefined;
  placeholder: string | undefined;
  onClose: () => void;
};

/** Main function. */
function DialogAddNewChildBlock({ block, className, placeholder, onClose, title, ...rest }: DialogAddNewChildBlockProps) {
  const [addMore, setAddMore] = useState(false);
  const { navigateToBlock } = useAppNavigate();

  if (!block) {
    return null;
  }

  const toggleAddMore = () => setAddMore(!addMore);

  const handleClose = () => {
    onClose?.();
  };

  const handleValidation = (label: string) => ({ isValid: label.length > 0 });

  const handleCreate = async (label: string) => {
    try {
      const id = await block.addNewBlock(label);
      if (!id) {
        return false;
      } else {
        if (!addMore) {
          navigateToBlock(id);
        }
        return true;
      }
    } catch (err) {
      console.error(err);
      return false;
    } finally {
      if (!addMore) {
        handleClose();
      }
    }
  };

  const modalTitle = `${title} to ${getLabelsPath(block).join(" > ")}`;

  return (
    <DialogLegacy
      className={classNames("component--dialog-add-new-child-block", className)}
      onClose={handleClose}
      {...rest}
      title={modalTitle}
    >
      <div className="px-6 py-6">
        <CreateNewInput placeholder={placeholder} tryCreate={handleCreate} validation={handleValidation} autoFocus />
        <span className={classNames(Classes.RUNNING_TEXT, Classes.TEXT_MUTED, "mt-1", "block")}>Enter to create</span>
        <Checkbox
          className="dialog-add-new-child-block--checkbox mt-3"
          label="Create additional child block"
          onClick={toggleAddMore}
          checked={addMore}
        />
      </div>
    </DialogLegacy>
  );
}

/** Default props. */
DialogAddNewChildBlock.defaultProps = {
  title: "Add new child block",
  placeholder: "Add new child block",
  isCloseButtonShown: true,
};

/** Exports. */
export type { DialogAddNewChildBlockProps };
export default observer(DialogAddNewChildBlock);
