import { useState } from "react";
import { observer } from "mobx-react";

import { WorkspaceContext } from "@providers/workspace";
import appStore from "@store/AppStore";

import { DebugWindow } from "./DebugWindow/DebugWindow";
import { DependencyGraphWindow } from "./DependencyGraphWindow/DependencyGraphWindow";
import { HoopsDebugWindow } from "./HoopsDebugWindow/HoopsDebugWindow";
import { ActiveWindowContext } from "./ActiveWindowContext";
import { ExpressionEditorWindow } from "./ExpressionEditorWindow";
import { PinnedPropertyWindow } from "./PinnedPropertiesWindow";
import { UploadQueueWindow } from "./QueueWindow";

export const FloatingWindowManager = observer(() => {
  const [activeId, setActiveId] = useState("");
  const { hoopsDebugDialogEnabled, developerDialogEnabled } = appStore.ui;
  const { developerMode, dependencyGraphVisible, detachedExpressionEditorVisible } = appStore.env;

  const workspace = appStore.workspaceModel;

  return (
    <ActiveWindowContext.Provider value={{ activeId, setActiveId }}>
      {/* workspace-independent workspace */}
      {developerMode && developerDialogEnabled && <DebugWindow />}
      {/* workspace-dependent must be wrapped in a workspace context provider */}
      {!!workspace && (
        <WorkspaceContext.Provider value={workspace}>
          <PinnedPropertyWindow />
          {dependencyGraphVisible && <DependencyGraphWindow />}
          {detachedExpressionEditorVisible && <ExpressionEditorWindow />}
          {developerMode && hoopsDebugDialogEnabled && <HoopsDebugWindow />}
          <UploadQueueWindow />
        </WorkspaceContext.Provider>
      )}
    </ActiveWindowContext.Provider>
  );
});
