import { CreatePropertyInstanceDto, PropertyInstance, PropertyInstanceUpdateDto } from "@rollup-api/models/propertyInstance";
import { trackSegmentEvent } from "src/lib/Segment";

import { HttpClient, ParentClient } from "../client";

export class PropertyInstances extends HttpClient {
  public constructor(parent: ParentClient) {
    super(parent);
  }

  private get endpoint() {
    return `workspace/${this.parent.workspaceId}/property-instances`;
  }

  public create = (dto: CreatePropertyInstanceDto) => {
    trackSegmentEvent("property-instance:create", { id: dto.id, definitionId: dto.propertyDefinition });
    return this.instance.post<PropertyInstance>(this.endpoint, dto);
  };

  public update = (id: string, dto: PropertyInstanceUpdateDto) => {
    trackSegmentEvent("property-instance:update", { id, dto });
    return this.instance.patch<PropertyInstance>(`${this.endpoint}/${id}`, dto);
  };

  public lock = (id: string) => {
    trackSegmentEvent("property-instance:lock", { id });
    return this.instance.post<PropertyInstance>(`${this.endpoint}/${id}/lock`);
  };

  public unlock = (id: string) => {
    trackSegmentEvent("property-instance:unlock", { id });
    return this.instance.post<PropertyInstance>(`${this.endpoint}/${id}/unlock`);
  };

  public delete = (id: string) => {
    trackSegmentEvent("property-instance:delete", { id });
    return this.instance.delete<PropertyInstance>(`${this.endpoint}/${id}`);
  };

  public reorder = (id: string, destinationId: string, parentBlock?: string) => {
    trackSegmentEvent("property-instance:reorder", { id });
    return this.instance.patch(`${this.endpoint}/${id}/reorder`, { destinationId, parentBlock });
  };
}
