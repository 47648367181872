import { useEffect, useRef } from "react";
import { ButtonGroup } from "@blueprintjs/core";
import { Editor, NodeViewWrapper } from "@tiptap/react";

import VerticalDistributionIcon from "@assets/icons/vertical-distribution.svg?react";
import { Button } from "@components/Button";
import AssetImage from "@components/Shared/AssetImage/AssetImage";
import { LegacyCustomIcon } from "@components/Shared/LegacyCustomIcon/LegacyCustomIcon";
import { moveCaretToEnd } from "@utilities";

import { alignIcon, EAlignment } from "./constants";

import "./ImageResizeComponent.scss";

type Attributes = {
  width: number;
  height: number;
  align: EAlignment;
  caption: string;
  src: string;
  showCaption: boolean;
};

type Props = {
  editor: Editor;
  node: {
    attrs: Attributes;
  };
  updateAttributes: (e: Partial<Attributes>) => void;
  onResizeEnd: (editor: Editor) => void;
};

export const getImageResizeComponent = (url: string) => `<image-resizer src="${url}"></image-resizer>`;

const ImageResizeComponent = (props: Props) => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const captionRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (wrapperRef.current && captionRef.current) {
      captionRef.current.setAttribute("style", `width: ${wrapperRef.current.clientWidth}px`);
    }
  }, [wrapperRef, captionRef]);

  const handler = (mouseDownEvent: React.MouseEvent<HTMLDivElement>, leftResize: boolean) => {
    const parent = (mouseDownEvent.target as HTMLElement).closest(".image-resize");
    const image = parent?.querySelector("img.image-resize--image") ?? null;

    if (!image) {
      return;
    }

    const startSize = { x: image.clientWidth, y: image.clientHeight };
    const startPosition = { x: mouseDownEvent.pageX, y: mouseDownEvent.pageY };

    function onMouseMove(mouseMoveEvent: MouseEvent) {
      if (leftResize) {
        props.updateAttributes({
          width: startSize.x + (startPosition.x - mouseMoveEvent.pageX),
          height: startSize.y + (startPosition.y - mouseMoveEvent.pageY),
        });
      } else {
        props.updateAttributes({
          width: startSize.x - startPosition.x + mouseMoveEvent.pageX,
          height: startSize.y - startPosition.y + mouseMoveEvent.pageY,
        });
      }

      mouseDownEvent.stopPropagation();
    }

    function onMouseUp() {
      document.body.removeEventListener("mousemove", onMouseMove);
      props.onResizeEnd(props.editor);
    }

    document.body.addEventListener("mousemove", onMouseMove);
    document.body.addEventListener("mouseup", onMouseUp, { once: true });
  };

  const handleChangeCaption = (e: React.KeyboardEvent<HTMLDivElement>) => {
    const caption = (e.target as HTMLDivElement).innerText;
    props.updateAttributes({ caption });
    if (!e.metaKey && !e.ctrlKey) {
      moveCaretToEnd(e.target as HTMLDivElement);
    }
    props.onResizeEnd(props.editor);
  };

  const getNextAlignment = () => {
    const align = props.node.attrs.align;

    switch (align) {
      case EAlignment.LEFT:
        return EAlignment.CENTER;
      case EAlignment.CENTER:
        return EAlignment.RIGHT;
      default:
        return EAlignment.LEFT;
    }
  };

  const handleAlignmentChange = () => {
    props.updateAttributes({ align: getNextAlignment() });
    props.onResizeEnd(props.editor);
  };

  const handleShowCaption = () => {
    props.updateAttributes({ showCaption: !props.node.attrs.showCaption });
    props.onResizeEnd(props.editor);
  };

  const renderCaption = () => {
    const showCaption = props.node.attrs.showCaption;
    const caption = props.node.attrs.caption;

    if (showCaption) {
      return (
        <div
          ref={captionRef}
          suppressContentEditableWarning
          onKeyUp={handleChangeCaption}
          contentEditable
          style={{ width: wrapperRef.current?.clientWidth }}
          className="image-resize--caption"
        >
          {caption}
        </div>
      );
    }
  };

  const renderTrigger = () => {
    const align = props.node.attrs.align;

    switch (align) {
      case EAlignment.CENTER:
        return (
          <>
            <div className="image-resize--trigger image-resize--trigger-left" onMouseDown={e => handler(e, true)} />
            <div className="image-resize--trigger image-resize--trigger-right" onMouseDown={e => handler(e, false)} />
          </>
        );
      case EAlignment.RIGHT:
        return <div className="image-resize--trigger image-resize--trigger-left" onMouseDown={e => handler(e, true)} />;
      default:
        return <div className="image-resize--trigger" onMouseDown={e => handler(e, false)} />;
    }
  };

  return (
    <NodeViewWrapper className={`image-resize image-resize--${props.node.attrs.align}`}>
      <div className="image-resize--wrapper" ref={wrapperRef}>
        <AssetImage
          width={props.node.attrs.width}
          height={props.node.attrs.height}
          src={props.node.attrs.src}
          alt="resizable-image"
          className="image-resize--image"
        />
        {renderTrigger()}
        <ButtonGroup className="image-resize--actions">
          <Button
            small
            icon={alignIcon[props.node.attrs.align]}
            onClick={handleAlignmentChange}
            e2eIdentifiers={["image-resize-component", "align"]}
          />
          <Button
            small
            icon={<LegacyCustomIcon icon={<VerticalDistributionIcon />} />}
            onClick={handleShowCaption}
            e2eIdentifiers={["image-resize-component", "show-caption"]}
          />
        </ButtonGroup>
      </div>
      {renderCaption()}
    </NodeViewWrapper>
  );
};

export default ImageResizeComponent;
