import { Color } from "@rollup-types/colors";

export interface IComplexTag {
  value: string;
  color: Color;
}

export type ITag = IComplexTag | string;

export const isComplexTag = (tag: ITag): tag is IComplexTag => {
  return typeof tag === "object";
};

export const isStringTag = (tag: ITag): tag is string => {
  return typeof tag === "string";
};
