import { observer } from "mobx-react-lite";

import Avatar from "@components/Avatar/Avatar";
import UploadFileButton from "@components/UploadFileButton/UploadFileButton";
import appStore from "@store/AppStore";

import styles from "./ProfileConfig.module.scss";

const ProfileConfig = () => {
  return (
    <div className={styles.profileConfig}>
      <div className={styles.profileConfigAvatar}>
        <Avatar source={appStore.userModel?.avatarUrl} size={52} />
      </div>
      <UploadFileButton
        large
        className={styles.uploadButton}
        text={appStore.userModel?.avatarUrl ? "Upload new photo" : "Upload profile photo"}
        icon="mugshot"
        onChange={appStore.userModel?.uploadProfilePicture}
        e2eIdentifiers="upload-profile-photo"
      />
    </div>
  );
};

export default observer(ProfileConfig);
