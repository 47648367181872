import { Icon, NonIdealState } from "@blueprintjs/core";
import { observer } from "mobx-react";

import { Button } from "@components/Button";
import FileDropZone, { EFileFormat } from "@components/FileDropZone";
import { formatFileSize } from "@utilities";

import { FILE_IMPORT_LIMIT } from "./constants";

import "./FilePanel.scss";

type Props = {
  onChange: (file: File | null) => void;
  file: File | null;
};

const FilePanel = (props: Props) => {
  const { onChange, file } = props;

  const handleFilesChange = (e: FileList) => {
    if (e.length) {
      onChange(e[0]);
    }
  };

  const renderContent = () => {
    if (!file) {
      return (
        <NonIdealState
          icon="paperclip"
          iconSize={20}
          title="Select CSV or CAD file up to 100MB to upload"
          description="click or drag and drop it here"
        />
      );
    }

    return (
      <div className="file-panel--file">
        <div className="flex">
          <Icon size={20} className="file-panel--file-icon" icon="document" />
          <div>
            <div className="file-panel--file-name">{file.name}</div>
            <div className="file-panel--file-size">{formatFileSize(file.size)}</div>
          </div>
        </div>
        <Button minimal icon="trash" onClick={() => onChange(null)} e2eIdentifiers="delete" />
      </div>
    );
  };

  return (
    <div className="file-panel">
      <FileDropZone
        sizeLimit={FILE_IMPORT_LIMIT}
        format={[EFileFormat.CSV, EFileFormat.PRT, EFileFormat.SLDASM, EFileFormat.STP, EFileFormat.STEP]}
        clickToSelect
        onChange={handleFilesChange}
      >
        {renderContent}
      </FileDropZone>
    </div>
  );
};

export default observer(FilePanel);
