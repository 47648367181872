import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Intent } from "@blueprintjs/core";
import { useWorkspace } from "@hooks/useWorkspace";

import { showToast } from "@components/UiLayers/toaster";
import { AttachmentStatus } from "@rollup-api/models";
import appStore from "@store/AppStore";
import { IAttachmentModule } from "@store/AttachmentModuleStore";
import { getIdFromHash } from "@utilities";

export const useSetActiveAttachmentFromHash = (attachmentsParam?: IAttachmentModule) => {
  const { hash } = useLocation();
  const hashId = getIdFromHash(hash);
  const workspace = useWorkspace();
  const attachments = attachmentsParam || workspace.attachments;

  useEffect(() => {
    if (!hashId) {
      return;
    }
    const attachment = attachments.get(hashId);
    if (attachment) {
      if (attachment.status === AttachmentStatus.Converted) {
        appStore.env.setActiveAttachment(attachment);
      } else {
        appStore.env.setDetailedAttachment(attachment);
      }
    } else {
      showToast("Can't find attachment", Intent.WARNING);
    }
  }, [attachments, hashId]);
};
