import { ComponentProps } from "react";
import { Card, Icon, Intent, Menu, Spinner } from "@blueprintjs/core";
import { observer } from "mobx-react";

import { Button } from "@components/Button";
import { MenuItem } from "@components/MenuItem";
import { MenuItemDelete } from "@components/MenuItems";
import ThumbnailPlaceholder from "@components/Modeling/ModelingFrame/ModelBlock/Attachments/ThumbnailPlaceholder";
import { Popover } from "@components/Popover";
import { AttachmentType } from "@rollup-api/models/cloudStorage";
import { EFileUploadStatus, IUploadStore } from "@store/UploadStore";
import { formatFileSize } from "@utilities";
import { Text } from "src/ui/Text";

import { AttachmentCardActionRow } from "../../Attachments/AttachmentCardActionRow";
import AttachmentIcon from "../../Attachments/AttachmentIcon";
import { AttachmentInfo } from "../../Attachments/AttachmentInfo";
import fileUploadHooks from "../hooks";

import "./FileUploadCard.scss";

export type FileUploadCardProps = {
  uploadStore: IUploadStore;
};

const FileUploadCard = ({ uploadStore }: FileUploadCardProps) => {
  const { deleteFileUpload } = fileUploadHooks(uploadStore);

  const attachmentViewMenu = () => (
    <Popover
      placement="left-start"
      content={
        <Menu>
          {uploadStore.status === EFileUploadStatus.FAILED && (
            <MenuItem
              icon="refresh"
              text="Retry upload"
              intent={Intent.NONE}
              onClick={uploadStore.retryUploadChunks}
              e2eIdentifiers="retry-upload"
            />
          )}
          <MenuItemDelete onDelete={deleteFileUpload} />
        </Menu>
      }
    >
      <Button minimal icon="more" className="menu-button" e2eIdentifiers="menu" />
    </Popover>
  );

  return (
    <Card className="attachment-card">
      <ThumbnailPlaceholder icon={<AttachmentIcon mimeType={uploadStore.type} height={50} />} />
      <div className="attachment-actions">
        <AttachmentCardActionRow>
          <AttachmentIcon mimeType={uploadStore.type} />
          <Text className="attachment-actions--title" title={uploadStore.label}>
            {uploadStore.label}
          </Text>
          <div className="attachment-status--indicator">
            <AttachmentStatusIndicator status={uploadStore.status} />
          </div>
        </AttachmentCardActionRow>
        <AttachmentCardActionRow>
          <AttachmentInfo
            size={formatFileSize(uploadStore.file.size)}
            date={new Date(uploadStore.createdAt).toLocaleDateString("en-us", {
              // weekday: "long",
              // hour: "numeric",
              year: "numeric",
              month: "long",
              day: "numeric",
            })}
            type={AttachmentType.file}
          />
          <div className="attachment-actions--info-right">{attachmentViewMenu()}</div>
        </AttachmentCardActionRow>
      </div>
    </Card>
  );
};

type AttachmentStatusProps = {
  status: EFileUploadStatus;
};

export const AttachmentStatusIndicator = ({ status }: AttachmentStatusProps) => {
  if (status === EFileUploadStatus.UPLOADING) return <Spinner size={16} intent="primary" />;
  return <Icon {...statusIconProps[status]} title={StaticText.statuses.title[status]} />;
};

const statusIconProps = {
  [EFileUploadStatus.PAUSED]: { icon: "pause" },
  [EFileUploadStatus.FAILED]: { icon: "issue", color: "#CD4246" },
  [EFileUploadStatus.CANCELED]: { icon: "issue", color: "#CD4246" },
  [EFileUploadStatus.UPLOADED]: { icon: "confirm", color: "#32A467" },
  [EFileUploadStatus.PENDING]: { icon: null },
  [EFileUploadStatus.INITIALIZING]: { icon: null },
  [EFileUploadStatus.FINALIZING]: { icon: null },
  [EFileUploadStatus.UPLOADING]: { icon: null },
} satisfies Record<EFileUploadStatus, ComponentProps<typeof Icon>>;

const StaticText = {
  statuses: {
    title: {
      [EFileUploadStatus.PAUSED]: "Paused",
      [EFileUploadStatus.FAILED]: "Failed",
      [EFileUploadStatus.PENDING]: "Pending",
      [EFileUploadStatus.CANCELED]: "Canceled",
      [EFileUploadStatus.UPLOADED]: "Uploaded",
      [EFileUploadStatus.UPLOADING]: "Uploading",
      [EFileUploadStatus.INITIALIZING]: "Initializing",
      [EFileUploadStatus.FINALIZING]: "Finalizing",
    },
  },
} as const;

export default observer(FileUploadCard);
