import { Invoices } from "@rollup-api/api/billing/invoices";

import { HttpClient, ParentClient } from "../client";

export class BillingModule extends HttpClient {
  public readonly invoices: Invoices;

  public constructor(parent: ParentClient) {
    super(parent);
    this.invoices = new Invoices(parent);
  }
}
