import { MouseEventHandler, ReactNode, useEffect, useRef, useState } from "react";
import { TagProps } from "@blueprintjs/core";
import { ItemRendererProps } from "@blueprintjs/select";
import { BlueprintIcon } from "@ui/BlueprintIcon";
import { CustomCellRendererProps } from "ag-grid-react";
import classNames from "classnames";
import { observer } from "mobx-react";

import { MenuItem } from "@components/MenuItem";
import { MenuItemCreateNewTag } from "@components/MenuItems/MenuItemCreateNewTag";
import { MultiSelect } from "@components/MultiSelect";
import { PlusButton } from "@components/PlusButton";
import { IComplexSelectOption } from "@rollup-types/selection";
import { getIntentClassFromColor } from "@utilities";
import { withObservedValueGetter } from "@utilities/AgGridUtils";

import styles from "./TagMultiSelectCellRenderer.module.scss";

export interface ITagMultiSelectCellRendererOwnProps<T> {
  getOptions(data: T): IComplexSelectOption[];
  onSelect(data: T, value: IComplexSelectOption): void;
  onDelete(data: T, value: IComplexSelectOption): void;
}

type ITagMultiSelectCellRendererProps<T> = ITagMultiSelectCellRendererOwnProps<T> & CustomCellRendererProps<T, IComplexSelectOption[]>;

const TagMultiSelectCellRenderer = <T,>(props: ITagMultiSelectCellRendererProps<T>) => {
  const { data, onSelect, onDelete, api, column, node } = props;
  const selectedOptions = props.value ?? [];
  const options = data ? props.getOptions(data) : [];
  const [inputValue, setInputValue] = useState("");
  const [showInput, setShowInput] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (showInput) {
      inputRef.current?.focus();
    }
  }, [showInput]);

  if (!data || !column) {
    return null;
  }

  const refreshCell = () => api.refreshCells({ columns: [column], rowNodes: [node] });

  const handleItemPredicate = (query: string, option: IComplexSelectOption) => {
    return option.label.toLowerCase().indexOf(query.toLowerCase()) >= 0;
  };

  const handleDeleteOption = (option: IComplexSelectOption) => {
    onDelete(data, option);
    refreshCell();
  };

  const handleItemSelect = (option: IComplexSelectOption) => {
    if (selectedOptions.some(opt => opt.value === option.value)) {
      handleDeleteOption(option);
    } else {
      onSelect(data, option);
      refreshCell();
    }
  };

  const renderItem = (option: IComplexSelectOption, itemProps: ItemRendererProps) => {
    const { handleClick } = itemProps;

    const isSelected = selectedOptions.some(opt => opt.value === option.value);
    return (
      <MenuItem
        active={itemProps.modifiers.active}
        key={option.value}
        text={option.label}
        onClick={handleClick}
        e2eIdentifiers={`select-${option.value}`}
        rightElement={<BlueprintIcon className="menu-item-option--tick-icon" style={{ opacity: Number(isSelected) }} icon="small-tick" />}
      />
    );
  };

  const renderCreateNewItem = (query: string, active: boolean, onClick: MouseEventHandler<HTMLElement>) => {
    return <MenuItemCreateNewTag query={query} active={active} onClick={(_, e) => onClick(e)} />;
  };

  const tagProps = (value: ReactNode): TagProps => {
    const option = options.find((option: IComplexSelectOption) => option.label === value);
    const className = option?.color && getIntentClassFromColor(option.color);

    return {
      minimal: false,
      className,
    };
  };

  const onBlur = () => {
    setShowInput(false);
    setInputValue("");
  };

  return (
    <MultiSelect<IComplexSelectOption>
      query={inputValue}
      onQueryChange={setInputValue}
      placeholder=""
      resetOnSelect
      resetOnQuery
      onItemSelect={handleItemSelect}
      itemPredicate={handleItemPredicate}
      selectedItems={selectedOptions}
      onRemove={handleDeleteOption}
      popoverProps={{ minimal: true, matchTargetWidth: true }}
      tagInputProps={{
        tagProps,
        inputRef,
        className: styles.tagMultiSelectCellRendererTagInput,
        inputProps: {
          className: classNames(styles.tagMultiSelectCellRendererInput, { [styles.tagMultiSelectCellRendererInputHidden]: !showInput }),
          onBlur,
        },
        children: !showInput && <PlusButton onClick={() => setShowInput(true)} e2eIdentifiers="add-option" />,
      }}
      itemsEqual={(a: IComplexSelectOption, b: IComplexSelectOption) => a.value === b.value}
      tagRenderer={(option: IComplexSelectOption) => option.label}
      items={options}
      noResults={
        <MenuItem
          className={styles.tagMultiSelectCellRendererNoResults}
          disabled
          text="No results. Type to create an option"
          e2eIdentifiers="no-results"
        />
      }
      itemRenderer={renderItem}
      createNewItemFromQuery={query => ({ label: query, value: query })}
      createNewItemRenderer={renderCreateNewItem}
    />
  );
};

export default withObservedValueGetter(observer(TagMultiSelectCellRenderer));
