import { Menu } from "@blueprintjs/core";
import { observer } from "mobx-react-lite";

import { MenuItem } from "@components/MenuItem";
import { IntegrationLinkData } from "@rollup-api/models";
import { ReferenceLinkData } from "@rollup-api/models/references/references.model";

import ReportsFilterableMenu from "./InternalAttachments/ReportsFilterableMenu";
import RequirementsFilterableMenu from "./InternalAttachments/RequirementsFilterableMenu";
import { IntegrationsMenuSection } from "./Integrations";

type IAddAttachmentMenuProps = {
  onFileUpload(): void;
  onAttachLink(): void;
  onAttachIntegration(data: IntegrationLinkData): void;
  onAttachReferenceLink(data: ReferenceLinkData): void;
};

const AddAttachmentMenu = (props: IAddAttachmentMenuProps) => {
  return (
    <Menu>
      <MenuItem icon="upload" text="Upload file" onClick={props.onFileUpload} e2eIdentifiers="upload" />
      <MenuItem icon="link" text="Attach link" onClick={props.onAttachLink} e2eIdentifiers="link" />
      <RequirementsFilterableMenu onAttach={props.onAttachReferenceLink} />
      <ReportsFilterableMenu onAttach={props.onAttachReferenceLink} />
      <IntegrationsMenuSection onAttachIntegration={props.onAttachIntegration} />
    </Menu>
  );
};

export default observer(AddAttachmentMenu);
