import { IntercomProvider } from "react-use-intercom";
import * as Frigade from "@frigade/react";
import { useThemeSwitcher } from "@hooks/useThemeSwitcher/useThemeSwitcher";
import { QueryClientProvider } from "@tanstack/react-query";
import { observer } from "mobx-react";
import remotedev from "remotedev";

import AppRouter from "@router/AppRouter";
import appStore from "@store/AppStore";
import { initAgGridLicense } from "src/lib/AgGrid";
import { initDatadog } from "src/lib/Datadog";
import { FRIGADE_API_KEY } from "src/lib/Frigade/constants";
import { INTERCOM_APP_ID, PROVIDER_MESSENGER_PROPS } from "src/lib/Intercom/constants";
import { initSegment } from "src/lib/Segment";
import ThirdPartyInits from "src/lib/ThirdPartyInits";

import "@fontsource/jetbrains-mono";

import { connectReduxDevTools } from "./lib/MstMiddlewares/redux";
import { queryClient } from "./lib/ReactQuery/queryClient";

import "./App.scss";
import "./styles/main.scss";

if (!import.meta.env.PROD) {
  connectReduxDevTools(remotedev, appStore);
}

initAgGridLicense();
initDatadog();
initSegment();

/** Main function. */
function App() {
  useThemeSwitcher();

  return (
    <Frigade.Provider apiKey={FRIGADE_API_KEY}>
      <IntercomProvider
        autoBootProps={PROVIDER_MESSENGER_PROPS}
        apiBase="https://analytics.rollup.ai/intercom"
        appId={INTERCOM_APP_ID}
        autoBoot
      >
        <ThirdPartyInits />
        <QueryClientProvider client={queryClient}>
          <AppRouter />
        </QueryClientProvider>
      </IntercomProvider>
    </Frigade.Provider>
  );
}

/** Exports. */
export default observer(App);
