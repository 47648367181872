import capitalize from "lodash/capitalize";

import { ExecutionEnvironmentType } from "@rollup-api/models/execution-environments";

export const getDefaultCode = (type: ExecutionEnvironmentType) => {
  switch (type) {
    case ExecutionEnvironmentType.Python:
      return "print('Hello, World!')";
    case ExecutionEnvironmentType.Node:
    case ExecutionEnvironmentType.Bun:
      return 'console.log("Hello, World!")';
    case ExecutionEnvironmentType.Bash:
      return "echo 'Hello, World!'";
    case ExecutionEnvironmentType.Matlab:
      return "disp('Hello, World!')";
    case ExecutionEnvironmentType.Julia:
      return 'print("Hello, World!")';
    default:
      return "";
  }
};

export const getLanguageFromType = (type: ExecutionEnvironmentType) => {
  switch (type) {
    case ExecutionEnvironmentType.Python:
      return "python";
    case ExecutionEnvironmentType.Node:
      return "javascript";
    case ExecutionEnvironmentType.Bun:
      return "typescript";
    case ExecutionEnvironmentType.Bash:
      return "shell";
    case ExecutionEnvironmentType.Matlab:
      return "matlab";
    case ExecutionEnvironmentType.Julia:
      return "julia";
    default:
      return "";
  }
};

export const getDefaultVariableName = (type: ExecutionEnvironmentType, isOutput = false) => {
  const defaultName = isOutput ? "output" : "input";
  switch (type) {
    case ExecutionEnvironmentType.Python:
    case ExecutionEnvironmentType.Julia:
    case ExecutionEnvironmentType.Bash:
      return `new_${defaultName}`;
    case ExecutionEnvironmentType.Node:
    case ExecutionEnvironmentType.Bun:
    case ExecutionEnvironmentType.Matlab:
      return `new${capitalize(defaultName)}`;
    default:
      return `new_${defaultName}`;
  }
};
