import { PopoverPosition, Tooltip } from "@blueprintjs/core";
import { observer } from "mobx-react";

import { RelativeDateWithTooltip } from "@components/RelativeDateWithTooltip";
import { UnknownUserTag } from "@components/Reports/Editor/Extentions/Mention/MentionTag/UnknownUserTag";
import UserInfo from "@components/UserInfo/UserInfo";
import appStore from "@store/AppStore";
import { Text, TextVariant } from "src/ui/Text";

import styles from "./CommentHeader.module.scss";

interface CommentHeaderProps {
  userId?: string;
  userName?: string;
  avatarUrl?: string;
  createdAt: number;
  updatedAt?: number;
  formattedUpdatedAt?: string;
}

const CommentHeader = ({ userId, userName, avatarUrl, createdAt, updatedAt, formattedUpdatedAt }: CommentHeaderProps) => {
  const user = userId && appStore.orgModel.info.userWithId(userId);

  const renderUserInfo = () => {
    if (user) {
      return <UserInfo user={user} size="small" nowrap={false} />;
    } else if (userName && avatarUrl) {
      return <UserInfo userName={userName} avatarUrl={avatarUrl} size="small" nowrap={false} />;
    }
    return <UnknownUserTag showAvatar />;
  };

  return (
    <div className={styles.commentHeaderOwnerHeader}>
      {renderUserInfo()}
      <div className={styles.commentHeaderOwnerHeaderTimeInfo}>
        <RelativeDateWithTooltip popoverPosition={PopoverPosition.TOP} variant={TextVariant.BodyDimmed} epochTime={createdAt} />
        {createdAt !== updatedAt && (
          <Tooltip content={`Last edited at ${formattedUpdatedAt}`} minimal>
            <Text variant={TextVariant.Caption} nowrap>
              - edited
            </Text>
          </Tooltip>
        )}
      </div>
    </div>
  );
};

export default observer(CommentHeader);
