import { BomMetaColumn } from "@rollup-api/models";
import { IBlock } from "@store/BlockStore";
import { currencyCellValueFormatter } from "@utilities/AgGridUtils";

import { isCurrency } from "./Units";

export function getSubtotalColumnValue(block: IBlock, returnNumber?: boolean): string | number {
  const costInstance = block.propertyInstances?.find(i => {
    return i.propertyDefinition?.label.toLowerCase() === "cost";
  });

  if (costInstance && costInstance.intrinsicValue) {
    const unit = costInstance.propertyDefinition?.unit || "";
    const isCurrencyUnit = isCurrency(unit);
    const value = block.multiplicity ? block.multiplicity * costInstance.intrinsicValue : costInstance.intrinsicValue;

    if (returnNumber) {
      return value;
    }

    return isCurrencyUnit ? currencyCellValueFormatter(unit, value) : `${value}`;
  }

  return returnNumber ? 0 : "";
}

export function getBomColumnLabelFromMetaColumn(metaColumn: BomMetaColumn): string {
  switch (metaColumn) {
    case BomMetaColumn.Id:
      return "ID";
    case BomMetaColumn.Name:
      return "Name";
    case BomMetaColumn.PartNumber:
      return "Part number";
    case BomMetaColumn.ProductGroup:
      return "Product";
    case BomMetaColumn.Level:
      return "Level";
    case BomMetaColumn.BlockType:
      return "Block type";
    case BomMetaColumn.Notes:
      return "Notes";
    case BomMetaColumn.File:
      return "File";
    case BomMetaColumn.Thumbnail:
      return "Thumbnail";
    case BomMetaColumn.UnitOfMeasure:
      return "Unit of measure";
    case BomMetaColumn.Multiplicity:
      return "Multiplicity";
    case BomMetaColumn.Subtotal:
      return "Subtotal";
    default:
      return "Unknown";
  }
}

export const getBomMetaColumns = (): BomMetaColumn[] => [
  BomMetaColumn.Name,
  BomMetaColumn.PartNumber,
  BomMetaColumn.Thumbnail,
  BomMetaColumn.File,
  BomMetaColumn.ProductGroup,
  BomMetaColumn.Level,
  BomMetaColumn.Notes,
  BomMetaColumn.Multiplicity,
  BomMetaColumn.Subtotal,
];

export const getBomRelatedProperties = (): BomMetaColumn[] => [BomMetaColumn.Subtotal];
