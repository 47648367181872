import { useEffect } from "react";
import defer from "lodash/defer";

interface IUseOnDocumentClickParams {
  disabled?: boolean;
  onDocumentClick(e: MouseEvent): void;
}

export const useOnDocumentClick = (params: IUseOnDocumentClickParams) => {
  const { onDocumentClick, disabled } = params;

  useEffect(() => {
    if (!disabled) {
      // defer is used to prevent the event listener from being added and removed in the same click
      defer(() => document.addEventListener("click", onDocumentClick));
    }

    return () => document.removeEventListener("click", onDocumentClick);
  }, [onDocumentClick, disabled]);
};
