import { Text, TextVariant } from "@ui/Text";
import { observer } from "mobx-react";

import { Button } from "@components/Button";
import appStore from "@store/AppStore";

import styles from "./EntityAnnotationListHeader.module.scss";

interface IEntityAnnotationListHeaderProps {
  entityId: string;
  entityIdsWithComments: string[];
  onClose(): void;
}

const EntityAnnotationListHeader = (props: IEntityAnnotationListHeaderProps) => {
  const { entityId, entityIdsWithComments, onClose } = props;
  const currentIndex = entityIdsWithComments.indexOf(entityId);
  const totalCount = entityIdsWithComments.length;
  const { setActiveCommentPopoverEntityId } = appStore.ui;

  const handleMoveToPreviousAnnotation = () => {
    if (currentIndex > 0) {
      const previousEntityId = entityIdsWithComments[currentIndex - 1];
      setActiveCommentPopoverEntityId(previousEntityId);
      onClose();
    }
  };

  const handleMoveToNextAnnotation = () => {
    if (currentIndex < totalCount - 1) {
      const nextEntityId = entityIdsWithComments[currentIndex + 1];
      setActiveCommentPopoverEntityId(nextEntityId);
      onClose();
    }
  };

  return (
    <div className={styles.entityAnnotationListHeader}>
      <div className={styles.navigation}>
        <Button
          minimal
          icon="chevron-left"
          onClick={handleMoveToPreviousAnnotation}
          disabled={currentIndex === 0}
          e2eIdentifiers="previous"
        />
        <Text variant={TextVariant.Caption}>
          {currentIndex + 1} of {totalCount}
        </Text>
        <Button
          minimal
          icon="chevron-right"
          onClick={handleMoveToNextAnnotation}
          disabled={currentIndex === totalCount - 1}
          e2eIdentifiers="next"
        />
      </div>
      <Button icon="cross" onClick={onClose} e2eIdentifiers="close" minimal />
    </div>
  );
};

export default observer(EntityAnnotationListHeader);
