import assignIn from "lodash/assignIn";
import { action, observable } from "mobx";
import { Instance, IType, SnapshotIn, SnapshotOut, types } from "mobx-state-tree";
import { Socket } from "socket.io-client";

import { defaultEmptyConfigString } from "@components/Modeling/ModelingFrame/Table/TableConfigTabs/constants";
import appStore from "@store/AppStore";

import { TConfigDataObjects, TConfigJson } from "../Components/Modeling/ModelingFrame/Table/TableComponent/types";
import { applyActiveColumnStateConfig } from "../Components/Modeling/ModelingFrame/Table/TableComponent/utils";
import { showApiErrorToast } from "../Components/UiLayers/toaster";
import { rollupClient } from "../core/api";
import { CreateTableViewConfigDto, TableViewConfigUpdateDto } from "../lib/RollupClient/models/tableViewConfig";

class TableViewConfigUiStore {
  @observable accessor activeConfigId = "";

  @action.bound
  public setActiveConfigId(id: string) {
    this.activeConfigId = id;
  }
}

export const TableViewConfigStore = types
  .model("TableViewConfig", {
    id: types.identifier,
    config: types.optional(types.string, defaultEmptyConfigString),
    createdBy: types.optional(types.string, ""),
    label: types.string,
    orderIndex: types.optional(types.number, 0),
    createdAt: types.optional(types.number, Date.now()),
    updatedAt: types.optional(types.number, Date.now()),
  })
  .volatile(() => ({
    ui: new TableViewConfigUiStore(),
  }))
  .actions(self => ({
    patch(update: TableViewConfigUpdateDto) {
      // Prevent updating of fixed properties
      const invalidFields = ["id", "ui", "createdAt", "createdBy"];
      const updateKeys = Object.keys(update);
      for (const field of invalidFields) {
        if (updateKeys.includes(field)) {
          return false;
        }
      }

      try {
        assignIn(self, update);
        if (updateKeys.includes("config") && appStore.env.activeTableViewConfigId == self.id && update.config) {
          if (appStore.env.tableViewGridApi) {
            applyActiveColumnStateConfig(appStore.env.tableViewGridApi);
          }
        }
        return true;
      } catch (err) {
        console.warn(err);
        return false;
      }
    },
  }))
  .actions(self => ({
    setLabel(label: string, notify = true) {
      self.label = label;
      if (notify) {
        rollupClient.tableViewConfigs.update(self.id, { label }).catch((err: Error) => {
          showApiErrorToast("Error updating table view config", err);
        });
      }
    },
    setConfig(config: string) {
      self.config = config;
      rollupClient.tableViewConfigs.update(self.id, { config }).catch((err: Error) => {
        showApiErrorToast("Error updating table view config", err);
      });
    },
  }))
  .views(self => ({
    get configObject(): TConfigDataObjects {
      try {
        const parsedJson: TConfigJson = JSON.parse(self.config || defaultEmptyConfigString);
        return parsedJson.data;
      } catch (err) {
        return JSON.parse(defaultEmptyConfigString);
      }
    },
  }));

export function subscribeToTableViewConfigsEvents(socket: Socket) {
  socket.on(
    "createTableViewConfig",
    (data: { workspaceId: string; createTableViewConfigDto: CreateTableViewConfigDto; userId: string }) => {
      if (data.workspaceId === appStore.workspaceModel?.id) {
        const { config, id, label } = data.createTableViewConfigDto;
        appStore.workspaceModel?.addNewTableViewConfig(config, false, label, id);
      }
    }
  );

  socket.on("deleteTableViewConfig", (data: { workspaceId: string; id: string; userId: string }) => {
    if (data.id && data.workspaceId === appStore.workspaceModel?.id) {
      const config = appStore.workspaceModel?.tableViewConfigs.get(data.id);
      config && appStore.workspaceModel?.deleteTableViewConfig(config, false);
    }
  });

  socket.on(
    "updateTableViewConfig",
    (data: { workspaceId: string; id: string; updateTableViewConfigDto: TableViewConfigUpdateDto; userId: string }) => {
      if (data.id && data.workspaceId === appStore.workspaceModel?.id) {
        const config = appStore.workspaceModel?.tableViewConfigs.get(data.id);
        config && config.patch(data.updateTableViewConfigDto);
      }
    }
  );

  // @todo add reorder - table view configs iteration 3
  // socket.on("reorderTableViewConfig", (data: { workspaceId: string; id: string; reorderTableViewConfigDto: { destinationId: string } }) => {
  //   if (data.id && data.reorderTableViewConfigDto?.destinationId && data.workspaceId === appStore.workspaceModel?.id) {
  //   }
  // });
}

export interface ITableViewConfig extends Instance<typeof TableViewConfigStore> {}

interface ITableViewConfigSnapshotIn extends SnapshotIn<typeof TableViewConfigStore> {}

interface ITableViewConfigSnapshotOut extends SnapshotOut<typeof TableViewConfigStore> {}

export interface ITableViewConfigMobxType extends IType<ITableViewConfigSnapshotIn, ITableViewConfigSnapshotOut, ITableViewConfig> {}
