import { Outlet } from "react-router-dom";

import PaneGroup from "@router/components/Panes/PaneGroup";

function DefaultLayout() {
  return (
    <PaneGroup>
      <Outlet />
    </PaneGroup>
  );
}

export default DefaultLayout;
