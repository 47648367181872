import { HTMLProps } from "react";
import classNames from "classnames";

import styles from "./Bar.module.scss";

type BarProps = HTMLProps<HTMLDivElement>;

export const Bar = ({ className, ...rest }: BarProps) => {
  return <div className={classNames(styles.bar, className)} {...rest} />;
};
