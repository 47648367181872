import { Divider, Intent, Menu } from "@blueprintjs/core";
import { observer } from "mobx-react-lite";

import { MenuItem } from "@components/MenuItem";
import { MenuItemDelete } from "@components/MenuItems";
import { getAttachmentLink } from "@components/Modeling/ModelingFrame/ModelBlock/Attachments/utils";
import { CopyLinkMenuItem } from "@components/SmallComponents";
import { AttachmentStatus } from "@rollup-api/models/cloudStorage";
import { IAttachment } from "@store/AttachmentStore";
import { createCopyToClipboardHandler, FilePreviewType, getPreviewType } from "@utilities";

interface IAttachmentContextMenuFileProps {
  attachment: IAttachment;
  onDelete(): void;
  onDownload(): void;
  onRetry(): void;
  onOpenEditingLabel(): void;
  onOpenDetailsDialog(): void;
  onMarkAsMain?(): void;
  onAddVersion?(): void;
}

const AttachmentContextMenuFile = ({
  attachment,
  onAddVersion,
  onDelete,
  onDownload,
  onRetry,
  onOpenEditingLabel,
  onOpenDetailsDialog,
  onMarkAsMain,
}: IAttachmentContextMenuFileProps) => {
  const previewType = getPreviewType(attachment);
  const showCopyLinkItem = previewType !== FilePreviewType.None;

  const handleCopyLink = () => {
    const linkUrl = getAttachmentLink(attachment);
    if (linkUrl) {
      const copyToClipboard = createCopyToClipboardHandler(linkUrl, "attachment link");
      copyToClipboard();
    }
  };

  return (
    <Menu>
      {attachment.status === AttachmentStatus.ConversionFailed && (
        <MenuItem icon="refresh" text="Retry conversion" intent={Intent.NONE} onClick={onRetry} e2eIdentifiers="retry-convertion" />
      )}
      <MenuItem icon="download" text="Download" intent={Intent.NONE} onClick={onDownload} e2eIdentifiers="download" />
      <MenuItem icon="info-sign" text="Details" intent={Intent.NONE} onClick={onOpenDetailsDialog} e2eIdentifiers="details" />
      {showCopyLinkItem && (
        <MenuItem icon="link" text="Copy link" intent={Intent.NONE} onClick={handleCopyLink} e2eIdentifiers="copy-link" />
      )}
      {onMarkAsMain && <MenuItem icon="star" text="Mark as main file" onClick={onMarkAsMain} e2eIdentifiers="mark-as-main" />}
      {onAddVersion && <MenuItem icon="plus" text="Add new version" onClick={() => onAddVersion()} e2eIdentifiers="add-new-version" />}
      <CopyLinkMenuItem node={attachment} />
      <Divider />
      <MenuItem icon="edit" text="Edit label" intent={Intent.NONE} onClick={onOpenEditingLabel} e2eIdentifiers="edit-label" />
      <MenuItemDelete onDelete={onDelete} />
    </Menu>
  );
};

export default observer(AttachmentContextMenuFile);
