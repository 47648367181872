import { Menu } from "@blueprintjs/core";
import { observer } from "mobx-react";

import { Button } from "@components/Button";
import { CommentThreadDisplayStyle } from "@components/Modeling/ModelingFrame/ModelBlock/Comments/CommentThread";
import CommentThreadList from "@components/Modeling/ModelingFrame/ModelBlock/Comments/CommentThreadList";
import { CommentLocationType } from "@components/Modeling/ModelingFrame/ModelBlock/Comments/utils";
import { Popover } from "@components/Popover";
import { Switch } from "@components/Switch";
import appStore from "@store/AppStore";
import { IAttachment } from "@store/AttachmentStore";
import { CustomOperatorNames } from "@store/HoopsViewerStore";
import { Text, TextVariant } from "src/ui/Text";

import "./AnnotationsPanel.scss";

type AnnotationsPanelProps = {
  viewer: Communicator.WebViewer | undefined;
  attachment: IAttachment;
};

const AnnotationsPanel = ({ viewer, attachment }: AnnotationsPanelProps) => {
  // TODO: handle search later
  // const [searchQuery, setSearchQuery] = useState("");
  // useEffect(() => {
  //   setSearchQuery("");
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [appStore.env.hoopsSidePanelState]);

  const handleAddAnnotation = () => {
    appStore.env.setShowSmartAnnotations(true);
    const operatorId = appStore.env.attachmentViewer?.getCustomOperatorIdByName(CustomOperatorNames.AnnotationOperator);
    if (!operatorId || !viewer) {
      return;
    }

    appStore.env.attachmentViewer?.activateAnnotationMode(viewer, operatorId);
  };

  // TODO: handle search later
  // const normalizedSearchQuery = searchQuery.toLowerCase();
  // const handleAnnotationsFilter = (annotation: IComment) => {
  //   return searchQuery ? annotation.text.toLowerCase().includes(normalizedSearchQuery) : true;
  // };

  return (
    <>
      <div className="hoops-right-panel-annotation--header">
        <Text variant={TextVariant.H3}>Annotations</Text>
        <div>
          <Button disabled={!viewer} icon="add" minimal onClick={handleAddAnnotation} e2eIdentifiers="add-annotation">
            Add annotation
          </Button>
          <Popover
            position="bottom-right"
            content={
              <Menu>
                <Switch
                  className="hoops-right-panel-annotation--toggle"
                  alignIndicator="right"
                  e2eIdentifiers="toggle-show-annotations"
                  checked={appStore.env.showSmartAnnotations}
                  label="Show annotations"
                  onChange={appStore.env.toggleShowSmartAnnotations}
                />
              </Menu>
            }
          >
            <Button minimal e2eIdentifiers="annotations-more" icon="more" />
          </Popover>
        </div>
      </div>
      {/* TODO: handle search later */}
      {/* <div className="hoops-right-panel-annotation--search">
        <InputGroup
          value={searchQuery}
          onChange={e => setSearchQuery(e.target.value)}
          placeholder="Search for annotations"
          leftIcon="search"
          large
        />
      </div> */}
      <div className="hoops-right-panel-annotation--list">
        <CommentThreadList
          commentThreadList={attachment.annotationList.commentThreadList}
          showOlderThreads
          showNewerThreads
          type={CommentLocationType.Annotation}
          parentId={attachment.id}
          displayStyle={CommentThreadDisplayStyle.Sidebar}
        />
      </div>
    </>
  );
};

export default observer(AnnotationsPanel);
