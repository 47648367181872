import { useState } from "react";

export const useBubbleMenu = () => {
  const [isOpen, setIsOpen] = useState(false);

  const onShow = () => {
    setIsOpen(true);
  };

  const onHide = () => {
    setIsOpen(false);
  };

  return {
    onShow,
    onHide,
    isOpen,
  };
};
