import { Color } from "@rollup-types/colors";
import { VerificationStatus } from "@store/RequirementsStore";

export const getReqVerificationTagColor = (verificationStatus: VerificationStatus): Color => {
  switch (verificationStatus) {
    case VerificationStatus.Pending:
      return Color.Orange;
    case VerificationStatus.Pass:
      return Color.Green;
    case VerificationStatus.Fail:
      return Color.Red;
  }
};
