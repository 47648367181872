import { observer } from "mobx-react";

import BirdEatsBug from "./BirdEatsBug";
import Intercom from "./Intercom";

const ThirdPartyInits = () => {
  return (
    <div>
      <BirdEatsBug />
      <Intercom />
    </div>
  );
};

export default observer(ThirdPartyInits);
