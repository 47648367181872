import { useMemo } from "react";
import { ButtonGroup, IconName, MaybeElement, Position, Tooltip } from "@blueprintjs/core";
import { observer } from "mobx-react";

import ViewsBackViewIconDark from "@assets/icons/hoopsIcons/dark/views_back_view_dark.svg?react";
import ViewsBottomViewIconDark from "@assets/icons/hoopsIcons/dark/views_bottom_ view_dark.svg?react";
import ViewsDefaultViewIconDark from "@assets/icons/hoopsIcons/dark/views_default_dark.svg?react";
import ViewsFrontViewIconDark from "@assets/icons/hoopsIcons/dark/views_front_view_dark.svg?react";
import ViewsIsometricViewIconDark from "@assets/icons/hoopsIcons/dark/views_isometric_view_dark.svg?react";
import ViewsLeftViewIconDark from "@assets/icons/hoopsIcons/dark/views_left_view_dark.svg?react";
import ViewsRightViewIconDark from "@assets/icons/hoopsIcons/dark/views_right_view_dark.svg?react";
import ViewsTopViewIconDark from "@assets/icons/hoopsIcons/dark/views_top_view_dark.svg?react";
import ViewsBackViewIconLight from "@assets/icons/hoopsIcons/light/views_back_view_light.svg?react";
import ViewsBottomViewIconLight from "@assets/icons/hoopsIcons/light/views_bottom_ view_light.svg?react";
import ViewsDefaultViewIconLight from "@assets/icons/hoopsIcons/light/views_default_light.svg?react";
import ViewsFrontViewIconLight from "@assets/icons/hoopsIcons/light/views_front_view_light.svg?react";
import ViewsIsometricViewIconLight from "@assets/icons/hoopsIcons/light/views_isometric_view_light.svg?react";
import ViewsLeftViewIconLight from "@assets/icons/hoopsIcons/light/views_left_view_light.svg?react";
import ViewsRightViewIconLight from "@assets/icons/hoopsIcons/light/views_right_view_light.svg?react";
import ViewsTopViewIconLight from "@assets/icons/hoopsIcons/light/views_top_view_light.svg?react";
import { Button } from "@components/Button";
import { Popover } from "@components/Popover";
import { LegacyCustomIcon } from "@components/Shared/LegacyCustomIcon/LegacyCustomIcon";
import appStore from "@store/AppStore";

import styles from "../HoopsMenu.module.scss";

type HoopsMenuPresetViewButtonGroupProps = {
  viewer: Communicator.WebViewer;
};

type PresetView = {
  name: string;
  description?: string;
  view: Communicator.ViewOrientation;
  icon: IconName | MaybeElement;
};

const presetViews: PresetView[] = [
  {
    name: "Isometric",
    view: Communicator.ViewOrientation.Iso,
    icon: <LegacyCustomIcon large icon={appStore.env.themeIsDark ? <ViewsIsometricViewIconDark /> : <ViewsIsometricViewIconLight />} />,
  },
  {
    name: "Left",
    view: Communicator.ViewOrientation.Left,
    icon: <LegacyCustomIcon large icon={appStore.env.themeIsDark ? <ViewsLeftViewIconDark /> : <ViewsLeftViewIconLight />} />,
  },
  {
    name: "Right",
    view: Communicator.ViewOrientation.Right,
    icon: <LegacyCustomIcon large icon={appStore.env.themeIsDark ? <ViewsRightViewIconDark /> : <ViewsRightViewIconLight />} />,
  },
  {
    name: "Top",
    view: Communicator.ViewOrientation.Top,
    icon: <LegacyCustomIcon large icon={appStore.env.themeIsDark ? <ViewsTopViewIconDark /> : <ViewsTopViewIconLight />} />,
  },
  {
    name: "Bottom",
    view: Communicator.ViewOrientation.Bottom,
    icon: <LegacyCustomIcon large icon={appStore.env.themeIsDark ? <ViewsBottomViewIconDark /> : <ViewsBottomViewIconLight />} />,
  },
  {
    name: "Front",
    view: Communicator.ViewOrientation.Front,
    icon: <LegacyCustomIcon large icon={appStore.env.themeIsDark ? <ViewsFrontViewIconDark /> : <ViewsFrontViewIconLight />} />,
  },
  {
    name: "Back",
    view: Communicator.ViewOrientation.Back,
    icon: <LegacyCustomIcon large icon={appStore.env.themeIsDark ? <ViewsBackViewIconDark /> : <ViewsBackViewIconLight />} />,
  },
];

const HoopsMenuPresetViewButtonGroup = ({ viewer }: HoopsMenuPresetViewButtonGroupProps) => {
  const handlePresetViewChanged = (presetView: Communicator.ViewOrientation) => {
    appStore.env.attachmentViewer?.setViewOrientation(presetView);
    viewer.view.setViewOrientation(presetView);
  };

  const presetViewMenu = (
    <ButtonGroup large>
      {presetViews.map(entry => (
        <Tooltip position={Position.TOP} hoverOpenDelay={500} key={entry.name} content={entry.name}>
          <Button
            className={styles.hoopsToolbarButtonAndButtonGroup}
            icon={entry.icon}
            active={appStore.env.attachmentViewer?.presetView === entry.view}
            onClick={() => handlePresetViewChanged(entry.view)}
            e2eIdentifiers="change-preset-view"
          />
        </Tooltip>
      ))}
    </ButtonGroup>
  );

  const presetViewEntry = useMemo(() => presetViews.find(e => e.view === appStore.env.attachmentViewer?.presetView), []);

  const presetViewIcon = () =>
    presetViewEntry ? (
      presetViewEntry.icon
    ) : (
      <LegacyCustomIcon large icon={appStore.env.themeIsDark ? <ViewsDefaultViewIconDark /> : <ViewsDefaultViewIconLight />} />
    );

  const presetViewTooltipText = () => {
    if (appStore.env.attachmentViewer?.presetView && appStore.env.attachmentViewer?.presetView >= 0) {
      return `Preset view: ${presetViewEntry?.name}`;
    } else {
      return "Preset view";
    }
  };

  return (
    <Popover content={presetViewMenu} placement={Position.TOP}>
      <Tooltip position={Position.TOP} content={presetViewTooltipText()}>
        <Button className={styles.hoopsToolbarButtonAndButtonGroup} icon={presetViewIcon()} e2eIdentifiers="preset-view-menu" />
      </Tooltip>
    </Popover>
  );
};

export default observer(HoopsMenuPresetViewButtonGroup);
