import { forwardRef, LegacyRef } from "react";
// eslint-disable-next-line no-restricted-imports
import { Popover as BPPopover, PopoverProps } from "@blueprintjs/core";
import { observer } from "mobx-react";

interface IPopoverProps extends PopoverProps {}

export type IPopoverRefType = BPPopover;

const Popover = forwardRef((props: IPopoverProps, ref: LegacyRef<IPopoverRefType>) => {
  return <BPPopover minimal ref={ref} {...props} />;
});

Popover.displayName = "Popover";

export default observer(Popover);
