import { Text, TextVariant } from "@ui/Text";
import { observer } from "mobx-react";

import { Button } from "@components/Button";
import DataSourceIcon from "@components/DataSources/DataSourceIcon/DataSourceIcon";
import { Icon } from "@components/Icon";
import { IDataConnection } from "@store/DataConnection/DataConnectionModuleStore";
import { isDataSink, isDataSource } from "@utilities/DataConnection";

import styles from "./DataConnectionDropdownSidePanel.module.scss";

interface IDataConnectionDropdownSidePanelProps {
  dataConnections: IDataConnection[];
  selectedDataConnection?: IDataConnection;
  onSelect(dataSource: IDataConnection): void;
}

const DataConnectionDropdownSidePanel = (props: IDataConnectionDropdownSidePanelProps) => {
  const { dataConnections, selectedDataConnection, onSelect } = props;

  const renderIcon = (dataConnection: IDataConnection) => {
    if (isDataSource(dataConnection)) {
      return <DataSourceIcon dataSource={dataConnection} />;
    } else if (isDataSink(dataConnection)) {
      return dataConnection.icon ? <Icon icon={dataConnection.icon} /> : null;
    }
  };

  return (
    <div className={styles.dataConnectionDropdownSidePanel}>
      <div className={styles.dataConnectionDropdownSidePanelHeading}>
        <Text className={styles.dataConnectionDropdownSidePanelHeadingText} variant={TextVariant.Caption}>
          Data Connection
        </Text>
      </div>
      {dataConnections.map(dataConnection => (
        <Button
          key={dataConnection.id}
          className={styles.dataConnectionDropdownSidePanelItem}
          onClick={() => onSelect(dataConnection)}
          icon={renderIcon(dataConnection)}
          e2eIdentifiers="select-data-source"
          active={selectedDataConnection?.id === dataConnection.id}
          minimal
        >
          {dataConnection.label}
        </Button>
      ))}
    </div>
  );
};

export default observer(DataConnectionDropdownSidePanel);
