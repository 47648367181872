import { observer } from "mobx-react";

import appStore from "@store/AppStore";

import Ribbon from "./Ribbon/Ribbon";
import TabulatedViewTable from "./TableComponent/TabulatedViewTable";
import TableConfigTabs from "./TableConfigTabs";

import "./TabulatedView.scss";

const TabulatedView = () => {
  return (
    <div className="component--tabulated-view">
      <TableConfigTabs
        activeTabId={appStore.env.activeTableViewConfigId}
        tableViewConfigs={appStore.workspaceModel?.listTableViewConfigs}
      />
      <Ribbon />
      <TabulatedViewTable />
    </div>
  );
};

export default observer(TabulatedView);
