import { UseQueryOptions } from "@tanstack/react-query";

import { EGitHubRepoStatus, IGitHubRepoStatus, SimpleGitHubRepoMetadata } from "@rollup-api/models";

import { QueryKeys, rollupClient } from "../core/api";

const FIVE_MINUTES = 5 * 60 * 1000;

export abstract class GitHubQOFactory {
  public static createGetStatusQO(repoMetadata?: SimpleGitHubRepoMetadata): UseQueryOptions<IGitHubRepoStatus, unknown, EGitHubRepoStatus> {
    return {
      queryKey: [QueryKeys.GitHubRepoStatusCheck, repoMetadata?.owner, repoMetadata?.name],
      queryFn: (): Promise<IGitHubRepoStatus> => {
        if (!repoMetadata) {
          return Promise.resolve({ status: EGitHubRepoStatus.Failed });
        }
        return rollupClient.integrations.fetchGitHubRepoStatus(repoMetadata).then(res => res.data);
      },
      select: data => data.status,
      refetchInterval: FIVE_MINUTES,
      enabled: !!repoMetadata?.owner && !!repoMetadata?.name,
    };
  }
}
