import { IAnnotation } from "@store/AnnotationStore";

import { AnnotationMarkupManager } from "./AnnotationMarkupManager";

export class AnnotationMarkup extends Communicator.Markup.MarkupItem {
  public static className = "AnnotationMarkup";

  private _viewer: Communicator.WebViewer;
  private _annotationMarkupManager: AnnotationMarkupManager;

  private _annotationElementId: string | null = null;

  private _pointInstanceId?: Communicator.NodeId;

  private _position: Communicator.Point3 = Communicator.Point3.zero();
  private _selectionPosition: Communicator.Point3 = Communicator.Point3.zero();
  private _selectionNormal: Communicator.Point3 = Communicator.Point3.zero();
  private _partId: Communicator.PartId = 0;
  private _faceIndex = 0;

  private _pointBoundingBox?: Communicator.Box;

  private _lengthFactor = 0.03;

  private _deleted = false;

  private _callbacks: Communicator.CallbackMap | null = null;

  private _container!: HTMLDivElement;
  private _textArea!: HTMLTextAreaElement;

  // store connections
  private _annotation: IAnnotation | undefined;

  public constructor(
    viewer: Communicator.WebViewer,
    annotationMarkupManager: AnnotationMarkupManager,
    args: {
      selectionPosition: Communicator.Point3;
      selectionNormal: Communicator.Point3;
      partId: Communicator.PartId;
      faceIndex: number;
    }
  ) {
    super();

    this._viewer = viewer;
    this._annotationMarkupManager = annotationMarkupManager;
    this._selectionPosition = args.selectionPosition;
    this._selectionNormal = args.selectionNormal;
    this._partId = args.partId;
    this._faceIndex = args.faceIndex;

    // below is needed for hoops to register the markup element. We don't actually use it
    this._container = document.createElement("div");
    this._container.className = "noteTextElement";
    this._textArea = document.createElement("textarea");
    this._container.appendChild(this._textArea);
    this._container.style.display = "none";

    // Continue async
    this._init();
  }

  private async _init(): Promise<void> {
    const matrix = this._createPointTransformationMatrix(this._selectionPosition, this._selectionNormal);
    this._pointInstanceId = await this._createPointInstance(matrix);

    this._viewer.markupManager.registerMarkup(this);
    await this.draw();

    this._callbacks = {
      visibilityChanged: () => {
        this._matchPartVisibility();
      },
    };
    this._viewer.setCallbacks(this._callbacks);
  }

  public setAnnotation(annotation: IAnnotation): void {
    this._annotation = annotation;
  }

  public async draw(): Promise<void> {
    if (this._deleted) {
      return;
    }

    await this.updatePosition();

    const screenPos = this._viewer.view.projectPoint(this._position);
    const ray = this._viewer.view.raycastFromPoint(new Communicator.Point2(screenPos.x, screenPos.y));
    if (ray) {
      const pick = await this._viewer.view.pickFromRay(ray, new Communicator.PickConfig());
      if (pick.getFaceEntity()?.getCadFaceIndex() !== this._faceIndex && !this._annotation?.locationData3D?.isBehindView) {
        this._annotation?.setIsBehindView(true);
      } else if (pick.getFaceEntity()?.getCadFaceIndex() === this._faceIndex && this._annotation?.locationData3D?.isBehindView) {
        this._annotation?.setIsBehindView(false);
      }
    }

    this._updateAnnotationPosition(screenPos.x - 22, screenPos.y - 36); // magic numbers depend on the size of the annotation marker
    this._setAnnotationHidden(false);
    if (this._annotationElementId === null) {
      this._annotationElementId = this._viewer.markupManager.addMarkupElement(this._container);
    }
  }

  private _updateAnnotationPosition(x: number, y: number): void {
    if (this._annotation) {
      this._annotation.updateScreenPosition(x, y);
    }
  }

  private _setAnnotationHidden(hidden: boolean): void {
    if (this._annotation) {
      this._annotation.setIsHidden(hidden);
    }
  }

  private _matchPartVisibility(): void {
    if (this._pointInstanceId === undefined) {
      return;
    }

    const model = this._viewer.model;
    const partVisibility = model.getNodeVisibility(this._partId);
    const pointVisibility = model.getNodeVisibility(this._pointInstanceId);

    // match point visibility to associated part visibility
    if (partVisibility !== pointVisibility && !this._annotationMarkupManager.getExplodeActive()) {
      model.setNodesVisibility([this._pointInstanceId], partVisibility) as Communicator.Internal.UnusedPromise;
    }
  }

  public async updatePosition(): Promise<void> {
    if (this._pointInstanceId === undefined) {
      return;
    }
    const box = await this._viewer.model.getNodeRealBounding(this._pointInstanceId);
    this._pointBoundingBox = box;
    this._position = this._pointBoundingBox.center();
  }

  public async remove(): Promise<void> {
    if (this._deleted) {
      // This is already removed. Nothing to do
      return;
    }

    if (this._callbacks !== null) {
      this._viewer.unsetCallbacks(this._callbacks);
      this._callbacks = null;
    }

    const model = this._viewer.model;
    const ps: Promise<void>[] = [];

    // delete point instance
    if (this._pointInstanceId !== undefined) {
      ps.push(model.deleteMeshInstances([this._pointInstanceId]));
    }

    this._deleted = true;

    await Communicator.Util.waitForAll(ps);

    super.remove();
  }

  // point methods
  private _createPointTransformationMatrix(selectionPosition: Communicator.Point3, normal: Communicator.Point3): Communicator.Matrix {
    // rotate
    let i = 0;
    let min = normal.x;
    if (Math.abs(normal.y) < Math.abs(min)) {
      min = normal.y;
      i = 1;
    }
    if (Math.abs(normal.z) < Math.abs(min)) {
      i = 2;
    }

    const x = [0, 0, 0];
    x[i] = 1;
    const point = Communicator.Point3.createFromArray(x);

    const tangent0 = Communicator.Point3.cross(normal, point).normalize();
    const tangent1 = Communicator.Point3.cross(normal, tangent0);

    let matrix = new Communicator.Matrix();

    matrix.m = [normal.x, normal.y, normal.z, 0, tangent0.x, tangent0.y, tangent0.z, 0, tangent1.x, tangent1.y, tangent1.z, 0, 0, 0, 0, 1];

    matrix = Communicator.Matrix.multiply(
      matrix,
      new Communicator.Matrix().setScaleComponent(this._lengthFactor, this._lengthFactor, this._lengthFactor)
    );

    matrix.setTranslationComponent(selectionPosition.x, selectionPosition.y, selectionPosition.z);

    return matrix;
  }

  private async _createPointInstance(matrix: Communicator.Matrix): Promise<Communicator.NodeId> {
    const pointMeshId = this._annotationMarkupManager.getPointMeshId();
    if (pointMeshId === null) {
      throw new Communicator.CommunicatorError("Point hasn't been created yet");
    }

    const meshInstanceData = new Communicator.MeshInstanceData(
      pointMeshId,
      matrix,
      "point-instance",
      undefined,
      Communicator.Color.black()
    );

    meshInstanceData.setOpacity(0);

    const instanceFlags =
      Communicator.MeshInstanceCreationFlags.SuppressCameraScale |
      Communicator.MeshInstanceCreationFlags.DoNotCut |
      Communicator.MeshInstanceCreationFlags.DoNotExplode |
      Communicator.MeshInstanceCreationFlags.DoNotXRay |
      Communicator.MeshInstanceCreationFlags.ExcludeBounding |
      Communicator.MeshInstanceCreationFlags.OverrideSceneVisibility |
      Communicator.MeshInstanceCreationFlags.AlwaysDraw;
    meshInstanceData.setCreationFlags(instanceFlags);

    const model = this._viewer.model;
    return model.createMeshInstance(meshInstanceData, undefined, true, true);
  }
}
