import { ReactNode } from "react";
import { Tabs, TabsProps } from "@blueprintjs/core";
import classNames from "classnames";

import { createDataTestId, ElementType, IE2EIdentifiers } from "@utilities/E2EUtils";

import styles from "./Selector.module.scss";

export interface SelectorProps extends Omit<TabsProps, "id"> {
  options: { id: string; label: ReactNode; e2eIdentifiers: IE2EIdentifiers; tag?: ReactNode }[];
  selected: string;
  className?: string;
  onSelected?: (id: string) => void;
}

const Selector = (props: SelectorProps) => {
  const { className, options, selected, onSelected, ...rest } = props;

  return (
    <div className={classNames(styles.selector, className)}>
      <Tabs
        {...rest}
        id="selector"
        large
        selectedTabId={selected !== null ? selected : undefined}
        onChange={id => {
          onSelected?.(id.toString());
        }}
      >
        {options.map(option => (
          <Tabs.Tab
            key={option.id}
            id={option.id}
            title={option.label}
            tagContent={option.tag}
            {...createDataTestId(ElementType.Tab, option.e2eIdentifiers)}
          />
        ))}
      </Tabs>
    </div>
  );
};

export default Selector;
