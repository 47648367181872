import { CreateStatusDefinitionDto, StatusDefinition, StatusDefinitionUpdateDto } from "../models/statusDefinition";

import { HttpClient, ParentClient } from "./client";

export class StatusDefinitions extends HttpClient {
  public constructor(parent: ParentClient) {
    super(parent);
  }

  private get endpoint() {
    return `workspace/${this.parent.workspaceId}/status-definitions`;
  }

  public create = (dto: CreateStatusDefinitionDto) => {
    return this.instance.post<StatusDefinition>(`${this.endpoint}`, dto);
  };

  public update = (id: string, dto: StatusDefinitionUpdateDto) => {
    return this.instance.patch<StatusDefinition>(`${this.endpoint}/${id}`, dto);
  };

  public reorder = (id: string, destinationId: string) => {
    return this.instance.patch(`${this.endpoint}/${id}/reorder`, { destinationId });
  };

  public delete = (id: string) => {
    return this.instance.delete<StatusDefinition>(`${this.endpoint}/${id}`);
  };
}
