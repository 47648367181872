const APP_NAME = "Rollup";

export const createNewLogger = (loggerName: string) => {
  return {
    debug: (...args: any[]) => {
      console.debug(`%c[${APP_NAME}][${loggerName}]`, "font-weight:bold", ...args);
    },
    error: (...args: any[]) => {
      console.error(`%c[${APP_NAME}][${loggerName}]`, "font-weight:bold", ...args);
    },
  };
};
