import { BlueprintIcon } from "@ui/BlueprintIcon";
import { Text, TextVariant } from "@ui/Text";
import classNames from "classnames";
import { observer } from "mobx-react";

import { getPropTypeIcon } from "@components/Block/utils";
import { getExpressionValue } from "@components/Shared/ScalarExpressionEditor/utils";
import { IPropertyDefinition } from "@store/PropertyDefinitionStore";
import { IPropertyInstance } from "@store/PropertyInstanceStore";

import "./BlockPropertyItem.scss";

interface IBlockPropertyItemReadOnlyProps {
  propertyInstance: IPropertyInstance;
  propertyDefinition: IPropertyDefinition;
  className?: string;
}

const BlockPropertyItem = (props: IBlockPropertyItemReadOnlyProps) => {
  const { propertyInstance, propertyDefinition, className } = props;
  const propIcon = getPropTypeIcon(propertyDefinition.dataType);

  return (
    <div className={classNames("block-property-item", className)}>
      <BlueprintIcon icon={propIcon} />
      <Text variant={TextVariant.Body}>{propertyDefinition.label}</Text>
      <Text variant={TextVariant.Body}>{getExpressionValue(propertyInstance)}</Text>
    </div>
  );
};

export default observer(BlockPropertyItem);
