import { useWorkspace } from "@hooks/useWorkspace";
import { observer } from "mobx-react-lite";

import { DocumentIcon } from "@components/DocumentIcon";
import { MenuItem } from "@components/MenuItem";
import { ReferenceLinkData } from "@rollup-api/models/references/references.model";
import appStore from "@store/AppStore";
import { IReport } from "@store/ReportsStore";
import { EntityType } from "@store/types";

import InternalAttachmentFilterableMenu from "./InternalAttachmentFilterableMenu";

interface IAddAttachmentMenuRequirementsProps {
  onAttach(data: ReferenceLinkData): void;
}

const ReportsFilterableMenu = (props: IAddAttachmentMenuRequirementsProps) => {
  const workspace = useWorkspace();
  const onAttachReport = (report: IReport) => {
    props.onAttach({ entityId: report.id, entityType: EntityType.Report, label: report.label, workspaceId: workspace.id });
  };

  return (
    <MenuItem icon={<DocumentIcon entityType={EntityType.Report} />} text="Add from Knowledgebase" e2eIdentifiers="report">
      <InternalAttachmentFilterableMenu<IReport>
        itemName="Page"
        collection={appStore.workspaceModel?.reports ?? []}
        placeholder="Search for page"
        onAttachDocument={onAttachReport}
      />
    </MenuItem>
  );
};

export default observer(ReportsFilterableMenu);
