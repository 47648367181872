import { Position, Tooltip } from "@blueprintjs/core";
import { observer } from "mobx-react";

import { Button } from "@components/Button";
import appStore from "@store/AppStore";
import { FeatureFlag } from "@store/FeatureFlagsStore";

import styles from "../HoopsMenu.module.scss";

const HoopsDebugButton = () =>
  appStore.env.featureFlags.enabled(FeatureFlag.THREE_D_VIEWER_CONFIGURATION) ? (
    <Tooltip position={Position.TOP} hoverOpenDelay={500} content="Viewer configuration">
      <Button
        className={styles.hoopsToolbarButtonAndButtonGroup}
        icon="wrench"
        active={appStore.ui.hoopsDebugDialogEnabled}
        onClick={appStore.ui.toggleHoopsDebugDialog}
        e2eIdentifiers="toggle-hoops-debug-dialog"
      />
    </Tooltip>
  ) : null;

export default observer(HoopsDebugButton);
