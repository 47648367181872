import { cast, flow, getParent, getSnapshot, IAnyModelType, Instance, types } from "mobx-state-tree";

import { CommentLocationType } from "@components/Modeling/ModelingFrame/ModelBlock/Comments/utils";
import { showApiErrorToast } from "@components/UiLayers/toaster";
import { TemporalDirection } from "@rollup-api/models/comments/commentGetThreadedRequestDto.model";
import { Transaction, TransactionCommentEntityData } from "@rollup-api/models/transactions";
import { convertTimestamp } from "@utilities";
import { rollupClient } from "src/core/api";

import { IComment } from "./CommentStore";
import { CommentThreadListStore, CommentThreadListType } from "./CommentThreadListStore";
import { ICommentThread } from "./CommentThreadStore";
import { EFeedEntityType, FeedStore } from "./FeedStore";

export const CommentFeedStore = types
  .model("CommentFeed", {
    commentFeed: types.array(types.late((): IAnyModelType => FeedStore)),
    earlierIncrementalCommentFeed: types.array(types.late((): IAnyModelType => FeedStore)),
    commentThreadList: types.optional(CommentThreadListStore, {
      parentTake: 1,
      type: CommentThreadListType.Discrete,
    }),
    feedParentCommentIds: types.array(types.string),
    hasMoreCommentFeedItems: types.optional(types.boolean, true),
    isFetchingCommentFeed: types.optional(types.boolean, false),
  })
  .views(self => ({
    getThreadFromTransaction(transaction: Transaction<TransactionCommentEntityData>): ICommentThread | undefined {
      const parentCommentId = transaction.newEntity?.parentCommentId ?? transaction.referenceId;
      return self.commentThreadList.threads.find(thread => thread.parentComment?.id === parentCommentId);
    },
    getCommentFromTransaction(transaction: Transaction<TransactionCommentEntityData>): IComment | undefined {
      const isChildComment = !!transaction.newEntity?.parentCommentId;
      const thread = this.getThreadFromTransaction(transaction);

      if (isChildComment) {
        return thread?.childComments.find(comment => comment.id === transaction.referenceId);
      } else {
        return thread?.parentComment;
      }
    },
  }))
  .actions(self => ({
    setHasMoreCommentFeedItems(hasMore: boolean) {
      self.hasMoreCommentFeedItems = hasMore;
    },
    clearCommentHistory() {
      self.commentFeed = cast([]);
      self.earlierIncrementalCommentFeed = cast([]);
      self.commentThreadList.reset();
      self.feedParentCommentIds = cast([]);
      this.setHasMoreCommentFeedItems(true);
      self.isFetchingCommentFeed = false;
    },
  }))
  .actions(self => ({
    buildEarlierCommentThreads: flow(function* buildEarlierCommentThreads(): Generator<any, boolean, any> {
      const parent = getParent(self, 1) as any;
      // convert transaction data
      const earlierFeedParentComments: Array<{ parentCommentId: string; parentId: string }> = [];

      self.earlierIncrementalCommentFeed.forEach((t: Transaction) => {
        if (t.referenceType !== EFeedEntityType.COMMENT) {
          return;
        }

        // if no parent comment id, it is a root comment
        const parentCommentId = t.newEntity.parentCommentId ?? t.referenceId;

        if (
          !self.feedParentCommentIds.includes(parentCommentId) &&
          !earlierFeedParentComments.some(c => c.parentCommentId === parentCommentId)
        ) {
          earlierFeedParentComments.push({ parentCommentId, parentId: t.newEntity.parentId });
        }
      });

      // there is no guaranteed continuity we need to fetch each thread one by one
      for (const { parentCommentId, parentId } of earlierFeedParentComments) {
        yield self.commentThreadList.fetchSingleThread({
          temporalDirection: TemporalDirection.Older,
          focusedCommentId: parentCommentId,
          type: parentId === parent.id ? CommentLocationType.Root : CommentLocationType.Annotation,
          parentId,
        });
      }
      yield new Promise(resolve => setTimeout(resolve, 100));
      self.feedParentCommentIds.push(...earlierFeedParentComments.map(i => i.parentCommentId));
      return true;
    }),
  }))
  .actions(self => ({
    loadThreadsForTransactions: flow(function* fetchThreadsForTransactions(transactions: Transaction[]): Generator<any, boolean, boolean> {
      self.earlierIncrementalCommentFeed = cast([
        ...transactions.map((i: Transaction) => ({
          ...i,
          createdAt: convertTimestamp(i.createdAt),
        })),
      ]);
      // Construct feed stores, add the new feed part to the end of the current feed (if exists).
      self.commentFeed = cast([...self.commentFeed, ...getSnapshot(self.earlierIncrementalCommentFeed)]);
      return yield self.buildEarlierCommentThreads();
    }),
  }))
  .actions(self => ({
    fetch: flow(function* fetch({
      additionalParentIds = [],
      force,
    }: {
      additionalParentIds?: string[];
      force?: boolean;
    }): Generator<any, boolean, any> {
      // do not trigger load if we already have loaded the feed
      if (self.commentFeed.length && !force) {
        return false;
      }

      const parent = getParent(self, 1) as any;

      self.isFetchingCommentFeed = true;
      const referenceTypes = [EFeedEntityType.COMMENT];

      try {
        let endTime = self.commentFeed.at(-1)?.createdAt;
        endTime = endTime ? new Date(endTime) : undefined;
        const { data, status } = yield rollupClient.transactions.list({
          parentIds: [parent.id, ...additionalParentIds],
          referenceTypes,
          endTime,
        });

        if (status !== 200) {
          console.error(`Error fetching comment history for entity ${parent.id}`);
          showApiErrorToast(`Error fetching comment history`);
          return false;
        }

        const { transactions, hasMore } = data;

        yield self.loadThreadsForTransactions(transactions);

        self.setHasMoreCommentFeedItems(hasMore);
        return true;
      } catch (error) {
        console.error(`Error fetching comment history for entity ${parent.id}`, error);
        self.setHasMoreCommentFeedItems(false);
        return false;
      } finally {
        self.isFetchingCommentFeed = false;
      }
    }),
    handleCreateCommentTransaction(transaction: Transaction<TransactionCommentEntityData>) {
      const isChildComment = !!transaction.newEntity?.parentCommentId;

      if (isChildComment) {
        // load the new comment into the correct thread
        const thread = self.getThreadFromTransaction(transaction);
        thread?.loadCommentIntoThread(transaction.referenceId);
      } else {
        // fetch the thread from the BE and add it to the threads list, since this is a new root comment
        self.loadThreadsForTransactions([transaction]);
      }
    },
    handleDeleteCommentTransaction(transaction: Transaction<TransactionCommentEntityData>) {
      const isChildComment = !!transaction.newEntity?.parentCommentId;
      const thread = self.getThreadFromTransaction(transaction);
      if (!thread) {
        return;
      }

      if (isChildComment) {
        thread.removeComment(transaction.referenceId);
      } else {
        self.commentThreadList.threads.remove(thread);
      }
    },
    handleUpdateCommentTransaction(transaction: Transaction<TransactionCommentEntityData>) {
      const comment = self.getCommentFromTransaction(transaction);
      comment?.patch({ text: transaction.newEntity.text });
    },
  }));

export interface ICommentFeed extends Instance<typeof CommentFeedStore> {}
