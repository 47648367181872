import { Callout, CalloutProps } from "@blueprintjs/core";
import classNames from "classnames";
import { observer } from "mobx-react";

import { Button } from "@components/Button";
import appStore from "@store/AppStore";

import "./DismissibleCallout.scss";

interface PropsWithCalloutKey {
  calloutKey: string;
}

interface PropsWithDismissed {
  dismissed: boolean;
  onDismiss: () => void;
}

export type DismissibleCalloutProps = CalloutProps & (PropsWithCalloutKey | PropsWithDismissed);

function DismissibleCallout({ children, ...rest }: DismissibleCalloutProps) {
  let handleDismiss: () => void;
  let isDismissed: boolean;
  let calloutProps: CalloutProps;

  if ("dismissed" in rest) {
    const { dismissed, onDismiss, ...calloutPropsRest } = rest;
    handleDismiss = onDismiss;
    isDismissed = dismissed;
    calloutProps = calloutPropsRest;
  } else {
    const { calloutKey, ...calloutPropsRest } = rest;
    handleDismiss = () => appStore.env.dismissCallout(calloutKey);
    isDismissed = appStore.env.hasDismissedCallout(calloutKey);
    calloutProps = calloutPropsRest;
  }

  if (isDismissed) {
    return null;
  }

  return (
    <Callout {...calloutProps} className={classNames("dismissible-callout", { untitled: !calloutProps.title?.trim()?.length })}>
      <Button
        className="dismissible-callout--close-button"
        // we cannot use onClick otherwise the data source dropdown will close due to onDocumentClick logic in ScalarExpressionEditor
        onMouseUp={handleDismiss}
        icon="cross"
        e2eIdentifiers="dismiss-callout"
        minimal
      />
      {children}
    </Callout>
  );
}

export default observer(DismissibleCallout);
