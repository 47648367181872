import { IIcon } from "@rollup-types/icons";
import { StoreType } from "@store/types";
import { getPropertyInstanceByIdAsync } from "@utilities";
import { getAttachmentById } from "@utilities/Attachment";
import { getBlockByIdWithWorkspace } from "@utilities/Block";
import { createBpIcon } from "@utilities/Icon";

export const getParentNameAndIcon = async (
  parentType: StoreType,
  parentId: string,
  workspaceId: string
): Promise<{ parentName: string; parentIcon?: IIcon }> => {
  let parentName: string = "";
  let parentIcon: IIcon | undefined;
  switch (parentType) {
    case StoreType.Block:
      {
        const block = await getBlockByIdWithWorkspace(parentId, workspaceId);
        parentName = block?.label ?? "";
        parentIcon = block?.iconView;
      }
      break;
    case StoreType.Attachment:
      {
        const attachment = await getAttachmentById(parentId, workspaceId);
        parentName = attachment?.label ?? "";
        parentIcon = createBpIcon("comment");
      }
      break;
    case StoreType.PropertyInstance:
      {
        const propertyInstance = await getPropertyInstanceByIdAsync(parentId, workspaceId);
        parentName = propertyInstance?.label ?? "";
        parentIcon = createBpIcon("property");
      }
      break;
    case StoreType.Report:
    case StoreType.Requirement:
    case StoreType.BOM:
      // TODO: implement
      break;
    default:
      console.warn("Unknown parent type", parentType);
      parentName = "";
  }
  return { parentName, parentIcon };
};
