import Markdown from "react-markdown";
import { useNavigate, useParams } from "react-router-dom";
import { NonIdealState } from "@blueprintjs/core";
import Text, { TextVariant } from "@ui/Text";
import { observer } from "mobx-react";
import rehypeRaw from "rehype-raw";
import remarkGfm from "remark-gfm";

import { Button } from "@components/Button";
import ImageGallery from "@components/ImageGallery";
import appStore from "@store/AppStore";
import { IIntegration } from "@store/IntegrationStore";

import IntegrationOverviewLeftPane from "./IntegrationOverviewLeftPane";

import "./IntegrationOverview.scss";

const IntegrationOverview = () => {
  const { integrationId } = useParams();
  const navigate = useNavigate();
  const integration: IIntegration | undefined = appStore.settingsModel?.integrationsMap.get(integrationId || "");

  if (!integrationId || !integration || !integration.isActive) {
    return (
      <NonIdealState title="Integration not found" description="We can't find an integration with the specified ID" icon="warning-sign" />
    );
  }

  const renderCarousel = () => {
    if (!integration.headerImages?.length) {
      return null;
    }

    return <ImageGallery images={integration.headerImages} />;
  };

  const renderBuildBy = () => {
    if (integration.publisherLink) {
      return (
        <a href={integration.publisherLink} target="_blank" rel="noreferrer">
          {integration.publisher}
        </a>
      );
    }

    return <span>{integration.publisher}</span>;
  };

  return (
    <div className="settings-layout--content">
      <Button
        minimal
        className="integration-overview--back"
        e2eIdentifiers="integrations_back_btn"
        onClick={() => navigate("/settings/marketplace")}
        icon="arrow-left"
      >
        Back
      </Button>
      <div className="integration-overview">
        <IntegrationOverviewLeftPane integration={integration} />
        <div className="integration-overview--main">
          <div>
            <div className="integration-overview--header">
              <Text variant={TextVariant.H1}>{integration.name}</Text>
              <div>
                {integration.types?.map(type => (
                  <span key={type} className="integration-overview--type">
                    {type}
                  </span>
                ))}
              </div>
            </div>
            {integration.publisher && (
              <div className="integration-overview--publisher">
                <b>Built by: </b>
                {renderBuildBy()}
              </div>
            )}
          </div>
          {renderCarousel()}
          <div className="integration-overview--content">
            {/* we use `rehypeRaw` for underline text support */}
            {/* we use `remarkGfm` for crossed text support */}
            <Markdown rehypePlugins={[rehypeRaw]} remarkPlugins={[remarkGfm]}>
              {integration.shortSummary}
            </Markdown>
          </div>
        </div>
      </div>
    </div>
  );
};

export default observer(IntegrationOverview);
