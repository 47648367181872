import RequirementsFrame from "@components/Requirements/RequirementsFrame";
import Pane from "@router/components/Panes/Pane";

/** Main function. */
function RequirementIndex() {
  return (
    <Pane className="component--requirement-index">
      <RequirementsFrame />
    </Pane>
  );
}

/** Exports. */
export default RequirementIndex;
