import { PartNumberSchema, PartNumberSchemaCreateDto, PartNumberSchemaUpdateDto } from "@rollup-api/models";

import { HttpClient, ParentClient } from "./client";

export class PartNumberSchemas extends HttpClient {
  public constructor(parent: ParentClient) {
    super(parent);
  }

  private get endpoint() {
    return "part-number-schemas";
  }

  public create = (dto: PartNumberSchemaCreateDto) => {
    return this.instance.post<PartNumberSchema>(`${this.endpoint}`, dto);
  };

  public update = (id: string, dto: PartNumberSchemaUpdateDto) => {
    return this.instance.patch<PartNumberSchema>(`${this.endpoint}/${id}`, dto);
  };

  public delete = (id: string) => {
    return this.instance.delete<PartNumberSchema>(`${this.endpoint}/${id}`);
  };
}
