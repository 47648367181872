import { useEffect, useState } from "react";
import { HTMLTable, Icon, InputGroup, Menu } from "@blueprintjs/core";
import { observer } from "mobx-react";
import { useDebounceValue } from "usehooks-ts";

import { AnchorButton } from "@components/AnchorButton";
import { Button } from "@components/Button";
import { DeleteConfirmationDialog } from "@components/DeleteConfirmationDialog";
import { MenuItem } from "@components/MenuItem";
import { MenuItemDelete } from "@components/MenuItems";
import { Popover } from "@components/Popover";
import { Webhook } from "@rollup-api/models/webhook";
import { GetWebhooksRequestDto } from "@rollup-api/models/webhook/webhookRequestDto.model";
import { GetWebhooksResponseDto } from "@rollup-api/models/webhook/webhookResponseDto.model";
import { WebhookSortKeys } from "@rollup-api/models/webhook/WebhookSortKeys";
import { SortOrders } from "@rollup-api/SortOrders";
import { rollupClient } from "src/core/api";
import { Text, TextVariant } from "src/ui/Text";

import SettingsAddNewWebhook from "./SettingsAddNewWebhook";

import "./SettingsWebhooks.scss";

/** Main function. */
const SettingsWebhooks = () => {
  const [paginatedWebhooks, setPaginatedWebhooks] = useState<Webhook[]>();
  const [searchTerm, setSearchTerm] = useState("");
  const [debouncedSearchTerm] = useDebounceValue(searchTerm, 400);
  const [isNewWebhookScreenOpen, setIsNewWebhookScreenOpen] = useState(false);
  const [existingWebhookId, setExistingWebhookId] = useState<string | undefined>(undefined);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [itemToDelete, setItemToDelete] = useState<Webhook>();

  const fetchWebhooks = async (take: number, skip: number, sortedBy: WebhookSortKeys, sortOrder: SortOrders, sTerm?: string) => {
    const dto: GetWebhooksRequestDto = {
      take,
      skip,
      sortedBy,
      sortOrder,
      search: sTerm || undefined,
    };
    const webhooksResponse: GetWebhooksResponseDto | undefined = await rollupClient.webhooks.retrieveList(dto);
    if (webhooksResponse) {
      setPaginatedWebhooks(webhooksResponse.webhooks);
    }
  };

  useEffect(() => {
    if (!isNewWebhookScreenOpen) {
      fetchWebhooks(1000, 0, WebhookSortKeys.createdAt, SortOrders.DESC, searchTerm);
    }
  }, [isNewWebhookScreenOpen, searchTerm]);

  useEffect(() => {
    fetchWebhooks(1000, 0, WebhookSortKeys.createdAt, SortOrders.DESC, debouncedSearchTerm);
  }, [debouncedSearchTerm]);

  const renderWebhooksList = () => {
    return (
      <>
        <DeleteConfirmationDialog
          isOpen={isDeleteDialogOpen}
          titleItem="webhook"
          descriptionItem="this webhook"
          onCancel={() => setIsDeleteDialogOpen(false)}
          onClose={() => setIsDeleteDialogOpen(false)}
          onConfirm={async () => {
            if (itemToDelete) {
              await rollupClient.webhooks.delete(itemToDelete.id);
              fetchWebhooks(1000, 0, WebhookSortKeys.createdAt, SortOrders.DESC, searchTerm);
            }
            setIsDeleteDialogOpen(false);
          }}
        />
        <HTMLTable className="min-w-full">
          <thead>
            <tr>
              <th>
                <Text variant={TextVariant.H5}>Label</Text>
              </th>
              <th>
                <Text variant={TextVariant.H5}>URL</Text>
              </th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {!paginatedWebhooks?.length ? (
              <tr>
                <td colSpan={2} className="message">
                  <Text variant={TextVariant.Caption}>You don't have any webhooks meeting the filter criteria</Text>
                </td>
              </tr>
            ) : (
              paginatedWebhooks?.map((webHook: Webhook) => (
                <tr key={webHook.id}>
                  <td>
                    <Text variant={TextVariant.H3}>{webHook.label}</Text>
                  </td>
                  <td>
                    <Text variant={TextVariant.Body}>{webHook.url}</Text>
                  </td>
                  <td>
                    <Popover
                      placement="bottom-end"
                      content={
                        <Menu>
                          <MenuItem
                            text="Edit"
                            onClick={() => {
                              setExistingWebhookId(webHook.id);
                              setIsNewWebhookScreenOpen(true);
                            }}
                            e2eIdentifiers="edit"
                          />
                          <MenuItemDelete
                            onDelete={() => {
                              setItemToDelete(webHook);
                              setIsDeleteDialogOpen(true);
                            }}
                          />
                        </Menu>
                      }
                    >
                      <AnchorButton minimal rightIcon="more" e2eIdentifiers="show-menu" />
                    </Popover>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </HTMLTable>
      </>
    );
  };

  const renderWebhookSettings = () => {
    return (
      <div className="settings-layout--content">
        <div className="settings-layout--header">
          <Text variant={TextVariant.H1}>Webhooks</Text>
          <div className="settings-layout--header--right-section">
            <InputGroup
              className="settings-layout--search"
              large
              value={searchTerm}
              onChange={e => setSearchTerm(e.target.value)}
              placeholder="Search for webhook..."
              leftElement={<Icon icon="search" />}
            />
            <Button
              large
              intent="primary"
              icon="plus"
              className="settings-layout--cta"
              onClick={() => {
                setExistingWebhookId(undefined);
                setIsNewWebhookScreenOpen(true);
              }}
              e2eIdentifiers="add-new-webhook"
            >
              Add webhook
            </Button>
          </div>
        </div>
        <div className="settings-list-area">{renderWebhooksList()}</div>
      </div>
    );
  };

  const renderAddNewWebhook = () => {
    return (
      <SettingsAddNewWebhook
        webhookId={existingWebhookId}
        onClose={() => {
          setExistingWebhookId(undefined);
          setIsNewWebhookScreenOpen(false);
        }}
      />
    );
  };

  return <>{isNewWebhookScreenOpen ? renderAddNewWebhook() : renderWebhookSettings()}</>;
};

/** Exports. */
export default observer(SettingsWebhooks);
