import { useState } from "react";
import { Intent } from "@blueprintjs/core";
import omitBy from "lodash/omitBy";

import { Button } from "@components/Button";
import { CatalogItemCsvColumnMap } from "@rollup-api/models/catalogItem/catalogItemDtos";
import appStore from "@store/AppStore";

import FilePanel from "./Steps/File";
import HeadersPanel from "./Steps/Headers";
import { defaultColumnsMap } from "./constants";

import "./Csv.scss";

export enum CatalogItemCsvCreationStep {
  File = "file",
  Columns = "columns",
}

const Csv = () => {
  const [step, setStep] = useState<CatalogItemCsvCreationStep>(CatalogItemCsvCreationStep.File);
  const [file, setFile] = useState<File | null>(null);
  const [columnMap, setColumnMap] = useState<CatalogItemCsvColumnMap>(defaultColumnsMap);

  const renderStep = () => {
    switch (step) {
      case CatalogItemCsvCreationStep.File:
        return <FilePanel file={file} setFile={setFile} onStepChange={setStep} />;
      case CatalogItemCsvCreationStep.Columns:
        return <HeadersPanel file={file} onChange={setColumnMap} columnsMap={columnMap} />;
      default:
        return null;
    }
  };

  const handleBackClick = () => {
    if (step === CatalogItemCsvCreationStep.Columns) {
      setStep(CatalogItemCsvCreationStep.File);
    } else {
      appStore.ui.hideCatalogItemCreationModal();
    }
  };

  const handleNextClick = async () => {
    if (step === CatalogItemCsvCreationStep.File) {
      setStep(CatalogItemCsvCreationStep.Columns);
    } else {
      if (file) {
        const dataTransfer = new DataTransfer();
        dataTransfer.items.add(file);
        appStore.orgModel.uploads.addNewFileUpload({
          files: dataTransfer.files,
          onUpload: attachmentId =>
            appStore.orgModel.catalogItems.createCsvCatalogItem(attachmentId, file, JSON.stringify(omitBy(columnMap, v => !v))),
        });
        appStore.ui.hideCatalogItemCreationModal();
      }
    }
  };

  const getButtonDisabled = () => {
    if (step === CatalogItemCsvCreationStep.File) {
      return !file;
    }

    return false;
  };

  return (
    <div className="csv-create">
      <div className="csv-create--content">{renderStep()}</div>
      <div className="csv-create--footer">
        <Button onClick={handleBackClick} large minimal e2eIdentifiers="cancel-catalog-item-creation">
          {step === CatalogItemCsvCreationStep.File ? "Cancel" : "Back"}
        </Button>
        <Button
          disabled={getButtonDisabled()}
          large
          style={{ width: 80 }}
          intent={Intent.PRIMARY}
          onClick={handleNextClick}
          e2eIdentifiers="csv-submit-catalog-item"
        >
          {step === CatalogItemCsvCreationStep.File ? "Next" : "Submit"}
        </Button>
      </div>
    </div>
  );
};

export default Csv;
