import { CreateCatalogItemReferenceDto } from "@rollup-api/models/catalogItem/catalogItemDtos";
import { CatalogItemReference } from "@rollup-api/models/catalogItem/catalogItemReference.model";

import { HttpClient, ParentClient } from "./client";

export class CatalogItemReferences extends HttpClient {
  public constructor(parent: ParentClient) {
    super(parent);
  }

  private readonly endpoint = "/catalog-item-reference";

  public create = async (createCatalogItemReferenceDto: CreateCatalogItemReferenceDto): Promise<CatalogItemReference | undefined> => {
    try {
      const res = await this.instance.post<CatalogItemReference>(this.endpoint, createCatalogItemReferenceDto);
      return res.data;
    } catch (err) {
      console.error(err);
      return;
    }
  };

  public remove = async (id: string): Promise<CatalogItemReference | undefined> => {
    try {
      const res = await this.instance.delete<CatalogItemReference>(`${this.endpoint}/${id}`);
      return res.data;
    } catch (err) {
      console.error(err);
      return;
    }
  };

  public bulkRemove = async (ids: string[]): Promise<CatalogItemReference[] | undefined> => {
    try {
      const res = await this.instance.post<CatalogItemReference[]>(`${this.endpoint}/bulk-remove`, { ids });
      return res.data;
    } catch (err) {
      console.error(err);
      return;
    }
  };
}
