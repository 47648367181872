import { ChangeEvent, useEffect, useRef, useState } from "react";
import { Classes, InputGroup, Menu, MenuDivider } from "@blueprintjs/core";
import { observer } from "mobx-react";

import { MenuItem } from "@components/MenuItem";
import { MenuItemDelete } from "@components/MenuItems";
import appStore from "@store/AppStore";
import { ITableViewConfig } from "@store/TableViewConfigStore";

import "./TabNav.scss";

type Props = {
  tab: ITableViewConfig;
  isMain?: boolean;
};

export const TabNav = (props: Props) => {
  const [nameInputValue, setNameInputValue] = useState<string>("");
  const inputRef = useRef<HTMLInputElement>(null);
  const { isMain, tab } = props;

  useEffect(() => setNameInputValue(tab.label || ""), [tab]);

  const handleDelete = (item: ITableViewConfig) => {
    appStore.workspaceModel?.deleteTableViewConfig(item);
  };

  const handleDuplicate = (item: ITableViewConfig) => {
    const config = item.config || appStore.env.tableView.ui.commonTableColumnsConfig;
    appStore.workspaceModel?.addNewTableViewConfig(config, true, `${item.label} (copy)`);
  };

  const handleInputFocus = (event: ChangeEvent<HTMLInputElement>) => event.target.select();

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => setNameInputValue(event.target.value);

  const updateLabel = () => {
    const trimmedValue = nameInputValue.trim();

    if (trimmedValue) {
      tab.setLabel(trimmedValue);
    } else {
      setNameInputValue(tab.label);
    }
  };

  const handleNameInputKeyUp = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      updateLabel();
      const overlay = document.querySelector(`.${Classes.OVERLAY_BACKDROP}`);
      overlay?.dispatchEvent(new MouseEvent("mousedown", { bubbles: true }));
    }
  };

  return (
    <Menu className="tab-nav">
      {!isMain && (
        <>
          <p className="header-nav--label">Name</p>
          <InputGroup
            className="header-nav--input"
            onKeyUp={handleNameInputKeyUp}
            inputRef={inputRef}
            onBlur={updateLabel}
            value={nameInputValue}
            autoFocus
            onFocus={handleInputFocus}
            onChange={handleInputChange}
          />
          <MenuDivider />
        </>
      )}
      <MenuItem text="Duplicate" icon="duplicate" onClick={() => handleDuplicate(tab)} e2eIdentifiers="duplicate" />
      {!isMain && <MenuItemDelete onDelete={() => handleDelete(tab)} />}
    </Menu>
  );
};

export default observer(TabNav);
