import { useMemo } from "react";
import { ColDef, ColumnMovedEvent, ICellRendererParams, RowDragEndEvent } from "ag-grid-community";
import { observer } from "mobx-react";
import { isAlive } from "mobx-state-tree";

import { FormattedTable } from "@components/Modeling/FormattedTable";
import TextCell from "@components/Modeling/PropertyDefinition/Cells/TextCell";
import appStore from "@store/AppStore";
import { IStatusDefinition } from "@store/StatusDefinitionStore";

import ActionsCell from "./Cells/ActionsCell";
import OptionsCell from "./Cells/OptionsCell";
import StatusEditorCell from "./Cells/StatusEditorCell";
import TypeCell from "./Cells/TypeCell";
import { EStatusesColumn } from "./constants";

import "./StatusesTable.scss";

type TCellRendererProps = ICellRendererParams<IStatusDefinition>;

type TEditCellProps = {
  newValue: string;
};

type TCellGetterProps = TEditCellProps & TCellRendererProps;

const cellRenderer = (column: EStatusesColumn, cellProps: ICellRendererParams<IStatusDefinition>) => {
  const { data, api, registerRowDragger } = cellProps;
  const isBlockAlive = data && isAlive(data);

  if (!isBlockAlive) {
    return null;
  }

  switch (column) {
    case EStatusesColumn.ACTIONS:
      return <ActionsCell registerRowDragger={registerRowDragger} gridApi={api} status={data} />;
    case EStatusesColumn.DESCRIPTION:
      return <TextCell label={data.description} e2eIdentifiers={["description", data?.label]} />;
    case EStatusesColumn.LABEL:
      return <TextCell label={data.label} e2eIdentifiers={["label", data?.label]} />;
    case EStatusesColumn.OPTIONS:
      return <OptionsCell statusDefinition={data} />;
    case EStatusesColumn.TYPE:
      return <TypeCell status={data} />;
  }
};

const getValidationErrorMessage = (label: string, definitions: IStatusDefinition[]) => {
  const existingValues = definitions.map((i: IStatusDefinition) => i.label);
  if (!label) {
    return "Cannot add a status with an empty name";
  } else if (existingValues.some((existingLabel: string) => label.toLowerCase() === existingLabel.toLowerCase())) {
    return "A status with this name already exists";
  }
  return "";
};

const StatusesTable = () => {
  const projectStatuses = appStore.workspaceModel?.modelStatusDefinitions ?? [];

  const handleAddNewRow = (label: string) => {
    appStore.workspaceModel?.addNewStatusDefinition(label);
  };

  const handleColumnMoved = (e: ColumnMovedEvent<IStatusDefinition>) => {
    const columnsOrder = e.api.getAllGridColumns().map(c => c.getColId()) as EStatusesColumn[];
    appStore.env.columnsOrder.updateProjectStatusesTableColumnsOrder(columnsOrder);
  };

  const columnsOrder = appStore.env.columnsOrder.projectStatusesTable;
  const columnDefs = useMemo(
    () =>
      (
        [
          {
            colId: EStatusesColumn.ACTIONS,
            headerName: "",
            lockPosition: "left",
            cellClass: "actions-cell",
            suppressMovable: true,
            width: 25,
            cellRenderer: (cellProps: TCellRendererProps) => cellRenderer(EStatusesColumn.ACTIONS, cellProps),
          },
          {
            editable: true,
            colId: EStatusesColumn.LABEL,
            minWidth: 100,
            flex: 3,
            headerName: EStatusesColumn.LABEL,
            cellRenderer: observer((cellProps: TCellRendererProps) => cellRenderer(EStatusesColumn.LABEL, cellProps)),
            cellEditor: StatusEditorCell,
            valueGetter: (cellProps: TCellRendererProps) => cellProps.data?.label || "",
            valueSetter: (cellProps: TCellGetterProps): boolean => {
              cellProps.data?.setLabel(cellProps.newValue);
              return true;
            },
          },
          {
            colId: EStatusesColumn.TYPE,
            width: 50,
            cellClass: "type-cell-wrapper",
            headerName: EStatusesColumn.TYPE,
            cellRenderer: observer((cellProps: TCellRendererProps) => cellRenderer(EStatusesColumn.TYPE, cellProps)),
          },
          {
            editable: true,
            colId: EStatusesColumn.DESCRIPTION,
            minWidth: 150,
            flex: 3,
            headerName: EStatusesColumn.DESCRIPTION,
            cellRenderer: observer((cellProps: TCellRendererProps) => cellRenderer(EStatusesColumn.DESCRIPTION, cellProps)),
            cellEditor: StatusEditorCell,
            valueGetter: (cellProps: TCellRendererProps) => cellProps.data?.description || "",
            valueSetter: (cellProps: TCellGetterProps): boolean => {
              cellProps.data?.setDescription(cellProps.newValue);
              return true;
            },
          },
          {
            colId: EStatusesColumn.OPTIONS,
            minWidth: 200,
            flex: 4,
            cellClass: "ag-cell-options",
            headerName: EStatusesColumn.OPTIONS,
            cellRenderer: observer((cellProps: TCellRendererProps) => cellRenderer(EStatusesColumn.OPTIONS, cellProps)),
          },
        ] as ColDef<IStatusDefinition>[]
      ).sort(
        (a: ColDef<IStatusDefinition>, b: ColDef<IStatusDefinition>) =>
          columnsOrder.indexOf(a.colId as EStatusesColumn) - columnsOrder.indexOf(b.colId as EStatusesColumn)
      ),
    [columnsOrder]
  );

  const onRowDragEnd = (e: RowDragEndEvent<IStatusDefinition>) => {
    const targetIndex = e.overIndex;
    const nodeId = e.node.data?.id;
    const targetId = projectStatuses[targetIndex]?.id;

    if (nodeId && targetId) {
      appStore.workspaceModel?.moveStatusDefinition(nodeId, targetId);
    }
  };

  return (
    <FormattedTable<IStatusDefinition>
      className="statuses-table"
      title="Statuses"
      description="These are the project statuses for this workspace."
      addNewLabel="Add new status"
      defaultDragText="Status row"
      onRowDragEnd={onRowDragEnd}
      columnDefs={columnDefs}
      rowData={projectStatuses}
      onColumnMoved={handleColumnMoved}
      getValidationErrorMessage={(label: string) => getValidationErrorMessage(label, projectStatuses)}
      onAddNewRow={handleAddNewRow}
    />
  );
};

export default observer(StatusesTable);
