import { JSX } from "react";
import { PopoverPosition } from "@blueprintjs/core";
import Link from "@tiptap/extension-link";
import { useEditor } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import { TextVariant } from "@ui/Text";
import { observer } from "mobx-react";

import { EditorContent } from "@components/EditorContent";
import { RelativeDateWithTooltip } from "@components/RelativeDateWithTooltip";
import { getMentionExtensions } from "@components/Reports/Editor/Extentions/Mention/MentionSuggestion";
import UserInfo from "@components/UserInfo/UserInfo";
import appStore from "@store/AppStore";
import { IUser } from "@store/UserStore";

import "./FeedItem.scss";

interface IFeedItemProps {
  actionText: string | JSX.Element;
  createdAt: number;
  user: IUser;
  editorContent?: string;
}

const FeedItem = (props: IFeedItemProps) => {
  const { actionText, user, createdAt, editorContent } = props;
  const workspace = appStore.workspaceModel ?? undefined;

  const editor = useEditor({
    extensions: [StarterKit, Link, ...getMentionExtensions({ workspace })],
    content: editorContent,
    editable: false,
  });

  return (
    <div className="feed-item">
      <div className="feed-item--user-container">
        <UserInfo user={user} size="extra-small" avatarOnly />
        <div className="feed-item--divider" />
      </div>
      <div>
        <span className="feed-item--content-container">
          <span className="feed-item--author">{user.id === appStore.userModel?.id ? "You" : user.displayName}</span>
          &nbsp;
          {actionText}
          &nbsp;
          <RelativeDateWithTooltip epochTime={createdAt} popoverPosition={PopoverPosition.TOP} variant={TextVariant.BodyDimmed} />
        </span>
        <EditorContent e2eIdentifiers="feed-item-editor" editor={editor} />
      </div>
    </div>
  );
};

export default observer(FeedItem);
