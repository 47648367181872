import { Plugin, PluginKey } from "@tiptap/pm/state";
import { ResolvedPos } from "prosemirror-model";

import { HORIZONTAL_BUTTON_WIDTH, VERTICAL_BUTTON_HEIGHT } from "./constants";
import { TIndexSetter } from "./types";

const tableButtonPluginKey = new PluginKey("tableButtonPluginKey");

const getChildIndex = (node: HTMLElement | ParentNode): number => {
  return Array.prototype.indexOf.call(node.parentNode?.childNodes, node);
};

const domCellAround = (target: HTMLElement | null): HTMLElement | null => {
  while (target && target.nodeName != "TD" && target.nodeName != "TH")
    target = target.classList && target.classList.contains("ProseMirror") ? null : (target.parentNode as HTMLElement);
  return target;
};

const cellAround = (position: ResolvedPos): ResolvedPos | null => {
  for (let d = position.depth - 1; d > 0; d--) {
    if (position.node(d).type.spec.tableRole == "row") {
      return position.node(0).resolve(position.before(d + 1));
    }
  }
  return null;
};

const tableButtonPlugin = (indexSetter: TIndexSetter): Plugin => {
  return new Plugin({
    key: tableButtonPluginKey,
    props: {
      handleDOMEvents: {
        mousemove: (view, b: MouseEvent) => {
          const cell = domCellAround(b.target as HTMLElement);
          if (cell) {
            const found = view.posAtCoords({ left: b.clientX, top: b.clientY });
            const cellPosition = found ? found.pos : -1;
            const cellIndex = getChildIndex(cell);
            const cellResolvedPosition = view.state.tr.doc.resolve(cellPosition);
            const aroundPos = cellAround(cellResolvedPosition);
            const rowIndex = cell.parentNode ? getChildIndex(cell.parentNode) : 0;
            const childCells = Array.from(cell.parentNode?.childNodes || []).slice(0, cellIndex) as HTMLElement[];
            const childRows = Array.from(cell.parentNode?.parentNode?.childNodes || []).slice(0, rowIndex) as HTMLElement[];
            const cellsWidth = childCells.reduce((start: number, n: HTMLElement) => start + n.offsetWidth, 0);
            const rowsHeight = childRows.reduce((start: number, n: HTMLElement) => start + n.offsetHeight, 0);
            const tableScroll = cell.closest(".report-editor--table")?.scrollLeft || 0;
            const verticalPos = rowsHeight + (cell.offsetHeight - VERTICAL_BUTTON_HEIGHT) / 2;
            const horizontalPos = cellsWidth - tableScroll + (cell.offsetWidth - HORIZONTAL_BUTTON_WIDTH) / 2;
            if (aroundPos) {
              indexSetter(verticalPos, horizontalPos, aroundPos);
            }
          }
        },
      },
    },
  });
};

export default tableButtonPlugin;
