import { GridApi } from "ag-grid-community";

import { ICatalogItemReference } from "@store/CatalogItem/CatalogItemReferenceStore";
import { ICatalogItem } from "@store/CatalogItem/CatalogItemStore";

export type CatalogItemNode = {
  catalogItem: ICatalogItem;
  path: string[];
  reference?: ICatalogItemReference;
};

export type CatalogItemTableGridApi = GridApi<CatalogItemNode>;

export enum CatalogItemTableColumn {
  Checkbox = "checkbox",
  Actions = "actions",
  Id = "id",
  Cost = "cost",
  CostCurrency = "costCurrency",
  PartNumber = "partNumber",
  Thumbnail = "thumbnail",
  AutoGroupColumn = "ag-Grid-AutoColumn",
  PartType = "type",
  Name = "name",
  Index = "index",
  Status = "status",
  Description = "description",
  Material = "material",
  Weight = "weight",
  WeightUnit = "weightUnit",
  Supplier = "supplier",
  Parent = "parentItemId",
  Quantity = "quantity",
  LeadTime = "leadTime",
  LeadTimePeriod = "leadTimePeriod",
  Comments = "comments",
  Source = "source",
  ParentRefs = "parentRefs",
  ChildRefs = "childRefs",
}

export const versionCellColumns = [
  CatalogItemTableColumn.Supplier,
  CatalogItemTableColumn.Quantity,
  CatalogItemTableColumn.Weight,
  CatalogItemTableColumn.WeightUnit,
  CatalogItemTableColumn.Cost,
  CatalogItemTableColumn.CostCurrency,
  CatalogItemTableColumn.Material,
  CatalogItemTableColumn.LeadTime,
  CatalogItemTableColumn.LeadTimePeriod,
];

export type CatalogItemEditableVersionColumn =
  | CatalogItemTableColumn.Supplier
  | CatalogItemTableColumn.Quantity
  | CatalogItemTableColumn.Weight
  | CatalogItemTableColumn.WeightUnit
  | CatalogItemTableColumn.Cost
  | CatalogItemTableColumn.CostCurrency
  | CatalogItemTableColumn.Material;

export type CatalogItemCsvColumn = Exclude<
  CatalogItemTableColumn,
  | CatalogItemTableColumn.Checkbox
  | CatalogItemTableColumn.Actions
  | CatalogItemTableColumn.Thumbnail
  | CatalogItemTableColumn.AutoGroupColumn
  | CatalogItemTableColumn.Comments
  | CatalogItemTableColumn.Source
  | CatalogItemTableColumn.WeightUnit
  | CatalogItemTableColumn.CostCurrency
  | CatalogItemTableColumn.LeadTimePeriod
  | CatalogItemTableColumn.ChildRefs
  | CatalogItemTableColumn.ParentRefs
>;
