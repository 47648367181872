import * as React from "react";
const SvgGoogleSheets = (props) => /* @__PURE__ */ React.createElement("svg", { width: 16, height: 22, viewBox: "0 0 16 22", fill: "none", xmlns: "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("mask", { id: "mask0_5775_11520", style: {
  maskType: "alpha"
}, maskUnits: "userSpaceOnUse", x: 0, y: 0, width: 16, height: 22 }, /* @__PURE__ */ React.createElement("path", { d: "M9.5996 0.314453H1.48476C0.697147 0.314453 0.0527344 0.970143 0.0527344 1.77154V20.228C0.0527344 21.0294 0.697147 21.6851 1.48476 21.6851H13.8957C14.6833 21.6851 15.3277 21.0294 15.3277 20.228V6.14281L9.5996 0.314453Z", fill: "white" })), /* @__PURE__ */ React.createElement("g", { mask: "url(#mask0_5775_11520)" }, /* @__PURE__ */ React.createElement("path", { d: "M9.5996 0.314453H1.48476C0.697147 0.314453 0.0527344 0.970143 0.0527344 1.77154V20.228C0.0527344 21.0294 0.697147 21.6851 1.48476 21.6851H13.8957C14.6833 21.6851 15.3277 21.0294 15.3277 20.228V6.14281L11.9863 3.71432L9.5996 0.314453Z", fill: "#0F9D58" })), /* @__PURE__ */ React.createElement("mask", { id: "mask1_5775_11520", style: {
  maskType: "alpha"
}, maskUnits: "userSpaceOnUse", x: 0, y: 0, width: 16, height: 22 }, /* @__PURE__ */ React.createElement("path", { d: "M9.5996 0.314453H1.48476C0.697147 0.314453 0.0527344 0.970143 0.0527344 1.77154V20.228C0.0527344 21.0294 0.697147 21.6851 1.48476 21.6851H13.8957C14.6833 21.6851 15.3277 21.0294 15.3277 20.228V6.14281L9.5996 0.314453Z", fill: "white" })), /* @__PURE__ */ React.createElement("g", { mask: "url(#mask1_5775_11520)" }, /* @__PURE__ */ React.createElement("path", { d: "M3.87109 10.7573V17.7999H11.5086V10.7573H3.87109ZM7.2125 16.8285H4.8258V15.6143H7.2125V16.8285ZM7.2125 14.8857H4.8258V13.6715H7.2125V14.8857ZM7.2125 12.943H4.8258V11.7287H7.2125V12.943ZM10.5539 16.8285H8.16718V15.6143H10.5539V16.8285ZM10.5539 14.8857H8.16718V13.6715H10.5539V14.8857ZM10.5539 12.943H8.16718V11.7287H10.5539V12.943Z", fill: "#F1F1F1" })), /* @__PURE__ */ React.createElement("mask", { id: "mask2_5775_11520", style: {
  maskType: "alpha"
}, maskUnits: "userSpaceOnUse", x: 0, y: 0, width: 16, height: 22 }, /* @__PURE__ */ React.createElement("path", { d: "M9.5996 0.314453H1.48476C0.697147 0.314453 0.0527344 0.970143 0.0527344 1.77154V20.228C0.0527344 21.0294 0.697147 21.6851 1.48476 21.6851H13.8957C14.6833 21.6851 15.3277 21.0294 15.3277 20.228V6.14281L9.5996 0.314453Z", fill: "white" })), /* @__PURE__ */ React.createElement("g", { mask: "url(#mask2_5775_11520)" }, /* @__PURE__ */ React.createElement("path", { d: "M10.0186 5.7168L15.3278 11.1178V6.143L10.0186 5.7168Z", fill: "url(#paint0_linear_5775_11520)" })), /* @__PURE__ */ React.createElement("mask", { id: "mask3_5775_11520", style: {
  maskType: "alpha"
}, maskUnits: "userSpaceOnUse", x: 0, y: 0, width: 16, height: 22 }, /* @__PURE__ */ React.createElement("path", { d: "M9.5996 0.314453H1.48476C0.697147 0.314453 0.0527344 0.970143 0.0527344 1.77154V20.228C0.0527344 21.0294 0.697147 21.6851 1.48476 21.6851H13.8957C14.6833 21.6851 15.3277 21.0294 15.3277 20.228V6.14281L9.5996 0.314453Z", fill: "white" })), /* @__PURE__ */ React.createElement("g", { mask: "url(#mask3_5775_11520)" }, /* @__PURE__ */ React.createElement("path", { d: "M9.59961 0.314453V4.68573C9.59961 5.49075 10.2404 6.14281 11.0316 6.14281H15.3277L9.59961 0.314453Z", fill: "#87CEAC" })), /* @__PURE__ */ React.createElement("mask", { id: "mask4_5775_11520", style: {
  maskType: "alpha"
}, maskUnits: "userSpaceOnUse", x: 0, y: 0, width: 16, height: 22 }, /* @__PURE__ */ React.createElement("path", { d: "M9.5996 0.314453H1.48476C0.697147 0.314453 0.0527344 0.970143 0.0527344 1.77154V20.228C0.0527344 21.0294 0.697147 21.6851 1.48476 21.6851H13.8957C14.6833 21.6851 15.3277 21.0294 15.3277 20.228V6.14281L9.5996 0.314453Z", fill: "white" })), /* @__PURE__ */ React.createElement("g", { mask: "url(#mask4_5775_11520)" }, /* @__PURE__ */ React.createElement("path", { d: "M1.48476 0.314453C0.697147 0.314453 0.0527344 0.970143 0.0527344 1.77154V1.89297C0.0527344 1.09157 0.697147 0.435878 1.48476 0.435878H9.5996V0.314453H1.48476Z", fill: "white", fillOpacity: 0.2 })), /* @__PURE__ */ React.createElement("mask", { id: "mask5_5775_11520", style: {
  maskType: "alpha"
}, maskUnits: "userSpaceOnUse", x: 0, y: 0, width: 16, height: 22 }, /* @__PURE__ */ React.createElement("path", { d: "M9.5996 0.314453H1.48476C0.697147 0.314453 0.0527344 0.970143 0.0527344 1.77154V20.228C0.0527344 21.0294 0.697147 21.6851 1.48476 21.6851H13.8957C14.6833 21.6851 15.3277 21.0294 15.3277 20.228V6.14281L9.5996 0.314453Z", fill: "white" })), /* @__PURE__ */ React.createElement("g", { mask: "url(#mask5_5775_11520)" }, /* @__PURE__ */ React.createElement("path", { d: "M14.1115 21.564H1.70059C0.912968 21.564 0.268555 20.9083 0.268555 20.1069V20.2284C0.268555 21.0298 0.912968 21.6855 1.70059 21.6855H14.1115C14.8991 21.6855 15.5435 21.0298 15.5435 20.2284V20.1069C15.5435 20.9083 14.8991 21.564 14.1115 21.564Z", fill: "#263238", fillOpacity: 0.2 })), /* @__PURE__ */ React.createElement("mask", { id: "mask6_5775_11520", style: {
  maskType: "alpha"
}, maskUnits: "userSpaceOnUse", x: 0, y: 0, width: 16, height: 22 }, /* @__PURE__ */ React.createElement("path", { d: "M9.5996 0.314453H1.48476C0.697147 0.314453 0.0527344 0.970143 0.0527344 1.77154V20.228C0.0527344 21.0294 0.697147 21.6851 1.48476 21.6851H13.8957C14.6833 21.6851 15.3277 21.0294 15.3277 20.228V6.14281L9.5996 0.314453Z", fill: "white" })), /* @__PURE__ */ React.createElement("g", { mask: "url(#mask6_5775_11520)" }, /* @__PURE__ */ React.createElement("path", { d: "M11.0316 6.14312C10.2404 6.14312 9.59961 5.49106 9.59961 4.68604V4.80745C9.59961 5.6125 10.2404 6.26453 11.0316 6.26453H15.3277V6.14312H11.0316Z", fill: "#263238", fillOpacity: 0.1 }), /* @__PURE__ */ React.createElement("path", { d: "M9.5996 0.314453H1.48476C0.697147 0.314453 0.0527344 0.970143 0.0527344 1.77154V20.228C0.0527344 21.0294 0.697147 21.6851 1.48476 21.6851H13.8957C14.6833 21.6851 15.3277 21.0294 15.3277 20.228V6.14281L9.5996 0.314453Z", fill: "url(#paint1_radial_5775_11520)" })), /* @__PURE__ */ React.createElement("defs", null, /* @__PURE__ */ React.createElement("linearGradient", { id: "paint0_linear_5775_11520", x1: 12.6735, y1: 6.18053, x2: 12.6735, y2: 11.1185, gradientUnits: "userSpaceOnUse" }, /* @__PURE__ */ React.createElement("stop", { stopColor: "#263238", stopOpacity: 0.2 }), /* @__PURE__ */ React.createElement("stop", { offset: 1, stopColor: "#263238", stopOpacity: 0.02 })), /* @__PURE__ */ React.createElement("radialGradient", { id: "paint1_radial_5775_11520", cx: 0, cy: 0, r: 1, gradientUnits: "userSpaceOnUse", gradientTransform: "translate(0.536654 0.739239) scale(24.6307 25.0617)" }, /* @__PURE__ */ React.createElement("stop", { stopColor: "white", stopOpacity: 0.1 }), /* @__PURE__ */ React.createElement("stop", { offset: 1, stopColor: "white", stopOpacity: 0 }))));
export default SvgGoogleSheets;
