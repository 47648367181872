import { AttachmentType } from "@rollup-api/models/cloudStorage";
import { Text, TextVariant } from "src/ui/Text";

import "./AttachmentInfo.scss";

type AttachmentInfoProps = {
  size: string;
  date: string;
  type: AttachmentType;
};

export const AttachmentInfo = ({ size, date, type }: AttachmentInfoProps) => {
  return (
    <div className="attachment-actions--info">
      {type === "file" && <Text variant={TextVariant.Caption}>{size}</Text>}
      <Text variant={TextVariant.Caption}>{date}</Text>
    </div>
  );
};
