import { KeyboardEvent, useCallback, useEffect, useState } from "react";
import { DialogBody, FormGroup, InputGroup, Menu, Tooltip } from "@blueprintjs/core";
import { BlueprintIcon } from "@ui/BlueprintIcon";
import capitalize from "lodash/capitalize";
import isEqual from "lodash/isEqual";
import { observer } from "mobx-react";

import { Button } from "@components/Button";
import { DialogLegacy } from "@components/Dialog";
import { DialogFooter } from "@components/DialogFooter";
import { MenuItem } from "@components/MenuItem";
import { Popover } from "@components/Popover";
import { UserRole } from "@rollup-api/api/authTypes";
import appStore from "@store/AppStore";
import { emailListSeparatorRegex, isValidDomain, isValidEmailList } from "@utilities/Validation";
import { Text, TextVariant } from "src/ui/Text";

import { Keys } from "../../constants/keys";
import { rollupClient } from "../../core/api";
import { showApiErrorToast, showToast } from "../UiLayers/toaster";

import CompanyDomainsMultiSelect from "./CompanyDomainsMultiSelect";

import styles from "./InviteNewUserDialog.module.scss";

const COMPANY_DOMAINS_INPUT_ID = "company-domains";
const INVITE_BY_EMAIL_INPUT_ID = "invite-by-email";

const InviteNewUserDialog = () => {
  const [emails, setEmails] = useState<string>("");
  const [role, setRole] = useState<UserRole>(UserRole.User);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  // Needed to use a state to store the element reference because as the component gets mounted we want it to trigger
  // the useEffect that sets the focus to the text area
  const [textAreaEl, setTextAreaEl] = useState<HTMLInputElement>();
  const { info } = appStore.orgModel;
  const [selectedDomains, setSelectedDomains] = useState<string[]>(info.allowedDomains);
  const [allowedDomains, setAllowedDomains] = useState<string[]>(info.allowedDomains);

  const handleRef = (node: HTMLInputElement) => {
    setTextAreaEl(node);
  };

  const focusOnTextArea = useCallback(() => {
    textAreaEl?.focus();
  }, [textAreaEl]);

  useEffect(() => {
    focusOnTextArea();
  }, [focusOnTextArea]);

  const handleClose = () => {
    appStore.inviteNewUser.hide();
  };

  const handleKeyDown = (event: KeyboardEvent) => {
    if (!isSubmitting && event.key === "Enter" && (event.ctrlKey || event.metaKey)) {
      void handleEmailSubmit();
    }
  };

  const handleEmailSubmit = async () => {
    if (isValidEmailList(emails)) {
      if (!isEqual(selectedDomains, allowedDomains)) {
        appStore.pushOrgChanges({ allowedDomains: selectedDomains });
      }

      setIsSubmitting(true);
      const sentSuccessful = await sendInvite();
      setIsSubmitting(false);
      if (sentSuccessful) {
        handleClose();
      }
    }
  };

  const handleCreateNewDomain = (domain: string) => {
    if (isValidDomain(domain)) {
      setAllowedDomains(s => s.concat(domain));
      return true;
    }
    return false;
  };

  const sendInvite = async () => {
    const emailList = emails
      .split(new RegExp(emailListSeparatorRegex))
      .map(e => e.trim())
      .filter(Boolean);
    try {
      const res = await rollupClient.invitations.inviteUsers({ emails: emailList, role });
      if (res.status === 201 || res.status === 200) {
        showToast("Users invited successfully", "success", "info-sign");
        setEmails("");
      }
      return true;
    } catch (err) {
      showApiErrorToast("Error inviting users", err as Error);
      return false;
    }
  };

  const roleMenu = (
    <Popover
      content={
        <Menu>
          <MenuItem text="User" onClick={() => setRole(UserRole.User)} e2eIdentifiers="user" />
          <MenuItem text="Admin" onClick={() => setRole(UserRole.Admin)} e2eIdentifiers="admin" />
        </Menu>
      }
      placement="bottom-end"
    >
      <Button minimal rightIcon="chevron-down" e2eIdentifiers="open-menu">
        {capitalize(role.replace("rollup_", ""))}
      </Button>
    </Popover>
  );

  const renderDomains = () => {
    return selectedDomains.map((domain, index) => {
      const isFirstElement = index === 0;
      const isLastElement = index === allowedDomains.length - 1;
      if (isFirstElement) {
        return (
          <span className={styles.domain} key={domain}>
            {domain}
          </span>
        );
      } else if (isLastElement) {
        return (
          <span key={domain}>
            <span> or </span>
            <span className={styles.domain}>{domain}</span>
          </span>
        );
      } else {
        return (
          <span key={domain}>
            , <span className={styles.domain}>{domain}</span>
          </span>
        );
      }
    });
  };

  return (
    <div>
      <DialogLegacy className={styles.inviteNewUserDialogWrap} isOpen onClose={handleClose} title="Invite new users">
        <DialogBody>
          {!!allowedDomains?.length && (
            <div className={styles.inviteNewUserDialogReminder}>
              <div className={styles.inviteNewUserDialogReminderHeader}>
                <BlueprintIcon icon="info-sign" />
                <Text variant={TextVariant.H4}>Reminder</Text>
              </div>
              <ul className={styles.inviteNewUserDialogReminderList}>
                <li>
                  Anyone with a {renderDomains()} domain can register directly by visiting the following link:&nbsp;
                  <a className={styles.domain} href={"https://" + window.location.host.split(".")[0] + ".rollup.ai"}>
                    {window.location.host.split(".")[0]}.rollup.ai
                  </a>
                </li>
                <li>Allowing users to join your organization by domain will automatically increase your number of billing seats</li>
              </ul>
              <div>To disable this, reach out to support.</div>
            </div>
          )}
          {!!allowedDomains?.length && (
            <FormGroup className={styles.formGroup} label="Company domains allowed" labelFor={COMPANY_DOMAINS_INPUT_ID}>
              <CompanyDomainsMultiSelect
                selectedDomains={selectedDomains}
                allowedDomains={allowedDomains}
                inputId={COMPANY_DOMAINS_INPUT_ID}
                setSelectedDomains={setSelectedDomains}
                onCreateNewDomain={handleCreateNewDomain}
              />
            </FormGroup>
          )}
          <FormGroup
            className={styles.formGroup}
            label="Invite by email"
            labelFor={INVITE_BY_EMAIL_INPUT_ID}
            helperText="Comma or space separated"
          >
            <InputGroup
              inputRef={handleRef}
              className={styles.inviteNewUserDialogInputGroup}
              id={INVITE_BY_EMAIL_INPUT_ID}
              placeholder="john@space.co jane@space.co"
              large
              onChange={e => setEmails(e.target.value)}
              value={emails}
              onKeyDown={handleKeyDown}
              rightElement={roleMenu}
              fill
            />
          </FormGroup>
          <DialogFooter className={styles.inviteNewUserDialogFooter}>
            <div className={styles.inviteNewUserDialogFooterContainer}>
              <Button
                className={styles.inviteNewUserDialogButton}
                onClick={handleClose}
                e2eIdentifiers="cancel-invite-users"
                minimal
                large
                outlined
              >
                Cancel
              </Button>
              <Tooltip content={`${Keys.mod} + Enter`}>
                <Button
                  className={styles.inviteNewUserDialogButton}
                  onClick={handleEmailSubmit}
                  large
                  intent="primary"
                  disabled={!isValidEmailList(emails) || isSubmitting}
                  loading={isSubmitting}
                  e2eIdentifiers="submit-invite-users"
                >
                  Invite
                </Button>
              </Tooltip>
            </div>
          </DialogFooter>
        </DialogBody>
      </DialogLegacy>
    </div>
  );
};

export default observer(InviteNewUserDialog);
