import { NonIdealState } from "@blueprintjs/core";
import { observer } from "mobx-react";

import BlockPropertyItem from "@components/BomTables/Table/Components/BlockPreview/BlockPropertyItem";
import { IBlock } from "@store/BlockStore";
import { isFieldDefined } from "@utilities/TypeGuards";

import BlockPreviewCard from "./BlockPreviewCard";

import "./BlockProperties.scss";

interface IBlockPropertiesProps {
  block: IBlock;
  className?: string;
}

const BlockProperties = (props: IBlockPropertiesProps) => {
  const { block, className } = props;

  const renderContent = () => {
    if (!block.propertyInstances?.length) {
      return (
        <NonIdealState
          iconSize={20}
          icon="search-template"
          title="No block properties"
          description="This block doesn’t have any property instances"
        />
      );
    }

    return block.propertyInstances
      .filter(isFieldDefined("propertyDefinition"))
      .map(prop => <BlockPropertyItem key={prop.id} propertyInstance={prop} propertyDefinition={prop.propertyDefinition} />);
  };

  return (
    <BlockPreviewCard title="Properties" className={className}>
      <div>{renderContent()}</div>
    </BlockPreviewCard>
  );
};

export default observer(BlockProperties);
