import { IBlock } from "@store/BlockStore";
import { IPropertyInstance } from "@store/PropertyInstanceStore";

export const validateBlockLabel = (candidateText: string, parentBlock?: IBlock) => {
  if (!candidateText) {
    return {
      isValid: false,
      message: "Empty string",
    };
  }
  candidateText = candidateText.trim();

  const allowedCharacters = /^[a-z\d\-_ ]+$/i;

  if (!candidateText.match(allowedCharacters)) {
    return {
      isValid: false,
      message: "Invalid characters in string",
    };
  }

  if (parentBlock) {
    for (const sibling of parentBlock.validatedChildren) {
      if (sibling.label === candidateText) {
        return {
          isValid: false,
          message: "Duplicate label",
        };
      }
    }
  }

  return { isValid: true, result: candidateText };
};

export const getValidationErrorMsg = (candidateText: string, property: IPropertyInstance): string => {
  const hasOnlyValidChars = /^[a-z\d\-_\s]+$/i;

  if (!candidateText) {
    return "Label cannot be empty";
  } else if (!hasOnlyValidChars.test(candidateText)) {
    return "Label contains invalid characters";
  }

  const block: IBlock | undefined = property.parentBlock;

  if (!block?.validatedPropertyInstances) {
    return "Block not found";
  }

  for (const p of block.validatedPropertyInstances) {
    if (p.propertyDefinition?.label === candidateText && p.id !== property.id) {
      return "Duplicate label";
    }
  }

  return "";
};
