import { HTMLTable, Tooltip } from "@blueprintjs/core";
import { isValidEvaluation } from "@rollup-io/engineering";
import classNames from "classnames";
import { observer } from "mobx-react";

import { MultiplicityTag } from "@components/MultiplicityTag";
import { useAppNavigate } from "@router/hooks";
import appStore from "@store/AppStore";
import { IBlock } from "@store/BlockStore";
import { ModelingActiveTab } from "@store/EnvironmentStore";
import { IPropertyInstance } from "@store/PropertyInstanceStore";
import { formatStringFromMathResult, truncate } from "@utilities";

import "./PropertyInfoTable.scss";

/** Type defs. */
type PropertyInfoTableProps = {
  properties: IPropertyInstance[];
  includeLabel?: boolean;
};

/** Main function. */
export const PropertyInfoTable = observer(({ properties, includeLabel }: PropertyInfoTableProps) => {
  const { navigateToBlock } = useAppNavigate();

  const handlePropertyClicked = (property: IPropertyInstance) => {
    appStore.env.setModelingLeftActiveTab(ModelingActiveTab.model);
    navigateToBlock(property.parentBlock.id);
    // TODO: Once we have the concept of an active property, also activate that
    // This will be useful for collaboration
  };

  if (properties?.length) {
    const rows = properties.map(p => {
      // Trim path if the label is not supposed to be included
      // Once we have a parent field, this could be simplified
      const path = includeLabel ? p.path : p.path?.replace(/\.[^.]*$/, "");

      const validValue = isValidEvaluation(p.evaluatedExpressionInfo);
      const formattedValue = validValue
        ? formatStringFromMathResult(p.evaluatedExpressionInfo?.calculatedResult, p.propertyDefinition?.formatType)
        : "Error";

      const className = classNames("property-row", { "invalid-value": !validValue });
      const block = p.parentBlock as IBlock;

      return (
        <tr key={p.id} onClick={() => handlePropertyClicked(p)} className={className}>
          <td className="path-cell">
            <span>{path}</span>
            {block.multiplicity ? <MultiplicityTag multiplicity={block.multiplicity} /> : null}
          </td>
          <td>
            {validValue ? (
              <span>{truncate(formattedValue ?? "")}</span>
            ) : (
              <Tooltip content={p.evaluatedExpressionInfo?.message ?? "Unknown error"}>
                <span>Error</span>
              </Tooltip>
            )}
          </td>
        </tr>
      );
    });

    return (
      <>
        <div className="table-div">
          <HTMLTable interactive striped={false} className="popover-table">
            <thead>
              <tr>
                <th>Element</th>
                <th>Value</th>
              </tr>
            </thead>
            <tbody>{rows}</tbody>
          </HTMLTable>
        </div>
      </>
    );
  } else {
    return <strong>No rollup children were found.</strong>;
  }
});
