import { IIcon } from "@rollup-types/icons";

export enum MentionType {
  Attachment = "attachment",
  User = "user",
  Block = "block",
  AnalysisOutput = "analysis-output",
  Report = "report",
  PropertyInstanceName = "prop",
  PropertyInstanceValue = "prop-val",
  Unknown = "unknown",
}

export interface ISimpleMentionItem {
  id: string;
  type: MentionType;
}

export interface IMentionItem<T = void> extends ISimpleMentionItem {
  label: string;
  extendedLabel?: string;
  queryString: string;
  icon?: IIcon;
  entity: T;
}

export enum MentionMode {
  Link = "Link",
  Mention = "Mention",
  Preview = "Preview",
}
