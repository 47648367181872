import { ComponentProps } from "react";
// eslint-disable-next-line no-restricted-imports
import { SegmentedControl as BPSegmentedControl } from "@blueprintjs/core";
import type { OptionProps } from "@blueprintjs/core/src/common/props";
import classNames from "classnames";
import { observer } from "mobx-react";

import { IE2EDataTag } from "@utilities/E2EUtils";

import styles from "./SegmentedControl.module.scss";

interface IOptionProps extends OptionProps<string>, IE2EDataTag {}

interface ISegmentedControlProps extends ComponentProps<typeof BPSegmentedControl> {
  options: Array<IOptionProps>;
}

const SegmentedControl = (props: ISegmentedControlProps) => {
  const { className, ...restProps } = props;

  return <BPSegmentedControl className={classNames(styles.segmentedControl, className)} {...restProps} />;
};

export default observer(SegmentedControl);
