import { Extension } from "@tiptap/core";

const PreventShiftEnter = Extension.create({
  name: "prevent-shift-enter",
  addKeyboardShortcuts(this) {
    return {
      "Shift-Enter": () => true,
    };
  },
});

export default PreventShiftEnter;
