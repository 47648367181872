import { Outlet, useMatch } from "react-router-dom";
import { Divider, H4, Menu } from "@blueprintjs/core";
import { observer } from "mobx-react";

import { Button } from "@components/Button";
import FloatingFeedbackButton from "@components/FloatingFeedbackButton/FloatingFeedbackButton";
import { MenuItem } from "@components/MenuItem";
import { Switch } from "@components/Switch";
import { useAppNavigate } from "@router/hooks";
import CollapsibleMenuItem from "@router/navigation/CollapsibleMenuItem";
import NavLinkAsMenuItem, { INavLinkAsMenuItem } from "@router/navigation/NavLinkAsMenuItem";
import appStore from "@store/AppStore";
import { prefixIdentifiers } from "@utilities/E2EUtils";

import { getSettingsMenuSections } from "./constants";

import "./SettingsLayout.scss";

/** Main function. */
const SettingsLayout = () => {
  const { navigateToWorkspace } = useAppNavigate();
  const navMatch = useMatch("/settings/*");
  const nestedNavMatch = useMatch("/settings/:module/:subModule");

  const settingsMenuSections = getSettingsMenuSections(appStore);

  const renderNavLink = (link: INavLinkAsMenuItem, index: number) => {
    const { nestedLinks, text, e2eIdentifiers, ...rest } = link;
    const key = typeof text === "string" ? text : index;
    if (nestedLinks) {
      return (
        <CollapsibleMenuItem
          open
          key={key}
          navLink={{
            ...link,
            nestedLinks: link.nestedLinks?.map(nestedLink => ({
              ...nestedLink,
              selected: nestedLink.to === nestedNavMatch?.pathname,
            })),
          }}
        />
      );
    }

    return (
      <NavLinkAsMenuItem
        key={key}
        selected={link.to === navMatch?.pathname}
        text={text}
        {...rest}
        e2eIdentifiers={prefixIdentifiers("settings_menu", e2eIdentifiers)}
      />
    );
  };

  const renderMenu = () => (
    <Menu className="settings-layout--menu">
      {settingsMenuSections.map((section, index) => {
        const isLastSection = index === settingsMenuSections.length - 1;

        return (
          <div key={section.name}>
            <H4>{section.name}</H4>
            <div className="settings-layout--menu-section-content">{section.navLinks.map(renderNavLink)}</div>
            {!isLastSection && <Divider />}
          </div>
        );
      })}
      <MenuItem
        className="settings-layout--dev-mode-menu-item"
        icon="git-pull"
        text={
          <Switch
            defaultChecked={appStore.env.developerMode}
            label="Developer mode"
            alignIndicator="right"
            onChange={appStore.env.toggleDeveloperMode}
            e2eIdentifiers="developer-mode"
          />
        }
        e2eIdentifiers="developer-mode"
      />
    </Menu>
  );

  const handleBackClick = () => {
    const workspaceId = appStore.workspaceModel?.id ?? appStore.userModel?.mostRecentWorkspace;
    navigateToWorkspace(workspaceId);
  };

  return (
    <div className="settings-layout">
      <div className="settings-layout--sidebar">
        <Button e2eIdentifiers="settings-back-button" alignText="left" large fill minimal icon="arrow-left" onClick={handleBackClick}>
          Settings
        </Button>
        <Divider />
        {renderMenu()}
        <FloatingFeedbackButton />
      </div>
      <div className="settings-layout--content-pane">
        <Outlet />
      </div>
    </div>
  );
};

/** Exports. */
export default observer(SettingsLayout);
