import isNull from "lodash/isNull";
import omitBy from "lodash/omitBy";

import { AnalysisInput } from "@rollup-api/models/code-blocks/analysis-input.model";
import { AnalysisOutput } from "@rollup-api/models/code-blocks/analysis-output.model";
import { ExecutionEnvironmentType } from "@rollup-api/models/execution-environments/execution-environment.type";
import { EntityModelBase } from "@rollup-api/shared/entityModelBase";
import { ICodeBlockSnapshotIn } from "@store/Analysis/CodeBlockStore";
import { convertTimestamp } from "@utilities";

export class CodeBlock extends EntityModelBase {
  public readonly label!: string;
  public readonly autoRun!: boolean;
  public readonly orderIndex!: number;
  public readonly type!: ExecutionEnvironmentType;
  public readonly executionEnvironmentId!: string;
  public readonly code?: string;
  public readonly analysisInputs?: AnalysisInput[];
  public readonly analysisOutputs?: AnalysisOutput[];
  public readonly createdBy?: string;
  public readonly updatedBy?: string;
}

export function toCodeBlockSnapshot(codeBlock: CodeBlock): ICodeBlockSnapshotIn {
  const input = omitBy(codeBlock, isNull) as CodeBlock;
  return {
    ...input,
    createdAt: convertTimestamp(input.createdAt),
    updatedAt: convertTimestamp(input.updatedAt),
  };
}
