import { CreateRequirementsPageDto, RequirementsPage, RequirementsPageUpdateDto } from "@rollup-api/models";
import { trackSegmentEvent } from "src/lib/Segment";

import { HttpClient, ParentClient } from "./client";

export class RequirementsPages extends HttpClient {
  public constructor(parent: ParentClient) {
    super(parent);
  }

  private get endpoint() {
    return `workspace/${this.parent.workspaceId}/requirements-pages`;
  }

  public create = (dto: CreateRequirementsPageDto) => {
    trackSegmentEvent("requirement-page:create", { id: dto.id, label: dto.label });
    return this.instance.post<RequirementsPage>(`${this.endpoint}`, dto);
  };

  public update = (id: string, dto: RequirementsPageUpdateDto) => {
    trackSegmentEvent("requirement-page:update", { id, label: dto.label });
    return this.instance.patch<RequirementsPage>(`${this.endpoint}/${id}`, dto);
  };

  public delete = (id: string) => {
    trackSegmentEvent("requirement-page:delete", { id });
    return this.instance.delete<RequirementsPage>(`${this.endpoint}/${id}`);
  };
}
