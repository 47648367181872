import { JSX, ReactNode } from "react";
import { FormGroup, InputGroup, Intent } from "@blueprintjs/core";
import { Suggest } from "@blueprintjs/select";
import classNames from "classnames";
import { observer } from "mobx-react-lite";

import { Text, TextVariant } from "src/ui/Text";

import "./FilterableMenu.scss";

interface IFilterableMenuProps<T = any> {
  queryString: string;
  title?: string;
  children?: ReactNode;
  intent?: Intent;
  helperText?: string;
  placeholder?: string;
  className?: string;
  dropdownProps?: {
    dropdownItems?: T[];
    dropdownItemRenderer(item: T): JSX.Element | null;
  };
  setQueryString(value: string): void;
}

const FilterableMenu = <T,>(props: IFilterableMenuProps<T>) => {
  const { queryString, title, intent, helperText, placeholder } = props;
  const { children, className, dropdownProps, setQueryString } = props;
  const { dropdownItems = [], dropdownItemRenderer } = dropdownProps ?? {};
  const useDropdown = !!dropdownItemRenderer;

  const renderChildren = () => <div className="filterable-menu--children">{children}</div>;

  const renderInputWithPopover = () => (
    <Suggest
      className="filterable-menu--popover-target"
      items={dropdownItems}
      itemRenderer={dropdownItemRenderer}
      onQueryChange={setQueryString}
      popoverProps={{ minimal: true, matchTargetWidth: true }}
    />
  );

  const renderInput = () => (
    <InputGroup
      className="filterable-menu--input"
      placeholder={placeholder ?? "Search"}
      autoFocus
      intent={intent}
      leftIcon="search"
      value={queryString}
      onChange={e => setQueryString(e.target.value)}
    />
  );

  return (
    <div className={classNames("filterable-menu", className)}>
      {title && (
        <Text className="filterable-menu--title" variant={TextVariant.Caption}>
          {title}
        </Text>
      )}
      <FormGroup intent={intent} helperText={helperText}>
        {useDropdown ? renderInputWithPopover() : renderInput()}
      </FormGroup>
      {!useDropdown && children && renderChildren()}
    </div>
  );
};

export default observer(FilterableMenu);
