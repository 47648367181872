import { useEffect, useState } from "react";
import { EditableText } from "@blueprintjs/core";
import { observer } from "mobx-react";

import appStore from "@store/AppStore";
import type { IBlock } from "@store/BlockStore";
import type { IStatusDefinition } from "@store/StatusDefinitionStore";

/** Type defs. */
type StatusTypeNumberEditorProps = {
  statusDefinition?: IStatusDefinition;
  block?: IBlock | null;
  hidePlaceholder?: boolean;
  disabled?: boolean;
  readOnly?: boolean;
};

/** Main function. */
function StatusTypeNumberEditor({ statusDefinition, block, hidePlaceholder, disabled, readOnly }: StatusTypeNumberEditorProps) {
  // This should be calculated through the store.
  const statusInstance = block?.validatedStatusInstances.find(i => i.statusDefinition === statusDefinition);
  const inputTextDefaultValue = statusInstance?.value || "";

  const [inputText, setInputText] = useState(inputTextDefaultValue);

  useEffect(() => {
    setInputText(inputTextDefaultValue);
  }, [inputTextDefaultValue, statusInstance]);

  if (!statusDefinition || !block) {
    return null;
  }

  if (readOnly) {
    return statusInstance?.value;
  }

  const setValue = (value: string) => {
    if (!statusInstance) {
      if (!value) {
        return; // Don't create new instances for empty values.
      }
      appStore.workspaceModel?.addStatusInstance(block, statusDefinition, value);
    } else {
      if (!value) {
        block.deleteStatusInstance(statusInstance);
      } else {
        statusInstance.setValue(value);
      }
    }
  };

  return (
    <div className="component--status-type-number-editor ">
      <EditableText
        value={inputText}
        disabled={disabled}
        onChange={setInputText}
        onConfirm={() => setValue(inputText)}
        type="number"
        className="w-full my-[3px] [&>*]:w-full pl-1" // Because of the number input we need to stretch the child elements.
        placeholder={hidePlaceholder ? "" : "Empty"}
      />
    </div>
  );
}

/** Exports. */
export type { StatusTypeNumberEditorProps };
export default observer(StatusTypeNumberEditor);
