import { useEffect, useRef } from "react";
import { useWorkspace } from "@hooks/useWorkspace";
import {
  ColumnEventType,
  ColumnMovedEvent,
  GridApi,
  GridReadyEvent,
  RowClickedEvent,
  RowDragEndEvent,
  RowSelectedEvent,
} from "ag-grid-community";
import { observer } from "mobx-react";

import { bomTableGridOptions } from "@components/BomTables/Table/BomTableGridOptions";
import CreateNewInput from "@components/Shared/CreateNewInput/CreateNewInput";
import Table from "@components/Table";
import { updateRowsClass } from "@components/Table/utils";
import { BomTableNode } from "@store/BlockStore";
import { IBomTable } from "@store/BomTable/BomTableStore";
import { validateBlockLabel } from "@utilities";

import HeaderCell from "./Cells/HeaderCell";
import TotalRow from "./Components/TotalRow";
import { bomColumnIndexMargin, bomTablePrefix } from "./constants";
import { getColDefs } from "./utils";

import "./BomTable.scss";

interface IBomTableProps {
  table: IBomTable;
}

export const BomTable = (props: IBomTableProps) => {
  const { table } = props;
  const workspace = useWorkspace();
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    return table.cleaSelectedRows;
  }, [table]);

  useEffect(() => {
    updateRowsClass(table.previewBlockId, bomTablePrefix);
  }, [table.previewBlockId]);

  useEffect(() => {
    if (table.tableGridApi) {
      if (table.rowNodes.length) {
        table.setPreviewBlockId(table.rowNodes[0].block.id);
      }
      updateRowsClass(table.previewBlockId, bomTablePrefix);
      ref.current?.scrollTo(0, 0);
    }
  }, [table.validRows, table.tableGridApi, table]);

  const handleDestroyed = () => {
    table.setTableGridApi();
  };

  const handleGridReady = (e: GridReadyEvent<BomTableNode>) => {
    table.setTableGridApi(e.api);
  };

  const handleColumnResize = (colId: string, width: number, _api: GridApi, source: ColumnEventType) => {
    const column = table.columnMap.get(colId);
    column?.setWidth(width, source !== "api");
  };

  const handleColumnMoved = (e: ColumnMovedEvent<BomTableNode>) => {
    if (!e.finished || e.toIndex === undefined) {
      return;
    }

    const id = e.column?.getColId();
    const destId = table.columnArray.at(e.toIndex - bomColumnIndexMargin).id;
    const srcIndex = table.columnArray.findIndex(c => c.id === id);

    if (destId && id && srcIndex >= 0) {
      const columnOrder = e.api
        .getAllGridColumns()
        .map(c => c.getColId())
        .slice(0, bomColumnIndexMargin);
      table.reorderColumn(id, destId, columnOrder, e.source === "uiColumnMoved");
    }
  };

  const handleRowSelection = (e: RowSelectedEvent<BomTableNode>) => table.toggleRowSelection(e.data?.block.id);

  const onRowDragEnd = (e: RowDragEndEvent<BomTableNode>) => {
    const targetIndex: number = e.overIndex;
    const sourceId: string | undefined = e.node.data?.block.id;
    const targetId: string | undefined = table.rows[targetIndex]?.id;

    if (sourceId && targetId) {
      table.moveRow(sourceId, targetId);
    }
  };

  const handleNewRow = async (label: string) => {
    const blockId = await workspace.addDetachedRow(label, table);
    if (blockId) {
      table.setPreviewBlockId(blockId);
      setTimeout(() => {
        // wait until height animation is done
        table.tableGridApi?.ensureIndexVisible(table.validRows.length - 1);
      }, 500);
    }
    return !!blockId;
  };

  const handleRowClicked = (event: RowClickedEvent<BomTableNode>) => {
    const blockId = event.node.id?.replace(`${bomTablePrefix}-`, "");
    blockId && table.setPreviewBlockId(blockId);
  };

  return (
    <div ref={ref} className="bom-table--root">
      <Table<BomTableNode>
        rowData={table.rowNodes}
        className="bom-table"
        columnDefs={getColDefs(table.columns)}
        onRowDragEnd={onRowDragEnd}
        onRowClicked={handleRowClicked}
        onRowSelected={handleRowSelection}
        components={{ agColumnHeader: HeaderCell }}
        onChartDestroyed={handleDestroyed}
        onGridReady={handleGridReady}
        onColumnResize={handleColumnResize}
        onColumnMoved={handleColumnMoved}
        gridOptions={bomTableGridOptions}
        // If we enable this prop, PartNumberSchemaModal stops working
        stopEditingWhenCellsLoseFocus={false}
        dynamicAutoHeight
      />
      <CreateNewInput icon="add" placeholder="Add new row" validation={validateBlockLabel} tryCreate={handleNewRow} />
      <TotalRow table={table} />
    </div>
  );
};

export default observer(BomTable);
