import { KeyboardEvent } from "react";
import classNames from "classnames";
import { observer } from "mobx-react";

import { EditorContent } from "@components/EditorContent";
import { useDocumentEditor } from "@components/Reports/Editor/useDocumentEditor";
import { getDocumentEditorClassNames } from "@components/Reports/Editor/utils";

import { TEditorBlockProps } from "../types";

const CodeReportBlock = (props: TEditorBlockProps) => {
  const { documentBlock, editable = true, eventHandlers, disableDashNavigation, onUpdate, onEditorReady } = props;
  const { onFocus, onBlur, onEnterKeyDown, onBackspaceKeyDown, onArrowDownKey, onArrowUpKey } = eventHandlers;
  const content = documentBlock.label || `<blockquote></blockquote>`;

  const { editor } = useDocumentEditor({
    blockId: documentBlock.id,
    content,
    editable,
    disableDashNavigation,
    onUpdate,
    onFocus,
    onBlur,
    onEditorReady,
  });

  const handleKeyDownEvents = (e: KeyboardEvent<HTMLDivElement>) => {
    onEnterKeyDown(e);
    onBackspaceKeyDown(e);
    onArrowUpKey(e);
    onArrowDownKey(e);
  };

  const commonClassNames = getDocumentEditorClassNames({
    annotationList: documentBlock.annotationList,
  });

  return (
    <EditorContent
      id={documentBlock.id}
      onKeyDown={handleKeyDownEvents}
      className={classNames("report-editor--quote", commonClassNames)}
      editor={editor}
      e2eIdentifiers="quote"
    />
  );
};

export default observer(CodeReportBlock);
