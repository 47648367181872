import { KeyboardEvent } from "react";
import { Placeholder } from "@tiptap/extension-placeholder";
import { observer } from "mobx-react";

import { EditorContent } from "@components/EditorContent";
import PreventModEnter from "@components/Reports/Editor/Extentions/PreventModEnter";
import { RollupInputRules } from "@components/Reports/Editor/Extentions/RollupInputRules";
import RollupPlaceholder from "@components/Reports/Editor/Extentions/RollupPlaceholder";
import { useDocumentEditor } from "@components/Reports/Editor/useDocumentEditor";
import { getDocumentEditorClassNames } from "@components/Reports/Editor/utils";

import EditorBubbleMenu from "../EditorBubbleMenu";
import { TEditorBlockProps } from "../types";

import "./Headings.scss";

const TextReportBlock = (props: TEditorBlockProps) => {
  const { documentBlock, editable = true, eventHandlers, disableDashNavigation, onUpdate, onEditorReady } = props;
  const { onFocus, onBlur, onEnterKeyDown, onBackspaceKeyDown, onArrowDownKey, onArrowUpKey } = eventHandlers;

  const placeholderExtension = disableDashNavigation
    ? [Placeholder.configure({ placeholder: documentBlock.placeholder })]
    : [RollupPlaceholder];
  const { editor, onBubbleMenuHide, onBubbleMenuShow } = useDocumentEditor({
    blockId: documentBlock.id,
    content: documentBlock.label,
    editable,
    extensions: [...placeholderExtension, PreventModEnter, RollupInputRules.configure({ reportBlockId: documentBlock.id })],
    disableDashNavigation,
    onUpdate,
    onFocus,
    onBlur,
    onEditorReady,
  });

  const handleKeyDownEvents = (e: KeyboardEvent<HTMLDivElement>) => {
    onEnterKeyDown(e);
    onBackspaceKeyDown(e);
    onArrowUpKey(e);
    onArrowDownKey(e);
  };

  return (
    <div key={documentBlock.id} className="report-editor--headings">
      <EditorBubbleMenu onHide={onBubbleMenuHide} onShow={onBubbleMenuShow} editor={editor} />
      <EditorContent
        id={documentBlock.id}
        onKeyDown={handleKeyDownEvents}
        className={getDocumentEditorClassNames({
          annotationList: documentBlock.annotationList,
        })}
        editor={editor}
        e2eIdentifiers="text"
      />
    </div>
  );
};

export default observer(TextReportBlock);
