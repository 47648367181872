import { useCallback, useMemo, useState } from "react";
import { ButtonGroup, IconName, MaybeElement, Position, Tooltip } from "@blueprintjs/core";
import { observer } from "mobx-react";

import OperatorOrbitCameraIconDark from "@assets/icons/hoopsIcons/dark/operator_orbit_camera_dark.svg?react";
import OperatorTurntableIconDark from "@assets/icons/hoopsIcons/dark/operator_turnable_dark.svg?react";
import OperatorWalkIconDark from "@assets/icons/hoopsIcons/dark/operator_walk_dark.svg?react";
import OperatorOrbitCameraIconLight from "@assets/icons/hoopsIcons/light/operator_orbit_camera_light.svg?react";
import OperatorTurntableIconLight from "@assets/icons/hoopsIcons/light/operator_turnable_light.svg?react";
import OperatorWalkIconLight from "@assets/icons/hoopsIcons/light/operator_walk_light.svg?react";
import { Button } from "@components/Button";
import { Popover } from "@components/Popover";
import { ThemedCustomIcon } from "@components/Shared/LegacyCustomIcon/ThemedCustomIcon";

import styles from "../HoopsMenu.module.scss";

type HoopsMenuOperatorButtonGroupProps = {
  viewer: Communicator.WebViewer;
};

type Operator = {
  name: string;
  description?: string;
  mode: Communicator.OperatorId;
  icon: IconName | MaybeElement;
  onSelect?: (operator: Communicator.Operator.Operator) => void;
};

const operators: Operator[] = [
  {
    name: "Orbit",
    mode: Communicator.OperatorId.Navigate,
    icon: <ThemedCustomIcon large lightIcon={<OperatorOrbitCameraIconLight />} darkIcon={<OperatorOrbitCameraIconDark />} />,
  },
  {
    name: "Turntable",
    mode: Communicator.OperatorId.Turntable,
    icon: <ThemedCustomIcon large lightIcon={<OperatorTurntableIconLight />} darkIcon={<OperatorTurntableIconDark />} />,
    onSelect: operator => {
      (operator as Communicator.Operator.CameraTurntableOperator).setRotationAxis(Communicator.Axis.Y);
    },
  },
  {
    name: "Walk",
    mode: Communicator.OperatorId.Walk,
    icon: <ThemedCustomIcon lightIcon={<OperatorWalkIconLight />} darkIcon={<OperatorWalkIconDark />} />,
  },
];

const HoopsMenuOperatorButtonGroup = ({ viewer }: HoopsMenuOperatorButtonGroupProps) => {
  const [operatorId, setOperatorId] = useState<Communicator.OperatorId>(Communicator.OperatorId.Navigate);

  const handleOperatorChanged = useCallback(
    (entry: Operator) => {
      const operatorId = entry.mode;
      setOperatorId(operatorId);
      viewer.operatorManager.set(operatorId, 0);
      if (entry.onSelect) {
        const hoopsOperator = viewer.operatorManager.getOperator(operatorId);
        if (hoopsOperator) {
          entry.onSelect(hoopsOperator);
        }
      }
    },
    [viewer]
  );

  const operatorMenu = (
    <ButtonGroup large>
      {operators.map(entry => (
        <Tooltip position={Position.TOP} hoverOpenDelay={500} key={entry.name} content={entry.name}>
          <Button
            className={styles.hoopsToolbarButtonAndButtonGroup}
            icon={entry.icon}
            active={operatorId === entry.mode}
            onClick={() => handleOperatorChanged(entry)}
            e2eIdentifiers="change-operator"
          />
        </Tooltip>
      ))}
    </ButtonGroup>
  );

  const operatorEntry = useMemo(() => operators.find(e => e.mode === operatorId), [operatorId]);

  return (
    <Popover content={operatorMenu} placement={Position.TOP}>
      <Tooltip position={Position.TOP} content={`Camera: ${operatorEntry?.name}`}>
        <Button
          className={styles.hoopsToolbarButtonAndButtonGroup}
          icon={operatorEntry?.icon}
          e2eIdentifiers={["camera", operatorEntry?.name ?? ""]}
        />
      </Tooltip>
    </Popover>
  );
};

export default observer(HoopsMenuOperatorButtonGroup);
