import { useEffect } from "react";
import { Content, Editor } from "@tiptap/react";

export interface IUseUpdateEditorContentParams {
  content: Content;
  editor: Editor | null;
  shouldNotUpdate?: boolean;
}

/**
 * This hook is useful for updating editor content when a real-time update happens,
 * i.e. when a user makes a change in the editor that gets propagated to another user
 */
export const useUpdateEditorContent = (params: IUseUpdateEditorContentParams) => {
  const { content, editor, shouldNotUpdate } = params;

  useEffect(() => {
    const contentHasChanged = editor && editor.getHTML() !== content && editor.getText() !== content;

    if (!editor || !contentHasChanged || shouldNotUpdate) {
      return;
    }

    editor.commands.setContent(content);
  }, [editor, content, shouldNotUpdate]);
};
