import { Checkbox } from "@blueprintjs/core";
import { observer } from "mobx-react";

import appStore from "@store/AppStore";
import type { IBlock } from "@store/BlockStore";
import type { IStatusDefinition } from "@store/StatusDefinitionStore";
import { createDataTestId, ElementType } from "@utilities/E2EUtils";

/** Type defs. */
type StatusTypeCheckEditorProps = {
  statusDefinition?: IStatusDefinition;
  block?: IBlock | null;
  disabled?: boolean;
};

/** Main function. */
function StatusTypeCheckEditor({ statusDefinition, block, disabled }: StatusTypeCheckEditorProps) {
  if (!statusDefinition || !block) {
    return null;
  }

  // This should be calculated through the store.
  const statusInstance = block.validatedStatusInstances.find(i => i.statusDefinition === statusDefinition);

  const setValue = (value: string) => {
    if (!statusInstance) {
      if (!value) {
        return; // Don't create new instances for empty values.
      }
      appStore.workspaceModel?.addStatusInstance(block, statusDefinition, value);
    } else {
      if (!value) {
        block.deleteStatusInstance(statusInstance);
      } else {
        statusInstance.setValue(value);
      }
    }
  };

  return (
    <div className="component--status-type-check-editor px-1.5">
      <Checkbox
        disabled={disabled}
        className="my-[3px]"
        checked={statusInstance?.checkedValue || false}
        onChange={event => setValue(event.currentTarget.checked ? "true" : "false")}
        {...createDataTestId(ElementType.Checkbox, statusDefinition.label)}
      />
    </div>
  );
}

/** Exports. */
export type { StatusTypeCheckEditorProps };
export default observer(StatusTypeCheckEditor);
