import { useEffect, useState } from "react";
import { Intent, NonIdealState } from "@blueprintjs/core";
import { datadogRum } from "@datadog/browser-rum";
import { StiggQOFactory } from "@queries/StiggQOFactory";
import { CustomerPortal as StiggCustomerPortal, useStiggContext } from "@stigg/react-sdk";
import { useQuery } from "@tanstack/react-query";
import { Text, TextVariant } from "@ui/Text";
import classNames from "classnames";

import { Button } from "@components/Button";
import { SubscriptionStatus } from "@rollup-types/stigg";
import BillingSupportNote from "@router/elements/settings/SettingsPlanAndBilling/BillingSupportNote";
import { TrialExtensionDialog } from "@router/elements/settings/SettingsPlanAndBilling/TrialExtensionDialog";
import { ISelectedPlanData } from "@router/elements/settings/SettingsPlanAndBilling/types";
import { daysLeftToTrialEnd } from "@utilities/Billing";
import { trackSegmentEvent } from "src/lib/Segment";

import { Invoices } from "../Invoices";
import { Paywall } from "../Paywall";
import { Usage } from "../Usage";

import styles from "./CustomerPortal.module.scss";

interface ICustomerPortalProps {
  productId: string;
  onPlanSelected(plan: ISelectedPlanData): void;
}

export const CustomerPortal = (props: ICustomerPortalProps) => {
  const { productId, onPlanSelected } = props;
  const ctx = useStiggContext();
  const [isTrialExtensionDialogOpen, setIsTrialExtensionDialogOpen] = useState(false);

  const { data, isLoading } = useQuery(StiggQOFactory.createGetCustomerQO(ctx.stigg));
  const { customer, error } = data ?? {};
  const hasNoSubscription = !isLoading && !customer?.subscriptions.length;
  const hasActiveSubscription = !isLoading && !!customer?.subscriptions.some(sub => sub.status === SubscriptionStatus.Active);
  const daysLeft = customer?.subscriptions[0] ? daysLeftToTrialEnd(customer.subscriptions[0]) : 0;

  useEffect(() => {
    if (error) {
      datadogRum.addError(error);
      trackSegmentEvent("error-stigg-customer", error);
    }
  }, [customer, error]);

  const renderNoActiveSubscription = () => {
    return (
      <div className={styles.customerPortalNoActiveSubscription}>
        <Text variant={TextVariant.H2}>Subscription</Text>
        <Text variant={TextVariant.H1}>No active subscription</Text>
        {!!customer?.trialedPlans?.length && (
          <>
            <Text variant={TextVariant.Body}>
              Your trial has expired. You can reactivate your access by subscribing to one of our plans.
            </Text>
            <Text variant={TextVariant.Body}>If you need more time to evaluate our product, you can request a trial extension.</Text>
            <Button onClick={() => setIsTrialExtensionDialogOpen(true)} e2eIdentifiers="request-trial-extension" intent={Intent.PRIMARY}>
              Request for trial extension
            </Button>
          </>
        )}
      </div>
    );
  };

  const renderContent = () => {
    if (error) {
      return (
        <NonIdealState
          icon="warning-sign"
          title="Error"
          description="An error occurred while fetching your billing information. Please reach out to us for help."
        />
      );
    }

    return (
      <>
        {isTrialExtensionDialogOpen && <TrialExtensionDialog onClose={() => setIsTrialExtensionDialogOpen(false)} />}
        <div className={styles.customerPortalHeaderContainer}>
          <Text variant={TextVariant.H1}>Billing information</Text>
          {hasActiveSubscription && <BillingSupportNote />}
        </div>
        {hasNoSubscription && renderNoActiveSubscription()}
        <div
          className={classNames(styles.customerPortalShowOverviewOnly, {
            [styles.freeTrialIndicatorRed]: daysLeft <= 2,
            [styles.freeTrialIndicatorOrange]: daysLeft > 2 && daysLeft < 15,
            [styles.freeTrialIndicatorGray]: daysLeft >= 15,
          })}
        >
          <StiggCustomerPortal hiddenSections={["paymentDetails", "invoices", "usage"]} />
        </div>
        <div className={styles.customerPortalBottomContainer}>
          <Paywall productId={productId} onPlanSelected={onPlanSelected} />
          <div className={styles.customerPortalPaymentDetailsInvoices}>
            <Usage />
            <StiggCustomerPortal hiddenSections={["invoices", "usage"]} />
            <Invoices />
          </div>
        </div>
        <div className={styles.customerPortalShowInvoicesOnly}></div>
      </>
    );
  };

  return (
    // When the user has no active subscription, Stigg's CustomerPortal widget shows a loading placeholder,
    // which is not informative and gives the misleading idea that something is loading, therefore we hide it
    <div className={classNames(styles.customerPortal, { [styles.customerPortalOverviewHidden]: hasNoSubscription })}>{renderContent()}</div>
  );
};
