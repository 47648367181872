import { FinishImportDto } from "@rollup-api/models/import/importFinishDto.model";

import { CreateImportDto, Import, ImportExtensionType } from "../models/import";

import { HttpClient, ParentClient } from "./client";

export type TImportsListResponse = { imports: Import[]; hasMore: boolean };

export type TImportListRequestParams = { take: number; skip: number; workspaceId?: string };

export class Imports extends HttpClient {
  public constructor(parent: ParentClient) {
    super(parent);
  }

  private get endpoint() {
    return "import";
  }

  public finish = async (dto: FinishImportDto): Promise<Import | undefined> => {
    const result = await this.instance.post<Import>(`${this.endpoint}/finish`, dto);
    return result.data;
  };

  public delete = async (id: string) => {
    const result = await this.instance.delete(`${this.endpoint}/${id}`);
    return result.data;
  };

  public create = async (dto: CreateImportDto, type = ImportExtensionType.CSV): Promise<Import | undefined> => {
    try {
      const res = await this.instance.post<Import>(`${this.endpoint}/${type}`, dto);
      return res.data;
    } catch (err) {
      console.error(err);
      return;
    }
  };

  public retrieveList = async (params: TImportListRequestParams): Promise<TImportsListResponse> => {
    try {
      const res = await this.instance.get<TImportsListResponse>(this.endpoint, { params });
      return res.data;
    } catch (err) {
      console.error(err);
      return { imports: [], hasMore: false };
    }
  };
}
