import { HttpClient, ParentClient } from "@rollup-api/api";
import { ICreateDataSinkDto, IUpdateDataSinkDto } from "@rollup-api/models/data-sinks";
import { DataSink } from "@rollup-api/models/data-sinks/data-sink.model";

export class DataSinks extends HttpClient {
  public constructor(parent: ParentClient) {
    super(parent);
  }

  private get endpoint() {
    return `workspace/${this.parent.workspaceId}/data-sinks`;
  }

  public getAll = () => {
    return this.instance.get<DataSink[]>(`${this.endpoint}`);
  };

  public getOne = (id: string) => {
    return this.instance.get<DataSink>(`${this.endpoint}/${id}`);
  };

  public create = (dto: ICreateDataSinkDto) => {
    return this.instance.post<DataSink>(`${this.endpoint}`, dto);
  };

  public update = (id: string, dto: IUpdateDataSinkDto) => {
    return this.instance.patch<DataSink>(`${this.endpoint}/${id}`, dto);
  };

  public delete = (id: string) => {
    return this.instance.delete<DataSink>(`${this.endpoint}/${id}`);
  };
}
