import assignIn from "lodash/assignIn";
import { IAnyModelType, Instance, types } from "mobx-state-tree";
import { Socket } from "socket.io-client";

import { CreateInterfaceDto, InterfaceType, InterfaceUpdateDto } from "@rollup-api/models/interface";
import { updateInterface } from "@rollup-api/utils";
import { AttributeStore, IAttribute } from "@store/AttributeStore";
import { BlockStore } from "@store/BlockStore";
import { moveItemInRefArray, parentWorkspace, validatedRefArray } from "@utilities";
import { rollupClient } from "src/core/api";

import { showApiErrorToast } from "../Components/UiLayers/toaster";

import appStore from "./AppStore";

const InterfaceUiStore = types
  .model("InterfaceUi", {
    isOpen: types.optional(types.boolean, true),
  })
  .actions(self => ({
    setIsOpen(open: boolean) {
      self.isOpen = open;
    },
    toggleIsOpen() {
      self.isOpen = !self.isOpen;
    },
  }));

export const InterfaceStore = types
  .model("Interface", {
    id: types.identifier,
    replicated: types.optional(types.boolean, true),
    parentBlock: types.safeReference(types.late((): IAnyModelType => BlockStore)),
    interfaceType: types.optional(types.enumeration("InterfaceType", [...Object.values(InterfaceType)]), InterfaceType.contact),
    label: types.optional(types.string, ""),
    description: types.optional(types.string, ""),
    attributes: types.maybeNull(types.array(types.safeReference(types.late((): IAnyModelType => AttributeStore)))),
    ui: types.optional(InterfaceUiStore, {}),
  })
  .views(self => ({
    get validatedAttributes() {
      return validatedRefArray<IAttribute>(self.attributes);
    },
  }))
  .actions(self => ({
    setReplicated() {
      self.replicated = true;
    },
    patch(update: InterfaceUpdateDto) {
      // Prevent updating of fixed properties
      const invalidFields = ["id", "parentBlock", "attributes", "ui", "replicated"];
      const updateKeys = Object.keys(update);
      for (const field of invalidFields) {
        if (updateKeys.includes(field)) {
          return false;
        }
      }

      try {
        assignIn(self, update);
        return true;
      } catch (err) {
        console.warn(err);
        return false;
      }
    },
    setInterfaceType(interfaceType: InterfaceType) {
      self.interfaceType = interfaceType;
      updateInterface(self.id, { interfaceType });
    },
    setLabel(label: string) {
      self.label = label;
      updateInterface(self.id, { label });
    },
    setDescription(description: string) {
      self.description = description;
      updateInterface(self.id, { description });
    },
    // region Attribute actions
    addNewAttribute(): string | undefined {
      return parentWorkspace(self)?.addNewAttribute(self as IInterface);
    },
    deleteAttribute(existingAttribute?: IAttribute): boolean {
      if (existingAttribute && self.attributes?.includes(existingAttribute)) {
        self.attributes.remove(existingAttribute);
        return parentWorkspace(self)?.deleteAttribute(existingAttribute) ?? false;
      } else {
        return false;
      }
    },
    moveAttribute(srcId: string, destId: string, notify = true) {
      if (self.attributes) {
        const srcIndex = self.attributes.findIndex(a => a?.id === srcId);
        const destIndex = self.attributes.findIndex(a => a?.id === destId);
        moveItemInRefArray(self.attributes, srcIndex, destIndex);
        if (notify) {
          rollupClient.attributes.reorder(srcId, destId).catch((err: Error) => {
            showApiErrorToast("Error reordering attribute", err);
          });
        }
      }
    },
    // endregion
  }));

export function subscribeToInterfaceEvents(socket: Socket) {
  socket.on("createBlocksInterface", (data: { workspaceId: string; createBlocksInterfaceDto: CreateInterfaceDto }) => {
    if (data.createBlocksInterfaceDto?.id && data.workspaceId === appStore.workspaceModel?.id) {
      const parentBlock = appStore.workspaceModel.blockMap.get(data.createBlocksInterfaceDto.parentBlock);
      if (parentBlock) {
        const { description, label, id, interfaceType } = data.createBlocksInterfaceDto;
        appStore.workspaceModel.addNewInterface(parentBlock, label, description, interfaceType as InterfaceType, id, false);
      }
    }
  });

  socket.on("deleteBlocksInterface", (data: { workspaceId: string; id: string }) => {
    if (data.id && data.workspaceId === appStore.workspaceModel?.id) {
      const interfaceToDelete = appStore.workspaceModel.interfaceMap.get(data.id);
      if (interfaceToDelete) {
        appStore.workspaceModel.deleteInterface(interfaceToDelete, false);
      }
    }
  });

  socket.on("updateBlocksInterface", (data: { workspaceId: string; id: string; updateBlocksInterfaceDto: InterfaceUpdateDto }) => {
    if (data.id && data.workspaceId === appStore.workspaceModel?.id) {
      const interfaceToUpdate = appStore.workspaceModel.interfaceMap.get(data.id);
      interfaceToUpdate?.patch(data.updateBlocksInterfaceDto);
    }
  });

  socket.on("reorderBlocksInterface", (data: { workspaceId: string; id: string; reorderBlocksInterfaceDto: { destinationId: string } }) => {
    if (data.id && data.reorderBlocksInterfaceDto?.destinationId && data.workspaceId === appStore.workspaceModel?.id) {
      const interfaceToUpdate = appStore.workspaceModel.interfaceMap.get(data.id);
      const block = interfaceToUpdate?.parentBlock;
      block?.moveInterface(data.id, data.reorderBlocksInterfaceDto.destinationId, false);
    }
  });
}

export interface IInterface extends Instance<typeof InterfaceStore> {}
