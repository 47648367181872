import { CustomCellEditorProps } from "ag-grid-react";
import { observer } from "mobx-react";

import { MultiplicityInput } from "@components/MultiplicityInput";
import { IBlock } from "@store/BlockStore";

type NumberCellEditorProps = CustomCellEditorProps<{ block: IBlock }>;

const NumberCellEditor = (props: NumberCellEditorProps) => {
  const block: IBlock | null | undefined = props.node.data?.block;

  if (!block) {
    return null;
  }

  const onBlur = () => {
    props.api.stopEditing();
  };

  return <MultiplicityInput hideButtons autoFocus hideClose fill block={block} onBlur={onBlur} />;
};

export default observer(NumberCellEditor);
