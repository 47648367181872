import { AxiosResponse } from "axios";
import assignIn from "lodash/assignIn";
import { cast, flow, IAnyModelType, Instance, isAlive, IType, SnapshotIn, SnapshotOut, types } from "mobx-state-tree";
import { Socket } from "socket.io-client";
import { v4 as uuidv4 } from "uuid";

import { CommentLocationType } from "@components/Modeling/ModelingFrame/ModelBlock/Comments/utils";
import { showApiErrorToast } from "@components/UiLayers/toaster";
import { TemporalDirection } from "@rollup-api/models/comments/commentGetThreadedRequestDto.model";
import { CommentThreadedItem } from "@rollup-api/models/comments/threadedComments.model";
import {
  CreateRequirementBlockDto,
  FunctionalType,
  ManualVerificationStatus,
  RequirementBlockType,
  RequirementBlockUpdateDto,
  RequirementValidationOperation,
  RequirementVerificationMethod,
  SuccessCriteriaType,
} from "@rollup-api/models/requirementBlock";
import { CreateRequirementsPageDto, RequirementsPageUpdateDto } from "@rollup-api/models/requirementsPage";
import { lockRequirementBlock, unlockRequirementBlock, updateRequirementBlock, updateRequirementsPage } from "@rollup-api/utils";
import { AnnotationListStore } from "@store/AnnotationListStore";
import appStore from "@store/AppStore";
import { CommentFeedStore } from "@store/CommentFeedStore";
import { moveItemInRefArray, parentWorkspace, validatedRefArray } from "@utilities";
import { validateRequirement } from "@utilities/EngineeringMath";
import { isDefined } from "@utilities/TypeGuards";
import { rollupClient } from "src/core/api";

import { StoreType } from "./types";

export enum AutomaticVerificationStatus {
  Valid = "valid",
  Invalid = "invalid",
  NotSet = "not-set",
}

export enum VerificationStatus {
  Pending = "Pending",
  Pass = "Pass",
  Fail = "Failed",
}

export const isHeading = (type: RequirementBlockType): boolean => {
  return [RequirementBlockType.h1, RequirementBlockType.h2, RequirementBlockType.h3].includes(type);
};

// TODO move into its own file
export const RequirementBlockStore = types
  .model(StoreType.RequirementBlock, {
    id: types.identifier,
    visibleId: types.maybeNull(types.string),
    parentPage: types.safeReference(types.late((): IAnyModelType => RequirementsPageStore)),
    label: types.optional(types.string, ""),
    locked: types.optional(types.boolean, false),
    description: types.optional(types.string, ""),
    rationale: types.optional(types.string, ""),
    comment: types.optional(types.string, ""),
    note: types.optional(types.string, ""),
    level: types.maybeNull(types.number),
    manualVerification: types.optional(
      types.enumeration("Verification", [...Object.values(ManualVerificationStatus)]),
      ManualVerificationStatus.Undefined
    ),
    successCriteria: types.optional(
      types.enumeration("SuccessCriteriaType", [...Object.values(SuccessCriteriaType)]),
      SuccessCriteriaType.Automatic
    ),
    validationOperation: types.optional(
      types.enumeration("ValidationOperation", [...Object.values(RequirementValidationOperation)]),
      RequirementValidationOperation.Equals
    ),
    verificationMethods: types.optional(
      types.array(
        types.optional(
          types.enumeration("VerificationMethod", [...Object.values(RequirementVerificationMethod)]),
          RequirementVerificationMethod.Sample
        )
      ),
      [RequirementVerificationMethod.Sample]
    ),
    imageUrl: types.optional(types.string, ""),
    linkedProperty: types.optional(types.string, ""),
    validationFormula: types.optional(types.string, "0"),
    unit: types.optional(types.string, ""),
    type: types.enumeration("DataType", [...Object.values(RequirementBlockType)]),
    functionalType: types.optional(types.enumeration("FunctionalType", [...Object.values(FunctionalType)]), FunctionalType.Functional),
    annotationList: types.optional(AnnotationListStore, () => AnnotationListStore.create({})),
  })
  .volatile(() => ({
    validationErrorMessage: "",
    isValid: AutomaticVerificationStatus.NotSet,
  }))
  .actions(self => ({
    patch(update: RequirementsPageUpdateDto) {
      // Prevent updating of fixed properties
      const invalidFields = ["id", "parentPage"];
      const updateKeys = Object.keys(update);
      for (const field of invalidFields) {
        if (updateKeys.includes(field)) {
          return false;
        }
      }

      try {
        assignIn(self, update);
        validateRequirement(self as IRequirementBlock);
        return true;
      } catch (err) {
        console.warn(err);
        return false;
      }
    },
    setLabel(label: string) {
      self.label = label;
      updateRequirementBlock(self.id, { label });
    },
    setValidationMessage(validationErrorMessage: string) {
      self.validationErrorMessage = validationErrorMessage;
    },
    updateValidation(validationFormula: string) {
      self.validationFormula = validationFormula;
      updateRequirementBlock(self.id, { validationFormula });
      validateRequirement(self as IRequirementBlock);
    },
    setImageUrl(imageUrl: string) {
      self.imageUrl = imageUrl;
      updateRequirementBlock(self.id, { imageUrl });
    },
    setBlockType(type: RequirementBlockType) {
      self.type = type;
      updateRequirementBlock(self.id, { type });
    },
    setRationale(rationale: string) {
      self.rationale = rationale;
      updateRequirementBlock(self.id, { rationale });
    },
    setNote(note: string) {
      self.note = note;
      updateRequirementBlock(self.id, { note });
    },
    setComment(comment: string) {
      self.comment = comment;
      updateRequirementBlock(self.id, { comment });
    },
    setDescription(description: string) {
      self.description = description;
      updateRequirementBlock(self.id, { description });
    },
    setFunctionalType(functionalType: FunctionalType) {
      self.functionalType = functionalType;
      updateRequirementBlock(self.id, { functionalType });
    },
    setLinkedPropertyID(linkedProperty: string) {
      self.linkedProperty = linkedProperty;
      updateRequirementBlock(self.id, { linkedProperty });
      validateRequirement(self as IRequirementBlock);
    },
    setManualVerification() {
      self.manualVerification =
        self.manualVerification === ManualVerificationStatus.NotVerified
          ? ManualVerificationStatus.Verified
          : ManualVerificationStatus.NotVerified;
      updateRequirementBlock(self.id, { manualVerification: self.manualVerification });
    },
    setUnit(unit: string) {
      self.unit = unit;
      updateRequirementBlock(self.id, { unit });
      validateRequirement(self as IRequirementBlock);
    },
    setValidationOperation(validationOperation: RequirementValidationOperation) {
      self.validationOperation = validationOperation;
      updateRequirementBlock(self.id, { validationOperation });
      validateRequirement(self as IRequirementBlock);
    },
    setIsValid(isValid: AutomaticVerificationStatus) {
      self.isValid = isValid;
    },
    setLevel(level: number | null) {
      self.level = level;
      updateRequirementBlock(self.id, { level });
    },
    setSuccessCriteria(successCriteria: SuccessCriteriaType) {
      self.successCriteria = successCriteria;
      updateRequirementBlock(self.id, { successCriteria });
    },
    setIsValidBoolean(isValid: boolean) {
      if (isValid) {
        self.isValid = AutomaticVerificationStatus.Valid;
      } else {
        self.isValid = AutomaticVerificationStatus.Invalid;
      }
    },
    toggleVerificationMethod(verificationMethod: RequirementVerificationMethod) {
      if (self.verificationMethods.includes(verificationMethod)) {
        const filteredMethods = self.verificationMethods.filter(method => method !== verificationMethod);
        self.verificationMethods = cast(filteredMethods);
      } else {
        self.verificationMethods.push(verificationMethod);
      }
      updateRequirementBlock(self.id, { verificationMethods: self.verificationMethods.slice() });
    },
    setLocked(locked: boolean, disableNotify?: boolean) {
      self.locked = locked;
      if (!disableNotify) {
        if (locked) {
          lockRequirementBlock(self.id);
        } else {
          unlockRequirementBlock(self.id);
        }
      }
    },
    toggleLocked() {
      this.setLocked(!self.locked);
    },
    setVisibleId(value: string) {
      self.visibleId = value;
      updateRequirementBlock(self.id, { visibleId: self.visibleId });
    },
  }))
  .views(self => ({
    get verificationStatus(): VerificationStatus {
      if (self.successCriteria === SuccessCriteriaType.Automatic) {
        switch (self.isValid) {
          case AutomaticVerificationStatus.NotSet:
            return VerificationStatus.Pending;
          case AutomaticVerificationStatus.Valid:
            return VerificationStatus.Pass;
          case AutomaticVerificationStatus.Invalid:
            return VerificationStatus.Fail;
        }
      }

      switch (self.manualVerification) {
        case ManualVerificationStatus.Undefined:
          return VerificationStatus.Pending;
        case ManualVerificationStatus.Verified:
          return VerificationStatus.Pass;
        case ManualVerificationStatus.NotVerified:
          return VerificationStatus.Fail;
      }
    },
    get computedVisibleId(): string {
      return self.visibleId ?? this.defaultVisibleId;
    },
    get defaultVisibleId(): string {
      let h1Incrementer = 0;
      let h2Incrementer = 0;
      let h3Incrementer = 0;
      let blockIncrementer = 0;
      // number in terms of sections
      for (const block of self.parentPage.requirementBlocks) {
        if (block.type == RequirementBlockType.h1) {
          h1Incrementer += 1;
          h2Incrementer = 0;
          h3Incrementer = 0;
          blockIncrementer = 0;
        } else if (block.type == RequirementBlockType.h2) {
          h2Incrementer += 1;
          h3Incrementer = 0;
          blockIncrementer = 0;
        } else if (block.type == RequirementBlockType.h3) {
          h3Incrementer += 1;
          blockIncrementer = 0;
        } else {
          blockIncrementer += 1;
        }
        if (block == self) {
          break;
        }
      }
      return `ROL-${h1Incrementer}.${h2Incrementer}.${h3Incrementer}.${String(blockIncrementer)}`;
    },
    getUpdateDto(): RequirementBlockUpdateDto {
      return {
        label: self.label,
        rationale: self.rationale,
        manualVerification: self.manualVerification,
        validationOperation: self.validationOperation,
        verificationMethods: [...self.verificationMethods],
        linkedProperty: self.linkedProperty,
        validationFormula: self.validationFormula,
        unit: self.unit,
        type: self.type,
      };
    },
    get hasComments() {
      return !!self.annotationList.annotations[0]?.hasComment;
    },
  }));

// TODO: Tech debt: Create a UI sub-model once the tree stuff has been fixed
export const RequirementsPageStore = types
  .model("RequirementsPage", {
    id: types.identifier,
    replicated: types.optional(types.boolean, true),
    label: types.optional(types.string, "Untitled Page"),
    requirementBlocks: types.array(types.safeReference<IRequirementBlockMobxType>(types.late((): IAnyModelType => RequirementBlockStore))),
    updatedAt: types.optional(types.number, Date.now()),
    updatedBy: types.maybeNull(types.string),
    commentFeed: types.optional(CommentFeedStore, {}),
  })
  .actions(self => ({
    setReplicated() {
      self.replicated = true;
    },
    patch(update: RequirementsPageUpdateDto) {
      // Prevent updating of fixed properties
      const invalidFields = ["id", "requirementBlocks", "replicated"];
      const updateKeys = Object.keys(update);
      for (const field of invalidFields) {
        if (updateKeys.includes(field)) {
          return false;
        }
      }

      try {
        assignIn(self, update);
        return true;
      } catch (err) {
        console.warn(err);
        return false;
      }
    },
    addBlock(id: string, orderIndex?: number): string | undefined {
      if (orderIndex !== undefined) {
        const originalBlocksOrder = self.requirementBlocks.slice();
        self.requirementBlocks.splice(orderIndex, 0, id);
        return originalBlocksOrder.at(orderIndex)?.id;
      } else {
        self.requirementBlocks.push(id);
      }
    },
    deleteRequirementBlock(block: IRequirementBlock): boolean {
      if (block && self.requirementBlocks?.includes(block)) {
        const hasBlockInAgGrid = !!appStore.env.requirementsTableGridApi?.getRowNode(block.id);
        hasBlockInAgGrid && appStore.env.requirementsTableGridApi?.applyTransaction({ remove: [{ id: block.id } as IRequirementBlock] });
        self.requirementBlocks.remove(block);
        return parentWorkspace(self)?.deleteRequirementBlock(block) ?? false;
      } else {
        return false;
      }
    },
    setLabel(label: string) {
      if (label === self.label) {
        return;
      }

      self.label = label;
      updateRequirementsPage(self.id, { label });
    },
    moveBlock(srcId: string, destId: string, notify = true) {
      if (self.requirementBlocks) {
        const srcIndex = self.requirementBlocks.findIndex(i => i?.id === srcId);
        const destIndex = self.requirementBlocks.findIndex(i => i?.id === destId);
        moveItemInRefArray(self.requirementBlocks, srcIndex, destIndex);
        if (notify) {
          rollupClient.requirementBlocks.reorder(srcId, destId).catch((err: Error) => {
            showApiErrorToast("Error reordering requirement", err);
          });
        }
      }
    },
    clearCommentHistory() {
      self.commentFeed = cast({});
    },
  }))
  .views(self => ({
    getReqBlockIdsWithComments(removeHeadings?: boolean): string[] {
      return self.requirementBlocks
        .filter(isDefined)
        .filter(block => block.hasComments)
        .filter(block => !removeHeadings || !isHeading(block.type))
        .map(block => block.id);
    },
    get allValidBlockIds(): string[] {
      return self.requirementBlocks.filter(isDefined).map(block => block.id);
    },
    get validatedBlocks() {
      return validatedRefArray<IRequirementBlock>(self.requirementBlocks).filter(block => isAlive(block));
    },
    get validatedBlockIds() {
      return this.validatedBlocks.map(block => block.id);
    },
    getCreateDto(): CreateRequirementsPageDto {
      return {
        id: uuidv4(),
        label: self.label ? `Copy of ${self.label}` : "",
      };
    },
  }))
  .actions(self => ({
    fetchReqBlockComments: flow(function* fetch(): Generator<any, void, AxiosResponse<CommentThreadedItem[]>> {
      if (!self.requirementBlocks) {
        return;
      }

      try {
        const { data } = yield rollupClient.comments.retrieveThreaded({
          parentIds: self.validatedBlockIds,
          childTake: 9999,
          type: CommentLocationType.Annotation,
          parentTake: 1,
          childSkip: 0,
          temporalDirection: TemporalDirection.Older,
        });
        data.forEach(({ parentId, threadedComments }) => {
          if (!self.requirementBlocks) return;
          const requirementBlock = self.validatedBlocks.find(p => p?.id === parentId);
          requirementBlock?.annotationList.loadThreadedComments(threadedComments);
        });
      } catch (error) {
        throw new Error(`Error fetching requirement block comments for block ${self.id}: ${error}`);
      }
    }),
  }));

export function subscribeToRequirementsPageEvents(socket: Socket) {
  socket.on("createRequirementsPage", (data: { workspaceId: string; createRequirementsPageDto: CreateRequirementsPageDto }) => {
    if (data.createRequirementsPageDto?.id && data.workspaceId === appStore.workspaceModel?.id) {
      const { label, id } = data.createRequirementsPageDto;
      appStore.workspaceModel.requirementsPages.createRequirementsPage({ id, label }, false);
    }
  });

  socket.on("deleteRequirementsPage", (data: { workspaceId: string; id: string }) => {
    if (data.id && data.workspaceId === appStore.workspaceModel?.id) {
      const page = appStore.workspaceModel.requirementsPages.get(data.id);
      if (page) {
        appStore.workspaceModel.deleteRequirementsPage(page.id, false);
      }
    }
  });

  socket.on("updateRequirementsPage", (data: { workspaceId: string; id: string; updateRequirementsPageDto: RequirementsPageUpdateDto }) => {
    if (data.id && data.workspaceId === appStore.workspaceModel?.id) {
      const page = appStore.workspaceModel.requirementsPages.get(data.id);
      page?.patch(data.updateRequirementsPageDto);
    }
  });
}

export function subscribeToRequirementBlockEvents(socket: Socket) {
  socket.on("createRequirementBlock", (data: { workspaceId: string; createRequirementBlockDto: CreateRequirementBlockDto }) => {
    if (data.createRequirementBlockDto?.id && data.workspaceId === appStore.workspaceModel?.id) {
      const parentPage = appStore.workspaceModel.requirementsPages.get(data.createRequirementBlockDto.parentPage);
      if (parentPage) {
        const { label, type, id } = data.createRequirementBlockDto;
        appStore.workspaceModel.addRequirementsBlock(parentPage, type as RequirementBlockType, label, undefined, id, false);
      }
    }
  });

  socket.on("deleteRequirementBlock", (data: { workspaceId: string; id: string }) => {
    if (data.id && data.workspaceId === appStore.workspaceModel?.id) {
      const property = appStore.workspaceModel.requirementBlockMap.get(data.id);
      if (property) {
        appStore.workspaceModel.deleteRequirementBlock(property, false);
      }
    }
  });

  socket.on("updateRequirementBlock", (data: { workspaceId: string; id: string; updateRequirementBlockDto: RequirementBlockUpdateDto }) => {
    if (data.id && data.workspaceId === appStore.workspaceModel?.id) {
      const requirementBlock = appStore.workspaceModel.requirementBlockMap.get(data.id);
      requirementBlock?.patch(data.updateRequirementBlockDto);
    }
  });

  socket.on(
    "reorderRequirementBlock",
    (data: { workspaceId: string; id: string; reorderRequirementBlockDto: { destinationId: string } }) => {
      if (data.id && data.reorderRequirementBlockDto?.destinationId && data.workspaceId === appStore.workspaceModel?.id) {
        const requirementBlock = appStore.workspaceModel.requirementBlockMap.get(data.id);
        requirementBlock?.parentPage?.moveBlock(data.id, data.reorderRequirementBlockDto.destinationId, false);
      }
    }
  );

  socket.on("lockRequirementBlock", (data: { workspaceId: string; id: string }) => {
    if (data.id && data.workspaceId === appStore.workspaceModel?.id) {
      const instance = appStore.workspaceModel.requirementBlockMap.get(data.id);
      instance?.setLocked(true, true);
    }
  });

  socket.on("unlockRequirementBlock", (data: { workspaceId: string; id: string }) => {
    if (data.id && data.workspaceId === appStore.workspaceModel?.id) {
      const instance = appStore.workspaceModel.requirementBlockMap.get(data.id);
      instance?.setLocked(false, true);
    }
  });
}

export interface IRequirementBlock extends Instance<typeof RequirementBlockStore> {}
interface IRequirementBlockSnapshotIn extends SnapshotIn<typeof RequirementBlockStore> {}
interface IRequirementBlockSnapshotOut extends SnapshotOut<typeof RequirementBlockStore> {}
export interface IRequirementBlockMobxType extends IType<IRequirementBlockSnapshotIn, IRequirementBlockSnapshotOut, IRequirementBlock> {}

export interface IRequirementsPage extends Instance<typeof RequirementsPageStore> {}
export interface IRequirementsPageSnapshotIn extends SnapshotIn<typeof RequirementsPageStore> {}
interface IRequirementsPageSnapshotOut extends SnapshotOut<typeof RequirementsPageStore> {}
export interface IRequirementsPageMobxType extends IType<IRequirementsPageSnapshotIn, IRequirementsPageSnapshotOut, IRequirementsPage> {}
