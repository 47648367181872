import { forwardRef, Fragment, MouseEvent, useEffect, useImperativeHandle, useState } from "react";
import { Menu, MenuDivider } from "@blueprintjs/core";
import { SuggestionProps } from "@tiptap/suggestion";
import groupBy from "lodash/groupBy";

import { MenuItem } from "@components/MenuItem";
import { IPropertyListItem } from "@components/Shared/ScalarExpressionEditor/PropertyListTypes";
import appStore from "@store/AppStore";
import { formatStringFromMathResult, getPropertyInstanceById } from "@utilities";

import { PropertyTagType } from "./tags/PropertyTag";
import PropertyListItem from "./PropertyListItem";
import { propertyTagTypeToEntityTypeMap } from "./utils";

import "./PropertyList.scss";

export interface PropertyListProps extends Pick<SuggestionProps<IPropertyListItem>, "items" | "command" | "editor" | "range"> {
  onDataSourceSelect(dataSourceId: string): void;
}

export type PropertyListRef = {
  onKeyDown: (props: { event: KeyboardEvent }) => boolean;
};

const PropertyMenuTitle = ({ label, pathText }: { label: string; pathText: string }) => {
  return (
    <>
      <div className="property-menu-item--title">{label}</div>
      <div className="property-menu-item--subtitle">{pathText}</div>
    </>
  );
};

function getTitleFromType(type: PropertyTagType): string {
  switch (type) {
    case PropertyTagType.AnalysisOutput:
      return "Code outputs";
    case PropertyTagType.DataLink:
      return "Data sources";
    case PropertyTagType.DataSinkEntry:
      return "Data sinks";
    case PropertyTagType.PropertyInstance:
      return "Properties";
  }
  return "Unknown";
}

const PropertyList = forwardRef<PropertyListRef, PropertyListProps>((props, ref) => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const { items, onDataSourceSelect } = props;
  const selectedItem = props.items.at(selectedIndex);

  // Reset selected item every time the query changes
  useEffect(() => setSelectedIndex(0), [props.items]);

  const handleItemSelection = (item: IPropertyListItem) => {
    switch (item.type) {
      case PropertyTagType.DataLink:
        onDataSourceSelect(item.id);
        break;
      default:
        props.command(item);
    }
    const entityType = propertyTagTypeToEntityTypeMap[item.type];
    if (entityType) {
      appStore.env.addRecentEntity({ id: item.id, type: entityType });
    }
  };

  useImperativeHandle(ref, () => ({
    onKeyDown: ({ event }) => {
      if (event.key === "ArrowUp") {
        setSelectedIndex((selectedIndex + props.items.length - 1) % props.items.length);
        return true;
      }

      if (event.key === "ArrowDown") {
        setSelectedIndex((selectedIndex + 1) % props.items.length);
        return true;
      }

      if (event.key === "Enter") {
        if (selectedItem) {
          handleItemSelection(selectedItem);
        }

        event.stopPropagation();
        return true;
      }

      event.stopPropagation();
      return false;
    },
  }));

  if (!props.items?.length) {
    return (
      <Menu>
        <MenuItem disabled text="No results found" e2eIdentifiers="no-result" />
      </Menu>
    );
  }

  const handleClick = (event: MouseEvent, item: IPropertyListItem) => {
    handleItemSelection(item);

    event.stopPropagation();
    event.preventDefault();
    return true;
  };

  const renderPropertyMentionItem = (item: IPropertyListItem) => {
    const { id, label } = item;
    const propertyInstance = getPropertyInstanceById(id);
    if (!propertyInstance) {
      return null;
    }
    const expValue = formatStringFromMathResult(propertyInstance.combinedResult, propertyInstance.propertyDefinition?.formatType);
    // Remove leading "/" from path if it exists
    const pathText = propertyInstance.parentBlock?.path?.replace("/", "") || "/";
    // TODO: show query list based on abs or relative path depending on query string

    return (
      <MenuItem
        className="property-menu-item"
        active={item === selectedItem}
        label={expValue}
        key={id}
        onMouseDown={e => handleClick(e, item)}
        text={<PropertyMenuTitle label={label} pathText={pathText} />}
        e2eIdentifiers={[label]}
      />
    );
  };

  const renderMentionItems = () => {
    const groupedItems = groupBy(items, item => item.type) as Record<PropertyTagType, IPropertyListItem[]>;
    const itemTypes = Object.keys(groupedItems) as PropertyTagType[];

    return itemTypes.map(type => {
      return (
        <Fragment key={type}>
          <MenuDivider key={type} title={getTitleFromType(type)} />
          {groupedItems[type].map(item => {
            if (type === PropertyTagType.PropertyInstance) {
              return renderPropertyMentionItem(item);
            }
            return <PropertyListItem key={item.id} item={item} selectedItem={selectedItem} onClick={handleClick} />;
          })}
        </Fragment>
      );
    });
  };

  return <Menu className="property-list">{renderMentionItems()}</Menu>;
});
PropertyList.displayName = "PropertyList";

export default PropertyList;
