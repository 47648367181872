import { observer } from "mobx-react";

interface IEmojiContainerProps {
  emoji?: string;
  className?: string;
}

const EmojiContainer = (props: IEmojiContainerProps) => {
  const { emoji, className } = props;
  return <div className={className}>{emoji}</div>;
};

export default observer(EmojiContainer);
