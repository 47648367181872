import { Position, Tooltip } from "@blueprintjs/core";
import { observer } from "mobx-react";

import CreateAnnotationIconDark from "@assets/icons/hoopsIcons/dark/annotations_create_annotation_dark.svg?react";
import CreateAnnotationWithNotificationDotIconDark from "@assets/icons/hoopsIcons/dark/annotations_create_annotation_with_notification_dot_dark.svg?react";
import CreateAnnotationIconLight from "@assets/icons/hoopsIcons/light/annotations_create_annotation_light.svg?react";
import CreateAnnotationWithNotificationDotIconLight from "@assets/icons/hoopsIcons/light/annotations_create_annotation_with_notification_dot_light.svg?react";
import { Button } from "@components/Button";
import { LegacyCustomIcon } from "@components/Shared/LegacyCustomIcon/LegacyCustomIcon";
import appStore from "@store/AppStore";
import { CustomOperatorNames } from "@store/HoopsViewerStore";

import styles from "../HoopsMenu.module.scss";

type HoopsMenuNewAnnotationButtonProps = {
  viewer: Communicator.WebViewer;
};

const HoopsMenuAnnotationModeButton = ({ viewer }: HoopsMenuNewAnnotationButtonProps) => {
  const operatorId = appStore.env.attachmentViewer?.getCustomOperatorIdByName(CustomOperatorNames.AnnotationOperator);
  if (!operatorId) {
    return null;
  }

  const handleClick = () => {
    appStore.env.setShowSmartAnnotations(true);
    if (!appStore.env.attachmentViewer?.isAnnotationModeActive) {
      appStore.env.attachmentViewer?.activateAnnotationMode(viewer, operatorId);
    } else {
      appStore.env.attachmentViewer?.deactivateAnnotationMode(viewer);
    }
  };

  const annotationIcon = () => {
    // TODO: add notification dot icon logic if there are unread notifications
    const noNewAnnotations = true;
    if (appStore.env.themeIsDark) {
      if (noNewAnnotations) {
        return <CreateAnnotationIconDark />;
      } else {
        return <CreateAnnotationWithNotificationDotIconDark />;
      }
    } else {
      if (noNewAnnotations) {
        return <CreateAnnotationIconLight />;
      } else {
        return <CreateAnnotationWithNotificationDotIconLight />;
      }
    }
  };

  return (
    <Tooltip
      position={Position.RIGHT}
      hoverOpenDelay={500}
      content={
        appStore.env.attachmentViewer?.isExplodeActive ? "Annotations are disabled while exploded view is active" : "Create Annotation"
      }
    >
      <Button
        className={styles.hoopsToolbarButtonAndButtonGroup}
        onClick={handleClick}
        icon={<LegacyCustomIcon large icon={annotationIcon()} />}
        active={appStore.env.attachmentViewer?.isAnnotationModeActive}
        disabled={appStore.env.attachmentViewer?.isExplodeActive}
        e2eIdentifiers="create-annotation"
      />
    </Tooltip>
  );
};

export default observer(HoopsMenuAnnotationModeButton);
