import { observer } from "mobx-react";

import { TEditorBlockProps } from "@components/Reports/Editor/types";
import BulletListItem from "@components/Reports/Editor/Types/BulletListItem";
import Checkbox from "@components/Reports/Editor/Types/Checkbox";
import CodeEditor from "@components/Reports/Editor/Types/Code";
import DividerEditor from "@components/Reports/Editor/Types/Divider";
import HeadingsEditor from "@components/Reports/Editor/Types/Headings";
import ImageEditor from "@components/Reports/Editor/Types/Image";
import OrderListItem from "@components/Reports/Editor/Types/OrderListItem";
import QuoteEditor from "@components/Reports/Editor/Types/Quote";
import TableEditor from "@components/Reports/Editor/Types/Table";
import TextReportBlock from "@components/Reports/Editor/Types/TextReportBlock";
import { reportBlockToDocumentBlock } from "@components/Reports/ReportBlock/ReportBlock.utils";
import { RollupEditorType } from "@rollup-types/editor";
import appStore from "@store/AppStore";
import { IReportBlock } from "@store/ReportBlockStore";

import "./ReportEditor.scss";

interface TReportsEditorPropsTypes extends Omit<TEditorBlockProps, "documentBlock"> {
  reportBlock: IReportBlock;
}

const ReportEditor = (props: TReportsEditorPropsTypes) => {
  const { reportBlock, onEditorReady, editable = true, onUpdate } = props;
  const { eventHandlers } = props;

  const editorProps: TEditorBlockProps = {
    editable,
    onEditorReady,
    onUpdate,
    documentBlock: reportBlockToDocumentBlock(reportBlock),
    eventHandlers,
  };

  // Note: when we decouple the editors below that still depend on reportBlock, we can
  // replace this component by DocumentEditor instead
  switch (reportBlock.type) {
    case RollupEditorType.table:
      return (
        <TableEditor key={reportBlock.id} {...editorProps} reportBlock={reportBlock} reportBlocks={appStore.workspaceModel?.reportBlocks} />
      );
    case RollupEditorType.quote:
      return <QuoteEditor key={reportBlock.id} {...editorProps} />;
    case RollupEditorType.image:
      return <ImageEditor key={reportBlock.id} {...editorProps} />;
    case RollupEditorType.checklist:
      return <Checkbox key={reportBlock.id} {...editorProps} />;
    case RollupEditorType.ul:
      return <BulletListItem key={reportBlock.id} {...editorProps} />;
    case RollupEditorType.ol:
      return <OrderListItem key={reportBlock.id} {...editorProps} reportBlock={reportBlock} />;
    case RollupEditorType.code:
      return (
        <CodeEditor key={reportBlock.id} {...editorProps} reportBlock={reportBlock} reportBlocks={appStore.workspaceModel?.reportBlocks} />
      );
    case RollupEditorType.p:
      return <TextReportBlock key={reportBlock.id} {...editorProps} />;
    case RollupEditorType.hr:
      return <DividerEditor key={reportBlock.id} {...editorProps} />;
    default:
      return <HeadingsEditor key={reportBlock.id} {...editorProps} />;
  }
};

export default observer(ReportEditor);
