import { ChangeEvent, useEffect, useState } from "react";
import { Checkbox, Icon, InputGroup, Intent, Label, Radio } from "@blueprintjs/core";
import { Text, TextVariant } from "@ui/Text";
import { observer } from "mobx-react";
import { v4 as uuidv4 } from "uuid";

import { Button } from "@components/Button";
import { DialogLegacy } from "@components/Dialog";
import { Tooltip } from "@components/Tooltip";
import { RevisionCodeType, SemanticRevisionCodeType } from "@rollup-api/models/organizationSettings/organizationSettings.model";
import appStore from "@store/AppStore";

import styles from "./CreateNewCatalogItemVersionDialog.module.scss";

const CreateNewCatalogItemVersionDialog = () => {
  const showModal = appStore.ui.showPdmNewVersionDialog;
  const pdmItemVersionProgress = appStore.ui.pdmItemVersionProgress;
  const [addRevision, setAddRevision] = useState(false);
  const [revisionCode, setRevisionCode] = useState<string>();
  const [revisionName, setRevisionName] = useState("");
  const [revisionPatchType, setRevisionPatchType] = useState(SemanticRevisionCodeType.Patch);
  const catalogItem = pdmItemVersionProgress?.catalogItemId
    ? appStore.orgModel.catalogItems.getCatalogItem(pdmItemVersionProgress.catalogItemId)
    : null;
  const nextRevisionCode = catalogItem?.latestVersion?.nextRevisionCode(revisionPatchType);

  useEffect(() => {
    setRevisionCode(addRevision ? nextRevisionCode : undefined);
  }, [nextRevisionCode, addRevision]);

  if (!showModal || !catalogItem || !pdmItemVersionProgress) {
    return null;
  }

  const handleDiscardChanges = () => {
    appStore.ui.updatePdmItemVersionProgress(null);
    appStore.ui.hidePdmNewVersionDialog();
    setAddRevision(false);
    setRevisionName("");
  };

  const handleConfirm = () => {
    appStore.ui.hidePdmNewVersionDialog();
    appStore.orgModel.catalogItems.createCatalogItemVersion({
      id: uuidv4(),
      ...pdmItemVersionProgress,
      ...(addRevision && {
        revisionCode,
        revisionName,
      }),
    });
    handleDiscardChanges();
  };

  const handleSetComment = (e: ChangeEvent<HTMLInputElement>) => {
    appStore.ui.updatePdmItemVersionProgress({
      ...pdmItemVersionProgress,
      comment: e.target.value,
    });
  };

  const handleChangeRevisionName = (e: ChangeEvent<HTMLInputElement>) => {
    setRevisionName(e.target.value);
  };

  return (
    <DialogLegacy
      isOpen
      title={`Create new ${catalogItem.name} version from row changes`}
      onClose={appStore.ui.hidePdmNewVersionDialog}
      lazy
      canEscapeKeyClose
    >
      <div className={styles.createNewCatalogItemVersionDialogBody}>
        <Label>
          <Text variant={TextVariant.Caption}>Comment</Text>
          <InputGroup value={pdmItemVersionProgress.comment ?? ""} onChange={handleSetComment} />
        </Label>
        <Checkbox label="Add revision" checked={addRevision} onChange={e => setAddRevision(e.target.checked)} />
        {addRevision && (
          <>
            <div>
              <div className={styles.createNewCatalogItemVersionDialogCode}>
                <Text variant={TextVariant.Caption}>Revision code: </Text>
                {nextRevisionCode}
                <Tooltip
                  className={styles.createNewCatalogItemVersionDialogTooltip}
                  content="This is incremented on every new version. It's cannot be changed"
                >
                  <Icon icon="info-sign" size={12} />
                </Tooltip>
              </div>
              {appStore.orgModel.catalogItems.catalogItemRevisionCodeType === RevisionCodeType.Semantic && (
                <div className={styles.createNewCatalogItemVersionDialogPatchType}>
                  {Object.values(SemanticRevisionCodeType).map(revisionCodeType => (
                    <Radio
                      onChange={() => setRevisionPatchType(revisionCodeType)}
                      key={revisionCodeType}
                      value={revisionCodeType}
                      label={revisionCodeType}
                      checked={revisionPatchType === revisionCodeType}
                    />
                  ))}
                </div>
              )}
            </div>
            <Label>
              <Text variant={TextVariant.Caption}>Revision name</Text>
              <InputGroup value={revisionName} onChange={handleChangeRevisionName} />
            </Label>
          </>
        )}
      </div>
      <div className={styles.createNewCatalogItemVersionDialogFooter}>
        <Button onClick={handleDiscardChanges} intent={Intent.DANGER} e2eIdentifiers="btn-discard-new-version-dialog" large>
          Discard changes
        </Button>
        <Button onClick={appStore.ui.hidePdmNewVersionDialog} minimal outlined e2eIdentifiers="btn-cancel-new-version-dialog" large>
          Keep editing
        </Button>
        <Button intent={Intent.PRIMARY} onClick={handleConfirm} e2eIdentifiers="btn-confirm-new-version-dialog" large>
          Create
        </Button>
      </div>
    </DialogLegacy>
  );
};

export default observer(CreateNewCatalogItemVersionDialog);
