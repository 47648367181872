import { useEffect, useState } from "react";
import { DialogStep, MultistepDialog } from "@blueprintjs/core";
import { useWorkspace } from "@hooks/useWorkspace";
import omitBy from "lodash/omitBy";
import { observer } from "mobx-react";

import { ECsvImportColumn, ImportExtensionType, ImportType, TCsvImportColumnsMap } from "@rollup-api/models/import";
import appStore from "@store/AppStore";

import { defaultColumnsMap } from "./constants";
import FilePanel from "./FilePanel";
import HeadersPanel from "./HeadersPanel";
import TargetBlockPanel from "./TargetBlockPanel";
import TargetBomPanel from "./TargetBomPanel";
import TypePanel from "./TypePanel";

import "./ImportModal.scss";

type Props = {
  setOpen: (open: boolean) => void;
  open: boolean;
};

enum EImportModalStep {
  Type,
  File,
  Headers,
  Target,
}

const ImportModal = (props: Props) => {
  const { setOpen, open } = props;
  const [step, setStep] = useState(EImportModalStep.Type);
  const [type, setType] = useState(ImportType.Block);
  const [file, setFile] = useState<File | null>(null);
  const [dryRun, setDryRun] = useState(true);
  const [columnMap, setColumnMap] = useState<TCsvImportColumnsMap>(defaultColumnsMap);
  const [entityId, setEntityId] = useState("");
  const isCsv = file?.type === "text/csv";
  const workspace = useWorkspace();
  const isHeadersValid = () => {
    if (isCsv && type === ImportType.Block) {
      return !!columnMap[ECsvImportColumn.Label];
    } else {
      return true;
    }
  };

  const isEntityValid = type === ImportType.Block ? !!entityId : true;
  const submitDisabled = !isEntityValid || !file || !isHeadersValid();

  useEffect(() => {
    setColumnMap(defaultColumnsMap);
  }, [file]);

  const nextButtonDisabled = () => {
    switch (step) {
      case EImportModalStep.File:
        return !file;
      case EImportModalStep.Target:
        return type === ImportType.Block ? !entityId : false;
      case EImportModalStep.Headers:
        return !columnMap[ECsvImportColumn.Label];
      default:
        return !type;
    }
  };

  const resetSteps = () => {
    setStep(EImportModalStep.Type);
    setColumnMap(defaultColumnsMap);
    setFile(null);
    setEntityId("");
  };

  const handleCloseModal = () => {
    setOpen(false);
    resetSteps();
  };

  const handleTypeChange = (type: ImportType) => {
    resetSteps();
    setType(type);
  };

  const handleSubmit = async () => {
    if (file) {
      const dataTransfer = new DataTransfer();
      dataTransfer.items.add(file);
      appStore.orgModel.uploads.addNewFileUpload({
        workspaceId: workspace.id,
        files: dataTransfer.files,
        removeOnUpload: true,
        blockId: type === ImportType.Block ? entityId : workspace.rootBlock.id,
        onUpload: attachmentId =>
          appStore.workspaceModel?.addImport(
            {
              attachmentId,
              originalFileName: file.name,
              type,
              dryRun,
              columnMap: JSON.stringify(omitBy(columnMap, v => !v)),
              ...(entityId && { entityId }),
            },
            isCsv ? ImportExtensionType.CSV : ImportExtensionType.CAD
          ),
      });
      handleCloseModal();
      appStore.ui.showUploadQueueWindow();
    }
  };

  const TargetPanel = type === ImportType.Block ? TargetBlockPanel : TargetBomPanel;

  return (
    <MultistepDialog
      className="import-modal"
      isOpen={open}
      navigationPosition="left"
      onChange={(step: EImportModalStep) => setStep(step)}
      onClose={handleCloseModal}
      nextButtonProps={{ disabled: nextButtonDisabled() }}
      finalButtonProps={{ onClick: handleSubmit, disabled: submitDisabled }}
      title="Create import"
    >
      <DialogStep
        id={EImportModalStep.Type}
        panel={<TypePanel setDryRun={setDryRun} dryRun={dryRun} setType={handleTypeChange} type={type} />}
        title="Select type"
      />
      <DialogStep id={EImportModalStep.File} panel={<FilePanel file={file} onChange={setFile} />} title="Upload file" />
      <DialogStep
        id={EImportModalStep.Target}
        panel={<TargetPanel entityId={entityId} onChange={setEntityId} />}
        title="Select destination"
      />
      {isCsv && (
        <DialogStep
          id={EImportModalStep.Headers}
          panel={<HeadersPanel importType={type} entityId={entityId} onChange={setColumnMap} columnsMap={columnMap} file={file} />}
          title="Map columns"
        />
      )}
    </MultistepDialog>
  );
};
export default observer(ImportModal);
