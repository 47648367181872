import { useCallback } from "react";
import { Icon, Position, Tooltip } from "@blueprintjs/core";
import { observer } from "mobx-react";

import { useAppNavigate } from "@router/hooks";
import { IBlock } from "@store/BlockStore";

interface IBlockTagProps {
  block: IBlock;
  className?: string;
  labelClassName?: string;
}

const BlockTag = ({ block, className, labelClassName }: IBlockTagProps) => {
  const { navigateToBlock } = useAppNavigate();

  const handleBlockClicked = useCallback(() => {
    if (block) {
      navigateToBlock(block.id);
    }
  }, [block, navigateToBlock]);

  return (
    <Tooltip hoverOpenDelay={500} placement={Position.BOTTOM} content={block.path}>
      <div className={className} onClick={handleBlockClicked}>
        <Icon icon="cube" />
        <span className={labelClassName}>{block.label}</span>
      </div>
    </Tooltip>
  );
};

export default observer(BlockTag);
