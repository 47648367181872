import { useState } from "react";
import { Icon, IconName } from "@blueprintjs/core";
import { observer } from "mobx-react";

import AssetImage from "@components/Shared/AssetImage/AssetImage";

import "./Image.scss";

type Props = {
  fallbackUrl?: string;
  fallbackIcon?: IconName;
  imageUrl: string;
  alt?: string;
  iconSize?: number;
  width?: string | number;
  height?: string | number;
};

const Image = (props: Props) => {
  const { alt, imageUrl, fallbackUrl, fallbackIcon = "media", iconSize = 40, width, height } = props;
  const [failedImage, setFailedImage] = useState(false);
  const [src, setSrc] = useState(imageUrl);

  const handleImageError = () => {
    if (fallbackUrl) {
      setFailedImage(false);
      setSrc(fallbackUrl);
    } else {
      setFailedImage(true);
    }
  };

  const renderImage = () => {
    return <AssetImage width={width} height={height} src={src} alt={alt || "image"} onError={handleImageError} />;
  };

  const renderFallBackIcon = () => {
    return <Icon className="image--fallback-icon" icon={fallbackIcon} size={iconSize} />;
  };

  return failedImage ? renderFallBackIcon() : renderImage();
};

export default observer(Image);
