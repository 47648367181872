import { ChangeEvent, KeyboardEvent, memo } from "react";
import { NumericInput } from "@blueprintjs/core";
import { CustomCellEditorProps } from "ag-grid-react";

import { ENTER_KEY, ESCAPE_KEY } from "@constants/keys";

import styles from "./PositiveDecimalCellEditor.module.scss";

const PositiveDecimalCellEditor = memo((cellEditor: CustomCellEditorProps) => {
  const { value, api, onValueChange } = cellEditor;

  const handleCommit = () => {
    api.stopEditing();
  };

  const handleCancel = () => {
    api.stopEditing(true);
  };

  const handleKeyUp = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === ENTER_KEY) {
      handleCommit();
    } else if (e.key === ESCAPE_KEY) {
      handleCancel();
    }
  };

  const handleValueChange = (event: ChangeEvent<HTMLInputElement>) => {
    const stringValue = event.target.value;
    const numericValue = Number(stringValue);
    const decimalsCount = stringValue.split(".")[1]?.length ?? 0;

    if (isNaN(numericValue) || numericValue < 0 || decimalsCount > 2) {
      return;
    }

    onValueChange(stringValue);
  };

  return (
    <NumericInput
      onKeyUp={handleKeyUp}
      onBlur={handleCommit}
      buttonPosition="none"
      className={styles.positiveDecimalCellEditor}
      value={value ?? ""}
      minorStepSize={0.01}
      min={0}
      selectAllOnFocus
      autoFocus
      onChange={handleValueChange}
    />
  );
});

PositiveDecimalCellEditor.displayName = "PositiveDecimalCellEditor";

export default PositiveDecimalCellEditor;
