import { ComponentProps } from "react";
import { Icon as BPIcon } from "@blueprintjs/core";
import classNames from "classnames";

import { Color } from "@rollup-types/colors";
import { getColorClassName } from "@utilities/Color";
import { createDataTestId, ElementType, IE2EIdentifiersParam } from "@utilities/E2EUtils";

import styles from "./BlueprintIcon.module.scss";

interface IBlueprintIconProps extends ComponentProps<typeof BPIcon>, Partial<IE2EIdentifiersParam> {
  active?: boolean;
  highlight?: boolean;
  outline?: boolean;
  color?: Color;
}

export const BlueprintIcon = ({ active, highlight, outline, color, e2eIdentifiers, ...p }: IBlueprintIconProps) => {
  const colorClassName = getColorClassName(color);
  return (
    <BPIcon
      {...p}
      className={classNames(styles.blueprintIcon, p.className, colorClassName, {
        [styles.blueprintIconActive]: active,
        [styles.blueprintIconHighlight]: highlight,
        [styles.blueprintIconOutline]: outline,
      })}
      {...(e2eIdentifiers && createDataTestId(ElementType.Icon, e2eIdentifiers))}
    />
  );
};
