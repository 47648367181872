import { CustomUnits } from "@rollup-api/api/modeling/customUnits";
import { DataSinkEntries } from "@rollup-api/api/modeling/dataSinkEntries";
import { DataSinks } from "@rollup-api/api/modeling/dataSinks";

import { HttpClient, ParentClient } from "../client";

import { Blocks } from "./blocks";
import { DataSources } from "./dataSources";
import { PropertyDefinitions } from "./propertyDefinitions";
import { PropertyInstances } from "./propertyInstances";

export class ModelingModule extends HttpClient {
  public readonly blocks: Blocks;
  public readonly customUnits: CustomUnits;
  public readonly dataSinks: DataSinks;
  public readonly dataSinkEntries: DataSinkEntries;
  public readonly dataSources: DataSources;
  public readonly propertyDefinitions: PropertyDefinitions;
  public readonly propertyInstances: PropertyInstances;

  public constructor(parent: ParentClient) {
    super(parent);
    this.blocks = new Blocks(parent);
    this.customUnits = new CustomUnits(parent);
    this.dataSinks = new DataSinks(parent);
    this.dataSinkEntries = new DataSinkEntries(parent);
    this.dataSources = new DataSources(parent);
    this.propertyDefinitions = new PropertyDefinitions(parent);
    this.propertyInstances = new PropertyInstances(parent);
  }
}
