import { useState } from "react";
import { Classes, Icon, IconName, NonIdealState, Spinner } from "@blueprintjs/core";
import { useSingleFileUpload } from "@hooks/useSingleFileUpload";
import classNames from "classnames";

import { Attachment } from "@rollup-api/models";

import FileDropZone from "../FileDropZone";
import { EFileFormat, TFileDropZoneChildProps } from "../FileDropZone/types";

import "./FileUpload.scss";

export enum EFileDisplayType {
  IMG = "image",
  FILE = "file",
}

export interface IFileUploadProps {
  format?: Array<EFileFormat>;
  displayType: EFileDisplayType;
  fileUrl?: string;
  width?: number;
  height?: number;
  blockId?: string;
  emptyStateIcon?: IconName;
  workspaceId?: string | null;
  emptyStateTitle?: string;
  onUploadStart?(): void;
  onUploadSuccess(link: string, file: Attachment): void;
}

const FileUpload = (props: IFileUploadProps) => {
  const { emptyStateIcon = "document", emptyStateTitle, format, width = "100%", height = "auto", fileUrl, workspaceId } = props;
  const { displayType, blockId, onUploadStart, onUploadSuccess } = props;
  const [file, setFile] = useState<File | null>(null);

  const handleUploadStart = (file: File) => {
    setFile(file);
    onUploadStart?.();
  };

  const { isLoading, handleFileUpload } = useSingleFileUpload({ blockId, workspaceId, onUploadStart: handleUploadStart, onUploadSuccess });

  const renderPreview = () => {
    return (
      <div>
        {displayType === EFileDisplayType.IMG ? <img src={fileUrl} alt="image" width="100%" /> : <div>{file?.name || "File name"}</div>}
      </div>
    );
  };

  const renderEmptyState = () => {
    return (
      <div className="file-upload--empty">
        <Icon icon={emptyStateIcon} size={16} />
        {emptyStateTitle}
      </div>
    );
  };

  const renderContent = () => {
    return fileUrl ? renderPreview() : renderEmptyState();
  };

  const renderLoader = () => {
    return <NonIdealState icon={<Spinner size={30} />} />;
  };

  return (
    <div>
      <FileDropZone dropIconSize={20} clickToSelect format={format} onChange={handleFileUpload}>
        {({ isOver }: TFileDropZoneChildProps) => (
          <>
            <div style={{ width, height }} className={classNames(Classes.CARD, "file-upload", { "file-upload--active": isOver })}>
              {isLoading ? renderLoader() : renderContent()}
            </div>
          </>
        )}
      </FileDropZone>
    </div>
  );
};

export default FileUpload;
