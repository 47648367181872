import { useEffect, useState } from "react";
import { InputGroup, Position } from "@blueprintjs/core";
import { GridApi } from "ag-grid-community";
import { observer } from "mobx-react";

import PartNumberSchemaModal from "@components/PartNumberSchemaModal";
import { Popover } from "@components/Popover";
import PartNumberSchemaSelectorMenu from "@router/components/BlockView/PartNumberSchemaSelectorMenu";
import { IPartNumberSchema } from "@store/PartNumberSchemaStore";

import styles from "./PartNumber.module.scss";

type Props = {
  onAddNewSchema(label: string, schema: string, leadingZeroes: number): void;
  onUpdatePartNumber(partNumber: string): void;
  onSetPartNumber(partNumberSchema: IPartNumberSchema): void;
  onDeletePartNumberSchema(schemaId: string): void;
  partNumberSchemas: IPartNumberSchema[];
  value?: string | null;
  onValueChange(newValue: string): void;
  api: GridApi;
};

const PartNumber = (props: Props) => {
  const {
    onAddNewSchema,
    value = "",
    partNumberSchemas,
    onUpdatePartNumber,
    onSetPartNumber,
    onDeletePartNumberSchema,
    onValueChange,
    api,
  } = props;
  const [schemaToEdit, setSchemaToEdit] = useState<IPartNumberSchema | undefined>(undefined);
  const [partNumberValue, setPartNumberValue] = useState<string>(value || "");
  const [isPopoverOpen, setIsPopoverOpen] = useState(true);
  const [showIdModal, setShowIdModal] = useState(false);

  useEffect(() => {
    onValueChange(partNumberValue || "");
  }, [onValueChange, partNumberValue]);

  useEffect(() => {
    setPartNumberValue(value || "");
  }, [value]);

  useEffect(() => {
    if (!isPopoverOpen && !showIdModal) {
      api.stopEditing();
    }
  }, [isPopoverOpen, api, showIdModal]);

  const handleEditSchema = (partNumberSchema: IPartNumberSchema) => {
    setSchemaToEdit(partNumberSchema);
    setShowIdModal(true);
  };

  const handleCloseModal = () => {
    setSchemaToEdit(undefined);
    setShowIdModal(false);
    setIsPopoverOpen(true);
  };

  return (
    <div className={styles.partNumber}>
      <PartNumberSchemaModal onAddNew={onAddNewSchema} partNumberSchema={schemaToEdit} isOpen={showIdModal} onClose={handleCloseModal} />
      <Popover
        isOpen={isPopoverOpen}
        onInteraction={setIsPopoverOpen}
        targetTagName="div"
        enforceFocus={false}
        canEscapeKeyClose={false}
        autoFocus={false}
        position={Position.BOTTOM_LEFT}
        content={
          <PartNumberSchemaSelectorMenu
            hideNameInput
            onEdit={handleEditSchema}
            defaultPartNumber={value || ""}
            onUpdatePartNumber={onUpdatePartNumber}
            onSetPartNumber={onSetPartNumber}
            partNumberSchemas={partNumberSchemas}
            onDelete={onDeletePartNumberSchema}
            onAddNew={() => setShowIdModal(true)}
          />
        }
      >
        <InputGroup
          className={styles.partNumberInput}
          autoFocus
          value={partNumberValue}
          onChange={e => setPartNumberValue(e.target.value)}
        />
      </Popover>
    </div>
  );
};

export default observer(PartNumber);
