import { Annotation } from "@rollup-api/models";
import { AnnotationStore, IAnnotation } from "@store/AnnotationStore";
import appStore from "@store/AppStore";
import { convertTimestamp } from "@utilities/Date";
import { wrapAround } from "@utilities/MathUtils";
import { rollupClient } from "src/core/api";

export enum MoveDirection {
  Previous = -1,
  Stay = 0,
  Next = 1,
}

export function moveToAnnotation(currentId: string, direction: MoveDirection = MoveDirection.Stay) {
  if (!appStore.env.activeAttachment) {
    return;
  }

  const annotationsCount = appStore.env.activeAttachment.annotationList.annotationCount;
  const currentIndex = appStore.env.activeAttachment.annotationList.annotations.findIndex((a: IAnnotation) => a.id === currentId);
  const newIndex = wrapAround(currentIndex + direction, 0, annotationsCount - 1);
  const newAnnotation = appStore.env.activeAttachment.annotationList.annotations[newIndex];

  if (newAnnotation.id !== appStore.env.activeAttachment.annotationList.expandedAnnotationId) {
    appStore.env.activeAttachment.annotationList.setAllAnnotationsExpanded(false);
  }

  appStore.env.activeAttachment.annotationList.setActiveAnnotationId(newAnnotation.id);
}

export const getAnnotationById = async (annotationId: string): Promise<IAnnotation | undefined> => {
  try {
    const { data, status } = await rollupClient.annotations.retrieve(annotationId);
    if (status !== 200) {
      console.warn(`Error fetching annotation ${annotationId}`);
      return;
    }
    return AnnotationStore.create({
      ...data,
      createdAt: convertTimestamp(data.createdAt),
      updatedAt: convertTimestamp(data.updatedAt),
      locationData3D: data.locationData3D ? createLocationData3D(data.locationData3D, data.parentId) : undefined,
    });
  } catch (error) {
    console.warn(`Error fetching annotation ${annotationId}`, error);
    return;
  }
};

const createLocationData3D = (locationData3D: any, parentId: string) => {
  return {
    ...locationData3D,
    view: {
      ...locationData3D.view,
      cloudFileId: parentId,
      isolatedNodeId: locationData3D.view.isolatedNodeId || undefined,
    },
    recreated: true,
  };
};

export const createAnnotation = (annotationData: Annotation, parentId: string): IAnnotation => {
  return AnnotationStore.create({
    ...annotationData,
    createdAt: convertTimestamp(annotationData.createdAt),
    updatedAt: convertTimestamp(annotationData.updatedAt),
    locationData3D: annotationData.locationData3D ? createLocationData3D(annotationData.locationData3D, parentId) : undefined,
  });
};

export const create3DAnnotationScreenshot = async (
  annotation: IAnnotation,
  // annotation: IAnnotationMobxType,
  viewer: Communicator.WebViewer,
  screenPos: Communicator.Point3
) => {
  // create a screenshot of the whereabouts of the annotation
  const viewElement = viewer.getViewElement();

  try {
    const imgElement = await viewer.takeSnapshot({
      // save snapshot
      width: viewElement.offsetWidth,
      height: viewElement.offsetHeight,
      layers: Communicator.SnapshotLayer.All,
    });

    // Create a new image object
    const imageWidth = 500;
    const imageHeight = 400;
    const img = new Image();
    img.src = imgElement.src;
    img.onload = () => {
      // Create a canvas element
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");
      if (ctx) {
        // Set the canvas dimensions
        canvas.width = imageWidth;
        canvas.height = imageHeight;

        // Calculate the center point
        const annotationX = screenPos.x;
        const annotationY = screenPos.y;

        // Adjust the center point if it's too close to an edge
        const imageCenterX = Math.max(imageWidth / 2, Math.min(annotationX, img.width - imageWidth / 2));
        const imageCenterY = Math.max(imageHeight / 2, Math.min(annotationY, img.height - imageHeight / 2));

        // Calculate the new annotation X and Y based on the image with shifted center
        const newAnnotationX = annotationX - (imageCenterX - imageWidth / 2);
        const newAnnotationY = annotationY - (imageCenterY - imageHeight / 2);

        // Draw the image onto the canvas, cropping around the center point
        ctx.drawImage(
          img,
          imageCenterX - imageWidth / 2,
          imageCenterY - imageHeight / 2,
          imageWidth,
          imageHeight,
          0,
          0,
          imageWidth,
          imageHeight
        );

        // Draw a crosshair at the center point
        drawTarget(ctx, newAnnotationX, newAnnotationY);

        // Create a new canvas to hold the scaled image
        const scaledCanvas = document.createElement("canvas");
        scaledCanvas.width = imageWidth / 2;
        scaledCanvas.height = imageHeight / 2;
        const scaledCtx = scaledCanvas.getContext("2d");

        if (!scaledCtx) {
          throw new Error("Scaled context not found");
        }
        scaledCtx.drawImage(canvas, 0, 0, imageWidth / 2, imageHeight / 2);

        // Convert the canvas back to an image
        const croppedImageSrc = scaledCanvas.toDataURL("image/webp");

        annotation.setThumbnail(croppedImageSrc);

        if (annotation.posted) {
          rollupClient.annotations.setThumbnail(annotation.id, croppedImageSrc);
        }
      }
    };
  } catch (err: any) {
    console.error(err);
  }
};

// Function to draw a target
export const drawTarget = (context: CanvasRenderingContext2D, X: number, Y: number) => {
  const circleRadius = 10;
  // Draw a hollow circle at the tip of the arrow
  context.beginPath();
  context.arc(X, Y, circleRadius, 0, 2 * Math.PI, false);
  context.strokeStyle = "white";
  context.lineWidth = 3;
  context.stroke();
  // Outline the white circle with black
  context.beginPath();
  context.arc(X, Y, circleRadius + 2, 0, 2 * Math.PI, false);
  context.strokeStyle = "black";
  context.lineWidth = 2;
  context.stroke();
  // Draw a crosshair
  context.beginPath();
  context.moveTo(X - circleRadius * 2, Y);
  context.lineTo(X + circleRadius * 2, Y);
  context.moveTo(X, Y - circleRadius * 2);
  context.lineTo(X, Y + circleRadius * 2);
  context.strokeStyle = "black";
  context.lineWidth = 2;
  context.stroke();
};
