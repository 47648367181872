import { CustomCellEditorProps } from "ag-grid-react";
import { observer } from "mobx-react";

import appStore from "@store/AppStore";
import { StatusType } from "@store/StatusDefinitionStore";

import MentionsCellEditor from "./MentionsCellEditor";
import StatusTypeCellEditor from "./StatusTypeCellEditor";

const StatusPopupCellEditor = (props: CustomCellEditorProps) => {
  const statusDefinitionProxy = appStore.workspaceModel?.statusDefinitions.find(i => i.id === props.colDef?.colId);

  if (!statusDefinitionProxy) {
    return null;
  }

  const renderComponent = () => {
    switch (statusDefinitionProxy.type) {
      case StatusType.multiSelect:
      case StatusType.singleSelect:
        return <StatusTypeCellEditor projectStatusDefinition={statusDefinitionProxy} {...props} />;
      case StatusType.mention:
        return <MentionsCellEditor projectStatusDefinition={statusDefinitionProxy} {...props} />;
      default:
        return null;
    }
  };

  return renderComponent();
};

export default observer(StatusPopupCellEditor);
