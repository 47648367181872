import { Icon } from "@blueprintjs/core";
import classNames from "classnames";
import { observer } from "mobx-react";

import { Attachment } from "@rollup-api/models";
import Text, { TextVariant } from "src/ui/Text";

import { EFileFormat } from "../../FileDropZone/types";
import FileUpload from "../../FileUpload";
import { EFileDisplayType } from "../../FileUpload/FileUpload";

import styles from "../AttachmentPopup.module.scss";

type Props = {
  blockId?: string;
  workspaceId?: string | null;
  allowAllFormats?: boolean;
  onUploadStart?(): void;
  onUploadSuccess(link: string, file: Attachment): void;
};

const Upload = (props: Props) => {
  return (
    <div className={styles.attachmentPopupTab}>
      <div className={classNames(styles.attachmentPopupIcon, "flex")}>
        <div>
          <Icon size={14} className={styles.attachmentPopupInfo} icon="info-sign" />
        </div>
        <Text variant={TextVariant.Caption}>
          You can drag and drop attachments directly to this block or add them using the button below
        </Text>
      </div>
      <FileUpload
        workspaceId={props.workspaceId}
        blockId={props.blockId}
        height={160}
        displayType={EFileDisplayType.IMG}
        format={props.allowAllFormats ? undefined : [EFileFormat.JPEG, EFileFormat.JPG, EFileFormat.PNG]}
        onUploadStart={props.onUploadStart}
        onUploadSuccess={props.onUploadSuccess}
      />
    </div>
  );
};

export default observer(Upload);
