import { Intent } from "@blueprintjs/core";
import { UseMutationOptions } from "@tanstack/react-query";

import { showApiErrorToast, showToast } from "@components/UiLayers/toaster";

import { rollupClient } from "../core/api";

export abstract class SubscriptionQOFactory {
  public static createRequestTrialExtensionQO(): UseMutationOptions<unknown, Error, string, unknown> {
    return {
      mutationFn: reason => rollupClient.subscriptions.requestTrialExtension({ reason }).then(res => res.data),
      onSuccess: () => {
        showToast("Successfully requested a trial extension", Intent.SUCCESS);
      },
      onError: err => {
        const errorMsg = "Error requesting trial extension";
        console.warn(errorMsg, err);
        showApiErrorToast(errorMsg);
      },
    };
  }
}
