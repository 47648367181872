export function getFormDataFromObject(dto: object): FormData {
  const formData = new FormData();

  Object.entries(dto).forEach(([key, value]) => {
    if (value !== undefined && value !== null) {
      if (value instanceof File) {
        formData.append(key, value);
      } else {
        formData.append(key, `${value}`);
      }
    }
  });

  return formData;
}
