import { useState } from "react";
import { Position, Tooltip } from "@blueprintjs/core";
import { observer } from "mobx-react";

import OrthographicIconDark from "@assets/icons/hoopsIcons/dark/orthographic_view_dark.svg?react";
import PerspectiveIconDark from "@assets/icons/hoopsIcons/dark/perspective_view_dark.svg?react";
import OrthographicIconLight from "@assets/icons/hoopsIcons/light/orthographic_view_light.svg?react";
import PerspectiveIconLight from "@assets/icons/hoopsIcons/light/perspective_view_light.svg?react";
import { Button } from "@components/Button";
import { LegacyCustomIcon } from "@components/Shared/LegacyCustomIcon/LegacyCustomIcon";
import appStore from "@store/AppStore";

import styles from "../HoopsMenu.module.scss";

type HoopsMenuProjectionButtonProps = {
  viewer: Communicator.WebViewer;
};

const HoopsMenuProjectionButton = ({ viewer }: HoopsMenuProjectionButtonProps) => {
  const [projection, setProjection] = useState<Communicator.Projection>(viewer.view.getProjectionMode());

  const toggleProjection = () => {
    const newProjection =
      projection === Communicator.Projection.Orthographic ? Communicator.Projection.Perspective : Communicator.Projection.Orthographic;
    viewer.view.setProjectionMode(newProjection);
    setProjection(newProjection);
  };

  const projectionIcon = (
    <LegacyCustomIcon
      large
      icon={
        projection === Communicator.Projection.Orthographic ? (
          appStore.env.themeIsDark ? (
            <OrthographicIconDark />
          ) : (
            <OrthographicIconLight />
          )
        ) : appStore.env.themeIsDark ? (
          <PerspectiveIconDark />
        ) : (
          <PerspectiveIconLight />
        )
      }
    />
  );

  return (
    <Tooltip
      position={Position.TOP}
      hoverOpenDelay={500}
      content={`Projection: ${projection === Communicator.Projection.Orthographic ? "Orthographic" : "Perspective"}`}
    >
      <Button
        className={styles.hoopsToolbarButtonAndButtonGroup}
        icon={projectionIcon}
        onClick={toggleProjection}
        e2eIdentifiers="toggle-projection"
      />
    </Tooltip>
  );
};

export default observer(HoopsMenuProjectionButton);
