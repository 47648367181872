import { useEffect, useState } from "react";
import { Intent } from "@blueprintjs/core";
import { useWorkspace } from "@hooks/useWorkspace";
import { DataSinkEntryQOFactory } from "@queries/DataSinkEntryQOFactory";
import { DataSinkQOFactory } from "@queries/DataSinkQOFactory";
import { useMutation } from "@tanstack/react-query";
import { Text, TextVariant } from "@ui/Text";
import { observer } from "mobx-react";

import { Button } from "@components/Button";
import { DataSinkDrawerBody } from "@components/DataSources/AddDataSink/DataSinkDrawerBody";
import { DataSinkDrawerStore } from "@components/DataSources/AddDataSink/stores/DataSinkDrawerStore";
import { IDataSink } from "@store/DataConnection/DataSinkStore";

import styles from "./DataSinkDrawer.module.scss";

interface IDataSinkDrawerProps {
  editingDataSink?: IDataSink;
  onClose(): void;
}

const DataSinkDrawer = (props: IDataSinkDrawerProps) => {
  const { editingDataSink, onClose } = props;
  const workspace = useWorkspace();
  const [dataSinkStore, setDataSinkStore] = useState(() => new DataSinkDrawerStore(editingDataSink));
  const addDataSinkMutation = useMutation(DataSinkQOFactory.createPostQO(workspace.id));
  const patchDataSinkMutation = useMutation(DataSinkQOFactory.createPatchQO(workspace.id));
  const postDataSinkEntryMutation = useMutation(DataSinkEntryQOFactory.createPostQO(workspace.id));
  const deleteDataSinkEntryMutation = useMutation(DataSinkEntryQOFactory.createDeleteQO(workspace.id));
  const patchDataSinkEntryMutation = useMutation(DataSinkEntryQOFactory.createPatchQO(workspace.id));
  const isEditing = !!editingDataSink;

  useEffect(() => {
    setDataSinkStore(new DataSinkDrawerStore(editingDataSink));
  }, [editingDataSink]);

  const handleConfirm = async () => {
    if (isEditing) {
      if (dataSinkStore.isMetadataDirty) {
        patchDataSinkMutation.mutate({ id: editingDataSink.id, dto: dataSinkStore.updateDataSinkDto });
      }

      dataSinkStore.deletedEntries.forEach(entry => deleteDataSinkEntryMutation.mutate({ dataSinkId: editingDataSink.id, key: entry.key }));
      dataSinkStore.addedEntries.forEach(entry =>
        postDataSinkEntryMutation.mutate({ dataSinkId: editingDataSink.id, dto: entry.createDto })
      );
      dataSinkStore.updatedEntries.forEach(entry => {
        patchDataSinkEntryMutation.mutate({
          dataSinkId: editingDataSink.id,
          key: entry.key,
          dto: entry.updateDto,
        });
      });
    } else {
      addDataSinkMutation.mutate(dataSinkStore.createDataSinkDto, {
        onSuccess: () => {
          setDataSinkStore(new DataSinkDrawerStore());
        },
      });
    }
    onClose();
  };

  return (
    <div className={styles.dataSinkDrawer}>
      <div className={styles.dataSinkDrawerHeader}>
        <Text variant={TextVariant.H4}>{isEditing ? "Edit" : "Create"} Data Sink</Text>
        <Button icon="cross" onClick={onClose} e2eIdentifiers="close-add-data-sink" minimal />
      </div>
      <div className={styles.dataSinkDrawerBody}>
        <DataSinkDrawerBody dataSinkStore={dataSinkStore} />
      </div>
      <div className={styles.dataSinkDrawerFooter}>
        <Button onClick={onClose} e2eIdentifiers="cancel-add-data-sink" minimal outlined large>
          Cancel
        </Button>
        <Button
          onClick={handleConfirm}
          e2eIdentifiers="add-data-sink"
          intent={Intent.PRIMARY}
          disabled={(isEditing && !dataSinkStore.isDirty) || !dataSinkStore.hasFilledInRequiredFields}
          large
        >
          {isEditing ? "Update" : "Create"}
        </Button>
      </div>
    </div>
  );
};

export default observer(DataSinkDrawer);
