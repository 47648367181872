import { EntityModelBase } from "../../shared/entityModelBase";

// More info: https://mathjs.org/docs/datatypes/units.html#userdefined-units
export enum MathJsPrefix {
  None = "none",
  Short = "short",
  Long = "long",
  BinaryShort = "binary_short",
  BinaryLong = "binary_long",
}

export class CustomUnit extends EntityModelBase {
  public readonly label!: string;
  public readonly aliases!: string[];
  public readonly prefix!: MathJsPrefix;
  public readonly offset?: string;
  public readonly definition?: string;
  public readonly createdBy!: string;
  public readonly updatedBy?: string;
}
