import { useEffect, useMemo, useState } from "react";
import { Checkbox, Icon, Intent, Tree, TreeNodeInfo } from "@blueprintjs/core";
import { observer } from "mobx-react";

import { Button } from "@components/Button";
import { CreateBlockDto } from "@rollup-api/models";
import appStore from "@store/AppStore";
import { IImport } from "@store/ImportStore";

import { DialogLegacy } from "../index";

import "./ReviewImportDialog.scss";

const BlockTree = Tree.ofType<CreateBlockDto>();

const ReviewImportDialog = () => {
  const importItem: IImport | undefined = appStore.workspaceModel?.importsMap.get(appStore.ui.reviewImportId);
  const blocksDto: CreateBlockDto[] = useMemo(() => importItem?.blocks || [], [importItem?.blocks]);
  const isOpen = !!appStore.ui.reviewImportId && !!importItem;
  const handleClose = () => appStore.ui.setReviewImportId("");
  const [selectedBlocks, setSelectedBlocks] = useState<string[]>([]);
  const [collapsedBlocks, setCollapsedBlocks] = useState<string[]>([]);
  const [nodesError, setNodesError] = useState(false);

  const getNodePathLength = (blockDto: CreateBlockDto): number => {
    const path = [blockDto];
    let parent = blockDto.parentBlock;
    while (parent) {
      const parentBlock = blocksDto.find(b => b.label === parent);
      if (parentBlock) {
        path.unshift(parentBlock);
        parent = parentBlock.parentBlock;
      } else {
        parent = undefined;
      }
    }
    return path.length;
  };

  const toggleSelection = (node: TreeNodeInfo<CreateBlockDto>, recursive = false) => {
    const id = `${node.id}`;
    const childBlocks = node.childNodes?.map(n => `${n.id}`) || [];
    if (selectedBlocks.includes(id)) {
      if (recursive) {
        setSelectedBlocks(selectedBlocks.filter(i => !childBlocks.includes(i) && i !== id));
      } else {
        setSelectedBlocks(selectedBlocks.filter(i => i !== id));
      }
    } else {
      if (recursive) {
        setSelectedBlocks([...selectedBlocks, id, ...childBlocks]);
      } else {
        setSelectedBlocks([...selectedBlocks, id]);
      }
    }
  };

  useEffect(() => {
    if (blocksDto.length) {
      const blockIds = blocksDto.map((b, i) => `${b.label}${i}`);
      setSelectedBlocks(blockIds);
    }
  }, [blocksDto]);

  const handleDiscard = () => {
    handleClose();
    importItem?.id && appStore.workspaceModel?.deleteImport(importItem?.id);
    if (!appStore.workspaceModel?.unsavedImports.length) {
      appStore.ui.hideUploadQueueWindow();
    }
  };

  const handleConfirm = () => {
    if (!selectedBlocks.length) {
      setNodesError(true);
      return;
    }

    setNodesError(false);
    const blocksToAdd = blocksDto.filter((b, i) => selectedBlocks.includes(`${b.label}${i}`));
    importItem?.finish(blocksToAdd, appStore.workspaceModel?.id);
    handleClose();
  };

  const handleNodeClicked = (node: TreeNodeInfo<CreateBlockDto>, _path: number[], ev: React.MouseEvent<HTMLElement>) => {
    toggleSelection(node, ev.shiftKey || ev.ctrlKey || ev.metaKey);
    if (nodesError) {
      setNodesError(false);
    }
  };

  const toggleExpand = (e: React.MouseEvent<HTMLElement>, blockDto: CreateBlockDto) => {
    e.stopPropagation();
    const id = `${blockDto.label}${blocksDto.indexOf(blockDto)}`;

    if (collapsedBlocks.includes(id)) {
      setCollapsedBlocks(collapsedBlocks.filter(bId => bId !== id));
    } else {
      setCollapsedBlocks([...collapsedBlocks, id]);
    }
  };

  const renderLabel = (blockDto: CreateBlockDto, hasChild: boolean) => {
    const path = getNodePathLength(blockDto) - 1;
    const id = `${blockDto.label}${blocksDto.indexOf(blockDto)}`;
    const expanded = !collapsedBlocks.includes(id);
    const paddingLeft = path * 42 - (hasChild ? 30 : 0);

    return (
      <div className="review-import-dialog--item-wrap">
        <Checkbox checked={selectedBlocks.includes(id)} large />
        <div className="review-import-dialog--item" style={{ paddingLeft }}>
          {hasChild && (
            <Button
              onClick={e => toggleExpand(e, blockDto)}
              small
              minimal
              icon={expanded ? "caret-down" : "caret-right"}
              e2eIdentifiers={["review-import-dialog", "toggle-expand"]}
            />
          )}
          <Icon icon="cube" />
          {blockDto.label}
        </div>
      </div>
    );
  };

  const convertToTreeNode = (blockDto: CreateBlockDto) => {
    const id = `${blockDto.label}${blocksDto.indexOf(blockDto)}`;
    const childBlocks = blocksDto.filter(b => b.parentBlock === blockDto.label);

    const node: TreeNodeInfo<CreateBlockDto> = {
      id,
      isExpanded: !collapsedBlocks.includes(id),
      hasCaret: false,
      isSelected: selectedBlocks?.includes(id),
      label: renderLabel(
        blockDto,
        blocksDto.some(b => b.parentBlock === blockDto.label)
      ),
      nodeData: blockDto,
      childNodes: childBlocks.map(convertToTreeNode),
    };

    return node;
  };

  const getBlocks = (): TreeNodeInfo<CreateBlockDto>[] => {
    if (!isOpen) {
      return [];
    }

    const blocks: TreeNodeInfo<CreateBlockDto>[] = [];

    blocksDto.forEach(b => {
      !b.parentBlock && blocks.push(convertToTreeNode(b));
    });

    return blocks;
  };

  return (
    <DialogLegacy className="review-import-dialog" isOpen={isOpen} title="Confirm import" onClose={handleClose}>
      <div className="review-import-dialog--body">
        <span className="review-import-dialog--label">Select Blocks to import</span>
        {nodesError && <span className="review-import-dialog--error">Please select at least one block</span>}
        <BlockTree className="review-import-dialog--tree" contents={getBlocks()} onNodeClick={handleNodeClicked} />
      </div>
      <div className="review-import-dialog--footer">
        <Button large intent={Intent.DANGER} onClick={handleDiscard} e2eIdentifiers="discard">
          Discard
        </Button>
        <Button disabled={!selectedBlocks.length} large intent={Intent.PRIMARY} onClick={handleConfirm} e2eIdentifiers="confirm">
          Confirm
        </Button>
      </div>
    </DialogLegacy>
  );
};

export default observer(ReviewImportDialog);
