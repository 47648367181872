import { FormEvent, useCallback, useState } from "react";
import { Radio, RadioGroup } from "@blueprintjs/core";
import { useWorkspace } from "@hooks/useWorkspace";
import { GoogleQOFactory } from "@queries/GoogleQOFactory";
import { useQuery } from "@tanstack/react-query";
import { BlueprintIcon } from "@ui/BlueprintIcon";
import { observer } from "mobx-react";

import { GoogleSheetsIcon } from "@assets/icons/thirdParty";
import { Loading } from "@components/Loading";
import { MenuItem } from "@components/MenuItem";
import AsyncFilterableMenu from "@components/Shared/AsyncFilterableMenu/AsyncFilterableMenu";
import { GoogleIntegrationItem } from "@rollup-api/models";

import "./GoogleSheetsCustomPanel.scss";

interface IGoogleSheetsCustomPanelProps {
  activeStep: boolean;
  setSelectedSheet(value?: GoogleIntegrationItem): void;
}

const INITIAL_PAGE_SIZE = 5;
const QUERY_PAGE_SIZE = 20;

const GoogleSheetsCustomPanel = (props: IGoogleSheetsCustomPanelProps) => {
  const { activeStep, setSelectedSheet } = props;
  const { id: workspaceId } = useWorkspace();
  const [queriedSheets, setQueriedSheets] = useState<GoogleIntegrationItem[]>([]);
  const { data: initialSheets = [], isLoading } = useQuery(GoogleQOFactory.createGetSheetsQO({ workspaceId, pageSize: INITIAL_PAGE_SIZE }));
  const [selectedRadio, setSelectedRadio] = useState("");
  const allSheets = [...queriedSheets, ...initialSheets];

  const handleRadioChange = (e: FormEvent<HTMLInputElement>) => {
    const sheetId = e.currentTarget.value;
    setSelectedRadio(sheetId);
    const sheet = allSheets.find(s => s.data.id === sheetId);
    if (sheet) {
      setSelectedSheet(sheet);
    }
  };

  const getQueryObject = useCallback(
    (query: string) => {
      return GoogleQOFactory.createGetSheetsQO({ workspaceId, query, pageSize: QUERY_PAGE_SIZE, enabled: !!query });
    },
    [workspaceId]
  );

  if (isLoading) {
    return <Loading className="google-sheets-custom-panel--loading" />;
  }

  const handleMenuItemSelect = (sheet: GoogleIntegrationItem) => {
    setSelectedRadio(sheet.data.id);
    setSelectedSheet(sheet);
    setQueriedSheets(sheets => {
      if ([...initialSheets, ...sheets].some(s => s.data.id === sheet.data.id)) {
        return sheets;
      }
      return [...sheets, sheet];
    });
  };

  const dropdownItemRenderer = (sheet: GoogleIntegrationItem) => {
    return (
      <MenuItem
        key={sheet.data.id}
        onClick={() => handleMenuItemSelect(sheet)}
        text={sheet.title}
        icon={<GoogleSheetsIcon />}
        e2eIdentifiers={`select-sheet-${sheet.data.id}`}
      />
    );
  };

  return (
    <div className="google-sheets-custom-panel">
      <div className="google-sheets-custom-panel--content">
        <AsyncFilterableMenu<GoogleIntegrationItem>
          className="google-sheets-custom-panel--filterable-menu"
          dropdownItemRenderer={dropdownItemRenderer}
          getQueryObject={getQueryObject}
          usePopover
        />
        <RadioGroup
          className="google-sheets-custom-panel--radio-group"
          onChange={handleRadioChange}
          selectedValue={selectedRadio}
          disabled={!activeStep}
        >
          {allSheets.map(sheet => (
            <Radio key={sheet.data.id} className="google-sheets-custom-panel--radio" label={sheet.title} value={sheet.data.id} />
          ))}
        </RadioGroup>
      </div>
      {activeStep && (
        <div className="google-sheets-custom-panel--info-msg">
          <BlueprintIcon icon="small-info-sign" />
          <div>
            <b>Note:</b> you can not change this data after confirmation, but you can always create a new data source.
          </div>
        </div>
      )}
    </div>
  );
};

export default observer(GoogleSheetsCustomPanel);
