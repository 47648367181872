import { Intent } from "@blueprintjs/core";
import { UseMutationOptions, UseQueryOptions } from "@tanstack/react-query";

import { showApiErrorToast, showToast } from "@components/UiLayers/toaster";
import { DataSourcesValueResult } from "@rollup-api/api/modeling";
import { DataSource } from "@rollup-api/models/data-sources/data-source.model";
import { CreateDataSourceDto, UpdateDataSourceDto } from "@rollup-api/models/data-sources/data-sources.dto";
import appStore from "@store/AppStore";

import { QueryKeys, rollupClient } from "../core/api";
import { queryClient } from "../lib/ReactQuery/queryClient";

interface IGetDataSourcesOptions extends Omit<UseQueryOptions<DataSource[]>, "queryKey" | "queryFn"> {}
interface IDeleteDataSourcesOptions extends Omit<UseMutationOptions<DataSource, unknown, string>, "mutationFn"> {}
interface IPostDataSourcesOptions extends Omit<UseMutationOptions<DataSource, unknown, CreateDataSourceDto>, "mutationFn"> {}

/**
 * A factory for creating Data Source Query Objects
 */
export abstract class DataSourceQOFactory {
  public static createGetQO(workspaceId?: string, options: IGetDataSourcesOptions = {}): UseQueryOptions<DataSource[]> {
    return {
      queryKey: [QueryKeys.DataSourceList, workspaceId],
      queryFn: () => rollupClient.modelingModule.dataSources.getAll().then(res => res.data),
      ...options,
    };
  }

  public static createDeleteQO(
    id: string,
    workspaceId: string,
    options: IDeleteDataSourcesOptions = {}
  ): UseMutationOptions<DataSource, Error, string> {
    const queryKey = [QueryKeys.DataSourceList, workspaceId];
    return {
      mutationFn: () => rollupClient.modelingModule.dataSources.delete(id).then(res => res.data),
      onSuccess: (_, id) => {
        showToast("Successfully deleted data source", Intent.SUCCESS);
        appStore.workspaceModel?.removeDataSource(id);
        queryClient.setQueryData<DataSource[]>(queryKey, prev => prev?.filter(d => d.id !== id));
      },
      onError: err => {
        const errorMsg = "Error deleting data source";
        console.warn(errorMsg, err);
        showApiErrorToast(errorMsg);
      },
      ...options,
    };
  }

  public static createPostQO(
    workspaceId: string,
    options: IPostDataSourcesOptions = {}
  ): UseMutationOptions<DataSource, Error, CreateDataSourceDto> {
    return {
      mutationFn: dto => rollupClient.modelingModule.dataSources.create(dto).then(res => res.data),
      onSuccess: (data, variables, context) => {
        appStore.workspaceModel?.addOrUpdateDataSource(data);
        showToast("Successfully created data source", Intent.SUCCESS);
        queryClient.invalidateQueries({ queryKey: [QueryKeys.DataSourceList, workspaceId] });
        options?.onSuccess?.(data, variables, context);
      },
      onError: err => {
        const errorMsg = "Error adding data source";
        console.warn(errorMsg, err);
        showApiErrorToast(errorMsg);
      },
    };
  }

  public static createPatchQO(id: string, workspaceId: string): UseMutationOptions<DataSource, Error, UpdateDataSourceDto> {
    return {
      mutationFn: dto => rollupClient.modelingModule.dataSources.update(id, dto).then(res => res.data),
      onSuccess: data => {
        appStore.workspaceModel?.addOrUpdateDataSource(data);
        showToast("Successfully updated data source", Intent.SUCCESS);
        queryClient.invalidateQueries({ queryKey: [QueryKeys.DataSourceList, workspaceId] });
      },
      onError: err => {
        const errorMsg = "Error updating data source";
        console.warn(errorMsg, err);
        showApiErrorToast(errorMsg);
      },
    };
  }

  public static createGetValueQO(id: string, queryString: string): UseQueryOptions<DataSourcesValueResult> {
    return {
      queryKey: [QueryKeys.DataSourceValueTest, id, queryString],
      enabled: !!queryString,
      queryFn: () => rollupClient.modelingModule.dataSources.getValue(id, queryString).then(res => res.data),
    };
  }
}
