import { IconName } from "@blueprintjs/core";
import { BlueprintIcons_16 } from "@blueprintjs/icons/lib/esm/generated/16px/blueprint-icons-16";

import { IBlueprintIcon, IconSource, IDevIcon, IIcon } from "@rollup-types/icons";

export const isBPIcon = (icon: any): icon is IconName => {
  return Object.values(BlueprintIcons_16).includes(icon);
};

export const isNewIconFormat = (icon: unknown): icon is IIcon => {
  return icon !== null && typeof icon === "object" && "source" in icon && "name" in icon;
};

export const isBlueprintIcon = (icon: IIcon): icon is IBlueprintIcon => {
  return icon.source === IconSource.Blueprint;
};

export const isDevIcon = (icon: IIcon): icon is IDevIcon => {
  return icon.source === IconSource.DevIcon;
};

export const isColorIcon = (icon: IIcon): icon is IBlueprintIcon | IDevIcon => {
  return isBlueprintIcon(icon) || isDevIcon(icon);
};

const coloredIconSources = [IconSource.Blueprint, IconSource.DevIcon];

export const isColorIconSource = (iconSource: IconSource): boolean => {
  return coloredIconSources.includes(iconSource);
};

export const createBpIcon = (icon: IconName): IBlueprintIcon => {
  return { source: IconSource.Blueprint, name: icon };
};

export const areIconsEqual = (icon1?: IIcon | null, icon2?: IIcon | null): boolean => {
  if (!icon1 || !icon2) {
    return icon1 === icon2;
  } else if (icon1.source !== icon2.source || icon1.name !== icon2.name) {
    return false;
  } else if (isColorIcon(icon1) && isColorIcon(icon2)) {
    return icon1.color === icon2.color;
  }
  return true;
};
