import { useEffect, useMemo, useState } from "react";
import { H3 } from "@blueprintjs/core";
import capitalize from "lodash/capitalize";
import { observer } from "mobx-react";

import { ListEntry } from "@components/Shared";
import UserInfo from "@components/UserInfo/UserInfo";
import { GoogleFileMetadata } from "@rollup-api/models/integrations";
import { IAttachment } from "@store/AttachmentStore";
import { formatDate } from "@utilities";
import { rollupClient } from "src/core/api";

import AttachmentDetailsDialog from "./AttachmentDetailsDialog";
import AttachmentDetailsImagePreview from "./AttachmentDetailsImagePreview";
import AttachmentDetailsLoading from "./AttachmentDetailsLoading";
import AttachmentDetailsPanel from "./AttachmentDetailsPanel";

interface IIntegrationAttachmentDetailsProps {
  attachment: IAttachment;
  isOpen: boolean;
  onClose: () => void;
}

const getFileType = (mimeType: string) => {
  const splitByDot = mimeType.split(".");
  return capitalize(splitByDot[splitByDot.length - 1]);
};

const AttachmentDetailsGoogle = (props: IIntegrationAttachmentDetailsProps) => {
  const [metadata, setMetadata] = useState<GoogleFileMetadata>();
  const { attachment, isOpen, onClose } = props;
  const { version } = attachment;
  const { fileId } = attachment.metadata ?? {};

  const previewUrl = useMemo(() => {
    if (fileId && version !== undefined) {
      return rollupClient.integrations.getFilePreviewUrl(fileId, version);
    }
  }, [fileId, version]);

  useEffect(() => {
    if (isOpen && !metadata && fileId) {
      rollupClient.integrations.fetchFileMetadata(fileId).then(res => setMetadata(res.data));
    }
  }, [isOpen, fileId, metadata]);

  const renderContent = (metadataParam: GoogleFileMetadata) => {
    const { lastModifyingUser } = metadataParam;
    return (
      <>
        <AttachmentDetailsImagePreview src={previewUrl} />
        <AttachmentDetailsPanel>
          <H3>Details</H3>
          <ListEntry label="File name" value={metadataParam.name} />
          <ListEntry label="Integration type" value="Google" />
          <ListEntry label="File type" value={getFileType(metadataParam.mimeType)} />
          <ListEntry label="Created at" value={formatDate(metadataParam.createdTime)} />
          <ListEntry label="Last modified at" value={formatDate(metadataParam.modifiedTime)} />
          {lastModifyingUser && (
            <div>
              <ListEntry
                label="Last modified by"
                value={<UserInfo avatarUrl={lastModifyingUser.photoLink} userName={lastModifyingUser.displayName} size="small" />}
              />
            </div>
          )}
        </AttachmentDetailsPanel>
      </>
    );
  };

  return (
    <AttachmentDetailsDialog label={attachment.label} isOpen={isOpen} onClose={onClose}>
      {metadata ? renderContent(metadata) : <AttachmentDetailsLoading />}
    </AttachmentDetailsDialog>
  );
};

export default observer(AttachmentDetailsGoogle);
