import { KeyboardEvent } from "react";
import { useEditor } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import { observer } from "mobx-react";

import { EditorContent } from "@components/EditorContent";
import { getDocumentEditorClassNames } from "@components/Reports/Editor/utils";

import PreventEnter from "../Extentions/PreventEnter";
import PreventModEnter from "../Extentions/PreventModEnter";
import { TEditorBlockProps } from "../types";

import "./Divider.scss";

const DividerReportBlock = (props: TEditorBlockProps) => {
  const { documentBlock, eventHandlers } = props;
  const { onEnterKeyDown, onBackspaceKeyDown } = eventHandlers;

  const editor = useEditor({
    extensions: [StarterKit, PreventEnter, PreventModEnter],
    editable: false,
  });

  const handleKeyDownEvents = (e: KeyboardEvent<HTMLDivElement>) => {
    onEnterKeyDown(e);
    onBackspaceKeyDown(e);
  };

  return (
    <div key={documentBlock.id} className="report-editor--divider">
      <EditorContent
        id={documentBlock.id}
        onKeyDown={handleKeyDownEvents}
        className={getDocumentEditorClassNames({
          annotationList: documentBlock.annotationList,
        })}
        editor={editor}
        e2eIdentifiers="report-editor"
      />
    </div>
  );
};

export default observer(DividerReportBlock);
