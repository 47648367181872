import { useCallback, useEffect } from "react";
import { useDebouncedCallback } from "@hooks/useDebouncedCallback";
import { useUpdateEditorContent } from "@hooks/useUpdateEditorContent";
import Link from "@tiptap/extension-link";
import { Underline } from "@tiptap/extension-underline";
import { Editor, EditorEvents, Extensions, useEditor } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";

import DashNavigationExtension from "@components/Reports/Editor/Extentions/DashNavigation";
import { getMentionExtensions } from "@components/Reports/Editor/Extentions/Mention/MentionSuggestion";
import PreventEnter from "@components/Reports/Editor/Extentions/PreventEnter";
import { useBubbleMenu } from "@components/Reports/Editor/hooks/useBubbleMenu";
import { useAppNavigate } from "@router/hooks";
import appStore from "@store/AppStore";

interface IUseReportEditorParams {
  blockId: string;
  activeBlockId?: string;
  content: string;
  editable?: boolean;
  extensions?: Extensions;
  disableDashNavigation?: boolean;
  onBlur?(e: EditorEvents["blur"]): void;
  onFocus?(e: EditorEvents["focus"]): void;
  onEditorReady?(editor: Editor | null): void;
  onUpdate(value: string): void;
}

export const useDocumentEditor = (params: IUseReportEditorParams) => {
  const { blockId, content, editable = true, extensions = [], disableDashNavigation, onUpdate, onBlur, onFocus } = params;
  const { onEditorReady } = params;
  const { navigateToReport } = useAppNavigate();
  const { onHide: onBubbleMenuHide, onShow: onBubbleMenuShow, isOpen: isBubbleMenuOpen } = useBubbleMenu();

  const handleBlur = (e: EditorEvents["blur"]) => {
    onUpdate(e.editor.getHTML());
    onBlur?.(e);
  };

  const handleUpdate = useCallback(
    (e: EditorEvents["update"]) => {
      onUpdate(e.editor.getHTML());
    },
    [onUpdate]
  );

  const debouncedHandleEditorUpdate = useDebouncedCallback(handleUpdate, 1000);

  const handleFocus = (e: any) => {
    e.editor.commands.focus("end");
    onFocus?.(e);
  };

  const editor = useEditor({
    extensions: [
      StarterKit,
      Underline,
      Link,
      PreventEnter,
      ...(appStore.workspaceModel
        ? getMentionExtensions({ workspace: appStore.workspaceModel, disableMentionNodeRemoval: true, onUpdate: onUpdate })
        : []),
      ...(disableDashNavigation ? [] : [DashNavigationExtension(navigateToReport)]),
      ...extensions,
    ],
    editable,
    onUpdate: debouncedHandleEditorUpdate,
    onFocus: handleFocus,
    onBlur: handleBlur,
    autofocus: appStore.env.activeDocumentBlockId === blockId,
    content,
  });

  useUpdateEditorContent({ content, editor, shouldNotUpdate: isBubbleMenuOpen });

  useEffect(() => {
    onEditorReady?.(editor);
  }, [onEditorReady, editor]);

  const handleBubbleMenuHide = () => {
    onBubbleMenuHide();
    if (editor) {
      onUpdate(editor.getHTML());
    }
  };

  return { editor, onBubbleMenuHide: handleBubbleMenuHide, onBubbleMenuShow };
};
