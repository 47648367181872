import { NavbarDivider, Tooltip } from "@blueprintjs/core";
import { observer } from "mobx-react";

import { Button } from "@components/Button";
import { isMacOS } from "@constants/platform";
import appStore from "@store/AppStore";

import OrgSelectDropdown from "./OrgSelectDropdown";
import WorkspaceDropdown from "./WorkspaceDropdown";

import "./LeftHeaderElements.scss";

const LeftHeaderElements = () => {
  const showCommandPalette = () => {
    void appStore.pullOrgWorkspaces();
    appStore.ui.showCommandPalette();
  };

  return (
    <div className="left-header-elements">
      <OrgSelectDropdown />
      <div className="flex items-center gap-2">
        <NavbarDivider className="m-0" />
        <WorkspaceDropdown />
        <NavbarDivider className="m-0" />
        <Tooltip content={isMacOS() ? "Cmd + K" : "Ctrl + K"}>
          <Button className="search" icon="search" onClick={showCommandPalette} e2eIdentifiers="show-command-palette" minimal />
        </Tooltip>
      </div>
    </div>
  );
};

export default observer(LeftHeaderElements);
