import { useEffect, useMemo, useState } from "react";
import { Intent } from "@blueprintjs/core";
import { observer } from "mobx-react";
import { useWindowSize } from "usehooks-ts";

import { Button } from "@components/Button";
import LoadingImportsList from "@components/LoadingImportsList";
import FileUploadList from "@components/Modeling/ModelingFrame/ModelBlock/Uploads/FileUploadList/FileUploadList";
import appStore from "@store/AppStore";
import { EFileUploadStatus, IUploadStore } from "@store/UploadStore";
import { getPluralOrSingularString } from "@utilities";

import FloatingWindow from "../FloatingWindow";

import { EWarningType, MAX_WINDOW_HEIGHT, MIN_WINDOW_HEIGHT, ROW_HEIGHT, warningText, WINDOW_HEADER_HEIGHT } from "./constants";

import styles from "./QueueWindow.module.scss";

const QueueWindow = () => {
  const [warning, setWarning] = useState<EWarningType | undefined>();
  const { height: windowHeight, width: windowWidth } = useWindowSize();
  const { uploadQueueWindowEnabled, hideUploadQueueWindow } = appStore.ui;
  const files: IUploadStore[] = appStore.orgModel.uploads.uploadFiles || [];
  const workspaceImportsList = appStore.workspaceModel?.importsInProgress || [];
  const orgImportsList = appStore.orgModel.importsInProgress || [];
  const unsavedImports = appStore.workspaceModel?.unsavedImports || [];
  const importsList = [...workspaceImportsList, ...orgImportsList];
  const isUploaded = files.every(f => f.status === EFileUploadStatus.UPLOADED) && !importsList.length;
  const totalItemsLength = files.length + importsList.length;
  const title = `${isUploaded ? "Uploaded" : "Uploading"} ${totalItemsLength} ${getPluralOrSingularString(totalItemsLength, "file")}`;
  const height = useMemo(
    () => (totalItemsLength > 5 ? MAX_WINDOW_HEIGHT : WINDOW_HEADER_HEIGHT + totalItemsLength * ROW_HEIGHT),
    [totalItemsLength]
  );
  const windowSize = { width: 540, height: Math.max(MIN_WINDOW_HEIGHT, height) };

  useEffect(() => {
    return () => {
      appStore.workspaceModel?.deleteUnsavedImports();
    };
  }, []);

  const handleCloseWindow = () => {
    setWarning(undefined);
    appStore.orgModel.uploads.cleanFileUploadStore();
    appStore.workspaceModel?.deleteUnsavedImports();
    hideUploadQueueWindow();
  };

  const handleCollapse = (toggleCollapse: () => void) => {
    toggleCollapse();
    setWarning(undefined);
  };

  const handleClose = (collapsed: boolean, toggleCollapsed: () => void) => {
    if (appStore.orgModel.uploads.uploadQueueBusy) {
      setWarning(EWarningType.UPLOAD_IN_PROGRESS);
      collapsed && toggleCollapsed();
    } else if (appStore.orgModel.uploads.uploadQueueFailed) {
      setWarning(EWarningType.UPLOAD_FAILED);
    } else if (unsavedImports.length) {
      setWarning(EWarningType.UNSAVED_IMPORTS);
    } else {
      handleCloseWindow();
    }
  };

  const handleWarningCancel = (toggleCollapse: () => void) => {
    if (warning === EWarningType.UPLOAD_FAILED) {
      appStore.orgModel.uploads.retryUploadQueue();
      setWarning(undefined);
      return;
    }

    handleCollapse(toggleCollapse);
  };

  const warningRenderer = (toggleCollapse: () => void) => {
    if (warning) {
      return (
        <div className={styles.queueWindowWarning}>
          <h2 className={styles.queueWindowWarningTitle}>{warningText[warning].title}</h2>
          <div className={styles.queueWindowWarningButtons}>
            <Button intent={Intent.PRIMARY} onClick={() => handleWarningCancel(toggleCollapse)} e2eIdentifiers="cancel">
              {warningText[warning].cancel}
            </Button>
            <Button outlined minimal onClick={handleCloseWindow} e2eIdentifiers="cancel">
              {warningText[warning].confirm}
            </Button>
          </div>
        </div>
      );
    }

    return null;
  };

  if (windowWidth === 0 || windowHeight === 0) {
    return null;
  }

  return (
    <FloatingWindow
      contentClassName={styles.queueWindowContent}
      defaultPosition={{ x: windowWidth - windowSize.width, y: windowHeight - windowSize.height }}
      minSize={windowSize}
      defaultSize={windowSize}
      id="uploadQueueWindow"
      title={title}
      warningRenderer={warningRenderer}
      open={uploadQueueWindowEnabled}
      onClose={handleClose}
      draggable
      resizable
    >
      <FileUploadList fileList={files} />
      <LoadingImportsList importsList={importsList} />
    </FloatingWindow>
  );
};

export default observer(QueueWindow);
