import { getParentOfType, IAnyStateTreeNode, IMSTArray, IReferenceType } from "mobx-state-tree";

import { BlockStore, IBlock } from "@store/BlockStore";
import { IWorkspace, IWorkspaceSnapshotIn, WorkspaceStore } from "@store/WorkspaceStore";

export function validateWorkspace(workspace: IWorkspaceSnapshotIn) {
  try {
    const blockChildCount = new Map<string, number>();
    const propChildCount = new Map<string, number>();

    if (workspace.rootBlock) {
      blockChildCount.set(`${workspace.rootBlock}`, 1);
    }

    if (!workspace.propertyInstanceMap) {
      workspace.propertyInstanceMap = {};
    }

    if (!workspace.propertyDefinitionMap) {
      workspace.propertyDefinitionMap = {};
    }

    for (const id in workspace.blockMap) {
      const block = workspace.blockMap[id];
      if (id !== workspace.rootBlock && (!block.parentBlock || !workspace.blockMap[block.parentBlock])) {
        console.warn(`Workspace validation failed for ${workspace.id}: Block ${id} is missing parent block`);
        return false;
      }

      if (block.children) {
        for (const child of block.children) {
          if (typeof child !== "string") {
            console.warn(`Workspace validation failed for ${workspace.id}: Unresolved child block ${child}`);
            return false;
          }
          if (!child || !workspace.blockMap[child]) {
            console.warn(`Workspace validation failed for ${workspace.id}: Unresolved child block ${child}`);
            return false;
          }
          if (blockChildCount.has(child)) {
            console.warn(`Workspace validation failed for ${workspace.id}: Block ${child} is defined as a child of two parents`);
            return false;
          }
          blockChildCount.set(child, 1);
        }
      }

      if (block.propertyInstances) {
        for (const prop of block.propertyInstances) {
          if (typeof prop !== "string") {
            console.warn(`Workspace validation failed for ${workspace.id}: Unresolved child property ${prop}`);
            return false;
          }
          if (!prop || !workspace.propertyInstanceMap[prop]) {
            console.warn(`Workspace validation failed for ${workspace.id}: Unresolved child property ${prop}`);
            return false;
          }
          if (propChildCount.has(prop)) {
            console.warn(`Workspace validation failed for ${workspace.id}: Property ${prop} is defined as a child of two blocks`);
            return false;
          }
          const propObj = workspace.propertyInstanceMap[prop];
          if (propObj.parentBlock !== block.id) {
            console.warn(`Workspace validation failed for ${workspace.id}: Child property ${prop} does not point to block ${block.id}`);
            return false;
          }
          if (!propObj.propertyDefinition || !workspace.propertyDefinitionMap[propObj.propertyDefinition]) {
            console.warn(`Workspace validation failed for ${workspace.id}: Undefined child property ${prop}`);
            return false;
          }
          propChildCount.set(prop, 1);
        }
      }
    }

    // Check for dangling blocks and prop that are not children of anything
    for (const id in workspace.blockMap) {
      if (!blockChildCount.has(id)) {
        console.warn(`Block ${id} is dangling`);
        return false;
      }
    }

    for (const id in workspace.propertyInstanceMap) {
      if (!propChildCount.has(id)) {
        console.warn(`Property ${id} is dangling`);
        return false;
      }
    }
  } catch (e) {
    console.warn(e);
    return false;
  }
  return true;

  // TODO: more validation of other things
}

// Adapted from a few answers here: https://stackoverflow.com/questions/5306680/move-an-array-element-from-one-array-position-to-another
// The important aspect of this is the in-place modification, resulting in fewer patches.
// Warning: this should not be used for non-ref arrays! TODO: Add stricter typing to prevent this
export function moveItemInRefArray(array: IMSTArray<IReferenceType<any>>, srcIndex: number, destIndex: number) {
  if (!array || srcIndex === destIndex || srcIndex < 0 || destIndex < 0 || srcIndex >= array.length || destIndex >= array.length) {
    return false;
  }

  const target = array[srcIndex];
  const increment = destIndex < srcIndex ? -1 : 1;
  for (let i = srcIndex; i != destIndex; i += increment) {
    array[i] = array[i + increment];
  }
  array[destIndex] = target;
  return true;
}

// This should also be typed more strictly
export function validatedRefArray<T>(refArray?: IMSTArray<any> | null) {
  const validRefs = [];
  if (refArray) {
    for (const r of refArray) {
      if (r && r.isValid !== false) {
        validRefs.push(r);
      }
    }
  }
  return validRefs as T[];
}

export type OrderableEntity = { id: string; orderIndex: number };

// This function is adapted from the "reorderChildEntities" function in the backend codebase.
// There's probably a neater way to do this, but for now this approach works well for orderable entities
export function moveOrderIndexedItem(array: OrderableEntity[], srcId: string, destId: string) {
  const srcIndex = array.findIndex(item => item?.id === srcId);
  const destIndex = array.findIndex(item => item?.id === destId);
  if (!array || srcIndex === destIndex || srcIndex < 0 || destIndex < 0 || srcIndex >= array.length || destIndex >= array.length) {
    return false;
  }

  const srcItem = array[srcIndex];
  const destItem = array[destIndex];
  const rangeMin = Math.min(srcItem.orderIndex, destItem.orderIndex);
  const rangeMax = Math.max(srcItem.orderIndex, destItem.orderIndex);

  const siblings = array
    .filter(item => item.orderIndex >= rangeMin + 1 && item.orderIndex <= rangeMax - 1)
    .sort((a, b) => a.orderIndex - b.orderIndex);

  let orderedSiblings = new Array<OrderableEntity>();

  if (destItem.orderIndex < srcItem.orderIndex) {
    orderedSiblings.push(srcItem);
    orderedSiblings.push(destItem);
    orderedSiblings = orderedSiblings.concat(siblings);
  } else {
    orderedSiblings = orderedSiblings.concat(siblings);
    orderedSiblings.push(destItem);
    orderedSiblings.push(srcItem);
  }

  for (let i = 0; i < orderedSiblings.length; i++) {
    orderedSiblings[i].orderIndex = rangeMin + i;
  }
  return true;
}

// Sorts an array of reference IDs by their lookup value's order index.
// TODO: update typing to use {[key: string]: OrderableEntity} once snapshot typings are fixed
export function sortRefArrayByOrderIndex<T extends (string | number | undefined)[] | null | undefined>(idArray: T, objectMap: any): T {
  if (idArray?.length && objectMap) {
    idArray.sort((a, b) => {
      const objA = a ? objectMap[a] : undefined;
      const objB = b ? objectMap[b] : undefined;
      return (objA?.orderIndex ?? 0) - (objB?.orderIndex ?? 0);
    });
  }
  return idArray;
}

// This function is adapted from the "getNextOrderIndex" function in the backend codebase.
// Returns the appropriate orderIndex value, given the list of siblings.
// The list is sorted by orderIndex and then the last element is extracted and incremented by one
// If the list is empty, the orderIndex is set as zero
export function getNextOrderIndex(items?: OrderableEntity[]) {
  if (!items?.length) {
    return 0;
  }
  // sort after mapping to ensure the original array isn't mutated
  const sortedIndices = items.map(o => o.orderIndex).sort((a, b) => a - b);
  // grab the last element and increment
  return sortedIndices.slice(-1)[0] + 1;
}

export function parentWorkspace(node: IAnyStateTreeNode): IWorkspace | undefined {
  try {
    return getParentOfType(node, WorkspaceStore) as IWorkspace;
  } catch (e) {
    return undefined;
  }
}

export function parentBlock(node: IAnyStateTreeNode): IBlock | undefined {
  try {
    return getParentOfType(node, BlockStore) as IBlock;
  } catch (e) {
    return undefined;
  }
}

export function filterEnumArray<T extends Record<string, string | number>>(enumType: T, entries?: (string | number)[]) {
  if (!entries?.length) {
    return entries;
  }

  const allowedValues = [...Object.values(enumType)];
  return entries.filter(v => allowedValues.includes(v)) as unknown as T[];
}

// Adapted from:
// https://github.com/microsoft/TypeScript/issues/30611#issuecomment-570773496
export function createDefaultEnumAssigner<T extends string, TEnumValue extends string | number>(
  enumVariable: { [key in T]: TEnumValue },
  defaultValue: TEnumValue
) {
  const enumValues = Object.values(enumVariable);
  return (inputValue: TEnumValue | undefined) => (inputValue !== undefined && enumValues.includes(inputValue) ? inputValue : defaultValue);
}
