import { observer } from "mobx-react";

import DevIcon from "@components/DevIcon";
import { DevIconSvg, DevIconSvgName } from "@components/DevIconSvg";
import { ExecutionEnvironmentType } from "@rollup-api/models/execution-environments";

interface IExecutionTypeIconProps {
  type: ExecutionEnvironmentType;
  className?: string;
  size?: number;
}

const getIconName = (type: ExecutionEnvironmentType) => {
  switch (type) {
    case ExecutionEnvironmentType.Python:
      return DevIconSvgName.Python;
    case ExecutionEnvironmentType.Node:
      return DevIconSvgName.Node;
    case ExecutionEnvironmentType.Bun:
      return DevIconSvgName.Bun;
    case ExecutionEnvironmentType.Matlab:
      return DevIconSvgName.Matlab;
    case ExecutionEnvironmentType.Julia:
      return DevIconSvgName.Julia;
    default:
      return undefined;
  }
};

function ExecutionTypeIcon(props: IExecutionTypeIconProps) {
  const { type, size = 16, className } = props;

  if (type === ExecutionEnvironmentType.Bash) {
    return <DevIcon className={className} icon="bash" size={size} />;
  }

  const iconName = getIconName(type);

  if (!iconName) {
    return null;
  }

  return <DevIconSvg className={className} icon={iconName} size={size} />;
}

export default observer(ExecutionTypeIcon);
