import { useCallback, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { Classes } from "@blueprintjs/core";
import { GetRowIdParams, GridReadyEvent, ICellRendererParams, IRowDragItem, RowClickedEvent } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import { observer } from "mobx-react";
import { isAlive } from "mobx-state-tree";

import { expandAllAscendants, getBlocksTreeNodeId } from "@components/BlocksTree/BlocksTree.utils";
import { DialogAddNewChildBlock } from "@components/Dialog";
import HeaderCell from "@components/Modeling/ModelingFrame/Table/TableComponent/Cells/HeaderCell";
import { NodeInfo } from "@components/Modeling/ModelingFrame/Table/TableComponent/types";
import { getDataPath } from "@components/Modeling/ModelingFrame/Table/TableComponent/utils";
import Table from "@components/Table";
import useTreeDrag, { AG_GIRD_TARGET_ROW_CLASS } from "@components/Table/hooks/useTreeDrag";
import { useAppNavigate } from "@router/hooks";
import appStore from "@store/AppStore";
import { IBlock } from "@store/BlockStore";

import { blockNodeList } from "../Modeling/ModelingFrame/Table/utils";
import { updateRowsClass } from "../Table/utils";

import { BLOCKS_TREE_PREFIX } from "./constants";
import NameCell from "./NameCell";

import styles from "./BlocksTree.module.scss";

type Props = {
  expanded: boolean;
};

function BlocksTree({ expanded }: Props) {
  const tableRef = useRef<AgGridReact<NodeInfo> | null>(null);
  const [addNewModalBlock, setAddNewModalBlock] = useState<IBlock | null>(null);
  const { onRowDragEnd, onRowDragMove, onRowDragLeave } = useTreeDrag(25);
  const { blockId } = useParams();
  const { navigateToBlock } = useAppNavigate();

  useEffect(() => {
    const { blocksTreeGridApi } = appStore.env;
    const nodeId = getBlocksTreeNodeId(blockId);
    const node = blocksTreeGridApi?.getRowNode(nodeId);

    if (!node || !blocksTreeGridApi) {
      return;
    }
    expandAllAscendants(node.data.block, blocksTreeGridApi);
  }, [blockId]);

  useEffect(() => {
    updateRowsClass(blockId, BLOCKS_TREE_PREFIX);
  }, [blockId]);

  useEffect(() => {
    if (expanded) {
      tableRef.current?.api?.expandAll();
    } else {
      tableRef.current?.api?.collapseAll();
    }
    tableRef.current?.api?.redrawRows();
  }, [expanded]);

  const handleTreeScroll = () => {
    updateRowsClass(blockId, BLOCKS_TREE_PREFIX);
  };

  const handleRowDataUpdated = useCallback(() => {
    updateRowsClass(blockId, BLOCKS_TREE_PREFIX);
  }, [blockId]);

  const handleGridReady = useCallback((e: GridReadyEvent<NodeInfo>) => {
    appStore.env.setBlocksTreeGridApi(e.api);
  }, []);

  const onDestroyed = () => {
    appStore.env.setBlocksTreeGridApi();
  };

  const handleOpenAddNewModal = (block: IBlock) => {
    setAddNewModalBlock(block);
  };

  const handleCloseAddNewModal = () => {
    setAddNewModalBlock(null);
    tableRef.current?.api?.redrawRows();
  };

  const handleRowClicked = useCallback(
    (event: RowClickedEvent<NodeInfo>) => {
      const target = event.event?.target as HTMLElement;
      const isButtonOrPopover = target.classList.contains(Classes.BUTTON) || target.classList.contains("blocks-tree--actions-nav");
      const isIcon = target.dataset.icon || target.localName === "path";

      if (!isButtonOrPopover && !isIcon && event.data?.block) {
        navigateToBlock(event.data?.block.id);
      }
    },
    [navigateToBlock]
  );

  const getRowDragText = (params: IRowDragItem) => {
    const block = params.rowNode?.data.block as IBlock | undefined;
    return block?.label || "Block tree row";
  };

  return (
    <>
      <Table<NodeInfo>
        rowData={blockNodeList()}
        onBodyScroll={handleTreeScroll}
        disableAutoHeight
        style={{ height: "calc(100% - 40px)", width: "100%" }}
        tableRef={tableRef}
        headerHeight={0}
        className={styles.blocksTree}
        getDataPath={getDataPath}
        getRowId={(row: GetRowIdParams<NodeInfo>) => {
          return isAlive(row.data.block) ? getBlocksTreeNodeId(row.data.block?.id) : "";
        }}
        rowDragText={getRowDragText}
        treeData
        onRowDataUpdated={handleRowDataUpdated}
        onChartDestroyed={onDestroyed}
        onGridReady={handleGridReady}
        onRowDragEnd={onRowDragEnd}
        onRowDragMove={onRowDragMove}
        onRowDragLeave={onRowDragLeave}
        components={{ agColumnHeader: HeaderCell }}
        columnDefs={[]}
        gridOptions={{
          groupDefaultExpanded: -1,
          autoGroupColumnDef: {
            headerName: "",
            flex: 1,
            cellClass: "ag-custom-group-cell",
            cellRenderer: (props: ICellRendererParams<NodeInfo>) => (
              <NameCell tableApi={props.api} {...props} onAddNewOpen={handleOpenAddNewModal} />
            ),
            headerComponentParams: {
              hideNav: true,
            },
          },
        }}
        rowHeight={32}
        rowDragEntireRow
        rowClass={AG_GIRD_TARGET_ROW_CLASS}
        onRowClicked={handleRowClicked}
      />
      {addNewModalBlock && <DialogAddNewChildBlock block={addNewModalBlock} isOpen onClose={handleCloseAddNewModal} />}
    </>
  );
}

export default observer(BlocksTree);
