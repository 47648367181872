import { KeyboardEvent, ReactNode, useMemo, useState } from "react";
import { Menu, MenuDivider, TagProps, TextArea } from "@blueprintjs/core";
import { ItemListRendererProps, ItemRendererProps, renderFilteredItems } from "@blueprintjs/select";
import { Text, TextVariant } from "@ui/Text";
import { observer } from "mobx-react";

import { Button } from "@components/Button";
import { DataSinkDrawerStore } from "@components/DataSources/AddDataSink/stores/DataSinkDrawerStore";
import { FormGroup } from "@components/FormGroup";
import { IconSelector } from "@components/IconSelector";
import { InputGroup } from "@components/InputGroup";
import { MenuItem } from "@components/MenuItem";
import { MenuItemCreateNewTag } from "@components/MenuItems/MenuItemCreateNewTag";
import { MultiSelect } from "@components/MultiSelect";
import MultiSelectMenuItem from "@components/MultiSelect/MultiSelectMenuItem";
import DismissibleCallout from "@components/Shared/DismissibleCallout/DismissibleCallout";
import { IComplexTag } from "@components/TagsContainer/TagsContainer.types";
import { allColors, Color } from "@rollup-types/colors";
import { IconSource } from "@rollup-types/icons";
import { getBgColorClassName, getRandomHexColor } from "@utilities";
import { enhanceStringTags } from "@utilities/TagUtils";

import { DataSinkEntryCard } from "../DataSinkEntryCard";

import styles from "./DataSinkDrawerBody.module.scss";

interface IDataSinkDrawerBodyProps {
  dataSinkStore: DataSinkDrawerStore;
}

const prefilledTags = ["Python", "Local", "AWS"];

const DataSinkDrawerBody = (props: IDataSinkDrawerBodyProps) => {
  const { dataSinkStore } = props;
  const { icon, tags, tagColors, label, description, entries } = dataSinkStore;
  const { setIcon, toggleTag, addTag, removeTag, isTagSelected, setLabel, setDescription, addEntry, deleteEntry } = dataSinkStore;
  const [query, setQuery] = useState("");

  const sortedTags = useMemo(() => {
    const filteredPrefilledTags = prefilledTags.filter(tag => !tags.some(t => t.value === tag));
    const enhancedPrefilledTags = enhanceStringTags(filteredPrefilledTags, tagColors);
    const allTags = [...tags, ...enhancedPrefilledTags];
    return allTags.sort((a, b) => a.value.localeCompare(b.value));
  }, [tagColors, tags]);

  const allTagColors = sortedTags.map(t => t.color);
  const generateRandomColor = () => getRandomHexColor(allTagColors, undefined, allColors) as Color;
  const [newTagColor, setNewTagColor] = useState<Color>(generateRandomColor);

  const handleAddNewItem = async (queryValue: string) => {
    setQuery("");
    addTag({ value: queryValue, color: newTagColor });
    setNewTagColor(generateRandomColor());
  };

  const handleInputKeyDown = (e: KeyboardEvent<HTMLElement>) => {
    if (e.key === "Enter" && query) {
      e.stopPropagation();
      e.preventDefault();
      handleAddNewItem(query);
      setQuery("");
    }
  };

  const tagProps = (value: ReactNode): TagProps => {
    const option = tags.find(tag => tag.value === value);
    const { color } = option ?? {};
    const className = getBgColorClassName(color);

    return {
      minimal: false,
      className,
    };
  };

  const renderCreateNewItem = (query: string) => {
    return <MenuItemCreateNewTag query={query} color={newTagColor} onClick={handleAddNewItem} />;
  };

  const renderItem = (tag: IComplexTag, itemProps: ItemRendererProps) => {
    const { handleClick } = itemProps;

    return (
      <MultiSelectMenuItem
        key={tag.value}
        text={tag.value}
        selected={isTagSelected(tag)}
        onClick={handleClick}
        e2eIdentifiers="toggle-tag"
      />
    );
  };

  const renderNoResults = <MenuItem disabled text="No results. Type to create an option" e2eIdentifiers="no-results" />;

  const renderItemList = (listProps: ItemListRendererProps<IComplexTag>) => {
    // omit noResults if createNewItemFromQuery and createNewItemRenderer are both supplied, and query is not empty
    const createItemView = listProps.renderCreateItem();
    const maybeNoResults = createItemView != null ? null : renderNoResults;
    const menuContent = renderFilteredItems(listProps, maybeNoResults);

    if (menuContent == null && createItemView == null) {
      return null;
    }

    const title = menuContent === null ? "Create New Tag" : "Select an Option or Create a New One";

    return (
      <Menu role="listbox" {...listProps.menuProps} ulRef={listProps.itemsParentRef}>
        <MenuDivider title={title} />
        {menuContent}
        {createItemView}
      </Menu>
    );
  };

  return (
    <div className={styles.dataSinkDrawerBody}>
      <div className={styles.dataSinkDrawerBodyTopContainer}>
        {dataSinkStore.visibleId && (
          <FormGroup label="Data Sink ID:" className={styles.dataSinkDrawerBodyIdFormGroup}>
            <Text variant={TextVariant.Label}>{dataSinkStore.visibleId}</Text>
          </FormGroup>
        )}
        <div className={styles.dataSinkDrawerBodyTypeMetadata}>
          <FormGroup label="Icon">
            <IconSelector
              currentIcon={icon}
              onChange={setIcon}
              tooltip="Set Data sink icon"
              buttonProps={{ minimal: true, outlined: true }}
              iconSources={[IconSource.Blueprint, IconSource.DevIcon]}
              closeOnIconSelect
            />
          </FormGroup>
          <FormGroup label="Tags" className={styles.dataSinkDrawerBodyTags}>
            <MultiSelect<IComplexTag>
              query={query}
              onQueryChange={query => setQuery(query)}
              placeholder=""
              resetOnSelect
              resetOnQuery
              className={styles.dataSinkDrawerBodyTagsMultiSelect}
              selectedItems={tags}
              items={sortedTags}
              tagInputProps={{
                tagProps,
                onKeyDown: handleInputKeyDown,
              }}
              onRemove={removeTag}
              popoverProps={{ minimal: true, popoverClassName: "status-options-popover", position: "bottom-right" }}
              tagRenderer={tag => tag.value}
              onItemSelect={toggleTag}
              itemRenderer={renderItem}
              itemListRenderer={renderItemList}
              createNewItemFromQuery={query => ({ value: query, color: newTagColor })}
              createNewItemRenderer={renderCreateNewItem}
            />
          </FormGroup>
        </div>
        <FormGroup label="Name" required>
          <InputGroup
            className={styles.dataSinkDrawerBodyInput}
            value={label}
            onChange={e => setLabel(e.target.value)}
            e2eIdentifiers="name"
          />
        </FormGroup>
        <FormGroup label="Description">
          <TextArea className={styles.dataSinkDrawerBodyTextArea} value={description} onChange={e => setDescription(e.target.value)} />
        </FormGroup>
      </div>
      <div className={styles.dataSinkDrawerBodyBottomContainer}>
        <div>
          <Text variant={TextVariant.H4}>Property keys</Text>
        </div>
        <DismissibleCallout intent="warning" calloutKey="data-sink-data-type-change" title="Important">
          <Text variant={TextVariant.Body}>
            If you change the data type, sets of keys and connections will be broken. You will need to set it one more time.
          </Text>
        </DismissibleCallout>
        <div className={styles.dataSinkDrawerBodyPropertiesContainer}>
          {entries.map(property => (
            <DataSinkEntryCard key={property.id} property={property} onDelete={deleteEntry} />
          ))}
        </div>
        <Button
          className={styles.dataSinkDrawerBodyAddPropertyBtn}
          onClick={addEntry}
          icon="add"
          e2eIdentifiers="add-property"
          minimal
          outlined
        >
          Add Property
        </Button>
      </div>
    </div>
  );
};

export default observer(DataSinkDrawerBody);
