import Text, { TextVariant } from "@ui/Text";
import classNames from "classnames";
import { observer } from "mobx-react";

import { Button } from "@components/Button";

import "./SidePanelBackButton.scss";

interface ISidePanelBackButtonProps {
  label: string;
  className?: string;
  onClick(): void;
}

const SidePanelBackButton = (props: ISidePanelBackButtonProps) => {
  const { label, className, onClick } = props;

  return (
    <div className={classNames("side-panel-back-button", className)}>
      <Button e2eIdentifiers="back-button" alignText="left" icon="arrow-left" onClick={onClick} minimal large fill>
        <Text variant={TextVariant.BodyDimmed} clamp={1}>
          {label}
        </Text>
      </Button>
    </div>
  );
};

export default observer(SidePanelBackButton);
