import { Intent } from "@blueprintjs/core";
import { toArray } from "@rollup-io/engineering";
import { cast, destroy, flow, IAnyModelType, Instance, types } from "mobx-state-tree";

import { showApiErrorToast, showToast } from "@components/UiLayers/toaster";
import { CreateAttachmentDto, IntegrationLinkData } from "@rollup-api/models";
import { CreateAttachmentWebSocketDto } from "@rollup-api/models/cloudStorage/attachmentCreateWebSocketDto.model";
import { ReferenceLinkData } from "@rollup-api/models/references/references.model";
import appStore from "@store/AppStore";
import { AttachmentStore, IAttachment, IAttachmentMobxType } from "@store/AttachmentStore";
import { IBlock } from "@store/BlockStore";

import { rollupClient } from "../core/api";
import { convertTimestamp, getBlockById } from "../Utilities";

export const AttachmentModuleStore = types
  .model("AttachmentModuleStore", {
    attachmentMap: types.map<IAttachmentMobxType>(types.late((): IAnyModelType => AttachmentStore)),
  })
  .views(self => ({
    get values(): IAttachment[] {
      return toArray<IAttachment>(self.attachmentMap);
    },
    get(id: string) {
      return self.attachmentMap.get(id);
    },
    has(id: string) {
      return !!this.get(id);
    },
  }))
  .actions(self => ({
    addBlockIdToAttachment(attachmentId: string, blockId: string) {
      const attachment = self.attachmentMap.get(attachmentId);
      if (attachment) {
        attachment.block = blockId;
      }
    },
  }))
  .actions(self => ({
    delete(attachmentToDelete: IAttachment, notify = true) {
      if (!attachmentToDelete?.id) {
        return false;
      }
      const { id, workspaceId } = attachmentToDelete;

      if (notify) {
        rollupClient.attachments.delete(id, workspaceId).catch((err: Error) => {
          showApiErrorToast("Error deleting attachment", err);
        });
      }

      if (self.attachmentMap.delete(id)) {
        destroy(attachmentToDelete);
        return true;
      }
      return false;
    },
    addUploadedAttachmentsToBlock(attachmentIds: string | string[], blockId: string): void {
      if (!Array.isArray(attachmentIds)) {
        attachmentIds = [attachmentIds];
      }

      const block = getBlockById(blockId);
      if (block) {
        attachmentIds.forEach(id => {
          block.addAttachmentRef(id);
          self.addBlockIdToAttachment(id, block.id);
        });
      }
    },
    addUploadedAttachment(attachmentData: any): IAttachment {
      delete attachmentData["block"];
      attachmentData.updatedAt = convertTimestamp(attachmentData.updatedAt);

      const existingAttachment = self.attachmentMap.get(attachmentData.id);
      if (existingAttachment) {
        attachmentData.createdAt = existingAttachment.createdAt;
        const updatedAttachment: IAttachment = {
          ...existingAttachment,
          ...attachmentData,
        };
        return self.attachmentMap.put(updatedAttachment) as IAttachment;
      }

      attachmentData.createdAt = convertTimestamp(attachmentData.createdAt);

      return self.attachmentMap.put({ ...attachmentData }) as IAttachment;
    },
    addExistingAttachment(dto: CreateAttachmentWebSocketDto, block?: IBlock): IAttachment | undefined {
      if (!dto.id) {
        return undefined;
      }

      const attachment = self.attachmentMap.put({
        id: dto.id,
        name: dto.name,
        label: dto.label,
        fileSize: dto.fileSize,
        fileType: dto.fileType,
        createdBy: dto.userId,
        block: block?.id,
        workspaceId: dto.workspaceId,
      });

      if (block) {
        if (block.attachments) {
          block.addAttachmentRef(attachment.id);
        } else {
          block.attachments = cast([attachment.id]);
        }
      }

      return attachment;
    },
  }))

  .actions(self => ({
    uploadAction: flow(function* uploadAction(
      uploadCallback: () => any,
      errorMsg: string,
      successMsg?: string
    ): Generator<any, string | undefined, any> {
      try {
        const res = yield uploadCallback();
        if (res.status !== 201) {
          showApiErrorToast(errorMsg, new Error());
          return;
        }
        if (successMsg) {
          showToast(successMsg, Intent.SUCCESS);
        }
        const attachment = self.addUploadedAttachment(res.data);
        if (res.data.blockId && attachment) {
          self.addUploadedAttachmentsToBlock(attachment.id, res.data.blockId);
        }
        return res.data.id;
      } catch (err) {
        showApiErrorToast(errorMsg, err as Error);
        console.warn(err);
      }
    }),
  }))

  .actions(self => ({
    add: flow(function* add(dto: CreateAttachmentDto, file: File): Generator<any, string | undefined, any> {
      return yield self.uploadAction(() => rollupClient.attachments.uploadFile(dto, file), "Error creating attachment");
    }),
    addLink: flow(function* addLink(blockId: string, linkName: string, linkUrl: string): Generator<any, string | undefined, any> {
      return yield self.uploadAction(
        () => rollupClient.attachments.uploadLink(blockId, linkName, linkUrl, appStore.workspaceModel?.id),
        "Error uploading link"
      );
    }),
    addIntegration: flow(function* addNewIntegration(blockId: string, data: IntegrationLinkData): Generator<any, string | undefined, any> {
      return yield self.uploadAction(
        () => rollupClient.attachments.uploadIntegrationLink(blockId, { ...data, workspaceId: appStore.workspaceModel?.id }),
        "Error adding integration attachment"
      );
    }),
    addReferenceLink: flow(function* addReferenceLink(blockId: string, data: ReferenceLinkData): Generator<any, string | undefined, any> {
      return yield self.uploadAction(
        () => rollupClient.attachments.uploadReferenceLink(blockId, data),
        "Error adding Attachment",
        "Successfully added Attachment"
      );
    }),
  }));

export interface IAttachmentModule extends Instance<typeof AttachmentModuleStore> {}
