import { ReactElement } from "react";
import { Callout, Intent, PopoverPosition } from "@blueprintjs/core";

import Avatar from "@components/Avatar/Avatar";
import { RelativeDateWithTooltip } from "@components/RelativeDateWithTooltip";
import { Integration } from "@rollup-api/models/integrations";
import appStore from "@store/AppStore";
import { diffTimestamps } from "@utilities";
import { TextVariant } from "src/ui/Text";

import styles from "./IntegrationCallout.module.scss";

export const IntegrationCallout = ({
  integration,
  integrationTargetElement,
}: {
  integration: Integration;
  integrationTargetElement?: ReactElement;
}) => {
  const { createdBy, createdAt, updatedBy, updatedAt } = integration;
  const createByUser = appStore.orgModel.info.userWithId(createdBy);
  const updatedByUser = appStore.orgModel.info.userWithId(updatedBy);
  const diffInMinutes = diffTimestamps(updatedAt, createdAt, "minutes");

  return (
    <div className={styles.integrationCallout}>
      <Callout intent={Intent.PRIMARY}>
        <div className={styles.integrationCalloutRow}>
          {integrationTargetElement ? <>Connected to {integrationTargetElement}</> : "Connected"}
          by
          <Avatar source={createByUser?.avatarUrl} size={20} shape="circle" /> {createByUser?.displayName}
          <RelativeDateWithTooltip popoverPosition={PopoverPosition.TOP} variant={TextVariant.Caption} epochTime={createdAt} />
        </div>
      </Callout>
      {diffInMinutes > 1 && updatedByUser && (
        <Callout intent={Intent.PRIMARY}>
          <div className={styles.integrationCalloutRow}>
            Last updated by
            <Avatar source={updatedByUser?.avatarUrl} size={20} shape="circle" /> {updatedByUser?.displayName}
            <RelativeDateWithTooltip popoverPosition={PopoverPosition.TOP} variant={TextVariant.Caption} epochTime={updatedAt} />
          </div>
        </Callout>
      )}
    </div>
  );
};
