import { useCallback, useEffect, useMemo } from "react";
import { Menu, PopoverPosition } from "@blueprintjs/core";
import { observer } from "mobx-react";

import { MenuItem } from "@components/MenuItem";
import { MentionMode } from "@components/Reports/Editor/Extentions/Mention/types";
import { Tooltip } from "@components/Tooltip";

import "./ReportTagContextMenu.scss";

interface IReportBlockContextMenuProps {
  activeMode: MentionMode;
  isSelfReference?: boolean;
  onPasteAsLink(): void;
  onPasteAsMention(): void;
  onPasteAsPreview(): void;
  onChangeActiveMode(mode: MentionMode): void;
  onConfirm(): void;
}

interface IReportTagContextMenuItem {
  mode: MentionMode;
  tooltipMessage?: string;
  disabled?: boolean;
  onClick(): void;
}

const ReportTagContextMenu = (props: IReportBlockContextMenuProps) => {
  const { activeMode, isSelfReference, onPasteAsLink, onPasteAsMention, onPasteAsPreview, onChangeActiveMode, onConfirm } = props;

  const menuItems: IReportTagContextMenuItem[] = useMemo(
    () => [
      {
        mode: MentionMode.Mention,
        onClick: onPasteAsMention,
      },
      {
        mode: MentionMode.Link,
        onClick: onPasteAsLink,
      },
      {
        mode: MentionMode.Preview,
        onClick: onPasteAsPreview,
        tooltipMessage: isSelfReference ? "Cannot use mention preview for self-reference mentions" : undefined,
        disabled: isSelfReference,
      },
    ],
    [isSelfReference, onPasteAsLink, onPasteAsMention, onPasteAsPreview]
  );

  const selectPrevOption = useCallback(() => {
    const index = menuItems.findIndex(item => item.mode === activeMode);
    if (index === 0) {
      const lastIndex = menuItems.length - 1;
      onChangeActiveMode(menuItems[lastIndex].mode);
    } else {
      onChangeActiveMode(menuItems[index - 1].mode);
    }
  }, [menuItems, activeMode, onChangeActiveMode]);

  const selectNextOption = useCallback(() => {
    const index = menuItems.findIndex(item => item.mode === activeMode);
    if (index < menuItems.length - 1) {
      onChangeActiveMode(menuItems[index + 1].mode);
    } else {
      onChangeActiveMode(menuItems[0].mode);
    }
  }, [menuItems, activeMode, onChangeActiveMode]);

  const handleMouseEnter = (mode: MentionMode) => {
    onChangeActiveMode(mode);
  };

  const handleKeyDown = useCallback(
    (e: KeyboardEvent) => {
      if (e.key === "ArrowDown") {
        e.preventDefault();
        selectNextOption();
      } else if (e.key === "ArrowUp") {
        e.preventDefault();
        selectPrevOption();
      } else if (e.key === "Enter" || e.key === "Escape") {
        e.stopPropagation();
        onConfirm();
      }
    },
    [onConfirm, selectNextOption, selectPrevOption]
  );

  useEffect(() => {
    document.body.addEventListener("keydown", handleKeyDown, { capture: true });
    return () => {
      document.body.removeEventListener("keydown", handleKeyDown, { capture: true });
    };
  }, [handleKeyDown]);

  return (
    <Menu className="report-tag-context-menu">
      {menuItems.map(item => (
        <Tooltip key={item.mode} content={item.tooltipMessage ?? ""} disabled={!item.tooltipMessage} position={PopoverPosition.RIGHT}>
          <MenuItem
            onClick={item.onClick}
            onMouseEnter={() => handleMouseEnter(item.mode)}
            active={activeMode === item.mode}
            text={`Paste as ${item.mode.toLowerCase()}`}
            disabled={item.disabled}
            e2eIdentifiers={`paste-as-${item.mode}`}
          />
        </Tooltip>
      ))}
    </Menu>
  );
};

export default observer(ReportTagContextMenu);
