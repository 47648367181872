import { Text, TextVariant } from "@ui/Text";
import classNames from "classnames";

import { Button } from "@components/Button";
import { getAssetLink } from "@utilities/LinkUtils";

import ImageUploaderCardOverlay from "./ImageUploaderCardOverlay";

import styles from "./ImageUploaderCardImage.module.scss";

type Props = {
  src?: string;
  error?: string;
  className?: string;
  height?: number;
};

const DEFAULT_HEIGHT = 192;

const ImageUploaderCardImage = (props: Props) => {
  const { height = DEFAULT_HEIGHT, src, error, className } = props;
  const hasValidaImage = !!src && !error;

  return (
    <div
      className={classNames(styles.imageUploaderCardImage, className)}
      style={{ backgroundImage: hasValidaImage ? `url(${getAssetLink(src)})` : undefined, height }}
    >
      <ImageUploaderCardOverlay showOnHover={hasValidaImage}>
        {hasValidaImage && <Button text="Change image" e2eIdentifiers="change-image" />}
        {!hasValidaImage && <Button icon="media" text="Upload image" minimal e2eIdentifiers="upload-image" />}
        {error && (
          <Text variant={TextVariant.Caption} className={styles.imageUploaderCardImageError}>
            {error}
          </Text>
        )}
      </ImageUploaderCardOverlay>
    </div>
  );
};

export default ImageUploaderCardImage;
