import { Divider, Intent, Menu } from "@blueprintjs/core";
import { useWorkspace } from "@hooks/useWorkspace";
import { DataSourceQOFactory } from "@queries/DataSourceQOFactory";
import { useMutation } from "@tanstack/react-query";
import { observer } from "mobx-react-lite";

import { MenuItem } from "@components/MenuItem";
import { MenuItemDelete } from "@components/MenuItems";
import { TitledTooltip } from "@components/Shared";
import { showToast } from "@components/UiLayers/toaster";
import { GoogleDriveMimeType, IntegrationProvider } from "@rollup-api/models";
import appStore from "@store/AppStore";
import { IAttachment } from "@store/AttachmentStore";
import { createCopyToClipboardHandler } from "@utilities";

import { getAttachmentLink, isAttachmentGoogleDriveFile } from "../utils";

interface IAttachmentContextMenuUrlProps {
  attachment: IAttachment;
  handleDelete(): void;
  openEditingLabel(): void;
  setIsDetailsDialogOpen(): void;
}

const AttachmentContextMenuUrl = ({
  attachment,
  handleDelete,
  openEditingLabel,
  setIsDetailsDialogOpen,
}: IAttachmentContextMenuUrlProps) => {
  const workspace = useWorkspace();
  const addDataSourceMutation = useMutation(DataSourceQOFactory.createPostQO(workspace.id));

  const existingDataSource = [...workspace.dataConnection.dataSourceMap.values()].find(ds => ds.label === attachment.label);
  const canAddAsDataSource =
    isAttachmentGoogleDriveFile(attachment) && attachment.fileType === GoogleDriveMimeType.SPREADSHEET && attachment.metadata?.fileId;

  const handleAddAsDataSource = async () => {
    const integration = appStore.orgModel?.connectedProviders?.get(IntegrationProvider.Google);

    if (!canAddAsDataSource || !integration) {
      showToast("Cannot create data source: no matching integration was found", Intent.WARNING, "error");
      return;
    }

    addDataSourceMutation.mutate({
      label: attachment.label,
      integrationId: integration.id,
      provider: attachment.metadata?.provider,
      metadata: {
        fileId: attachment.metadata?.fileId,
        mimeType: attachment.fileType,
      },
    });
  };

  const handleCopyLink = () => {
    const linkUrl = getAttachmentLink(attachment);
    if (linkUrl) {
      const copyToClipboard = createCopyToClipboardHandler(linkUrl, "attachment link");
      copyToClipboard();
    }
  };

  return (
    <Menu>
      <MenuItem icon="info-sign" text="Details" intent={Intent.NONE} onClick={setIsDetailsDialogOpen} e2eIdentifiers="details" />
      <MenuItem icon="link" text="Copy link" intent={Intent.NONE} onClick={handleCopyLink} e2eIdentifiers="copy-link" />
      {canAddAsDataSource && (
        <TitledTooltip
          hoverOpenDelay={500}
          content={
            <span>
              A data source can be a spreadsheet, a database, or a web service.
              <br />
              Custom queries can be combined with a data source name in the property
              <br />
              expression editor in order to extract specific data for calculations.
            </span>
          }
          title="This attachment can be added as a data source"
          position="left"
        >
          <MenuItem
            icon="data-connection"
            text="Add as data source"
            intent={Intent.NONE}
            onClick={handleAddAsDataSource}
            disabled={!!existingDataSource}
            e2eIdentifiers="add-data-connection"
          />
        </TitledTooltip>
      )}
      <Divider />
      <MenuItem icon="edit" text="Edit label" intent={Intent.NONE} onClick={openEditingLabel} e2eIdentifiers="edit-label" />
      <MenuItemDelete onDelete={handleDelete} />
    </Menu>
  );
};

export default observer(AttachmentContextMenuUrl);
