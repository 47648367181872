import { NonIdealState, Spinner } from "@blueprintjs/core";
import { observer } from "mobx-react";

import UserInfo from "@components/UserInfo/UserInfo";
import { IAttachment } from "@store/AttachmentStore";
import { formatDate } from "@utilities";
import { Text, TextVariant } from "src/ui/Text";

import "./ModelDetailsPanel.scss";

type ModelDetailsPanelProps = {
  attachment: IAttachment;
};

const ModelDetailsPanel = ({ attachment }: ModelDetailsPanelProps) => {
  const renderVersionHistory = () => {
    if (attachment.fetchingVersions) {
      return (
        <NonIdealState className="hoops-right-panel-model-details--padded" icon={<Spinner size={16} />} title="Loading version history" />
      );
    }
    if (!attachment.sortedVersions?.length) {
      return <NonIdealState className="hoops-right-panel-model-details--padded" icon="info-sign" title="No previous versions" />;
    }

    const createdVersion = attachment.sortedVersions[attachment.sortedVersions.length - 1];
    const updatedVersions = attachment.sortedVersions.slice(0, attachment.sortedVersions.length - 1);
    return (
      <>
        <Text variant={TextVariant.H5}>Added by</Text>
        <div className="hoops-right-panel-model-details--property">
          <UserInfo userId={createdVersion.createdBy} size="small" />
          <Text variant={TextVariant.Caption}>{formatDate(attachment.createdAt)}</Text>
        </div>
        {updatedVersions.map(version => (
          <div className="hoops-right-panel-model-details--property" key={version.id}>
            <UserInfo userId={version.createdBy} size="small" />
            <Text variant={TextVariant.Caption}>{formatDate(version.lastModified)}</Text>
          </div>
        ))}
      </>
    );
  };

  return (
    <>
      <div className="hoops-right-panel-model-details--header">
        <Text variant={TextVariant.H3}>Model details</Text>
      </div>
      <div className="hoops-right-panel-model-details">
        <div className="hoops-right-panel-model-details--section">
          <div className="hoops-right-panel-model-details--property">
            <Text variant={TextVariant.Caption}>Type</Text>
            <Text variant={TextVariant.Body}>{attachment.fileType}</Text>
          </div>
          <div className="hoops-right-panel-model-details--property">
            <Text variant={TextVariant.Caption}>Added by</Text>
            <UserInfo user={attachment.user} size="small" />
            <Text variant={TextVariant.Caption}>{formatDate(attachment.createdAt)}</Text>
          </div>
          <div className="hoops-right-panel-model-details--property">
            <Text variant={TextVariant.Caption}>Size</Text>
            <Text variant={TextVariant.Body}>{attachment.formattedFileSize}</Text>
          </div>
          <div className="hoops-right-panel-model-details--property">
            <Text variant={TextVariant.Caption}>Label</Text>
            <Text variant={TextVariant.Body}>{attachment.label}</Text>
          </div>
          <div className="hoops-right-panel-model-details--property">
            <Text variant={TextVariant.Caption}>File name</Text>
            <Text variant={TextVariant.Body}>{attachment.name}</Text>
          </div>
        </div>
        <div className="hoops-right-panel-model-details--header">
          <Text variant={TextVariant.H3}>Version history</Text>
        </div>
        <div className="hoops-right-panel-model-details--section">{renderVersionHistory()}</div>
      </div>
    </>
  );
};

export default observer(ModelDetailsPanel);
