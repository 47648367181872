import { AxiosResponse } from "axios";

import { Favorite } from "@rollup-api/models/favorites/favorite.model";
import { ICreateFavoriteDto } from "@rollup-api/models/favorites/favorites.dto";
import { trackSegmentEvent } from "src/lib/Segment";

import { HttpClient, ParentClient } from "./client";

export class Favorites extends HttpClient {
  public constructor(parent: ParentClient) {
    super(parent);
  }

  private get endpoint() {
    return `workspace/${this.parent.workspaceId}/favorites`;
  }

  public create = (dto: ICreateFavoriteDto): Promise<AxiosResponse<Favorite>> => {
    trackSegmentEvent("favorite:create", dto);
    return this.instance.post<Favorite>(`${this.endpoint}`, dto);
  };

  public delete = (id: string): Promise<AxiosResponse<Favorite>> => {
    trackSegmentEvent("favorite:delete", { id });
    return this.instance.delete<Favorite>(`${this.endpoint}/${id}`);
  };
}
