import { MouseEvent, useEffect, useState } from "react";
import { Menu } from "@blueprintjs/core";
import classNames from "classnames";
import { observer } from "mobx-react";

import { Button } from "@components/Button";
import { MenuItem } from "@components/MenuItem";
import { MenuItemDelete } from "@components/MenuItems";
import CommentHeader from "@components/Modeling/ModelingFrame/ModelBlock/Comments/CommentHeader";
import { Popover } from "@components/Popover";
import SimpleCommentEditor from "@components/SimpleCommentEditor/SimpleCommentEditor";
import { showToast } from "@components/UiLayers/toaster";
import appStore from "@store/AppStore";
import { IComment } from "@store/CommentStore";
import { copyToClipboard } from "@utilities";
import { getDeepLinkUrl } from "@utilities/GetDeepLinkUrl";

import { trackSegmentEvent } from "../../lib/Segment";

import styles from "./AnnotationItem.module.scss";

interface IUserData {
  imageSource?: string;
  userName?: string;
}

export interface AnnotationItemProps {
  comment: IComment;
  hideCopyLinkButton?: boolean;
  className?: string;
  shouldFlash?: boolean;
  onClick?(e: MouseEvent): void;
  onEditingComment?(isEditing: boolean): void;
}

const AnnotationItem = (props: AnnotationItemProps) => {
  const { comment, hideCopyLinkButton, className, shouldFlash } = props;
  const { onClick, onEditingComment } = props;
  const [userData, setUserData] = useState<IUserData>({});
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    appStore.orgModel?.info.getUserProfile(comment.createdBy).then(userProfile => {
      if (userProfile) {
        setUserData({
          imageSource: userProfile.avatarUrl,
          userName: userProfile.name,
        });
      }
    });
  }, [comment]);

  const handleEditingChange = (value: boolean) => {
    setIsEditing(value);
    onEditingComment?.(value);
  };

  const handleConfirm = (value: string) => {
    if (comment.workspaceId) {
      comment.update({ text: value, attachmentIdList: [] });
    }
    handleEditingChange(false);
  };

  const handleDeleteComment = () => {
    trackSegmentEvent("annotation:delete", { annotationId: comment.id });
    comment.delete();
  };

  const handleCopyLink = async () => {
    const commentUrl = getDeepLinkUrl({ annId: comment.displayParentId || undefined, commId: comment.id });
    try {
      await copyToClipboard(commentUrl);
      showToast(`Copied comment link to clipboard`, "success", "info-sign");
    } catch (err) {
      console.error("Error while copying to clipboard", err);
      return;
    }
  };

  return (
    <div
      className={classNames(styles.annotationItem, {
        [styles.annotationItemFlash]: shouldFlash,
        className,
      })}
      onClick={onClick}
    >
      <CommentHeader
        userName={userData.userName}
        avatarUrl={userData.imageSource}
        createdAt={comment.createdAt}
        updatedAt={comment.updatedAt}
        formattedUpdatedAt={comment.formattedUpdatedAt}
      />
      <span>
        {!hideCopyLinkButton && (
          <span className={styles.copyLinkButton}>
            <Button minimal icon="link" intent="none" onClick={handleCopyLink} e2eIdentifiers="copy-link" />
          </span>
        )}
        {(comment.isMyComment || hideCopyLinkButton) && (
          <Popover
            content={
              <Menu>
                {hideCopyLinkButton && <MenuItem text="Copy link" icon="link" onClick={handleCopyLink} e2eIdentifiers="copy-link" />}
                {comment.isMyComment && (
                  <MenuItem text="Edit" icon="edit" onClick={() => handleEditingChange(true)} e2eIdentifiers="edit" />
                )}
                {comment.isMyComment && <MenuItemDelete onDelete={handleDeleteComment} />}
              </Menu>
            }
            position="bottom-left"
          >
            <Button icon="more" minimal e2eIdentifiers={["smart-annotation-item", "more"]} />
          </Popover>
        )}
      </span>

      <span className={styles.itemEditor}>
        <SimpleCommentEditor content={comment.text} editable={comment.isMyComment && isEditing} onConfirm={handleConfirm} />
      </span>
    </div>
  );
};

export default observer(AnnotationItem);
