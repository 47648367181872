import { MouseEvent } from "react";
import { Tag } from "@blueprintjs/core";
import { observer } from "mobx-react";

import { MenuItem } from "@components/MenuItem";
import { getIntentClassFromColor } from "@utilities";

import styles from "./MenuItemCreateNewTag.module.scss";

interface IMenuItemCreateNewTagProps {
  query: string;
  color?: string;
  active?: boolean;
  onClick(value: string, e: MouseEvent<HTMLElement>): void;
}

const MenuItemCreateNewTag = (props: IMenuItemCreateNewTagProps) => {
  const { query, color, active, onClick } = props;
  const tagClassName = color && getIntentClassFromColor(color);
  return (
    <MenuItem
      className={styles.menuItemCreateNewTag}
      icon="add"
      active={active}
      selected={false}
      shouldDismissPopover
      text={
        <div className={styles.menuItemCreateNewTagContent}>
          <div>Create</div>
          <Tag className={tagClassName}>{query}</Tag>
        </div>
      }
      onClick={e => onClick(query, e)}
      e2eIdentifiers="create-new-item"
    />
  );
};

export default observer(MenuItemCreateNewTag);
