import { ContextMenuManager } from "@components/Modeling/ModelingFrame/HoopsViewer/UI/HoopsCustomOperators/RightClickOperator/ContextMenuManager";
import { CustomOperatorNames } from "@store/HoopsViewerStore";

export class ContextMenuOperator extends Communicator.Operator.OperatorBase {
  private _contextMenuButton = Communicator.Button.Right;
  private _defaultActionButton = Communicator.Button.Left;
  private _operatorName = CustomOperatorNames.ContextMenuOperator;
  private _contextMenuManager: ContextMenuManager;

  public constructor(viewer: Communicator.WebViewer, contextMenuManager: ContextMenuManager) {
    super(viewer);
    this._contextMenuManager = contextMenuManager;
  }

  public getOperatorName(): string {
    return this._operatorName;
  }

  public async onMouseDown(event: Communicator.Event.MouseInputEvent): Promise<void> {
    super.onMouseDown(event);

    if (this.isActive()) {
      this._dragging = false;

      if (event.getButton() === this._contextMenuButton) {
        const pickConfig = new Communicator.PickConfig(Communicator.SelectionMask.Face);
        const selection = await this._viewer.view.pickFromPoint(event.getPosition(), pickConfig);

        const canHandleClicked = selection.overlayIndex() === 0 && selection.isFaceSelection() && selection.getNodeId();
        if (canHandleClicked) {
          this._contextMenuButtonClicked(selection.getNodeId(), selection.getPosition());
        }
      }

      if (event.getButton() === this._defaultActionButton && this._contextMenuManager.isContextMenuActive()) {
        this._defaultActionButtonClicked();
      }
    }
  }

  private async _contextMenuButtonClicked(nodeId: number, selectionPosition: Communicator.Point3): Promise<void> {
    const screenPos = this._viewer.view.projectPoint(selectionPosition);
    this._contextMenuManager.activateContextMenu(nodeId, screenPos.x, screenPos.y);
  }

  private async _defaultActionButtonClicked(): Promise<void> {
    this._contextMenuManager.deactivateContextMenu();
  }
}
