import { CodeBlock, CreateCodeBlockDto, UpdateCodeBlockDto } from "@rollup-api/models/code-blocks";
import { ExecutionResult } from "@rollup-api/models/execution-environments";

import { HttpClient, ParentClient } from "../client";

export class CodeBlocks extends HttpClient {
  public constructor(parent: ParentClient) {
    super(parent);
  }

  private get endpoint() {
    return `workspace/${this.parent.workspaceId}/code-blocks`;
  }

  public create = (dto: CreateCodeBlockDto) => {
    return this.instance.post<CodeBlock>(this.endpoint, dto);
  };

  public retrieve = (id: string) => {
    return this.instance.get<CodeBlock>(`${this.endpoint}/${id}`);
  };

  public update = (id: string, dto: UpdateCodeBlockDto) => {
    return this.instance.patch<CodeBlock>(`${this.endpoint}/${id}`, dto);
  };

  public delete = (id: string) => {
    return this.instance.delete<CodeBlock>(`${this.endpoint}/${id}`);
  };

  public run = (id: string) => {
    return this.instance.post<ExecutionResult>(`${this.endpoint}/${id}/run`);
  };
}
