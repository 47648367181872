import { useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { InputGroup } from "@blueprintjs/core";
import { observer } from "mobx-react";

import BlocksTree from "@components/BlocksTree";
import { Button } from "@components/Button";
import SearchResults from "@components/SearchResults";
import { useAppNavigate } from "@router/hooks";
import { IBlock } from "@store/BlockStore";
import { getLabelsPath } from "@utilities/Block";

import { NodeInfo } from "../ModelingFrame/Table/TableComponent/types";
import { blockNodeList } from "../ModelingFrame/Table/utils";

import "./ModelTree.scss";

/** Type Defs. */
type ModelTreeProps = {
  expanded?: boolean;
  showSearch?: boolean;
};

/** Main function. */
function ModelTree({ expanded = true, showSearch }: ModelTreeProps) {
  const inputGroupRef = useRef<HTMLInputElement>(null);
  const [searchValue, setSearchValue] = useState("");
  const { navigateToBlock } = useAppNavigate();
  const { blockId } = useParams();

  const filterByBlockLabel = (nodeInfo: NodeInfo) =>
    nodeInfo.block && nodeInfo.block.label.toLowerCase().includes(searchValue.toLowerCase());

  const searchResult = blockNodeList().filter(filterByBlockLabel) as Array<{ block: IBlock }>;
  const formattedSearchResult = searchResult.map(r => ({ id: r.block.id, path: getLabelsPath(r.block), label: r.block.label }));

  return (
    <div className="model-tree">
      {showSearch && (
        <InputGroup
          inputRef={inputGroupRef}
          leftElement={<Button minimal icon="search" onClick={() => inputGroupRef.current?.focus()} e2eIdentifiers="focus" />}
          rightElement={searchValue ? <Button minimal icon="cross" onClick={() => setSearchValue("")} e2eIdentifiers="clear" /> : undefined}
          value={searchValue}
          onChange={e => setSearchValue(e.target.value)}
          placeholder="Search"
          className="model-tree--search"
        />
      )}
      {searchValue ? (
        <SearchResults activeId={blockId} onClick={navigateToBlock} items={formattedSearchResult} />
      ) : (
        <BlocksTree expanded={expanded} />
      )}
    </div>
  );
}

/** Exports. */
export default observer(ModelTree);
