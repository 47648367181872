import { CustomOperatorNames } from "@store/HoopsViewerStore";

export class KeyPressListenerOperator extends Communicator.Operator.OperatorBase {
  private _operatorName = CustomOperatorNames.KeyPressListenerOperator;

  public constructor(viewer: Communicator.WebViewer) {
    super(viewer);
  }

  getOperatorName(): string {
    return this._operatorName;
  }

  public onKeyDown(event: Communicator.Event.KeyInputEvent): void {
    const keyCode = event.getKeyCode();
    switch (keyCode) {
      case Communicator.KeyCode.Escape:
        this._viewer.selectionManager.clear();
        break;
      default:
        break;
    }
  }
}
