import { RefObject } from "react";
// eslint-disable-next-line no-restricted-imports
import { Button as BPButton, ButtonProps, PopoverPosition, TooltipProps } from "@blueprintjs/core";
import classNames from "classnames";
import { observer } from "mobx-react";

import { withDataTestId } from "@components/Shared/WithDataTestId";
import { Tooltip } from "@components/Tooltip";
import { ElementType } from "@utilities/E2EUtils";

import styles from "./Button.module.scss";

export interface IButtonProps extends ButtonProps {
  active?: boolean;
  underline?: boolean;
  buttonRef?: RefObject<HTMLButtonElement>;
  highlight?: boolean;
  tooltip?: string;
  tooltipProps?: Omit<TooltipProps, "content">;
}

const Button = (props: IButtonProps) => {
  const { buttonRef, className, active, underline, highlight, tooltip, tooltipProps, ...restProps } = props;

  const renderButton = () => {
    return (
      <BPButton
        ref={buttonRef}
        className={classNames(styles.button, className, {
          [styles.buttonActive]: active,
          [styles.buttonUnderline]: underline,
          [styles.buttonHighlight]: highlight,
        })}
        {...restProps}
      />
    );
  };

  if (tooltip) {
    return (
      <Tooltip content={tooltip} position={PopoverPosition.TOP} {...tooltipProps}>
        {renderButton()}
      </Tooltip>
    );
  }

  return renderButton();
};

export default withDataTestId(observer(Button), ElementType.Button);
