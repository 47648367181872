import { observer } from "mobx-react";

import appStore from "@store/AppStore";

import styles from "./StatusBar.module.scss";

const StatusBar = () => {
  const selectedCatalogItems = appStore.ui.selectedCatalogItemIds.length;
  const total = appStore.orgModel.catalogItems.totalItems;

  if (!total) {
    return null;
  }

  return (
    <div className={styles.statusBar}>
      {selectedCatalogItems || total} of {total} items {!!selectedCatalogItems && "selected"}
    </div>
  );
};

export default observer(StatusBar);
