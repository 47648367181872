import { Classes, Menu, MenuDivider, Tooltip } from "@blueprintjs/core";
import { observer } from "mobx-react";
import moment from "moment";

import gitInfo from "@assets/git-info.json";
import { MenuItem } from "@components/MenuItem";
import appStore from "@store/AppStore";
import { createCopyToClipboardHandler, openDocumentationLink } from "@utilities";
import packageInfo from "src/../package.json";

import "./HelpMenu.scss";

const HelpMenu = () => {
  const buildDate = moment(gitInfo.datetime)?.format("MMM Do YYYY") ?? "";

  const copyCommitToClipboard = createCopyToClipboardHandler(gitInfo, "commit info");
  const copyVersionToClipboard = createCopyToClipboardHandler(packageInfo.version, "version");
  const copyBuildDateToClipboard = createCopyToClipboardHandler(buildDate, "build date");

  return (
    <Menu className={Classes.RUNNING_TEXT}>
      <MenuDivider title="Get Help" />
      <MenuItem icon="book" text="Documentation" onClick={openDocumentationLink} e2eIdentifiers="documentation" />
      <MenuItem icon="comment" text="Leave feedback" onClick={appStore.feedback.show} e2eIdentifiers="leave-feedback" />
      <MenuDivider title="About" />
      <Tooltip className="help-menu--tooltip" content="Copy version number">
        <MenuItem
          text={
            <>
              Version <code>{packageInfo.version}</code>
            </>
          }
          onClick={copyVersionToClipboard}
          e2eIdentifiers="version"
        />
      </Tooltip>
      <Tooltip className="help-menu--tooltip" content="Copy build date">
        <MenuItem
          text={
            <>
              Build date <code>{buildDate}</code>
            </>
          }
          onClick={copyBuildDateToClipboard}
          e2eIdentifiers="build-date"
        />
      </Tooltip>
      <Tooltip className="help-menu--tooltip" content="Copy commit info">
        <MenuItem
          text={
            <>
              Commit <code>{gitInfo.shortHash}</code>
            </>
          }
          onClick={copyCommitToClipboard}
          e2eIdentifiers="commit"
        />
      </Tooltip>
    </Menu>
  );
};

export default observer(HelpMenu);
