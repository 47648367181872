// production https://app.frigade.com/flows/flow_kmWj0WRf5JcQSf1U/overview
// development https://app.frigade.com/flows/flow_XhzIFglrkAoXjAHR/overview

import * as Frigade from "@frigade/react";

import "./Survey.scss";

const FrigadeSurvey = () => {
  const flowId = import.meta.env.VITE_ENV_TYPE !== "production" ? "flow_XhzIFglrkAoXjAHR" : "flow_kmWj0WRf5JcQSf1U";

  return <Frigade.Survey.NPS className="frigade-survey" flowId={flowId} />;
};

export default FrigadeSurvey;
