import { CustomOperatorNames } from "@store/HoopsViewerStore";

import { AnnotationMarkupManager } from "./AnnotationMarkupManager";

export class AnnotationOperator extends Communicator.Operator.OperatorBase {
  private _insertAnnotationButton = Communicator.Button.Left;
  private _annotationMarkupManager: AnnotationMarkupManager;
  private _operatorName = CustomOperatorNames.AnnotationOperator;
  private _cursor: Communicator.Operator.Common.PointCursor;
  private _cameraInteractionActive = false;

  public constructor(viewer: Communicator.WebViewer, annotationMarkupManager: AnnotationMarkupManager) {
    super(viewer);
    this._annotationMarkupManager = annotationMarkupManager;
    this._cursor = new Communicator.Operator.Common.PointCursor(this._viewer);
    this._viewer.setCallbacks({
      beginInteraction: () => {
        this._onBeginInteraction();
      },
      endInteraction: () => {
        this._onEndInteraction();
      },
    });
  }

  private _onBeginInteraction() {
    this._cameraInteractionActive = true;
    this._cursor.activateCursorSprite(false);
  }

  private _onEndInteraction() {
    this._cameraInteractionActive = false;
  }

  private _draw(): void {
    this._cursor.draw();
    this._viewer.markupManager.refreshMarkup();
  }

  /**
   * Determine if the given mouse event should cause snapping. This is influenced by
   * the snap configuration enabled value.
   */
  private _useSnapping(event: Communicator.Event.MouseInputEvent): boolean {
    // COM-1683 Changed behavior to default to snapping unless the alt-key is down
    return this._cursor.snappingConfig.enabled && !event.altDown();
  }

  getOperatorName(): string {
    return this._operatorName;
  }

  public onMouseDown(event: Communicator.Event.MouseInputEvent): void {
    super.onMouseDown(event);
    if (this.isActive()) {
      this._dragging = false;
    }
  }

  public onMouseUp(event: Communicator.Event.MouseInputEvent): void {
    if (this.isActive()) {
      const pickConfig = new Communicator.PickConfig(Communicator.SelectionMask.Face);
      if ((this._ptFirst.equals(this._ptCurrent) && event.getButton() === this._insertAnnotationButton) || this._primaryTouchId !== null) {
        const p = this._viewer.view.pickFromPoint(event.getPosition(), pickConfig).then(selection => {
          if (
            !this._annotationMarkupManager.getExplodeActive() &&
            !this._annotationMarkupManager.getIsolateActive() &&
            selection.overlayIndex() === 0
          ) {
            if (selection.isFaceSelection()) {
              this._annotationMarkupManager.createAnnotationAndMarkupViaUserAction(
                selection.getPosition(),
                selection.getFaceEntity().getNormal(),
                selection.getNodeId(),
                selection.getFaceEntity().getCadFaceIndex()
              );
            }
            event.setHandled(true);
          }
        });
        p as Communicator.Internal.UnusedPromise;
      }
    }

    super.onMouseUp(event);
  }

  public onMouseMove(event: Communicator.Event.MouseInputEvent): void {
    super.onMouseMove(event);
    if (!this._cameraInteractionActive) {
      const mousePosition = event.getPosition();
      this._cursor.updateCursorSprite(mousePosition, this._useSnapping(event), null);
    }
    this._draw();
  }

  public onActivate(): void {
    this._cursor.onOperatorActivate();
  }

  public onDeactivate(): void {
    this._cursor.onOperatorDeactivate();
  }
}
