import { useEffect, useState } from "react";
import { observer } from "mobx-react";

import Avatar from "@components/Avatar/Avatar";
import { Button } from "@components/Button";
import { Profile } from "@rollup-api/models";
import { TemporalDirection } from "@rollup-api/models/comments/commentGetThreadedRequestDto.model";
import appStore from "@store/AppStore";
import { COMMENT_THREAD_LIST_PARENT_TAKE } from "@store/CommentThreadListStore";
import { Text, TextVariant } from "src/ui/Text";

import "./ShowMoreButton.scss";
interface ShowMoreButtonProps {
  remainingNextSetOfCommenters: string[];
  remainingCommentsCount: number;
  temporalDirection: Exclude<TemporalDirection, TemporalDirection.Both>;
  showChildren?: boolean;
  onShowMore: () => void;
}

const ShowMoreButton = ({
  remainingNextSetOfCommenters,
  remainingCommentsCount,
  temporalDirection,
  showChildren,
  onShowMore,
}: ShowMoreButtonProps) => {
  const [userImages, setUserImages] = useState<string[]>([]);
  const [additionalCommentersCount, setAdditionalCommentersCount] = useState<number>(0);

  useEffect(() => {
    const getAndSetUserImages = async (): Promise<void> => {
      let numberOfCommentersToDisplay = 2;
      if (remainingNextSetOfCommenters.length > 3) {
        setAdditionalCommentersCount(remainingNextSetOfCommenters.length - numberOfCommentersToDisplay);
      } else {
        numberOfCommentersToDisplay = remainingNextSetOfCommenters.length;
      }
      const slicedUserIds = remainingNextSetOfCommenters.slice(0, numberOfCommentersToDisplay);

      const images: string[] = await Promise.all(
        slicedUserIds.map(async userId => {
          const userProfile: Profile | null = await appStore.orgModel?.info.getUserProfile(userId);
          return userProfile?.avatarUrl || "";
        })
      );
      if (images.length > 0) {
        setUserImages(images);
        return;
      }
    };

    if (remainingNextSetOfCommenters.length > 0) {
      getAndSetUserImages();
    }
  }, [remainingNextSetOfCommenters, remainingCommentsCount]);

  if (remainingCommentsCount === 0) {
    return null;
  }

  const showMoreCount = remainingCommentsCount > COMMENT_THREAD_LIST_PARENT_TAKE ? COMMENT_THREAD_LIST_PARENT_TAKE : remainingCommentsCount;
  let showMoreEntityName = "";
  const showMoreDirection = temporalDirection === TemporalDirection.Older ? "previous" : "subsequent";
  if (showChildren) {
    showMoreEntityName = showMoreCount > 1 ? "replies" : "reply";
  } else {
    showMoreEntityName = showMoreCount > 1 ? "threads" : "thread";
  }
  const showMorePhrase = `Show ${showMoreCount} ${showMoreDirection} ${showMoreEntityName}`;

  return (
    <div className="show-more-button">
      <div className="show-more-button--next-set-of-commenters">
        {Array.from(userImages).map((image, index) => (
          <div key={index}>
            <Avatar source={image} size={20} />
          </div>
        ))}
        {additionalCommentersCount > 0 && (
          <div className="show-more-button--additional-commenters-count">
            <Text variant={TextVariant.Label}>+{additionalCommentersCount}</Text>
          </div>
        )}
      </div>
      <Button minimal onClick={onShowMore} e2eIdentifiers="show-more-parent-comments-button">
        <Text variant={TextVariant.Button2Underline}>{showMorePhrase}</Text>
      </Button>
    </div>
  );
};

export default observer(ShowMoreButton);
