import { DialogBody } from "@blueprintjs/core";
import { observer } from "mobx-react";

import { Button } from "@components/Button";
import { CreateCatalogItemDialogType } from "@components/CreateCatalogItemDialog/types";
import { renderIcon, renderText } from "@components/CreateCatalogItemDialog/utils";
import { DialogLegacy } from "@components/Dialog";
import appStore from "@store/AppStore";

import BlockMode from "./Modes/BlockMode";
import CadMode from "./Modes/Cad";
import CsvMode from "./Modes/Csv";
import ManualCreate from "./Modes/ManualCreate";

import "./CreateCatalogItemDialog.scss";

const CreateCatalogItemDialog = () => {
  const mode = appStore.ui.catalogItemCreationModalTab;

  const renderContent = () => {
    switch (mode) {
      case CreateCatalogItemDialogType.CSV:
        return <CsvMode />;
      case CreateCatalogItemDialogType.CAD:
        return <CadMode />;
      case CreateCatalogItemDialogType.Block:
        return <BlockMode />;
      default:
        return <ManualCreate />;
    }
  };

  return (
    <DialogLegacy
      title="Add catalog item"
      className="create-catalog-item-dialog"
      isOpen={!!appStore.ui.catalogItemCreationModalTab}
      canEscapeKeyClose
      onClose={appStore.ui.hideCatalogItemCreationModal}
    >
      <DialogBody>
        <div className="create-catalog-item-dialog--sidebar">
          {Object.values(CreateCatalogItemDialogType).map(type => (
            <Button
              alignText="left"
              minimal
              large
              active={type === mode}
              icon={renderIcon(type)}
              onClick={() => appStore.ui.setCatalogItemCreationModalTab(type)}
              e2eIdentifiers={["set-catalog-item-dialog-mode", type]}
              key={type}
            >
              {renderText(type)}
            </Button>
          ))}
        </div>
        <div className="create-catalog-item-dialog--content">{renderContent()}</div>
      </DialogBody>
    </DialogLegacy>
  );
};

export default observer(CreateCatalogItemDialog);
