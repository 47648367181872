import appStore from "@store/AppStore";
import { EFileUploadStatus, IUploadStore } from "@store/UploadStore";

const fileUploadHooks = (uploadStore: IUploadStore) => {
  const deleteFileUpload = () => {
    if (uploadStore.status === EFileUploadStatus.UPLOADING) {
      uploadStore.cancelUpload();
    }
    appStore.orgModel.uploads.removeFileUploadStore(uploadStore);
  };

  return {
    deleteFileUpload,
  };
};

export default fileUploadHooks;
