import { useState } from "react";
import { AnchorButtonProps, Position, Tab, TabId, Tabs } from "@blueprintjs/core";
import { observer } from "mobx-react";

import { AnchorButton } from "@components/AnchorButton";
import { Icon } from "@components/Icon";
import { Popover } from "@components/Popover";
import { Tooltip } from "@components/Tooltip";
import { Color } from "@rollup-types/colors";
import { IconSource, IIcon } from "@rollup-types/icons";
import appStore from "@store/AppStore";
import { isColorIcon, isColorIconSource } from "@utilities/Icon";

import IconSelectorTab from "./IconSelectorTab";

import styles from "./IconSelector.module.scss";

export interface IconSelectorProps {
  iconSources?: IconSource[];
  currentIcon?: IIcon;
  tooltip?: string;
  buttonProps?: Omit<AnchorButtonProps, "icon">;
  hideColorPicker?: boolean;
  closeOnIconSelect?: boolean;
  onChange?: (icon: IIcon) => void;
}

const defaultIcon: IIcon = { source: IconSource.Blueprint, name: "add" };
const defaultIconSources = [IconSource.Blueprint];

const IconSelector = (props: IconSelectorProps) => {
  const { tooltip, buttonProps, hideColorPicker, onChange } = props;
  const currentIcon = props.currentIcon ?? defaultIcon;
  const { closeOnIconSelect } = props;
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const iconSources = props.iconSources?.length ? props.iconSources : defaultIconSources;
  const [selectedTabId, setSelectedTabId] = useState<TabId>(iconSources[0]);
  const [color, setColor] = useState(isColorIcon(currentIcon) ? currentIcon.color : Color.Default);

  const handleIconSelect = (icon: IIcon, disableReordering?: boolean) => {
    onChange?.({ ...icon, ...(isColorIcon(icon) ? { color } : {}) });
    appStore.env.addRecentIcon(icon, disableReordering);
    if (closeOnIconSelect) {
      setIsPopoverOpen(false);
    }
  };

  const handleIconColorChange = (color: Color) => {
    if (isColorIcon(currentIcon)) {
      onChange?.({ ...currentIcon, color });
    }
    setColor(color);
  };

  const getTabTitle = (source: IconSource) => {
    switch (source) {
      case IconSource.Blueprint:
        return "General";
      case IconSource.DevIcon:
        return "Dev";
      default:
        return source;
    }
  };

  const renderIconSelector = () => {
    return (
      <Tabs className={styles.iconSelectorTabs} selectedTabId={selectedTabId} onChange={setSelectedTabId}>
        {iconSources.map(source => (
          <Tab
            panelClassName={styles.iconSelectorTabPanel}
            key={source}
            id={source}
            title={getTabTitle(source)}
            panel={
              <IconSelectorTab
                key={source}
                iconSource={source}
                color={color}
                hideColorPicker={hideColorPicker || !isColorIconSource(source)}
                onColorChange={handleIconColorChange}
                onIconChange={handleIconSelect}
              />
            }
          />
        ))}
      </Tabs>
    );
  };

  return (
    <Popover isOpen={isPopoverOpen} onInteraction={setIsPopoverOpen} content={renderIconSelector()} position={Position.BOTTOM_LEFT}>
      <Tooltip content={tooltip ?? ""} disabled={!tooltip}>
        <AnchorButton
          className={styles.iconSelectorButton}
          icon={<Icon className={styles.iconSelectorIcon} icon={currentIcon} />}
          e2eIdentifiers="show-icon-selector-menu"
          {...buttonProps}
        />
      </Tooltip>
    </Popover>
  );
};

export default observer(IconSelector);
