import { useEffect, useState } from "react";
import { Checkbox, InputGroup, Intent, Menu } from "@blueprintjs/core";
import { observer } from "mobx-react";

import { Button } from "@components/Button";
import { exportableColumnKeys } from "@components/CatalogItems/Table/pdmColumnDefs";
import { processCellCallback } from "@components/CatalogItems/Table/utils";
import { CreateCatalogItemDialogType } from "@components/CreateCatalogItemDialog/types";
import { Popover } from "@components/Popover";
import { showApiErrorToast } from "@components/UiLayers/toaster";
import appStore from "@store/AppStore";

import styles from "./CatalogItemTableBar.module.scss";

const CatalogItemTableBar = () => {
  const [search, setSearch] = useState("");

  useEffect(() => {
    appStore.env.catalogItemTableGridApi?.setGridOption("quickFilterText", search);
  }, [search]);

  const resetSearch = () => {
    setSearch("");
    appStore.env.catalogItemTableGridApi?.resetQuickFilter();
  };

  const handleExport = () => {
    if (appStore.env.catalogItemTableGridApi) {
      try {
        appStore.env.catalogItemTableGridApi?.exportDataAsCsv({
          fileName: "catalog-items.csv",
          processCellCallback,
          columnKeys: exportableColumnKeys,
        });
      } catch (err) {
        showApiErrorToast("Error exporting data");
      }
    } else {
      showApiErrorToast("Error exporting data");
    }
  };

  const handleToggleThumbnails = () => {
    appStore.env.toggleShowPdmThumbnail();
  };

  const handleToggleFlatten = () => {
    appStore.ui.toggleShowPdmFlatten();
  };

  return (
    <div className={styles.catalogItemTableBar}>
      <Button
        onClick={() => appStore.ui.setCatalogItemCreationModalTab(CreateCatalogItemDialogType.Manual)}
        intent={Intent.PRIMARY}
        icon="add"
        e2eIdentifiers="add-catalog-item"
      >
        Create item
      </Button>
      <Button
        onClick={() => appStore.ui.setCatalogItemCreationModalTab(CreateCatalogItemDialogType.CAD)}
        intent={Intent.PRIMARY}
        icon="import"
        e2eIdentifiers="add-catalog-item"
      >
        Import items
      </Button>
      <InputGroup
        rightElement={<Button e2eIdentifiers="reset-catalog-item-search" minimal icon="cross" onClick={resetSearch} />}
        placeholder="Search"
        value={search}
        onChange={e => setSearch(e.target.value)}
        leftIcon="search"
      />
      <Button minimal disabled e2eIdentifiers="catalog-items-sort-btn" icon="sort">
        Sort
      </Button>
      <Popover
        placement="bottom-start"
        content={
          <Menu className={styles.catalogItemTableBarUiOptionsNav}>
            <li>
              <Checkbox
                className={styles.catalogItemTableBarCheckbox}
                label="Show thumbnail"
                onClick={handleToggleThumbnails}
                checked={appStore.env.showPdmThumbnail}
              />
            </li>
            <li>
              <Checkbox
                className={styles.catalogItemTableBarCheckbox}
                label="Flatten view"
                onClick={handleToggleFlatten}
                checked={appStore.ui.showPdmFlatten}
              />
            </li>
          </Menu>
        }
      >
        <Button e2eIdentifiers="pdm_ui_options" minimal icon="cog">
          UI Options
        </Button>
      </Popover>
      <Button
        onClick={appStore.ui.toggleDeleteCatalogItemsModal}
        minimal
        disabled={!appStore.ui.selectedCatalogItemIds.length}
        e2eIdentifiers="catalog-items-delete-btn"
        icon="trash"
      >
        Delete
      </Button>
      <div className={styles.catalogItemTableBarRightActions}>
        <Button onClick={handleExport} minimal e2eIdentifiers="catalog-items-add-export-btn" icon="export">
          Export
        </Button>
        {appStore.ui.pdmItemVersionProgress && (
          <>
            <Button
              onClick={() => appStore.ui.updatePdmItemVersionProgress(null)}
              intent={Intent.DANGER}
              e2eIdentifiers="catalog-items-discard-version-btn"
            >
              Discard changes
            </Button>
            <Button onClick={appStore.ui.setShowPdmNewVersionDialog} intent={Intent.PRIMARY} e2eIdentifiers="catalog-items-add-version-btn">
              Save updates
            </Button>
          </>
        )}
      </div>
    </div>
  );
};

export default observer(CatalogItemTableBar);
