import { IconName } from "@blueprintjs/core";

export enum EAlignment {
  LEFT = "left",
  CENTER = "center",
  RIGHT = "right",
}

export const alignIcon: Record<EAlignment, IconName> = {
  [EAlignment.LEFT]: "align-left",
  [EAlignment.CENTER]: "align-center",
  [EAlignment.RIGHT]: "align-right",
};
