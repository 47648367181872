import { NodeViewWrapper } from "@tiptap/react";

import AssetImage from "@components/Shared/AssetImage/AssetImage";

type Attributes = {
  width: number;
  height: number;
  src: string;
  className?: string;
};

type Props = {
  node: {
    attrs: Attributes;
  };
};

export const getImageAssetComponent = (url: string, className?: string) =>
  `<image-asset className="${className}" src="${url}"></image-asset>`;

const ImageAssetComponent = (props: Props) => {
  const { src, className } = props.node.attrs;

  return <NodeViewWrapper className="image-asset">{src && <AssetImage className={className} src={src} />}</NodeViewWrapper>;
};

export default ImageAssetComponent;
