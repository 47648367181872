import { Icon, Intent, Spinner, Tooltip } from "@blueprintjs/core";
import { observer } from "mobx-react-lite";

import { Button } from "@components/Button";
import AttachmentIcon from "@components/Modeling/ModelingFrame/ModelBlock/Attachments/AttachmentIcon";
import { ImportStatus } from "@rollup-api/models";
import appStore from "@store/AppStore";
import { IImport } from "@store/ImportStore";

import "./LoadingImportsList.scss";

type Props = {
  importsList: IImport[];
};

const LoadingImportsList = (props: Props) => {
  const { importsList } = props;

  const renderItem = (importItem: IImport) => {
    const orgAttachment = appStore.orgModel.attachments.get(importItem.attachmentId);
    const workspaceAttachment = appStore.workspaceModel?.attachments.get(importItem.attachmentId);
    const attachment = orgAttachment || workspaceAttachment;
    const pending = importItem.status === ImportStatus.PendingApproval;
    const type = attachment?.fileType || "model/";

    return (
      <div key={importItem.id} className="loading-imports-list--item">
        <div className="file-upload-list--data file-upload-list--data-overflow">
          <AttachmentIcon mimeType={type} />
          <span>{importItem.originalFileName}</span>
        </div>
        <div className="file-upload-list--data">
          <span className="file-upload-list--status">{importItem.statusLabel}</span>
          {pending ? (
            <>
              <Icon icon="warning-sign" intent={Intent.WARNING} />
              <Tooltip position="top-right" content="Confirm import">
                <Button minimal onClick={importItem.preview} icon="eye-on" e2eIdentifiers="confirm-import" />
              </Tooltip>
            </>
          ) : (
            <Spinner
              className="file-upload-list--spinner"
              size={18}
              value={importItem.progress ? importItem.progressPercentage : undefined}
              intent={Intent.PRIMARY}
            />
          )}
        </div>
      </div>
    );
  };

  return <div className="loading-imports-list">{importsList.map(renderItem)}</div>;
};

export default observer(LoadingImportsList);
