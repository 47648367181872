import { Breadcrumbs, InputGroup } from "@blueprintjs/core";
import { PropertyDataType } from "@rollup-io/engineering";
import { observer } from "mobx-react";
import { isAlive } from "mobx-state-tree";

import { ScalarExpressionEditor } from "@components/Shared";
import appStore from "@store/AppStore";

import "./Ribbon.scss";

const Ribbon = () => {
  const propertyInstance = appStore.workspaceModel?.propertyInstanceMap.get(appStore.env.tableView.activePropertyInstanceId);
  const statusInstance = appStore.workspaceModel?.statusInstanceMap.get(appStore.env.tableView.activeStatusInstanceId);

  const renderPath = () => {
    if (propertyInstance && propertyInstance.propertyDefinition?.dataType === PropertyDataType.scalar) {
      return (
        <>
          <Breadcrumbs
            className="ribbon--breadcrumbs"
            minVisibleItems={1}
            popoverProps={{ minimal: true, position: "bottom-right" }}
            currentBreadcrumbRenderer={({ text }) => (
              <span className="ribbon--property-path">
                {text}:{propertyInstance.propertyDefinition?.label}
              </span>
            )}
            breadcrumbRenderer={({ text }) => <span className="ribbon--property-path">{text}/</span>}
            items={propertyInstance.parentBlock.path.split("/").map((text: string) => ({ text }))}
          />
          <span className="ribbon--equal">=</span>
        </>
      );
    }
  };

  const renderInput = () => {
    if (propertyInstance && isAlive(propertyInstance) && propertyInstance.propertyDefinition?.dataType === PropertyDataType.scalar) {
      return <ScalarExpressionEditor propertyInstance={propertyInstance} />;
    }

    if (propertyInstance) {
      return <InputGroup disabled className="ribbon--input" value={propertyInstance.value || ""} />;
    }

    if (statusInstance) {
      return <InputGroup disabled className="ribbon--input" value={statusInstance.value || ""} />;
    }
  };

  return (
    <div className="ribbon">
      {renderPath()}
      {renderInput()}
    </div>
  );
};

export default observer(Ribbon);
