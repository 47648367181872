import { useState } from "react";
import { InputGroup, Position } from "@blueprintjs/core";
import { observer } from "mobx-react";

import { BlockPartNumberDark, BlockPartNumberLight } from "@assets/icons/blockTypes";
import Popover from "@components/Popover/Popover";
import { ThemedCustomIcon } from "@components/Shared/LegacyCustomIcon/ThemedCustomIcon";
import PartNumberSchemaSelectorMenu from "@router/components/BlockView/PartNumberSchemaSelectorMenu";
import { IPartNumberSchema } from "@store/PartNumberSchemaStore";

import PartNumberSchemaModal from "../PartNumberSchemaModal";

import "./PartNumber.scss";

type Props = {
  partNumber?: string;
  partNumberSchemas: IPartNumberSchema[];
  onUpdateFromString: (partNumber: string) => void;
  onUpdateFromSchema: (partNumberSchema: IPartNumberSchema) => void;
  onAddNewSchema: (label: string, schema: string, leadingZeroes?: number) => void;
  onSchemaDelete: (schemaId: string) => void;
  asInput?: boolean;
};

const PartNumber = (props: Props) => {
  const { asInput, partNumber = "", onUpdateFromString, onUpdateFromSchema, onAddNewSchema, onSchemaDelete, partNumberSchemas } = props;
  const [showIdModal, setShowIdModal] = useState(false);
  const [schemaToEdit, setSchemaToEdit] = useState<IPartNumberSchema | undefined>(undefined);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  const handleEditSchema = (partNumberSchema: IPartNumberSchema) => {
    setSchemaToEdit(partNumberSchema);
    setShowIdModal(true);
  };

  const handleCloseModal = () => {
    setSchemaToEdit(undefined);
    setShowIdModal(false);
  };

  const renderContent = () => {
    if (asInput) {
      return (
        <div>
          <InputGroup className="part-number--input" value={partNumber} onChange={e => onUpdateFromString(e.target.value)} />
        </div>
      );
    }

    return (
      <div className="part-number--holder">
        {partNumber && (
          <ThemedCustomIcon className="part-number--icon" lightIcon={<BlockPartNumberLight />} darkIcon={<BlockPartNumberDark />} />
        )}
        <span className="part-number--button">{partNumber || "Add part number"}</span>
      </div>
    );
  };

  return (
    <div>
      <PartNumberSchemaModal onAddNew={onAddNewSchema} partNumberSchema={schemaToEdit} isOpen={showIdModal} onClose={handleCloseModal} />
      <Popover
        autoFocus={false}
        isOpen={isPopoverOpen}
        onInteraction={state => setIsPopoverOpen(state)}
        position={Position.BOTTOM_LEFT}
        content={
          <PartNumberSchemaSelectorMenu
            hideNameInput={asInput}
            partNumberSchemas={partNumberSchemas}
            onDelete={onSchemaDelete}
            onEdit={handleEditSchema}
            defaultPartNumber={partNumber}
            onUpdatePartNumber={onUpdateFromString}
            onSetPartNumber={onUpdateFromSchema}
            onAddNew={() => setShowIdModal(true)}
            onClose={() => setIsPopoverOpen(false)}
          />
        }
      >
        {renderContent()}
      </Popover>
    </div>
  );
};

export default observer(PartNumber);
