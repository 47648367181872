import { ReactNode } from "react";
import { observer } from "mobx-react";
import { useWindowSize } from "usehooks-ts";

import { SlidingPanel } from "@components/SlidingPanel";
import { BlockSidePanel } from "@store/SidePanelStore";

import styles from "./RightSidePanels.module.scss";

type IRightSidePanels<T extends string> = {
  activeSidePanel?: T;
  pinnedSidePanels: T[];
  renderPanel(key?: T): ReactNode;
};

const ACTIVE_PANEL_WIDTH = 400;

const calcPinnedPanelWidth = (windowWidth: number, pinnedPanelCount: number) => {
  if (ACTIVE_PANEL_WIDTH + pinnedPanelCount * ACTIVE_PANEL_WIDTH > 0.65 * windowWidth) {
    return ACTIVE_PANEL_WIDTH / 2;
  }

  return ACTIVE_PANEL_WIDTH;
};

const RightSidePanels = <T extends string>(props: IRightSidePanels<T>) => {
  const { activeSidePanel, pinnedSidePanels, renderPanel } = props;
  const pinnedPanels = pinnedSidePanels.filter(panel => panel !== activeSidePanel);
  const { width: windowWidth } = useWindowSize();
  const pinnedPanelWidth = calcPinnedPanelWidth(windowWidth, pinnedPanels.length);

  const calculateRightPanelWidth = () => {
    return ACTIVE_PANEL_WIDTH + pinnedPanels.length * pinnedPanelWidth;
  };

  return (
    <SlidingPanel className={styles.rightSidePanels} isOpen={activeSidePanel !== BlockSidePanel.None} width={calculateRightPanelWidth()}>
      {pinnedPanels.map((panel, index) => (
        <div key={panel} className={styles.pinnedPanel} style={{ zIndex: index, width: pinnedPanelWidth, left: index * pinnedPanelWidth }}>
          {renderPanel(panel)}
        </div>
      ))}
      <div className={styles.activePanel} style={{ width: ACTIVE_PANEL_WIDTH }}>
        {renderPanel(activeSidePanel)}
      </div>
    </SlidingPanel>
  );
};

export default observer(RightSidePanels);
