import { Revision } from "../models/revisions";

import { HttpClient, ParentClient } from "./client";

export class Revisions extends HttpClient {
  public constructor(parent: ParentClient) {
    super(parent);
  }

  private readonly endpoint: string = "/revisions";

  public retrieveList(workspaceId: string) {
    return this.instance.get<Revision[]>(`${this.endpoint}/${workspaceId}`);
  }

  public restore(id: string) {
    return this.instance.post(`${this.endpoint}/${id}/restore`);
  }

  public delete(id: string) {
    return this.instance.delete(`${this.endpoint}/${id}`);
  }

  public create(workspaceId: string, label: string, description?: string) {
    const body = { label, description };
    return this.instance.post<Revision>(`${this.endpoint}/${workspaceId}/create`, body);
  }
}
