import { AxiosResponse } from "axios";
import { cast, flow, getParentOfType, IAnyModelType, Instance, IType, SnapshotIn, SnapshotOut, types } from "mobx-state-tree";
import { v4 as uuidv4 } from "uuid";

import { Comment, CreateCommentDto } from "@rollup-api/models";
import { CommentGetChildrenRequestDto } from "@rollup-api/models/comments/commentGetChildrenRequestDto.model";
import appStore from "@store/AppStore";
import { CommentStore, IComment, ICommentMobxType, ICommentSnapshotIn } from "@store/CommentStore";
import { CommentThreadListStore } from "@store/CommentThreadListStore";
import { convertTimestamp, getBlockById } from "@utilities";
import { isDefined } from "@utilities/TypeGuards";
import { rollupClient } from "src/core/api";

import { mapRtoToSnapshot } from "../services/services.utils";

import { StoreType } from "./types";

export const COMMENT_THREAD_LIST_CHILD_TAKE = 5;

export const CommentThreadStore = types
  .model("CommentThread", {
    id: types.identifier, // id is for FE only, BE isn't aware of comment threads
    parentComment: types.maybe<ICommentMobxType>(types.late((): IAnyModelType => CommentStore)),
    childComments: types.array<ICommentMobxType>(types.late((): IAnyModelType => CommentStore)),
    remainingChildCommentsCount: types.optional(types.number, 0),
    remainingChildCommentsCommenters: types.array(types.string),
  })
  .actions(self => ({
    removeComment(commentId: string) {
      if (self.parentComment?.id === commentId) {
        const parentThreadList = getParentOfType(self, CommentThreadListStore);
        parentThreadList.removeThread(self.id);
        return;
      }
      const commentIndex = self.childComments.findIndex(childComment => childComment.id === commentId);
      // if the comment is found, remove it from the array
      if (commentIndex !== -1) {
        self.childComments.splice(commentIndex, 1);
        return;
      }

      return;
    },
  }))
  .actions(self => ({
    addComment: flow(function* addComment(dto: CreateCommentDto): Generator<any, string | undefined, any> {
      // If "value" has no letters and no images, only other html, do not create new comment.
      const string = dto.text.replace(/<(?!image-asset)(?:.|\n)*?>/gm, "");
      if (!string.length && !dto.attachmentIdList?.length) {
        return;
      }

      // Optimistically create the comment
      const optimisticCommentId = uuidv4();
      const newComment = CommentStore.create({
        ...dto,
        id: optimisticCommentId,
        createdBy: appStore.userModel?.id || "",
        createdAt: Date.now(),
        updatedAt: Date.now(),
        attachments: dto.attachmentIdList?.map(att => cast(att)),
        parentCommentId: self.parentComment?.id || null,
        view: undefined,
        pending: true,
      });
      if (self.parentComment?.id) {
        self.childComments.push(newComment);
      } else {
        self.parentComment = newComment;
      }

      // Try to actually create the comment on the server
      try {
        const { data, status } = yield rollupClient.comments.create({ ...dto, id: optimisticCommentId });
        const comment = data;

        if (status !== 201) {
          console.warn(`Error creating comment`);
          return;
        }

        // Comment was successfully created, update the optimistic comment with the server response
        if (comment) {
          // Check if the attachments are marked as publish to block and add them to the block
          if (dto.attachmentIdList?.length && dto.parentType === StoreType.Block && dto.publishToBlock) {
            const block = getBlockById(dto.parentId);
            if (block) {
              dto.attachmentIdList.forEach(attachmentId => {
                block.addAttachmentRef(attachmentId);
              });
            }
          }
          newComment.setPending(false);
          return comment.id;
        }

        // Posting of the comment failed, remove the optimistic updates
        self.removeComment(optimisticCommentId);
        console.warn(`Error creating comment`);
        return;
      } catch (error) {
        // Posting of the comment failed, remove the optimistic update
        console.warn(`Error creating comment`, error);
        self.removeComment(optimisticCommentId);
        return;
      }
    }),
  }))
  .actions(self => ({
    loadCommentIntoThread: flow(function* loadCommentIntoThread(
      commentId: string
    ): Generator<any, boolean, AxiosResponse<Comment | undefined>> {
      let comment: Comment | undefined;
      try {
        const res = yield rollupClient.comments.retrieve(commentId);
        comment = res.data;
      } catch (error) {
        console.error(`Error fetching comment ${commentId}`);
        return false;
      }

      if (!comment) {
        console.error(`Could not find comment with id ${commentId}`);
        return false;
      }

      const commentStore = CommentStore.create(mapRtoToSnapshot<Comment, ICommentSnapshotIn>(comment));
      self.childComments.push(commentStore);

      return true;
    }),
    fetchChildren: flow(function* fetchChildren(): Generator<any, boolean, any> {
      const anchorChildCommentId = self.childComments[0]?.id;
      const parentCommentId = self.parentComment?.id;
      if (!parentCommentId && !anchorChildCommentId) {
        return false;
      }
      const dto: CommentGetChildrenRequestDto = {
        parentCommentId: parentCommentId,
        anchorChildCommentId: anchorChildCommentId,
        childTake: COMMENT_THREAD_LIST_CHILD_TAKE,
      };

      try {
        const { data, status } = yield rollupClient.comments.retrieveChildren(dto);
        if (status !== 200) {
          throw new Error(`Error: response ${status}`);
        }
        // Construct comment stores
        self.remainingChildCommentsCount = data.olderChildCommentsCount;
        self.remainingChildCommentsCommenters = data.uniqueNextSetOfOlderChildCommentersUserIds;

        if (data.childComments.length === 0) {
          self.remainingChildCommentsCount = 0;
          self.remainingChildCommentsCommenters = cast([]);
          return true;
        }

        // Construct child comments from response
        let newChildComments: IComment[] = [];

        data.childComments.reverse().forEach((childComment: Comment) => {
          const comment = CommentStore.create({
            ...childComment,
            createdAt: convertTimestamp(childComment.createdAt),
            updatedAt: convertTimestamp(childComment.updatedAt),
          });
          newChildComments.push(comment);
        });

        // push the new threads to the beginning of the child comments array
        self.childComments.unshift(...newChildComments);
        newChildComments = [];

        return true;
      } catch (error) {
        throw new Error(`Error: response ${error}`);
      }
    }),
    addChildComment: flow(function* addChildComment(
      value: string,
      attachmentIds: string[],
      publishAttachmentsToBlock?: boolean
    ): Generator<any, string | undefined, any> {
      if (!self.parentComment) {
        console.warn("Comment not created: no parent comment to add child comment to.");
        return;
      }
      const dto: CreateCommentDto = {
        id: uuidv4(),
        text: value,
        parentId: self.parentComment.parentId,
        parentType: self.parentComment.parentType,
        displayParentId: self.parentComment.displayParentId,
        displayParentType: self.parentComment.displayParentType,
        parentCommentId: self.parentComment.id,
        attachmentIdList: attachmentIds,
        publishToBlock: publishAttachmentsToBlock !== undefined ? publishAttachmentsToBlock : true,
        workspaceId: self.parentComment.workspaceId ?? undefined,
      };
      return yield self.addComment(dto);
    }),
  }))
  .views(self => ({
    get allIds(): string[] {
      const parentCommentId = self.parentComment?.id;
      const childCommentIds = self.childComments.map(comment => comment.id) || [];
      return [parentCommentId, ...childCommentIds].filter(isDefined);
    },
    get commentCount() {
      if (!self.parentComment) {
        return 0;
      }
      return 1 + (self.childComments.length || 0);
    },
  }));
export interface ICommentThread extends Instance<typeof CommentThreadStore> {}
export interface ICommentThreadSnapshotIn extends SnapshotIn<typeof CommentThreadStore> {}
interface ICommentThreadSnapshotOut extends SnapshotOut<typeof CommentThreadStore> {}
export interface ICommentThreadMobxType extends IType<ICommentThreadSnapshotIn, ICommentThreadSnapshotOut, ICommentThread> {}
