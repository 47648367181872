import { useCallback } from "react";
import { useSearchParams } from "react-router-dom";

export const useSearchParam = <K extends string | number>(key: K): [string | undefined, (value: string) => void] => {
  const [searchParams, setSearchParams] = useSearchParams();

  const setParamValue = useCallback(
    (newValue: string) => {
      const newSearchParams = new URLSearchParams(searchParams.toString());
      if (!newValue) {
        newSearchParams.delete(key.toString());
      } else {
        newSearchParams.set(key.toString(), newValue);
      }
      setSearchParams(newSearchParams);
    },
    [key, searchParams, setSearchParams]
  );
  return [searchParams.get(key.toString()) as string, setParamValue];
};

// This hook is to be used where we want to change more than 1 search param at the same time, or right after another
// Each search param change triggers a page load. If we use the above function two times for two different search
// param changes, before the first one is loaded, the second change will be triggered and the the result will be
// first change will be lost (will stay as the initial value) and only the second will be applied. To prevent that,
// we use this hook, which allows us to change multiple search params at the same time, and only trigger one page load.
export const useSearchParamArray = <K extends (string | number)[]>(
  keys: K
): [Record<string, string | undefined>, (key: string, value: string) => void, (newValues: Record<string, string>) => void] => {
  const [searchParams, setSearchParams] = useSearchParams();

  const setParamValue = useCallback(
    (key: string, newValue: string) => {
      const newSearchParams = new URLSearchParams(searchParams.toString());
      if (!newValue) {
        newSearchParams.delete(key);
      } else {
        newSearchParams.set(key, newValue);
      }
      setSearchParams(newSearchParams);
    },
    [searchParams, setSearchParams]
  );

  const setParamValues = useCallback(
    (newValues: Record<string, string>) => {
      const newSearchParams = new URLSearchParams(searchParams.toString());
      Object.entries(newValues).forEach(([key, value]) => {
        if (!value) {
          newSearchParams.delete(key);
        } else {
          newSearchParams.set(key, value);
        }
      });
      setSearchParams(newSearchParams);
    },
    [searchParams, setSearchParams]
  );

  const params = keys.reduce(
    (acc, key) => {
      acc[key] = searchParams.get(key.toString()) as string;
      return acc;
    },
    {} as Record<string, string | undefined>
  );

  return [params, setParamValue, setParamValues];
};
