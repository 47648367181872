import classNames from "classnames";
import { observer } from "mobx-react";

import ImageUploaderCard from "@components/ImageUploaderCard";
import { IBlock } from "@store/BlockStore";
import { parentWorkspace } from "@utilities/Models";

import BlockPreviewCard from "./BlockPreviewCard";

import "./BomPmImageCard.scss";

interface IProjectManagementImageCardProps {
  block: IBlock;
  className?: string;
}

const BomPmImageCard = (props: IProjectManagementImageCardProps) => {
  const { block, className } = props;
  const workspaceId = parentWorkspace(block)?.id;
  const imageUrl = block.dedicatedAttachment?.previewUrl ?? block.pmImageUrl;

  const handleAddImage = (link: string) => {
    block.setCustomPreviewLink(link);
  };

  return (
    <BlockPreviewCard className={classNames("bom-pm-image-card", className)}>
      <ImageUploaderCard workspaceId={workspaceId} src={imageUrl} imageClassName="bom-pm-image-card--image" onSrcChange={handleAddImage} />
    </BlockPreviewCard>
  );
};

export default observer(BomPmImageCard);
