import { useState } from "react";
import { observer } from "mobx-react";

import { PopupMenu } from "@components/Shared/PopupMenu";
import { RequirementBlockType } from "@rollup-api/models";

import { RequirementAddButtonMenu } from "../RequirementsDocumentView/RequirementAddButtonMenu";

import "./RequirementPageNewRowButton.scss";

interface IRequirementPageNewRowButtonProps {
  onAddRequirementsBlock(type: RequirementBlockType): void;
}

const RequirementPageNewRowButton = (props: IRequirementPageNewRowButtonProps) => {
  const { onAddRequirementsBlock } = props;
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="requirement-page-new-row-button">
      <PopupMenu
        popoverProps={{ isOpen, onInteraction: setIsOpen }}
        buttonProps={{ icon: "add", text: "New row", minimal: true, rightIcon: isOpen ? "caret-up" : "caret-down" }}
        content={<RequirementAddButtonMenu onAddBlock={onAddRequirementsBlock} />}
        e2eIdentifiers="new-row"
      />
    </div>
  );
};

export default observer(RequirementPageNewRowButton);
