import { NavLink, NavLinkProps } from "react-router-dom";
import { Alignment, ButtonProps, Classes, Icon, Intent } from "@blueprintjs/core";
import classNames from "classnames";

/** Type defs. */
type NavLinkAsButtonProps = NavLinkProps &
  Pick<ButtonProps, "text" | "icon" | "rightIcon" | "fill" | "intent" | "disabled" | "outlined" | "minimal" | "alignText">;

/** Main function. */
function NavLinkAsButton({
  text,
  icon,
  rightIcon,
  outlined,
  minimal,
  disabled,
  alignText,
  to,
  fill,
  intent,
  ...rest
}: NavLinkAsButtonProps) {
  return (
    <NavLink
      to={to}
      className={({ isActive }) =>
        classNames(Classes.BUTTON, {
          [Classes.ACTIVE]: isActive,
          [Classes.MINIMAL]: minimal,
          [Classes.OUTLINED]: outlined,
          [Classes.FILL]: fill,
          [Classes.DISABLED]: disabled,
          [Classes.ALIGN_LEFT]: alignText === Alignment.LEFT,
          [Classes.ALIGN_RIGHT]: alignText === Alignment.RIGHT,
          [Classes.INTENT_PRIMARY]: intent === Intent.PRIMARY,
          [Classes.INTENT_SUCCESS]: intent === Intent.SUCCESS,
          [Classes.INTENT_WARNING]: intent === Intent.WARNING,
          [Classes.INTENT_DANGER]: intent === Intent.DANGER,
        })
      }
      {...rest}
    >
      {!!icon && <Icon icon={icon} />}
      {!!text && <span className={Classes.BUTTON_TEXT}>{text}</span>}
      {!!rightIcon && <Icon icon={rightIcon} />}
    </NavLink>
  );
}

/** Exports. */
export type { NavLinkAsButtonProps };
export default NavLinkAsButton;
