import { RefAttributes } from "react";
import { Mention } from "@tiptap/extension-mention";
import { mergeAttributes, ReactNodeViewRenderer, ReactRenderer } from "@tiptap/react";
import { SuggestionOptions } from "@tiptap/suggestion";
import { PluginKey } from "prosemirror-state";
import tippy, { GetReferenceClientRect, Instance } from "tippy.js";

import { handleBackspaceIfMention } from "@components/Reports/Editor/Extentions/Mention/MentionUtils";
import { IPropertyListItem } from "@components/Shared/ScalarExpressionEditor/PropertyListTypes";
import {
  createListItems,
  createSampleEntries,
  IPropertyReferenceSuggestionParams,
} from "@components/Shared/ScalarExpressionEditor/PropertyReferenceEntriesCreation";
import { ESCAPE_KEY } from "@constants/keys";
import appStore from "@store/AppStore";
import { fuseSearch } from "@utilities/FuseSearch";

import PropertyTag, { PropertyTagType } from "./tags/PropertyTag";
import PropertyList, { PropertyListProps, PropertyListRef } from "./PropertyList";

type PropertyReferenceSuggestionOptions<T> = Omit<SuggestionOptions<T>, "editor">;
export const PropertyReferencePluginKey = new PluginKey("property-ref");

export const PropertyReferenceSuggestion = (
  params: IPropertyReferenceSuggestionParams
): PropertyReferenceSuggestionOptions<IPropertyListItem> => ({
  allowSpaces: true,
  allowedPrefixes: null,
  char: "{{",
  pluginKey: PropertyReferencePluginKey,
  items: ({ query }) => {
    const workspace = appStore.workspaceModel;
    if (!workspace) {
      return [];
    }

    if (!query) {
      return createSampleEntries(workspace, params);
    }

    const entries = createListItems(workspace, params);

    return fuseSearch({ entries, query, fuseSearchOptions: { limit: 5 } });
  },

  render: () => {
    let component: ReactRenderer<PropertyListRef, PropertyListProps & RefAttributes<PropertyListRef>>;
    let popup: Instance;

    return {
      onStart: props => {
        component = new ReactRenderer(PropertyList, {
          props: {
            ...props,
            onDataSourceSelect: (dataSourceId: string) => {
              params.onDataSourceSelect(dataSourceId);
              popup?.hide();
            },
          },
          editor: props.editor,
        });

        if (!props.clientRect) {
          return;
        }

        // TODO: Use a BlueprintJS popover instead of tippy
        popup = tippy("body", {
          getReferenceClientRect: props.clientRect as GetReferenceClientRect,
          appendTo: () => document.body,
          content: component.element,
          showOnCreate: true,
          interactive: true,
          trigger: "manual",
          placement: "top-start",
          maxWidth: "50vw",
          onShown: params.onSuggestOpen,
          onHidden: params.onSuggestClosed,
        })?.[0];
      },

      onUpdate: props => {
        component.updateProps(props);

        if (!props.clientRect) {
          return;
        }

        popup.setProps({
          getReferenceClientRect: props.clientRect as GetReferenceClientRect,
        });
      },

      onKeyDown: props => {
        if (props.event.key === ESCAPE_KEY) {
          popup.hide();

          return true;
        }

        return component.ref?.onKeyDown(props) ?? false;
      },

      onExit: () => {
        if (popup?.state && !popup.state.isDestroyed) {
          popup.destroy();
        }
        component?.destroy();
      },
    };
  },
});

export const PropertyReferenceMention = Mention.extend({
  name: "property-ref-mention",
  addNodeView() {
    return ReactNodeViewRenderer(PropertyTag);
  },
  addAttributes() {
    return {
      id: {
        default: null,
        parseHTML: element => element.getAttribute("data-id"),
        renderHTML: attributes => {
          return { ...(attributes.id && { "data-id": attributes.id }) };
        },
      },

      type: {
        default: null,
        parseHTML: element => element.getAttribute("data-type"),
        renderHTML: attributes => {
          if (!attributes.type) {
            return {};
          }

          return {
            "data-type": attributes.type,
          };
        },
      },
    };
  },
  parseHTML() {
    return [
      {
        tag: "property-ref",
      },
    ];
  },
  renderHTML({ HTMLAttributes }) {
    return ["property-ref", mergeAttributes(HTMLAttributes)];
  },

  renderText({ node }) {
    const typeString = node.attrs?.type;
    if (typeString && typeString !== PropertyTagType.PropertyInstance) {
      return `{{${typeString}:${node.attrs.id}}}`;
    }

    return `{{${node.attrs.id}}}`;
  },
  addKeyboardShortcuts() {
    return {
      // Override the default backspace behavior that left the suggestion char ("{{") behind when the mention was deleted
      Backspace: handleBackspaceIfMention,
    };
  },
});
