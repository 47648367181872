import { types } from "mobx-state-tree";

export enum PdmCard {
  Overview = "overview",
  Specifications = "specifications",
  Sourcing = "sourcing",
  Relations = "relations",
  Versions = "versions",
  Attachments = "attachments",
}

export const PdmCardsVisibilityStore = types
  .model("PdmCardsVisibility", {
    [PdmCard.Overview]: types.optional(types.boolean, true),
    [PdmCard.Specifications]: types.optional(types.boolean, true),
    [PdmCard.Sourcing]: types.optional(types.boolean, true),
    [PdmCard.Relations]: types.optional(types.boolean, true),
    [PdmCard.Versions]: types.optional(types.boolean, true),
    [PdmCard.Attachments]: types.optional(types.boolean, true),
  })
  .actions(self => {
    return {
      toggleCard(card: PdmCard) {
        self[card] = !self[card];
      },
    };
  });
