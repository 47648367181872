import { memo } from "react";
import { CustomCellEditorProps } from "ag-grid-react";

import SimpleTextCellEditor from "@components/Table/CellEditors/SimpleTextCellEditor";
import { IStatusDefinition } from "@store/StatusDefinitionStore";

import { EStatusesColumn } from "../constants";

import "./StatusEditorCell.scss";

const StatusEditorCell = memo((props: CustomCellEditorProps<IStatusDefinition, string>) => {
  const { eGridCell, data, onValueChange, api, node } = props;
  const cellName = eGridCell.attributes.getNamedItem("col-id")?.value as EStatusesColumn;

  const getDefaultValue = () => {
    switch (cellName) {
      case EStatusesColumn.LABEL:
        return data.label;
      case EStatusesColumn.DESCRIPTION:
        return data.description;
      default:
        return "";
    }
  };

  return (
    <div className="status-editor-cell">
      <SimpleTextCellEditor<IStatusDefinition>
        singleLine
        onBlur={api.stopEditing}
        defaultValue={getDefaultValue()}
        api={api}
        node={node}
        onValueChange={onValueChange}
      />
    </div>
  );
});

StatusEditorCell.displayName = "StatusEditorCell";

export default StatusEditorCell;
