import { ComponentProps, PropsWithChildren, ReactNode } from "react";
import { useBool } from "@hooks/useBool/useBool";
import { BlueprintIcon } from "@ui/BlueprintIcon";
import classNames from "classnames";

import Placeholder from "./Placeholder";

import "./InputBox.scss";

export type InputBoxProps = PropsWithChildren<{
  placeholder: string | ReactNode | ((activateInput: () => void) => ReactNode);
  wrapperProps?: ComponentProps<"div">;
  iconProps?: ComponentProps<typeof BlueprintIcon>;
}>;

export const InputBox = (p: InputBoxProps) => {
  const [inputActive, { set: activateInput, unset: deactivateInput }] = useBool();

  return (
    <div {...p.wrapperProps} className={classNames("input-box", p.wrapperProps?.className)}>
      <BlueprintIcon
        icon="plus"
        {...p.iconProps}
        onClick={inputActive ? deactivateInput : activateInput}
        className={classNames("input-box--icon close-btn", inputActive && "is-active")}
      />
      {!inputActive && <Placeholder placeholder={p.placeholder} onClick={activateInput} />}
      {inputActive && p.children}
    </div>
  );
};
