import { useCallback, useEffect, useState } from "react";
import { ButtonGroup, Intent, NonIdealState, Position, ResizeSensor, Spinner } from "@blueprintjs/core";
import { Editor } from "@monaco-editor/react";
import { observer } from "mobx-react";
import type * as Monaco from "monaco-editor";
import { KeyCode, KeyMod } from "monaco-editor";
import { useDebounceValue } from "usehooks-ts";

import { EnvironmentTypeSelect } from "@components/Analysis";
import { Button } from "@components/Button";
import { ModulePageHeader } from "@components/Shared/ModulePageHeader";
import { Tooltip } from "@components/Tooltip";
import { showApiErrorToast } from "@components/UiLayers/toaster";
import { ExecutionEnvironment, ExecutionEnvironmentType, ExecutionResult } from "@rollup-api/models/execution-environments";
import { runCodeBlock } from "@rollup-api/utils";
import { ICodeBlock } from "@store/Analysis/CodeBlockStore";
import appStore from "@store/AppStore";
import { getLanguageFromType } from "@utilities";
import { rollupClient } from "src/core/api";

import AnalysisInputOutputSidebar from "../../AnalysisInputOutputSidebar/AnalysisInputOutputSidebar";
import CodeBlocksFooter from "../CodeBlocksFooter/CodeBlocksFooter";
import CodeBlocksSidebarContextMenu from "../CodeBlocksSidebarContextMenu/CodeBlocksSidebarContextMenu";
import ExecutionEnvironmentSelect from "../ExecutionEnvironmentSelect";

import "./CodeBlockPage.scss";

const SHOW_SIDE_BAR_WIDTH = 1000;
const IO_SIDEBAR_WIDTH = 400;
const AUTOSAVE_DEBOUNCE_DURATION = 2000;

function CodeBlockPage({ codeBlock }: { codeBlock: ICodeBlock }) {
  const [isFetchingCode, setIsFetchingCode] = useState(false);
  const [inputCode, setInputCode] = useState<string>("");
  const [isExecuting, setIsExecuting] = useState(false);
  const [editorWidth, setEditorWidth] = useState<number>(-1);
  const [result, setResult] = useState<ExecutionResult | undefined>();

  const [debouncedCode] = useDebounceValue(inputCode, AUTOSAVE_DEBOUNCE_DURATION);

  // TODO: I think we could should be able to use the useQuery hook here and in the execution dialog
  const [isFetchingEnvs, setIsFetchingEnvs] = useState(false);
  const [availableEnvironments, setAvailableEnvironments] = useState<ExecutionEnvironment[]>();

  const updateCodeIfRequired = useCallback(
    async (code: string) => {
      const originalCode = codeBlock.code;
      if (originalCode !== null && code !== originalCode) {
        await codeBlock.setCode(code, true);
      }
    },
    [codeBlock]
  );

  useEffect(() => {
    setIsFetchingEnvs(true);
    rollupClient.analysisModule.executionEnvironments
      .retrieveList()
      .then(res => {
        setAvailableEnvironments(res.data);
      })
      .catch(error => {
        console.warn("Error fetching execution environments", error);
        showApiErrorToast("Error fetching execution environments", error);
      })
      .finally(() => {
        setIsFetchingEnvs(false);
      });
  }, []);

  useEffect(() => {
    if (!codeBlock) {
      return;
    }
    setResult(undefined);
    setIsFetchingCode(true);
    codeBlock.clearCode();
    rollupClient.analysisModule.codeBlocks
      .retrieve(codeBlock.id)
      .then(fullBlock => {
        if (fullBlock.data) {
          setInputCode(fullBlock.data.code || "");
          codeBlock.setCode(fullBlock.data.code || "", false);
        }
      })
      .finally(() => {
        setIsFetchingCode(false);
      });
  }, [codeBlock]);

  // Realtime code updates: subscribe to room and handle code update events
  useEffect(() => {
    appStore.realtimeService.subscribeToChanges(`code-block/${codeBlock.id}`);

    // Add handler for updating code
    const handleCodeUpdate = ({ id, code }: { id: string; code: string }) => {
      if (codeBlock.id === id) {
        codeBlock.setCode(code, false);
        setInputCode(code);
      }
    };
    const removeHandlerCallback = appStore.realtimeService.addEventHandler("updateCodeBlockCode", handleCodeUpdate);

    return () => {
      removeHandlerCallback();
      appStore.realtimeService.unsubscribeFromChanges(`code-block/${codeBlock.id}`);
    };
  }, [codeBlock]);

  useEffect(() => {
    updateCodeIfRequired(debouncedCode);
    // eslint-disable-next-line
  }, [debouncedCode]);

  const executeCode = async (code: string) => {
    if (isFetchingCode || !code) {
      return;
    }
    await updateCodeIfRequired(code);
    setIsExecuting(true);
    setResult(undefined);
    const result = await runCodeBlock(codeBlock.id);
    setResult(result);
    setIsExecuting(false);
  };

  const onResize = (entries: ResizeObserverEntry[]) => {
    const width = entries?.[0]?.contentRect.width;
    setEditorWidth(width);
  };

  const onEditorMounted = (editor: Monaco.editor.IStandaloneCodeEditor) => {
    editor.addCommand(KeyMod.CtrlCmd | KeyCode.Enter, () => {
      const value = editor.getValue();
      setInputCode(value);
      executeCode(value);
    });
    editor.focus();
    editor.onDidBlurEditorText(() => {
      updateCodeIfRequired(editor.getValue());
    });
  };

  const onExecutionTypeChange = (type: ExecutionEnvironmentType) => {
    codeBlock.setType(type);
    codeBlock.setExecutionEnvironmentId(null);
  };

  const isChanged = inputCode !== codeBlock.code;
  const selectedEnv = availableEnvironments?.find(env => env.id === codeBlock.executionEnvironmentId);
  const canExecute = !isFetchingCode && !isExecuting && inputCode && (selectedEnv || !codeBlock.executionEnvironmentId);

  if (!codeBlock) {
    return null;
  }

  const loadingPlaceholder = <NonIdealState icon="code-block" title="Loading code..." />;
  if (isFetchingCode) {
    return loadingPlaceholder;
  }

  const actions = (
    <div className="code-block-page--actions">
      <EnvironmentTypeSelect value={codeBlock.type} onChange={onExecutionTypeChange} />
      {isFetchingEnvs ? (
        <Spinner />
      ) : (
        <ExecutionEnvironmentSelect
          selectedId={codeBlock.executionEnvironmentId}
          availableEnvironments={availableEnvironments}
          environmentType={codeBlock.type}
          onChange={id => codeBlock.setExecutionEnvironmentId(id)}
        />
      )}
      <ButtonGroup>
        <Tooltip content="Save changes and run execution" position={Position.BOTTOM}>
          <Button
            icon="play"
            disabled={!canExecute}
            loading={isExecuting}
            intent={Intent.SUCCESS}
            onClick={() => executeCode(inputCode)}
            e2eIdentifiers="run-code"
          />
        </Tooltip>
        <Tooltip content="Stop execution">
          <Button icon="stop" intent={Intent.WARNING} disabled e2eIdentifiers="stop-code" />
        </Tooltip>
        <Tooltip content="Save changes">
          <Button icon="floppy-disk" disabled={!isChanged} e2eIdentifiers="save-code" />
        </Tooltip>
      </ButtonGroup>
    </div>
  );

  return (
    <div className="code-block-page">
      <ModulePageHeader<ICodeBlock>
        entityName="code block"
        entity={codeBlock}
        unsaved={isChanged}
        showLink
        contextMenu={<CodeBlocksSidebarContextMenu codeBlock={codeBlock} />}
        rightActions={actions}
      />
      <ResizeSensor onResize={onResize}>
        <div className="code-block-page--main-content">
          <Editor
            className="code-block-page--editor"
            options={{
              minimap: { enabled: editorWidth - IO_SIDEBAR_WIDTH >= SHOW_SIDE_BAR_WIDTH },
              fontFamily: "JetBrains Mono",
              fontLigatures: true,
              scrollBeyondLastLine: false,
              wordWrap: "on",
              lineNumbers: "on",
              fixedOverflowWidgets: true,
            }}
            width={editorWidth - IO_SIDEBAR_WIDTH}
            loading={loadingPlaceholder}
            onMount={onEditorMounted}
            language={getLanguageFromType(codeBlock.type)}
            value={inputCode}
            onChange={value => setInputCode(value ?? "")}
            theme={appStore.env.themeIsDark ? "vs-dark" : "light"}
          />
          <AnalysisInputOutputSidebar codeBlock={codeBlock} />
        </div>
      </ResizeSensor>
      <CodeBlocksFooter codeBlock={codeBlock} result={result} />
    </div>
  );
}

export default observer(CodeBlockPage);
