import { isValidEvaluation, LinkState, RollupPropertyInfo } from "@rollup-io/engineering";
import { v4 as uuidv4 } from "uuid";

import { DataLink } from "@rollup-api/models/data-sources/data-link.model";
import appStore from "@store/AppStore";
import { IPropertyInstance } from "@store/PropertyInstanceStore";
import { EntityType } from "@store/types";
import { formatStringFromMathResult } from "@utilities";

import { PropertyTagType } from "./tags/PropertyTag";

export const expressionTemplateRegex =
  /{{(?:(?<type>newDataLink|dataLink|prop|analysisOutput|codeOutput|dataSinkEntry):)?(?<id>[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12})(?::(?<query>[\w:]*))?}}/g;

export const getParsedExpression = (propertyInstance: IPropertyInstance) => {
  // Replace {{uuid}} entries with property-ref tags
  return propertyInstance.value?.replaceAll(expressionTemplateRegex, (_, type, id, query) => {
    if (type === "newDataLink") {
      const dataLink = createDataLink(id, query);
      return `<property-ref data-type="${PropertyTagType.DataLink}" data-id="${dataLink.id}"></property-ref>`;
    } else if (type) {
      return `<property-ref data-type="${type}" data-id="${id}"></property-ref>`;
    }
    return `<property-ref data-id="${id}"></property-ref>`;
  });
};

export const getExpressionValue = (propertyInstance: IPropertyInstance) => {
  if (!propertyInstance.evaluatedExpressionInfo || propertyInstance.evaluatedExpressionInfo.errorType) {
    return getParsedExpression(propertyInstance);
  }
  return formatStringFromMathResult(propertyInstance.combinedResult, propertyInstance.propertyDefinition?.formatType);
};

export const getTooltipContent = (rollupInfo: RollupPropertyInfo, propertyInstance: IPropertyInstance) => {
  if (isValidEvaluation(rollupInfo)) {
    return `Rollup value: ${formatStringFromMathResult(
      rollupInfo.calculatedResult,
      propertyInstance.propertyDefinition?.formatType
    )}. Click to see details`;
  }

  return rollupInfo.message ?? "Unknown error";
};

export const openBracesRegex = /{{(?<dataSource>[-\w:]*)(?![-\w:}])/;
// "content" must include "{{" at the beginning
export const getReplaceRegex = (content: string = "") => new RegExp(`${content}(?![-\\w:}])`);

export const createDataLink = (dataSourceId: string, query: string): DataLink => {
  const dataLink = appStore.workspaceModel?.getDataLink(dataSourceId, query);

  if (dataLink) {
    return dataLink;
  }

  const newDataLink = {
    id: uuidv4(),
    dataSourceId,
    query,
    state: LinkState.LOADING,
    createdAt: new Date().toISOString(),
    updatedAt: new Date().toISOString(),
  } satisfies DataLink;

  appStore.workspaceModel?.addOrUpdateDataLink(newDataLink).catch(err => {
    console.warn(err);
  });

  return newDataLink;
};

export const propertyTagTypeToEntityTypeMap: Record<PropertyTagType, EntityType> = {
  [PropertyTagType.DataLink]: EntityType.DataSource,
  [PropertyTagType.DataSinkEntry]: EntityType.DataSinkEntry,
  [PropertyTagType.PropertyInstance]: EntityType.PropertyInstance,
  [PropertyTagType.AnalysisOutput]: EntityType.AnalysisOutput,
};

export const isValidPropertyEntry = (
  propertyInstance: IPropertyInstance,
  referenceProperty?: IPropertyInstance,
  showDetachedProperties?: boolean
): boolean => {
  const { propertyDefinition, parentBlock, path } = propertyInstance;
  const result =
    propertyDefinition && path && propertyInstance !== referenceProperty && (showDetachedProperties || !parentBlock.isDetachedBlock);
  return !!result;
};
