import { Radio } from "@blueprintjs/core";
import { Text, TextVariant } from "@ui/Text";
import { observer } from "mobx-react";

import DismissibleCallout from "@components/Shared/DismissibleCallout/DismissibleCallout";
import { IDataSinkEntry } from "@store/DataConnection/DataSinkEntryStore";
import { IDataSink } from "@store/DataConnection/DataSinkStore";
import { isNonEmptyValueEntry, isScalarEntry } from "@utilities/DataConnectionUtils";

import styles from "./DataSinkPanel.module.scss";

interface IDataSinkPanelProps {
  selectedEntryId?: string;
  dataSink: IDataSink;
  onSelectEntry(entryId: string): void;
}

const DataSinkPanel = (props: IDataSinkPanelProps) => {
  const { selectedEntryId, dataSink, onSelectEntry } = props;

  const renderHeaderRow = (label: string, value: string) => {
    return (
      <div>
        <Text variant={TextVariant.Caption}>{label}: </Text>
        <Text variant={TextVariant.Label}>{value}</Text>
      </div>
    );
  };

  const renderEntryCard = (entry: IDataSinkEntry) => {
    return (
      <div className={styles.entryCard} onClick={() => onSelectEntry(entry.id)}>
        <Radio className={styles.entryCardRadio} value={entry.id} checked={selectedEntryId === entry.id} readOnly />
        <div className={styles.entryCardData}>
          <Text variant={TextVariant.Label}>{entry.key}</Text>
          <Text variant={TextVariant.Label}>{entry.value}</Text>
        </div>
      </div>
    );
  };

  return (
    <div className={styles.dataSinkPanel}>
      <div>
        {renderHeaderRow("ID", dataSink.visibleId)}
        {renderHeaderRow("Name", dataSink.label)}
        {renderHeaderRow("Description", dataSink.description)}
        <DismissibleCallout
          icon="info-sign"
          intent="primary"
          title="The list below may not include all entries for this data sink"
          calloutKey="scalar-entry-keys-only"
        >
          <Text>
            Only entries with <b>non-empty scalar values</b> are displayed below.
          </Text>
        </DismissibleCallout>
      </div>
      <div className={styles.entriesContainer}>
        {dataSink.entries
          .filter(isScalarEntry)
          .filter(isNonEmptyValueEntry)
          .map(entry => renderEntryCard(entry))}
      </div>
    </div>
  );
};

export default observer(DataSinkPanel);
