import { ButtonGroup, IconName, MaybeElement, Position, Tooltip } from "@blueprintjs/core";
import { observer } from "mobx-react";

import PartViewHideIconDark from "@assets/icons/hoopsIcons/dark/part_view_hide_dark.svg?react";
import PartViewIsolateViewIconDark from "@assets/icons/hoopsIcons/dark/part_view_isolate_dark.svg?react";
import PartViewLookAtIconDark from "@assets/icons/hoopsIcons/dark/part_view_look_at_dark.svg?react";
import PartViewSetTransparentIconDark from "@assets/icons/hoopsIcons/dark/part_view_set_transparent_dark.svg?react";
import PartViewShowAllPartsIconDark from "@assets/icons/hoopsIcons/dark/part_view_show_all_parts_dark.svg?react";
import PartViewZoomToIconDark from "@assets/icons/hoopsIcons/dark/part_view_zoom_to_dark.svg?react";
import PartViewHideIconLight from "@assets/icons/hoopsIcons/light/part_view_hide_light.svg?react";
import PartViewIsolateViewIconLight from "@assets/icons/hoopsIcons/light/part_view_isolate_light.svg?react";
import PartViewLookAtIconLight from "@assets/icons/hoopsIcons/light/part_view_look_at_light.svg?react";
import PartViewSetTransparentIconLight from "@assets/icons/hoopsIcons/light/part_view_set_transparent_light.svg?react";
import PartViewShowAllPartsIconLight from "@assets/icons/hoopsIcons/light/part_view_show_all_parts_light.svg?react";
import PartViewZoomToIconLight from "@assets/icons/hoopsIcons/light/part_view_zoom_to_light.svg?react";
import { Button } from "@components/Button";
import { Popover } from "@components/Popover";
import { LegacyCustomIcon } from "@components/Shared/LegacyCustomIcon/LegacyCustomIcon";
import appStore from "@store/AppStore";

import styles from "../HoopsMenu.module.scss";

type PartView = {
  name: string;
  description?: string;
  icon: IconName | MaybeElement;
  enabled?: boolean;
  function: () => void;
};

type HoopsMenuPartViewButtonGroupProps = {
  viewer: Communicator.WebViewer;
};

const HoopsMenuPartViewButtonGroup = ({ viewer }: HoopsMenuPartViewButtonGroupProps) => {
  const partViews: PartView[] = [
    {
      name: "Zoom to",
      icon: <LegacyCustomIcon large icon={appStore.env.themeIsDark ? <PartViewZoomToIconDark /> : <PartViewZoomToIconLight />} />,
      function: async () => {
        if (appStore.env.attachmentViewer?.selectedNode) {
          const nodeBounding = await viewer.model.getNodesBounding([appStore.env.attachmentViewer.selectedNode.id]);
          viewer.view.fitBounding(nodeBounding);
        }
      },
    },
    {
      name: "Look at",
      icon: <LegacyCustomIcon large icon={appStore.env.themeIsDark ? <PartViewLookAtIconDark /> : <PartViewLookAtIconLight />} />,
      function: async () => {
        if (appStore.env.attachmentViewer?.selectedNode) {
          viewer.view.centerCameraOnNode(appStore.env.attachmentViewer.selectedNode.id);
        }
      },
    },
    {
      name: "Isolate",
      icon: <LegacyCustomIcon large icon={appStore.env.themeIsDark ? <PartViewIsolateViewIconDark /> : <PartViewIsolateViewIconLight />} />,
      function: () => {
        if (appStore.env.attachmentViewer?.selectedNode) {
          appStore.env.attachmentViewer?.toggleNodeIsolation(appStore.env.attachmentViewer.selectedNode);
        }
      },
    },
    {
      name: appStore.env.attachmentViewer?.selectedNode?.visible ? "Hide" : "Show",
      icon: <LegacyCustomIcon large icon={appStore.env.themeIsDark ? <PartViewHideIconDark /> : <PartViewHideIconLight />} />,
      function: () => {
        if (appStore.env.attachmentViewer?.selectedNode) {
          appStore.env.attachmentViewer?.toggleNodeVisibility(appStore.env.attachmentViewer.selectedNode);
        }
      },
    },
    {
      name: appStore.env.attachmentViewer?.selectedNode?.transparencySet ? "Set opaque" : "Set transparent",
      icon: (
        <LegacyCustomIcon
          large
          icon={appStore.env.themeIsDark ? <PartViewSetTransparentIconDark /> : <PartViewSetTransparentIconLight />}
        />
      ),
      function: () => {
        if (appStore.env.attachmentViewer?.selectedNode) {
          appStore.env.attachmentViewer?.toggleNodeTransparency(appStore.env.attachmentViewer.selectedNode);
        }
      },
    },
    {
      name: "Show all parts",
      icon: (
        <LegacyCustomIcon large icon={appStore.env.themeIsDark ? <PartViewShowAllPartsIconDark /> : <PartViewShowAllPartsIconLight />} />
      ),
      enabled: true,
      function: () => {
        appStore.env.attachmentViewer?.showAllNodes();
      },
    },
  ];
  const partViewMenu = (
    <ButtonGroup large>
      {partViews.map(entry => (
        <Tooltip position={Position.TOP} hoverOpenDelay={500} key={entry.name} content={entry.name}>
          <Button
            className={styles.hoopsToolbarButtonAndButtonGroup}
            icon={entry.icon}
            disabled={entry.enabled !== undefined ? !entry.enabled : appStore.env.attachmentViewer?.selectedNode?.id ? false : true}
            onClick={() => {
              entry.function();
            }}
            e2eIdentifiers={[entry.name]}
          />
        </Tooltip>
      ))}
    </ButtonGroup>
  );
  return (
    <Popover content={partViewMenu} placement={Position.TOP}>
      <Tooltip position={Position.TOP} content="Part view">
        <Button
          className={styles.hoopsToolbarButtonAndButtonGroup}
          icon={<LegacyCustomIcon large icon={appStore.env.themeIsDark ? <PartViewLookAtIconDark /> : <PartViewLookAtIconLight />} />}
          e2eIdentifiers={[appStore.env.themeIsDark ? "dark" : "light"]}
        />
      </Tooltip>
    </Popover>
  );
};

export default observer(HoopsMenuPartViewButtonGroup);
