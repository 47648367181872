import { TCellRendererProps } from "@components/Table";
import { Tag } from "@components/Tag";
import { Invoice, InvoiceStatus } from "@rollup-api/models/invoices/invoice.model";
import { Color } from "@rollup-types/colors";

import styles from "./InvoiceTableStatusCell.module.scss";

interface IInvoiceTableStatusCellProps extends TCellRendererProps<Invoice> {}

const tagDataMap: Record<InvoiceStatus, { color: Color; label: string }> = {
  [InvoiceStatus.Draft]: { color: Color.Gray, label: "Draft" },
  [InvoiceStatus.Open]: { color: Color.Orange, label: "Pending" },
  [InvoiceStatus.Paid]: { color: Color.Green, label: "Paid" },
  [InvoiceStatus.Uncollectible]: { color: Color.Red, label: "Failed" },
  [InvoiceStatus.Void]: { color: Color.Red, label: "Failed" },
};

export const InvoiceTableStatusCell = (props: IInvoiceTableStatusCellProps) => {
  const { data: invoice } = props;

  return (
    <Tag className={styles.invoiceTableStatusCell} color={tagDataMap[invoice.status].color}>
      {tagDataMap[invoice.status].label}
    </Tag>
  );
};
