import { InputGroup, Intent, Label } from "@blueprintjs/core";
import { observer } from "mobx-react-lite";

import appStore from "@store/AppStore";

type Props = {
  preferredName: string;
  setPreferredName: (name: string) => void;
};

const PrimaryConfigs = (props: Props) => {
  const { preferredName, setPreferredName } = props;

  return (
    <div className="name-email-area">
      <Label className="name-email-area--label">
        Preferred name
        <InputGroup
          large
          intent={preferredName !== appStore.userModel?.name ? Intent.PRIMARY : Intent.NONE}
          value={preferredName}
          onChange={event => setPreferredName(event.target.value)}
          placeholder={appStore.userModel?.name ?? "Preferred name"}
        />
      </Label>
      <Label className="name-email-area--label">
        Email
        <InputGroup large readOnly value={appStore.userModel?.email ?? ""} />
      </Label>
    </div>
  );
};

export default observer(PrimaryConfigs);
