import classNames from "classnames";

import { IAnnotationList } from "@store/AnnotationListStore";
import appStore from "@store/AppStore";
import { moveCaretToEnd } from "@utilities";
import { moveCaretToStart } from "@utilities/TipTap";

export const getCheckboxComponent = (checked = false, content = "") => `<editor-checkbox checked="${checked}"></editor-checkbox>${content}`;

export const focusReportBlockById = (id: string, focusEnd = true) => {
  setTimeout(() => {
    const newBlockElement = document.getElementById(id);
    if (newBlockElement && newBlockElement.firstChild) {
      const newBlock = newBlockElement.firstChild as HTMLDivElement;
      newBlock.focus();
      if (focusEnd) {
        moveCaretToEnd(newBlock);
      } else {
        moveCaretToStart(newBlock, "start");
      }
    }
  }, 25);
};

const isHoveringCommentAnnotation = (annotationList: IAnnotationList) => {
  const { annotations } = annotationList;
  const { hoveredCommentAnnotationId } = appStore.ui;
  return hoveredCommentAnnotationId && hoveredCommentAnnotationId === annotations[0]?.id;
};

export const getDocumentEditorClassNames = (params: { annotationList?: IAnnotationList }) => {
  const { annotationList } = params;

  return classNames("report-editor", {
    ["report-editor--has-comment"]: annotationList?.annotations[0]?.hasComment,
    ["report-editor--hovering-comment-annotation"]: annotationList && isHoveringCommentAnnotation(annotationList),
  });
};
