import Fuse, { FuseSearchOptions, IFuseOptions } from "fuse.js";

interface IFuseSearchParams<T extends { type: string }> {
  entries: T[];
  query: string;
  fuseOptions?: IFuseOptions<T>;
  fuseSearchOptions?: FuseSearchOptions;
}

export const fuseSearch = <T extends { type: string }>(params: IFuseSearchParams<T>) => {
  const { entries, query, fuseOptions, fuseSearchOptions } = params;

  const fuse = new Fuse(entries, {
    isCaseSensitive: false,
    findAllMatches: true,
    keys: ["queryString", "label", "extendedLabel"],
    threshold: 0.4,
    ignoreLocation: true,
    ...fuseOptions,
  });

  const results = fuse.search(query, { limit: 10, ...fuseSearchOptions });

  // Determine the ordering of groups
  const resultGroups = new Set<string>();
  for (const result of results) {
    resultGroups.add(result.item.type);
  }
  const resultList = Array.from(resultGroups);

  // Sort results by group, and then by score
  results.sort((a, b) => {
    const valA = resultList.indexOf(a.item.type) * 10000 + (a.score ?? 0);
    const valB = resultList.indexOf(b.item.type) * 10000 + (b.score ?? 0);
    return valA - valB;
  });

  return results.map(v => v.item);
};
