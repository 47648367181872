import { JSX, ReactNode, useEffect, useState } from "react";
import { Intent } from "@blueprintjs/core";
import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import { observer } from "mobx-react";
import { useDebounceValue } from "usehooks-ts";

import { Loading } from "@components/Loading";
import { FilterableMenu } from "@components/Shared/FilterableMenu";

interface IAsyncFilterableMenuProps<T> {
  placeholder?: string;
  minSearchChars?: number;
  className?: string;
  usePopover?: boolean;
  renderItems?(data: T[]): ReactNode;
  dropdownItemRenderer?(item: T): JSX.Element | null;
  getQueryObject(query: string): UseQueryOptions<T[]>;
}

const debounceDelay = 500;

function AsyncFilterableMenu<T>(props: IAsyncFilterableMenuProps<T>) {
  const { placeholder, minSearchChars = 3, className, dropdownItemRenderer, getQueryObject, renderItems } = props;
  const [queryString, setQueryString] = useState("");
  const [debouncedQueryString] = useDebounceValue(queryString, debounceDelay);
  const [queryObject, setQueryObject] = useState<UseQueryOptions<T[]>>(getQueryObject(debouncedQueryString));
  const { data, isLoading, error } = useQuery<T[]>(queryObject);
  const invalidInput = !!queryString && queryString.length < minSearchChars;

  useEffect(() => {
    setQueryObject(getQueryObject(debouncedQueryString));
  }, [debouncedQueryString, getQueryObject]);

  const getIntent = (): Intent => {
    if (invalidInput) {
      return Intent.WARNING;
    } else if (error) {
      return Intent.DANGER;
    }
    return Intent.NONE;
  };

  const getHelperText = (): string => {
    if (invalidInput) {
      return `Minimum of ${minSearchChars} characters`;
    } else if (isLoading) {
      return "Searching...";
    } else if (error) {
      console.error(error);
      return "There was an error fetching the list of sheets";
    }
    return "";
  };

  const dropdownProps = dropdownItemRenderer && { dropdownItems: data, dropdownItemRenderer };

  return (
    <FilterableMenu<T>
      className={className}
      queryString={queryString}
      intent={getIntent()}
      helperText={getHelperText()}
      placeholder={placeholder}
      setQueryString={setQueryString}
      dropdownProps={dropdownProps}
    >
      {data && renderItems?.(data)}
      {isLoading && <Loading />}
    </FilterableMenu>
  );
}

export default observer(AsyncFilterableMenu);
