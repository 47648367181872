import { AttachmentType } from "@rollup-api/models/cloudStorage";
import { OpenGraphResponseModel, OpenGraphSummary } from "@rollup-api/models/cloudStorage/OpenGraphResponseModel";
import { GitHubRepository, IntegrationProvider, SimpleGitHubRepoMetadata } from "@rollup-api/models/integrations";
import appStore from "@store/AppStore";
import { IAttachmentModule } from "@store/AttachmentModuleStore";
import { IAttachment } from "@store/AttachmentStore";
import { FilePreviewType, getPreviewType } from "@utilities";
import { rollupClient } from "src/core/api";

export const isAttachmentValidLinkAttachment = (attachment: IAttachment): boolean => {
  return isAttachmentGithubRepository(attachment) || isAttachmentRegularLink(attachment);
};

export const isAttachmentGithubRepository = (attachment: IAttachment): boolean => {
  if (attachment.type === AttachmentType.integration && attachment.metadata.provider === IntegrationProvider.GitHub) {
    return true;
  } else if (attachment.type !== AttachmentType.url || !attachment.linkAttachmentUrl) {
    return false;
  }
  return /^https?:\/\/github\.com\/.+$/i.test(attachment.linkAttachmentUrl);
};

export const isAttachmentGoogleDriveFile = (attachment: IAttachment): boolean => {
  return attachment.type === AttachmentType.integration && attachment.metadata.provider === IntegrationProvider.Google;
};

export const isAttachmentRegularLink = (attachment: IAttachment): boolean => {
  if (attachment.type !== AttachmentType.url || !attachment.linkAttachmentUrl) {
    return false;
  }
  return /^https?:\/\/.+$/i.test(attachment.linkAttachmentUrl);
};

export const getLinkAttachmentDetails = async (
  attachment: IAttachment,
  attachments: IAttachmentModule
): Promise<GitHubRepository | IAttachment | undefined> => {
  if (attachment.type !== AttachmentType.url || !attachment.linkAttachmentUrl) {
    return;
  }

  if (isAttachmentGithubRepository(attachment)) {
    const repositoryDetails: GitHubRepository | undefined = await rollupClient.liveLinks.getGitHubRepository(attachment.linkAttachmentUrl);
    return repositoryDetails;
  }

  if (isAttachmentRegularLink(attachment)) {
    const attachmentDetails = attachments.get(attachment.id);
    if (attachmentDetails) {
      return attachmentDetails;
    }
  }

  return;
};

export const getAttachmentThumbnailUrl = async (attachment: IAttachment): Promise<string | undefined> => {
  if ((attachment.type === AttachmentType.file || attachment.type === AttachmentType.integration) && attachment.hasThumbnail) {
    return rollupClient.attachments.getThumbnailUrl(attachment.id, attachment.version, attachment.workspaceId);
  }

  if (attachment.type === AttachmentType.url && attachment.linkAttachmentUrl) {
    if (isAttachmentGithubRepository(attachment)) {
      const repositoryDetails: GitHubRepository | undefined = await rollupClient.liveLinks.getGitHubRepository(
        attachment.linkAttachmentUrl
      );
      if (repositoryDetails) {
        return repositoryDetails.repositoryCoverImageUrl;
      }
      return;
    }

    if (isAttachmentRegularLink(attachment)) {
      const { image } = await parseOpenGraphData(attachment);
      return image;
    }
  }
  return;
};

export const parseOpenGraphData = async (attachment: IAttachment): Promise<OpenGraphSummary> => {
  if (!attachment.linkAttachmentUrl || !isAttachmentRegularLink(attachment)) {
    return {};
  }

  const openGraphData: OpenGraphResponseModel | undefined = await rollupClient.attachments.getOpenGraphData(attachment.linkAttachmentUrl);

  const favicon = openGraphData?.hybridGraph?.favicon || openGraphData?.htmlInferred?.favicon;

  const image =
    openGraphData?.openGraph?.image?.url ||
    openGraphData?.hybridGraph?.image ||
    openGraphData?.htmlInferred?.image ||
    openGraphData?.htmlInferred?.images[0] ||
    favicon;

  const title = openGraphData?.openGraph?.title || openGraphData?.hybridGraph?.title || openGraphData?.htmlInferred?.title;

  const description =
    openGraphData?.openGraph?.description || openGraphData?.hybridGraph?.description || openGraphData?.htmlInferred?.description;

  const siteName = openGraphData?.openGraph?.site_name || openGraphData?.hybridGraph?.site_name || openGraphData?.htmlInferred?.site_name;

  return { image, title, description, favicon, siteName };
};

export const isFileAttachment = (attachment?: IAttachment) => {
  return attachment?.type === AttachmentType.file;
};

export const isUrlAttachment = (attachment?: IAttachment) => {
  return attachment?.type === AttachmentType.url;
};

export const isIntegrationAttachment = (attachment?: IAttachment) => {
  return attachment?.type === AttachmentType.integration;
};

export const isReferenceAttachment = (attachment?: IAttachment) => {
  return attachment?.type === AttachmentType.reference;
};

export const getGitHubRepoMetadata = (url: string): SimpleGitHubRepoMetadata => {
  const [, path] = url.split("https://github.com/");
  const [repoOwner, repoName] = path.split("/");
  return { owner: repoOwner, name: repoName };
};

export const getAttachmentLink = (attachment: IAttachment): string | undefined => {
  switch (attachment.type) {
    case AttachmentType.file: {
      const previewType = getPreviewType(attachment);
      if (previewType !== FilePreviewType.None) {
        const attachmentIdLink = `#aid=${attachment.id}`;
        return `${window.location.href}${attachmentIdLink}`;
      }
      return;
    }
    case AttachmentType.reference: {
      const workspaceId = appStore.workspaceModel?.id;
      const referenceUrl = workspaceId && attachment.getReferenceUrl(workspaceId);
      return referenceUrl && `${window.location.origin}${referenceUrl}`;
    }
    case AttachmentType.url:
    case AttachmentType.integration:
      return attachment.linkAttachmentUrl ?? undefined;
  }
};
