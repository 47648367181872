import { useState } from "react";
import { EditableText } from "@blueprintjs/core";
import classNames from "classnames";
import { observer } from "mobx-react";

import { TCellRendererProps } from "@components/CatalogItems/Cells/types";
import { CustomIcon } from "@components/CustomIcon";
import { CellLines } from "@components/Table/Components/CellLines";
import appStore from "@store/AppStore";

import { initialPaddingWithChild, initialPaddingWithoutChild, nodeLevelPaddingStep } from "./constants";

import styles from "./PdmNameCell.module.scss";

const PdmNameCell = (props: TCellRendererProps) => {
  const catalogItem = props.data?.catalogItem;
  const reference = props.data?.reference;
  const [label, setLabel] = useState(catalogItem?.name || "");
  const [isExpanded, setIsExpanded] = useState(props.node.expanded);
  const [editing, setEditing] = useState(false);

  if (!catalogItem) {
    return null;
  }

  const showPdmThumbnail = appStore.env.showPdmThumbnail || appStore.ui.showPdmThumbnailForIds.includes(catalogItem.id);

  const hasChild =
    !reference && (!!catalogItem.validatedChildren.length || (!!catalogItem.childReferences.length && !appStore.ui.showPdmFlatten));
  const lastChildLineNodeHeight = showPdmThumbnail ? 27 : 10;
  const childLineNodeHeight = showPdmThumbnail ? "auto" : 24;
  const curveLineTopPosition = showPdmThumbnail ? 27 : 9;

  const handleExpand = () => {
    props.node.setExpanded(!props.node.expanded);
    setIsExpanded(!isExpanded);
  };

  const getPaddingLeft = () => {
    if (!props.node.uiLevel) {
      return hasChild ? initialPaddingWithChild : initialPaddingWithoutChild;
    }

    return props.node.uiLevel * nodeLevelPaddingStep + initialPaddingWithChild;
  };

  const handleNewLabelConfirm = (value: string) => {
    if (value) {
      catalogItem.setName(value);
    } else {
      setLabel(catalogItem.name);
    }

    setEditing(false);
  };

  return (
    <div
      className={classNames(styles.pdmNameCell, {
        [styles.pdmNameCellEditing]: editing,
      })}
      style={{ paddingLeft: getPaddingLeft() }}
    >
      <CellLines
        node={props.node}
        hasChild={hasChild}
        isExpanded={isExpanded}
        cellLinesStyles={{
          initialPaddingWithChild,
          nodeLevelPaddingStep,
          lastChildLineNodeHeight,
          straightLineTop: 4,
          straightLineBottom: 4,
          childLineNodeHeight,
          curveLineTopPosition,
        }}
        onToggleExpand={handleExpand}
      />
      <CustomIcon
        className={classNames(styles.pdmNameCellIcon, { [styles.pdmNameCellIconChildless]: !hasChild })}
        icon={catalogItem.icon}
      />
      <EditableText
        onEdit={() => setEditing(true)}
        onCancel={() => setEditing(false)}
        defaultValue={catalogItem.name}
        confirmOnEnterKey
        value={label}
        isEditing={editing}
        onChange={setLabel}
        onConfirm={handleNewLabelConfirm}
        placeholder="Name"
        className={styles.pdmNameCellName}
      />
    </div>
  );
};

export default observer(PdmNameCell);
