import { useEffect, useRef } from "react";

/**
 * Imperatively sets attribute in an HTMLElement using ref object
 */
export const useSetAttribute = <T extends HTMLElement>(attributeName: string, attributeValue: string) => {
  const ref = useRef<T>(null);

  useEffect(() => {
    ref.current?.setAttribute(attributeName, attributeValue);
  }, [attributeName, attributeValue]);

  return ref;
};
