import { ImgHTMLAttributes } from "react";
import { observer } from "mobx-react";

import { getAssetLink } from "@utilities/LinkUtils";

function AssetImage({ src, alt, ...props }: ImgHTMLAttributes<HTMLImageElement>) {
  return <img src={getAssetLink(src)} alt={alt} {...props} />;
}

export default observer(AssetImage);
