import { Component, ReactNode } from "react";
import { Collapse, EditableText, H4, Intent, Menu, MenuDivider } from "@blueprintjs/core";
import { SyntheticListenerMap } from "@dnd-kit/core/dist/hooks/utilities";
import classNames from "classnames";
import { observer } from "mobx-react";

import { Button } from "@components/Button";
import { MenuItem } from "@components/MenuItem";
import { Popover } from "@components/Popover";
import { SortableItemComponent } from "@components/Shared";
import { IBlock } from "@store/BlockStore";
import { IPropertyGroup } from "@store/PropertyGroupStore";

import "./BlockProperty.scss";
import "./PropertyGroup.scss";

interface PropertyGroupProps extends SortableItemComponent {
  group: IPropertyGroup;
  block: IBlock;
  dragListeners: SyntheticListenerMap;
  children?: ReactNode | ReactNode[];
  headerElement?: ReactNode;
}

class PropertyGroup extends Component<PropertyGroupProps, any> {
  handleCollapse = () => {
    this.props.group?.ui.toggleOpen();
  };

  handleGroupDeleteClicked = () => {
    this.props.block?.deletePropertyGroup(this.props.group);
  };

  render() {
    const { group, block } = this.props;

    if (!group || !block || !block.propertyGroups?.length) {
      return;
    }

    const isCollapsed = this.props.isDragging || !this.props.group.ui.isOpen;

    const propertyGroupMenu = (
      <Menu>
        <MenuDivider title="Actions" />
        <MenuItem icon="ungroup-objects" text="Ungroup" intent="warning" onClick={this.handleGroupDeleteClicked} e2eIdentifiers="ungroup" />
      </Menu>
    );

    return (
      <div className="property-group">
        {/* 1. Section: Actions/Title/Toggle. */}
        <div className={classNames("property-group--header", "dnd-drag-handle-item")}>
          {/* Actions */}
          <div className="flex gap-1">
            {this.props.dragListeners && (
              <Popover hoverCloseDelay={500} placement="left-start" content={propertyGroupMenu}>
                <Button
                  {...this.props.dragListeners}
                  minimal
                  intent={Intent.NONE}
                  icon="drag-handle-vertical"
                  e2eIdentifiers="show-property-group-menu"
                />
              </Popover>
            )}
            <div>
              <Button
                onClick={this.handleCollapse}
                minimal
                rightIcon={group.ui.isOpen ? "chevron-down" : "chevron-right"}
                e2eIdentifiers="toggle-collapse"
              />
            </div>
          </div>
          {/* Title - editable.*/}
          <div className="group-heading">
            <H4 className="mb-0">
              <EditableText
                className="w-full"
                key={`${group.id}-${group.label}`} // Workaround to ensure value gets updated if label changes from patch
                defaultValue={group.label}
                onConfirm={group.setLabel}
              />
            </H4>
            <div className="group-heading--element">{this.props.headerElement}</div>
          </div>
        </div>

        {/* 2. Section: Collapsible content. */}
        <Collapse className="property-group--collapsible" isOpen={!isCollapsed} keepChildrenMounted={false}>
          <div className="property-group--collapsible-content">{this.props.children}</div>
        </Collapse>
      </div>
    );
  }
}

export default observer(PropertyGroup);
