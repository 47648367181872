import { useCallback, useEffect, useState } from "react";
import { HTMLTable, Icon, InputGroup, Menu, MenuDivider } from "@blueprintjs/core";
import { observer } from "mobx-react";
import { useDebounceValue } from "usehooks-ts";

import { AnchorButton } from "@components/AnchorButton";
import { Button } from "@components/Button";
import { DeleteConfirmationDialog } from "@components/DeleteConfirmationDialog";
import { MenuItem } from "@components/MenuItem";
import { MenuItemDelete } from "@components/MenuItems";
import { Popover } from "@components/Popover";
import { showToast } from "@components/UiLayers/toaster";
import { UserPermission } from "@rollup-api/api/authTypes";
import { ApiKey } from "@rollup-api/models/apiKeys";
import { ApiKeySortKeys } from "@rollup-api/models/apiKeys/ApiKeySortKeys";
import { GetApiKeysRequestDto } from "@rollup-api/models/apiKeys/apiKeysRequestDto.model";
import { GetApiKeysResponseDto } from "@rollup-api/models/apiKeys/apiKeysResponseDto.model";
import { SortOrders } from "@rollup-api/SortOrders";
import appStore from "@store/AppStore";
import { copyToClipboard } from "@utilities";
import { convertTimestampToDate, getFormattedDate } from "@utilities/Date";
import { rollupClient } from "src/core/api";
import { Text, TextVariant } from "src/ui/Text";

import SettingsAddNewApiKey from "./SettingsAddNewApiKey";

import "./SettingsApiKeys.scss";

/** Main function. */
const SettingsApiKeys = () => {
  const [paginatedApiKeys, setPaginatedApiKeys] = useState<ApiKey[]>();
  const [searchTerm, setSearchTerm] = useState("");
  const [debouncedSearchTerm] = useDebounceValue(searchTerm, 400);
  const [isNewApiKeyScreenOpen, setIsNewApiKeyScreenOpen] = useState(false);
  const [existingApiId, setExistingApiId] = useState<string | undefined>(undefined);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [itemToDelete, setItemToDelete] = useState<ApiKey>();

  const fetchApiKeys = async (take: number, skip: number, sortedBy: ApiKeySortKeys, sortOrder: SortOrders, sTerm?: string) => {
    const dto: GetApiKeysRequestDto = {
      take,
      skip,
      sortedBy,
      sortOrder,
      search: sTerm || undefined,
    };
    const apiKeysResponse: GetApiKeysResponseDto | undefined = await rollupClient.apiKeys.retrieveList(dto);
    if (apiKeysResponse) {
      setPaginatedApiKeys(apiKeysResponse.apiKeys);
    }
  };

  useEffect(() => {
    if (!isNewApiKeyScreenOpen) {
      fetchApiKeys(1000, 0, ApiKeySortKeys.createdAt, SortOrders.DESC, searchTerm);
    }
  }, [isNewApiKeyScreenOpen, searchTerm]);

  useEffect(() => {
    fetchApiKeys(1000, 0, ApiKeySortKeys.createdAt, SortOrders.DESC, debouncedSearchTerm);
  }, [debouncedSearchTerm]);

  const handleCopyClicked = useCallback(async (apiKey: ApiKey) => {
    await copyToClipboard(apiKey.apiKey);
    showToast(`Copied API key ${apiKey.label} to clipboard`, "success", "info-sign");
  }, []);

  const renderMoreOptionsButton = (apiKey: ApiKey) => {
    return (
      <Popover
        placement="bottom-end"
        content={
          <Menu>
            <MenuItem icon="duplicate" text="Copy API Key" onClick={() => handleCopyClicked(apiKey)} e2eIdentifiers="" />
            <MenuDivider />
            <MenuItem
              icon="edit"
              text="Edit"
              onClick={() => {
                setExistingApiId(apiKey.id);
                setIsNewApiKeyScreenOpen(true);
              }}
              e2eIdentifiers="edit"
            />
            <MenuItemDelete
              onDelete={() => {
                setItemToDelete(apiKey);
                setIsDeleteDialogOpen(true);
              }}
            />
          </Menu>
        }
      >
        <AnchorButton minimal rightIcon="more" e2eIdentifiers="show-menu" />
      </Popover>
    );
  };

  const renderApiList = () => {
    return (
      <>
        <DeleteConfirmationDialog
          titleItem="API key"
          descriptionItem="this API key"
          isOpen={isDeleteDialogOpen}
          onCancel={() => setIsDeleteDialogOpen(false)}
          onClose={() => setIsDeleteDialogOpen(false)}
          onConfirm={async () => {
            if (itemToDelete) {
              await rollupClient.apiKeys.delete(itemToDelete.id);
              fetchApiKeys(1000, 0, ApiKeySortKeys.createdAt, SortOrders.DESC, searchTerm);
            }
            setIsDeleteDialogOpen(false);
          }}
        />
        <HTMLTable className="min-w-full">
          <thead>
            <tr>
              <th>
                <Text variant={TextVariant.H5}>Label</Text>
              </th>
              <th>
                <Text variant={TextVariant.H5}>Scopes</Text>
              </th>
              <th>
                <Text variant={TextVariant.H5}>Creation date</Text>
              </th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {!paginatedApiKeys?.length ? (
              <tr>
                <td colSpan={5} className="message">
                  <Text variant={TextVariant.Caption}>You don't have any API keys meeting the filter criteria</Text>
                </td>
              </tr>
            ) : (
              paginatedApiKeys?.map((apiKey: ApiKey) => (
                <tr key={apiKey.id}>
                  <td>
                    <Text variant={TextVariant.H3}>{apiKey.label}</Text>
                  </td>
                  <td>
                    <Text variant={TextVariant.Body}>{apiKey.scopes?.join(", ")}</Text>
                  </td>
                  <td>
                    <Text variant={TextVariant.Body}>{getFormattedDate(convertTimestampToDate(apiKey.createdAt))}</Text>
                  </td>
                  <td>{renderMoreOptionsButton(apiKey)}</td>
                </tr>
              ))
            )}
          </tbody>
        </HTMLTable>
      </>
    );
  };

  const renderApiSettings = () => {
    return (
      <div className="settings-layout--content">
        <div className="settings-layout--header">
          <Text variant={TextVariant.H1}>API keys</Text>
          <div className="settings-layout--header--right-section">
            <InputGroup
              className="settings-layout--search"
              large
              value={searchTerm}
              onChange={e => setSearchTerm(e.target.value)}
              placeholder="Search for API key..."
              leftElement={<Icon icon="search" />}
            />
            <Button
              large
              intent="primary"
              icon="plus"
              className="settings-layout--cta"
              onClick={() => {
                setExistingApiId(undefined);
                setIsNewApiKeyScreenOpen(true);
              }}
              disabled={!appStore.userModel?.hasPermission(UserPermission.InviteUsers)}
              e2eIdentifiers="new-api-keu"
            >
              Add API key
            </Button>
          </div>
        </div>
        <div className="api-keys-area">{renderApiList()}</div>
      </div>
    );
  };

  const renderAddNewApiKey = () => {
    return (
      <SettingsAddNewApiKey
        apiId={existingApiId}
        onClose={() => {
          setExistingApiId(undefined);
          setIsNewApiKeyScreenOpen(false);
        }}
      />
    );
  };

  return <>{isNewApiKeyScreenOpen ? renderAddNewApiKey() : renderApiSettings()}</>;
};

/** Exports. */
export default observer(SettingsApiKeys);
