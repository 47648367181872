import { useState } from "react";
import { Checkbox, DialogBody, Intent, Radio } from "@blueprintjs/core";
import { observer } from "mobx-react";

import { Button } from "@components/Button";
import { showToast } from "@components/UiLayers/toaster";
import { ImportExtensionType, ImportType } from "@rollup-api/models/import";
import appStore from "@store/AppStore";
import { Text } from "src/ui/Text";

import DialogLegacy from "../DialogLegacy";

import "./AddNewCadFile.scss";

enum UploadType {
  Attachment = "attachment",
  ModelTree = "model-tree",
}

const AddNewCadFile = () => {
  const [type, setType] = useState<UploadType>(UploadType.Attachment);
  const [dryRun, setDryRun] = useState(true);

  const handleImport = () => {
    if (appStore.ui.cadFileUploadData) {
      const blockId = appStore.ui.cadFileUploadData.blockId;
      if (type === UploadType.Attachment) {
        appStore.orgModel.uploads.addNewFileUpload({
          blockId,
          workspaceId: appStore.workspaceModel?.id,
          files: appStore.ui.cadFileUploadData.fileList,
        });
      } else {
        const file = appStore.ui.cadFileUploadData.fileList[0];
        appStore.workspaceModel?.attachments
          .add(
            {
              blockId,
              label: file.name,
              workspaceId: appStore.workspaceModel.id,
            },
            file
          )
          .then(attachmentId => {
            if (attachmentId) {
              appStore.workspaceModel?.addImport(
                {
                  originalFileName: file.name,
                  attachmentId,
                  entityId: blockId,
                  type: ImportType.Block,
                  columnMap: "",
                  dryRun,
                },
                ImportExtensionType.CAD
              );
            }
          });
        appStore.ui.showUploadQueueWindow();
        showToast("The CAD import has started", Intent.SUCCESS);
      }
    }
    appStore.ui.hideAddNewCadFileDialog();
  };

  return (
    <DialogLegacy
      className="add-new-cad-file"
      onClose={appStore.ui.hideAddNewCadFileDialog}
      isOpen={!!appStore.ui.cadFileUploadData && !!appStore.ui.cadFileUploadData.blockId}
      canEscapeKeyClose
      canOutsideClickClose
      isCloseButtonShown
      title="CAD file handling"
    >
      <DialogBody>
        <div className="add-new-cad-file--body">
          <Text className="add-new-cad-file--title">What would you like to do with this file?</Text>
          <div className="add-new-cad-file--option">
            <Radio
              checked={type === UploadType.Attachment}
              onChange={() => setType(UploadType.Attachment)}
              name="type"
              large
              label="Attach to the block"
              value={UploadType.Attachment}
            />
            <span className="add-new-cad-file--caption">
              This will attach this file to the block as an attachment. If an attachment with the same name already exists, you will be
              asked if you'd like to add it as a separate attachment or to add it to an existing attachment as a new version.
            </span>
          </div>
          <div className="add-new-cad-file--option">
            <Radio
              checked={type === UploadType.ModelTree}
              onChange={() => setType(UploadType.ModelTree)}
              name="type"
              large
              label="Import into the model"
              value={UploadType.ModelTree}
            />
            <span className="add-new-cad-file--caption">
              If you'd like to create additional model blocks from this CAD file, select this option. This is great for generating Rollup
              models quickly from complex CAD assemblies.
            </span>
          </div>
          {type === UploadType.ModelTree && (
            <div className="add-new-cad-file--option">
              <Checkbox large checked={dryRun} onChange={e => setDryRun(e.target.checked)} label="Dry run" />
              <div className="add-new-cad-file--caption">The system will require your confirmation before saving any data</div>
            </div>
          )}
        </div>
        <div className="add-new-cad-file--actions">
          <Button onClick={appStore.ui.hideAddNewCadFileDialog} large minimal e2eIdentifiers="cance-btn">
            Cancel
          </Button>
          <Button onClick={handleImport} intent={Intent.PRIMARY} large e2eIdentifiers="add-new-cad">
            Confirm
          </Button>
        </div>
      </DialogBody>
    </DialogLegacy>
  );
};

export default observer(AddNewCadFile);
