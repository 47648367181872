import { observer } from "mobx-react-lite";

import { Loading } from "@components/Loading";

import "./AttachmentDetailsLoading.scss";

const AttachmentDetailsLoading = () => {
  return (
    <div className="attachment-details-loading">
      <Loading title="Loading..." />
    </div>
  );
};

export default observer(AttachmentDetailsLoading);
