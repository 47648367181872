import { cast, Instance, types } from "mobx-state-tree";

export enum RollupEventTypes {
  EMAIL = "email",
  DESKTOP = "desktop",
  SLACK = "slack",
}

export enum RollupEventEvents {
  MENTIONED_IN_COMMENT = "mentionedInComment",
  COMMENT_REPLIED = "commentReplied",
  MENTIONED_IN_REPORT = "mentionedInReport",
}

export enum RollupEventEventAreas {
  COMMENTS = "comments",
  REPORTS = "reports",
}

export const RollupEventEventGroups: { [key in RollupEventEventAreas]: RollupEventEvents[] } = {
  comments: [RollupEventEvents.MENTIONED_IN_COMMENT, RollupEventEvents.COMMENT_REPLIED],
  reports: [RollupEventEvents.MENTIONED_IN_REPORT],
};

export const RollupEventSettings = types
  .model("RollupEventsSettings", {
    emailRollupEvents: types.array(types.enumeration<RollupEventEvents>("RollupEventTypes", [...Object.values(RollupEventEvents)])),
    desktopRollupEvents: types.array(types.enumeration<RollupEventEvents>("RollupEventTypes", [...Object.values(RollupEventEvents)])),
    slackRollupEvents: types.array(types.enumeration<RollupEventEvents>("RollupEventTypes", [...Object.values(RollupEventEvents)])),
  })
  .actions(self => ({
    toggleRollupEvent(rollupEventType: RollupEventTypes, rollupEventEvent: RollupEventEvents) {
      switch (rollupEventType) {
        case RollupEventTypes.EMAIL:
          if (self.emailRollupEvents.includes(rollupEventEvent)) {
            self.emailRollupEvents = cast(self.emailRollupEvents.filter(type => type !== rollupEventEvent));
          } else {
            self.emailRollupEvents.push(rollupEventEvent);
          }
          break;
        case RollupEventTypes.DESKTOP:
          if (self.desktopRollupEvents.includes(rollupEventEvent)) {
            self.desktopRollupEvents = cast(self.desktopRollupEvents.filter(type => type !== rollupEventEvent));
          } else {
            self.desktopRollupEvents.push(rollupEventEvent);
          }
          break;
        case RollupEventTypes.SLACK:
          if (self.slackRollupEvents.includes(rollupEventEvent)) {
            self.slackRollupEvents = cast(self.slackRollupEvents.filter(type => type !== rollupEventEvent));
          } else {
            self.slackRollupEvents.push(rollupEventEvent);
          }
          break;
      }
    },
    setRollupEvent(rollupEventType: RollupEventTypes, rollupEventEvent: RollupEventEvents, value: boolean) {
      switch (rollupEventType) {
        case RollupEventTypes.EMAIL:
          if (value) {
            self.emailRollupEvents.push(rollupEventEvent);
          } else {
            self.emailRollupEvents = cast(self.emailRollupEvents.filter(type => type !== rollupEventEvent));
          }
          break;
        case RollupEventTypes.DESKTOP:
          if (value) {
            self.desktopRollupEvents.push(rollupEventEvent);
          } else {
            self.desktopRollupEvents = cast(self.desktopRollupEvents.filter(type => type !== rollupEventEvent));
          }
          break;
        case RollupEventTypes.SLACK:
          if (value) {
            self.slackRollupEvents.push(rollupEventEvent);
          } else {
            self.slackRollupEvents = cast(self.slackRollupEvents.filter(type => type !== rollupEventEvent));
          }
          break;
      }
    },
    setRollupEventEventsGroup(
      rollupEventType: RollupEventTypes,
      rollupEventEventGroup: keyof typeof RollupEventEventGroups,
      value: boolean
    ) {
      RollupEventEventGroups[rollupEventEventGroup].forEach(event => {
        this.setRollupEvent(rollupEventType, event, value);
      });
    },
  }))
  .views(self => ({
    isRollupEventsEnabled(rollupEventType: RollupEventTypes, rollupEventEvent: RollupEventEvents) {
      switch (rollupEventType) {
        case RollupEventTypes.EMAIL:
          return self.emailRollupEvents.includes(rollupEventEvent);
        case RollupEventTypes.DESKTOP:
          return self.desktopRollupEvents.includes(rollupEventEvent);
        case RollupEventTypes.SLACK:
          return self.slackRollupEvents.includes(rollupEventEvent);
      }
    },
    getRollupEventEventsGroup(rollupEventType: RollupEventTypes, eventGroup: keyof typeof RollupEventEventGroups) {
      const rollupEventTypeEventEnabledArray = RollupEventEventGroups[eventGroup].filter(event =>
        this.isRollupEventsEnabled(rollupEventType, event)
      );
      if (rollupEventTypeEventEnabledArray.length === 0) {
        return false;
      }
      if (rollupEventTypeEventEnabledArray.length === RollupEventEventGroups[eventGroup].length) {
        return true;
      }
      return "indeterminate";
    },
  }));

export const UserSettingsStore = types.model("UserSettings", {
  rollupEventSettings: types.optional(RollupEventSettings, {
    emailRollupEvents: [],
    desktopRollupEvents: [],
    slackRollupEvents: [],
  }),
});

export interface IUserSettings extends Instance<typeof UserSettingsStore> {}
