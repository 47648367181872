import omit from "lodash/omit";
import { applySnapshot, getSnapshot } from "mobx-state-tree";
import { IStateTreeNode, IType } from "mobx-state-tree/dist/internal";

import { StringKeys } from "@rollup-types/typeUtils";

export const applySanitizedSnapshot = <S extends object, T extends IStateTreeNode<IType<S, any, any>>, K extends StringKeys<T>>(
  treeNode: T,
  snapshot: S,
  invalidFields: K[]
) => {
  const sanitizedSnapshot = omit(snapshot, invalidFields);
  const currentSnapshot = getSnapshot(treeNode);
  const mergedSnapshot = { ...currentSnapshot, ...sanitizedSnapshot };
  try {
    applySnapshot(treeNode, mergedSnapshot);
    return true;
  } catch (err) {
    console.warn(err);
    return false;
  }
};
