import { useParams } from "react-router-dom";
import { useHandleDeleteDocument } from "@hooks/useHandleDeleteDocument";

import { DeleteConfirmationDialog } from "@components/DeleteConfirmationDialog";
import { EPageName } from "@router/hooks/useAppNavigate";
import appStore from "@store/AppStore";
import { IReport } from "@store/ReportsStore";

type DeleteReportConfirmationProps = {
  report: IReport;
  isOpen: boolean;
  onClose(): void;
  onDelete?(): void;
};

const DeleteReportConfirmation = (props: DeleteReportConfirmationProps) => {
  const { report, isOpen, onClose, onDelete } = props;
  const { reportId: currentReportId } = useParams();

  const handleDeleteAction = () => {
    appStore.workspaceModel?.deleteReport(report.id);
    onClose();
  };

  const handleDelete = useHandleDeleteDocument({
    document: report,
    docArray: appStore.workspaceModel?.reports,
    pageName: EPageName.Knowledgebase,
    disableNavigation: report.id !== currentReportId,
    deleteDocument: handleDeleteAction,
  });

  const reportLabel = report.label || "Untitled";

  return (
    <DeleteConfirmationDialog
      titleItem={`Page "${reportLabel}"`}
      descriptionItem={`Page "${reportLabel}"`}
      onCancel={onClose}
      onClose={onClose}
      onConfirm={onDelete ?? handleDelete}
      isOpen={isOpen}
    />
  );
};

export default DeleteReportConfirmation;
