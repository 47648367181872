import { toArray } from "@rollup-io/engineering";
import { destroy, flow, types } from "mobx-state-tree";
import { v4 as uuidv4 } from "uuid";

import { showApiErrorToast } from "@components/UiLayers/toaster";
import { IRequirementsPage, IRequirementsPageMobxType, RequirementsPageStore } from "@store/RequirementsStore";
import { ICreateRequirementsPageData } from "@store/types";

import { rollupClient } from "../core/api";

export const RequirementsPageModuleStore = types
  .model("RequirementsPageModuleStore", {
    requirementsPageMap: types.map<IRequirementsPageMobxType>(RequirementsPageStore),
  })
  .views(self => ({
    get values(): IRequirementsPage[] {
      return toArray<IRequirementsPage>(self.requirementsPageMap);
    },
    get(id: string): IRequirementsPage | undefined {
      return self.requirementsPageMap.get(id);
    },
  }))
  .actions(self => ({
    delete(id: string, notify = true) {
      const page = self.get(id);

      if (notify) {
        rollupClient.requirementsPages.delete(id).catch((err: Error) => {
          showApiErrorToast("Error deleting requirements pages", err);
        });
      }

      destroy(page);
      return true;
    },
  }))
  .actions(self => ({
    createRequirementsPage: flow(function* addRequirementsPage(
      data: ICreateRequirementsPageData = {},
      notify = true
    ): Generator<any, IRequirementsPage | undefined, any> {
      const { id = uuidv4(), label = "New Document" } = data;
      const newPage = self.requirementsPageMap.put({
        id,
        label,
        replicated: !notify,
      });

      if (notify) {
        try {
          const res = yield rollupClient.requirementsPages.create({ id, label });
          if (res.status === 201) {
            newPage.setReplicated();
          } else {
            showApiErrorToast("Error creating requirements document", new Error());
            self.delete(newPage.id, false);
            return undefined;
          }
        } catch (err) {
          console.warn(err);
          showApiErrorToast("Error creating requirements document", err as Error);
          return undefined;
        }
      }

      return newPage;
    }),
  }));
