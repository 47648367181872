import { Handle, NodeProps, Position } from "reactflow";
import { Tooltip } from "@blueprintjs/core";
import classNames from "classnames";
import { observer } from "mobx-react";

import { Icon } from "@components/Icon";
import { TitledTooltip } from "@components/Shared";
import { IDataSinkEntry } from "@store/DataConnection/DataSinkEntryStore";
import { humanReadableDateTime, joinStrings } from "@utilities";

import { CustomNodeType } from "../types";

import "./DataSinkEntryNode.scss";

/** Type defs. */
type DataSinkEntryNodeData = {
  type: CustomNodeType;
  id: string;
  dataSinkEntry: IDataSinkEntry;
  active: boolean;
};
type DataSinkEntryNodeProps = NodeProps<DataSinkEntryNodeData>;

/** Main function. */
function DataSinkEntryNode(props: DataSinkEntryNodeProps) {
  const {
    targetPosition,
    sourcePosition,
    data: { dataSinkEntry, type, active },
  } = props;

  const dataSink = dataSinkEntry.dataSink;

  return (
    <div className={classNames("data-sink-entry-node", { active })}>
      {type !== CustomNodeType.INPUT && <Handle type="target" position={targetPosition || Position.Left} className="invisible" />}
      <div className="container">
        {dataSink?.icon && <Icon icon={dataSink?.icon} />}
        <div className="content">
          <Tooltip content={dataSink?.description} disabled={!dataSink?.description} placement="top">
            <div className="subtitle">{dataSink?.label ?? "Unknown"}</div>
          </Tooltip>
          <TitledTooltip content={`Last updated ${humanReadableDateTime(dataSinkEntry.updatedAt)}`} position={Position.Right}>
            <div className="content--title">
              <span className="content--title--label">{dataSinkEntry.key}</span> <span>&nbsp;=&nbsp;</span>
              <span className="content--title--value">{joinStrings(dataSinkEntry.value, " ")}</span>
            </div>
          </TitledTooltip>
        </div>
      </div>
      {type !== CustomNodeType.OUTPUT && <Handle type="source" position={sourcePosition || Position.Right} className="invisible" />}
    </div>
  );
}

/** Exports. */
export type { DataSinkEntryNodeData, DataSinkEntryNodeProps };
export default observer(DataSinkEntryNode);
