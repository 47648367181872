import { AnchorButton } from "@components/AnchorButton";

import "./ChangelogButton.scss";

export const ChangelogButton = () => (
  <AnchorButton className="changelog-button" minimal target="_blank" href="https://rollup.ai/changelogs" e2eIdentifiers="changelog">
    Changelog
  </AnchorButton>
);

export default ChangelogButton;
