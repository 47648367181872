import { InputGroup, Intent, PopoverPosition } from "@blueprintjs/core";
import { useInputHandlers } from "@hooks/useInputHandlers";
import { PropertyDataType } from "@rollup-io/engineering";
import { BlueprintIcon } from "@ui/BlueprintIcon";
import { observer } from "mobx-react";

import { FormGroup } from "@components/FormGroup";
import { RichTextEditor } from "@components/RichTextEditor";
import { ScalarExpressionEditor } from "@components/Shared";
import ConfirmableNumericInput from "@components/Shared/ConfirmableNumericInput/ConfirmableNumericInput";
import {
  getValidationErrorMessage,
  parseUncertaintyToNumber,
  parseUncertaintyToString,
} from "@router/components/BlockView/PropertyDetailsViewer/PropertyDetailsUtils";
import appStore from "@store/AppStore";
import { UncertaintySymmetry, UncertaintyType } from "@store/PropertyDefinitionStore";
import { IPropertyInstance } from "@store/PropertyInstanceStore";

import "./PropertyInstanceDetails.scss";

interface IPropertyInstanceDetailsProps {
  propertyInstance: IPropertyInstance;
}

const PropertyInstanceDetails = (props: IPropertyInstanceDetailsProps) => {
  const { propertyInstance } = props;
  const { propertyDefinition } = propertyInstance;
  const isScalarDataType = propertyDefinition?.dataType === PropertyDataType.scalar;
  const isRelative = propertyDefinition?.uncertaintyType === UncertaintyType.Relative;
  const isSymmetrical = propertyDefinition?.uncertaintySymmetry === UncertaintySymmetry.Symmetrical;
  const uncertaintyRightEl = isRelative ? <BlueprintIcon className="property-instance-details--icon" icon="percentage" /> : undefined;

  const handleCommitLowerBoundUncertainty = (value: string) => {
    const parsedValue = parseUncertaintyToNumber(value, isRelative);
    propertyInstance.setLowerBoundUncertainty(parsedValue, true);

    if (isSymmetrical) {
      propertyInstance.setUpperBoundUncertainty(parsedValue, true);
    }
  };

  const handleCommitUpperBoundUncertainty = (value: string) => {
    const parsedValue = parseUncertaintyToNumber(value, isRelative);
    propertyInstance.setUpperBoundUncertainty(parsedValue, true);
  };

  const {
    value: lowerBoundUncertainty,
    handleChange: handleChangeLowerBoundUncertainty,
    handleKeyDown: handleKeyDownLowerBoundUncertainty,
    handleBlur: handleBlurLowerBoundUncertainty,
    error: errorLowerBoundUncertainty,
    ref: refLowerBoundUncertainty,
  } = useInputHandlers({
    value: parseUncertaintyToString(propertyInstance.lowerBoundUncertainty, isRelative),
    getValidationErrorMessage,
    onChange: handleCommitLowerBoundUncertainty,
  });

  const {
    value: upperBoundUncertainty,
    handleChange: handleChangeUpperBoundUncertainty,
    handleKeyDown: handleKeyDownUpperBoundUncertainty,
    handleBlur: handleBlurUpperBoundUncertainty,
    error: errorUpperBoundUncertainty,
    ref: refUpperBoundUncertainty,
  } = useInputHandlers({
    value: parseUncertaintyToString(propertyInstance.upperBoundUncertainty, isRelative),
    getValidationErrorMessage,
    onChange: handleCommitUpperBoundUncertainty,
  });

  const commitChanges = (content: string) => {
    if (appStore.userModel) {
      propertyInstance.propertyDefinition?.setDescription(content, appStore.userModel.id, true);
    }
  };

  return (
    <div className="property-instance-details">
      <div className="property-instance-details--uncertainty-row">
        <FormGroup
          className="uncertainty"
          label={isSymmetrical ? "Uncertainty" : "Uncertainty low"}
          helperText={errorLowerBoundUncertainty}
        >
          <InputGroup
            inputRef={refLowerBoundUncertainty}
            value={lowerBoundUncertainty}
            onChange={handleChangeLowerBoundUncertainty}
            onKeyDown={handleKeyDownLowerBoundUncertainty}
            onBlur={handleBlurLowerBoundUncertainty}
            intent={errorLowerBoundUncertainty ? Intent.DANGER : undefined}
            rightElement={uncertaintyRightEl}
          />
        </FormGroup>
        {!isSymmetrical && (
          <FormGroup className="uncertainty" label="Uncertainty high" helperText={errorUpperBoundUncertainty}>
            <InputGroup
              inputRef={refUpperBoundUncertainty}
              value={upperBoundUncertainty}
              onChange={handleChangeUpperBoundUncertainty}
              onKeyDown={handleKeyDownUpperBoundUncertainty}
              onBlur={handleBlurUpperBoundUncertainty}
              intent={errorUpperBoundUncertainty ? Intent.DANGER : undefined}
              rightElement={uncertaintyRightEl}
            />
          </FormGroup>
        )}
      </div>
      <FormGroup label="Value">
        {isScalarDataType ? (
          <ScalarExpressionEditor
            propertyInstance={propertyInstance}
            popoverPosition={PopoverPosition.BOTTOM_RIGHT}
            disableNotifications
            enableWrappingOnFocus
          />
        ) : (
          <ConfirmableNumericInput
            value={propertyInstance.value}
            tryConfirmValueFromString={value => propertyInstance.setStringValue(value, true)}
            disabled={propertyInstance.locked}
            placeholder="String value"
            applyValueOnChange
          />
        )}
      </FormGroup>
      <FormGroup label="Description">
        <RichTextEditor
          className="property-instance-details--editor"
          content={propertyDefinition?.description ?? ""}
          onChange={commitChanges}
        />
      </FormGroup>
    </div>
  );
};

export default observer(PropertyInstanceDetails);
