import { useState } from "react";
import { ContextMenu, EditableText, Icon } from "@blueprintjs/core";
import { useOnMount } from "@hooks/useOnMount";
import classNames from "classnames";
import { observer } from "mobx-react";

import { useAttachmentFunctions } from "@components/Modeling/ModelingFrame/ModelBlock/Attachments/attachmentHooks";
import AttachmentIcon from "@components/Modeling/ModelingFrame/ModelBlock/Attachments/AttachmentIcon";
import AttachmentContextMenuFile from "@components/Modeling/ModelingFrame/ModelBlock/Attachments/ContextMenu/AttachmentContextMenuFile";
import MoreOptionsMenu from "@components/MoreOptionsMenu/MoreOptionsMenu";
import { StarIcon } from "@components/StarIcon";
import { Tag } from "@components/Tag";
import { Tooltip } from "@components/Tooltip";
import appStore from "@store/AppStore";
import { IAttachment } from "@store/AttachmentStore";

import styles from "./AttachmentCardItem.module.scss";

interface IAttachmentCardItemProps {
  attachment: IAttachment;
  versionIndex: number;
  isMainAttachment: boolean;
  onMarkAsMain(attachmentId: string): void;
}

const AttachmentCardItem = (props: IAttachmentCardItemProps) => {
  const { attachment, versionIndex, isMainAttachment, onMarkAsMain } = props;
  const { isCatalogItemTableVerticalSplit } = appStore.env;
  const { handleDownload, handleRetry, handleConfirm, handleDelete } = useAttachmentFunctions({ attachment });
  const [isEditingName, setIsEditingName] = useState(false);

  useOnMount(() => {
    if (!attachment.annotationList.fetchedAnnotationsAmount) {
      attachment.annotationList.fetchAnnotationsAmount();
    }
  });

  const commentsAmount = attachment.annotationList.annotations.length || attachment.annotationList.annotationsAmount;

  const handleOpenDetailsDialog = () => {
    appStore.ui.setAttachmentDetails(attachment.id);
  };

  const handleLabelChange = (value: string) => {
    setIsEditingName(false);
    handleConfirm(value);
  };

  const handleMarkAsMain = () => {
    onMarkAsMain(attachment.id);
  };

  const renderContextMenu = () => {
    return (
      <AttachmentContextMenuFile
        attachment={attachment}
        onDelete={handleDelete}
        onDownload={handleDownload}
        onRetry={handleRetry}
        onMarkAsMain={handleMarkAsMain}
        onOpenEditingLabel={() => setIsEditingName(true)}
        onOpenDetailsDialog={handleOpenDetailsDialog}
      />
    );
  };

  return (
    <ContextMenu
      className={classNames(styles.attachmentCardItem, { [styles.attachmentCardItemVertical]: isCatalogItemTableVerticalSplit })}
      content={renderContextMenu}
    >
      <div className={styles.attachmentCardItemMeta} onClick={handleOpenDetailsDialog}>
        <div className={styles.attachmentCardItemIconContainer}>
          <AttachmentIcon mimeType="model/" />
          {isMainAttachment && <StarIcon className={styles.attachmentCardItemIconStar} filled colorful />}
        </div>
        <div className={styles.attachmentCardItemFileNameWrapper} onClick={isEditingName ? e => e.stopPropagation() : undefined}>
          <EditableText
            key={attachment.id}
            className={styles.attachmentCardItemFileName}
            isEditing={isEditingName}
            maxLength={179}
            minWidth={20}
            placeholder="Name"
            disabled={!isEditingName}
            defaultValue={attachment.label}
            confirmOnEnterKey
            onConfirm={handleLabelChange}
            onCancel={() => setIsEditingName(false)}
          />
        </div>
        <Tooltip content="Related file version">
          <Tag className={styles.attachmentCardItemTag} minimal>
            Ver {versionIndex}
          </Tag>
        </Tooltip>
        {!!commentsAmount && (
          <Tooltip content="Comments amount">
            <span className={styles.attachmentCardItemComments}>
              <Icon icon="comment" /> {commentsAmount}
            </span>
          </Tooltip>
        )}
        <div onClick={e => e.stopPropagation()}>
          <MoreOptionsMenu
            className={styles.attachmentCardItemMoreOptionsMenu}
            content={renderContextMenu()}
            e2eIdentifiers={attachment.label}
            small
          />
        </div>
      </div>
    </ContextMenu>
  );
};

export default observer(AttachmentCardItem);
