import { useCallback, useEffect, useRef } from "react";
import { useDebouncedCallback } from "@hooks/useDebouncedCallback";

export const useInfiniteScrollFetch = (fetchFunction: VoidFunction, isFetching: boolean) => {
  const loadMoreAnchorRef = useRef<HTMLDivElement>(null);
  const scrollContainerRef = useRef<HTMLDivElement>(null);

  const fetchMoreIfAnchorVisible = useCallback(() => {
    if (loadMoreAnchorRef.current && loadMoreAnchorRef.current.getBoundingClientRect().top <= window.innerHeight) {
      fetchFunction();
    }
  }, [fetchFunction]);

  const debouncedFetchMore = useDebouncedCallback(fetchMoreIfAnchorVisible, 200);

  useEffect(() => {
    if (scrollContainerRef.current) {
      const listRef = scrollContainerRef.current;
      listRef.addEventListener("scroll", debouncedFetchMore);

      return () => {
        listRef.removeEventListener("scroll", debouncedFetchMore);
      };
    }
  }, [scrollContainerRef, debouncedFetchMore]);

  // load more history if scroll is at the bottom
  useEffect(() => {
    if (!isFetching) {
      debouncedFetchMore();
    }
  }, [isFetching, debouncedFetchMore]);

  return { loadMoreAnchorRef, scrollContainerRef };
};
