import { BlueprintIcon } from "@ui/BlueprintIcon";
import classNames from "classnames";

import { Text } from "src/ui/Text";

import "./CreatePropertySelect.scss";

type CreatePropertySelectAnchorProps = {
  open?: boolean;
  label?: string;
};

export const CreatePropertySelectAnchor = ({ open, label }: CreatePropertySelectAnchorProps) => {
  return (
    <div className={classNames("select-anchor", open && "active")}>
      <Text className="anchor-text">{label}</Text>
      <BlueprintIcon icon="caret-down" className="icon" />
    </div>
  );
};
