import { RefObject, useCallback, useEffect, useRef, useState } from "react";
import { Icon } from "@blueprintjs/core";
import { ItemRendererProps, MultiSelect } from "@blueprintjs/select";
import classNames from "classnames";
import { observer } from "mobx-react";
import { isAlive } from "mobx-state-tree";

import { MenuItem } from "@components/MenuItem";
import { IPopoverRefType } from "@components/Popover";
import UserInfo from "@components/UserInfo/UserInfo";
import appStore from "@store/AppStore";
import type { IBlock } from "@store/BlockStore";
import type { IStatusDefinition } from "@store/StatusDefinitionStore";
import { IStatusInstance } from "@store/StatusInstanceStore";
import { IUser } from "@store/UserStore";

import "./StatusTypeMentionEditor.scss";

/** Type defs. */
type StatusTypeMentionEditorProps = {
  statusDefinition?: IStatusDefinition;
  block?: IBlock | null;
  onComponentReady?: (popoverRef: React.RefObject<IPopoverRefType>) => void;
  placeholder?: string;
  disabled?: boolean;
  matchTargetWidth?: boolean;
};

/** Main function. */
function StatusTypeMentionEditor(props: StatusTypeMentionEditorProps) {
  const [query, setQuery] = useState("");
  const { statusDefinition, block, onComponentReady, placeholder, matchTargetWidth, disabled } = props;
  const [statusInstance, setStatusInstance] = useState<IStatusInstance | undefined>(undefined);
  const popoverRef: RefObject<IPopoverRefType> = useRef(null);

  useEffect(() => {
    onComponentReady?.(popoverRef);
  }, [onComponentReady]);

  const { getStatusInstanceByStatusDefinitionId, validatedStatusInstances } = block ?? {};

  useEffect(() => {
    if (statusDefinition) {
      const instance = getStatusInstanceByStatusDefinitionId?.(statusDefinition?.id);
      setStatusInstance(instance);
    }
  }, [validatedStatusInstances, statusDefinition, getStatusInstanceByStatusDefinitionId]);

  const isSelected = useCallback((user: IUser) => !!statusInstance?.userValues.find(({ id }: IUser) => id === user.id), [statusInstance]);

  const renderUserInfo = (user: IUser) => <UserInfo user={user} size="extra-small" nowrap={false} />;

  if (!statusDefinition || !block) {
    return null;
  }

  const itemPredicate = (query: string, user: IUser) => user.displayName.toLowerCase().indexOf(query.toLowerCase()) >= 0;

  const renderItem = (user: IUser, { handleClick, modifiers }: ItemRendererProps) => {
    return isAlive(user) ? (
      <MenuItem
        className="status-mention-editor--menu-item"
        key={user.id}
        text={renderUserInfo(user)}
        disabled={modifiers.disabled}
        selected={isSelected(user)}
        onClick={handleClick}
        label={isSelected(user) ? ((<Icon icon="small-tick" />) as unknown as string) : undefined}
        e2eIdentifiers="menu-item"
      />
    ) : null;
  };

  const setValue = async (user: IUser) => {
    if (!statusInstance) {
      if (!user) {
        return; // Don't create new instances for empty values.
      }
      await appStore.workspaceModel?.addStatusInstance(block, statusDefinition, JSON.stringify([user.id]));
    } else {
      if (isSelected(user)) {
        statusInstance.removeUserFromList(user);
      } else {
        statusInstance.addUserToList(user);
      }
    }
  };

  const getPlaceholder = () => {
    if (placeholder !== undefined) {
      return placeholder;
    } else {
      return "Empty";
    }
  };

  return (
    <div className="status-mention-editor">
      <MultiSelect<IUser>
        className="status-mention-editor--multi-select"
        disabled={disabled}
        popoverRef={popoverRef}
        query={query}
        onQueryChange={query => setQuery(query)}
        placeholder={getPlaceholder()}
        resetOnSelect
        resetOnQuery
        itemPredicate={itemPredicate}
        tagRenderer={renderUserInfo}
        noResults={<MenuItem disabled text="No match." e2eIdentifiers="no-match" />}
        itemsEqual={(a, b) => a.id === b.id}
        popoverProps={{ minimal: true, matchTargetWidth, popoverClassName: "status-options-popover" }}
        itemRenderer={renderItem}
        menuProps={{ className: "status-mention-editor--list" }}
        selectedItems={statusInstance?.userValues || []}
        onRemove={user => statusInstance?.removeUserFromList(user)}
        createNewItemFromQuery={() => null as unknown as IUser}
        items={appStore.orgModel?.info?.orgMembers || []}
        onItemSelect={setValue}
        tagInputProps={{
          className: "status-mention-editor--tag-input",
          tagProps: { minimal: true, className: classNames("status-mention-editor--tag", { "tag--disabled": disabled }) },
        }}
      />
    </div>
  );
}

/** Exports. */
export type { StatusTypeMentionEditorProps };
export default observer(StatusTypeMentionEditor);
