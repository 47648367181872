import { observer } from "mobx-react";

import { NodeInfo } from "@components/Modeling/ModelingFrame/Table/TableComponent/types";
import { expandRowNodePath } from "@components/Table/utils";
import appStore from "@store/AppStore";
import { IPropertyInstance } from "@store/PropertyInstanceStore";

import SearchBar from "../../SearchBar/SearchBar";

const AutoGroupHeaderCell = () => {
  const handleSearchItemSelect = (item: IPropertyInstance) => {
    const gridApi = appStore.env.tableViewGridApi;
    const colKey = item.propertyDefinition?.id || "";
    const rowId = `table-view-${item.parentBlock.id}`;

    if (gridApi && colKey) {
      const rowNode = gridApi.getRowNode(rowId);
      expandRowNodePath<NodeInfo>(rowNode);
      setTimeout(() => {
        gridApi.setFocusedCell(rowNode?.rowIndex || 0, colKey);
      }, 100);
    }
  };

  return <SearchBar onItemSelect={handleSearchItemSelect} />;
};

export default observer(AutoGroupHeaderCell);
