import { Tooltip } from "@blueprintjs/core";
import { observer } from "mobx-react";

import { Button } from "@components/Button";
import appStore from "@store/AppStore";

import styles from "./FloatingFeedbackButton.module.scss";

const FloatingFeedbackButton = () => {
  return (
    <div className={styles.floatingFeedbackButton}>
      <Tooltip content="Send feedback">
        <Button icon="envelope" intent="primary" onClick={appStore.feedback.show} e2eIdentifiers={["menu-pane", "show-feedback"]} />
      </Tooltip>
    </div>
  );
};

export default observer(FloatingFeedbackButton);
