import { observer } from "mobx-react";

import appStore from "@store/AppStore";
import { Text, TextVariant } from "src/ui/Text";

import "./HoopsAnnotationsDisabledMessage.scss";

const HoopsAnnotationsDisabledMessage = () => {
  return (
    <>
      {appStore.env.attachmentViewer?.isExplodeActive && (
        <div className="hoops-annotations-disabled-message">
          <Text variant={TextVariant.Body}>Annotations are disabled while exploded view is active</Text>
        </div>
      )}
    </>
  );
};

export default observer(HoopsAnnotationsDisabledMessage);
