import { useState } from "react";
import { Menu } from "@blueprintjs/core";
import { observer } from "mobx-react";

import { Button } from "@components/Button";
import { MenuItem } from "@components/MenuItem";
import { Popover } from "@components/Popover";
import { TitledTooltip } from "@components/Shared";
import { showToast } from "@components/UiLayers/toaster";
import { OrganizationListEntry } from "@rollup-api/models";
import appStore from "@store/AppStore";

import OrgIcon from "./OrgIcon";

import "./OrgSelectDropdownButton.scss";

interface IOrgSelectDropdownButtonProps {
  orgList: OrganizationListEntry[];
  setIsSwitching(value: boolean): void;
}

const OrgSelectDropdownButton = (props: IOrgSelectDropdownButtonProps) => {
  const [isDropdownOpen, setDropdownOpen] = useState(false);

  const handleOrgChange = async (org: OrganizationListEntry) => {
    props.setIsSwitching(true);
    const success = await appStore.switchOrganization(org.id, org.slug);
    if (success) {
      showToast("Organization switched successfully. Reloading...", "success", "switch");
    } else {
      props.setIsSwitching(false);
      showToast("Error while switching organization", "danger", "error");
    }
  };

  const orgMenu = (
    <Menu className="org-menu">
      {props.orgList.map(org => (
        <div key={org.id} className="org-menu--item-container">
          <TitledTooltip title={org.name} content={org.description} disabled={!org.description}>
            <MenuItem
              icon={<OrgIcon org={org} />}
              text={org.name}
              active={org.id === appStore.orgModel?.info?.id}
              onClick={() => handleOrgChange(org)}
              e2eIdentifiers={["org-select-dropdown", org.name]}
            />
          </TitledTooltip>
        </div>
      ))}
    </Menu>
  );

  return (
    <Popover onOpening={() => setDropdownOpen(true)} onClosing={() => setDropdownOpen(false)} content={orgMenu} placement="bottom">
      <TitledTooltip title="Switch organization">
        <Button
          className="org-select-dropdown-button"
          rightIcon={isDropdownOpen ? "chevron-up" : "chevron-down"}
          icon={<OrgIcon org={appStore.orgModel.info} />}
          e2eIdentifiers={["switch-org", appStore.orgModel?.info?.name]}
          minimal
        >
          {appStore.orgModel?.info?.name}
        </Button>
      </TitledTooltip>
    </Popover>
  );
};

export default observer(OrgSelectDropdownButton);
