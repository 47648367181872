import { useState } from "react";
import { Classes, Divider, EditableText, FormGroup, H4 } from "@blueprintjs/core";
import { observer } from "mobx-react";

import ImageUploaderCard from "@components/ImageUploaderCard";
import { ProjectManagementStatusGroup } from "@components/ProjectManagement";
import { Keys } from "@constants/keys";
import { IBlock } from "@store/BlockStore";
import { parentWorkspace } from "@utilities/Models";

import "./ProgrammaticsPanel.module.scss";
import styles from "./ProgrammaticsPanel.module.scss";
/** Type defs. */
type ProjectManagementPanelProps = {
  block: IBlock;
};

/** Main function. */
function ProgrammaticsPanel({ block }: ProjectManagementPanelProps) {
  const [imgIsInError, setImgIsInError] = useState(false);
  const workspaceId = parentWorkspace(block)?.id;
  const [showDescriptionHelperText, setShowDescriptionHelperText] = useState(false);

  const setImage = (url: string) => {
    block.setCustomPreviewLink(url);
    setImgIsInError(false);
  };

  const showEditHint = (value: boolean) => {
    setShowDescriptionHelperText(value);
  };

  const setDescription = (value?: string) => {
    setShowDescriptionHelperText(false);
    if (value !== block.description) {
      block.setNewDescription(value?.trim());
    }
  };

  const previewUrl = block.previewCustomLink || block.dedicatedAttachment?.previewCustomLink || "";
  const hasImage = block.previewSource == "custom-link" && previewUrl !== "" && !imgIsInError;
  return (
    <div className={styles.programmaticsPanel}>
      <div className={styles.programmaticsPanelHeader}>
        <H4>Programmatics</H4>
      </div>
      <div className={styles.programmaticsPanelContent}>
        {block.ui.showPreviewCard && (
          <ImageUploaderCard workspaceId={workspaceId} src={hasImage ? previewUrl : undefined} onSrcChange={setImage} />
        )}
        <FormGroup
          className={styles.programmaticsPanelFormGroup}
          helperText={
            showDescriptionHelperText && (
              <span className={Classes.RUNNING_TEXT}>
                <code>{Keys.mod}+Enter</code>
                &nbsp; for new line, <code>Enter</code>
                &nbsp; to confirm
              </span>
            )
          }
          labelFor="text-input"
          labelInfo="(required)"
        >
          <EditableText
            className={styles.blockDescriptionEditText}
            multiline
            key={`${block.id}-${block.description}`}
            placeholder="Add description..."
            selectAllOnFocus={false}
            disabled={false}
            confirmOnEnterKey
            maxLines={7}
            defaultValue={block.description}
            onEdit={() => showEditHint(true)}
            onConfirm={setDescription}
            onCancel={() => showEditHint(false)}
          />
        </FormGroup>
        <Divider className={styles.programmaticsPanelDivider} />
        <div className={styles.programmaticsPanelStatusGroup}>
          <ProjectManagementStatusGroup block={block} />
        </div>
      </div>
    </div>
  );
}

/** Exports. */
export type { ProjectManagementPanelProps };
export default observer(ProgrammaticsPanel);
