import { useState } from "react";
import { NonIdealState, PopoverPosition, Tab, Tabs, Tooltip } from "@blueprintjs/core";
import classNames from "classnames";
import { observer } from "mobx-react";

import HorizontalLayoutIcon from "@assets/icons/horizontal-layout.svg?react";
import { Button } from "@components/Button";
import { DragHandle } from "@components/DragHandle";
import { TabTitle } from "@components/TabTitle";
import { useAppNavigate } from "@router/hooks";
import appStore from "@store/AppStore";
import { IBlock } from "@store/BlockStore";
import { IBomTable } from "@store/BomTable/BomTableStore";

import BlockProperties from "./BlockProperties";
import BomPmImageCard from "./BomPmImageCard";
import BomProperties from "./BomProperties";
import { ProjectManagement } from "./ProjectManagement";
import RelatedAttachments from "./RelatedAttachments";

import "./BlockPreview.scss";

type IBlockPreviewProps = {
  table: IBomTable;
  verticalLayout?: boolean;
  onToggleLayout(): void;
};

enum BlockPreviewTab {
  Properties = "properties",
  BomProperties = "bomProperties",
  Attachments = "attachments",
}

export const BlockPreview = (props: IBlockPreviewProps) => {
  const { table, verticalLayout, onToggleLayout } = props;
  const [isDockTooltipOpen, setIsDockTooltipOpen] = useState(false);
  const { navigateToBlock } = useAppNavigate();
  const block: IBlock | undefined = appStore.workspaceModel?.blockMap.get(table.previewBlockId);

  const getTitle = () => {
    let title = "Details";

    if (block?.label) {
      title = block.label;

      if (block.partNumber) {
        title += ` #${block.partNumber}`;
      }
    }

    return title;
  };

  const handleToggleLayout = () => {
    setIsDockTooltipOpen(false);
    onToggleLayout();
  };

  const renderHorizontalContent = (block: IBlock) => (
    <>
      <ProjectManagement block={block} className="block-preview--pm-card" />
      <BlockProperties block={block} className="block-preview--block-props-card" />
      <BomProperties block={block} table={table} className="block-preview--bom-props-card" />
      <RelatedAttachments block={block} className="block-preview--attachments-card" />
      <BomPmImageCard block={block} className="block-preview--image-card" />
    </>
  );

  const renderVerticalContent = (block: IBlock) => (
    <>
      <ProjectManagement block={block} className="block-preview--pm-card" />
      <Tabs className="block-preview--tabs" renderActiveTabPanelOnly animate large>
        <Tab
          id={BlockPreviewTab.Properties}
          title={<TabTitle title="Properties" count={block.propertyInstances?.length} />}
          panel={<BlockProperties block={block} />}
        />
        <Tab
          id={BlockPreviewTab.BomProperties}
          disabled={!table.relatedProperties.length}
          title={<TabTitle title="BOM properties" count={table.relatedProperties.length} />}
          panel={<BomProperties block={block} table={table} />}
        />
        <Tab
          id={BlockPreviewTab.Attachments}
          title={<TabTitle title="Attachments" count={block.statusInstances?.length} />}
          panel={<RelatedAttachments block={block} />}
        />
      </Tabs>
    </>
  );

  const renderContent = () => {
    if (!block) {
      return (
        <NonIdealState
          iconSize={20}
          icon="search-template"
          title="Select block"
          description="Hover on the row in the table to see the details"
        />
      );
    }
    return verticalLayout ? renderVerticalContent(block) : renderHorizontalContent(block);
  };

  return (
    <div className={classNames("block-preview", { "block-preview--vertical": verticalLayout })}>
      <DragHandle className="block-preview--drag-handle" vertical={verticalLayout} />
      <div className="block-preview--content-container">
        <div className="block-preview--header">
          <div>{getTitle()}</div>
          <div className="ml-auto">
            <Tooltip
              isOpen={isDockTooltipOpen}
              onInteraction={setIsDockTooltipOpen}
              content={verticalLayout ? "Dock to bottom" : "Dock to right"}
              position={verticalLayout ? PopoverPosition.BOTTOM : PopoverPosition.TOP}
              hoverOpenDelay={500}
            >
              <Button
                icon={verticalLayout ? <HorizontalLayoutIcon /> : "column-layout"}
                onClick={handleToggleLayout}
                e2eIdentifiers="toggle-panel-position"
                minimal
              />
            </Tooltip>
            {block && (
              <Tooltip
                content="Go to block details"
                position={verticalLayout ? PopoverPosition.BOTTOM : PopoverPosition.TOP}
                hoverOpenDelay={500}
              >
                <Button icon="share" minimal onClick={() => navigateToBlock(block.id)} e2eIdentifiers={["navigate-to-block", block.id]} />
              </Tooltip>
            )}
          </div>
        </div>
        <div className="block-preview--body">{renderContent()}</div>
      </div>
    </div>
  );
};

export default observer(BlockPreview);
