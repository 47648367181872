import { ReactNode } from "react";

import Dialog, { DialogProps, DialogType } from "./Dialog";

interface DialogSimpleProps extends DialogProps {
  footerButtons?: ReactNode[];
}

const DialogSimple = ({ footerButtons, ...rest }: DialogSimpleProps) => {
  return <Dialog type={DialogType.Simple} {...rest} footerButtons={footerButtons} />;
};

export default DialogSimple;
