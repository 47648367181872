export function isStringArraysEqual(source: Array<string>, target: Array<string>): boolean {
  if (source.length !== target.length) {
    return false;
  }

  let equal = true;

  source.forEach((item: string, index: number) => {
    if (target[index] !== item) {
      equal = false;
    }
  });

  return equal;
}

export function moveItemsInStringArray(srcArray: string[], destinationId: string, moveIds: string[]) {
  if (!srcArray.length || !destinationId || !moveIds.length) {
    return srcArray;
  }

  const originalDestIndex = srcArray.findIndex((id: string) => id === destinationId);
  const originalSrcIndex = srcArray.findIndex(i => i === moveIds[0]);
  const cleanArray = srcArray.filter((id: string) => !moveIds.includes(id));

  // if move to the beginning of the array
  if (originalDestIndex === 0) {
    return [...moveIds, ...cleanArray];
  }

  let destIndex = cleanArray.findIndex((id: string) => id === destinationId);
  const increment = originalDestIndex < originalSrcIndex ? 0 : 1;
  destIndex += increment;
  const leftSide = cleanArray.slice(0, destIndex);
  const rightSide = cleanArray.slice(destIndex);
  return [...leftSide, ...moveIds, ...rightSide];
}

export const getPreviousElement = <T extends object = { id: string }>(data: Array<T>, el: T): T | undefined => {
  const currIndex = data.indexOf(el);
  return currIndex > 0 ? data[currIndex - 1] : undefined;
};

export const getNextElement = <T extends object = { id: string }>(data: Array<T>, el: T): T | undefined => {
  const currIndex = data.indexOf(el);
  return currIndex < data.length - 1 ? data[currIndex + 1] : undefined;
};

export const getDocumentToBeSelected = <T extends object>(data: Array<T>, doc: T): T | undefined => {
  return getNextElement<T>(data, doc) ?? getPreviousElement<T>(data, doc);
};

// Small utility function for filtering out null/undefined values from an array and returning an array typed as non-nullable.
export function filterArray<T>(array: Array<T | undefined | null>): Array<T> {
  return array.filter(Boolean) as Array<T>;
}
