import { useState } from "react";
import classNames from "classnames";
import { observer } from "mobx-react";

import { AnonymousUserDark, AnonymousUserLight } from "@assets/icons/user";
import { ThemedCustomIcon } from "@components/Shared/LegacyCustomIcon/ThemedCustomIcon";

import styles from "./Avatar.module.scss";
interface AvatarProps {
  source: string | null | undefined;
  size: number;
  shape?: "circle" | "square";
  isOnline?: boolean;
  statusIconClassName?: string;
}

const Avatar = ({ source, size, shape = "circle", isOnline, statusIconClassName }: AvatarProps) => {
  const [imageError, setImageError] = useState(false);

  if (source && !imageError) {
    return (
      <div
        className={classNames(styles.avatar, statusIconClassName, {
          [styles.avatarStatusIcon]: isOnline !== undefined,
          [styles.avatarStatusIconOnline]: isOnline === true,
          [styles.avatarStatusIconOffline]: isOnline === false,
        })}
      >
        <img
          alt="User avatar"
          referrerPolicy="no-referrer"
          width={size}
          height={size}
          className={shape === "circle" ? styles.avatarShapeCircle : styles.avatarShapeSquare}
          src={source}
          onError={() => setImageError(false)}
        />
      </div>
    );
  }
  return (
    <div className={styles.avatar}>
      <ThemedCustomIcon
        lightIcon={<AnonymousUserDark width={size} height={size} />}
        darkIcon={<AnonymousUserLight width={size} height={size} />}
      />
    </div>
  );
};

export default observer(Avatar);
