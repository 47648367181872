import { InputRule } from "@tiptap/core";

import { RollupEditorType } from "@rollup-types/editor";
import appStore from "@store/AppStore";

import { focusReportBlockById } from "../../utils";

const regExp = /^- /g;

const unorderedListItemInputRule = (reportBlockId: string) => {
  return new InputRule({
    find: regExp,
    handler: props => {
      const { commands } = props;
      const reportBlock = appStore.workspaceModel?.reportBlocksMap.get(reportBlockId);
      commands.setContent("");
      reportBlock?.updateType(RollupEditorType.ul);
      focusReportBlockById(reportBlockId);
    },
  });
};

export default unorderedListItemInputRule;
