import { EntityModelBase } from "@rollup-api/shared/entityModelBase";

export enum ImportStatus {
  InProgress = "inProgress",
  Extracting = "extracting",
  Processing = "processing",
  PendingApproval = "pendingApproval",
  CreatingEntities = "creating",
  Success = "success",
  Failed = "failed",
}

export const ImportInProgressStatuses = [
  ImportStatus.InProgress,
  ImportStatus.Processing,
  ImportStatus.Extracting,
  ImportStatus.CreatingEntities,
];

export enum ImportExtensionType {
  ZIP = "zip",
  CSV = "csv",
  CAD = "cad",
}

export enum ImportType {
  Bom = "bom",
  Block = "block",
  CatalogItem = "catalogItem",
}

export type ImportLogEntry = {
  activity: string;
  timestamp: Date;
  status: ImportStatus;
};

export class Import extends EntityModelBase {
  public readonly originalFileName!: string;
  public readonly attachmentId!: string;
  public readonly columnMap!: string;
  public readonly logs!: ImportLogEntry[];
  public readonly createdBy!: string;
  public readonly status!: ImportStatus;
  public readonly type!: ImportType;
  public readonly entityId?: string;
}
