import { PropertyDataType } from "@rollup-io/engineering";
import { action, computed, observable } from "mobx";
import { v4 as uuidv4 } from "uuid";

import { ICreateDataSinkEntryDto, IUpdateDataSinkEntryDto } from "@rollup-api/models/data-sinks";
import { DataSinkEntry } from "@rollup-api/models/data-sinks/data-sink.model";

export class DataSinkEntryDrawerStore {
  public id = uuidv4();

  @observable accessor key: string = "";

  @observable accessor value: string = "";

  @observable accessor dataType = PropertyDataType.scalar;

  public originalDataSinkEntry?: DataSinkEntry;

  constructor(dataSinkEntry?: DataSinkEntry) {
    if (dataSinkEntry) {
      this.id = dataSinkEntry.id;
      this.key = dataSinkEntry.key;
      this.value = dataSinkEntry.value;
      this.dataType = dataSinkEntry.dataType;
      this.originalDataSinkEntry = dataSinkEntry;
    }
  }

  @computed
  public get isExisting(): boolean {
    return !!this.originalDataSinkEntry;
  }

  @action.bound
  public setKey(key: string) {
    this.key = key;
  }

  @action.bound
  public setValue(value: string) {
    this.value = value;
  }

  @action.bound
  public setDataType(value: PropertyDataType) {
    this.dataType = value;
  }

  @computed
  public get isDirty(): boolean {
    if (!this.originalDataSinkEntry) {
      return false;
    }
    return this.dataType !== this.originalDataSinkEntry.dataType || this.value !== this.originalDataSinkEntry.value;
  }

  @computed
  public get createDto(): ICreateDataSinkEntryDto {
    return {
      key: this.key,
      value: this.value,
      dataType: this.dataType,
    };
  }

  @computed
  public get updateDto(): IUpdateDataSinkEntryDto {
    return {
      key: this.key,
      value: this.value,
      dataType: this.dataType,
    };
  }
}
