import { Divider, Menu } from "@blueprintjs/core";
import { Text, TextVariant } from "@ui/Text";
import { observer } from "mobx-react";

import { Button } from "@components/Button";
import SyncFilterableMenu from "@components/Shared/SyncFilterableMenu/SyncFilterableMenu";
import { IBomColumn } from "@store/BomTable/BomColumnStore";
import { IBomTable } from "@store/BomTable/BomTableStore";

import "./GroupByMenu.scss";

interface IGroupByMenuProps {
  table: IBomTable;
  onClose(): void;
}

const GroupByMenu = (props: IGroupByMenuProps) => {
  const { table, onClose } = props;

  const renderGroupedByColumn = (column: IBomColumn) => {
    const unsetRowGroup = () => {
      onClose();
      column.setRowGroup(false);
      table.tableGridApi?.removeRowGroupColumn(column.id);
    };

    return (
      <div key={column.id} className="group-by-menu--grouped-by-column">
        <div>{column.label}</div>
        <Button
          className="group-by-menu--grouped-by-column-btn"
          onClick={unsetRowGroup}
          icon="cross"
          e2eIdentifiers="ungroup-by-column"
          minimal
        />
      </div>
    );
  };

  const handleSelect = (column: IBomColumn) => {
    column.setRowGroup(true);
    table.tableGridApi?.addRowGroupColumn(column.id);
  };

  const groupedColumns = table.columnArray.filter(column => column.rowGroup);
  const ungroupedColumns = table.columnArray.filter(column => !column.rowGroup);

  return (
    <Menu className="group-by-menu">
      <Text className="group-by-menu--title" variant={TextVariant.Caption}>
        Group by
      </Text>
      <Divider className="group-by-menu--divider" />
      {groupedColumns.map(renderGroupedByColumn)}
      <SyncFilterableMenu<IBomColumn>
        placeholder="Search a column"
        items={ungroupedColumns}
        onSelect={handleSelect}
        e2eIdentifiers="group-by-column"
      />
    </Menu>
  );
};

export default observer(GroupByMenu);
