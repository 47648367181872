export const HOOPS_PROGRESS = {
  URI_FETCH_BEGIN: "resolving attachment uri",
  URI_FETCHED: "attachment uri resolved",
  VIEWER_INIT_BEGIN: "initializing viewer",
  VIEWER_INIT_END: "viewer initialized",
  VIEWER_SETUP_BEGIN: "setting up viewer",
  VIEWER_SETUP_END: "viewer setup complete",
  VIEWER_START_BEGIN: "starting viewer",
  ASSEMBLY_TREE_READY: "assembly tree ready",
  CAD_VIEW_CREATED: "cad view created",
  CONFIGURATION_ACTIVATED: "configuration activated",
  FIRST_MODEL_LOADED: "first model loaded",
  MODEL_LOAD_BEGIN: "loading model",
  SUBTREE_LOADED: "model loaded",
  MODEL_STRUCTURE_HEADER_PARSED: "model structure header parsed",
  MODEL_STRUCTURE_READY: "model structure ready",
  REGISTERING_CUSTOM_OPERATORS: "registering custom operators",
  CUSTOM_OPERATORS_REGISTERED: "custom operators registered",
  EXTRACT_NODES_BEGIN: "extracting nodes",
  EXTRACT_NODES_END: "nodes extracted",
  SCENE_READY: "scene ready",
  STREAMING_ACTIVATED: "streaming activated",
  STREAMING_DEACTIVATED: "streaming deactivated",
  VIEW_CREATED: "view created",
  VIEW_LOADED: "view loaded",
  POPULATING_SMART_ANNOTATIONS: "populating smart annotations",
  POPULATING_VIEWS: "populating views",
  HOOPS_READY: "loading complete",
};

export type ProgressKey = keyof typeof HOOPS_PROGRESS;
