import { EditorEvents } from "@tiptap/core";
import TiptapDocument from "@tiptap/extension-document";
import { EditorView } from "@tiptap/pm/view";
import { Extension, KeyboardShortcutCommand, useEditor } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";

import { PropertyReferenceMention, PropertyReferenceSuggestion } from "@components/Shared/ScalarExpressionEditor/PropertyReference";
import { createDataLink } from "@components/Shared/ScalarExpressionEditor/utils";
import appStore from "@store/AppStore";
import { IPropertyInstance } from "@store/PropertyInstanceStore";

const tiptapDocument = TiptapDocument.extend({
  content: "block",
});

const handlePaste = (view: EditorView, event: ClipboardEvent) => {
  const text = (event as ClipboardEvent)?.clipboardData?.getData("text/plain") ?? "";
  const dataLinkMatch = /{{newDataLink:(?<dataSourceId>[0-9a-z]{8}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{12}):(?<query>.*?)}}/;
  const match = text.match(dataLinkMatch);
  if (match?.groups?.dataSourceId && match.groups.query) {
    const dataSourceId = match.groups.dataSourceId;
    const query = match.groups.query;
    const hasSource = appStore.workspaceModel?.dataConnection.dataSourceMap?.has(dataSourceId);
    if (!hasSource) {
      return false;
    }

    const dataLink = createDataLink(dataSourceId, query);
    view.pasteHTML(`<property-ref data-type="dataLink" data-id="${dataLink.id}"></property-ref>`);
    return true;
  }
  return false;
};

interface IScalarExpressionEditorParams {
  content: string;
  referenceProperty?: IPropertyInstance;
  onEnterKeyPress: KeyboardShortcutCommand;
  onModEnterKeyPress: KeyboardShortcutCommand;
  autoFocus?: boolean;
  onDataSourceSelect(dataSourceId: string): void;
  onTransaction?(props: EditorEvents["transaction"]): void;
  onSuggestOpen?(): void;
  onSuggestClosed?(): void;
  onCreate?(props: EditorEvents["create"]): void;
  onUpdate?(props: EditorEvents["update"]): void;
  onFocus?(props: EditorEvents["focus"]): void;
  onBlur?(props: EditorEvents["blur"]): void;
}

export const useScalarExpressionEditor = (params: IScalarExpressionEditorParams) => {
  const { content, referenceProperty, autoFocus } = params;
  const { onSuggestOpen, onSuggestClosed, onEnterKeyPress, onModEnterKeyPress, onDataSourceSelect } = params;
  const { onTransaction, onFocus, onBlur, onCreate, onUpdate } = params;

  const PropertyReferenceMentionExtension = PropertyReferenceMention.configure({
    suggestion: PropertyReferenceSuggestion({
      referenceProperty,
      showDetachedProperties: false,
      onSuggestOpen,
      onSuggestClosed,
      onDataSourceSelect,
    }),
  });

  return useEditor(
    {
      content,
      autofocus: autoFocus ? "start" : false,
      enableInputRules: false,
      enablePasteRules: false,
      editorProps: {
        handlePaste,
      },
      extensions: [
        StarterKit.configure({ document: false, bold: false, italic: false, strike: false }),
        tiptapDocument,
        PropertyReferenceMentionExtension,
        Extension.create({
          addKeyboardShortcuts: () => ({
            Escape: ({ editor }) => {
              editor.commands.setContent(content);
              editor.commands.blur();
              return true;
            },
            Enter: onEnterKeyPress,
            "Mod-Enter": onModEnterKeyPress,
          }),
        }),
      ],
      ...(onTransaction && { onTransaction }),
      ...(onFocus && { onFocus }),
      ...(onBlur && { onBlur }),
      ...(onCreate && { onCreate }),
      ...(onUpdate && { onUpdate }),
    },
    [content]
  );
};
