import { MouseEvent, useCallback, useEffect, useMemo, useState } from "react";
import { hideContextMenu, Icon, showContextMenu, Tree, TreeNodeInfo, TreeProps } from "@blueprintjs/core";
import { useWorkspace } from "@hooks/useWorkspace";
import { observer } from "mobx-react";

import BlockMenu from "@components/BlocksTree/BlockMenu";
import { Button } from "@components/Button";
import { DialogAddNewChildBlock } from "@components/Dialog";
import { WorkspaceContext } from "@providers/workspace";
import { useAppNavigate } from "@router/hooks";
import type { IBlock } from "@store/BlockStore";

import { transformBlockToTreeNodeProps } from "./utils";

type BlockTreeProps = {
  blocks: IBlock[] | undefined;
  activeBlockId?: string | undefined;
  TreeProps?: Omit<TreeProps, "contents" | "onNodeExpand" | "onNodeCollapse" | "onNodeClick" | "onNodeContextMenu"> | undefined;
  depth?: number | undefined;
  expanded?: boolean | undefined;
};

const toggleExpand = (contents: TreeNodeInfo<IBlock>[], expanded: boolean | undefined) => {
  function expand(node: TreeNodeInfo<IBlock>) {
    if (!node || expanded === undefined) {
      return null;
    }
    node.nodeData?.ui.setExpanded(expanded);
    if (node.childNodes?.length) {
      for (const item of node.childNodes) {
        expand(item);
      }
    }
  }

  for (const item of contents) {
    expand(item);
  }
};

function BlockTree({ blocks, TreeProps, activeBlockId, depth, expanded }: BlockTreeProps) {
  const [showDialogAddNewChildBlock, setShowDialogAddNewChildBlock] = useState(false);
  const [selectedBlock, setSelectedBlock] = useState<IBlock | undefined>(undefined);
  const { navigateToBlock } = useAppNavigate();
  const workspace = useWorkspace();

  const handleNodeContextMenu = useCallback(
    ({ nodeData }: { nodeData?: IBlock }, _nodePath: number[], e: MouseEvent) => {
      if (!nodeData) {
        return null;
      }
      e.preventDefault();

      showContextMenu({
        content: (
          // need to wrap BlockMenu with the workspace provider, since for some reason in this
          // case BlockMenu is not able to access the context defined in WorkspaceLayout
          <WorkspaceContext.Provider value={workspace}>
            <BlockMenu
              block={nodeData}
              onAddNewOpen={() => {
                setShowDialogAddNewChildBlock(true);
                setSelectedBlock(nodeData);
              }}
              onNavigateToBlock={navigateToBlock}
              onEnter={hideContextMenu}
            />
          </WorkspaceContext.Provider>
        ),
        targetOffset: { left: e.clientX, top: e.clientY },
      });
    },
    [navigateToBlock, workspace]
  );

  const contents = useMemo(
    () =>
      blocks?.map(item =>
        transformBlockToTreeNodeProps(
          item,
          activeBlockId,
          depth === undefined ? -1 : depth,
          <Button
            minimal
            className="p-0"
            icon={<Icon icon="more" size={12} />}
            onClick={e => {
              e.stopPropagation();
              handleNodeContextMenu({ nodeData: item }, [], e);
            }}
            e2eIdentifiers={["child-block-list-item-action-menu", item.id]}
          />
        )
      ) ?? [],
    [activeBlockId, blocks, depth, handleNodeContextMenu]
  );

  useEffect(() => {
    toggleExpand(contents, expanded);
  }, [contents, expanded]);

  if (!blocks) {
    return null;
  }

  // Handlers.
  const handleNodeExpand = (node: TreeNodeInfo<IBlock>) => {
    node.nodeData?.ui.setExpanded(true);
  };
  const handleNodeCollapse = (node: TreeNodeInfo<IBlock>) => {
    node.nodeData?.ui.setExpanded(false);
  };
  const handleNodeClick = (node: TreeNodeInfo<IBlock>) => {
    if (node.nodeData) {
      navigateToBlock(node.nodeData.id);
    }
  };

  return (
    <div className="component--block-tree">
      <Tree<IBlock>
        contents={contents}
        onNodeExpand={handleNodeExpand}
        onNodeCollapse={handleNodeCollapse}
        onNodeClick={handleNodeClick}
        onNodeContextMenu={handleNodeContextMenu}
        {...TreeProps}
      />
      {/* Section: Add new child block Dialog. */}
      <DialogAddNewChildBlock
        block={selectedBlock}
        isOpen={showDialogAddNewChildBlock}
        onClose={() => {
          setShowDialogAddNewChildBlock(false);
          setSelectedBlock(undefined);
        }}
      />
    </div>
  );
}

export type { BlockTreeProps };
export default observer(BlockTree);
