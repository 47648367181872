import { ReactNode } from "react";
import { StiggProvider as OriginalStiggProvider } from "@stigg/react-sdk";
import { observer } from "mobx-react";

import appStore from "@store/AppStore";
import { createNewLogger } from "@utilities/LoggingUtils";
import { getCssVar } from "@utilities/StyleUtils";

interface IStiggProviderProps {
  children: ReactNode;
}

const logger = createNewLogger("StiggProvider");

const logDebugMessage = (apiKey?: string, orgId?: string) => {
  if (!apiKey) {
    logger.debug("Stigg apiKey env variable is not set, skipping StiggProvider");
  } else if (!orgId) {
    logger.debug("orgId not available, skipping StiggProvider");
  }
};

const StiggProvider = ({ children }: IStiggProviderProps) => {
  const apiKey: string | undefined = import.meta.env.VITE_STIGG_API_KEY;
  const orgId = appStore.orgModel?.info?.id;

  if (!apiKey || !orgId) {
    logDebugMessage(apiKey, orgId);
    return children;
  }

  // Note: Stripe renders some forms inside an iFrame, which makes it impossible
  // for us to style it via CSS. We need to customize Stigg's theme via React
  // so we get the styling we want for Stripe's forms.
  const textColor = getCssVar("--text-color");

  const mockedCustomerId = import.meta.env.VITE_STIGG_MOCK_CUSTOMER_ID;

  return (
    <OriginalStiggProvider apiKey={apiKey} customerId={mockedCustomerId ?? orgId} theme={{ palette: { text: { primary: textColor } } }}>
      {children}
    </OriginalStiggProvider>
  );
};

export default observer(StiggProvider);
