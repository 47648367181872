// eslint-disable-next-line no-restricted-imports
import { Dialog as BpDialog, DialogProps } from "@blueprintjs/core";
import classNames from "classnames";
import { observer } from "mobx-react";

import "./DialogLegacy.scss";

function DialogLegacy(props: DialogProps) {
  const { children, className, canOutsideClickClose = true, canEscapeKeyClose = true, ...rest } = props;

  return (
    <BpDialog
      className={classNames("dialog", className)}
      canEscapeKeyClose={canEscapeKeyClose}
      canOutsideClickClose={canOutsideClickClose}
      {...rest}
    >
      {children}
    </BpDialog>
  );
}

export type { DialogProps };
export default observer(DialogLegacy);
