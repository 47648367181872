import { useEffect, useRef, useState } from "react";

interface IUseElementObserverParams {
  selector: string;
  disabled?: boolean;
  onMutation(element: HTMLElement): void;
}

/**
 * Observe an element for mutations. Anytime the element changes, the onMutation callback gets called.
 */
export const useElementObserver = (params: IUseElementObserverParams) => {
  const { selector, onMutation, disabled } = params;
  const [element, setElement] = useState<HTMLElement>();
  const observerRef = useRef<MutationObserver>();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (!element && !disabled) {
      const elements = document.querySelector(selector);
      if (elements) {
        setElement(elements as HTMLElement);
      }
    }
  });

  useEffect(() => {
    if (!observerRef.current && element && !disabled) {
      observerRef.current = new MutationObserver(function () {
        onMutation(element);
      });
      observerRef.current.observe(element, { attributes: true });
    }
    return () => {
      observerRef.current?.disconnect();
      observerRef.current = undefined;
    };
  }, [disabled, element, onMutation]);
};
