import { H3, Text } from "@blueprintjs/core";
import { observer } from "mobx-react-lite";

import AnchorLink from "@components/AnchorLink";
import DevIcon from "@components/DevIcon";
import AttachmentDetailsPanel from "@components/Modeling/ModelingFrame/ModelBlock/Attachments/AttachmentDetails/AttachmentDetailsPanel";
import { ListEntry } from "@components/Shared";
import { RepoDetails } from "@rollup-api/models";

import "./GithubRepoDetailsPanel.scss";

interface IRepoDetailsProps {
  metadata: RepoDetails;
}

const GithubRepoDetailsPanel = (props: IRepoDetailsProps) => {
  const { metadata } = props;

  return (
    <AttachmentDetailsPanel className="github-repo-details-panel">
      <H3>Details</H3>
      <ListEntry label="Name" value={metadata.name} />
      <ListEntry label="Description" value={metadata.description} />
      <ListEntry label="URL" value={<AnchorLink href={metadata.url} />} />
      <ListEntry label="Type" value="Repository" />
      {metadata.primaryLanguage && (
        <ListEntry
          label="Primary Language"
          value={
            <div className="list-entry-container">
              <DevIcon icon={metadata.primaryLanguage.name} color={metadata.primaryLanguage.color} />
              <Text style={{ color: metadata.primaryLanguage.color }}>{metadata.primaryLanguage.name}</Text>
            </div>
          }
        />
      )}
      <ListEntry label="Stars" value={metadata.stargazerCount.toString()} />
      <ListEntry label="Watchers" value={metadata.watchers.totalCount.toString()} />
      <ListEntry label="Issues" value={metadata.issues.totalCount.toString()} />
      <ListEntry label="Pull Requests" value={metadata.pullRequests.totalCount.toString()} />
      <ListEntry label="Forks" value={metadata.forkCount.toString()} />
    </AttachmentDetailsPanel>
  );
};

export default observer(GithubRepoDetailsPanel);
