import { ChangeEvent } from "react";
import { Label, NumericInput } from "@blueprintjs/core";
import { Select } from "@blueprintjs/select";
import { SelectItem } from "@ui/Select/Item/SelectItem";

import { Button } from "@components/Button";
import { currencyUnits, CustomUnit } from "@utilities/Units";

import styles from "./CatalogItemCostPicker.module.scss";

type Props = {
  onCurrencyChange: (currency: string) => void;
  currency?: string;
  cost?: string;
  onCostChange: (cost: string) => void;
  disabled?: boolean;
};

const CatalogItemCostPicker = (props: Props) => {
  const { cost, currency, onCurrencyChange, onCostChange, disabled } = props;

  const handleCostChange = (event: ChangeEvent<HTMLInputElement>) => {
    const stringValue = event.target.value;
    const numericValue = Number(stringValue);
    if (isNaN(numericValue) || numericValue < 0 || (stringValue.includes(".") && stringValue.split(".")[1].length > 2)) {
      return;
    }

    onCostChange(event.target.value);
  };

  return (
    <Label>
      <span className={styles.catalogItemCostPickerLabel}>Cost</span>
      <div className={styles.catalogItemCostPicker}>
        <NumericInput
          disabled={disabled}
          buttonPosition="none"
          className={styles.catalogItemCostPickerCost}
          value={cost}
          minorStepSize={0.01}
          min={0}
          onChange={handleCostChange}
        />
        <Select<CustomUnit>
          disabled={disabled}
          filterable={false}
          items={currencyUnits}
          popoverProps={{ minimal: true, className: styles.catalogItemCostPickerCurrencySelect }}
          onItemSelect={item => onCurrencyChange(item.shorthand)}
          itemRenderer={(item, { handleClick }) => (
            <SelectItem slug={item} key={item.name} label={item.name} onClick={handleClick} active={currency === item.shorthand} />
          )}
        >
          <Button
            disabled={disabled}
            fill
            alignText="left"
            text={currency}
            rightIcon="chevron-down"
            className={styles.catalogItemCostPickerCurrencyBtn}
            e2eIdentifiers="cost-button"
          />
        </Select>
      </div>
    </Label>
  );
};

export default CatalogItemCostPicker;
