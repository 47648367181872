import { MouseEvent } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { getDocumentToBeSelected as getDocumentToBeSelectedFromArray } from "../Utilities/ArrayUtils";

interface IUseHandleDeleteDocumentParams<T extends { id: string }> {
  document: T;
  pageName: string;
  disableNavigation?: boolean;
  docArray?: Array<T>;
  deleteDocument?(id: string): void;
}

export const useHandleDeleteDocument = <T extends { id: string }>(params: IUseHandleDeleteDocumentParams<T>) => {
  const navigate = useNavigate();
  const { workspaceId } = useParams();
  const { docArray, document, pageName, disableNavigation, deleteDocument } = params;

  return (event: MouseEvent) => {
    event.stopPropagation();

    if (docArray && !disableNavigation) {
      const documentToBeSelected = getDocumentToBeSelectedFromArray(docArray, document);
      navigate(`/workspaces/${workspaceId}/${pageName}${documentToBeSelected ? `/${documentToBeSelected.id}` : ""}`);
    }

    deleteDocument?.(document.id);
  };
};
