import { useEffect, useState } from "react";
import { Checkbox, Icon, Menu } from "@blueprintjs/core";
import { useWorkspace } from "@hooks/useWorkspace";
import { IHeaderParams } from "ag-grid-community";
import { observer } from "mobx-react";

import { Button } from "@components/Button";
import { DeleteConfirmationDialog } from "@components/DeleteConfirmationDialog";
import { MenuItem } from "@components/MenuItem";
import { EHeaderType } from "@components/Modeling/ModelingFrame/Table/TableComponent/Cells/HeaderCell";
import HeaderStatusNav from "@components/Modeling/ModelingFrame/Table/TableComponent/Cells/HeaderStatusNav";
import { Popover } from "@components/Popover";
import PropertyDefinitionMenu from "@components/Shared/PropertyDefinitionMenu/PropertyDefinitionMenu";
import appStore from "@store/AppStore";
import { BomTableNode } from "@store/BlockStore";
import { IBomColumn } from "@store/BomTable/BomColumnStore";
import { IPropertyDefinition } from "@store/PropertyDefinitionStore";

import { getColDefs } from "../utils";

import CreateNewColumnNav from "./CreateNewColumnNav";

import "./HeaderCell.scss";

export interface IHeaderCellParams extends IHeaderParams<BomTableNode> {
  headerType?: EHeaderType;
  bomColumn?: IBomColumn;
  hideNav?: boolean;
}

const HeaderCell = (props: IHeaderCellParams) => {
  const { headerType, hideNav, bomColumn } = props;
  const [label, setLabel] = useState(props.displayName);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const workspace = useWorkspace();
  const tableProxy = workspace.bomTablesMap.get(appStore.env.activeBomTableId || "");
  const bomColumnProxy = tableProxy?.columnMap.get(bomColumn?.id || "");

  useEffect(() => {
    if (bomColumnProxy?.label) {
      setLabel(bomColumnProxy?.label);
    }
  }, [bomColumnProxy?.label]);

  const handleDeleteDefinition = () => {
    if (bomColumn) {
      tableProxy?.removeColumn(bomColumn.id);
      if (bomColumn.statusDefinition) {
        const definitionProxy = workspace.statusDefinitionMap.get(bomColumn.statusDefinition.id);
        definitionProxy && workspace.deleteStatusDefinition(definitionProxy);
      } else if (bomColumn.propertyDefinition) {
        const definitionProxy = workspace.propertyDefinitionMap.get(bomColumn.propertyDefinition.id);
        definitionProxy && workspace.deletePropertyDefinition(definitionProxy);
      }
    }
    setShowConfirmation(false);
  };

  const handleStatusTypeChange = () => {
    if (tableProxy) {
      props.api.setGridOption("columnDefs", getColDefs(tableProxy.columnArray));
    }
  };

  const handleRemoveColumn = () => bomColumn && tableProxy?.removeColumn(bomColumn.id);

  const getHeaderNav = () => {
    if (bomColumn?.propertyDefinition) {
      const definitionProxy = workspace.propertyDefinitionMap.get(bomColumn.propertyDefinition.id) as IPropertyDefinition;
      return (
        <Menu>
          <PropertyDefinitionMenu
            propertyDefinition={definitionProxy}
            onRemove={handleRemoveColumn}
            onDelete={() => setShowConfirmation(true)}
          />
        </Menu>
      );
    } else if (bomColumn?.statusDefinition) {
      const definitionProxy = workspace.statusDefinitionMap.get(bomColumn.statusDefinition.id);
      return (
        <Menu>
          <HeaderStatusNav
            hideErase
            onStatusTypeChange={handleStatusTypeChange}
            statusDefinition={definitionProxy}
            onDelete={() => setShowConfirmation(true)}
            onRemove={handleRemoveColumn}
          />
        </Menu>
      );
    } else if (bomColumn?.metaColumn) {
      return (
        <Menu>
          <MenuItem onClick={handleRemoveColumn} icon="th-disconnect" text="Hide" e2eIdentifiers="remove" />
        </Menu>
      );
    }

    return undefined;
  };

  const handleToggleSelectAll = () => {
    if (tableProxy?.selectedRows.length) {
      props.api.deselectAll();
    } else {
      props.api.selectAll();
    }
  };

  const getIndeterminate = (): boolean => {
    return !!tableProxy?.selectedRows.length && tableProxy.validRows.length > props.api.getSelectedRows().length;
  };

  const getChecked = (): boolean => tableProxy?.validRows.length === props.api.getSelectedRows().length;

  switch (headerType) {
    case EHeaderType.CHECKBOX:
      return (
        <Checkbox
          indeterminate={getIndeterminate()}
          className="header-cell--checkbox"
          checked={getChecked()}
          onChange={handleToggleSelectAll}
        />
      );
    case EHeaderType.ADD_NEW:
      return (
        <Popover fill className="header-cell--create-new" position="bottom-left" content={<CreateNewColumnNav />}>
          <Button minimal small icon={<Icon icon="plus" size={12} />} e2eIdentifiers={["header-cell", "add-new"]} />
        </Popover>
      );
    default:
      return (
        <div className="header-cell">
          <span className="ag-header-custom--name">{label}</span>
          {!hideNav && (
            <Popover position="bottom-left" content={getHeaderNav()}>
              <Button
                className="mr-1"
                minimal
                small
                icon={<Icon icon="drag-handle-vertical" size={12} />}
                e2eIdentifiers={["header-cell", "default"]}
              />
            </Popover>
          )}
          <DeleteConfirmationDialog
            isOpen={showConfirmation}
            titleItem="definition from the system"
            description="Are you sure you want to delete this definition? It will remove all related instances!"
            onCancel={() => setShowConfirmation(false)}
            onClose={() => setShowConfirmation(false)}
            onConfirm={handleDeleteDefinition}
          />
        </div>
      );
  }
};

export default observer(HeaderCell);
