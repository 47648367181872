import { Handle, NodeProps, Position } from "reactflow";
import { observer } from "mobx-react";

import { CustomIcon, ModuleColorfulIcon } from "@components/CustomIcon";
import { CatalogItemChildRef } from "@rollup-api/models/catalogItem/catalogItemDtos";

import "./ReferenceNode.scss";

export type ReferenceNodeData = {
  reference: CatalogItemChildRef;
};

type ReferenceNodeProps = NodeProps<ReferenceNodeData>;

const ReferenceNode = (props: ReferenceNodeProps) => {
  const {
    targetPosition,
    sourcePosition,
    data: { reference },
  } = props;

  return (
    <div className="reference-node">
      <Handle type="target" position={targetPosition || Position.Left} className="invisible" />
      <div className="reference-node--content">
        <div className="reference-node--body">
          <CustomIcon className="reference-node--icon" icon={ModuleColorfulIcon.HardwareCatalog} />
          <div className="reference-node--label">{reference.name}</div>
        </div>
        <div className="reference-node--subtitle">Reference</div>
      </div>
      <Handle type="source" position={sourcePosition || Position.Right} className="invisible" />
    </div>
  );
};

export default observer(ReferenceNode);
