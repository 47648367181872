// eslint-disable-next-line no-restricted-imports
import { Switch as BPSwitch, SwitchProps } from "@blueprintjs/core";
import classNames from "classnames";
import { observer } from "mobx-react";

import { withDataTestId } from "@components/Shared/WithDataTestId";
import { ElementType, IE2EIdentifiersParam } from "@utilities/E2EUtils";

import styles from "./Switch.module.scss";

interface ISwitchProps extends SwitchProps, IE2EIdentifiersParam {}

const Switch = (props: ISwitchProps) => {
  const { className, ...restProps } = props;

  return <BPSwitch className={classNames(styles.switch, className)} {...restProps} />;
};

export default withDataTestId(observer(Switch), ElementType.Switch);
