import { isValidNumber } from "@utilities/NumberUtils";

export const getValidationErrorMessage = (value: string) => {
  if (!value) {
    return "";
  }

  return isValidNumber(value) ? "" : "Invalid number";
};

export const parseUncertaintyToNumber = (value: string, isRelative: boolean): number => {
  if (!value) {
    return 0;
  }
  // Note: we check if value is a valid number in "getValidationErrorMessage"
  const numberValue = parseFloat(value);
  return isRelative ? numberValue / 100 : numberValue;
};

export const parseUncertaintyToString = (value: number, isRelative: boolean): string => {
  const parsedValue = isRelative ? value * 100 : value;
  return parsedValue.toString();
};
