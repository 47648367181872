import { GoogleDriveMimeType, IntegrationProvider } from "@rollup-api/models";
import { DataSource, GoogleDataSource } from "@rollup-api/models/data-sources";

export enum DataConnectionType {
  DataSource = "DataSource",
  DataSink = "DataSink",
}

const googleDriveUrl = "https://docs.google.com";

export const openDataSourceLink = (dataSource: DataSource) => {
  switch (dataSource.provider) {
    case IntegrationProvider.Google:
      openGoogleDataSource(dataSource);
      break;
  }
};

export const openGoogleDataSource = (dataSource: GoogleDataSource) => {
  switch (dataSource.metadata.mimeType) {
    case GoogleDriveMimeType.SPREADSHEET:
      window.open(`${googleDriveUrl}/spreadsheets/d/${dataSource.metadata.fileId}`, "_blank");
      break;
  }
};
