import { useMemo } from "react";
import { ColDef, ValueGetterParams } from "ag-grid-community";

import RequirementEditorCell from "@components/Requirements/RequirementsTable/Cells/RequirementEditorCell";
import { ERequirementsTableColumn } from "@components/Requirements/RequirementsTable/constants";
import { TCellGetterProps, TCellRendererProps } from "@components/Table";
import { CommentCellRenderer } from "@components/Table/Components/CommentCellRenderer";
import {
  ICommentCellRendererParams,
  ICommentCellRendererValue,
} from "@components/Table/Components/CommentCellRenderer/CommentCellRenderer";
import appStore from "@store/AppStore";
import { IRequirementBlock, IRequirementsPage } from "@store/RequirementsStore";

import { CommentHeader } from "../../Table/HeaderComponents/CommentHeader";

import { IRequirementsActionsCellCustomProps, RequirementsActionsCell } from "./Cells/RequirementsActionsCell";
import {
  commentCellRenderer,
  descriptionCellRenderer,
  functionalTypeCellRenderer,
  idCellRenderer,
  levelCellRenderer,
  linkedBlockCellRenderer,
  linkedPropertyCellRenderer,
  methodCellRenderer,
  notesCellRenderer,
  rationaleCellRenderer,
  successCriteriaCellRenderer,
  titleCellRenderer,
  verificationCellRenderer,
  verificationStatusCellRenderer,
} from "./utils";

import styles from "./RequirementsTable.module.scss";

export const useReqTableColumnDefs = (reqPage: IRequirementsPage): ColDef[] => {
  const columnsOrder = appStore.env.columnsOrder.requirementsTable;
  const columnDefs = useMemo(
    () =>
      (
        [
          {
            colId: ERequirementsTableColumn.ACTIONS,
            headerName: "",
            pinned: "left",
            lockPosition: "left" as const,
            resizable: false,
            width: 25,
            cellRenderer: RequirementsActionsCell,
            cellRendererParams: { onDeleteRequirementBlock: reqPage.deleteRequirementBlock } as IRequirementsActionsCellCustomProps,
          },
          {
            editable: (cellProps: TCellRendererProps<IRequirementBlock>) => !cellProps.data.locked,
            minWidth: 100,
            flex: 1,
            lockPosition: "left" as const,
            colId: ERequirementsTableColumn.ID,
            headerName: ERequirementsTableColumn.ID,
            cellRenderer: idCellRenderer,
            cellEditor: RequirementEditorCell,
            cellClassRules: {
              "requirements-table--locked-cell": (cellProps: TCellRendererProps<IRequirementBlock>) => cellProps.data.locked,
            },
            valueSetter: (cellProps: TCellGetterProps<IRequirementBlock>): boolean => {
              cellProps.data.setVisibleId(cellProps.newValue);
              return true;
            },
          },
          {
            width: 90,
            minWidth: 70,
            flex: 1,
            lockPosition: "left" as const,
            headerName: ERequirementsTableColumn.LEVEL,
            colId: ERequirementsTableColumn.LEVEL,
            cellRenderer: levelCellRenderer,
            cellClass: "select-cell-wrapper",
          },
          {
            editable: (cellProps: TCellRendererProps<IRequirementBlock>) => !cellProps.data.locked,
            minWidth: 140,
            maxWidth: 500,
            lockPosition: "left" as const,
            suppressMovable: true,
            flex: 2,
            headerName: ERequirementsTableColumn.TITLE,
            colId: ERequirementsTableColumn.TITLE,
            cellEditor: RequirementEditorCell,
            cellRenderer: titleCellRenderer,
            cellClassRules: {
              "requirements-table--locked-cell": (cellProps: TCellRendererProps<IRequirementBlock>) => cellProps.data.locked,
            },
            valueGetter: (cellProps: TCellRendererProps<IRequirementBlock>) => cellProps.data.label,
            valueSetter: (cellProps: TCellGetterProps<IRequirementBlock>): boolean => {
              cellProps.data.setLabel(cellProps.newValue);
              return true;
            },
          },
          {
            width: 140,
            headerName: ERequirementsTableColumn.FUNCTIONAL_TYPE,
            colId: ERequirementsTableColumn.FUNCTIONAL_TYPE,
            cellRenderer: functionalTypeCellRenderer,
          },
          {
            width: 44,
            headerName: "",
            headerComponent: CommentHeader,
            colId: ERequirementsTableColumn.COMMENTS,
            resizable: false,
            cellRenderer: CommentCellRenderer,
            cellRendererParams: {
              getEntityIdsWithComments: () => reqPage.getReqBlockIdsWithComments(true),
            } satisfies ICommentCellRendererParams,
            valueGetter: (params: ValueGetterParams<IRequirementBlock, ICommentCellRendererValue>) => {
              return {
                entityId: params.data?.id ?? "",
                annotationList: params.data?.annotationList,
              } satisfies ICommentCellRendererValue;
            },
          },
          {
            editable: (cellProps: TCellRendererProps<IRequirementBlock>) => !cellProps.data.locked,
            minWidth: 130,
            flex: 1,
            headerName: ERequirementsTableColumn.RATIONALE,
            colId: ERequirementsTableColumn.RATIONALE,
            cellRenderer: rationaleCellRenderer,
            cellClassRules: {
              "requirements-table--locked-cell": (cellProps: TCellRendererProps<IRequirementBlock>) => cellProps.data.locked,
            },
            cellEditor: RequirementEditorCell,
            valueGetter: (cellProps: TCellRendererProps<IRequirementBlock>) => cellProps.data.rationale,
            valueSetter: (cellProps: TCellGetterProps<IRequirementBlock>): boolean => {
              cellProps.data.setRationale(cellProps.newValue);
              return true;
            },
          },
          {
            editable: (cellProps: TCellRendererProps<IRequirementBlock>) => !cellProps.data.locked,
            minWidth: 130,
            flex: 1,
            headerName: ERequirementsTableColumn.DESCRIPTION,
            colId: ERequirementsTableColumn.DESCRIPTION,
            cellRenderer: descriptionCellRenderer,
            cellClassRules: {
              "requirements-table--locked-cell": (cellProps: TCellRendererProps<IRequirementBlock>) => cellProps.data.locked,
            },
            cellEditor: RequirementEditorCell,
            valueGetter: (cellProps: TCellRendererProps<IRequirementBlock>) => cellProps.data.description,
            valueSetter: (cellProps: TCellGetterProps<IRequirementBlock>): boolean => {
              cellProps.data.setDescription(cellProps.newValue);
              return true;
            },
          },
          {
            minWidth: 100,
            flex: 1,
            headerName: ERequirementsTableColumn.LINKED_BLOCK,
            colId: ERequirementsTableColumn.LINKED_BLOCK,
            cellRenderer: linkedBlockCellRenderer,
          },
          {
            minWidth: 100,
            flex: 1,
            headerName: ERequirementsTableColumn.LINKED_PROPERTY,
            colId: ERequirementsTableColumn.LINKED_PROPERTY,
            cellRenderer: linkedPropertyCellRenderer,
            cellClass: "linked-property-cell--wrapper",
          },
          {
            width: 160,
            headerName: ERequirementsTableColumn.METHOD,
            colId: ERequirementsTableColumn.METHOD,
            cellRenderer: methodCellRenderer,
          },
          {
            headerName: ERequirementsTableColumn.VERIFICATION,
            colId: ERequirementsTableColumn.VERIFICATION,
            cellRenderer: verificationCellRenderer,
            cellClass: styles.requirementsTableNoPaddingCellWrapper,
          },
          {
            width: 160,
            cellClass: "select-cell-wrapper",
            headerName: ERequirementsTableColumn.SUCCESS_CRITERIA,
            colId: ERequirementsTableColumn.SUCCESS_CRITERIA,
            cellRenderer: successCriteriaCellRenderer,
          },
          {
            minWidth: 90,
            flex: 1,
            headerName: ERequirementsTableColumn.VERIFICATION_STATUS,
            colId: ERequirementsTableColumn.VERIFICATION_STATUS,
            cellRenderer: verificationStatusCellRenderer,
            cellClass: styles.requirementsTableNoPaddingCellWrapper,
          },
          {
            editable: (cellProps: TCellRendererProps<IRequirementBlock>) => !cellProps.data.locked,
            minWidth: 130,
            flex: 1,
            headerName: ERequirementsTableColumn.COMMENT,
            colId: ERequirementsTableColumn.COMMENT,
            cellRenderer: commentCellRenderer,
            cellClassRules: {
              "requirements-table--locked-cell": (cellProps: TCellRendererProps<IRequirementBlock>) => cellProps.data.locked,
            },
            cellEditor: RequirementEditorCell,
            valueGetter: (cellProps: TCellRendererProps<IRequirementBlock>) => cellProps.data.comment,
            valueSetter: (cellProps: TCellGetterProps<IRequirementBlock>): boolean => {
              cellProps.data.setComment(cellProps.newValue);
              return true;
            },
          },
          {
            editable: (cellProps: TCellRendererProps<IRequirementBlock>) => !cellProps.data.locked,
            minWidth: 130,
            flex: 1,
            headerName: ERequirementsTableColumn.NOTES,
            colId: ERequirementsTableColumn.NOTES,
            cellRenderer: notesCellRenderer,
            cellClassRules: {
              "requirements-table--locked-cell": (cellProps: TCellRendererProps<IRequirementBlock>) => cellProps.data.locked,
            },
            cellEditor: RequirementEditorCell,
            valueGetter: (cellProps: TCellRendererProps<IRequirementBlock>) => cellProps.data.note,
            valueSetter: (cellProps: TCellGetterProps<IRequirementBlock>): boolean => {
              cellProps.data.setNote(cellProps.newValue);
              return true;
            },
          },
        ] as ColDef<IRequirementBlock>[]
      )
        .sort(
          (a: ColDef<IRequirementBlock>, b: ColDef<IRequirementBlock>) =>
            columnsOrder.indexOf(a.colId as ERequirementsTableColumn) - columnsOrder.indexOf(b.colId as ERequirementsTableColumn)
        )
        .map((colDef: ColDef<IRequirementBlock>) => {
          const width = appStore.env.columnsSize.requirementsTable.get(colDef.colId || "");
          return width ? { ...colDef, width, flex: undefined } : colDef;
        }),
    [reqPage, columnsOrder]
  );

  return columnDefs;
};
