import { Handle, NodeProps, Position } from "reactflow";
import { observer } from "mobx-react";

import AttachmentIcon from "@components/Modeling/ModelingFrame/ModelBlock/Attachments/AttachmentIcon";
import appStore from "@store/AppStore";
import { ICatalogItem } from "@store/CatalogItem/CatalogItemStore";

import { CustomNodeType } from "../../types";

import "./FileNode.scss";

type FileNodeData = {
  type: CustomNodeType;
  id: string;
  activeVersion: string;
  catalogItem: ICatalogItem;
};

type FileNodeProps = NodeProps<FileNodeData>;

const FileNode = (props: FileNodeProps) => {
  const {
    type,
    targetPosition,
    sourcePosition,
    data: { activeVersion },
  } = props;

  const version = appStore.orgModel.catalogItems.getCatalogItemVersion(activeVersion);
  const fileName = version?.attachment?.name;

  return (
    <div className="file-node">
      {type !== CustomNodeType.INPUT && <Handle type="target" position={targetPosition || Position.Left} className="invisible" />}
      <div className="file-node--content">
        <AttachmentIcon attachment={version?.attachment} />
        <div className="file-node--label">{fileName}</div>
      </div>
      {type !== CustomNodeType.OUTPUT && <Handle type="source" position={sourcePosition || Position.Right} />}
    </div>
  );
};

export default observer(FileNode);
