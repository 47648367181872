import { observer } from "mobx-react";

import { IBomTable } from "@store/BomTable/BomTableStore";

import { getTableTotal } from "../utils";

import "./TotalRow.scss";

type Props = {
  table: IBomTable;
};

export const TotalRow = (props: Props) => {
  const { table } = props;

  return <div className="total-row">Total: {getTableTotal(table)}</div>;
};

export default observer(TotalRow);
