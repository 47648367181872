import { JSX } from "react";

import { expressionTemplateRegex } from "@components/Shared/ScalarExpressionEditor/utils";
import { TransactionEntity, TransactionPropertyInstanceEntity } from "@rollup-api/models/transactions";
import appStore from "@store/AppStore";
import { EFeedActionType, EFeedEntityType, IFeed } from "@store/FeedStore";
import { IPropertyInstance } from "@store/PropertyInstanceStore";
import { evaluateSmartExpression, formatStringFromMathResult, getPropertyInstanceById } from "@utilities";

import { getChangedString, isPropertyDefinitionEntity, isPropertyInstanceEntity } from "../utils";

import { PropertyFeedFormatter } from "./PropertyFeedFormatter";

import styles from "../FeedPanel.module.scss";

type IPropertyInstanceAllowedKeys = keyof TransactionPropertyInstanceEntity;

export abstract class PropertyInstanceFeedFormatter {
  private static fieldToLabelMap: Partial<Record<IPropertyInstanceAllowedKeys, string>> = {
    lowerBoundUncertainty: "uncertainty low",
    upperBoundUncertainty: "uncertainty high",
  };

  public static getPropertyActionText(feedItem: IFeed) {
    switch (feedItem.method) {
      case EFeedActionType.UPDATE:
        return PropertyInstanceFeedFormatter.getUpdateMessage(feedItem);
      case EFeedActionType.DELETE:
        return PropertyFeedFormatter.getDeleteMessage(feedItem);
      default:
        return (
          <span className={styles.feedPanelAction}>
            {feedItem.actionTitle} {feedItem.entityTitle}
            <span className={styles.feedPanelEntity}>
              &nbsp;
              {PropertyInstanceFeedFormatter.getPropertyEntityLabel(feedItem.referenceType, feedItem.newEntity)}
            </span>
          </span>
        );
    }
  }

  private static parseExpression(expression: string = ""): string {
    return expression.replaceAll(expressionTemplateRegex, (_, type, id, query) => {
      if (type === "newDataLink") {
        const dataLink = appStore.workspaceModel?.getDataLink(id, query);
        if (dataLink) {
          return `{{dataLink:${dataLink.id}}}`;
        }
      }
      return _;
    });
  }

  private static evaluateExpression(referenceProperty: IPropertyInstance, smartExpression: string): string {
    const parsedExpression = PropertyInstanceFeedFormatter.parseExpression(smartExpression);
    const { calculatedResult, substitutedExpression = "" } = evaluateSmartExpression(parsedExpression, referenceProperty) ?? {};
    const strResult = formatStringFromMathResult(calculatedResult, referenceProperty.propertyDefinition?.formatType);
    return strResult || substitutedExpression;
  }

  public static getUpdateMessage(feedItem: IFeed): JSX.Element {
    const newEntity = feedItem.newEntity as TransactionPropertyInstanceEntity;
    const oldEntity = feedItem.oldEntity as TransactionPropertyInstanceEntity;

    const propertyInstance = getPropertyInstanceById(feedItem.referenceId);

    if (propertyInstance) {
      const newValue = newEntity.value && PropertyInstanceFeedFormatter.evaluateExpression(propertyInstance, newEntity.value);
      const oldValue = oldEntity?.value && PropertyInstanceFeedFormatter.evaluateExpression(propertyInstance, oldEntity.value);

      if (typeof newValue === "string") {
        return getChangedString({
          newValue,
          changedField: "value",
          entityType: feedItem.entityTitle,
          oldValue,
          name: PropertyInstanceFeedFormatter.getLabelById(feedItem.referenceId),
        });
      }
    }

    const changedField = PropertyInstanceFeedFormatter.getChangedField(newEntity, oldEntity);

    if (changedField) {
      const newFieldValue = newEntity[changedField];
      const oldFieldValue = oldEntity[changedField];
      const fieldLabel = PropertyInstanceFeedFormatter.fieldToLabelMap[changedField];

      if (newFieldValue !== undefined && newFieldValue !== null && fieldLabel) {
        return getChangedString({
          newValue: newFieldValue,
          changedField: fieldLabel,
          entityType: feedItem.entityTitle,
          oldValue: oldFieldValue,
          name: PropertyInstanceFeedFormatter.getLabelById(feedItem.referenceId),
        });
      }
    }

    return PropertyFeedFormatter.getUpdateMessage(feedItem);
  }

  private static getChangedField(
    newEntity: TransactionPropertyInstanceEntity,
    oldEntity: TransactionPropertyInstanceEntity
  ): IPropertyInstanceAllowedKeys | undefined {
    const fields = Object.keys(PropertyInstanceFeedFormatter.fieldToLabelMap) as Array<IPropertyInstanceAllowedKeys>;
    return fields.find(field => newEntity[field] !== null && newEntity[field] !== oldEntity[field]);
  }

  private static getPropertyEntityLabel(referenceType: EFeedEntityType, entity: TransactionEntity): string | undefined {
    if (isPropertyInstanceEntity(referenceType, entity)) {
      return entity.propertyDefinition.label;
    } else if (isPropertyDefinitionEntity(referenceType, entity)) {
      return entity.label;
    }
  }

  private static getLabelById(id: string): string {
    const propertyInstance = appStore.workspaceModel?.propertyInstanceMap.get(id);
    return propertyInstance?.propertyDefinition?.label ?? "";
  }
}
