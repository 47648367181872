import { useState } from "react";
import { IRowNode, RowDragEndEvent, RowDragEvent, RowDragLeaveEvent, RowDragMoveEvent } from "ag-grid-community";

import { DANGER_DROP, ICON_WIDTH } from "@components/Modeling/ModelingFrame/Table/TableComponent/constants";
import { EMoveType, NodeInfo } from "@components/Modeling/ModelingFrame/Table/TableComponent/types";
import {
  getElementPosition,
  getMoveDestinationBlock,
  getTableRowById,
  isValidRowMove,
} from "@components/Modeling/ModelingFrame/Table/TableComponent/utils";
import { blockNodeList } from "@components/Modeling/ModelingFrame/Table/utils";
import appStore from "@store/AppStore";
import { isStringArraysEqual } from "@utilities";

export const AG_GIRD_TARGET_ROW_CLASS = "ag-grid-target-row-class";

const useTreeDrag = (extraPadding = 0) => {
  const [moveType, setMoveType] = useState<EMoveType>(EMoveType.RE_PARENT);
  const [potentialParent, setPotentialParent] = useState<IRowNode<NodeInfo> | undefined | null>(null);

  const onRowDragMove = (moveProps: RowDragMoveEvent<NodeInfo>) => {
    let leftOffset = 0;
    let newMoveType = EMoveType.RE_ORDER;

    const agRowElement = (moveProps.eventPath?.at(0) as HTMLElement | null)?.closest(".ag-row") as HTMLElement | null;
    const targetNode = moveProps.api.getRowNode(agRowElement?.getAttribute("row-id") || "");
    const imageNode = agRowElement?.querySelector(".ag-group-custom-name-icon");

    if (imageNode && agRowElement) {
      const imageNodeCords = getElementPosition(imageNode);
      const rowNodeCords = getElementPosition(agRowElement);
      const pseudoElementPosition = imageNodeCords.x - rowNodeCords.x + extraPadding;
      const isRootElement = moveProps.overNode?.data?.path.length === 1;
      const isOuterHover = imageNodeCords.x + ICON_WIDTH > moveProps.event.x;

      newMoveType = isOuterHover && !isRootElement ? EMoveType.RE_ORDER : EMoveType.RE_PARENT;
      leftOffset = newMoveType === EMoveType.RE_ORDER ? pseudoElementPosition - ICON_WIDTH : pseudoElementPosition - 5;

      if (moveType === EMoveType.RE_PARENT) {
        agRowElement.classList.add("ag-grid-re-parent");
        agRowElement.classList.remove("ag-grid-re-order");
      } else {
        agRowElement.classList.add("ag-grid-re-order");
        agRowElement.classList.remove("ag-grid-re-parent");
      }

      agRowElement.style.setProperty("--left-position", `${leftOffset}px`);
    }

    if (!moveProps.node.rowIndex) {
      return;
    }

    setMoveType(newMoveType);
    setPotentialParentForNode(moveProps, targetNode);
  };

  const onRowDragLeave = (event: RowDragLeaveEvent<NodeInfo>) =>
    setPotentialParentForNode(
      {
        ...event,
        overNode: undefined,
      },
      null
    );

  const onRowDragEnd = async (event: RowDragEndEvent<NodeInfo>) => {
    const targetBlock = potentialParent?.data?.block;
    const selectedBlock = event.node.data?.block;
    const isValidMove = isValidRowMove(event);
    const parentElement = document.querySelector(`[row-id='${potentialParent?.id}']`);

    if (parentElement) {
      parentElement.classList.remove(DANGER_DROP);
    }

    if (targetBlock && selectedBlock && isValidMove) {
      const isSameParent = targetBlock.parentBlock?.id === selectedBlock.parentBlock?.id;
      const reorderInsideParent = isSameParent && moveType === EMoveType.RE_ORDER;

      // reorder blocks inside parent
      if (reorderInsideParent) {
        const destinationBlock = getMoveDestinationBlock(selectedBlock, targetBlock);
        selectedBlock.parentBlock.moveBlock(selectedBlock.id, destinationBlock.id);

        // re-parent block and place it on top of the child list
      } else if (moveType === EMoveType.RE_PARENT) {
        // if re-parent inside the same parent
        if (targetBlock.id === selectedBlock.parentBlock?.id) {
          selectedBlock.parentBlock.moveBlock(selectedBlock.id, selectedBlock.parentBlock.children.at(0).id);
        } else {
          const reParentDone = await appStore.workspaceModel?.reparentBlock(selectedBlock, targetBlock);
          if ((reParentDone && targetBlock.children?.length) || 0 > 1) {
            targetBlock.moveBlock(selectedBlock.id, targetBlock.children?.at(0).id);
          }
        }

        // re-parent block and reorder it inside the new parent
      } else {
        const reParentDone = await appStore.workspaceModel?.reparentBlock(selectedBlock, targetBlock.parentBlock);
        const destinationBlock = getMoveDestinationBlock(selectedBlock, targetBlock);

        reParentDone && targetBlock.parentBlock.moveBlock(selectedBlock.id, destinationBlock.id);
      }
    }

    event.api.setGridOption("rowData", blockNodeList());
    potentialParent?.setSelected(false);
    setPotentialParent(null);
  };

  const setPotentialParentForNode = (event: RowDragEvent<NodeInfo>, targetNode: IRowNode<NodeInfo> | null | undefined) => {
    let newPotentialParent = targetNode;
    const alreadySelected = potentialParent === newPotentialParent;
    const isValidMove = isValidRowMove(event);
    const moveToSelf = isStringArraysEqual([...(event.node.data?.path || [])], [...(event.overNode?.data?.path || [])]);

    if (moveToSelf) {
      newPotentialParent = null;
    }

    if (alreadySelected) {
      return;
    }

    if (newPotentialParent) {
      const potentialParentElement = getTableRowById(newPotentialParent.id);

      if (!isValidMove && potentialParentElement) {
        potentialParentElement.classList.add(DANGER_DROP);
      }
      newPotentialParent.setSelected(true);
    }

    if (potentialParent) {
      const parentElement = getTableRowById(potentialParent.id);

      if (parentElement) {
        parentElement.classList.remove(DANGER_DROP);
      }
      potentialParent.setSelected(false);
    }

    setPotentialParent(newPotentialParent);
  };

  return {
    onRowDragMove,
    onRowDragLeave,
    onRowDragEnd,
    moveType,
    potentialParent,
  };
};

export default useTreeDrag;
