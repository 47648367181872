import { CSSProperties, MutableRefObject, useRef } from "react";
import { Classes, ResizeSensor } from "@blueprintjs/core";
import { ColumnEventType, ColumnResizedEvent, DragStartedEvent, GridApi, GridReadyEvent } from "ag-grid-community";
import { AgGridReact, AgGridReactProps } from "ag-grid-react";
import classNames from "classnames";
import { observer } from "mobx-react";

import { useDynamicAutoHeight } from "@components/Table/hooks/useDynamicAutoHeight";

import "ag-grid-enterprise";

import "./Table.scss";

enum EColumnResizeEventType {
  COLUMN_RESIZED = "columnResized",
}

export type TTableProps<T> = AgGridReactProps<T> & {
  disableAutoHeight?: boolean;
  dynamicAutoHeight?: boolean;
  style?: CSSProperties;
  onColumnResize?: (columnName: string, width: number, api: GridApi, source: ColumnEventType) => void;
  tableRef?: MutableRefObject<AgGridReact<T> | null>;
};

const Table = <T,>(props: TTableProps<T>) => {
  const { className, dynamicAutoHeight, disableAutoHeight, onGridReady, ...restProps } = props;
  const wrapperRef = useRef<HTMLDivElement>(null);
  const { isAutoHeightEnabled, handleResize } = useDynamicAutoHeight(dynamicAutoHeight, !disableAutoHeight, className);

  const handleDragStarted = (e: DragStartedEvent<T>) => {
    const app: Element | null = document.getElementById("app");
    const textHolder = document.querySelector(".ag-dnd-ghost");

    if (textHolder && app?.classList.contains(Classes.DARK)) {
      textHolder.classList.add("ag-dnd-ghost-dark");
    }

    props.onDragStarted && props.onDragStarted(e);
  };

  const handleColumnResized = (event: ColumnResizedEvent<T>) => {
    const column = event.column;

    if (event.type === EColumnResizeEventType.COLUMN_RESIZED && column && event.finished && props.onColumnResize) {
      props.onColumnResize(column.getColId(), column.getActualWidth(), event.api, event.source);
    }
  };

  const handleGridReady = (event: GridReadyEvent<T>) => {
    event.api.setGridOption("rowBuffer", 25);
    onGridReady?.(event);
  };

  return (
    <ResizeSensor onResize={handleResize}>
      <div
        className={classNames("ag-theme-rollup", className)}
        ref={wrapperRef}
        style={{ flex: isAutoHeightEnabled ? undefined : 1, ...props.style }}
      >
        <AgGridReact<T>
          suppressContextMenu
          suppressDragLeaveHidesColumns
          stopEditingWhenCellsLoseFocus
          suppressRowClickSelection
          suppressGroupRowsSticky
          ref={props.tableRef}
          domLayout={isAutoHeightEnabled ? "autoHeight" : "normal"}
          containerStyle={{ height: isAutoHeightEnabled ? "auto" : "100%" }}
          onGridReady={handleGridReady}
          rowBuffer={0}
          {...restProps}
          onColumnResized={handleColumnResized}
          onDragStarted={handleDragStarted}
        />
      </div>
    </ResizeSensor>
  );
};

export default observer(Table);
