import { CommentGetChildrenRequestDto } from "@rollup-api/models/comments/commentGetChildrenRequestDto.model";
import { CommentGetThreadedRequestDto } from "@rollup-api/models/comments/commentGetThreadedRequestDto.model";
import { CommentThreadedItem } from "@rollup-api/models/comments/threadedComments.model";
import { trackSegmentEvent } from "src/lib/Segment";

import { Comment, CreateCommentDto, UpdateCommentDto } from "../models/comments";

import { HttpClient, ParentClient } from "./client";

export class Comments extends HttpClient {
  public constructor(parent: ParentClient) {
    super(parent);
  }

  private get endpoint() {
    return "comments";
  }

  public create = (dto: CreateCommentDto) => {
    trackSegmentEvent("comment:create");
    return this.instance.post<Comment>(this.endpoint, dto);
  };

  public retrieve = (id: string) => {
    return this.instance.get<Comment>(`${this.endpoint}/${id}`);
  };

  public retrieveList = () => {
    return this.instance.get<Comment[]>(this.endpoint);
  };

  public retrieveThreaded = (dto: CommentGetThreadedRequestDto) => {
    return this.instance.get<CommentThreadedItem[]>(`${this.endpoint}/threaded`, { params: dto });
  };

  public retrieveChildren = (dto: CommentGetChildrenRequestDto) => {
    return this.instance.get<Comment[]>(`${this.endpoint}/children`, { params: dto });
  };

  public update = (id: string, dto: UpdateCommentDto) => {
    trackSegmentEvent("comment:update", { id });
    return this.instance.patch<Comment>(`${this.endpoint}/${id}`, dto);
  };

  public delete = (id: string) => {
    trackSegmentEvent("comment:delete");
    return this.instance.delete<Comment>(`${this.endpoint}/${id}`);
  };
}
