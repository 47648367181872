import { useEffect, useState } from "react";
import { Alignment, Divider, Icon } from "@blueprintjs/core";
import { observer } from "mobx-react";

import SlackIcon from "@assets/icons/thirdParty/slack.svg?react";
import InfoBox from "@components/InfoBox/InfoBox";
import { LegacyCustomIcon } from "@components/Shared/LegacyCustomIcon/LegacyCustomIcon";
import { Switch } from "@components/Switch";
import { showApiErrorToast } from "@components/UiLayers/toaster";
import { NotificationChannel } from "@rollup-api/models/userSettings/notificationSetting.model";
import { rollupClient } from "src/core/api";
import { Text, TextVariant } from "src/ui/Text";

import "./SettingsNotifications.scss";

// TODO: bring these back once implemented in the BE
// interface RollupEventCheckBoxProps {
//   rollupEventType: RollupEventTypes;
//   rollupEventEvent: RollupEventEvents;
// }

// TODO: bring these back once implemented in the BE
// const RollupEventCheckBox = observer(({ rollupEventType, rollupEventEvent }: RollupEventCheckBoxProps) => {
//   return (
//     <Checkbox
//       checked={appStore.userModel?.settings?.rollupEventSettings.isRollupEventsEnabled(rollupEventType, rollupEventEvent)}
//       disabled={!appStore.userModel?.settings?.rollupEventSettings.isRollupEventTypeEnabled(rollupEventType)}
//       onChange={() => appStore.userModel?.settings?.rollupEventSettings.toggleRollupEvent(rollupEventType, rollupEventEvent)}
//     />
//   );
// });

// TODO: bring these back once implemented in the BE
// interface RollupEventGroupCheckBoxProps {
//   rollupEventType: RollupEventTypes;
//   rollupEventEventArea: RollupEventEventAreas;
// }

// TODO: bring these back once implemented in the BE
// const RollupEventGroupCheckBox = observer(({ rollupEventType, rollupEventEventArea }: RollupEventGroupCheckBoxProps) => {
//   return (
//     <Checkbox
//       indeterminate={
//         appStore.userModel?.settings?.rollupEventSettings.getRollupEventEventsGroup(rollupEventType, rollupEventEventArea) ===
//         "indeterminate"
//       }
//       checked={!!appStore.userModel?.settings?.rollupEventSettings.getRollupEventEventsGroup(rollupEventType, rollupEventEventArea)}
//       disabled={!appStore.userModel?.settings?.rollupEventSettings.isRollupEventTypeEnabled(rollupEventType)}
//       onChange={e =>
//         appStore.userModel?.settings?.rollupEventSettings.setRollupEventEventsGroup(
//           rollupEventType,
//           rollupEventEventArea,
//           e.currentTarget.checked
//         )
//       }
//     />
//   );
// });

/** Main function. */
const SettingsNotifications = () => {
  const [enabledChannels, setEnabledChannels] = useState<NotificationChannel[]>([]);
  const [isFetching, setIsFetching] = useState<boolean>(false);

  useEffect(() => {
    setIsFetching(true);
    rollupClient.notificationSettings
      .retrieve()
      .then(res => {
        const currentChannels =
          res.data.find(setting => setting.entityType === "comment" && setting.action === "mentionedInComment")?.channels ?? [];
        setEnabledChannels(currentChannels);
      })
      .catch(error => {
        console.error("Error fetching notification settings", error);
        showApiErrorToast("Error fetching notification settings", error);
      })
      .finally(() => {
        setIsFetching(false);
      });
  }, []);

  const toggleChannel = async (channel: NotificationChannel) => {
    const isCurrentlyEnabled = enabledChannels.includes(channel);
    setIsFetching(true);
    let channels: NotificationChannel[];
    if (isCurrentlyEnabled) {
      channels = enabledChannels.filter(c => c !== channel);
    } else {
      channels = [...enabledChannels, channel];
    }

    setEnabledChannels(channels);
    try {
      await rollupClient.notificationSettings.update({
        notificationSettings: [
          {
            entityType: "comment",
            action: "mentionedInComment",
            channels,
          },
        ],
      });
    } catch (error: any) {
      console.error("Error updating notification settings", error);
      showApiErrorToast("Error updating notification settings", error);
    }
    setIsFetching(false);
  };

  return (
    <div className="settings-layout--content">
      <div className="settings-layout--header">
        <Text variant={TextVariant.H1}>Notification settings</Text>
      </div>
      <div className="method-area">
        <Text variant={TextVariant.H2}>Methods</Text>
        <InfoBox
          text="You will receive a notification by default in the Rollup notification center for the workspace you participate in."
          icon="small-info-sign"
        />
        <div className="method-area--grid">
          <Icon icon="envelope" size={16} />
          <Text variant={TextVariant.H3}>Email</Text>
          <Switch
            className="no-m-no-p"
            checked={enabledChannels.includes(NotificationChannel.EMAIL)}
            alignIndicator={Alignment.CENTER}
            onChange={() => toggleChannel(NotificationChannel.EMAIL)}
            e2eIdentifiers="enable-email-notifications"
          />
          <Text> </Text>
          <Text color="gray4">Receive email updates, including mentions and comment replies.</Text>
        </div>
        <Divider />
        {/* TODO: bring these back once implemented in the BE */}
        {/* <div className="method-area--grid">
          <Icon icon="desktop" size={16} />
          <Text variant={TextVariant.H3}>Desktop notifications</Text>
          <Switch
            className="no-m-no-p"
            checked={appStore.userModel?.settings?.rollupEventSettings.desktopEnabled}
            alignIndicator={Alignment.CENTER}
            onChange={appStore.userModel?.settings?.rollupEventSettings.toggleDesktopEnabled}
          />
          <Text> </Text>
          <Text color="gray4">Show desktop notification badge.</Text>
        </div>
        <Divider /> */}
        <div className="method-area--grid">
          <LegacyCustomIcon icon={<SlackIcon />} />
          <Text variant={TextVariant.H3}>Slack notifications</Text>
          <Switch
            disabled={isFetching}
            className="no-m-no-p"
            checked={enabledChannels.includes(NotificationChannel.SLACK)}
            alignIndicator={Alignment.CENTER}
            onChange={() => toggleChannel(NotificationChannel.SLACK)}
            e2eIdentifiers="enable-slack-notifications"
          />
          <Text> </Text>
          <Text color="gray4">
            Receive notifications in your Slack workspace when you're mentioned in a page, database property, or comment.
          </Text>
        </div>
      </div>
      {/* TODO: bring these back once implemented in the BE */}
      {/* <div className="pref-area">
        <Text variant={TextVariant.H2}>Preferences</Text>
        <table className="pref-area">
          <thead>
            <tr>
              <th>
                <Text variant={TextVariant.H5}>Notification Type</Text>
              </th>
              <th>
                <Text variant={TextVariant.H5}>Email</Text>
              </th>
              <th>
                <Text variant={TextVariant.H5}>Desktop</Text>
              </th>
              <th>
                <Text variant={TextVariant.H5}>Slack</Text>
              </th>
            </tr>
            <tr>
              <td colSpan={4} />
            </tr>
          </thead>

          <tbody>
            <tr>
              <td>
                <Text variant={TextVariant.H3}>Comments</Text>
              </td>
              <td>
                <RollupEventGroupCheckBox rollupEventType={RollupEventTypes.EMAIL} rollupEventEventArea={RollupEventEventAreas.COMMENTS} />
              </td>
              <td>
                <RollupEventGroupCheckBox
                  rollupEventType={RollupEventTypes.DESKTOP}
                  rollupEventEventArea={RollupEventEventAreas.COMMENTS}
                />
              </td>
              <td>
                <RollupEventGroupCheckBox rollupEventType={RollupEventTypes.SLACK} rollupEventEventArea={RollupEventEventAreas.COMMENTS} />
              </td>
            </tr>
            <tr>
              <td>
                <ul>
                  <li>
                    <Text>You are mentioned in a comment</Text>
                  </li>
                </ul>
              </td>
              <td>
                <RollupEventCheckBox rollupEventType={RollupEventTypes.EMAIL} rollupEventEvent={RollupEventEvents.MENTIONED_IN_COMMENT} />
              </td>
              <td>
                <RollupEventCheckBox rollupEventType={RollupEventTypes.DESKTOP} rollupEventEvent={RollupEventEvents.MENTIONED_IN_COMMENT} />
              </td>
              <td>
                <RollupEventCheckBox rollupEventType={RollupEventTypes.SLACK} rollupEventEvent={RollupEventEvents.MENTIONED_IN_COMMENT} />
              </td>
            </tr>
            <tr>
              <td>
                <ul>
                  <li>
                    <Text>An answer is added to a comment you wrote</Text>
                  </li>
                </ul>
              </td>
              <td>
                <RollupEventCheckBox rollupEventType={RollupEventTypes.EMAIL} rollupEventEvent={RollupEventEvents.COMMENT_REPLIED} />
              </td>
              <td>
                <RollupEventCheckBox rollupEventType={RollupEventTypes.DESKTOP} rollupEventEvent={RollupEventEvents.COMMENT_REPLIED} />
              </td>
              <td>
                <RollupEventCheckBox rollupEventType={RollupEventTypes.SLACK} rollupEventEvent={RollupEventEvents.COMMENT_REPLIED} />
              </td>
            </tr>
            <tr>
              <td colSpan={4} />
            </tr>

            <tr>
              <td>
                <Text variant={TextVariant.H3}>Reports</Text>
              </td>
              <td>
                <RollupEventGroupCheckBox rollupEventType={RollupEventTypes.EMAIL} rollupEventEventArea={RollupEventEventAreas.REPORTS} />
              </td>
              <td>
                <RollupEventGroupCheckBox rollupEventType={RollupEventTypes.DESKTOP} rollupEventEventArea={RollupEventEventAreas.REPORTS} />
              </td>
              <td>
                <RollupEventGroupCheckBox rollupEventType={RollupEventTypes.SLACK} rollupEventEventArea={RollupEventEventAreas.REPORTS} />
              </td>
            </tr>
            <tr>
              <td>
                <ul>
                  <li>
                    <Text>You are mentioned in a Report</Text>
                  </li>
                </ul>
              </td>
              <td>
                <RollupEventCheckBox rollupEventType={RollupEventTypes.EMAIL} rollupEventEvent={RollupEventEvents.MENTIONED_IN_REPORT} />
              </td>
              <td>
                <RollupEventCheckBox rollupEventType={RollupEventTypes.DESKTOP} rollupEventEvent={RollupEventEvents.MENTIONED_IN_REPORT} />
              </td>
              <td>
                <RollupEventCheckBox rollupEventType={RollupEventTypes.SLACK} rollupEventEvent={RollupEventEvents.MENTIONED_IN_REPORT} />
              </td>
            </tr>
          </tbody>
        </table>
      </div> */}
    </div>
  );
};
/** Exports. */
export default observer(SettingsNotifications);
