import { IntegrationItem } from "@rollup-api/models";

export enum GoogleDriveMimeType {
  DOCUMENT = "application/vnd.google-apps.document",
  SPREADSHEET = "application/vnd.google-apps.spreadsheet",
  FOLDER = "application/vnd.google-apps.folder",
  FORM = "application/vnd.google-apps.form",
  DRAWING = "application/vnd.google-apps.drawing",
  PRESENTATION = "application/vnd.google-apps.presentation",
}

export type GoogleDriveListItem = {
  id: string;
  name: string;
  kind: string;
};

export type GoogleFileListItem = {
  id: string;
  name: string;
  mimeType: string;
  webViewLink: string;
  createdTime: number;
  modifiedTime: number;
  starred: boolean;
  trashed: boolean;
};

export type GoogleFileMetadata = GoogleFileListItem & {
  version: number;
  size: number;
  lastModifyingUser?: {
    displayName: string;
    photoLink: string;
    emailAddress: string;
  };
};

export enum GoogleDriveFileType {
  Document = "document",
  Spreadsheet = "spreadsheet",
  Presentation = "presentation",
  Drawing = "drawing",
  Form = "form",
}

export type GoogleIntegrationItem = IntegrationItem<GoogleFileListItem>;
