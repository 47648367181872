export class Invoice {
  currency!: string;
  total!: number;
  status!: InvoiceStatus;
  createdAt!: Date;
  paymentUrl!: string;
  pdfUrl!: string;
}

/** More info about the statuses: https://docs.stripe.com/billing/migration/invoice-states **/
export enum InvoiceStatus {
  Draft = "draft",
  Open = "open",
  Paid = "paid",
  Uncollectible = "uncollectible",
  Void = "void",
}
