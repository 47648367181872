import { useEffect, useState } from "react";
import { ContextMenu, Menu, MenuDivider } from "@blueprintjs/core";
import { BlueprintIcon } from "@ui/BlueprintIcon";
import { ICellRendererParams } from "ag-grid-community";
import classNames from "classnames";
import { observer } from "mobx-react";
import { isAlive } from "mobx-state-tree";

import { DeleteConfirmationDialog } from "@components/DeleteConfirmationDialog";
import { EditableName } from "@components/EditableName";
import { Icon } from "@components/Icon";
import { MenuItem } from "@components/MenuItem";
import { MenuItemDelete } from "@components/MenuItems";
import { CopyLinkMenuItem, CopyPasteBlockMenuItems } from "@components/SmallComponents";
import appStore from "@store/AppStore";

import { AddNewDefinitionPopover } from "../Components/AddNewDefinitionPopover";
import { CHEVRON_WIDTH } from "../constants";
import { NodeInfo } from "../types";

enum NewBlockType {
  Child = "Child",
  Sibling = "Sibling",
}

const TreeCell = (props: ICellRendererParams<NodeInfo>) => {
  const block = props.data?.block;
  const [inputValue, setInputValue] = useState<string>(block?.label || "");
  const [newBlockType, setNewBlockType] = useState<NewBlockType>();
  const [newBlockLabel, setNewBlockLabel] = useState("");
  const [isDeletePopoverOpen, setIsDeletePopoverOpen] = useState(false);

  useEffect(() => {
    setInputValue(block?.label || "");
  }, [block?.label]);

  if (!block || !isAlive(block)) {
    return null;
  }

  const handleCancelEdit = () => {
    setInputValue(block.label || "");
  };

  const handleChange = (value: string) => {
    setInputValue(value);
  };

  const handleConfirm = (value: string) => {
    if (!value) {
      setInputValue(block.label || "");
    } else {
      block.setLabel(value);
    }
  };

  const hasChild = !!block.validatedChildren.length;
  const additionalMargin = hasChild ? 0 : CHEVRON_WIDTH;

  const handleExpand = () => {
    props.node.setExpanded(!props.node.expanded);
  };

  const onAddChildBlock = () => {
    setNewBlockType(NewBlockType.Child);
  };

  const onAddSiblingBlock = () => {
    setNewBlockType(NewBlockType.Sibling);
  };

  const onCloseAddNewPopover = () => {
    setNewBlockType(undefined);
  };

  const onAddNewBlock = () => {
    switch (newBlockType) {
      case NewBlockType.Child:
        appStore.workspaceModel?.addNewBlock(block, newBlockLabel);
        break;
      case NewBlockType.Sibling:
        appStore.workspaceModel?.addNewBlock(block.parentBlock, newBlockLabel);
        break;
    }
  };

  const openDeletePopover = () => setIsDeletePopoverOpen(true);
  const onCloseDeletePopover = () => setIsDeletePopoverOpen(false);
  const onDeleteBlock = () => {
    setIsDeletePopoverOpen(false);

    if (appStore.workspaceModel && appStore.workspaceModel.rootBlock !== block) {
      appStore.workspaceModel.deleteBlock(block);
    }
  };

  const renderContextContent = () => {
    if (!block) {
      return undefined;
    }

    if (block === appStore.workspaceModel?.rootBlock) {
      return (
        <Menu>
          <MenuItem icon="new-layers" text="Add Child Block" onClick={onAddChildBlock} e2eIdentifiers="add-child-block" />
          <CopyPasteBlockMenuItems block={block} />
          <CopyLinkMenuItem node={block} />
        </Menu>
      );
    }

    return (
      <Menu>
        <MenuItem icon="new-layers" text="Add Sibling block" onClick={onAddSiblingBlock} e2eIdentifiers="add-sibling-block" />
        <MenuItem icon="new-layers" text="Add Child block" onClick={onAddChildBlock} e2eIdentifiers="add-child-block" />
        <CopyPasteBlockMenuItems block={block} />
        <CopyLinkMenuItem node={block} />
        <MenuDivider />
        <MenuItemDelete onDelete={openDeletePopover} />
      </Menu>
    );
  };

  return (
    <ContextMenu content={renderContextContent} className="flex flex-1 items-center">
      <div
        data-testid={`blockname_${block.label}`}
        className="ag-group-custom-name"
        style={{ paddingLeft: props.node.uiLevel * 20 + additionalMargin }}
      >
        {hasChild && <BlueprintIcon className="ag-group-custom-name-chevron" icon="chevron-right" onClick={handleExpand} />}
        <Icon
          icon={block.iconView}
          onClick={handleExpand}
          className={classNames("ag-group-custom-name-icon", {
            ["ag-group-custom-name-icon-clickable"]: hasChild,
          })}
        />
        <AddNewDefinitionPopover
          value={newBlockLabel}
          onChange={setNewBlockLabel}
          isOpen={!!newBlockType}
          onClose={onCloseAddNewPopover}
          onConfirm={onAddNewBlock}
        >
          <EditableName
            className="prop-label-name cell-title"
            name={inputValue}
            onCancel={handleCancelEdit}
            onConfirm={handleConfirm}
            onChange={handleChange}
            e2eIdentifiers={[inputValue, props.node.rowIndex || ""]}
          />
        </AddNewDefinitionPopover>
        <DeleteConfirmationDialog
          isOpen={isDeletePopoverOpen}
          titleItem={`block "${block.label}"`}
          descriptionItem="this block"
          onClose={onCloseDeletePopover}
          onCancel={onCloseDeletePopover}
          onConfirm={onDeleteBlock}
        />
      </div>
    </ContextMenu>
  );
};

export default observer(TreeCell);
