import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Tab, TabId, Tabs } from "@blueprintjs/core";
import { observer } from "mobx-react";

import IntegrationCard from "@components/IntegrationCard";
import { TabTitle } from "@components/TabTitle";
import appStore from "@store/AppStore";
import { IIntegration } from "@store/IntegrationStore";
import { Text, TextVariant } from "src/ui/Text";

import "./SettingsMarketplace.scss";

enum InternalMarketplaceTabs {
  All = "all",
}

/** Main function. */
const SettingsMarketplace = () => {
  const navigate = useNavigate();
  const { tab } = useParams();
  const [activeTab, setActiveTab] = useState<TabId>(InternalMarketplaceTabs.All);

  useEffect(() => {
    if (!tab || !appStore.settingsModel?.integrationCategories.includes(tab)) {
      setActiveTab(InternalMarketplaceTabs.All);
    } else {
      setActiveTab(tab);
    }
  }, [tab]);

  const renderIntegrationsList = () => {
    let integrations: IIntegration[] = [];

    if (activeTab === InternalMarketplaceTabs.All) {
      integrations = appStore.settingsModel?.integrations || [];
    } else {
      integrations = appStore.settingsModel?.integrations.filter(integration => integration.categories?.includes(`${activeTab}`)) || [];
    }

    if (!integrations.length) {
      return <Text>No integrations in the selected category</Text>;
    }

    return (
      <div className="marketplace--items">
        {integrations.map(integration => (
          <IntegrationCard key={integration.id} integration={integration} />
        ))}
      </div>
    );
  };

  const handleTabClick = (tabId: TabId) => {
    setActiveTab(tabId);
    navigate(`/settings/marketplace/${tabId}`);
  };

  return (
    <div className="settings-layout--content">
      <div className="settings-layout--header">
        <Text variant={TextVariant.H1}>Marketplace</Text>
      </div>
      <Tabs className="marketplace--tabs" id="marketplace-tabs" selectedTabId={activeTab} onChange={handleTabClick} large>
        <Tab id="all" title={<TabTitle title="All" />} panel={renderIntegrationsList()} />
        {appStore.settingsModel?.integrationCategories.map(category => (
          <Tab id={category} title={<TabTitle title={category} />} key={category} panel={renderIntegrationsList()} />
        ))}
      </Tabs>
    </div>
  );
};

/** Exports. */
export default observer(SettingsMarketplace);
