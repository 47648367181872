import { MouseEvent } from "react";
import { useSearchParam } from "@hooks/useSearchParam/useSearchParam";
import { useTimeout } from "@hooks/useTimeout";
import { observer } from "mobx-react";

import { AnnotationItem } from "@components/AnnotationItem";
import { IComment } from "@store/CommentStore";

interface IEntityAnnotationItemProps {
  comment: IComment;
  lastFocusedCommentId?: string;
  onFocusComment?(): void;
  onEditingComment?(isEditing: boolean): void;
}

const EntityAnnotationItem = (props: IEntityAnnotationItemProps) => {
  const { comment, lastFocusedCommentId, onFocusComment, onEditingComment } = props;
  const [commentId] = useSearchParam("commId");
  const shouldFlash = comment.id === commentId && lastFocusedCommentId !== commentId;

  useTimeout({ onTimeout: onFocusComment, delay: 2000, disabled: !shouldFlash });

  const handleAnnotationItemClick = (e: MouseEvent) => {
    // this avoids closing the popover when the user clicks on a menu item, .e.g "Edit"
    e.stopPropagation();
  };

  return (
    <AnnotationItem
      key={comment.id}
      comment={comment}
      onClick={handleAnnotationItemClick}
      shouldFlash={shouldFlash}
      onEditingComment={onEditingComment}
    />
  );
};

export default observer(EntityAnnotationItem);
