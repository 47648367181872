import { Organization, OrganizationListEntry, OrganizationUpdateDto } from "../models/organizations";
import { LogoDtoModel } from "../models/organizations/logoDto.model";
import { Profile } from "../models/profiles";
import { GetProfilesRequestDto } from "../models/profiles/profilesRequestDto.model";

import { UserRole } from "./authTypes";
import { HttpClient, ParentClient } from "./client";

export class Organizations extends HttpClient {
  public constructor(parent: ParentClient) {
    super(parent);
  }

  private readonly endpoint: string = "/organizations";

  public retrieve = (modules?: string) => {
    return this.instance.get<Organization>(`${this.endpoint}/current`, { params: { modules } });
  };

  public retrieveAll = () => {
    return this.instance.get<OrganizationListEntry[]>(`${this.endpoint}`);
  };

  public retrieveProfileById = (id: string) => {
    return this.instance.get<Profile>(`${this.endpoint}/members/${id}`);
  };

  public retrieveProfiles = async (dto: GetProfilesRequestDto): Promise<Profile[] | undefined> => {
    try {
      const res = await this.instance.get<Profile[]>(`${this.endpoint}/members`, { params: dto });
      return res.data;
    } catch (err) {
      console.error(err);
      return;
    }
  };

  // TODO: won't work since I have nowhere to put a search term. BE needs to change.
  public searchMembers = async (
    // email?: string,
    // name?: string,
    searchTerm?: string,
    sortedBy: "name" | "email" | "lastTimeActive" = "name",
    sortOrder: "ASC" | "DESC" = "ASC"
  ) => {
    return this.instance.get<Profile[]>(`${this.endpoint}/members`, {
      params: { searchTerm, sortedBy, sortOrder },
    });
  };

  public removeMember = (id: string) => {
    return this.instance.delete<Profile>(`${this.endpoint}/members/${id}`);
  };

  public setMemberRole = (id: string, role: UserRole) => {
    return this.instance.patch<Profile[]>(`${this.endpoint}/members/${id}`, { role });
  };

  public update = (dto: OrganizationUpdateDto) => {
    return this.instance.patch<Organization>(this.endpoint, dto);
  };

  public uploadLogo = (file: File) => {
    const data = new FormData();
    data.append("file", file);
    return this.instance.put<LogoDtoModel>(`${this.endpoint}/upload-organization-logo`, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  };
}
