import { TextArea as BPTextArea, TextAreaProps } from "@blueprintjs/core";
import classNames from "classnames";
import { observer } from "mobx-react";

import styles from "./TextArea.module.scss";

interface ITextAreaProps extends TextAreaProps {}

const TextArea = (props: ITextAreaProps) => {
  return <BPTextArea {...props} className={classNames(styles.textArea, props.className)} />;
};

export default observer(TextArea);
