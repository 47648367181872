import { observer } from "mobx-react";

import { IViewer, PresenceAvatar } from "@components/Header/PresenceList/PresenceAvatar";

import styles from "./PresenceList.module.scss";

interface IPresenceListProps {
  viewers: IViewer[];
}

export const PresenceList = observer(function PresenceList({ viewers }: IPresenceListProps) {
  if (viewers?.length) {
    const avatars = viewers.map(v => <PresenceAvatar key={v.clientId} viewer={v} />);
    return <div className={styles.avatarList}>{avatars}</div>;
  }

  return null;
});
