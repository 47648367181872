import { useParams } from "react-router-dom";
import { NonIdealState } from "@blueprintjs/core";
import { observer } from "mobx-react";

import HoopsViewer from "@components/Modeling/ModelingFrame/HoopsViewer/HoopsViewer";
import Pane from "@router/components/Panes/Pane";
import appStore from "@store/AppStore";

/** Main function. */
function HoopsViewIndex() {
  // get attachment id from url router param & look for org level attachment
  const { attachmentId } = useParams();
  const attachment = attachmentId ? appStore.orgModel.attachments.get(attachmentId) : undefined;

  if (!attachment) {
    return <NonIdealState title="Attachment not found" />;
  } else {
    appStore.env.setActiveAttachment(attachment);
  }

  return (
    <Pane>
      <HoopsViewer attachment={attachment} />
    </Pane>
  );
}

/** Exports. */
export default observer(HoopsViewIndex);
