import startCase from "lodash/startCase";

import { IComplexSelectOption, ISelectOption } from "@rollup-types/selection";
import { createDataTestId, ElementType, IE2EDataTag } from "@utilities/E2EUtils";

export const enhanceStringOptionWithCase = <T extends string>(option: T): IComplexSelectOption<T> & IE2EDataTag => ({
  value: option,
  label: startCase(option),
  ...createDataTestId(ElementType.ControlOption, option),
});
export const enhanceStringOption = <T extends string>(option: T): IComplexSelectOption<T> => ({ value: option, label: option });
export const isComplexOption = (option?: ISelectOption): option is IComplexSelectOption => {
  return !!option && typeof option !== "string";
};
