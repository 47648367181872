import { InputGroup, Label } from "@blueprintjs/core";
import { observer } from "mobx-react-lite";

import appStore from "@store/AppStore";
import { Text, TextVariant } from "src/ui/Text";

type Props = {
  jobTitle: string;
  setJobTitle: (jobTitle: string) => void;
  department: string;
  setDepartment: (department: string) => void;
};

const UserDetailsConfig = (props: Props) => {
  const { jobTitle, setJobTitle, department, setDepartment } = props;

  return (
    <div className="user-details-area">
      <Text variant={TextVariant.H3}>User details</Text>
      <div className="user-details-area--subsection">
        <Label className="user-details-area--subsection--label">
          What is your role?
          <InputGroup
            large
            value={jobTitle}
            onChange={event => setJobTitle(event.target.value)}
            placeholder={appStore.userModel?.jobTitle || "Your role..."}
          />
        </Label>
        <Label className="user-details-area--subsection--label">
          What is your department?
          <InputGroup
            large
            value={department}
            onChange={event => setDepartment(event.target.value)}
            placeholder={appStore.userModel?.department ?? "Your department..."}
          />
        </Label>
      </div>
    </div>
  );
};

export default observer(UserDetailsConfig);
