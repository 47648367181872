import { PropertyDefinitionTable } from "@components/Modeling/PropertyDefinition";
import Pane from "@router/components/Panes/Pane";

function PropertiesIndex() {
  return (
    <Pane>
      <PropertyDefinitionTable />
    </Pane>
  );
}

export default PropertiesIndex;
