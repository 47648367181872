import { Position } from "@blueprintjs/core";
import { observer } from "mobx-react";

import ExecutionTypeIcon from "@components/Analysis/ExecutionTypeIcon";
import { TitledTooltip } from "@components/Shared";
import { IAnalysisOutput } from "@store/Analysis/AnalysisOutputStore";

interface IAnalysisOutputTagProps {
  analysisOutput?: IAnalysisOutput;
  iconClassName?: string;
  labelClassName?: string;
}

const AnalysisOutputTag = (props: IAnalysisOutputTagProps) => {
  const { analysisOutput, iconClassName, labelClassName } = props;

  if (!analysisOutput?.codeBlock || !analysisOutput.label) {
    return <div className="data-link-tag warning">Invalid code output</div>;
  }

  const { codeBlock } = analysisOutput;
  const tagLabel = `${codeBlock.label}:${analysisOutput.label}`;
  const tooltipMessage = `Value: ${analysisOutput.value}${analysisOutput.unit ? ` ${analysisOutput.unit}` : ""}`;

  return (
    <TitledTooltip placement={Position.BOTTOM} title={codeBlock.type} content={tooltipMessage}>
      <div className="analysis-output-tag">
        <ExecutionTypeIcon className={iconClassName} type={codeBlock.type} size={16} />
        <span className={labelClassName}>{tagLabel}</span>
      </div>
    </TitledTooltip>
  );
};

export default observer(AnalysisOutputTag);
