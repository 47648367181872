import { BlockIcon, ICustomIconName, ModuleMonochromeIcon } from "@components/CustomIcon/CustomIcon.types";

// these are the icons that have an active icon version
type IActiveIcon = BlockIcon | ModuleMonochromeIcon.Chat | ModuleMonochromeIcon.Feedback;
const activeIcons: IActiveIcon[] = [...Object.values(BlockIcon), ModuleMonochromeIcon.Chat, ModuleMonochromeIcon.Feedback];
type IActiveOnlyIcon = ModuleMonochromeIcon.Chat | ModuleMonochromeIcon.Feedback;
const activeOnlyIcons: IActiveOnlyIcon[] = [ModuleMonochromeIcon.Chat, ModuleMonochromeIcon.Feedback];

export const hasActiveIcon = (icon: ICustomIconName): icon is IActiveIcon => {
  return activeIcons.includes(icon as IActiveIcon);
};

export const isActiveOnlyIcon = (icon: ICustomIconName): icon is IActiveOnlyIcon => {
  return activeOnlyIcons.includes(icon as IActiveOnlyIcon);
};
