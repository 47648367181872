import { ChangeEvent, KeyboardEvent, MouseEvent } from "react";
import { H1, InputGroup } from "@blueprintjs/core";
import { useOnMount } from "@hooks/useOnMount";
import classNames from "classnames";
import { observer } from "mobx-react";

import { Button } from "@components/Button";
import { SortableLinkedLists } from "@components/Shared/SortableLinkedLists";
import { TItemRendererArgs } from "@components/Shared/SortableLinkedLists/Components/Item";
import { RollupEditorType } from "@rollup-types/editor";
import appStore from "@store/AppStore";
import { IReport } from "@store/ReportsStore";
import { useDragToSelect } from "src/providers/DragToSelect/useDragToSelect";

import EmojiHolder from "../EmojiHolder";
import ReportBlock from "../ReportBlock/ReportBlock";
import ReportCover from "../ReportCover";

import { ignoredLastItemClickTypes } from "./constants";

import "./ReportPage.scss";

const REPORT_CONTAINER_ID = "REPORT_CONTAINER_ID";
const REPORT_PAGE_CONTENT = "REPORT_PAGE_CONTENT";

/** Type defs. */
type ReportPageProps = {
  report: IReport;
};

/** Main function. */
const ReportPage = ({ report }: ReportPageProps) => {
  const { resetSelection, elementsContainerRef } = useDragToSelect({
    onDelete: appStore.workspaceModel?.deleteReportBlocks,
    allIds: report.reportBlocks.slice(),
  });

  useOnMount(() => {
    appStore.env.setActiveReport(report.id);
    return () => {
      appStore.env.clearActiveReportBlock();
    };
  });

  const handleUpdateTitle = (label: string) => {
    if (report.label !== label) {
      report.update({ label });
    }
  };

  const onTitleKeyPress = (e: KeyboardEvent<HTMLInputElement>) => {
    const label = (e.target as EventTarget & HTMLInputElement).value;
    if (e.key === "Enter") {
      handleUpdateTitle(label);
      addPlaceholderBlock(0);
    }
  };

  const onTitleBlur = (e: ChangeEvent<HTMLInputElement>) => {
    const label = (e.target as EventTarget & HTMLInputElement).value;
    handleUpdateTitle(label);
  };

  const handleBlockRender = (args: TItemRendererArgs) => {
    const { index, listeners, handleProps, dragging, value: reportBlockId, selected } = args;
    const reportBlockProxy = appStore.workspaceModel?.reportBlocksMap.get(reportBlockId);

    if (!reportBlockProxy) {
      return null;
    }

    return (
      <ReportBlock
        report={report}
        onFocus={resetSelection}
        selected={selected}
        dragListeners={{ ...listeners, ...handleProps }}
        isDragging={dragging}
        key={`${args.value}--${index}`}
        reportBlock={reportBlockProxy}
      />
    );
  };

  const addPlaceholderBlock = (orderIndex?: number) => {
    const id = appStore.workspaceModel?.addReportBlock(report, RollupEditorType.p, "", orderIndex);
    setTimeout(() => {
      const newBlockElement = document.getElementById(id || "");
      (newBlockElement?.firstChild as HTMLDivElement).focus();
    }, 25);
  };

  const handlePushPlaceholder = () => {
    const latestReportBlockId = report.reportBlocks.at(-1);
    const latestReportBlock = appStore.workspaceModel?.reportBlocksMap.get(latestReportBlockId || "");

    if (latestReportBlock && !ignoredLastItemClickTypes.includes(latestReportBlock.type)) {
      const editorHtml = document.getElementById(latestReportBlock?.id);
      if (editorHtml?.innerText.trim().length) {
        addPlaceholderBlock();
      } else {
        (editorHtml?.firstChild as HTMLDivElement).focus();
      }
    } else {
      addPlaceholderBlock();
    }
  };

  const handleMoveBlocks = (ids: string[], targetId: string) => {
    if (ids.length === 1) {
      report.moveBlock(ids[0], targetId);
    } else {
      report.moveBlocks(ids, targetId);
    }
  };

  const handleMouseUp = (e: any) => {
    const isEmptySpaceClick = e.target.id === REPORT_PAGE_CONTENT;

    if (isEmptySpaceClick && !appStore.ui.selectedIds.length) {
      handlePushPlaceholder();
    }
  };

  const handlePageMouseDown = (e: MouseEvent<HTMLDivElement>) => {
    const isEmptySpaceClick = [REPORT_CONTAINER_ID, REPORT_PAGE_CONTENT].includes((e.target as HTMLElement).id);
    if (isEmptySpaceClick && appStore.ui.selectedIds.length) {
      appStore.ui.setSelectedIds([]);
    }
  };

  const reportPageClassName = classNames("report-page--content", {
    ["report-page--content-full"]: report.fullWidth,
    ["report-page--no-cover"]: !report.coverUrl,
  });

  const renderButtons = () => {
    if (report.coverUrl && report.icon) {
      return null;
    }

    return (
      <div className="report-page--buttons">
        {!report.coverUrl && <ReportCover report={report} />}
        {!report.icon && <EmojiHolder report={report} />}
      </div>
    );
  };

  return (
    <div
      className={classNames("report-page", "drag-to-select-scroll-container")}
      id={REPORT_CONTAINER_ID}
      onMouseDown={handlePageMouseDown}
      data-dragselectable
    >
      {report.coverUrl && <ReportCover report={report} />}
      <div id={REPORT_PAGE_CONTENT} onMouseUp={handleMouseUp} className={reportPageClassName}>
        {report.icon && <EmojiHolder report={report} />}
        {renderButtons()}
        <div data-dragselectable={false}>
          <H1>
            <InputGroup
              inputClassName="report-page--title-input"
              autoFocus={!report.label}
              className="report-page--title"
              key={`${report.id}${report.label}`}
              placeholder="Untitled"
              onBlur={onTitleBlur}
              defaultValue={report.label}
              onKeyUp={onTitleKeyPress}
            />
          </H1>
          {!report.reportBlocks.length && (
            <div className="report-page--empty">
              <div>
                <Button onClick={handlePushPlaceholder} icon="document" e2eIdentifiers="report-create-empty" minimal>
                  Empty page
                </Button>
              </div>
              <Button onClick={appStore.ui.showReportTemplatesDialog} icon="search-template" e2eIdentifiers="report-templates" minimal>
                Templates
              </Button>
            </div>
          )}
          <SortableLinkedLists
            noGap
            placeholder={() => null}
            defaultSelectedIds={[...appStore.ui.selectedIds]}
            wrapperRef={elementsContainerRef}
            multiSelect
            onMultipleItemsDragEnd={handleMoveBlocks}
            renderItem={handleBlockRender}
            items={{ "main-report-block": report?.reportBlocks || [] }}
          />
        </div>
      </div>
    </div>
  );
};

/** Exports. */
export default observer(ReportPage);
