import { Intent } from "@blueprintjs/core";
import { toArray } from "@rollup-io/engineering";
import { AxiosResponse } from "axios";
import { cast, destroy, flow, getSnapshot, IAnyModelType, Instance, types } from "mobx-state-tree";
import { Socket } from "socket.io-client";
import { v4 as uuidv4 } from "uuid";

import { showApiErrorToast, showToast } from "@components/UiLayers/toaster";
import { CustomUnit, IUpdateCustomUnitDto } from "@rollup-api/models/customUnits";
import appStore from "@store/AppStore";
import { CustomUnitStore, ICustomUnit, ICustomUnitMobxType } from "@store/CustomUnitStore";
import { convertTimestamp, getNextOrderIndex, moveOrderIndexedItem } from "@utilities";

import { rollupClient } from "../core/api";
import { mapRtoToSnapshot } from "../services/services.utils";

export enum CustomUnitEvents {
  CreateCustomUnit = "createCustomUnit",
  UpdateCustomUnit = "updateCustomUnit",
  DeleteCustomUnit = "deleteCustomUnit",
  ReorderCustomUnit = "reorderCustomUnit",
}

export const CustomUnitModuleStore = types
  .model("CustomUnitModule", {
    customUnitMap: types.map<ICustomUnitMobxType>(types.late((): IAnyModelType => CustomUnitStore)),
  })
  .views(self => ({
    get values(): ICustomUnit[] {
      const customUnitsArr = toArray<ICustomUnit>(self.customUnitMap);
      return customUnitsArr.sort((a, b) => a.orderIndex - b.orderIndex);
    },
    get(id: string) {
      return self.customUnitMap.get(id);
    },
  }))
  .views(self => ({
    getLabelValidationErrorMessage(label: string): string {
      if (!label || label === "<p></p>") {
        return "Cannot add a custom unit with an empty name";
      } else if (self.values.some(customUnit => customUnit.label.toLowerCase() === label.toLowerCase())) {
        return "A custom unit with this name already exists";
      }
      return "";
    },
  }))
  .views(self => ({
    isValidLabel(label: string): boolean {
      return !self.getLabelValidationErrorMessage(label);
    },
  }))
  .actions(self => ({
    setLabel(id: string, label: string) {
      const validationErrorMessage = self.getLabelValidationErrorMessage(label);

      if (validationErrorMessage) {
        console.error(validationErrorMessage);
        showToast(validationErrorMessage, Intent.WARNING);
        return;
      }
      self.customUnitMap.get(id)?.setLabel(label);
    },
    delete(id: string, disableNotify?: boolean) {
      const customUnitToDelete = self.get(id);
      if (!customUnitToDelete) {
        return;
      }

      if (!disableNotify) {
        rollupClient.modelingModule.customUnits.delete(id).catch((err: Error) => {
          showApiErrorToast("Error deleting custom unit", err);
        });
      }

      if (self.customUnitMap.delete(id)) {
        destroy(customUnitToDelete);
        return true;
      }
      return false;
    },
    move(srcId: string, destId: string, disableNotify?: boolean) {
      if (moveOrderIndexedItem(self.values, srcId, destId) && !disableNotify) {
        rollupClient.modelingModule.customUnits.reorder(srcId, destId);
      }
    },
  }))
  .actions(self => ({
    create: flow(function* (label: string): Generator<any, ICustomUnit | undefined, AxiosResponse<CustomUnit>> {
      const customUnit = self.customUnitMap.put(
        cast({
          id: uuidv4(),
          label,
          orderIndex: getNextOrderIndex(self.values),
        })
      );

      const res = yield rollupClient.modelingModule.customUnits.create(getSnapshot(customUnit));
      if (res.status !== 201) {
        showApiErrorToast("Error creating property definition", new Error());
        self.delete(customUnit.id, false);
        return undefined;
      }
      return customUnit;
    }),
    add(customUnit: CustomUnit) {
      self.customUnitMap.put(mapRtoToSnapshot(customUnit));
    },
    update(id: string, IUpdateCustomUnitDto: IUpdateCustomUnitDto) {
      const customUnit = self.customUnitMap.get(id);
      customUnit?.patch(IUpdateCustomUnitDto);
    },
  }));

export function subscribeToCustomUnitEvents(socket: Socket) {
  socket.on(CustomUnitEvents.CreateCustomUnit, (data: { customUnit: CustomUnit }) => {
    if (data.customUnit?.id) {
      appStore.orgModel.customUnits.add(data.customUnit);
    }
  });

  socket.on(
    CustomUnitEvents.UpdateCustomUnit,
    (data: { id: string; updateCustomUnitDto: IUpdateCustomUnitDto; customUnit: CustomUnit }) => {
      const dto = {
        ...data.updateCustomUnitDto,
        updatedAt: convertTimestamp(data.customUnit.updatedAt),
        updatedBy: data.customUnit.updatedBy,
      };
      appStore.orgModel.customUnits.update(data.id, dto);
    }
  );

  socket.on(CustomUnitEvents.DeleteCustomUnit, (data: { id: string }) => {
    if (data.id) {
      appStore.orgModel.customUnits.delete(data.id, true);
    }
  });

  socket.on(CustomUnitEvents.ReorderCustomUnit, (data: { id: string; reorderCustomUnitDto: { destinationId: string } }) => {
    if (data.id) {
      appStore.orgModel.customUnits.move(data.id, data.reorderCustomUnitDto.destinationId, true);
    }
  });
}

export interface ICustomUnitModule extends Instance<typeof CustomUnitModuleStore> {}
