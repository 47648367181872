import { MouseEvent, useState } from "react";
import { ContextMenu, EditableText, Intent } from "@blueprintjs/core";
import { GitHubQOFactory } from "@queries/GitHubQOFactory";
import { useQuery } from "@tanstack/react-query";
import classNames from "classnames";
import { observer } from "mobx-react";

import { Button } from "@components/Button";
import AttachmentStatusIcon from "@components/Modeling/ModelingFrame/ModelBlock/Attachments/AttachmentStatusIcon";
import { RepoStatusIcon } from "@components/Modeling/ModelingFrame/ModelBlock/Attachments/RepoStatusIcon";
import { Popover } from "@components/Popover";
import UserInfo from "@components/UserInfo/UserInfo";
import appStore from "@store/AppStore";
import { IAttachment } from "@store/AttachmentStore";
import { IBlock } from "@store/BlockStore";
import { createDataTestId, ElementType } from "@utilities/E2EUtils";
import { Text, TextVariant } from "src/ui/Text";

import { useAttachmentFunctions } from "./attachmentHooks";
import AttachmentIcon from "./AttachmentIcon";
import { AttachmentContextMenu } from "./ContextMenu";
import { getGitHubRepoMetadata, isAttachmentGithubRepository } from "./utils";

import "./AttachmentListRow.scss";

type AttachmentListRowProps = {
  block: IBlock;
  attachment: IAttachment;
};

const stopPropagation = (e: MouseEvent<HTMLSpanElement>) => e.stopPropagation();

const AttachmentListRow = ({ block, attachment }: AttachmentListRowProps) => {
  const [isEditingName, setIsEditingName] = useState(false);
  const { handleConfirm, handleView, handleClick } = useAttachmentFunctions({
    attachment,
    block,
    onOpenDetailsDialog: () => appStore.ui.setAttachmentDetails(attachment.id),
  });
  const url = attachment.linkAttachmentUrl;
  const isGitHubRepo = isAttachmentGithubRepository(attachment);
  const githubRepoMetadata = isGitHubRepo && url ? getGitHubRepoMetadata(url) : undefined;

  const { data: repoStatus } = useQuery(GitHubQOFactory.createGetStatusQO(githubRepoMetadata));

  const onConfirm = (value: string) => {
    setIsEditingName(false);
    handleConfirm(value);
  };

  const renderContextMenu = () => {
    return (
      <AttachmentContextMenu
        attachment={attachment}
        block={block}
        onOpenEditingLabel={() => setIsEditingName(true)}
        onOpenDetailsDialog={() => appStore.ui.setAttachmentDetails(attachment.id)}
      />
    );
  };

  return (
    <ContextMenu
      tagName="tr"
      content={renderContextMenu()}
      className={classNames("attachment-list-row", {
        ["attachment--list-row--selected"]: attachment.id === appStore.ui.selectedAttachmentId,
      })}
      id={`attachmentRow-${attachment.id}`}
      onClick={handleClick}
      {...createDataTestId(ElementType.TableRow, "attachment")}
    >
      <td className="attachment-icon-cell">
        <AttachmentIcon attachment={attachment} />
      </td>
      <td>
        <span className="attachment-list-row--name" onClick={isEditingName ? stopPropagation : undefined}>
          <EditableText
            key={attachment.id}
            className={classNames("attachment-name", { ["attachment-name--disabled"]: !isEditingName })}
            isEditing={isEditingName}
            maxLength={255}
            minWidth={20}
            placeholder="Name"
            disabled={!isEditingName}
            defaultValue={attachment.label}
            confirmOnEnterKey
            onConfirm={onConfirm}
            onCancel={() => setIsEditingName(false)}
          />
          {repoStatus && !isEditingName && <RepoStatusIcon status={repoStatus} />}
        </span>
      </td>
      <td className="name-cell">
        <Text variant={TextVariant.Caption}>{attachment.name}</Text>
      </td>
      <td className="attachment-size">{attachment.formattedFileSize}</td>
      <td className="attachment-date">{attachment.formattedCreatedAt}</td>
      <td className="attachment-user">
        <UserInfo user={attachment.user} size="extra-small" />
      </td>
      <td className="attachment-icon-cell">
        <AttachmentStatusIcon attachment={attachment} onClick={handleView} />
      </td>
      <td onClick={e => e.stopPropagation()}>
        <Popover placement="right-start" content={renderContextMenu()}>
          <Button minimal intent={Intent.NONE} icon="more" e2eIdentifiers={["attachment-context-menu"]} />
        </Popover>
      </td>
    </ContextMenu>
  );
};

export default observer(AttachmentListRow);
