import { useRef } from "react";
import { observer } from "mobx-react";

import { GenericSortableList } from "@components/Shared";
import { InputBox } from "@router/components/BlockView/BlockViewTabs/components/InputBox/InputBox";
import appStore from "@store/AppStore";
import type { IBlock } from "@store/BlockStore";
import { scrollToBottom } from "@utilities/DomUtils";

import CreateNewInput from "../Shared/CreateNewInput/CreateNewInput";

import ProjectManagementStatusGroupItem from "./ProjectManagementStatusGroupItem";

import styles from "./ProjectManagementStatusGroup.module.scss";

/** Type defs. */
type ProjectManagementStatusGroupProps = {
  block: IBlock | undefined | null;
};

/** Main function. */
function ProjectManagementStatusGroup({ block }: ProjectManagementStatusGroupProps) {
  const contentRef = useRef<HTMLDivElement>(null);

  const handleAddNewStatus = async (label: string) => {
    const status = await appStore.workspaceModel?.addNewStatusDefinition(label);
    if (contentRef.current) {
      scrollToBottom(contentRef.current);
    }
    return !!status;
  };

  const handleDragEnd = (srcId: string, targetId: string) => {
    appStore.workspaceModel?.moveStatusDefinition(srcId, targetId);
  };

  return (
    <div className={styles.projectManagementStatusGroup}>
      <div className={styles.projectManagementStatusGroupContent} ref={contentRef}>
        <GenericSortableList
          items={appStore.workspaceModel?.modelStatusDefinitions || []}
          renderItem={(item, isDragging, dragListeners) => (
            <ProjectManagementStatusGroupItem dragListeners={dragListeners} isDragging={isDragging} statusDefinition={item} block={block} />
          )}
          onDragEnd={handleDragEnd}
        />
      </div>
      <InputBox wrapperProps={{ className: styles.projectManagementStatusGroupInputBox }} placeholder="Add new status">
        <div className={styles.projectManagementStatusGroupInputWrapper}>
          <CreateNewInput hideIcon placeholder="Add new status" tryCreate={handleAddNewStatus} autoFocus />
        </div>
      </InputBox>
    </div>
  );
}

/** Exports. */
export type { ProjectManagementStatusGroupProps };
export default observer(ProjectManagementStatusGroup);
