import { useEffect, useState } from "react";
import { InputGroup, Intent, Label, TextArea } from "@blueprintjs/core";
import classNames from "classnames";
import { observer } from "mobx-react";

import { EnvironmentTypeSelect } from "@components/Analysis";
import { Button } from "@components/Button";
import { showApiErrorToast, showToast } from "@components/UiLayers/toaster";
import {
  CreateExecutionEnvironmentDto,
  ExecutionEnvironment,
  ExecutionEnvironmentStatus,
  ExecutionEnvironmentType,
} from "@rollup-api/models/execution-environments";
import { rollupClient } from "src/core/api";
import { Text, TextVariant } from "src/ui/Text";

const emptyEnvironment: ExecutionEnvironment = {
  id: "",
  label: "",
  description: "",
  baseImage: "",
  requirements: "",
  type: ExecutionEnvironmentType.Python,
  buildLog: "",
  status: ExecutionEnvironmentStatus.Pending,
  createdAt: "",
  updatedAt: "",
};

interface AddNewExecutionEnvironmentProps {
  onClose?: () => void;
  existingEnvironment?: ExecutionEnvironment;
  formOnly?: boolean;
  onChange?: (environment: ExecutionEnvironment) => void;
}

function getDefaultImage(type: ExecutionEnvironmentType) {
  switch (type) {
    case ExecutionEnvironmentType.Python:
      return "python:3";
    case ExecutionEnvironmentType.Bun:
      return "oven/bun:latest";
    case ExecutionEnvironmentType.Node:
      return "node:lts";
    case ExecutionEnvironmentType.Matlab:
      return "gnuoctave/octave:latest";
    case ExecutionEnvironmentType.Julia:
      return "julia:latest";
    case ExecutionEnvironmentType.Bash:
      return "ubuntu:latest";
    default:
      return "ubuntu:latest";
  }
}

/** Main function. */
const AddNewExecutionEnvironment = ({ onClose, existingEnvironment, formOnly, onChange }: AddNewExecutionEnvironmentProps) => {
  const [executionEnvironment, setExecutionEnvironment] = useState<ExecutionEnvironment>(emptyEnvironment);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    if (existingEnvironment?.id) {
      setExecutionEnvironment(existingEnvironment);
    }
  }, [existingEnvironment]);

  useEffect(() => {
    onChange?.(executionEnvironment);
  }, [onChange, executionEnvironment]);

  const resetValues = () => {
    setExecutionEnvironment(emptyEnvironment);
  };

  const validateForm = () => {
    return executionEnvironment?.label && executionEnvironment.type;
  };

  const handleSubmitClicked = async () => {
    if (validateForm()) {
      const dto: CreateExecutionEnvironmentDto = {
        label: executionEnvironment.label,
        description: executionEnvironment.description,
        type: executionEnvironment.type,
        baseImage: executionEnvironment.baseImage,
        requirements: executionEnvironment.requirements,
      };

      setIsSubmitting(true);
      try {
        if (existingEnvironment?.id) {
          await rollupClient.analysisModule.executionEnvironments.update(existingEnvironment?.id, dto);
        } else {
          await rollupClient.analysisModule.executionEnvironments.create(dto);
        }
        showToast(`Saved execution environment "${dto.label}`, Intent.SUCCESS);
      } catch (error) {
        console.warn(error);
        showApiErrorToast("Error saving execution environment", error as Error);
      }
      setIsSubmitting(false);
      resetValues();
      onClose?.();
    }
  };

  return (
    <div className={classNames("settings-layout--content", { ["form-only"]: formOnly })}>
      <div className="new-environment">
        {!formOnly && (
          <>
            <Button
              large
              className="settings-layout--back"
              minimal
              icon="arrow-left"
              onClick={() => {
                onClose?.();
              }}
              fill={false}
              e2eIdentifiers="go-back"
            >
              Back
            </Button>
            <div className="settings-layout--header">
              <Text variant={TextVariant.H1}>{`${existingEnvironment?.id ? "Edit" : "Create"} execution environment`}</Text>
              <span />
            </div>
          </>
        )}
        <Label className="new-environment--label">
          Label
          <InputGroup
            className="settings-layout--search"
            large
            value={executionEnvironment.label ? executionEnvironment.label : ""}
            onChange={e => setExecutionEnvironment({ ...executionEnvironment, label: e.target.value })}
            placeholder="Enter execution environment label..."
          />
        </Label>
        <Label className="new-environment--description">
          Description
          <InputGroup
            className="settings-layout--search"
            large
            value={executionEnvironment.description ? executionEnvironment.description : ""}
            onChange={e => setExecutionEnvironment({ ...executionEnvironment, description: e.target.value })}
            placeholder="Enter execution environment description..."
          />
        </Label>
        <Label className="new-environment--type">
          Type
          <EnvironmentTypeSelect
            value={executionEnvironment.type}
            onChange={type => setExecutionEnvironment({ ...executionEnvironment, type })}
          />
        </Label>
        <Label className="new-environment--base-image">
          Base Image
          <InputGroup
            className="settings-layout--search"
            large
            value={executionEnvironment.baseImage ? executionEnvironment.baseImage : ""}
            onChange={e => setExecutionEnvironment({ ...executionEnvironment, baseImage: e.target.value })}
            placeholder={getDefaultImage(executionEnvironment.type)}
          />
        </Label>
        <Label className="new-environment--requirements">
          Package requirements
          <TextArea
            className="settings-layout--search"
            large
            autoResize
            minLength={10}
            value={executionEnvironment.requirements ? executionEnvironment.requirements : ""}
            onChange={e => setExecutionEnvironment({ ...executionEnvironment, requirements: e.target.value })}
            placeholder="Enter optional requirements.txt..."
          />
        </Label>
        {!formOnly && (
          <Button
            large
            intent="primary"
            className="create-button"
            onClick={handleSubmitClicked}
            loading={isSubmitting}
            e2eIdentifiers="submit"
          >
            {existingEnvironment?.id ? "Update" : "Create"}
          </Button>
        )}
      </div>
    </div>
  );
};

/** Exports. */
export default observer(AddNewExecutionEnvironment);
