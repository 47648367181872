export enum BoardLoadingState {
  Loading,
  PartiallyLoaded,
  FullyLoaded,
  Error,
}

export enum LayerState {
  Loading,
  Error,
  Loaded,
}

export enum BoardInteractionState {
  Idle = 0,
  Dragging = 1,
  Zooming = 2,
}
