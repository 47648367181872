import { types } from "mobx-state-tree";

export enum EPane {
  NAV = "nav",
  SUB_NAV = "subNav",
  PROJECT_MANAGEMENT = "projectManagement",
  FEED = "feed",
}

export const PaneWidthStore = types
  .model("PaneWidth", {
    nav: types.optional(types.number, 180),
    subNav: types.optional(types.number, 300),
    projectManagement: types.optional(types.number, 400),
    feed: types.optional(types.number, 400),
  })
  .actions(self => {
    return {
      updatePaneWidth(nav: EPane, width: number) {
        self[nav] = width;
      },
    };
  });
