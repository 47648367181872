import { Checkbox } from "@blueprintjs/core";
import { observer } from "mobx-react";

import { TCellRendererProps } from "@components/CatalogItems/Cells/types";
import { getRowId } from "@components/CatalogItems/Table/utils";
import appStore from "@store/AppStore";

import styles from "./CheckboxCell.module.scss";

const CheckboxCell = (props: TCellRendererProps) => {
  if (!props.node.data) {
    return null;
  }

  const rowId = getRowId(props.node.data);
  const selected = props.node.data?.catalogItem && appStore.ui.selectedCatalogItemIds.includes(rowId);

  const toggleSelection = () => {
    appStore.ui.toggleSelectedCatalogItemId(rowId);
  };

  return <Checkbox onChange={toggleSelection} className={styles.checkboxCell} checked={selected} />;
};

export default observer(CheckboxCell);
