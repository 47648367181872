import { KeyboardEvent } from "react";
import { observer } from "mobx-react";

import { EditorContent } from "@components/EditorContent";
import PreventModEnter from "@components/Reports/Editor/Extentions/PreventModEnter";
import { RollupInputRules } from "@components/Reports/Editor/Extentions/RollupInputRules";
import RollupPlaceholder from "@components/Reports/Editor/Extentions/RollupPlaceholder";
import { useDocumentEditor } from "@components/Reports/Editor/useDocumentEditor";
import { ENTER_KEY } from "@constants/keys";
import { RollupEditorType } from "@rollup-types/editor";

import EditorBubbleMenu from "../EditorBubbleMenu";
import { TEditorBlockProps } from "../types";
import { focusReportBlockById, getDocumentEditorClassNames } from "../utils";

const BulletListItemEditor = (props: TEditorBlockProps) => {
  const { documentBlock, editable = true, eventHandlers, disableDashNavigation, onUpdate, onEditorReady } = props;
  const { onFocus, onBlur, onEnterKeyDown, onBackspaceKeyDown, onArrowDownKey, onArrowUpKey } = eventHandlers;

  const placeholderExtension = disableDashNavigation ? [] : [RollupPlaceholder];
  const { editor, onBubbleMenuHide, onBubbleMenuShow } = useDocumentEditor({
    blockId: documentBlock.id,
    content: documentBlock.label,
    editable,
    extensions: [...placeholderExtension, PreventModEnter, RollupInputRules.configure({ reportBlockId: documentBlock.id })],
    disableDashNavigation,
    onUpdate,
    onFocus,
    onBlur,
    onEditorReady,
  });

  const handleKeyDownEvents = (e: KeyboardEvent<HTMLDivElement>) => {
    if (e.key === ENTER_KEY && !editor?.getText()) {
      documentBlock.updateText("");
      documentBlock.updateType(RollupEditorType.p, true);
      focusReportBlockById(documentBlock.id);
      return;
    }
    onEnterKeyDown(e, RollupEditorType.ul);
    onBackspaceKeyDown(e);
    onArrowDownKey(e);
    onArrowUpKey(e);
  };

  return (
    <div key={documentBlock.id} className="report-editor--bullet">
      <EditorBubbleMenu onHide={onBubbleMenuHide} onShow={onBubbleMenuShow} editor={editor} />
      <EditorContent
        id={documentBlock.id}
        onKeyDown={handleKeyDownEvents}
        className={getDocumentEditorClassNames({
          annotationList: documentBlock.annotationList,
        })}
        editor={editor}
        e2eIdentifiers="report-editor"
      />
    </div>
  );
};

export default observer(BulletListItemEditor);
