import { Component } from "react";
import { NonIdealState } from "@blueprintjs/core";
import { datadogRum } from "@datadog/browser-rum";

import { trackSegmentEvent } from "../../lib/Segment";

import "./ErrorBoundary.scss";

interface ErrorBoundaryProps {
  message?: string;
  onError?: (error: Error) => void;
  logErrors?: boolean;
  children?: any;
}

interface ErrorBoundaryState {
  hasError: boolean;
  errorName?: string;
}

export default class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: any) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: Error) {
    return { hasError: true, errorName: error?.message };
  }

  componentDidCatch(error: any, errorInfo?: any) {
    // You can also log the error to an error reporting service
    console.error(`Caught an uncaught error!`);
    console.debug(error);
    if (errorInfo) {
      console.debug(errorInfo);
    }

    if (this.props.logErrors) {
      datadogRum.addError(error);
      trackSegmentEvent("hard-crash", error);
    }
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className="error-boundary">
          <NonIdealState
            icon="warning-sign"
            title={this.props.message ?? "Something went wrong"}
            description={this.props.logErrors ? "Your error log has been submitted to Rollup" : ""}
          />
        </div>
      );
    }

    return this.props.children;
  }
}
