import { types } from "mobx-state-tree";

import { RollupEditorType } from "@rollup-types/editor";

export type IReportBlockMetadata = IReportBlockCheckboxMetadata | IReportBlockImageMetadata;

export interface IReportBlockCheckboxMetadata {
  type: RollupEditorType.checklist;
  checked: boolean;
}

export interface IReportBlockImageMetadata {
  type: RollupEditorType.image;
  imageUrl: string;
}

export const ReportBlockMetadata = types.union(
  types.model({
    type: types.literal(RollupEditorType.checklist),
    checked: types.optional(types.boolean, false),
  }),
  // currently not used, added just for the sake of example/future-proofing
  types.model({
    type: types.literal(RollupEditorType.image),
    imageUrl: types.optional(types.string, ""),
  })
);

export const isChecklistMetadata = (metadata?: IReportBlockMetadata): metadata is IReportBlockCheckboxMetadata => {
  return metadata?.type === RollupEditorType.checklist;
};
