import { cast, Instance, IType, SnapshotIn, SnapshotOut, types } from "mobx-state-tree";

import { IUpdateCustomUnitDto, MathJsPrefix } from "@rollup-api/models/customUnits";
import { updateCustomUnit } from "@rollup-api/utils";
import { applySanitizedSnapshot } from "@utilities/MobxUtils";

export const CustomUnitStore = types
  .model("CustomUnit", {
    id: types.identifier,
    label: types.string,
    prefix: types.optional(types.enumeration("Prefix", Object.values(MathJsPrefix)), MathJsPrefix.None),
    offset: types.optional(types.string, ""),
    aliases: types.array(types.string),
    orderIndex: types.optional(types.number, 0),
    definition: types.optional(types.string, ""),
    createdAt: types.optional(types.number, Date.now()),
    createdBy: types.maybeNull(types.string),
    updatedAt: types.optional(types.number, Date.now()),
    updatedBy: types.maybeNull(types.string),
  })
  .actions(self => ({
    sendUpdate(updateDto: IUpdateCustomUnitDto) {
      return updateCustomUnit(self.id, updateDto);
    },
    patch(update: IUpdateCustomUnitDto) {
      applySanitizedSnapshot(self, update, ["id", "createdAt", "createdBy"]);
    },
    setLabel(label: string) {
      if (!label) {
        return;
      }
      self.label = label;
      this.sendUpdate({ label });
    },
    setPrefix(prefix: MathJsPrefix) {
      self.prefix = prefix;
      this.sendUpdate({ prefix });
    },
    setOffset(offset: string) {
      self.offset = offset;
      this.sendUpdate({ offset });
    },
    setDefinition(definition: string) {
      self.definition = definition;
      this.sendUpdate({ definition });
    },
    addAlias(alias: string) {
      if (!alias || self.aliases.includes(alias)) {
        return;
      }
      self.aliases.push(alias);
      this.sendUpdate({ aliases: self.aliases });
    },
    deleteAlias(alias: string) {
      if (!self.aliases.includes(alias)) {
        return;
      }
      self.aliases = cast(self.aliases.filter(a => a !== alias));
      this.sendUpdate({ aliases: self.aliases });
    },
  }));

export interface ICustomUnit extends Instance<typeof CustomUnitStore> {}
interface ICustomUnitSnapshotIn extends SnapshotIn<typeof CustomUnitStore> {}
interface ICustomUnitSnapshotOut extends SnapshotOut<typeof CustomUnitStore> {}
export interface ICustomUnitMobxType extends IType<ICustomUnitSnapshotIn, ICustomUnitSnapshotOut, ICustomUnit> {}
