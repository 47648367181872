import { Tag } from "@blueprintjs/core";
import { observer } from "mobx-react";
import { isAlive } from "mobx-state-tree";

import { Tooltip } from "@components/Tooltip";

import { TCellRendererProps } from "../types";

import styles from "./VersionCell.module.scss";

const VersionCell = (props: TCellRendererProps) => {
  const catalogItemId = isAlive(props.data?.catalogItem) ? props.data?.catalogItem.id : "";

  if (!catalogItemId) {
    return null;
  }

  const revName = props.data?.catalogItem.revisionName;
  const revCode = props.data?.catalogItem.revisionCode;
  const nameContent = revName ? `Name: ${revName} & ` : "";
  const codeContent = revCode ? `Code: ${revCode}` : "";
  const tooltipContent = nameContent + codeContent;

  return (
    <Tooltip className={styles.versionCellTooltip} content={tooltipContent}>
      <Tag minimal className={styles.versionCellTag}>
        {props.data?.catalogItem.version}
      </Tag>
    </Tooltip>
  );
};

export default observer(VersionCell);
