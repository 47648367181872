import { DataSource } from "@rollup-api/models/data-sources";
import { IDataConnection } from "@store/DataConnection/DataConnectionModuleStore";
import { IDataSink } from "@store/DataConnection/DataSinkStore";

export const isDataSink = (dataConnection?: IDataConnection): dataConnection is IDataSink => {
  return !!dataConnection && "entries" in dataConnection;
};

export const isDataSource = (dataConnection?: IDataConnection): dataConnection is DataSource => {
  return !isDataSink(dataConnection);
};
