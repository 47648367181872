import { ChangeEvent, useState } from "react";
import { useNavigate } from "react-router-dom";
import { InputGroup, Intent, Radio } from "@blueprintjs/core";
import { observer } from "mobx-react";

import emptyWorkspaceTemplate from "@assets/workspaces/empty-workspace-template.json";
import engineeringWorkspaceTemplate from "@assets/workspaces/engineering-workspace-template.json";
import reports from "@assets/workspaces/reports.json";
import { Button } from "@components/Button";
import { showToast } from "@components/UiLayers/toaster";
import appStore from "@store/AppStore";
import { IWorkspaceSnapshotIn } from "@store/WorkspaceStore";
import { rollupClient } from "src/core/api";
import { Text, TextVariant } from "src/ui/Text";

import { DialogLegacy, DialogProps } from "./index";

import "./DialogAddNewWorkspace.scss";

/**
 * Dialog for adding new workspace.
 */

/** Type defs. */
type DialogAddNewWorkspaceProps = DialogProps & {
  onClose: () => void;
};

enum NewWorkspaceType {
  Engineering = "Engineering",
  Blank = "Blank",
}

/** Main function. */
const DialogAddNewWorkspace = ({ onClose, ...rest }: DialogAddNewWorkspaceProps) => {
  const [workspaceLabel, setWorkspaceLabel] = useState("");
  const [newWorkspaceType, setNewWorkspaceType] = useState(NewWorkspaceType.Engineering);
  const [isCreating, setIsCreating] = useState(false);

  const navigate = useNavigate();

  const resetInputValue = () => setWorkspaceLabel("");
  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => setWorkspaceLabel(event.target.value);

  const handleClose = () => {
    onClose?.();
    resetInputValue();
  };

  const handleCreateClicked = async () => {
    if (!workspaceLabel?.length) {
      return;
    }

    setIsCreating(true);
    const isEngineeringWorkspace = newWorkspaceType === NewWorkspaceType.Engineering;
    const template = isEngineeringWorkspace ? engineeringWorkspaceTemplate : emptyWorkspaceTemplate;
    try {
      const reportsCreated = await rollupClient.reports.list();
      const hasDefaultTemplates = reportsCreated.data.some(report => report.isDefaultTemplate);
      const workspaceData = hasDefaultTemplates ? template : { ...template, ...reports };
      const id = await appStore.setNewWorkspaceFromExternal(workspaceData as IWorkspaceSnapshotIn);
      if (id) {
        appStore.workspaceModel?.setLabel(workspaceLabel || "Untitled Workspace");
        navigate(`/workspaces/${id}`);
      }
    } catch (error) {
      console.error(error);
      showToast("Error while creating workspace", "danger", "error");
    }
    setIsCreating(false);
    handleClose();
  };

  const engineeringWorkspaceRadioBox = () => {
    return (
      <div className="radioBoxContent">
        <Radio
          disabled={isCreating}
          value="engineering"
          checked={newWorkspaceType === NewWorkspaceType.Engineering}
          onClick={() => setNewWorkspaceType(NewWorkspaceType.Engineering)}
        >
          <Text variant={TextVariant.H4} className="inline-block">
            Engineering Workspace
          </Text>
        </Radio>
        <Text variant={TextVariant.Caption}>These workspaces come pre-populated with common attributes.</Text>
        <div className="flex flex-row gap-1">
          <div className="w-36">
            <Text variant={TextVariant.Label}>Properties</Text>
            <ul className="list-disc list-inside p-0 pb-1 m-0">
              <li>
                <Text variant={TextVariant.Caption}>Mass</Text>
              </li>
              <li>
                <Text variant={TextVariant.Caption}>Volume</Text>
              </li>
              <li>
                <Text variant={TextVariant.Caption}>Cost</Text>
              </li>
              <li>
                <Text variant={TextVariant.Caption}>Power</Text>
              </li>
            </ul>
          </div>
          <div className="w-36">
            <Text variant={TextVariant.Label}>Status</Text>
            <ul className="list-disc list-inside p-0 pb-1 m-0">
              <li>
                <Text variant={TextVariant.Caption}>Lead</Text>
              </li>
              <li>
                <Text variant={TextVariant.Caption}>Delivery Date</Text>
              </li>
              <li>
                <Text variant={TextVariant.Caption}>Part no.</Text>
              </li>
              <li>
                <Text variant={TextVariant.Caption}>Power</Text>
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  };

  const blankWorkspaceRadioBox = () => {
    return (
      <div className="radioBoxContent">
        <Radio
          disabled={isCreating}
          value="blank"
          checked={newWorkspaceType === NewWorkspaceType.Blank}
          onClick={() => setNewWorkspaceType(NewWorkspaceType.Blank)}
        >
          <Text variant={TextVariant.H4} className="inline-block">
            Blank Workspace
          </Text>
        </Radio>
        <Text variant={TextVariant.Caption}>No pre-populated attributes.</Text>
      </div>
    );
  };

  return (
    <DialogLegacy
      className="dialog-add-new-workspace"
      title={<Text variant={TextVariant.H2}>Add new workspace</Text>}
      onClose={handleClose}
      isCloseButtonShown
      {...rest}
    >
      <div className="dialogBody">
        <div className="nameInput">
          <Text>Workspace label</Text>
          <InputGroup
            autoFocus
            required
            disabled={isCreating}
            value={workspaceLabel}
            onChange={handleInputChange}
            large
            placeholder="Enter here"
            rightElement={
              workspaceLabel ? (
                <Button minimal icon="delete" onClick={resetInputValue} e2eIdentifiers={["dialog-add-new-workspace", "delete"]} />
              ) : undefined
            }
            onKeyDown={event => {
              if (event.key === "Enter") {
                handleCreateClicked();
              }
            }}
          />
        </div>
        <div className="radioBoxGroup">
          <Text>Workspace type</Text>
          {engineeringWorkspaceRadioBox()}
          {blankWorkspaceRadioBox()}
        </div>
        <div className="buttonGroup">
          <Button intent={Intent.NONE} disabled={isCreating} onClick={handleClose} e2eIdentifiers={["dialog-add-new-workspace", "cancel"]}>
            Cancel
          </Button>
          <Button
            loading={isCreating}
            intent={Intent.PRIMARY}
            onClick={handleCreateClicked}
            disabled={isCreating || !workspaceLabel?.length}
            e2eIdentifiers={["dialog-add-new-workspace", "create"]}
          >
            Create
          </Button>
        </div>
      </div>
    </DialogLegacy>
  );
};

/** Exports. */
export default observer(DialogAddNewWorkspace);
