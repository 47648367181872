import * as icons from "@assets/icons/thirdParty/devLanguages";
import { DevIconSvgName } from "@components/DevIconSvg/DevIconSvg.types";

interface IDevIconSvgProps {
  icon: DevIconSvgName;
  className?: string;
  size?: number;
}

export const DevIconSvg = (props: IDevIconSvgProps) => {
  const { icon, className, size } = props;
  const Icon = icons[`${icon}Icon`];

  return <Icon className={className} style={{ width: size, height: size }} />;
};
