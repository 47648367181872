import { Icon, IconName, MaybeElement } from "@blueprintjs/core";

interface IndicatorIconProps {
  icon: IconName | MaybeElement;
  showIndicator: boolean;
}

import classNames from "classnames";

import styles from "./IndicatorIcon.module.scss";

const IndicatorIcon = ({ icon, showIndicator }: IndicatorIconProps) => {
  return (
    <div className={styles.indicatorIcon}>
      <Icon icon={icon} className={classNames({ [styles.indicatorIconIndicator]: showIndicator })} />
    </div>
  );
};

export default IndicatorIcon;
