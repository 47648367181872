import { observer } from "mobx-react";

import { Icon } from "@components/Icon";
import { EntityType } from "@store/types";
import { getDocumentIcon } from "@utilities/Icons";

interface IDocumentIconProps {
  entityType?: EntityType;
}

const DocumentIcon = (props: IDocumentIconProps) => {
  const { entityType } = props;
  const icon = getDocumentIcon(entityType);

  return icon ? <Icon icon={icon} /> : null;
};

export default observer(DocumentIcon);
