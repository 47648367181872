import { Icon, NonIdealState } from "@blueprintjs/core";

import { Button } from "@components/Button";
import { FILE_IMPORT_LIMIT } from "@components/CreateCatalogItemDialog/constants";
import { CatalogItemCsvCreationStep } from "@components/CreateCatalogItemDialog/Modes/Csv/Csv";
import FileDropZone, { EFileFormat } from "@components/FileDropZone";
import { formatFileSize } from "@utilities/Formats";

type Props = {
  file: File | null;
  setFile: (file: File | null) => void;
  onStepChange: (step: CatalogItemCsvCreationStep) => void;
};

const FilePanel = (props: Props) => {
  const { file, setFile, onStepChange } = props;

  const handleFileDrop = (fileList: FileList) => {
    if (fileList.length) {
      setFile(fileList[0]);
      onStepChange(CatalogItemCsvCreationStep.Columns);
    } else {
      setFile(null);
    }
  };

  const renderFileContent = () => {
    if (!file) {
      return (
        <NonIdealState
          icon="paperclip"
          iconSize={20}
          title="Select CSV file up to 100MB to upload"
          description="click or drag and drop it here"
        />
      );
    }

    return (
      <div className="file-panel--file">
        <div className="flex">
          <Icon size={20} className="file-panel--file-icon" icon="document" />
          <div>
            <div className="file-panel--file-name">{file.name}</div>
            <div className="file-panel--file-size">{formatFileSize(file.size)}</div>
          </div>
        </div>
        <Button minimal icon="trash" onClick={() => setFile(null)} e2eIdentifiers="delete" />
      </div>
    );
  };

  return (
    <FileDropZone sizeLimit={FILE_IMPORT_LIMIT} format={[EFileFormat.CSV]} clickToSelect onChange={handleFileDrop}>
      {renderFileContent}
    </FileDropZone>
  );
};

export default FilePanel;
