import { UserRole } from "@rollup-api/api/authTypes";

import { EntityModelBase } from "../../shared/entityModelBase";

export class Profile extends EntityModelBase {
  public readonly active!: boolean;
  public readonly email!: string;
  public readonly name?: string;
  public readonly avatarUrl?: string;
  public readonly mostRecentWorkspace?: string;
  public readonly roles?: UserRole[];
  public readonly teams?: string[];
  public readonly lastActiveTime?: number;
  public readonly jobTitle?: string;
  public readonly department?: string;
  public readonly hasPassword!: boolean;
}

export const EmptyUserProfile: Profile = {
  id: "",
  active: false,
  email: "",
  name: "",
  avatarUrl: "",
  mostRecentWorkspace: "",
  roles: [],
  teams: [],
  lastActiveTime: 0,
  jobTitle: "",
  department: "",
  createdAt: "",
  updatedAt: "",
  hasPassword: false,
};
