import { useEffect, useState } from "react";
import { Select } from "@blueprintjs/select";
import { SelectItem } from "@ui/Select/Item/SelectItem";
import { observer } from "mobx-react";

import { Button } from "@components/Button";
import { LeadPeriod, leadPeriodLabel } from "@components/CreateCatalogItemDialog/types";
import { periodToText } from "@components/CreateCatalogItemDialog/utils";
import PositiveNumberInput from "@components/PositiveNumberInput";

import styles from "./CatalogItemLeadTimePicker.module.scss";

type Props = {
  onLeadTimeChange: (leadTimeString: string) => void;
  defaultValue?: string | null; // example values: P3M | P1W | P1D | P1Y
  disabled?: boolean;
  hideLabel?: boolean;
};

const CatalogItemLeadTimePicker = (props: Props) => {
  const { defaultValue, onLeadTimeChange, disabled, hideLabel } = props;
  const [leadPeriod, setLeadPeriod] = useState<LeadPeriod>(LeadPeriod.Days);
  const [leadPeriodAmount, setLeadPeriodAmount] = useState<number>();

  useEffect(() => {
    if (!defaultValue) {
      return;
    }

    const duration = periodToText(defaultValue).split(" ");
    const periodAmount = Number(duration[0]);
    const period = (duration[1]?.at(0)?.toUpperCase() || LeadPeriod.Days) as LeadPeriod;
    setLeadPeriodAmount(periodAmount || 1);
    setLeadPeriod(period || LeadPeriod.Days);
  }, [defaultValue]);

  useEffect(() => {
    if (leadPeriod && leadPeriodAmount) {
      onLeadTimeChange(`P${leadPeriodAmount}${leadPeriod}`);
    } else {
      onLeadTimeChange("");
    }
  }, [onLeadTimeChange, leadPeriod, leadPeriodAmount]);

  return (
    <div>
      {!hideLabel && <span className={styles.catalogItemLeadTimePickerLabel}>Lead time</span>}
      <div className={styles.catalogItemLeadTimePicker}>
        <PositiveNumberInput
          disabled={disabled}
          className={styles.catalogItemLeadTimePickerQuantity}
          value={leadPeriodAmount}
          onChange={setLeadPeriodAmount}
        />
        <Select<LeadPeriod>
          disabled={disabled}
          filterable={false}
          items={Object.values(LeadPeriod)}
          popoverProps={{ minimal: true, className: styles.catalogItemLeadTimePickerPeriod }}
          onItemSelect={setLeadPeriod}
          itemRenderer={(item, { handleClick }) => (
            <SelectItem slug={item} key={item} label={`${leadPeriodLabel[item]}s`} onClick={handleClick} active={leadPeriod === item} />
          )}
        >
          <Button
            key={leadPeriod}
            disabled={disabled}
            fill
            alignText="left"
            text={`${leadPeriodLabel[leadPeriod]}s`}
            rightIcon="chevron-down"
            className={styles.catalogItemLeadTimePickerButton}
            e2eIdentifiers="select-lead-period-button"
          />
        </Select>
      </div>
    </div>
  );
};

export default observer(CatalogItemLeadTimePicker);
