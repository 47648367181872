import { useHandleDeleteDocument } from "@hooks/useHandleDeleteDocument";
import { useWorkspace } from "@hooks/useWorkspace";
import { observer } from "mobx-react";

import { EntityContextMenu } from "@components/EntityContextMenu";
import { EPageName } from "@router/hooks/useAppNavigate";
import appStore from "@store/AppStore";
import { IBomTable } from "@store/BomTable/BomTableStore";
import { EntityType } from "@store/types";

interface IBomTablesSidebarContextMenuProps {
  bomTable: IBomTable;
  onEnter?(): void;
}

const BomTablesSidebarContextMenu = (props: IBomTablesSidebarContextMenuProps) => {
  const { bomTable, onEnter } = props;
  const workspace = useWorkspace();

  const handleDelete = useHandleDeleteDocument({
    document: bomTable,
    docArray: workspace.bomTables,
    pageName: `${EPageName.Pdm}/${EPageName.Bom}`,
    deleteDocument: appStore.workspaceModel?.deleteBomTable,
  });

  const handleUpdate = (label: string) => {
    bomTable.setLabel(label);
  };

  return (
    <EntityContextMenu
      node={bomTable}
      entityType={EntityType.BomTable}
      defaultName={bomTable.label}
      onNameChange={handleUpdate}
      onDelete={handleDelete}
      onEnter={onEnter}
    />
  );
};

export default observer(BomTablesSidebarContextMenu);
