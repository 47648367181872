import { ChangeEventHandler, useEffect, useState } from "react";
import { HTMLSelect, Intent, NonIdealState } from "@blueprintjs/core";
import { DataSourceQOFactory } from "@queries/DataSourceQOFactory";
import { useQuery } from "@tanstack/react-query";
import { observer } from "mobx-react";
import { useDebounceValue } from "usehooks-ts";

import { Button } from "@components/Button";
import { DataSourceValueUtils } from "@components/DataSources/DataSourceValueUtils";
import { PreviewGrid, PreviewGridSize } from "@components/DataSources/QueryDataSourceDialog/PreviewGrid";
import { openDataSourceLink } from "@components/DataSources/utils";
import { FormGroup } from "@components/FormGroup";
import { InputGroup } from "@components/InputGroup";
import DismissibleCallout from "@components/Shared/DismissibleCallout/DismissibleCallout";
import { IntegrationProvider } from "@rollup-api/models";
import { DataSource } from "@rollup-api/models/data-sources/data-source.model";
import { rollupClient } from "src/core/api";
import { Text, TextVariant } from "src/ui/Text";

import "./QueryDataSourceContainer.scss";

type QueryDataSourceContainerProps = {
  dataSource: DataSource;
  selectedSheet: string;
  queryString: string;
  initialPreviewRange?: string;
  gridSize?: PreviewGridSize;
  onQueryChanged: (query: string) => void;
  onSheetChanged: (sheet: string) => void;
};

const defaultPreviewRange = "A1:J10";
export const QueryDataSourceContainer = observer(function QueryDataSourceContainer({
  dataSource,
  selectedSheet,
  gridSize,
  initialPreviewRange = defaultPreviewRange,
  onQueryChanged,
  onSheetChanged,
  queryString,
}: QueryDataSourceContainerProps) {
  const [previewRange, setPreviewRange] = useState(initialPreviewRange);
  const [debouncedQueryString] = useDebounceValue(queryString, 100);
  const [debouncedPreviewString] = useDebounceValue(previewRange, 100);

  const [sheetNames, setSheetNames] = useState<string[]>([]);

  useEffect(() => {
    setSheetNames([]);
    if (dataSource?.provider === IntegrationProvider.Google && dataSource.metadata?.fileId) {
      rollupClient.integrations.fetchSheetList(dataSource.metadata.fileId).then(res => {
        setSheetNames(res.data);
      });
    }
  }, [dataSource]);

  useEffect(() => {
    // Clear query string when data source changes
    setPreviewRange(initialPreviewRange);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataSource]);

  const previewQuery = `'${selectedSheet}'!${debouncedPreviewString}`;
  const { isLoading, data: queryResult, error } = useQuery(DataSourceQOFactory.createGetValueQO(dataSource.id, debouncedQueryString));
  const {
    isLoading: isPreviewLoading,
    data: previewResult,
    error: previewError,
  } = useQuery(DataSourceQOFactory.createGetValueQO(dataSource.id, previewQuery));

  if (dataSource?.provider !== IntegrationProvider.Google) {
    return (
      <div>
        <NonIdealState
          icon="warning-sign"
          title="Unsupported Data Source"
          description="Only Google Sheets data sources are supported for querying."
        />
      </div>
    );
  }

  const helperText = DataSourceValueUtils.getHelperText(debouncedQueryString, isLoading, queryResult, error);
  const intent = DataSourceValueUtils.getIntent(queryResult);

  const handleSheetSelected: ChangeEventHandler<HTMLSelectElement> = ev => {
    const value = ev.target.value;
    if (value) {
      onSheetChanged(value === selectedSheet?.[0] ? "" : value);
    }
  };

  return (
    <div className="query-data-source-container">
      <div className="query-data-source-container--preview-row">
        <div className="query-data-source-container--top-left-container">
          <HTMLSelect disabled={!sheetNames?.length} onChange={handleSheetSelected} value={selectedSheet} defaultValue={selectedSheet?.[0]}>
            {sheetNames.length ? (
              sheetNames.map((name, index) => (
                <option key={`${index}-${name}`} value={name}>
                  {name}
                </option>
              ))
            ) : (
              <option value="">Loading sheets..</option>
            )}
          </HTMLSelect>
          <Button onClick={() => openDataSourceLink(dataSource)} icon="share" e2eIdentifiers="open-google-sheet" minimal>
            Open Google sheet
          </Button>
        </div>
        <FormGroup label="Preview range" inline>
          <InputGroup
            autoComplete="off"
            autoCorrect="off"
            autoCapitalize="off"
            placeholder="Enter preview range"
            value={previewRange}
            onChange={e => setPreviewRange(e.target.value)}
            e2eIdentifiers="preview-range"
          />
        </FormGroup>
      </div>
      {previewError ? (
        <NonIdealState icon="warning-sign" title="Error Loading Preview" description={previewError.message} />
      ) : (
        <PreviewGrid
          isLoading={isPreviewLoading}
          result={previewResult}
          viewRange={previewRange}
          onQueryChanged={onQueryChanged}
          size={gridSize}
        />
      )}
      <DismissibleCallout icon="info-sign" intent="primary" title="Construct a data query" calloutKey="data-source-hint">
        <Text variant={TextVariant.Body}>
          Enter a query string in&nbsp;
          <a href="https://developers.google.com/sheets/api/guides/concepts#expandable-1" target="_blank" rel="noreferrer">
            A1 notation
          </a>
          &nbsp;or select a 1x1 or 1x2 region from the preview grid. Query values can be copied to the clipboard as a new data link for use
          in property expressions.
        </Text>
      </DismissibleCallout>
      <div className="query-data-source-container--selection-row">
        <FormGroup
          helperText={intent === Intent.SUCCESS ? "Valid range" : (helperText ?? "Pick a range")}
          intent={intent}
          inline
          label="Cell selection:"
        >
          <InputGroup
            fill
            intent={intent}
            autoFocus
            autoComplete="off"
            autoCorrect="off"
            autoCapitalize="off"
            placeholder="Enter query string"
            value={queryString}
            onChange={ev => onQueryChanged(ev.target.value)}
            e2eIdentifiers="query-string"
          />
        </FormGroup>
      </div>
    </div>
  );
});
