import { Menu } from "@blueprintjs/core";
import { observer } from "mobx-react";

import { MenuItem } from "@components/MenuItem";
import { IStatusDefinition, StatusType } from "@store/StatusDefinitionStore";

import { STATUS_MENU_ITEMS } from "./constants";

type StatusDefinitionMenuProps = {
  statusDefinition: IStatusDefinition;
  onTypeClick: (type: StatusType) => void;
};

export const StatusDefinitionMenu = observer(({ statusDefinition, onTypeClick }: StatusDefinitionMenuProps) => {
  return (
    <Menu>
      {STATUS_MENU_ITEMS.map(({ statusType, ...props }) => (
        <MenuItem
          {...props}
          key={statusType}
          selected={statusDefinition.type === statusType}
          onClick={() => onTypeClick(statusType)}
          e2eIdentifiers={["status-menu", props.text.toLowerCase()]}
        />
      ))}
    </Menu>
  );
});
