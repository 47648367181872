import { Callout, Icon } from "@blueprintjs/core";
import { EvaluatedExpression } from "@rollup-io/engineering";
import classNames from "classnames";
import { observer } from "mobx-react";

import {
  ExpressionReferenceType,
  IExpressionReference,
} from "@components/Shared/ScalarExpressionEditor/ExpressionInfoOverlay/ExpressionInfoReferences/ExpressionInfoReferences.types";
import { useAppNavigate } from "@router/hooks/useAppNavigate";
import { joinStrings } from "@utilities";

import {
  getReferenceData,
  toAnalysisOutputExpressionReference,
  toDataLinkExpressionReference,
  toDataSinkEntryExpressionReference,
  toPropertyExpressionReference,
} from "./ExpressionInfoReferences.utils";

import styles from "./ExpressionInfoReferences.module.scss";

interface IExpressionInfoReferencesProps {
  evaluatedExpression: EvaluatedExpression;
  className?: string;
  labelClassName?: string;
}

const ExpressionInfoReferences = (props: IExpressionInfoReferencesProps) => {
  const { evaluatedExpression, className, labelClassName } = props;
  const { analysisOutputs = [], dataLinks, dataSinkEntries = [], propertyInstances } = evaluatedExpression;
  const { navigateToProperty, navigateToDataConnection, navigateToCodeBlock } = useAppNavigate();

  const references: IExpressionReference[] = [
    ...propertyInstances.map(toPropertyExpressionReference),
    ...analysisOutputs.map(toAnalysisOutputExpressionReference),
    ...dataLinks.map(toDataLinkExpressionReference),
    ...dataSinkEntries.map(toDataSinkEntryExpressionReference),
  ];

  if (!references.length) {
    return null;
  }

  const handleLabelMouseDown = (reference: IExpressionReference) => {
    switch (reference.type) {
      case ExpressionReferenceType.PropertyInstance:
        navigateToProperty(reference.item.id);
        break;
      case ExpressionReferenceType.DataLink:
        navigateToDataConnection(reference.item.dataSourceId);
        break;
      case ExpressionReferenceType.DataSinkEntry:
        navigateToDataConnection(reference.item.dataSinkId);
        break;
      case ExpressionReferenceType.AnalysisOutput:
        navigateToCodeBlock(reference.item.codeBlockId);
        break;
    }
  };

  const renderReference = (reference: IExpressionReference) => {
    const referenceData = getReferenceData(reference);

    if (!referenceData) {
      return null;
    }

    const { label, value, icon } = referenceData;

    return (
      <div className={styles.expressionInfoReferencesItemContent}>
        <div className={styles.expressionInfoReferencesDataLinkLabel}>
          {icon}
          <div className={styles.expressionInfoReferencesLink} onMouseDown={() => handleLabelMouseDown?.(reference)}>
            {label}
          </div>
        </div>
        <div>=</div>
        <div>{joinStrings(value, " ")}</div>
      </div>
    );
  };

  return (
    <Callout className={classNames(styles.expressionInfoReferences, className)}>
      <div className={labelClassName}>
        <Icon icon="one-to-one" intent="primary" size={14} />
        <div>References</div>
      </div>
      <ol className={styles.expressionInfoReferencesList}>
        {references.map((reference, index) => {
          return (
            <li key={reference.item.id} className={styles.expressionInfoReferencesItem}>
              <div>{index + 1}.</div>
              {renderReference(reference)}
            </li>
          );
        })}
      </ol>
    </Callout>
  );
};

export default observer(ExpressionInfoReferences);
