import { MenuDivider } from "@blueprintjs/core";
import { observer } from "mobx-react";

import { FormGroup } from "@components/FormGroup";
import { IBlock } from "@store/BlockStore";

import { MultiplicityInput } from "../MultiplicityInput";

type Props = {
  block: IBlock;
};

const MultiplicityNavItem = (props: Props) => {
  const { block } = props;

  return (
    <>
      <MenuDivider />
      <FormGroup label="Multiplicity">
        <MultiplicityInput small block={block} />
      </FormGroup>
    </>
  );
};

export default observer(MultiplicityNavItem);
