export const getIncrementedName = <T>(name: string, names: Map<string, T>): string => {
  const lowerCaseName = name.toLowerCase();
  let counter = 0;

  do {
    counter += 1;
  } while (names.has(`${lowerCaseName} ${counter}`));

  return `${name} ${counter}`;
};
