import { IconName, Intent, MaybeElement } from "@blueprintjs/core";
import classNames from "classnames";
import { observer } from "mobx-react";

import { Button } from "@components/Button";
import { IE2EIdentifiersParam } from "@utilities/E2EUtils";

import "./StepButtons.scss";

export interface IStepButton extends IE2EIdentifiersParam {
  label: string;
  icon?: IconName | MaybeElement;
  intent?: Intent;
  minimal?: boolean;
  disabled?: boolean;
  onClick?(): void;
}

interface IStepButtonsProps {
  rightButton: IStepButton;
  leftButton?: IStepButton;
}

const StepButtons = (props: IStepButtonsProps) => {
  const { rightButton, leftButton } = props;

  return (
    <div className={classNames("step-buttons", { ["step-buttons--no-left-btn"]: !leftButton })}>
      {leftButton && (
        <Button
          className="step-buttons--btn"
          onClick={leftButton.onClick}
          intent={leftButton.intent}
          icon={leftButton.icon}
          e2eIdentifiers={leftButton.e2eIdentifiers}
          minimal={leftButton.minimal}
          disabled={leftButton.disabled}
        >
          {leftButton.label}
        </Button>
      )}
      <Button
        className="step-buttons--btn"
        onClick={rightButton.onClick}
        intent={rightButton.intent}
        icon={rightButton.icon}
        minimal={rightButton.minimal}
        disabled={rightButton.disabled}
        e2eIdentifiers={rightButton.e2eIdentifiers}
      >
        {rightButton.label}
      </Button>
    </div>
  );
};

export default observer(StepButtons);
