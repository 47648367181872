import { useEffect } from "react";
import classNames from "classnames";
import { observer } from "mobx-react";

import { SafeTooltip } from "@components/SmallComponents";
import { IRequirementBlock, VerificationStatus } from "@store/RequirementsStore";
import { validateRequirement } from "@utilities";

import styles from "./VerificationStatusCell.module.scss";

type Props = {
  requirementBlock: IRequirementBlock;
};

const VerificationStatusCell = (props: Props) => {
  const { requirementBlock } = props;

  useEffect(() => {
    validateRequirement(requirementBlock);
  }, [requirementBlock]);

  const renderPopoverContent = () => {
    return (
      requirementBlock.validationErrorMessage && (
        <div className={styles.verificationStatusCellError}>{requirementBlock.validationErrorMessage}</div>
      )
    );
  };

  return (
    <SafeTooltip
      className={classNames(styles.verificationStatusCell, {
        [styles.verificationStatusCellPending]: requirementBlock.verificationStatus === VerificationStatus.Pending,
        [styles.verificationStatusCellPass]: requirementBlock.verificationStatus === VerificationStatus.Pass,
        [styles.verificationStatusCellFail]: requirementBlock.verificationStatus === VerificationStatus.Fail,
      })}
      content={renderPopoverContent()}
      disabled={!requirementBlock.validationErrorMessage}
    >
      {requirementBlock.verificationStatus}
    </SafeTooltip>
  );
};

export default observer(VerificationStatusCell);
