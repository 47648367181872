import { MouseEvent, useMemo, useState } from "react";
import { observer } from "mobx-react-lite";
import { useDebounceValue } from "usehooks-ts";

import { MenuItem } from "@components/MenuItem";
import { IE2EIdentifiersParam, mergeE2EIdentifiers } from "@utilities/E2EUtils";

import { FilterableMenu } from "../FilterableMenu";

import "./SyncFilterableMenu.scss";

interface IFilterableItem {
  id: string;
  label: string;
}

interface ISyncFilterableMenuProps<T extends IFilterableItem> extends IE2EIdentifiersParam {
  items: T[];
  title?: string;
  placeholder?: string;
  queryDebounceTime?: number;
  onSelect(item: T, e: MouseEvent): void;
  renderItem?(item: T): void;
}

const SyncFilterableMenu = <T extends IFilterableItem>(props: ISyncFilterableMenuProps<T>) => {
  const { items, queryDebounceTime = 500, placeholder = "Search", title, e2eIdentifiers } = props;
  const { onSelect, renderItem } = props;
  const [queryString, setQueryString] = useState("");
  const [debouncedQueryString] = useDebounceValue(queryString, queryDebounceTime);

  const filteredItems = useMemo(() => {
    const lowerCaseQueryString = debouncedQueryString.toLowerCase();
    return items.filter(item => item.label.toLowerCase().includes(lowerCaseQueryString));
  }, [debouncedQueryString, items]);

  return (
    <FilterableMenu title={title} queryString={queryString} setQueryString={setQueryString} placeholder={placeholder}>
      {filteredItems.map(item => (
        <MenuItem
          key={item.id}
          textClassName="sync-filterable-menu--menu-item"
          text={renderItem?.(item) ?? item.label}
          onClick={e => onSelect(item, e)}
          e2eIdentifiers={mergeE2EIdentifiers(e2eIdentifiers, item.id)}
        />
      ))}
    </FilterableMenu>
  );
};

export default observer(SyncFilterableMenu);
