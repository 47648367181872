import { Icon, Intent, NonIdealState } from "@blueprintjs/core";
import { observer } from "mobx-react";

import { Button } from "@components/Button";
import { FILE_FORMATS, FILE_IMPORT_LIMIT } from "@components/CreateCatalogItemDialog/constants";
import FileDropZone from "@components/FileDropZone";
import appStore from "@store/AppStore";
import { formatFileSize } from "@utilities/Formats";

import "./Cad.scss";

const Cad = () => {
  const fileList = appStore.ui.cadFileUploadData?.fileList;
  const file = fileList?.[0] || null;

  const handleSubmit = async () => {
    if (file) {
      const dataTransfer = new DataTransfer();
      dataTransfer.items.add(file);
      appStore.orgModel.uploads.addNewFileUpload({
        files: dataTransfer.files,
        onUpload: appStore.orgModel.catalogItems.createCatalogItemFromFile,
        removeOnUpload: true,
        skipCadConversion: true,
      });
      appStore.ui.hideCatalogItemCreationModal();
    }
  };

  const handleFileDrop = (fileList: FileList) => {
    if (fileList.length) {
      appStore.ui.setCadFileUploadData({ fileList });
    } else {
      appStore.ui.setCadFileUploadData(null);
    }
  };

  const renderFileContent = () => {
    if (!file) {
      return (
        <NonIdealState
          icon="paperclip"
          iconSize={20}
          title="Select CAD file up to 100MB to upload"
          description="click or drag and drop it here"
        />
      );
    }

    return (
      <div className="file-panel--file">
        <div className="flex">
          <Icon size={20} className="file-panel--file-icon" icon="document" />
          <div>
            <div className="file-panel--file-name">{file.name}</div>
            <div className="file-panel--file-size">{formatFileSize(file.size)}</div>
          </div>
        </div>
        <Button minimal icon="trash" onClick={() => appStore.ui.setCadFileUploadData(null)} e2eIdentifiers="delete" />
      </div>
    );
  };

  return (
    <div className="cad-create">
      <div className="cad-create--content">
        <FileDropZone sizeLimit={FILE_IMPORT_LIMIT} format={FILE_FORMATS} clickToSelect onChange={handleFileDrop}>
          {renderFileContent}
        </FileDropZone>
      </div>
      <div className="cad-create--footer">
        <Button onClick={appStore.ui.hideCatalogItemCreationModal} large minimal e2eIdentifiers="cancel-catalog-item-creation">
          Cancel
        </Button>
        <Button
          disabled={!file}
          large
          style={{ width: 80 }}
          intent={Intent.PRIMARY}
          onClick={handleSubmit}
          e2eIdentifiers="cad-submit-catalog-item"
        >
          Submit
        </Button>
      </div>
    </div>
  );
};

export default observer(Cad);
