import { observer } from "mobx-react";
import { isAlive } from "mobx-state-tree";

import FormattedTableActionsCell from "@components/Modeling/FormattedTable/Cells/FormattedTableActionsCell";
import { TCellRendererProps } from "@components/Table";
import appStore from "@store/AppStore";
import { ICustomUnit } from "@store/CustomUnitStore";

const CustomUnitActionsCell = (props: TCellRendererProps<ICustomUnit>) => {
  const { data: customUnit, api, registerRowDragger } = props;

  const handleDeleteClick = () => {
    api.applyTransaction({ remove: [{ id: customUnit.id }] });
    appStore.orgModel.customUnits.delete(customUnit.id);
  };

  if (!customUnit || !isAlive(customUnit)) {
    return null;
  }

  return <FormattedTableActionsCell node={customUnit} onDelete={handleDeleteClick} registerRowDragger={registerRowDragger} />;
};

export default observer(CustomUnitActionsCell);
