import { useEffect, useState } from "react";
import { HTMLTable, Menu, MenuDivider, NonIdealState, Spinner } from "@blueprintjs/core";
import { observer } from "mobx-react";

import { ExecutionStatusIcon, ExecutionTypeIcon } from "@components/Analysis";
import { AnchorButton } from "@components/AnchorButton";
import { Button } from "@components/Button";
import { DeleteConfirmationDialog } from "@components/DeleteConfirmationDialog";
import { MenuItem } from "@components/MenuItem";
import { MenuItemDelete } from "@components/MenuItems";
import { Popover } from "@components/Popover";
import { showApiErrorToast } from "@components/UiLayers/toaster";
import { ExecutionEnvironment, ExecutionEnvironmentStatus } from "@rollup-api/models/execution-environments";
import appStore from "@store/AppStore";
import { rollupClient } from "src/core/api";
import { Text, TextVariant } from "src/ui/Text";

import AddNewExecutionEnvironment from "./AddNewExecutionEnvironment";
import TestExecutionDialog from "./TestExecutionDialog";

/** Main function. */
const SettingsExecutionEnvironments = () => {
  const [environments, setEnvironments] = useState<ExecutionEnvironment[]>();
  const [isNewEnvironmentScreenOpen, setIsNewEnvironmentScreenOpen] = useState(false);
  const [isTestEnvironmentDialogOpen, setIsTestEnvironmentDialogOpen] = useState(false);
  const [existingEnvironment, setExistingEnvironment] = useState<ExecutionEnvironment | undefined>(undefined);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [itemToDelete, setItemToDelete] = useState<ExecutionEnvironment>();
  const [isFetching, setIsFetching] = useState(false);

  useEffect(() => {
    if (!isNewEnvironmentScreenOpen) {
      setIsFetching(true);
      rollupClient.analysisModule.executionEnvironments
        .retrieveList()
        .then(res => {
          setIsFetching(false);
          setEnvironments(res.data);
        })
        .catch(error => {
          console.warn("Error fetching execution environments", error);
          showApiErrorToast("Error fetching execution environments", error);
        });
    }
  }, [isNewEnvironmentScreenOpen]);

  // Subscribe to changes in execution environments
  // TODO: handle realtime CRUD operations. For now we're only handling status updates to existing envs
  const orgId = appStore.orgModel?.info?.id;
  useEffect(() => {
    if (orgId) {
      appStore.realtimeService.subscribeToChanges(`organization/${orgId}/execution-environments`);
    }
    return () => {
      if (orgId) {
        appStore.realtimeService.unsubscribeFromChanges(`organization/${orgId}/execution-environments`);
      }
    };
  }, [orgId]);

  // Add handler for status updates (after environment is created, it goes from pending->creating->ready)
  useEffect(() => {
    const handleStatusUpdate = ({ id, status }: { id: string; status: ExecutionEnvironmentStatus }) => {
      const updatedEnvs = environments?.map(env => {
        if (env.id === id) {
          return { ...env, status: status };
        }
        return env;
      });
      setEnvironments(updatedEnvs);
    };

    const removeHandlerCallback = appStore.realtimeService.addEventHandler("execution-environment-status-updated", handleStatusUpdate);
    // Remove the handler when the effect is cleaned up
    return () => removeHandlerCallback();
  }, [environments]);

  const handleDelete = async () => {
    if (itemToDelete) {
      try {
        await rollupClient.analysisModule.executionEnvironments.delete(itemToDelete.id);
        setEnvironments(environments?.filter(env => env.id !== itemToDelete.id));
      } catch (error: any) {
        console.warn("Error deleting execution environment", error);
        showApiErrorToast("Error deleting execution environment", error);
      }
    }
    setIsDeleteDialogOpen(false);
  };

  const renderEnvironmentActions = (env: ExecutionEnvironment) => (
    <Menu>
      <MenuItem
        text="Test"
        icon="play"
        disabled={env.status !== ExecutionEnvironmentStatus.Ready}
        onClick={() => {
          setExistingEnvironment(env);
          setIsTestEnvironmentDialogOpen(true);
        }}
        e2eIdentifiers="test"
      />
      <MenuDivider />
      <MenuItem
        text="Edit"
        icon="edit"
        onClick={() => {
          setExistingEnvironment(env);
          setIsNewEnvironmentScreenOpen(true);
        }}
        e2eIdentifiers="edit"
      />

      <MenuItemDelete
        onDelete={() => {
          setItemToDelete(env);
          setIsDeleteDialogOpen(true);
        }}
      />
    </Menu>
  );

  const renderEnvironmentList = () => {
    return (
      <>
        <DeleteConfirmationDialog
          isOpen={isDeleteDialogOpen}
          titleItem="execution environment"
          descriptionItem="this execution environment"
          onCancel={() => setIsDeleteDialogOpen(false)}
          onClose={() => setIsDeleteDialogOpen(false)}
          onConfirm={handleDelete}
        />
        {existingEnvironment && isTestEnvironmentDialogOpen && (
          <TestExecutionDialog environment={existingEnvironment} onClose={() => setIsTestEnvironmentDialogOpen(false)} />
        )}

        <HTMLTable className="min-w-full">
          <thead>
            <tr>
              <th></th>
              <th>
                <Text variant={TextVariant.H5}>Label</Text>
              </th>
              <th>
                <Text variant={TextVariant.H5}>Description</Text>
              </th>
              <th>
                <Text variant={TextVariant.H5}>Base Image</Text>
              </th>
              <th></th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {isFetching && !environments?.length && (
              <tr>
                <td colSpan={6} className="message">
                  <NonIdealState icon={<Spinner />} title="Loading environments" />
                </td>
              </tr>
            )}
            {!isFetching && !environments?.length ? (
              <tr>
                <td colSpan={6} className="message">
                  <Text variant={TextVariant.Caption}>You don't have any execution environments</Text>
                </td>
              </tr>
            ) : (
              environments?.map(env => (
                <tr key={env.id}>
                  <td>
                    <ExecutionTypeIcon type={env.type} />
                  </td>
                  <td>
                    <Text variant={TextVariant.Code}>{env.label}</Text>
                  </td>
                  <td>
                    <Text variant={TextVariant.Body}>{env.description}</Text>
                  </td>
                  <td>
                    <Text variant={TextVariant.Code}>{env.baseImage}</Text>
                  </td>
                  <td>
                    <ExecutionStatusIcon status={env.status} />
                  </td>
                  <td>
                    <Popover placement="bottom-end" content={renderEnvironmentActions(env)}>
                      <AnchorButton minimal rightIcon="more" e2eIdentifiers="show-menu" />
                    </Popover>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </HTMLTable>
      </>
    );
  };

  const renderEnvironmentSettings = () => {
    return (
      <div className="settings-layout--content">
        <div className="settings-layout--header">
          <Text variant={TextVariant.H1}>Execution Environments</Text>
          <div className="settings-layout--header--right-section">
            <Button
              large
              intent="primary"
              icon="plus"
              className="settings-layout--cta"
              onClick={() => {
                setExistingEnvironment(undefined);
                setIsNewEnvironmentScreenOpen(true);
              }}
              e2eIdentifiers="add-new-environment"
            >
              Add execution environment
            </Button>
          </div>
        </div>
        <div className="settings-list-area">{renderEnvironmentList()}</div>
      </div>
    );
  };

  const renderAddNewEnvironment = () => {
    return (
      <AddNewExecutionEnvironment
        existingEnvironment={existingEnvironment}
        onClose={() => {
          setExistingEnvironment(undefined);
          setIsNewEnvironmentScreenOpen(false);
        }}
      />
    );
  };

  return <>{isNewEnvironmentScreenOpen ? renderAddNewEnvironment() : renderEnvironmentSettings()}</>;
};

/** Exports. */
export default observer(SettingsExecutionEnvironments);
