import { useEffect, useMemo, useRef, useState } from "react";
import { Classes, Icon, Intent, PopoverInteractionKind, PopoverPosition, Tooltip } from "@blueprintjs/core";
import { intentClass } from "@blueprintjs/core/lib/esnext/common/classes";
import { useClosePopoverOnClickOutside } from "@hooks/useClosePopoverOnClickOutside";
import { Editor, EditorEvents } from "@tiptap/core";
import classNames from "classnames";
import { observer } from "mobx-react";

import { AnchorButton } from "@components/AnchorButton";
import { Button } from "@components/Button";
import { EditorContent } from "@components/EditorContent";
import { IPopoverRefType, Popover } from "@components/Popover";
import { PropertyInfoTable } from "@components/Shared";
import { useScalarExpressionEditor } from "@components/Shared/ScalarExpressionEditor/useScalarExpressionEditor";
import { DataSource } from "@rollup-api/models/data-sources";
import appStore from "@store/AppStore";
import { IPropertyInstance } from "@store/PropertyInstanceStore";
import { evaluateSmartExpression } from "@utilities";
import { createDataTestId, ElementType } from "@utilities/E2EUtils";
import { getCaretPosition } from "@utilities/TipTap";

import { DataConnectionDropdown } from "./DataConnectionDropdown";
import { ExpressionInfoOverlay } from "./ExpressionInfoOverlay";
import { getExpressionValue, getParsedExpression, getReplaceRegex, getTooltipContent, openBracesRegex } from "./utils";

import "./ScalarExpressionEditor.scss";

type IScalarExpressionEditorProps = {
  propertyInstance: IPropertyInstance;
  className?: string;
  showInfoOverlayOnFocus?: boolean;
  enableWrappingOnFocus?: boolean;
  enableWrapping?: boolean;
  showRollupInfo?: boolean;
  autoFocus?: boolean;
  disableNotifications?: boolean;
  hideDetachButton?: boolean;
  // it will only be used for data connection dropdown
  allowMatchingTargetWidth?: boolean;
  popoverPosition?: PopoverPosition;
  onBlur?(value: string): void;
  onFocus?(): void;
  onCreate?(): void;
  onUpdate?(): void;
  onModEnterKeyPress?(): void;
};

export const scalarExpressionEditorInputClassName = "scalar-expression-editor--input";

export const ScalarExpressionEditor = observer(function ScalarExpressionEditor(props: IScalarExpressionEditorProps) {
  const { propertyInstance, className, showInfoOverlayOnFocus, autoFocus, disableNotifications } = props;
  const { enableWrappingOnFocus, enableWrapping, hideDetachButton, onModEnterKeyPress, onCreate, onUpdate } = props;
  const { allowMatchingTargetWidth, popoverPosition = PopoverPosition.BOTTOM_LEFT } = props;
  const [isFocused, setIsFocused] = useState(autoFocus ?? false);
  const [showDataConnectionDropdown, setShowDataConnectionDropdown] = useState(false);
  const [intent, setIntent] = useState<Intent>(Intent.NONE);
  const [caretPosition, setCaretPosition] = useState<number>();
  const shouldFocusAfterTransactionRef = useRef(false);
  const popoverRef = useRef<IPopoverRefType>(null);
  const popoverElementRef = useRef<HTMLElement>(null);
  const [selectedDataSource, setSelectedDataSource] = useState<DataSource>();
  const suggestOpen = useRef(false);
  const ref = useRef<HTMLDivElement>(null);
  const newResult = evaluateSmartExpression(propertyInstance.value, propertyInstance)?.calculatedResult;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const blurredContent = useMemo(() => `<p>${getExpressionValue(propertyInstance)}</p>`, [propertyInstance.value, newResult]);

  const onSuggestOpen = () => {
    suggestOpen.current = true;
  };

  const onSuggestClosed = () => {
    suggestOpen.current = false;
  };

  useClosePopoverOnClickOutside({
    popoverRef: popoverElementRef,
    setPopoverOpen: setShowDataConnectionDropdown,
    disabled: !showDataConnectionDropdown,
  });

  const handleShowDataConnectionDropdown = () => {
    setCaretPosition(getCaretPosition(editor));
    setShowDataConnectionDropdown(true);
  };

  const onDataSourceSelect = (dataSourceId: string) => {
    const dataSource = appStore.workspaceModel?.dataConnection.dataSourceMap?.get(dataSourceId);
    if (dataSource) {
      setSelectedDataSource(dataSource);
      handleShowDataConnectionDropdown();
    }
  };

  const handleModEnterKeyPress = ({ editor }: { editor: Editor }): boolean => {
    onModEnterKeyPress?.();
    return handleEnterKeyPress({ editor });
  };

  const handleEnterKeyPress = ({ editor }: { editor: Editor }) => {
    if (!suggestOpen.current) {
      handleConfirm(editor);
      return true;
    } else {
      return false;
    }
  };

  const handleTransaction = ({ editor }: EditorEvents["transaction"]) => {
    if (shouldFocusAfterTransactionRef.current) {
      shouldFocusAfterTransactionRef.current = false;
      editor.commands.focus();
      popoverRef.current?.setState({ isOpen: true });
    }
  };

  const handleFocus = ({ editor }: EditorEvents["focus"]) => {
    props.onFocus?.();
    const focusedContent = `<p>${getParsedExpression(propertyInstance)}</p>`;
    setIsFocused(true);
    const position = caretPosition ?? "end";
    editor.chain().setContent(focusedContent).focus(position).run();
    setCaretPosition(undefined);
  };

  const handleBlur = ({ editor }: EditorEvents["blur"]) => {
    if (showDataConnectionDropdown || !isFocused) {
      return;
    }
    const currentText = editor.getText();
    setIsFocused(false);
    setCaretPosition(undefined);
    props.onBlur?.(currentText);
    if (propertyInstance.locked || propertyInstance.value === currentText) {
      editor?.commands.setContent(`<p>${getExpressionValue(propertyInstance)}</p>`);
      return;
    }
    if (!propertyInstance.locked) {
      handleConfirm(editor);
    }
  };

  const editor = useScalarExpressionEditor({
    content: blurredContent,
    referenceProperty: propertyInstance,
    onEnterKeyPress: handleEnterKeyPress,
    onModEnterKeyPress: handleModEnterKeyPress,
    autoFocus,
    onCreate,
    onUpdate,
    onDataSourceSelect,
    onTransaction: handleTransaction,
    onSuggestOpen,
    onSuggestClosed,
    onFocus: handleFocus,
    onBlur: handleBlur,
  });

  const currentText = editor?.getText()?.replaceAll(/\s/g, " ") ?? "";
  const evaluatedExpression = useMemo(() => evaluateSmartExpression(currentText, propertyInstance), [currentText, propertyInstance]);

  useEffect(() => {
    setIntent(evaluatedExpression?.errorType ? Intent.WARNING : Intent.NONE);
  }, [evaluatedExpression]);

  const rollupInfo = propertyInstance.rollupInfo;
  const rollupDependencies = rollupInfo?.dependencies?.filter(v => v.value);
  const isRollup = propertyInstance.isRollup && rollupInfo && !!rollupDependencies?.length;
  const showRollupInfo = (props.showRollupInfo ?? true) && isRollup;

  const handleConfirm = (editor: Editor) => {
    const text = editor.getText().replaceAll(/\s/g, " ");
    propertyInstance.setScalarValueFromString(text, disableNotifications);
    editor.commands.setContent(`<codeBlock>${getExpressionValue(propertyInstance)}</codeBlock>`);
    setIsFocused(false);
    props.onBlur?.(currentText);

    if (!propertyInstance.evaluatedExpressionInfo?.errorType) {
      setTimeout(() => setIntent(Intent.SUCCESS), 100);
      setTimeout(() => setIntent(Intent.NONE), 500);
    }
  };

  const renderLeftIcons = () => {
    if (propertyInstance.locked || showRollupInfo) {
      return (
        <div className="scalar-expression-editor--left-element">
          {propertyInstance.locked && (
            <Tooltip content="Property value is locked. Click to unlock">
              <AnchorButton minimal intent={intent} icon="lock" onClick={propertyInstance.toggleLocked} e2eIdentifiers="toggle-locked" />
            </Tooltip>
          )}
          {showRollupInfo && (
            <Popover
              className="rollup-icon-popover"
              placement="bottom-start"
              content={<PropertyInfoTable properties={rollupDependencies as IPropertyInstance[]} />}
              interactionKind={PopoverInteractionKind.CLICK}
            >
              <Tooltip content={getTooltipContent(rollupInfo, propertyInstance)}>
                <AnchorButton icon="layout-hierarchy" minimal intent={intent} e2eIdentifiers="show-property-info-table" />
              </Tooltip>
            </Popover>
          )}
        </div>
      );
    }

    return null;
  };

  const renderRightIcons = () => {
    return (
      <div className="scalar-expression-editor--right-element">
        <Button
          className="scalar-expression-editor--data-source-btn"
          onClick={() => setShowDataConnectionDropdown(true)}
          icon="data-connection"
          e2eIdentifiers="open-data-source-dropdown"
          tooltip="Open data connection dropdown"
          minimal
        />
        {!hideDetachButton && (
          <Button
            className="scalar-expression-editor--data-source-btn"
            onClick={() => appStore.env.showDetachedExpressionEditorWindow(propertyInstance)}
            icon="open-application"
            e2eIdentifiers="detach-expression-editor"
            tooltip="Open equation editor window"
            minimal
          />
        )}
        {evaluatedExpression?.message && (
          <Tooltip intent={intent} content={evaluatedExpression?.message} minimal className="">
            <Icon
              className="scalar-expression-editor--warning-icon"
              icon={intent === Intent.WARNING ? "warning-sign" : "info-sign"}
              size={16}
              intent={intent}
              {...createDataTestId(ElementType.Icon, ["expression-warning"])}
            />
          </Tooltip>
        )}
      </div>
    );
  };

  const insertStringIntoExpression = (linkString: string, stringToBeReplaced?: string): string => {
    if (stringToBeReplaced) {
      const replaceRegex = getReplaceRegex(stringToBeReplaced);
      return currentText.replace(replaceRegex, linkString);
    } else if (editor && caretPosition) {
      editor.commands.insertContentAt(caretPosition, linkString);
      return editor.getText();
    }
    return linkString;
  };

  const handleAddDataLink = (linkString: string, stringToBeReplaced?: string) => {
    if (!editor) {
      return;
    }

    const finalExpression = insertStringIntoExpression(linkString, stringToBeReplaced);
    propertyInstance.setScalarValueFromString(finalExpression, disableNotifications);
    // increment caret position as we're adding a new node
    setCaretPosition((state = 1) => state + 1);
    setShowDataConnectionDropdown(false);
    // we need to close the popover otherwise the focus command in handleTransaction will not work
    popoverRef.current?.setState({ isOpen: false });
    shouldFocusAfterTransactionRef.current = true;
  };

  const renderPopoverContent = () => {
    if (showDataConnectionDropdown) {
      const openBracesContentMatch = currentText.match(openBracesRegex);
      return (
        <DataConnectionDropdown
          initialDataConnection={selectedDataSource}
          initialSearchTerm={openBracesContentMatch?.groups?.dataSource}
          isSimplifiedVersion={!!selectedDataSource}
          onAddDataConnection={dataLink => handleAddDataLink(dataLink, openBracesContentMatch?.[0])}
          style={{ minWidth: ref.current?.offsetWidth }}
        />
      );
    }

    return (
      <ExpressionInfoOverlay
        propertyInstance={propertyInstance}
        expression={currentText}
        onShowDataConnectionDropdown={handleShowDataConnectionDropdown}
        style={{ maxWidth: ref.current?.offsetWidth }}
      />
    );
  };

  return (
    <div
      ref={ref}
      className={classNames(intentClass(intent), "scalar-expression-editor", Classes.INPUT, className, {
        "scalar-expression-editor--focused": isFocused,
        "scalar-expression-editor--wrap": enableWrapping || (enableWrappingOnFocus && isFocused),
      })}
    >
      <Popover
        fill
        ref={popoverRef}
        popoverRef={popoverElementRef}
        isOpen={showDataConnectionDropdown || (isFocused && showInfoOverlayOnFocus)}
        autoFocus={false}
        className="scalar-expression-editor--popover-target"
        popoverClassName="scalar-expression-editor--popover"
        position={popoverPosition}
        content={renderPopoverContent()}
        matchTargetWidth={showDataConnectionDropdown && allowMatchingTargetWidth}
      >
        <div className="scalar-expression-editor--container">
          {renderLeftIcons()}
          <EditorContent
            className={scalarExpressionEditorInputClassName}
            readOnly={propertyInstance.locked}
            spellCheck={false}
            autoComplete="off"
            autoCapitalize="off"
            autoCorrect="off"
            autoFocus={autoFocus}
            editor={editor}
            onFocus={() => appStore.env.setActivePropertyInstance(propertyInstance)}
            e2eIdentifiers={["scalar-expression-editor", propertyInstance.propertyDefinition?.label ?? ""]}
          />
          {renderRightIcons()}
        </div>
      </Popover>
    </div>
  );
});
