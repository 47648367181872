import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Classes, Collapse, Tooltip } from "@blueprintjs/core";
import { BlueprintIcon } from "@ui/BlueprintIcon";
import classNames from "classnames";

import { Icon } from "@components/Icon";
import { TitledTooltip } from "@components/Shared";
import { createDataTestId, ElementType } from "@utilities/E2EUtils";
import { isNewIconFormat } from "@utilities/Icon";

import NavLinkAsMenuItem, { INavLinkAsMenuItem, INestedNavLinkAsMenuItem } from "./NavLinkAsMenuItem";

import "./NavLinkAsMenuItem.scss";
import styles from "./CollapsibleMenuItem.module.scss";

interface CollapsibleMenuItemProps {
  open: boolean;
  navLink: INavLinkAsMenuItem;
}

// height in px - used for animation purposes
const menuItemHeight = 36;

const CollapsibleMenuItem = ({ open, navLink }: CollapsibleMenuItemProps) => {
  const expandedInitialState =
    navLink.expandedInitialState || navLink.selected || navLink.nestedLinks?.some((link: INavLinkAsMenuItem) => link.selected);
  const [expanded, setExpanded] = useState(expandedInitialState);
  const { text, label, icon, tooltip, e2eIdentifiers, collapseIfSidebarCollapsed } = navLink;
  const isControlled = navLink.expandedInitialState !== undefined && navLink.onToggleExpand;
  const location = useLocation();
  const isDigitalThreadsRoute = location.pathname.includes("/threads");

  useEffect(() => {
    if (isDigitalThreadsRoute && !expanded) {
      handleToggleExpand();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDigitalThreadsRoute]);

  useEffect(() => {
    if (isControlled) {
      setExpanded(navLink.expandedInitialState);
    }
  }, [navLink.expandedInitialState, isControlled]);

  const handleToggleExpand = () => {
    if (isControlled) {
      navLink.onToggleExpand?.();
    } else {
      setExpanded(!expanded);
    }
  };

  const renderIcon = () => {
    if (isNewIconFormat(icon)) {
      return <Icon className="navlink-menu-item--icon" icon={icon} />;
    }

    return icon;
  };

  const renderEmptyPlaceholder = () => {
    return (
      <div
        className={classNames(styles.collapsibleMenuItemEmpty, styles.collapsibleMenuItemCollapsible, {
          [styles.collapsed]: !open,
        })}
      >
        {open ? "Empty" : ""}
      </div>
    );
  };

  const renderNestedNavLinks = (nestedLinks: INestedNavLinkAsMenuItem) => {
    const collapseStyles = {
      ...(open
        ? { maxHeight: nestedLinks.length * menuItemHeight }
        : { transform: `translateY(-${nestedLinks.length * menuItemHeight}px)` }),
    };
    return (
      <div
        className={classNames(styles.collapsibleMenuItemCollapsible, {
          [styles.collapsed]: collapseIfSidebarCollapsed && !open,
        })}
        style={collapseIfSidebarCollapsed ? collapseStyles : {}}
      >
        {nestedLinks.map((link: INavLinkAsMenuItem, index) => (
          <NavLinkAsMenuItem key={`${link.label}${index}`} open={open} text={open ? link.text : undefined} {...link} />
        ))}
      </div>
    );
  };

  return (
    <div>
      <div
        onClick={handleToggleExpand}
        className={classNames(Classes.MENU_ITEM, Classes.POPOVER_DISMISS, "navlink-menu-item")}
        {...createDataTestId(ElementType.NavLink, e2eIdentifiers)}
      >
        {icon &&
          (typeof tooltip === "string" || !tooltip ? (
            <Tooltip content={tooltip} disabled={!tooltip}>
              <span className={classNames(Classes.MENU_ITEM_ICON, "icon")}>
                <BlueprintIcon icon={renderIcon()} />
              </span>
            </Tooltip>
          ) : (
            <TitledTooltip title={tooltip.title} content={tooltip.content} hoverOpenDelay={100}>
              <span className={classNames(Classes.MENU_ITEM_ICON, "icon")}>
                <BlueprintIcon icon={renderIcon()} />
              </span>
            </TitledTooltip>
          ))}
        {open && (
          <>
            {text && <div className={classNames(Classes.FILL, Classes.TEXT_OVERFLOW_ELLIPSIS)}>{text}</div>}
            {label && <div className={Classes.MENU_ITEM_LABEL}>{label}</div>}
            <span className={Classes.MENU_ITEM_ICON}>
              <BlueprintIcon icon={expanded ? "chevron-up" : "chevron-down"} />
            </span>
          </>
        )}
      </div>
      <Collapse isOpen={expanded}>
        <div className={classNames(styles.collapsibleMenuItemContent, { [styles.collapsibleMenuItemContentOpen]: open })}>
          {navLink.nestedLinks?.length ? renderNestedNavLinks(navLink.nestedLinks) : renderEmptyPlaceholder()}
        </div>
      </Collapse>
    </div>
  );
};

export default CollapsibleMenuItem;
