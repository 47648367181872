import { getGitHubRepoMetadata } from "@components/Modeling/ModelingFrame/ModelBlock/Attachments/utils";

import { GitHubRepository } from "../models/integrations";

import { HttpClient, ParentClient } from "./client";

export class LiveLinks extends HttpClient {
  public constructor(parent: ParentClient) {
    super(parent);
  }

  private readonly endpoint: string = "/integrations";

  public getGitHubRepository = async (repositoryLink: string): Promise<GitHubRepository | undefined> => {
    try {
      const repoMetadata = getGitHubRepoMetadata(repositoryLink);
      const response = await this.instance.get<GitHubRepository>(
        `${this.endpoint}/github/repos/${repoMetadata.owner}/${repoMetadata.name}`
      );
      return response.data;
    } catch (err) {
      console.warn(err);
      return;
    }
  };
}
