import { ChangeEvent, KeyboardEvent, useCallback, useState } from "react";
import { NavLink } from "react-router-dom";
import { Checkbox, HTMLTable, InputGroup, Intent, Menu, MenuDivider, NonIdealState } from "@blueprintjs/core";
import { useSearchParam } from "@hooks/useSearchParam/useSearchParam";
import classNames from "classnames";
import { observer } from "mobx-react";

import { AnchorButton } from "@components/AnchorButton";
import { Button } from "@components/Button";
import { MenuItem } from "@components/MenuItem";
import { MenuItemDelete } from "@components/MenuItems";
import { Popover } from "@components/Popover";
import User from "@components/User";
import { updateWorkspace } from "@rollup-api/utils";
import NavLinkAsMenuItem from "@router/navigation/NavLinkAsMenuItem";
import appStore from "@store/AppStore";
import { getFormattedDate, humanReadableDateTime } from "@utilities";
import { IWorkspaceListItem } from "src/services";
import { Text, TextVariant } from "src/ui/Text";

import styles from "./SettingsWorkspaces.module.scss";

enum WorkspaceSortField {
  CreatedAt = "createdAt",
  UpdatedAt = "updatedAt",
  Label = "label",
}

const filtersNameMap = {
  [WorkspaceSortField.CreatedAt]: "Creation date",
  [WorkspaceSortField.UpdatedAt]: "Last updated",
  [WorkspaceSortField.Label]: "Name",
};

/** Main function. */
function SettingsWorkspaces() {
  const [sort] = useSearchParam("sort");
  const [itemToEdit, setItemToEdit] = useState<IWorkspaceListItem>();
  const [newLabel, setNewLabel] = useState("");
  const [sortState, setSortState] = useState<WorkspaceSortField>((sort as WorkspaceSortField) || WorkspaceSortField.CreatedAt);
  const [inputValue, setInputValue] = useState("");
  const [selectedItems, setSelectedItems] = useState<IWorkspaceListItem[]>([]);
  const resetInputValue = useCallback(() => setInputValue(""), [setInputValue]);
  const handleInputValueChange = useCallback((event: ChangeEvent<HTMLInputElement>) => setInputValue(event.target.value), [setInputValue]);
  const sortedListOfWorkspaces = appStore.orgModel.workspacesList.slice().sort((a, b) => {
    if (sortState === WorkspaceSortField.Label) {
      return a.label.localeCompare(b.label);
    }
    return new Date(b[sortState]).getTime() - new Date(a[sortState]).getTime();
  });

  const lowerCaseInputValue = inputValue.toLowerCase();
  const filteredListOfWorkspaces = sortedListOfWorkspaces.filter(item => item.label.toLowerCase().includes(lowerCaseInputValue));
  const selectedFilteredWorkspaces = filteredListOfWorkspaces.filter(workspace => selectedItems.includes(workspace));

  const handleSelectAllClicked = () => {
    if (selectedItems.length === 0) {
      setSelectedItems(filteredListOfWorkspaces);
    } else {
      setSelectedItems([]);
    }
  };

  const renderSortNavigation = () => {
    return (
      <Menu>
        {Object.values(WorkspaceSortField).map(field => (
          <NavLinkAsMenuItem
            key={field}
            text={filtersNameMap[field]}
            selected={field === sortState}
            to={`/settings/workspaces?sort=${field}`}
            e2eIdentifiers={`sort-workspaces-${field}`}
            onClick={() => setSortState(field)}
          />
        ))}
      </Menu>
    );
  };

  const confirmLabelUpdate = () => {
    if (itemToEdit && newLabel && newLabel !== itemToEdit.label) {
      updateWorkspace(itemToEdit.id, { label: newLabel });
      appStore.orgModel.updateWorkspaceLabel(itemToEdit.id, newLabel);
    }

    setItemToEdit(undefined);
  };

  const handleNameKeyUp = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Escape") {
      setItemToEdit(undefined);
    } else if (e.key === "Enter") {
      confirmLabelUpdate();
    }
  };

  const renderLabel = (workspace: IWorkspaceListItem) => {
    if (workspace.id === itemToEdit?.id) {
      return (
        <InputGroup className="mr-4" onChange={e => setNewLabel(e.target.value)} value={newLabel} autoFocus onKeyUp={handleNameKeyUp} />
      );
    } else {
      return (
        <NavLink to={`/workspaces/${workspace.id}`} key={workspace.id}>
          <Text className="settings-list-area--td-label">{workspace.label}</Text>
        </NavLink>
      );
    }
  };

  function handleWorkspaceCheckToggled(workspace: IWorkspaceListItem) {
    setSelectedItems(prev => (prev.includes(workspace) ? prev.filter(item => item !== workspace) : [...prev, workspace]));
  }

  const renderMoreOptionsButton = (workspace: IWorkspaceListItem) => {
    return (
      <Popover
        placement="bottom-end"
        content={
          <Menu>
            <MenuItem icon="duplicate" text="Duplicate" onClick={() => appStore.duplicateWorkspace(workspace.id)} e2eIdentifiers="delete" />
            <MenuDivider />
            <MenuItem
              icon="edit"
              text="Edit name"
              onClick={() => {
                setItemToEdit(workspace);
                setNewLabel(workspace.label);
              }}
              e2eIdentifiers="edit"
            />
            {selectedFilteredWorkspaces.includes(workspace) ? (
              <MenuItemDelete
                text={`Delete ${selectedFilteredWorkspaces.length} selected`}
                onDelete={() =>
                  appStore.ui.showDeleteConfirmationDialog({
                    idList: selectedFilteredWorkspaces.map(item => item.id),
                    label: workspace.label,
                  })
                }
                e2eIdentifiers="delete-selected"
              />
            ) : (
              <MenuItemDelete
                onDelete={() =>
                  appStore.ui.showDeleteConfirmationDialog({
                    idList: [workspace.id],
                    label: workspace.label,
                  })
                }
              />
            )}
          </Menu>
        }
      >
        <AnchorButton minimal rightIcon="more" e2eIdentifiers="show-menu" />
      </Popover>
    );
  };

  return (
    <div className="settings-layout--content">
      <div className="settings-layout--header">
        <Text variant={TextVariant.H1}>Workspaces</Text>
        <div className="settings-layout--header--right-section">
          <InputGroup
            value={inputValue}
            onChange={handleInputValueChange}
            leftIcon="search"
            large
            placeholder="Search for workspace"
            className="settings-layout--search"
          />
          <Button
            intent={Intent.PRIMARY}
            icon="plus"
            large
            onClick={appStore.ui.toggleAddNewWorkspaceDialog}
            e2eIdentifiers="add-new-workspace"
          >
            Create workspace
          </Button>
          <Popover placement="bottom-end" content={renderSortNavigation()}>
            <Button intent={Intent.PRIMARY} icon="sort" large e2eIdentifiers="add-new-workspace" />
          </Popover>
        </div>
      </div>

      {!!filteredListOfWorkspaces.length && (
        <div className="settings-list-area">
          <HTMLTable className="min-w-full">
            <thead>
              <tr className={styles.workspaceRow}>
                <th className={styles.workspaceCheckboxColumn}>
                  <Checkbox
                    className={classNames(styles.workspaceCheckbox, { [styles.workspaceChecked]: selectedFilteredWorkspaces.length })}
                    indeterminate={
                      selectedFilteredWorkspaces.length > 0 && selectedFilteredWorkspaces.length < filteredListOfWorkspaces.length
                    }
                    checked={selectedFilteredWorkspaces.length === filteredListOfWorkspaces.length}
                    onChange={() => handleSelectAllClicked()}
                  />
                </th>
                <th>
                  <Text variant={TextVariant.Caption}>Name</Text>
                </th>
                <th>
                  <Text variant={TextVariant.Caption}>Creator</Text>
                </th>
                <th>
                  <Text variant={TextVariant.Caption}>Last updated</Text>
                </th>
                <th>
                  <Text variant={TextVariant.Caption}>Created</Text>
                </th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {filteredListOfWorkspaces?.map((workspace: IWorkspaceListItem) => (
                <tr key={workspace.id} className={styles.workspaceRow}>
                  <td className={styles.workspaceCheckboxColumn}>
                    <Checkbox
                      className={classNames(styles.workspaceCheckbox, {
                        [styles.workspaceChecked]: selectedFilteredWorkspaces.includes(workspace),
                      })}
                      checked={selectedFilteredWorkspaces.includes(workspace)}
                      onChange={() => handleWorkspaceCheckToggled(workspace)}
                    />
                  </td>
                  <td width={400}>{renderLabel(workspace)}</td>
                  <td>
                    <Text>
                      <User userId={workspace.createdBy} />
                    </Text>
                  </td>
                  <td>
                    <Text className="mr-2" variant={TextVariant.Caption}>
                      {humanReadableDateTime(workspace.updatedAt)}
                    </Text>
                  </td>
                  <td>
                    <Text className="mr-2" variant={TextVariant.Caption}>
                      {getFormattedDate(workspace.createdAt)}
                    </Text>
                  </td>
                  <td>{renderMoreOptionsButton(workspace)}</td>
                </tr>
              ))}
            </tbody>
          </HTMLTable>
        </div>
      )}
      {!filteredListOfWorkspaces.length && inputValue && (
        <NonIdealState
          className="min-h-[400px]"
          icon="search"
          title={`Can't find workspace for "${inputValue}"`}
          action={
            <>
              <Button onClick={resetInputValue} text="Reset search" e2eIdentifiers="reset-input-value" />
              <Button
                text="Create workspace"
                intent={Intent.PRIMARY}
                icon="plus"
                onClick={appStore.ui.toggleAddNewWorkspaceDialog}
                e2eIdentifiers="create-workspace"
              />
            </>
          }
        />
      )}
      {!filteredListOfWorkspaces.length && !inputValue && (
        <NonIdealState
          className="min-h-[400px]"
          icon="applications"
          title="Create a workspace and start building."
          action={
            <Button
              text="Create workspace"
              intent={Intent.PRIMARY}
              icon="plus"
              onClick={appStore.ui.toggleAddNewWorkspaceDialog}
              e2eIdentifiers="create-workspace"
            />
          }
        />
      )}
    </div>
  );
}

/** Exports. */
export default observer(SettingsWorkspaces);
