import { Color } from "@rollup-types/colors";

export interface SimpleGitHubRepoMetadata {
  owner: string;
  name: string;
}

export interface GitHubRepository {
  type: "github repository";
  name: string;
  description: string;
  repositoryCoverImageUrl: string;
  open_issues_count: string;
  stargazers_count: string;
  owner?: {
    login: string;
    avatar_url: string;
  };
}

export enum GitHubItemType {
  REPO = "repo",
  ISSUE = "issue",
  PULL_REQUEST = "pull-request",
  TAG = "tag",
  BRANCH = "branch",
  RELEASE = "release",
}

export type RepoListItem = {
  id: string;
  isPrivate: boolean;
  isArchived: boolean;
  url: string;
  name: string;
  primaryLanguage?: { color: Color; name: string };
  type: GitHubItemType.REPO;
  title: string;
};

export type RepoDetails = RepoListItem & {
  stargazerCount: number;
  forkCount: number;
  defaultBranchRef: { name: string };
  description: string;
  openGraphImageUrl: string;
  issues: { totalCount: number };
  pullRequests: { totalCount: number };
  watchers: { totalCount: number };
};

export type IssueListItem = {
  type: GitHubItemType.ISSUE;
  number: number;
  url: string;
  id: string;
  title: string;
  closed: boolean;
};

export type GithubLabel = {
  description: string;
  name: string;
  color: string;
};

export type GithubUser = {
  login: string;
  avatarUrl: string;
};

export type IssueDetails = IssueListItem & {
  createdAt: string;
  lastEditedAt: string;
  closedAt: string;
  comments: { totalCount: number };
  assignees: { totalCount: number; nodes: GithubUser[] };
  participants: { totalCount: number; nodes: GithubUser[] };
  linkedBranches: { totalCount: number; nodes: { ref: { name: string } }[] };
  labels: { totalCount: number; nodes: GithubLabel[] };
};

export type PullRequestListItem = {
  type: GitHubItemType.PULL_REQUEST;
  number: number;
  url: string;
  id: string;
  title: string;
  isDraft: boolean;
  merged: boolean;
  closed: boolean;
};

export type PullRequestDetails = PullRequestListItem & {
  createdAt: string;
  lastEditedAt: string;
  closedAt: string;
  mergedAt: string;
  author: GithubUser;
  mergedBy?: GithubUser;
  baseRefName: string;
  headRefName: string;
  comments?: { totalCount: number };
  assignees?: { totalCount: number; nodes: GithubUser[] };
  participants?: { totalCount: number; nodes: GithubUser[] };
  closingIssuesReferences?: { totalCount: number; nodes: { number: number; title: string; url: string }[] };
  labels?: { totalCount: number; nodes: { description: string; name: string; color: string }[] };
};

export type TagListItem = {
  type: GitHubItemType.TAG;
  title: string;
  id: string;
  url: string;
  repositoryName: string;
};

export type GitHubListItem = RepoListItem | IssueListItem | PullRequestListItem | TagListItem;
export type GitHubDetails = RepoDetails | IssueDetails | PullRequestDetails;
