import { ReactNode } from "react";
import { Classes, Colors } from "@blueprintjs/core";

const ptIconWrapper = (path: ReactNode, viewboxDefault = 24) => {
  return (
    <span className={Classes.ICON}>
      <svg
        className="pt-icon"
        version="1.1"
        id="Capa_1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        width="16px"
        height="16px"
        viewBox={`0 0 ${viewboxDefault} ${viewboxDefault}`}
      >
        {path}
      </svg>
    </span>
  );
};

export const rocketIcon = ptIconWrapper(
  <>
    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
    <path d="M4 13a8 8 0 0 1 7 7a6 6 0 0 0 3 -5a9 9 0 0 0 6 -8a3 3 0 0 0 -3 -3a9 9 0 0 0 -8 6a6 6 0 0 0 -5 3" />
    <path d="M7 14a6 6 0 0 0 -3 6a6 6 0 0 0 6 -3" />
    <circle cx="15" cy="9" r="1" />
  </>,
  48
);

export const pythonIcon = ptIconWrapper(
  <>
    <path d="M14.25.18l.9.2.73.26.59.3.45.32.34.34.25.34.16.33.1.3.04.26.02.2-.01.13V8.5l-.05.63-.13.55-.21.46-.26.38-.3.31-.33.25-.35.19-.35.14-.33.1-.3.07-.26.04-.21.02H8.77l-.69.05-.59.14-.5.22-.41.27-.33.32-.27.35-.2.36-.15.37-.1.35-.07.32-.04.27-.02.21v3.06H3.17l-.21-.03-.28-.07-.32-.12-.35-.18-.36-.26-.36-.36-.35-.46-.32-.59-.28-.73-.21-.88-.14-1.05-.05-1.23.06-1.22.16-1.04.24-.87.32-.71.36-.57.4-.44.42-.33.42-.24.4-.16.36-.1.32-.05.24-.01h.16l.06.01h8.16v-.83H6.18l-.01-2.75-.02-.37.05-.34.11-.31.17-.28.25-.26.31-.23.38-.2.44-.18.51-.15.58-.12.64-.1.71-.06.77-.04.84-.02 1.27.05zm-6.3 1.98l-.23.33-.08.41.08.41.23.34.33.22.41.09.41-.09.33-.22.23-.34.08-.41-.08-.41-.23-.33-.33-.22-.41-.09-.41.09zm13.09 3.95l.28.06.32.12.35.18.36.27.36.35.35.47.32.59.28.73.21.88.14 1.04.05 1.23-.06 1.23-.16 1.04-.24.86-.32.71-.36.57-.4.45-.42.33-.42.24-.4.16-.36.09-.32.05-.24.02-.16-.01h-8.22v.82h5.84l.01 2.76.02.36-.05.34-.11.31-.17.29-.25.25-.31.24-.38.2-.44.17-.51.15-.58.13-.64.09-.71.07-.77.04-.84.01-1.27-.04-1.07-.14-.9-.2-.73-.25-.59-.3-.45-.33-.34-.34-.25-.34-.16-.33-.1-.3-.04-.25-.02-.2.01-.13v-5.34l.05-.64.13-.54.21-.46.26-.38.3-.32.33-.24.35-.2.35-.14.33-.1.3-.06.26-.04.21-.02.13-.01h5.84l.69-.05.59-.14.5-.21.41-.28.33-.32.27-.35.2-.36.15-.36.1-.35.07-.32.04-.28.02-.21V6.07h2.09l.14.01zm-6.47 14.25l-.23.33-.08.41.08.41.23.33.33.23.41.08.41-.08.33-.23.23-.33.08-.41-.08-.41-.23-.33-.33-.23-.41-.08-.41.08z" />
  </>,
  24
);

export const matlabIcon = ptIconWrapper(
  <>
    <path d="M4.323 16.25C3.13 15.354 1.64 14.31 0 13.12l5.814-2.237 2.386 1.79c-1.79 2.086-2.982 2.831-3.877 3.577zm15.952-6.261c-.447-1.193-.745-2.385-1.193-3.578-.447-1.343-.894-2.535-1.64-3.579-.299-.447-.895-1.49-1.64-1.49-.15 0-.299.148-.448.148-.447.15-1.043 1.043-1.193 1.64-.447.745-1.342 1.938-1.938 2.683-.149.299-.447.597-.596.746-.447.298-.894.745-1.491 1.043-.15 0-.298.149-.447.149-.447 0-.745.298-1.043.447-.447.447-.894 1.043-1.342 1.49 0 .15-.15.299-.298.448l2.236 1.64c1.64-1.938 3.578-3.876 4.92-7.603 0 0-.448 4.026-4.026 8.349-2.236 2.534-4.025 3.876-4.324 4.174 0 0 .597-.149 1.194.15 1.193.446 1.789 2.087 2.236 3.279.298.894.745 1.64 1.043 2.534 1.193-.298 1.938-.745 2.683-1.491.745-.746 1.491-1.64 2.236-2.385 1.342-1.64 2.982-3.728 5.069-2.684.298.15.745.447.894.596.447.298.745.596 1.193 1.043.745.597 1.043 1.044 1.64 1.343-1.491-2.982-2.534-5.964-3.727-9.094z" />
  </>,
  24
);

export const juliaIcon = ptIconWrapper(
  <>
    <path d="M11.138 17.569a5.569 5.569 0 1 1-11.138 0 5.569 5.569 0 1 1 11.138 0zm6.431-11.138a5.569 5.569 0 1 1-11.138 0 5.569 5.569 0 1 1 11.138 0zM24 17.569a5.569 5.569 0 1 1-11.138 0 5.569 5.569 0 1 1 11.138 0z" />
  </>,
  24
);

export const wolframIcon = ptIconWrapper(
  <>
    <path d="M15.74 10.7c-.53-1.53.14-4 .14-4S13.6 8.01 11.93 8 8.13 6.7 8.13 6.7s.56 2.46.07 4.04c-.48 1.58-2.47 3.34-2.47 3.34s2.58.23 3.85 1.1S12 18.64 12 18.64s.78-2.35 2.32-3.41c1.54-1.07 3.96-1.15 3.96-1.15s-2-1.84-2.54-3.37zm-2 3.69c-1.09.81-1.75 2.19-1.75 2.19s-.76-1.43-1.8-2.22c-1.05-.79-2.57-.97-2.57-.97a5.06 5.06 0 0 0 1.53-2.37c.46-1.54.18-2.73.18-2.73s.92.7 2.62.7c1.7 0 2.79-.7 2.79-.7s-.38 1.58.07 2.7c.44 1.14 1.57 2.41 1.57 2.41s-1.56.17-2.64.99zM13.73 9.6s-.95.38-1.77.37c-.82 0-1.7-.37-1.7-.37.05.58 0 1.15-.17 1.7-.27.86-.91 1.64-.91 1.64A4.23 4.23 0 0 1 12 14.95s.45-.85 1.18-1.33c.5-.31 1.05-.55 1.63-.7 0 0-.74-.78-1.02-1.57-.28-.78-.06-1.75-.06-1.75zM20.1 12l3.31-3.7-4.85-1.07.5-4.94-4.56 2L12 0 9.5 4.29l-4.55-2 .49 4.95L.59 8.29 3.89 12 .6 15.7l4.85 1.07-.5 4.94 4.55-2L12 24l2.5-4.29 4.56 2-.5-4.94 4.85-1.06-3.3-3.71zm-5.27 3.95C14 16.56 12 20.81 12 20.81S10.5 16.95 9 15.95c-1.48-1-5.38-1.22-5.38-1.22s2.99-2.37 3.57-4.47c.59-2.1-.37-5.37-.37-5.37s2.9 2.1 5.08 2.1c2.17 0 5.26-2.1 5.26-2.1s-.82 4.5-.53 5.46c.3.96 3.73 4.38 3.73 4.38s-4.7.62-5.54 1.22z" />
  </>,
  24
);

export const interfaceContactIcon = ptIconWrapper(
  <>
    {" "}
    <path
      fill={Colors.GRAY4}
      d="m577.5 350h-455c-9.6719 0-17.5 7.8281-17.5 17.5v140c0 9.6719 7.8281 17.5 17.5 17.5h455c9.6719 0 17.5-7.8281 17.5-17.5v-140c0-9.6719-7.8281-17.5-17.5-17.5z"
    />
    <path
      fill={Colors.GRAY4}
      d="m245 227.5c-9.6719 0-17.5 7.8281-17.5 17.5v27.754l-5.1289-5.1289c-6.8359-6.8359-17.91-6.8359-24.746 0s-6.8359 17.91 0 24.746l35 35c3.4219 3.4219 7.8984 5.1289 12.375 5.1289s8.957-1.7109 12.375-5.1289l35-35c6.8359-6.8359 6.8359-17.91 0-24.746s-17.91-6.8359-24.746 0l-5.1289 5.1289v-27.754c0-9.6719-7.8281-17.5-17.5-17.5z"
    />
    <path
      fill={Colors.GRAY4}
      d="m455 332.5c9.6719 0 17.5-7.8281 17.5-17.5v-27.754l5.1289 5.1289c3.418 3.418 7.8945 5.125 12.371 5.125s8.957-1.7109 12.375-5.1289c6.8359-6.8359 6.8359-17.91 0-24.746l-35-35c-6.8359-6.8359-17.91-6.8359-24.746 0l-35 35c-6.8359 6.8359-6.8359 17.91 0 24.746 6.8359 6.8359 17.91 6.8359 24.746 0l5.125-5.125v27.754c0 9.6719 7.8281 17.5 17.5 17.5z"
    />
    <path
      fill={Colors.GRAY4}
      d="m577.5 35h-455c-9.6719 0-17.5 7.8281-17.5 17.5v140c0 9.6719 7.8281 17.5 17.5 17.5h455c9.6719 0 17.5-7.8281 17.5-17.5v-140c0-9.6719-7.8281-17.5-17.5-17.5z"
    />
  </>,
  700
);

export const interfaceWaterIcon = ptIconWrapper(
  <>
    <path
      fill={Colors.GRAY4}
      d="m349.54 0.0039062c-4.9922 0.125-9.6953 2.375-12.922 6.1875 0 0-48.359 57.629-96.762 128.69-24.203 35.527-48.527 74.496-67.027 111.87-18.496 37.371-31.648 72.688-31.648 104.39 0 115.12 93.652 208.87 208.77 208.87 115.12 0 208.87-93.754 208.87-208.87 0-31.695-13.289-67.012-31.789-104.39-18.496-37.371-42.684-76.34-66.887-111.87-48.406-71.055-96.762-128.68-96.762-128.68-3.4375-4.0586-8.5312-6.3359-13.844-6.1875zm119.87 327.27c5.3438 0.054687 10.371 2.5508 13.645 6.7695 3.2773 4.2227 4.4453 9.7109 3.1719 14.902-0.53516 2.332-1.168 4.6445-1.8125 6.8359-14.852 50.352-47.066 91.043-88.215 117.17-5.2891 3.3438-11.957 3.6133-17.5 0.70703-5.5391-2.9062-9.1094-8.5469-9.3633-14.797-0.25391-6.2539 2.8438-12.164 8.1328-15.512 34.258-21.75 60.922-55.348 73.348-97.379 0.44141-1.5 0.91016-3.1016 1.2969-4.7852h0.003906c0.76562-3.6602 2.6875-6.9844 5.4805-9.4727 2.7891-2.4922 6.3086-4.0234 10.035-4.3672 0.58984-0.054688 1.1836-0.078125 1.7773-0.070312z"
    />
  </>,
  700
);
export const javascriptIcon = ptIconWrapper(
  <>
    <path d="M0 0h24v24H0V0zm22.034 18.276c-.175-1.095-.888-2.015-3.003-2.873-.736-.345-1.554-.585-1.797-1.14-.091-.33-.105-.51-.046-.705.15-.646.915-.84 1.515-.66.39.12.75.42.976.9 1.034-.676 1.034-.676 1.755-1.125-.27-.42-.404-.601-.586-.78-.63-.705-1.469-1.065-2.834-1.034l-.705.089c-.676.165-1.32.525-1.71 1.005-1.14 1.291-.811 3.541.569 4.471 1.365 1.02 3.361 1.244 3.616 2.205.24 1.17-.87 1.545-1.966 1.41-.811-.18-1.26-.586-1.755-1.336l-1.83 1.051c.21.48.45.689.81 1.109 1.74 1.756 6.09 1.666 6.871-1.004.029-.09.24-.705.074-1.65l.046.067zm-8.983-7.245h-2.248c0 1.938-.009 3.864-.009 5.805 0 1.232.063 2.363-.138 2.711-.33.689-1.18.601-1.566.48-.396-.196-.597-.466-.83-.855-.063-.105-.11-.196-.127-.196l-1.825 1.125c.305.63.75 1.172 1.324 1.517.855.51 2.004.675 3.207.405.783-.226 1.458-.691 1.811-1.411.51-.93.402-2.07.397-3.346.012-2.054 0-4.109 0-6.179l.004-.056z" />{" "}
  </>,
  24
);

export const plusMinusIcon = ptIconWrapper(
  <>
    <path d="M 12 2 C 11.448 2 11 2.448 11 3 L 11 8 L 6 8 C 5.448 8 5 8.448 5 9 C 5 9.552 5.448 10 6 10 L 11 10 L 11 15 C 11 15.552 11.448 16 12 16 C 12.552 16 13 15.552 13 15 L 13 10 L 18 10 C 18.552 10 19 9.552 19 9 C 19 8.448 18.552 8 18 8 L 13 8 L 13 3 C 13 2.448 12.552 2 12 2 Z M 6 18 C 5.448 18 5 18.448 5 19 C 5 19.552 5.448 20 6 20 L 18 20 C 18.552 20 19 19.552 19 19 C 19 18.448 18.552 18 18 18 L 6 18 Z" />{" "}
  </>,
  24
);
