import { GridApi, IRowNode } from "ag-grid-community";

export const AG_GRID_ACTIVE_ROW_CLASS = "ag-row-active";

export const expandRowNodePath = <T>(rowNode: IRowNode<T> | undefined) => {
  if (!rowNode) {
    return;
  }

  rowNode.setExpanded(true);

  if (rowNode.parent) {
    rowNode.parent.setExpanded(true);
    expandRowNodePath(rowNode.parent);
  }
};

export const updateRowsClass = (activeId: string | undefined, rowPrefix = "") => {
  setTimeout(() => {
    const rowsArray = Array.from(document.querySelectorAll(`div[row-id*='${rowPrefix}']`));
    rowsArray.forEach(r => {
      const id = r.getAttribute("row-id");
      if (`${rowPrefix}-${activeId}` === id) {
        r.classList.add(AG_GRID_ACTIVE_ROW_CLASS);
      } else {
        r.classList.remove(AG_GRID_ACTIVE_ROW_CLASS);
      }
    });
  }, 100);
};

export const resizeRow = (params: { container: HTMLElement; node: IRowNode; api: GridApi; slack?: number; resizeIfSmaller?: boolean }) => {
  const { container, node, api, resizeIfSmaller, slack = 0 } = params;
  // reset container height so that scrollHeight matches content height
  container.style.height = "1px";
  const { scrollHeight } = container;
  container.style.height = `${scrollHeight}px`;

  const expectedRowHeight = scrollHeight + slack;
  const rowHeight = node.rowHeight ?? 0;

  const shouldResize = () => {
    if (expectedRowHeight > rowHeight) {
      return true;
    } else if (resizeIfSmaller && expectedRowHeight < rowHeight) {
      return true;
    }
  };

  if (shouldResize()) {
    node.setRowHeight(expectedRowHeight);
    api.onRowHeightChanged();
  }
};
