import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ReactFlowProvider } from "reactflow";
import { Divider, NonIdealState, Tooltip } from "@blueprintjs/core";
import { PropertyDataType } from "@rollup-io/engineering";
import { observer } from "mobx-react";
import { useToggle } from "usehooks-ts";

import { Bar } from "@components/Bar/Bar";
import FlowViewProperties from "@components/Modeling/ModelingFrame/FlowView/FlowViewProperties/FlowViewProperties";
import { EntitySelectDropdown } from "@components/Shared/EntitySelectDropdown/EntitySelectDropdown";
import { Switch } from "@components/Switch";
import { useAppNavigate } from "@router/hooks";
import appStore from "@store/AppStore";
import { IBlock } from "@store/BlockStore";
import { IPropertyInstance } from "@store/PropertyInstanceStore";
import { getPropertyInstanceById } from "@utilities";
import { Text } from "src/ui/Text";

import "./ThreadsDag.scss";

function ThreadsDag() {
  const { propertyId } = useParams();
  const { navigateToPropertyDependency } = useAppNavigate();

  const [selectedBlock, setSelectedBlock] = useState<IBlock | undefined>(undefined);
  const [selectedProperty, setSelectedProperty] = useState<IPropertyInstance | undefined>(undefined);
  const [showRollups, toggleShowRollups] = useToggle(true);
  const [showFullSubgraph, toggleShowFullSubgraph] = useToggle(true);
  // Set the selected block and property based on the URL
  useEffect(() => {
    const property = propertyId ? getPropertyInstanceById(propertyId) : undefined;
    if (property?.parentBlock) {
      setSelectedBlock(property.parentBlock);
      appStore.env.setActivePropertyInstance(property);
    }
    setSelectedProperty(property);
  }, [propertyId]);

  // If the selected block changes, update the selected property
  useEffect(() => {
    if (selectedBlock && selectedProperty && selectedProperty.parentBlock !== selectedBlock) {
      // Set to the same property on the new block
      const newProperty = selectedBlock.propertyInstances?.find(p => p.propertyDefinition === selectedProperty.propertyDefinition);
      setSelectedProperty(newProperty);
    }
  }, [selectedBlock, selectedProperty]);

  // If the selected property changes, update the URL
  useEffect(() => {
    if (selectedProperty && selectedProperty.id !== propertyId) {
      navigateToPropertyDependency(selectedProperty.id);
    }
  }, [navigateToPropertyDependency, propertyId, selectedProperty]);

  const validRecentProperties = appStore.env.recentPropertyIds.filter(id => getPropertyInstanceById(id));
  const recentPropertyList =
    validRecentProperties.length > 0 ? (
      <>
        <Divider />
        <div className="recent-properties">
          <Text>Recent Properties</Text>
          {validRecentProperties.slice(0, 5).map(id => {
            const property = getPropertyInstanceById(id);
            if (!property?.parentBlock || !property.propertyDefinition) {
              return null;
            }
            return (
              <a key={id} onClick={() => navigateToPropertyDependency(id)}>
                {property.parentBlock.label}:{property.propertyDefinition.label}
              </a>
            );
          })}
        </div>
      </>
    ) : null;

  const propertyDropdown = (
    <div className="property-dropdown">
      <EntitySelectDropdown<IBlock>
        selectedItem={selectedBlock}
        items={appStore.workspaceModel?.modelBlocks ?? []}
        onItemSelect={setSelectedBlock}
        placeholder="Select block"
        popoverProps={{ minimal: true, popoverClassName: "property-dropdown-popover" }}
      />
      <EntitySelectDropdown<IPropertyInstance>
        selectedItem={selectedProperty}
        items={selectedBlock?.propertyInstances?.filter(p => p?.propertyDefinition?.dataType === PropertyDataType.scalar) ?? []}
        onItemSelect={setSelectedProperty}
        placeholder="Select property"
        disabled={!selectedBlock}
        popoverProps={{ minimal: true, popoverClassName: "property-dropdown-popover" }}
      />
    </div>
  );

  return (
    <div className="threads-dag">
      <Bar className="threads-bar">
        <Text>Property Relations and Traces</Text>
        <Tooltip
          hoverOpenDelay={500}
          content="Rollup connections must be displayed when full subgraph is shown"
          disabled={!showFullSubgraph}
        >
          <Switch
            label="Show Rollups"
            disabled={showFullSubgraph}
            checked={showFullSubgraph ? true : showRollups}
            onChange={toggleShowRollups}
            e2eIdentifiers="show-rollups"
          />
        </Tooltip>
        <Switch
          label="Show Full Dependency Subgraph"
          checked={showFullSubgraph}
          onChange={toggleShowFullSubgraph}
          e2eIdentifiers="show-full-dependency-subgraph"
        />
        {propertyDropdown}
      </Bar>
      {selectedProperty ? (
        <ReactFlowProvider>
          <FlowViewProperties propertyInstance={selectedProperty} showRollups={showRollups} showFullSubgraph={showFullSubgraph} />
        </ReactFlowProvider>
      ) : (
        <NonIdealState icon="info-sign" title="Select a property to trace" description="Only scalar properties are supported">
          {propertyDropdown}
          {recentPropertyList}
        </NonIdealState>
      )}
    </div>
  );
}

export default observer(ThreadsDag);
