import { ChangeEvent } from "react";
import { Alignment, Checkbox, Icon, Menu, MenuDivider, Tooltip } from "@blueprintjs/core";
import { observer } from "mobx-react";

import {
  AssemblyDark,
  AssemblyLight,
  BlockPartNumberDark,
  BlockPartNumberLight,
  ProductDark,
  ProductLight,
} from "@assets/icons/blockTypes";
import appStore from "@store/AppStore";
import { BlockType, IBlock } from "@store/BlockStore";

import "./BlockTypeSelectorMenu.scss";

type BlockIconSelectorMenuProps = {
  block: IBlock;
};

function BlockTypeSelectorMenu({ block }: BlockIconSelectorMenuProps) {
  const themeName = appStore.env.themeIsDark ? "dark" : "light";

  return (
    <Menu className="block-type-selector-menu">
      <MenuDivider title="Set Block type" />
      <Tooltip
        popoverClassName="block-type-selector-menu--tooltip"
        content="Products are self-contained things that can be packaged and distributed"
      >
        <Checkbox
          className="block-type-selector-menu--checkbox"
          alignIndicator={Alignment.RIGHT}
          onChange={(e: ChangeEvent<HTMLInputElement>) => block.toggleType(BlockType.Product, e.target.checked)}
          checked={block.type.includes(BlockType.Product)}
        >
          <div className="block-type-selector-menu--checkbox-content">
            {themeName === "dark" ? <ProductDark /> : <ProductLight />}
            <div className="block-type-selector-menu--label">{BlockType.Product}</div>
          </div>
        </Checkbox>
      </Tooltip>
      <Tooltip
        popoverClassName="block-type-selector-menu--tooltip"
        content="Assemblies are physical systems composed of parts and sub-assemblies"
      >
        <Checkbox
          className="block-type-selector-menu--checkbox"
          alignIndicator={Alignment.RIGHT}
          onChange={(e: ChangeEvent<HTMLInputElement>) => block.toggleType(BlockType.Assembly, e.target.checked)}
          checked={block.type.includes(BlockType.Assembly)}
        >
          <div className="block-type-selector-menu--checkbox-content">
            {themeName === "dark" ? <AssemblyDark /> : <AssemblyLight />}
            <span className="block-type-selector-menu--label">{BlockType.Assembly}</span>
          </div>
        </Checkbox>
      </Tooltip>
      <Tooltip popoverClassName="block-type-selector-menu--tooltip" content="Subsystems are composed of smaller elements, that are related">
        <Checkbox
          className="block-type-selector-menu--checkbox"
          alignIndicator={Alignment.RIGHT}
          onChange={(e: ChangeEvent<HTMLInputElement>) => block.toggleType(BlockType.Subsystem, e.target.checked)}
          checked={block.type.includes(BlockType.Subsystem)}
        >
          <div className="block-type-selector-menu--checkbox-content">
            <Icon icon="data-lineage" />
            <span>{BlockType.Subsystem}</span>
          </div>
        </Checkbox>
      </Tooltip>
      <Tooltip
        popoverClassName="block-type-selector-menu--tooltip"
        disabled={!block.children?.length}
        content="A part is an atomic physical object. They cannot have children."
      >
        <Checkbox
          className="block-type-selector-menu--checkbox"
          disabled={!!block.children?.length}
          alignIndicator={Alignment.RIGHT}
          onChange={(e: ChangeEvent<HTMLInputElement>) => block.toggleType(BlockType.Part, e.target.checked)}
          checked={block.type.includes(BlockType.Part)}
        >
          <div className="block-type-selector-menu--checkbox-content">
            {themeName === "dark" ? <BlockPartNumberDark /> : <BlockPartNumberLight />}
            <span className="block-type-selector-menu--label">{BlockType.Part}</span>
          </div>
        </Checkbox>
      </Tooltip>
    </Menu>
  );
}

export default observer(BlockTypeSelectorMenu);
