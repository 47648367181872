import { HTMLProps } from "react";
import classNames from "classnames";

/** Type defs. */
type PaneGroupProps = HTMLProps<HTMLDivElement>;

/** Main function. */
function PaneGroup({ className, ...rest }: PaneGroupProps) {
  // IMPORTANT: Pane only plays nice with immediate "<Pane>" children components.
  return <div className={classNames("component--pane-group flex flex-1 overflow-hidden", className)} {...rest} />;
}

/** Exports. */
export type { PaneGroupProps };
export default PaneGroup;
