import { ButtonGroup } from "@blueprintjs/core";
import classNames from "classnames";

import HoopsDebugButton from "./HoopsMenuButtonGroups/HoopsDebugButton";
import HoopsMenuCuttingPlaneButtonGroup from "./HoopsMenuButtonGroups/HoopsMenuCuttingPlaneButtonGroup";
import HoopsMenuExplodeButton from "./HoopsMenuButtonGroups/HoopsMenuExplodeButton";
import HoopsMenuHomeButton from "./HoopsMenuButtonGroups/HoopsMenuHomeButton";
import HoopsMenuMeasureButtonGroup from "./HoopsMenuButtonGroups/HoopsMenuMeasureButtonGroup";
import HoopsMenuOperatorButtonGroup from "./HoopsMenuButtonGroups/HoopsMenuOperatorButtonGroup";
import HoopsMenuPartViewButtonGroup from "./HoopsMenuButtonGroups/HoopsMenuPartViewButtonGroup";
import HoopsMenuPresetViewButtonGroup from "./HoopsMenuButtonGroups/HoopsMenuPresetViewButtonGroup";
import HoopsMenuProjectionButton from "./HoopsMenuButtonGroups/HoopsMenuProjectionButton";
import HoopsMenuRenderModeButtonGroup from "./HoopsMenuButtonGroups/HoopsMenuRenderModeButtonGroup";
import HoopsMenuSelectButton from "./HoopsMenuButtonGroups/HoopsMenuSelectButton";

import styles from "./HoopsMenu.module.scss";

type HoopsMenuBottomProps = {
  viewer: Communicator.WebViewer;
  renderMode?: Communicator.DrawMode;
};

const HoopsMenuBottom = ({ viewer, renderMode = Communicator.DrawMode.HiddenLine }: HoopsMenuBottomProps) => {
  return (
    <ButtonGroup large className={classNames(styles.hoopsFloatingMenu, styles.bottom)}>
      <HoopsMenuSelectButton viewer={viewer} />
      <HoopsMenuHomeButton viewer={viewer} />
      <HoopsMenuProjectionButton viewer={viewer} />
      <HoopsMenuRenderModeButtonGroup viewer={viewer} renderMode={renderMode} />
      <HoopsMenuPresetViewButtonGroup viewer={viewer} />
      <HoopsMenuOperatorButtonGroup viewer={viewer} />
      <HoopsMenuPartViewButtonGroup viewer={viewer} />
      <HoopsMenuExplodeButton viewer={viewer} />
      <HoopsMenuCuttingPlaneButtonGroup viewer={viewer} />
      <HoopsMenuMeasureButtonGroup viewer={viewer} />
      <HoopsDebugButton />
    </ButtonGroup>
  );
};

export default HoopsMenuBottom;
