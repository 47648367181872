import { ReactNode } from "react";
import { Classes } from "@blueprintjs/core";
import classNames from "classnames";
import { observer } from "mobx-react-lite";

import { DialogLegacy } from "@components/Dialog";

import "./AttachmentDetailsDialog.scss";

interface IAttachmentDetailsDialogProps {
  isOpen: boolean;
  children: ReactNode;
  label: string;
  onClose(): void;
}

const AttachmentDetailsDialog = (props: IAttachmentDetailsDialogProps) => {
  return (
    <DialogLegacy
      icon="info-sign"
      className="attachment-details-dialog"
      isOpen={props.isOpen}
      onClose={props.onClose}
      title={`Attachment details: ${props.label}`}
    >
      <div className={classNames(Classes.DIALOG_BODY, "flex")}>{props.children}</div>
    </DialogLegacy>
  );
};

export default observer(AttachmentDetailsDialog);
