import { IAnyModelType, Instance, types } from "mobx-state-tree";
import { Socket } from "socket.io-client";

import { CreateCatalogItemReferenceDto } from "@rollup-api/models/catalogItem/catalogItemDtos";
import appStore from "@store/AppStore";
import { CatalogItemStore } from "@store/CatalogItem/CatalogItemStore";

export const CatalogItemReferenceStore = types.model("CatalogItemReference", {
  id: types.identifier,
  parentCatalogItem: types.safeReference(types.late((): IAnyModelType => CatalogItemStore)),
  referencedCatalogItem: types.safeReference(types.late((): IAnyModelType => CatalogItemStore)),
  nodeId: types.maybeNull(types.string),
  refId: types.maybeNull(types.string),
  name: types.maybeNull(types.string),
});

export interface ICatalogItemReference extends Instance<typeof CatalogItemReferenceStore> {}

export function subscribeToCatalogItemReferenceEvents(socket: Socket) {
  socket.on("createCatalogItemReference", (data: { createCatalogItemReferenceDto: CreateCatalogItemReferenceDto }) => {
    if (data.createCatalogItemReferenceDto.id) {
      appStore.orgModel.catalogItems.addCatalogItemReference(data.createCatalogItemReferenceDto, false);
    }
  });

  socket.on("deleteCatalogItemReference", (data: { id: string }) => {
    if (data.id) {
      appStore.orgModel.catalogItems.removeCatalogItemReference(data.id, false);
    }
  });

  socket.on("deleteCatalogItemReferences", (data: { ids: string[] }) => {
    if (data.ids) {
      appStore.orgModel.catalogItems.bulkRemoveCatalogItemReference(data.ids, false);
    }
  });
}
