import { Placeholder } from "@tiptap/extension-placeholder";

const RollupPlaceholder = Placeholder.configure({
  placeholder: p => {
    const reportEditorElements = document.querySelectorAll(".report-block .report-editor");

    if (reportEditorElements.length === 1) {
      return "Press '/' for commands or start typing text";
    }

    return p.editor.isFocused ? "Press '/' for commands or start typing text" : "";
  },
});

export default RollupPlaceholder;
