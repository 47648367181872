import { useEffect, useState } from "react";
import { FormGroup, InputGroup, Intent, TextArea } from "@blueprintjs/core";
import { observer } from "mobx-react";

import { Button } from "@components/Button";
import { TagInput } from "@components/TagInput";
import { showToast } from "@components/UiLayers/toaster";
import UploadFileButton from "@components/UploadFileButton/UploadFileButton";
import { UserPermission } from "@rollup-api/api/authTypes";
import { OrganizationUpdateDto } from "@rollup-api/models/organizations";
import appStore from "@store/AppStore";
import { createDataTestId, ElementType } from "@utilities/E2EUtils";
import { isValidDomain } from "@utilities/Validation";
import { Text, TextVariant } from "src/ui/Text";

import { LogoBackgroundDialog } from "./LogoBackgroundDialog";

import "./SettingsGeneral.scss";

/** Main function. */
const SettingsGeneral = () => {
  const { description: modelDescription, allowedDomains: modelAllowedDomains, name: modelName } = appStore.orgModel.info;
  const [description, setDescription] = useState(modelDescription);
  const [name, setName] = useState(modelName);
  const [companyDomains, setCompanyDomains] = useState<string[]>(modelAllowedDomains);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLogoDialogOpen, setLogoDialogOpen] = useState(false);

  useEffect(() => {
    setDescription(modelDescription);
    setName(modelName);
    setCompanyDomains(modelAllowedDomains);
  }, [modelDescription, modelAllowedDomains, modelName]);

  const validateForm = () => {
    return name && companyDomains && companyDomains.length > 0;
  };

  const handleSubmitClicked = async () => {
    if (validateForm()) {
      const dto: OrganizationUpdateDto = {
        name: name,
        description: description,
        allowedDomains: companyDomains?.map(d => d.trim()) ?? [],
      };
      setIsSubmitting(true);

      await appStore.pushOrgChanges(dto);
      setIsSubmitting(false);
    }
  };

  const onUploadLogo = async (file: FileList) => {
    await appStore.orgModel?.info?.uploadLogo(file);
    if (appStore.orgModel?.info?.logoUrl) {
      setLogoDialogOpen(true);
    }
  };

  const handleTagInputChange = (values: string[]) => {
    const validDomains = values.filter(isValidDomain);
    const uniqueDomains = [...new Set(validDomains)];

    if (validDomains.length !== uniqueDomains.length) {
      showToast("Duplicate domain", Intent.WARNING);
    }

    setCompanyDomains(uniqueDomains);
  };

  const logoUrl = appStore.orgModel?.info?.logoUrl;

  return (
    <div className="settings-layout--content">
      {logoUrl && <LogoBackgroundDialog logoUrl={logoUrl} isOpen={isLogoDialogOpen} onClose={() => setLogoDialogOpen(false)} />}
      <div className="settings-layout--header">
        <Text variant={TextVariant.H1}>General settings</Text>
      </div>
      <div className="org-profile-area">
        <div className="org-profile-area--avatar">
          {logoUrl && <img className="org-profile-area--avatar-image" src={logoUrl} alt="Company logo" />}
        </div>
        <UploadFileButton
          large
          className="upload-button"
          text={logoUrl ? "Upload new logo" : "Upload company logo"}
          icon="media"
          onChange={onUploadLogo}
          e2eIdentifiers="upload-company-logo"
        />
      </div>
      <div className="org-profile-area">
        <FormGroup className="org-profile-area--label" label="Organization name" labelFor="organization-name">
          <InputGroup
            id="organization-name"
            intent={name !== appStore.orgModel?.info?.name ? Intent.PRIMARY : undefined}
            value={name}
            onChange={e => setName(e.target.value)}
            placeholder="Enter organization name..."
            disabled={!appStore.userModel?.hasPermission(UserPermission.UpdateOrganization)}
            large
          />
        </FormGroup>
      </div>
      <FormGroup className="org-profile-area--label" label="Description" labelFor="organization-description">
        <div className="org-profile-area--description-box">
          <TextArea
            id="organization-description"
            className="org-profile-area--description"
            intent={description !== appStore.orgModel?.info?.description ? Intent.PRIMARY : undefined}
            value={description}
            onChange={e => setDescription(e.target.value)}
            placeholder="Enter organization description..."
            disabled={!appStore.userModel?.hasPermission(UserPermission.UpdateOrganization)}
            autoResize
            large
          />
        </div>
      </FormGroup>

      <FormGroup className="org-profile-area--label" label="Company domains" labelFor="company-domains">
        <div {...createDataTestId(ElementType.Input, "company-domains")}>
          <TagInput inputProps={{ id: "company-domains" }} onChange={handleTagInputChange} values={companyDomains} />
        </div>
        <Text variant={TextVariant.Caption}>A comma-separated list of email domains anyone can join your workspace from.</Text>
      </FormGroup>
      <Button
        large
        className="settings-layout--save"
        intent="primary"
        onClick={handleSubmitClicked}
        loading={isSubmitting}
        e2eIdentifiers={["settings", "submit"]}
      >
        Update
      </Button>
    </div>
  );
};

/** Exports. */
export default observer(SettingsGeneral);
