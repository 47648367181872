// Promise wrapper for setTimeout in order to delay operation in an async function
export function delay(time: number) {
  return new Promise(resolve => {
    setTimeout(resolve, time);
  });
}

// Wraps an async interval callback, with the delay between the function finishing and
export function setIntervalAsync(callback: () => Promise<void> | void, interval: number, errCallback?: (err: any) => void) {
  const handleObj: { handle: any; interval: number } = { handle: undefined, interval };
  const wrappedCallback = async () => {
    clearTimeout(handleObj.handle);
    try {
      await callback();
    } catch (err) {
      if (errCallback) {
        errCallback(err);
      }
    }
    handleObj.handle = setTimeout(wrappedCallback, handleObj.interval);
  };

  handleObj.handle = setTimeout(wrappedCallback, handleObj.interval);
  return handleObj;
}

export function clearIntervalAsync(handleObj: { handle: any }) {
  clearTimeout(handleObj?.handle);
}

export function updateIntervalAsync(handleObj: { interval: number }, newInterval: number) {
  handleObj.interval = newInterval;
}
