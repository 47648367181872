import { BlueprintIcon } from "@ui/BlueprintIcon";
import { observer } from "mobx-react-lite";

import SyncFilterableMenu from "@components/Shared/SyncFilterableMenu";
import appStore from "@store/AppStore";
import { IBlock } from "@store/BlockStore";

interface IBlockFilterableMenuProps {
  onSelect(block: IBlock): void;
}

const BlockFilterableMenu = (props: IBlockFilterableMenuProps) => {
  const renderItem = (block: IBlock) => {
    return (
      <>
        <BlueprintIcon icon="layers" />
        <span>{block.label}</span>
      </>
    );
  };

  return (
    <SyncFilterableMenu<IBlock>
      items={appStore.workspaceModel?.blocks ?? []}
      title="Select Block to Add to"
      onSelect={props.onSelect}
      renderItem={renderItem}
      e2eIdentifiers="add-to-block-attachments"
    />
  );
};

export default observer(BlockFilterableMenu);
