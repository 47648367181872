import { Text, TextVariant } from "@ui/Text";
import { observer } from "mobx-react";

const BillingSupportNote = () => {
  return (
    <Text variant={TextVariant.BodyDimmed}>
      To cancel your plan or to get help, please reach out via the help widget or contact Rollup support at{" "}
      <a href="mailto:support+billing@rollup.ai">support+billing@rollup.ai</a>.
    </Text>
  );
};

export default observer(BillingSupportNote);
