import { KeyboardEvent, ReactNode, useEffect, useRef, useState } from "react";
import { Tag, TagProps } from "@blueprintjs/core";
import { ItemRendererProps, MultiSelect } from "@blueprintjs/select";
import classNames from "classnames";
import { observer } from "mobx-react";
import { isAlive } from "mobx-state-tree";

import { MenuItem } from "@components/MenuItem";
import { PlusButton } from "@components/PlusButton";
import appStore from "@store/AppStore";
import type { IStatusDefinition } from "@store/StatusDefinitionStore";
import { StatusType } from "@store/StatusDefinitionStore";
import { IStatusOption } from "@store/StatusOptionStore";
import { getIntentClassFromColor } from "@utilities";

import MenuItemOption from "./MenuItemOption";

import "./OptionsCell.scss";

type OptionCellProps = {
  statusDefinition: IStatusDefinition;
};

const OptionsCell = (props: OptionCellProps) => {
  const statusDefinition = appStore.workspaceModel?.statusDefinitionMap.get(props.statusDefinition.id);
  const [inputValue, setInputValue] = useState("");
  const [showInput, setShowInput] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const statusOptions = statusDefinition.statusOptions || [];
  const optionsAreApplicable = statusDefinition.type === StatusType.multiSelect || statusDefinition.type === StatusType.singleSelect;

  useEffect(() => {
    if (showInput) {
      inputRef.current?.focus();
    }
  }, [showInput]);

  if (!optionsAreApplicable) {
    return <div className="options-cell--not-applicable">No options can be applied to this type</div>;
  }

  const handleAddNewStatusOption = () => {
    appStore.workspaceModel?.addNewStatusOption(statusDefinition, inputValue);
    setInputValue("");
  };

  const handleItemPredicate = (query: string, option: IStatusOption) => {
    return option.label.toLowerCase().indexOf(query.toLowerCase()) >= 0;
  };

  const handleDeleteStatusOption = (option: IStatusOption) => {
    appStore.workspaceModel?.deleteStatusOption(option);
  };

  const renderItem = (statusOption: IStatusOption, itemProps: ItemRendererProps) => {
    const { handleClick, modifiers } = itemProps;

    return isAlive(statusOption) ? (
      <MenuItemOption key={statusOption.id} statusOption={statusOption} handleClick={handleClick} modifiers={modifiers} />
    ) : null;
  };

  const renderAddNewItem = (query: string) => (
    <MenuItem
      icon="add"
      text={
        <div className="flex gap-2">
          <div>Create</div>
          <Tag intent="primary">{query}</Tag>
        </div>
      }
      onClick={handleAddNewStatusOption}
      e2eIdentifiers="create"
    />
  );

  const tagProps = (value: ReactNode): TagProps => {
    const option = statusDefinition.statusOptions.find((option: IStatusOption) => option.label === value);

    return {
      minimal: false,
      className: getIntentClassFromColor(option?.color),
    };
  };

  const onBlur = () => {
    setShowInput(false);
    setInputValue("");
  };

  const onKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      handleAddNewStatusOption();
    }
  };

  return (
    <MultiSelect<IStatusOption>
      className="options-cell"
      query={inputValue}
      onQueryChange={setInputValue}
      placeholder=""
      resetOnSelect
      resetOnQuery
      onItemSelect={() => null}
      itemPredicate={handleItemPredicate}
      selectedItems={statusOptions}
      onRemove={handleDeleteStatusOption}
      popoverProps={{ minimal: true, matchTargetWidth: true }}
      tagInputProps={{
        tagProps,
        inputRef,
        className: "options-cell--tag-input",
        inputProps: {
          className: classNames("options-cell--input", { "options-cell--input-hidden": !showInput }),
          onBlur,
          onKeyDown,
        },
        children: !showInput && <PlusButton onClick={() => setShowInput(true)} e2eIdentifiers="add-option" />,
      }}
      itemsEqual={(a: IStatusOption, b: IStatusOption) => isAlive(a) && isAlive(b) && a.id === b.id}
      tagRenderer={(option: IStatusOption) => option.label}
      items={statusDefinition.statusOptions.filter(isAlive)}
      noResults={<MenuItem disabled text="No results. Type to create an option" e2eIdentifiers="no-results" />}
      itemRenderer={renderItem}
      createNewItemFromQuery={() => null as unknown as IStatusOption}
      createNewItemRenderer={renderAddNewItem}
    />
  );
};

export default observer(OptionsCell);
