import { ChangeEvent, useCallback, useEffect, useState } from "react";
import { Checkbox, InputGroup, Label } from "@blueprintjs/core";
import { observer } from "mobx-react";

import { Button } from "@components/Button";
import { CreateApiKeyDto } from "@rollup-api/models/apiKeys";
import { FeatureScope, FeatureSubScope, ScopeRoot } from "@rollup-api/models/scope";
import { rollupClient } from "src/core/api";
import { Text, TextVariant } from "src/ui/Text";

import "./SettingsApiKeys.scss";

interface SettingsAddNewApiKeyProps {
  onClose?: () => void;
  apiId?: string;
}

/** Main function. */
const SettingsAddNewApiKey = ({ onClose, apiId }: SettingsAddNewApiKeyProps) => {
  const [apiKeyLabel, setApiKeyLabel] = useState("");
  const [apiKeyScopes, setApiKeyScopes] = useState([""]);
  const [allScopes, setAllScopes] = useState<FeatureScope[]>();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const operators = ["create", "read", "delete", "edit"];

  useEffect(() => {
    const fetchApiKey = async (apiId: string) => {
      const apiKey = await rollupClient.apiKeys.retrieveById(apiId);
      if (apiKey) {
        setApiKeyLabel(apiKey.label);
        if (apiKey.scopes) {
          setApiKeyScopes(apiKey.scopes);
        }
      }
    };
    const fetchScopes = async () => {
      const scopes = await rollupClient.generalInfo.getAllScopes();
      if (scopes) {
        setAllScopes(scopes);
      }
    };
    if (apiId) {
      fetchApiKey(apiId);
    }
    fetchScopes();
  }, [apiId]);

  const resetValues = () => {
    setApiKeyLabel("");
    setApiKeyScopes([""]);
  };

  const validateForm = () => {
    return apiKeyLabel && apiKeyScopes.length > 0;
  };

  const handleSubmitClicked = async () => {
    if (validateForm()) {
      const dto: CreateApiKeyDto = {
        label: apiKeyLabel,
        scopes: apiKeyScopes,
      };
      setIsSubmitting(true);
      if (apiId) {
        await rollupClient.apiKeys.update(apiId, dto);
      } else {
        if (dto.scopes && dto.scopes.length > 0) {
          await rollupClient.apiKeys.create(dto);
        }
      }
      setIsSubmitting(false);
      resetValues();
      onClose?.();
    }
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>, scope?: FeatureScope) => {
    const value = event.target.value;

    if (apiKeyScopes) {
      if (scope && allOptionsSelected(scope)) {
        setApiKeyScopes(apiKeyScopes.filter(scope => !scope.includes(scope)));
        return;
      }

      if (apiKeyScopes.includes(value)) {
        setApiKeyScopes(apiKeyScopes.filter(scope => scope !== value));
      } else {
        setApiKeyScopes([...apiKeyScopes, value]);
      }
    }
  };

  const isIndeterminate = useCallback(
    (scopeRoot: ScopeRoot) => {
      return !apiKeyScopes?.includes(scopeRoot.value) && apiKeyScopes?.some(v => v.includes(scopeRoot.value));
    },
    [apiKeyScopes]
  );

  const allOptionsSelected = (scope: FeatureScope) =>
    operators.every(operator => {
      const key = operator as keyof FeatureSubScope;
      // Checks whether all available scopes are selected
      const subScope = scope.scopes?.[key];
      return !subScope || apiKeyScopes?.includes(subScope.value);
    });

  const renderCheckbox = (scopeRoot?: ScopeRoot, forceChecked?: boolean) => {
    if (!scopeRoot) {
      return null;
    }

    return (
      <div>
        <Checkbox
          disabled={forceChecked}
          value={scopeRoot.value}
          onChange={handleChange}
          label={scopeRoot.value}
          checked={forceChecked ? true : apiKeyScopes?.includes(scopeRoot.value)}
        />
        <div className="api-scope-picker--description">{scopeRoot.description}</div>
      </div>
    );
  };

  const renderScopeBlock = (scope: FeatureScope) => {
    const forceChecked = apiKeyScopes?.includes(scope.value);

    return (
      <div key={scope.value} className="api-scope-picker--list">
        <div>
          <Checkbox
            value={scope.value}
            label={scope.value}
            onChange={(event: ChangeEvent<HTMLInputElement>) => handleChange(event, scope)}
            indeterminate={isIndeterminate(scope) && !allOptionsSelected(scope)}
            checked={apiKeyScopes?.includes(scope.value) || allOptionsSelected(scope)}
          />
          <div className="api-scope-picker--description">{scope.description}</div>
        </div>
        <div className="api-scope-picker--sub-list">
          {renderCheckbox(scope.scopes.create, forceChecked)}
          {renderCheckbox(scope.scopes.read, forceChecked)}
          {renderCheckbox(scope.scopes.edit, forceChecked)}
          {renderCheckbox(scope.scopes.delete, forceChecked)}
        </div>
      </div>
    );
  };

  return (
    <div className="settings-layout--content">
      <div className="new-api-key">
        <Button
          large
          className="settings-layout--back"
          minimal
          icon="arrow-left"
          onClick={() => {
            onClose?.();
          }}
          fill={false}
          e2eIdentifiers="go-back"
        >
          Back
        </Button>
        <div className="settings-layout--header">
          <Text variant={TextVariant.H1}>{`${apiId ? "Edit" : "Create"} API key`}</Text>
          <span />
          <Button
            large
            className="settings-layout--save"
            intent="primary"
            onClick={handleSubmitClicked}
            loading={isSubmitting}
            e2eIdentifiers="update-create"
          >
            {apiId ? "Update" : "Create"}
          </Button>
        </div>
        <Label className="name-email-area--label">
          API key label
          <InputGroup
            className="settings-layout--search"
            large
            value={apiKeyLabel}
            onChange={e => setApiKeyLabel(e.target.value)}
            placeholder="Enter API key label..."
          />
        </Label>

        <div className="api-scope-picker">{allScopes?.map(renderScopeBlock)}</div>

        <Button
          large
          className="settings-layout--save"
          intent="primary"
          onClick={handleSubmitClicked}
          loading={isSubmitting}
          e2eIdentifiers="update-create"
        >
          {apiId ? "Update" : "Create"}
        </Button>
      </div>
    </div>
  );
};

/** Exports. */
export default observer(SettingsAddNewApiKey);
