import classNames from "classnames";

import { BlockExtendedModel } from "@rollup-api/models";

export const getCatalogItemSelectionBlockPath = (block: BlockExtendedModel, blocks: BlockExtendedModel[]): string[] => {
  const path: string[] = [];

  let currentBlock = block;
  while (currentBlock) {
    path.push(currentBlock.id);
    currentBlock = blocks.find(b => b.id === currentBlock.parentBlock?.id)!;
  }

  return path.reverse();
};

export const highlightMatchingText = (text: string, searchValue: string): React.ReactNode => {
  const parts = text.toLowerCase().split(new RegExp(`(${searchValue})`, "gi"));

  return (
    <span>
      {parts.map((part, index) => (
        <span key={index} className={classNames({ "block-mode--highlighted-text": part.toLowerCase() === searchValue.toLowerCase() })}>
          {part}
        </span>
      ))}
    </span>
  );
};
