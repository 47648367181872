export type SlackChannel = {
  id: string;
  name: string;
  is_archived: boolean;
  created: number;
  updated: number;
};

export type LinkChannelDto = {
  id: string;
  entityTypes: SlackEventTypes[];
};

export enum SlackEventTypes {
  BLOCK = "block",
  CHILD_BLOCK = "child-block",
  PROPERTY_DEFINITION = "property-definition",
  PROPERTY_INSTANCE = "property-instance",
  STATUS_DEFINITION = "status-definition",
  STATUS_INSTANCE = "status-instance",
  COMMENT = "comment",
  ATTACHMENT = "cloud-storage",
  REPORT = "report",
  REPORT_BLOCK = "report-block",
}

export type SlackIntegrationMetaData = {
  appId: string;
  team: {
    id: string;
    name: string;
  };
  authedUser: {
    id: string;
  };
  channels: Array<LinkChannelDto>;
};

export const supportSlackEventTypes = Object.values(SlackEventTypes);
