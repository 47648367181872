import { cast, types } from "mobx-state-tree";

export enum BlockSidePanel {
  HistoryAndComments = "HistoryAndComments",
  None = "None",
  Programmatics = "Programmatics",
  PropertyDetails = "PropertyDetails",
}

export enum ReqSidePanel {
  Comments = "Comments",
  RequirementDetails = "RequirementDetails",
  None = "None",
}

export enum FeatureSidePanel {
  Comments = "Comments",
  None = "None",
}

export const SidePanelStore = types
  .model("SidePanel", {
    activeBlockSidePanel: types.optional(types.enumeration(Object.values(BlockSidePanel)), BlockSidePanel.Programmatics),
    activeReqSidePanel: types.optional(types.enumeration(Object.values(ReqSidePanel)), ReqSidePanel.Comments),
    activeKnowledgebaseSidePanel: types.optional(types.enumeration(Object.values(FeatureSidePanel)), FeatureSidePanel.Comments),
    pinnedBlockSidePanels: types.array(types.enumeration(Object.values(BlockSidePanel))),
    pinnedReqSidePanels: types.array(types.enumeration(Object.values(ReqSidePanel))),
    pinnedKnowledgebaseSidePanels: types.array(types.enumeration(Object.values(FeatureSidePanel))),
  })
  .actions(self => ({
    hideBlockSidePanel() {
      this.setBlockSidePanel(BlockSidePanel.None);
    },
    hideReqSidePanel() {
      this.setReqSidePanel(ReqSidePanel.None);
    },
    hideKnowledgebaseSidePanel() {
      this.setKnowledgebaseSidePanel(FeatureSidePanel.None);
    },
    setBlockSidePanel(panel: BlockSidePanel) {
      self.activeBlockSidePanel = panel;
    },
    setReqSidePanel(panel: ReqSidePanel) {
      self.activeReqSidePanel = panel;
    },
    setKnowledgebaseSidePanel(panel: FeatureSidePanel) {
      self.activeKnowledgebaseSidePanel = panel;
    },
    togglePinnedBlockSidePanel(panel: BlockSidePanel) {
      if (self.pinnedBlockSidePanels.includes(panel)) {
        self.pinnedBlockSidePanels = cast(self.pinnedBlockSidePanels.filter(p => p !== panel));
      } else {
        self.pinnedBlockSidePanels.push(panel);
      }
    },
    togglePinnedReqSidePanel(panel: ReqSidePanel) {
      if (self.pinnedReqSidePanels.includes(panel)) {
        self.pinnedReqSidePanels = cast(self.pinnedReqSidePanels.filter(p => p !== panel));
      } else {
        self.pinnedReqSidePanels.push(panel);
      }
    },
    togglePinnedKnowledgebaseSidePanel(panel: FeatureSidePanel) {
      if (self.pinnedKnowledgebaseSidePanels.includes(panel)) {
        self.pinnedKnowledgebaseSidePanels = cast(self.pinnedKnowledgebaseSidePanels.filter(p => p !== panel));
      } else {
        self.pinnedKnowledgebaseSidePanels.push(panel);
      }
    },
  }));
