import { Colors } from "@blueprintjs/colors";
import { Classes, Intent } from "@blueprintjs/core";
import tinycolor from "tinycolor2";

import { Color } from "@rollup-types/colors";

export interface ITagColor {
  name: string;
  value: string;
}

export type ExtendedIntent =
  | Intent
  | "red"
  | "green"
  | "blue"
  | "orange"
  | "cerulean"
  | "violet"
  | "rose"
  | "vermilion"
  | "indigo"
  | "lime"
  | "forest"
  | "turquoise"
  | "gold"
  | "sepia"
  | "none";

// Note: CERULEAN and VERMILION were purposefully left out because CERULEAN is too close to BLUE and VERMILION is too close to RED
export const TAG_COLORS: ITagColor[] = [
  {
    name: "Gray",
    value: Colors.GRAY1,
  },
  {
    name: "Blue",
    value: Colors.BLUE3,
  },
  {
    name: "Indigo",
    value: Colors.INDIGO3,
  },
  {
    name: "Violet",
    value: Colors.VIOLET3,
  },
  {
    name: "Rose",
    value: Colors.ROSE3,
  },
  {
    name: "Red",
    value: Colors.RED3,
  },
  {
    name: "Orange",
    value: Colors.ORANGE3,
  },
  {
    name: "Sepia",
    value: Colors.SEPIA3,
  },
  {
    name: "Gold",
    value: Colors.GOLD3,
  },
  {
    name: "Lime",
    value: Colors.LIME3,
  },
  {
    name: "Forest",
    value: Colors.FOREST3,
  },
  {
    name: "Turquoise",
    value: Colors.TURQUOISE3,
  },
];

export function getExtendedIntentFromColor(color: string): ExtendedIntent {
  // For now we just return the color name if it's one of the extended colors
  // TODO: determine which is the _closest_ color?

  switch (color) {
    case Colors.RED3:
    case Colors.VERMILION3:
      return "red";
    case Colors.CERULEAN3:
    case Colors.BLUE3:
      return "blue";
    case Colors.GREEN3:
      return "green";
    case Colors.VIOLET3:
      return "violet";
    case Colors.INDIGO3:
      return "indigo";
    case Colors.ROSE3:
      return "rose";
    case Colors.ORANGE3:
      return "orange";
    case Colors.GOLD3:
      return "gold";
    case Colors.LIME3:
      return "lime";
    case Colors.FOREST3:
      return "forest";
    case Colors.TURQUOISE3:
      return "turquoise";
    case Colors.SEPIA3:
      return "sepia";
    default:
      return "none";
  }
}

export const ALLOWED_TAG_COLORS = Array.from(new Set(TAG_COLORS.map(color => color.value)));

const filterOutUsedColors = (alreadyUsedColors: string[], allColors = ALLOWED_TAG_COLORS): string[] => {
  if (!alreadyUsedColors.length) {
    return allColors;
  }

  const filteredColors = allColors.filter(i => !alreadyUsedColors.includes(i));

  return filteredColors.length ? filteredColors : allColors;
};

export function getRandomHexColor(alreadyUsedColors: string[] = [], seedString?: string, allColors?: string[]) {
  const availableColors = filterOutUsedColors(alreadyUsedColors, allColors);

  let index: number;
  if (seedString) {
    // Adapted from https://stackoverflow.com/a/7616484
    let hash = 0;
    for (let i = 0; i < seedString.length; i++) {
      const chr = seedString.charCodeAt(i);
      hash = (hash << 5) - hash + chr;
      hash |= 0;
    }
    index = Math.abs(hash % availableColors.length);
  } else {
    index = Math.floor(Math.random() * availableColors.length);
  }

  return availableColors.at(index) || "";
}

export function getIntentClassFromColor(color: string) {
  const intent = getExtendedIntentFromColor(color);
  if (intent === "none") {
    return undefined;
  }

  return Classes.intentClass(intent as Intent);
}

export function isLightColor(hexColor: string) {
  return tinycolor(hexColor).isLight();
}

export function getTextColorForTag(tagColor: string) {
  return tagColor && isLightColor(tagColor) ? Colors.BLACK : Colors.LIGHT_GRAY5;
}

export function hoopsColorFromHex(hexString: string) {
  const rgbColor = tinycolor(hexString).toRgb();
  return new Communicator.Color(rgbColor.r, rgbColor.g, rgbColor.b);
}

export const sortedColors = [
  Color.Default,
  Color.Red,
  Color.Vermilion,
  Color.Orange,
  Color.Gold,
  Color.Lime,
  Color.Forest,
  Color.Green,
  Color.Turquoise,
  Color.Cerulean,
  Color.Blue,
  Color.Indigo,
  Color.Violet,
  Color.Rose,
  Color.Gray,
];

export const getColorClassName = (color?: Color) => {
  if (!color) {
    return "";
  }

  return `color-${color.toLowerCase()}`;
};

export const getBgColorClassName = (color?: Color) => {
  if (!color) {
    return "";
  }

  return `background-color-${color.toLowerCase()}`;
};
