import { ReactElement } from "react";

export enum EFileFormat {
  PNG = ".png",
  JPEG = ".jpeg",
  JPG = ".jpg",
  PDF = ".pdf",
  DOC = ".doc",
  DOCX = ".docx",
  PRT = ".prt",
  ODT = ".odt",
  XLS = ".xls",
  XLSX = ".xlsx",
  TXT = ".txt",
  SCS = ".scs",
  STL = ".stl",
  IGS = ".igs",
  CSV = ".csv",
  SLDASM = ".sldasm",
  SLDPRT = ".sldprt",
  STP = ".stp",
  STEP = ".step",
  ZIP = ".zip",
}

export type TFileDropZoneChildProps = {
  isOver: boolean;
  files?: FileList;
};

export interface IFileUploadProps {
  disabled?: boolean;
  multiple?: boolean;
  dropIconSize?: number;
  sizeLimit?: number;
  clickToSelect?: boolean;
  format?: Array<EFileFormat>;
  onChange?: (files: FileList) => void;
  children: ReactElement | ((childProps: TFileDropZoneChildProps) => ReactElement);
}
