import { ReportPageNavItem } from "@components/Reports/Editor/Extentions/DashNavigation/types";
import { getCheckboxComponent } from "@components/Reports/Editor/utils";
import { blockTypeIcons } from "@components/Reports/Page/utils";
import { RollupEditorType } from "@rollup-types/editor";

import { DashNavigationListItemData } from "./DashNavigationList";

export const reportPageItems: DashNavigationListItemData[] = [
  {
    type: ReportPageNavItem.CreatePage,
    title: "Page",
    description: "Create an empty page",
    icon: "document",
  },
];

export const getListItemDataBasedOnType = (type: RollupEditorType): DashNavigationListItemData => {
  switch (type) {
    case RollupEditorType.h1:
      return {
        type: RollupEditorType.h1,
        title: "Heading 1",
        description: "Large section heading.",
        icon: blockTypeIcons[RollupEditorType.h1],
      };
    case RollupEditorType.h2:
      return {
        type: RollupEditorType.h2,
        title: "Heading 2",
        description: "Large section heading.",
        icon: blockTypeIcons[RollupEditorType.h2],
      };
    case RollupEditorType.h3:
      return {
        type: RollupEditorType.h3,
        title: "Heading 3",
        description: "Medium section heading.",
        icon: blockTypeIcons[RollupEditorType.h3],
      };
    case RollupEditorType.h4:
      return {
        type: RollupEditorType.h4,
        title: "Heading 4",
        description: "Medium section heading.",
        icon: blockTypeIcons[RollupEditorType.h4],
      };
    case RollupEditorType.h5:
      return {
        type: RollupEditorType.h5,
        title: "Heading 5",
        description: "Small section heading.",
        icon: blockTypeIcons[RollupEditorType.h5],
      };
    case RollupEditorType.h6:
      return {
        type: RollupEditorType.h6,
        title: "Heading 6",
        description: "Small section heading.",
        icon: blockTypeIcons[RollupEditorType.h6],
      };
    case RollupEditorType.table:
      return {
        type: RollupEditorType.table,
        title: "Table",
        description: "Add simple tabular content to your page.",
        icon: blockTypeIcons[RollupEditorType.table],
      };
    case RollupEditorType.code:
      return {
        type: RollupEditorType.code,
        title: "Code block",
        description: "Capture a code snippet.",
        icon: blockTypeIcons[RollupEditorType.code],
      };
    case RollupEditorType.image:
      return {
        type: RollupEditorType.image,
        title: "Image",
        description: "Upload or embed with a link.",
        icon: blockTypeIcons[RollupEditorType.image],
      };
    case RollupEditorType.checklist:
      return {
        type: RollupEditorType.checklist,
        title: "To-do list",
        description: "Track tasks with a to-do list.",
        keywords: "checkbox, todo",
        icon: blockTypeIcons[RollupEditorType.checklist],
      };
    case RollupEditorType.ol:
      return {
        type: RollupEditorType.ol,
        title: "Numbered list",
        description: "Create a list with numbering.",
        icon: blockTypeIcons[RollupEditorType.ol],
      };
    case RollupEditorType.ul:
      return {
        type: RollupEditorType.ul,
        title: "Bulleted list",
        description: "Create a simple bulleted list.",
        icon: blockTypeIcons[RollupEditorType.ul],
      };
    case RollupEditorType.quote:
      return {
        type: RollupEditorType.quote,
        title: "Quote",
        description: "Capture a quote.",
        icon: blockTypeIcons[RollupEditorType.quote],
      };
    case RollupEditorType.hr:
      return {
        type: RollupEditorType.hr,
        title: "Divider",
        description: "Visually divide blocks.",
        icon: blockTypeIcons[RollupEditorType.hr],
      };
    default:
      return {
        type: RollupEditorType.p,
        title: "Text",
        description: "Just start writing with plain text.",
        icon: blockTypeIcons[RollupEditorType.p],
      };
  }
};

export const getReportBlockDefaultLabel = (type: RollupEditorType) => {
  switch (type) {
    case RollupEditorType.checklist:
      return getCheckboxComponent();
    default:
      return "";
  }
};
