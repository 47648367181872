import { useMemo, useState } from "react";

export const useBool = (initialValue = false) => {
  const [value, setValue] = useState(initialValue);

  const handlers = useMemo(
    () => ({
      toggle: () => setValue(v => !v),
      set: () => setValue(true),
      unset: () => setValue(false),
    }),
    []
  );

  return [value, handlers] as const;
};
