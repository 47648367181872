import { ReactNode } from "react";
import { Tooltip } from "@blueprintjs/core";
import classNames from "classnames";
import { observer } from "mobx-react";

import Avatar from "@components/Avatar/Avatar";
import appStore from "@store/AppStore";
import { IUser } from "@store/UserStore";

import "./UserTooltip.scss";

interface IUserTooltipProps {
  children: ReactNode;
  className?: string;
  user?: IUser;
  disable?: boolean;
}

const UserTooltip = (props: IUserTooltipProps) => {
  const { user, className, children, disable } = props;

  if (!user) {
    return children;
  }

  const isOnline = appStore.isUserOnline(user.id);

  const renderSubtitle = () => {
    if (user.jobTitle || user.department) {
      return (
        <div className="user-tooltip--row">
          {user.jobTitle && <span>{user.jobTitle}</span>}
          {user.department && <span>&nbsp;({user.department})</span>}
        </div>
      );
    }

    return (
      <div className="user-status">
        <span>{isOnline ? "Online" : "Offline"}</span>
      </div>
    );
  };

  const tooltipContent = (
    <div className="user-tooltip--content">
      <Avatar statusIconClassName="user-status-icon" source={user.avatarUrl} isOnline={isOnline} size={30} />
      <div className="right-container">
        <div className="user-tooltip--row">
          <span className="username">{user.displayName}</span>
          <span className="tooltip-content">&nbsp;({user.email})</span>
        </div>
        {renderSubtitle()}
      </div>
    </div>
  );

  return (
    <Tooltip
      className={classNames("user-tooltip", className)}
      popoverClassName="user-tooltip--popover"
      hoverOpenDelay={500}
      placement="bottom-start"
      content={tooltipContent}
      disabled={disable}
    >
      {children}
    </Tooltip>
  );
};

export default observer(UserTooltip);
