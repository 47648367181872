import { action, observable } from "mobx";
import { getParentOfType, Instance, types } from "mobx-state-tree";

import { BlockStore } from "@store/BlockStore";

class PropertyGroupUiStore {
  @observable accessor isOpen = true;

  @action.bound
  public toggleOpen() {
    this.isOpen = !this.isOpen;
  }
}

export const PropertyGroupStore = types
  .model("PropertyGroup", {
    id: types.identifier,
    label: types.string,
  })
  .volatile(() => ({
    ui: new PropertyGroupUiStore(),
  }))
  .actions(self => ({
    setLabel(label: string) {
      self.label = label;
      const block = getParentOfType(self, BlockStore);
      // ensure updates get pushed to backend via a snapshot
      block?.handlePropertyGroupUpdated();
    },
  }));

export interface IPropertyGroup extends Instance<typeof PropertyGroupStore> {}
