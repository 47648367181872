import { MaybeElement } from "@blueprintjs/core";
import { IconName } from "@blueprintjs/icons";

import { IRollupEvent } from "@store/RollupEventsStore";

export const getEventIcon = (rollupEvent: IRollupEvent): IconName | MaybeElement => {
  switch (rollupEvent.entityType) {
    case "comment":
      return "comment";
    default:
      return "arrow-right";
  }
};
