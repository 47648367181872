import { useState } from "react";
import { Intent, Radio, RadioGroup } from "@blueprintjs/core";
import { BlueprintIcon } from "@ui/BlueprintIcon";
import { observer } from "mobx-react";

import { GoogleSheetsIcon, OnShapeDarkIcon, OnShapeLightIcon } from "@assets/icons/thirdParty";
import { Button } from "@components/Button";
import DevIcon from "@components/DevIcon";
import DialogSimple from "@components/Dialog/DialogSimple";
import { ThemedCustomIcon } from "@components/Shared/LegacyCustomIcon/ThemedCustomIcon";

import { DataConnectionType } from "../utils";

import "./AddDataConnectionDialog.scss";

interface IAddDataConnectionDialogProps {
  onClose(): void;
  onContinue(value: DataConnectionType): void;
}

const AddDataConnectionDialog = (props: IAddDataConnectionDialogProps) => {
  const { onClose, onContinue } = props;
  const [selectDataConnection, setSelectDataConnection] = useState<DataConnectionType>(DataConnectionType.DataSource);

  const handleContinue = () => {
    if (selectDataConnection) {
      onContinue(selectDataConnection);
    }
  };

  const renderCancelButton = () => (
    <Button key="Cancel" onClick={onClose} e2eIdentifiers="cancel" minimal outlined>
      Cancel
    </Button>
  );

  const renderContinueButton = () => (
    <Button key="Continue" onClick={handleContinue} e2eIdentifiers="continue" intent={Intent.PRIMARY} disabled={!selectDataConnection}>
      Continue
    </Button>
  );

  return (
    <DialogSimple
      title="Select type of data connection"
      onClose={onClose}
      footerButtons={[renderCancelButton(), renderContinueButton()]}
      isOpen
    >
      <RadioGroup onChange={e => setSelectDataConnection(e.currentTarget.value as DataConnectionType)} selectedValue={selectDataConnection}>
        <Radio
          labelElement={
            <span>
              <BlueprintIcon className="add-data-connection-dialog--radio-icon" icon="repeat" size={12} />
              <span className="add-data-connection-dialog--radio-label">Live Data Source</span>
            </span>
          }
          value={DataConnectionType.DataSource}
        >
          <div className="add-data-connection-dialog--radio-content">
            <div>These are integrations in Rollup that use “live data” from 3rd-party apps connected to Rollup. </div>
            <div>
              <span>Examples include </span>
              <span>
                <GoogleSheetsIcon className="add-data-connection-dialog--google-sheets-icon" />
                <span>Google Sheets</span>
              </span>
              <span>, </span>
              <span>
                <ThemedCustomIcon
                  lightIcon={<OnShapeLightIcon className="add-data-connection-dialog--on-shape-icon" />}
                  darkIcon={<OnShapeDarkIcon className="add-data-connection-dialog--on-shape-icon" />}
                />
                <span>OnShape</span>
              </span>
              <span>, Cloud Simulation Software, etc.</span>
            </div>
          </div>
        </Radio>
        <Radio
          labelElement={
            <span>
              <BlueprintIcon className="add-data-connection-dialog--radio-icon" icon="social-media" size={12} />
              <span className="add-data-connection-dialog--radio-label">Data Sink</span>
            </span>
          }
          value={DataConnectionType.DataSink}
        >
          <div className="add-data-connection-dialog--radio-content">
            <div>
              <span>
                These are atomic data objects that can hold values. They are “set” using Rollup’s API by a more traditional “push, pull”
                method.
              </span>
              <span> Use data sinks in cases where you want to update rollup remotely from a 3rd party app.</span>
            </div>
            <div>
              <span>Examples include: Updating a parameter value from </span>
              <span>
                <DevIcon className="add-data-connection-dialog--python-icon" icon="python" colored />
                <span>Python </span>
              </span>
              <span>script on your local machine, etc.</span>
            </div>
          </div>
        </Radio>
      </RadioGroup>
    </DialogSimple>
  );
};

export default observer(AddDataConnectionDialog);
