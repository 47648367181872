import { RefAttributes } from "react";
import { Mention, MentionOptions } from "@tiptap/extension-mention";
import { mergeAttributes, ReactNodeViewRenderer, ReactRenderer } from "@tiptap/react";
import { SuggestionOptions } from "@tiptap/suggestion";
import { PluginKey } from "prosemirror-state";
import tippy, { GetReferenceClientRect, Instance } from "tippy.js";

import {
  createMentionEntries,
  createSampleMentionEntries,
} from "@components/Reports/Editor/Extentions/Mention/MentionSuggestionEntriesCreation";
import { cancelBackspaceIfMention } from "@components/Reports/Editor/Extentions/Mention/MentionUtils";
import { IMentionItem, MentionMode } from "@components/Reports/Editor/Extentions/Mention/types";
import { ESCAPE_KEY } from "@constants/keys";
import { IAnalysisOutput } from "@store/Analysis/AnalysisOutputStore";
import { IAttachment } from "@store/AttachmentStore";
import { IBlock } from "@store/BlockStore";
import { IPropertyInstance } from "@store/PropertyInstanceStore";
import { IReport } from "@store/ReportsStore";
import { IUser } from "@store/UserStore";
import { IWorkspace } from "@store/WorkspaceStore";
import { fuseSearch } from "@utilities/FuseSearch";

import MentionList, { MentionListProps, MentionListRef } from "./MentionList";
import { MentionTag } from "./MentionTag";

type MentionSuggestionOptions<T> = Omit<SuggestionOptions<T>, "editor">;
export const MentionValuePluginKey = new PluginKey("mention-value");
export const MentionNamePluginKey = new PluginKey("mention-name");
export const MentionValueTrigger = "{{";
export const MentionNameTrigger = "@";

export const MENTION_TAG_COMPONENT_TAG = "mention-tag-component";

export enum MentionExtensionType {
  Name = "mention-name",
  Value = "mention-value",
}

const MentionSuggestion = (
  valueOnly: boolean,
  workspace?: IWorkspace,
  scalarOnly?: boolean
): MentionSuggestionOptions<IMentionItem<IUser | IBlock | IPropertyInstance | IReport | IAttachment | IAnalysisOutput>> => ({
  allowSpaces: true,
  char: valueOnly ? MentionValueTrigger : MentionNameTrigger,
  pluginKey: valueOnly ? MentionValuePluginKey : MentionNamePluginKey,
  items: ({ query }) => {
    if (!query) {
      return createSampleMentionEntries(workspace, valueOnly, scalarOnly);
    }
    const entries = createMentionEntries(workspace, valueOnly, scalarOnly) ?? [];
    return fuseSearch({ entries, query });
  },

  render: () => {
    let component: ReactRenderer<MentionListRef, MentionListProps & RefAttributes<MentionListRef>>;
    let popup: Instance;

    return {
      onStart: props => {
        component = new ReactRenderer(MentionList, { props, editor: props.editor });

        if (!props.clientRect) {
          return;
        }

        // TODO: Use a BlueprintJS popover instead of tippy
        popup = tippy("body", {
          getReferenceClientRect: props.clientRect as GetReferenceClientRect,
          appendTo: () => document.body,
          content: component.element,
          showOnCreate: true,
          interactive: true,
          trigger: "manual",
          placement: "bottom-start",
          maxWidth: "50vw",
        })?.[0];
      },

      onUpdate: props => {
        component.updateProps(props);

        if (!props.clientRect) {
          return;
        }

        popup.setProps({
          getReferenceClientRect: props.clientRect as GetReferenceClientRect,
        });
      },

      onKeyDown: props => {
        if (props.event.key === ESCAPE_KEY) {
          popup.hide();

          return true;
        }

        return component.ref?.onKeyDown(props) ?? false;
      },

      onExit: () => {
        if (popup?.state && !popup.state.isDestroyed) {
          popup.destroy();
        }
        component?.destroy();
      },
    };
  },
});

/**
 * disableMentionNodeRemoval: if this is true, the extension will not delete the mention node when backspace is pressed,
 * and the deletion will have to be handled externally
 */
const CustomNameMention = Mention.extend<
  MentionOptions & {
    workspace?: IWorkspace;
    disableMentionNodeRemoval?: boolean;
    onUpdate?: (content: string) => void;
  }
>({
  name: MentionExtensionType.Name,
  addOptions() {
    return {
      ...this.parent?.(),
      workspace: undefined,
    };
  },
  addNodeView() {
    return ReactNodeViewRenderer(MentionTag);
  },
  parseHTML() {
    return [
      {
        tag: MENTION_TAG_COMPONENT_TAG,
      },
    ];
  },
  renderHTML({ HTMLAttributes }) {
    return [MENTION_TAG_COMPONENT_TAG, mergeAttributes(HTMLAttributes)];
  },
  addKeyboardShortcuts() {
    return {
      // Override the default backspace behavior that left the suggestion char ("@") behind when the mention was deleted
      Backspace: cancelBackspaceIfMention(this.options.disableMentionNodeRemoval),
    };
  },
});

/**
 * disableMentionNodeRemoval: if this is true, the extension will not delete the mention node when backspace is pressed,
 * and the deletion will have to be handled externally
 */
const CustomValueMention = Mention.extend<
  MentionOptions & {
    displayPath?: boolean;
    displayIcon?: boolean;
    workspace?: IWorkspace;
    disableMentionNodeRemoval?: boolean;
    onUpdate?: (content: string) => void;
  }
>({
  name: MentionExtensionType.Value,
  addOptions() {
    return {
      ...this.parent?.(),
      displayPath: false,
      displayIcon: true,
      workspace: undefined,
    };
  },
  addAttributes() {
    return {
      ...this.parent?.(),
      mode: {
        default: MentionMode.Mention,
        parseHTML: element => element.getAttribute("mode") ?? MentionMode.Mention,
        renderHTML: attributes => ({ mode: attributes.mode }),
      },
      usePlaceholder: {
        default: false,
        parseHTML: element => {
          const attribute = element.getAttribute("usePlaceholder");
          return attribute === "true";
        },
      },
      showContextMenu: {
        default: false,
        parseHTML: element => {
          const attribute = element.getAttribute("showContextMenu");
          return attribute === "true";
        },
        renderHTML: attributes => {
          if (!attributes.showContextMenu) {
            return {};
          }

          return {
            showContextMenu: attributes.showContextMenu,
          };
        },
      },
    };
  },
  addNodeView() {
    return ReactNodeViewRenderer(MentionTag);
  },
  parseHTML() {
    return [
      {
        tag: MENTION_TAG_COMPONENT_TAG,
      },
    ];
  },
  renderHTML({ HTMLAttributes }) {
    return [MENTION_TAG_COMPONENT_TAG, mergeAttributes(HTMLAttributes)];
  },
  addKeyboardShortcuts() {
    return {
      // Override the default backspace behavior that left the suggestion char ("{{") behind when the mention was deleted
      Backspace: cancelBackspaceIfMention(this.options.disableMentionNodeRemoval),
    };
  },
});

// More info on rendering react components inside a tiptap editor: https://tiptap.dev/docs/editor/guide/node-views/react
export const createMentionTagComponent = (id: string, label: string, showContextMenu?: boolean) => {
  return `<${MENTION_TAG_COMPONENT_TAG} data-id="${id}" data-label="${label}" showContextMenu="${showContextMenu}" />`;
};

export interface IMentionExtensionParams {
  workspace?: IWorkspace;
  disableMentionNodeRemoval?: boolean;
  onUpdate?: (content: string) => void;
}

export const MentionNameExtension = ({ workspace, disableMentionNodeRemoval, onUpdate }: IMentionExtensionParams = {}) =>
  CustomNameMention.configure({
    workspace,
    disableMentionNodeRemoval,
    suggestion: MentionSuggestion(false, workspace),
    onUpdate,
  });

export const MentionValueExtension = ({ workspace, disableMentionNodeRemoval, onUpdate }: IMentionExtensionParams = {}) =>
  CustomValueMention.configure({
    workspace,
    disableMentionNodeRemoval,
    displayPath: false,
    suggestion: MentionSuggestion(true, workspace),
    onUpdate,
  });

export const getMentionExtensions = (params?: IMentionExtensionParams) => {
  return [MentionValueExtension(params), MentionNameExtension(params)];
};
