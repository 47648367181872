import { cast, flow, getParentOfType, Instance, IType, SnapshotIn, SnapshotOut, types } from "mobx-state-tree";
import { Socket } from "socket.io-client";

import { Comment, UpdateCommentDto } from "@rollup-api/models/comments";
import { IAnnotation } from "@store/AnnotationStore";
import { CommentThreadStore } from "@store/CommentThreadStore";
import { StoreType } from "@store/types";
import { getBlockByIdWithWorkspace, getWorkspaceById } from "@utilities";
import { rollupClient } from "src/core/api";

import appStore from "./AppStore";
import { IBlock } from "./BlockStore";
import { ViewStore } from "./ViewStore";

// Add other types which can contain a comment as it's child.
export type CommentParent = IBlock | IAnnotation;

export enum CommentDisplayParentType {
  NotAssigned = "not assigned", // for legacy comments
  Block = "block",
  Attachment = "attachment",
  Report = "report",
  Requirement = "requirement",
  BOM = "bom",
  Annotation = "annotation",
}

export const CommentStore = types
  .model("Comment", {
    id: types.identifier,
    text: types.string,
    createdBy: types.string,
    createdAt: types.optional(types.number, Date.now()),
    updatedAt: types.optional(types.number, Date.now()),
    attachments: types.array(types.string),
    publishAttachmentsToBlock: types.optional(types.boolean, true),
    // TODO: comments should no longer have view. All view related information will be provided by annotation model.
    view: types.maybe(ViewStore),
    // Parent and displayParent type and id currently belong to Comment (no BE support for threads)
    parentType: types.enumeration(Object.values(StoreType)),
    parentId: types.string,
    displayParentType: types.enumeration<CommentDisplayParentType>("CommentDisplayParentType", [
      ...Object.values(CommentDisplayParentType),
    ]),
    displayParentId: types.string,
    parentCommentId: types.maybeNull(types.string),
    workspaceId: types.maybeNull(types.string),
    pending: types.optional(types.boolean, false),
  })
  .actions(self => ({
    setPending(pending: boolean) {
      self.pending = pending;
    },
    update: flow(function* update({
      text,
      attachmentIdList,
      publishAttachmentsToBlock,
    }: {
      text: string;
      attachmentIdList: string[];
      publishAttachmentsToBlock?: boolean;
    }): Generator<any, string | undefined, any> {
      const addedAttachments = attachmentIdList.filter(item => !self.attachments.includes(item));
      const removedAttachments = self.attachments.filter(item => !attachmentIdList.includes(item));
      // If text is not changed, and no attachments are added or removed, and publish settings hasn't changed, do not update comment.
      if (
        text !== self.text ||
        addedAttachments.length ||
        removedAttachments.length ||
        publishAttachmentsToBlock !== self.publishAttachmentsToBlock
      ) {
        self.text = text.trim();
        self.updatedAt = Date.now();
        const previousAttachmentsIdList = self.attachments;
        self.attachments = cast(attachmentIdList);
        const previousPublishSetting = self.publishAttachmentsToBlock;
        const hasPublishSettingChanged = previousPublishSetting !== publishAttachmentsToBlock;
        self.publishAttachmentsToBlock = !!publishAttachmentsToBlock;

        try {
          const { data: comment, status } = yield rollupClient.comments.update(self.id, {
            text,
            attachmentIdList: attachmentIdList,
            removeAttachmentList: removedAttachments,
            publishToBlock: !!publishAttachmentsToBlock,
          });

          if (status !== 200) {
            console.warn(`Error updating comment`);
            return;
          }

          if (comment) {
            // remove attachments from the block or publish attachments to the block if publish settings are changed
            if (hasPublishSettingChanged && self.workspaceId && self.parentType === "block") {
              const workspace = yield getWorkspaceById(self.workspaceId);
              if (self.publishAttachmentsToBlock) {
                workspace?.addAttachmentsToBlock(self.parentId, attachmentIdList);
              } else {
                workspace?.removeAttachmentsFromBlock(self.parentId, previousAttachmentsIdList);
              }
            }
            return comment.id;
          }
        } catch (error) {
          console.warn(`Error updating comment`, error);
          return;
        }
      }
      return;
    }),
    delete: flow(function* deleteSelf(notify = true): Generator<any, any, any> {
      try {
        if (notify) {
          const { status } = yield rollupClient.comments.delete(self.id);
          if (status !== 200) {
            console.warn(`Error deleting comment ${self.id}`);
            return false;
          }
        }
        const parentThread = getParentOfType(self, CommentThreadStore);
        parentThread.removeComment(self.id);
        return true;
      } catch (error) {
        console.warn(`Error deleting comment ${self.id}`, error);
        return false;
      }
    }),
    getThreadListParent: flow(function* getParent(): Generator<any, IBlock | IAnnotation | undefined, any> {
      if (!self.parentId || !self.workspaceId) {
        return;
      }
      switch (self.parentType) {
        case StoreType.Block:
          return yield getBlockByIdWithWorkspace(self.parentId, self.workspaceId);
        // TODO: Populate below types.
        case StoreType.Attachment:
          return;
        case StoreType.Report:
          return;
        case StoreType.Requirement:
          return;
        case StoreType.BOM:
          return;
        default:
          return;
      }
    }),
  }))
  .actions(self => ({
    patch(dto: UpdateCommentDto) {
      self.text = dto.text.trim();
      self.updatedAt = Date.now();
    },
  }))
  .views(self => ({
    get user() {
      return appStore.orgModel?.info?.orgMembers.find(user => user.id === self.createdBy);
    },
    get isMyComment() {
      return self.createdBy === appStore.userModel?.id;
    },
    get formattedCreatedAt() {
      return new Date(self.createdAt).toLocaleDateString("en-us", {
        year: "numeric",
        month: "short",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
      });
    },
    get formattedUpdatedAt() {
      return new Date(self.updatedAt).toLocaleDateString("en-us", {
        year: "numeric",
        month: "short",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
      });
    },
    get attachmentIds() {
      return self.attachments;
    },
  }));

// TODO: implement socket operations
export function subscribeToCommentEvents(socket: Socket) {
  socket.on("createComment", (data: { workspaceId: string; result: Comment; userId: string }) => {
    if (data.workspaceId === appStore.workspaceModel?.id && data.result.parentId) {
      if (data.result.parentType === "block") {
        // const block: IBlock | undefined = appStore.workspaceModel?.blockMap.get(data.result.parentId);
        // block?.addExistingComment(data.result);
      }
    }
  });

  socket.on("deleteComment", (data: { workspaceId: string; id: string }) => {
    if (data.workspaceId === appStore.workspaceModel?.id) {
      // const commentToDelete = appStore.env.activeBlock?.comments.find(item => item.id === data.id);
      // if (!commentToDelete) {
      //   console.warn(`Could not remove comment ${data.id} from store.`);
      //   return;
      // }
      // commentToDelete.delete(false);
    }
  });

  socket.on("updateComment", (data: { workspaceId: string; updateCommentDto: UpdateCommentDto; id: string }) => {
    if (data.workspaceId === appStore.workspaceModel?.id) {
      // const commentToUpdate = appStore.env.activeBlock?.comments.find(item => item.id === data.id);
      // if (!commentToUpdate) {
      //   console.warn(`Could not update comment ${data.id}.`);
      //   return;
      // }
      // commentToUpdate.patch(data.updateCommentDto);
    }
  });
}

export interface IComment extends Instance<typeof CommentStore> {}
export interface ICommentSnapshotIn extends SnapshotIn<typeof CommentStore> {}
interface ICommentSnapshotOut extends SnapshotOut<typeof CommentStore> {}
export interface ICommentMobxType extends IType<ICommentSnapshotIn, ICommentSnapshotOut, IComment> {}
