import { v4 as uuidv4 } from "uuid";

import { IAnnotation } from "@store/AnnotationStore";
import appStore from "@store/AppStore";
import { IAttachment } from "@store/AttachmentStore";

import { AnnotationMarkup } from "./AnnotationMarkup";

export class AnnotationMarkupManager extends Communicator.MarkupTypeManager {
  private static _globalPointMeshData: Communicator.MeshData | null = null;

  private _pointMeshId: Communicator.MeshId | null = null;

  private _annotationMarkups: Map<string, AnnotationMarkup>;

  private _viewer: Communicator.WebViewer;

  private _explodeActive = false;
  private _isolateActive = false;
  private _attachment: IAttachment;

  public constructor(viewer: Communicator.WebViewer, attachment: IAttachment) {
    super();

    this._viewer = viewer;
    this._attachment = attachment;
    this._annotationMarkups = new Map();

    const callbacks = {
      sceneReady: async () => {
        await this._init();
      },
    };

    this._viewer.setCallbacks(callbacks);
    this._init();
  }

  private async _init(): Promise<void> {
    if (AnnotationMarkupManager._globalPointMeshData === null) {
      AnnotationMarkupManager._globalPointMeshData = this._createPointMeshData();
    }
    const model = this._viewer.model;
    this._pointMeshId = await model.createMesh(AnnotationMarkupManager._globalPointMeshData);
    // This line below is worth 5 hours of your time debugging. So, be nice to it.
    appStore.env.attachmentViewer?.setAnnotationMarkupManager(this);
  }

  private _createPointMeshData(): Communicator.MeshData {
    const meshData = new Communicator.MeshData();
    meshData.addPoints([0, 0, 0]);
    return meshData;
  }

  /**
   * Retrieves the mesh id of the annotation point, if there is one
   * @returns MeshId of the annotation point, or null if there is none
   */
  public getPointMeshId(): Communicator.MeshId | null {
    return this._pointMeshId;
  }

  /**
   * Used to create a new annotation markup via user interaction from the Annotation Operator
   * Called from Annotation Operator with data needed to create a new annotation and markup
   * @returns void
   */
  public async createAnnotationAndMarkupViaUserAction(
    selectionPosition: Communicator.Point3,
    selectionNormal: Communicator.Point3,
    partId: number,
    faceIndex: number
  ): Promise<void> {
    // Create annotation markup
    const newMarkup = await new AnnotationMarkup(this._viewer, this, {
      selectionPosition,
      selectionNormal,
      partId,
      faceIndex,
    });
    // Create annotation in store
    const screenPos = this._viewer.view.projectPoint(selectionPosition);
    const newAnnotation = await this._attachment.annotationList.addNew3DAnnotation({
      viewName: uuidv4(),
      selectionPosition,
      selectionNormal,
      partId,
      faceIndex,
      screenPos,
    });
    // Connect annotation to markup
    if (newAnnotation) {
      newMarkup.setAnnotation(newAnnotation);
      // Save reference of the markup in the markup map
      this._annotationMarkups.set(newAnnotation.id, newMarkup);
      newAnnotation.setIsExpanded(true);
    } else {
      throw new Error("Failed to create annotation");
    }
  }

  /**
   * Used to recreate an annotation markup, using an annotation which is already in the store,
   * without user interaction. This is used when the user opens an attachment with annotations already
   * created.
   * Called from Annotation Store with data needed to recreate a new annotation and markup
   * @returns void
   */
  public async recreateAnnotationAndMarkupFromStore(annotation: IAnnotation): Promise<void> {
    const newMarkup = await new AnnotationMarkup(this._viewer, this, {
      selectionPosition: annotation.locationData3D?.selectionPosition as Communicator.Point3,
      selectionNormal: annotation.locationData3D?.selectionNormal as Communicator.Point3,
      partId: annotation.locationData3D?.partId || 0,
      faceIndex: annotation.locationData3D?.faceIndex || 0,
    });
    // Connect annotation to markup
    newMarkup.setAnnotation(annotation);
    // Save reference of the markup in the markup map
    this._annotationMarkups.set(annotation.id, newMarkup);
  }

  public deleteAnnotationMarkup(annotationId: string) {
    const markup = this._annotationMarkups.get(annotationId);
    if (markup) {
      markup.remove();
      this._annotationMarkups.delete(annotationId);
    }
  }

  /**
   * Gets managers explosion state. Active explosion hides annotation points
   */
  public getExplodeActive(): boolean {
    return this._explodeActive;
  }

  /**
   * Sets whether an isolate is currently active or not
   * @param isolateActive
   */
  public setIsolateActive(isolateActive: boolean): void {
    this._isolateActive = isolateActive;
  }

  /**
   * Gets whether an isolate is currently active or not
   * @returns isolate status
   */
  public getIsolateActive(): boolean {
    return this._isolateActive;
  }
}
