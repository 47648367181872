import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ContextMenu } from "@blueprintjs/core";
import classNames from "classnames";
import { observer } from "mobx-react";

import BomTablesSidebarContextMenu from "@components/BomTables/BomTablesSidebar/BomTablesSidebarContextMenu";
import { DocumentNameInput } from "@components/DocumentNameInput";
import { FavoriteButton } from "@components/FavoriteButton";
import MoreOptionsMenu from "@components/MoreOptionsMenu/MoreOptionsMenu";
import NavLinkAsMenuItem from "@router/navigation/NavLinkAsMenuItem";
import appStore from "@store/AppStore";
import { IBomTable } from "@store/BomTable/BomTableStore";
import { EntityType } from "@store/types";
import { closePopover } from "@utilities/DomUtils";
import { getDocumentIcon } from "@utilities/Icons";

import styles from "./BomTablesSidebarItem.module.scss";

interface IBomTablesSidebarItemProps {
  bomTable: IBomTable;
  minimal?: boolean;
}

const BomTablesSidebarItem = (props: IBomTablesSidebarItemProps) => {
  const { bomTable, minimal } = props;
  const [isEditing, setIsEditing] = useState(false);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const navigate = useNavigate();

  const openBomTable = (reportId: string) => {
    navigate(reportId);
  };

  const renderMoreNav = (className: string) => {
    return (
      <div className={classNames(className, { [styles.bomTablesSidebarItemPopoverOpen]: isPopoverOpen })}>
        <FavoriteButton entityId={bomTable.id} entityType={EntityType.BomTable} />
        <MoreOptionsMenu
          content={<BomTablesSidebarContextMenu bomTable={bomTable} />}
          popoverProps={{ isOpen: isPopoverOpen, onInteraction: setIsPopoverOpen, placement: "bottom-end" }}
          e2eIdentifiers={bomTable.label}
          small
        />
      </div>
    );
  };

  const renderContent = () => {
    if (isEditing) {
      return (
        <DocumentNameInput
          className="bom-tables-sidebar--document-name-input"
          inputClassName="bom-tables-sidebar--document-name-input-group"
          iconClassName={styles.bomTablesSidebarItemIcon}
          icon={getDocumentIcon(EntityType.BomTable)}
          moreNav={minimal ? undefined : <BomTablesSidebarContextMenu bomTable={bomTable} />}
          defaultValue={bomTable.label}
          onUpdate={bomTable.setLabel}
          onEnter={() => setIsEditing(false)}
          onEscape={() => setIsEditing(false)}
          onBlur={() => setIsEditing(false)}
          inputGroupProps={{ autoFocus: true }}
        />
      );
    }

    return (
      <NavLinkAsMenuItem
        className="bom-tables-sidebar--nav-link"
        textClassName={styles.bomTablesSidebarItemLabel}
        selected={bomTable.id === appStore.env.activeBomTableId}
        to={`bom/${bomTable.id}`}
        icon={getDocumentIcon(EntityType.BomTable)}
        text={minimal ? "" : bomTable.label}
        e2eIdentifiers={["bom_tables_sidebar", bomTable.label]}
        renderRightElements={minimal ? undefined : renderMoreNav}
      />
    );
  };

  return (
    <ContextMenu
      onContextMenu={() => openBomTable(bomTable.id)}
      content={<BomTablesSidebarContextMenu bomTable={bomTable} onEnter={closePopover} />}
      onDoubleClick={() => setIsEditing(true)}
    >
      {renderContent()}
    </ContextMenu>
  );
};

export default observer(BomTablesSidebarItem);
