import { useEffect, useState } from "react";
import { InputGroup, Menu, Position } from "@blueprintjs/core";
import { observer } from "mobx-react";

import { Button } from "@components/Button";
import { DeleteConfirmationDialog } from "@components/DeleteConfirmationDialog";
import { MenuItem } from "@components/MenuItem";
import { Popover } from "@components/Popover";
import { Tooltip } from "@components/Tooltip";
import appStore from "@store/AppStore";
import { IAttachment } from "@store/AttachmentStore";
import { IView } from "@store/ViewStore";
import { Text, TextVariant } from "src/ui/Text";

import SavedViewItem from "../SavedViewItem";

import "./ViewsPanel.scss";

type ViewsPanelProps = {
  attachment: IAttachment;
};

const ViewsPanel = ({ attachment }: ViewsPanelProps) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [newViewName, setNewViewName] = useState("");
  const [deleteAll, setDeleteAll] = useState(false);

  useEffect(() => {
    setSearchQuery("");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appStore.env.hoopsSidePanelState]);

  const normalizedSearchQuery = searchQuery.toLowerCase();

  const handleViewFilter = (view: IView) => {
    return searchQuery ? view.name.toLowerCase().includes(normalizedSearchQuery) : true;
  };

  const toggleDeleteAll = () => {
    setDeleteAll(!deleteAll);
  };

  const handleSaveNewView = () => {
    if (newViewName) {
      attachment.saveNewView(newViewName);
      setNewViewName("");
    }
  };

  const handleDeleteAllViews = () => {
    attachment.deleteAllViews();
    setDeleteAll(false);
  };

  const renderModal = () => {
    return (
      <DeleteConfirmationDialog
        titleItem="Delete all views"
        description="Are you sure you want to delete all views?"
        onCancel={toggleDeleteAll}
        onClose={toggleDeleteAll}
        isOpen={deleteAll}
        onConfirm={handleDeleteAllViews}
      />
    );
  };

  return (
    <>
      {renderModal()}
      <div className="hoops-right-panel-views--header">
        <Text variant={TextVariant.H3}>Saved views</Text>
        <Popover
          position="bottom-right"
          content={
            <Menu>
              <MenuItem e2eIdentifiers="delete-all-views" text="Delete all views" onClick={toggleDeleteAll} />
            </Menu>
          }
        >
          <Button minimal e2eIdentifiers="annotations-more" icon="more" />
        </Popover>
      </div>
      <div className="hoops-right-panel-views--search">
        <InputGroup
          value={searchQuery}
          onChange={e => setSearchQuery(e.target.value)}
          placeholder="Search for views"
          leftIcon="search"
          large
        />
      </div>
      <div className="hoops-right-panel-views--new">
        <InputGroup value={newViewName} onChange={e => setNewViewName(e.target.value)} placeholder="Name the current view" large />
        <Tooltip content="To save a view, enter a name first" disabled={!!newViewName} position={Position.BOTTOM_RIGHT}>
          <Button e2eIdentifiers="save-new-view" large outlined onClick={handleSaveNewView} disabled={!newViewName}>
            Save new view
          </Button>
        </Tooltip>
      </div>
      <div className="hoops-right-panel-views--list">
        {attachment.views.filter(handleViewFilter).map((view: IView) => (
          <SavedViewItem key={view.id} view={view} />
        ))}
      </div>
    </>
  );
};

export default observer(ViewsPanel);
