import { useCallback } from "react";
import { Position } from "@blueprintjs/core";
import classNames from "classnames";
import { observer } from "mobx-react";

import { TitledTooltip } from "@components/Shared";
import { useAppNavigate } from "@router/hooks";
import { IPropertyInstance } from "@store/PropertyInstanceStore";

interface IPropertyInstanceTagProps {
  propertyInstance?: IPropertyInstance;
  labelClassName?: string;
}

const PropertyInstanceTag = (props: IPropertyInstanceTagProps) => {
  const { propertyInstance, labelClassName } = props;
  const { navigateToProperty } = useAppNavigate();

  const handleInstanceClicked = useCallback(() => {
    if (propertyInstance?.parentBlock && propertyInstance?.id) {
      navigateToProperty(propertyInstance.id);
    }
  }, [navigateToProperty, propertyInstance?.id, propertyInstance?.parentBlock]);

  if (!propertyInstance?.propertyDefinition || !propertyInstance.parentBlock) {
    return null;
  }
  const tagLabel = `${propertyInstance.parentBlock.label}:${propertyInstance.propertyDefinition.label}`;
  const hasError = !!propertyInstance.validationErrorMessage;

  let tooltipMessage = "";
  if (hasError) {
    tooltipMessage = `Error: ${propertyInstance.validationErrorMessage}`;
  } else {
    tooltipMessage = `Value: ${propertyInstance.numericText}`;
  }

  const { validationErrorMessage } = propertyInstance;

  return (
    <TitledTooltip placement={Position.BOTTOM} title={propertyInstance.path} content={tooltipMessage}>
      <div
        className={classNames("property-instance-tag", {
          ["warning"]: validationErrorMessage,
        })}
        onClick={handleInstanceClicked}
      >
        <span className={labelClassName}>{tagLabel}</span>
      </div>
    </TitledTooltip>
  );
};

export default observer(PropertyInstanceTag);
