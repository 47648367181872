import { RefObject } from "react";
import { Tab, Tabs, TabsExpander } from "@blueprintjs/core";
import { observer } from "mobx-react";

import { Button } from "@components/Button";
import { Attachment } from "@rollup-api/models";

import Link from "./Tabs/Link";
import Upload from "./Tabs/Upload";

import styles from "./AttachmentPopup.module.scss";

type Props = {
  allowAllFormats?: boolean;
  fileUrl?: string;
  blockId?: string;
  hideLinkInput?: boolean;
  hideRemove?: boolean;
  workspaceId?: string | null;
  containerRef?: RefObject<HTMLDivElement>;
  onUploadStart?(): void;
  onLinkChange(link: string, file?: Attachment): void;
};

const AttachmentPopup = (props: Props) => {
  const { workspaceId, fileUrl, hideRemove, hideLinkInput, allowAllFormats, blockId, containerRef } = props;
  const { onUploadStart, onLinkChange } = props;

  const handleRemove = () => {
    onLinkChange("");
  };

  return (
    <div ref={containerRef} className={styles.attachmentPopup}>
      <Tabs animate renderActiveTabPanelOnly>
        <Tab
          id="upload"
          title="Upload"
          panel={
            <Upload
              workspaceId={workspaceId}
              blockId={blockId}
              allowAllFormats={allowAllFormats}
              onUploadStart={onUploadStart}
              onUploadSuccess={onLinkChange}
            />
          }
        />
        {!hideLinkInput && <Tab id="link" title="Link" panel={<Link onLinkChange={onLinkChange} />} />}
        <TabsExpander />
        {!hideRemove && (
          <Button disabled={!fileUrl} onClick={handleRemove} minimal e2eIdentifiers="remove">
            Remove
          </Button>
        )}
      </Tabs>
    </div>
  );
};

export default observer(AttachmentPopup);
