import { useState } from "react";
import { Menu, NonIdealState, NonIdealStateProps, PopoverProps, TooltipProps } from "@blueprintjs/core";
import classNames from "classnames";
import { observer } from "mobx-react-lite";

import { IButtonProps } from "@components/Button/Button";
import { MenuItem } from "@components/MenuItem";
import { PopupMenu } from "@components/Shared/PopupMenu";
import { ISelectOption } from "@rollup-types/selection";
import { isComplexOption } from "@utilities";
import { IE2EIdentifiersParam } from "@utilities/E2EUtils";

import styles from "./Dropdown.module.scss";

interface IDropdownProps<T extends ISelectOption = ISelectOption> extends IE2EIdentifiersParam {
  options: T[];
  className?: string;
  selected?: T;
  placeholder?: string;
  popupMenuClassName?: string;
  popoverProps?: PopoverProps;
  buttonProps?: Omit<IButtonProps, "e2eIdentifiers">;
  tooltipProps?: Omit<TooltipProps, "content">;
  disabled?: boolean;
  hideLabel?: boolean;
  hideArrow?: boolean;
  nonIdealStateProps?: NonIdealStateProps;
  onSelect(value: T): void;
}

const Dropdown = <T extends ISelectOption>(props: IDropdownProps<T>) => {
  const { selected, options, placeholder, popoverProps, hideLabel, hideArrow, tooltipProps } = props;
  const { className, buttonProps, e2eIdentifiers, disabled, popupMenuClassName, onSelect } = props;
  const { nonIdealStateProps } = props;
  const [isOpen, setIsOpen] = useState(false);
  const filteredOptions = options.filter(opt => !isComplexOption(opt) || !opt.isEnabled || opt.isEnabled());

  const renderMenu = () => {
    return (
      <Menu className={styles.dropdownMenu}>
        {filteredOptions.map(opt => {
          const icon = typeof opt === "string" ? undefined : opt.icon;
          const tooltip = typeof opt === "string" ? undefined : opt.tooltip;
          const label = typeof opt === "string" ? opt : opt.label;
          const value = typeof opt === "string" ? opt : opt.value;
          const selectedValue = typeof selected === "string" ? selected : selected?.value;
          return (
            <MenuItem
              key={value}
              text={
                <div className={styles.dropdownOption}>
                  {icon}
                  {label}
                </div>
              }
              tooltip={tooltip}
              tooltipProps={tooltipProps}
              onClick={() => onSelect(opt)}
              e2eIdentifiers={`dropdown-menu-item-${value}`}
              active={selectedValue === value}
            />
          );
        })}
      </Menu>
    );
  };

  const renderPopupContent = () => {
    if (!filteredOptions.length) {
      return (
        <NonIdealState
          className={styles.dropdownNonIdealState}
          iconSize={20}
          icon="search-template"
          title="No options available"
          {...nonIdealStateProps}
        />
      );
    }

    return renderMenu();
  };

  const selectedOptLabel = typeof selected === "string" ? selected : selected?.label;
  const selectedOptIcon = isComplexOption(selected) && selected.icon;

  return (
    <div className={classNames(styles.dropdown, className)}>
      <PopupMenu
        className={classNames(styles.dropdownPopupMenu, popupMenuClassName)}
        popoverProps={{ matchTargetWidth: true, ...popoverProps }}
        content={renderPopupContent()}
        disabled={disabled}
        buttonIcon={selectedOptIcon}
        buttonText={hideLabel ? "" : selectedOptLabel || placeholder}
        buttonProps={{
          ...buttonProps,
          rightIcon: hideArrow ? undefined : isOpen ? "chevron-up" : "chevron-down",
        }}
        e2eIdentifiers={e2eIdentifiers}
        onOpening={() => setIsOpen(true)}
        onClosing={() => setIsOpen(false)}
      />
    </div>
  );
};

export default observer(Dropdown);
