import { Intent } from "@blueprintjs/core";

import { showToast } from "@components/UiLayers/toaster";
import { copyImageToClipboard, downloadImage, getPngBlob } from "@utilities/image";
import { getAssetLink } from "@utilities/LinkUtils";

const useThumbnailActions = (assetLink: string, onAction?: () => void) => {
  const onCopy = async () => {
    if (assetLink) {
      try {
        const link = getAssetLink(assetLink, true) ?? "";
        const pngBlob = await getPngBlob(link);
        await copyImageToClipboard(pngBlob);
        showToast("Thumbnail copied to clipboard", Intent.SUCCESS);
      } catch (error) {
        showToast("Failed to copy thumbnail to clipboard", Intent.DANGER);
      }
    }
    onAction?.();
  };

  const onDownload = () => {
    onAction?.();
    if (assetLink) {
      downloadImage(assetLink);
    }
  };

  return {
    onCopy,
    onDownload,
  };
};

export default useThumbnailActions;
