import { useMemo } from "react";
import { Position } from "@blueprintjs/core";
import { Select } from "@blueprintjs/select";
import { useWorkspace } from "@hooks/useWorkspace";
import { SelectItem } from "@ui/Select/Item/SelectItem";
import { observer } from "mobx-react";

import { Button } from "@components/Button";
import { createPropertyInstanceEntries } from "@components/Reports/Editor/Extentions/Mention/MentionSuggestionEntriesCreation";
import { IMentionItem } from "@components/Reports/Editor/Extentions/Mention/types";
import { TitledTooltip } from "@components/Shared";
import { IPropertyInstance } from "@store/PropertyInstanceStore";
import { fuseSearch } from "@utilities/FuseSearch";

import "./PropertySelect.scss";

export type PropertySelectProps = {
  selected?: IPropertyInstance | null;
  onChange: (property: IPropertyInstance) => void;
  scalarOnly?: boolean;
};

const MAX_ITEMS = 10;

function PropertySelect({ selected, onChange, scalarOnly = false }: PropertySelectProps) {
  const workspace = useWorkspace();
  const entries = useMemo(() => createPropertyInstanceEntries(workspace.propertyInstances, scalarOnly), [workspace, scalarOnly]);

  const filterItems = (query: string, items: IMentionItem<IPropertyInstance>[]) => {
    if (!query) {
      return items?.slice(0, MAX_ITEMS);
    }
    return fuseSearch({ entries: items, query, fuseSearchOptions: { limit: MAX_ITEMS } });
  };

  let tooltipTitle = "";
  let tooltipContent = "";

  if (selected?.path) {
    const hasError = !!selected?.validationErrorMessage;

    if (hasError) {
      tooltipContent = `Error: ${selected.validationErrorMessage}`;
    } else {
      tooltipContent = `Value: ${selected.numericText}`;
    }
    tooltipTitle = selected.path;
  }

  return (
    <Select<IMentionItem<IPropertyInstance>>
      className="property-select"
      filterable
      itemListPredicate={filterItems}
      items={entries}
      popoverContentProps={{ className: "property-select--popover" }}
      popoverProps={{ minimal: true, matchTargetWidth: true }}
      onItemSelect={i => onChange(i.entity)}
      itemRenderer={(item, { handleClick }) => (
        <SelectItem
          slug={item}
          key={item.id}
          label={item.label}
          description={item.extendedLabel}
          onClick={handleClick}
          active={selected === item.entity}
        />
      )}
    >
      <TitledTooltip placement={Position.BOTTOM} title={tooltipTitle} content={tooltipContent} disabled={!selected}>
        <Button text={selected?.compactPath || "Select reference property"} rightIcon="chevron-down" e2eIdentifiers="select-property" />
      </TitledTooltip>
    </Select>
  );
}

export default observer(PropertySelect);
