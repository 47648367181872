import { useEffect, useState } from "react";
import { useSearchParam } from "@hooks/useSearchParam/useSearchParam";
import classNames from "classnames";
import { observer } from "mobx-react";
import { getSnapshot } from "mobx-state-tree";

import CommentAttachmentsList from "@components/Modeling/ModelingFrame/ModelBlock/Comments/CommentAttachmentsList";
import CommentContextMenu from "@components/Modeling/ModelingFrame/ModelBlock/Comments/CommentContextMenu";
import CommentEditor from "@components/Modeling/ModelingFrame/ModelBlock/Comments/CommentEditor";
import CommentThreadRunner from "@components/Modeling/ModelingFrame/ModelBlock/Comments/CommentThreadRunner";
import SimpleCommentEditor from "@components/SimpleCommentEditor/SimpleCommentEditor";
import appStore from "@store/AppStore";
import { CommentParent, IComment } from "@store/CommentStore";
import { IUser } from "@store/UserStore";

import CommentHeader from "./CommentHeader";

import "./Comment.scss";

interface CommentProps {
  comment: IComment;
  showUser: boolean;
  isEditing?: boolean;
  showRunner?: boolean;
  shortEditor?: boolean;
  simpleEditor?: boolean;
  hideReplyButton?: boolean;
  onCancel?: () => void;
  onReply?: (to: IUser, mentionTo: boolean) => void;
  onDelete?: () => void;
}

const Comment = ({
  comment,
  showUser,
  isEditing: editing,
  showRunner,
  shortEditor,
  simpleEditor,
  hideReplyButton,
  onCancel,
  onReply,
  onDelete,
}: CommentProps) => {
  const [isEditing, setIsEditing] = useState(editing);
  const [flashing, setFlashing] = useState(false);
  const [commentParent, setCommentParent] = useState<CommentParent>();
  const [commentId] = useSearchParam("commId");

  useEffect(() => {
    comment.getThreadListParent().then(parent => setCommentParent(parent));
  }, [comment]);

  useEffect(() => {
    setIsEditing(editing);
  }, [editing]);

  useEffect(() => {
    let stopFlashingTimeout: NodeJS.Timeout;
    let startFlashingTimeout: NodeJS.Timeout;
    if (commentId === comment.id && commentParent?.lastFocusedCommentId !== commentId) {
      startFlashingTimeout = setTimeout(() => {
        setFlashing(true);
        stopFlashingTimeout = setTimeout(() => {
          commentParent?.setLastFocusedCommentId(commentId);
          setFlashing(false);
        }, 2000);
      }, 2000);
    }
    return () => {
      clearTimeout(stopFlashingTimeout);
      clearTimeout(startFlashingTimeout);
    };
  }, [commentId, comment.id, commentParent]);

  const commenter = appStore.orgModel.info.userWithId(comment.createdBy);

  const handleConfirm = (value: string, attachmentIds: string[], publishAttachmentsToBlock: boolean) => {
    comment.update({
      text: value,
      attachmentIdList: attachmentIds,
      publishAttachmentsToBlock: publishAttachmentsToBlock,
    });
    setIsEditing(false);
  };

  const handleCancel = () => {
    setIsEditing(false);
    onCancel?.();
  };

  const handleReply = (to: IUser, mentionTo: boolean) => {
    onReply?.(to, mentionTo);
  };

  const handleDelete = () => {
    onDelete?.();
  };

  const attachmentList = getSnapshot(comment.attachments);

  return (
    <div
      className={classNames("comment", {
        ["comment--pending"]: comment?.pending,
        ["flash"]: comment.id === commentId && flashing,
      })}
    >
      {/* User info and context menu section */}
      <div className="comment--message">
        {showUser && commenter && (
          <div className="comment--message--owner-header">
            <CommentHeader
              userId={commenter.id}
              createdAt={comment.createdAt}
              updatedAt={comment.updatedAt}
              formattedUpdatedAt={comment.formattedUpdatedAt}
            />
            {!isEditing && (
              <CommentContextMenu
                comment={comment}
                mentionToInReply
                buttonsClassName="comment--context-menu-buttons"
                onEdit={() => setIsEditing(true)}
                onReply={handleReply}
                onDelete={handleDelete}
                hideReplyButton={hideReplyButton}
              />
            )}
          </div>
        )}
        {/* Editor and attachments section */}
        <div className="comment--message--content">
          {showUser && <CommentThreadRunner display={showRunner} />}
          <div className="comment--message--content--editor-and-attachments">
            {simpleEditor ? (
              <SimpleCommentEditor
                onConfirm={text => handleConfirm(text, [], false)}
                onCancel={handleCancel}
                content={comment.text}
                editable={comment.isMyComment && !!isEditing}
                resetOnConfirm
              />
            ) : (
              <CommentEditor
                workspaceId={comment.workspaceId}
                comment={comment}
                onConfirm={handleConfirm}
                content={comment.text}
                readonly={!comment.isMyComment}
                isEditing={comment.isMyComment && isEditing}
                fileList={comment.attachmentIds}
                onCancel={handleCancel}
                short={shortEditor}
              />
            )}
            {!isEditing && attachmentList.length > 0 && (
              <CommentAttachmentsList workspaceId={comment.workspaceId} attachmentIds={attachmentList} readonly allowDeletion={false} />
            )}
          </div>
          {!showUser && !isEditing && (
            <div className="comment--main-context-menu">
              <CommentContextMenu
                comment={comment}
                onEdit={() => setIsEditing(true)}
                onReply={handleReply}
                onDelete={handleDelete}
                hideReplyButton={hideReplyButton}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default observer(Comment);
