import { useCallback, useEffect, useMemo, useState } from "react";
import { Divider, ResizeSensor, useHotkeys } from "@blueprintjs/core";
import { useSearchParam, useSearchParamArray } from "@hooks/useSearchParam/useSearchParam";
import { observer } from "mobx-react";

import RollupEventsFilter from "@components/Inbox/RollupEventFilters/RollupEventsFilter";
import RollupEventReadingPane from "@components/Inbox/RollupEventReadingPane/RollupEventReadingPane";
import RollupEventsList from "@components/Inbox/RollupEventsList/RollupEventsList";
import { InboxView } from "@components/Inbox/utils/enums";
import { RollupEventsFindAllDto } from "@rollup-api/models/rollupEvents";
import SplitPaneGroup from "@router/components/Panes/SplitPaneGroup";
import appStore from "@store/AppStore";
import { RollupEventStatuses } from "@store/RollupEventsStore";

import { FilterChoices } from "../Filter/types";
import { Selector, SelectorProps } from "../Selector";

import styles from "./RollupEventsInbox.module.scss";

const emptyFilterChoices: FilterChoices = {
  originators: [],
  status: [],
  startDate: undefined,
  endDate: undefined,
};

const RollupEventsInbox = () => {
  const [selectedViewId, setSelectedViewId] = useState(InboxView.Inbox);
  const [filterChoices, setFilterChoices] = useState(emptyFilterChoices);
  const [rollupEventCounts, setRollupEventCounts] = useState<{ [key: string]: number | undefined }>({});
  const [selectorKey, setSelectorKey] = useState(false);

  const [workspaceIdParam] = useSearchParam("ws");
  const [viewAndMsgParams, , setViewAndMsgParams] = useSearchParamArray(["view", "msg"]);
  const [rollupEventIdParam, setRollupEventIdParam] = useSearchParam("msg");

  const rollupEventList = appStore.userModel?.rollupEvents?.rollupEvents;

  useEffect(() => {
    if (viewAndMsgParams.view) {
      setSelectedViewId(viewAndMsgParams.view as InboxView);
    } else {
      setViewAndMsgParams({ view: "inbox", msg: "" });
    }
  }, [viewAndMsgParams, setViewAndMsgParams]);

  const getAndSetUnreadRollupEventCounts = useCallback(async () => {
    const inboxUnreadRollupEventCount = await appStore.userModel?.rollupEvents?.getRollupEventsCount({
      statuses: [RollupEventStatuses.unread, RollupEventStatuses.unarchived],
      workspaceIds: [workspaceIdParam],
    });
    // TODO: implement mentions view once BE is ready (currently the same as inbox)
    const mentionUnreadRollupEventCount = await appStore.userModel?.rollupEvents?.getRollupEventsCount({
      statuses: [RollupEventStatuses.unread, RollupEventStatuses.unarchived],
      workspaceIds: [workspaceIdParam],
    });
    const archiveUnreadRollupEventCount = await appStore.userModel?.rollupEvents?.getRollupEventsCount({
      statuses: [RollupEventStatuses.unread, RollupEventStatuses.archived],
      workspaceIds: [workspaceIdParam],
    });
    setRollupEventCounts({
      inbox: inboxUnreadRollupEventCount,
      mentions: mentionUnreadRollupEventCount,
      archive: archiveUnreadRollupEventCount,
    });
  }, [workspaceIdParam, setRollupEventCounts]);

  // update event counts for the workspace(s) when workspaceIdParam changes
  useEffect(() => {
    getAndSetUnreadRollupEventCounts();
  }, [workspaceIdParam, getAndSetUnreadRollupEventCounts]);

  useEffect(() => {
    let statuses: RollupEventStatuses[] = [];
    if (selectedViewId === InboxView.Inbox) {
      statuses = [RollupEventStatuses.unarchived];
    } else if (selectedViewId === InboxView.Mentions) {
      // TODO: implement mentions view once BE is ready (currently the same as inbox)
      statuses = [RollupEventStatuses.unarchived];
    } else if (selectedViewId === InboxView.Archive) {
      statuses = [RollupEventStatuses.archived];
    }
    setFilterChoices(stateFilterChoices => ({ ...stateFilterChoices, status: statuses }));
  }, [selectedViewId]);

  // update messages list when parameters change
  useEffect(() => {
    refreshEventsList(filterChoices, workspaceIdParam);
  }, [workspaceIdParam, filterChoices]);

  const handleResize = () => {
    setSelectorKey(state => !state);
  };

  const handleSelectPreviousEvent = useCallback(() => {
    if (!rollupEventList) {
      return;
    }
    const index = rollupEventList?.findIndex(e => e.id === rollupEventIdParam);
    if (index === 0) {
      return;
    }
    if (index === -1 && rollupEventList.length > 0) {
      setRollupEventIdParam(rollupEventList?.map(event => event.id)[0]);
      return;
    }
    setRollupEventIdParam(rollupEventList?.map(event => event.id)[index - 1]);
  }, [setRollupEventIdParam, rollupEventIdParam, rollupEventList]);

  const handleSelectNextEvent = useCallback(() => {
    if (!rollupEventList) {
      return;
    }
    const index = rollupEventList?.findIndex(e => e.id === rollupEventIdParam);
    if (index === rollupEventList.length - 1) {
      return;
    }
    if (index === -1 && rollupEventList.length > 0) {
      setRollupEventIdParam(rollupEventList?.map(event => event.id)[rollupEventList.length - 1]);
      return;
    }
    setRollupEventIdParam(rollupEventList?.map(event => event.id)[index + 1]);
  }, [setRollupEventIdParam, rollupEventIdParam, rollupEventList]);

  const hotkeys = useMemo(
    () => [
      {
        combo: "J",
        group: "Inbox",
        label: "Previous event",
        onKeyDown: handleSelectPreviousEvent,
      },
      {
        combo: "K",
        group: "Inbox",
        label: "Next event",
        onKeyDown: handleSelectNextEvent,
      },
      {
        combo: "up",
        group: "Inbox",
        label: "Previous event",
        onKeyDown: handleSelectPreviousEvent,
        preventDefault: true,
      },
      {
        combo: "down",
        group: "Inbox",
        label: "Next event",
        onKeyDown: handleSelectNextEvent,
        preventDefault: true,
      },
    ],
    [handleSelectPreviousEvent, handleSelectNextEvent]
  );
  const { handleKeyDown, handleKeyUp } = useHotkeys(hotkeys);

  const refreshEventsList = (filterChoices: FilterChoices, workspaceIdParam?: string) => {
    const dto: RollupEventsFindAllDto = {
      take: 9999,
      skip: 0,
      createdBy: filterChoices?.originators,
      statuses: filterChoices?.status,
      startDate: filterChoices?.startDate,
      endDate: filterChoices?.endDate,
      workspaceId: workspaceIdParam,
    };
    appStore.userModel?.rollupEvents?.getRollupEvents(dto);
  };

  const handleOnEventMove = () => {
    getAndSetUnreadRollupEventCounts();
  };

  const handleFilterOptionsChange = useCallback(async (filterChoices: FilterChoices) => {
    setFilterChoices(filterChoices);
  }, []);

  const setSelectedView = (id: string) => {
    if (id !== selectedViewId) {
      setViewAndMsgParams({ view: id, msg: "" });
      setSelectedViewId(id as InboxView);
    }
  };

  const inboxSelectorOptions: SelectorProps["options"] = [
    {
      id: InboxView.Inbox as string,
      label: "Inbox",
      tag: rollupEventCounts.inbox || undefined,
      e2eIdentifiers: "inbox",
    },
    { id: InboxView.Mentions as string, label: "Mentioned", tag: rollupEventCounts.mentions || undefined, e2eIdentifiers: "mentions" },
    { id: InboxView.Archive as string, label: "Archive", tag: rollupEventCounts.archive || undefined, e2eIdentifiers: "archive" },
  ];

  return (
    <div className={styles.inbox} onKeyDown={handleKeyDown} onKeyUp={handleKeyUp} tabIndex={0}>
      <SplitPaneGroup className={styles.inbox} minSize={352} maxSize={516}>
        <ResizeSensor onResize={handleResize}>
          <div className={styles.inbox}>
            <Selector
              className={styles.inboxSelector}
              options={inboxSelectorOptions}
              selected={selectedViewId}
              onSelected={id => setSelectedView(id)}
              key={+selectorKey}
            />
            <Divider />
            <RollupEventsFilter onChange={handleFilterOptionsChange} />
            <RollupEventsList
              rollupEventList={appStore.userModel?.rollupEvents?.rollupEvents}
              onMarkAs={getAndSetUnreadRollupEventCounts}
              onMove={handleOnEventMove}
            />
          </div>
        </ResizeSensor>
        <RollupEventReadingPane onMarkAs={getAndSetUnreadRollupEventCounts} onMove={handleOnEventMove} />
      </SplitPaneGroup>
    </div>
  );
};

export default observer(RollupEventsInbox);
