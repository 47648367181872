import { NonIdealState } from "@blueprintjs/core";
import { observer } from "mobx-react";

import { BlockTree } from "@components/Block";
import CreateNewInput from "@components/Shared/CreateNewInput/CreateNewInput";
import type { IBlock } from "@store/BlockStore";

/** Type defs. */
type ChildBlockListProps = {
  block: IBlock | null | undefined;
};

/** Main function. */
function ChildBlockList({ block }: ChildBlockListProps) {
  if (!block) return null;

  const hasChild = !!block.children && !!block.children.length;
  const handleCreate = async (label: string) => {
    try {
      const res = await block.addNewBlock(label);
      if (!res) {
        return false;
      } else {
        return true;
      }
    } catch (err) {
      console.error(err);
      return false;
    }
  };
  const handleValidation = (label: string) => ({ isValid: label.length > 0 });

  return (
    <div className="component--child-block-list grid gap-5 py-3">
      {/* Section: List of child blocks in single level TreeView. */}
      {hasChild && (
        <div>
          <BlockTree blocks={block.validatedChildren} depth={0} />
        </div>
      )}
      {/* Section: Empty state. */}
      {!hasChild && (
        <div>
          <NonIdealState title="This block has no children." />
        </div>
      )}
      {/* Section: Actions. */}
      <div>
        <CreateNewInput placeholder="Add new child block" tryCreate={handleCreate} validation={handleValidation} />
      </div>
    </div>
  );
}

/** Exports. */
export type { ChildBlockListProps };
export default observer(ChildBlockList);
