import { useWorkspace } from "@hooks/useWorkspace";
import { ICellRendererParams } from "ag-grid-community";
import classNames from "classnames";
import { observer } from "mobx-react";

import { BomMetaColumn } from "@rollup-api/models/bom";
import { BomTableNode } from "@store/BlockStore";
import { IBomColumn } from "@store/BomTable/BomColumnStore";

type TValueCellRenderer = ICellRendererParams<BomTableNode> & { bomColumn: IBomColumn };

const ValueCell = (props: TValueCellRenderer) => {
  const { bomColumn } = props;
  const block = props.node.data?.block;
  const workspace = useWorkspace();
  const table = workspace.bomTablesMap.get(bomColumn.table.id);
  const bomTableProxy = table?.columnMap.get(bomColumn.id);

  if (!block || !bomTableProxy) {
    return "";
  }

  return (
    <span key={props.value} className={classNames({ ["cell-title"]: bomColumn.metaColumn === BomMetaColumn.Name })}>
      {bomTableProxy.getValue(block, true)}
    </span>
  );
};

export default observer(ValueCell);
