import { AvailableNotificationSetting, NotificationSetting } from "@rollup-api/models/userSettings/notificationSetting.model";
import { UpdateNotificationSettingsDto } from "@rollup-api/models/userSettings/notificationSettingsUpdateDto.model";

import { HttpClient, ParentClient } from "./client";

export class NotificationSettings extends HttpClient {
  public constructor(parent: ParentClient) {
    super(parent);
  }

  private readonly endpoint: string = "/notification-settings";

  public retrieveAvailable = async () => {
    return this.instance.get<AvailableNotificationSetting[]>(`${this.endpoint}/available`);
  };

  public retrieve = async () => {
    return this.instance.get<NotificationSetting[]>(this.endpoint);
  };

  public update = async (dto: UpdateNotificationSettingsDto) => {
    return await this.instance.patch<NotificationSetting[]>(this.endpoint, dto);
  };
}
