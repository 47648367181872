import { Checkbox, Radio } from "@blueprintjs/core";
import { observer } from "mobx-react";

import { ImportType } from "@rollup-api/models/import";
import appStore from "@store/AppStore";
import { FeatureFlag } from "@store/FeatureFlagsStore";
import { Text } from "src/ui/Text";

import "./TypePanel.scss";

type Props = {
  type: ImportType;
  setType: (type: ImportType) => void;
  dryRun: boolean;
  setDryRun: (dryRun: boolean) => void;
};

const TypePanel = (props: Props) => {
  const { type, setType, dryRun, setDryRun } = props;

  return (
    <div className="type-panel">
      <Text className="type-panel--title">Select type of import</Text>
      <div className="type-panel--option">
        <Radio
          checked={type === ImportType.Block}
          onChange={() => setType(ImportType.Block)}
          name="type"
          large
          label="Import to Model Blocks"
          value={ImportType.Block}
        />
        <div className="type-panel--extra">Build a new tree model based on passed data, select destination block as a source</div>
      </div>
      {appStore.env.featureFlags.enabled(FeatureFlag.BOM) && (
        <div className="type-panel--option">
          <Radio
            checked={type === ImportType.Bom}
            onChange={() => setType(ImportType.Bom)}
            name="type"
            large
            label="Import to BOM"
            value={ImportType.Bom}
          />
          <div className="type-panel--extra">Create BOM table or extend the existing one with new blocks</div>
        </div>
      )}
      <div className="type-panel--option">
        <Checkbox
          large
          content="The system will require your confirmation for the imported blocks"
          onChange={e => setDryRun(e.target.checked)}
          checked={dryRun}
          label="Dry run"
        />
        <div className="type-panel--extra">The system will require your confirmation before saving any data</div>
      </div>
    </div>
  );
};

export default observer(TypePanel);
