import { JSX } from "react";
import { observer } from "mobx-react";

import Avatar from "@components/Avatar/Avatar";
import SimpleCommentEditor from "@components/SimpleCommentEditor/SimpleCommentEditor";
import { IAnnotation } from "@store/AnnotationStore";
import appStore from "@store/AppStore";
import { IComment } from "@store/CommentStore";

import styles from "./AnnotationThread.module.scss";

interface IAnnotationThreadProps {
  annotation: IAnnotation;
  disableAutoFocus?: boolean;
  renderAnnotationItem(comment: IComment): JSX.Element;
  onDraftComment?(isDraftCommentPresent: boolean): void;
  onCancel?(): void;
}

const AnnotationThread = (props: IAnnotationThreadProps) => {
  const { annotation, disableAutoFocus, renderAnnotationItem, onDraftComment, onCancel } = props;
  const { parentComment, childComments } = annotation.commentThread ?? {};

  const handleNewComment = async (value: string) => {
    await annotation.addNewComment(value);
  };

  return (
    <div className={styles.annotationThread}>
      <div className={styles.annotationThreadItems}>
        {parentComment && renderAnnotationItem(parentComment)}
        {childComments?.map(renderAnnotationItem)}
      </div>
      <div className={styles.annotationThreadCommentEditorContainer}>
        <Avatar source={appStore.userModel?.avatarUrl} size={20} />
        <SimpleCommentEditor
          onConfirm={handleNewComment}
          content=""
          editable
          resetOnConfirm
          showUnfocusedPlaceholder={annotation.commentCount > 0}
          showFocusedPlaceholder
          onCancel={onCancel}
          onDraftComment={onDraftComment}
          disableAutoFocus={disableAutoFocus}
        />
      </div>
    </div>
  );
};

export default observer(AnnotationThread);
