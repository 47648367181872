import { KeyboardEvent, useState } from "react";
import { InputGroup, Menu, MenuDivider } from "@blueprintjs/core";
import { observer } from "mobx-react";

import { Button } from "@components/Button";
import { MenuItem } from "@components/MenuItem";
import { MenuItemDelete } from "@components/MenuItems";
import { Popover } from "@components/Popover";
import { IPartNumberSchema } from "@store/PartNumberSchemaStore";

import "./PartNumberSchemaSelectorMenu.scss";

type Props = {
  onAddNew: () => void;
  onEdit: (partNumberSchema: IPartNumberSchema) => void;
  onMenuItemClick?: () => void;
  hideNameInput?: boolean;
  defaultPartNumber?: string;
  onUpdatePartNumber: (partNumber: string) => void;
  onDelete: (schemaId: string) => void;
  onSetPartNumber: (partNumberSchema: IPartNumberSchema) => void;
  partNumberSchemas: IPartNumberSchema[];
  onClose?(): void;
};

const PartNumberSchemaSelectorMenu = (props: Props) => {
  const {
    partNumberSchemas,
    defaultPartNumber = "",
    onEdit,
    onAddNew,
    onUpdatePartNumber,
    onDelete,
    onSetPartNumber,
    hideNameInput,
    onMenuItemClick,
    onClose,
  } = props;
  const [partNumber, setPartNumber] = useState(defaultPartNumber || "");

  const handleSavePartNumber = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      onUpdatePartNumber(partNumber);
      onClose?.();
    }
  };

  const handleMenuItemClick = (partNumberSchema: IPartNumberSchema) => {
    onMenuItemClick?.();
    onSetPartNumber(partNumberSchema);
  };

  const handleClearPartNumber = () => {
    onMenuItemClick?.();
    onUpdatePartNumber("");
  };

  return (
    <Menu className="part-number-schema-selector-menu">
      {!hideNameInput && (
        <InputGroup
          className="m-1"
          autoFocus
          value={partNumber}
          onChange={e => setPartNumber(e.target.value)}
          onKeyUp={handleSavePartNumber}
        />
      )}
      {defaultPartNumber && <MenuItemDelete onDelete={handleClearPartNumber} />}
      <MenuItem icon="add" text="Create new format" onClick={onAddNew} e2eIdentifiers="create-new-format" />
      {!!partNumberSchemas.length && <MenuDivider title="Select part number format" />}
      {partNumberSchemas.map((partNumberSchema: IPartNumberSchema) => (
        <div key={partNumberSchema.id} className="part-number-schema-selector-menu--item">
          <MenuItem
            className="part-number-schema-selector-menu--button"
            text={partNumberSchema.label}
            onClick={() => handleMenuItemClick(partNumberSchema)}
            e2eIdentifiers={["part-number-schema", partNumberSchema.label]}
          />
          <Popover
            className="part-number-schema-selector-menu--item-nav"
            placement="bottom-start"
            content={
              <Menu>
                <MenuItem onClick={() => onEdit(partNumberSchema)} text="Edit" icon="edit" e2eIdentifiers="edit" />
                <MenuItemDelete onDelete={() => onDelete(partNumberSchema.id)} />
              </Menu>
            }
          >
            <Button minimal icon="more" e2eIdentifiers="more" />
          </Popover>
        </div>
      ))}
    </Menu>
  );
};

export default observer(PartNumberSchemaSelectorMenu);
