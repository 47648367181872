import { observer } from "mobx-react";

import { DeleteConfirmationDialog } from "@components/DeleteConfirmationDialog";
import appStore from "@store/AppStore";

const DeleteCatalogItemDialog = () => {
  const isRef = !!appStore.ui.deleteCatalogItemReferenceId;
  const reference = appStore.orgModel.catalogItems.getCatalogItemReference(appStore.ui.deleteCatalogItemReferenceId);
  const catalogItem = appStore.orgModel.catalogItems.getCatalogItem(
    reference ? reference.referencedCatalogItem.id : appStore.ui.deleteCatalogItemId
  );

  if (!catalogItem) {
    return null;
  }

  const handleConfirm = () => {
    if (isRef) {
      appStore.orgModel.catalogItems.removeCatalogItemReference(appStore.ui.deleteCatalogItemReferenceId);
      appStore.ui.setDeleteCatalogItemReference("");
    } else {
      appStore.orgModel.catalogItems.removeCatalogItem(appStore.ui.deleteCatalogItemId);
      appStore.ui.setDeleteCatalogItem("");
    }
  };

  return (
    <DeleteConfirmationDialog
      titleItem={`Catalog item ${catalogItem.name} ${isRef ? "reference" : ""}`}
      description={`Are you sure you want to delete Catalog item ${catalogItem.name} ${isRef ? "reference" : ""}?`}
      onCancel={() => appStore.ui.setDeleteCatalogItem("")}
      onClose={() => appStore.ui.setDeleteCatalogItem("")}
      isOpen
      onConfirm={handleConfirm}
    />
  );
};

export default observer(DeleteCatalogItemDialog);
