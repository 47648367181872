import { AxiosResponse } from "axios";
import { cast, flow, getParent, getType, IAnyModelType, Instance, IType, SnapshotIn, SnapshotOut, types } from "mobx-state-tree";
import { v4 as uuidv4 } from "uuid";

import { createCreateHoopsViewDto } from "@components/Modeling/ModelingFrame/HoopsViewer/utils";
import { CommentLocationType } from "@components/Modeling/ModelingFrame/ModelBlock/Comments/utils";
import { Annotation } from "@rollup-api/models/annotations";
import { TemporalDirection } from "@rollup-api/models/comments/commentGetThreadedRequestDto.model";
import { ThreadedComments } from "@rollup-api/models/comments/threadedComments.model";
import { AnnotationStore, IAnnotation, IAnnotationMobxType } from "@store/AnnotationStore";
import appStore from "@store/AppStore";
import { IAttachment } from "@store/AttachmentStore";
import { CommentThreadListStore } from "@store/CommentThreadListStore";
import { StoreType } from "@store/types";
import { ICamera, IView, ViewStore } from "@store/ViewStore";
import { create3DAnnotationScreenshot, createAnnotation } from "@utilities/AnnotationUtils";
import { rollupClient } from "src/core/api";
interface IAnnotationListStoreVolatile {
  lastFocusedCommentId?: string;
  isPopulatingAnnotations: boolean;
  fetchedAnnotationsAmount: boolean;
}

export const AnnotationListStore = types
  .model("AnnotationList", {
    annotations: types.array<IAnnotationMobxType>(types.late((): IAnyModelType => AnnotationStore)),
    fetchingComments: types.optional(types.boolean, false),
    activeAnnotationId: types.maybeNull(types.string),
    annotationsAmount: types.optional(types.number, 0),
    commentThreadList: types.optional(CommentThreadListStore, {
      parentTake: 9999,
    }),
  })
  .volatile<IAnnotationListStoreVolatile>(() => ({
    lastFocusedCommentId: undefined,
    isPopulatingAnnotations: false,
    fetchedAnnotationsAmount: false,
  }))
  .actions(self => ({
    fetchAnnotationsAmount: flow(function* fetchAnnotationsAmount(): Generator<any, boolean, any> {
      if (self.fetchedAnnotationsAmount) {
        return false;
      }

      const parent = getParent(self, 1) as IAttachment;

      const {
        data,
        status,
      }: AxiosResponse<{
        annotations: Annotation[];
      }> = yield rollupClient.annotations.retrieveByParent(parent.id);
      if (status !== 200) {
        console.warn("Error fetching annotations amount");
        return false;
      }

      self.fetchedAnnotationsAmount = true;
      self.annotationsAmount = data.annotations.length;
      return true;
    }),
    fetchAnnotations: flow(function* fetchAnnotations(): Generator<any, boolean, any> {
      // Populate annotations
      const parent = getParent(self, 1) as any;
      const parentType = getType(parent).name as StoreType;

      try {
        const { data, status } = yield rollupClient.annotations.retrieveByParent(parent.id);
        if (status !== 200) {
          console.warn(`Error fetching annotations for ${parentType} ${parent.id}`);
          return false;
        } else if (!self.fetchedAnnotationsAmount) {
          self.annotationsAmount = data.annotations.length;
          self.fetchedAnnotationsAmount = true;
        }

        for (const restoredAnnotation of data.annotations) {
          // create new annotation with the data from the API if it doesn't exist in the store
          const existingAnnotation = self.annotations.find(annotation => annotation.id === restoredAnnotation.id);
          if (!existingAnnotation) {
            const annotation = createAnnotation(restoredAnnotation, parent.id);
            self.annotations.push(annotation);
          } else {
            if (existingAnnotation.locationData3D?.recreated === false) {
              existingAnnotation.locationData3D.recreated = true;
            }
          }
        }
        // remove annotations that are not in the API response
        for (const existingAnnotation of self.annotations) {
          if (!data.annotations.find((annotation: Annotation) => annotation.id === existingAnnotation.id)) {
            self.annotations.remove(existingAnnotation);
          }
        }
        return true;
      } catch (error) {
        console.error(`Error fetching annotations for ${parentType} ${parent.id}`, error);
        return false;
      }
    }),
    refreshAnnotationCommentThread() {
      // Assign correct comment threads to the correct annotations
      for (const thread of self.commentThreadList.threads) {
        const { parentComment } = thread;
        const annotation = parentComment && this.getAnnotationById(parentComment.displayParentId);
        if (annotation) {
          annotation.setCommentThread(thread);
        }
      }
    },
    hasAnnotation(annotationId: string): boolean {
      return !!this.getAnnotationById(annotationId);
    },
    getAnnotationById(id: string) {
      return self.annotations.find(annotation => annotation.id === id);
    },
  }))
  .actions(self => ({
    populate: flow(function* populate(): Generator<any, boolean, any> {
      self.isPopulatingAnnotations = true;
      // Fetch annotations
      yield self.fetchAnnotations();
      // Fetch comment threads
      const parentId = (getParent(self, 1) as any).id;
      yield self.commentThreadList.fetchThreadList({
        initialFetch: true,
        temporalDirection: TemporalDirection.Older,
        childTake: 5,
        parentId,
        type: CommentLocationType.Annotation,
      });

      self.refreshAnnotationCommentThread();

      self.isPopulatingAnnotations = false;
      return true;
    }),
    setAllAnnotationsExpanded(expanded: boolean) {
      self.annotations.forEach(annotation => {
        annotation.setIsExpanded(expanded);
      });
    },
    setLastFocusedCommentId(id: string) {
      self.lastFocusedCommentId = id;
    },
    loadThreadedComments: flow(function* loadThreadedComments(
      threadedComments: ThreadedComments
    ): Generator<Promise<boolean>, void, boolean> {
      yield self.fetchAnnotations();
      self.commentThreadList.loadThreadedComments(threadedComments, TemporalDirection.Older);
      self.refreshAnnotationCommentThread();
    }),
  }))
  .actions(self => ({
    populate3DAnnotationMarkups() {
      for (const annotation of self.annotations) {
        appStore.env.attachmentViewer?.annotationMarkupManager?.recreateAnnotationAndMarkupFromStore(annotation);
      }
    },
    setActiveAnnotationId(id: string | null) {
      self.activeAnnotationId = id;
    },
    // Used only for HOOPS 3D model attachments
    addNew3DAnnotation({
      viewName,
      selectionPosition,
      selectionNormal,
      partId,
      faceIndex,
      screenPos,
    }: {
      viewName: string;
      selectionPosition: Communicator.Point3;
      selectionNormal: Communicator.Point3;
      partId: number;
      faceIndex: number;
      screenPos: Communicator.Point3;
    }): IAnnotation | null {
      const parent = getParent(self, 1) as any;
      const annotationId = uuidv4();
      // Extract the current view
      const viewer = appStore.env.attachmentViewer?.viewerInstance;
      if (!viewer) {
        throw new Error("Viewer not found");
      }
      const newViewDto = createCreateHoopsViewDto(viewName, viewer, parent.id, parent.workspaceId);
      if (!newViewDto) {
        throw new Error("Error creating view");
      }

      const newView = ViewStore.create({
        id: annotationId,
        uniqueId: newViewDto.name,
        name: newViewDto.name,
        camera: newViewDto.camera as ICamera,
        cuttingData: newViewDto.cuttingData,
        explodeMagnitude: newViewDto.explodeMagnitude,
        lineVisibility: newViewDto.lineVisibility,
        faceVisibility: newViewDto.faceVisibility,
        markup: [],
        sheetId: -1,
        defaultVisibility: newViewDto.defaultVisibility,
        createdAt: new Date().toISOString(),
        updatedAt: new Date().toISOString(),
        visibilityExceptions: [],
        colors: [],
        imageSrc: "",
        recreated: false,
        cloudFileId: parent.id,
        isolatedNodeId: appStore.env.attachmentViewer?.isolatedNode?.id,
      });

      // create a new annotation in the store
      const newAnnotation = AnnotationStore.create({
        id: annotationId,
        parentType: StoreType.Attachment,
        parentId: parent.id,
        createdBy: appStore.userModel?.id || "",
        workspaceId: parent.workspaceId,
        topCommentingUsers: [],
        posted: false,
        locationData3D: {
          faceIndex,
          partId,
          pin2dPositionX: 0,
          pin2dPositionY: 0,
          selectionNormal: selectionNormal,
          selectionPosition: selectionPosition,
          view: newView,
        },
      });

      self.annotations.push(newAnnotation);
      create3DAnnotationScreenshot(newAnnotation, viewer, screenPos);
      return newAnnotation;
    },
    addNew3DAnnotationOld({
      viewName,
      selectionPosition,
      selectionNormal,
      partId,
      faceIndex,
    }: {
      viewName: string;
      selectionPosition: Communicator.Point3;
      selectionNormal: Communicator.Point3;
      partId: number;
      faceIndex: number;
    }): IAnnotation | null {
      const parent = getParent(self, 1) as any;
      const annotationId = uuidv4();
      // Extract the current view
      const viewer = appStore.env.attachmentViewer?.viewerInstance;
      if (!viewer) {
        throw new Error("Viewer not found");
      }
      const newViewDto = createCreateHoopsViewDto(viewName, viewer, parent.id, parent.workspaceId);
      if (!newViewDto) {
        throw new Error("Error creating view");
      }

      const newView: IView = ViewStore.create({
        id: annotationId,
        uniqueId: newViewDto.name,
        name: newViewDto.name,
        camera: newViewDto.camera as ICamera,
        cuttingData: newViewDto.cuttingData,
        explodeMagnitude: newViewDto.explodeMagnitude,
        lineVisibility: newViewDto.lineVisibility,
        faceVisibility: newViewDto.faceVisibility,
        markup: [],
        sheetId: -1,
        defaultVisibility: newViewDto.defaultVisibility,
        createdAt: new Date().toISOString(),
        updatedAt: new Date().toISOString(),
        visibilityExceptions: [],
        colors: [],
        imageSrc: "",
        recreated: false,
        cloudFileId: parent.id,
        isolatedNodeId: appStore.env.attachmentViewer?.isolatedNode?.id,
      });

      // create a new annotation in the store
      const newAnnotation = AnnotationStore.create({
        id: annotationId,
        parentType: StoreType.Attachment,
        parentId: parent.id,
        createdBy: appStore.userModel?.id || "",
        workspaceId: parent.workspaceId,
        topCommentingUsers: [],
        posted: false,
        locationData3D: {
          faceIndex,
          partId,
          pin2dPositionX: 0,
          pin2dPositionY: 0,
          selectionNormal: selectionNormal,
          selectionPosition: selectionPosition,
          view: newView,
        },
      });

      self.annotations.push(newAnnotation);
      return newAnnotation;
    },
    addAnnotation(workspaceId: string, parentType: StoreType): IAnnotation {
      const parent = getParent(self, 1) as any;
      const annotation = AnnotationStore.create({
        createdBy: appStore.userModel?.id || "",
        parentType,
        parentId: parent.id,
        workspaceId,
        posted: false,
      });
      self.annotations.push(annotation);
      return annotation;
    },
    deleteAnnotation(annotation: IAnnotation) {
      annotation.delete();
      self.annotations.remove(annotation);
    },
    cleanUpAnnotations() {
      self.annotations = cast([]);
    },
  }))

  .views(self => ({
    get annotationCount() {
      return self.annotations?.length;
    },
    get expandedAnnotationId() {
      const expandedAnnotation = self.annotations.find(annotation => annotation.isExpanded);
      return expandedAnnotation?.id;
    },
    get hasComment(): boolean {
      return self.annotations[0]?.hasComment;
    },
  }));

export interface IAnnotationList extends Instance<typeof AnnotationListStore> {}
export interface IAnnotationListSnapshotIn extends SnapshotIn<typeof AnnotationListStore> {}
interface IAnnotationListSnapshotOut extends SnapshotOut<typeof AnnotationListStore> {}
export interface IAnnotationListMobxType extends IType<IAnnotationListSnapshotIn, IAnnotationListSnapshotOut, IAnnotationList> {}
