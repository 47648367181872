import { Menu } from "@blueprintjs/core";
import { observer } from "mobx-react";

import { disabledTooltip } from "@components/CatalogItems/constants";
import { CatalogItemWeightUnit } from "@components/CatalogItemWeightPicker/CatalogItemWeightPicker";
import { CellDropdown } from "@components/CellDropdown";
import { MenuItem } from "@components/MenuItem";
import appStore from "@store/AppStore";
import { withObservedValueGetter } from "@utilities/AgGridUtils";

import { TCellRendererProps } from "../types";

import styles from "./WeightUnitCell.module.scss";

const WeightUnitCell = (props: TCellRendererProps) => {
  const rowEditProgress = appStore.ui.pdmItemVersionProgress;
  const disabled = rowEditProgress && props.data?.catalogItem?.id !== rowEditProgress.catalogItemId;
  const catalogItem = props.data?.catalogItem;
  const weightUnit = catalogItem?.weightUnit;

  if (!catalogItem) {
    return null;
  }

  const valueChanged = rowEditProgress?.weightUnit && rowEditProgress.weightUnit !== catalogItem.weightUnit;
  const isEdited = !disabled && valueChanged;

  const handleChangeUnit = (unit: string) => {
    props.setValue?.(unit);
  };

  const getMenuContent = () => (
    <Menu>
      {Object.values(CatalogItemWeightUnit).map(u => (
        <MenuItem key={u} text={u} active={weightUnit === u} onClick={() => handleChangeUnit(u)} e2eIdentifiers={u} />
      ))}
    </Menu>
  );

  return (
    <CellDropdown
      tooltip={disabled ? disabledTooltip : undefined}
      disabled={!!disabled}
      className={isEdited ? styles.weightUnitCellEdited : undefined}
      buttonClassName={styles.weightUnitCell}
      text={props.value || "Unit"}
      content={getMenuContent()}
      e2eIdentifiers="catalog-item-weight-unit"
    />
  );
};

export default withObservedValueGetter(observer(WeightUnitCell));
