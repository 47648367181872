import { EntityModelBase } from "../../shared/entityModelBase";

export enum AttributeDataType {
  scalar = "scalar",
  string = "string",
  array = "array",
}

export class Attribute extends EntityModelBase {
  public readonly parentInterface!: string;
  public readonly label!: string;
  public readonly description!: string;
  public readonly unit?: string;
  public readonly locked?: boolean;
  public readonly dataType?: AttributeDataType;
  public readonly stringValue?: string;
  public readonly uncertaintyValue?: number;
  public readonly uncertaintyPercentage?: number;
}
