import { observer } from "mobx-react";
import { isAlive } from "mobx-state-tree";

import TextCell from "@components/Modeling/PropertyDefinition/Cells/TextCell";
import { TCellRendererProps } from "@components/Table";
import { IPropertyDefinition } from "@store/PropertyDefinitionStore";

const UnitCell = (cellProps: TCellRendererProps<IPropertyDefinition>) => {
  const { data } = cellProps;
  const isBlockAlive = isAlive(data);

  return isBlockAlive ? <TextCell label={data.unit} e2eIdentifiers={["unit", data?.label]} /> : null;
};

export default observer(UnitCell);
