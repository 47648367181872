import { ProgressBar } from "@blueprintjs/core";
import { ProgressBarProps } from "@blueprintjs/core";
import { observer } from "mobx-react";

import "./LinearProgress.scss";

interface LinerProgressProps extends ProgressBarProps {
  message: string;
}

const LinearProgress = ({ value, message, intent = "primary", ...other }: LinerProgressProps) => {
  // Used to ensure that we don't get a CSS animation between determinate and indeterminate progress bars
  const barKey = value === undefined ? "indeterminate" : "progress";
  return (
    <div className="progress-bar-container">
      <div className="progress-bar">
        <ProgressBar key={barKey} value={value} stripes intent={intent} {...other} />
      </div>
      <div className="progress-bar-message">{message}</div>
    </div>
  );
};

export default observer(LinearProgress);
