import { observer } from "mobx-react";

import { MenuItem } from "@components/MenuItem";
import appStore from "@store/AppStore";
import { IBlock } from "@store/BlockStore";
import { FeatureFlag } from "@store/FeatureFlagsStore";
import { EntityType } from "@store/types";

export const CopyPasteBlockMenuItems = observer(({ block }: { block: IBlock }) => {
  const workspace = appStore.workspaceModel;
  if (!appStore.env.featureFlags.enabled(FeatureFlag.COPY_PASTE_BLOCKS) || !block || !workspace) {
    return null;
  }

  const blockCopied = appStore.env.copiedEntityType === EntityType.Block;
  const handlePasteClicked = () => {
    workspace.duplicateBlock(appStore.env?.copiedEntity?.id, block.id);
  };

  return (
    <>
      <MenuItem
        icon="clipboard"
        e2eIdentifiers="copy-block"
        text="Copy"
        onClick={() => appStore.env.setCopiedEntity(block)}
        disabled={block === workspace.rootBlock}
      />
      {blockCopied && (
        <MenuItem
          icon="cube-add"
          e2eIdentifiers="paste-block"
          text="Paste block"
          disabled={!appStore.env.canPasteOnBlock(block)}
          onClick={handlePasteClicked}
        />
      )}
    </>
  );
});
