import { useEffect, useState } from "react";
import { observer } from "mobx-react";

import { GitHubDetails, GitHubItemType } from "@rollup-api/models/integrations";
import { isIssueDetails, isPullRequestDetails, isRepoDetails } from "@rollup-api/models/integrations/github.type-guards";
import { IAttachment } from "@store/AttachmentStore";
import { rollupClient } from "src/core/api";

import AttachmentDetailsDialog from "../AttachmentDetailsDialog";
import AttachmentDetailsImagePreview from "../AttachmentDetailsImagePreview";
import AttachmentDetailsLoading from "../AttachmentDetailsLoading";

import GithubIssueDetailsPanel from "./GithubIssueDetailsPanel";
import GithubPullRequestDetailsPanel from "./GithubPullRequestDetailsPanel";
import GithubRepoDetailsPanel from "./GithubRepoDetailsPanel";
import GithubTagDetailsPanel from "./GithubTagDetailsPanel";

interface IAttachmentDetailsGithubProps {
  attachment: IAttachment;
  isOpen: boolean;
  onClose: () => void;
}

const AttachmentDetailsGithub = (props: IAttachmentDetailsGithubProps) => {
  const [metadata, setMetadata] = useState<GitHubDetails>();
  const { attachment, isOpen, onClose } = props;
  const { id, type } = attachment.metadata ?? {};
  const isTagAttachment = type === GitHubItemType.TAG;

  useEffect(() => {
    if (isOpen && !metadata && id) {
      switch (type as GitHubItemType) {
        case GitHubItemType.REPO:
          rollupClient.integrations.fetchGitHubRepo(id).then(res => setMetadata(res.data));
          break;
        case GitHubItemType.PULL_REQUEST:
          rollupClient.integrations.fetchGitHubPullRequest(id).then(res => setMetadata(res.data));
          break;
        case GitHubItemType.ISSUE:
          rollupClient.integrations.fetchGitHubIssue(id).then(res => setMetadata(res.data));
          break;
        case GitHubItemType.TAG:
          break;
      }
    }
  }, [isOpen, id, metadata, type]);

  const renderContent = (metadata?: GitHubDetails) => {
    if (isRepoDetails(metadata)) {
      return (
        <>
          <AttachmentDetailsImagePreview src={metadata.openGraphImageUrl} />
          <GithubRepoDetailsPanel metadata={metadata} />
        </>
      );
    } else if (isPullRequestDetails(metadata)) {
      return (
        <>
          <AttachmentDetailsImagePreview />
          <GithubPullRequestDetailsPanel metadata={metadata} />
        </>
      );
    } else if (isIssueDetails(metadata)) {
      return (
        <>
          <AttachmentDetailsImagePreview />
          <GithubIssueDetailsPanel metadata={metadata} />
        </>
      );
    } else if (isTagAttachment) {
      return (
        <>
          <AttachmentDetailsImagePreview />
          <GithubTagDetailsPanel attachment={attachment} />
        </>
      );
    }
  };

  return (
    <AttachmentDetailsDialog label={attachment.label} isOpen={isOpen} onClose={onClose}>
      {metadata || isTagAttachment ? renderContent(metadata) : <AttachmentDetailsLoading />}
    </AttachmentDetailsDialog>
  );
};

export default observer(AttachmentDetailsGithub);
