import { useRef } from "react";
import { Menu, MenuDivider, Position } from "@blueprintjs/core";
import { useAttachments } from "@hooks/useAttachments";
import { useWorkspace } from "@hooks/useWorkspace";
import classNames from "classnames";
import { observer } from "mobx-react";

import AttachmentPopup from "@components/AttachmentPopup";
import { Button } from "@components/Button";
import { MenuItem } from "@components/MenuItem";
import { MenuItemDelete } from "@components/MenuItems";
import { useAttachmentFunctions } from "@components/Modeling/ModelingFrame/ModelBlock/Attachments/attachmentHooks";
import { IPopoverRefType, Popover } from "@components/Popover";
import { Attachment } from "@rollup-api/models";
import appStore from "@store/AppStore";
import { IAttachment } from "@store/AttachmentStore";

import { TCellRendererProps } from "../types";

import "./AttachmentCell.scss";

const AttachmentCell = (props: TCellRendererProps) => {
  const block = props.data?.block;
  const popoverRef = useRef<IPopoverRefType>(null);
  const workspace = useWorkspace();
  const tableProxy = workspace.bomTablesMap.get(appStore.env.activeBomTableId || "");
  const { handleDownload } = useAttachmentFunctions({ attachment: block?.dedicatedAttachment });
  const attachments = useAttachments();

  if (!block || !tableProxy) {
    return null;
  }

  const availableAttachments = block.attachments?.filter(a => a.id !== block.dedicatedAttachment?.id) || [];

  const onAddNewAttachment = (_link: string, attachment?: Attachment) => {
    if (attachment && appStore.userModel) {
      const attachmentInstance = attachments.addExistingAttachment({
        ...attachment,
        label: attachment.label || "",
        fileType: attachment.fileType || "",
        userId: appStore.userModel.id,
        workspaceId: workspace.id,
      });

      if (attachmentInstance) {
        block?.setDedicatedAttachment(attachmentInstance);
      }
    }
  };

  const getAttachmentsNav = () => {
    return (
      <Menu>
        {block.dedicatedAttachment && (
          <>
            <MenuItemDelete text="Remove" onDelete={block.removeDedicatedAttachment} />
            <MenuDivider />
          </>
        )}
        {renderAddAttachment(true)}
        {!!availableAttachments.length && (
          <>
            <MenuDivider />
            {availableAttachments.map((a: IAttachment) => (
              <MenuItem text={a.title} onClick={() => block?.setDedicatedAttachment(a)} key={a.id} e2eIdentifiers={[a.title]} />
            ))}
          </>
        )}
      </Menu>
    );
  };

  const renderAddAttachment = (listItem?: boolean) => (
    <>
      <Popover
        fill
        ref={popoverRef}
        position="bottom-right"
        popoverClassName="report-cover-change-button"
        content={
          <AttachmentPopup
            workspaceId={workspace.id}
            blockId={block.id}
            allowAllFormats
            hideLinkInput
            hideRemove
            onLinkChange={onAddNewAttachment}
          />
        }
      >
        <Button
          alignText="left"
          className={classNames({
            ["attachment-cell--add"]: !listItem,
            ["attachment-cell--add-list"]: listItem,
          })}
          minimal
          fill
          icon={listItem ? "paperclip" : "add"}
          e2eIdentifiers="add-image"
          text={listItem && "Upload new attachment"}
        />
      </Popover>
    </>
  );

  const renderView = () => {
    if (block.dedicatedAttachment) {
      return (
        <>
          <span className="attachment-cell--link" onMouseUp={handleDownload}>
            {block.dedicatedAttachment.title}
          </span>
          <Popover position={Position.BOTTOM_LEFT} content={getAttachmentsNav()}>
            <Button minimal icon="chevron-down" e2eIdentifiers="attachment-cell" />
          </Popover>
        </>
      );
    }

    if (!block.attachments?.length) {
      return null;
    }

    return <span className="attachment-cell--placeholder">Click to set file</span>;
  };

  if (!block.dedicatedAttachment && !block.attachments?.length) {
    return <div className="attachment-cell">{renderAddAttachment()}</div>;
  }

  return (
    <div className="attachment-cell">
      <Popover
        className="attachment-cell--popover"
        disabled={block.dedicatedAttachment || !block.attachments?.length}
        position={Position.BOTTOM_LEFT}
        content={getAttachmentsNav()}
      >
        {renderView()}
      </Popover>
    </div>
  );
};

export default observer(AttachmentCell);
