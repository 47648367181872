import { useState } from "react";
import { Text, TextVariant } from "@ui/Text";
import { observer } from "mobx-react";

import DialogSimple from "@components/Dialog/DialogSimple";
import BillingSupportNote from "@router/elements/settings/SettingsPlanAndBilling/BillingSupportNote";
import CustomerPortalPaywall from "@router/elements/settings/SettingsPlanAndBilling/Paywall/Paywall";
import { ISelectedPlanData } from "@router/elements/settings/SettingsPlanAndBilling/types";
import appStore from "@store/AppStore";

import styles from "./ExpiredFreeTrialDialog.module.scss";

const ExpiredFreeTrialDialog = () => {
  const [isOpen, setIsOpen] = useState(true);
  const productId = import.meta.env.VITE_STIGG_PRODUCT_ID;

  const handlePlanSelected = (plan: ISelectedPlanData) => {
    setIsOpen(false);
    appStore.env.setSelectedBillingPlan(plan);
  };

  return (
    <DialogSimple
      className={styles.expiredFreeTrialDialog}
      title="Your trial has expired. Subscribe now."
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
    >
      <div className={styles.expiredFreeTrialDialogBody}>
        <Text variant={TextVariant.H2}>Time to select your plan</Text>
        <div>
          The 30-days trial for your team has just ended, but all of your workspaces are still safe. If you want to continue to use Rollup
          please select your plan below.
        </div>
        <CustomerPortalPaywall className={styles.customerPortalPaywall} productId={productId} onPlanSelected={handlePlanSelected} />
        <BillingSupportNote />
      </div>
    </DialogSimple>
  );
};

export default observer(ExpiredFreeTrialDialog);
