import { isMacOS } from "./platform";

export const ENTER_KEY = "Enter";
export const ESCAPE_KEY = "Escape";
export const BACKSPACE_KEY = "Backspace";
export const DELETE_KEY = "Delete";
export const ARROW_UP_KEY = "ArrowUp";
export const ARROW_DOWN_KEY = "ArrowDown";

/**
 * Keys object contains text representation of buttons. For keycodes use separate exports!
 */
export const Keys = {
  mod: isMacOS() ? "Cmd" : "Ctrl",
};
