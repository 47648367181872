import { PropertyDataType } from "@rollup-io/engineering";
import { Instance, IType, SnapshotIn, SnapshotOut, types } from "mobx-state-tree";

import { IUpdateDataSinkEntryDto } from "@rollup-api/models/data-sinks";
import { applySanitizedSnapshot } from "@utilities/MobxUtils";

import { DataSinkStore } from "./DataSinkStore";

export const DataSinkEntryStore = types
  .model("DataSinkEntry", {
    id: types.identifier,
    key: types.string,
    value: types.optional(types.string, ""),
    dataSink: types.safeReference(types.late(() => DataSinkStore)),
    dataType: types.optional(types.enumeration("DataType", [...Object.values(PropertyDataType)]), PropertyDataType.scalar),
    createdAt: types.optional(types.number, Date.now()),
    updatedAt: types.optional(types.number, Date.now()),
    createdBy: types.optional(types.string, ""),
    updatedBy: types.optional(types.string, ""),
  })
  .views(self => ({
    get dataSinkId(): string {
      return self.dataSink?.id ?? "";
    },
  }))
  .actions(self => ({
    patch(update: IUpdateDataSinkEntryDto) {
      applySanitizedSnapshot(self, update, ["id", "createdAt", "createdBy"]);
    },
    setKey(key: string) {
      self.key = key;
    },
    setValue(value: string) {
      self.value = value;
    },
    setDataType(dataType: PropertyDataType) {
      self.dataType = dataType;
    },
  }));

export interface IDataSinkEntry extends Instance<typeof DataSinkEntryStore> {}
export interface IDataSinkEntrySnapshotIn extends SnapshotIn<typeof DataSinkEntryStore> {}
interface IDataSinkEntrySnapshotOut extends SnapshotOut<typeof DataSinkEntryStore> {}
export interface IDataSinkEntryMobxType extends IType<IDataSinkEntrySnapshotIn, IDataSinkEntrySnapshotOut, IDataSinkEntry> {}
