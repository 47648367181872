// eslint-disable-next-line no-restricted-imports
import { AnchorButton as BPAnchorButton, AnchorButtonProps } from "@blueprintjs/core";
import classNames from "classnames";
import { observer } from "mobx-react";

import { createDataTestId, ElementType, IE2EIdentifiersParam } from "@utilities/E2EUtils";

import styles from "./AnchorButton.module.scss";

interface IAnchorButtonProps extends AnchorButtonProps, IE2EIdentifiersParam {}

const AnchorButton = (props: IAnchorButtonProps) => {
  const { e2eIdentifiers, className, ...restProps } = props;

  return (
    <BPAnchorButton
      className={classNames(styles.anchorButton, className)}
      {...createDataTestId(ElementType.Button, e2eIdentifiers)}
      {...restProps}
    />
  );
};

export default observer(AnchorButton);
