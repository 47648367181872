import { NonIdealState } from "@blueprintjs/core";
import { Text, TextVariant } from "@ui/Text";
import { observer } from "mobx-react";

import CollapsibleSection from "@components/Shared/CollapsibleSection/CollapsibleSection";
import DismissibleCallout from "@components/Shared/DismissibleCallout/DismissibleCallout";
import { ExecutionEnvironmentType } from "@rollup-api/models/execution-environments";
import { ICodeBlock } from "@store/Analysis/CodeBlockStore";

import AnalysisInputListItem from "./AnalysisInputListItem";
import AnalysisOutputListItem from "./AnalysisOutputListItem";

import "./AnalysisInputOutputSidebar.scss";

type AnalysisInputOutputSidebarProps = {
  codeBlock: ICodeBlock;
};

function AnalysisInputOutputSidebar({ codeBlock }: AnalysisInputOutputSidebarProps) {
  const inputs = codeBlock.sortedInputs;
  const outputs = codeBlock.sortedOutputs;

  if (codeBlock.type !== ExecutionEnvironmentType.Python) {
    return (
      <div className="analysis-input-output-sidebar">
        <NonIdealState icon="variable" title="Not supported" description="Inputs and outputs are only supported in Python blocks." />
      </div>
    );
  }

  return (
    <div className="analysis-input-output-sidebar">
      <CollapsibleSection
        defaultOpen
        title="Input"
        action={{ icon: "add", label: "Add new input", onClick: codeBlock.addNewInput, e2eIdentifiers: "action-add-new-analysis-input" }}
      >
        <>
          {inputs.length ? (
            inputs.map(input => <AnalysisInputListItem key={input.id} analysisInput={input} />)
          ) : (
            <NonIdealState title="No inputs" description="Add inputs to this code block." />
          )}
        </>
      </CollapsibleSection>
      <CollapsibleSection
        defaultOpen
        title="Output"
        action={{ icon: "add", label: "Add new output", onClick: codeBlock.addNewOutput, e2eIdentifiers: "action-add-new-analysis-output" }}
      >
        <>
          {outputs.length ? (
            outputs.map(output => <AnalysisOutputListItem key={output.id} analysisOutput={output} />)
          ) : (
            <NonIdealState title="No outputs" description="Add outputs to this code block." />
          )}
        </>
      </CollapsibleSection>
      <div className="spacer" />
      <DismissibleCallout intent="warning" icon="info-sign" calloutKey="code-io-limitations" title="Experimental">
        <Text variant={TextVariant.Body}>
          End-to-end code execution is currently in development. Output values from code execution can not be used in property expressions
          yet.
        </Text>
      </DismissibleCallout>
    </div>
  );
}

export default observer(AnalysisInputOutputSidebar);
