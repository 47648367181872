import { Editor, Extension } from "@tiptap/core";

import { RollupEditorType } from "@rollup-types/editor";
import { IReportBlock } from "@store/ReportBlockStore";
import { focusNextBlock } from "@utilities/TipTap";

const ArrowNavigation = Extension.create<{ reportBlock: IReportBlock; reportBlocks: IReportBlock[] }>({
  name: "arrow-navigation",
  addKeyboardShortcuts(this) {
    return {
      ArrowDown: ({ editor }: { editor: Editor }) => {
        const { reportBlock, reportBlocks } = this.options;
        if (reportBlock.type === RollupEditorType.code) {
          // prevent code block from moving to the next (none-code) line inside a current report block
          const canJumpToNext = (editor.getText().length || 0) + 1 === editor?.state.selection.$anchor.pos;
          canJumpToNext && focusNextBlock(reportBlock, reportBlocks);
          return canJumpToNext;
        }

        if (reportBlock.type === RollupEditorType.table) {
          // prevent table from moving to the next (none-table) line inside a current report block
          const canJumpToNext = editor.state.selection.from + 4 === editor.state.doc.content.size;
          canJumpToNext && focusNextBlock(reportBlock, reportBlocks);
          return canJumpToNext;
        }

        return false;
      },
    };
  },
});

export default ArrowNavigation;
