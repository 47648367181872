import { JSX } from "react";
import { NonIdealState } from "@blueprintjs/core";
import noop from "lodash/noop";
import { observer } from "mobx-react";

import { DocumentPreview } from "@components/DocumentPreview";
import ReportsEditor from "@components/Reports/Editor/ReportEditor";
import appStore from "@store/AppStore";
import { IReport } from "@store/ReportsStore";
import { getReportBlockById } from "@utilities/Properties";

import "./ReportPreview.scss";

interface IReportPreviewProps {
  report: IReport;
  menuContent?: JSX.Element;
  large?: boolean;
  usePlaceholder?: boolean;
}

// these events are irrelevant for the ReportPreview, but at the same time we don't want to make them optional since for
// all other purposes they are important
const dummyEventHandlers = {
  onEnterKeyDown: noop,
  onModEnterKeyDown: noop,
  onShiftEnterKeyDown: noop,
  onBackspaceKeyDown: noop,
  onArrowDownKey: noop,
  onArrowUpKey: noop,
};

// If we have another preview mention inside the report preview, we will display a
// placeholder instead, so we avoid infinite recursion when reports cross-reference
// themselves. This is accomplished with the help of the prop "usePlaceholder"
const injectUsePlaceholderProp = (label: string) => {
  return label.replace(/(mode="Preview")/g, '$1 usePlaceholder="true"');
};

const ReportPreview = (props: IReportPreviewProps) => {
  const { report, menuContent, large, usePlaceholder } = props;
  const user = report.updatedBy ? appStore.orgModel.info.userWithId(report.updatedBy) : undefined;

  if (usePlaceholder) {
    return (
      <NonIdealState
        className="report-preview--non-ideal-state"
        icon="warning-sign"
        title="Embedded Preview Mention"
        description={`This is a preview mention to another report: ${report.label}`}
      />
    );
  }

  return (
    <DocumentPreview
      documentType="Report"
      documentTypeIcon="clipboard"
      title={report.label}
      titleIcon={report.displayedIcon}
      updatedAt={report.updatedAt}
      user={user}
      menuContent={menuContent}
      large={large}
      containerClassName="report-preview"
    >
      {report.displayedIcon && <div className="report-preview--icon">{report.displayedIcon}</div>}
      <h1 className="report-preview--title">{report.label || "Untitled"}</h1>
      {report.reportBlocks.map(reportBlockId => {
        const reportBlock = getReportBlockById(reportBlockId);
        return (
          reportBlock && (
            <ReportsEditor
              key={reportBlockId}
              editable={false}
              reportBlock={{ ...reportBlock, label: injectUsePlaceholderProp(reportBlock.label) }}
              onUpdate={reportBlock.updateText}
              eventHandlers={dummyEventHandlers}
            />
          )
        );
      })}
    </DocumentPreview>
  );
};

export default observer(ReportPreview);
