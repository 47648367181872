import { useCallback, useEffect, useState } from "react";
import { ContextMenu, Icon } from "@blueprintjs/core";
import { useSearchParam } from "@hooks/useSearchParam/useSearchParam";
import classNames from "classnames";
import { observer } from "mobx-react";

import RollupEventContextMenu from "@components/Inbox/RollupEventContextMenu";
import { RelativeDateWithTooltip } from "@components/RelativeDateWithTooltip";
import { Tag } from "@components/Tag";
import UserInfo from "@components/UserInfo/UserInfo";
import { Profile } from "@rollup-api/models/profiles";
import { RollupEventSetStatusRequestDto } from "@rollup-api/models/rollupEvents/RollupEventSetStatusRequestDto.model";
import appStore from "@store/AppStore";
import { IRollupEvent, RollupEventLocations, RollupEventStatuses } from "@store/RollupEventsStore";
import { getParentNameAndIcon } from "@utilities/Inbox";
import { Text, TextVariant } from "src/ui/Text";

import { getEventIcon } from "../utils/getEventIcon";
import { getEventName } from "../utils/getEventName";

import styles from "./RollupEventSummary.module.scss";

interface RollupEventSummaryProps {
  rollupEvent: IRollupEvent;
  onMarkAs: () => void;
  onMove: () => void;
}

const RollupEventSummary = ({ rollupEvent, onMarkAs, onMove }: RollupEventSummaryProps) => {
  const [userProfile, setUserProfile] = useState<Profile | null>(null);
  const [isContextMenuOpen, setIsContextMenuOpen] = useState(false);
  const [parentName, setParentName] = useState("");
  const [rollupEventIdParam, setRollupEventIdParam] = useSearchParam("msg");
  const { info } = appStore.orgModel;

  useEffect(() => {
    const getAndSetUserProfile = async () => {
      if (rollupEvent.createdBy) {
        const userProfile: Profile | null = await appStore.orgModel?.info.getUserProfile(rollupEvent.createdBy);
        setUserProfile(userProfile ?? null);
      }
    };
    if (info.memberCount > 0) {
      getAndSetUserProfile();
    }
  }, [rollupEvent, info.memberCount]);

  const markAsRead = useCallback(async (id: string) => {
    const dto: RollupEventSetStatusRequestDto = {
      ids: [id],
      toStatus: RollupEventStatuses.read,
    };
    await appStore.userModel?.rollupEvents?.setRollupEventStatus(dto);
  }, []);

  useEffect(() => {
    let timeoutId: NodeJS.Timeout;
    if (rollupEventIdParam === rollupEvent.id && !rollupEvent.isRead) {
      timeoutId = setTimeout(() => {
        markAsRead(rollupEvent.id);
        onMarkAs();
      }, 500);
    }
    return () => clearTimeout(timeoutId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rollupEventIdParam]);

  // Set parent name and icon to display at the top of the reading pane.
  useEffect(() => {
    if (!rollupEvent) {
      return;
    }
    switch (rollupEvent.metadata.mentionEntityType) {
      case RollupEventLocations.comment:
        if (!rollupEvent.metadata.parentId || !rollupEvent.metadata.parentType) {
          break;
        }
        getParentNameAndIcon(rollupEvent.metadata.parentType, rollupEvent.metadata.parentId, rollupEvent.workspaceId).then(
          parentNameAndIcon => {
            setParentName(parentNameAndIcon.parentName);
          }
        );

        break;
      case RollupEventLocations.block:
      case RollupEventLocations.report:
        // TODO: implement. try to reuse the above function setParentNameAndIcon also for these cases.
        break;
      default:
        console.warn("Unknown mention entity type", rollupEvent.metadata.mentionEntityType);
    }
  }, [rollupEvent]);

  const handleEventClick = () => {
    setRollupEventIdParam(rollupEvent.id);
    // Marking the event as read without a delay seemed so fast that it looked weird, hence the timeout
    setTimeout(() => {
      markAsRead(rollupEvent.id);
      onMarkAs();
    }, 500);
  };

  return (
    <ContextMenu
      content={<RollupEventContextMenu rollupEvent={rollupEvent} onMarkAs={onMarkAs} onMove={onMove} />}
      onContextMenu={() => setIsContextMenuOpen(true)}
      onClose={() => setIsContextMenuOpen(false)}
    >
      <div
        className={classNames(styles.eventSummary, {
          [styles.unreadLeftBorder]: !rollupEvent.isRead,
          [styles.selected]: rollupEventIdParam === rollupEvent.id,
          [styles.contextMenuOpen]: isContextMenuOpen,
        })}
        onClick={handleEventClick}
      >
        <div className={styles.iconAndPreview}>
          <Icon icon={getEventIcon(rollupEvent)} />
          <Text variant={rollupEvent.isRead ? TextVariant.BodyDimmed : TextVariant.Body} clamp={1}>
            {rollupEvent.metadata.workspaceLabel}
          </Text>
          <Tag noHover>{parentName}</Tag>
        </div>
        <div className={styles.descriptionAndTime}>
          <div className={styles.creator}>
            <Text variant={TextVariant.BodyDimmed} nowrap>
              {getEventName(rollupEvent)}
            </Text>
            <UserInfo userName={userProfile?.name} avatarUrl={userProfile?.avatarUrl} size="extra-small" shortenName />
          </div>
          {rollupEvent.createdAt && <RelativeDateWithTooltip variant={TextVariant.Caption} epochTime={rollupEvent.createdAt} />}
        </div>
      </div>
    </ContextMenu>
  );
};

export default observer(RollupEventSummary);
