import { MouseEvent } from "react";
import { ButtonGroup } from "@blueprintjs/core";
import { useBool } from "@hooks/useBool/useBool";
import { BubbleMenu, Editor } from "@tiptap/react";
import classNames from "classnames";

import { CommentEditorPropertyButton } from "@components/Modeling/ModelingFrame/ModelBlock/Comments/CommentEditorPropertyButton";
import { EditorProperty } from "@components/Modeling/ModelingFrame/ModelBlock/Comments/CommentEditorPropertyButton.utils";
import { Popover } from "@components/Popover";
import { UrlInputForm } from "@components/Reports/Editor/UrlInputForm";

import "./EditorBubbleMenu.scss";

type Props = {
  editor: Editor | null;
  className?: string;
  onShow?: () => void;
  onHide?: () => void;
};

enum ESelectionType {
  CELL_SELECTION = "CellSelection",
}

const EditorBubbleMenu = (props: Props) => {
  const [popoverOpen, { set: openPopover, unset: closePopover }] = useBool(false);
  const { editor, className, onShow, onHide } = props;

  if (!editor) {
    return null;
  }

  const isCellSelection = editor.view.state.selection.constructor.name === ESelectionType.CELL_SELECTION;

  const handleShow = () => {
    // do not display bubble menu if we select table cell
    if (isCellSelection) {
      return;
    }

    onShow?.();
  };

  const handleHide = () => {
    closePopover();
    onHide?.();
  };

  const handleLinkFormSubmit = (href: string) => {
    if (!href) return editor.chain().focus().unsetLink().run();

    editor?.chain().focus().extendMarkRange("link").setLink({ href }).run();

    closePopover();
  };

  const handleLinkButtonClick = (e: MouseEvent<HTMLElement>) => {
    e.preventDefault();
    if (editor.isActive("link")) return editor?.chain().focus().unsetLink().run();
    openPopover();
  };

  return (
    <BubbleMenu
      updateDelay={0}
      className={classNames("editor-bubble-menu", className)}
      editor={editor}
      tippyOptions={{ animation: false, duration: 0, onHide: handleHide, onShow: handleShow }}
    >
      <ButtonGroup minimal>
        <CommentEditorPropertyButton name={EditorProperty.Bold} editor={editor} />
        <CommentEditorPropertyButton name={EditorProperty.Italic} editor={editor} />
        <CommentEditorPropertyButton name={EditorProperty.Strike} editor={editor} />
        <CommentEditorPropertyButton name={EditorProperty.Underline} editor={editor} />
        <CommentEditorPropertyButton name={EditorProperty.Code} editor={editor} />
        <Popover
          popoverClassName="editor-bubble-menu--link-popover"
          isOpen={popoverOpen}
          content={<UrlInputForm defaultValue={editor.getAttributes("link").href} onSubmit={handleLinkFormSubmit} />}
        >
          <CommentEditorPropertyButton name={EditorProperty.Link} editor={editor} onClick={handleLinkButtonClick} />
        </Popover>
      </ButtonGroup>
    </BubbleMenu>
  );
};

export default EditorBubbleMenu;
