import { MenuDivider } from "@blueprintjs/core";
import { observer } from "mobx-react";

import { MenuItem } from "@components/MenuItem";
import { RollupEditorType } from "@rollup-types/editor";
import appStore from "@store/AppStore";
import { IReportBlock } from "@store/ReportBlockStore";
import { changeBlockTypes } from "@utilities/TipTap";
import { isDefined } from "@utilities/TypeGuards";

import { blockTypeIcons, getTypeLabel } from "./utils";

type Props = {
  reportBlock: IReportBlock;
  allowedTypes: RollupEditorType[];
};

const ReportBlockConvertMenuItems = (props: Props) => {
  const { reportBlock, allowedTypes } = props;

  const handleChangeType = (type: RollupEditorType) => {
    const { selectedIds } = appStore.ui;
    const selectedReportBlocks = selectedIds.map(id => appStore.workspaceModel?.reportBlocksMap.get(id)).filter(isDefined);
    if (selectedReportBlocks.length && selectedIds.includes(reportBlock.id)) {
      changeBlockTypes(selectedReportBlocks, type);
    } else {
      changeBlockTypes([reportBlock], type);
    }
  };

  const renderContent = () => {
    return (
      <>
        <MenuDivider title="Convert to" />
        {allowedTypes.map(type => (
          <MenuItem
            onClick={() => handleChangeType(type)}
            key={type}
            text={getTypeLabel(type)}
            active={reportBlock.type === type}
            icon={blockTypeIcons[type]}
            e2eIdentifiers={["convert-to", type]}
          />
        ))}
      </>
    );
  };

  return allowedTypes.length ? renderContent() : null;
};

export default observer(ReportBlockConvertMenuItems);
