import { CellClassParams, ColDef, ValueGetterParams, ValueSetterParams } from "ag-grid-community";

import appStore from "@store/AppStore";
import { IPropertyDefinition } from "@store/PropertyDefinitionStore";
import { IStatusDefinition } from "@store/StatusDefinitionStore";

import ActionsCellRenderer from "./Cells/ActionsCell";
import AutoGroupHeaderCell from "./Cells/AutoGroupHeaderCell";
import { EHeaderType } from "./Cells/HeaderCell";
import MultiplicityCellRenderer from "./Cells/MultiplicityCell";
import NumberCellEditor from "./Cells/NumberCellEditor";
import PropertyValueCell from "./Cells/PropertyValueCell";
import StatusCell from "./Cells/StatusCell";
import StatusPopupCellEditor from "./Cells/StatusPopupCellEditor";
import TreeCellRenderer from "./Cells/TreeCell";
import { ACTIONS_COL_ID, CREATE_NEW_COL_ID, DEFAULT_SORT_INDEX, MULTIPLICITY_COL_ID } from "./constants";
import { NodeInfo } from "./types";
import {
  cellValueGetter,
  cellValueSetter,
  isProjectStatusCellEditable,
  propertyCellClassName,
  statusCellClassName,
  statusCellValueGetter,
  statusCellValueSetter,
} from "./utils";

export const defaultColDef = {
  editable: false,
  autoHeight: false,
  suppressMovable: true,
};

export const createNewColumnDef = {
  headerName: "",
  colId: CREATE_NEW_COL_ID,
  width: 50,
  editable: false,
  cellClass: "ag-no-actions-cell",
  cellRenderer: () => null,
  suppressSizeToFit: true,
  suppressColumnsToolPanel: true,
  headerComponentParams: {
    hideNav: true,
    headerType: EHeaderType.ADD_NEW,
  },
};

export const actionsColumnDef = {
  headerName: "",
  suppressColumnsToolPanel: true,
  colId: ACTIONS_COL_ID,
  width: 25,
  lockPosition: "left" as const,
  pinned: "left" as const,
  sortIndex: 0,
  cellClass: "ag-actions-cell",
  cellRenderer: ActionsCellRenderer,
  suppressSizeToFit: true,
  headerComponentParams: {
    hideNav: true,
  },
};

export const multiplicityColumnDef = {
  headerName: "",
  hide: !appStore.env.tabulatedViewDefaultShowMultiplicity,
  suppressColumnsToolPanel: true,
  colId: MULTIPLICITY_COL_ID,
  width: 48,
  editable: true,
  singleClickEdit: true,
  lockPosition: "left" as const,
  pinned: "left" as const,
  sortIndex: 1,
  cellClass: "ag-multiplicity-cell",
  cellEditor: NumberCellEditor,
  cellRenderer: MultiplicityCellRenderer,
  suppressSizeToFit: true,
  headerComponentParams: {
    hideNav: true,
  },
};

export const autoGroupColumnDef: ColDef = {
  headerName: "Element",
  width: 250,
  minWidth: 50,
  resizable: true,
  suppressSizeToFit: true,
  suppressColumnsToolPanel: true,
  cellClass: "ag-custom-group-cell",
  pinned: "left" as const,
  cellRenderer: TreeCellRenderer,
  headerComponent: AutoGroupHeaderCell,
  headerComponentParams: {
    hideNav: true,
  },
};

export const propertyColumnDef = (propertyDefinition: IPropertyDefinition, index: number): ColDef => ({
  colId: propertyDefinition.id,
  sortIndex: index + DEFAULT_SORT_INDEX,
  minWidth: 50,
  resizable: true,
  hide: false,
  headerName: propertyDefinition.label,
  headerComponentParams: {
    propertyDefinition: propertyDefinition,
  },
  cellClass: (cellProps: CellClassParams<NodeInfo>) => propertyCellClassName(cellProps, propertyDefinition),
  cellRenderer: PropertyValueCell,
  cellRendererParams: {
    propertyDefinitionLabel: propertyDefinition.label,
  },
  editable: true,
  valueGetter: cellValueGetter,
  valueSetter: cellValueSetter,
});

export const statusColumnDef = (statusDefinition: IStatusDefinition, index: number): ColDef => {
  return {
    colId: statusDefinition.id,
    // we take in count propertyInfo columns + action & tree columns
    sortIndex: index + DEFAULT_SORT_INDEX,
    minWidth: 50,
    resizable: true,
    hide: !appStore.env.tabulatedViewShowProjectStatuses,
    singleClickEdit: true,
    cellEditor: StatusPopupCellEditor,
    cellEditorPopup: true,
    cellRenderer: StatusCell,
    cellRendererParams: {
      statusDefinitionLabel: statusDefinition.label,
      statusDefinition: statusDefinition,
    },
    editable: isProjectStatusCellEditable(statusDefinition.type),
    cellClass: (cellProps: CellClassParams<NodeInfo>) => statusCellClassName(cellProps, statusDefinition),
    valueSetter: (cellParams: ValueSetterParams<NodeInfo>) => statusCellValueSetter(cellParams, statusDefinition),
    valueGetter: (cellProps: ValueGetterParams) => statusCellValueGetter(cellProps, statusDefinition),
    headerName: statusDefinition.label,
    cellEditorParams: {
      // prevent popup editor from being closed when new option is created
      onKeyDown: () => undefined,
      projectStatusDefinition: statusDefinition,
    },
    headerComponentParams: {
      statusDefinition,
    },
  };
};

const getColDefs = (): ColDef[] => {
  const propertyColDefs = appStore.workspaceModel?.propertyDefinitions.map(propertyColumnDef) || [];
  const statusColDefs =
    appStore.workspaceModel?.modelStatusDefinitions.map((s: IStatusDefinition, i: number) =>
      statusColumnDef(s, i + propertyColDefs.length)
    ) || [];
  return [multiplicityColumnDef, actionsColumnDef, ...propertyColDefs, ...statusColDefs, createNewColumnDef];
};

export default getColDefs;
