import { Handle, NodeProps, Position } from "reactflow";
import { Tooltip } from "@blueprintjs/core";
import { BlueprintIcon } from "@ui/BlueprintIcon";
import classNames from "classnames";
import { observer } from "mobx-react";

import { CustomNodeType } from "../types";

import "./UnknownNode.scss";

/** Type defs. */
type UnknownNodeData = {
  type: CustomNodeType;
  id: string;
  active: boolean;
};
type UnknownNodeProps = NodeProps<UnknownNodeData>;

/** Main function. */
function UnknownNode(props: UnknownNodeProps) {
  const {
    targetPosition,
    sourcePosition,
    data: { type, active },
  } = props;

  return (
    <div className={classNames("unknown-node", { active })}>
      {type !== CustomNodeType.INPUT && <Handle type="target" position={targetPosition || Position.Left} className="invisible" />}
      <div className="container">
        <BlueprintIcon icon="warning-sign" size={16} />
        <div className="content">
          <Tooltip content="Unknown node content" placement="top">
            <div className="subtitle">Unknown node</div>
          </Tooltip>
          <div className="content--title">The node type is unknown or not supported</div>
        </div>
      </div>
      {type !== CustomNodeType.OUTPUT && <Handle type="source" position={sourcePosition || Position.Right} className="invisible" />}
    </div>
  );
}

/** Exports. */
export type { UnknownNodeData, UnknownNodeProps };
export default observer(UnknownNode);
