import { toArray } from "@rollup-io/engineering";
import { AxiosResponse } from "axios";
import { reaction } from "mobx";
import { addDisposer, destroy, flow, Instance, types } from "mobx-state-tree";

import { Favorite } from "@rollup-api/models/favorites/favorite.model";
import { SimpleGenerator } from "@rollup-types/typeUtils";
import { FavoriteStore, IFavorite, IFavoriteMobxType } from "@store/FavoriteStore";
import { EntityType } from "@store/types";

import { rollupClient } from "../core/api";
import { mapRtoToSnapshot } from "../services/services.utils";

export const FavoriteModuleStore = types
  .model("FavoriteModuleStore", {
    favoritesMap: types.map<IFavoriteMobxType>(FavoriteStore),
  })
  .views(self => ({
    get values(): IFavorite[] {
      return toArray<IFavorite>(self.favoritesMap);
    },
    get aliveFavorites(): IFavorite[] {
      return this.values.filter(f => f.isAlive);
    },
    get notAliveFavorites(): IFavorite[] {
      return this.values.filter(f => !f.isAlive);
    },
    isFavorite(entityId: string): boolean {
      return !!this.getFavoriteByEntityId(entityId);
    },
    getFavoriteByEntityId(entityId: string): IFavorite | undefined {
      return this.values.find(f => f.entityId === entityId);
    },
  }))
  .actions(self => ({
    afterCreate(): void {
      const disposer = reaction(
        () => self.notAliveFavorites.length,
        () => {
          self.notAliveFavorites.forEach(favorite => this.removeFavorite(favorite));
        },
        { fireImmediately: true }
      );

      addDisposer(self, disposer);
    },
    addToFavorites: flow(function* (entityId: string, entityType: EntityType): SimpleGenerator<IFavorite, AxiosResponse<Favorite>> {
      const res = yield rollupClient.favorites.create({ entityId, entityType });
      return self.favoritesMap.put(mapRtoToSnapshot(res.data));
    }),
    removeEntityFromFavorites(entityId: string): void {
      const favorite = self.getFavoriteByEntityId(entityId);

      if (!favorite) {
        console.warn(`Favorite not found for entity ${entityId}`);
        return;
      }

      this.removeFavorite(favorite);
    },
    removeFavorite(favorite: IFavorite, disableNotify?: boolean): void {
      if (!disableNotify) {
        rollupClient.favorites.delete(favorite.id);
      }

      self.favoritesMap.delete(favorite.id);
      destroy(favorite);
    },
  }));

export interface IFavoriteModule extends Instance<typeof FavoriteModuleStore> {}
