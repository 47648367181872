import { Instance, types } from "mobx-state-tree";

import { ViewStore } from "./ViewStore";

export const LocationData3DStore = types.model("LocationData3D", {
  faceIndex: types.maybe(types.number),
  partId: types.maybe(types.number),
  pin2dPositionX: types.optional(types.number, 0),
  pin2dPositionY: types.optional(types.number, 0),
  selectionNormal: types.maybe(types.frozen<Communicator.Point3>()),
  selectionPosition: types.maybe(types.frozen<Communicator.Point3>()),
  view: types.maybe(ViewStore),
  isHidden: types.optional(types.boolean, true),
  viewId: types.maybe(types.string),
  recreated: types.optional(types.boolean, false), // false if annotation was created by user, true if annotation was recreated based on existing data
  isBehindView: types.optional(types.boolean, false),
  thumbnail: types.optional(types.string, ""),
});

export interface ILocationData3D extends Instance<typeof LocationData3DStore> {}
