import { useEffect } from "react";

import appStore from "@store/AppStore";
import { BlockSidePanel } from "@store/SidePanelStore";

interface IUseBlockViewRTSubscriptionsParams {
  workspaceId: string;
  blockId: string;
  propertyInstanceIds?: string[];
}

export const useBlockViewRTSubscriptions = (params: IUseBlockViewRTSubscriptionsParams) => {
  const { workspaceId, blockId, propertyInstanceIds } = params;
  const { activePropertyInstance } = appStore.env;
  const { activeBlockSidePanel, pinnedBlockSidePanels } = appStore.env.sidePanel;

  const isPanelOpen = (panel: BlockSidePanel) => {
    return activeBlockSidePanel === panel || (pinnedBlockSidePanels.includes(panel) && activeBlockSidePanel !== BlockSidePanel.None);
  };

  const isPropertyDetailsViewerVisible = isPanelOpen(BlockSidePanel.PropertyDetails) ? !!activePropertyInstance : false;
  const isFeedPanelVisible = isPanelOpen(BlockSidePanel.HistoryAndComments);

  const shouldSubscribeToBlockTransactions = isPropertyDetailsViewerVisible || isFeedPanelVisible;

  useEffect(() => {
    if (shouldSubscribeToBlockTransactions) {
      // Subscribe to block-level transactions
      appStore.realtimeService.subscribeToChanges(`workspace/${workspaceId}/transaction/${blockId}`);
    }

    return () => {
      if (shouldSubscribeToBlockTransactions) {
        appStore.realtimeService.unsubscribeFromChanges(`workspace/${workspaceId}/transaction/${blockId}`);
      }
    };
  }, [blockId, propertyInstanceIds, shouldSubscribeToBlockTransactions, workspaceId]);
};
