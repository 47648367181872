import { useParams } from "react-router-dom";
import { PopoverPosition } from "@blueprintjs/core";
import { Text, TextVariant } from "@ui/Text";
import { observer } from "mobx-react";

import { RelativeDateWithTooltip } from "@components/RelativeDateWithTooltip";
import appStore from "@store/AppStore";

const EditedDaysAgo = () => {
  const { reportId, requirementId, tableId } = useParams();

  const renderEditedDaysAgo = (epochTime: number) => {
    return (
      <div>
        <Text variant={TextVariant.Caption}>Edited </Text>
        <RelativeDateWithTooltip popoverPosition={PopoverPosition.BOTTOM} epochTime={epochTime} variant={TextVariant.Caption} />
      </div>
    );
  };

  if (reportId) {
    const report = appStore.workspaceModel?.reportsMap.get(reportId);
    return report ? renderEditedDaysAgo(report.updatedAt) : null;
  } else if (requirementId) {
    const requirementsPage = appStore.workspaceModel?.requirementsPages.get(requirementId);
    return requirementsPage ? renderEditedDaysAgo(requirementsPage.updatedAt) : null;
  } else if (tableId) {
    const table = appStore.workspaceModel?.bomTablesMap.get(tableId);
    return table ? renderEditedDaysAgo(table.updatedAt) : null;
  }
  return null;
};

export default observer(EditedDaysAgo);
