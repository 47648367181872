import assignIn from "lodash/assignIn";
import { IAnyModelType, Instance, IType, SnapshotIn, SnapshotOut, types } from "mobx-state-tree";
import { Socket } from "socket.io-client";

import { periodToText } from "@components/CreateCatalogItemDialog/utils";
import { CreateCatalogItemVersionDto, ThumbnailStatus, UpdateCatalogItemVersionDto } from "@rollup-api/models/catalogItem/catalogItemDtos";
import { SemanticRevisionCodeType } from "@rollup-api/models/organizationSettings/organizationSettings.model";
import appStore from "@store/AppStore";
import { IAttachment } from "@store/AttachmentStore";
import { CatalogItemStore } from "@store/CatalogItem/CatalogItemStore";

import { CatalogItemVersionMetaDataStore } from "./CatalogItemVersionMetaDataStore";

export const CatalogItemVersionStore = types
  .model("CatalogItemVersion", {
    id: types.identifier,
    catalogItem: types.safeReference(types.late((): IAnyModelType => CatalogItemStore)),
    index: types.number,
    material: types.maybeNull(types.string),
    weight: types.maybeNull(types.number),
    weightUnit: types.maybeNull(types.string),
    cost: types.maybeNull(types.number),
    costCurrency: types.maybeNull(types.string),
    supplier: types.maybeNull(types.string),
    quantity: types.maybeNull(types.number),
    leadTime: types.maybeNull(types.string),
    isDraft: types.maybeNull(types.boolean),
    revisionCode: types.maybeNull(types.string),
    revisionName: types.maybeNull(types.string),
    comment: types.maybeNull(types.string),
    attachmentId: types.maybeNull(types.string),
    createdBy: types.string,
    createdAt: types.optional(types.number, Date.now()),
    updatedAt: types.optional(types.number, Date.now()),
    updatedBy: types.maybeNull(types.string),
    metaData: types.maybeNull(CatalogItemVersionMetaDataStore),
  })
  .views(self => ({
    get leadTimeHumanized(): string {
      return self.leadTime ? periodToText(self.leadTime) : "";
    },
    get attachment(): IAttachment | undefined {
      return self.attachmentId ? appStore.orgModel.attachments.get(self.attachmentId) : undefined;
    },
    get isThumbnailReady() {
      return !self.metaData || self.metaData.thumbnailStatus === ThumbnailStatus.Ready;
    },
    get isThumbnailLoading() {
      return self.metaData && self.metaData.thumbnailStatus === ThumbnailStatus.Pending;
    },
  }))
  .actions(self => ({
    nextRevisionCode(updateType: SemanticRevisionCodeType): string {
      return self.catalogItem?.nextRevisionCode(updateType) || "";
    },
    patch(dto: UpdateCatalogItemVersionDto) {
      const invalidFields = ["id"];
      const updateKeys = Object.keys(dto);
      for (const field of invalidFields) {
        if (updateKeys.includes(field)) {
          return false;
        }
      }

      try {
        assignIn(self, dto);
        return true;
      } catch (err) {
        console.warn(err);
        return false;
      }
    },
  }));

export interface ICatalogItemVersion extends Instance<typeof CatalogItemVersionStore> {}
interface ICatalogItemVersionSnapshotIn extends SnapshotIn<typeof CatalogItemVersionStore> {}
interface ICatalogItemVersionSnapshotOut extends SnapshotOut<typeof CatalogItemVersionStore> {}
export interface ICatalogItemVersionMobxType
  extends IType<ICatalogItemVersionSnapshotIn, ICatalogItemVersionSnapshotOut, ICatalogItemVersion> {}

export function subscribeToCatalogItemVersionEvents(socket: Socket) {
  socket.on("createCatalogItemVersion", (data: { createCatalogItemVersionDto: CreateCatalogItemVersionDto }) => {
    if (data.createCatalogItemVersionDto.id) {
      // @todo implement
    }
  });

  socket.on("updateCatalogItemVersion", (data: { id: string; updateCatalogItemVersionDto: UpdateCatalogItemVersionDto }) => {
    if (data.id) {
      const item = appStore.orgModel.catalogItems.getCatalogItemVersion(data.id);
      item?.patch(data.updateCatalogItemVersionDto);
    }
  });
}
