import { IHoopsViewer } from "@store/HoopsViewerStore";

export class ContextMenuManager {
  private _store: IHoopsViewer;

  public constructor(store: IHoopsViewer) {
    this._store = store;
  }

  public activateContextMenu(nodeId: number, x: number, y: number): void {
    this._store.viewerInstance.selectionManager.selectNode(nodeId);
    this._store.activateContextMenu(nodeId, x, y);
  }

  public deactivateContextMenu(): void {
    this._store.deactivateContextMenu();
  }

  public isContextMenuActive(): boolean {
    return this._store.isContextMenuOpen;
  }
}
