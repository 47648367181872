import { Position, Tooltip } from "@blueprintjs/core";
import { observer } from "mobx-react";

import MeasureHideAllIconDark from "@assets/icons/hoopsIcons/dark/measure_hide_all_dark.svg?react";
import MeasureShowAllIconDark from "@assets/icons/hoopsIcons/dark/measure_show_all_dark.svg?react";
import MeasureHideAllIconLight from "@assets/icons/hoopsIcons/light/measure_hide_all_light.svg?react";
import MeasureShowAllIconLight from "@assets/icons/hoopsIcons/light/measure_show_all_light.svg?react";
import { Button } from "@components/Button";
import { LegacyCustomIcon } from "@components/Shared/LegacyCustomIcon/LegacyCustomIcon";
import appStore from "@store/AppStore";

import styles from "../HoopsMenu.module.scss";

type HoopsMenuHideShowMeasurementsButtonProps = {
  viewer: Communicator.WebViewer;
};

const HoopsMenuHideShowMeasurementsButton = ({ viewer }: HoopsMenuHideShowMeasurementsButtonProps) => {
  const handleHideShowMeasurementsClick = () => {
    const measurements = viewer.measureManager.getAllMeasurements();
    if (appStore.env.attachmentViewer?.areMeasurementsHidden) {
      measurements.forEach(measurement => {
        measurement.setVisibility(true);
      });
      appStore.env.attachmentViewer?.setAreMeasurementsHidden(false);
    } else {
      measurements.forEach(measurement => {
        measurement.setVisibility(false);
      });
      appStore.env.attachmentViewer?.setAreMeasurementsHidden(true);
    }
    viewer.markupManager.refreshMarkup();
    void viewer.measureManager.loadData(measurements);
  };

  const hideOrShowIcon = () => {
    if (appStore.env.themeIsDark) {
      if (appStore.env.attachmentViewer?.areMeasurementsHidden) {
        return <LegacyCustomIcon large icon={<MeasureShowAllIconDark />} />;
      } else {
        return <LegacyCustomIcon large icon={<MeasureHideAllIconDark />} />;
      }
    } else {
      if (appStore.env.attachmentViewer?.areMeasurementsHidden) {
        return <LegacyCustomIcon large icon={<MeasureShowAllIconLight />} />;
      } else {
        return <LegacyCustomIcon large icon={<MeasureHideAllIconLight />} />;
      }
    }
  };

  return (
    <Tooltip
      position={Position.TOP}
      hoverOpenDelay={500}
      content={appStore.env.attachmentViewer?.areMeasurementsHidden ? "Show all measurements" : "Hide all measurements"}
    >
      <Button
        className={styles.hoopsToolbarButtonAndButtonGroup}
        icon={hideOrShowIcon()}
        onClick={handleHideShowMeasurementsClick}
        e2eIdentifiers="hide"
      />
    </Tooltip>
  );
};

export default observer(HoopsMenuHideShowMeasurementsButton);
