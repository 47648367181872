import { HttpClient, ParentClient } from "@rollup-api/api";
import { ICreateDataSinkEntryDto, IUpdateDataSinkEntryDto } from "@rollup-api/models/data-sinks";
import { DataSinkEntry } from "@rollup-api/models/data-sinks/data-sink.model";

export class DataSinkEntries extends HttpClient {
  public constructor(parent: ParentClient) {
    super(parent);
  }

  private get endpoint() {
    return `workspace/${this.parent.workspaceId}/data-sinks`;
  }

  public create = (dataSinkId: string, dto: ICreateDataSinkEntryDto) => {
    return this.instance.post<DataSinkEntry>(`${this.endpoint}/${dataSinkId}`, dto);
  };

  public update = (dataSinkId: string, key: string, dto: IUpdateDataSinkEntryDto) => {
    return this.instance.patch<DataSinkEntry>(`${this.endpoint}/${dataSinkId}/${key}`, dto);
  };

  public delete = (dataSinkId: string, key: string) => {
    return this.instance.delete<DataSinkEntry>(`${this.endpoint}/${dataSinkId}/${key}`);
  };
}
