import { AxiosResponse } from "axios";
import assignIn from "lodash/assignIn";
import { cast, flow, IAnyModelType, Instance, IType, SnapshotIn, SnapshotOut, types } from "mobx-state-tree";
import { Socket } from "socket.io-client";
import { v4 as uuidv4 } from "uuid";

import { CommentLocationType } from "@components/Modeling/ModelingFrame/ModelBlock/Comments/utils";
import { reportNodeList } from "@components/ReportsTree/constants";
import { showApiErrorToast } from "@components/UiLayers/toaster";
import { CreateReportDto, ReportUpdateDto } from "@rollup-api/models";
import { TemporalDirection } from "@rollup-api/models/comments/commentGetThreadedRequestDto.model";
import { CommentThreadedItem } from "@rollup-api/models/comments/threadedComments.model";
import { reorderReport } from "@rollup-api/utils";
import { CommentFeedStore } from "@store/CommentFeedStore";
import { CommentThreadListStore } from "@store/CommentThreadListStore";
import { StoreType } from "@store/types";
import { moveItemInRefArray, moveItemsInStringArray, validatedRefArray } from "@utilities";
import { rollupClient } from "src/core/api";

import appStore from "./AppStore";

export const ReportStore = types
  .model(StoreType.Report, {
    id: types.identifier,
    label: types.string,
    orderIndex: types.optional(types.number, 0),
    children: types.maybeNull(types.array(types.safeReference(types.late((): IAnyModelType => ReportStore)))),
    parentReport: types.maybeNull(types.safeReference(types.late((): IAnyModelType => ReportStore))),
    reportBlocks: types.array(types.string),
    icon: types.optional(types.string, ""),
    coverUrl: types.optional(types.string, ""),
    fullWidth: types.optional(types.boolean, false),
    isTemplate: types.optional(types.boolean, false),
    updatedAt: types.optional(types.number, Date.now()),
    updatedBy: types.maybeNull(types.string),
    commentThreadList: types.optional(CommentThreadListStore, {}),
    commentFeed: types.optional(CommentFeedStore, {}),
  })
  .views(self => ({
    get validatedChildren() {
      return validatedRefArray<IReport>(self.children);
    },
    get displayedLabel() {
      return self.label;
    },
    get displayedIcon() {
      return self.icon || "📋";
    },
    get path(): string[] {
      if (!self.parentReport) {
        return [self.id];
      }

      const path: string[] = [];

      const getParentReportId = (sourceReport: IReport) => {
        path.unshift(sourceReport.id);
        const parentReport = sourceReport.parentReport as IReport;

        if (parentReport) {
          getParentReportId(parentReport);
        }
      };

      getParentReportId(self as IReport);
      return path;
    },
  }))
  .views(self => ({
    get pathNames(): string[] {
      return self.path.map(id => appStore.workspaceModel?.reportsMap.get(id)?.label || "Untitled");
    },
  }))
  .actions(self => ({
    update(dto: ReportUpdateDto, notify = true) {
      assignIn(self, dto);

      if (notify) {
        rollupClient.reports.update(self.id, dto);
      }
    },
    addBlock(id: string, orderIndex?: number): string | undefined {
      if (orderIndex !== undefined) {
        const originalBlocksOrder = self.reportBlocks.slice();
        self.reportBlocks.splice(orderIndex, 0, id);
        return originalBlocksOrder.at(orderIndex);
      } else {
        self.reportBlocks.push(id);
      }
    },
    deleteBlock(id: string) {
      self.reportBlocks = cast(self.reportBlocks.filter(i => i !== id));
    },
    patch(update: ReportUpdateDto) {
      const invalidFields = ["id"];
      const updateKeys = Object.keys(update);
      for (const field of invalidFields) {
        if (updateKeys.includes(field)) {
          return false;
        }
      }

      try {
        assignIn(self, update);
        return true;
      } catch (err) {
        console.warn(err);
        return false;
      }
    },
    moveBlocks(ids: string[], destId: string, notify = true) {
      self.reportBlocks = cast(moveItemsInStringArray(self.reportBlocks, destId, ids));

      if (notify) {
        rollupClient.reportBlocks.bulkReorder(ids, destId).catch((err: Error) => {
          showApiErrorToast("Error reordering page blocks", err);
        });
      }
    },
    moveBlock(srcId: string, destId: string, notify = true) {
      if (self.reportBlocks) {
        const srcIndex = self.reportBlocks.findIndex((id: string) => id === srcId);
        const destIndex = self.reportBlocks.findIndex((id: string) => id === destId);

        moveItemInRefArray(self.reportBlocks, srcIndex, destIndex);

        if (notify) {
          rollupClient.reportBlocks.reorder(srcId, destId).catch((err: Error) => {
            showApiErrorToast("Error reordering page block", err);
          });
        }
      }
    },
    moveReport(srcId: string, destId: string, notify = true) {
      if (self.children) {
        const srcIndex = self.children.findIndex(b => b?.id === srcId);
        const destIndex = self.children.findIndex(b => b?.id === destId);
        moveItemInRefArray(self.children, srcIndex, destIndex);
        if (notify) {
          reorderReport(srcId, destId);
        } else {
          appStore.env.reportsTreeGridApi?.setGridOption("rowData", reportNodeList());
        }
      }
    },
    getCreateDto(): CreateReportDto {
      return {
        id: uuidv4(),
        label: self.label ? `Copy of ${self.label}` : "",
        icon: self.icon,
        parentReportId: self.parentReport?.id,
      };
    },
    clearCommentHistory() {
      self.commentFeed = cast({});
    },
  }))
  .actions(self => ({
    // TODO remove this function once the backend supports these fields when creating reports
    updateFromReport(report: IReport) {
      self.update({ coverUrl: report.coverUrl, fullWidth: report.fullWidth });
    },
    fetchReportBlockComments: flow(function* fetch(): Generator<any, void, AxiosResponse<CommentThreadedItem[]>> {
      try {
        const { data } = yield rollupClient.comments.retrieveThreaded({
          parentIds: self.reportBlocks,
          childTake: 9999,
          type: CommentLocationType.Annotation,
          parentTake: 1,
          childSkip: 0,
          temporalDirection: TemporalDirection.Older,
        });
        data.forEach(({ parentId, threadedComments }) => {
          const blockId = self.reportBlocks.find(id => id === parentId);
          const block = blockId ? appStore.workspaceModel?.reportBlocksMap.get(blockId) : undefined;
          block?.annotationList.loadThreadedComments(threadedComments);
        });
      } catch (error) {
        throw new Error(`Error fetching requirement block comments for block ${self.id}: ${error}`);
      }
    }),
  }));

export interface IReport extends Instance<typeof ReportStore> {}
interface IReportSnapshotIn extends SnapshotIn<typeof ReportStore> {}
interface IReportSnapshotOut extends SnapshotOut<typeof ReportStore> {}
export interface IReportMobxType extends IType<IReportSnapshotIn, IReportSnapshotOut, IReport> {}

export function subscribeToReportEvents(socket: Socket) {
  socket.on("createReport", (data: { workspaceId: string; createReportDto: CreateReportDto }) => {
    if (data.createReportDto?.id && data.workspaceId === appStore.workspaceModel?.id) {
      const { label, id } = data.createReportDto;
      appStore.workspaceModel.createReport({ label, id }, { notify: false });
    }
  });

  socket.on("deleteReport", (data: { workspaceId: string; id: string }) => {
    if (data.id && data.workspaceId === appStore.workspaceModel?.id) {
      const report = appStore.workspaceModel.reportsMap.get(data.id);
      if (report) {
        appStore.workspaceModel.deleteReport(report.id, false);
      }
    }
  });

  socket.on("updateReport", (data: { workspaceId: string; id: string; updateReportDto: ReportUpdateDto }) => {
    if (data.id && data.workspaceId === appStore.workspaceModel?.id) {
      const report = appStore.workspaceModel.reportsMap.get(data.id);
      report?.patch(data.updateReportDto);
    }
  });

  socket.on(
    "reorderEntityReportBlock",
    (data: { workspaceId: string; id: string; reorderEntityReportBlockDto: { destinationId: string } }) => {
      if (data.id && data.reorderEntityReportBlockDto?.destinationId && data.workspaceId === appStore.workspaceModel?.id) {
        const reportBlock = appStore.workspaceModel.reportBlocksMap.get(data.id);
        const report = reportBlock ? appStore.workspaceModel.reportsMap.get(reportBlock.parentReport) : null;
        report?.moveBlock(data.id, data.reorderEntityReportBlockDto.destinationId, false);
      }
    }
  );

  socket.on(
    "bulkReorderReportBlock",
    (data: { workspaceId: string; bulkReorderReportBlockDto: { destinationId: string; ids: string[] } }) => {
      if (
        data.bulkReorderReportBlockDto.ids.length &&
        data.bulkReorderReportBlockDto?.destinationId &&
        data.workspaceId === appStore.workspaceModel?.id
      ) {
        const destinationReportBlock = appStore.workspaceModel.reportBlocksMap.get(data.bulkReorderReportBlockDto.destinationId);
        const report = destinationReportBlock ? appStore.workspaceModel.reportsMap.get(destinationReportBlock.parentReport) : null;
        report?.moveBlocks(data.bulkReorderReportBlockDto.ids, data.bulkReorderReportBlockDto.destinationId, false);
      }
    }
  );

  socket.on("reparentReport", (data: { workspaceId: string; id: string; reparentReportDto: { parentReport: string } }) => {
    if (data.id && data.workspaceId === appStore.workspaceModel?.id) {
      const report = appStore.workspaceModel.reportsMap.get(data.id);
      const parentReport = data.reparentReportDto?.parentReport
        ? appStore.workspaceModel.blockMap.get(data.reparentReportDto.parentReport)
        : undefined;
      // Blocks reparented to an empty string are detached from the tree
      if (report && (parentReport || !data.reparentReportDto?.parentReport)) {
        appStore.workspaceModel.reparentReport(report, parentReport, false);
      }
    }
  });

  socket.on("reorderReport", (data: { workspaceId: string; id: string; reorderReportDto: { destinationId: string } }) => {
    if (data.id && data.workspaceId === appStore.workspaceModel?.id) {
      const report = appStore.workspaceModel.reportsMap.get(data.id);
      report?.parentReport?.moveReport(data.id, data.reorderReportDto.destinationId, false);
    }
  });
}
