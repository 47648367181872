import { ChangeEvent, KeyboardEvent, useState } from "react";
import { InputGroup } from "@blueprintjs/core";
import classNames from "classnames";

import { Button } from "@components/Button";
import { isValidImageUrl } from "@utilities/image";
import { getAssetLink } from "@utilities/LinkUtils";

import { showApiErrorToast } from "../../UiLayers/toaster";

import styles from "../AttachmentPopup.module.scss";

type Props = {
  url?: string;
  onLinkChange: (url: string) => void;
};

const Link = (props: Props) => {
  const { url, onLinkChange } = props;
  const [loading, setLoading] = useState(false);
  const [imageLink, setImageLink] = useState(url || "");

  const handleUrlChange = (event: ChangeEvent<HTMLInputElement>) => setImageLink(event.target.value);

  const handleInputKeyUp = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter" && imageLink) {
      handleEmbed();
    }
  };

  const handleEmbed = () => {
    setLoading(true);
    const assetLink = getAssetLink(imageLink) || "";
    isValidImageUrl(assetLink)
      .then((isValid: boolean) => {
        if (!isValid) {
          showApiErrorToast("Invalid image link");
          setImageLink("");
        } else {
          onLinkChange(imageLink);
        }
        setLoading(false);
      })
      .catch(() => {
        showApiErrorToast("Failed to validate image");
        setLoading(false);
      });
  };

  return (
    <div className={classNames(styles.attachmentPopupTab, styles.attachmentPopupLinkBox)}>
      <InputGroup
        multiple
        value={imageLink}
        fill
        onKeyUp={handleInputKeyUp}
        placeholder="Paste the image link..."
        onChange={handleUrlChange}
      />
      <Button
        className={styles.attachmentPopupLink}
        loading={loading}
        disabled={!imageLink || imageLink === url}
        onClick={handleEmbed}
        e2eIdentifiers="embed-image"
      >
        Embed image
      </Button>
    </div>
  );
};

export default Link;
