export enum NotificationChannel {
  SLACK = "slack",
  EMAIL = "email",
}

export interface NotificationSetting {
  entityType: string;
  action: string;
  channels: NotificationChannel[];
}

export interface AvailableNotificationSetting {
  action: string;
  entityType: string;
  description: string;
}
