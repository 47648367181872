import { ItemPredicate, ItemRenderer, Suggest } from "@blueprintjs/select";
import { observer } from "mobx-react";

import { Button } from "@components/Button";
import { MenuItem } from "@components/MenuItem";
import appStore from "@store/AppStore";
import { IBomTable } from "@store/BomTable/BomTableStore";
import { Text } from "src/ui/Text";

import "./DestinationPanel.scss";

type Props = {
  entityId: string;
  onChange: (id: string) => void;
};

const TargetBomPanel = (props: Props) => {
  const { entityId, onChange } = props;

  const itemPredicate: ItemPredicate<IBomTable> = (query, item: IBomTable, _index, exactMatch) => {
    const normalizedTitle = item.label.toLowerCase();
    const normalizedQuery = query.toLowerCase();

    return exactMatch ? normalizedTitle === normalizedQuery : normalizedTitle.includes(normalizedQuery);
  };

  const renderAutoCompleteItem: ItemRenderer<IBomTable> = (entry, { handleClick, modifiers }) => {
    if (!entry || !modifiers.matchesPredicate) {
      return null;
    }
    const text = `${entry.label}`;

    return (
      <MenuItem
        active={modifiers.active}
        disabled={modifiers.disabled}
        text={entry.label}
        key={entry.id}
        onClick={handleClick}
        label={text}
        e2eIdentifiers={["autocomplete-item", text]}
      />
    );
  };

  const rightElement = <>{entityId && <Button minimal e2eIdentifiers="clear-selection" icon="cross" onClick={() => onChange("")} />}</>;

  return (
    <div className="destination-panel">
      <Text className="destination-panel--title">Select a BOM Table to import to or leave it blank to create a new one</Text>
      <Suggest<IBomTable>
        inputProps={{
          placeholder: "Select here",
          large: true,
          rightElement,
        }}
        key={entityId}
        onItemSelect={b => onChange(b.id)}
        itemPredicate={itemPredicate}
        popoverProps={{ matchTargetWidth: true, minimal: true }}
        popoverContentProps={{ className: "destination-panel--popover" }}
        items={appStore.workspaceModel?.bomTables || []}
        itemRenderer={renderAutoCompleteItem}
        inputValueRenderer={b => b.label}
        selectedItem={entityId ? appStore.workspaceModel?.bomTablesMap.get(entityId) : undefined}
        noResults={<MenuItem disabled text="No results" e2eIdentifiers="no-results" />}
        resetOnSelect
      />
    </div>
  );
};

export default observer(TargetBomPanel);
