import { useCallback } from "react";
import { GridApi } from "ag-grid-community";
import { observer } from "mobx-react";

import FormattedTableActionsCell from "@components/Modeling/FormattedTable/Cells/FormattedTableActionsCell";
import appStore from "@store/AppStore";
import { IStatusDefinition } from "@store/StatusDefinitionStore";

type Props = {
  status: IStatusDefinition;
  gridApi: GridApi;
  registerRowDragger: (ref: HTMLElement) => void;
};

const ActionsCell = (props: Props) => {
  const { status, gridApi, registerRowDragger } = props;

  const handleDeleteClick = useCallback(() => {
    appStore.workspaceModel?.deleteStatusDefinition(status);
    gridApi.applyTransaction({ remove: [{ id: status.id }] });
  }, [status, gridApi]);

  return <FormattedTableActionsCell node={status} onDelete={handleDeleteClick} registerRowDragger={registerRowDragger} />;
};

export default observer(ActionsCell);
