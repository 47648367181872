import { observer } from "mobx-react";

import { IUploadStore } from "@store/UploadStore";

import FileUploadListItem from "../FileUploadListItem/FileUploadListItem";

import "./FileUploadList.scss";

type TFileUploadListProps = {
  fileList: IUploadStore[];
};

const FileUploadList = ({ fileList }: TFileUploadListProps) => {
  return (
    <div>
      {fileList.map((file: IUploadStore) => (
        <FileUploadListItem key={file.id} uploadStore={file} />
      ))}
    </div>
  );
};

export default observer(FileUploadList);
