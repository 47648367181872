import { FormEvent, useRef } from "react";
import { ContextMenu, InputGroup, Tooltip } from "@blueprintjs/core";
import { useBool } from "@hooks/useBool/useBool";
import { useClickOutside } from "@hooks/useClickOutside/useClickOutside";
import { observer } from "mobx-react";

import appStore from "@store/AppStore";
import { IBlock } from "@store/BlockStore";
import { IStatusDefinition } from "@store/StatusDefinitionStore";
import { IUrl } from "@utilities/StatusInstance";

import "./StatusTypeUrlEditor.scss";

type StatusTypeUrlEditorProps = {
  statusDefinition: IStatusDefinition;
  block: IBlock;
  disabled?: boolean;
  readOnly?: boolean;
};

const StatusTypeUrlEditor = ({ statusDefinition, block, disabled, readOnly }: StatusTypeUrlEditorProps) => {
  const statusInstance = block.validatedStatusInstances.find(i => i.statusDefinition === statusDefinition);
  const urlValue = statusInstance?.urlValue;

  const [popupOpen, { set: openPopup, unset: hidePopup }] = useBool();

  if (readOnly) {
    const url = urlValue?.url;
    if (!url) {
      return null;
    }
    return (
      <a href={url} target="_blank" rel="noreferrer">
        {urlValue?.label || url}
      </a>
    );
  }

  const handleUrlSubmit = async ({ url, label }: Partial<IUrl>) => {
    hidePopup();
    // Delete an instance
    if (statusInstance && !url) return block.deleteStatusInstance(statusInstance);
    if (url) {
      // Create an instance first, if it is not already present
      const instance = statusInstance ?? (await appStore.workspaceModel?.addStatusInstance(block, statusDefinition, url));
      return instance?.setValueFromURL({ url, label });
    }
  };

  const labelText = urlValue?.label || urlValue?.url;
  const popupContent = popupOpen ? <UrlForm onSubmit={handleUrlSubmit} url={urlValue} key={urlValue?.url} /> : <></>;

  return (
    <div className="status-type-url-editor">
      <ContextMenu disabled={disabled} content={popupContent} onContextMenu={openPopup}>
        <Tooltip disabled={disabled} content={Text.tooltip.content}>
          {urlValue?.url ? (
            <a href={urlValue?.url} target="_blank" rel="noreferrer">
              {labelText}
            </a>
          ) : (
            <span className="status-type-url-editor--placeholder">{Text.tooltip.placeholder}</span>
          )}
        </Tooltip>
      </ContextMenu>
    </div>
  );
};

export default observer(StatusTypeUrlEditor);

type UrlFormProps = {
  onSubmit: (url: Partial<IUrl>) => void;
  url?: IUrl;
};

const UrlForm = ({ onSubmit, url }: UrlFormProps) => {
  const formRef = useRef<HTMLFormElement>(null);
  const labelInputRef = useRef<HTMLInputElement>(null);
  const urlInputRef = useRef<HTMLInputElement>(null);

  const handleSubmit = () => {
    return onSubmit({
      url: urlInputRef.current?.value ?? url?.url,
      label: labelInputRef.current?.value ?? url?.label,
    });
  };

  const handleFormSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    return handleSubmit();
  };

  useClickOutside(formRef, handleSubmit);

  return (
    <form ref={formRef} onSubmit={handleFormSubmit} className="flex flex-col gap-1 p-1">
      <InputGroup inputRef={labelInputRef} defaultValue={url?.label} placeholder={Text.inputs.label.placeholder} />
      <InputGroup inputRef={urlInputRef} defaultValue={url?.url} placeholder={Text.inputs.url.placeholder} leftIcon="link" />
      <input type="submit" hidden />
    </form>
  );
};

const Text = {
  tooltip: {
    content: "right-click to edit a link",
    placeholder: "Right-click to insert...",
  },
  inputs: {
    label: {
      placeholder: "Enter label...",
      label: "Label",
    },
    url: {
      placeholder: "Enter url...",
      label: "Url",
    },
  },
};
