import { observer } from "mobx-react-lite";

import { AnchorButton } from "@components/AnchorButton";

import styles from "./AnchorLink.module.scss";

interface IAnchorLinkProps {
  href: string;
}

const AnchorLink = (props: IAnchorLinkProps) => {
  return (
    <div className={styles.anchorLink}>
      <a href={props.href} target="_blank" rel="noreferrer">
        {props.href}
      </a>
      <AnchorButton icon="share" href={props.href} target="_blank" e2eIdentifiers="open-link" minimal small />
    </div>
  );
};

export default observer(AnchorLink);
