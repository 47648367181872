import { observer } from "mobx-react";

import { DocumentNameInput } from "@components/DocumentNameInput";

import styles from "./DynamicLabelInput.module.scss";

interface IDynamicNameInputProps {
  isEditing: boolean;
  value: string;
  className?: string;
  onUpdate(value: string): void;
  onStopEditing(): void;
}

const DynamicLabelInput = (props: IDynamicNameInputProps) => {
  const { isEditing, value, className, onUpdate, onStopEditing } = props;

  if (isEditing) {
    return (
      <DocumentNameInput
        className={styles.dynamicLabelInput}
        inputClassName={styles.dynamicLabelInputGroup}
        defaultValue={value}
        onUpdate={onUpdate}
        inputGroupProps={{ autoFocus: true }}
        onEnter={onStopEditing}
        onEscape={onStopEditing}
        onBlur={onStopEditing}
      />
    );
  }

  return <span className={className}>{value}</span>;
};

export default observer(DynamicLabelInput);
