import { MouseEvent } from "react";
import { MaybeElement } from "@blueprintjs/core/src/common/props";
import { IconName } from "@blueprintjs/icons";
import { BlueprintIcon } from "@ui/BlueprintIcon";
import classNames from "classnames";
import kebabCase from "lodash/kebabCase";
import { observer } from "mobx-react";

import { Button } from "@components/Button";

import styles from "./FeatureRightSidebarButton.module.scss";

interface IFeatureRightSidebarButtonProps<T extends string> {
  sidePanelKey: T;
  label: string;
  active: boolean;
  pinned: boolean;
  icon: IconName | MaybeElement;
  disablePinning?: boolean;
  onClick(sidePanel: T): void;
  onPinPanel(e: MouseEvent): void;
}

const FeatureRightSidebarButton = <T extends string>(props: IFeatureRightSidebarButtonProps<T>) => {
  const { sidePanelKey, label, active, pinned, icon, disablePinning, onClick, onPinPanel } = props;

  const renderPinIcon = () => (
    <BlueprintIcon
      className={classNames(styles.pinIcon, { [styles.pinned]: pinned })}
      icon="pin"
      onClick={onPinPanel}
      e2eIdentifiers={`pin-${kebabCase(sidePanelKey)}`}
      active={active}
    />
  );

  return (
    <Button
      className={styles.featureRightSidebarButton}
      icon={icon}
      onClick={() => onClick(sidePanelKey)}
      active={active}
      rightIcon={disablePinning ? undefined : renderPinIcon()}
      e2eIdentifiers={`toggle-${kebabCase(sidePanelKey)}-panel`}
      minimal
    >
      {label}
    </Button>
  );
};

export default observer(FeatureRightSidebarButton);
