import { EditableText, Icon, Intent, Spinner, Tooltip } from "@blueprintjs/core";
import { observer } from "mobx-react";

import { Button } from "@components/Button";
import { useAppNavigate } from "@router/hooks";
import appStore from "@store/AppStore";
import { EBlockTab } from "@store/BlockStore";
import { EFileUploadStatus, IUploadStore } from "@store/UploadStore";
import { getFileUploadIcon, getFileUploadIconColor, getFileUploadMessage } from "@utilities";

import AttachmentIcon from "../../Attachments/AttachmentIcon";
import fileUploadHooks from "../hooks";

import styles from "./FileUploadListItem.module.scss";

export type TFileUploadListItemProps = {
  uploadStore: IUploadStore;
};

const FileUploadListItem = ({ uploadStore }: TFileUploadListItemProps) => {
  const { block, type } = uploadStore;
  const { navigateToBlock } = useAppNavigate();
  const { deleteFileUpload } = fileUploadHooks(uploadStore);

  const handleNavigateToBlock = () => {
    navigateToBlock(block?.id);
    appStore.env.setCurrentBlockTab(EBlockTab.ATTACHMENTS);
    setTimeout(() => {
      appStore.ui.setSelectedAttachmentId(uploadStore.attachmentId);
      const attachmentRow = document.getElementById(`attachmentRow-${uploadStore.attachmentId}`);
      if (attachmentRow) {
        attachmentRow.scrollIntoView({ behavior: "smooth" });
      }
    }, 50);
  };

  const renderIconRow = () => {
    const icon = getFileUploadIcon(uploadStore.status);
    if (icon) {
      return <Icon icon={icon} size={16} color={getFileUploadIconColor(uploadStore.status)} />;
    }

    return <Spinner className="file-upload-list--spinner" size={18} value={uploadStore.progressPercentage} intent={Intent.PRIMARY} />;
  };

  const renderActionCell = () => {
    if (uploadStore.status === EFileUploadStatus.UPLOADED && block) {
      return (
        <Tooltip content={block.label}>
          <Button onClick={handleNavigateToBlock} icon="arrow-right" minimal small e2eIdentifiers={["navigate-to", "block.label"]} />
        </Tooltip>
      );
    }

    if (uploadStore.isFailed) {
      return (
        <>
          <Tooltip className="file-upload-list--retry-button" content="Retry upload">
            <Button onClick={uploadStore.retryUploadChunks} icon="reset" minimal small e2eIdentifiers="retry-upload-chunks" />
          </Tooltip>
          <Button
            onClick={deleteFileUpload}
            disabled={!uploadStore.allowCancel}
            icon="cross"
            minimal
            small
            e2eIdentifiers="delete-file-upload"
          />
        </>
      );
    }

    return (
      <Tooltip content="Cancel upload">
        <Button onClick={deleteFileUpload} icon="cross" minimal small e2eIdentifiers="cancel-upload" />
      </Tooltip>
    );
  };

  return (
    <div className={styles.fileUploadListItem}>
      <div className="file-upload-list--data file-upload-list--data-overflow">
        <AttachmentIcon mimeType={type} />
        <EditableText
          key={`${uploadStore.id}${uploadStore.label}`}
          maxLength={255}
          className="file-upload-list--label-input"
          placeholder="Name"
          disabled={!uploadStore.isLabelEditable}
          defaultValue={uploadStore.label}
          confirmOnEnterKey
          onConfirm={uploadStore.setLabel}
        />
      </div>
      <div className="file-upload-list--data">
        <span className="file-upload-list--status">{`${uploadStore.progressString} ${getFileUploadMessage(uploadStore.status)}`}</span>
        {renderIconRow()}
        {renderActionCell()}
      </div>
    </div>
  );
};

export default observer(FileUploadListItem);
