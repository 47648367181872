import { useState } from "react";
import { Icon, Tag } from "@blueprintjs/core";
import { ItemModifiers } from "@blueprintjs/select";
import { observer } from "mobx-react";

import { Button } from "@components/Button";
import { MenuItem } from "@components/MenuItem";
import EditStatus from "@components/Modeling/StatusesTable/Cells/MenuItemOption/EditStatusOption";
import { Popover } from "@components/Popover";
import { IStatusOption } from "@store/StatusOptionStore";
import { getIntentClassFromColor } from "@utilities/Color";

import "./MenuItemOption.scss";

type Props = {
  statusOption: IStatusOption;
  handleClick: (event: React.MouseEvent<HTMLElement>) => void;
  modifiers: ItemModifiers;
  displaySelected?: boolean;
  isSelected?: boolean;
};

const MenuItemOption = (props: Props) => {
  const { statusOption, modifiers, handleClick, displaySelected, isSelected } = props;
  const [isEditStatusOpen, setIsEditStatusOpen] = useState(false);

  const renderStatusTitle = () => {
    return (
      <div className="status-option-nav-title">
        <Tag className={getIntentClassFromColor(statusOption.color)}>{statusOption.label}</Tag>
      </div>
    );
  };

  return (
    <div className="menu-item-option">
      <MenuItem
        key={statusOption.id}
        text={renderStatusTitle()}
        disabled={modifiers.disabled}
        selected={displaySelected ? isSelected : true}
        onClick={handleClick}
        submenuProps={{ className: "color-picker--menu" }}
        className="status-option-nav"
        e2eIdentifiers={[statusOption.label]}
      />
      <Popover
        className="menu-item-option--more"
        isOpen={isEditStatusOpen}
        onInteraction={setIsEditStatusOpen}
        content={<EditStatus statusOption={statusOption} onClose={() => setIsEditStatusOpen(false)} />}
        position="bottom-right"
      >
        <Button small minimal icon="more" e2eIdentifiers={["menu-item-option", "more"]} />
      </Popover>
      <Icon
        className="menu-item-option--tick-icon"
        onClick={handleClick}
        style={{ display: displaySelected ? "inherit" : "none", opacity: isSelected ? 1 : 0 }}
        icon="small-tick"
      />
    </div>
  );
};

export default observer(MenuItemOption);
