import { Divider, Menu } from "@blueprintjs/core";
import { observer } from "mobx-react";

import { MenuItem } from "@components/MenuItem";
import { MenuItemDelete } from "@components/MenuItems";

interface IRequirementDragButtonMenuProps {
  onAddNote(): void;
  onAddImage(): void;
  onDelete(): void;
}

const RequirementDragButtonMenu = (props: IRequirementDragButtonMenuProps) => {
  const { onAddNote, onAddImage, onDelete } = props;

  return (
    <Menu>
      <MenuItem
        text="Note"
        icon="new-text-box"
        description="Add a note to this requirement"
        e2eIdentifiers="add-note"
        onClick={onAddNote}
      />
      <MenuItem text="Image" icon="media" description="Upload or ember with a link" e2eIdentifiers="add-image" onClick={onAddImage} />
      <Divider />
      <MenuItemDelete onDelete={onDelete} />
    </Menu>
  );
};

export default observer(RequirementDragButtonMenu);
