import { Intent } from "@blueprintjs/core";

import { showToast } from "@components/UiLayers/toaster";
import { LinkChannelDto, SlackEventTypes, SlackIntegrationMetaData } from "@rollup-api/models";
import appStore from "@store/AppStore";
import { IIntegration } from "@store/IntegrationStore";
import { rollupClient } from "src/core/api";

const useSlackIntegration = (integration?: IIntegration) => {
  const metadata = integration?.connection?.metadata as SlackIntegrationMetaData | undefined;
  const provider = integration?.connection?.provider || "";
  const subscriptions = metadata?.channels || [];
  const connectionId = integration?.connection?.id || "";

  const linkChannel = (id: string, entityTypes: SlackEventTypes[]) => {
    const channel = { id, entityTypes };
    rollupClient.integrations
      .linkSlackChannel(connectionId, channel)
      .then(() => {
        if (provider) {
          appStore.orgModel.updateConnectionProviderMetadata(provider, { channels: [...subscriptions, channel] });
        }
        showToast("Channel linked successfully", Intent.SUCCESS);
      })
      .catch(err => {
        console.error(err);
        showToast("Channel link failed", Intent.DANGER);
      });
  };

  const updateChannelEvents = (channelId: string, entityTypes: SlackEventTypes[]) => {
    const updatedChannel = { id: channelId, entityTypes };
    rollupClient.integrations
      .updateSlackChannelLink(connectionId, { id: channelId, entityTypes })
      .then(() => {
        if (provider) {
          appStore.orgModel.updateConnectionProviderMetadata(provider, {
            channels: subscriptions.map(s => (s.id === channelId ? updatedChannel : s)),
          });
        }
        showToast("Channel updated successfully", Intent.SUCCESS);
      })
      .catch(err => {
        console.error(err);
        showToast("Channel update failed", Intent.DANGER);
      });
  };

  const unlinkChannel = (subscriptionDto: LinkChannelDto) => {
    rollupClient.integrations
      .unlinkSlackChannel(connectionId, subscriptionDto.id)
      .then(() => {
        if (provider) {
          appStore.orgModel.updateConnectionProviderMetadata(provider, {
            channels: subscriptions.filter(s => s.id !== subscriptionDto.id),
          });
        }
        showToast("Channel unlinked successfully", Intent.SUCCESS);
      })
      .catch(err => {
        console.error(err);
        showToast("Channel unlink failed", Intent.DANGER);
      });
  };

  return {
    linkChannel,
    unlinkChannel,
    updateChannelEvents,
    metadata,
    subscriptions,
  };
};

export default useSlackIntegration;
