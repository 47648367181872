import { useCallback, useEffect, useState } from "react";

const COLLAPSED_WIDTH = 48;

interface IUseMenuPaneParams {
  isOpen?: boolean;
  minExpandedWidth?: number;
  maxExpandedWidth?: number;

  toggleIsOpen?(): void;
}

function useMenuPane(params?: IUseMenuPaneParams) {
  const { isOpen = false, minExpandedWidth = 220, maxExpandedWidth = 440, toggleIsOpen } = params ?? {};
  const [open, setOpen] = useState(isOpen || false);
  const toggle = useCallback(() => {
    setOpen(prev => !prev);
    toggleIsOpen?.();
  }, [toggleIsOpen]);
  const [minSize, setMinSize] = useState(COLLAPSED_WIDTH);
  const [maxSize, setMaxSize] = useState(maxExpandedWidth);

  useEffect(() => {
    // To control open/close state from outside the component is needed.
    setOpen(isOpen);
  }, [isOpen]);

  useEffect(() => {
    setMinSize(open ? minExpandedWidth : COLLAPSED_WIDTH);
    setMaxSize(open ? maxExpandedWidth : COLLAPSED_WIDTH);
  }, [maxExpandedWidth, minExpandedWidth, open]);

  return { open, setOpen, toggle, minSize, maxSize };
}

export { useMenuPane };
