export enum UserRole {
  Default = "stytch_member",
  ReadOnly = "rollup_read-only",
  User = "rollup_user",
  Admin = "rollup_admin",
  Owner = "rollup_owner",
}

export enum AnalyticsRole {
  Admin = "admin",
  User = "user",
}

export enum UserPermission {
  DeleteWorkspaces = "delete:workspaces",
  InviteUsers = "invite:users",
  PromoteUsers = "promote:users",
  DeleteUsers = "delete:users",
  UpdateOrganization = "write:organization",
  RenameOrganization = "write:slug",
  DeleteOrganization = "delete:organization",
}

export enum TokenError {
  Unknown = "unknown error",
  MissingToken = "missing token",
  InvalidToken = "invalid token",
  IncorrectOrg = "incorrect org",
  None = "",
}

export function isAdmin(user?: { roles?: UserRole[] }) {
  return user?.roles?.includes(UserRole.Admin) || user?.roles?.includes(UserRole.Owner) || false;
}

export function getRoleName(user: { roles?: UserRole[] }) {
  if (user.roles?.includes(UserRole.Owner)) {
    return "Owner";
  }
  if (user.roles?.includes(UserRole.Admin)) {
    return "Admin";
  }
  return "User";
}
