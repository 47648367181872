import { useWorkspace } from "@hooks/useWorkspace";
import { CustomCellEditorProps } from "ag-grid-react";
import { observer } from "mobx-react";

import PartNumber from "@components/Table/CellEditors/PartNumber";
import appStore from "@store/AppStore";
import { BomTableNode } from "@store/BlockStore";

const PartNumberCellEditor = (props: CustomCellEditorProps<BomTableNode, string>) => {
  const { data, api, onValueChange } = props;
  const block = data?.block;
  const workspace = useWorkspace();
  const activeTable = workspace.bomTablesMap.get(appStore.env.activeBomTableId || "");

  if (!activeTable || !block) {
    return null;
  }

  const handleAddNewSchema = (label: string, schema: string, leadingZeroes?: number) => {
    workspace.partNumberSchemas.addPartNumberSchema(label, schema, leadingZeroes, workspace.id).then(newSchema => {
      if (newSchema) {
        block.setPartNumber(newSchema);
      }
    });
  };

  return (
    <PartNumber
      onUpdatePartNumber={block.updatePartNumber}
      onSetPartNumber={block.setPartNumber}
      partNumberSchemas={workspace.partNumberSchemas.values}
      onDeletePartNumberSchema={workspace.partNumberSchemas.deletePartNumberSchema}
      api={api}
      value={block?.partNumber}
      onAddNewSchema={handleAddNewSchema}
      onValueChange={onValueChange}
    />
  );
};

PartNumberCellEditor.displayName = "PartNumberCellEditor";

export default observer(PartNumberCellEditor);
