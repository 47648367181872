import { DataSink, DataSinkEntry } from "@rollup-api/models/data-sinks/data-sink.model";
import { IDataSinkEntrySnapshotIn } from "@store/DataConnection/DataSinkEntryStore";
import { IDataSinkSnapshotIn } from "@store/DataConnection/DataSinkStore";
import { mapRtoToSnapshot } from "src/services/services.utils";

export const mapDataSinkToSnapshot = (dataSink: DataSink): IDataSinkSnapshotIn => {
  const parsedDataSink = mapRtoToSnapshot<DataSink, IDataSinkSnapshotIn>(dataSink);
  return { ...parsedDataSink, entries: dataSink.entries?.map(e => e.id) };
};

export const mapDataSinkEntryToSnapshot = (dataSinkEntry: DataSinkEntry): IDataSinkEntrySnapshotIn => {
  const parsedDataSink = mapRtoToSnapshot<DataSinkEntry, IDataSinkEntrySnapshotIn>(dataSinkEntry);
  return { ...parsedDataSink, dataSink: dataSinkEntry.dataSinkId };
};
