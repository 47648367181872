import { ImportType } from "./import.model";

export enum ECsvImportColumn {
  Label = "label",
  PartNumber = "partNumber",
  Multiplicity = "multiplicity",
  Description = "description",
  ParentBlock = "parentBlock",
}

export type TCsvImportColumnsMap = {
  [ECsvImportColumn.Label]: string;
  [ECsvImportColumn.PartNumber]: string;
  [ECsvImportColumn.Multiplicity]: string;
  [ECsvImportColumn.Description]: string;
  [ECsvImportColumn.ParentBlock]: string;
};

export class CreateImportDto {
  public readonly type!: ImportType;
  public readonly attachmentId!: string;
  public readonly originalFileName!: string;
  public readonly columnMap?: string;
  public readonly id?: string;
  public readonly workspaceId?: string;
  public readonly entityId?: string;
  public readonly dryRun?: boolean;
}
