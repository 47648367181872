import { useEffect, useState } from "react";
import { Divider, InputGroup, Menu, Position } from "@blueprintjs/core";
import { observer } from "mobx-react";
import { useDebounceValue } from "usehooks-ts";

import { Button } from "@components/Button";
import { CustomIcon, ModuleMonochromeIcon } from "@components/CustomIcon";
import { MenuItem } from "@components/MenuItem";
import { Popover } from "@components/Popover";
import appStore from "@store/AppStore";
import { Text, TextVariant } from "src/ui/Text";

import "./WorkspaceSelector.scss";

export interface WorkspaceSelectorProps {
  showAllWorkspacesOption?: boolean;
  selectedWorkspaceId?: string;
  onChange?: (workspaceId: string) => void;
}

const WorkspaceSelector = ({ showAllWorkspacesOption, selectedWorkspaceId: selectedWorkspaceIdProp, onChange }: WorkspaceSelectorProps) => {
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [buttonLabel, setButtonLabel] = useState<string>("Select workspace");
  const [filteredWorkspaceList, setFilteredWorkspaceList] = useState<{ id: string; label: string }[]>([]);
  const [debouncedSearchTerm] = useDebounceValue(searchTerm, 400);

  const { workspacesList } = appStore.orgModel;

  // Set the button label
  useEffect(() => {
    if (selectedWorkspaceIdProp) {
      const selectedWorkspace = appStore.orgModel.workspacesList.find(workspace => workspace.id === selectedWorkspaceIdProp);
      if (selectedWorkspace) {
        setButtonLabel(selectedWorkspace.label);
      }
    } else {
      if (showAllWorkspacesOption) {
        setButtonLabel("All workspaces");
      } else {
        setButtonLabel("Select workspace");
      }
    }
  }, [workspacesList, selectedWorkspaceIdProp, showAllWorkspacesOption]);

  // Populate the workspace list
  useEffect(() => {
    const initialWorkspaceList = workspacesList.map(workspace => {
      return {
        id: workspace.id,
        label: workspace.label,
      };
    });
    setFilteredWorkspaceList(initialWorkspaceList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appStore.orgModel.workspacesList.length]);

  useEffect(() => {
    const newList = appStore.orgModel.workspacesList.filter(workspace =>
      workspace.label.toLowerCase().includes(debouncedSearchTerm.toLowerCase())
    );
    setFilteredWorkspaceList(newList);
  }, [debouncedSearchTerm]);

  const handleWorkspaceChange = ({ id, label }: { id: string; label: string }) => {
    setSearchTerm("");
    setButtonLabel(label);
    onChange?.(id);
  };

  const renderWorkspacesList = () => {
    return (
      <Menu className="workspace-selector--dropdown">
        <InputGroup
          className="workspace-selector--search-box"
          leftIcon="search"
          placeholder="Search"
          value={searchTerm}
          onChange={e => setSearchTerm(e.target.value)}
        />
        {showAllWorkspacesOption && (
          <>
            <MenuItem
              icon={<CustomIcon icon={ModuleMonochromeIcon.Workspace} />}
              text="All workspaces"
              onClick={() => handleWorkspaceChange({ label: "All workspaces", id: "" })}
              e2eIdentifiers="all-workspaces"
            />
            <Divider />
          </>
        )}
        {filteredWorkspaceList.length === 0 ? (
          <Text variant={TextVariant.Caption}>No workspaces found</Text>
        ) : (
          <div className="workspace-selector--list">
            {filteredWorkspaceList.map(workspace => {
              return (
                <MenuItem
                  key={workspace.id}
                  icon={<CustomIcon icon={ModuleMonochromeIcon.Workspace} />}
                  text={workspace.label}
                  onClick={() => handleWorkspaceChange({ label: workspace.label, id: workspace.id })}
                  e2eIdentifiers={["workspace", workspace.label]}
                />
              );
            })}
          </div>
        )}
      </Menu>
    );
  };

  return (
    <div className="workspace-selector">
      <Popover
        fill
        position={Position.BOTTOM_LEFT}
        onOpened={() => appStore.pullOrgWorkspaces()}
        content={renderWorkspacesList()}
        usePortal={false}
      >
        <Button
          outlined
          fill
          large
          alignText="left"
          icon={<CustomIcon icon={ModuleMonochromeIcon.Workspace} />}
          rightIcon="chevron-down"
          e2eIdentifiers={[buttonLabel]}
        >
          <Text variant={TextVariant.Button1} clamp={1}>
            {buttonLabel}
          </Text>
        </Button>
      </Popover>
    </div>
  );
};

export default observer(WorkspaceSelector);
