import { MouseEvent } from "react";
import classNames from "classnames";
import { observer } from "mobx-react";

import { Button } from "@components/Button";
import { IFavorite } from "@store/FavoriteStore";

import styles from "./RemoveFavoriteButton.module.scss";

interface IRemoveFavoriteButtonProps {
  favorite: IFavorite;
  className?: string;
  onRemoveFavorite(favorite: IFavorite): void;
}

const RemoveFavoriteButton = (props: IRemoveFavoriteButtonProps) => {
  const { favorite, className, onRemoveFavorite } = props;

  const handleRemoveFavorite = (e: MouseEvent) => {
    e.preventDefault();
    onRemoveFavorite(favorite);
  };

  return (
    <div className={classNames(className, styles.removeFavoriteButton)}>
      <Button
        className={styles.removeFavoriteButtonButton}
        onClick={handleRemoveFavorite}
        icon="cross"
        e2eIdentifiers={`remove-favorite-${favorite.label}`}
        tooltip="Remove from favorites"
        tooltipProps={{ hoverOpenDelay: 500 }}
        minimal
      />
    </div>
  );
};

export default observer(RemoveFavoriteButton);
