import assignIn from "lodash/assignIn";
import { IAnyModelType, Instance, types } from "mobx-state-tree";
import { Socket } from "socket.io-client";

import { StatusDefinitionStore } from "@store/StatusDefinitionStore";

import { rollupClient } from "../core/api";
import { CreateStatusOptionDto, StatusOptionUpdateDto } from "../lib/RollupClient/models/statusOption";

import appStore from "./AppStore";

export const StatusOptionStore = types
  .model("StatusOption", {
    id: types.identifier,
    statusDefinition: types.safeReference(types.late((): IAnyModelType => StatusDefinitionStore)),
    label: types.string,
    color: types.string,
  })
  .actions(self => ({
    patch(update: StatusOptionUpdateDto) {
      // Prevent updating of fixed properties
      const invalidFields = ["id", "statusDefinition"];
      const updateKeys = Object.keys(update);
      for (const field of invalidFields) {
        if (updateKeys.includes(field)) {
          return false;
        }
      }

      try {
        assignIn(self, update);
        return true;
      } catch (err) {
        console.warn(err);
        return false;
      }
    },
    setLabel(label: string) {
      self.label = label;
      rollupClient.statusOptions.update(self.id, { label });
    },
    setColor(color: string) {
      self.color = color;
      rollupClient.statusOptions.update(self.id, { color });
    },
  }));

export function subscribeToStatusOptionEvents(socket: Socket) {
  socket.on("createStatusOption", (data: { workspaceId: string; createStatusOptionDto: CreateStatusOptionDto }) => {
    if (data.createStatusOptionDto?.id && data.createStatusOptionDto.statusDefinition && data.workspaceId === appStore.workspaceModel?.id) {
      const statusDefinition = appStore.workspaceModel.statusDefinitionMap.get(data.createStatusOptionDto.statusDefinition);
      if (statusDefinition) {
        const { label, color, id } = data.createStatusOptionDto;
        appStore.workspaceModel.addNewStatusOption(statusDefinition, label, color, id, false);
      }
    }
  });

  socket.on("deleteStatusOption", (data: { workspaceId: string; id: string }) => {
    if (data.id && data.workspaceId === appStore.workspaceModel?.id) {
      const option = appStore.workspaceModel.statusOptionMap.get(data.id);
      if (option) {
        appStore.workspaceModel.deleteStatusOption(option, false);
      }
    }
  });

  socket.on("updateStatusOption", (data: { workspaceId: string; id: string; updateStatusOptionDto: StatusOptionUpdateDto }) => {
    if (data.id && data.workspaceId === appStore.workspaceModel?.id) {
      const option = appStore.workspaceModel.statusOptionMap.get(data.id);
      option?.patch(data.updateStatusOptionDto);
    }
  });
}

export interface IStatusOption extends Instance<typeof StatusOptionStore> {}
