import { AxiosResponse } from "axios";
import assignIn from "lodash/assignIn";
import { cast, flow, IAnyModelType, Instance, IType, SnapshotIn, SnapshotOut, types } from "mobx-state-tree";
import { Socket } from "socket.io-client";
import { v4 as uuidv4 } from "uuid";

import { createCreateHoopsViewDto } from "@components/Modeling/ModelingFrame/HoopsViewer/utils";
import { showApiErrorToast, showToast } from "@components/UiLayers/toaster";
import { AttachmentVersionInfo } from "@rollup-api/models";
import { AttachmentStatus, AttachmentType, UpdateAttachmentDto } from "@rollup-api/models/cloudStorage";
import { CreateAttachmentWebSocketDto } from "@rollup-api/models/cloudStorage/attachmentCreateWebSocketDto.model";
import { CreateAttachmentVersionWebSocketDto } from "@rollup-api/models/cloudStorage/attachmentVersionAddedDto.model";
import { updateAttachment } from "@rollup-api/utils";
import { entityTypeToPageMap } from "@router/hooks/useAppNavigate";
import { AnnotationListStore } from "@store/AnnotationListStore";
import { CommentThreadListStore } from "@store/CommentThreadListStore";
import { EntityType, StoreType } from "@store/types";
import { convertTimestamp, formatFileSize, getFileList } from "@utilities";
import { processGerberMetadata } from "@utilities/BoardHelpers";
import { rollupClient } from "src/core/api";

import appStore from "./AppStore";
import { BlockStore } from "./BlockStore";
import { IUser } from "./UserStore";
import { IView, ViewStore } from "./ViewStore";

export const AttachmentStore = types
  .model(StoreType.Attachment, {
    id: types.identifier,
    name: types.string,
    label: types.optional(types.string, ""),
    fileSize: types.number,
    fileType: types.optional(types.string, ""),
    createdBy: types.string,
    status: types.optional(types.enumeration("AttachmentStatus", [...Object.values(AttachmentStatus)]), AttachmentStatus.UploadPending),
    hasThumbnail: types.optional(types.boolean, false),
    uploadingNewVersion: types.optional(types.boolean, false),
    fetchingVersions: types.optional(types.boolean, false),
    previewId: types.maybeNull(types.string),
    metadata: types.maybeNull(types.frozen<any>()),
    version: types.optional(types.number, 0),
    versions: types.array(types.frozen<AttachmentVersionInfo>()),
    block: types.safeReference(types.late((): IAnyModelType => BlockStore)),
    createdAt: types.optional(types.number, Date.now()),
    updatedAt: types.optional(types.number, Date.now()),
    lastFocusedCommentId: types.maybeNull(types.string),
    views: types.array(types.late((): IAnyModelType => ViewStore)),
    type: types.optional(types.enumeration("AttachmentType", [...Object.values(AttachmentType)]), AttachmentType.file),
    linkAttachmentUrl: types.maybeNull(types.string),
    // Internal attachment reference (e.g. a report, a req page)
    // This field stores "entityType" and "uuid" separated by a colon (:), e.g.: "${entityType}:${uuid}"
    reference: types.maybeNull(types.string),
    workspaceId: types.maybeNull(types.string),
    // Comment thread information for the attachment
    commentThreadList: types.optional(CommentThreadListStore, {}),
    annotationList: types.optional(AnnotationListStore, {}),
  })
  .actions(self => ({
    addExistingVersion(versionInfo: AttachmentVersionInfo) {
      self.versions = cast([...self.versions, versionInfo]);
    },
    fetchVersions: flow(function* fetchVersions() {
      if (self.fetchingVersions) {
        return;
      }
      self.fetchingVersions = true;
      try {
        const res: AxiosResponse<AttachmentVersionInfo[]> = yield rollupClient.attachments.retrieveVersionList(self.id, self.workspaceId);
        self.versions = cast(res.data);
      } catch (err: any) {
        showApiErrorToast("Failed to fetch attachment versions", err);
      }
      self.fetchingVersions = false;
    }),
  }))
  .actions(self => ({
    handleVersionUploadComplete(file: File) {
      const lastModified = new Date().toISOString();
      const createdBy = appStore.userModel?.id || "";
      self.updatedAt = convertTimestamp(lastModified);
      self.fileSize = file.size;
      self.fileType = file.type || "";
      self.createdBy = createdBy;
      self.version = self.version + 1;
      self.addExistingVersion({ id: uuidv4(), size: file.size, lastModified, createdBy, isLatest: true });
      self.uploadingNewVersion = false;
    },
  }))
  .actions(self => {
    return {
      patch(update: UpdateAttachmentDto) {
        // Prevent updating of fixed properties
        const invalidFields = ["id"];
        const updateKeys = Object.keys(update);
        for (const field of invalidFields) {
          if (updateKeys.includes(field)) {
            return false;
          }
        }

        try {
          assignIn(self, update);
          return true;
        } catch (err) {
          console.warn(err);
          return false;
        }
      },
      setLabel(label: string) {
        self.label = label;
        updateAttachment(self.id, { label });
      },
      addNewVersion(file: File) {
        try {
          self.uploadingNewVersion = true;
          const fileList = getFileList([file]);
          appStore.orgModel.uploads.addNewFileUpload({
            blockId: self.block?.id,
            files: fileList,
            onUpload: () => self.handleVersionUploadComplete(file),
            attachmentId: self.id,
            // as this is a version upload only, we don't want to add it as a new attachment to the block
            disableAddToBlock: true,
            workspaceId: self.workspaceId ?? undefined,
          });
          return true;
        } catch (err: any) {
          self.uploadingNewVersion = false;
          showApiErrorToast("Error uploading new version", err);
        }
        self.uploadingNewVersion = false;
        return false;
      },
    };
  })
  .actions(self => ({
    saveNewView: flow(function* saveNewView(viewName: string, notify = true): Generator<any, IView | null, any> {
      try {
        // Extract the current view
        const viewer = appStore.env.attachmentViewer?.viewerInstance;
        if (!viewer) {
          throw new Error("Viewer not found");
        }
        const newViewDto = createCreateHoopsViewDto(viewName, viewer, self.id, self.workspaceId);
        if (!newViewDto) {
          throw new Error("Error creating view");
        }
        // Call the API to create a new view
        const { data: view, status } = yield rollupClient.views.create(newViewDto); // returns the full view data from the BE

        if (notify) {
          if (status !== 201) {
            console.warn(`Error creating view for attachment ${self.id}`);
            return null;
          }
        }

        // create a new view in the store
        const newView = ViewStore.create({
          ...view,
          cloudFileId: self.id,
          recreated: false,
          isolatedNodeId: view.isolatedNodeId || undefined,
        });

        self.views.push(newView);

        if (notify) {
          showToast("New view created", "success", "info-sign");
        }
        return newView;
      } catch (error) {
        console.warn(`Error creating view for attachment ${self.id}`, error);
        return null;
      }
    }),
    cleanUpViews() {
      self.views = cast([]);
    },

    setLastFocusedCommentId(id: string) {
      self.lastFocusedCommentId = id;
    },
  }))

  .actions(self => ({
    populateHoopsViews: flow(function* fetchViews(): Generator<any, boolean, any> {
      try {
        const { data, status } = yield rollupClient.views.retrieveByAttachmentId(self.id);
        if (status !== 200) {
          console.warn(`Error fetching views for attachment ${self.id}`);
          return false;
        }

        // empty the views array
        self.cleanUpViews();

        for (const v of data.views) {
          // create new annotation with the data from the API
          const view = ViewStore.create({
            ...v,
            cloudFileId: self.id,
            recreated: true,
            // TODO: remove the uniqueId assignment below once BE changes uniqueId type to string
            uniqueId: v.id,
            isolatedNodeId: v.isolatedNodeId || undefined,
          });
          self.views.push(view);
        }

        return true;
      } catch (error) {
        console.warn(`Error fetching views for attachment ${self.id}`, error);
        return false;
      }
    }),
    deleteView: flow(function* deleteView(id: string): Generator<any, boolean, any> {
      try {
        const { status } = yield rollupClient.views.delete(id);
        if (status !== 200) {
          console.warn(`Error deleting view`);
          return false;
        }

        // remove the view from the store
        const index = self.views.findIndex(view => view.id === id);
        if (index > -1) {
          self.views.splice(index, 1);
        }
        // self.views.filter(view => view.id !== id);
        showToast("View deleted", "success", "info-sign");
        return true;
      } catch (error) {
        console.warn(`Error deleting view`, error);
        return false;
      }
    }),
    updateViewName: flow(function* updateViewName(id: string, newName: string): Generator<any, boolean, any> {
      try {
        const { status } = yield rollupClient.views.update(id, { name: newName });
        if (status !== 200) {
          console.warn(`Error updating view name`);
          return false;
        }
        // update view in store
        const view = self.views.find(view => view.id === id);
        if (view) {
          view.setName(newName);
        }
        showToast("View name updated", "success", "info-sign");
        return true;
      } catch (error) {
        console.warn(`Error updating view name`, error);
        return false;
      }
    }),
    fillMetadata(metadata: any) {
      if (self.fileType === "board/zip") {
        metadata = processGerberMetadata(metadata);
      }
      self.metadata = cast(metadata);
    },
    clearMetadata() {
      self.metadata = null;
    },
  }))
  .views(self => ({
    get previewUrl(): string {
      return self?.previewId ? rollupClient.attachments.getFileLink(self.id, self.workspaceId, true, self.version) : "";
    },
    get user(): IUser | undefined {
      return appStore.orgModel?.info?.orgMembers.find((user: IUser) => user.id === self.createdBy);
    },
    get formattedFileSize() {
      return formatFileSize(self.fileSize);
    },
    get sortedVersions() {
      return self.versions.slice().sort((a, b) => convertTimestamp(b.lastModified) - convertTimestamp(a.lastModified));
    },
    get formattedCreatedAt() {
      return new Date(self.createdAt).toLocaleDateString("en-us", {
        year: "numeric",
        month: "long",
        day: "numeric",
      });
    },
    get referenceEntityType(): EntityType | undefined {
      const [referenceType] = self.reference?.split(":") ?? [];
      if (referenceType) {
        return referenceType as EntityType;
      }
    },
    get referenceId(): string | undefined {
      const [, referenceId] = self.reference?.split(":") ?? [];
      return referenceId;
    },
  }))
  .views(self => ({
    get title(): string {
      const title = self.label || self.name;

      if (title) {
        return title;
      } else if (self.referenceEntityType === EntityType.Report) {
        return "Untitled report";
      } else if (self.referenceEntityType === EntityType.RequirementsDocument) {
        return "Untitled requirements document";
      }

      return "Untitled attachment";
    },
  }))
  .actions(self => ({
    deleteAllViews() {
      for (const view of self.views) {
        self.deleteView(view.id);
      }
    },
    getReferenceUrl(workspaceId: string): string {
      const pageUrl = self.referenceEntityType && entityTypeToPageMap[self.referenceEntityType];
      if (!pageUrl || !self.referenceId) {
        return "";
      }
      return `/workspaces/${workspaceId}/${pageUrl}/${self.referenceId}`;
    },
  }));

export function subscribeToAttachmentsEvents(socket: Socket) {
  socket.on("completeUpload", (data: CreateAttachmentWebSocketDto) => {
    if (data.id) {
      if (!data.workspaceId && data.orgId === appStore.orgModel?.info?.id) {
        appStore.orgModel.attachments.addExistingAttachment(data);
      } else if (data.workspaceId === appStore.workspaceModel?.id) {
        const block = data.blockId && appStore.workspaceModel.blockMap.get(data.blockId);
        appStore.workspaceModel.attachments.addExistingAttachment(data, block);
      }
    }
  });

  socket.on("newVersionAdded", (data: CreateAttachmentVersionWebSocketDto) => {
    if (data.attachmentId) {
      if (!data.workspaceId && data.orgId === appStore.orgModel?.info?.id) {
        const attachment = appStore.orgModel?.attachments.get(data.attachmentId);
        attachment?.addExistingVersion(data.createVersionDto);
      } else if (data.workspaceId === appStore.workspaceModel?.id) {
        const attachment = appStore.workspaceModel?.attachments.get(data.attachmentId);
        attachment?.addExistingVersion(data.createVersionDto);
      }
    }
  });

  socket.on(
    "updateFile",
    (data: { workspaceId?: string; orgId?: string; id: string; updateCloudStorageDto: UpdateAttachmentDto; userId: string }) => {
      if (data.id && data.orgId === appStore.orgModel.info.id) {
        const shouldRefreshCatalogItemImage = appStore.orgModel.catalogItems.catalogItems.some(i => i.imageUrl?.includes(data.id));
        shouldRefreshCatalogItemImage && appStore.env.catalogItemTableGridApi?.redrawRows();

        const attachment = appStore.orgModel.attachments.get(data.id);

        if (attachment) {
          attachment.patch(data.updateCloudStorageDto);
        }
      }

      if (data.id && data.workspaceId === appStore.workspaceModel?.id) {
        const attachment = appStore.workspaceModel?.attachments.attachmentMap.get(data.id);

        if (attachment) {
          attachment.patch(data.updateCloudStorageDto);
        }
      }
    }
  );

  socket.on("deleteFile", (data: { workspaceId?: string; orgId?: string; id: string; userId: string }) => {
    if (data.id) {
      if (!data.workspaceId && data.orgId === appStore.orgModel?.info?.id) {
        const attachment = appStore.orgModel.attachments.get(data.id);
        attachment && appStore.orgModel.attachments.delete(attachment, false);
      } else if (data.workspaceId === appStore.workspaceModel?.id) {
        const attachment = appStore.workspaceModel?.attachments.get(data.id);
        attachment && appStore.workspaceModel?.attachments.delete(attachment, false);
      }
    }
  });
}

export interface IAttachment extends Instance<typeof AttachmentStore> {}
interface IAttachmentSnapshotIn extends SnapshotIn<typeof AttachmentStore> {}
interface IAttachmentSnapshotOut extends SnapshotOut<typeof AttachmentStore> {}
export interface IAttachmentMobxType extends IType<IAttachmentSnapshotIn, IAttachmentSnapshotOut, IAttachment> {}
