import { IconNames } from "@blueprintjs/icons";

import { ModuleColorfulIcon, ModuleMonochromeIcon } from "@components/CustomIcon";
import { IconSource, IIcon } from "@rollup-types/icons";
import { EntityType } from "@store/types";

export const getDocumentIcon = (entityType?: EntityType): IIcon | undefined => {
  switch (entityType) {
    case EntityType.Report:
      return { source: IconSource.Custom, name: ModuleColorfulIcon.Knowledgebase };
    case EntityType.RequirementsDocument:
      return { source: IconSource.Custom, name: ModuleMonochromeIcon.RequirementsBw };
    case EntityType.BomTable:
      return { source: IconSource.Custom, name: ModuleMonochromeIcon.Bom };
  }
};

export const blueprintIconNameList = [...new Set(Object.values(IconNames))];
