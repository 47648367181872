import { ComponentProps, useMemo } from "react";
// eslint-disable-next-line no-restricted-imports
import { EditableName as BPEditableName } from "@blueprintjs/table";
import { observer } from "mobx-react";

import { useSetAttribute } from "../../hooks/useSetAttribute/useSetAttribute";
import { createTestId, dataTestIdTag, ElementType, IE2EIdentifiersParam } from "../../Utilities/E2EUtils";

type IBPEditableNameProps = ComponentProps<typeof BPEditableName>;
type IEditableNameProps = IBPEditableNameProps & IE2EIdentifiersParam;

const EditableName_ = ({ e2eIdentifiers, ...restProps }: IEditableNameProps) => {
  const testId = useMemo(() => createTestId(ElementType.Input, e2eIdentifiers), [e2eIdentifiers]);
  // Note: BPEditableName does not pass attributes down to its HTML element, so
  // we're unable to pass the data tag to it. For that reason we need to
  // set the data tag imperatively using the hook below
  const ref = useSetAttribute<HTMLDivElement>(dataTestIdTag, testId);

  return <BPEditableName ref={ref} {...restProps} />;
};

export const EditableName = observer(EditableName_);
