import { bottomPaneMinHeight, rightPaneMinWidth } from "@components/CatalogItems/constants";

export const calcMaxSize = (container: HTMLDivElement, isVerticalLayout: boolean) => {
  return isVerticalLayout ? container.offsetWidth - rightPaneMinWidth : container.offsetHeight - bottomPaneMinHeight;
};

export const calcDefaultSize = (windowSize: number, size?: number, previewOpen?: boolean) => {
  if (!previewOpen) {
    return windowSize;
  }

  if (!size || size > windowSize) {
    return windowSize * 0.66;
  }

  return size;
};
