import { IconName, Intent } from "@blueprintjs/core";

import { humanReadableDateTime } from "@utilities";

import {
  GitHubItemType,
  GitHubListItem,
  GoogleDriveMimeType,
  GoogleFileListItem,
  IntegrationItem,
  IssueListItem,
  PullRequestListItem,
  RepoListItem,
  TagListItem,
} from "../models/integrations";

function getIntegrationForRepo(item: RepoListItem): IntegrationItem<GitHubListItem> {
  return {
    data: item,
    title: item.title,
    icon: item.isArchived ? "archive" : "git-repo",
    iconIntent: Intent.NONE,
    group: "Repositories",
    label: item.primaryLanguage?.name,
  };
}

function getIntegrationForIssue(item: IssueListItem): IntegrationItem<GitHubListItem> {
  return {
    data: item,
    title: item.title,
    icon: "issue",
    iconIntent: item.closed ? Intent.NONE : Intent.SUCCESS,
    group: "Issues",
    label: `#${item.number}`,
  };
}

function getIntegrationForTag(item: TagListItem): IntegrationItem<TagListItem> {
  return {
    data: item,
    title: item.title,
    icon: "tag",
    iconIntent: Intent.NONE,
    group: "Tags",
    label: item.title,
  };
}

function getIntegrationForPullRequest(item: PullRequestListItem): IntegrationItem<GitHubListItem> {
  let icon: IconName;
  let iconIntent: Intent;

  if (item.closed) {
    icon = "git-merge";
    iconIntent = item.merged ? Intent.PRIMARY : Intent.DANGER;
  } else {
    icon = "git-pull";
    iconIntent = item.isDraft ? Intent.NONE : Intent.SUCCESS;
  }

  return {
    data: item,
    title: item.title,
    icon,
    iconIntent,
    group: "Pull Requests",
    label: `#${item.number}`,
  };
}

export function getIntegrationItem(item: GitHubListItem): IntegrationItem<GitHubListItem> {
  switch (item.type) {
    case GitHubItemType.REPO:
      return getIntegrationForRepo(item);
    case GitHubItemType.ISSUE:
      return getIntegrationForIssue(item);
    case GitHubItemType.PULL_REQUEST:
      return getIntegrationForPullRequest(item);
    case GitHubItemType.TAG:
      return getIntegrationForTag(item);
  }
}

function getGroupFromMimeType(mimeType: string): string {
  switch (mimeType) {
    case GoogleDriveMimeType.DOCUMENT:
      return "Documents";
    case GoogleDriveMimeType.SPREADSHEET:
      return "Spreadsheets";
    case GoogleDriveMimeType.FOLDER:
      return "Folders";
    case GoogleDriveMimeType.FORM:
      return "Forms";
    case GoogleDriveMimeType.DRAWING:
      return "Drawings";
    case GoogleDriveMimeType.PRESENTATION:
      return "Slides";
    default:
      return "Other";
  }
}

function getIconFromMimeType(mimeType: string): IconName {
  switch (mimeType) {
    case GoogleDriveMimeType.DOCUMENT:
      return "document";
    case GoogleDriveMimeType.SPREADSHEET:
      return "th";
    case GoogleDriveMimeType.FOLDER:
      return "folder-close";
    case GoogleDriveMimeType.FORM:
      return "form";
    case GoogleDriveMimeType.DRAWING:
      return "draw";
    case GoogleDriveMimeType.PRESENTATION:
      return "presentation";
    default:
      return "blank";
  }
}

export function getGoogleIntegrationItem(item: GoogleFileListItem): IntegrationItem<GoogleFileListItem> {
  return {
    data: item,
    title: item.name,
    label: `modified ${humanReadableDateTime(item.modifiedTime)}`,
    icon: getIconFromMimeType(item.mimeType),
    iconIntent: Intent.NONE,
    group: getGroupFromMimeType(item.mimeType),
  };
}
