import { PropertyDataType } from "@rollup-io/engineering";
import assignIn from "lodash/assignIn";
import isNull from "lodash/isNull";
import omit from "lodash/omit";
import omitBy from "lodash/omitBy";
import { Instance, IType, SnapshotIn, SnapshotOut, types } from "mobx-state-tree";
import { Socket } from "socket.io-client";

import { CreatePropertyDefinitionDto, PropertyDefinitionUpdateDto } from "@rollup-api/models/propertyDefinition";
import { updatePropertyDefinition } from "@rollup-api/utils";
import appStore from "@store/AppStore";
import { IPropertyInstance } from "@store/PropertyInstanceStore";
import { getUnitType, isAllowedFormatTypeByUnitType, parentWorkspace } from "@utilities";
import { FormatType } from "@utilities/Formats";

export enum UncertaintyType {
  Relative = "relative",
  Absolute = "absolute",
}

export enum UncertaintySymmetry {
  Symmetrical = "symmetrical",
  Asymmetrical = "asymmetrical",
}

export const PropertyDefinitionStore = types
  .model("PropertyDefinition", {
    id: types.identifier,
    label: types.optional(types.string, ""),
    description: types.optional(types.string, ""),
    descriptionLastUpdatedBy: types.maybe(types.string), // user id
    unit: types.optional(types.string, ""),
    dataType: types.optional(types.enumeration("DataType", [...Object.values(PropertyDataType)]), PropertyDataType.scalar),
    formatType: types.optional(types.enumeration("FormatType", [...Object.values(FormatType)]), FormatType.NUMBER_WITH_COMMAS),
    defaultPropertyGroup: types.maybe(types.string),
    autoRollupChildren: types.optional(types.boolean, true),
    autoAdd: types.optional(types.boolean, false),
    orderIndex: types.optional(types.number, 0),
    uncertaintyType: types.optional(types.enumeration("UncertaintyType", [...Object.values(UncertaintyType)]), UncertaintyType.Relative),
    uncertaintySymmetry: types.optional(
      types.enumeration("UncertaintySymmetry", [...Object.values(UncertaintySymmetry)]),
      UncertaintySymmetry.Asymmetrical
    ),
  })
  .actions(self => ({
    patch(update: PropertyDefinitionUpdateDto) {
      // Prevent updating of fixed properties
      const invalidFields = ["id", "ui", "orderIndex"];
      const { propertyInstances, ...definitionDto } = update;
      const updateDto = omitBy(omit(definitionDto, invalidFields), isNull);

      try {
        assignIn(self, updateDto);
        for (const instance of propertyInstances ?? []) {
          const propertyInstance = appStore.workspaceModel?.propertyInstanceMap.get(instance.id);
          if (propertyInstance) {
            const allowedChanges = omitBy(omit(instance, ["id", "propertyDefinition", "parentBlock", "createdAt"]), isNull);
            propertyInstance.patch(allowedChanges);
          }
        }

        return true;
      } catch (err) {
        console.warn(err);
        return false;
      }
    },
    sendUpdate(updateDto: PropertyDefinitionUpdateDto) {
      return updatePropertyDefinition(self.id, updateDto);
    },
    setDescription(description: string, descriptionLastUpdatedBy: string, disableNotification?: boolean) {
      self.description = description;
      self.descriptionLastUpdatedBy = descriptionLastUpdatedBy;
      if (!disableNotification) {
        this.sendUpdate({ description });
      }
    },
    setLabel(label: string) {
      if (label == "") {
        label = "Untitled Property";
      }
      // Prevent duplicate definitions
      const lowerCaseLabel = label?.toLowerCase();
      if (parentWorkspace(self)?.propertyDefinitions?.find(p => p.label?.toLowerCase() === lowerCaseLabel)) {
        return;
      }
      self.label = label;
      this.sendUpdate({ label });
    },
    resetFormatType() {
      self.formatType = FormatType.NUMBER_WITH_COMMAS;
    },
    setUnit(unit: string) {
      self.unit = unit;

      // CASE: When unit changes, we need to update the format to a fallback value (NUMBER) if necessary.
      const unitType = getUnitType(unit);
      const isAllowed = isAllowedFormatTypeByUnitType(unitType, self.formatType);
      if (!isAllowed) {
        this.resetFormatType();
      }

      this.sendUpdate({ unit }).then(res => {
        if (res?.data?.propertyInstances) {
          this.patch({ propertyInstances: res.data.propertyInstances });
        }
      });
    },
    setDefaultPropertyGroup(defaultPropertyGroup: string | undefined) {
      self.defaultPropertyGroup = defaultPropertyGroup;
      this.sendUpdate({ defaultPropertyGroup });
    },
    setDataType(dataType: PropertyDataType, disableNotification?: boolean) {
      self.dataType = dataType;
      if (!disableNotification) {
        // TODO: We will need to propagate things properly to instances
        this.sendUpdate({ dataType });
      }
    },
    setFormatType(formatType: FormatType): boolean {
      const unitType = getUnitType(self.unit);
      const isAllowed = isAllowedFormatTypeByUnitType(unitType, formatType);
      if (!isAllowed) {
        return false;
      }

      self.formatType = formatType;
      this.sendUpdate({ formatType });
      return true;
    },
    toggleAutoRollupChildren(disableNotification?: boolean) {
      self.autoRollupChildren = !self.autoRollupChildren;
      if (!disableNotification) {
        this.sendUpdate({ autoRollupChildren: self.autoRollupChildren });
      }
    },
    toggleAutoAdd(disableNotification?: boolean) {
      self.autoAdd = !self.autoAdd;
      if (!disableNotification) {
        this.sendUpdate({ autoAdd: self.autoAdd });
      }
    },
    setUncertaintyType(value: UncertaintyType, disableNotification?: boolean) {
      self.uncertaintyType = value;
      if (!disableNotification) {
        updatePropertyDefinition(self.id, { uncertaintyType: self.uncertaintyType });
      }
    },
    setUncertaintySymmetry(value: UncertaintySymmetry, disableNotification?: boolean) {
      self.uncertaintySymmetry = value;
      if (!disableNotification) {
        updatePropertyDefinition(self.id, { uncertaintySymmetry: self.uncertaintySymmetry });
      }
    },
  }))
  .views(self => ({
    get labelIsValid() {
      // TODO: validate properly
      return !!self.label;
    },
    get instances(): IPropertyInstance[] {
      return parentWorkspace(self)?.propertyInstances?.filter(i => i?.propertyDefinition === self) ?? [];
    },
  }))
  .views(self => ({
    get instancesLength(): number {
      return self.instances.length;
    },
  }));

export function subscribeToPropertyDefinitionEvents(socket: Socket) {
  socket.on("createPropertyDefinition", (data: { workspaceId: string; createPropertyDefinitionDto: CreatePropertyDefinitionDto }) => {
    if (data.createPropertyDefinitionDto?.id && data.workspaceId === appStore.workspaceModel?.id) {
      const { defaultPropertyGroup, label, unit, dataType, id } = data.createPropertyDefinitionDto;
      appStore.workspaceModel.addNewPropertyDefinition(label, defaultPropertyGroup, unit, dataType as PropertyDataType, id, false);
    }
  });

  socket.on("deletePropertyDefinition", (data: { workspaceId: string; id: string }) => {
    if (data.id && data.workspaceId === appStore.workspaceModel?.id) {
      const propertyDefinition = appStore.workspaceModel.propertyDefinitionMap.get(data.id);
      if (propertyDefinition) {
        appStore.workspaceModel.deletePropertyDefinition(propertyDefinition, false);
      }
    }
  });

  socket.on(
    "updatePropertyDefinition",
    (data: { workspaceId: string; id: string; updatePropertyDefinitionDto: PropertyDefinitionUpdateDto }) => {
      if (data.id && data.workspaceId === appStore.workspaceModel?.id) {
        const propertyDefinition = appStore.workspaceModel.propertyDefinitionMap.get(data.id);
        propertyDefinition?.patch(data.updatePropertyDefinitionDto);
      }
    }
  );

  socket.on(
    "reorderPropertyDefinition",
    (data: {
      workspaceId: string;
      id: string;
      reorderPropertyDefinitionDto: {
        destinationId: string;
      };
    }) => {
      if (data.id && data.reorderPropertyDefinitionDto?.destinationId && data.workspaceId === appStore.workspaceModel?.id) {
        const definition = appStore.workspaceModel.propertyDefinitionMap.get(data.id);
        if (definition) {
          appStore.workspaceModel.movePropertyDefinition(data.id, data.reorderPropertyDefinitionDto.destinationId, false);
        }
      }
    }
  );
}

export interface IPropertyDefinition extends Instance<typeof PropertyDefinitionStore> {}
interface IPropertyDefinitionSnapshotIn extends SnapshotIn<typeof PropertyDefinitionStore> {}
interface IPropertyDefinitionSnapshotOut extends SnapshotOut<typeof PropertyDefinitionStore> {}
export interface IPropertyDefinitionMobxType
  extends IType<IPropertyDefinitionSnapshotIn, IPropertyDefinitionSnapshotOut, IPropertyDefinition> {}
