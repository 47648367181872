import { ColDef } from "ag-grid-community";

import { Invoice } from "@rollup-api/models/invoices/invoice.model";
import { formatDate, formatString, FormatType } from "@utilities";

import { InvoiceTableDownloadCell } from "./Cells/InvoiceTableDownloadCell";
import { InvoiceTableStatusCell } from "./Cells/InvoiceTableStatusCell";

import styles from "./Invoices.module.scss";

enum InvoicesTableColumn {
  Date = "Date",
  Amount = "Amount",
  Status = "Status",
  PDF = "PDF",
}

export const invoicesTableColDefs: ColDef<Invoice>[] = [
  {
    colId: InvoicesTableColumn.Date,
    headerName: InvoicesTableColumn.Date,
    width: 120,
    cellClass: styles.invoicesCellValue,
    valueFormatter: params => formatDate(params.data?.createdAt, true),
  },
  {
    colId: InvoicesTableColumn.Amount,
    headerName: InvoicesTableColumn.Amount,
    width: 110,
    cellClass: styles.invoicesCellValue,
    valueFormatter: params => {
      return formatString(params.data?.total, params.data?.currency, FormatType.CURRENCY);
    },
  },
  {
    colId: InvoicesTableColumn.Status,
    headerName: InvoicesTableColumn.Status,
    width: 80,
    cellClass: styles.invoicesCellValue,
    cellRenderer: InvoiceTableStatusCell,
  },
  {
    colId: InvoicesTableColumn.PDF,
    width: 40,
    cellClass: styles.invoicesCellValue,
    cellRenderer: InvoiceTableDownloadCell,
    suppressSizeToFit: true,
  },
];

export const invoicesTableDefaultColDef: ColDef = {
  suppressHeaderMenuButton: true,
  suppressMovable: true,
  editable: false,
};
