import { UseQueryOptions } from "@tanstack/react-query";

import { GoogleDriveMimeType, GoogleIntegrationItem } from "@rollup-api/models";
import { getGoogleIntegrationItem } from "@rollup-api/utils/integrations";

import { QueryKeys, rollupClient } from "../core/api";

interface ICreateGetSheetsQOParams {
  workspaceId: string;
  query?: string;
  pageSize?: number;
  enabled?: boolean;
}

export abstract class GoogleQOFactory {
  public static createGetSheetsQO(params: ICreateGetSheetsQOParams): UseQueryOptions<GoogleIntegrationItem[]> {
    const { workspaceId, query = "", pageSize } = params;
    return {
      queryKey: [QueryKeys.GoogleSheetList, workspaceId, query, pageSize],
      queryFn: () =>
        rollupClient.integrations
          .filterDriveItems(query, [GoogleDriveMimeType.SPREADSHEET], pageSize)
          .then(res => res.data?.map(getGoogleIntegrationItem)),
      enabled: params.enabled,
    };
  }
}
