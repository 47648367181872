// eslint-disable-next-line no-restricted-imports
import { Dialog as BpDialog, DialogProps as BpDialogProps } from "@blueprintjs/core";
import Text, { TextColor, TextVariant } from "@ui/Text";
import classNames from "classnames";
import { observer } from "mobx-react";

import "./DialogBase.scss";

export interface DialogBaseProps extends BpDialogProps {
  titleButtons?: React.ReactNode[];
}

export const DialogBase = ({
  children,
  className,
  title,
  titleButtons,
  canEscapeKeyClose = true,
  canOutsideClickClose = true,
  ...rest
}: DialogBaseProps) => {
  const titleWithButtons = () => {
    const titleText = (
      <Text variant={TextVariant.H3} color={TextColor.Primary}>
        {title}
      </Text>
    );
    if (!titleButtons) {
      return titleText;
    } else {
      return (
        <div className="title-container">
          {titleText}
          {titleButtons.map(button => button)}
        </div>
      );
    }
  };

  const dialogClass = classNames("dialog-base", className);

  return (
    <BpDialog
      className={dialogClass}
      canEscapeKeyClose={canEscapeKeyClose}
      canOutsideClickClose={canOutsideClickClose}
      title={titleWithButtons()}
      {...rest}
    >
      {children}
    </BpDialog>
  );
};

export default observer(DialogBase);
