import { JSX, useState } from "react";
import { IconName, NonIdealState } from "@blueprintjs/core";
import { MaybeElement } from "@blueprintjs/core/src/common";
import { BlueprintIcon } from "@ui/BlueprintIcon";
import { observer } from "mobx-react-lite";

import AssetImage from "@components/Shared/AssetImage/AssetImage";

import "./AttachmentDetailsImagePreview.scss";

interface IAttachmentDetailsImagePreviewProps {
  src?: string;
  icon?: IconName | MaybeElement;
  iconSize?: number;
  action?: JSX.Element;
  onDoubleClick?(): void;
}

const AttachmentDetailsImagePreview = (props: IAttachmentDetailsImagePreviewProps) => {
  const { src, icon, iconSize, action, onDoubleClick } = props;
  const [previewImageError, setPreviewImageError] = useState(false);

  const renderContent = () => {
    if (!src && icon) {
      return <BlueprintIcon icon={icon} size={iconSize} />;
    }

    return src && !previewImageError ? (
      <>
        <AssetImage
          alt="Preview Image"
          className="attachment-details-preview--image"
          src={src}
          onError={() => setPreviewImageError(true)}
          onDoubleClick={onDoubleClick}
        />
        {action && <div className="attachment-details-preview--actions">{action}</div>}
      </>
    ) : (
      <NonIdealState icon="info-sign" title={previewImageError ? "Error loading preview" : "No preview available"} action={action} />
    );
  };

  return <div className="attachment-details-preview">{renderContent()}</div>;
};

export default observer(AttachmentDetailsImagePreview);
