import { useCallback, useEffect, useState } from "react";
import { ButtonGroup, Position, Tooltip } from "@blueprintjs/core";
import { observer } from "mobx-react";

import CrossSectionIconDark from "@assets/icons/hoopsIcons/dark/cross_section_cross_section_tools_dark.svg?react";
import CrossSectionIconLight from "@assets/icons/hoopsIcons/light/cross_section_cross_section_tools_light.svg?react";
import { Button } from "@components/Button";
import { Popover } from "@components/Popover";
import { LegacyCustomIcon } from "@components/Shared/LegacyCustomIcon/LegacyCustomIcon";
import appStore from "@store/AppStore";
import { trackSegmentEvent } from "src/lib/Segment";

import styles from "../HoopsMenu.module.scss";

enum CuttingPlaneMode {
  None = 0,
  XPlus = 1,
  XMinus = 2,
  YPlus = 4,
  YMinus = 8,
  ZPlus = 16,
  ZMinus = 32,
}

type HoopsMenuCuttingPlaneButtonGroupProps = {
  viewer: Communicator.WebViewer;
};

type CuttingPlaneEntry = {
  name: string;
  mode: CuttingPlaneMode;
  normal: { x: number; y: number; z: number };
  complement: CuttingPlaneMode;
};

const cuttingPlaneEntries: CuttingPlaneEntry[] = [
  {
    name: "X+",
    mode: CuttingPlaneMode.XPlus,
    complement: CuttingPlaneMode.XMinus,
    normal: { x: 1, y: 0, z: 0 },
  },
  {
    name: "X-",
    mode: CuttingPlaneMode.XMinus,
    complement: CuttingPlaneMode.XPlus,
    normal: { x: -1, y: 0, z: 0 },
  },
  {
    name: "Y+",
    mode: CuttingPlaneMode.YPlus,
    complement: CuttingPlaneMode.YMinus,
    normal: { x: 0, y: 1, z: 0 },
  },
  {
    name: "Y-",
    mode: CuttingPlaneMode.YMinus,
    complement: CuttingPlaneMode.YPlus,
    normal: { x: 0, y: -1, z: 0 },
  },
  {
    name: "Z+",
    mode: CuttingPlaneMode.ZPlus,
    complement: CuttingPlaneMode.ZMinus,
    normal: { x: 0, y: 0, z: 1 },
  },
  {
    name: "Z-",
    mode: CuttingPlaneMode.ZMinus,
    complement: CuttingPlaneMode.ZPlus,
    normal: { x: 0, y: 0, z: -1 },
  },
];

const HoopsMenuCuttingPlaneButtonGroup = ({ viewer }: HoopsMenuCuttingPlaneButtonGroupProps) => {
  const [cuttingPlaneMode, setCuttingPlaneMode] = useState(CuttingPlaneMode.None);

  const handleCuttingPlaneToggled = (entry: CuttingPlaneEntry) => {
    trackSegmentEvent("hoops:cutting-plane-toggled", { cuttingPlane: entry.name });
    const { mode, complement } = entry;
    const isCurrentlyActive = (cuttingPlaneMode & mode) === mode;
    // Bitwise toggle the mode
    let combinedMode = isCurrentlyActive ? cuttingPlaneMode & ~mode : cuttingPlaneMode | mode;
    // Ensure the complementary mode is off
    combinedMode &= ~complement;
    setCuttingPlaneMode(combinedMode);
  };

  const handleCuttingPlaneCleared = () => {
    setCuttingPlaneMode(CuttingPlaneMode.None);
  };

  const updateCuttingSection = useCallback(
    async (cuttingSection: Communicator.CuttingSection) => {
      // Cutting mode deactivated
      if (cuttingPlaneMode === CuttingPlaneMode.None) {
        await cuttingSection?.clear();
        return;
      }

      const bounding = await viewer.model.getModelBounding(true, true);

      const currentPlanes = [];
      for (let i = 0; i < cuttingPlaneEntries.length; i++) {
        const plane = cuttingSection.getPlane(i);
        if (plane) {
          currentPlanes.push(plane);
        }
      }

      for (const { normal, mode } of cuttingPlaneEntries) {
        const position = normal.x + normal.y + normal.z > 0 ? bounding.max : bounding.min;
        const axis = normal.x ? Communicator.Axis.X : normal.y ? Communicator.Axis.Y : Communicator.Axis.Z;
        const referenceGeometry = viewer.cuttingManager.createReferenceGeometryFromAxis(axis, bounding);
        const plane = Communicator.Plane.createFromPointAndNormal(position, new Communicator.Point3(normal.x, normal.y, normal.z));

        const shouldBeActive = (cuttingPlaneMode & mode) === mode;
        const existingPlaneIndex = currentPlanes.findIndex(p => p.normal.equals(plane.normal));
        if (shouldBeActive && existingPlaneIndex === -1) {
          await cuttingSection.addPlane(plane, referenceGeometry);
        } else if (!shouldBeActive && existingPlaneIndex !== -1) {
          await cuttingSection.removePlane(existingPlaneIndex);
        }
      }
      await cuttingSection.activate();
    },
    [cuttingPlaneMode, viewer.cuttingManager, viewer.model]
  );

  useEffect(() => {
    const cuttingSection = viewer?.cuttingManager.getCuttingSection(0);
    if (cuttingSection) {
      void updateCuttingSection(cuttingSection);
    }
  }, [viewer, cuttingPlaneMode, updateCuttingSection]);

  const cuttingPlaneMenu = (
    <ButtonGroup large>
      {cuttingPlaneEntries.map(entry => (
        <Button
          className={styles.hoopsToolbarButtonAndButtonGroup}
          key={entry.name}
          text={entry.name}
          active={(cuttingPlaneMode & entry.mode) === entry.mode}
          onClick={() => handleCuttingPlaneToggled(entry)}
          e2eIdentifiers={[entry.name]}
        />
      ))}
      <Button
        className={styles.hoopsToolbarButtonAndButtonGroup}
        key="clear"
        icon="delete"
        onClick={handleCuttingPlaneCleared}
        e2eIdentifiers="clear"
      />
    </ButtonGroup>
  );

  return (
    <Popover content={cuttingPlaneMenu} placement={Position.TOP}>
      <Tooltip position={Position.RIGHT} content="Cutting planes">
        <Button
          className={styles.hoopsToolbarButtonAndButtonGroup}
          icon={<LegacyCustomIcon large icon={appStore.env.themeIsDark ? <CrossSectionIconDark /> : <CrossSectionIconLight />} />}
          active={cuttingPlaneMode !== CuttingPlaneMode.None}
          e2eIdentifiers={[appStore.env.themeIsDark ? "dark" : "light"]}
        />
      </Tooltip>
    </Popover>
  );
};

export default observer(HoopsMenuCuttingPlaneButtonGroup);
