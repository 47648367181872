import { ComponentType, JSX } from "react";

import { createDataTestId, ElementType, IE2EDataTag, IE2EIdentifiersParam } from "../../Utilities/E2EUtils";

export const withDataTestId = <P extends object>(Component: ComponentType<P>, elementType: ElementType, additionalProps?: Partial<P>) =>
  function WithDataTestId(props: Omit<P, keyof IE2EDataTag> & IE2EIdentifiersParam): JSX.Element {
    const { e2eIdentifiers = [], ...restProps } = props;
    if (additionalProps) {
      Object.assign(restProps, additionalProps);
    }
    return <Component {...(restProps as unknown as P)} {...createDataTestId(elementType, e2eIdentifiers)} />;
  };
