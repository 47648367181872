import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useWorkspace } from "@hooks/useWorkspace";
import { observer } from "mobx-react";

import appStore from "@store/AppStore";

import CodeBlockPage from "../CodeBlockPage/CodeBlockPage";

import "./CodeBlocksFrame.scss";

/** Main Function. */
function CodeBlocksFrame() {
  const { codeBlockId } = useParams();
  const workspace = useWorkspace();
  const codeBlock = codeBlockId ? workspace.analysis.codeBlockMap.get(codeBlockId) : undefined;

  useEffect(() => {
    if (codeBlock) {
      appStore.env.setActiveCodeBlock(codeBlock.id);
    }
  }, [codeBlock]);

  if (!codeBlock) {
    return null;
  }

  return <CodeBlockPage codeBlock={codeBlock} />;
}

/** Exports. */
export default observer(CodeBlocksFrame);
