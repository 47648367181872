export enum UnitType {
  UNITLESS = "unitless",
  PHYSICAL = "physical",
  CURRENCY = "currency",
}

export type CustomUnit = {
  shorthand: string;
  name: CurrencyCode;
  prefixes?: string;
  aliases?: string[];
  offset?: number;
};

export enum CurrencyCode {
  USD = "USD",
  EUR = "EUR",
  GBP = "GBP",
}

export const currencyUnits: CustomUnit[] = [
  { shorthand: "$", name: CurrencyCode.USD, prefixes: "none", aliases: ["dollar", "dollars", "usd"], offset: 0 },
  { shorthand: "€", name: CurrencyCode.EUR, prefixes: "none", aliases: ["euro", "euros", "eur"], offset: 0 },
  { shorthand: "£", name: CurrencyCode.GBP, prefixes: "none", aliases: ["pound", "pounds", "sterling", "gbp"], offset: 0 },
];

export function getCurrencyUnit(currency: string) {
  if (!currency) {
    return undefined;
  }
  const lowerCaseCurrency = currency.toLowerCase();
  return currencyUnits?.find(
    u => u.name.toLowerCase() === lowerCaseCurrency || u.aliases?.includes(lowerCaseCurrency) || u.shorthand === currency
  );
}
export function isCurrency(unit: string): boolean {
  return !!unit && !!getCurrencyUnit(unit);
}

export function isUnitless(unit: string): boolean {
  return !unit || /^\s*$/.test(unit);
}

export function isPhysical(unit: string): boolean {
  return !isCurrency(unit) && !isUnitless(unit);
}

export function getUnitType(unit: string | undefined): UnitType | undefined {
  if (unit === undefined) {
    return undefined;
  }
  if (isUnitless(unit)) {
    return UnitType.UNITLESS;
  }
  if (isPhysical(unit)) {
    return UnitType.PHYSICAL;
  }
  if (isCurrency(unit)) {
    return UnitType.CURRENCY;
  }
}
