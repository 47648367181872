import { useState } from "react";
import { SketchPicker } from "react-color";
import { observer } from "mobx-react";

import { Button } from "@components/Button";
import { Popover } from "@components/Popover";

interface ColorButtonProps {
  color: string;
  showAlpha?: boolean;
  onChange: (color: string, alpha?: number) => void;
}

export const ColorButton = observer(({ color, onChange, showAlpha }: ColorButtonProps) => {
  const [showColorPicker, setShowColorPicker] = useState(false);

  const toggleColorPicker = () => setShowColorPicker(!showColorPicker);

  return (
    <div className="color-button">
      <Popover
        isOpen={showColorPicker}
        onClose={() => setShowColorPicker(false)}
        content={<SketchPicker color={color} onChange={e => onChange(e.hex, e.rgb.a)} disableAlpha={!showAlpha} />}
        placement="bottom"
      >
        <Button
          className="color-button--button"
          onClick={toggleColorPicker}
          style={{ backgroundColor: color }}
          e2eIdentifiers="toggle-color-picker"
        />
      </Popover>
    </div>
  );
});
