import { useState } from "react";
import { H3, Intent } from "@blueprintjs/core";
import startCase from "lodash/startCase";
import { observer } from "mobx-react-lite";

import { DocumentIcon } from "@components/DocumentIcon";
import { ListEntry } from "@components/Shared";
import { showToast } from "@components/UiLayers/toaster";
import appStore from "@store/AppStore";
import { IAttachment } from "@store/AttachmentStore";

import AttachmentDetailsDialog from "./AttachmentDetailsDialog";
import AttachmentDetailsImagePreview from "./AttachmentDetailsImagePreview";
import AttachmentDetailsPanel from "./AttachmentDetailsPanel";

import "./AttachmentDetailsReference.scss";

interface IReferenceDocument {
  id: string;
  label: string;
  coverUrl?: string;
  icon?: string;
}

interface IAttachmentDetailsReferenceProps {
  attachment: IAttachment;
  isOpen: boolean;
  onClose(): void;
}

const getDocument = (attachment: IAttachment): IReferenceDocument | undefined => {
  const { referenceId, referenceEntityType } = attachment;

  if (!referenceId || !referenceEntityType) {
    console.error(`Missing reference information. id: ${referenceId}, entityType: ${referenceEntityType}`);
    return;
  }

  return appStore.workspaceModel?.getDocument(referenceId, referenceEntityType);
};

const AttachmentDetailsReference = (props: IAttachmentDetailsReferenceProps) => {
  const { attachment, isOpen, onClose } = props;
  const [document] = useState(() => getDocument(attachment));
  const { referenceEntityType } = attachment;

  if (!document) {
    const { referenceId } = attachment;
    console.error(`Could not find document. id: ${referenceId}, entityType: ${referenceEntityType}`);
    showToast("Could not find document", Intent.WARNING);
    return null;
  }

  const { label, icon, coverUrl } = document;

  const renderName = () => {
    if (!icon) {
      return label;
    }

    return (
      <div className="attachment-details-reference--name">
        <span>{icon}</span>
        <span>{label}</span>
      </div>
    );
  };

  return (
    <AttachmentDetailsDialog label={document.label} isOpen={isOpen} onClose={onClose}>
      <AttachmentDetailsImagePreview src={coverUrl} icon={<DocumentIcon entityType={referenceEntityType} />} iconSize={64} />
      <AttachmentDetailsPanel>
        <H3>Details</H3>
        <ListEntry label="Name" value={renderName()} />
        <ListEntry label="Document Type" value={startCase(referenceEntityType)} />
      </AttachmentDetailsPanel>
    </AttachmentDetailsDialog>
  );
};

export default observer(AttachmentDetailsReference);
