import { IconName } from "@blueprintjs/core";

import { StatusType } from "@store/StatusDefinitionStore";

export const STATUS_MENU_ITEMS: Array<{ icon: IconName; text: string; statusType: StatusType }> = [
  { icon: "italic", text: "Text", statusType: StatusType.text },
  { icon: "numerical", text: "Numeric", statusType: StatusType.number },
  { icon: "link", text: "Link", statusType: StatusType.url },
  { icon: "tick", text: "Check", statusType: StatusType.check },
  { icon: "star", text: "Single Select", statusType: StatusType.singleSelect },
  { icon: "multi-select", text: "Multi Select", statusType: StatusType.multiSelect },
  { icon: "person", text: "Mention", statusType: StatusType.mention },
  { icon: "calendar", text: "Date", statusType: StatusType.date },
];

export const getProjectStatusIcon = (statusType: StatusType): string => {
  const item = STATUS_MENU_ITEMS.find(item => item.statusType === statusType);
  return item?.icon || "";
};
