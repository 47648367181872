import { Modifier } from "@dnd-kit/core";
import { getEventCoordinates } from "@dnd-kit/utilities";

export const snapFixedSizeObjectToCenter = (fixedWidth: number, fixedHeight: number): Modifier => {
  return ({ activatorEvent, draggingNodeRect, transform }) => {
    if (draggingNodeRect && activatorEvent) {
      const activatorCoordinates = getEventCoordinates(activatorEvent);

      if (!activatorCoordinates) {
        return transform;
      }

      const offsetX = activatorCoordinates.x - draggingNodeRect.left;
      const offsetY = activatorCoordinates.y - draggingNodeRect.top;

      return {
        ...transform,
        x: transform.x + offsetX - fixedWidth / 2.0,
        y: transform.y + offsetY - fixedHeight / 2.0,
      };
    }

    return transform;
  };
};
