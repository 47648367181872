import { Intent } from "@blueprintjs/core";
import { observer } from "mobx-react";

import { StatusIcon } from "@components/Shared/StatusIcon";
import { ExecutionEnvironmentStatus } from "@rollup-api/models/execution-environments";

function ExecutionStatusIcon({ status }: { status: ExecutionEnvironmentStatus }) {
  switch (status) {
    case ExecutionEnvironmentStatus.Pending:
      return <StatusIcon intent={Intent.NONE} tooltip="Awaiting image creation" />;
    case ExecutionEnvironmentStatus.Creating:
      return <StatusIcon intent={Intent.WARNING} tooltip="Creating image" />;
    case ExecutionEnvironmentStatus.Ready:
      return <StatusIcon intent={Intent.SUCCESS} tooltip="Ready" />;
    case ExecutionEnvironmentStatus.Error:
      return <StatusIcon intent={Intent.DANGER} tooltip="Image error" />;
    default:
      return <StatusIcon intent={Intent.SUCCESS} tooltip="Unknown status" />;
  }
}

export default observer(ExecutionStatusIcon);
