import { JSX, useState } from "react";
import { HTMLTable, InputGroup, NonIdealState } from "@blueprintjs/core";
import classNames from "classnames";
import isString from "lodash/isString";
import { observer } from "mobx-react";

import { Button } from "@components/Button";
import ImportRow from "@components/Imports/ImportRow/ImportRow";
import { LogsDialog } from "@components/Imports/LogsDialog";
import appStore from "@store/AppStore";
import { IImport } from "@store/ImportStore";
import { Text, TextVariant } from "src/ui/Text";

import ImportModal from "../Modal/ImportModal";

import "./ImportsTable.scss";

type Props = {
  title: string | JSX.Element;
  loading: boolean;
  allowCreate?: boolean;
  imports: IImport[];
  onLoadMore: () => void;
};

const ImportsTable = (props: Props) => {
  const { title, imports, loading, allowCreate, onLoadMore } = props;
  const [modalOpen, setModalOpen] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [activeImport, setActiveImport] = useState<IImport | undefined>();

  const renderCreateButton = (testId: string) => {
    if (!allowCreate) {
      return;
    }

    return (
      <Button onClick={() => setModalOpen(true)} icon="plus" large intent="primary" e2eIdentifiers={testId}>
        Create import
      </Button>
    );
  };

  const renderContent = () => {
    if (loading) {
      return (
        <tr>
          <td colSpan={7} className="message">
            <div className="text-center">Loading imports...</div>
          </td>
        </tr>
      );
    }

    if (!imports.length) {
      return (
        <tr>
          <td colSpan={7}>
            <div>
              <NonIdealState
                action={renderCreateButton("create-import-non-ideal")}
                iconSize={20}
                icon="import"
                description="You don't have any imports yet"
                title="No imports"
              />
            </div>
          </td>
        </tr>
      );
    }

    const filteredImports = imports.filter(i =>
      searchValue ? i.originalFileName.toLowerCase().includes(searchValue.toLowerCase()) : true
    );

    if (!filteredImports.length) {
      return (
        <tr>
          <td colSpan={7} className="message">
            <div className="text-center">No file names matching search query</div>
          </td>
        </tr>
      );
    }

    return filteredImports
      .sort((a, b) => b.createdAt - a.createdAt)
      .map(importEntity => <ImportRow key={importEntity.id} onLogsView={setActiveImport} importEntity={importEntity} />);
  };

  return (
    <div className={classNames("imports-table", { ["imports-table--fill"]: !imports.length })}>
      <div className="imports-table--wrap">
        {isString(title) ? <h1>{title}</h1> : title}
        <div className="imports-table--header">
          <InputGroup
            className="imports-table--search"
            value={searchValue}
            onChange={e => setSearchValue(e.target.value)}
            disabled={!imports.length}
            leftIcon="search"
            rightElement={
              searchValue ? <Button minimal icon="cross" onClick={() => setSearchValue("")} e2eIdentifiers="clear-search" /> : undefined
            }
            large
            placeholder="Search for the imports"
          />
          {renderCreateButton("create-import")}
        </div>
      </div>
      <HTMLTable className="imports-table--table">
        <thead>
          <tr>
            <th>
              <Text variant={TextVariant.H5}>Source</Text>
            </th>
            <th>
              <Text variant={TextVariant.H5}>Imported By</Text>
            </th>
            <th>
              <Text variant={TextVariant.H5}>Date</Text>
            </th>
            <th>
              <Text variant={TextVariant.H5}>Logs</Text>
            </th>
            <th>
              <Text variant={TextVariant.H5}>File</Text>
            </th>
            <th>
              <Text variant={TextVariant.H5}>Status</Text>
            </th>
          </tr>
        </thead>
        <tbody>{renderContent()}</tbody>
      </HTMLTable>
      {appStore.workspaceModel?.hasMoreImports && (
        <div className="imports-table--more">
          <Button onClick={onLoadMore} loading={loading} e2eIdentifiers="load-more">
            Load more
          </Button>
        </div>
      )}
      {modalOpen && <ImportModal setOpen={setModalOpen} open />}
      <LogsDialog onClose={() => setActiveImport(undefined)} importEntity={activeImport} />
    </div>
  );
};

export default observer(ImportsTable);
