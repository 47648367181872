import { observer } from "mobx-react";

import { IAttachment } from "@store/AttachmentStore";
import { IBlock } from "@store/BlockStore";

import AttachmentListRow from "./AttachmentListRow";

import "./AttachmentList.scss";

type AttachmentsListProps = {
  block: IBlock;
  attachmentList: IAttachment[];
};

const AttachmentList = ({ block, attachmentList }: AttachmentsListProps) => {
  return (
    <table className="attachment-list-table">
      <tbody>
        {attachmentList.map((attachment: IAttachment) => (
          <AttachmentListRow key={`${attachment.id}${attachment.label}`} block={block} attachment={attachment} />
        ))}
      </tbody>
    </table>
  );
};

export default observer(AttachmentList);
