import { Tab, Tabs } from "@blueprintjs/core";
import { observer } from "mobx-react";

import { TabTitle } from "@components/TabTitle";
import appStore from "@store/AppStore";
import { EBlockTab, IBlock } from "@store/BlockStore";
import { FeatureFlag } from "@store/FeatureFlagsStore";

import AttachmentsTabPanel from "./AttachmentsTabPanel";
import ChildBlocksTabPanel from "./ChildBlocksTabPanel";
import CommentsPanel from "./CommentsTabPanel";
import InterfacesTabPanel from "./InterfacesTabPanel";
import PropertiesTabPanel from "./PropertiesTabPanel";

import "./BlockViewTabs.scss";

/** Type defs. */
type BlockViewTabsProps = {
  block: IBlock;
};

/** Main function. */
function BlockViewTabs({ block }: BlockViewTabsProps) {
  const handleTabChange = (newTabId: string) => {
    appStore.env.setCurrentBlockTab(newTabId as EBlockTab);
    newTabId === EBlockTab.FEED && block.fetchActionHistory();
  };

  return (
    <Tabs
      id="block-view-tabs"
      large
      onChange={handleTabChange}
      className="block-view-tabs"
      renderActiveTabPanelOnly
      selectedTabId={appStore.env.currentBlockTab}
    >
      {[
        {
          id: EBlockTab.PROPERTIES,
          title: "Properties",
          count: block.propertyInstances?.length,
          panel: <PropertiesTabPanel block={block} />,
          enabled: true,
        },
        {
          id: EBlockTab.INTERFACES,
          title: "Interfaces",
          count: block.validatedInterfaces?.length,
          panel: <InterfacesTabPanel block={block} />,
          enabled: appStore.env.featureFlags.enabled(FeatureFlag.INTERFACES),
        },
        {
          id: EBlockTab.ATTACHMENTS,
          title: "Attachments",
          count: block.attachments?.length,
          panel: <AttachmentsTabPanel block={block} />,
          enabled: true,
        },
        {
          id: EBlockTab.CHILD_BLOCKS,
          title: "Child Blocks",
          count: block.validatedChildren?.length,
          panel: <ChildBlocksTabPanel block={block} />,
          enabled: true,
        },
        {
          id: EBlockTab.COMMENTS,
          title: "Discussion",
          count: undefined,
          panel: <CommentsPanel block={block} />,
          enabled: true,
        },
      ].map(
        ({ id, title, count, panel, enabled }) =>
          enabled && <Tab key={id} id={id} title={<TabTitle title={title} count={count} />} panel={panel} />
      )}
    </Tabs>
  );
}

/** Exports. */
export type { BlockViewTabsProps };
export default observer(BlockViewTabs);
