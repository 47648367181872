import { JSX, MouseEvent } from "react";
import { Intent } from "@blueprintjs/core";
import { observer } from "mobx-react";

import ConfirmationDialog from "@components/ConfirmationDialog/ConfirmationDialog";
import { IE2EIdentifiers, mergeE2EIdentifiers } from "@utilities/E2EUtils";

interface IDeleteConfirmationDialogProps {
  isOpen: boolean;
  titleItem: string;
  descriptionItem?: string;
  description?: string;
  disabled?: boolean;
  cancelE2EIdentifiers?: IE2EIdentifiers;
  confirmE2EIdentifiers?: IE2EIdentifiers;
  onCancel(): void;
  onClose(): void;
  onConfirm(event: MouseEvent): void;
}

const DeleteConfirmationDialog = (props: IDeleteConfirmationDialogProps): JSX.Element => {
  const { isOpen, titleItem, descriptionItem = "this", description } = props;
  const { disabled, cancelE2EIdentifiers = "cancel", confirmE2EIdentifiers = "confirm", onCancel, onClose, onConfirm } = props;

  return (
    <ConfirmationDialog
      isOpen={isOpen}
      title={`Delete ${titleItem}?`}
      description={description ?? `Are you sure you want to delete ${descriptionItem}?`}
      actionButtonText="Delete"
      cancelE2EIdentifiers={mergeE2EIdentifiers("delete", cancelE2EIdentifiers)}
      confirmE2EIdentifiers={mergeE2EIdentifiers("delete", confirmE2EIdentifiers)}
      disabled={disabled}
      intent={Intent.DANGER}
      onCancel={onCancel}
      onClose={onClose}
      onConfirm={onConfirm}
    />
  );
};

export default observer(DeleteConfirmationDialog);
