import { useEffect, useState } from "react";
import { DialogBody, DialogFooter, Intent } from "@blueprintjs/core";
import { observer } from "mobx-react";

import { GoogleSheetsIcon } from "@assets/icons/thirdParty";
import { Button } from "@components/Button";
import { DialogLegacy } from "@components/Dialog";
import CustomIcon from "@components/Shared/LegacyCustomIcon";
import { DataSource } from "@rollup-api/models/data-sources/data-source.model";
import { getDataLinkString } from "@utilities/DataConnectionUtils";
import { createCopyToClipboardHandler } from "@utilities/TextUtil";

import { QueryDataSourceContainer } from "./QueryDataSourceContainer";

import "./QueryDataSourceDialog.scss";

export interface QueryDataSourceDialogProps {
  dataSource: DataSource;
  onClose: () => void;
}

export const QueryDataSourceDialog = observer(function QueryDataSourceDialog({ dataSource, onClose }: QueryDataSourceDialogProps) {
  const [query, setQuery] = useState("");
  const [selectedSheet, setSelectedSheet] = useState<string>("");

  useEffect(() => {
    if (dataSource) {
      setQuery("");
      setSelectedSheet("");
    }
  }, [dataSource]);

  useEffect(() => {
    setQuery("");
  }, [selectedSheet]);

  const linkString = getDataLinkString({ dataSourceId: dataSource.id, sheet: selectedSheet, query });
  const copyToClipboard = createCopyToClipboardHandler(linkString, "data link");

  const handleCopyLink = async () => {
    await copyToClipboard();
    onClose();
  };

  return (
    <DialogLegacy
      className="query-data-source-dialog"
      isOpen={!!dataSource}
      onClose={onClose}
      canEscapeKeyClose
      canOutsideClickClose
      icon={<CustomIcon icon={<GoogleSheetsIcon />} />}
      title={dataSource?.label || "Data source"}
    >
      <DialogBody>
        <QueryDataSourceContainer
          queryString={query}
          dataSource={dataSource}
          onQueryChanged={setQuery}
          onSheetChanged={setSelectedSheet}
          selectedSheet={selectedSheet}
        />
      </DialogBody>
      <DialogFooter
        actions={
          <Button icon="clipboard" e2eIdentifiers="copy-link" intent={Intent.PRIMARY} disabled={!query} onClick={handleCopyLink}>
            Copy data link
          </Button>
        }
      />
    </DialogLegacy>
  );
});
