import { Position, Tooltip } from "@blueprintjs/core";
import { observer } from "mobx-react";

import MeasureDeleteAllIconDark from "@assets/icons/hoopsIcons/dark/measure_delete_all_dark.svg?react";
import MeasureDeleteAllIconLight from "@assets/icons/hoopsIcons/light/measure_delete_all_light.svg?react";
import { Button } from "@components/Button";
import { LegacyCustomIcon } from "@components/Shared/LegacyCustomIcon/LegacyCustomIcon";
import appStore from "@store/AppStore";

import styles from "../HoopsMenu.module.scss";

type HoopsMenuDeleteAllMeasurementsButtonProps = {
  viewer: Communicator.WebViewer;
};

const HoopsMenuDeleteAllMeasurementsButton = ({ viewer }: HoopsMenuDeleteAllMeasurementsButtonProps) => {
  const deleteAllMeasurementsClick = () => {
    viewer.measureManager.removeAllMeasurements();
  };

  const deleteIcon = () => {
    if (appStore.env.themeIsDark) {
      return <LegacyCustomIcon large icon={<MeasureDeleteAllIconDark />} />;
    } else {
      return <LegacyCustomIcon large icon={<MeasureDeleteAllIconLight />} />;
    }
  };

  return (
    <Tooltip position={Position.TOP} hoverOpenDelay={500} content="Delete all measurements">
      <Button
        className={styles.hoopsToolbarButtonAndButtonGroup}
        icon={deleteIcon()}
        onClick={deleteAllMeasurementsClick}
        e2eIdentifiers="delete"
      />
    </Tooltip>
  );
};

export default observer(HoopsMenuDeleteAllMeasurementsButton);
