import { observer } from "mobx-react";

import { reqBlockTypeToRollupEditorType } from "@components/Requirements/RequirementsDocumentView/RequirementsDocumentView.utils";
import { TCellRendererProps } from "@components/Table";
import RichTextCellEditor from "@components/Table/CellEditors/RichTextCellEditor";
import { RequirementBlockType } from "@rollup-api/models";
import { IRequirementBlock } from "@store/RequirementsStore";
import { getHeadingContent } from "@utilities/TipTap";

import { RequirementsActionsCell } from "./RequirementsActionsCell";

import styles from "./HeadingCellRenderer.module.scss";

export interface IHeadingCellCustomProps {
  onDeleteRequirementBlock(block: IRequirementBlock): void;
}

export type IHeadingCellRendererProps = IHeadingCellCustomProps & TCellRendererProps<IRequirementBlock>;

export const getHeadingPlaceholder = (type: RequirementBlockType): string | undefined => {
  switch (type) {
    case RequirementBlockType.h1:
      return "Heading 1";
    case RequirementBlockType.h2:
      return "Heading 2";
    case RequirementBlockType.h3:
      return "Heading 3";
  }
};

const HeadingCellRenderer = (props: IHeadingCellRendererProps) => {
  const { data: requirementBlock, api, node } = props;

  const handleCommit = (value: string) => {
    requirementBlock.setLabel(value);
  };

  const blockType = reqBlockTypeToRollupEditorType(requirementBlock.type);
  const defaultValue = getHeadingContent(blockType, requirementBlock.label);

  const renderHeading = () => {
    return (
      <RichTextCellEditor<IRequirementBlock>
        className={styles.richTextEditor}
        node={node}
        api={api}
        defaultValue={defaultValue}
        placeholder={getHeadingPlaceholder(requirementBlock.type)}
        onCommit={handleCommit}
        type={reqBlockTypeToRollupEditorType(requirementBlock.type)}
        resizeIfSmaller
      />
    );
  };

  return (
    <div className={styles.headingCellRenderer}>
      <div className={styles.headingCellRendererActions}>
        <RequirementsActionsCell {...props} disableStatusIndicator />
      </div>
      <div className={styles.headingCellRendererHeading}>{renderHeading()}</div>
    </div>
  );
};

export default observer(HeadingCellRenderer);
