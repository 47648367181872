import { useEffect, useRef, useState } from "react";
import { Intent, Menu, MenuDivider, PopoverInteractionKind } from "@blueprintjs/core";
import { useWorkspace } from "@hooks/useWorkspace";
import { observer } from "mobx-react";
import { isAlive } from "mobx-state-tree";

import { Button } from "@components/Button";
import { DeleteConfirmationDialog } from "@components/DeleteConfirmationDialog";
import { MenuItem } from "@components/MenuItem";
import { MenuItemDelete } from "@components/MenuItems";
import { updateConfigWithAddedColumn } from "@components/Modeling/ModelingFrame/Table/TableComponent/utils";
import { Popover } from "@components/Popover";
import { CopyLinkMenuItem, CopyPasteBlockMenuItems } from "@components/SmallComponents";
import appStore from "@store/AppStore";

import { AddNewDefinitionPopover } from "../Components/AddNewDefinitionPopover";
import { TCellRendererProps } from "../types";

enum ECreateNew {
  PROPERTY,
  STATUS,
  BLOCK,
  SIBLING_BLOCK,
}

const ActionsCell = (props: TCellRendererProps) => {
  const block = props.data?.block;
  const { registerRowDragger } = props;
  const buttonRef = useRef<HTMLButtonElement>(null);
  const [definition, setDefinition] = useState(ECreateNew.STATUS);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isCreateNew, setIsCreateNew] = useState(false);
  const [label, setLabel] = useState("");
  const workspace = useWorkspace();

  useEffect(() => {
    buttonRef.current && registerRowDragger(buttonRef.current);
  }, [buttonRef, registerRowDragger]);

  if (!block || !isAlive(block)) {
    return null;
  }

  const handleOpenConfirmation = () => setIsModalOpen(true);

  const handleDeleteBlock = () => {
    setIsModalOpen(false);

    if (block !== workspace.rootBlock) {
      workspace.deleteBlock(block);
    }
  };

  const updateTable = (definition?: { id: string }) => {
    const { isDefaultTableViewConfigTab, tableViewGridApi } = appStore.env;
    if (!tableViewGridApi || !tableViewGridApi || !definition) return;

    if (isDefaultTableViewConfigTab) {
      tableViewGridApi.moveColumns([definition.id], -1);
      tableViewGridApi.setFocusedCell(0, definition.id);
      tableViewGridApi.ensureColumnVisible(definition.id, "middle");
    } else {
      updateConfigWithAddedColumn(tableViewGridApi, definition.id);
    }
  };

  const handleCreateDefinition = () => {
    if (definition === ECreateNew.STATUS) {
      workspace.addNewStatusDefinition(label).then(updateTable);
    } else if (definition === ECreateNew.PROPERTY) {
      workspace.addNewPropertyDefinition(label).then(updateTable);
    } else {
      workspace.addNewBlock(definition === ECreateNew.SIBLING_BLOCK ? block.parentBlock : block, label);
    }

    handleAddNewModalClose();
    setLabel("");
  };

  const handleAddNewProperty = () => {
    setDefinition(ECreateNew.PROPERTY);
    setIsCreateNew(true);
  };

  const handleAddNewBlock = () => {
    setDefinition(ECreateNew.BLOCK);
    setIsCreateNew(true);
  };

  const handleAddNewSiblingBlock = () => {
    setDefinition(ECreateNew.SIBLING_BLOCK);
    setIsCreateNew(true);
  };

  const handleAddNewStatus = () => {
    setDefinition(ECreateNew.STATUS);
    setIsCreateNew(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const handleAddNewModalClose = () => {
    setIsCreateNew(false);
  };

  const handleDetachBlock = () => {
    workspace.detachBlock(block);
  };

  const getActionsMenu = () => (
    <Menu>
      <MenuItem icon="new-layers" text="Add New Child Block" onClick={handleAddNewBlock} e2eIdentifiers="add-new-child-block" />
      <MenuItem icon="new-layers" text="Add New Sibling Block" onClick={handleAddNewSiblingBlock} e2eIdentifiers="add-new-sibling-block" />
      <MenuDivider />
      <CopyPasteBlockMenuItems block={block} />
      <CopyLinkMenuItem node={block} />
      <MenuItem
        icon="th-list"
        text="Detach from tree"
        disabled={block === workspace.rootBlock || !!block.children?.length}
        onClick={handleDetachBlock}
        e2eIdentifiers="detach-from-tree"
      />
      <MenuItemDelete onDelete={handleOpenConfirmation} />
    </Menu>
  );

  const getCreateMenu = () => (
    <Menu>
      <MenuItem icon="new-layers" text="Add New Child Block" onClick={handleAddNewBlock} e2eIdentifiers="add-new-child-block" />
      <MenuItem icon="new-layer" text="Add new Property" onClick={handleAddNewProperty} e2eIdentifiers="add-new-property" />
      <MenuItem icon="new-layer" text="Add new Status" onClick={handleAddNewStatus} e2eIdentifiers="add-new-status" />
    </Menu>
  );

  return block !== appStore.workspaceModel?.rootBlock ? (
    <>
      <AddNewDefinitionPopover
        value={label}
        onChange={setLabel}
        isOpen={isCreateNew}
        onClose={handleAddNewModalClose}
        onConfirm={handleCreateDefinition}
      >
        <Popover
          canEscapeKeyClose
          inheritDarkTheme
          interactionKind={PopoverInteractionKind.CLICK}
          placement="bottom-start"
          content={getActionsMenu()}
        >
          <Button
            buttonRef={buttonRef}
            small
            minimal
            icon="drag-handle-vertical"
            intent={Intent.NONE}
            e2eIdentifiers={["menuexpand", block.id, block.label]}
          />
        </Popover>
      </AddNewDefinitionPopover>
      <DeleteConfirmationDialog
        isOpen={isModalOpen}
        titleItem={`block "${block.label}"`}
        descriptionItem="this block"
        onClose={handleModalClose}
        onCancel={handleModalClose}
        onConfirm={handleDeleteBlock}
      />
    </>
  ) : (
    <AddNewDefinitionPopover
      value={label}
      onChange={setLabel}
      isOpen={isCreateNew}
      onClose={handleAddNewModalClose}
      onConfirm={handleCreateDefinition}
    >
      <Popover
        canEscapeKeyClose
        inheritDarkTheme
        interactionKind={PopoverInteractionKind.CLICK}
        placement="bottom-start"
        content={getCreateMenu()}
      >
        <Button small minimal icon="small-plus" e2eIdentifiers="root-block-row-action" />
      </Popover>
    </AddNewDefinitionPopover>
  );
};

export default observer(ActionsCell);
