import { Extension } from "@tiptap/core";

const PreventModEnter = Extension.create({
  name: "prevent-mod-enter",
  addKeyboardShortcuts(this) {
    return {
      "Mod-Enter": () => true,
    };
  },
});

export default PreventModEnter;
