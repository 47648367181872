import { H3 } from "@blueprintjs/core";
import { observer } from "mobx-react-lite";

import AnchorLink from "@components/AnchorLink/AnchorLink";
import { ListEntry } from "@components/Shared";
import UserInfo from "@components/UserInfo/UserInfo";
import { GithubLabel, GithubUser, IssueDetails } from "@rollup-api/models";
import { formatDate } from "@utilities";

import AttachmentDetailsPanel from "../AttachmentDetailsPanel";

import "./GithubIssueDetailsPanel.scss";

interface IGithubIssueDetailsProps {
  metadata: IssueDetails;
}

// Alpha values used in Github
const BG_ALPHA = "2E"; // 0.18 in decimal
const BORDER_ALPHA = "4D"; // 0.3 in decimal

const GithubIssueDetailsPanel = (props: IGithubIssueDetailsProps) => {
  const { metadata } = props;
  const hasAssignees = !!metadata.assignees.nodes.length;
  const hasLabels = !!metadata.labels.nodes.length;

  const renderAssignees = (assignees: GithubUser[]) => {
    return (
      <>
        {assignees.map(assignee => (
          <UserInfo key={assignee.login} avatarUrl={assignee.avatarUrl} userName={assignee.login} size="small" />
        ))}
      </>
    );
  };

  const renderLabels = (labels: GithubLabel[]) => {
    return (
      <div className="github-issue-details-panel--labels-container">
        {labels.map(label => {
          const hexColor = `#${label.color}`;
          return (
            <div
              className="github-issue-details-panel--label"
              style={{ borderColor: `${hexColor}${BORDER_ALPHA}`, color: hexColor, backgroundColor: `${hexColor}${BG_ALPHA}` }}
              key={label.name}
            >
              {label.name}
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <AttachmentDetailsPanel className="github-issue-details-panel">
      <H3>Details</H3>
      <ListEntry label="Title" value={metadata.title} />
      {hasAssignees && <ListEntry label="Assignees" value={renderAssignees(metadata.assignees.nodes)} />}
      <ListEntry label="URL" value={<AnchorLink href={metadata.url} />} />
      <ListEntry label="Type" value="Issue" />
      {hasLabels && <ListEntry label="Labels" value={renderLabels(metadata.labels.nodes)} />}
      <ListEntry label="Comments" value={metadata.comments.totalCount.toString()} />
      <ListEntry label="Issue Number" value={metadata.number.toString()} />
      <ListEntry label="Closed" value={metadata.closed ? "Yes" : "No"} />
      <ListEntry label="Created at" value={formatDate(metadata.createdAt)} />
      {metadata.closedAt && <ListEntry label="Closed at" value={formatDate(metadata.closedAt)} />}
    </AttachmentDetailsPanel>
  );
};

export default observer(GithubIssueDetailsPanel);
