import { NonIdealState, Spinner } from "@blueprintjs/core";
import classNames from "classnames";

import "./Loading.scss";

export interface LoadingProps {
  title?: string;
  size?: number;
  className?: string;
  overlay?: boolean;
}

/** Main function. */
function Loading(props: LoadingProps) {
  const { title, size, className, overlay } = props;
  return (
    <NonIdealState className={classNames(className, { ["loading--overlay"]: overlay })} title={title}>
      <Spinner size={size ?? 64} />
    </NonIdealState>
  );
}

/** Exports. */
export default Loading;
