import { H3 } from "@blueprintjs/core";
import { observer } from "mobx-react-lite";

import AnchorLink from "@components/AnchorLink/AnchorLink";
import { ListEntry } from "@components/Shared";
import UserInfo from "@components/UserInfo/UserInfo";
import { PullRequestDetails } from "@rollup-api/models";
import { formatDate } from "@utilities";

import AttachmentDetailsPanel from "../AttachmentDetailsPanel";

interface IGithubPullRequestDetailsProps {
  metadata: PullRequestDetails;
}

const GithubPullRequestDetailsPanel = (props: IGithubPullRequestDetailsProps) => {
  const { metadata } = props;

  return (
    <AttachmentDetailsPanel>
      <H3>Details</H3>
      <ListEntry label="Title" value={metadata.title} />
      <ListEntry label="Author" value={<UserInfo avatarUrl={metadata.author.avatarUrl} userName={metadata.author.login} size="small" />} />
      <ListEntry label="URL" value={<AnchorLink href={metadata.url} />} />
      <ListEntry label="Type" value="Pull Request" />
      {metadata.comments && <ListEntry label="Comments" value={metadata.comments.totalCount.toString()} />}
      <ListEntry label="Draft" value={metadata.isDraft ? "Yes" : "No"} />
      <ListEntry label="Merged" value={metadata.merged ? "Yes" : "No"} />
      <ListEntry label="Closed" value={metadata.closed ? "Yes" : "No"} />
      <ListEntry label="Pull Request Number" value={metadata.number.toString()} />
      <ListEntry label="Created at" value={formatDate(metadata.createdAt)} />
      {metadata.closedAt && <ListEntry label="Closed at" value={formatDate(metadata.closedAt)} />}
    </AttachmentDetailsPanel>
  );
};

export default observer(GithubPullRequestDetailsPanel);
