import { forwardRef, ReactElement } from "react";
import { Classes } from "@blueprintjs/core";
import type { DraggableSyntheticListeners } from "@dnd-kit/core";
import type { Transform } from "@dnd-kit/utilities";
import classNames from "classnames";
import { observer } from "mobx-react";

import appStore from "@store/AppStore";

import "./item.scss";

export type TItemRendererArgs = {
  selected?: boolean;
  dragging?: boolean;
  isOver?: boolean;
  listeners: DraggableSyntheticListeners;
  handleProps: any;
  value: string;
  index?: number;
  onSelect?: () => void;
};

export type TItemRendererCallBack = (args: TItemRendererArgs) => ReactElement | null;

export interface Props {
  dragOverlay?: boolean;
  dragging?: boolean;
  isOver?: boolean;
  newItemAbove?: boolean;
  handleProps?: any;
  index?: number;
  transform?: Transform | null;
  listeners?: DraggableSyntheticListeners;
  transition?: string | null;
  value: any;
  onSelect?: () => void;
  selected?: boolean;
  renderItem: TItemRendererCallBack;
}

const Item = forwardRef(function Item(props: Props, ref: any) {
  const { newItemAbove, isOver, dragOverlay, index, dragging } = props as unknown as Props;
  const { handleProps, selected, listeners, renderItem, value, onSelect } = props as unknown as Props;

  return (
    <div data-id={value} className="rollup-dnd-item--wrapper" ref={ref}>
      <div
        className={classNames("rollup-dnd-item", {
          [Classes.DARK]: appStore.env.themeIsDark,
          ["rollup-dnd-item--dragging"]: dragging,
          ["rollup-dnd-item--over-above"]: isOver && newItemAbove,
          ["rollup-dnd-item--over-below"]: isOver && !newItemAbove,
          ["rollup-dnd-item--dragOverlay"]: dragOverlay,
        })}
      >
        {renderItem({
          onSelect,
          selected,
          dragging,
          isOver,
          index,
          listeners: dragging ? {} : listeners,
          handleProps: dragging ? {} : handleProps,
          value,
        })}
      </div>
    </div>
  );
});

Item.displayName = "Item";

export default observer(Item);
