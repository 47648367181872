import { IE2EIdentifiersParam } from "@utilities/E2EUtils";

import { ISelectOption } from "../Dropdown";

export enum EStepFieldType {
  Input = "Input",
  Dropdown = "Dropdown",
  TextArea = "TextArea",
}

interface IBaseStepField {
  name: string;
  type: EStepFieldType;
  required?: boolean;
}

interface IStepInputField extends IBaseStepField {
  value?: string;
  type: EStepFieldType.Input;
  placeholder?: string;

  onChange(value: string): void;
}

interface IStepDropdownField extends IBaseStepField, IE2EIdentifiersParam {
  selected?: ISelectOption;
  type: EStepFieldType.Dropdown;
  options: ISelectOption[];
  placeholder?: string;

  onSelect(selected: ISelectOption): void;
}

interface IStepTextAreaField extends IBaseStepField {
  value?: string;
  type: EStepFieldType.TextArea;
  placeholder?: string;

  onChange(value: string): void;
}

export type IStepField = IStepInputField | IStepDropdownField | IStepTextAreaField;
