import { useState } from "react";
import { Menu } from "@blueprintjs/core";
import { useDebouncedCallback } from "@hooks/useDebouncedCallback";
import classNames from "classnames";
import { observer } from "mobx-react";

import Avatar from "@components/Avatar/Avatar";
import { Button } from "@components/Button";
import { MenuItem } from "@components/MenuItem";
import { MenuItemDelete } from "@components/MenuItems";
import { Popover } from "@components/Popover";
import { RichTextEditor } from "@components/RichTextEditor";
import { UserTooltip } from "@components/UserTooltip";
import { IUser } from "@store/UserStore";

import styles from "./PropertyDefinitionDescription.module.scss";

type Props = {
  isEditing: boolean;
  description: string;
  lastModifiedBy?: IUser;
  onStartEditing(): void;
  onFinishEditing(): void;
  onChange(value: string): void;
};

const PropertyDefinitionDescription = (props: Props) => {
  const { description, isEditing, lastModifiedBy, onStartEditing, onFinishEditing, onChange } = props;
  const [isEditorFocused, setIsEditorFocused] = useState(false);
  const [editorContent, setEditorContent] = useState(description);

  const commitChanges = () => {
    setIsEditorFocused(false);
    onChange(editorContent);
    onFinishEditing();
  };

  const debouncedChange = useDebouncedCallback((content: string) => {
    onChange(content);
  }, 5000);

  const handleChange = (content: string) => {
    setEditorContent(content);
    debouncedChange(content);
  };

  return (
    <div
      className={classNames(styles.propertyDefinitionDescriptionRow, {
        [styles.propertyDefinitionDescriptionRowWithBorder]: !isEditing,
      })}
    >
      <div className={styles.propertyDefinitionDescriptionDescContainer}>
        {isEditing && (
          <div className={styles.propertyDefinitionDescriptionDescQuoteContainer}>
            <Button
              className={styles.propertyDefinitionDescriptionDescQuoteCloseIcon}
              icon="cross"
              onClick={commitChanges}
              e2eIdentifiers="cancel-new-description"
              minimal
            />
          </div>
        )}
        {!isEditing && lastModifiedBy && (
          <UserTooltip user={lastModifiedBy}>
            <Avatar source={lastModifiedBy.avatarUrl} size={20} shape="circle" />
          </UserTooltip>
        )}
        <RichTextEditor
          className={styles.propertyDefinitionDescriptionEditor}
          content={description}
          isEditable={isEditing}
          isFocused={isEditorFocused}
          onChange={handleChange}
          onCommit={commitChanges}
        />
      </div>
      {!isEditing && (
        <Popover
          content={
            <Menu>
              <MenuItem text="Edit" icon="edit" onClick={onStartEditing} e2eIdentifiers="description-edit" />
              <MenuItemDelete onDelete={() => onChange("")} e2eIdentifiers="description-remove" />
            </Menu>
          }
        >
          <Button className={styles.propertyDefinitionDescriptionMore} minimal icon="more" e2eIdentifiers="description-more" />
        </Popover>
      )}
    </div>
  );
};

export default observer(PropertyDefinitionDescription);
