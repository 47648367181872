import { Menu } from "@blueprintjs/core";
import { ICellRendererParams } from "ag-grid-community";
import { observer } from "mobx-react";

import { CellDropdown } from "@components/CellDropdown";
import { MenuItem } from "@components/MenuItem";
import { IComplexSelectOption } from "@components/Shared/Dropdown";
import { withObservedValueGetter } from "@utilities/AgGridUtils";
import { IE2EIdentifiers, mergeE2EIdentifiers } from "@utilities/E2EUtils";

import styles from "./DropdownCellRenderer.module.scss";

export interface IDropdownCellRendererOwnProps {
  options: IComplexSelectOption[];
  e2eIdentifiers: IE2EIdentifiers;
}

type IDropdownCellRendererProps = IDropdownCellRendererOwnProps & ICellRendererParams<any, IComplexSelectOption>;

export const DropdownCellRenderer = (props: IDropdownCellRendererProps) => {
  const { value, options, e2eIdentifiers, setValue } = props;

  const handleSelect = (option: IComplexSelectOption) => {
    setValue?.(option);
  };

  const getMenuContent = () => (
    <Menu>
      {options.map(option => (
        <MenuItem
          key={option.value}
          active={value?.value === option.value}
          onClick={() => handleSelect(option)}
          text={option.label}
          e2eIdentifiers={option.value}
        />
      ))}
    </Menu>
  );

  return (
    <CellDropdown
      buttonClassName={styles.dropdownCellRendererButton}
      text={value?.label ?? ""}
      content={getMenuContent()}
      e2eIdentifiers={mergeE2EIdentifiers(e2eIdentifiers, value?.value)}
    />
  );
};

export default withObservedValueGetter(observer(DropdownCellRenderer));
