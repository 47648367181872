import { JSX } from "react";
import { NonIdealState } from "@blueprintjs/core";
import { observer } from "mobx-react-lite";

import { Button } from "@components/Button";

import Pane from "../Panes/Pane";

interface IEmptyWorkspaceMessageProps {
  hasDocument: boolean;
  documentNameSingular?: string;
  documentNamePlural?: string;
  className?: string;
  addNewButtonEl?: JSX.Element;
  onAddNewDocument?(): void;
}

const EmptyWorkspaceMessage = (props: IEmptyWorkspaceMessageProps): JSX.Element => {
  const renderAction = () => {
    if (props.addNewButtonEl) {
      return props.addNewButtonEl;
    } else if (props.onAddNewDocument) {
      return (
        <Button
          icon="plus"
          text={`Create a new ${props.documentNameSingular}`}
          onClick={props.onAddNewDocument}
          e2eIdentifiers="add-new-document"
        />
      );
    }
  };

  const documentNamePlural = props.documentNamePlural || `${props.documentNameSingular}s`;

  return (
    <Pane className={props.className}>
      {!props.hasDocument && (
        <NonIdealState
          title={`This workspace doesn't have any ${documentNamePlural}`}
          description="Create a new one to get started."
          action={renderAction()}
        />
      )}
    </Pane>
  );
};

export default observer(EmptyWorkspaceMessage);
