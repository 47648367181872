import { AxiosResponse } from "axios";

import { CreateReportDto, Report, ReportUpdateDto } from "../models/reports";

import { HttpClient, ParentClient } from "./client";

export class Reports extends HttpClient {
  public constructor(parent: ParentClient) {
    super(parent);
  }

  private get endpoint() {
    return `workspace/${this.parent.workspaceId}/reports`;
  }

  public create = (dto: CreateReportDto) => {
    return this.instance.post<Report>(this.endpoint, dto);
  };

  public list = (): Promise<AxiosResponse<Report[]>> => {
    return this.instance.get<Report[]>("reports");
  };

  public update = (id: string, dto: ReportUpdateDto) => {
    return this.instance.patch<Report>(`${this.endpoint}/${id}`, dto);
  };

  public delete = (id: string) => {
    return this.instance.delete<Report>(`${this.endpoint}/${id}`);
  };

  public reorder = (id: string, destinationId: string, parentReport?: string | null) => {
    return this.instance.patch(`${this.endpoint}/${id}/reorder`, { destinationId, parentReport });
  };

  public reparent = (id: string, parentReportId: string, orderIndex?: number) => {
    const dto = { parentReport: parentReportId, orderIndex };
    return this.instance.patch(`${this.endpoint}/${id}/reparent`, dto);
  };
}
