import { Tag } from "@blueprintjs/core";
import { observer } from "mobx-react";

import { Button } from "@components/Button";
import AttachmentCell from "@components/Imports/Cells/AttachmentCell";
import appStore from "@store/AppStore";
import { IImport } from "@store/ImportStore";
import { getFormattedDate } from "@utilities";

import styles from "./ImportRow.module.scss";

type Props = {
  importEntity: IImport;
  onLogsView: (importEntity: IImport) => void;
};

const ImportRow = (props: Props) => {
  const { importEntity, onLogsView } = props;

  const viewLogs = () => onLogsView(importEntity);

  return (
    <tr key={importEntity.id}>
      <td>{importEntity.type}</td>
      <td>{appStore.orgModel.info.userWithId(importEntity.createdBy)?.displayName}</td>
      <td>{getFormattedDate(importEntity.createdAt)}</td>
      <td>
        <Button large className={styles.importRowLink} minimal e2eIdentifiers="view-logs" onClick={viewLogs}>
          View logs
        </Button>
      </td>
      <AttachmentCell attachmentId={importEntity.attachmentId} />
      <td>
        <Tag className={styles.importRowTag} round large intent={importEntity.statusIntent}>
          {importEntity.statusLabel}
        </Tag>
      </td>
    </tr>
  );
};

export default observer(ImportRow);
