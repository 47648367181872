import { CustomCellEditorProps } from "ag-grid-react";
import { observer } from "mobx-react";

import SimpleTextCellEditor from "@components/Table/CellEditors/SimpleTextCellEditor";
import { BomTableNode, IBlock } from "@store/BlockStore";

import styles from "./BomTableTextCellEditor.module.scss";

interface IBomTableTextCellEditorProps extends CustomCellEditorProps<BomTableNode, string> {
  getValue(block: IBlock): string;
}

const BomTableTextCellEditor = (props: IBomTableTextCellEditorProps) => {
  const { getValue, api, node, onValueChange } = props;
  const block = props.node.data?.block;

  if (!block) {
    return "";
  }

  const handleBlur = () => api.stopEditing();

  return (
    <SimpleTextCellEditor<BomTableNode>
      className={styles.bomTableTextCellEditor}
      singleLine
      defaultValue={getValue(block)}
      api={api}
      node={node}
      onBlur={handleBlur}
      onValueChange={onValueChange}
    />
  );
};

export default observer(BomTableTextCellEditor);
