import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useWorkspace } from "@hooks/useWorkspace";
import { observer } from "mobx-react";

import { FeatureRightSidebar, IFeatureSidePanel } from "@components/FeatureRightSidebar";
import { RequirementsRightSidePanels } from "@components/Requirements/RequirementsRightSidePanels";
import appStore from "@store/AppStore";
import { ReqSidePanel } from "@store/SidePanelStore";
import { getRequirementBlockById } from "@utilities";

import RequirementsPage from "./Page/RequirementsPage";

import "./RequirementsFrame.scss";
import styles from "./RequirementsFrame.module.scss";

const getSidePanels = (): IFeatureSidePanel<ReqSidePanel>[] => [
  { key: ReqSidePanel.RequirementDetails, icon: "detection", label: "Requirement Details" },
  { key: ReqSidePanel.Comments, icon: "comment", label: "Comments" },
];

function RequirementsFrame() {
  const { requirementId } = useParams();
  const workspace = useWorkspace();
  const reqPage = workspace.requirementsPages.get(requirementId || "");
  const { activeReqBlockId } = appStore.env;
  const { activeReqSidePanel, pinnedReqSidePanels, setReqSidePanel, hideReqSidePanel, togglePinnedReqSidePanel } = appStore.env.sidePanel;
  const activeReqBlock = activeReqBlockId ? getRequirementBlockById(activeReqBlockId) : undefined;

  useEffect(() => {
    reqPage?.fetchReqBlockComments();
  }, [reqPage]);

  useEffect(() => {
    if (reqPage) {
      appStore.env.setActiveRequirementsDocument(reqPage.id);
    }
  }, [reqPage]);

  if (!reqPage) {
    return null;
  }

  return (
    <div className={styles.requirementsFrame}>
      <RequirementsPage reqPage={reqPage} />
      <RequirementsRightSidePanels
        commentFeed={reqPage.commentFeed}
        activeSidePanel={activeReqSidePanel}
        activeReqBlock={activeReqBlock}
        childrenIds={reqPage.validatedBlockIds}
        onClearCommentHistory={reqPage.clearCommentHistory}
      />
      <FeatureRightSidebar<ReqSidePanel>
        activeSidePanelKey={activeReqSidePanel}
        pinnedSidePanelKeys={pinnedReqSidePanels}
        sidePanels={getSidePanels()}
        onTogglePinnedSidePanel={togglePinnedReqSidePanel}
        onChangeActiveSidePanel={setReqSidePanel}
        onHideSidePanel={hideReqSidePanel}
      />
    </div>
  );
}

/** Exports. */
export default observer(RequirementsFrame);
