import ImportsFrame from "@components/Imports/ImportsFrame";
import Pane from "@router/components/Panes/Pane";

export const IMPORT_PANE = "IMPORT_PANE";

/** Main function. */
function ImportsIndex() {
  return (
    <Pane className="component--import-index" id={IMPORT_PANE}>
      <ImportsFrame />
    </Pane>
  );
}

/** Exports. */
export default ImportsIndex;
