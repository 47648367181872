export enum Color {
  Default = "Default",
  Blue = "Blue",
  Cerulean = "Cerulean",
  Forest = "Forest",
  Gold = "Gold",
  Gray = "Gray",
  Green = "Green",
  Indigo = "Indigo",
  Lime = "Lime",
  Orange = "Orange",
  Red = "Red",
  Rose = "Rose",
  Turquoise = "Turquoise",
  Vermilion = "Vermilion",
  Violet = "Violet",
}

export const allColors = Object.values(Color);
