import { useState } from "react";
import { Intent } from "@blueprintjs/core";
import { SubscriptionQOFactory } from "@queries/SubscriptionQOFactory";
import { useMutation } from "@tanstack/react-query";

import { Button } from "@components/Button";
import DialogSimple from "@components/Dialog/DialogSimple";
import { FormGroup } from "@components/FormGroup";
import { TextArea } from "@components/TextArea";

interface ITrialExtensionDialogProps {
  onClose(): void;
}

export const TrialExtensionDialog = (props: ITrialExtensionDialogProps) => {
  const { onClose } = props;
  const [reason, setReason] = useState("");
  const requestTrialExtensionMutation = useMutation(SubscriptionQOFactory.createRequestTrialExtensionQO());

  const handleConfirm = () => {
    requestTrialExtensionMutation.mutate(reason);
    onClose();
  };

  const renderCancelButton = () => (
    <Button key="Cancel" onClick={onClose} e2eIdentifiers="cancel" minimal outlined>
      Cancel
    </Button>
  );

  const renderContinueButton = () => (
    <Button key="Confirm" onClick={handleConfirm} e2eIdentifiers="confirm" intent={Intent.PRIMARY} disabled={!reason}>
      Confirm
    </Button>
  );

  return (
    <DialogSimple
      title="Request for a trial extension"
      footerButtons={[renderCancelButton(), renderContinueButton()]}
      onClose={onClose}
      isOpen
    >
      <FormGroup label="Please prvoide a reason for the request">
        <TextArea value={reason} onChange={e => setReason(e.target.value)} autoFocus />
      </FormGroup>
    </DialogSimple>
  );
};
