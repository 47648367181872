import { GridApi, ICellRendererParams } from "ag-grid-community";

import { IBlock } from "@store/BlockStore";

export type TCellRendererProps = ICellRendererParams<NodeInfo>;

export type TConfigDataObject = { sortIndex: number; hide: boolean; colId: string };

export type TConfigDataObjects = TConfigDataObject[];

export type TConfigJson = {
  data: TConfigDataObjects;
};

export enum EMoveType {
  RE_PARENT = "RE_PARENT",
  RE_ORDER = "RE_ORDER",
}

export interface NodeInfo {
  block: IBlock | null;
  path: string[];
}

export type TableViewGridApi = GridApi<NodeInfo>;
