import { Menu } from "@blueprintjs/core";
import { observer } from "mobx-react";

import { disabledTooltip } from "@components/CatalogItems/constants";
import { CellDropdown } from "@components/CellDropdown";
import { LeadPeriod, leadPeriodLabel } from "@components/CreateCatalogItemDialog/types";
import { MenuItem } from "@components/MenuItem";
import appStore from "@store/AppStore";
import { withObservedValueGetter } from "@utilities/AgGridUtils";

import { TCellRendererProps } from "../types";

import styles from "./LeadTimePeriodCell.module.scss";

const LeadTimePeriodCell = (props: TCellRendererProps) => {
  const rowEditProgress = appStore.ui.pdmItemVersionProgress;
  const disabled = rowEditProgress && props.data?.catalogItem?.id !== rowEditProgress.catalogItemId;
  const catalogItem = props.data?.catalogItem;
  const period = props.value as LeadPeriod | undefined;

  if (!catalogItem) {
    return null;
  }

  const valueChanged = rowEditProgress?.leadTime && rowEditProgress.leadTime !== catalogItem.leadTime;
  const isEdited = !disabled && valueChanged;

  const handleChangeUnit = (unit: string) => {
    props.setValue?.(unit);
  };

  const getMenuContent = () => (
    <Menu>
      {Object.values(LeadPeriod).map(p => (
        <MenuItem key={p} text={leadPeriodLabel[p]} active={period === p} onClick={() => handleChangeUnit(p)} e2eIdentifiers={p} />
      ))}
    </Menu>
  );

  return (
    <CellDropdown
      disabled={!!disabled}
      tooltip={disabled ? disabledTooltip : undefined}
      buttonClassName={styles.leadTimePeriodCell}
      className={isEdited ? styles.leadTimePeriodCellEdited : ""}
      text={period ? `${leadPeriodLabel[period]}s` : "Period"}
      content={getMenuContent()}
      e2eIdentifiers="catalog-item-weight-unit"
    />
  );
};

export default withObservedValueGetter(observer(LeadTimePeriodCell));
