import { MouseEvent } from "react";
import classNames from "classnames";
import { observer } from "mobx-react";

import RollupIcon from "@assets/icons/rollup.svg?react";
import { AnchorButton } from "@components/AnchorButton";
import { useAppNavigate } from "@router/hooks";
import { IIntegration } from "@store/IntegrationStore";
import { trackSegmentEvent } from "src/lib/Segment";
import Text from "src/ui/Text";
import { TextVariant } from "src/ui/Text";

import "./IntegrationCard.scss";

export type IntegrationCardProps = {
  integration: IIntegration;
};

const IntegrationCard = (props: IntegrationCardProps) => {
  const { navigateToIntegrationOverview, navigateToIntegrationConfig } = useAppNavigate();
  const { integration } = props;

  const handleCardClick = () => {
    if (integration.isActive) {
      navigateToIntegrationOverview(integration.id);
      trackSegmentEvent("integration:tile-click", { integration: integration.name });
    }
  };

  const renderCardType = (type: string) => {
    if (type === "Internal") {
      return (
        <>
          <RollupIcon />
          <span>Rollup Native</span>
        </>
      );
    }

    return type;
  };

  const handleButtonClick = (e: MouseEvent) => {
    e.stopPropagation();

    if (integration.isConnected) {
      trackSegmentEvent("integration:configure", { integration: integration.name });
      navigateToIntegrationConfig(integration.id);
    } else {
      integration.connect();
    }
  };

  const getButtonLabel = () => {
    if (!integration.isActive) {
      return "Coming soon";
    } else if (integration.isConnected) {
      return "Configure";
    }

    return integration.ctaText || "Connect";
  };

  return (
    <div className={classNames("integration-card", { ["integration-card--disabled"]: !integration.isActive })} onClick={handleCardClick}>
      <div className="integration-card--body">
        <div className="integration-card--header">
          {integration.logoUrl ? (
            <img width={32} height={32} src={integration.logoUrl} alt={integration.name} />
          ) : (
            <div className="integration-card--img-mock" />
          )}
          <div className="integration-card--types">
            {integration.types?.map(type => (
              <span
                className={classNames("integration-card--type", { ["integration-card--type-internal"]: type === "Internal" })}
                key={type}
              >
                {renderCardType(type)}
              </span>
            ))}
          </div>
        </div>
        <Text variant={TextVariant.H3}>{integration.name}</Text>
        <Text variant={TextVariant.Caption} clamp={3}>
          {integration.summary}
        </Text>
      </div>
      <div className="integration-card--footer">
        <AnchorButton
          target="_blank"
          onClick={handleButtonClick}
          className="integration-card--btn"
          disabled={!integration.isActive}
          intent={integration.buttonIntent}
          e2eIdentifiers={getButtonLabel()}
        >
          {getButtonLabel()}
        </AnchorButton>
      </div>
    </div>
  );
};

export default observer(IntegrationCard);
