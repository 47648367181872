import { Colors, IconName } from "@blueprintjs/core";

import { EFileUploadStatus } from "../store/UploadStore";

export const MAX_UPLOAD_CHUNKS = 20;
export const MAX_PARALLEL_UPLOADS = 10;
export const ONE_MB_IN_BYTES = 1024 * 1024;
export const S3_CHUNK_MIN_UPLOAD_SIZE = ONE_MB_IN_BYTES * 5;
export const ALLOWED_FILE_UPLOAD_SIZE = +import.meta.env.VITE_ALLOWED_FILE_UPLOAD_SIZE || 2048 * ONE_MB_IN_BYTES; // 2048MB

export const getFileChunksAmount = (fileSize: number): number => {
  if (fileSize < S3_CHUNK_MIN_UPLOAD_SIZE * 2) {
    return 1;
  }

  if (fileSize > S3_CHUNK_MIN_UPLOAD_SIZE * MAX_UPLOAD_CHUNKS) {
    return MAX_UPLOAD_CHUNKS;
  }

  return Math.floor(fileSize / S3_CHUNK_MIN_UPLOAD_SIZE);
};

export const getFileUploadMessage = (status: EFileUploadStatus) => {
  switch (status) {
    case EFileUploadStatus.UPLOADED:
      return "Uploaded";
    case EFileUploadStatus.PAUSED:
      return "Paused";
    case EFileUploadStatus.FAILED:
      return "Upload failed";
    case EFileUploadStatus.UPLOADING:
      return "Uploading...";
    case EFileUploadStatus.INITIALIZING:
      return "Initializing...";
    case EFileUploadStatus.FINALIZING:
      return "Finalizing...";
    default:
      return "Queued...";
  }
};

export const getFileUploadIcon = (status: EFileUploadStatus): IconName | undefined => {
  switch (status) {
    case EFileUploadStatus.UPLOADED:
      return "confirm";
    case EFileUploadStatus.PAUSED:
      return "pause";
    case EFileUploadStatus.FAILED:
      return "issue";
    default:
      return undefined;
  }
};

export const getFileUploadIconColor = (status: EFileUploadStatus): string | undefined => {
  switch (status) {
    case EFileUploadStatus.UPLOADED:
      return Colors.GREEN4;
    case EFileUploadStatus.FAILED:
      return Colors.RED3;
    default:
      return undefined;
  }
};

export const showFileBrowser = (allowedFormats?: string[]): Promise<File | null | undefined> => {
  const inputElement = document.createElement("input");
  inputElement.type = "file";
  inputElement.multiple = false;
  if (allowedFormats?.length) {
    inputElement.accept = allowedFormats.join(", ");
  }
  inputElement.click();
  return new Promise((resolve, reject) => {
    inputElement.onchange = () => {
      resolve(inputElement.files?.[0]);
    };
    inputElement.oncancel = reject;
  });
};

export const validateFiles = (
  files: FileList,
  allowedFormats: string[],
  allowedSize: number,
  onSizeError: (file: File, size: number) => void,
  onFormatError: (file: File) => void
): FileList => {
  const allowedFiles = new DataTransfer();

  Array.from(files).forEach((file: File) => {
    let isValidFormat = true;
    let isValidSize = true;

    if (allowedFormats.length) {
      const format = file.name.split(".").pop();
      if (!format || !allowedFormats.join(", ").includes(format.toLowerCase())) {
        isValidFormat = false;
        onFormatError(file);
      }
    }

    if (file.size > allowedSize) {
      isValidSize = false;
      onSizeError(file, allowedSize);
    }

    if (isValidFormat && isValidSize) {
      allowedFiles.items.add(file);
    }
  });

  return allowedFiles.files;
};
