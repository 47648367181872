import { Extension } from "@tiptap/core";

import checkboxListItemInputRule from "../InputRules/CheckboxListItem";
import orderedListItemInputRule from "../InputRules/OrderedListItem";
import quoteInputRule from "../InputRules/Quote";
import unorderedListItemInputRule from "../InputRules/UnorderedListItem";

const warning =
  "RollupInputRules extension will not work without passed `reportBlockId` property, use RollupInputRules.configure({ reportBlockId })";

export const RollupInputRules = Extension.create<{ reportBlockId: string }>({
  name: "rollupInputRules",
  onCreate() {
    if (!this.options.reportBlockId) {
      console.warn(warning);
    }
  },
  addOptions() {
    return {
      reportBlockId: "",
    };
  },
  addInputRules() {
    return [
      checkboxListItemInputRule(this.options.reportBlockId),
      quoteInputRule(this.options.reportBlockId),
      orderedListItemInputRule(this.options.reportBlockId),
      unorderedListItemInputRule(this.options.reportBlockId),
    ];
  },
});
