import { ReactElement, useEffect, useState } from "react";
import { ButtonGroup, H3 } from "@blueprintjs/core";
import { observer } from "mobx-react";
import moment from "moment/moment";

import { AnchorButton } from "@components/AnchorButton";
import AnchorLink from "@components/AnchorLink";
import { ListEntry } from "@components/Shared";
import User from "@components/User";
import { OpenGraphSummary } from "@rollup-api/models/cloudStorage/OpenGraphResponseModel";
import { GitHubRepository } from "@rollup-api/models/integrations";
import appStore from "@store/AppStore";
import { IAttachment } from "@store/AttachmentStore";

import { getLinkAttachmentDetails, isAttachmentGithubRepository, isAttachmentRegularLink, parseOpenGraphData } from "../utils";

import AttachmentDetailsDialog from "./AttachmentDetailsDialog";
import AttachmentDetailsImagePreview from "./AttachmentDetailsImagePreview";
import AttachmentDetailsLoading from "./AttachmentDetailsLoading";
import AttachmentDetailsPanel from "./AttachmentDetailsPanel";

interface AttachmentDetailsProps {
  attachment: IAttachment;
  isOpen: boolean;
  onClose: () => void;
}

const AttachmentDetailsUrl = ({ attachment, isOpen, onClose }: AttachmentDetailsProps) => {
  const [attachedLink, setAttachedLink] = useState<GitHubRepository | IAttachment>();
  const [liveLinkDetails, setLiveLinkDetails] = useState<
    {
      label: string;
      value: string | ReactElement;
    }[]
  >([]);
  const [openGraphSummary, setOpenGraphSummary] = useState<OpenGraphSummary>();
  const attachments = appStore.workspaceModel?.attachments;

  // could have been done cleaner, but this is a preparation to the time when we will have
  // live links of not only github integration type
  useEffect(() => {
    const getUrlAttachmentDetails = async () => {
      if (attachment.linkAttachmentUrl && attachments) {
        const attDetails = await getLinkAttachmentDetails(attachment, attachments);
        if (!attDetails) return;
        setAttachedLink(attDetails);
        if (isAttachmentRegularLink(attachment)) {
          const ogSummary = await parseOpenGraphData(attachment);
          setOpenGraphSummary(ogSummary);
        }
      }
    };
    if (isOpen) {
      getUrlAttachmentDetails();
    }
  }, [attachment, attachments, isOpen]);

  useEffect(() => {
    setLiveLinkDetails([]);
    if (attachedLink) {
      const url = attachment.linkAttachmentUrl;
      setLiveLinkDetails(llDetails => [
        ...llDetails,
        { label: "Source", value: url ? <AnchorLink href={url} /> : "" },
        { label: "Created by", value: <User userId={attachment.user?.id} /> },
        { label: "Created at", value: moment(attachment.createdAt).format("D MMMM YY, h:mm a") },
      ]);
      // GitHub repository specific details
      if (isAttachmentGithubRepository(attachment)) {
        const repositoryDetails = attachedLink as GitHubRepository;
        setLiveLinkDetails(llDetails => [
          { label: "Repository name", value: repositoryDetails.name },
          { label: "Description", value: repositoryDetails.description },
          { label: "Stars", value: repositoryDetails.stargazers_count ?? 0 },
          { label: "Open issues", value: repositoryDetails.open_issues_count ?? 0 },
          ...llDetails,
        ]);
      } else if (isAttachmentRegularLink(attachment)) {
        // Regular url specific details
        setLiveLinkDetails(llDetails => [
          { label: "Title", value: openGraphSummary?.title || attachment.name },
          { label: "Description", value: openGraphSummary?.description ?? "" },
          ...llDetails,
        ]);
      }
    }
  }, [attachedLink, attachment, openGraphSummary]);

  const attachmentActions = (
    <ButtonGroup>
      <AnchorButton icon="share" href={attachment.linkAttachmentUrl ?? ""} target="_blank" e2eIdentifiers="open-link">
        Open link
      </AnchorButton>
    </ButtonGroup>
  );
  const getImageSrc = (): string | undefined => {
    if (isAttachmentGithubRepository(attachment)) {
      return (attachedLink as GitHubRepository).repositoryCoverImageUrl;
    } else if (isAttachmentRegularLink(attachment)) {
      return openGraphSummary?.image;
    }
  };

  const renderImage = () => {
    const src = getImageSrc();
    return <AttachmentDetailsImagePreview src={src} icon="link" iconSize={72} action={attachmentActions} />;
  };

  return (
    <AttachmentDetailsDialog label={attachment.label} isOpen={isOpen} onClose={onClose}>
      {!attachedLink ? (
        <AttachmentDetailsLoading />
      ) : (
        <>
          {renderImage()}
          <AttachmentDetailsPanel>
            <H3>Details</H3>
            {liveLinkDetails.map(entry => {
              if (entry.value) {
                return <ListEntry key={entry.label} label={entry.label} value={entry.value} />;
              }
            })}
          </AttachmentDetailsPanel>
        </>
      )}
    </AttachmentDetailsDialog>
  );
};

export default observer(AttachmentDetailsUrl);
