import { Instance, types } from "mobx-state-tree";
import { Socket } from "socket.io-client";

import { Transaction, TransactionEntity } from "@rollup-api/models/transactions";
import { IBlock } from "@store/BlockStore";
import { getBlockById } from "@utilities/Block";
import { getPropertyDefinitionById, getPropertyInstanceById } from "@utilities/Properties";

import appStore from "./AppStore";
import { IUser } from "./UserStore";

export enum EFeedActionType {
  DELETE = "delete",
  CREATE = "create",
  UPDATE = "update",
}

export enum EFeedEntityType {
  BLOCK = "block",
  PROPERTY_DEFINITION = "property-definition",
  PROPERTY_INSTANCE = "property-instance",
  STATUS_DEFINITION = "status-definition",
  STATUS_INSTANCE = "status-instance",
  COMMENT = "comment",
  ATTACHMENT = "cloud-storage",
  CHILD_BLOCK = "child-block",
  REPORT = "report",
  REPORT_BLOCK = "report-block",
}

export const FeedStore = types
  .model("Feed", {
    id: types.identifier,
    method: types.enumeration("EFeedActionType", [...Object.values(EFeedActionType)]),
    referenceType: types.enumeration("EFeedEntityType", [...Object.values(EFeedEntityType)]),
    referenceId: types.string,
    createdBy: types.string,
    createdAt: types.optional(types.number, Date.now()),
    oldEntity: types.maybe(types.frozen<TransactionEntity>()),
    newEntity: types.frozen<TransactionEntity>(),
  })
  .views(self => ({
    get label(): string {
      switch (self.referenceType) {
        case EFeedEntityType.PROPERTY_INSTANCE:
          return getPropertyInstanceById(self.referenceId)?.label ?? "";
        case EFeedEntityType.PROPERTY_DEFINITION:
          return getPropertyDefinitionById(self.referenceId)?.label ?? "";
        case EFeedEntityType.BLOCK:
          return getBlockById(self.referenceId)?.label ?? "";
        default:
          return "";
      }
    },
    get user(): IUser | undefined {
      return appStore.orgModel?.info?.orgMembers.find((user: IUser) => user.id === self.createdBy);
    },
    get actionTitle() {
      switch (self.method) {
        case EFeedActionType.CREATE:
          return "created";
        case EFeedActionType.UPDATE:
          return "updated";
        case EFeedActionType.DELETE:
          return "deleted";
      }
    },
    get entityTitle() {
      switch (self.referenceType) {
        case EFeedEntityType.PROPERTY_INSTANCE:
          return "property instance";
        case EFeedEntityType.PROPERTY_DEFINITION:
          return "property definition";
        case EFeedEntityType.STATUS_INSTANCE:
          return "status instance";
        case EFeedEntityType.BLOCK:
          return "block";
        case EFeedEntityType.CHILD_BLOCK:
          return "child block";
        case EFeedEntityType.ATTACHMENT:
          return "attachment";
        default:
          return "";
      }
    },
    get formattedCreatedAt() {
      return `at ${new Date(self.createdAt).toLocaleDateString("en-us", {
        year: "numeric",
        month: "short",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
      })}`;
    },
  }));

export interface IFeed extends Instance<typeof FeedStore> {}

export const subscribeToBlockFeed = (socket: Socket) => {
  socket.on("createTransaction", (data: { workspaceId: string; transaction: Transaction; parentId: string }) => {
    if (data.transaction.id && data.workspaceId === appStore.workspaceModel?.id) {
      const block: IBlock = appStore.workspaceModel.blockMap.get(data.parentId);
      if (block) {
        block.addFeedItem(data.transaction);
      }
    }
  });
};
