import { useCallback, useEffect, useRef, useState } from "react";
import { Colors, ResizeSensor } from "@blueprintjs/core";
import { useDebouncedCallback } from "@hooks/useDebouncedCallback";
import { useSearchParam } from "@hooks/useSearchParam/useSearchParam";
import { AgGridReact } from "ag-grid-react";
import throttle from "lodash/throttle";
import { observer } from "mobx-react";
import { isAlive } from "mobx-state-tree";

import FloatingFeedbackButton from "@components/FloatingFeedbackButton/FloatingFeedbackButton";
import LinearProgress from "@components/LinearProgress/LinearProgress";
import HoopsContextMenu from "@components/Modeling/ModelingFrame/HoopsViewer/HoopsContextMenu";
import HoopsAnnotationsDisabledMessage from "@components/Modeling/ModelingFrame/HoopsViewer/UI/HoopsAnnotationsDisabledMessage";
import { ContextMenuManager } from "@components/Modeling/ModelingFrame/HoopsViewer/UI/HoopsCustomOperators/RightClickOperator/ContextMenuManager";
import { ContextMenuOperator } from "@components/Modeling/ModelingFrame/HoopsViewer/UI/HoopsCustomOperators/RightClickOperator/ContextMenuOperator";
import SmartAnnotations from "@components/SmartAnnotation/SmartAnnotations";
import { expandRowNodePath } from "@components/Table/utils";
import { showApiErrorToast } from "@components/UiLayers/toaster";
import { ESCAPE_KEY } from "@constants/keys";
import { AttachmentStatus } from "@rollup-api/models/cloudStorage";
import Pane from "@router/components/Panes/Pane";
import SplitPaneGroup from "@router/components/Panes/SplitPaneGroup";
import appStore from "@store/AppStore";
import { IAttachment } from "@store/AttachmentStore";
import { IBlock } from "@store/BlockStore";
import { HoopsTreePanelState } from "@store/EnvironmentStore";
import { FeatureFlag } from "@store/FeatureFlagsStore";
import { IHoopsNode } from "@store/HoopsViewerStore";
import { IView } from "@store/ViewStore";
import { formatFileSize, hoopsColorFromHex, isHoopsFile } from "@utilities";
import { rollupClient } from "src/core/api";

import { BLOCKS_TREE_COLUMN_ID } from "../Table/TableComponent/constants";

import { AnnotationMarkupManager } from "./UI/HoopsCustomOperators/AnnotationOperator/AnnotationMarkupManager";
import { AnnotationOperator } from "./UI/HoopsCustomOperators/AnnotationOperator/AnnotationOperator";
import { KeyPressListenerOperator } from "./UI/HoopsCustomOperators/KeyPressListenerOperator/KeyPressListenerOperator";
import HoopsIsolatedMessage from "./UI/HoopsIsolatedMessage";
import HoopsMenuBottom from "./UI/HoopsMenuBottom";
import HoopsMenuLeft from "./UI/HoopsMenuLeft";
import { HOOPS_PROGRESS, ProgressKey } from "./UI/HoopsProgressTracker";
import RightPanel from "./UI/RightPanel/RightPanel";
import FloatingExpandHoopsTreeButton from "./FloatingExpandHoopsTreeButton";
import HoopsFloatingPartWindow from "./HoopsFloatingPartWindow";
import HoopsTreeSideBar from "./HoopsTreeSideBar";
import HoopsViewerHeader from "./HoopsViewerHeader";

import "./HoopsViewer.scss";

export interface HoopsViewerProps {
  attachment: IAttachment;
  block?: IBlock;
  onViewerReady?: (viewer: Communicator.WebViewer) => void;
  onModelLoaded?: () => void;
  onGoBack?: () => void;
}

const getBackgroundColor = (): Communicator.Color => {
  const backgroundColorHex = getComputedStyle(document.body).getPropertyValue("--bg-color-level1");
  return hoopsColorFromHex(backgroundColorHex);
};

const selectionColor = hoopsColorFromHex(Colors.BLUE5);
const selectionOutlineColor = hoopsColorFromHex(Colors.BLUE2);

const DEFAULT_PANE_SIZE = 320;

const HoopsViewer = (props: HoopsViewerProps) => {
  const [viewerInstance, setViewerInstance] = useState<Communicator.WebViewer>();
  const [subViewerInstance, setSubViewerInstance] = useState<Communicator.WebViewer>();
  const [isLoading, setIsLoading] = useState(false);
  const [uri, setUri] = useState<string>("");
  const [nodesExtracted, setNodesExtracted] = useState(false);
  const [subNodesExtracted, setSubNodesExtracted] = useState(false);
  const [hoopsReady, setHoopsReady] = useState(false);
  const [externalTasksCompleted, setExternalTasksCompleted] = useState(false);
  const [allTasksCompleted, setAllTasksCompleted] = useState(false);
  const viewerElementRef = useRef<HTMLDivElement>(null);
  const subViewerElementRef = useRef<HTMLDivElement>(null);
  const tableRef = useRef<AgGridReact<IHoopsNode> | null>(null);
  const { loadingTheme } = appStore.env;

  const [progressValue, setProgressValue] = useState<number | undefined>(undefined);
  const [progressMessage, setProgressMessage] = useState("Loading...");
  const [hoopsTreeWidth, setHoopsTreeWidth] = useState(DEFAULT_PANE_SIZE);

  const [nodeIdFromUrlParams] = useSearchParam("nodeId");

  const isFloatingPartWindowEnabled = appStore.env.featureFlags.enabled(FeatureFlag.HOOPS_FLOATING_PART_VIEWER);

  const newProgress = (progressStep: ProgressKey) => {
    setProgressMessage(HOOPS_PROGRESS[progressStep]);
  };

  const newProgressManual = (message: string, value: number) => {
    setProgressValue(value);
    setProgressMessage(message);
  };

  // On mount, get the download link for the attachment and check if it is a valid HOOPS model. If so, set the URI to the download link.
  useEffect(() => {
    if (!viewerElementRef.current || (isFloatingPartWindowEnabled && !subViewerElementRef.current)) return;

    newProgress("URI_FETCH_BEGIN");
    const fetchUri = async (attachment: IAttachment) => {
      try {
        const res = await rollupClient.attachments.generateDownloadLink(
          attachment.id,
          attachment.status === AttachmentStatus.Converted,
          attachment.workspaceId
        );
        if (res.data.url) {
          const isValid = await isHoopsFile(res.data.url);
          if (isValid) {
            setUri(res.data.url);
            newProgress("URI_FETCHED");
            return;
          } else {
            console.error("URL does not point to a valid HOOPS model");
          }
        }
      } catch (err) {
        console.error(err);
      }
      showApiErrorToast("Error fetching model: URL does not point to a compatible model");
      appStore.env.clearActiveAttachment();
    };
    fetchUri(props.attachment);

    return () => {
      appStore.ui.hideHoopsDebugDialog();
    };
  }, [props.attachment, isFloatingPartWindowEnabled]);

  // Once we have the URI, initialize the viewer
  useEffect(() => {
    if (!uri || !viewerElementRef.current || (isFloatingPartWindowEnabled && !subViewerElementRef.current)) return;

    newProgress("VIEWER_INIT_BEGIN");
    const viewer = new Communicator.WebViewer({
      endpointUri: uri,
      container: viewerElementRef.current,
    });
    let subViewer: Communicator.WebViewer | undefined;
    if (isFloatingPartWindowEnabled && subViewerElementRef.current) {
      subViewer = new Communicator.WebViewer({
        endpointUri: uri,
        container: subViewerElementRef.current,
      });
    }
    newProgress("VIEWER_INIT_END");

    setIsLoading(true);
    setViewerInstance(viewer);
    if (isFloatingPartWindowEnabled) {
      setSubViewerInstance(subViewer);
    }

    // Clean-up
    // TODO!: Application crashes when clicked on an attachment and then immediately clicked on the root block name in the viewer view because of this clean-up. Without this clean-up, we have a memory leak, so this clean-up cannot be removed.
    return () => {
      if (viewer !== undefined) {
        viewer.shutdown();
        setViewerInstance(undefined);
      }
      if (isFloatingPartWindowEnabled && subViewer !== undefined) {
        subViewer.shutdown();
        setSubViewerInstance(undefined);
      }
      appStore.env.clearActiveAttachment();
    };
  }, [uri, isFloatingPartWindowEnabled]);

  // Once we have the viewer initialized, set the callbacks
  useEffect(() => {
    if (!viewerInstance || (isFloatingPartWindowEnabled && !subViewerInstance)) return;

    newProgress("VIEWER_SETUP_BEGIN");
    viewerInstance.setCallbacks({
      fetchOnProgress: throttle((progress: ProgressEvent) => {
        const completedRatio = progress.loaded / progress.total;
        newProgressManual(`Fetching attachment ${formatFileSize(progress.loaded)} / ${formatFileSize(progress.total)}`, completedRatio);
      }, 100),
      sceneReady: () => {
        // Default settings for HOOPS viewer
        viewerInstance.view.setSilhouetteEnabled(true);
        viewerInstance.view.setAmbientOcclusionEnabled(true);
        viewerInstance.view.setDrawMode(Communicator.DrawMode.WireframeOnShaded);
        viewerInstance.view.setAmbientOcclusionRadius(0.02);
        viewerInstance.view.setAntiAliasingMode(Communicator.AntiAliasingMode.SMAA);
        const backgroundColor = getBackgroundColor();
        viewerInstance.view.setBackgroundColor(backgroundColor, backgroundColor);
        viewerInstance.selectionManager.setNodeSelectionColor(selectionColor);
        viewerInstance.selectionManager.setNodeSelectionOutlineColor(selectionOutlineColor);
        viewerInstance.selectionManager.setNodeElementSelectionColor(selectionOutlineColor);
        viewerInstance.selectionManager.setNodeElementSelectionOutlineColor(selectionOutlineColor);
        viewerInstance.selectionManager.setNodeElementSelectionHighlightMode(Communicator.SelectionHighlightMode.OutlineOnly);
        appStore.env.setAttachmentViewer(viewerInstance);
        const canvas = viewerInstance.getViewElement();
        /**
         * This line is equivalent to canvas.focus()
         */
        viewerInstance.focusInput(true);
        canvas.addEventListener("mouseenter", function () {
          viewerInstance.focusInput(true);
        });
        newProgress("SCENE_READY");
      },
      modelStructureReady: () => {
        newProgress("MODEL_STRUCTURE_READY");
        setIsLoading(false);
        if (appStore.env.attachmentViewer) {
          newProgress("EXTRACT_NODES_BEGIN");
          const updateSuccessful = appStore.env.attachmentViewer.updateTree();
          setNodesExtracted(updateSuccessful);
          newProgress("EXTRACT_NODES_END");
        }

        newProgress("REGISTERING_CUSTOM_OPERATORS");
        // Smart annotation operator
        const annotationMarkupManager = new AnnotationMarkupManager(viewerInstance, props.attachment);
        const annotationOperator = new AnnotationOperator(viewerInstance, annotationMarkupManager);
        const annotationOperatorId: number = viewerInstance.operatorManager.registerCustomOperator(annotationOperator);
        appStore.env.attachmentViewer?.addCustomOperator(annotationOperatorId, annotationOperator.getOperatorName());
        // Key press operator
        const keyPressOperator = new KeyPressListenerOperator(viewerInstance);
        const keyPressOperatorId: number = viewerInstance.operatorManager.registerCustomOperator(keyPressOperator);
        appStore.env.attachmentViewer?.addCustomOperator(keyPressOperatorId, keyPressOperator.getOperatorName());
        // Right click operator
        const contextMenuManager = new ContextMenuManager(appStore.env.attachmentViewer!);
        const contextMenuOperator = new ContextMenuOperator(viewerInstance, contextMenuManager);
        const contextMenuOperatorId: number = viewerInstance.operatorManager.registerCustomOperator(contextMenuOperator);
        appStore.env.attachmentViewer?.addCustomOperator(contextMenuOperatorId, contextMenuOperator.getOperatorName());
        newProgress("CUSTOM_OPERATORS_REGISTERED");

        // reduce the number of standard operators to improve performance (especially when moving around with annotations)
        // TODO: explore further performance improvements via removing selection operator when navigating, and vice versa etc.
        appStore.env.attachmentViewer?.setStandardOperatorIds([
          Communicator.OperatorId.Navigate,
          Communicator.OperatorId.Select,
          Communicator.OperatorId.Cutting,
          contextMenuOperatorId,
          Communicator.OperatorId.NavCube,
          Communicator.OperatorId.AxisTriad,
        ]);
        viewerInstance.operatorManager.clear();
        appStore.env.attachmentViewer?.standardOperatorIds.forEach(op => viewerInstance.operatorManager.push(op));
      },
      selectionArray: (selectionEvents: Communicator.Event.NodeSelectionEvent[]) => {
        if (selectionEvents?.length) {
          const nodeId = selectionEvents[0].getSelection().getNodeId();
          // Clicked on a node surface or a node edge
          if (nodeId) {
            appStore.env.attachmentViewer?.selectNodeById(nodeId);
            const clickOnItem = selectionEvents.at(0)?.getSelection().getFaceEntity();
            // Clicked on a node surface
            if (clickOnItem) {
              const rowNode = tableRef.current?.api?.getRowNode(`${nodeId}`);
              expandRowNodePath<IHoopsNode>(rowNode);
              rowNode && rowNode.setSelected(true);
              setTimeout(() => {
                tableRef.current?.api?.setFocusedCell(rowNode?.rowIndex || 0, BLOCKS_TREE_COLUMN_ID);
                rowNode && tableRef.current?.api?.ensureNodeVisible(rowNode);
              }, 100);
            }
          }
        } else {
          // appStore.env.attachmentViewer?.clearSelectedNode();
        }
      },
      beginInteraction: () => {
        // User may have pressed a preset view button, and then started moving/rotating the model. Now, the model is no longer in the preset view, so update the state accordingly so that the preset view button is no longer highlighted, etc.
        appStore.env.attachmentViewer?.setViewOrientation(-1);
        appStore.env.attachmentViewer?.setActiveSavedViewId(null);
      },
      explode(magnitude) {
        appStore.env.attachmentViewer?.setIsExplodeActive(magnitude > 0);
      },
      // Below are added for measuring progress, other then that, they are not used (currently)
      assemblyTreeReady() {
        newProgress("ASSEMBLY_TREE_READY");
      },
      cadViewCreated() {
        newProgress("CAD_VIEW_CREATED");
      },
      configurationActivated() {
        newProgress("CONFIGURATION_ACTIVATED");
      },
      firstModelLoaded() {
        newProgress("FIRST_MODEL_LOADED");
      },
      modelLoadBegin() {
        newProgress("MODEL_LOAD_BEGIN");
      },
      subtreeLoaded() {
        newProgress("SUBTREE_LOADED");
      },
      modelStructureHeaderParsed() {
        newProgress("MODEL_STRUCTURE_HEADER_PARSED");
      },
      streamingActivated() {
        newProgress("STREAMING_ACTIVATED");
      },
      streamingDeactivated() {
        newProgress("STREAMING_DEACTIVATED");
      },
      viewCreated() {
        newProgress("VIEW_CREATED");
      },
      viewLoaded() {
        newProgress("VIEW_LOADED");
      },
    });

    if (isFloatingPartWindowEnabled && subViewerInstance) {
      subViewerInstance.setCallbacks({
        sceneReady: () => {
          // Default settings for HOOPS viewer
          subViewerInstance.view.setSilhouetteEnabled(true);
          subViewerInstance.view.setAmbientOcclusionEnabled(true);
          subViewerInstance.view.setDrawMode(Communicator.DrawMode.WireframeOnShaded);
          subViewerInstance.view.setAmbientOcclusionRadius(0.02);
          subViewerInstance.view.setAntiAliasingMode(Communicator.AntiAliasingMode.SMAA);
          const backgroundColor = getBackgroundColor();
          subViewerInstance.view.setBackgroundColor(backgroundColor, backgroundColor);
          subViewerInstance.selectionManager.setNodeSelectionColor(selectionColor);
          subViewerInstance.selectionManager.setNodeSelectionOutlineColor(selectionOutlineColor);
          subViewerInstance.selectionManager.setNodeElementSelectionColor(selectionOutlineColor);
          subViewerInstance.selectionManager.setNodeElementSelectionOutlineColor(selectionOutlineColor);
          subViewerInstance.selectionManager.setNodeElementSelectionHighlightMode(Communicator.SelectionHighlightMode.OutlineOnly);
          appStore.env.setAttachmentSubViewer(subViewerInstance);
        },
        modelStructureReady: () => {
          setIsLoading(false);
          if (appStore.env.attachmentSubViewer) {
            const updateSuccessful = appStore.env.attachmentSubViewer.updateTree();
            setSubNodesExtracted(updateSuccessful);
          }
        },
      });
    }

    newProgress("VIEWER_SETUP_END");

    // Start the viewer
    newProgress("VIEWER_START_BEGIN");
    viewerInstance.start();
    if (isFloatingPartWindowEnabled && subViewerInstance) {
      subViewerInstance.start();
    }
  }, [viewerInstance, subViewerInstance, props.attachment, isFloatingPartWindowEnabled]);

  // Once everything is ready, set hoopsReady to true
  useEffect(() => {
    // Add 400ms delay so that the loading bar full will be visible (400ms because the progress bar is animated)
    let timer: NodeJS.Timeout;
    if (
      !isLoading &&
      !!viewerInstance &&
      // TODO: The commented out items in this condition are related to the subviewer. Move the subviewer to it's own fully fledged component.
      // !!subViewerInstance &&
      uri !== "" &&
      !!appStore.env.attachmentViewer &&
      isAlive(appStore.env.attachmentViewer) &&
      // !!appStore.env.attachmentSubViewer &&
      // !!isAlive(appStore.env.attachmentSubViewer) &&
      nodesExtracted
      // && subNodesExtracted
    ) {
      newProgress("HOOPS_READY");
      timer = setTimeout(() => {
        setHoopsReady(true);
      }, 400);
    }

    return () => clearTimeout(timer);
  }, [uri, viewerInstance, subViewerInstance, isLoading, nodesExtracted, subNodesExtracted]);

  // Limit canvas resize to every 50 ms
  const resizeCanvas = useCallback(() => viewerInstance?.resizeCanvas(), [viewerInstance]);
  const debouncedResize = useDebouncedCallback(resizeCanvas, 50);

  // Handle theme changes while viewer is already mounted
  useEffect(() => {
    if (viewerInstance && !loadingTheme) {
      const backgroundColor = getBackgroundColor();
      viewerInstance.view.setBackgroundColor(backgroundColor, backgroundColor);
      if (isFloatingPartWindowEnabled && subViewerInstance) {
        subViewerInstance.view.setBackgroundColor(backgroundColor, backgroundColor);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingTheme]);

  // Isolate the selected node in the sub viewer
  // useEffect(() => {
  //   if (appStore.env.attachmentSubViewer && appStore.env.attachmentViewer?.selectedNode) {
  //     // TODO:: below line creates a bug: when you select a node, it tries to isolate it in
  //     // the sub viewer. However, it's also marked as isolated in the main viewer. Once
  //     // the sub viewer is being put into use, we need to fix this bug first. For now, we
  //     // just comment out the line below.
  //     // appStore.env.attachmentSubViewer.subIsolateNode(appStore.env.attachmentViewer.selectedNode);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [appStore.env.attachmentViewer?.selectedNode, appStore.env.attachmentSubViewer]);

  // Handle tasks external to hoops communicator (e.g. annotations)
  useEffect(() => {
    const loadViewsToMarkupManager = async () => {
      // export markups:
      const exportedMarkup = await viewerInstance?.markupManager.exportMarkup();
      if (exportedMarkup) {
        await props.attachment.populateHoopsViews();
        appStore.env.activeAttachment?.views.map((v: IView) => {
          exportedMarkup.views.push(v);
        });
        // Reimport all markups
        await viewerInstance?.markupManager.loadMarkupData(exportedMarkup);
        await viewerInstance?.markupManager.refreshMarkup();
      }
    };
    if (hoopsReady && props.attachment && viewerInstance?.markupManager) {
      newProgress("POPULATING_SMART_ANNOTATIONS");
      props.attachment.annotationList.populate3DAnnotationMarkups();
      newProgress("POPULATING_VIEWS");
      loadViewsToMarkupManager();
      setExternalTasksCompleted(true);

      viewerInstance?.view.getAxisTriad().enable();
      viewerInstance?.view.getNavCube().enable();
    }
  }, [hoopsReady, props.attachment, viewerInstance?.markupManager, viewerInstance?.view]);

  // Everything that needs to be done once all external tasks are completed
  useEffect(() => {
    if (externalTasksCompleted && hoopsReady) {
      setAllTasksCompleted(true);
    }
  }, [externalTasksCompleted, hoopsReady]);

  // Everything that needs to be done once all tasks are completed
  useEffect(() => {
    let isolateNodeTimer: NodeJS.Timeout;
    if (allTasksCompleted) {
      if (nodeIdFromUrlParams) {
        const nodeToIsolate = appStore.env.attachmentViewer?.getNodeById(parseInt(nodeIdFromUrlParams));
        if (nodeToIsolate) {
          isolateNodeTimer = setTimeout(() => {
            appStore.env.attachmentViewer?.selectNode(nodeToIsolate);
            appStore.env.attachmentViewer?.toggleNodeIsolation(nodeToIsolate);
          }, 0);
        }
      }
    }
    return () => clearTimeout(isolateNodeTimer);
  }, [allTasksCompleted, nodeIdFromUrlParams]);

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === ESCAPE_KEY) {
      appStore.env.attachmentViewer?.showAllNodes();
      e.preventDefault();
      e.stopPropagation();
    }
  };

  useEffect(() => {
    return () => {
      props.attachment.cleanUpViews();
      setViewerInstance(undefined);
      if (isFloatingPartWindowEnabled) {
        setSubViewerInstance(undefined);
      }
      appStore.env.clearActiveAttachment();
    };
  }, [props.attachment, isFloatingPartWindowEnabled]);

  const cursorClass = () => {
    if (appStore.env.attachmentViewer?.isAnnotationModeActive) {
      return "cursor-annotation";
    }
    return undefined;
  };

  const handleHoopsTreeSizeChange = (size: number) => {
    setHoopsTreeWidth(size);
  };

  return (
    <div className="hoops-viewer-container" onKeyDown={handleKeyDown} tabIndex={-1}>
      <div className="hoops-viewer-model-group-and-header">
        <HoopsViewerHeader attachment={props.attachment} block={props.block} onGoBack={props.onGoBack} />
        <div className="hoops-viewer-model-group">
          <FloatingExpandHoopsTreeButton />
          <FloatingFeedbackButton />
          <SplitPaneGroup
            primary="first"
            minSize={220}
            maxSize={520}
            size={appStore.env.hoopsTreePanelState === HoopsTreePanelState.Closed ? 0 : hoopsTreeWidth}
            useCapturing
            hideResizer
            onChange={handleHoopsTreeSizeChange}
          >
            <HoopsTreeSideBar ready={hoopsReady} tableRef={tableRef} />

            <Pane className="hoops-viewer-pane" containerClassName="hoops-viewer-pane-children-container">
              <ResizeSensor onResize={debouncedResize}>
                <div className="hoops-viewer">
                  <div id="HoopsWebViewer" ref={viewerElementRef} className={cursorClass()} />
                  {/* TODO: Below displays an error in the console. I'm leaving it for now until this feature is being implemented. */}
                  {isFloatingPartWindowEnabled && <HoopsFloatingPartWindow ref={subViewerElementRef} />}
                  {hoopsReady && viewerInstance && (
                    <>
                      <HoopsMenuBottom viewer={viewerInstance} renderMode={appStore.env.attachmentViewer?.drawMode} />
                      <HoopsMenuLeft viewer={viewerInstance} />
                      <HoopsIsolatedMessage />
                      <HoopsAnnotationsDisabledMessage />
                    </>
                  )}
                  {allTasksCompleted ? (
                    <>
                      <SmartAnnotations annotations={props.attachment.annotationList.annotations} />
                      <HoopsContextMenu viewerStore={appStore.env.attachmentViewer!} />
                    </>
                  ) : (
                    <div className="progress-bar-container">
                      <LinearProgress value={progressValue} message={progressMessage} animate={progressValue === undefined} />
                    </div>
                  )}
                </div>
              </ResizeSensor>
            </Pane>
          </SplitPaneGroup>
        </div>
      </div>
      <RightPanel attachment={props.attachment} viewer={viewerInstance} />
    </div>
  );
};

export default observer(HoopsViewer);
