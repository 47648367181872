import { Position } from "@blueprintjs/core";
import { DataLink } from "@rollup-io/engineering";
import { observer } from "mobx-react";

import { GoogleSheetsIcon } from "@assets/icons/thirdParty";
import { TitledTooltip } from "@components/Shared";
import CustomIcon from "@components/Shared/LegacyCustomIcon";
import { IntegrationProvider } from "@rollup-api/models";
import { getDataSourceById } from "@utilities/Properties";
import { joinStrings } from "@utilities/TextUtil";

interface IDataLinkTagProps {
  dataLink?: DataLink;
  iconClassName?: string;
  labelClassName?: string;
}

const DataLinkTag = (props: IDataLinkTagProps) => {
  const { dataLink, iconClassName, labelClassName } = props;

  if (!dataLink?.dataSourceId || !dataLink.query) {
    return <div className="data-link-tag warning">Invalid data link</div>;
  }

  const dataSource = getDataSourceById(dataLink.dataSourceId);
  if (!dataSource) {
    return <div className="data-link-tag warning">Invalid data source</div>;
  }

  const tagLabel = `${dataSource.label}:${dataLink.query}`;

  const tooltipMessage = `Value: ${joinStrings(dataLink.value, " ")}`;

  return (
    <TitledTooltip
      placement={Position.BOTTOM}
      title={dataSource.provider === IntegrationProvider.Google ? "Google Sheet" : "Unknown data source"}
      content={tooltipMessage}
    >
      <div className="data-link-tag">
        <CustomIcon className={iconClassName} icon={<GoogleSheetsIcon />} />
        <span className={labelClassName}>{tagLabel}</span>
      </div>
    </TitledTooltip>
  );
};

export default observer(DataLinkTag);
