import { useCallback, useEffect, useMemo, useState } from "react";
import { HTMLTable } from "@blueprintjs/core";
import { Select2 } from "@blueprintjs/select";
import { observer } from "mobx-react";
import csv from "papaparse";

import { Button } from "@components/Button";
import { CELL_WIDTH, excludedBomColumns } from "@components/Imports/Modal/constants";
import { showApiErrorToast } from "@components/UiLayers/toaster";
import { ECsvImportColumn, ImportType, TCsvImportColumnsMap } from "@rollup-api/models/import";
import appStore from "@store/AppStore";
import { IBomTable } from "@store/BomTable/BomTableStore";
import { SelectItem } from "src/ui/Select/Item/SelectItem";
import { Text } from "src/ui/Text";

import "./HeaderPanel.scss";

type Props = {
  file: File | null;
  columnsMap: TCsvImportColumnsMap;
  onChange: (columnsMap: TCsvImportColumnsMap) => void;
  importType: ImportType;
  entityId: string;
};

const handleParsingError = (err: Error) => {
  showApiErrorToast("Error parsing CSV file", err);
};

const HeadersPanel = (props: Props) => {
  const [headers, setHeaders] = useState<string[]>([]);
  const [error, setError] = useState(false);
  const [values, setValues] = useState<Record<string, string>[]>([]);
  const { file, columnsMap, onChange, entityId, importType } = props;

  const availableHeaders = useMemo(() => {
    const defaultHeaders = Object.keys(columnsMap);
    if (importType === ImportType.Block) {
      return defaultHeaders;
    }

    const bomTable: IBomTable | undefined = appStore.workspaceModel?.bomTablesMap.get(entityId);
    return bomTable?.columns.map(c => c.label).filter(c => !excludedBomColumns.includes(c.toLowerCase())) || defaultHeaders;
  }, [columnsMap, entityId, importType]);

  const handleCompleteParsing = useCallback(
    (results: csv.ParseResult<Record<string, string>>) => {
      setHeaders(results.meta.fields || []);
      const existingHeaders = results.meta.fields?.filter(h => availableHeaders.includes(h)) || [];
      const mappedHeaders = existingHeaders.reduce((prev, curr) => ({ ...prev, [curr]: curr }), {});

      if (existingHeaders.length) {
        onChange({ ...columnsMap, ...mappedHeaders });
      }
      if (!results.data.length) {
        setError(true);
      } else {
        setValues(results.data.slice(0, 6));
      }
    },
    [availableHeaders, columnsMap, onChange]
  );

  const parseFile = useCallback(
    (f: File) => {
      csv.parse(f, {
        header: true,
        skipEmptyLines: true,
        complete: handleCompleteParsing,
        error: handleParsingError,
      });
    },
    [handleCompleteParsing]
  );

  useEffect(() => {
    if (file) {
      parseFile(file);
    } else {
      setValues([]);
      setHeaders([]);
    }
  }, [file, parseFile]);

  const setColumnValue = (column: ECsvImportColumn, value: string) => {
    onChange({ ...columnsMap, [column]: value });
  };

  const renderColumnSelect = (h: string) => {
    const column = h as ECsvImportColumn;
    return (
      <td width={CELL_WIDTH} key={h} className="header-panel--select-cell">
        <Select2<string>
          disabled={error}
          filterable={false}
          items={headers}
          popoverContentProps={{ className: "header-panel--select" }}
          popoverProps={{ minimal: true }}
          onItemSelect={value => setColumnValue(column, value)}
          itemRenderer={(item, { handleClick }) => (
            <SelectItem slug={item} key={item} label={item} onClick={handleClick} active={columnsMap[column] === item} />
          )}
        >
          <Button
            fill
            alignText="left"
            text={columnsMap[column] || "Select column"}
            rightIcon="double-caret-vertical"
            e2eIdentifiers={[columnsMap[column] || "Select column"]}
          />
        </Select2>
      </td>
    );
  };

  const renderColumnHeader = (header: string, index: number) => (
    <th key={header}>
      {header}
      {!index && importType === ImportType.Block && "*"}
    </th>
  );

  const renderRow = (value: Record<string, string>, index: number) => {
    if (Object.values(columnsMap).every(v => !v)) {
      return null;
    }

    return (
      <tr key={value.id + index}>
        {availableHeaders.map((header: string) => {
          const column = header as ECsvImportColumn;
          const valueKey = columnsMap[column];

          return (
            <td width={CELL_WIDTH} key={header}>
              {value[valueKey] || ""}
            </td>
          );
        })}
      </tr>
    );
  };

  return (
    <div className="header-panel">
      <Text className="header-panel--title">Select columns to map</Text>
      {error && <p className="header-panel--error">Selected file does not have data, please pick another one.</p>}
      <div className="header-panel--content">
        <HTMLTable className="header-panel--table">
          <thead>
            <tr>{availableHeaders.map(renderColumnHeader)}</tr>
            <tr>{availableHeaders.map(renderColumnSelect)}</tr>
          </thead>
          <tbody>{values.map(renderRow)}</tbody>
        </HTMLTable>
      </div>
    </div>
  );
};

export default observer(HeadersPanel);
