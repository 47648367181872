import { useEffect, useRef } from "react";
import { Menu } from "@blueprintjs/core";
import classNames from "classnames";
import { observer } from "mobx-react";
import { isAlive } from "mobx-state-tree";

import { Button } from "@components/Button";
import { MenuItem } from "@components/MenuItem";
import { MenuItemDelete } from "@components/MenuItems";
import { Popover } from "@components/Popover";
import { CopyLinkMenuItem } from "@components/SmallComponents";
import { TCellRendererProps } from "@components/Table";
import { IRequirementBlock, VerificationStatus } from "@store/RequirementsStore";

import styles from "./RequirementsActionsCell.module.scss";

export interface IRequirementsActionsCellCustomProps {
  disableStatusIndicator?: boolean;
  onDeleteRequirementBlock(block: IRequirementBlock): void;
}

export type IActionCellProps = IRequirementsActionsCellCustomProps & TCellRendererProps<IRequirementBlock>;

const RequirementsActionsCell = (props: IActionCellProps) => {
  const { data: requirementBlock, api, node, disableStatusIndicator, onDeleteRequirementBlock, registerRowDragger } = props;
  const buttonRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    buttonRef.current && registerRowDragger(buttonRef.current);
  }, [registerRowDragger, buttonRef]);

  if (!isAlive(requirementBlock)) {
    return null;
  }

  const handleLockClick = () => {
    requirementBlock.toggleLocked();
    api.redrawRows({ rowNodes: [node] });
  };

  const handleDeleteClick = () => {
    api.applyTransaction({ remove: [{ id: requirementBlock.id }] });
    onDeleteRequirementBlock(requirementBlock);
  };

  const getRequirementMenu = () => (
    <Menu>
      <MenuItem
        icon="lock"
        text={requirementBlock.locked ? "Unlock" : "Lock"}
        onClick={handleLockClick}
        disabled={false}
        e2eIdentifiers="lock"
      />
      <CopyLinkMenuItem node={requirementBlock} />
      <MenuItemDelete
        text="Delete requirement"
        onDelete={handleDeleteClick}
        disabled={requirementBlock.locked}
        tooltip={requirementBlock.locked ? "Requirement is locked" : ""}
      />
    </Menu>
  );

  return (
    <Popover
      className={classNames(styles.requirementsActionsCell, { [styles.requirementsActionsCellLocked]: requirementBlock.locked })}
      content={getRequirementMenu()}
      placement="bottom-start"
    >
      <Button
        className={classNames({
          [styles.requirementsActionsCellPending]:
            requirementBlock.verificationStatus === VerificationStatus.Pending && !disableStatusIndicator,
          [styles.requirementsActionsCellPass]: requirementBlock.verificationStatus === VerificationStatus.Pass && !disableStatusIndicator,
          [styles.requirementsActionsCellFail]: requirementBlock.verificationStatus === VerificationStatus.Fail && !disableStatusIndicator,
        })}
        buttonRef={buttonRef}
        icon="drag-handle-vertical"
        minimal
        small
        disabled={false}
        e2eIdentifiers={["actions-cell", "drag"]}
      />
    </Popover>
  );
};

export default observer(RequirementsActionsCell);
