import { LoaderFunction, redirect } from "react-router-dom";
import { NonIdealState } from "@blueprintjs/core";

import Pane from "@router/components/Panes/Pane";
import appStore from "@store/AppStore";

/** Loader. */
const blockIndexLoader: LoaderFunction = ({ params }) => {
  const { workspaceId } = params;
  const activeBlockId = appStore.env.activeBlock?.id;
  const rootBlock = appStore.workspaceModel?.rootBlockId;

  if (activeBlockId || rootBlock) {
    return redirect(`/workspaces/${workspaceId}/modeling/blocks/${activeBlockId || rootBlock}`);
  }

  return null;
};

/** Main function. */
function BlockIndex() {
  return (
    <Pane className="component--block-index">
      <NonIdealState title="BlockIndex" />
    </Pane>
  );
}

/** Exports. */
export { blockIndexLoader };
export default BlockIndex;
