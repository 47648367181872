export const INTERCOM_APP_ID = import.meta.env.INTERCOM_APP_ID ?? "zknrk9cd";

export const INTERCOM_BUTTON_ID = "intercomLauncher";

export const PROVIDER_MESSENGER_PROPS = {
  customLauncherSelector: `#${INTERCOM_BUTTON_ID}`,
  hideDefaultLauncher: true,
  alignment: "left",
  horizontalPadding: 50,
};
