import { useCallback, useEffect, useState } from "react";
import { Menu, MenuDivider, PopoverPosition } from "@blueprintjs/core";
import { Editor } from "@tiptap/react";
import classNames from "classnames";
import { observer } from "mobx-react";

import { EntityAnnotationListPopover } from "@components/EntityAnnotationListPopover";
import { PresenceList } from "@components/Header/PresenceList/PresenceList";
import { MenuItem } from "@components/MenuItem";
import { MenuItemDelete } from "@components/MenuItems";
import { useEditorHandlers } from "@components/Reports/Editor/hooks";
import ReportBlockConvertMenuItems from "@components/Reports/Page/ReportBlockConvertMenuItems";
import { getAllowedTypes } from "@components/Reports/Page/utils";
import { SortableItemComponent } from "@components/Shared";
import { CopyLinkMenuItem } from "@components/SmallComponents";
import { RollupEditorType } from "@rollup-types/editor";
import appStore from "@store/AppStore";
import { IReportBlock } from "@store/ReportBlockStore";
import { IReport } from "@store/ReportsStore";
import { StoreType } from "@store/types";
import { getReportBlockById } from "@utilities";
import { isDefined } from "@utilities/TypeGuards";

import ReportsEditor from "../Editor";

import DocumentActionsNav from "./DocumentActionsNav";

import "./ReportBlock.scss";

const defaultMargin = 50;
const buttonWidth = 30;

type Props = SortableItemComponent & {
  reportBlock: IReportBlock;
  report: IReport;
  editable?: boolean;
  selected?: boolean;
  onFocus?: () => void;
};

const ReportBlock = (props: Props) => {
  const { reportBlock, selected, dragListeners, onFocus, report } = props;
  const viewers = appStore.workspaceViewers.filter(viewer => viewer.entity === reportBlock);
  const workspace = appStore.workspaceModel;
  const [editor, setEditor] = useState<Editor>();
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const { handleEnterKeyDown, handleBackspaceKeyDown } = useEditorHandlers(reportBlock, report, editor);
  const { handleModEnterKeyDown, handleArrowDownKey, handleArrowUpKey } = useEditorHandlers(reportBlock, report, editor);
  const { handleBlockUpdate, handleShiftEnterKeyDown } = useEditorHandlers(reportBlock, report, editor);
  const reportBlocks = report.reportBlocks.map(getReportBlockById).filter(isDefined);

  const handleFocus = () => {
    appStore.env.setActiveReportBlock(reportBlock.id);
    onFocus?.();
  };

  const handleBlur = () => {
    appStore.env.clearActiveReportBlock();
  };

  const { handlePaste } = useEditorHandlers(reportBlock, report, editor, workspace?.id);

  const handleEditorReady = useCallback(
    (editor: Editor | null) => {
      if (editor) {
        appStore.env.editors.set(reportBlock.id, editor);
        setEditor(editor);
      }
    },
    [reportBlock.id]
  );

  useEffect(() => {
    if (editor) {
      editor.setOptions({ editorProps: { handlePaste } });
    }
  }, [handlePaste, editor]);

  const marginLeft = viewers.length * buttonWidth + defaultMargin;

  const handleDelete = () => {
    if (appStore.ui.selectedIds.length && appStore.ui.selectedIds.includes(reportBlock.id)) {
      workspace?.deleteReportBlocks(appStore.ui.selectedIds);
    } else {
      appStore.workspaceModel?.deleteReportBlock(reportBlock);
    }
  };

  const handleAdd = () => {
    const activeReport = appStore.workspaceModel?.reportsMap.get(reportBlock.parentReport || "");
    if (activeReport) {
      const currentBlockLiveIndex = activeReport.reportBlocks.findIndex(id => id === reportBlock.id);
      const id = appStore.workspaceModel?.addReportBlock(activeReport, RollupEditorType.p, "", currentBlockLiveIndex + 1);
      setTimeout(() => {
        const newBlockElement = id && document.getElementById(id);
        newBlockElement && (newBlockElement?.firstChild as HTMLDivElement).focus();
      }, 50);
    }
  };

  const renderDragButtonMenu = () => (
    <Menu>
      <ReportBlockConvertMenuItems reportBlock={reportBlock} allowedTypes={getAllowedTypes(reportBlock.type)} />
      <MenuDivider title="Actions" />
      <CopyLinkMenuItem node={reportBlock} />
      <MenuItem icon="comment" text="Comment" e2eIdentifiers="add-comment" onClick={() => setIsPopoverOpen(true)} />
      <MenuItemDelete onDelete={handleDelete} />
    </Menu>
  );

  return (
    <div style={{ marginLeft: `-${marginLeft}px` }} className={classNames("report-block", { "report-block--selected": selected })}>
      <DocumentActionsNav
        className="actions-nav"
        blockCount={reportBlocks.length}
        dragListeners={dragListeners}
        onAdd={handleAdd}
        dragButtonMenuElement={renderDragButtonMenu()}
      />
      <PresenceList viewers={viewers} />
      <EntityAnnotationListPopover
        className="report-block--popover-target"
        entityId={reportBlock.id}
        entityIdsWithComments={reportBlocks.filter(block => block.hasComment).map(block => block.id)}
        annotationList={reportBlock.annotationList}
        storeType={StoreType.ReportBlock}
        isOpen={isPopoverOpen}
        onInteraction={setIsPopoverOpen}
        popoverProps={{ position: PopoverPosition.BOTTOM_LEFT }}
        disableOpenOnTargetClick
      >
        <ReportsEditor
          onEditorReady={handleEditorReady}
          reportBlock={reportBlock}
          onUpdate={handleBlockUpdate}
          eventHandlers={{
            onFocus: handleFocus,
            onBlur: handleBlur,
            onEnterKeyDown: handleEnterKeyDown,
            onModEnterKeyDown: handleModEnterKeyDown,
            onShiftEnterKeyDown: handleShiftEnterKeyDown,
            onBackspaceKeyDown: handleBackspaceKeyDown,
            onArrowDownKey: handleArrowDownKey,
            onArrowUpKey: handleArrowUpKey,
          }}
        />
      </EntityAnnotationListPopover>
    </div>
  );
};

export default observer(ReportBlock);
