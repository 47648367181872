import { DependencyEdge, DependencyNode, fillDependencyGraph } from "@rollup-io/engineering";
import { AxiosResponse } from "axios";
import { DirectedGraph } from "graphology";
import assignIn from "lodash/assignIn";
import isSafeInteger from "lodash/isSafeInteger";
import { action, observable } from "mobx";
import {
  cast,
  destroy,
  flow,
  getSnapshot,
  IAnyModelType,
  IAnyStateTreeNode,
  Instance,
  IType,
  SnapshotIn,
  SnapshotOut,
  types,
} from "mobx-state-tree";
import { moveItem } from "mobx-utils";
import moment from "moment";
import { Socket } from "socket.io-client";
import { v4 as uuidv4 } from "uuid";

import { CommentLocationType } from "@components/Modeling/ModelingFrame/ModelBlock/Comments/utils";
import { blockNodeList } from "@components/Modeling/ModelingFrame/Table/utils";
import { showApiErrorToast, showMiddleToast } from "@components/UiLayers/toaster";
import { Block } from "@rollup-api/models/block";
import { CreateBlockDto } from "@rollup-api/models/block/blockCreateDto.model";
import { BlockUpdateDto } from "@rollup-api/models/block/blockUpdateDto.model";
import { TemporalDirection } from "@rollup-api/models/comments/commentGetThreadedRequestDto.model";
import { CommentThreadedItem } from "@rollup-api/models/comments/threadedComments.model";
import { IntegrationLinkData } from "@rollup-api/models/integrations";
import { PropertyInstance } from "@rollup-api/models/propertyInstance";
import { ReferenceLinkData } from "@rollup-api/models/references/references.model";
import { StatusInstance } from "@rollup-api/models/statusInstance";
import { Transaction } from "@rollup-api/models/transactions";
import { reorderBlock, reorderInterface, reorderPropertyInstance, setBlockPartNumber, updateBlock } from "@rollup-api/utils";
import { IconSource, IIcon } from "@rollup-types/icons";
import { AnnotationListStore } from "@store/AnnotationListStore";
import { CommentFeedStore } from "@store/CommentFeedStore";
import { IInterface, InterfaceStore } from "@store/InterfaceStore";
import { IPropertyDefinition } from "@store/PropertyDefinitionStore";
import { IPropertyInstance, PropertyInstanceStore } from "@store/PropertyInstanceStore";
import { StoreType } from "@store/types";
import { convertTimestamp, getPath, moveItemInRefArray, parentWorkspace, validatedRefArray } from "@utilities";
import { rollupClient } from "src/core/api";

import appStore from "./AppStore";
import { AttachmentStore, IAttachment } from "./AttachmentStore";
import { CommentThreadListStore } from "./CommentThreadListStore";
import { EFeedEntityType, FeedStore } from "./FeedStore";
import { FigureBlockStore, IFigureBlock } from "./FigureBlockStore";
import { IPartNumberSchema } from "./PartNumberSchemaStore";
import { IPropertyGroup, PropertyGroupStore } from "./PropertyGroupStore";
import { IStatusInstance, StatusInstanceStore } from "./StatusInstanceStore";

export enum BlockType {
  Part = "part",
  Subsystem = "subsystem",
  Assembly = "assembly",
  Product = "product",
}

export enum EActiveUpdates {
  ALL = "all",
  CHANGES = "changes",
  COMMENTS = "comments",
}

export const UNGROUPED_ID = "ungrouped";
type TGroupId = string;
type TPropertyId = string;

export enum EBlockTab {
  CODE = "code",
  FEED = "feed",
  COMMENTS = "comments",
  ATTACHMENTS = "attachments",
  CHILD_BLOCKS = "child_blocks",
  INFO = "info",
  PROPERTIES = "properties",
  FIGURES = "figures",
  INTERFACES = "interfaces",
}

export type BomTableNode = {
  block: IBlock;
};

class BlockUiStore {
  @observable accessor blockViewFileDropZoneEnabled = true;
  @observable accessor isExpanded = true;
  @observable accessor showPreviewCard = true;
  @observable accessor hasMoreActionHistoryFeedItems = true;
  @observable accessor isFetchingActionHistory = false;
  @observable accessor activeFeedUpdates: EActiveUpdates = EActiveUpdates.ALL;
  @observable accessor discussionDraggedImageLink: string | undefined;

  @action.bound
  public toggleShowPreviewCard() {
    this.showPreviewCard = !this.showPreviewCard;
  }

  @action.bound
  public setBlockViewFileDropZoneEnabled(isEnabled: boolean) {
    this.blockViewFileDropZoneEnabled = isEnabled;
  }

  @action.bound
  public setExpanded(expanded: boolean) {
    this.isExpanded = expanded;
  }

  @action.bound
  public setIsFetchingActionHistory(isFetching: boolean) {
    this.isFetchingActionHistory = isFetching;
  }

  @action.bound
  public setMoreActionHistoryFeedItems(moreItems: boolean) {
    this.hasMoreActionHistoryFeedItems = moreItems;
  }

  @action.bound
  public setActiveFeedUpdates(activeFeedUpdates: EActiveUpdates) {
    this.activeFeedUpdates = activeFeedUpdates;
  }

  @action.bound
  public setDiscussionDraggedImageLink(link?: string) {
    this.discussionDraggedImageLink = link;
  }

  @action.bound
  public clearDiscussionDraggedImageLink() {
    this.discussionDraggedImageLink = undefined;
  }
}

export const BlockStore = types
  .model(StoreType.Block, {
    id: types.identifier,
    replicated: types.optional(types.boolean, true),
    label: types.string,
    parentBlock: types.maybeNull(types.safeReference(types.late((): IAnyModelType => BlockStore))),
    description: types.optional(types.string, ""),
    icon: types.maybeNull(types.frozen<IIcon>()),
    partNumber: types.maybeNull(types.string),
    type: types.array(types.enumeration("BlockType", [...Object.values(BlockType)])),
    statusInstances: types.maybeNull(types.array(types.safeReference(types.late((): IAnyModelType => StatusInstanceStore)))),
    propertyGroups: types.maybeNull(types.array(PropertyGroupStore)),
    attachments: types.array(types.safeReference(types.late((): IAnyModelType => AttachmentStore))),
    propertyInstances: types.maybeNull(types.array(types.safeReference(types.late((): IAnyModelType => PropertyInstanceStore)))),
    interfaces: types.maybeNull(types.array(types.safeReference(types.late((): IAnyModelType => InterfaceStore)))),
    multiplicity: types.maybeNull(types.number),
    figures: types.maybeNull(types.array(types.safeReference(types.late((): IAnyModelType => FigureBlockStore)))),
    previewCustomLink: types.optional(types.string, ""),
    previewSource: types.optional(types.enumeration("preview-source", ["pdm", "custom-link"]), "custom-link"),
    children: types.maybeNull(types.array(types.safeReference(types.late((): IAnyModelType => BlockStore)))),
    dedicatedAttachment: types.maybeNull(types.safeReference(types.late((): IAnyModelType => AttachmentStore))),
    commentThreadList: types.optional(CommentThreadListStore, {}),
    annotationList: types.optional(AnnotationListStore, {}),
    actionHistoryFeed: types.array(types.late((): IAnyModelType => FeedStore)),
    commentFeed: types.optional(CommentFeedStore, {}),
  })
  .volatile(() => ({
    ui: new BlockUiStore(),
    lastFocusedCommentId: undefined as string | undefined,
  }))
  .actions(self => ({
    setDedicatedAttachment(attachment: IAttachment) {
      self.dedicatedAttachment = attachment;
      updateBlock(self.id, { dedicatedAttachment: attachment.id });
    },
    removeDedicatedAttachment() {
      self.dedicatedAttachment = null;
      updateBlock(self.id, { dedicatedAttachment: null });
    },
    addFeedItem(feedItem: Transaction) {
      self.actionHistoryFeed.unshift({
        ...feedItem,
        createdAt: convertTimestamp(feedItem.createdAt),
        updatedAt: convertTimestamp(feedItem.updatedAt),
      });
    },
    setReplicated() {
      self.replicated = true;
    },
    patch(update: BlockUpdateDto) {
      // Prevent updating of fixed properties
      const invalidFields = ["id", "parentBlock", "properties", "interfaces", "ui", "replicated"];
      const updateKeys = Object.keys(update);
      for (const field of invalidFields) {
        if (updateKeys.includes(field)) {
          return false;
        }
      }

      // Property groups have to be handled separately, because they are a snapshot
      if (update.propertyGroups) {
        const { propertyGroups, ...other } = update;
        self.propertyGroups = cast(propertyGroups);
        update = other;
      }
      try {
        assignIn(self, update);
        return true;
      } catch (err) {
        console.warn(err);
        return false;
      }
    },
    addNewBlock(label: string): Promise<string | undefined> | undefined {
      return parentWorkspace(self)?.addNewBlock(self as IBlock, label);
    },
    deleteBlock(block: IBlock): boolean {
      if (self.children?.includes(block)) {
        showMiddleToast("block deleted", "none");
        return parentWorkspace(self)?.deleteBlock(block) ?? false;
      } else {
        return false;
      }
    },
    setLabel(label: string) {
      if (label === self.label) {
        return;
      }

      self.label = label;
      updateBlock(self.id, { label });
    },
    setCustomPreviewLink(previewCustomLink: string) {
      if (self.previewCustomLink === previewCustomLink) {
        return;
      }

      self.previewCustomLink = previewCustomLink;
      updateBlock(self.id, { previewCustomLink });
    },
    setNewDescription(description?: string) {
      self.description = description ?? "";
      updateBlock(self.id, { description });
    },
    //Group is an ID not a label or an object.,
    async addNewProperty(definition?: IPropertyDefinition, group?: string): Promise<IPropertyInstance | undefined> {
      // TODO handle situations where property has no definition
      if (!definition) {
        return undefined;
      }

      return appStore.workspaceModel?.addPropertyInstance(self as IBlock, definition, group);
    },
    addNewPropertyGroup: flow(function* addNewPropertyGroup(
      label?: string,
      id = uuidv4(),
      notify = true
    ): Generator<any, IPropertyGroup | undefined, any> {
      label = label?.trim();
      if (label && self.propertyGroups?.find(g => g.label === label)) {
        return undefined;
      }
      const group = PropertyGroupStore.create({
        id,
        label: label ?? "",
      });
      if (self.propertyGroups) {
        self.propertyGroups.unshift(group);
      } else {
        self.propertyGroups = cast([group]);
      }

      let success = false;
      try {
        if (notify && self.propertyGroups) {
          const res = yield updateBlock(self.id, { propertyGroups: getSnapshot(self.propertyGroups) });
          success = res.status === 200;
        }
      } catch (err) {
        console.warn(err);
      }
      if (!success) {
        self.propertyGroups?.remove(group);
        return undefined;
      }
      return group;
    }),
    movePropertyGroup(srcId: string, destId: string) {
      if (self.propertyGroups) {
        const srcIndex = self.propertyGroups.findIndex(p => p?.id === srcId);
        const destIndex = self.propertyGroups.findIndex(p => p?.id === destId);
        moveItem(self.propertyGroups, srcIndex, destIndex);
        updateBlock(self.id, { propertyGroups: getSnapshot(self.propertyGroups) });
      }
    },
    deletePropertyGroup(group: IPropertyGroup) {
      if (!group || !self.propertyGroups || self.propertyGroups?.indexOf(group) < 0) {
        return;
      }
      if (self.propertyInstances) {
        for (const item of self.propertyInstances) {
          if (item?.propertyGroup === group.id) {
            item.clearGroup();
          }
        }
      }
      destroy(group);
      updateBlock(self.id, { propertyGroups: getSnapshot(self.propertyGroups) });
    },
    handlePropertyGroupUpdated() {
      if (self.propertyGroups) {
        updateBlock(self.id, { propertyGroups: getSnapshot(self.propertyGroups) });
      }
    },
    deletePropertyInstance(property: IPropertyInstance): boolean {
      if (property && self.propertyInstances?.includes(property)) {
        self.propertyInstances.remove(property);
        return parentWorkspace(self)?.deletePropertyInstance(property) ?? false;
      } else {
        return false;
      }
    },
    deleteExternalLink(externalLink: IAnyStateTreeNode) {
      destroy(externalLink);
    },
    setPreviewImageSource(previewSource: typeof self.previewSource) {
      self.previewSource = previewSource;
      updateBlock(self.id, { previewSource });
    },
    moveBlockByIndex(childIndex: number, destinationIndex: number, notify = true) {
      if (self.children) {
        const srcBlock = self.children?.[childIndex];
        const destBlock = self.children?.[destinationIndex];
        moveItemInRefArray(self.children, childIndex, destinationIndex);
        if (srcBlock && destBlock && notify) {
          reorderBlock(srcBlock.id, destBlock.id);
        }
      }
    },
    moveBlock(srcId: string, destId: string, notify = true) {
      if (self.children) {
        const srcIndex = self.children.findIndex(b => b?.id === srcId);
        const destIndex = self.children.findIndex(b => b?.id === destId);
        moveItemInRefArray(self.children, srcIndex, destIndex);
        if (notify) {
          reorderBlock(srcId, destId);
        } else {
          appStore.env.tableViewGridApi?.setGridOption("rowData", blockNodeList());
        }
      }
    },
    movePropertyInstance(srcId: string, destId: string, notify = true, groupId?: string) {
      if (self.propertyInstances) {
        const srcIndex = self.propertyInstances.findIndex(p => p?.id === srcId);
        const destIndex = self.propertyInstances.findIndex(p => p?.id === destId);
        moveItemInRefArray(self.propertyInstances, srcIndex, destIndex);
        if (notify) {
          reorderPropertyInstance(srcId, destId, groupId);
        }
      }
    },
    addNewFigure() {
      //TODO Flesh out this method
      // const figure = FigureBlockStore.create({});
      // self.figures.push(figure);
    },
    deleteFigure(figure: IFigureBlock) {
      destroy(figure);
    },
    setIcon(icon: IIcon) {
      if (self.icon === icon) {
        return;
      }

      self.icon = icon;
      updateBlock(self.id, { iconData: icon });
    },
    toggleType(type: BlockType, active: boolean) {
      const newTypes = active ? [...self.type, type] : self.type.filter(t => t !== type);
      self.type = cast(newTypes);
      updateBlock(self.id, { type: newTypes });
    },
    updatePartNumber(partNumber: string, notify = true) {
      self.partNumber = partNumber;
      if (notify) {
        updateBlock(self.id, { partNumber });
      }
    },
    setPartNumber: flow(function* setPartNumber(partNumberSchema: IPartNumberSchema) {
      const response: AxiosResponse<string> | undefined = yield setBlockPartNumber(self.id, partNumberSchema.id);
      if (response) {
        self.partNumber = `${response.data}`;
      }
    }),
    setMultiplicity(multiplicity: number) {
      // positive integers only
      if (multiplicity && isSafeInteger(multiplicity) && multiplicity >= 1) {
        self.multiplicity = multiplicity;
        updateBlock(self.id, { multiplicity });
      }
    },
    enableMultiplicity() {
      if (!self.multiplicity) {
        self.multiplicity = 1;
        updateBlock(self.id, { multiplicity: 1 });
      }
    },
    clearMultiplicity() {
      self.multiplicity = 0;
      updateBlock(self.id, { multiplicity: 0 });
    },
    deleteStatusInstance(statusInstance: IStatusInstance): boolean {
      if (statusInstance && self.statusInstances?.includes(statusInstance)) {
        self.statusInstances.remove(statusInstance);
        return parentWorkspace(self)?.deleteStatusInstance(statusInstance) ?? false;
      } else {
        return false;
      }
    },
    addNewInterface(): Promise<string | undefined> | undefined {
      return parentWorkspace(self)?.addNewInterface(self as IBlock);
    },
    deleteInterface(existingInterface?: IInterface): boolean {
      if (existingInterface && self.interfaces?.includes(existingInterface)) {
        self.interfaces.remove(existingInterface);
        return parentWorkspace(self)?.deleteInterface(existingInterface) ?? false;
      } else {
        return false;
      }
    },
    moveInterface(srcId: string, destId: string, notify = true) {
      if (self.interfaces) {
        const srcIndex = self.interfaces.findIndex(i => i?.id === srcId);
        const destIndex = self.interfaces.findIndex(i => i?.id === destId);
        moveItemInRefArray(self.interfaces, srcIndex, destIndex);
        if (notify) {
          reorderInterface(srcId, destId);
        }
      }
    },
    getStatusInstanceByStatusDefinitionId(statusDefinitionId: string): IStatusInstance | undefined {
      return self.statusInstances?.find(statusInstance => statusInstance?.statusDefinition?.id === statusDefinitionId);
    },
    getPropertyInstanceByPropertyId(propertyId: string): IStatusInstance | undefined {
      return self.propertyInstances?.find(propertyInstance => propertyInstance?.id === propertyId);
    },
    addNewFileUpload(files: FileList, onUpload?: (attachmentId: string) => void): void {
      return appStore.orgModel.uploads.addNewFileUpload({
        blockId: self.id,
        files: files,
        onUpload: onUpload,
        workspaceId: parentWorkspace(self)?.id,
      });
    },
    addLink(linkName: string, linkUrl: string): Promise<string | undefined> | undefined {
      return parentWorkspace(self)?.attachments.addLink(self.id, linkName, linkUrl);
    },
    addReferenceLink(data: ReferenceLinkData): Promise<string | undefined> | undefined {
      return parentWorkspace(self)?.attachments.addReferenceLink(self.id, data);
    },
    addIntegrationAttachment(data: IntegrationLinkData): Promise<string | undefined> | undefined {
      return parentWorkspace(self)?.attachments.addIntegration(self.id, { ...data, workspaceId: parentWorkspace(self)?.id });
    },
    clearActionHistory() {
      self.actionHistoryFeed = cast([]);
      self.ui.setMoreActionHistoryFeedItems(true);
    },
  }))
  .actions(self => ({
    clearCommentHistory() {
      self.commentFeed = cast({});
    },
  }))
  .actions(self => ({
    fetchActionHistory: flow(function* fetchActionHistory(force?: boolean): Generator<any, boolean, any> {
      // do not trigger load if we already have loaded the feed
      if (self.actionHistoryFeed.length && !force) {
        return false;
      }

      self.ui.setIsFetchingActionHistory(true);
      const referenceTypes = Object.values(EFeedEntityType).filter(type => type !== EFeedEntityType.COMMENT);

      try {
        let endTime = self.actionHistoryFeed.at(-1)?.createdAt;
        endTime = endTime ? new Date(endTime) : undefined;
        const { data, status } = yield rollupClient.transactions.list({ parentId: self.id, referenceTypes, endTime });
        const { transactions, hasMore } = data;

        if (status !== 200) {
          showApiErrorToast(`Error fetching action history for block ${self.id}`);
          self.ui.setIsFetchingActionHistory(false);
          return false;
        }
        // Construct feed stores
        self.actionHistoryFeed = cast([
          ...self.actionHistoryFeed,
          ...transactions.map((i: Transaction) => ({
            ...i,
            createdAt: convertTimestamp(i.createdAt),
          })),
        ]);

        self.ui.setMoreActionHistoryFeedItems(hasMore);
        self.ui.setIsFetchingActionHistory(false);
        return true;
      } catch (error) {
        showApiErrorToast(`Error fetching action history for block ${self.id}`, error as Error);
        self.ui.setIsFetchingActionHistory(false);
        return false;
      }
    }),
    setLastFocusedCommentId(id: string) {
      self.lastFocusedCommentId = id;
    },
    addAttachment(attachment: IAttachment) {
      self.attachments?.push(attachment);
    },
    addAttachmentRef(id: string) {
      if (self.attachments) {
        self.attachments.push(id);
      } else {
        self.attachments = cast([id]);
      }
    },
    removeAttachmentRef(attachmentId: string) {
      self.attachments = cast(self.attachments.filter(attachment => attachment.id !== attachmentId));
    },
  }))
  .views(self => ({
    get parentBlockId(): string | undefined {
      return self.parentBlock?.id;
    },
    get childIds(): string[] | undefined {
      return self.children?.map(b => b?.id)?.filter(id => id);
    },
    get propertyInstanceIds(): string[] | undefined {
      return self.propertyInstances?.map(p => p?.id)?.filter(id => id);
    },
    get path(): string | undefined {
      try {
        const parent = self.parentBlock as IBlock | undefined;
        if (parent) {
          return `${parent.path}/${self.label}`;
        } else {
          return "";
        }
      } catch (err) {
        return undefined;
      }
    },
    get isProduct(): boolean {
      return self.type.includes(BlockType.Product) || !self.parentBlock;
    },
    get pathIds(): string[] {
      return getPath(self as IBlock);
    },
    get iconView(): IIcon {
      if (self.icon) {
        return self.icon;
      } else if (self.children && self.children.length) {
        return { source: IconSource.Blueprint, name: "layers" };
      }
      return { source: IconSource.Blueprint, name: "layer" };
    },
    get thumbnailUrl(): string {
      return self.dedicatedAttachment?.previewUrl || self.previewCustomLink;
    },
    get pmImageUrl(): string {
      return self.previewCustomLink || self.dedicatedAttachment?.previewUrl;
    },
    get validatedChildren() {
      return validatedRefArray<IBlock>(self.children);
    },
    get validatedPropertyInstances() {
      return validatedRefArray<IPropertyInstance>(self.propertyInstances);
    },
    get validatedInterfaces() {
      return validatedRefArray<IInterface>(self.interfaces);
    },
    get validatedStatusInstances() {
      return validatedRefArray<IStatusInstance>(self.statusInstances);
    },
    get validatedAttachments() {
      return validatedRefArray<IAttachment>(self.attachments);
    },
  }))
  .views(self => ({
    get orderedPropertyInstances(): IPropertyInstance[] {
      return [...self.validatedPropertyInstances].sort((a, b) => {
        const groupIndexA = a.propertyGroup ? (self.propertyGroups?.findIndex(g => g.id === a.propertyGroup) ?? 0) : 0;
        const groupIndexB = b.propertyGroup ? (self.propertyGroups?.findIndex(g => g.id === b.propertyGroup) ?? 0) : 0;

        if (groupIndexA !== groupIndexB) {
          return groupIndexA - groupIndexB;
        }

        const indexA = self.propertyInstances?.findIndex(p => p.id === a.id) ?? 0;
        const indexB = self.propertyInstances?.findIndex(p => p.id === b.id) ?? 0;

        return indexB - indexA;
      });
    },
    get parentProductName(): string {
      if (!self.parentBlock) {
        return self.label;
      }

      return self.parentBlock.isProduct ? self.parentBlock.label : self.parentBlock.parentProductName;
    },
    get groupedProperties(): Record<TGroupId, TPropertyId[]> {
      const ungroupedProps = self.validatedPropertyInstances
        .filter(p => !p.effectivePropertyGroup)
        .map(g => g.id)
        .reverse();
      const validGroups = self.propertyGroups?.filter(g => g) ?? [];
      const listData: Record<string, string[]> = {};

      validGroups.forEach(g => {
        listData[g.id] = self.validatedPropertyInstances
          .filter(p => g.id === p.effectivePropertyGroup)
          .map(i => i.id)
          .reverse();
      });

      if (!ungroupedProps.length) {
        return listData;
      }

      return { [UNGROUPED_ID]: ungroupedProps, ...listData };
    },
    get allPropertyIds(): string[] {
      return self.validatedPropertyInstances.map(p => p.id);
    },
    get recentlyAddedProperty(): IPropertyInstance | undefined {
      const propertyInstance = self.validatedPropertyInstances.at(-1);
      if (propertyInstance && !moment().diff(propertyInstance.createdAt, "seconds")) {
        return propertyInstance;
      }
    },
    get multiplicityIsEnabled(): boolean {
      return self.multiplicity ? isSafeInteger(self.multiplicity) && self.multiplicity >= 1 : false;
    },
    get dependencySubGraph() {
      const graph = new DirectedGraph<DependencyNode, DependencyEdge>();
      if (appStore.workspaceModel && self.propertyInstances?.length) {
        for (const prop of self.propertyInstances) {
          fillDependencyGraph(appStore.workspaceModel, prop, graph);
        }
      }
      return graph;
    },
    get depth() {
      if (!self.parentBlock) {
        return 0;
      }
      return self.parentBlock.depth + 1;
    },
    get isDetachedBlock(): boolean {
      return !self.parentBlock && self.id !== appStore.workspaceModel?.rootBlockId;
    },
  }))
  .actions(self => ({
    fetchPropertyComments: flow(function* fetch(): Generator<any, void, AxiosResponse<CommentThreadedItem[]>> {
      const propertyIds = Object.values(self.groupedProperties).flat();
      if (!propertyIds.length) {
        return;
      }
      try {
        const { data } = yield rollupClient.comments.retrieveThreaded({
          parentIds: propertyIds,
          childTake: 9999,
          type: CommentLocationType.Annotation,
          parentTake: 1,
          childSkip: 0,
          temporalDirection: TemporalDirection.Older,
        });
        data.forEach(({ parentId, threadedComments }) => {
          const propertyInstance = self.validatedPropertyInstances.find(p => p.id === parentId);
          propertyInstance?.annotationList.loadThreadedComments(threadedComments);
        });
      } catch (error) {
        throw new Error(`Error fetching property comments for block ${self.id}: ${error}`);
      }
    }),
  }));

export function subscribeToBlockEvents(socket: Socket) {
  socket.on("createBlock", (data: { workspaceId: string; createBlockDto: CreateBlockDto; userId: string }) => {
    if (data.createBlockDto?.id && data.workspaceId === appStore.workspaceModel?.id) {
      appStore.workspaceModel.addExistingBlock(data.createBlockDto);
    }
  });

  socket.on("deleteBlock", (data: { workspaceId: string; id: string; userId: string }) => {
    if (data.id && data.workspaceId === appStore.workspaceModel?.id) {
      const block: IBlock | undefined = appStore.workspaceModel.blockMap.get(data.id);

      if (block) {
        appStore.workspaceModel.deleteBlock(block, false);
      }
    }
  });

  socket.on("updateBlock", (data: { workspaceId: string; id: string; updateBlockDto: BlockUpdateDto; userId: string }) => {
    if (data.id && data.workspaceId === appStore.workspaceModel?.id) {
      const block: IBlock | undefined = appStore.workspaceModel.blockMap.get(data.id);
      block?.patch(data.updateBlockDto);
    }
  });

  socket.on(
    "duplicateBlock",
    (data: {
      workspaceId: string;
      id: string;
      duplicateBlockDto: { parentBlock: string };
      newEntities: { blocks: Block[]; propertyInstances: PropertyInstance[]; statusInstances: StatusInstance[] };
    }) => {
      if (data.id && data.duplicateBlockDto?.parentBlock && data.workspaceId === appStore.workspaceModel?.id) {
        const block = appStore.workspaceModel.blockMap.get(data.id);
        if (block && data.newEntities?.blocks?.length) {
          appStore.workspaceModel.populateNewBlocks(
            data.newEntities.blocks,
            data.newEntities.propertyInstances,
            data.newEntities.statusInstances
          );
        }
      }
    }
  );

  socket.on("reparentBlock", (data: { workspaceId: string; id: string; reparentBlockDto: { parentBlock: string } }) => {
    if (data.id && data.workspaceId === appStore.workspaceModel?.id) {
      const block = appStore.workspaceModel.blockMap.get(data.id);
      const parentBlock = data.reparentBlockDto?.parentBlock
        ? appStore.workspaceModel.blockMap.get(data.reparentBlockDto.parentBlock)
        : undefined;
      // Blocks reparented to an empty string are detached from the tree
      if (block && (parentBlock || !data.reparentBlockDto?.parentBlock)) {
        appStore.workspaceModel.reparentBlock(block, parentBlock, false);
      }
    }
  });

  socket.on("reorderBlock", (data: { workspaceId: string; id: string; reorderBlockDto: { destinationId: string } }) => {
    if (data.id && data.workspaceId === appStore.workspaceModel?.id) {
      const block = appStore.workspaceModel.blockMap.get(data.id);
      block?.parentBlock?.moveBlock(data.id, data.reorderBlockDto.destinationId, false);
    }
  });

  socket.on("setBlockPartNumber", (data: { workspaceId: string; id: string; partNumber: string }) => {
    if (data.id && data.workspaceId === appStore.workspaceModel?.id) {
      const block = appStore.workspaceModel.blockMap.get(data.id);
      block?.updatePartNumber(data.partNumber, false);
    }
  });
}

export interface IBlock extends Instance<typeof BlockStore> {}

interface IBlockSnapshotIn extends SnapshotIn<typeof BlockStore> {}

interface IBlockSnapshotOut extends SnapshotOut<typeof BlockStore> {}

export interface IBlockMobxType extends IType<IBlockSnapshotIn, IBlockSnapshotOut, IBlock> {}
