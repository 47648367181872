import { RefAttributes } from "react";
import { Editor, Range } from "@tiptap/core";
import { Mention } from "@tiptap/extension-mention";
import { ReactRenderer } from "@tiptap/react";
import { SuggestionOptions } from "@tiptap/suggestion";
import Fuse from "fuse.js";
import { PluginKey } from "prosemirror-state";
import tippy, { GetReferenceClientRect, Instance } from "tippy.js";

import { ENTER_KEY, ESCAPE_KEY } from "@constants/keys";
import { RollupEditorType } from "@rollup-types/editor";
import appStore from "@store/AppStore";
import { isReportBlockType, moveCaretToEnd } from "@utilities";

import DashNavigationList, { DashNavigationListItemData, DashNavigationListProps, DashNavigationListRef } from "./DashNavigationList";
import { getListItemDataBasedOnType, getReportBlockDefaultLabel, reportPageItems } from "./utils";

type DashNavigationSuggestionOptions<T> = Omit<SuggestionOptions<T>, "editor">;
type NavigateReportHandler = (reportId: string) => void;
export const DashNavigationPluginKey = new PluginKey("dash-navigation");
export const DashNavigationTrigger = "/";

const DashNavigation = (navigateToReport: NavigateReportHandler): DashNavigationSuggestionOptions<DashNavigationListItemData> => ({
  allowSpaces: false,
  char: DashNavigationTrigger,
  pluginKey: DashNavigationPluginKey,
  items: ({ query }: { query: string }) => {
    const blockTypeEntities = Object.values(RollupEditorType).map(getListItemDataBasedOnType);
    const entries = [...blockTypeEntities, ...reportPageItems];

    if (query) {
      const fuse = new Fuse(entries, {
        isCaseSensitive: false,
        findAllMatches: true,
        keys: ["title", "description", "keywords"],
        threshold: 0.8,
        ignoreLocation: true,
      });

      const results = fuse.search(query, { limit: 10 });
      return results.map(r => r.item);
    }

    return entries;
  },
  command: (commandData: { editor: Editor; range: Range; props: DashNavigationListItemData }) => {
    const { props, editor, range } = commandData;
    editor.commands.deleteRange(range);
    const reportBlock = appStore.workspaceModel?.reportBlocksMap.get(editor.options.element.id);
    let oldIndex;

    if (reportBlock) {
      if (editor.isEmpty || !editor.getText()) {
        if (reportBlock.type === props.type) {
          editor.chain().focus();
          return;
        } else {
          oldIndex = reportBlock.orderIndex;
          appStore.workspaceModel?.deleteReportBlock(reportBlock);
        }
      }
    }

    const activeReportId = appStore.env.activeReportId;
    const activeReport = appStore.workspaceModel?.reportsMap.get(activeReportId || "");
    const orderIndex = reportBlock ? reportBlock.orderIndex + 1 : undefined;

    if (activeReport) {
      if (isReportBlockType(props.type)) {
        const id = appStore.workspaceModel?.addReportBlock(
          activeReport,
          props.type,
          getReportBlockDefaultLabel(props.type),
          oldIndex || orderIndex
        );
        setTimeout(() => {
          const newBlockElement = id && document.getElementById(id);
          if (newBlockElement && newBlockElement.firstChild) {
            if (props.type !== RollupEditorType.table) {
              (newBlockElement?.firstChild as HTMLDivElement).focus();
              moveCaretToEnd(newBlockElement.firstChild as HTMLDivElement);
            }
          }
        }, 50);
      } else {
        appStore.workspaceModel?.createReport({ parentReportId: activeReport.id }).then(r => {
          appStore.env.setActiveReport(r.id);
          navigateToReport(r.id);
        });
      }
    }
  },
  render: () => {
    let component: ReactRenderer<DashNavigationListRef, DashNavigationListProps & RefAttributes<DashNavigationListRef>>;
    let popup: Instance;

    return {
      onStart: props => {
        component = new ReactRenderer(DashNavigationList, { props: { ...props, popup }, editor: props.editor });

        if (!props.clientRect) {
          return;
        }

        popup = tippy("body", {
          getReferenceClientRect: props.clientRect as GetReferenceClientRect,
          appendTo: () => document.body,
          content: component.element,
          showOnCreate: true,
          interactive: true,
          trigger: "manual",
          placement: "bottom-start",
          maxWidth: "50vw",
        })?.[0];
      },

      onUpdate: props => {
        component.updateProps(props);

        if (!props.clientRect) {
          return;
        }

        popup.setProps({
          getReferenceClientRect: props.clientRect as GetReferenceClientRect,
        });
      },

      onKeyDown: props => {
        if (props.event.key === ESCAPE_KEY) {
          popup.hide();
          return true;
        }

        if (props.event.key === ENTER_KEY) {
          popup.hide();
        }

        return component.ref?.onKeyDown(props, popup) ?? false;
      },

      onExit: () => {
        if (popup?.state && !popup.state.isDestroyed) {
          popup.destroy();
        }
        component?.destroy();
      },
    };
  },
});

export const DashNavigationExtension = (navigateToReport: NavigateReportHandler) =>
  Mention.extend({ name: "dash-navigation" }).configure({ suggestion: DashNavigation(navigateToReport) });
