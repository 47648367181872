import { FeatureScope, Scopes } from "../models/scope";
import { WebhookEvents } from "../models/webhookEvents";

import { HttpClient, ParentClient } from "./client";

export class GeneralInfo extends HttpClient {
  public constructor(parent: ParentClient) {
    super(parent);
  }

  private readonly endpoint: string = "/info";

  public getAllScopes = async (): Promise<FeatureScope[] | undefined> => {
    try {
      const res = await this.instance.get<Scopes>(`${this.endpoint}/scopes`);
      return Object.values(res.data.scopes);
    } catch (err) {
      console.error(err);
      return;
    }
  };

  public getAllEvents = async (): Promise<string[] | undefined> => {
    try {
      const res = await this.instance.get<WebhookEvents>(`${this.endpoint}/events`);
      return res.data.events;
    } catch (err) {
      console.error(err);
      return;
    }
  };
}
