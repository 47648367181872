import { NonIdealState, NonIdealStateIconSize } from "@blueprintjs/core";
import { observer } from "mobx-react";

import { PreviewGridSize } from "@components/DataSources/QueryDataSourceDialog/PreviewGrid";
import { QueryDataSourceContainer } from "@components/DataSources/QueryDataSourceDialog/QueryDataSourceContainer";
import { DataSource } from "@rollup-api/models/data-sources/data-source.model";

import styles from "./DataSourcePanel.module.scss";

interface IDataSourcePanelProps {
  query: string;
  selectedSheet: string;
  dataSource?: DataSource;
  initialPreviewRange?: string;
  onQueryChanged(query: string): void;
  onSheetChanged(query: string): void;
}

const DataSourcePanel = (props: IDataSourcePanelProps) => {
  const { query, selectedSheet, dataSource, initialPreviewRange } = props;
  const { onQueryChanged, onSheetChanged } = props;

  const renderContent = () => {
    if (!dataSource) {
      return (
        <NonIdealState
          className={styles.dataSourcePanelNonIdealState}
          icon="info-sign"
          iconSize={NonIdealStateIconSize.SMALL}
          title="No data source available"
        />
      );
    }

    return (
      <QueryDataSourceContainer
        queryString={query}
        dataSource={dataSource}
        selectedSheet={selectedSheet}
        initialPreviewRange={initialPreviewRange}
        gridSize={PreviewGridSize.Small}
        onQueryChanged={onQueryChanged}
        onSheetChanged={onSheetChanged}
      />
    );
  };

  return <div className={styles.dataSourcePanel}>{renderContent()}</div>;
};

export default observer(DataSourcePanel);
