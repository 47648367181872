import { CreatePmTagDto, PmTag } from "@rollup-api/models/pmTags";

import { HttpClient, ParentClient } from "./client";

export class PmTags extends HttpClient {
  public constructor(parent: ParentClient) {
    super(parent);
  }

  private readonly endpoint: string = "pmtags";

  public retrieve = (id: string) => {
    return this.instance.get<PmTag>(`${this.endpoint}/${id}`);
  };

  public retrieveList = (workspaceId: string) => {
    return this.instance.get<PmTag[]>(this.endpoint, { params: { workspaceId: workspaceId } });
  };

  public create = (dto: CreatePmTagDto) => {
    return this.instance.post<PmTag>(this.endpoint, dto);
  };

  public delete = (id: string) => {
    return this.instance.delete<PmTag>(`${this.endpoint}/${id}`);
  };
}
