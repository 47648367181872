import { Attachment, Block, Comment, PropertyDefinition, PropertyInstance, StatusDefinition, StatusInstance } from "@rollup-api/models";
import { EntityModelBase } from "@rollup-api/shared/entityModelBase";
import { EFeedActionType, EFeedEntityType } from "@store/FeedStore";

type TransactionBlockEntityData = {
  referenceType: EFeedEntityType.BLOCK;
  newEntity: Block;
  oldEntity?: Block;
};

export type TransactionPropertyInstanceEntity = PropertyInstance & { propertyDefinition: PropertyDefinition };
type TransactionPropertyInstanceEntityData = {
  referenceType: EFeedEntityType.PROPERTY_INSTANCE;
  newEntity: TransactionPropertyInstanceEntity;
  oldEntity?: TransactionPropertyInstanceEntity;
};

export type TransactionPropertyDefinitionEntity = PropertyDefinition;
type TransactionPropertyDefinitionEntityData = {
  referenceType: EFeedEntityType.PROPERTY_DEFINITION;
  newEntity: TransactionPropertyDefinitionEntity;
  oldEntity?: TransactionPropertyDefinitionEntity;
};

export type TransactionStatusInstanceEntity = StatusInstance & { statusDefinition: StatusDefinition };
type TransactionStatusInstanceEntityData = {
  referenceType: EFeedEntityType.STATUS_INSTANCE;
  newEntity: TransactionStatusInstanceEntity;
  oldEntity?: TransactionStatusInstanceEntity;
};

export type TransactionCommentEntityData = {
  referenceType: EFeedEntityType.COMMENT;
  newEntity: Comment;
  oldEntity?: Comment;
};

type TransactionAttachmentEntityData = {
  referenceType: EFeedEntityType.ATTACHMENT;
  newEntity: Attachment;
  oldEntity?: Attachment;
};

export type TransactionEntity =
  | Attachment
  | Block
  | Comment
  | TransactionPropertyInstanceEntity
  | TransactionPropertyDefinitionEntity
  | TransactionStatusInstanceEntity;

export type TransactionEntityData =
  | TransactionBlockEntityData
  | TransactionPropertyInstanceEntityData
  | TransactionPropertyDefinitionEntityData
  | TransactionStatusInstanceEntityData
  | TransactionCommentEntityData
  | TransactionAttachmentEntityData;

export type Transaction<T extends TransactionEntityData = TransactionEntityData> = EntityModelBase &
  T & {
    createdBy: string;
    updatedBy: string;
    method: EFeedActionType;
    referenceId: string;
    parentId: string;
  };

export const isCommentTransaction = (transaction: TransactionEntityData): transaction is TransactionCommentEntityData => {
  return transaction.referenceType === EFeedEntityType.COMMENT;
};
