import { KeyboardEvent, useRef, useState } from "react";
import { Table as TipTapTable } from "@tiptap/extension-table";
import { TableCell } from "@tiptap/extension-table-cell";
import TableHeader from "@tiptap/extension-table-header";
import TableRow from "@tiptap/extension-table-row";
import classNames from "classnames";
import { observer } from "mobx-react";
import { ResolvedPos } from "prosemirror-model";

import { EditorContent } from "@components/EditorContent";
import { IPopoverRefType } from "@components/Popover";
import ArrowNavigation from "@components/Reports/Editor/Extentions/ArrowNavigation";
import { useDocumentEditor } from "@components/Reports/Editor/useDocumentEditor";
import { getDocumentEditorClassNames } from "@components/Reports/Editor/utils";
import { BACKSPACE_KEY } from "@constants/keys";
import appStore from "@store/AppStore";
import { IReportBlock } from "@store/ReportBlockStore";

import EditorBubbleMenu from "../../EditorBubbleMenu";
import TableButtonExtension, { DEFAULT_X_POS, DEFAULT_Y_POS } from "../../Extentions/TableButton";
import TableButton from "../../Extentions/TableButton/TableButton";
import { TEditorBlockProps } from "../../types";

import AddColumnButton from "./AddColumnButton";
import AddRowButton from "./AddRowButton";
import { DEFAULT_TABLE } from "./constants";

import "./Table.scss";

// TODO need to migrate to using IDocumentBlock. Not relevant atm, because requirements
//  don't use this block type
interface ITableBlockProps extends Omit<TEditorBlockProps, "documentBlock"> {
  reportBlock: IReportBlock;
  reportBlocks?: IReportBlock[];
}

const Table = (props: ITableBlockProps) => {
  const { reportBlock, reportBlocks, editable = true, eventHandlers, disableDashNavigation, onUpdate, onEditorReady } = props;
  const { onFocus, onBlur, onBackspaceKeyDown, onArrowUpKey } = eventHandlers;
  const horMenuRef = useRef<IPopoverRefType>(null);
  const vertMenuRef = useRef<IPopoverRefType>(null);
  const [cellPosition, setCellPosition] = useState<ResolvedPos>();
  const [verticalButtonPosition, setVerticalButtonPosition] = useState(DEFAULT_Y_POS);
  const [horizontalButtonPosition, setHorizontalButtonPosition] = useState(DEFAULT_X_POS);

  const onCellHover = (verticalPos: number, horizontalPos: number, cellPos: ResolvedPos) => {
    if (vertMenuRef.current?.state.isOpen || horMenuRef.current?.state.isOpen) {
      return;
    }

    setVerticalButtonPosition(verticalPos);
    setHorizontalButtonPosition(horizontalPos);
    setCellPosition(cellPos);
  };

  const arrowNavigation = reportBlocks ? [ArrowNavigation.configure({ reportBlock, reportBlocks })] : [];

  const content = reportBlock.label || DEFAULT_TABLE;
  const { editor, onBubbleMenuHide, onBubbleMenuShow } = useDocumentEditor({
    blockId: reportBlock.id,
    content,
    editable,
    extensions: [
      TipTapTable.configure({ resizable: true }),
      TableRow,
      TableHeader,
      TableCell,
      TableButtonExtension.configure({ onCellHover }),
      ...arrowNavigation,
    ],
    disableDashNavigation,
    onUpdate,
    onFocus,
    onBlur,
    onEditorReady,
  });

  const handleKeyDownEvents = (e: KeyboardEvent<HTMLDivElement>) => {
    if (e.key === BACKSPACE_KEY && !editor?.getText().trim()) {
      appStore.workspaceModel?.deleteReportBlock(reportBlock);
      return;
    }
    onBackspaceKeyDown(e);
    onArrowUpKey(e);
  };

  const tableClassNames = {
    "report-block--table-active-hor": horMenuRef.current?.state.isOpen,
    "report-block--table-active-vert": vertMenuRef.current?.state.isOpen,
  };

  const handleAddColumn = () => {
    if (editor) {
      editor.chain().focus().addColumnAfter().run();
      reportBlock.updateText(editor.getHTML());
    }
  };

  const handleAddRow = () => {
    if (editor) {
      editor.chain().focus().addRowAfter().run();
      reportBlock.updateText(editor.getHTML());
    }
  };

  const commonClassNames = getDocumentEditorClassNames({
    annotationList: reportBlock.annotationList,
  });

  return (
    <div key={reportBlock.id} className={classNames("report-block--table", tableClassNames)}>
      <div className="report-editor--table-wrapper">
        <EditorBubbleMenu onHide={onBubbleMenuHide} onShow={onBubbleMenuShow} editor={editor} />
        <EditorContent
          id={reportBlock.id}
          onKeyDown={handleKeyDownEvents}
          className={classNames("report-editor--table", commonClassNames)}
          editor={editor}
          e2eIdentifiers="table"
        />
      </div>
      <TableButton
        reportBlock={reportBlock}
        cellPosition={cellPosition}
        menuRef={horMenuRef}
        editor={editor}
        xPosition={horizontalButtonPosition}
        key={1}
        horizontal
      />
      <TableButton
        reportBlock={reportBlock}
        cellPosition={cellPosition}
        menuRef={vertMenuRef}
        editor={editor}
        yPosition={verticalButtonPosition}
        key={2}
      />
      <AddColumnButton onClick={handleAddColumn} />
      <AddRowButton onClick={handleAddRow} />
    </div>
  );
};

export default observer(Table);
