import { useEffect, useState } from "react";
import { BlueprintIcon } from "@ui/BlueprintIcon";
import classNames from "classnames";
import { observer } from "mobx-react";

import {
  GitHubDarkIcon,
  GitHubLightIcon,
  GoogleDocsIcon,
  GoogleDrawingsIcon,
  GoogleFormsIcon,
  GoogleSheetsIcon,
  GoogleSlidesIcon,
} from "@assets/icons/thirdParty";
import { Icon } from "@components/Icon";
import { getIconFromMimeType } from "@components/Modeling/ModelingFrame/ModelBlock/Attachments/AttachmentIcon.utils";
import {
  isAttachmentGithubRepository,
  isAttachmentGoogleDriveFile,
  isAttachmentRegularLink,
  parseOpenGraphData,
} from "@components/Modeling/ModelingFrame/ModelBlock/Attachments/utils";
import { ThemedCustomIcon } from "@components/Shared/LegacyCustomIcon/ThemedCustomIcon";
import { GoogleDriveFileType } from "@rollup-api/models";
import { AttachmentType } from "@rollup-api/models/cloudStorage";
import { IAttachment } from "@store/AttachmentStore";
import { getDocumentIcon } from "@utilities/Icons";

import styles from "./AttachmentIcon.module.scss";

interface AttachmentIconProps {
  attachment?: IAttachment;
  height?: number | string;
  width?: number | string;
  iconSize?: number;
  mimeType?: string;
  className?: string;
}

const AttachmentIcon = (props: AttachmentIconProps) => {
  const { attachment, height = 24, width = "auto", iconSize, mimeType, className } = props;
  const [favicon, setFavicon] = useState("");
  const _mimeType = mimeType ?? attachment?.fileType;
  const MimeIcon = getIconFromMimeType(_mimeType, attachment?.name);

  useEffect(() => {
    if (attachment && !favicon && isAttachmentRegularLink(attachment) && !isAttachmentGithubRepository(attachment)) {
      parseOpenGraphData(attachment).then(data => {
        setFavicon(data.favicon ?? "");
      });
    }
  }, [attachment, favicon]);

  const renderDefaultLinkIcon = () => {
    return <BlueprintIcon className={styles.linkIcon} size={iconSize} icon="link" />;
  };

  const renderGitHubIcon = () => {
    return (
      <BlueprintIcon
        className={styles.linkIcon}
        size={iconSize}
        icon={<ThemedCustomIcon lightIcon={<GitHubLightIcon />} darkIcon={<GitHubDarkIcon />} />}
      />
    );
  };

  const renderGoogleDriveFileIcon = (attachment: IAttachment) => {
    switch (attachment.metadata?.type) {
      case GoogleDriveFileType.Document:
        return <GoogleDocsIcon height={height} width={width} />;
      case GoogleDriveFileType.Spreadsheet:
        return <GoogleSheetsIcon height={height} width={width} />;
      case GoogleDriveFileType.Presentation:
        return <GoogleSlidesIcon height={height} width={width} />;
      case GoogleDriveFileType.Drawing:
        return <GoogleDrawingsIcon height={height} width={width} />;
      case GoogleDriveFileType.Form:
        return <GoogleFormsIcon height={height} width={width} />;
      default:
        return renderDefaultLinkIcon();
    }
  };

  const renderIcon = () => {
    switch (attachment?.type) {
      case AttachmentType.reference: {
        const icon = getDocumentIcon(attachment?.referenceEntityType);
        return <Icon className={styles.linkIcon} size={iconSize} icon={icon} />;
      }
      case AttachmentType.integration:
        if (isAttachmentGithubRepository(attachment)) {
          return renderGitHubIcon();
        } else if (isAttachmentGoogleDriveFile(attachment)) {
          return renderGoogleDriveFileIcon(attachment);
        }
        return renderDefaultLinkIcon();
      case AttachmentType.url:
        if (isAttachmentGithubRepository(attachment)) {
          return renderGitHubIcon();
        } else if (favicon) {
          return <img height={height} width={width} src={favicon} alt="Link favicon" />;
        }
        return (
          <img
            height={height}
            width={width}
            src={`https://www.google.com/s2/favicons?sz=64&domain=${attachment.linkAttachmentUrl}`}
            alt="Link favicon"
          />
        );
      default:
        return <MimeIcon style={{ height, width }} />;
    }
  };

  return <div className={classNames(styles.attachmentIcon, className)}>{renderIcon()}</div>;
};

export default observer(AttachmentIcon);
