import { HotkeyConfig } from "@blueprintjs/core";

import appStore from "@store/AppStore";

export const hotkeys: HotkeyConfig[] = [
  {
    combo: "mod + k",
    global: true,
    allowInInput: true,
    preventDefault: true,
    label: "Display command palette",
    onKeyDown: () => {
      appStore.pullOrgWorkspaces();
      appStore.ui.showCommandPalette();
    },
  },
  {
    combo: "shift + d",
    group: "Appearance",
    label: "Toggle dark theme",
    onKeyDown: appStore.env.toggleTheme,
  },
  {
    combo: "mod + shift + f",
    global: true,
    label: "Leave feedback",
    onKeyDown: appStore.feedback.show,
  },
];
