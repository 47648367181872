import { ChangeEvent } from "react";
import { Label, NumericInput } from "@blueprintjs/core";
import { Select } from "@blueprintjs/select";
import { SelectItem } from "@ui/Select/Item/SelectItem";

import { Button } from "@components/Button";

import styles from "./CatalogItemWeightPicker.module.scss";

type Props = {
  onWeightChange: (weight: string) => void;
  unit?: string;
  weight?: string;
  onUnitChange: (unit: string) => void;
  disabled?: boolean;
};

export enum CatalogItemWeightUnit {
  Kg = "kg",
  Lb = "lb",
  Mg = "mg",
  G = "g",
}

const CatalogItemWeightPicker = (props: Props) => {
  const { weight, unit, onUnitChange, onWeightChange, disabled } = props;

  const handleWeightChange = (event: ChangeEvent<HTMLInputElement>) => {
    const stringValue = event.target.value;
    const numericValue = Number(stringValue);
    if (isNaN(numericValue) || numericValue < 0 || (stringValue.includes(".") && stringValue.split(".")[1].length > 2)) {
      return;
    }

    onWeightChange(event.target.value);
  };

  return (
    <Label>
      <span className={styles.catalogItemWeightPickerLabel}>Weight</span>
      <div className={styles.catalogItemWeightPicker}>
        <NumericInput
          disabled={disabled}
          buttonPosition="none"
          className={styles.catalogItemWeightPickerWeight}
          value={weight}
          minorStepSize={0.01}
          min={0}
          onChange={handleWeightChange}
        />
        <Select<CatalogItemWeightUnit>
          disabled={disabled}
          filterable={false}
          items={Object.values(CatalogItemWeightUnit)}
          popoverProps={{ minimal: true, className: styles.catalogItemWeightPickerUnitSelect }}
          onItemSelect={item => onUnitChange(item)}
          itemRenderer={(item, { handleClick }) => (
            <SelectItem slug={item} key={item} label={item} onClick={handleClick} active={unit === item} />
          )}
        >
          <Button
            disabled={disabled}
            fill
            alignText="left"
            text={unit}
            rightIcon="chevron-down"
            className={styles.catalogItemWeightPickerUnitBtn}
            e2eIdentifiers="unit-button"
          />
        </Select>
      </div>
    </Label>
  );
};

export default CatalogItemWeightPicker;
