import { CreateRequirementBlockDto, RequirementBlock, RequirementBlockUpdateDto } from "@rollup-api/models";
import { trackSegmentEvent } from "src/lib/Segment";

import { HttpClient, ParentClient } from "./client";

export class RequirementBlocks extends HttpClient {
  public constructor(parent: ParentClient) {
    super(parent);
  }

  private get endpoint() {
    return `workspace/${this.parent.workspaceId}/requirement-blocks`;
  }

  public create = (dto: CreateRequirementBlockDto) => {
    trackSegmentEvent("requirement-block:create", { id: dto.id, label: dto.label });
    return this.instance.post<RequirementBlock>(`${this.endpoint}`, dto);
  };

  public update = (id: string, dto: RequirementBlockUpdateDto) => {
    trackSegmentEvent("requirement-block:update", { id, label: dto.label });
    return this.instance.patch<RequirementBlock>(`${this.endpoint}/${id}`, dto);
  };

  public delete = (id: string) => {
    trackSegmentEvent("requirement-block:delete", { id });
    return this.instance.delete<RequirementBlock>(`${this.endpoint}/${id}`);
  };

  public reorder = (id: string, destinationId: string) => {
    trackSegmentEvent("requirement-block:reorder", { id, destinationId });
    return this.instance.patch(`${this.endpoint}/${id}/reorder`, { destinationId });
  };

  public lock = (id: string) => {
    trackSegmentEvent("requirement-block:lock", { id });
    return this.instance.post<RequirementBlock>(`${this.endpoint}/${id}/lock`);
  };

  public unlock = (id: string) => {
    trackSegmentEvent("requirement-block:unlock", { id });
    return this.instance.post<RequirementBlock>(`${this.endpoint}/${id}/unlock`);
  };
}
