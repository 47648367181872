import assignIn from "lodash/assignIn";
import { action, observable } from "mobx";
import { IAnyModelType, Instance, types } from "mobx-state-tree";
import { Socket } from "socket.io-client";

import { AttributeDataType, AttributeUpdateDto, CreateAttributeDto } from "@rollup-api/models/attribute";
import { InterfaceUpdateDto } from "@rollup-api/models/interface";
import { updateAttribute } from "@rollup-api/utils";
import appStore from "@store/AppStore";
import { InterfaceStore } from "@store/InterfaceStore";

class AttributeUiStore {
  @observable accessor isCollapsed = true;

  @action.bound
  public setIsCollapsed(collapsed: boolean) {
    this.isCollapsed = collapsed;
  }

  @action.bound
  public toggleIsCollapsed() {
    this.isCollapsed = !this.isCollapsed;
  }
}

export const AttributeStore = types
  .model("Attribute", {
    id: types.identifier,
    parentInterface: types.safeReference(types.late((): IAnyModelType => InterfaceStore)),
    dataType: types.optional(types.enumeration("AttributeDataType", [...Object.values(AttributeDataType)]), AttributeDataType.scalar),
    label: types.optional(types.string, ""),
    description: types.optional(types.string, ""),
    stringValue: types.optional(types.string, ""),
    unit: types.optional(types.string, ""),
    uncertaintyValue: types.optional(types.number, 0),
    uncertaintyPercentage: types.optional(types.number, 0),
    locked: types.optional(types.boolean, false),
  })
  .volatile(() => ({
    ui: new AttributeUiStore(),
  }))
  .actions(self => ({
    patch(update: InterfaceUpdateDto) {
      // Prevent updating of fixed properties
      const invalidFields = ["id", "parentInterface", "ui"];
      const updateKeys = Object.keys(update);
      for (const field of invalidFields) {
        if (updateKeys.includes(field)) {
          return false;
        }
      }

      try {
        assignIn(self, update);
        return true;
      } catch (err) {
        console.warn(err);
        return false;
      }
    },
    setDataType(dataType: AttributeDataType) {
      self.dataType = dataType;
      updateAttribute(self.id, { dataType });
    },
    setLabel(label: string) {
      self.label = label;
      updateAttribute(self.id, { label });
    },
    setDescription(description: string) {
      self.description = description;
      updateAttribute(self.id, { description });
    },
    setStringValue(stringValue: string) {
      self.stringValue = stringValue;
      updateAttribute(self.id, { stringValue });
    },
    setUnit(unit: string) {
      self.unit = unit;
      updateAttribute(self.id, { unit });
    },
    setUncertaintyValue(uncertaintyValue: number) {
      self.uncertaintyValue = uncertaintyValue;
      updateAttribute(self.id, { uncertaintyValue });
    },
    setUncertaintyPercentage(uncertaintyPercentage: number) {
      self.uncertaintyPercentage = uncertaintyPercentage;
      updateAttribute(self.id, { uncertaintyPercentage });
    },
    setLocked(locked: boolean) {
      self.locked = locked;
      updateAttribute(self.id, { locked });
    },
    toggleLocked() {
      self.locked = !self.locked;
      updateAttribute(self.id, { locked: self.locked });
    },
  }))
  .views(self => ({
    get numericValue(): number {
      return Number(self.stringValue);
    },
    get lowerBound(): number {
      // need to use "this" to access previously defined view "numericValue":
      // https://mobx-state-tree.js.org/tips/typescript#typing-self-in-actions-and-views
      return this.numericValue - self.uncertaintyValue;
    },
    get upperBound(): number {
      return this.numericValue - self.uncertaintyValue;
    },
  }));

export function subscribeToAttributeEvents(socket: Socket) {
  socket.on("createAttribute", (data: { workspaceId: string; createAttributeDto: CreateAttributeDto }) => {
    if (data.createAttributeDto?.id && data.workspaceId === appStore.workspaceModel?.id) {
      const parentInterface = appStore.workspaceModel.interfaceMap.get(data.createAttributeDto.parentInterface);
      if (parentInterface) {
        const { label, id } = data.createAttributeDto;
        appStore.workspaceModel.addNewAttribute(parentInterface, label, id, false);
      }
    }
  });

  socket.on("deleteAttribute", (data: { workspaceId: string; id: string }) => {
    if (data.id && data.workspaceId === appStore.workspaceModel?.id) {
      const attribute = appStore.workspaceModel.attributeMap.get(data.id);
      if (attribute) {
        appStore.workspaceModel.deleteAttribute(attribute, false);
      }
    }
  });

  socket.on("updateAttribute", (data: { workspaceId: string; id: string; updateAttributeDto: AttributeUpdateDto }) => {
    if (data.id && data.workspaceId === appStore.workspaceModel?.id) {
      const attribute = appStore.workspaceModel.attributeMap.get(data.id);
      attribute?.patch(data.updateAttributeDto);
    }
  });

  socket.on("reorderAttribute", (data: { workspaceId: string; id: string; reorderAttributeDto: { destinationId: string } }) => {
    if (data.id && data.reorderAttributeDto?.destinationId && data.workspaceId === appStore.workspaceModel?.id) {
      const attribute = appStore.workspaceModel.attributeMap.get(data.id);
      attribute?.parentInterface?.moveAttribute(data.id, data.reorderAttributeDto.destinationId, false);
    }
  });
}

export interface IAttribute extends Instance<typeof AttributeStore> {}
