import { observer } from "mobx-react";

import { Tag } from "@components/Tag";
import { IComplexTag, isComplexTag, isStringTag, ITag } from "@components/TagsContainer/TagsContainer.types";
import { enhanceStringTags } from "@utilities/TagUtils";

import styles from "./TagsContainer.module.scss";

interface ITagsContainerProps<T extends ITag> {
  tags: T[];
}

const TagsContainer = <T extends ITag>(props: ITagsContainerProps<T>) => {
  const stringTags = props.tags.filter(isStringTag) as string[];
  const complexTags = props.tags.filter(isComplexTag) as IComplexTag[];
  const enhancedTags = enhanceStringTags(stringTags);
  const tags = [...complexTags, ...enhancedTags];

  const renderTag = (tag: IComplexTag) => {
    return (
      <Tag key={tag.value} color={tag.color}>
        {tag.value}
      </Tag>
    );
  };

  return <div className={styles.tagsContainer}>{tags.map(renderTag)}</div>;
};

export default observer(TagsContainer);
