import { HTMLProps, ReactNode } from "react";
import { Divider } from "@blueprintjs/core";
import classNames from "classnames";
import { observer } from "mobx-react";

import "./Pane.scss";

type PaneProps = HTMLProps<HTMLDivElement> & {
  topElement?: ReactNode;
  bottomElement?: ReactNode;
  id?: string;
  paneRef?: (node: HTMLDivElement) => void;
  containerClassName?: string;
  classaname?: string;
};

function Pane({ children, className, containerClassName, topElement, bottomElement, id, paneRef, ...rest }: PaneProps) {
  return (
    <div ref={paneRef} className={classNames("pane", className)} {...rest}>
      {/* 1. Top section. */}
      {topElement && <div>{topElement}</div>}
      {/* {topElement && children && <Divider className="m-0" />} */}

      {/* TODO - Have horizontal split bars here between sections. "react-split-pane" */}

      {/* 2. Main section. */}
      {/* IMPORTANT: Only this section is scrollable. */}
      <div id={id} className={classNames("pane--children-container", containerClassName)}>
        {children}
      </div>

      {/* 3. Bottom section. */}
      {bottomElement && <Divider className="m-0" />}
      {bottomElement && <div>{bottomElement}</div>}
    </div>
  );
}

/** Exports. */
export type { PaneProps };
export default observer(Pane);
