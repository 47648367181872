import { FormEvent, useCallback, useEffect, useRef, useState } from "react";
import { GridApi, IRowNode } from "ag-grid-community";

import { resizeRow } from "@components/Table/utils";

interface IUseCellAutoHeight<T> {
  value: string;
  node: IRowNode<T>;
  api: GridApi<T>;
  disabled?: boolean;
  resizeIfSmaller?: boolean;
  setValue?(value: string): void;
  isValid?(value: string): boolean;
}

const INVALID_CLASS = "ag-edit-focus-invalid";

export const useCellAutoHeight = <T>(params: IUseCellAutoHeight<T>) => {
  const { value, isValid, disabled, setValue, node, api, resizeIfSmaller } = params;
  const containerRef = useRef<HTMLDivElement>(null);
  const [selectionOffset, setSelectionOffset] = useState(value.length);

  const resizeContainerCallback = useCallback(
    (container: HTMLDivElement) => {
      resizeRow({ container, node, api, resizeIfSmaller, slack: 15 });
    },
    [api, node, resizeIfSmaller]
  );

  useEffect(() => {
    if (containerRef.current && !disabled) {
      resizeContainerCallback(containerRef.current);
    }
  }, [resizeContainerCallback, disabled]);

  const handleChange = (event: FormEvent<HTMLHeadingElement>) => {
    const newValue = (event.target as HTMLElement).innerText;

    if (containerRef.current) {
      if (isValid) {
        const validated = isValid(newValue);
        const classList = containerRef.current.parentElement?.classList;
        validated ? classList?.remove(INVALID_CLASS) : classList?.add(INVALID_CLASS);
      }
      resizeContainerCallback(containerRef.current);
    }

    setValue?.(newValue);
    stabilizeCaret();
  };

  const stabilizeCaret = useCallback(() => {
    const element = containerRef.current;
    const range = document.createRange();
    const selection = window.getSelection();

    if (element && selection) {
      const countElement: number = element.childNodes.length - 1;
      const childText = element.childNodes[countElement] as Text;
      const freshOffset = selection.focusOffset || (childText ? childText.length : 0);
      setSelectionOffset(freshOffset);

      if (childText) {
        const offset: number = Math.min(freshOffset, selectionOffset, childText.length);
        range.setStart(childText, Math.abs(offset) || 1);
        range.collapse(true);
        selection.removeAllRanges();
        selection.addRange(range);
      } else {
        element.focus();
      }
    }
  }, [selectionOffset]);

  return { handleChange, stabilizeCaret, containerRef };
};
