import { Tooltip } from "@blueprintjs/core";
import { CustomCellRendererProps } from "ag-grid-react";
import classNames from "classnames";
import { observer } from "mobx-react";

import { disabledTooltip } from "@components/CatalogItems/constants";
import { CatalogItemEditableVersionColumn, CatalogItemNode } from "@components/CatalogItems/Table/types";
import appStore from "@store/AppStore";
import { withObservedValueGetter } from "@utilities/AgGridUtils";

import styles from "./ValueCell.module.scss";

const ValueCell = (props: CustomCellRendererProps<CatalogItemNode>) => {
  const column = props.colDef?.colId as CatalogItemEditableVersionColumn;
  const catalogItem = props.data?.catalogItem;
  const editProgress = appStore.ui.pdmItemVersionProgress;

  if (!catalogItem || !column) {
    return null;
  }

  const isDisabled = editProgress && editProgress.catalogItemId !== catalogItem.id;
  const valueChanged = editProgress?.[column] && editProgress?.[column] !== catalogItem[column];
  const isEdited = !isDisabled && valueChanged;

  return (
    <Tooltip className={styles.valueCellPopover} content={disabledTooltip} disabled={!isDisabled}>
      <p className={classNames(styles.valueCell, { [styles.valueCellDisabled]: isDisabled, [styles.valueCellEdited]: isEdited })}>
        {props.value}
      </p>
    </Tooltip>
  );
};

export default withObservedValueGetter(observer(ValueCell));
