import { KeyboardEvent } from "react";
import { Placeholder } from "@tiptap/extension-placeholder";
import { observer } from "mobx-react";

import { EditorContent } from "@components/EditorContent";
import { useDocumentEditor } from "@components/Reports/Editor/useDocumentEditor";
import { getDocumentEditorClassNames } from "@components/Reports/Editor/utils";
import { getHeadingContent } from "@utilities/TipTap";

import EditorBubbleMenu from "../EditorBubbleMenu";
import { TEditorBlockProps } from "../types";

import "./Headings.scss";

const HeadingsReportBlock = (props: TEditorBlockProps) => {
  const { documentBlock, editable = true, eventHandlers, disableDashNavigation, onUpdate, onEditorReady } = props;
  const { onFocus, onBlur, onEnterKeyDown, onBackspaceKeyDown, onArrowDownKey, onArrowUpKey } = eventHandlers;
  const content = getHeadingContent(documentBlock.type, documentBlock.label);

  const headingNumber = documentBlock.type.match(/\d/)?.[0];
  const placeholderExtension = Placeholder.configure({ placeholder: `Heading ${headingNumber}` });
  const { editor, onBubbleMenuHide, onBubbleMenuShow } = useDocumentEditor({
    blockId: documentBlock.id,
    content,
    editable,
    disableDashNavigation,
    extensions: [placeholderExtension],
    onUpdate,
    onFocus,
    onBlur,
    onEditorReady,
  });

  const handleKeyDownEvents = (e: KeyboardEvent<HTMLDivElement>) => {
    onEnterKeyDown(e);
    onBackspaceKeyDown(e);
    onArrowUpKey(e);
    onArrowDownKey(e);
  };

  return (
    <div key={documentBlock.id} className="report-editor--headings">
      <EditorBubbleMenu onHide={onBubbleMenuHide} onShow={onBubbleMenuShow} editor={editor} />
      <EditorContent
        id={documentBlock.id}
        className={getDocumentEditorClassNames({
          annotationList: documentBlock.annotationList,
        })}
        onKeyDown={handleKeyDownEvents}
        editor={editor}
        e2eIdentifiers="report-editor"
      />
    </div>
  );
};

export default observer(HeadingsReportBlock);
