import { GridApi } from "ag-grid-community";

import { NodeInfo } from "@components/Modeling/ModelingFrame/Table/TableComponent/types";
import { IBlock } from "@store/BlockStore";

import { BLOCKS_TREE_PREFIX } from "./constants";

export const getBlocksTreeNodeId = (blockId?: string) => (blockId ? `${BLOCKS_TREE_PREFIX}-${blockId}` : "");

export const expandAllAscendants = (block: IBlock, api: GridApi<NodeInfo>) => {
  const parentBlock: IBlock | undefined = block.parentBlock;

  const nodeId = getBlocksTreeNodeId(parentBlock?.id);
  const node = nodeId ? api.getRowNode(nodeId) : undefined;

  if (!node || !parentBlock) {
    return;
  } else if (!node.expanded) {
    node.setExpanded(true);
  }

  expandAllAscendants(parentBlock, api);
};
