import { AnimateLayoutChanges, defaultAnimateLayoutChanges, useSortable } from "@dnd-kit/sortable";
import { observer } from "mobx-react";

import SortableContainer, { Props as ContainerProps } from "./SortableContainer";

const animateLayoutChanges: AnimateLayoutChanges = (args: any) =>
  defaultAnimateLayoutChanges({
    ...args,
    transition: null,
    wasDragging: false,
  });

type Props = {
  disabled?: boolean;
  isOverByItem?: boolean;
  id: string;
  newItemAbove?: boolean;
  isOver?: boolean;
  items: string[];
  renderContainer?: (handleProps: any, disabled?: boolean) => any;
};

const DroppableContainer = (props: ContainerProps & Props) => {
  const { children, disabled, id, items, renderContainer, newItemAbove, isOver, isOverByItem } = props;
  const { attributes, isDragging, listeners, setNodeRef } = useSortable({
    id,
    data: { type: "container", children: items },
    animateLayoutChanges,
  });

  return (
    <SortableContainer
      {...props}
      id={id}
      isOver={isOver}
      isEmpty={!items.length}
      isOverByItem={isOverByItem}
      newItemAbove={newItemAbove}
      renderContainer={renderContainer}
      ref={disabled ? undefined : setNodeRef}
      isDragging={isDragging}
      disabled={disabled}
      style={{
        opacity: isDragging ? 0.5 : undefined,
      }}
      handleProps={{ ...attributes, ...listeners }}
    >
      {children}
    </SortableContainer>
  );
};

export default observer(DroppableContainer);
