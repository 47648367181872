import { useEffect, useState } from "react";
import { Colors, Divider, FormGroup, H5 } from "@blueprintjs/core";
import { observer } from "mobx-react";
import tinycolor from "tinycolor2";

import { ColorButton } from "@components/Shared";
import { Switch } from "@components/Switch";
import appStore from "@store/AppStore";
import { hoopsColorFromHex } from "@utilities";

import FloatingWindow from "../FloatingWindow";

export const HoopsDebugWindow = observer(() => {
  const { hideHoopsDebugDialog } = appStore.ui;
  const viewer = appStore.env.attachmentViewer;

  // Colors
  const defaultBackground = appStore.env.themeIsDark ? Colors.DARK_GRAY3 : Colors.LIGHT_GRAY5;
  const [backgroundColorTop, setBackgroundColorTop] = useState(defaultBackground);
  const [backgroundColorBottom, setBackgroundColorBottom] = useState(defaultBackground);
  const [lineColor, setLineColor] = useState(Colors.BLACK);
  const [selectionOutlineColor, setSelectionOutlineColor] = useState(Colors.BLUE2);
  const [selectionColor, setSelectionColor] = useState(Colors.BLUE5);
  const [planeColor, setPlaneColor] = useState(tinycolor(Colors.BLUE5).setAlpha(0.1).toRgbString());

  const handlePlaneColorChanged = (color: string, alpha?: number) => {
    // combine RGB and alpha
    const newColor = tinycolor(color)
      .setAlpha(alpha ?? 1)
      .toRgbString();
    setPlaneColor(newColor);
  };

  // Various rendering settings
  const [ambientOcclusion, setAmbientOcclusion] = useState(true);
  const [antialiasingEnabled, setAntialiasingEnabled] = useState(true);
  const [silhouetteEnabled, setSilhouetteEnabled] = useState(true);
  const [hardEdgesEnabled, setHardEdgesEnabled] = useState(false);

  // Reset background colors when instance starts or theme changes
  useEffect(() => {
    setBackgroundColorTop(defaultBackground);
    setBackgroundColorBottom(defaultBackground);
  }, [viewer?.viewerInstance, defaultBackground]);

  // Reset viewer settings when instance starts
  useEffect(() => {
    setAmbientOcclusion(true);
    setAntialiasingEnabled(true);
    setSilhouetteEnabled(true);
    setHardEdgesEnabled(false);
  }, [viewer?.viewerInstance]);

  // Update viewer colors
  useEffect(() => {
    viewer?.viewerInstance?.view?.setBackgroundColor(hoopsColorFromHex(backgroundColorTop), hoopsColorFromHex(backgroundColorBottom));
  }, [viewer?.viewerInstance, backgroundColorTop, backgroundColorBottom]);
  useEffect(() => {
    if (viewer?.viewerInstance && viewer?.nodes) {
      viewer?.viewerInstance.model.setNodesLineColor(viewer?.nodes?.map(n => n.id) ?? [], hoopsColorFromHex(lineColor));
    }
  }, [viewer?.viewerInstance, lineColor, viewer?.nodes]);
  useEffect(() => {
    viewer?.viewerInstance.selectionManager.setNodeSelectionColor(hoopsColorFromHex(selectionColor));
  }, [viewer?.viewerInstance, selectionColor]);
  useEffect(() => {
    viewer?.viewerInstance.selectionManager.setNodeSelectionOutlineColor(hoopsColorFromHex(selectionOutlineColor));
  }, [viewer?.viewerInstance, selectionOutlineColor]);

  const cuttingSection = viewer?.viewerInstance.cuttingManager.getCuttingSection(0);
  useEffect(() => {
    // Split color into RGB and alpha
    const opaqueColor = tinycolor(planeColor).setAlpha(1).toRgbString();
    const alpha = tinycolor(planeColor).getAlpha();
    cuttingSection?.setColor(hoopsColorFromHex(opaqueColor));
    cuttingSection?.setOpacity(alpha);
  }, [cuttingSection, planeColor]);

  // Various rendering settings
  useEffect(() => {
    viewer?.viewerInstance?.view?.setAmbientOcclusionEnabled(ambientOcclusion);
  }, [viewer?.viewerInstance, ambientOcclusion]);
  useEffect(() => {
    viewer?.viewerInstance?.view?.setAntiAliasingMode(
      antialiasingEnabled ? Communicator.AntiAliasingMode.SMAA : Communicator.AntiAliasingMode.None
    );
  }, [viewer?.viewerInstance, antialiasingEnabled]);
  useEffect(() => {
    viewer?.viewerInstance?.view?.setSilhouetteEnabled(silhouetteEnabled);
  }, [viewer?.viewerInstance, silhouetteEnabled]);
  useEffect(() => {
    viewer?.viewerInstance?.view?.setHardEdgesEnabled(hardEdgesEnabled);
  }, [viewer?.viewerInstance, hardEdgesEnabled]);

  return (
    <FloatingWindow
      defaultSize={{ width: 200, height: 550 }}
      id="hoopsDebugWindow"
      title="HOOPS Configuration"
      resizable={false}
      onClose={hideHoopsDebugDialog}
    >
      <H5>Colors</H5>
      <FormGroup inline label="Background (top)">
        <ColorButton color={backgroundColorTop} onChange={setBackgroundColorTop} />
      </FormGroup>
      <FormGroup inline label="Background (bottom)">
        <ColorButton color={backgroundColorBottom} onChange={setBackgroundColorBottom} />
      </FormGroup>
      <FormGroup inline label="Line color">
        <ColorButton color={lineColor} onChange={setLineColor} />
      </FormGroup>
      <FormGroup inline label="Selection color">
        <ColorButton color={selectionColor} onChange={setSelectionColor} />
      </FormGroup>
      <FormGroup inline label="Selection outline color">
        <ColorButton color={selectionOutlineColor} onChange={setSelectionOutlineColor} />
      </FormGroup>
      <FormGroup inline label="Plane color">
        <ColorButton color={planeColor} onChange={handlePlaneColorChanged} showAlpha />
      </FormGroup>
      <Divider />
      <H5>Rendering</H5>
      <FormGroup inline label="Ambient Occlusion">
        <Switch checked={ambientOcclusion} onChange={() => setAmbientOcclusion(!ambientOcclusion)} e2eIdentifiers="ambient-occlusion" />
      </FormGroup>
      <FormGroup inline label="Antialiasing">
        <Switch checked={antialiasingEnabled} onChange={() => setAntialiasingEnabled(!antialiasingEnabled)} e2eIdentifiers="antialising" />
      </FormGroup>
      <FormGroup inline label="Silhouette">
        <Switch checked={silhouetteEnabled} onChange={() => setSilhouetteEnabled(!silhouetteEnabled)} e2eIdentifiers="silhouette" />
      </FormGroup>
      <FormGroup inline label="Hard edges">
        <Switch checked={hardEdgesEnabled} onChange={() => setHardEdgesEnabled(!hardEdgesEnabled)} e2eIdentifiers="hard-edges" />
      </FormGroup>
    </FloatingWindow>
  );
});
