import { useNavigate } from "react-router-dom";
import { StiggQOFactory } from "@queries/StiggQOFactory";
import { StiggClient } from "@stigg/react-sdk";
import { useQuery } from "@tanstack/react-query";
import { BlueprintIcon } from "@ui/BlueprintIcon";
import classNames from "classnames";
import { observer } from "mobx-react";

import { SubscriptionStatus } from "@rollup-types/stigg";
import { daysLeftToTrialEnd } from "@utilities/Billing";

import styles from "./FreeTrialIndicator.module.scss";

interface ITrialIndicatorProps {
  stigg: StiggClient;
}

const FreeTrialIndicator = (props: ITrialIndicatorProps) => {
  const { stigg } = props;
  const { data, isLoading } = useQuery(StiggQOFactory.createGetCustomerQO(stigg));
  const { customer, error } = data ?? {};
  const subscriptions = customer?.subscriptions;
  const navigate = useNavigate();

  if (isLoading || error || !customer || !subscriptions?.length || subscriptions[0].status !== SubscriptionStatus.InTrial) {
    return null;
  }

  const daysLeft = subscriptions[0] ? daysLeftToTrialEnd(subscriptions[0]) : 0;

  const handleUpdatePlan = () => {
    navigate("/settings/plan-and-billing");
  };

  return (
    <div
      className={classNames(styles.freeTrialIndicator, {
        [styles.freeTrialIndicatorRed]: daysLeft <= 2,
        [styles.freeTrialIndicatorOrange]: daysLeft > 2 && daysLeft < 15,
        [styles.freeTrialIndicatorGray]: daysLeft >= 15,
      })}
    >
      <BlueprintIcon icon="info-sign" size={12} />
      <span>{daysLeft} days remaining in trial</span>
      &nbsp;
      <span onClick={handleUpdatePlan} className={styles.freeTrialIndicatorButton}>
        Update plan
      </span>
    </div>
  );
};

export default observer(FreeTrialIndicator);
