import { Editor } from "@tiptap/core";
import { KeyboardShortcutCommand } from "@tiptap/react";
import { Node } from "prosemirror-model";

import { MentionExtensionType } from "@components/Reports/Editor/Extentions/Mention/MentionSuggestion";
import { IMentionTagAttributes } from "@components/Reports/Editor/Extentions/Mention/MentionTag";
import { ISimpleMentionItem, MentionType } from "@components/Reports/Editor/Extentions/Mention/types";
import { EPageName } from "@router/hooks/useAppNavigate";
import { EntityType } from "@store/types";
import { getReportById } from "@utilities/Properties";

export function getTitleFromType(type: MentionType) {
  switch (type) {
    case MentionType.Attachment:
      return "Attachments";
    case MentionType.Block:
      return "Blocks";
    case MentionType.AnalysisOutput:
      return "Code Outputs";
    case MentionType.Report:
      return "Knowledgebase";
    case MentionType.PropertyInstanceName:
    case MentionType.PropertyInstanceValue:
      return "Properties";
    case MentionType.User:
      return "Users";
  }
  return "Unknown";
}

export const getInfoFromId = (inputId: string): ISimpleMentionItem => {
  if (!inputId) {
    return { type: MentionType.Unknown, id: "" };
  }

  const mentionTypes = Object.values(MentionType);
  const idRegex = new RegExp(`^(${mentionTypes.join("|")}):(\\S+)$`);
  const matchResults = inputId.match(idRegex);
  if (matchResults?.length == 3) {
    const type = matchResults[1] as MentionType;
    const entityId = matchResults[2];
    if (mentionTypes.includes(type)) {
      return { type, id: entityId };
    }
  }
  return { type: MentionType.Unknown, id: "" };
};

// TODO add support for mentioning req docs and bom tables as well
const allowedPageNames = [EPageName.Knowledgebase];
const uuidRegex = "[0-9a-z]{8}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{12}";

/**
 * Conditions
 * - workspaceId in the text must match the provided workspaceId
 * - the page path must match one of the allowedPageNames
 * - the uuid must be a valid uuid
 */
const createPageLinkRegExp = (workspaceId: string) => {
  return new RegExp(`^https?://.*/workspaces/${workspaceId}/(?<pageName>${allowedPageNames.join("|")})/(?<id>${uuidRegex})$`);
};

const pageNameToMentionType = (pageName: EPageName): MentionType => {
  switch (pageName) {
    case EPageName.Knowledgebase:
      return MentionType.Report;
    default:
      return MentionType.Unknown;
  }
};

export const getMentionItemFromText = (link: string, workspaceId: string): ISimpleMentionItem | undefined => {
  const regex = createPageLinkRegExp(workspaceId);
  const match = link.match(regex);
  const { pageName, id } = match?.groups ?? {};

  if (pageName && id) {
    return { id, type: pageNameToMentionType(pageName as EPageName) };
  }
};

export const getMentionAttributes = (mention: ISimpleMentionItem): IMentionTagAttributes | undefined => {
  const id = `${mention.type}:${mention.id}`;

  switch (mention.type) {
    case MentionType.Report: {
      const report = getReportById(mention.id);
      if (report) {
        const label = report.label ? `${report.displayedIcon} ${report.label}` : "Untitled";
        return {
          id,
          label: label.trim(),
        };
      }
      break;
    }
    default:
      return undefined;
  }
};

export const cancelBackspaceIfMention =
  (disableNodeRemoval?: boolean): KeyboardShortcutCommand =>
  props => {
    return handleBackspaceIfMention(props, disableNodeRemoval);
  };

// This function will prevent tiptap from handling backspace if the node just before
// the cursor is a mention. The reason for that is that after removing the mention node,
// tiptap intentionally leaves the suggestion char behind, e.g. "{{" or "@"
// Adapted from @tiptap/extension-mention/src/mention.ts
export const handleBackspaceIfMention = ({ editor }: { editor: Editor }, disableNodeRemoval?: boolean) => {
  return editor.commands.command(({ tr, state }) => {
    const { selection } = state;
    if (!selection.empty) {
      return false;
    }

    const { anchor } = selection;
    let isMention = false;

    state.doc.nodesBetween(anchor - 1, anchor, (node, pos) => {
      if (isMentionNode(node)) {
        isMention = true;

        if (!disableNodeRemoval) {
          tr.insertText("", pos, pos + node.nodeSize);
        }

        return false;
      }
    });

    return isMention;
  });
};

export const isMentionNode = (node: Node) => {
  return Object.values<string>(MentionExtensionType).includes(node.type.name);
};

export const mentionTypeToEntityTypeMap: Record<MentionType, EntityType> = {
  [MentionType.Attachment]: EntityType.Attachment,
  [MentionType.Block]: EntityType.Block,
  [MentionType.AnalysisOutput]: EntityType.AnalysisOutput,
  [MentionType.Report]: EntityType.Report,
  [MentionType.PropertyInstanceName]: EntityType.PropertyInstance,
  [MentionType.PropertyInstanceValue]: EntityType.PropertyInstance,
  [MentionType.User]: EntityType.User,
  [MentionType.Unknown]: EntityType.None,
};

interface ICreateSampleEntriesParams<T> {
  recentEntities: T[];
  allEntities: T[];
  maxEntities?: number;
  isValid?(entry: T): boolean;
}

/**
 * Merges entities up to a maximum number of entries. Recent entities are prioritized.
 */
export const mergeEntities = <T>(params: ICreateSampleEntriesParams<T>): T[] => {
  const { recentEntities, allEntities, maxEntities = 3, isValid = () => true } = params;

  const filteredRecentEntries = recentEntities.filter(isValid);

  if (filteredRecentEntries.length >= maxEntities) {
    return filteredRecentEntries.slice(0, maxEntities);
  }

  const remainingEntries = maxEntities - filteredRecentEntries.length;
  const newEntries: T[] = [];

  for (const entry of allEntities) {
    if (!recentEntities.includes(entry) && isValid(entry)) {
      newEntries.push(entry);
    }
    if (newEntries.length >= remainingEntries) {
      break;
    }
  }

  return [...filteredRecentEntries, ...newEntries];
};
