import { KeyboardEvent } from "react";
import { Checkbox } from "@blueprintjs/core";
import { observer } from "mobx-react";

import { EditorContent } from "@components/EditorContent";
import { useDocumentEditor } from "@components/Reports/Editor/useDocumentEditor";
import { ENTER_KEY } from "@constants/keys";
import { RollupEditorType } from "@rollup-types/editor";

import EditorBubbleMenu from "../EditorBubbleMenu";
import PreventModEnter from "../Extentions/PreventModEnter";
import RollupPlaceholder from "../Extentions/RollupPlaceholder";
import { TEditorBlockProps } from "../types";
import { focusReportBlockById, getDocumentEditorClassNames } from "../utils";

const CheckboxBlockEditor = (props: TEditorBlockProps) => {
  const { documentBlock, editable = true, eventHandlers, disableDashNavigation, onUpdate, onEditorReady } = props;
  const { onFocus, onBlur, onEnterKeyDown, onBackspaceKeyDown, onArrowDownKey, onArrowUpKey } = eventHandlers;
  const { checked, setChecked } = documentBlock;

  const placeholderExtension = disableDashNavigation ? [] : [RollupPlaceholder];
  const { editor, onBubbleMenuHide, onBubbleMenuShow } = useDocumentEditor({
    blockId: documentBlock.id,
    content: documentBlock.label,
    editable,
    extensions: [...placeholderExtension, PreventModEnter],
    disableDashNavigation,
    onUpdate,
    onFocus,
    onBlur,
    onEditorReady,
  });

  const handleToggleCheckbox = () => {
    setChecked?.(!checked);
  };

  const handleKeyDownEvents = (e: KeyboardEvent<HTMLDivElement>) => {
    if (e.key === ENTER_KEY && !editor?.getText()) {
      documentBlock.updateText("");
      documentBlock.updateType(RollupEditorType.p, true);
      focusReportBlockById(documentBlock.id);
      return;
    }
    onEnterKeyDown(e, RollupEditorType.checklist);
    onBackspaceKeyDown(e);
    onArrowDownKey(e);
    onArrowUpKey(e);
  };

  return (
    <div key={documentBlock.id} className="report-editor--checkbox">
      <EditorBubbleMenu onHide={onBubbleMenuHide} onShow={onBubbleMenuShow} editor={editor} />
      <Checkbox className="report-editor--checkbox-btn" checked={checked} onChange={handleToggleCheckbox} />
      <EditorContent
        id={documentBlock.id}
        onKeyDown={handleKeyDownEvents}
        className={getDocumentEditorClassNames({
          annotationList: documentBlock.annotationList,
        })}
        editor={editor}
        e2eIdentifiers="report-editor"
      />
    </div>
  );
};

export default observer(CheckboxBlockEditor);
