import { Menu, MenuDivider } from "@blueprintjs/core";
import { PropertyDataType } from "@rollup-io/engineering";
import { observer } from "mobx-react";

import { getPropTypeIcon } from "@components/Block/utils";
import { Button } from "@components/Button";
import { MenuItem } from "@components/MenuItem";
import { Popover } from "@components/Popover";
import appStore from "@store/AppStore";
import { FeatureFlag } from "@store/FeatureFlagsStore";
import { IPropertyInstance } from "@store/PropertyInstanceStore";

interface IPropertyTypeMenuButtonProps {
  propertyInstance: IPropertyInstance;
  dataType: PropertyDataType;
  onDataTypeChange(value: PropertyDataType): void;
}

const PropertyTypeMenuButton = (props: IPropertyTypeMenuButtonProps) => {
  const { propertyInstance, dataType, onDataTypeChange } = props;
  const showEngineeringView = appStore.env.settingShowEngineeringView;

  if (showEngineeringView) {
    return null;
  }

  const icon = getPropTypeIcon(dataType);

  return (
    <Popover
      placement="bottom"
      content={
        <Menu>
          <MenuDivider title="Numerical" />
          <MenuItem
            icon="numerical"
            text="Scalar"
            onClick={() => onDataTypeChange(PropertyDataType.scalar)}
            selected={dataType === PropertyDataType.scalar}
            active={dataType === PropertyDataType.scalar}
            e2eIdentifiers="scalar"
          />
          {appStore.env.featureFlags.enabled(FeatureFlag.ARRAY_PROPERTY_TYPE) && (
            <MenuItem
              icon="array"
              text="Array"
              disabled={false}
              selected={dataType == PropertyDataType.array}
              active={dataType === PropertyDataType.array}
              onClick={() => onDataTypeChange(PropertyDataType.array)}
              e2eIdentifiers="array"
            />
          )}
          <MenuDivider title="Text" />
          <MenuItem
            icon="italic"
            text="String"
            selected={dataType == PropertyDataType.string}
            active={dataType === PropertyDataType.string}
            onClick={() => onDataTypeChange(PropertyDataType.string)}
            e2eIdentifiers="string"
          />
        </Menu>
      }
    >
      <Button
        minimal
        icon={icon}
        onClick={() => appStore.env.setActivePropertyInstance(propertyInstance)}
        e2eIdentifiers="block-property-list-item"
      />
    </Popover>
  );
};

export default observer(PropertyTypeMenuButton);
