import * as React from "react";
const SvgGoogleForms = (props) => /* @__PURE__ */ React.createElement("svg", { width: 16, height: 22, viewBox: "0 0 16 22", fill: "none", xmlns: "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("g", { id: "Google_Docs_Logo", clipPath: "url(#clip0_6031_5212)" }, /* @__PURE__ */ React.createElement("g", { id: "Group" }, /* @__PURE__ */ React.createElement("mask", { id: "mask0_6031_5212", style: {
  maskType: "alpha"
}, maskUnits: "userSpaceOnUse", x: -1, y: 0, width: 17, height: 22 }, /* @__PURE__ */ React.createElement("g", { id: "mask0_1:149" }, /* @__PURE__ */ React.createElement("path", { id: "Vector", d: "M9.68641 0H1.26703C0.449848 0 -0.21875 0.675 -0.21875 1.5V20.5C-0.21875 21.325 0.449848 22 1.26703 22H14.1437C14.9609 22 15.6295 21.325 15.6295 20.5V5.99999L9.68641 0Z", fill: "white" }))), /* @__PURE__ */ React.createElement("g", { mask: "url(#mask0_6031_5212)" }, /* @__PURE__ */ React.createElement("g", { id: "Group_2" }, /* @__PURE__ */ React.createElement("path", { id: "Vector_2", d: "M9.68641 0H1.26703C0.449848 0 -0.21875 0.675 -0.21875 1.5V20.5C-0.21875 21.325 0.449848 22 1.26703 22H14.1437C14.9609 22 15.6295 21.325 15.6295 20.5V5.99999L12.1627 3.5L9.68641 0Z", fill: "#774EBE" }))), /* @__PURE__ */ React.createElement("mask", { id: "mask1_6031_5212", style: {
  maskType: "alpha"
}, maskUnits: "userSpaceOnUse", x: -1, y: 0, width: 17, height: 22 }, /* @__PURE__ */ React.createElement("g", { id: "mask1_1:149" }, /* @__PURE__ */ React.createElement("path", { id: "Vector_3", d: "M9.68641 0H1.26703C0.449848 0 -0.21875 0.675 -0.21875 1.5V20.5C-0.21875 21.325 0.449848 22 1.26703 22H14.1437C14.9609 22 15.6295 21.325 15.6295 20.5V5.99999L9.68641 0Z", fill: "white" }))), /* @__PURE__ */ React.createElement("g", { mask: "url(#mask1_6031_5212)" }, /* @__PURE__ */ React.createElement("g", { id: "Group_3" }, /* @__PURE__ */ React.createElement("path", { id: "Vector_4", d: "M10.1211 5.56104L15.6296 11.121V5.99978L10.1211 5.56104Z", fill: "url(#paint0_linear_6031_5212)" }))), /* @__PURE__ */ React.createElement("mask", { id: "mask2_6031_5212", style: {
  maskType: "alpha"
}, maskUnits: "userSpaceOnUse", x: -1, y: 0, width: 17, height: 22 }, /* @__PURE__ */ React.createElement("g", { id: "mask2_1:149" }, /* @__PURE__ */ React.createElement("path", { id: "Vector_5", d: "M9.68641 0H1.26703C0.449848 0 -0.21875 0.675 -0.21875 1.5V20.5C-0.21875 21.325 0.449848 22 1.26703 22H14.1437C14.9609 22 15.6295 21.325 15.6295 20.5V5.99999L9.68641 0Z", fill: "white" }))), /* @__PURE__ */ React.createElement("g", { mask: "url(#mask2_6031_5212)" }, /* @__PURE__ */ React.createElement("g", { id: "Group_4" }, /* @__PURE__ */ React.createElement("path", { id: "Shape", d: "M6.41005 17.7638H11.8337V16.7777H6.41005V17.7638ZM6.41005 11.8471V12.8332H11.8337V11.8471H6.41005ZM5.17741 12.3402C5.17741 12.7482 4.84583 13.0798 4.43783 13.0798C4.02982 13.0798 3.69824 12.7482 3.69824 12.3402C3.69824 11.9322 4.02982 11.6006 4.43783 11.6006C4.84583 11.6006 5.17741 11.9322 5.17741 12.3402ZM5.17741 14.8054C5.17741 15.2135 4.84583 15.545 4.43783 15.545C4.02982 15.545 3.69824 15.2135 3.69824 14.8054C3.69824 14.3974 4.02982 14.0659 4.43783 14.0659C4.84583 14.0659 5.17741 14.3974 5.17741 14.8054ZM5.17741 17.2707C5.17741 17.6787 4.84583 18.0103 4.43783 18.0103C4.02982 18.0103 3.69824 17.6787 3.69824 17.2707C3.69824 16.8627 4.02982 16.5311 4.43783 16.5311C4.84583 16.5311 5.17741 16.8627 5.17741 17.2707ZM6.41005 15.2985H11.8337V14.3124H6.41005V15.2985Z", fill: "#F1F1F1" }))), /* @__PURE__ */ React.createElement("mask", { id: "mask3_6031_5212", style: {
  maskType: "alpha"
}, maskUnits: "userSpaceOnUse", x: -1, y: 0, width: 17, height: 22 }, /* @__PURE__ */ React.createElement("g", { id: "mask3_1:149" }, /* @__PURE__ */ React.createElement("path", { id: "Vector_6", d: "M9.68641 0H1.26703C0.449848 0 -0.21875 0.675 -0.21875 1.5V20.5C-0.21875 21.325 0.449848 22 1.26703 22H14.1437C14.9609 22 15.6295 21.325 15.6295 20.5V5.99999L9.68641 0Z", fill: "white" }))), /* @__PURE__ */ React.createElement("g", { mask: "url(#mask3_6031_5212)" }, /* @__PURE__ */ React.createElement("g", { id: "Group_5" }, /* @__PURE__ */ React.createElement("path", { id: "Vector_7", d: "M9.68652 0V4.49999C9.68652 5.32875 10.3514 5.99999 11.1723 5.99999H15.6296L9.68652 0Z", fill: "#B9A5DE" }))), /* @__PURE__ */ React.createElement("mask", { id: "mask4_6031_5212", style: {
  maskType: "alpha"
}, maskUnits: "userSpaceOnUse", x: -1, y: 0, width: 17, height: 22 }, /* @__PURE__ */ React.createElement("g", { id: "mask4_1:149" }, /* @__PURE__ */ React.createElement("path", { id: "Vector_8", d: "M9.68641 0H1.26703C0.449848 0 -0.21875 0.675 -0.21875 1.5V20.5C-0.21875 21.325 0.449848 22 1.26703 22H14.1437C14.9609 22 15.6295 21.325 15.6295 20.5V5.99999L9.68641 0Z", fill: "white" }))), /* @__PURE__ */ React.createElement("g", { mask: "url(#mask4_6031_5212)" }, /* @__PURE__ */ React.createElement("g", { id: "Group_6" }, /* @__PURE__ */ React.createElement("path", { id: "Vector_9", d: "M1.26703 0C0.449848 0 -0.21875 0.675 -0.21875 1.5V1.625C-0.21875 0.799999 0.449848 0.125 1.26703 0.125H9.68641V0H1.26703Z", fill: "white", fillOpacity: 0.2 }))), /* @__PURE__ */ React.createElement("mask", { id: "mask5_6031_5212", style: {
  maskType: "alpha"
}, maskUnits: "userSpaceOnUse", x: -1, y: 0, width: 17, height: 22 }, /* @__PURE__ */ React.createElement("g", { id: "mask5_1:149" }, /* @__PURE__ */ React.createElement("path", { id: "Vector_10", d: "M9.68641 0H1.26703C0.449848 0 -0.21875 0.675 -0.21875 1.5V20.5C-0.21875 21.325 0.449848 22 1.26703 22H14.1437C14.9609 22 15.6295 21.325 15.6295 20.5V5.99999L9.68641 0Z", fill: "white" }))), /* @__PURE__ */ React.createElement("g", { mask: "url(#mask5_6031_5212)" }, /* @__PURE__ */ React.createElement("g", { id: "Group_7" }, /* @__PURE__ */ React.createElement("path", { id: "Vector_11", d: "M14.1437 21.875H1.26703C0.449848 21.875 -0.21875 21.2 -0.21875 20.375V20.5C-0.21875 21.325 0.449848 22 1.26703 22H14.1437C14.9609 22 15.6295 21.325 15.6295 20.5V20.375C15.6295 21.2 14.9609 21.875 14.1437 21.875Z", fill: "#774EBE" }))), /* @__PURE__ */ React.createElement("mask", { id: "mask6_6031_5212", style: {
  maskType: "alpha"
}, maskUnits: "userSpaceOnUse", x: -1, y: 0, width: 17, height: 22 }, /* @__PURE__ */ React.createElement("g", { id: "mask6_1:149" }, /* @__PURE__ */ React.createElement("path", { id: "Vector_12", d: "M9.68641 0H1.26703C0.449848 0 -0.21875 0.675 -0.21875 1.5V20.5C-0.21875 21.325 0.449848 22 1.26703 22H14.1437C14.9609 22 15.6295 21.325 15.6295 20.5V5.99999L9.68641 0Z", fill: "white" }))), /* @__PURE__ */ React.createElement("g", { mask: "url(#mask6_6031_5212)" }, /* @__PURE__ */ React.createElement("g", { id: "Group_8" }, /* @__PURE__ */ React.createElement("path", { id: "Vector_13", d: "M11.1723 6C10.3514 6 9.68652 5.32876 9.68652 4.5V4.625C9.68652 5.45377 10.3514 6.12501 11.1723 6.12501H15.6296V6H11.1723Z", fill: "#774EBE" })), /* @__PURE__ */ React.createElement("path", { id: "Vector_14", d: "M9.68641 0H1.26703C0.449848 0 -0.21875 0.675 -0.21875 1.5V20.5C-0.21875 21.325 0.449848 22 1.26703 22H14.1437C14.9609 22 15.6295 21.325 15.6295 20.5V5.99999L9.68641 0Z", fill: "url(#paint1_radial_6031_5212)" })))), /* @__PURE__ */ React.createElement("defs", null, /* @__PURE__ */ React.createElement("linearGradient", { id: "paint0_linear_6031_5212", x1: 12.8757, y1: 6.03844, x2: 12.8757, y2: 11.1218, gradientUnits: "userSpaceOnUse" }, /* @__PURE__ */ React.createElement("stop", { stopColor: "#1A237E", stopOpacity: 0.2 }), /* @__PURE__ */ React.createElement("stop", { offset: 1, stopColor: "#1A237E", stopOpacity: 0.02 })), /* @__PURE__ */ React.createElement("radialGradient", { id: "paint1_radial_6031_5212", cx: 0, cy: 0, r: 1, gradientUnits: "userSpaceOnUse", gradientTransform: "translate(0.28333 0.43483) scale(25.5551 25.6509)" }, /* @__PURE__ */ React.createElement("stop", { stopColor: "white", stopOpacity: 0.1 }), /* @__PURE__ */ React.createElement("stop", { offset: 1, stopColor: "white", stopOpacity: 0 })), /* @__PURE__ */ React.createElement("clipPath", { id: "clip0_6031_5212" }, /* @__PURE__ */ React.createElement("rect", { width: 16, height: 22, fill: "white" }))));
export default SvgGoogleForms;
