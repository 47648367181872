import { useMemo } from "react";
import { MenuDivider } from "@blueprintjs/core";
import { resolveIdentifier } from "mobx-state-tree";

import { MenuItem } from "@components/MenuItem";
import { CodeBlockStore, ICodeBlock } from "@store/Analysis/CodeBlockStore";
import appStore from "@store/AppStore";
import { AttachmentStore, IAttachment } from "@store/AttachmentStore";
import { BlockStore, IBlock } from "@store/BlockStore";
import { BomTableStore, IBomTable } from "@store/BomTable/BomTableStore";
import { CommentStore, IComment } from "@store/CommentStore";
import { CustomUnitStore, ICustomUnit } from "@store/CustomUnitStore";
import { FeatureFlag } from "@store/FeatureFlagsStore";
import { IPropertyDefinition, PropertyDefinitionStore } from "@store/PropertyDefinitionStore";
import { IPropertyInstance, PropertyInstanceStore } from "@store/PropertyInstanceStore";
import { IReportBlock, ReportBlockStore } from "@store/ReportBlockStore";
import { IReport, ReportStore } from "@store/ReportsStore";
import { IRequirementBlock, IRequirementsPage, RequirementBlockStore, RequirementsPageStore } from "@store/RequirementsStore";
import { IStatusDefinition, StatusDefinitionStore } from "@store/StatusDefinitionStore";
import { IStatusInstance, StatusInstanceStore } from "@store/StatusInstanceStore";
import {
  copyToClipboard,
  linkToAttachment,
  linkToBlock,
  linkToBomTable,
  linkToCodeBlock,
  linkToComment,
  linkToCustomUnit,
  linkToPropertyDefinition,
  linkToPropertyInstance,
  linkToReport,
  linkToReportBlock,
  linkToRequirementBlock,
  linkToRequirementsPage,
  linkToStatusDefinition,
  linkToStatusInstance,
} from "@utilities";

import { showToast } from "../UiLayers/toaster";

export type LinkableNode =
  | IBlock
  | IPropertyInstance
  | IPropertyDefinition
  | IStatusInstance
  | IStatusDefinition
  | IComment
  | IAttachment
  | IRequirementsPage
  | IRequirementBlock
  | IReport
  | IReportBlock
  | IBomTable
  | ICodeBlock
  | ICustomUnit;

export const CopyLinkMenuItem = ({ node }: { node: LinkableNode }) => {
  const { link, nodeName } = useMemo(() => {
    const id = node.id;
    const block = resolveIdentifier(BlockStore, appStore.workspaceModel, id);
    if (block) {
      return { link: linkToBlock(block.id), nodeName: "block" };
    }

    const propInstance = resolveIdentifier(PropertyInstanceStore, appStore.workspaceModel, id);
    if (propInstance) {
      return { link: linkToPropertyInstance(propInstance), nodeName: "property" };
    }

    const propDefinition = resolveIdentifier(PropertyDefinitionStore, appStore.workspaceModel, id);
    if (propDefinition) {
      return { link: linkToPropertyDefinition(propDefinition), nodeName: "definition" };
    }

    const statusInstance = resolveIdentifier(StatusInstanceStore, appStore.workspaceModel, id);
    if (statusInstance) {
      return { link: linkToStatusInstance(statusInstance), nodeName: "status" };
    }

    const statusDefinition = resolveIdentifier(StatusDefinitionStore, appStore.workspaceModel, id);
    if (statusDefinition) {
      return { link: linkToStatusDefinition(statusDefinition), nodeName: "definition" };
    }

    const attachment = resolveIdentifier(AttachmentStore, appStore.workspaceModel, id);
    if (attachment) {
      return { link: linkToAttachment(attachment), nodeName: "attachment" };
    }

    const comment = resolveIdentifier(CommentStore, appStore.workspaceModel, id);
    if (comment) {
      return { link: linkToComment(comment), nodeName: "comment" };
    }

    const requirementsPage = resolveIdentifier(RequirementsPageStore, appStore.workspaceModel, id);
    if (requirementsPage) {
      return { link: linkToRequirementsPage(requirementsPage), nodeName: "page" };
    }

    const requirementBlock = resolveIdentifier(RequirementBlockStore, appStore.workspaceModel, id);
    if (requirementBlock) {
      return { link: linkToRequirementBlock(requirementBlock), nodeName: "block" };
    }

    const report = resolveIdentifier(ReportStore, appStore.workspaceModel, id);
    if (report) {
      return { link: linkToReport(report), nodeName: "page" };
    }

    const reportBlock = resolveIdentifier(ReportBlockStore, appStore.workspaceModel, id);
    if (reportBlock) {
      return { link: linkToReportBlock(reportBlock), nodeName: "block" };
    }

    const bomTable = resolveIdentifier(BomTableStore, appStore.workspaceModel, id);
    if (bomTable) {
      return { link: linkToBomTable(bomTable), nodeName: "table" };
    }

    const codeBlock = resolveIdentifier(CodeBlockStore, appStore.workspaceModel, id);
    if (codeBlock) {
      return { link: linkToCodeBlock(codeBlock), nodeName: "code block" };
    }

    const customUnit = resolveIdentifier(CustomUnitStore, appStore.orgModel.customUnits, id);
    if (customUnit) {
      return { link: linkToCustomUnit(customUnit), nodeName: "custom unit" };
    }

    return { link: undefined, nodeName: "unknown" };
  }, [node]);

  const copyLink = async () => {
    if (link) {
      try {
        await copyToClipboard(link);
        showToast("Copied link to clipboard", "success", "info-sign");
      } catch (err) {
        console.error(err);
        showToast("Error copying link to clipboard", "danger", "error");
      }
    }
  };

  if (!appStore.env.featureFlags.enabled(FeatureFlag.DEBUG_COPY_URL)) {
    return null;
  }

  return (
    <>
      <MenuDivider />
      <MenuItem onClick={copyLink} icon="clipboard" text={`Copy link to ${nodeName}`} e2eIdentifiers={[`copy-link-to-${nodeName}`]} />
    </>
  );
};
