import { useState } from "react";
import { Tab } from "@blueprintjs/core";
import { useSearchParam } from "@hooks/useSearchParam/useSearchParam";
import { useWorkspace } from "@hooks/useWorkspace";
import { observer } from "mobx-react";

import { Button } from "@components/Button";
import { AddDataConnectionDialog } from "@components/DataSources/AddDataConnectionDialog";
import DataSinkDrawer from "@components/DataSources/AddDataSink/DataSinkDrawer";
import { AddDataSource } from "@components/DataSources/AddDataSource";
import { DataConnectionType } from "@components/DataSources/utils";
import { SlidingPanel } from "@components/SlidingPanel";
import { Tabs } from "@components/Tabs";
import { TabTitle } from "@components/TabTitle";
import { IDataConnection } from "@store/DataConnection/DataConnectionModuleStore";
import { IDataSink } from "@store/DataConnection/DataSinkStore";

import DataConnectionsTable from "./Table/DataConnectionsTable";

import styles from "./DataConnectionsFrame.module.scss";

enum DataConnectionTab {
  All = "All",
  DataSources = "DataSources",
  DataSinks = "DataSinks",
}

/** Main function. */
function DataConnectionsFrame() {
  const [selectedTab, setSelectedTab] = useState(DataConnectionTab.All);
  const [showAddDataConnectionDialog, setShowAddDataConnectionDialog] = useState(false);
  const [addDataConnectionType, setAddDataConnectionType] = useState<DataConnectionType>();
  const [editingDataSink, setEditingDataSink] = useState<IDataSink>();
  const [dataConnectionIdSearchParam] = useSearchParam("id");
  const [hasFlashed, setHasFlashed] = useState(false);
  const workspace = useWorkspace();
  const { dataSources, dataSinks, allDataConnections } = workspace.dataConnection;

  const isAddingDataSource = addDataConnectionType === DataConnectionType.DataSource;
  const isAddingDataSink = addDataConnectionType === DataConnectionType.DataSink || !!editingDataSink;
  const clearAddDataConnectionType = () => setAddDataConnectionType(undefined);

  const handleCloseDataSinkDrawer = () => {
    clearAddDataConnectionType();
    setEditingDataSink(undefined);
  };

  const handleAddConnection = (value: DataConnectionType) => {
    setShowAddDataConnectionDialog(false);
    setEditingDataSink(undefined);
    setAddDataConnectionType(value);
  };

  const renderTable = (dataConnections: IDataConnection[]) => {
    return (
      <DataConnectionsTable
        dataConnections={dataConnections}
        dataConnectionIdToFlash={hasFlashed ? undefined : dataConnectionIdSearchParam}
        onEditDataSink={setEditingDataSink}
        onFlashedDataConnection={() => setHasFlashed(true)}
      />
    );
  };

  if (isAddingDataSource) {
    return <AddDataSource onGoBack={clearAddDataConnectionType} />;
  }

  return (
    <div className={styles.dataConnectionsFrame}>
      {showAddDataConnectionDialog && (
        <AddDataConnectionDialog onClose={() => setShowAddDataConnectionDialog(false)} onContinue={handleAddConnection} />
      )}
      <div className={styles.dataConnectionsFrameMain}>
        <div className={styles.dataConnectionsFrameHeader}>
          <h1>Data connections</h1>
          <Button
            onClick={() => setShowAddDataConnectionDialog(true)}
            icon="plus"
            large
            intent="primary"
            e2eIdentifiers="add-data-connection"
          >
            Add Data connection
          </Button>
        </div>
        <Tabs id="data-connection" selectedTabId={selectedTab} onChange={tabId => setSelectedTab(tabId as DataConnectionTab)} large>
          <Tab
            className={styles.dataConnectionsFrameTab}
            id={DataConnectionTab.All}
            title={<TabTitle title="All connections" count={allDataConnections.length} />}
            panel={renderTable(allDataConnections)}
          />
          <Tab
            className={styles.dataConnectionsFrameTab}
            id={DataConnectionTab.DataSources}
            title={<TabTitle title="Data sources" count={dataSources.length} />}
            panel={renderTable(dataSources)}
          />
          <Tab
            className={styles.dataConnectionsFrameTab}
            id={DataConnectionTab.DataSinks}
            title={<TabTitle title="Data sinks" count={dataSinks.length} />}
            panel={renderTable(dataSinks)}
          />
        </Tabs>
      </div>
      <SlidingPanel isOpen={isAddingDataSink} width={350}>
        <DataSinkDrawer onClose={handleCloseDataSinkDrawer} editingDataSink={editingDataSink} />
      </SlidingPanel>
    </div>
  );
}

/** Exports. */
export default observer(DataConnectionsFrame);
