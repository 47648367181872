import { Subscription } from "@stigg/react-sdk";

import { daysLeftToDate } from "@utilities/Date";

export const daysLeftToTrialEnd = (subscription: Subscription) => {
  const { trialEndDate } = subscription;

  // Need to add +1 to match the same behavior as in the Stigg widget, where 0.5 days are rounded up to 1 day
  return trialEndDate ? daysLeftToDate(trialEndDate) + 1 : 0;
};
