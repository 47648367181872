import { useEffect, useState } from "react";
import classNames from "classnames";
import { observer } from "mobx-react";

import AnnotationMarkerDoubleDark from "@assets/icons/annotations/annotation-marker-double-dark.svg?react";
import AnnotationMarkerDoubleLight from "@assets/icons/annotations/annotation-marker-double-light.svg?react";
import AnnotationMarkerEditDark from "@assets/icons/annotations/annotation-marker-edit-dark.svg?react";
import AnnotationMarkerEditLight from "@assets/icons/annotations/annotation-marker-edit-light.svg?react";
import AnnotationMarkerSingleDark from "@assets/icons/annotations/annotation-marker-single-dark.svg?react";
import AnnotationMarkerSingleLight from "@assets/icons/annotations/annotation-marker-single-light.svg?react";
import AnnotationMarkerTripleDark from "@assets/icons/annotations/annotation-marker-triple-dark.svg?react";
import AnnotationMarkerTripleLight from "@assets/icons/annotations/annotation-marker-triple-light.svg?react";
import { Profile } from "@rollup-api/models/profiles";
import { IAnnotation } from "@store/AnnotationStore";
import appStore from "@store/AppStore";

import Avatar from "../Avatar/Avatar";

import styles from "./SmartAnnotationMarker.module.scss";

interface SmartAnnotationMarkerProps {
  annotation: IAnnotation;
  isExpanded: boolean;
  onClick?: () => void;
}

const SmartAnnotationMarker = ({ annotation, isExpanded, onClick }: SmartAnnotationMarkerProps) => {
  const [userImages, setUserImages] = useState<string[]>([]);

  const renderMarkerIcon = (size: number) => {
    if (size === 0) {
      return appStore.env.themeIsDark ? <AnnotationMarkerEditDark /> : <AnnotationMarkerEditLight />;
    }
    if (size === 1) {
      return appStore.env.themeIsDark ? <AnnotationMarkerSingleDark /> : <AnnotationMarkerSingleLight />;
    }
    if (size === 2) {
      return appStore.env.themeIsDark ? <AnnotationMarkerDoubleDark /> : <AnnotationMarkerDoubleLight />;
    }
    if (size >= 3) {
      return appStore.env.themeIsDark ? <AnnotationMarkerTripleDark /> : <AnnotationMarkerTripleLight />;
    }
  };

  useEffect(() => {
    const getAndSetUserImages = async (userIds: string[], commentCount: number): Promise<void> => {
      const images: string[] = await Promise.all(
        userIds.map(async userId => {
          const userProfile: Profile | null = await appStore.orgModel?.info.getUserProfile(userId);
          return userProfile?.avatarUrl || "";
        })
      );
      if (images.length > 0) {
        setUserImages(images);
        return;
      }
      if (commentCount > 0) {
        setUserImages([appStore.userModel?.avatarUrl || ""]);
      }
    };

    void getAndSetUserImages(annotation.topCommentingUsers, annotation.commentCount);
  }, [annotation.commentCount, annotation.topCommentingUsers]);

  return (
    <div
      className={classNames(styles.marker, {
        [styles.markerExpanded]: isExpanded,
        [styles.behindView]: annotation.locationData3D?.isBehindView,
        [styles.inFrontOfView]: !annotation.locationData3D?.isBehindView,
      })}
      onClick={() => onClick?.()}
    >
      {renderMarkerIcon(userImages.length)}
      {Array.from(userImages).map((image, index) => (
        <div key={index} className={styles.avatar}>
          <Avatar source={image} size={22} />
        </div>
      ))}
    </div>
  );
};

export default observer(SmartAnnotationMarker);
