import { useParams } from "react-router-dom";
import { observer } from "mobx-react";

import { GitHubIntegrationCallout } from "@components/IntegrationCallout";
import appStore from "@store/AppStore";
import { IIntegration } from "@store/IntegrationStore";

import ConnectIntegrationCard from "./ConnectIntegrationCard";

const GithubSettingsInternal = () => {
  const { integrationId } = useParams();
  const githubIntegrationConfig: IIntegration | undefined = appStore.settingsModel?.integrationsMap.get(integrationId || "");

  if (!githubIntegrationConfig) {
    return null;
  }

  return (
    <>
      <ConnectIntegrationCard
        integration={githubIntegrationConfig}
        description="Connect your Github account to get access to repositories, merge request & issues to be able to use them as attachments in your workspace."
      />
      {githubIntegrationConfig.connection && <GitHubIntegrationCallout integration={githubIntegrationConfig.connection} />}
    </>
  );
};

export default observer(GithubSettingsInternal);
