import { Intent } from "@blueprintjs/core";
import { UseMutationOptions, UseQueryOptions } from "@tanstack/react-query";

import { showApiErrorToast, showToast } from "@components/UiLayers/toaster";
import { ICreateDataSinkDto, IUpdateDataSinkDto } from "@rollup-api/models/data-sinks";
import { DataSink } from "@rollup-api/models/data-sinks/data-sink.model";
import appStore from "@store/AppStore";

import { QueryKeys, rollupClient } from "../core/api";
import { queryClient } from "../lib/ReactQuery/queryClient";

interface IGetDataSinkOptions extends Omit<UseQueryOptions<DataSink[]>, "queryKey" | "queryFn"> {}
interface IDeleteDataSinkOptions extends Omit<UseMutationOptions<DataSink, unknown, string>, "mutationFn"> {}
interface IPostDataSinkOptions extends Omit<UseMutationOptions<DataSink, unknown, ICreateDataSinkDto>, "mutationFn"> {}

/**
 * A factory for creating Data Sink Query Objects
 */
export abstract class DataSinkQOFactory {
  public static createGetAllQO(workspaceId: string, options: IGetDataSinkOptions = {}): UseQueryOptions<DataSink[]> {
    return {
      queryKey: [QueryKeys.DataSinkList, workspaceId],
      queryFn: () => rollupClient.modelingModule.dataSinks.getAll().then(res => res.data),
      ...options,
    };
  }

  public static createPostQO(
    workspaceId: string,
    options: IPostDataSinkOptions = {}
  ): UseMutationOptions<DataSink, Error, ICreateDataSinkDto> {
    return {
      mutationFn: dto => rollupClient.modelingModule.dataSinks.create(dto).then(res => res.data),
      onSuccess: (data, variables, context) => {
        appStore.workspaceModel?.dataConnection.addDataSink(data);
        showToast("Successfully created data sink", Intent.SUCCESS);
        queryClient.invalidateQueries({ queryKey: [QueryKeys.DataSinkList, workspaceId] });
        options?.onSuccess?.(data, variables, context);
      },
      onError: err => {
        const errorMsg = "Error adding data sink";
        console.warn(errorMsg, err);
        showApiErrorToast(errorMsg);
      },
    };
  }

  public static createDeleteQO(
    id: string,
    workspaceId: string,
    options: IDeleteDataSinkOptions = {}
  ): UseMutationOptions<DataSink, Error, string> {
    const queryKey = [QueryKeys.DataSinkList, workspaceId];
    return {
      mutationFn: () => rollupClient.modelingModule.dataSinks.delete(id).then(res => res.data),
      onSuccess: (_, id) => {
        showToast("Successfully deleted data sink", Intent.SUCCESS);
        appStore.workspaceModel?.dataConnection.deleteDataSink(id, true);
        queryClient.setQueryData<DataSink[]>(queryKey, prev => prev?.filter(d => d.id !== id));
      },
      onError: err => {
        const errorMsg = "Error deleting data sink";
        console.warn(errorMsg, err);
        showApiErrorToast(errorMsg);
      },
      ...options,
    };
  }

  public static createPatchQO(workspaceId: string): UseMutationOptions<DataSink, Error, { id: string; dto: IUpdateDataSinkDto }> {
    return {
      mutationFn: ({ id, dto }) => rollupClient.modelingModule.dataSinks.update(id, dto).then(res => res.data),
      onSuccess: (_data, { id, dto }) => {
        appStore.workspaceModel?.dataConnection.updateDataSink(id, dto);
        showToast("Successfully updated data sink", Intent.SUCCESS);
        queryClient.invalidateQueries({ queryKey: [QueryKeys.DataSinkList, workspaceId] });
      },
      onError: err => {
        const errorMsg = "Error updating data sink";
        console.warn(errorMsg, err);
        showApiErrorToast(errorMsg);
      },
    };
  }
}
