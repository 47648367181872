export enum CreateCatalogItemDialogType {
  Manual = "manual",
  CSV = "csv",
  CAD = "cad",
  Block = "block",
}

export enum LeadPeriod {
  Days = "D",
  Months = "M",
  Weeks = "W",
  Years = "Y",
}

export const leadPeriodLabel = {
  [LeadPeriod.Days]: "Day",
  [LeadPeriod.Weeks]: "Week",
  [LeadPeriod.Months]: "Month",
  [LeadPeriod.Years]: "Year",
};
