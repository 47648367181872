import { BlueprintIcon } from "@ui/BlueprintIcon";
import classNames from "classnames";
import { observer } from "mobx-react";

import CollapsibleCard from "@components/CatalogItemPreview/Components/CollapsibleCard";
import CatalogItemVersionLabel from "@components/CatalogItemVersionLabel";
import appStore from "@store/AppStore";
import { ICatalogItem } from "@store/CatalogItem/CatalogItemStore";
import { PdmCard } from "@store/PdmCardsVisibilityStore";

import { tooltipContent } from "./constants";

import styles from "./VersionsCard.module.scss";

type Props = {
  catalogItem: ICatalogItem;
  onVersionClick: (versionId: string) => void;
  activeVersion: string;
  isVertical?: boolean;
};

const VersionsCard = (props: Props) => {
  const { catalogItem, onVersionClick, activeVersion, isVertical } = props;

  return (
    <CollapsibleCard
      visible={isVertical ? true : appStore.env.pdmCardVisibility.versions}
      onToggle={() => appStore.env.pdmCardVisibility.toggleCard(PdmCard.Versions)}
      disabled={isVertical}
      minWidth={240}
      height={isVertical ? 275 : undefined}
      title="Versions"
      tooltip={tooltipContent}
    >
      {catalogItem.sortedVersions.map(version => (
        <div
          key={version.id}
          className={classNames(styles.versionsCardItem, { [styles.versionsCardItemActive]: activeVersion === version.id })}
          onClick={() => onVersionClick(version.id)}
        >
          <BlueprintIcon icon="history" />
          <CatalogItemVersionLabel showComment version={version} />
        </div>
      ))}
    </CollapsibleCard>
  );
};

export default observer(VersionsCard);
