import { useEffect, useRef, useState } from "react";
import { Checkbox, Intent, Menu, MenuDivider } from "@blueprintjs/core";
import { observer } from "mobx-react";

import { Button } from "@components/Button";
import useThumbnailActions from "@components/CatalogItems/Cells/PdmThumbnailCell/useThumbnailActions";
import { rowHeight, thumbnailRowHeight } from "@components/CatalogItems/Table/constants";
import { getRowId } from "@components/CatalogItems/Table/utils";
import { useCatalogItemImageUrl } from "@components/CatalogItems/useCatalogItemImage";
import { MenuItem } from "@components/MenuItem";
import { MenuItemDelete } from "@components/MenuItems";
import { Popover } from "@components/Popover";
import appStore from "@store/AppStore";
import { getAssetLink } from "@utilities/LinkUtils";

import { TCellRendererProps } from "../types";

import styles from "./PdmActionsCell.module.scss";

const PdmActionsCell = (props: TCellRendererProps) => {
  const { registerRowDragger, node, api } = props;
  const catalogItem = props.data?.catalogItem;
  const buttonRef = useRef<HTMLButtonElement>(null);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [image] = useCatalogItemImageUrl(catalogItem);
  const assetLink = getAssetLink(image) ?? "";
  const { onCopy, onDownload } = useThumbnailActions(assetLink || "", () => setIsPopoverOpen(false));

  useEffect(() => {
    buttonRef.current && registerRowDragger(buttonRef.current);
  }, [registerRowDragger, buttonRef]);

  if (!catalogItem || !props.data) {
    return null;
  }

  const rowId = getRowId(props.data);
  const handleRemoveRow = () => {
    appStore.ui.setDeleteCatalogItem(rowId);
  };

  const showPdmThumbnailForId = appStore.ui.showPdmThumbnailForIds.includes(rowId);

  const handleToggleShowPdmThumbnail = () => {
    setIsPopoverOpen(false);
    appStore.ui.toggleShowPdmThumbnailForId(rowId);
    if (showPdmThumbnailForId || appStore.env.showPdmThumbnail) {
      node.setRowHeight(thumbnailRowHeight);
    } else {
      node.setRowHeight(rowHeight);
    }
    api.onRowHeightChanged();
  };

  const getActionsMenu = () => (
    <Menu>
      <Checkbox
        className={styles.pdmActionsCellCheckbox}
        label="Show thumbnail"
        onClick={handleToggleShowPdmThumbnail}
        checked={showPdmThumbnailForId}
      />
      {image && (
        <>
          <MenuItem icon="duplicate" text="Copy thumbnail png" onClick={onCopy} e2eIdentifiers="copy" />
          <MenuItem icon="download" text="Download thumbnail" onClick={onDownload} e2eIdentifiers="download" />
        </>
      )}
      <MenuDivider />
      <MenuItemDelete text="Delete item" onDelete={handleRemoveRow} e2eIdentifiers="delete-block" />
    </Menu>
  );

  return (
    <Popover isOpen={isPopoverOpen} onInteraction={setIsPopoverOpen} canEscapeKeyClose placement="bottom-start" content={getActionsMenu()}>
      <Button
        buttonRef={buttonRef}
        small
        minimal
        icon="drag-handle-vertical"
        intent={Intent.NONE}
        e2eIdentifiers={["catalog-item-action", catalogItem.id]}
      />
    </Popover>
  );
};

export default observer(PdmActionsCell);
