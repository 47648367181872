import { CreateReportDto, CreateRequirementsPageDto } from "@rollup-api/models";

export enum EntityType {
  None = "none",
  Attachment = "attachment",
  Block = "block",
  BomTable = "bom-table",
  Comment = "comment",
  CodeBlock = "code-block",
  AnalysisOutput = "analysis-output",
  AnalysisInput = "analysis-input",
  DataLink = "data-link",
  DataSource = "data-source",
  DataSink = "data-sink",
  DataSinkEntry = "data-sink-entry",
  PropertyInstance = "property-instance",
  PropertyDefinition = "property-definition",
  Report = "report",
  ReportBlock = "report-block",
  RequirementsBlock = "requirements-block",
  RequirementsDocument = "requirements-document",
  StatusDefinition = "status-definition",
  StatusInstance = "status-instance",
  User = "user",
  Workspace = "workspace",
}

export type ICreateReportData = Partial<CreateReportDto>;
export type ICreateRequirementsPageData = Partial<CreateRequirementsPageDto>;

export enum StoreType {
  Annotation = "annotation",
  Attachment = "attachment",
  Block = "block",
  BOM = "bom",
  PropertyInstance = "property-instance",
  Report = "report",
  ReportBlock = "report-block",
  Requirement = "requirement",
  RequirementBlock = "requirement-block",
}
