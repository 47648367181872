import { ReactFlowProvider } from "reactflow";
import { observer } from "mobx-react";

import FlowViewBlocks from "@components/Modeling/ModelingFrame/FlowView/FlowViewBlocks/FlowViewBlocks";

import "./ThreadsHierarchy.scss";

function ThreadsHierarchy() {
  return (
    <div className="component--threads-hierarchy">
      <ReactFlowProvider>
        <FlowViewBlocks />
      </ReactFlowProvider>
    </div>
  );
}

export default observer(ThreadsHierarchy);
