import { Profile, ProfilesUpdateDto } from "../models/profiles";

import { HttpClient, ParentClient } from "./client";

export class Profiles extends HttpClient {
  public constructor(parent: ParentClient) {
    super(parent);
  }

  private readonly endpoint: string = "/profile";

  public retrieveProfile = () => {
    return this.instance.get<Profile>(this.endpoint);
  };

  public setWorkspace = (id: string) => {
    return this.instance.post<Profile>(`${this.endpoint}/set-workspace?workspaceId=${id}`);
  };

  public clearWorkspace = () => {
    return this.instance.delete<Profile>(`${this.endpoint}/clear-workspace`);
  };

  public updateProfile = (dto: ProfilesUpdateDto) => {
    return this.instance.patch<Profile>(this.endpoint, dto);
  };

  public changePassword = (newPassword: string, currentPassword?: string) => {
    return this.instance.post(`${this.endpoint}/change-password`, { newPassword, currentPassword });
  };

  public uploadImage = (file: File) => {
    const data = new FormData();
    data.append("file", file);
    return this.instance.put<Profile>(`${this.endpoint}/upload-avatar`, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  };
}
