import { observer } from "mobx-react-lite";

import { useAttachmentFunctions } from "@components/Modeling/ModelingFrame/ModelBlock/Attachments/attachmentHooks";
import { AttachmentType } from "@rollup-api/models/cloudStorage";
import { IAttachment } from "@store/AttachmentStore";
import { IBlock } from "@store/BlockStore";

import AttachmentContextMenuFile from "./AttachmentContextMenuFile";
import AttachmentContextMenuUrl from "./AttachmentContextMenuUrl";

interface IAttachmentContextMenuProps {
  attachment: IAttachment;
  block: IBlock;
  onOpenEditingLabel(): void;
  onOpenDetailsDialog(): void;
}

export const AttachmentContextMenu = observer(function AttachmentContextMenu(props: IAttachmentContextMenuProps) {
  const { attachment, block } = props;
  const { onOpenEditingLabel, onOpenDetailsDialog } = props;
  const { handleDownload, handleRetry, handleDelete, handleAddVersion } = useAttachmentFunctions({
    attachment,
    block,
  });

  switch (attachment.type) {
    case AttachmentType.file:
      return (
        <AttachmentContextMenuFile
          attachment={attachment}
          onAddVersion={handleAddVersion}
          onDelete={handleDelete}
          onDownload={handleDownload}
          onRetry={handleRetry}
          onOpenEditingLabel={onOpenEditingLabel}
          onOpenDetailsDialog={onOpenDetailsDialog}
        />
      );
    case AttachmentType.integration:
    case AttachmentType.reference:
    case AttachmentType.url:
      return (
        <AttachmentContextMenuUrl
          attachment={attachment}
          handleDelete={handleDelete}
          openEditingLabel={onOpenEditingLabel}
          setIsDetailsDialogOpen={onOpenDetailsDialog}
        />
      );
    default:
      return null;
  }
});
