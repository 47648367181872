import { PopoverProps } from "@blueprintjs/core";
import { ItemPredicate, ItemRenderer, Select } from "@blueprintjs/select";
import classNames from "classnames";
import { observer } from "mobx-react";

import { Button } from "@components/Button";
import { MenuItem } from "@components/MenuItem";
import { LabeledEntity } from "@store/WorkspaceStore";

import "./EntitySelectDropdown.scss";

const filterItems: ItemPredicate<LabeledEntity> = (query, item: LabeledEntity, _index, exactMatch) => {
  const normalizedTitle = item.label.toLowerCase();
  const normalizedQuery = query.toLowerCase();

  if (exactMatch) {
    return normalizedTitle === normalizedQuery;
  } else {
    return normalizedTitle.indexOf(normalizedQuery) >= 0;
  }
};

const renderItem: ItemRenderer<LabeledEntity> = (item, { handleClick, handleFocus, modifiers }) => {
  if (!modifiers.matchesPredicate) {
    return null;
  }
  return (
    <MenuItem
      active={modifiers.active}
      disabled={modifiers.disabled}
      key={item.id}
      onClick={handleClick}
      onFocus={handleFocus}
      text={item.label}
      roleStructure="listoption"
      e2eIdentifiers={[item.label]}
    />
  );
};

export type BlockSelectProps<T> = {
  selectedItem: T | undefined;
  items: T[];
  onItemSelect: (item: T) => void;
  placeholder?: string;
  className?: string;
  disabled?: boolean;
  popoverProps?: Partial<Omit<PopoverProps, "content" | "defaultIsOpen" | "fill" | "renderTarget">>;
};

export const EntitySelectDropdown = observer(function <T extends LabeledEntity>({
  className,
  selectedItem,
  placeholder,
  ...rest
}: BlockSelectProps<T>) {
  return (
    <Select<T>
      className={classNames("entity-select-button", className)}
      itemPredicate={filterItems}
      itemRenderer={renderItem}
      noResults={<MenuItem disabled text="No results." roleStructure="listoption" e2eIdentifiers="no-results" />}
      {...rest}
    >
      <Button
        text={selectedItem?.label ?? placeholder ?? "Select item"}
        rightIcon="double-caret-vertical"
        e2eIdentifiers="select-item"
        fill
      />
    </Select>
  );
});
