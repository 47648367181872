import { isValidElement, ReactNode } from "react";
import { Tag, TagInput as BPTagInput } from "@blueprintjs/core";
import { HTMLInputProps } from "@blueprintjs/core/src/common/props";
import { observer } from "mobx-react";

import { UserPermission } from "@rollup-api/api/authTypes";
import appStore from "@store/AppStore";
import { Text } from "src/ui/Text";

import "./TagInput.scss";

interface ITagInputProps {
  values: string[];
  inputProps?: HTMLInputProps;
  onChange(values: string[]): void;
}

const TagInput = (props: ITagInputProps) => {
  const { values, inputProps, onChange } = props;

  const handleChange = (values: ReactNode[]) => {
    const keys = values.map(value => (isValidElement(value) ? value.key : value)).filter(Boolean) as string[];
    onChange(keys);
  };

  return (
    <BPTagInput
      className="tag-input"
      inputProps={inputProps}
      onChange={handleChange}
      values={values.map(value => (
        <Tag key={value}>
          <Text>{value}</Text>
        </Tag>
      ))}
      disabled={!appStore.userModel?.hasPermission(UserPermission.UpdateOrganization)}
      large
    />
  );
};

export default observer(TagInput);
