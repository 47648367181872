import { useEffect, useState } from "react";
import { PopoverPosition } from "@blueprintjs/core";
import { BlueprintIcon } from "@ui/BlueprintIcon";
import classNames from "classnames";
import { observer } from "mobx-react";

import { Tooltip } from "@components/Tooltip";
import { Color } from "@rollup-types/colors";
import { sortedColors } from "@utilities/Color";

import styles from "./ColorPicker.module.scss";

interface IColorPickerProps {
  selected?: Color;
  colors?: Color[];
  onChange?(color: Color): void;
}

const getColorClassName = (color: Color) => {
  switch (color) {
    case Color.Blue:
      return styles.colorPickerBlue;
    case Color.Cerulean:
      return styles.colorPickerCerulean;
    case Color.Forest:
      return styles.colorPickerForest;
    case Color.Gold:
      return styles.colorPickerGold;
    case Color.Gray:
      return styles.colorPickerGray;
    case Color.Green:
      return styles.colorPickerGreen;
    case Color.Indigo:
      return styles.colorPickerIndigo;
    case Color.Lime:
      return styles.colorPickerLime;
    case Color.Orange:
      return styles.colorPickerOrange;
    case Color.Red:
      return styles.colorPickerRed;
    case Color.Rose:
      return styles.colorPickerRose;
    case Color.Turquoise:
      return styles.colorPickerTurquoise;
    case Color.Vermilion:
      return styles.colorPickerVermilion;
    case Color.Violet:
      return styles.colorPickerViolet;
    default:
      return styles.colorPickerDefault;
  }
};

const ColorPicker = (props: IColorPickerProps) => {
  const { colors = sortedColors, onChange } = props;
  const [selected = Color.Default, setSelected] = useState(props.selected);

  useEffect(() => {
    if (props.selected !== selected) {
      setSelected(props.selected);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.selected]);

  const handleClick = (color: Color) => {
    setSelected(color);
    onChange?.(color);
  };

  return (
    <div className={styles.colorPicker}>
      {colors.map(color => {
        const isSelected = color === selected;
        return (
          <Tooltip key={color} content={color} position={PopoverPosition.TOP}>
            <div onClick={() => handleClick(color)} className={classNames(styles.colorPickerItemOuterContainer, getColorClassName(color))}>
              <div
                className={classNames(styles.colorPickerItem, getColorClassName(color), {
                  [styles.colorPickerItemSelected]: isSelected,
                })}
              >
                <BlueprintIcon
                  className={classNames(styles.colorPickerTickIcon, { [styles.colorPickerItemSelected]: isSelected })}
                  icon="tick"
                />
              </div>
            </div>
          </Tooltip>
        );
      })}
    </div>
  );
};

export default observer(ColorPicker);
