import { JSX } from "react";
import { Icon } from "@blueprintjs/core";
import { BlueprintIcon } from "@ui/BlueprintIcon";
import Text, { TextColor, TextVariant } from "@ui/Text";
import classNames from "classnames";
import { observer } from "mobx-react";

import { Tag } from "@components/Tag";
import { Tooltip } from "@components/Tooltip";

import styles from "./CollapsibleCard.module.scss";

const COLLAPSED_WIDTH = 48;

type Props = {
  title: string;
  tooltip?: string;
  className?: string;
  version?: string | number;
  disabled?: boolean;
  visible: boolean;
  onToggle: () => void;
  minHeight?: number | "auto";
  height?: number | "auto";
  minWidth?: number | "auto";
  width?: number | "auto";
  rightActions?: JSX.Element;
  children?: JSX.Element[] | JSX.Element;
};

const CollapsibleCard = (props: Props) => {
  const { onToggle, disabled, title, tooltip, visible, version, rightActions, children, className, minHeight, minWidth, width, height } =
    props;

  return (
    <div
      className={classNames(styles.collapsibleCard, className, { [styles.collapsibleCardCollapsed]: !visible })}
      style={{
        minHeight,
        minWidth: visible ? minWidth : COLLAPSED_WIDTH,
        width: visible ? width : COLLAPSED_WIDTH,
        height,
      }}
    >
      <div className={styles.collapsibleCardHeader}>
        <div className={styles.collapsibleCardHeaderLeft}>
          {!disabled && <BlueprintIcon onClick={onToggle} icon="double-chevron-right" className={styles.collapsibleCardCollapseBtn} />}
          <Text variant={TextVariant.H4} color={TextColor.Secondary}>
            {title}
          </Text>
          {tooltip && visible && (
            <Tooltip content={tooltip}>
              <Icon color={TextColor.Secondary} className={styles.collapsibleCardInfo} size={12} icon="info-sign" />
            </Tooltip>
          )}
          {version !== undefined && visible && (
            <Tooltip content="Current version">
              <Tag minimal>Ver {version}</Tag>
            </Tooltip>
          )}
        </div>
        {rightActions && visible && <div className={styles.collapsibleCardHeaderRight}>{rightActions}</div>}
      </div>
      {visible && children}
    </div>
  );
};

export default observer(CollapsibleCard);
