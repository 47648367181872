import { Component } from "react";
import { Card, Classes, Collapse, EditableText, H5, Intent, Menu, MenuDivider, PopoverInteractionKind } from "@blueprintjs/core";
import classNames from "classnames";
import { observer } from "mobx-react";

import { AnchorButton } from "@components/AnchorButton";
import { Button } from "@components/Button";
import { MenuItem } from "@components/MenuItem";
import { MenuItemDelete } from "@components/MenuItems";
import { Popover } from "@components/Popover";
import { RenderFunction } from "@components/Shared/SortableList/GenericDraggableItem";
import { GenericSortableList, SortableItemComponent } from "@components/Shared/SortableList/SortableList";
import { SafeTooltip } from "@components/SmallComponents";
import { InterfaceType } from "@rollup-api/models/interface";
import appStore from "@store/AppStore";
import { IAttribute } from "@store/AttributeStore";
import { IBlock } from "@store/BlockStore";
import { IInterface } from "@store/InterfaceStore";
import { interfaceContactIcon, interfaceWaterIcon } from "@utilities/CIcon";

import InterfaceAttributeComponent from "./Attributes/InterfaceAttributeComponent";

import "./InterfaceComponent.scss";

export interface InterfaceComponentProps extends SortableItemComponent {
  interface: IInterface;
  block: IBlock;
}

class InterfaceComponent extends Component<InterfaceComponentProps> {
  handleNewAttribute = () => {
    this.props.interface.addNewAttribute();
  };

  handleInterfaceTypeChanged = (interfaceType: InterfaceType) => {
    this.props.interface.setInterfaceType(interfaceType);
  };

  private static InterfaceTypeIcon = (interfaceType: InterfaceType | "info" | "physical") => {
    switch (interfaceType) {
      case "physical":
      case InterfaceType.contact:
        return interfaceContactIcon;
      case InterfaceType.electrical:
        return "lightning";
      case InterfaceType.fluid:
        return interfaceWaterIcon;
      case InterfaceType.flux:
        return "antenna";
      case "info":
      case InterfaceType.data:
        return "swap-horizontal";
      default:
        return "blank";
    }
  };

  private renderInterfaceMenuItem = (interfaceType: InterfaceType) => {
    return (
      <MenuItem
        key={interfaceType}
        className="interface-type-menu-item"
        icon={InterfaceComponent.InterfaceTypeIcon(interfaceType)}
        text={interfaceType}
        onClick={() => this.handleInterfaceTypeChanged(interfaceType)}
        active={interfaceType === this.props.interface?.interfaceType}
        e2eIdentifiers={[interfaceType]}
      />
    );
  };

  private renderAttributeItem: RenderFunction<IAttribute> = (item, dragging, dragListeners) => (
    <InterfaceAttributeComponent dragListeners={dragListeners} isDragging={dragging} attribute={item} interface={this.props.interface} />
  );

  render() {
    const currentInterface = this.props.interface;

    const interfaceTypeMenu = (
      <Menu>
        <MenuDivider title="Info" />
        {this.renderInterfaceMenuItem(InterfaceType.data)}
        <MenuDivider title="Physical" />
        {[InterfaceType.contact, InterfaceType.electrical, InterfaceType.fluid, InterfaceType.flux].map(t =>
          this.renderInterfaceMenuItem(t)
        )}
      </Menu>
    );

    const className = classNames("interface-component", { [Classes.DARK]: appStore.env.themeIsDark });

    const interfaceMenu = (
      <Menu>
        <MenuDivider title="Actions" />
        <MenuItemDelete onDelete={() => this.props.block.deleteInterface(currentInterface)} />
      </Menu>
    );

    const isCollapsed = this.props.isDragging || !currentInterface.ui.isOpen;

    return (
      <div className={className}>
        <div className="interface-header dnd-drag-handle-item">
          <H5>
            <Popover
              inheritDarkTheme
              interactionKind={PopoverInteractionKind.CLICK}
              hoverCloseDelay={500}
              placement="left-start"
              content={interfaceMenu}
            >
              <Button
                {...this.props.dragListeners}
                className="interface-attribute-menu-button"
                minimal
                intent={Intent.NONE}
                icon="drag-handle-vertical"
                e2eIdentifiers={["interface-component", "drag"]}
              />
            </Popover>
            <EditableText
              key={`${currentInterface.id}-${currentInterface.label}`}
              defaultValue={currentInterface.label}
              confirmOnEnterKey
              onConfirm={currentInterface.setLabel}
              placeholder="Interface Label"
            />
          </H5>
          <Popover
            interactionKind={PopoverInteractionKind.CLICK}
            placement="bottom"
            content={interfaceTypeMenu}
            popoverClassName="interface-type-menu"
          >
            <Button
              className="interface-type-menu-item"
              minimal
              intent={Intent.PRIMARY}
              icon={InterfaceComponent.InterfaceTypeIcon(currentInterface.interfaceType)}
              text={currentInterface.interfaceType}
              e2eIdentifiers={["interface-component", currentInterface.interfaceType]}
            />
          </Popover>
          <SafeTooltip content="Interface Documentation">
            <Button
              onClick={currentInterface.ui.toggleIsOpen}
              minimal
              className="drop-down-button"
              rightIcon={currentInterface.ui.isOpen ? "chevron-down" : "chevron-right"}
              e2eIdentifiers={["interface-component", "arrow"]}
            />
          </SafeTooltip>
        </div>

        <Collapse isOpen={!isCollapsed} keepChildrenMounted>
          <Card className="attribute-card">
            <EditableText
              defaultValue={currentInterface.description}
              key={`${currentInterface.id}-${currentInterface.description}`}
              multiline
              maxLines={6}
              confirmOnEnterKey
              onConfirm={currentInterface.setDescription}
              placeholder="Interface Description"
              className="interface-description-input"
            />
            <br />
            <GenericSortableList
              items={this.props.interface.validatedAttributes}
              onDragEnd={this.props.interface.moveAttribute}
              renderItem={this.renderAttributeItem}
            />
            <AnchorButton
              minimal
              small
              className="block-add-button"
              alignText="left"
              icon="small-plus"
              disabled={!currentInterface.replicated}
              onClick={this.handleNewAttribute}
              text="Attribute"
              e2eIdentifiers="new-attribute"
            />
          </Card>
        </Collapse>
      </div>
    );
  }
}

export default observer(InterfaceComponent);
