import { ReactHTML } from "react";
import { TextVariant } from "@ui/Text/Text.types";

import styles from "./Text.module.scss";

export const getElementTag = (variant: TextVariant): keyof ReactHTML => {
  switch (variant) {
    case TextVariant.H1:
    case TextVariant.ReportH1:
      return "h1";
    case TextVariant.H2:
    case TextVariant.ReportH2:
      return "h2";
    case TextVariant.H3:
    case TextVariant.ReportH3:
      return "h3";
    case TextVariant.H4:
    case TextVariant.ReportH4:
      return "h4";
    case TextVariant.H5:
    case TextVariant.ReportH5:
      return "h5";
    case TextVariant.ReportH6:
      return "h6";
    case TextVariant.Label:
      return "label";
    default:
      return "span";
  }
};

export const getElementClass = (variant: TextVariant): string => {
  switch (variant) {
    case TextVariant.H1:
    case TextVariant.H2:
    case TextVariant.H3:
    case TextVariant.H4:
    case TextVariant.H5:
      return styles.typography;
    case TextVariant.Label:
      return styles.label;
    case TextVariant.Caption:
      return styles.caption;
    case TextVariant.Code:
      return styles.code;
    case TextVariant.CodeCaps:
      return styles.codeCaps;
    case TextVariant.Value:
      return styles.value;
    case TextVariant.Button1:
      return styles.button1;
    case TextVariant.Button1Underline:
      return styles.button1Underline;
    case TextVariant.Button2:
      return styles.button2;
    case TextVariant.Button2Underline:
      return styles.button2Underline;
    case TextVariant.Body:
      return styles.body;
    case TextVariant.BodyDimmed:
      return styles.bodyDimmed;
    case TextVariant.ReportTitle:
      return styles.reportTypographyTitle;
    case TextVariant.ReportH1:
    case TextVariant.ReportH2:
    case TextVariant.ReportH3:
    case TextVariant.ReportH4:
    case TextVariant.ReportH5:
    case TextVariant.ReportH6:
      return styles.reportTypography;
    case TextVariant.ReportBody:
      return styles.reportTypographyBody;
    case TextVariant.ReportCaption:
      return styles.reportTypographyCaption;
    default:
      return styles.body;
  }
};

export const isTruncated = (e: HTMLElement | null) => {
  if (e) {
    return e.offsetHeight < e.scrollHeight || e.offsetWidth < e.scrollWidth;
  }
  return false;
};
