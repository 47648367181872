import { IAnyModelType, Instance, types } from "mobx-state-tree";

import { BlockStore } from "../BlockStore";

import { BomColumnStore } from "./BomColumnStore";
import { BomTableStore } from "./BomTableStore";

export const BomCellStore = types.model("BomCell", {
  id: types.identifier,
  table: types.safeReference(types.late((): IAnyModelType => BomTableStore)),
  column: types.safeReference(types.late((): IAnyModelType => BomColumnStore)),
  block: types.safeReference(BlockStore),
  value: types.string,
  orderIndex: types.number,
});

export interface IBomCell extends Instance<typeof BomCellStore> {}
