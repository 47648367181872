import { useContext } from "react";

import { WorkspaceContext } from "@providers/workspace";
import { IWorkspace } from "@store/WorkspaceStore";

export const useWorkspace = (): IWorkspace => {
  const workspace = useContext(WorkspaceContext);

  if (!workspace) {
    throw new Error("No WorkspaceContext.Provider found when calling useWorkspace.");
  }

  return workspace;
};
