import { CSSProperties, useEffect, useMemo, useState } from "react";
import { Intent } from "@blueprintjs/core";
import { useWorkspace } from "@hooks/useWorkspace";
import classNames from "classnames";
import { observer } from "mobx-react";

import { Button } from "@components/Button";
import { InputGroup } from "@components/InputGroup";
import { useAppNavigate } from "@router/hooks";
import { IDataConnection } from "@store/DataConnection/DataConnectionModuleStore";
import { isDataSink, isDataSource } from "@utilities/DataConnection";
import { getDataLinkString, getDataSinkEntryString } from "@utilities/DataConnectionUtils";

import { DataSinkPanel } from "../DataSinkPanel";

import { DataConnectionDropdownSidePanel } from "./DataConnectionDropdownSidePanel";
import { DataSourcePanel } from "./DataSourcePanel";

import styles from "./DataConnectionDropdown.module.scss";

interface IDataConnectionDropdownProps {
  initialSearchTerm?: string;
  initialDataConnection?: IDataConnection;
  isSimplifiedVersion?: boolean;
  style?: CSSProperties;
  onAddDataConnection(value: string): void;
}

const DataConnectionDropdown = (props: IDataConnectionDropdownProps) => {
  const { initialSearchTerm = "", initialDataConnection, isSimplifiedVersion, style, onAddDataConnection } = props;
  const [searchTerm, setSearchTerm] = useState(initialSearchTerm);
  const [selectedDataConnection, setSelectedDataConnection] = useState<IDataConnection | undefined>(initialDataConnection);
  const [selectedEntryId, setSelectedEntryId] = useState<string | undefined>();
  const [query, setQuery] = useState("");
  const [selectedSheet, setSelectedSheet] = useState("");
  const { navigateToDataConnections } = useAppNavigate();
  const workspace = useWorkspace();
  const { allDataConnections } = workspace.dataConnection;
  const filteredDataConnections = useMemo(
    () => allDataConnections.filter(ds => !searchTerm || ds.label.toLowerCase().includes(searchTerm.toLowerCase())),
    [allDataConnections, searchTerm]
  );

  useEffect(() => {
    if (isDataSink(selectedDataConnection)) {
      setSelectedEntryId(selectedDataConnection.entries[0]?.id);
    }
  }, [selectedDataConnection]);

  useEffect(() => {
    if (filteredDataConnections.length && !selectedDataConnection) {
      setSelectedDataConnection(filteredDataConnections[0]);
    }
  }, [filteredDataConnections, selectedDataConnection]);

  const getDataConnectionString = () => {
    if (isDataSource(selectedDataConnection)) {
      return getDataLinkString({ dataSourceId: selectedDataConnection.id, sheet: selectedSheet, query });
    } else if (isDataSink(selectedDataConnection) && selectedEntryId) {
      return getDataSinkEntryString(selectedEntryId);
    }
  };

  const handleAddDataConnection = () => {
    const dataConnectionString = getDataConnectionString();
    if (dataConnectionString) {
      onAddDataConnection(dataConnectionString);
    }
  };

  const renderMainPanel = () => {
    if (isDataSource(selectedDataConnection)) {
      return (
        <DataSourcePanel
          query={query}
          selectedSheet={selectedSheet}
          dataSource={selectedDataConnection}
          // In the simple version there's no sidebar, therefore we can use a bigger initial preview range
          initialPreviewRange={isSimplifiedVersion ? "A1:L10" : undefined}
          onQueryChanged={setQuery}
          onSheetChanged={setSelectedSheet}
        />
      );
    } else if (isDataSink(selectedDataConnection)) {
      return <DataSinkPanel selectedEntryId={selectedEntryId} dataSink={selectedDataConnection} onSelectEntry={setSelectedEntryId} />;
    }
  };

  return (
    <div className={styles.dataConnectionDropdown} style={style}>
      {!isSimplifiedVersion && (
        <InputGroup
          leftIcon="search"
          placeholder="Search"
          value={searchTerm}
          onChange={e => setSearchTerm(e.target.value)}
          e2eIdentifiers="search"
        />
      )}
      <div className={styles.dataConnectionDropdownMain}>
        {!isSimplifiedVersion && (
          <DataConnectionDropdownSidePanel
            dataConnections={filteredDataConnections}
            selectedDataConnection={selectedDataConnection}
            onSelect={setSelectedDataConnection}
          />
        )}
        {renderMainPanel()}
      </div>
      <div
        className={classNames(styles.dataConnectionDropdownFooter, {
          [styles.dataConnectionDropdownFooterSimpleVersion]: isSimplifiedVersion,
        })}
      >
        {!isSimplifiedVersion && (
          <Button icon="add" onClick={() => navigateToDataConnections()} e2eIdentifiers="create-new-data-source" minimal>
            Create new data connection
          </Button>
        )}
        <Button
          onClick={handleAddDataConnection}
          className={styles.dataConnectionDropdownAddButton}
          intent={Intent.PRIMARY}
          e2eIdentifiers="add-data-source"
        >
          Add
        </Button>
      </div>
    </div>
  );
};

export default observer(DataConnectionDropdown);
