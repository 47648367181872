import { KeyboardEvent, useEffect, useState } from "react";
import { useUpdateEditorContent } from "@hooks/useUpdateEditorContent";
import { EditorEvents } from "@tiptap/core";
import Link from "@tiptap/extension-link";
import { Placeholder } from "@tiptap/extension-placeholder";
import Underline from "@tiptap/extension-underline";
import { useEditor } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import classNames from "classnames";
import { observer } from "mobx-react";

import { EditorContent } from "@components/EditorContent";
import EditorBubbleMenu from "@components/Reports/Editor/EditorBubbleMenu";
import { getMentionExtensions } from "@components/Reports/Editor/Extentions/Mention/MentionSuggestion";
import PreventEnter from "@components/Reports/Editor/Extentions/PreventEnter";
import { useBubbleMenu } from "@components/Reports/Editor/hooks/useBubbleMenu";
import appStore from "@store/AppStore";

import styles from "./RichTextEditor.module.scss";

interface IRichTextEditorProps {
  content?: string;
  placeholder?: string;
  className?: string;
  noWrap?: boolean;
  autoFocus?: boolean;
  isEditable?: boolean;
  isFocused?: boolean;
  onCommit?(value: string): void;
  onCancel?(): void;
  onChange?(value: string): void;
}

const RichTextEditor = (props: IRichTextEditorProps) => {
  const { content: contentProp = "", placeholder = "", onChange } = props;
  const { className, noWrap, autoFocus = false, isEditable, onCommit, onCancel } = props;
  const [isFocused, setIsFocused] = useState(props.isFocused);
  const [content, setContent] = useState(contentProp);
  const workspace = appStore.workspaceModel ?? undefined;
  const { onHide: onBubbleMenuHide, onShow, isOpen: bubbleNavIsOpen } = useBubbleMenu();

  const handleUpdate = ({ editor }: EditorEvents["update"]) => {
    const html = editor.isEmpty ? "" : editor.getHTML();
    setContent(html);
    onChange?.(html);
  };

  const placeholderExtension = Placeholder.configure({ placeholder, showOnlyWhenEditable: false });

  const editor = useEditor({
    extensions: [StarterKit, Underline, Link, PreventEnter, ...getMentionExtensions({ workspace }), placeholderExtension],
    content,
    editable: isEditable,
    autofocus: autoFocus,
    onFocus: () => setIsFocused(true),
    onBlur: () => setIsFocused(false),
    onUpdate: handleUpdate,
  });

  useEffect(() => {
    setIsFocused(props.isFocused);
  }, [props.isFocused]);

  useEffect(() => {
    setContent(contentProp);
  }, [contentProp]);

  useEffect(() => {
    if (isEditable) {
      editor?.commands.focus();
    }
    if (isEditable !== editor?.isEditable) {
      editor?.setOptions({ editable: isEditable });
    }
  }, [isEditable, editor]);

  useUpdateEditorContent({ content, editor, shouldNotUpdate: bubbleNavIsOpen });

  if (!editor) {
    return null;
  }

  const handleKeyDown = (event: KeyboardEvent<HTMLDivElement>) => {
    if (event.key === "Enter") {
      onCommit?.(content);
      editor.commands.blur();
    } else if (event.key === "Escape") {
      onCancel?.();
      setContent(contentProp);
      editor.commands.blur();
    }
  };

  const handleBlur = () => {
    if (content !== contentProp) {
      onCommit?.(content);
    }
  };

  return (
    <div className={classNames(styles.richTextEditor, className, { [styles.readonly]: !isEditable, [styles.noWrap]: noWrap })}>
      <EditorBubbleMenu className="rich-text-cell-editor--bubble-menu" onHide={onBubbleMenuHide} editor={editor} onShow={onShow} />
      <EditorContent
        e2eIdentifiers="rich-text-editor"
        className={classNames(styles.richTextEditorEditor, className, {
          [styles.editable]: isEditable,
        })}
        editor={editor}
        onKeyDown={handleKeyDown}
        onBlur={handleBlur}
        isFocused={isFocused}
      />
    </div>
  );
};

export default observer(RichTextEditor);
