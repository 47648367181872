import { useCallback } from "react";
import { DateInput3 } from "@blueprintjs/datetime2";
import { Locale } from "date-fns";
import { observer } from "mobx-react";

import appStore from "@store/AppStore";
import type { IBlock } from "@store/BlockStore";
import type { IStatusDefinition } from "@store/StatusDefinitionStore";

import "./StatusTypeDateEditor.scss";

/** Type defs. */
type StatusTypeDateEditorProps = {
  statusDefinition?: IStatusDefinition;
  block?: IBlock | null;
  hidePlaceholder?: boolean;
  disabled?: boolean;
};

//Adapted from https://github.com/palantir/blueprint/issues/6481#issuecomment-1772803082
const loadDateFnsLocale: (localeCode: string) => Promise<Locale> = async localeCode => {
  const localeModule = await import(`../../../node_modules/date-fns/esm/locale/${localeCode}/index.js`);
  return localeModule.default;
};

/** Main function. */
function StatusTypeDateEditor({ statusDefinition, block, hidePlaceholder, disabled }: StatusTypeDateEditorProps) {
  const formatDate = useCallback((date: Date) => date.toLocaleString("en-US", { year: "numeric", month: "short", day: "2-digit" }), []);
  const parseDate = useCallback((str: string) => new Date(str), []);

  if (!statusDefinition || !block) {
    return null;
  }

  // This should be calculated through the store.
  const statusInstance = block.validatedStatusInstances.find(i => i.statusDefinition === statusDefinition);

  const setValue = (value: string) => {
    if (!statusInstance) {
      if (!value) {
        return; // Don't create new instances for empty values.
      }
      appStore.workspaceModel?.addStatusInstance(block, statusDefinition, value);
    } else {
      statusInstance.setValue(value);
    }
  };

  return (
    <div className="status-type-date-editor">
      <DateInput3
        className="status-type-date-editor--date-input"
        dateFnsLocaleLoader={loadDateFnsLocale}
        disabled={disabled}
        placeholder={hidePlaceholder ? "" : "MM/DD/YYYY"}
        inputProps={{ small: true }}
        value={statusInstance?.value}
        onChange={selectedDate => setValue(selectedDate ?? "")}
        parseDate={parseDate}
        formatDate={formatDate}
        showActionsBar
        dayPickerProps={{
          className: "status-type-date-editor--day-picker",
        }}
        popoverProps={{
          minimal: true,
        }}
      />
    </div>
  );
}

/** Exports. */
export type { StatusTypeDateEditorProps };
export default observer(StatusTypeDateEditor);
