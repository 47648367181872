import { MouseEvent } from "react";
import { useWorkspace } from "@hooks/useWorkspace";

import { EntityType } from "@store/types";
import { IE2EIdentifiers } from "@utilities/E2EUtils";

interface IUseFavoriteParams {
  entityId: string;
  entityType: EntityType;
}

export const useFavorite = (params: IUseFavoriteParams) => {
  const { entityId, entityType } = params;
  const { favorites } = useWorkspace();
  const isFavorite = favorites.isFavorite(entityId);
  const e2eIdentifiers: IE2EIdentifiers = isFavorite ? "remove-favorite" : "add-favorite";
  const tooltip = isFavorite ? "Remove from favorites" : "Add to favorites";

  const handleToggleFavorite = (e: MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();

    if (isFavorite) {
      favorites.removeEntityFromFavorites(entityId);
    } else {
      favorites.addToFavorites(entityId, entityType);
    }
  };

  return {
    e2eIdentifiers,
    isFavorite,
    tooltip,
    handleToggleFavorite,
  };
};
