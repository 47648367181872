import classNames from "classnames";
import { observer } from "mobx-react";

import { Button } from "@components/Button";
import AnnotationsPanel from "@components/Modeling/ModelingFrame/HoopsViewer/UI/RightPanel/AnnotationsPanel";
import ModelDetailsPanel from "@components/Modeling/ModelingFrame/HoopsViewer/UI/RightPanel/ModelDetailsPanel";
import ViewsPanel from "@components/Modeling/ModelingFrame/HoopsViewer/UI/RightPanel/ViewsPanel";
import { Selector, SelectorProps } from "@components/Selector";
import appStore from "@store/AppStore";
import { IAttachment } from "@store/AttachmentStore";
import { HoopsSidePanelState } from "@store/EnvironmentStore";

import "./RightPanel.scss";

type Props = {
  viewer: Communicator.WebViewer | undefined;
  attachment: IAttachment;
};

const selectorOptions: SelectorProps["options"] = [
  {
    id: HoopsSidePanelState.Annotations,
    label: "Annotations",
    e2eIdentifiers: "annotations",
  },
  {
    id: HoopsSidePanelState.SavedViews,
    label: "Saved views",
    e2eIdentifiers: "saved-views",
  },
];

const RightPanel = (props: Props) => {
  const { viewer, attachment } = props;
  const isOpen = appStore.env.hoopsSidePanelState !== HoopsSidePanelState.Closed;
  const isAnnotationTab = appStore.env.hoopsSidePanelState === HoopsSidePanelState.Annotations;
  const isSavedViewsTab = appStore.env.hoopsSidePanelState === HoopsSidePanelState.SavedViews;
  const isModelDetailsTab = appStore.env.hoopsSidePanelState === HoopsSidePanelState.ModelDetails;

  const toggleSidePanelState = (state: HoopsSidePanelState) => {
    if (appStore.env.hoopsSidePanelState === state) {
      appStore.env.setHoopsSidePanelState(HoopsSidePanelState.Closed);
    } else {
      appStore.env.setHoopsSidePanelState(state);
    }
  };

  const renderContent = () => {
    switch (appStore.env.hoopsSidePanelState) {
      case HoopsSidePanelState.Annotations:
        return <AnnotationsPanel viewer={viewer} attachment={attachment} />;
      case HoopsSidePanelState.SavedViews:
        return <ViewsPanel attachment={attachment} />;
      case HoopsSidePanelState.ModelDetails:
        return <ModelDetailsPanel attachment={attachment} />;
      default:
        return null;
    }
  };

  return (
    <div
      className={classNames("hoops-right-panel", {
        ["hoops-right-panel--wide"]: isOpen,
      })}
    >
      <div className="hoops-right-panel--content">
        {!isModelDetailsTab && (
          <div className="hoops-right-panel--header">
            <Selector
              className="hoops-right-panel--header-selector"
              options={selectorOptions}
              selected={appStore.env.hoopsSidePanelState}
              onSelected={state => appStore.env.setHoopsSidePanelState(state as HoopsSidePanelState)}
            />
          </div>
        )}
        {renderContent()}
      </div>
      <div className="hoops-right-panel--vertical-bar">
        <Button
          large
          minimal
          active={isAnnotationTab}
          e2eIdentifiers="set-annotations--side-btn"
          icon="comment"
          onClick={() => toggleSidePanelState(HoopsSidePanelState.Annotations)}
        >
          Annotations
        </Button>
        <Button
          large
          minimal
          active={isSavedViewsTab}
          e2eIdentifiers="set-saved-views--side-btn"
          icon="floppy-disk"
          onClick={() => toggleSidePanelState(HoopsSidePanelState.SavedViews)}
        >
          Saved views
        </Button>
        <Button
          large
          minimal
          active={isModelDetailsTab}
          e2eIdentifiers="set-model-details--side-btn"
          icon="info-sign"
          onClick={() => toggleSidePanelState(HoopsSidePanelState.ModelDetails)}
        >
          Model details
        </Button>
      </div>
    </div>
  );
};

export default observer(RightPanel);
