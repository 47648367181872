import { Intent } from "@blueprintjs/core";
import { UseMutationOptions } from "@tanstack/react-query";

import { showApiErrorToast, showToast } from "@components/UiLayers/toaster";
import { ICreateDataSinkEntryDto, IUpdateDataSinkEntryDto } from "@rollup-api/models/data-sinks";
import { DataSinkEntry } from "@rollup-api/models/data-sinks/data-sink.model";
import appStore from "@store/AppStore";

import { QueryKeys, rollupClient } from "../core/api";
import { queryClient } from "../lib/ReactQuery/queryClient";

export abstract class DataSinkEntryQOFactory {
  public static createPostQO(
    workspaceId: string
  ): UseMutationOptions<DataSinkEntry, Error, { dataSinkId: string; dto: ICreateDataSinkEntryDto }> {
    return {
      mutationFn: ({ dataSinkId, dto }) => rollupClient.modelingModule.dataSinkEntries.create(dataSinkId, dto).then(res => res.data),
      onSuccess: entry => {
        appStore.workspaceModel?.dataConnection.addDataSinkEntry(entry);
        showToast("Successfully created data sink entry", Intent.SUCCESS);
        queryClient.invalidateQueries({ queryKey: [QueryKeys.DataSinkList, workspaceId] });
      },
      onError: err => {
        const errorMsg = "Error adding data sink entry";
        console.warn(errorMsg, err);
        showApiErrorToast(errorMsg);
      },
    };
  }

  public static createDeleteQO(workspaceId: string): UseMutationOptions<DataSinkEntry, Error, { dataSinkId: string; key: string }> {
    const queryKey = [QueryKeys.DataSinkList, workspaceId];
    return {
      mutationFn: ({ dataSinkId, key }) => rollupClient.modelingModule.dataSinkEntries.delete(dataSinkId, key).then(res => res.data),
      onSuccess: (entry, variables) => {
        appStore.workspaceModel?.dataConnection.deleteDataSinkEntry(entry.id, true);
        showToast(`Successfully deleted data sink entry "${variables.key}"`, Intent.SUCCESS);
        queryClient.setQueryData<DataSinkEntry[]>(queryKey, prev => prev?.filter(d => d.id !== variables.key));
      },
      onError: (err, variables) => {
        const errorMsg = `Error deleting data sink entry "${variables.key}"`;
        console.warn(errorMsg, err);
        showApiErrorToast(errorMsg);
      },
    };
  }

  public static createPatchQO(
    workspaceId: string
  ): UseMutationOptions<DataSinkEntry, Error, { dataSinkId: string; key: string; dto: IUpdateDataSinkEntryDto }> {
    return {
      mutationFn: ({ dataSinkId, key, dto }) =>
        rollupClient.modelingModule.dataSinkEntries.update(dataSinkId, key, dto).then(res => res.data),
      onSuccess: (entry, variables) => {
        appStore.workspaceModel?.dataConnection.updateDataSinkEntry(entry.id, variables.dto);
        showToast(`Successfully updated data sink entry "${variables.key}"`, Intent.SUCCESS);
        queryClient.invalidateQueries({ queryKey: [QueryKeys.DataSinkList, workspaceId] });
      },
      onError: (err, variables) => {
        const errorMsg = `Error updating data sink entry "${variables.key}"`;
        console.warn(errorMsg, err);
        showApiErrorToast(errorMsg);
      },
    };
  }
}
