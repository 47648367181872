import { useRef, useState } from "react";
import { PropertyDataType } from "@rollup-io/engineering";
import { observer } from "mobx-react";

import { ScalarExpressionEditor, scalarExpressionEditorInputClassName } from "@components/Shared";
import ConfirmableNumericInput from "@components/Shared/ConfirmableNumericInput/ConfirmableNumericInput";
import appStore from "@store/AppStore";

import FloatingWindow, { FLOATING_WINDOW_HEADER_HEIGHT } from "../FloatingWindow";

import styles from "./ExpressionEditorWindow.module.scss";

const rowHeight = 30;
const rowPadding = 10;
const initialHeight = FLOATING_WINDOW_HEADER_HEIGHT + 3 * rowHeight;
const minHeight = FLOATING_WINDOW_HEADER_HEIGHT + rowHeight + rowPadding;
const getTitlePrefix = (dataType: PropertyDataType) => {
  switch (dataType) {
    case PropertyDataType.scalar:
      return "Equation - ";
    case PropertyDataType.string:
      return "String - ";
    default:
      return "";
  }
};

const ExpressionEditorWindow = () => {
  const { hideDetachedExpressionEditorWindow, activePropertyInstance } = appStore.env;
  const ref = useRef<HTMLDivElement | null>(null);
  const [height, setHeight] = useState(initialHeight);

  const calculateWindowHeight = () => {
    if (ref.current) {
      setHeight(ref.current.offsetHeight + FLOATING_WINDOW_HEADER_HEIGHT + rowPadding);
    }
  };

  if (!activePropertyInstance?.propertyDefinition) return null;

  const { dataType } = activePropertyInstance.propertyDefinition;

  const renderEditor = () => {
    switch (dataType) {
      case PropertyDataType.scalar:
        return (
          <ScalarExpressionEditor
            propertyInstance={activePropertyInstance}
            className={styles.expressionEditorWindowEditor}
            onModEnterKeyPress={hideDetachedExpressionEditorWindow}
            onCreate={calculateWindowHeight}
            onUpdate={calculateWindowHeight}
            enableWrapping
            hideDetachButton
          />
        );
      case PropertyDataType.string:
        return (
          <ConfirmableNumericInput
            value={activePropertyInstance.value}
            tryConfirmValueFromString={activePropertyInstance.setStringValue}
            disabled={activePropertyInstance.locked}
            placeholder="String value"
          />
        );
    }
  };

  return (
    <FloatingWindow
      minSize={{ width: 575, height: minHeight }}
      defaultSize={{ width: 650, height }}
      id="expresssion-editor"
      title={getTitlePrefix(dataType) + activePropertyInstance?.propertyDefinition?.label}
      open={!!activePropertyInstance}
      onClose={hideDetachedExpressionEditorWindow}
      cancel={`.${scalarExpressionEditorInputClassName}`}
    >
      <div className={styles.expressionEditorWindowContainer} ref={ref}>
        {renderEditor()}
      </div>
    </FloatingWindow>
  );
};

export default observer(ExpressionEditorWindow);
