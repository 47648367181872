import { useEffect } from "react";
import { useWorkspace } from "@hooks/useWorkspace";
import Image from "@tiptap/extension-image";
import { Editor, useEditor } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import classNames from "classnames";
import { observer } from "mobx-react";

import { EditorContent } from "@components/EditorContent";
import { getDocumentEditorClassNames } from "@components/Reports/Editor/utils";

import { ImageCreate } from "../Extentions/ImageCreate";
import { getImageCreateComponent } from "../Extentions/ImageCreate/component/ImageCreateComponent";
import ImageResize from "../Extentions/ImageResize";
import { getImageResizeComponent } from "../Extentions/ImageResize/component/ImageResizeComponent";
import { TEditorBlockProps } from "../types";

const ImageReportBlockEditor = (props: TEditorBlockProps) => {
  const { eventHandlers } = props;
  const { onBackspaceKeyDown } = eventHandlers;
  const workspace = useWorkspace();
  const { documentBlock, editable = true } = props;

  const handleResizeEnd = (editor: Editor) => {
    documentBlock.updateText(editor.getHTML());
  };

  const handleLinkChange = (url: string) => {
    documentBlock.updateText(getImageResizeComponent(url));
  };

  const editor = useEditor({
    extensions: [
      StarterKit,
      Image,
      ImageResize.configure({ onResizeEnd: handleResizeEnd }),
      ImageCreate.configure({ onLinkChange: handleLinkChange, workspaceId: workspace.id }),
    ],
    editable,
    content: documentBlock.label || getImageCreateComponent(),
  });

  useEffect(() => {
    editor?.commands.setContent(documentBlock.label || getImageCreateComponent());
  }, [editor, documentBlock.label]);

  const commonClassNames = getDocumentEditorClassNames({
    annotationList: documentBlock.annotationList,
  });
  return (
    <div className={classNames("report-editor--image", commonClassNames)}>
      <EditorContent onKeyDown={onBackspaceKeyDown} id={documentBlock.id} disabled editor={editor} e2eIdentifiers="image" />
    </div>
  );
};

export default observer(ImageReportBlockEditor);
