import { CustomerPortal as StiggCustomerPortal, Paywall as StiggPaywall } from "@stigg/react-sdk";
import classNames from "classnames";
import { observer } from "mobx-react";

import { ISelectedPlanData } from "@router/elements/settings/SettingsPlanAndBilling/types";

import styles from "./Paywall.module.scss";

interface IPaywallProps {
  productId: string;
  className?: string;
  onPlanSelected(plan: ISelectedPlanData): void;
}

const Paywall = (props: IPaywallProps) => {
  const { productId, className, onPlanSelected } = props;

  return (
    <div className={classNames(styles.paywall, className)}>
      <StiggCustomerPortal
        hiddenSections={["paymentDetails", "invoices", "usage"]}
        paywallComponent={<StiggPaywall onPlanSelected={onPlanSelected} productId={productId} />}
      />
    </div>
  );
};

export default observer(Paywall);
