import { Tag } from "@components/Tag";
import { ICatalogItemVersion } from "@store/CatalogItem/CatalogItemVersionStore";

import styles from "./CatalogItemVersionLabel.module.scss";

type Props = {
  version: ICatalogItemVersion;
  showComment?: boolean;
};

const CatalogItemVersionLabel = (props: Props) => {
  const { version, showComment } = props;

  const label = version.index || "Untitled version";

  return (
    <div className={styles.catalogItemVersionLabel}>
      <div className={styles.catalogItemVersionLabelTitle}>
        <div>{label}</div>
        {version.revisionCode && <Tag className={styles.catalogItemVersionLabelTag}>REV {version.revisionCode}</Tag>}
      </div>
      {showComment && <div className={styles.catalogItemVersionLabelComment}>{version.comment}</div>}
    </div>
  );
};

export default CatalogItemVersionLabel;
