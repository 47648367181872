import { useState } from "react";
import { Intent, Menu } from "@blueprintjs/core";
import { useSearchParam } from "@hooks/useSearchParam/useSearchParam";
import { observer } from "mobx-react";

import { Button } from "@components/Button";
import ConfirmationDialog from "@components/ConfirmationDialog/ConfirmationDialog";
import { MenuItem } from "@components/MenuItem";
import { Popover } from "@components/Popover";
import { showToast } from "@components/UiLayers/toaster";
import appStore from "@store/AppStore";

enum ChangeAllEventsActionType {
  markAllAsRead = "MarkAllAsRead",
  archiveAll = "ArchiveAll",
  archiveAllRead = "ArchiveAllRead",
}

const dialogMap = {
  [ChangeAllEventsActionType.markAllAsRead]: {
    title: "Mark all notifications as read?",
    toast: "All notifications are marked as read",
  },
  [ChangeAllEventsActionType.archiveAll]: {
    title: "Archive all notifications?",
    toast: "All notifications are archived",
  },
  [ChangeAllEventsActionType.archiveAllRead]: {
    title: "Archive all read notifications?",
    toast: "All read notifications are archived",
  },
};

const RollupEventsInboxActionButton = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [confirmationDialog, setConfirmationDialog] = useState<ChangeAllEventsActionType | undefined>();
  const [workspaceIdParam] = useSearchParam("ws");

  const toggleIsOpen = () => setIsOpen(!isOpen);

  // TODO: implement undo if BE is going to implement it as well.
  const executeAction = () => {
    if (confirmationDialog === ChangeAllEventsActionType.markAllAsRead) {
      appStore.userModel?.rollupEvents?.markAllAsRead(workspaceIdParam);
    } else if (confirmationDialog === ChangeAllEventsActionType.archiveAll) {
      appStore.userModel?.rollupEvents?.archiveAll(workspaceIdParam);
    } else {
      appStore.userModel?.rollupEvents?.archiveAllRead(workspaceIdParam);
    }

    showToast(dialogMap[confirmationDialog!].toast, Intent.NONE);
    setConfirmationDialog(undefined);
  };

  const actionMenu = () => (
    <Menu>
      <MenuItem
        text="Mark all as read"
        onClick={() => setConfirmationDialog(ChangeAllEventsActionType.markAllAsRead)}
        e2eIdentifiers="mark-all-as-read"
      />
      <MenuItem
        text="Archive all notifications"
        onClick={() => setConfirmationDialog(ChangeAllEventsActionType.archiveAll)}
        e2eIdentifiers="archive-all-notifications"
      />
      <MenuItem
        text="Archive all read notifications"
        onClick={() => setConfirmationDialog(ChangeAllEventsActionType.archiveAllRead)}
        e2eIdentifiers="archive-all-read-notifications"
      />
    </Menu>
  );

  return (
    <>
      {confirmationDialog && (
        <ConfirmationDialog
          title={dialogMap[confirmationDialog].title}
          actionButtonText="Yes"
          intent={Intent.PRIMARY}
          isOpen={!!confirmationDialog}
          onCancel={() => setConfirmationDialog(undefined)}
          onClose={() => setConfirmationDialog(undefined)}
          onConfirm={executeAction}
        />
      )}
      <Popover placement="bottom-end" content={actionMenu()}>
        <Button
          active={isOpen}
          icon="inbox"
          outlined
          onClick={toggleIsOpen}
          rightIcon={isOpen ? "chevron-up" : "chevron-down"}
          e2eIdentifiers="toggle-is-open"
        />
      </Popover>
    </>
  );
};

export default observer(RollupEventsInboxActionButton);
