import { memo } from "react";
import { CustomCellEditorProps } from "ag-grid-react";
import classNames from "classnames";

import RichTextCellEditor from "@components/Table/CellEditors/RichTextCellEditor";
import SimpleTextCellEditor from "@components/Table/CellEditors/SimpleTextCellEditor";

import styles from "./TextCellEditor.module.scss";

interface IEditorCellProps extends CustomCellEditorProps<any, string> {
  useRichText?: boolean;
  isValid?(value: string): boolean;
  stopOnBlur?: boolean;
}

/** It's necessary to add a valueGetter to the column definition so the EditorCell can access the value **/
const TextCellEditor = memo((props: IEditorCellProps) => {
  const { value, useRichText, api, node, isValid, onValueChange, stopOnBlur } = props;
  const alignedRight = props.eGridCell.classList.contains("ag-right-aligned-cell");

  const getValidationStatus = (value: string) => {
    return isValid?.(value) ?? true;
  };

  const handleCommit = () => {
    api.stopEditing();
  };

  const handleCancel = () => {
    api.stopEditing(true);
  };

  const handleBlur = () => {
    stopOnBlur && api.stopEditing();
  };

  const renderEditor = () => {
    if (useRichText) {
      return (
        <RichTextCellEditor
          node={node}
          api={api}
          defaultValue={value ?? undefined}
          onCommit={handleCommit}
          onCancel={handleCancel}
          onValueChange={onValueChange}
        />
      );
    }

    return (
      <SimpleTextCellEditor
        singleLine
        isValid={getValidationStatus}
        defaultValue={value ?? undefined}
        node={node}
        api={api}
        onBlur={handleBlur}
        onValueChange={onValueChange}
      />
    );
  };

  return (
    <div
      className={classNames(styles.textCellEditor, {
        [styles.textCellEditorAlignRight]: alignedRight,
      })}
    >
      {renderEditor()}
    </div>
  );
});

TextCellEditor.displayName = "TextCellEditor";

export default TextCellEditor;
