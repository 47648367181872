import { Intent } from "@blueprintjs/core";

import { showToast } from "@components/UiLayers/toaster";
import { CreateHoopsViewDto, CuttingData } from "@rollup-api/models/hoopsView";
import appStore from "@store/AppStore";

import { VIEW_CHANGE_DELAY_IN_MS } from "./constants";

export const createCreateHoopsViewDto = (
  name: string,
  viewer: Communicator.WebViewer,
  cloudFileId: string,
  workspaceId?: string | null
): CreateHoopsViewDto | undefined => {
  const annotationViewId = viewer.markupManager.createMarkupView();
  const annotationView = viewer.markupManager.getMarkupView(annotationViewId);

  if (!annotationView) {
    return;
  }
  // for some reason, getCamera() returns keys with an _. Below is a hack to remove them.
  const oldCamera = JSON.parse(JSON.stringify(annotationView.getCamera()));
  const newCamera = {
    position: oldCamera._position,
    target: oldCamera._target,
    up: oldCamera._up,
    width: oldCamera._width,
    height: oldCamera._height,
    projection: oldCamera._projection,
    nearLimit: oldCamera._nearLimit,
    className: "Communicator.Camera",
  };
  return {
    // We will use name as the unique id for the annotation when calling it, because the uniqueId we are trying to load is for some reason overwritten by the HOOPS viewer
    name,
    camera: newCamera,
    cuttingData: annotationView.getCuttingPlaneData() as CuttingData,
    cloudFileId,
    explodeMagnitude: annotationView.getExplodeMagnitude(),
    lineVisibility: annotationView.getLineVisibility(),
    faceVisibility: annotationView.getFaceVisibility(),
    markup: [],
    sheetId: -1,
    uniqueId: annotationViewId,
    defaultVisibility: annotationView.getDefaultVisibility(),
    visibilityExceptions: [],
    colors: [],
    ...(workspaceId && { workspaceId }),
    isolatedNodeId: appStore.env.attachmentViewer?.isolatedNode?.id,
  };
};

export const hacktivateCustomView = async (uniqueId: string, recreated: boolean, isolatedNodeId?: number) => {
  // When we activate the annotation view for any recreated annotation globally for the first time, during the camera
  // change from the current view to the annotation view the model disappears. Further changes have no problem.
  // Because, hoops :(
  //
  // As a hack to prevent this:
  // 1. We save the current camera.
  // 2. We get the camera of the annotation view and create another camera from this.
  // 3. We set the camera of the hoops viewer instance to this new camera.
  // 4. We create a new view from that camera.
  // 5. We set the camera of the hoops viewer instance back to the current camera.
  // 6. We activate the new view (the one we just created). Interestingly, this view is transitioned smoothly without the model disappearing.
  // 7. Then we activate the annotation view. This view is still buggy yet. So, actually it wants to disappear. But since the model is already
  // at the position it needs to be, it doesn't disappear. So, we are good.
  //
  // Once this is done for any annotation one time,
  // then there is no need to do it again for any annotation until the model is closed. So yes it's hacky but it works and it
  // took around 10 hours straight to figure this hack out.
  const instance = appStore.env.attachmentViewer?.viewerInstance;
  if (!instance) {
    return;
  }

  if (!appStore.env.attachmentViewer?.hack_hasAnyRecreatedAnnotationViewButtonClickedBefore && recreated) {
    const expectedAnnotationView = await instance.markupManager.getMarkupView(uniqueId);

    const originalCamera = instance.view.getCamera();
    const expectedCamera = expectedAnnotationView?.getCamera();
    if (!originalCamera || !expectedCamera) {
      return;
    }

    instance.view.setCamera(expectedCamera);

    const hackViewId = instance.markupManager.createMarkupView();

    instance.view.setCamera(originalCamera);

    await instance.markupManager.activateMarkupViewWithPromise(hackViewId, VIEW_CHANGE_DELAY_IN_MS);
    appStore.env.attachmentViewer?.setHack_hasAnyRecreatedAnnotationViewButtonClickedBefore(true);
    await instance.markupManager.activateMarkupViewWithPromise(uniqueId, 0);
  } else {
    await instance.markupManager.activateMarkupViewWithPromise(uniqueId, VIEW_CHANGE_DELAY_IN_MS);
  }
  // If isolatedNodeId is provided, isolate the node
  if (isolatedNodeId) {
    const isolatedNode = appStore.env.attachmentViewer?.getNodeById(isolatedNodeId);
    if (!isolatedNode) {
      showToast(`Cannot find node with id ${isolatedNodeId}`, Intent.WARNING, "warning-sign");
      return;
    }
    appStore.env.attachmentViewer?.isolateNode(isolatedNode);
  } else {
    appStore.env.attachmentViewer?.unisolateNodes();
  }
};
