import { EFeedbackType } from "./types";

export const supportEmail = "support+product@rollup.ai";

export const placeholdersByDialogType = {
  [EFeedbackType.PROBLEM]: {
    title: "Something is wrong with",
    description: "When I click the…",
  },
  [EFeedbackType.QUESTION]: {
    title: "Creating a …",
    description: "How do I …?",
  },
  [EFeedbackType.FEEDBACK_AND_REQUEST]: {
    title: "New feature",
    description: "I wish I could …",
  },
};
