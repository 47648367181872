import { useMemo, useState } from "react";
import { observer } from "mobx-react-lite";
import { useDebounceValue } from "usehooks-ts";

import { MenuItem } from "@components/MenuItem";
import { FilterableMenu } from "@components/Shared/FilterableMenu";

import styles from "./InternalAttachmentFilterableMenu.module.scss";

interface IFilterableDocument {
  id: string;
  label: string;
  icon?: string;
}

interface IAddAttachmentMenuInternalProps<T extends IFilterableDocument> {
  itemName: string;
  collection: T[];
  queryDebounceTime?: number;
  placeholder?: string;
  onAttachDocument(doc: T): void;
}

const InternalAttachmentFilterableMenu = <T extends IFilterableDocument>(props: IAddAttachmentMenuInternalProps<T>) => {
  const { itemName, collection, queryDebounceTime = 500, placeholder, onAttachDocument } = props;
  const [queryString, setQueryString] = useState("");
  const [debouncedQueryString] = useDebounceValue(queryString, queryDebounceTime);

  const filteredDocuments = useMemo(() => {
    return collection.filter(doc => doc.label.includes(debouncedQueryString));
  }, [collection, debouncedQueryString]);

  const renderItemLabel = (doc: T) => {
    const label = doc.label || "Untitled";

    if (doc.icon) {
      return (
        <div className={styles.internalAttachmentFilterableMenuLabel}>
          <span>{doc.icon}</span>
          <span>{label}</span>
        </div>
      );
    }

    return label;
  };

  return (
    <FilterableMenu title={`Select ${itemName} to Add`} queryString={queryString} setQueryString={setQueryString} placeholder={placeholder}>
      {filteredDocuments.map(doc => (
        <MenuItem
          key={doc.id}
          className={styles.internalAttachmentFilterableMenuMenuItem}
          text={renderItemLabel(doc)}
          onClick={() => onAttachDocument(doc)}
          e2eIdentifiers="attach-req-page"
        />
      ))}
    </FilterableMenu>
  );
};

export default observer(InternalAttachmentFilterableMenu);
