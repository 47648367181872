export const MAX_WINDOW_HEIGHT = 185;
export const WINDOW_HEADER_HEIGHT = 38;
export const MIN_WINDOW_HEIGHT = 100;
export const ROW_HEIGHT = 40;

export enum EWarningType {
  UPLOAD_FAILED = "uploadFailed",
  UPLOAD_IN_PROGRESS = "uploadInProgress",
  UNSAVED_IMPORTS = "unsavedImports",
}

export const warningText = {
  [EWarningType.UPLOAD_FAILED]: {
    title: "Upload failed. Delete file?",
    confirm: "Delete and close window",
    cancel: "Restart uploading",
  },
  [EWarningType.UPLOAD_IN_PROGRESS]: {
    title: "File upload is not complete. Cancel uploading?",
    confirm: "Cancel upload",
    cancel: "Collapse window instead",
  },
  [EWarningType.UNSAVED_IMPORTS]: {
    title: "You have unsaved imports for review. Cancel import?",
    confirm: "Remove unsaved imports",
    cancel: "Collapse window instead",
  },
};
