import { useRef, useState } from "react";
import { ContextMenu, DialogBody, H4, InputGroup, Intent, Menu } from "@blueprintjs/core";
import { observer } from "mobx-react";

import { Button } from "@components/Button";
import { MenuItem } from "@components/MenuItem";
import ReportPage from "@components/Reports/Page/ReportPage";
import ReportsSidebarContextMenu from "@components/Reports/ReportContextMenu";
import { showToast } from "@components/UiLayers/toaster";
import { WorkspaceContext } from "@providers/workspace";
import { useAppNavigate } from "@router/hooks";
import appStore from "@store/AppStore";
import { IReport } from "@store/ReportsStore";

import DialogLegacy from "../DialogLegacy";

import "./ReportTemplates.scss";

const ReportTemplates = () => {
  const contentRef = useRef<HTMLDivElement>(null);
  const [search, setSearch] = useState("");
  const [lading, setLoading] = useState(false);
  const [activeTemplate, setActiveTemplate] = useState<IReport | null>(null);
  const { navigateToReport } = useAppNavigate();
  const templates = appStore.workspaceModel?.reportTemplates || [];
  const visibleTemplates = search ? templates.filter(template => template.label.toLowerCase().includes(search.toLowerCase())) : templates;

  const handleUseTemplate = () => {
    if (activeTemplate) {
      setLoading(true);
      appStore.workspaceModel
        ?.duplicateReport(activeTemplate.id)
        .then(report => {
          if (report) {
            appStore.env.setActiveReport(report.id);
            navigateToReport(report.id);
            appStore.ui.hideReportTemplatesDialog();
            showToast("Report created from the template", Intent.SUCCESS);
          }
        })
        .catch(() => showToast("Error creating Report from the template", Intent.DANGER))
        .finally(() => setLoading(false));
    }
  };

  const handleDeleteTemplate = (report: IReport) => {
    appStore.workspaceModel?.deleteReport(report.id);
    setActiveTemplate(null);
  };

  const renderContent = () => {
    if (!visibleTemplates.length) {
      return <div className="report-templates--empty">No items found</div>;
    }

    return (
      <Menu className="report-templates--menu">
        {visibleTemplates.map(template => (
          <ContextMenu
            key={template.id}
            content={<ReportsSidebarContextMenu templateNav onDelete={handleDeleteTemplate} report={template} />}
          >
            <MenuItem
              className="report-templates--menu-item"
              e2eIdentifiers="template-item"
              active={template.id === activeTemplate?.id}
              onClick={() => {
                contentRef.current?.scrollTo({ top: 0 });
                setActiveTemplate(template);
              }}
              text={
                <span className="report-templates--menu-title">
                  <span className="report-templates--menu-icon">{template.displayedIcon}</span>
                  <span className="report-templates--menu-label">{template.displayedLabel}</span>
                </span>
              }
            />
          </ContextMenu>
        ))}
      </Menu>
    );
  };

  return (
    <DialogLegacy
      backdropProps={{ className: "report-templates--backdrop" }}
      className="report-templates"
      onClose={appStore.ui.hideReportTemplatesDialog}
      isOpen={appStore.ui.reportTemplatesDialog}
      canEscapeKeyClose
      canOutsideClickClose
      isCloseButtonShown
      title={<H4>Report templates</H4>}
    >
      <WorkspaceContext.Provider value={appStore.workspaceModel || undefined}>
        <DialogBody>
          <div className="report-templates--sidebar">
            <div className="report-templates--search">
              <InputGroup
                rightElement={
                  search ? <Button e2eIdentifiers="clear-search" minimal icon="cross" onClick={() => setSearch("")} /> : undefined
                }
                leftIcon="search"
                fill
                value={search}
                onChange={e => setSearch(e.target.value)}
              />
            </div>
            {renderContent()}
          </div>
          <div className="report-templates--body">
            <div ref={contentRef} className="report-templates--content">
              {activeTemplate && <ReportPage report={activeTemplate} />}
            </div>
            <div className="report-templates--actions">
              <Button onClick={appStore.ui.hideReportTemplatesDialog} large minimal e2eIdentifiers="cance-btn">
                Cancel
              </Button>
              <Button
                disabled={!activeTemplate}
                loading={lading}
                onClick={handleUseTemplate}
                intent={Intent.PRIMARY}
                large
                e2eIdentifiers="use-template-btn"
              >
                Use template
              </Button>
            </div>
          </div>
        </DialogBody>
      </WorkspaceContext.Provider>
    </DialogLegacy>
  );
};

export default observer(ReportTemplates);
