import { Intent, Spinner, Tag } from "@blueprintjs/core";
import { useQuery } from "@tanstack/react-query";
import { observer } from "mobx-react";
import moment from "moment";

import { TitledTooltip } from "@components/Shared";
import { DataSource } from "@rollup-api/models/data-sources/data-source.model";
import { QueryKeys, rollupClient } from "src/core/api";

export const SourceStatusTag = observer(function SourceStatusTag({ source }: { source: DataSource }) {
  const {
    isLoading,
    data: status,
    dataUpdatedAt,
  } = useQuery({
    queryKey: [QueryKeys.DataSourceValueTest, source.id],
    staleTime: 1000 * 60 * 2, // 2 minute TTL
    queryFn: () => rollupClient.modelingModule.dataSources.test(source.id).then(res => res.data),
  });

  if (isLoading) {
    return <Spinner size={12} />;
  }

  const title = status?.success ? "Able to fetch data successfully" : "Unable to fetch data";
  const message = status?.success
    ? `Data query took ${status?.elapsedTime?.toFixed(1)} ms. Ran ${moment(dataUpdatedAt).fromNow()}`
    : (status?.message ?? "Unknown error");

  return (
    <TitledTooltip title={title} content={message} hoverOpenDelay={500}>
      <Tag large round intent={status?.success ? Intent.SUCCESS : Intent.WARNING}>
        {status?.success ? "Connected" : "Disconnected"}
      </Tag>
    </TitledTooltip>
  );
});
