import { trackSegmentEvent } from "../../Segment";
import { Invitation, InvitationDto, SelfInviteDto } from "../models/invitations";
import { Profile } from "../models/profiles";

import { HttpClient, ParentClient } from "./client";

export class Invitations extends HttpClient {
  public constructor(parent: ParentClient) {
    super(parent);
  }

  private readonly endpoint: string = "organizations/invitations";

  public retrieveAll = () => {
    return this.instance.get<Invitation[]>(`${this.endpoint}/list`);
  };

  public inviteUsers = (dto: InvitationDto) => {
    trackSegmentEvent("member:invite", { dto });
    return this.instance.post<Profile[]>(`${this.endpoint}/send-invite`, dto);
  };

  public selfInvite = async (dto: SelfInviteDto) => {
    return this.instance.post(`${this.endpoint}/self-invite`, dto);
  };
}
