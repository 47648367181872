import { useState } from "react";
import { Card, EditableText, Intent, Menu, MenuDivider } from "@blueprintjs/core";
import { observer } from "mobx-react";

import { Button } from "@components/Button";
import { MenuItem } from "@components/MenuItem";
import { MenuItemDelete } from "@components/MenuItems";
import FileAttachmentDetails from "@components/Modeling/ModelingFrame/ModelBlock/Attachments/AttachmentDetails/AttachmentDetailsFile";
import { Popover } from "@components/Popover";
import { CopyLinkMenuItem } from "@components/SmallComponents";
import { AttachmentStatus } from "@rollup-api/models/cloudStorage";
import { IAttachment } from "@store/AttachmentStore";

import { useAttachmentFunctions } from "../Attachments/attachmentHooks";

import "./CommentAttachment.scss";

export type CommentAttachmentProps = {
  attachment: IAttachment;
  readonly?: boolean;
  allowDeletion?: boolean;
  onDelete?: (deletedAttachmentId: string) => void;
  handleDetach?: () => void;
};

const CommentAttachment = ({ attachment, readonly = true, allowDeletion = false, onDelete, handleDetach }: CommentAttachmentProps) => {
  const [isDetailsDialogOpen, setIsDetailsDialogOpen] = useState(false);
  const { handleConfirm, handleDownload, handleRetry, handleDelete } = useAttachmentFunctions({
    attachment,
  });

  const commentViewMenu = () => {
    if (readonly) {
      return (
        <Button
          minimal
          intent={Intent.NONE}
          icon="download"
          onClick={() => handleDownload()}
          e2eIdentifiers={["comment-attachment", "download"]}
        />
      );
    } else {
      return (
        <Popover
          placement="bottom-end"
          content={
            <Menu>
              {attachment.status === AttachmentStatus.ConversionFailed && (
                <MenuItem
                  icon="refresh"
                  text="Retry conversion"
                  intent={Intent.NONE}
                  onClick={handleRetry}
                  e2eIdentifiers="retry-conversion"
                />
              )}
              <MenuItem icon="download" text="Download" intent={Intent.NONE} onClick={handleDownload} e2eIdentifiers="download" />
              <MenuItem
                icon="info-sign"
                text="Details"
                intent={Intent.NONE}
                onClick={() => setIsDetailsDialogOpen(true)}
                e2eIdentifiers="details"
              />
              <CopyLinkMenuItem node={attachment} />
              <MenuDivider />
              {allowDeletion ? (
                <MenuItemDelete
                  onDelete={() => {
                    onDelete?.(attachment.id);
                    handleDelete();
                  }}
                />
              ) : (
                handleDetach && (
                  <MenuItem icon="cross-circle" text="Detach" intent={Intent.WARNING} onClick={handleDetach} e2eIdentifiers="detach" />
                )
              )}
            </Menu>
          }
        >
          <Button minimal intent={Intent.NONE} icon="more" e2eIdentifiers={["comment-attachment", "more"]} />
        </Popover>
      );
    }
  };

  return (
    <>
      <FileAttachmentDetails attachment={attachment} isOpen={isDetailsDialogOpen} onClose={() => setIsDetailsDialogOpen(false)} />
      <Card className="comment-attachment">
        <div className="comment-attachment--content">
          <EditableText
            key={attachment.id}
            className="comment-attachment--label"
            maxLength={255}
            placeholder="Name"
            disabled={readonly}
            defaultValue={attachment.label} // To have to "uncontrolled" might cause bugs when an update occurs in the store.
            confirmOnEnterKey
            onConfirm={value => handleConfirm && handleConfirm(value)}
          />
          {commentViewMenu()}
        </div>
      </Card>
    </>
  );
};

export default observer(CommentAttachment);
