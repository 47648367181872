import { useCallback, useEffect, useMemo, useState } from "react";
import ReactFlow, { Background, BackgroundVariant, Controls, Edge, MarkerType, Node, Position, useReactFlow } from "reactflow";
import { ButtonGroup, Colors } from "@blueprintjs/core";
import { ElkNode } from "elkjs/lib/elk-api";
import { observer } from "mobx-react";

import { Button } from "@components/Button";
import BlockFlowNode, { BlockFlowNodeData } from "@components/Modeling/ModelingFrame/FlowView/CustomNodes/BlockFlow/BlockFlowNode";
import { useAppNavigate } from "@router/hooks";
import appStore from "@store/AppStore";

import { elk } from "../elk";

import "../FlowView.scss";

const NODE_WIDTH = 140;
const NODE_WIDTH_PADDING = 48;
const NODE_HEIGHT = 100;
const ZOOM_DURATION = 100;

function FlowViewBlocks() {
  const [arrangedGraph, setArrangedGraph] = useState<{ nodes: Node[]; edges: Edge[] }>({ edges: [], nodes: [] });
  const { hierarchicalGraph: inputGraph, rootBlock } = appStore.workspaceModel ?? {};
  const { activeBlock } = appStore.env;
  const { navigateToBlock } = useAppNavigate();
  const { fitView, zoomIn, zoomOut } = useReactFlow();

  // Helpers functions.
  const handleGraphUpdate = useCallback(
    (generatedGraph: ElkNode) => {
      if (!inputGraph || !generatedGraph?.children) {
        return;
      }

      const nodes: Node<BlockFlowNodeData>[] = [];

      for (const elkNode of generatedGraph.children) {
        const block = inputGraph.getNodeAttributes(elkNode.id)?.block;

        if (!block) {
          return;
        }

        // Custom data for the node renderer.
        const data: BlockFlowNodeData = {
          block,
        };

        nodes.push({
          id: elkNode.id,
          type: "blockFlowNode",
          width: elkNode.width,
          height: elkNode.height,
          data,
          targetPosition: Position.Top,
          sourcePosition: Position.Bottom,

          connectable: false,
          selectable: true,
          selected: block === activeBlock,
          position: { x: elkNode.x ?? 0, y: elkNode.y ?? 0 },
          draggable: false,
        });
      }

      const edges: Edge[] = [];

      if (generatedGraph.edges) {
        for (const elkEdge of generatedGraph.edges) {
          const source = elkEdge.sources?.[0];
          const target = elkEdge.targets?.[0];
          if (source && target) {
            edges.push({
              id: elkEdge.id,
              source,
              target,
              style: { stroke: Colors.GRAY1, strokeWidth: 1 },
              type: "smoothstep",
              markerEnd: { type: MarkerType.ArrowClosed, width: 25, height: 25, orient: "90", strokeWidth: 0 },
              interactionWidth: 0,
            });
          }
        }
      }

      setArrangedGraph({ nodes, edges });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [inputGraph, rootBlock, activeBlock]
  );

  // Effects.
  useEffect(() => {
    if (!inputGraph) {
      return;
    }

    const measureContext = document.createElement("canvas").getContext("2d");
    if (measureContext) {
      measureContext.font = "16px sans-serif";
    }

    let width = NODE_WIDTH;

    const graph: ElkNode = {
      id: "root",
      children: inputGraph.mapNodes((id, node) => {
        if (measureContext) {
          let text = node.block.label;
          if (node.block.multiplicity && node.block.multiplicity > 1) {
            text += ` (${node.block.multiplicity})`;
          }
          width = measureContext.measureText(text).width;
          width = Math.ceil(width) + NODE_WIDTH_PADDING;
        }
        return { id, width, height: NODE_HEIGHT };
      }),
      edges: inputGraph.mapEdges((id, _attr, source, target) => {
        return {
          id,
          sources: [source],
          targets: [target],
        };
      }),
    };

    elk
      .layout(graph, { layoutOptions: { "elk.algorithm": "mrtree", "elk.direction": "down" } })
      .then(graph => handleGraphUpdate(graph))
      .catch(console.error);
  }, [handleGraphUpdate, inputGraph]);

  const nodeTypes = useMemo(() => ({ blockFlowNode: BlockFlowNode }), []);
  return (
    <ReactFlow
      nodes={arrangedGraph.nodes}
      edges={arrangedGraph.edges}
      nodeTypes={nodeTypes}
      maxZoom={1.0}
      minZoom={0.1}
      fitView
      proOptions={{ hideAttribution: true }}
      onNodeClick={(_ev, node) => navigateToBlock(node.id)}
    >
      <Controls showZoom={false} showInteractive={false} showFitView={false}>
        <ButtonGroup vertical>
          <Button icon="zoom-in" e2eIdentifiers="zoom-in" onClick={() => zoomIn({ duration: ZOOM_DURATION })} />
          <Button icon="zoom-out" e2eIdentifiers="zoom-out" onClick={() => zoomOut({ duration: ZOOM_DURATION })} />
          <Button icon="zoom-to-fit" e2eIdentifiers="fit-view" onClick={() => fitView({ duration: ZOOM_DURATION })} />
        </ButtonGroup>
      </Controls>
      <Background variant={BackgroundVariant.Dots} gap={15} size={0.5} />
    </ReactFlow>
  );
}

/** Exports. */
export default observer(FlowViewBlocks);
