import { MouseEvent } from "react";
import { observer } from "mobx-react";

import { SortableLinkedLists } from "@components/Shared/SortableLinkedLists";
import { TItemRendererArgs } from "@components/Shared/SortableLinkedLists/Components/Item";
import { useDragToSelect } from "@providers/DragToSelect";
import appStore from "@store/AppStore";
import { IRequirementsPage } from "@store/RequirementsStore";

import { RequirementsDocumentBlock } from "./RequirementsDocumentBlock";

import styles from "./RequirementsDocumentView.module.scss";

interface IRequirementsDocumentViewProps {
  page: IRequirementsPage;
}

const REQUIREMENTS_CONTAINER_ID = "REQUIREMENTS_CONTAINER_ID";

const RequirementsDocumentView = (props: IRequirementsDocumentViewProps) => {
  const { page } = props;
  const { resetSelection, elementsContainerRef } = useDragToSelect({
    onDelete: appStore.workspaceModel?.deleteRequirementBlocks,
    allIds: page.allValidBlockIds,
  });

  const handlePageMouseDown = (e: MouseEvent<HTMLDivElement>) => {
    const isEmptySpaceClick = [REQUIREMENTS_CONTAINER_ID].includes((e.target as HTMLElement).id);
    if (isEmptySpaceClick && appStore.ui.selectedIds.length) {
      appStore.ui.setSelectedIds([]);
    }
  };

  const handleMoveBlocks = (ids: string[], targetId: string) => {
    if (ids.length === 1) {
      page.moveBlock(ids[0], targetId);
    }
  };

  const renderItem = (params: TItemRendererArgs) => {
    const { index, listeners, handleProps, value: reqBlockId } = params;
    const reqBlock = appStore.workspaceModel?.requirementBlockMap.get(reqBlockId);

    if (!reqBlock) {
      return null;
    }

    return (
      <RequirementsDocumentBlock
        reqBlock={reqBlock}
        reqPage={page}
        onFocus={resetSelection}
        dragListeners={{ ...listeners, ...handleProps }}
        key={`${reqBlockId}--${index}`}
      />
    );
  };

  return (
    <div id={REQUIREMENTS_CONTAINER_ID} onMouseDown={handlePageMouseDown} className={styles.requirementsDocumentView}>
      <div className={styles.requirementsDocumentViewInner}>
        <SortableLinkedLists
          noGap
          placeholder={() => null}
          defaultSelectedIds={[...appStore.ui.selectedIds]}
          wrapperRef={elementsContainerRef}
          multiSelect
          onMultipleItemsDragEnd={handleMoveBlocks}
          renderItem={renderItem}
          items={{ "main-report-block": page.validatedBlockIds }}
        />
      </div>
    </div>
  );
};

export default observer(RequirementsDocumentView);
