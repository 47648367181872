import { CreateWebhookDto, UpdateWebhookDto as UpdateWebhookDto, Webhook } from "../models/webhook";
import { GetWebhooksRequestDto } from "../models/webhook/webhookRequestDto.model";
import { GetWebhooksResponseDto } from "../models/webhook/webhookResponseDto.model";

import { HttpClient, ParentClient } from "./client";

export class Webhooks extends HttpClient {
  public constructor(parent: ParentClient) {
    super(parent);
  }

  private readonly endpoint: string = "/webhooks";

  public create = async (dto: CreateWebhookDto): Promise<Webhook | undefined> => {
    try {
      const res = await this.instance.post<Webhook>(`${this.endpoint}`, dto);
      return res.data;
    } catch (err) {
      console.error(err);
      return;
    }
  };

  public update = (id: string, dto: UpdateWebhookDto) => {
    return this.instance.patch<Webhook>(`${this.endpoint}/${id}`, dto);
  };

  public retrieveList = async (dto: GetWebhooksRequestDto): Promise<GetWebhooksResponseDto | undefined> => {
    try {
      const res = await this.instance.get<GetWebhooksResponseDto>(`${this.endpoint}`, { params: dto });
      return res.data;
    } catch (err) {
      console.error(err);
      return;
    }
  };

  public retrieveById = async (id: string): Promise<Webhook | undefined> => {
    try {
      const res = await this.instance.get<{ webhook: Webhook }>(`${this.endpoint}/${id}`);
      return res.data.webhook;
    } catch (err) {
      console.error(err);
      return;
    }
  };

  public retrieveEvents = async (id: string): Promise<Webhook | undefined> => {
    try {
      const res = await this.instance.get<Webhook>(`${this.endpoint}/${id}`);
      return res.data;
    } catch (err) {
      console.error(err);
      return;
    }
  };

  public delete = (id: string) => {
    return this.instance.delete<Webhook>(`${this.endpoint}/${id}`);
  };
}
