import { Icon } from "@blueprintjs/core";
import { ItemPredicate, ItemRenderer, Suggest2 } from "@blueprintjs/select";
import { observer } from "mobx-react";

import { MenuItem } from "@components/MenuItem";
import appStore from "@store/AppStore";
import { IBlock } from "@store/BlockStore";
import { Text } from "src/ui/Text";

import "./DestinationPanel.scss";

type Props = {
  entityId?: string;
  onChange: (id: string) => void;
};

const TargetBlockPanel = (props: Props) => {
  const { entityId = "", onChange } = props;

  const itemPredicate: ItemPredicate<IBlock> = (query, item: IBlock, _index, exactMatch) => {
    const normalizedTitle = item.label.toLowerCase();
    const normalizedQuery = query.toLowerCase();

    return exactMatch ? normalizedTitle === normalizedQuery : normalizedTitle.includes(normalizedQuery);
  };

  const renderAutoCompleteItem: ItemRenderer<IBlock> = (entry, { handleClick, modifiers }) => {
    if (!entry || !modifiers.matchesPredicate) {
      return null;
    }
    const text = `${entry.path}`;

    return (
      <MenuItem
        active={modifiers.active}
        disabled={modifiers.disabled}
        text={entry.label}
        key={entry.id}
        onClick={handleClick}
        label={text}
        e2eIdentifiers={["autocomplete-item", text]}
      />
    );
  };

  const blocks = appStore.workspaceModel?.blocks.filter(b => b.id === appStore.workspaceModel?.rootBlock.id || !!b.parentBlock) || [];

  return (
    <div className="destination-panel">
      <Text className="destination-panel--title">Import to which block?</Text>
      <Suggest2<IBlock>
        inputProps={{
          placeholder: "Select here",
          large: true,
          rightElement: <Icon className="destination-panel--icon" size={20} icon="chevron-down" />,
        }}
        onItemSelect={b => onChange(b.id)}
        itemPredicate={itemPredicate}
        popoverProps={{ matchTargetWidth: true, minimal: true }}
        popoverContentProps={{ className: "destination-panel--popover" }}
        items={blocks}
        itemRenderer={renderAutoCompleteItem}
        inputValueRenderer={b => b.label}
        selectedItem={appStore.workspaceModel?.blockMap.get(entityId)}
        noResults={<MenuItem disabled text="No results" e2eIdentifiers="no-results" />}
        resetOnSelect
      />
    </div>
  );
};

export default observer(TargetBlockPanel);
