import { NonIdealState } from "@blueprintjs/core";
import { observer } from "mobx-react";

import { RightSidePanels } from "@components/RightSidePanels";
import FeedPanel from "@router/components/BlockView/Feed/FeedPanel";
import ProjectManagementPanel from "@router/components/BlockView/ProgrammaticsPanel/ProgrammaticsPanel";
import appStore from "@store/AppStore";
import { IBlock } from "@store/BlockStore";
import { IPropertyInstance } from "@store/PropertyInstanceStore";
import { BlockSidePanel } from "@store/SidePanelStore";

import { PropertyDetailsViewer } from "./PropertyDetailsViewer";

interface IBlockViewRightSidePanelsProps {
  block: IBlock;
  activeSidePanel?: BlockSidePanel;
  activePropertyInstance?: IPropertyInstance;
}

const BlockViewRightSidePanels = (props: IBlockViewRightSidePanelsProps) => {
  const { block, activeSidePanel, activePropertyInstance } = props;
  const { pinnedBlockSidePanels } = appStore.env.sidePanel;

  const renderPanel = (panel?: BlockSidePanel) => {
    switch (panel) {
      case BlockSidePanel.HistoryAndComments:
        return <FeedPanel key={panel} block={block} />;
      case BlockSidePanel.Programmatics:
        return <ProjectManagementPanel key={panel} block={block} />;
      case BlockSidePanel.PropertyDetails: {
        if (!activePropertyInstance) {
          return <NonIdealState title="No active Property Instance" description="You can select a property by clicking on its field" />;
        }

        return <PropertyDetailsViewer propertyInstance={activePropertyInstance} block={block} />;
      }
      default:
        return null;
    }
  };
  return (
    <RightSidePanels<BlockSidePanel> activeSidePanel={activeSidePanel} pinnedSidePanels={pinnedBlockSidePanels} renderPanel={renderPanel} />
  );
};

export default observer(BlockViewRightSidePanels);
