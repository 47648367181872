import AttachmentsPanel from "@components/Modeling/ModelingFrame/ModelBlock/Attachments/AttachmentsPanel";
import { IBlock } from "@store/BlockStore";

/** Type defs. */
type AttachmentsTabPanelProps = {
  block: IBlock;
};

/** Main function. */
function AttachmentsTabPanel({ block }: AttachmentsTabPanelProps) {
  return <AttachmentsPanel block={block} />;
}

/** Exports. */
export type { AttachmentsTabPanelProps };
export default AttachmentsTabPanel;
