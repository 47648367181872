import { JSX, ReactNode } from "react";
import { IconName } from "@blueprintjs/core";
import { MaybeElement } from "@blueprintjs/core/src/common";
import { BlueprintIcon } from "@ui/BlueprintIcon";
import { Text, TextVariant } from "@ui/Text";
import classNames from "classnames";
import { observer } from "mobx-react";

import { RelativeDateWithTooltip } from "@components/RelativeDateWithTooltip";
import { PopupMenu } from "@components/Shared/PopupMenu";
import UserInfo from "@components/UserInfo/UserInfo";
import { IUser } from "@store/UserStore";

import "./DocumentPreview.scss";

interface IDocumentPreviewProps {
  children: ReactNode | ReactNode[];
  documentType: string;
  documentTypeIcon?: IconName | MaybeElement;
  title?: string | ReactNode | ReactNode[];
  titleIcon?: string | ReactNode | ReactNode[];
  menuContent?: JSX.Element;
  user?: IUser;
  updatedAt?: number;
  large?: boolean;
  containerClassName?: string;
}

const DEFAULT_ZOOM = 0.33;
const LARGE_ZOOM = 0.8;
const DOT_UNICODE = "\u2022";

const DocumentPreview = (props: IDocumentPreviewProps) => {
  const { children, documentType, documentTypeIcon, large, containerClassName } = props;
  const { title = "Untitled", titleIcon, menuContent, user, updatedAt } = props;

  const renderMoreMenu = (content: JSX.Element) => {
    return (
      // Avoid navigating to document when clicking on the menu
      <div onClick={e => e.stopPropagation()}>
        <PopupMenu
          content={content}
          e2eIdentifiers="more-menu"
          buttonProps={{ icon: "more", minimal: true, className: "document-preview--popup-menu-button" }}
        />
      </div>
    );
  };

  const renderModifiedFields = () => (
    <div className={classNames("document-preview--modified-container", { "document-preview--modified-container-large": large })}>
      {user && (
        <div className="document-preview--header-row">
          <Text variant={TextVariant.BodyDimmed}>Modified by:</Text>
          <UserInfo user={user} size="extra-small" />
        </div>
      )}
      {large && <>{DOT_UNICODE}</>}
      {updatedAt && (
        <div className="document-preview--header-row">
          <Text variant={TextVariant.BodyDimmed}>Modified at:</Text>
          <RelativeDateWithTooltip epochTime={updatedAt} variant={TextVariant.Body} />
        </div>
      )}
    </div>
  );

  const renderHeader = () => (
    <div className="document-preview--header">
      <div className="document-preview--header-main">
        <div className="document-preview--header-title">
          <BlueprintIcon icon={documentTypeIcon} />
          <div className="document-preview--header-document-name">{documentType}</div>
        </div>
        {menuContent && renderMoreMenu(menuContent)}
      </div>
      <div className="document-preview--header-title">
        {documentTypeIcon && <span className="document-preview--header-title-icon">{titleIcon}</span>}
        <h3 className="document-preview--header-title-label">{title || "Untitled"}</h3>
      </div>
      {renderModifiedFields()}
    </div>
  );

  return (
    <div className={classNames("document-preview", { "document-preview--large": large })}>
      {renderHeader()}
      <div className={classNames("document-preview--container", containerClassName, { "document-preview--container-large": large })}>
        <div className="document-preview--main" style={{ zoom: large ? LARGE_ZOOM : DEFAULT_ZOOM }}>
          {children}
          <div className="document-preview--overlay" />
        </div>
      </div>
    </div>
  );
};

export default observer(DocumentPreview);
