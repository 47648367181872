import { useState } from "react";
import { PopoverInteractionKind, Position } from "@blueprintjs/core";
import classNames from "classnames";
import { observer } from "mobx-react";

import { Button } from "@components/Button";
import { Popover } from "@components/Popover";
import UserInfo from "@components/UserInfo/UserInfo";
import appStore from "@store/AppStore";
import { IUser } from "@store/UserStore";

import UserMenu from "./UserMenu";

import "./UserMenuButton.scss";

const getUsername = (user: IUser | null): string => {
  if (!user) {
    return "Unknown user";
  }

  return user.displayName ?? user.email ?? "Authenticated user";
};

const UserMenuButton = () => {
  const [isPopoverOpen, setPopoverOpen] = useState(false);

  const username = getUsername(appStore.userModel);
  const userEmail = appStore.userModel?.email;

  const togglePopoverOpen = () => setPopoverOpen(!isPopoverOpen);

  return (
    <Popover
      content={<UserMenu username={username} userEmail={userEmail} />}
      position={Position.TOP_RIGHT} // Explicit positioning resolves browser compatibility. Default: "auto"
      interactionKind={PopoverInteractionKind.CLICK}
      onClosing={() => setPopoverOpen(false)}
      onOpening={() => setPopoverOpen(true)}
    >
      <Button
        className={classNames("user-menu-button", { "user-menu-button-active": isPopoverOpen })}
        rightIcon={isPopoverOpen ? "chevron-up" : "chevron-down"}
        onClick={togglePopoverOpen}
        e2eIdentifiers="user-menu"
        minimal
      >
        {appStore.userModel && <UserInfo user={appStore.userModel} size="small" disableTooltip />}
      </Button>
    </Popover>
  );
};

export default observer(UserMenuButton);
