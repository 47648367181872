import { Editor, FocusPosition } from "@tiptap/core";

import { getCheckboxComponent } from "@components/Reports/Editor/utils";
import { reportBlockHeadingTypes } from "@components/Reports/Page/utils";
import { RollupEditorType } from "@rollup-types/editor";
import appStore from "@store/AppStore";

const isFocusableBlockType = (type: RollupEditorType): boolean => {
  return type !== RollupEditorType.image && type !== RollupEditorType.hr;
};

export function moveCaretToStart(element: HTMLDivElement, position: FocusPosition = "start"): void {
  const range = document.createRange();
  const sel = window.getSelection();
  range.setStart(element, 0);
  range.setEnd(element, 0);
  sel?.removeAllRanges();
  sel?.addRange(range);
  // We can access ProseMirror editor object from the start container;
  const editor = (range.startContainer as unknown as { editor: Editor }).editor as Editor;
  editor.commands.focus(position);
}

export const focusPreviousBlock = <T extends { id: string; type: RollupEditorType }>(block: T, blocks: T[]) => {
  const currentBlockIndex = blocks.findIndex(b => b.id === block.id) || 0;
  const previousBlock = blocks.at(currentBlockIndex - 1) || "";

  if (!previousBlock) {
    return;
  }

  if (!isFocusableBlockType(previousBlock.type) && currentBlockIndex > 0) {
    focusPreviousBlock(previousBlock, blocks);
    return;
  }

  const previousEditor = appStore.env.editors.get(previousBlock.id);
  previousEditor?.commands.focus("end");
};

export const focusNextBlock = <T extends { id: string; type: RollupEditorType }>(block: T, blocks: T[]) => {
  const currentBlockIndex = blocks.findIndex(b => b.id === block.id) || 0;
  const nextBlock = blocks.at(currentBlockIndex + 1) || "";

  if (!nextBlock) {
    return;
  }
  if (!isFocusableBlockType(nextBlock.type)) {
    focusNextBlock(nextBlock, blocks);
  }

  const nextEditor = appStore.env.editors.get(nextBlock.id);
  nextEditor?.commands.focus("end");
};

export const getCaretPosition = (editor: Editor | null) => editor?.state.selection.$anchor.pos || 1;

export const isEmpty = (editor: Editor | null) => {
  return editor?.getHTML().trim() === "<p></p>";
};

export const htmlStringToText = (htmlString: string): string => {
  const fragment = document.createRange().createContextualFragment(htmlString);
  return fragment.textContent || "";
};

const getNewBlockContent = (content: string, type: RollupEditorType) => {
  if (reportBlockHeadingTypes.includes(type)) {
    return getHeadingContent(type, content);
  } else if (type === RollupEditorType.quote) {
    return `<blockquote>${content}</blockquote>`;
  } else if (type === RollupEditorType.checklist) {
    return getCheckboxComponent(false, content);
  }
  return content;
};

export const changeBlockTypes = <
  T extends { id: string; updateText: (value: string) => void; updateType: (value: RollupEditorType) => void },
>(
  blocks: T[],
  type: RollupEditorType
) => {
  blocks.forEach(block => {
    const editor = appStore.env.editors.get(block.id);
    const currentContent = editor?.getText() || "";
    const newContent = getNewBlockContent(currentContent, type);
    block.updateText(newContent);
    block.updateType(type);
  });
};

export const getHeadingContent = (type: RollupEditorType, text?: string) => {
  const regex = /^<h[1-6]>.*<\/h[1-6]>$/;
  const textAlreadyHasTag = text && regex.test(text);
  if (textAlreadyHasTag) {
    return text;
  }
  const headingNumber = type.match(/\d/)?.[0];
  if (headingNumber) {
    return `<h${headingNumber}>${text}</h${headingNumber}>`;
  }
  return `<h1>${text}</h1>`;
};
