import { observer } from "mobx-react";

import { GitHubDarkIcon, GitHubLightIcon } from "@assets/icons/thirdParty";
import { MenuItem } from "@components/MenuItem";
import IntegrationFilterableMenu from "@components/Shared/IntegrationFilterableMenu";
import { ThemedCustomIcon } from "@components/Shared/LegacyCustomIcon/ThemedCustomIcon";
import { GitHubListItem, IntegrationItem, IntegrationLinkData, IntegrationProvider } from "@rollup-api/models/integrations";
import { getIntegrationItem } from "@rollup-api/utils/integrations";
import { rollupClient } from "src/core/api";

const GitHubMenu = IntegrationFilterableMenu<GitHubListItem>;

export const GitHubMenuSection = observer(({ onAttachLink }: { onAttachLink?: (data: IntegrationLinkData) => void }) => {
  const handleSelect = (item: IntegrationItem<GitHubListItem>) => {
    onAttachLink?.({
      name: item.title,
      url: item.data.url,
      metadata: { id: item.data.id, provider: IntegrationProvider.GitHub, type: item.data.type },
    });
  };

  const handleGitGubQuery = async (query: string): Promise<IntegrationItem<GitHubListItem>[]> => {
    const res = await rollupClient.integrations.filterGitHubItems(query);
    return res.data?.map(getIntegrationItem);
  };

  return (
    <MenuItem
      icon={<ThemedCustomIcon lightIcon={<GitHubLightIcon />} darkIcon={<GitHubDarkIcon />} />}
      e2eIdentifiers="github-integration"
      text="GitHub"
    >
      <GitHubMenu
        defaultItems={[]}
        onSelect={handleSelect}
        placeholder="Search for repos, issues, tags, and PRs"
        onQuery={handleGitGubQuery}
      />
    </MenuItem>
  );
});
