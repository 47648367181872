import { PropertyDataType } from "@rollup-io/engineering/dist/lib/interfaces/propertyDefinition";

import { mergeEntities } from "@components/Reports/Editor/Extentions/Mention/MentionUtils";
import { IMentionItem, MentionType } from "@components/Reports/Editor/Extentions/Mention/types";
import { IAnalysisOutput } from "@store/Analysis/AnalysisOutputStore";
import appStore from "@store/AppStore";
import { IAttachment } from "@store/AttachmentStore";
import { IBlock } from "@store/BlockStore";
import { IPropertyInstance } from "@store/PropertyInstanceStore";
import { IReport } from "@store/ReportsStore";
import { IUser } from "@store/UserStore";
import { IWorkspace } from "@store/WorkspaceStore";
import { createBpIcon } from "@utilities/Icon";
import { isDefined, isFieldDefined } from "@utilities/TypeGuards";

const isValidAttachmentEntry = (attachment: IAttachment) => !!attachment.block;

export const createPropertyInstanceEntries = (
  propertyInstances: IPropertyInstance[],
  valueOnly: boolean,
  scalarOnly?: boolean
): IMentionItem<IPropertyInstance>[] => {
  const mentionItems: IMentionItem<IPropertyInstance>[] = [];
  for (const p of propertyInstances) {
    if (p.propertyDefinition && (p.propertyDefinition.dataType === PropertyDataType.scalar || !scalarOnly) && p.path) {
      mentionItems.push({
        id: `prop${valueOnly ? "-val" : ""}:${p.id}`,
        type: valueOnly ? MentionType.PropertyInstanceValue : MentionType.PropertyInstanceName,
        queryString: p.path,
        icon: createBpIcon("property"),
        extendedLabel: p.path,
        label: `${p.parentBlock.label}:${p.propertyDefinition.label}`,
        entity: p,
      });
    }
  }
  return mentionItems;
};

const createAttachmentEntries = (attachments: IAttachment[]): IMentionItem<IAttachment>[] => {
  return attachments.filter(isValidAttachmentEntry).map(attachment => ({
    id: `attachment:${attachment.id}`,
    type: MentionType.Attachment,
    queryString: attachment.label,
    extendedLabel: attachment.label,
    label: attachment.label,
    entity: attachment,
  }));
};

const createBlockEntries = (blocks: IBlock[]): IMentionItem<IBlock>[] => {
  return blocks.reduce((acc: IMentionItem<IBlock>[], b) => {
    if (b.path) {
      acc.push({
        id: `block:${b.id}`,
        type: MentionType.Block,
        icon: b.iconView,
        queryString: b.path,
        extendedLabel: b.path,
        label: b.label,
        entity: b,
      });
    }
    return acc;
  }, []);
};

const createReportEntries = (reports: IReport[]): IMentionItem<IReport>[] => {
  return reports.map(r => ({
    id: `report:${r.id}`,
    type: MentionType.Report,
    queryString: r.label,
    extendedLabel: `${r.label || "Untitled"}`,
    label: `${r.label || "Untitled"}`,
    entity: r,
  }));
};

const createOrgMemberEntries = (orgMembers: IUser[] = []): IMentionItem<IUser>[] => {
  return orgMembers.map(user => ({
    id: `user:${user.id}`,
    type: MentionType.User,
    queryString: `${user.name ?? ""} ${user.email ?? ""}`,
    extendedLabel: (user.name || user.email) ?? "",
    label: (user.name || user.email) ?? "",
    entity: user,
  }));
};

const createAnalysisOutputEntries = (analysisOutputs: IAnalysisOutput[] = []): IMentionItem<IAnalysisOutput>[] => {
  return analysisOutputs.filter(isFieldDefined("codeBlock")).map(analysisOutput => {
    const label = `${analysisOutput.codeBlock.label}.${analysisOutput.label}`;
    return {
      id: `analysis-output:${analysisOutput.id}`,
      type: MentionType.AnalysisOutput,
      queryString: `${analysisOutput.label ?? ""} ${analysisOutput.codeBlock.label}`,
      extendedLabel: label,
      label: label,
      entity: analysisOutput,
    };
  });
};

export const createMentionEntries = (
  workspace: IWorkspace | undefined,
  valueOnly: boolean,
  scalarOnly?: boolean
): IMentionItem<IUser | IBlock | IPropertyInstance | IReport | IAttachment | IAnalysisOutput>[] => {
  if (!workspace) {
    if (valueOnly) {
      return [];
    }
    return createOrgMemberEntries(appStore.orgModel?.info.orgMembers);
  }

  return [
    ...createPropertyInstanceEntries(workspace.propertyInstances, valueOnly, scalarOnly),
    ...(valueOnly ? [] : createAttachmentEntries(workspace.attachments.values)),
    ...(valueOnly ? [] : createBlockEntries(workspace.blocks)),
    ...(valueOnly ? [] : createReportEntries(workspace.reports)),
    ...(valueOnly ? [] : createOrgMemberEntries(appStore.orgModel.info.orgMembers)),
    ...(valueOnly ? [] : createAnalysisOutputEntries(workspace.analysis.analysisOutputs)),
  ];
};

const createSamplePropertyInstanceEntries = (workspace: IWorkspace, valueOnly: boolean, scalarOnly?: boolean) => {
  const recentProperties = appStore.env.recentPropertyIds.map(id => workspace.propertyInstanceMap.get(id)).filter(isDefined);
  const isValid = (p: IPropertyInstance) =>
    !!(p.propertyDefinition && (p.propertyDefinition.dataType === PropertyDataType.scalar || !scalarOnly) && p.path);
  // if valueOnly is true, show up to 10 properties as we won't show attachments, blocks, reports, or org members
  const maxEntities = valueOnly ? 10 : 3;
  const entries = mergeEntities({
    recentEntities: recentProperties,
    allEntities: workspace.propertyInstances,
    isValid,
    maxEntities,
  });
  return createPropertyInstanceEntries(entries, valueOnly, scalarOnly);
};

const createSampleAttachmentEntries = (workspace: IWorkspace) => {
  const recentAttachments = appStore.env.recentAttachmentIds.map(id => workspace.attachments.get(id)).filter(isDefined);
  const entries = mergeEntities({
    recentEntities: recentAttachments,
    allEntities: workspace.attachments.values,
    isValid: isValidAttachmentEntry,
  });
  return createAttachmentEntries(entries);
};

const createSampleBlockEntries = (workspace: IWorkspace) => {
  const recentBlocks = appStore.env.recentBlockIds.map(id => workspace.blockMap.get(id)).filter(isDefined);
  const isValid = (b: IBlock) => !!b.path;
  const entries = mergeEntities({ recentEntities: recentBlocks, allEntities: workspace.blocks, isValid });
  return createBlockEntries(entries);
};

const createSampleReportEntries = (workspace: IWorkspace) => {
  const recentReports = appStore.env.recentReportIds.map(id => workspace.reports.find(r => r.id === id)).filter(isDefined);
  const entries = mergeEntities({ recentEntities: recentReports, allEntities: workspace.reports });
  return createReportEntries(entries);
};

const createSampleOrgMemberEntries = (orgMembers: IUser[]) => {
  const recentUsers = appStore.env.recentUserIds.map(id => orgMembers.find(user => user.id === id)).filter(isDefined);
  const entries = mergeEntities({ recentEntities: recentUsers, allEntities: orgMembers });
  return createOrgMemberEntries(entries);
};

const createSampleAnalysisOutputEntries = (workspace: IWorkspace) => {
  const recentAnalysisOutputs = appStore.env.recentAnalysisOutputIds
    .map(id => workspace.analysis.analysisOutputs.find(r => r.id === id))
    .filter(isDefined);
  const entries = mergeEntities({ recentEntities: recentAnalysisOutputs, allEntities: workspace.analysis.analysisOutputs });
  return createAnalysisOutputEntries(entries);
};

export const createSampleMentionEntries = (workspace: IWorkspace | undefined, valueOnly: boolean, scalarOnly?: boolean) => {
  if (!workspace) return [];
  return [
    ...createSamplePropertyInstanceEntries(workspace, valueOnly, scalarOnly),
    ...(valueOnly ? [] : createSampleAttachmentEntries(workspace)),
    ...(valueOnly ? [] : createSampleBlockEntries(workspace)),
    ...(valueOnly ? [] : createSampleReportEntries(workspace)),
    ...(valueOnly ? [] : createSampleOrgMemberEntries(appStore.orgModel.info.orgMembers)),
    ...(valueOnly ? [] : createSampleAnalysisOutputEntries(workspace)),
  ];
};
