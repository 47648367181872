import { UseQueryOptions } from "@tanstack/react-query";

import { Invoice } from "@rollup-api/models/invoices/invoice.model";

import { QueryKeys, rollupClient } from "../core/api";

export abstract class InvoiceQOFactory {
  public static createGetAllQO(): UseQueryOptions<Invoice[]> {
    return {
      queryKey: [QueryKeys.InvoiceList],
      queryFn: () => rollupClient.billingModule.invoices.getAll().then(res => res.data),
    };
  }
}
