import assignIn from "lodash/assignIn";
import { Instance, IType, SnapshotIn, SnapshotOut, types } from "mobx-state-tree";

import { UpdateAnalysisInputDto } from "@rollup-api/models/code-blocks";
import { updateAnalysisInput } from "@rollup-api/utils";
import { IPropertyInstance } from "@store/PropertyInstanceStore";
import { parentWorkspace } from "@utilities";

import { CodeBlockStore } from "./CodeBlockStore";

export const AnalysisInputStore = types
  .model("AnalysisInputStore", {
    id: types.identifier,
    value: types.optional(types.string, ""),
    unit: types.optional(types.string, ""),
    codeBlock: types.safeReference(types.late(() => CodeBlockStore)),
    label: types.optional(types.string, "Untitled Code Input"),
    createdAt: types.optional(types.number, Date.now()),
    updatedAt: types.optional(types.number, Date.now()),
  })
  .actions(self => ({
    patch(update: UpdateAnalysisInputDto) {
      // Prevent updating of fixed properties
      const invalidFields = ["id", "codeBlock"];
      const updateKeys = Object.keys(update);
      for (const field of invalidFields) {
        if (updateKeys.includes(field)) {
          return false;
        }
      }

      try {
        assignIn(self, update);
        return true;
      } catch (err) {
        console.warn(err);
        return false;
      }
    },
    setLabel(label: string) {
      self.label = label;
      updateAnalysisInput(self.id, { label });
    },
    setValue(value: string) {
      // TODO: Validation
      self.value = value;
      updateAnalysisInput(self.id, { value });
    },
    setPropertyReference(property: IPropertyInstance) {
      const value = `prop:${property.id}`;
      this.setValue(value);
    },
  }))
  .views(self => ({
    get propertyReference() {
      const propertyRegex = /^prop:(?<propId>.*)$/;
      const match = self.value?.match(propertyRegex);
      if (match?.groups?.propId) {
        return parentWorkspace(self)?.propertyInstanceMap?.get(match.groups.propId);
      }
      return undefined;
    },
    get codeBlockId() {
      return self.codeBlock?.id ?? "";
    },
  }));

export interface IAnalysisInput extends Instance<typeof AnalysisInputStore> {}

export interface IAnalysisInputSnapshotIn extends SnapshotIn<typeof AnalysisInputStore> {}

interface IAnalysisInputSnapshotOut extends SnapshotOut<typeof AnalysisInputStore> {}

export interface IAnalysisInputMobxType extends IType<IAnalysisInputSnapshotIn, IAnalysisInputSnapshotOut, IAnalysisInput> {}
