import { BomMetaColumn } from "@rollup-api/models";
import { ECsvImportColumn } from "@rollup-api/models/import";
import { ONE_MB_IN_BYTES } from "@utilities";

export const defaultColumnsMap: Record<ECsvImportColumn, string> = {
  label: "",
  multiplicity: "",
  partNumber: "",
  description: "",
  parentBlock: "",
};

export const CELL_WIDTH = 100;
export const FILE_IMPORT_LIMIT = 500 * ONE_MB_IN_BYTES;

export const excludedBomColumns: string[] = [BomMetaColumn.Subtotal, BomMetaColumn.File];
