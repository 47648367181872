import { useMemo } from "react";
import { ButtonGroup, Card, IconName, Intent, MaybeElement, Spinner, Tooltip } from "@blueprintjs/core";
import { observer } from "mobx-react";

import { AnchorButton } from "@components/AnchorButton";
import { Button } from "@components/Button";
import { AttachmentStatus } from "@rollup-api/models/cloudStorage";
import { IAttachment } from "@store/AttachmentStore";
import { IBlock } from "@store/BlockStore";

import { useAttachmentFunctions } from "./attachmentHooks";
import AttachmentIcon from "./AttachmentIcon";

import "./Attachment.scss";

const renderUserImageAndName = (displayName: string, imageUrl: string | null) => {
  return (
    <div className="flex items-center gap-2 text-[12px] leading-[1]">
      <div className="tag-image h-[18px] w-[18px] flex items-center justify-center bg-gray-600 rounded-full">
        {imageUrl && (
          <img referrerPolicy="no-referrer" width={18} height={18} src={imageUrl} alt={displayName} className="object-cover rounded-full" />
        )}
        {!imageUrl && displayName.charAt(0)}
      </div>
      <div>{displayName}</div>
    </div>
  );
};

type AttachmentProps = {
  block: IBlock;
  attachment: IAttachment;
  hideName?: boolean;
  hideDate?: boolean;
  hideSize?: boolean;
};

const Attachment = (props: AttachmentProps) => {
  const { block, attachment, hideName, hideDate, hideSize } = props;

  const { handleDownload, handleView } = useAttachmentFunctions({
    attachment,
    block,
  });

  const { icon, tooltip } = useMemo((): { icon: IconName | MaybeElement; tooltip: string } => {
    switch (attachment.status) {
      case AttachmentStatus.Converted:
        return { icon: "cube", tooltip: "View attachment" };
      case AttachmentStatus.ConversionPending:
        return { icon: "time", tooltip: "Conversion pending" };
      case AttachmentStatus.ConversionInProgress:
        return { icon: <Spinner size={12} />, tooltip: "Conversion in progress" };
      case AttachmentStatus.ConversionFailed:
        return { icon: "error", tooltip: "Conversion failed" };
      default:
        return { icon: "blank", tooltip: "Upload complete" };
    }
  }, [attachment?.status]);

  return (
    <Card className="attachment">
      <AttachmentIcon attachment={attachment} />
      <div>{attachment.label}</div>
      {/* File size */}
      {!hideSize && <div>{attachment.formattedFileSize}</div>}
      {/* Date */}
      {!hideDate && <div>{attachment.formattedCreatedAt}</div>}
      {/* User */}
      {!hideName && <div>{renderUserImageAndName(attachment.user?.displayName || "", attachment.user?.avatarUrl || null)}</div>}
      {/*Actions.*/}
      <div>
        <ButtonGroup>
          <Tooltip hoverOpenDelay={500} content={tooltip}>
            {attachment.status === AttachmentStatus.Converted && (
              <AnchorButton icon={icon} minimal small intent={Intent.NONE} onClick={() => handleView()} e2eIdentifiers="view" />
            )}
          </Tooltip>
          <Button
            minimal
            intent={Intent.NONE}
            icon="download"
            onClick={() => handleDownload()}
            e2eIdentifiers={["comment-attachment", "download"]}
          />
        </ButtonGroup>
      </div>
    </Card>
  );
};

export default observer(Attachment);
