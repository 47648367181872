import { useEffect, useState } from "react";
import { EditableText, Intent, Menu, MenuDivider } from "@blueprintjs/core";
import { useTimeout } from "@hooks/useTimeout";
import { useWorkspace } from "@hooks/useWorkspace";
import { DataSinkQOFactory } from "@queries/DataSinkQOFactory";
import { DataSourceQOFactory } from "@queries/DataSourceQOFactory";
import { useMutation } from "@tanstack/react-query";
import classNames from "classnames";
import { observer } from "mobx-react";

import { Button } from "@components/Button";
import DataSourceIcon from "@components/DataSources/DataSourceIcon/DataSourceIcon";
import { SourceStatusTag } from "@components/DataSources/Table/SourceStatusTag";
import { Icon } from "@components/Icon";
import { MenuItem } from "@components/MenuItem";
import { MenuItemDelete } from "@components/MenuItems";
import { Popover } from "@components/Popover";
import { TagsContainer } from "@components/TagsContainer";
import { showToast } from "@components/UiLayers/toaster";
import { DataSource } from "@rollup-api/models/data-sources";
import { IDataConnection } from "@store/DataConnection/DataConnectionModuleStore";
import { IDataSink } from "@store/DataConnection/DataSinkStore";
import { getFormattedDate } from "@utilities";
import { isDataSink, isDataSource } from "@utilities/DataConnection";
import { rollupClient } from "src/core/api";

import styles from "./DataConnectionTableRow.module.scss";

interface IDataConnectionTableRowProps {
  dataConnection: IDataConnection;
  dataConnectionIdToFlash?: string;
  onFlashedDataConnection(): void;
  onTestSource(dataSource: DataSource): void;
  onEditDataSink(dataSink: IDataSink): void;
}

const DataConnectionTableRow = (props: IDataConnectionTableRowProps) => {
  const { dataConnection, dataConnectionIdToFlash, onFlashedDataConnection, onTestSource, onEditDataSink } = props;
  const [isEditingName, setIsEditingName] = useState(false);
  const [name, setName] = useState(dataConnection.label);
  const workspace = useWorkspace();
  const deleteDataSinkMutation = useMutation(DataSinkQOFactory.createDeleteQO(dataConnection.id, workspace.id));
  const patchDataSinkMutation = useMutation(DataSinkQOFactory.createPatchQO(workspace.id));
  const deleteDataSourceMutation = useMutation(DataSourceQOFactory.createDeleteQO(dataConnection.id, workspace.id));
  const patchDataSourceMutation = useMutation(DataSourceQOFactory.createPatchQO(dataConnection.id, workspace.id));
  const shouldFlash = dataConnectionIdToFlash === dataConnection.id;

  useTimeout({ onTimeout: onFlashedDataConnection, delay: 2000, disabled: !shouldFlash });

  useEffect(() => {
    if (dataConnection.label !== name) {
      setName(dataConnection.label);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataConnection.label]);

  const handleDelete = async (id: string) => {
    if (isDataSource(dataConnection)) {
      deleteDataSourceMutation.mutate(id);
    } else if (isDataSink(dataConnection)) {
      deleteDataSinkMutation.mutate(id);
    }
  };

  const handleRefresh = async (dataSource: DataSource) => {
    try {
      const refreshedLinks = await rollupClient.modelingModule.dataSources.refresh(dataSource.id, 0);
      if (!refreshedLinks?.data?.length) {
        showToast("All values up to date", Intent.SUCCESS);
        return;
      }
      for (const link of refreshedLinks.data) {
        await workspace.addOrUpdateDataLink(link, false);
      }
      showToast(`Updated ${refreshedLinks.data.length} value(s)`, Intent.SUCCESS);
    } catch (error) {
      showToast("Failed to refresh data source", Intent.WARNING, "error");
      console.error(error);
    }
  };

  const handleEditNameConfirm = (value: string) => {
    setIsEditingName(false);
    if (value === dataConnection.label) {
      return;
    }
    if (isDataSource(dataConnection)) {
      patchDataSourceMutation.mutate({ label: value });
    } else if (isDataSink(dataConnection)) {
      patchDataSinkMutation.mutate({ id: dataConnection.id, dto: { label: value } });
    }
  };

  const renderDataSourceMenuItems = (dataSource: DataSource) => {
    return (
      <>
        <MenuItem
          e2eIdentifiers={["data-source-test", dataSource.id]}
          text="Query data"
          icon="variable"
          onClick={() => onTestSource(dataSource)}
        />
        <MenuItem
          e2eIdentifiers={["data-source-refresh", dataSource.id]}
          text="Refresh data"
          icon="refresh"
          onClick={() => handleRefresh(dataSource)}
        />
        <MenuItem
          e2eIdentifiers={["data-source-unused", dataSource.id]}
          text="Remove unused data"
          icon="eraser"
          onClick={workspace.deleteUnusedDataLinks}
        />
      </>
    );
  };

  const renderMenu = () => (
    <Menu>
      {isDataSource(dataConnection) && (
        <MenuItem e2eIdentifiers={["edit-name", dataConnection.id]} text="Edit name" icon="edit" onClick={() => setIsEditingName(true)} />
      )}
      {isDataSink(dataConnection) && (
        <MenuItem e2eIdentifiers={["edit", dataConnection.id]} text="Edit" icon="edit" onClick={() => onEditDataSink(dataConnection)} />
      )}
      {isDataSource(dataConnection) && renderDataSourceMenuItems(dataConnection)}
      <MenuDivider />
      <MenuItemDelete e2eIdentifiers={["data-source-delete", dataConnection.id]} onDelete={() => handleDelete(dataConnection.id)} />
    </Menu>
  );

  const renderIcon = () => {
    if (isDataSource(dataConnection)) {
      return <DataSourceIcon dataSource={dataConnection} />;
    } else if (dataConnection.icon) {
      return <Icon icon={dataConnection.icon} />;
    }
    return null;
  };

  return (
    <tr className={classNames(styles.dataConnectionTableRow, { [styles.flash]: shouldFlash })}>
      <td>
        <div className={styles.cellContent}>
          {renderIcon()}
          <EditableText
            value={name}
            onChange={setName}
            isEditing={isEditingName}
            maxLength={255}
            minWidth={20}
            placeholder="Name"
            disabled={!isEditingName}
            defaultValue={dataConnection.label}
            confirmOnEnterKey
            onConfirm={handleEditNameConfirm}
            onCancel={() => setIsEditingName(false)}
          />
        </div>
      </td>
      <td>{dataConnection.description}</td>
      <td>{isDataSink(dataConnection) ? <TagsContainer tags={dataConnection.enhancedTags} /> : null}</td>
      <td>{getFormattedDate(dataConnection.updatedAt)}</td>
      <td className={styles.statusColumn}>{isDataSource(dataConnection) && <SourceStatusTag source={dataConnection} />}</td>
      <td className={styles.actionsColumn}>
        <Popover placement="left-start" content={renderMenu()}>
          <Button minimal icon="more" e2eIdentifiers={["data-source-action", dataConnection.id]} />
        </Popover>
      </td>
    </tr>
  );
};

export default observer(DataConnectionTableRow);
