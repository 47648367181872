import { toArray } from "@rollup-io/engineering";
import { AxiosError, AxiosResponse } from "axios";
import { destroy, flow, isAlive, types } from "mobx-state-tree";
import { v4 as uuidv4 } from "uuid";

import { showApiErrorToast } from "@components/UiLayers/toaster";
import { PartNumberSchema } from "@rollup-api/models";
import { IPartNumberSchema, IPartNumberSchemaMobxType, PartNumberSchemaStore } from "@store/PartNumberSchemaStore";

import { rollupClient } from "../core/api";

export const PartNumberSchemaModuleStore = types
  .model("PartNumberSchemaModuleStore", {
    partNumberSchemasMap: types.map<IPartNumberSchemaMobxType>(PartNumberSchemaStore),
  })
  .views(self => ({
    get values(): IPartNumberSchema[] {
      return toArray<IPartNumberSchema>(self.partNumberSchemasMap).filter(schema => isAlive(schema));
    },
    get(id: string): IPartNumberSchema | undefined {
      return self.partNumberSchemasMap.get(id);
    },
  }))
  .actions(self => ({
    deletePartNumberSchema(schemaId: string, notify = true) {
      const partNumberSchema = self.partNumberSchemasMap.get(schemaId);
      self.partNumberSchemasMap.delete(schemaId);
      destroy(partNumberSchema);

      if (notify) {
        rollupClient.partNumberSchemas.delete(schemaId);
      }
    },
    addPartNumberSchema: flow(function* addPartNumberSchema(
      label: string,
      schema: string,
      leadingZeroes?: number,
      workspaceId?: string,
      id = uuidv4(),
      notify = true
    ) {
      if (self.partNumberSchemasMap.has(id)) {
        return self.partNumberSchemasMap.get(id);
      }

      const partNumberSchema = self.partNumberSchemasMap.put({
        id,
        label,
        schema,
        leadingZeroes,
      });

      if (notify) {
        try {
          const createResult: AxiosResponse<PartNumberSchema> = yield rollupClient.partNumberSchemas.create({
            id,
            label,
            schema,
            leadingZeroes,
            workspaceId,
          });
          if (createResult.status !== 201) {
            destroy(partNumberSchema);
            showApiErrorToast("Can't create new Schema");
            return;
          }
        } catch (err: unknown) {
          const errorResult = err as AxiosError<{ message: string }>;
          const message = errorResult.response?.data.message || "Can't create new Schema";
          destroy(partNumberSchema);
          showApiErrorToast(message);
          return;
        }
      }

      return partNumberSchema;
    }),
  }));
