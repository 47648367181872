import { toArray } from "@rollup-io/engineering";
import { cast, flow, IAnyModelType, types } from "mobx-state-tree";

import { Category } from "@rollup-api/models/strapi/category";
import { StrapiEntitiesResponse } from "@rollup-api/models/strapi/entity";
import { StoreListing } from "@rollup-api/models/strapi/storeListing";
import appStore from "@store/AppStore";
import { FeatureFlag } from "@store/FeatureFlagsStore";
import { rollupClient } from "src/core/api";

import { IIntegration, IntegrationStore } from "./IntegrationStore";

export const SettingsStore = types
  .model("SettingsStore", {
    integrationsMap: types.map(types.late((): IAnyModelType => IntegrationStore)),
    integrationCategories: types.array(types.string),
  })
  .actions(self => ({
    fetchIntegrationCategories: flow(function* fetchIntegrationCategories() {
      if (self.integrationCategories.length) {
        return;
      }

      const res: StrapiEntitiesResponse<Category> = yield rollupClient.strapi.retrieveCategories();
      self.integrationCategories = cast(res.data.map(entity => entity.attributes.label));
    }),
    fetchIntegrations: flow(function* fetchIntegrations() {
      if (self.integrationsMap.size) {
        return;
      }

      const res: StrapiEntitiesResponse<StoreListing> = yield rollupClient.strapi.retrieveList();
      res.data.forEach(integration => {
        const id = `${integration.id}`;
        self.integrationsMap.set(id, {
          ...integration.attributes,
          isActive: appStore.env.featureFlags.enabled(FeatureFlag.FORCE_ALL_INTEGRATIONS) ? true : integration.attributes.isActive,
          headerImages: integration.attributes.headerImages?.data?.map(image => image.attributes.url) || [],
          types: integration.attributes.types?.data?.map(type => type.attributes.label) || [],
          categories: integration.attributes.categories?.data?.map(type => type.attributes.label) || [],
          logo: integration.attributes.logo?.data?.attributes.url || "",
          lightThemeLogo: integration.attributes.lightThemeLogo?.data?.attributes.url || "",
          id,
        });
      });
    }),
  }))
  .actions(self => ({
    afterCreate() {
      self.fetchIntegrations();
      self.fetchIntegrationCategories();
    },
  }))
  .views(self => ({
    get integrations(): IIntegration[] {
      return toArray(self.integrationsMap);
    },
  }))
  .views(self => ({
    get nativeIntegrations(): IIntegration[] {
      return self.integrations.filter(integration => integration.isNative);
    },
  }));
