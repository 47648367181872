import { Tag } from "@blueprintjs/core";
import classNames from "classnames";
import { observer } from "mobx-react";

import { CatalogItemStatus } from "@rollup-api/models/catalogItem/catalogItemDtos";
import { getStatusLabel } from "@utilities/CatalogItem";

import "./StatusTag.scss";

type Props = {
  status: CatalogItemStatus;
};

const StatusTag = (props: Props) => {
  const { status } = props;
  const className = classNames("status-tag", {
    "status-tag--active": status === CatalogItemStatus.Active,
    "status-tag--suspended": status === CatalogItemStatus.Suspended,
    "status-tag--review": status === CatalogItemStatus.InReview,
    "status-tag--archived": status === CatalogItemStatus.Archived,
    "status-tag--pending": status === CatalogItemStatus.Pending,
  });

  return (
    <Tag minimal className={className}>
      {getStatusLabel(status)}
    </Tag>
  );
};

export default observer(StatusTag);
