import { Menu } from "@blueprintjs/core";
import { observer } from "mobx-react";

import { MenuItem } from "@components/MenuItem";
import { RequirementBlockType } from "@rollup-api/models";

interface IRequirementAddButtonMenuProps {
  onAddBlock(type: RequirementBlockType): void;
}

const RequirementAddButtonMenu = (props: IRequirementAddButtonMenuProps) => {
  const { onAddBlock } = props;

  return (
    <Menu>
      <MenuItem
        icon="new-text-box"
        text="Requirement"
        onClick={() => onAddBlock(RequirementBlockType.requirement)}
        e2eIdentifiers="add-requirement-block"
      />
      <MenuItem icon="header-one" text="Heading 1" onClick={() => onAddBlock(RequirementBlockType.h1)} e2eIdentifiers="add-h1-block" />
      <MenuItem icon="header-two" text="Heading 2" onClick={() => onAddBlock(RequirementBlockType.h2)} e2eIdentifiers="add-h2-block" />
      <MenuItem icon="header-three" text="Heading 3" onClick={() => onAddBlock(RequirementBlockType.h3)} e2eIdentifiers="add-h3-block" />
    </Menu>
  );
};

export default observer(RequirementAddButtonMenu);
