import { PropsWithChildren } from "react";
import { Tooltip, TooltipProps } from "@blueprintjs/core";
import { observer } from "mobx-react";

import "./TitledTooltip.scss";

const DEFAULT_TOOLTIP_HOVER_DELAY = 500;

export const TitledTooltip = observer(
  (
    props: PropsWithChildren<
      Partial<
        TooltipProps & {
          title: string;
        }
      >
    >
  ) => {
    const { children, title, content, hoverOpenDelay, ...rest } = props;
    const tooltipContent = (
      <span className="tooltip-container">
        {title ? (
          <>
            <span className="tooltip-container--title">{title}</span>
            <br />
          </>
        ) : null}
        <span className="tooltip-container--content">{content}</span>
      </span>
    );
    return (
      <Tooltip {...rest} hoverOpenDelay={hoverOpenDelay ?? DEFAULT_TOOLTIP_HOVER_DELAY} content={tooltipContent}>
        {children}
      </Tooltip>
    );
  }
);
