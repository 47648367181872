import { RefObject, useCallback } from "react";
import { useOnDocumentClick } from "@hooks/useOnDocumentClick";

interface IUserClosePopoverOnClickOutsideParams {
  popoverRef: RefObject<HTMLElement>;
  disabled?: boolean;
  setPopoverOpen(isOpen: boolean): void;
}

export const useClosePopoverOnClickOutside = (params: IUserClosePopoverOnClickOutsideParams) => {
  const { popoverRef, disabled, setPopoverOpen } = params;

  const handleDocumentClick = useCallback(
    (event: MouseEvent) => {
      const isInsidePopover = popoverRef.current?.contains(event.target as Node);
      // Make sure that the target was not detached from the tree
      const isInsideBody = document.body.contains(event.target as Node);

      if (isInsideBody && !isInsidePopover) {
        setPopoverOpen(false);
      }
    },
    [popoverRef, setPopoverOpen]
  );

  useOnDocumentClick({ onDocumentClick: handleDocumentClick, disabled });
};
