import { ChangeEvent, KeyboardEvent, useState } from "react";
import { InputGroup, Menu, PopoverPosition } from "@blueprintjs/core";
import classNames from "classnames";
import { observer } from "mobx-react";

import { Button } from "@components/Button";
import { MenuItem } from "@components/MenuItem";
import { Popover } from "@components/Popover";
import { RelativeDateWithTooltip } from "@components/RelativeDateWithTooltip";
import appStore from "@store/AppStore";
import { IView } from "@store/ViewStore";
import { Text, TextVariant } from "src/ui/Text";

import "./SavedViewItem.scss";

type SavedViewItemProps = {
  view: IView;
};

const SavedViewItem = ({ view }: SavedViewItemProps) => {
  const [isRenaming, setIsRenaming] = useState(false);
  const [name, setName] = useState(view.name);

  const handleDeleteView = () => {
    appStore.env.activeAttachment?.deleteView(view.id);
  };

  const toggleRename = () => {
    setIsRenaming(state => !state);
  };

  const onNameInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
  };

  const onNameInputBlur = () => {
    if (name !== view.name && name) {
      appStore.env.activeAttachment?.updateViewName(view.id, name);
    }
    toggleRename();
  };

  const onNameInputKeyPress = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      onNameInputBlur();
    } else if (e.key === "Escape") {
      setName(view.name);
      setIsRenaming(false);
    }
  };

  const renderNav = () => {
    return (
      <Menu>
        <MenuItem text="Rename" e2eIdentifiers="view-rename" onClick={toggleRename} />
        <MenuItem text="Delete" e2eIdentifiers="view-delete" onClick={handleDeleteView} />
      </Menu>
    );
  };

  const handleViewClick = () => {
    if (!isRenaming) {
      view.activate();
    }
  };

  return (
    <div className={classNames("saved-view-item", { ["selected"]: appStore.env.attachmentViewer?.activeSavedViewId === view.uniqueId })}>
      {isRenaming ? (
        <InputGroup
          value={name}
          onBlur={onNameInputBlur}
          onChange={onNameInputChange}
          onKeyUp={onNameInputKeyPress}
          placeholder="Enter name"
          autoFocus
          className="saved-view-item--label"
        />
      ) : (
        <>
          <div className="saved-view-item--label" onMouseDown={handleViewClick}>
            <Text className="saved-view-item--title">{view.name}</Text>
            <RelativeDateWithTooltip popoverPosition={PopoverPosition.TOP} variant={TextVariant.Caption} epochTime={view.createdAt} />
          </div>
          <div className="saved-view-item--context-menu-button">
            <Popover position="bottom-right" usePortal={false} content={renderNav()}>
              <Button minimal icon="more" e2eIdentifiers="saved-view-more" />
            </Popover>
          </div>
        </>
      )}
    </div>
  );
};

export default observer(SavedViewItem);
