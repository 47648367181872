import { useCallback, useEffect, useState } from "react";
import { Checkbox, Icon, InputGroup, Intent, Label, Radio } from "@blueprintjs/core";
import { Text, TextVariant } from "@ui/Text";
import classNames from "classnames";
import { observer } from "mobx-react";
import { v4 as uuidv4 } from "uuid";

import { AnchorButton } from "@components/AnchorButton";
import { Button } from "@components/Button";
import CatalogItemCostPicker from "@components/CatalogItemCostPicker";
import CollapsibleCard from "@components/CatalogItemPreview/Components/CollapsibleCard";
import CatalogItemWeightPicker from "@components/CatalogItemWeightPicker";
import { FILE_IMPORT_LIMIT } from "@components/CreateCatalogItemDialog/constants";
import FileDropZone, { EFileFormat } from "@components/FileDropZone";
import AttachmentIcon from "@components/Modeling/ModelingFrame/ModelBlock/Attachments/AttachmentIcon";
import { Tooltip } from "@components/Tooltip";
import { RevisionCodeType, SemanticRevisionCodeType } from "@rollup-api/models/organizationSettings/organizationSettings.model";
import { useAppNavigate } from "@router/hooks";
import appStore from "@store/AppStore";
import { ICatalogItem } from "@store/CatalogItem/CatalogItemStore";
import { PdmCard } from "@store/PdmCardsVisibilityStore";
import { getMimeType } from "@utilities";

import { tooltipContent } from "./constants";

import styles from "./SpecificationsCard.module.scss";

type Props = {
  catalogItem: ICatalogItem;
  activeVersion: string;
  isVertical?: boolean;
  onVersionChange: (id: string) => void;
};

const SpecificationsCard = (props: Props) => {
  const { catalogItem, activeVersion, onVersionChange, isVertical } = props;
  const version = appStore.orgModel.catalogItems.getCatalogItemVersion(activeVersion);
  const [loading, setLoading] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [addRevision, setAddRevision] = useState(false);
  const [material, setMaterial] = useState("");
  const [cost, setCost] = useState("0");
  const [costCurrency, setCostCurrency] = useState("$");
  const [weight, setWeight] = useState("0");
  const [weightUnit, setWeightUnit] = useState("kg");
  const [comment, setComment] = useState("");
  const [revisionCode, setRevisionCode] = useState<string>();
  const [revisionName, setRevisionName] = useState("");
  const [revisionPatchType, setRevisionPatchType] = useState(SemanticRevisionCodeType.Patch);
  const nextRevisionCode = version?.nextRevisionCode(revisionPatchType);
  const { navigateToPdmSettings } = useAppNavigate();
  const [file, setFile] = useState<File | null>(null);

  const setDefaults = useCallback(() => {
    if (version) {
      setMaterial(version.material || "");
      setCost(version.cost ? version.cost.toString() : "0");
      setCostCurrency(version.costCurrency || "$");
      setWeight(version.weight ? version.weight.toString() : "0");
      setWeightUnit(version.weightUnit || "kg");
      setComment(version.comment || "");
      setRevisionCode(version.revisionCode || undefined);
      setRevisionName(version.revisionName || "");
    }
  }, [version]);

  useEffect(() => {
    setDefaults();
  }, [setDefaults, version]);

  useEffect(() => {
    setRevisionCode(addRevision ? nextRevisionCode : undefined);
  }, [nextRevisionCode, addRevision]);

  const toggleEditMode = () => setEditMode(!editMode);

  const handleCancel = () => {
    setDefaults();
    toggleEditMode();
  };

  const handleSave = async (updateCurrent = false, isDraft = false) => {
    if (!version) {
      return;
    }

    setLoading(true);

    let attachmentId: string | undefined;

    if (file) {
      attachmentId = await appStore.orgModel.attachments.add({ label: file.name }, file);
    }

    const versionData = {
      material,
      cost: Number(cost),
      costCurrency,
      weight: Number(weight),
      weightUnit,
      revisionCode,
      comment,
      isDraft,
      attachmentId,
      revisionName: addRevision ? revisionName : undefined,
    };

    if (version.isDraft || updateCurrent) {
      await appStore.orgModel.catalogItems.updateCatalogItemVersion(version.id, versionData);
    } else {
      const id = uuidv4();
      await appStore.orgModel.catalogItems.createCatalogItemVersion({
        id,
        catalogItemId: catalogItem.id,
        ...versionData,
      });
      onVersionChange(id);
    }

    setLoading(false);
    toggleEditMode();
  };

  const handleFileDrop = (fileList: FileList) => {
    if (fileList.length) {
      setFile(fileList[0]);
    } else {
      setFile(null);
    }
  };

  const renderFileContent = () => (
    <div>
      <div className={styles.specificationsCardFileDropzone}>
        <Icon icon="paperclip" size={18} />
        <div>Select CAD file to upload</div>
      </div>
      {file && (
        <div className={styles.specificationsCardFile}>
          <div className={styles.specificationsCardFileLabel}>
            <AttachmentIcon mimeType={getMimeType(file)} />
            <div className={styles.specificationsCardFileName}>{file.name}</div>
          </div>
          <Button minimal icon="cross" onClick={() => setFile(null)} e2eIdentifiers="remove-file" />
        </div>
      )}
    </div>
  );

  const renderContent = () => {
    if (editMode) {
      return (
        <div className={classNames({ [styles.specificationsCardLoading]: loading })}>
          <div className={styles.specificationsCardHeader}>
            <div className={styles.specificationsCardIndex}>
              <span className={styles.specificationsCardLabel}>Version index:</span>{" "}
              {version?.isDraft ? version.index : (version?.index || 0) + 1}
              <Tooltip
                className={styles.specificationsCardTooltip}
                content="This is incremented on every new version. It's cannot be changed"
              >
                <Icon icon="info-sign" size={12} />
              </Tooltip>
            </div>
            <AnchorButton icon="cog" minimal e2eIdentifiers="pdm-configurations" onClick={() => navigateToPdmSettings()}>
              PDM Configurations
            </AnchorButton>
          </div>
          <Label>
            <Text className={styles.specificationsCardLabel} variant={TextVariant.Caption}>
              Primary material
            </Text>
            <InputGroup value={material} onChange={e => setMaterial(e.target.value)} />
          </Label>
          <div className={styles.specificationsCardEditRow}>
            <CatalogItemCostPicker cost={cost} onCurrencyChange={setCostCurrency} currency={costCurrency} onCostChange={setCost} />
            <CatalogItemWeightPicker onWeightChange={setWeight} onUnitChange={setWeightUnit} weight={weight} unit={weightUnit} />
          </div>
          <Label>
            <Text className={styles.specificationsCardLabel} variant={TextVariant.Caption}>
              Comment
            </Text>
            <InputGroup value={comment} onChange={e => setComment(e.target.value)} />
          </Label>
          <FileDropZone
            sizeLimit={FILE_IMPORT_LIMIT}
            format={[EFileFormat.PRT, EFileFormat.SLDASM]}
            clickToSelect
            onChange={handleFileDrop}
          >
            {renderFileContent}
          </FileDropZone>
          <Checkbox label="Add revision" checked={addRevision} onChange={e => setAddRevision(e.target.checked)} />
          {addRevision && (
            <div>
              <div className={styles.specificationsCardHeader}>
                <div className={styles.specificationsCardIndex}>
                  <div>
                    <Text className={styles.specificationsCardLabel} variant={TextVariant.Caption}>
                      Revision code:{" "}
                    </Text>
                    {nextRevisionCode}
                  </div>
                  <Tooltip
                    className={styles.specificationsCardTooltip}
                    content="This is incremented on every new version. It's cannot be changed"
                  >
                    <Icon icon="info-sign" size={12} />
                  </Tooltip>
                </div>
                {appStore.orgModel.catalogItems.catalogItemRevisionCodeType === RevisionCodeType.Semantic && (
                  <div className={styles.specificationsCardPatchType}>
                    {Object.values(SemanticRevisionCodeType).map(revisionCodeType => (
                      <Radio
                        onChange={() => setRevisionPatchType(revisionCodeType)}
                        key={revisionCodeType}
                        value={revisionCodeType}
                        label={revisionCodeType}
                        checked={revisionPatchType === revisionCodeType}
                      />
                    ))}
                  </div>
                )}
              </div>
              <Label>
                <Text className={styles.specificationsCardLabel} variant={TextVariant.Caption}>
                  Revision name
                </Text>
                <InputGroup value={revisionName} onChange={e => setRevisionName(e.target.value)} />
              </Label>
            </div>
          )}
        </div>
      );
    }

    return (
      <div className={styles.specificationsCardContent}>
        <div className={styles.specificationsCardRow}>
          <Text className={styles.specificationsCardLabel} variant={TextVariant.Caption}>
            Primary Material:
          </Text>
          <Text variant={TextVariant.Body}>{material}</Text>
        </div>
        <div className={styles.specificationsCardRow}>
          <Text className={styles.specificationsCardLabel} variant={TextVariant.Caption}>
            Cost:
          </Text>
          <Text variant={TextVariant.Body}>
            {costCurrency} {cost}
          </Text>
        </div>
        <div className={styles.specificationsCardRow}>
          <Text className={styles.specificationsCardLabel} variant={TextVariant.Caption}>
            Weight:
          </Text>
          <Text variant={TextVariant.Body}>
            {weight} {weightUnit}
          </Text>
        </div>
        <div className={classNames(styles.specificationsCardRow, styles.specificationsCardComment)}>
          <Text className={styles.specificationsCardLabel} variant={TextVariant.Caption}>
            Comment:
          </Text>
          <Text variant={TextVariant.Body}>{comment}</Text>
        </div>
        {version?.revisionCode && (
          <div className={styles.specificationsCardRow}>
            <Text className={styles.specificationsCardLabel} variant={TextVariant.Caption}>
              Revision Code:
            </Text>
            <Text variant={TextVariant.Body}>{version.revisionCode}</Text>
            <Tooltip
              className={styles.specificationsCardTooltip}
              content="This is incremented on every new version. It's cannot be changed"
            >
              <Icon icon="info-sign" size={12} />
            </Tooltip>
          </div>
        )}
        {version?.revisionName && (
          <div className={styles.specificationsCardRow}>
            <Text className={styles.specificationsCardLabel} variant={TextVariant.Caption}>
              Revision Name:
            </Text>
            <Text variant={TextVariant.Body}>{version.revisionName}</Text>
          </div>
        )}
      </div>
    );
  };

  const renderRightActions = () =>
    editMode ? (
      <>
        <Button disabled={loading} onClick={handleCancel} minimal e2eIdentifiers="cancel-specification">
          Cancel
        </Button>
        <Button
          tooltip="Save these updates as a draft version, return to this later"
          disabled={loading}
          onClick={() => handleSave(false, true)}
          outlined
          e2eIdentifiers="save-draft-specification"
        >
          Save as draft
        </Button>
        <Button
          tooltip="Save these updates as a new version"
          disabled={loading}
          onClick={() => handleSave()}
          intent={Intent.PRIMARY}
          e2eIdentifiers="save-specification"
        >
          Save as new
        </Button>
        <Button
          tooltip="Update current version"
          disabled={loading}
          onClick={() => handleSave(true)}
          intent={Intent.PRIMARY}
          e2eIdentifiers="save-specification"
        >
          Update
        </Button>
      </>
    ) : (
      <Button onClick={toggleEditMode} className={styles.specificationsCardEdit} minimal e2eIdentifiers="edit-specification" icon="edit">
        Edit
      </Button>
    );

  return (
    <CollapsibleCard
      visible={isVertical ? true : appStore.env.pdmCardVisibility.specifications}
      onToggle={() => appStore.env.pdmCardVisibility.toggleCard(PdmCard.Specifications)}
      className={styles.specificationsCard}
      width={editMode && !isVertical ? 550 : "auto"}
      version={!editMode ? version?.index : undefined}
      title="Specifications"
      tooltip={tooltipContent}
      disabled={isVertical}
      rightActions={renderRightActions()}
    >
      {renderContent()}
    </CollapsibleCard>
  );
};

export default observer(SpecificationsCard);
