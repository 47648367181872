import { MultiSelect as BPMultiSelect, MultiSelectProps } from "@blueprintjs/select";
import classNames from "classnames";
import { observer } from "mobx-react";

import styles from "./MultiSelect.module.scss";

interface IMultiSelectProps<T> extends MultiSelectProps<T> {}

const MultiSelect = <T,>(props: IMultiSelectProps<T>) => {
  const { className, popoverProps, tagInputProps, ...restProps } = props;

  return (
    <BPMultiSelect<T>
      className={classNames(className)}
      tagInputProps={{
        ...tagInputProps,
        className: classNames(styles.multiSelectTagInput, tagInputProps?.className, {}),
      }}
      popoverProps={{ minimal: true, matchTargetWidth: true, ...popoverProps }}
      resetOnSelect
      resetOnQuery
      {...restProps}
    />
  );
};

export default observer(MultiSelect);
