import { MouseEvent, RefObject, useRef, useState } from "react";
import { ContextMenu, Position, Tooltip } from "@blueprintjs/core";
import { BlueprintIcon } from "@ui/BlueprintIcon";
import { GridApi, ICellRendererParams } from "ag-grid-community";
import classNames from "classnames";
import { observer } from "mobx-react";

import { BlockPartNumberDark, BlockPartNumberLight } from "@assets/icons/blockTypes";
import { PADDING_PER_LEVEL } from "@components/BlocksTree/constants";
import { Button } from "@components/Button";
import { DocumentNameInput } from "@components/DocumentNameInput";
import { FavoriteButton } from "@components/FavoriteButton";
import { Icon } from "@components/Icon";
import { PlusButton } from "@components/PlusButton";
import { IPopoverRefType, Popover } from "@components/Popover";
import { ThemedCustomIcon } from "@components/Shared/LegacyCustomIcon/ThemedCustomIcon";
import { CellLines } from "@components/Table/Components/CellLines";
import { useAppNavigate } from "@router/hooks";
import appStore from "@store/AppStore";
import { IBlock } from "@store/BlockStore";
import { EntityType } from "@store/types";
import { createCopyToClipboardHandler } from "@utilities";
import { createDataTestId, ElementType } from "@utilities/E2EUtils";

import { NodeInfo } from "../Modeling/ModelingFrame/Table/TableComponent/types";
import { MultiplicityTag } from "../MultiplicityTag";

import BlockMenu from "./BlockMenu";

import blocksTreeStyles from "./BlocksTree.module.scss";
import styles from "./NameCell.module.scss";

const CELL_PADDING = 20;

type Props = ICellRendererParams<NodeInfo> & {
  onAddNewOpen: (block: IBlock) => void;
  tableApi: GridApi<NodeInfo>;
};

const NameCell = (props: Props) => {
  const { data, node, onAddNewOpen } = props;
  const [isEditing, setIsEditing] = useState(false);
  const [expanded, setExpanded] = useState(props.node.expanded);
  const hasChild = !!data?.block?.validatedChildren.length;
  const { navigateToBlock } = useAppNavigate();

  const popoverRef: RefObject<IPopoverRefType> = useRef(null);
  const block = data?.block;

  if (!block) {
    return null;
  }

  const handleExpand = () => {
    setExpanded(expanded => !expanded);
    props.node.setExpanded(!props.node.expanded);
  };

  const handleOpenAddNew = () => {
    onAddNewOpen(block);
  };

  const handleOpenAddNewFromButton = (e: MouseEvent) => {
    e.stopPropagation();
    onAddNewOpen(block);
  };

  const renderLabel = () => {
    if (isEditing) {
      return (
        <DocumentNameInput
          className={styles.blocksGroupCellDocumentNameInput}
          inputClassName={styles.blocksGroupCellDocumentNameInputGroup}
          defaultValue={block.label}
          onUpdate={block.setLabel}
          onEnter={() => setIsEditing(false)}
          onEscape={() => setIsEditing(false)}
          onBlur={() => setIsEditing(false)}
          inputGroupProps={{ autoFocus: true }}
        />
      );
    }

    return <span className={classNames(styles.blocksGroupCellName, styles.nameCellTitle)}>{block.label || ""}</span>;
  };

  const partNumber = block.partNumber;
  const copyPartNumberToClipboard = partNumber ? createCopyToClipboardHandler(partNumber, "part number") : undefined;
  const paddingLeft = node.uiLevel * PADDING_PER_LEVEL;
  const isSelectedBlock = appStore.env.activeBlock?.id === block.id;

  return (
    <ContextMenu
      style={{ paddingLeft }}
      className={styles.blocksGroupCell}
      content={<BlockMenu block={block} onAddNewOpen={onAddNewOpen} onNavigateToBlock={navigateToBlock} />}
      onDoubleClick={() => setIsEditing(true)}
    >
      <div className={styles.blocksGroupCellMeta} {...createDataTestId(ElementType.AgCell, ["block", block.label])}>
        <CellLines
          node={props.node}
          hasChild={hasChild}
          isExpanded={expanded}
          cellLinesStyles={{ nodeLevelPaddingStep: CELL_PADDING, curveLineWidth: 6 }}
          onToggleExpand={handleExpand}
          active={isSelectedBlock}
        />
        <Icon
          icon={block.iconView}
          active={isSelectedBlock}
          onClick={handleExpand}
          className={classNames("ag-group-custom-name-icon", blocksTreeStyles.blocksGroupCellIcon)}
        />
        {!!block.multiplicity && <MultiplicityTag multiplicity={block.multiplicity} active={isSelectedBlock} />}
        {renderLabel()}
        {block.partNumber && (
          <Tooltip
            className={classNames("ag-group-custom-name-icon", styles.blocksGroupCellPartNumber)}
            position={Position.TOP}
            content={block.partNumber}
          >
            <Button onClick={copyPartNumberToClipboard} minimal e2eIdentifiers="copy-part-number">
              <ThemedCustomIcon lightIcon={<BlockPartNumberLight />} darkIcon={<BlockPartNumberDark />} />
            </Button>
          </Tooltip>
        )}
      </div>
      <Popover
        ref={popoverRef}
        position="bottom-right"
        content={<BlockMenu popoverRef={popoverRef} block={block} onAddNewOpen={handleOpenAddNew} onNavigateToBlock={navigateToBlock} />}
      >
        <div className={styles.blocksGroupCellButtonsContainer}>
          <FavoriteButton entityId={block.id} entityType={EntityType.Block} />
          <PlusButton onClick={handleOpenAddNewFromButton} e2eIdentifiers="add-child-block" />
          <Button minimal small icon={<BlueprintIcon icon="more" size={16} />} e2eIdentifiers="add-new-open" />
        </div>
      </Popover>
    </ContextMenu>
  );
};

export default observer(NameCell);
