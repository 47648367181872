import { BomTablesFrame } from "@components/BomTables";
import Pane from "@router/components/Panes/Pane";

import "./BomTableIndex.scss";

export const BOM_PANE = "BOM_PANE";

function BomTableIndex() {
  return (
    <Pane className="bom-table--pane bom-table-index relative" id={BOM_PANE}>
      <BomTablesFrame />
    </Pane>
  );
}

export default BomTableIndex;
