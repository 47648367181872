import { observer } from "mobx-react";
import { isAlive } from "mobx-state-tree";

import TextCell from "@components/Modeling/PropertyDefinition/Cells/TextCell";
import { TCellRendererProps } from "@components/Table";
import { IPropertyDefinition } from "@store/PropertyDefinitionStore";

const GroupCell = (cellProps: TCellRendererProps<IPropertyDefinition>) => {
  const { data } = cellProps;
  const isBlockAlive = isAlive(data);

  return isBlockAlive ? <TextCell e2eIdentifiers={["group", data?.label]} label={data.defaultPropertyGroup || ""} /> : null;
};

export default observer(GroupCell);
