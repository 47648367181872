import { AxiosResponse } from "axios";
import { applySnapshot, cast, flow, IAnyModelType, Instance, types } from "mobx-state-tree";
import { v4 as uuidv4 } from "uuid";

import { CommentLocationType, CommentThread, getCommentThreads } from "@components/Modeling/ModelingFrame/ModelBlock/Comments/utils";
import { Comment, CreateCommentDto } from "@rollup-api/models";
import { CommentGetThreadedRequestDto, TemporalDirection } from "@rollup-api/models/comments/commentGetThreadedRequestDto.model";
import { CommentThreadedItem, ThreadedComments } from "@rollup-api/models/comments/threadedComments.model";
import { CommentStore, IComment } from "@store/CommentStore";
import { CommentThreadStore, ICommentThread, ICommentThreadMobxType } from "@store/CommentThreadStore";
import { convertTimestamp } from "@utilities";
import { isDefined } from "@utilities/TypeGuards";
import { rollupClient } from "src/core/api";

export const COMMENT_THREAD_LIST_PARENT_TAKE = 5;

export enum CommentThreadListType {
  Continuous = "continuous",
  Discrete = "discrete",
}

export const CommentThreadListStore = types
  .model("CommentThreadList", {
    threads: types.array<ICommentThreadMobxType>(types.late((): IAnyModelType => CommentThreadStore)),
    parentTake: types.optional(types.number, COMMENT_THREAD_LIST_PARENT_TAKE),
    olderParentCommentsCount: types.optional(types.number, 0),
    olderNextSetOfParentCommenters: types.array(types.string),
    newerParentCommentsCount: types.optional(types.number, 0),
    newerNextSetOfParentCommenters: types.array(types.string),
    type: types.optional(
      types.enumeration("CommentThreadListType", Object.values(CommentThreadListType)),
      CommentThreadListType.Continuous
    ),
  })
  .actions(self => ({
    loadThreadedComments: (threadedComments: ThreadedComments, temporalDirection: TemporalDirection, initialFetch = true) => {
      // Construct comment stores
      if (temporalDirection === TemporalDirection.Older || temporalDirection === TemporalDirection.Both) {
        self.olderParentCommentsCount = threadedComments.olderParentCommentsCount;
        self.olderNextSetOfParentCommenters = cast(threadedComments.uniqueNextSetOfOlderParentCommentersUserIds);
      }
      if (temporalDirection === TemporalDirection.Newer || temporalDirection === TemporalDirection.Both) {
        self.newerParentCommentsCount = threadedComments.newerParentCommentsCount;
        self.newerNextSetOfParentCommenters = cast(threadedComments.uniqueNextSetOfNewerParentCommentersUserIds);
      }
      // Construct parent comments from response
      const parentComments: IComment[] = [];
      threadedComments.parentComments.forEach((parentComment: Comment) => {
        const comment = CommentStore.create({
          ...parentComment,
          publishAttachmentsToBlock: parentComment.publishToBlock,
          attachments: parentComment.cloudFiles.map(att => att.id).filter(isDefined),
          createdAt: convertTimestamp(parentComment.createdAt),
          updatedAt: convertTimestamp(parentComment.updatedAt),
        });
        parentComments.push(comment);
      });
      // Construct child comments from response
      const childComments: IComment[] = [];
      for (const [_, value] of Object.entries(threadedComments.childCommentsMap)) {
        (value as Comment[]).forEach((childComment: Comment) => {
          const comment = CommentStore.create({
            ...childComment,
            publishAttachmentsToBlock: childComment.publishToBlock,
            attachments: childComment.cloudFiles.map(att => att.id).filter(isDefined),
            createdAt: convertTimestamp(childComment.createdAt),
            updatedAt: convertTimestamp(childComment.updatedAt),
          });
          childComments.push(comment);
        });
      }
      // Construct threads from parent and child comments
      const threads: CommentThread[] = getCommentThreads(
        parentComments,
        childComments,
        threadedComments.remainingChildCommentsCountPerParent,
        threadedComments.uniqueNextSetOfChildCommentersPerParent
      );
      if (initialFetch) {
        self.threads = cast(threads);
      } else {
        // push the new threads to the start or the end of the threaded comments array
        if (temporalDirection === TemporalDirection.Older) {
          self.threads.unshift(...threads);
        } else {
          self.threads.push(...threads);
        }
      }
    },
  }))
  .actions(self => ({
    fetchThreadList: flow(function* fetch({
      initialFetch = true,
      temporalDirection,
      focusedCommentId,
      childTake = 5,
      parentId,
      type = CommentLocationType.Root,
    }: {
      initialFetch?: boolean;
      temporalDirection: TemporalDirection;
      focusedCommentId?: string;
      childTake?: number;
      parentId: string;
      type: CommentLocationType;
    }): Generator<any, boolean, AxiosResponse<CommentThreadedItem[]>> {
      if (self.type === CommentThreadListType.Discrete) {
        throw new Error("Fetching a list of thread for Discrete CommentThreadList is not allowed.");
      }

      let anchorParentCommentId: string | undefined;
      // if we have threads and this is not the first fetch
      if (self.threads.length && !initialFetch) {
        if (temporalDirection === TemporalDirection.Older) {
          // fetch back starting from the oldest comment
          anchorParentCommentId = self.threads[0]?.parentComment?.id;
        } else if (temporalDirection === TemporalDirection.Newer) {
          // fetch forward starting from the newest comment
          anchorParentCommentId = self.threads[self.threads.length - 1]?.parentComment?.id;
        }
      }

      const dto: CommentGetThreadedRequestDto = {
        parentId: parentId,
        anchorParentCommentId,
        // only allow focusedCommentId on initial fetch, otherwise override
        focusedParentCommentId: initialFetch && focusedCommentId ? focusedCommentId : undefined,
        // API is expected to return 1 parent comment if focusedCommentId is provided
        parentTake: self.parentTake,
        childTake,
        childSkip: 0,
        temporalDirection,
        type,
      };
      try {
        const { data, status } = yield rollupClient.comments.retrieveThreaded(dto);
        if (status !== 200) {
          throw new Error(`Error: response ${status}`);
        }
        const { threadedComments } = data[0] ?? {};

        if (!threadedComments) {
          console.error(`No threaded comments found for ${parentId}`);
          return false;
        }

        self.loadThreadedComments(threadedComments, temporalDirection, initialFetch);

        return true;
      } catch (error) {
        throw new Error(`Error fetching threaded comments for ${parentId}: ${error}`);
      }
    }),
    fetchSingleThread: flow(function* fetch({
      temporalDirection,

      focusedCommentId,
      childTake = 5,
      parentId,
      type = CommentLocationType.Root,
    }: {
      temporalDirection: TemporalDirection;
      focusedCommentId?: string;
      childTake?: number;
      parentId: string;
      type: CommentLocationType;
    }): Generator<any, boolean, AxiosResponse<CommentThreadedItem[]>> {
      if (self.type === CommentThreadListType.Continuous) {
        throw new Error("Fetching a single thread for Continuous CommentThreadList is not allowed.");
      }
      const dto: CommentGetThreadedRequestDto = {
        parentId: parentId,
        focusedParentCommentId: focusedCommentId,
        // API is expected to return 1 parent comment if focusedCommentId is provided
        parentTake: self.parentTake,
        childTake,
        childSkip: 0,
        temporalDirection, // placeholder, not used since only 1 thread will be returned
        type,
      };
      try {
        const { data, status } = yield rollupClient.comments.retrieveThreaded(dto);
        if (status !== 200) {
          throw new Error(`Error: response ${status}`);
        }

        const { threadedComments } = data[0] ?? {};

        if (!threadedComments) {
          console.error(`No threaded comments found for ${parentId}`);
          return false;
        }

        if (threadedComments.parentComments.length === 0) {
          console.warn(`No parent comment found with id ${parentId}`);
          return false;
        }
        // Construct parent comment from response
        const parentComment: IComment = CommentStore.create({
          ...threadedComments.parentComments[0],
          publishAttachmentsToBlock: threadedComments.parentComments[0].publishToBlock,
          attachments: threadedComments.parentComments[0].cloudFiles.map(att => att.id).filter(isDefined),
          createdAt: convertTimestamp(threadedComments.parentComments[0].createdAt),
          updatedAt: convertTimestamp(threadedComments.parentComments[0].updatedAt),
        });

        // Construct child comments from response
        const childComments: IComment[] = [];
        for (const [_, value] of Object.entries(threadedComments.childCommentsMap)) {
          (value as Comment[]).forEach((childComment: Comment) => {
            const comment = CommentStore.create({
              ...childComment,
              publishAttachmentsToBlock: childComment.publishToBlock,
              attachments: childComment.cloudFiles.map(att => att.id!),
              createdAt: convertTimestamp(childComment.createdAt),
              updatedAt: convertTimestamp(childComment.updatedAt),
            });
            childComments.push(comment);
          });
        }
        // Construct threads from parent and child comments
        const threads: CommentThread[] = getCommentThreads(
          [parentComment],
          childComments,
          threadedComments.remainingChildCommentsCountPerParent,
          threadedComments.uniqueNextSetOfChildCommentersPerParent
        );
        // push the new threads to the start or the end of the threaded comments array
        // Note different behavior vs the fetchThreadList method above.
        if (temporalDirection === TemporalDirection.Older) {
          self.threads.push(...threads);
        } else {
          self.threads.unshift(...threads);
        }

        return true;
      } catch (error) {
        throw new Error(`Error fetching threaded comments for ${parentId}: ${error}`);
      }
    }),
    addThreadAndParentComment: flow(function* addComment(dto: CreateCommentDto): Generator<any, string | undefined, any> {
      const newThread = CommentThreadStore.create({
        id: uuidv4(),
      });
      self.threads.push(newThread);
      return yield self.threads.at(-1)?.addComment(dto);
    }),
    removeThread(threadId: string) {
      const threadIndex = self.threads.findIndex(thread => thread.id === threadId);
      if (threadIndex !== -1) {
        self.threads.splice(threadIndex, 1);
      }
    },
    reset() {
      applySnapshot(self, {
        threads: [],
        parentTake: COMMENT_THREAD_LIST_PARENT_TAKE,
        olderParentCommentsCount: 0,
        olderNextSetOfParentCommenters: [],
        newerParentCommentsCount: 0,
        newerNextSetOfParentCommenters: [],
      });
    },
  }))
  .views(self => ({
    commentThreadOfTheCommentWithId(commentId: string): ICommentThread | undefined {
      return self.threads.find((thread: ICommentThread) => {
        if (thread.parentComment?.id === commentId) {
          return true;
        }
        return thread.childComments?.find((c: IComment) => c.id === commentId);
      });
    },
  }));

export interface ICommentThreadList extends Instance<typeof CommentThreadListStore> {}
