import { DialogFooter as BPDialogFooter, DialogFooterProps } from "@blueprintjs/core";
import classNames from "classnames";
import { observer } from "mobx-react";

import "./DialogFooter.scss";

interface IDialogFooterProps extends DialogFooterProps {}

const DialogFooter = (props: IDialogFooterProps) => {
  const { className = "", ...rest } = props;

  return <BPDialogFooter className={classNames("dialog-footer", className)} {...rest} />;
};

export default observer(DialogFooter);
