export enum DataSourceIntegration {
  GoogleSheets = "GoogleSheets",
  // TODO add integrations below
  // OnShape = "OnShape",
  // Github = "Github",
  // Fusion360 = "Fusion360",
}

export enum ECreateDataSource {
  Setup = "Setup",
  Integration = "Integration",
  Custom = "Custom",
  Preview = "Preview",
}
