import appStore from "@store/AppStore";

import EmptyWorkspaceMessage from "../components/EmptyWorkspaceMessage/EmptyWorkspaceMessage";
import { useSelectInitialDocument } from "../hooks/useSelectInitialDocument";

import BomTableCreateNewPopover from "./BomTableCreateNewPopover";

/** Main function. */
function BomTablesIndex() {
  useSelectInitialDocument({
    documentUrl: "pdm/bom",
    documentPageMap: appStore.workspaceModel?.bomTablesMap,
    setActiveDocument: appStore.env.setActiveBomTable,
  });

  return (
    <EmptyWorkspaceMessage
      className="component--bomTables-index"
      documentNameSingular="bill of materials"
      documentNamePlural="bills of materials"
      hasDocument={!!appStore.workspaceModel?.bomTablesMap.size}
      addNewButtonEl={<BomTableCreateNewPopover />}
    />
  );
}

/** Exports. */
export default BomTablesIndex;
