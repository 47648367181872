import { useEffect, useState } from "react";
import { Card, Classes, Icon, Menu, MenuDivider, NonIdealState } from "@blueprintjs/core";
import { ItemListRenderer, ItemRenderer, Omnibar } from "@blueprintjs/select";
import classNames from "classnames";
import Fuse, { FuseResult } from "fuse.js";
import { observer } from "mobx-react";

import { MenuItem } from "@components/MenuItem";
import { CommandPaletteAction, fixedActions } from "@components/Shared/CommandPalette/FixedActions";
import appStore from "@store/AppStore";
import { ModelingActiveTab } from "@store/EnvironmentStore";
import { IWorkspace } from "@store/WorkspaceStore";
import { IWorkspaceListItem } from "src/services/WorkspaceService";
import { Text, TextVariant } from "src/ui/Text";

import { withRouter, WithRouterProps } from "../WithRouter";

import "./CommandPalette.scss";

/** Type defs. */
interface CommandPaletteProps extends WithRouterProps {
  workspace: IWorkspace | null | undefined;
}

const CommandPaletteOmnibar = Omnibar.ofType<CommandPaletteAction>();

const CommandPalette = (props: CommandPaletteProps) => {
  const [actionItems, setActionItems] = useState<CommandPaletteAction[]>([]);
  const [blockActions, setBlockActions] = useState<CommandPaletteAction[]>([]);
  const [workspaceActions, setWorkspaceActions] = useState<CommandPaletteAction[]>([]);
  const [query, setQuery] = useState<string>("");

  const handleClose = () => {
    appStore.ui.hideCommandPalette();
    setQuery("");
  };

  const ACTION_LIMIT_PER_GROUP = 6;

  useEffect(() => {
    const { workspace, navigate, params } = props;
    const { workspaceId } = params;
    if (workspace?.blockMap?.size) {
      const act = new Array<CommandPaletteAction>();
      for (const [id, block] of workspace.blockMap) {
        act.push({
          id,
          queryString: block.label,
          label: block.label,
          disabled: appStore.env.activeBlock === block,
          group: "blocks",
          path: block.path,
          onSelect: () => {
            appStore.env.setModelingLeftActiveTab(ModelingActiveTab.model);
            if (workspaceId && block.id) {
              // TODO - Replace this with hook based navigation,
              //  once class component has been refactored to be a function component.
              navigate(`/workspaces/${workspaceId}/modeling/blocks/${block.id}`);
            }
          },
        });
      }
      setBlockActions(act);
    }

    const workspaces: IWorkspaceListItem[] = appStore.orgModel?.workspacesList;
    if (workspaces?.length) {
      const ws = new Array<CommandPaletteAction>();
      ws.push(
        ...workspaces.map(w => ({
          id: w.id,
          queryString: w.label,
          label: w.label,
          disabled: appStore.workspaceModel?.id === w.id,
          group: "workspaces",
          onSelect: () => appStore.loadWorkspaceFromDatabase(w.id),
        }))
      );
      setWorkspaceActions(ws);
    }
  }, [props]);

  const { navigate, location } = props;

  useEffect(() => {
    setActionItems([...workspaceActions, ...blockActions, ...fixedActions(navigate, location)]);
  }, [workspaceActions, blockActions, navigate, location]);

  const handleItemSelect = (action: CommandPaletteAction) => {
    action.onSelect();
    appStore.ui.hideCommandPalette();
    setQuery("");
  };

  const handleQueryChanged = (val: string) => {
    setQuery(val);
  };

  // Applies a limit of 6 items per command group, before sorting the items by score
  const queryList = (query: string) => {
    const itemGroups = [workspaceActions, blockActions, fixedActions(props.navigate, props.location)];

    if (!query) {
      const list = new Array<CommandPaletteAction>();
      for (const group of itemGroups) {
        const entries = group.slice(0, ACTION_LIMIT_PER_GROUP);
        list.push(...entries);
      }
      return list;
    } else {
      const list = new Array<FuseResult<CommandPaletteAction>>();
      for (const group of itemGroups) {
        const entries = new Fuse<CommandPaletteAction>(group, {
          shouldSort: true,
          includeScore: true,
          keys: ["queryString"],
          isCaseSensitive: false,
          findAllMatches: false,
          threshold: 0.5,
          ignoreLocation: true,
        }).search(query, { limit: ACTION_LIMIT_PER_GROUP });
        list.push(...entries);
      }
      return list.sort((a, b) => (a.score && b.score && a.score > b.score ? 1 : -1)).map(r => r.item);
    }
  };

  const renderEntry: ItemRenderer<CommandPaletteAction> = (entry, { modifiers }) => {
    if (!entry || !modifiers.matchesPredicate) {
      return null;
    }
    const text = `${entry.label}`;
    return (
      <MenuItem
        active={modifiers.active}
        disabled={modifiers.disabled || entry.disabled}
        label={entry.label}
        key={entry.id}
        onClick={() => handleItemSelect(entry)}
        text={text}
        e2eIdentifiers={entry.label}
      />
    );
  };

  const renderList: ItemListRenderer<CommandPaletteAction> = itemListProps => {
    const itemMap = new Map<string, CommandPaletteAction[]>();
    const items = itemListProps.filteredItems;
    for (const i of items) {
      const groupName = i.group ?? "ungrouped";
      let groupItems = itemMap.get(groupName);
      if (!groupItems) {
        groupItems = new Array<CommandPaletteAction>();
        itemMap.set(groupName, groupItems);
      }
      groupItems.push(i);
    }

    const menuChildren = [];
    const workspaceItems = itemMap.get("workspaces");
    const blockItems = itemMap.get("blocks");
    const otherItems = itemMap.get("ungrouped");

    if (workspaceItems) {
      menuChildren.push(
        <MenuDivider
          key="workspace-title"
          title={
            <>
              <Icon icon="folder-shared-open" />
              <Text variant={TextVariant.H3}>Open workspace</Text>
            </>
          }
        />
      );
      for (const item of workspaceItems) {
        menuChildren.push(
          <MenuItem
            disabled={item.disabled}
            active={item === itemListProps.activeItem}
            text={item.label}
            key={item.id}
            icon="control"
            onClick={() => handleItemSelect(item)}
            e2eIdentifiers={item.label}
          />
        );
      }
    }

    if (blockItems) {
      menuChildren.push(
        <MenuDivider
          key="block-title"
          title={
            <>
              <Icon icon="cube" />
              <Icon icon="arrow-top-right" />
              <Text variant={TextVariant.H3}>Jump to block</Text>
            </>
          }
        />
      );
      for (const item of blockItems) {
        menuChildren.push(
          <MenuItem
            e2eIdentifiers={item.label}
            disabled={item.disabled}
            active={item === itemListProps.activeItem}
            text={<span className="font-bold">{item.label}</span>}
            key={item.id}
            icon="cube"
            className="flex-wrap"
            onClick={() => handleItemSelect(item)}
            labelClassName={item.path ? "w-full pl-[27px]" : undefined}
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            label={
              item.path && (
                <div className="truncate">
                  {item.path.split(".").map((path, index, array) => {
                    const isLast = array.length === index + 1;
                    return (
                      <span key={path} className={isLast ? "font-bold" : undefined}>
                        <span>{path}</span>
                        {!isLast && <span>{` / `}</span>}
                      </span>
                    );
                  })}
                </div>
              )
            }
          />
        );
      }
    }

    if (otherItems) {
      // remove hidden items
      const shownItems = otherItems.filter(item => !item.hidden);
      if (shownItems.length > 0) {
        menuChildren.push(
          <MenuDivider
            key="other-title"
            title={
              <>
                <Icon icon="wrench" />
                <Text variant={TextVariant.H3}>Other actions</Text>
              </>
            }
          />
        );
        for (const item of shownItems) {
          menuChildren.push(
            <MenuItem
              disabled={item.disabled}
              active={item === itemListProps.activeItem}
              text={item.label}
              key={item.id}
              onClick={() => handleItemSelect(item)}
              e2eIdentifiers={item.label}
            />
          );
        }
      }
    }

    if (menuChildren.length) {
      return <Menu className="command-palette--menu">{menuChildren}</Menu>;
    } else {
      return (
        <Card className="no-matches-card">
          <NonIdealState
            icon="search"
            title="No matches"
            description="Adjust your search query to search for workspaces, blocks, or actions"
          />
        </Card>
      );
    }
  };

  const className = classNames("command-palette", { [Classes.DARK]: appStore.env.themeIsDark });

  return (
    <CommandPaletteOmnibar
      className={className}
      itemsEqual={(a, b) => a.id === b.id}
      onClose={handleClose}
      isOpen={appStore.ui.commandPaletteEnabled}
      itemListRenderer={renderList}
      itemRenderer={renderEntry}
      items={actionItems}
      onItemSelect={handleItemSelect}
      query={query}
      onQueryChange={handleQueryChanged}
      itemListPredicate={queryList}
      overlayProps={{
        autoFocus: true,
        enforceFocus: true,
      }}
      inputProps={{
        placeholder: "Search for workspaces, blocks, or actions...",
      }}
    />
  );
};

/** Exports. */
export const CommandPaletteWrapper = withRouter<CommandPaletteProps>(observer(CommandPalette));
