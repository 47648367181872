import { observer } from "mobx-react";

import UserInfo from "@components/UserInfo/UserInfo";
import appStore from "@store/AppStore";

type Props = {
  userId?: string;
};

const User = (props: Props) => {
  const { userId } = props;
  const user = userId && appStore.orgModel?.info.orgMembers.find(u => u.id === userId);

  if (!user) {
    return "Unknown user";
  }

  return <UserInfo user={user} size="extra-small" />;
};

export default observer(User);
