import { AxiosResponse } from "axios";

import { CreateTableViewConfigDto, TableViewConfig, TableViewConfigUpdateDto } from "../models/tableViewConfig";

import { HttpClient, ParentClient } from "./client";

export class TableViewConfigs extends HttpClient {
  public constructor(parent: ParentClient) {
    super(parent);
  }

  private get endpoint() {
    return `workspace/${this.parent.workspaceId}/table-view-configs`;
  }

  public get = (id: string): Promise<AxiosResponse<TableViewConfig>> => {
    return this.instance.get(`${this.endpoint}/${id}`);
  };

  public list = (): Promise<AxiosResponse<TableViewConfig>> => {
    return this.instance.get(this.endpoint);
  };

  public create = (dto: CreateTableViewConfigDto): Promise<AxiosResponse<TableViewConfig>> => {
    return this.instance.post<TableViewConfig>(`${this.endpoint}`, dto);
  };

  public update = (id: string, dto: TableViewConfigUpdateDto) => {
    return this.instance.patch<TableViewConfig>(`${this.endpoint}/${id}`, dto);
  };

  public reorder = (id: string, destinationId: string) => {
    return this.instance.patch(`${this.endpoint}/${id}/reorder`, { destinationId });
  };

  public delete = (id: string) => {
    return this.instance.delete<TableViewConfig>(`${this.endpoint}/${id}`);
  };
}
