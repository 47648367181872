import { useNavigate } from "react-router-dom";
import { observer } from "mobx-react";

import ModelFrameToolbar from "@components/Modeling/ModelingFrame/ModelFrameToolbar";
import { useAppNavigate } from "@router/hooks";
import appStore from "@store/AppStore";
import { IAttachment } from "@store/AttachmentStore";
import { IBlock } from "@store/BlockStore";

import SidePanelBackButton from "../SidePanelBackButton";

import styles from "./HoopsViewerHeader.module.scss";

export interface HoopsViewerProps {
  attachment: IAttachment;
  block?: IBlock;
  onGoBack?(): void;
}

const HoopsViewerHeader = (props: HoopsViewerProps) => {
  const { attachment, block, onGoBack } = props;
  const { navigateToBlock, navigateToWorkspace } = useAppNavigate();
  const navigate = useNavigate();

  const handleGoBack = () => {
    const blockId = attachment.block?.id;

    if (!blockId && !attachment.workspaceId) {
      navigate(-1);
    }

    if (blockId) {
      navigateToBlock(blockId);
    } else if (appStore.workspaceModel?.id) {
      navigateToWorkspace(appStore.workspaceModel?.id);
    }
  };

  return (
    <div className={styles.hoopsViewerHeader}>
      <SidePanelBackButton label={attachment.label} onClick={onGoBack ?? handleGoBack} />
      <ModelFrameToolbar block={block} />
    </div>
  );
};

export default observer(HoopsViewerHeader);
