import { ComponentProps, useEffect } from "react";
// eslint-disable-next-line no-restricted-imports
import { EditorContent as TTEditorContent } from "@tiptap/react";
import classNames from "classnames";
import { observer } from "mobx-react";

import { createTestId, dataTestIdTag, ElementType, IE2EIdentifiersParam } from "../../Utilities/E2EUtils";

import styles from "./EditorContent.module.scss";

type ITTEditorContent = ComponentProps<typeof TTEditorContent>;
interface IEditorContentProps extends ITTEditorContent, IE2EIdentifiersParam {
  isFocused?: boolean;
}

const EditorContent = (props: IEditorContentProps) => {
  const { e2eIdentifiers, className, isFocused, ...restProps } = props;

  useEffect(() => {
    if (restProps.editor) {
      // Imperatively sets the data tag in tiptap's container element
      const tiptapElement = restProps.editor.options.element.childNodes.item(0) as HTMLElement;
      const testId = createTestId(ElementType.EditorContent, e2eIdentifiers);
      tiptapElement?.setAttribute(dataTestIdTag, testId);
    }
  }, [e2eIdentifiers, restProps.editor]);

  return (
    <TTEditorContent
      className={classNames(styles.editorContent, className, {
        [styles.editorContentFocused]: isFocused && restProps.editor?.isEditable !== false,
      })}
      {...restProps}
    />
  );
};

export default observer(EditorContent);
