import { HttpClient, ParentClient } from "../client";

import { AnalysisInputs } from "./analysisInputs";
import { AnalysisOutputs } from "./analysisOutputs";
import { CodeBlocks } from "./codeBlocks";
import { ExecutionEnvironments } from "./executionEnvironments";

export class AnalysisModule extends HttpClient {
  public readonly codeBlocks: CodeBlocks;
  public readonly executionEnvironments: ExecutionEnvironments;
  public readonly analysisInputs: AnalysisInputs;
  public readonly analysisOutputs: AnalysisOutputs;

  public constructor(parent: ParentClient) {
    super(parent);
    this.codeBlocks = new CodeBlocks(parent);
    this.executionEnvironments = new ExecutionEnvironments(parent);
    this.analysisInputs = new AnalysisInputs(parent);
    this.analysisOutputs = new AnalysisOutputs(parent);
  }
}
