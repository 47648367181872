import { MouseEvent, useEffect, useState } from "react";
import { Icon, Menu, Tooltip } from "@blueprintjs/core";
import Text, { TextVariant } from "@ui/Text";
import { ICellRendererParams } from "ag-grid-community";
import classNames from "classnames";
import { observer } from "mobx-react";

import VerticalMenuIcon from "@assets/icons/vertical-menu.svg?react";
import { BlockIcon, CustomIcon } from "@components/CustomIcon";
import { MenuItem } from "@components/MenuItem";
import { Popover } from "@components/Popover";
import { CellLines } from "@components/Table/Components/CellLines";
import appStore from "@store/AppStore";
import { IHoopsNode } from "@store/HoopsViewerStore";
import { createCopyToClipboardHandler } from "@utilities";
import { getDeepLinkUrl } from "@utilities/GetDeepLinkUrl";

import { useAttachmentFunctions } from "../../ModelBlock/Attachments/attachmentHooks";

import "./HoopsTree.scss";
import styles from "./HoopsTreeCell.module.scss";

const AG_GRID_ACTIVE_ROW_CLASS = "ag-row-active";
const AG_GRID_INVISIBLE_ROW_CLASS = "ag-row-invisible";

const CELL_PADDING = 10;

interface HoopsTreeCellProps extends ICellRendererParams<IHoopsNode> {}

const calcRowWidth = (paddingLeft: number, node?: IHoopsNode): number => {
  const charCount = node?.label?.length ?? 0;
  const caretIconWidth = node?.hasChildren ? 16 : 0;
  const actionsWidth = 60;
  const containerPadding = 32;
  const extraWidth = 10;
  return paddingLeft + caretIconWidth + 7 * charCount + actionsWidth + containerPadding + extraWidth;
};

const HoopsTreeCell = function HoopsTreeCell(props: HoopsTreeCellProps) {
  const [expanded, setExpanded] = useState(props.node.expanded);
  const hoopsNode = props.data;
  const additionalMargin = hoopsNode?.hasChildren ? 2 : 2 * CELL_PADDING;
  const paddingLeft = props.node.uiLevel * CELL_PADDING + additionalMargin;

  useEffect(() => {
    const rowWidth = calcRowWidth(paddingLeft, hoopsNode);
    if (rowWidth > appStore.ui.hoopsTreeWidth) {
      appStore.ui.setHoopsTreeWidth(rowWidth);
    }
  }, [paddingLeft, hoopsNode]);

  const { handleDownload } = useAttachmentFunctions({ attachment: appStore.env.activeAttachment });

  const copyNodeLinkToClipboard = createCopyToClipboardHandler(getDeepLinkUrl({ nodeId: hoopsNode?.id.toString() }), "node link");

  const handleExpand = (ev: MouseEvent) => {
    props.node.setExpanded(!props.node.expanded);
    setExpanded(!expanded);
    ev.stopPropagation();
  };

  if (!hoopsNode) {
    return null;
  }

  const isSelected = appStore.env.attachmentViewer?.selectedNode === props.data;
  const className = classNames("ag-group-custom-name", {
    [AG_GRID_ACTIVE_ROW_CLASS]: isSelected && hoopsNode.visible,
    [AG_GRID_INVISIBLE_ROW_CLASS]: !hoopsNode.visible,
  });

  const getIcon = (): BlockIcon => {
    if (hoopsNode.isRoot) {
      return BlockIcon.Block;
    } else if (hoopsNode.hasChildren) {
      return BlockIcon.Assembly;
    }
    return BlockIcon.Part;
  };

  const handleIsolate = () => {
    appStore.env.attachmentViewer?.toggleNodeIsolation(hoopsNode);
    props.node.setExpanded(true);
  };

  const handleClicked = () => {
    appStore.env.attachmentViewer?.selectNode(hoopsNode);
  };

  const handleDoubleClicked = () => {
    appStore.env.attachmentViewer?.toggleNodeIsolation(hoopsNode);
  };

  return (
    <div
      className={classNames(className, styles.hoopsTreeCell)}
      style={{ paddingLeft }}
      onClick={handleClicked}
      onDoubleClick={handleDoubleClicked}
    >
      <div className="cell-name">
        <CellLines
          node={props.node}
          hasChild={hoopsNode.hasChildren}
          isExpanded={expanded}
          cellLinesStyles={{ nodeLevelPaddingStep: CELL_PADDING, curveLineWidth: 6 }}
          onToggleExpand={handleExpand}
        />
        <Tooltip
          content={hoopsNode.isolated ? "Double click to remove isolation" : "Double click to isolate"}
          position="top"
          hoverOpenDelay={750}
        >
          <div className={styles.labelTooltip}>
            <CustomIcon icon={getIcon()} />
            <div className="cell-label">
              <Text variant={TextVariant.Caption} clamp={1}>
                {hoopsNode.label}
              </Text>
            </div>
          </div>
        </Tooltip>
      </div>
      <div className={styles.actions}>
        <Popover
          content={
            <Menu>
              <MenuItem text="Isolate" icon="widget" onClick={handleIsolate} active={hoopsNode.isolated} e2eIdentifiers="isolate" />
              <MenuItem text="Copy link" icon="link" onClick={copyNodeLinkToClipboard} e2eIdentifiers="copy-link" />
              <MenuItem text="Export file..." icon="export" e2eIdentifiers="export">
                <MenuItem text="Download original file" icon="download" onClick={handleDownload} e2eIdentifiers="download-file" />
              </MenuItem>
            </Menu>
          }
          targetProps={{ className: styles.menuTarget }}
        >
          <VerticalMenuIcon className="cell-icon" />
        </Popover>
        <Icon className="cell-icon" icon={hoopsNode.visible ? "eye-open" : "eye-off"} onClick={hoopsNode.toggleVisibility} />
      </div>
    </div>
  );
};

export default observer(HoopsTreeCell);
