import { IComplexTag } from "@components/TagsContainer/TagsContainer.types";
import { allColors, Color } from "@rollup-types/colors";
import { getRandomHexColor } from "@utilities/Color";

export const enhanceStringTags = (tags: string[], usedColorsExternally: Color[] = []): IComplexTag[] => {
  return tags.reduce((enhancedTags: IComplexTag[], tag) => {
    const existingTag = enhancedTags.find(enhancedTag => enhancedTag.value === tag);
    if (existingTag) {
      return enhancedTags.concat(existingTag);
    }
    const usedColors = enhancedTags.map(tag => tag.color);
    const color = getRandomHexColor(usedColors.concat(usedColorsExternally), undefined, allColors) as Color;
    return enhancedTags.concat({ value: tag, color });
  }, []);
};
