import { Icon } from "@blueprintjs/core";
import { IHeaderParams } from "ag-grid-community";
import { observer } from "mobx-react";

import { CatalogItemTableColumn } from "@components/CatalogItems/Table/types";

import styles from "./HeaderCell.module.scss";

const HeaderCell = (props: IHeaderParams) => {
  const { column } = props;

  if (column.getColId() === CatalogItemTableColumn.Comments) {
    return <Icon className={styles.headerCellIcon} icon="comment" />;
  }

  return props.displayName;
};

export default observer(HeaderCell);
