import { MouseEvent } from "react";
import { Menu, MenuDivider } from "@blueprintjs/core";
import { observer } from "mobx-react";

import { DocumentNameInput } from "@components/DocumentNameInput";
import { FormGroup } from "@components/FormGroup";
import { MenuItem } from "@components/MenuItem";
import { MenuItemDelete } from "@components/MenuItems";
import { MenuItemFavorite } from "@components/MenuItems/MenuItemFavorite";
import { CopyLinkMenuItem, LinkableNode } from "@components/SmallComponents";
import appStore from "@store/AppStore";
import { FeatureFlag } from "@store/FeatureFlagsStore";
import { EntityType } from "@store/types";

import styles from "./EntityContextMenu.module.scss";

interface IEntityContextMenuProps {
  defaultName: string;
  node: LinkableNode;
  entityType: EntityType;
  onNameChange(name: string): void;
  onDelete(event: MouseEvent): void;
  onDuplicate?(): void;
  onCreateTemplate?(): void;
  onEnter?(): void;
}

const EntityContextMenu = (props: IEntityContextMenuProps) => {
  const { defaultName, node, entityType, onNameChange, onDuplicate, onDelete, onCreateTemplate, onEnter } = props;

  const renderCopyLinkMenuItem = () => {
    const isCopyLinkMenuEnabled = appStore.env.featureFlags.enabled(FeatureFlag.DEBUG_COPY_URL);

    return (
      <>
        <CopyLinkMenuItem node={node} />
        {isCopyLinkMenuEnabled && <MenuDivider />}
      </>
    );
  };

  return (
    <Menu>
      <FormGroup label="Name">
        <DocumentNameInput className={styles.entityContextMenuInput} defaultValue={defaultName} onUpdate={onNameChange} onEnter={onEnter} />
      </FormGroup>
      <MenuDivider />
      {renderCopyLinkMenuItem()}
      <MenuItemFavorite entityId={node.id} entityType={entityType} />
      {onDuplicate && <MenuItem icon="duplicate" text="Duplicate" onClick={onDuplicate} e2eIdentifiers="duplicate" />}
      {onCreateTemplate && <MenuItem icon="add" text="Create template" onClick={onCreateTemplate} e2eIdentifiers="create-template" />}
      <MenuItemDelete onDelete={onDelete} e2eIdentifiers="delete" />
    </Menu>
  );
};

export default observer(EntityContextMenu);
