import { H6 } from "@blueprintjs/core";
import { GridOptions } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import classNames from "classnames";
import { observer } from "mobx-react";

import Table from "@components/Table";
import appStore from "@store/AppStore";
import { IHoopsNode } from "@store/HoopsViewerStore";

import HoopsTreeCell from "./HoopsTreeCell";

import "./HoopsTree.scss";

type HoopsTreeProps = {
  ready: boolean;
  tableRef?: React.MutableRefObject<AgGridReact<IHoopsNode> | null>;
};

const HoopsTree = ({ ready, tableRef }: HoopsTreeProps) => {
  const getDataPath = (node: IHoopsNode) => node.pathIds;

  const gridOptions: GridOptions = {
    groupDefaultExpanded: -1,
    autoGroupColumnDef: {
      headerName: "",
      cellClass: "ag-custom-group-cell",
      cellRenderer: HoopsTreeCell,
      headerComponentParams: {
        hideNav: true,
      },
    },
  };

  if (!ready) {
    return (
      <div className="hoops-tree-loading-label">
        <H6>LOADING...</H6>
      </div>
    );
  }

  if (!appStore.env.attachmentViewer?.nodeCount || appStore.env.attachmentViewer.nodeCount <= 0) {
    return (
      <div className="hoops-tree-loading-label">
        <H6>No nodes found.</H6>
      </div>
    );
  }

  return (
    <div
      className={classNames("hoops-tree", {
        ["hoops-tree--hidden-lines"]: appStore.env.attachmentViewer.drawMode === Communicator.DrawMode.HiddenLine,
      })}
    >
      <Table<IHoopsNode>
        disableAutoHeight
        style={{ height: "100%", width: "100%" }}
        tableRef={tableRef}
        headerHeight={0}
        getDataPath={getDataPath}
        rowData={appStore.env.attachmentViewer.nodes}
        columnDefs={[]}
        defaultColDef={{ flex: 1 }}
        treeData
        rowHeight={32}
        gridOptions={gridOptions}
      />
    </div>
  );
};

export default observer(HoopsTree);
