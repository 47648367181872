import { useMemo } from "react";
import { ColDef } from "ag-grid-community";

import AutoAddCell from "@components/Modeling/PropertyDefinition/Cells/AutoAddCell";
import DescriptionCell from "@components/Modeling/PropertyDefinition/Cells/DescriptionCell";
import GroupCell from "@components/Modeling/PropertyDefinition/Cells/GroupCell";
import InstancesCell from "@components/Modeling/PropertyDefinition/Cells/InstancesCell";
import LabelCell from "@components/Modeling/PropertyDefinition/Cells/LabelCell";
import PropertyDefinitionEditorCell from "@components/Modeling/PropertyDefinition/Cells/PropertyDefinitionEditorCell";
import RollupChildrenCell from "@components/Modeling/PropertyDefinition/Cells/RollupChildrenCell";
import TypeCell from "@components/Modeling/PropertyDefinition/Cells/TypeCell";
import UnitCell from "@components/Modeling/PropertyDefinition/Cells/UnitCell";
import { EPropertyDefinitionsColumn } from "@components/Modeling/PropertyDefinition/constants";
import { TCellRendererProps } from "@components/Table";
import appStore from "@store/AppStore";
import { IPropertyDefinition } from "@store/PropertyDefinitionStore";

import PropertyDefinitionActionsCell from "./Cells/PropertyDefinitionActionsCell";

type TEditCellProps = {
  newValue: string;
};
type TCellGetterProps = TEditCellProps & TCellRendererProps<IPropertyDefinition>;

export const usePropertyDefinitionTableColDef = (propertyDefinitions: IPropertyDefinition[]): ColDef<IPropertyDefinition>[] => {
  const columnsOrder = appStore.env.columnsOrder.propertyDefinitionTable;

  return useMemo(
    () =>
      (
        [
          {
            colId: EPropertyDefinitionsColumn.ACTIONS,
            headerName: "",
            lockPosition: "left",
            cellClass: "actions-cell",
            suppressMovable: true,
            width: 25,
            cellRenderer: PropertyDefinitionActionsCell,
          },
          {
            editable: true,
            colId: EPropertyDefinitionsColumn.LABEL,
            minWidth: 100,
            flex: 3,
            headerName: EPropertyDefinitionsColumn.LABEL,
            cellRenderer: LabelCell,
            cellEditor: PropertyDefinitionEditorCell,
            valueGetter: (cellProps: TCellGetterProps) => cellProps.data.label,
            valueSetter: (cellProps: TCellGetterProps): boolean => {
              cellProps.data.setLabel(cellProps.newValue);
              return true;
            },
          },
          {
            editable: true,
            colId: EPropertyDefinitionsColumn.GROUP,
            minWidth: 100,
            flex: 3,
            headerName: EPropertyDefinitionsColumn.GROUP,
            cellRenderer: GroupCell,
            cellEditor: PropertyDefinitionEditorCell,
            valueGetter: (cellProps: TCellGetterProps) => cellProps.data.defaultPropertyGroup,
            valueSetter: (cellProps: TCellGetterProps): boolean => {
              cellProps.data.setDefaultPropertyGroup(cellProps.newValue);
              return true;
            },
          },
          {
            editable: true,
            colId: EPropertyDefinitionsColumn.DESCRIPTION,
            minWidth: 250,
            flex: 3,
            headerName: EPropertyDefinitionsColumn.DESCRIPTION,
            cellRenderer: DescriptionCell,
            cellEditor: PropertyDefinitionEditorCell,
            valueGetter: (cellProps: TCellGetterProps) => {
              return cellProps.data.description;
            },
            valueSetter: (cellProps: TCellGetterProps): boolean => {
              if (appStore.userModel) {
                cellProps.data.setDescription(cellProps.newValue, appStore.userModel.id);
              }
              return true;
            },
          },
          {
            colId: EPropertyDefinitionsColumn.TYPE,
            minWidth: 130,
            flex: 2,
            headerName: EPropertyDefinitionsColumn.TYPE,
            cellClass: "type-cell-wrapper",
            cellRenderer: TypeCell,
          },
          {
            editable: true,
            colId: EPropertyDefinitionsColumn.UNIT,
            minWidth: 50,
            flex: 2,
            headerName: "Unit",
            cellRenderer: UnitCell,
            cellEditor: PropertyDefinitionEditorCell,
            valueGetter: (cellProps: TCellGetterProps) => cellProps.data.unit,
            valueSetter: (cellProps: TCellGetterProps): boolean => {
              cellProps.data.setUnit(cellProps.newValue);
              return true;
            },
          },
          {
            colId: EPropertyDefinitionsColumn.AUTO_ADD,
            minWidth: 50,
            flex: 2,
            headerName: "Default",
            cellRenderer: AutoAddCell,
          },
          {
            colId: EPropertyDefinitionsColumn.ROLLUP_CHILDREN,
            minWidth: 60,
            flex: 2,
            headerName: "Rollup",
            cellRenderer: RollupChildrenCell,
          },
          {
            colId: EPropertyDefinitionsColumn.INSTANCES,
            minWidth: 50,
            flex: 2,
            headerName: "Instances",
            cellRenderer: InstancesCell,
          },
        ] as ColDef<IPropertyDefinition>[]
      ).sort((a: ColDef<IPropertyDefinition>, b: ColDef<IPropertyDefinition>) => {
        const indexA = columnsOrder.indexOf(a.colId as EPropertyDefinitionsColumn);
        const indexB = columnsOrder.indexOf(b.colId as EPropertyDefinitionsColumn);
        // Do not change the original column position if it's not in the columnsOrder array yet (useful for new columns)
        if (indexA === -1 || indexB === -1) {
          return 0;
        }
        return columnsOrder.indexOf(a.colId as EPropertyDefinitionsColumn) - columnsOrder.indexOf(b.colId as EPropertyDefinitionsColumn);
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [propertyDefinitions, columnsOrder]
  );
};
