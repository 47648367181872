import { FormGroup, MaybeElement, Text } from "@blueprintjs/core";
import { observer } from "mobx-react";

import "./ListEntry.scss";

export type ListEntryProps = { label: string; helperText?: string; value: string | MaybeElement };

export const ListEntry = observer(({ label, helperText, value }: ListEntryProps) => (
  <div className="list-entry">
    <FormGroup label={label} helperText={helperText}>
      {typeof value === "string" ? <Text className="list-entry--value">{value}</Text> : <span className="list-entry--value">{value}</span>}
    </FormGroup>
  </div>
));
