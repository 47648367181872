import toCamelCase from "lodash/camelCase";

export const objectKeysToCamelCase = <T>(obj: Record<string, any>): T => {
  const result = Object.keys(obj).reduce(
    (acc, key) => {
      acc[toCamelCase(key)] = obj[key];
      return acc;
    },
    {} as Record<any, any>
  );

  return result as T;
};
