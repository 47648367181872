import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useWorkspace } from "@hooks/useWorkspace";

import { CreateReportDto } from "@rollup-api/models";
import appStore from "@store/AppStore";

import EmptyWorkspaceMessage from "../components/EmptyWorkspaceMessage/EmptyWorkspaceMessage";
import { EPageName } from "../hooks/useAppNavigate";

/** Main function. */
function ReportsIndex() {
  const navigate = useNavigate();
  const workspace = useWorkspace();

  useEffect(() => {
    if (workspace.reports.length === 0) {
      onAddNewReport({ label: "Getting started" });
    }
    // we are generating a default report page on first load if there are no reports
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onAddNewReport = async (createReportDto?: Partial<CreateReportDto>) => {
    const newReport = await appStore.workspaceModel?.createReport(createReportDto);
    const workspaceId = appStore.workspaceModel?.id;
    if (newReport && workspaceId) {
      appStore.env.setActiveReport(newReport.id);
      navigate(`/workspaces/${workspaceId}/${EPageName.Knowledgebase}/${newReport.id}`);
    }
  };

  return (
    <EmptyWorkspaceMessage
      className="component--reports-index"
      documentNameSingular="knowledgebase page"
      documentNamePlural="knowledgebase pages"
      hasDocument={!!appStore.workspaceModel?.reportsMap.size}
      onAddNewDocument={onAddNewReport}
    />
  );
}

/** Exports. */
export default ReportsIndex;
