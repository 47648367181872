import { memo } from "react";
import { Tooltip } from "@blueprintjs/core";
import { isUnitsBaseEqual } from "@rollup-io/engineering";
import { ValueGetterParams } from "ag-grid-community";
import { observer } from "mobx-react";
import { isAlive } from "mobx-state-tree";

import { NodeInfo } from "../types";
import { getCellProperty, getStringValue } from "../utils";

type PropertyValueCellProps = ValueGetterParams<NodeInfo> & {
  propertyDefinitionLabel: string;
};

const PropertyValueCell = ({ propertyDefinitionLabel, ...props }: PropertyValueCellProps) => {
  if (!props.data?.block || !isAlive(props.data?.block)) {
    return null;
  }

  const propertyInstance = getCellProperty(props);

  if (!propertyInstance || !isAlive(propertyInstance)) {
    return null;
  }

  const result = (
    <span data-testid={`${props.data.block.label}_${propertyDefinitionLabel}_${propertyInstance.id}`}>{getStringValue(props)}</span>
  );

  if (
    propertyInstance &&
    propertyInstance.propertyDefinition &&
    propertyInstance.unit &&
    !isUnitsBaseEqual(propertyInstance.unit, propertyInstance.propertyDefinition.unit)
  ) {
    return (
      <Tooltip intent="warning" content="Units do not match.">
        {result}
      </Tooltip>
    );
  }

  return result;
};

export default memo(observer(PropertyValueCell));
