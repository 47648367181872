import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ContextMenu } from "@blueprintjs/core";
import classNames from "classnames";
import { observer } from "mobx-react";

import { ExecutionTypeIcon } from "@components/Analysis";
import DynamicLabelInput from "@components/DynamicLabelInput/DynamicLabelInput";
import { FavoriteButton } from "@components/FavoriteButton";
import MoreOptionsMenu from "@components/MoreOptionsMenu/MoreOptionsMenu";
import NavLinkAsMenuItem from "@router/navigation/NavLinkAsMenuItem";
import { ICodeBlock } from "@store/Analysis/CodeBlockStore";
import appStore from "@store/AppStore";
import { EntityType } from "@store/types";
import { closePopover } from "@utilities/DomUtils";

import CodeBlocksSidebarContextMenu from "../CodeBlocksSidebarContextMenu/CodeBlocksSidebarContextMenu";

import styles from "./CodeBlocksSidebarItem.module.scss";

interface CodeBlocksSidebarItemProps {
  codeBlock: ICodeBlock;
  minimal?: boolean;
}

const CodeBlocksSidebarItem = (props: CodeBlocksSidebarItemProps) => {
  const { codeBlock, minimal } = props;
  const navigate = useNavigate();
  const [isEditing, setIsEditing] = useState(false);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  const openCodeBlock = (reportId: string) => {
    navigate(reportId);
  };

  const renderRightElements = (className: string) => {
    return (
      <div className={classNames(className, { [styles.codeBlocksSidebarItemPopoverOpen]: isPopoverOpen })}>
        <FavoriteButton entityId={codeBlock.id} entityType={EntityType.CodeBlock} />
        <MoreOptionsMenu
          content={<CodeBlocksSidebarContextMenu onEnter={() => setIsPopoverOpen(false)} codeBlock={codeBlock} />}
          popoverProps={{ isOpen: isPopoverOpen, onInteraction: setIsPopoverOpen, placement: "bottom-end" }}
          e2eIdentifiers={codeBlock.label}
          small
        />
      </div>
    );
  };

  const renderText = () => {
    if (minimal) {
      return undefined;
    }

    return (
      <DynamicLabelInput
        className={classNames("reports-group-cell--name", "cell-title")}
        isEditing={isEditing}
        value={codeBlock.label}
        onUpdate={codeBlock.setLabel}
        onStopEditing={() => setIsEditing(false)}
      />
    );
  };

  const renderIcon = () => {
    return (
      <div className={styles.codeBlocksSidebarItemIcon}>
        <ExecutionTypeIcon type={codeBlock.type} />
      </div>
    );
  };

  return (
    <ContextMenu
      key={codeBlock.id}
      onContextMenu={() => openCodeBlock(codeBlock.id)}
      content={<CodeBlocksSidebarContextMenu onEnter={closePopover} codeBlock={codeBlock} />}
      onDoubleClick={() => setIsEditing(true)}
    >
      <NavLinkAsMenuItem
        className="code-blocks-sidebar--nav-link"
        to={codeBlock.id}
        text={renderText()}
        icon={renderIcon()}
        selected={appStore.env.activeCodeBlockId === codeBlock.id}
        e2eIdentifiers={["code-blocks_sidebar", codeBlock.label]}
        renderRightElements={minimal ? undefined : renderRightElements}
      />
    </ContextMenu>
  );
};

export default observer(CodeBlocksSidebarItem);
