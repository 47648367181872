import { Intent } from "@blueprintjs/core";

import { GoogleSheetsIcon } from "@assets/icons/thirdParty";
import { Button } from "@components/Button";
import GoogleSheetsCustomPanel from "@components/DataSources/AddDataSource/GoogleSheetsCustomPanel/GoogleSheetsCustomPanel";
import { GoogleSheetsPreviewPanel } from "@components/DataSources/AddDataSource/GoogleSheetsPreviewPanel";
import { DataSourceIntegration, ECreateDataSource } from "@components/DataSources/AddDataSource/types";
import { IStep } from "@components/Shared/ColumnMultiStep/ColumnMultiStep";
import { EStepFieldType, IStepField, StepPanel } from "@components/Shared/StepPanel";
import { GoogleIntegrationItem, IntegrationProvider } from "@rollup-api/models";
import { DataSource } from "@rollup-api/models/data-sources";
import { IComplexSelectOption, ISelectOption } from "@rollup-types/selection";
import { useAppNavigate } from "@router/hooks";
import appStore from "@store/AppStore";

import addDataSourceStyles from "./AddDataSource.module.scss";

export interface IUseDataSourceStepsParams {
  name: string;
  description: string;
  integration?: IComplexSelectOption<DataSourceIntegration>;
  googleSheet?: GoogleIntegrationItem;
  dataSource?: DataSource;
  setName(value: string): void;
  setDescription(value: string): void;
  setIntegration(value: IComplexSelectOption<DataSourceIntegration>): void;
  setGoogleSheet(value: GoogleIntegrationItem): void;
}

export const useDataSourceSteps = (params: IUseDataSourceStepsParams): IStep<ECreateDataSource>[] => {
  const { navigateToIntegrationsPage } = useAppNavigate();

  const setupFields: IStepField[] = [
    { name: "Name", value: params.name, type: EStepFieldType.Input, required: true, placeholder: "Enter here", onChange: params.setName },
    {
      name: "Description",
      value: params.description,
      type: EStepFieldType.TextArea,
      placeholder: "Enter here",
      onChange: params.setDescription,
    },
  ];

  const integrationFields: IStepField[] = [
    {
      name: "Connected integrations",
      selected: params.integration,
      options: [
        {
          value: DataSourceIntegration.GoogleSheets,
          label: "Google Sheets",
          icon: <GoogleSheetsIcon />,
          isEnabled: () => appStore.orgModel.integrations.has(IntegrationProvider.Google),
        },
      ],
      type: EStepFieldType.Dropdown,
      required: true,
      placeholder: "Select",
      e2eIdentifiers: "open-integration-provider-dropdown",
      onSelect: (selected: ISelectOption) => params.setIntegration(selected as IComplexSelectOption<DataSourceIntegration>),
    },
  ];

  const renderIntegrationStep = (active: boolean) => {
    return (
      <StepPanel
        active={active}
        fields={integrationFields}
        nonIdealStateProps={{
          title: "No connected integration",
          description: (
            <div className={addDataSourceStyles.addDataSourceNoIntegration}>
              <div>Please connect an integration to use this feature (e.g. Google)</div>
              <Button onClick={navigateToIntegrationsPage} e2eIdentifiers="go-to-integrations-page" intent={Intent.PRIMARY}>
                Integrations Page
              </Button>
            </div>
          ),
        }}
      />
    );
  };

  const renderCustomStep = (active: boolean) => {
    switch (params.integration?.value) {
      case DataSourceIntegration.GoogleSheets:
        return <GoogleSheetsCustomPanel setSelectedSheet={params.setGoogleSheet} activeStep={active} />;
    }
  };

  const renderPreviewStep = () => {
    switch (params.integration?.value) {
      case DataSourceIntegration.GoogleSheets:
        return params.dataSource && <GoogleSheetsPreviewPanel dataSource={params.dataSource} />;
    }
  };

  return [
    {
      id: ECreateDataSource.Setup,
      renderPanel: (active: boolean) => <StepPanel active={active} fields={setupFields} />,
      title: "Setup",
    },
    {
      id: ECreateDataSource.Integration,
      renderPanel: renderIntegrationStep,
      title: "Select an Integration Connection",
    },
    { id: ECreateDataSource.Custom, renderPanel: renderCustomStep, title: "Select a Spreadsheet" },
    { id: ECreateDataSource.Preview, renderPanel: renderPreviewStep, title: "Preview" },
  ];
};
