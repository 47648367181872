import { ColumnMovedEvent, RowDragEndEvent } from "ag-grid-community";
import { observer } from "mobx-react";

import { ECustomUnitsColumn } from "@components/Modeling/CustomUnits/constants";
import { useCustomUnitsTableColDef } from "@components/Modeling/CustomUnits/useCustomUnitsTableColDef";
import { FormattedTable } from "@components/Modeling/FormattedTable";
import appStore from "@store/AppStore";
import { ICustomUnit } from "@store/CustomUnitStore";

const CustomUnitTable = () => {
  const { values: customUnits, getLabelValidationErrorMessage } = appStore.orgModel.customUnits;

  const handleAddNewRow = (label: string) => {
    appStore.orgModel?.customUnits.create(label);
  };

  const handleColumnMoved = (e: ColumnMovedEvent<ICustomUnit>) => {
    const columnsOrder = e.api.getAllGridColumns().map(c => c.getColId()) as ECustomUnitsColumn[];
    appStore.env.columnsOrder.updateCustomUnitsTableColumnsOrder(columnsOrder);
  };

  const onRowDragEnd = (e: RowDragEndEvent<ICustomUnit>) => {
    const targetIndex: number = e.overIndex;
    const nodeId: string | undefined = e.node.data?.id;
    const targetId: string | undefined = customUnits[targetIndex]?.id;

    if (nodeId && targetId) {
      appStore.orgModel.customUnits.move(nodeId, targetId);
    }
  };

  const columnDefs = useCustomUnitsTableColDef();

  return (
    <FormattedTable<ICustomUnit>
      className="property-definition-table"
      title="Custom Units"
      description="These are the custom units for this organization."
      addNewLabel="Add new custom unit"
      defaultDragText="Custom unit row"
      columnDefs={columnDefs}
      rowData={customUnits}
      onRowDragEnd={onRowDragEnd}
      onColumnMoved={handleColumnMoved}
      getValidationErrorMessage={getLabelValidationErrorMessage}
      onAddNewRow={handleAddNewRow}
    />
  );
};

export default observer(CustomUnitTable);
