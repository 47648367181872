import { Menu } from "@blueprintjs/core";
import { observer } from "mobx-react";

import { CellDropdown } from "@components/CellDropdown";
import { MenuItem } from "@components/MenuItem";
import StatusTag from "@components/StatusTag";
import { CatalogItemStatus } from "@rollup-api/models/catalogItem/catalogItemDtos";

import { TCellRendererProps } from "../types";

import styles from "./StatusCell.module.scss";

const StatusCell = (props: TCellRendererProps) => {
  const status = props.data?.catalogItem.status;
  const catalogItem = props.data?.catalogItem;

  const getMenuContent = () => (
    <Menu>
      {Object.values(CatalogItemStatus).map(s => (
        <MenuItem
          key={s}
          text={<StatusTag status={s} />}
          active={status === s}
          onClick={() => catalogItem?.setStatus(s)}
          e2eIdentifiers={s}
        />
      ))}
    </Menu>
  );

  return (
    <CellDropdown
      buttonClassName={styles.statusCell}
      text={status ? <StatusTag status={status} /> : "Status"}
      content={getMenuContent()}
      e2eIdentifiers="catalog-item-status"
    />
  );
};

export default observer(StatusCell);
