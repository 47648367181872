import { useEffect, useState } from "react";
import { Card } from "@blueprintjs/core";
import classNames from "classnames";

import AttachmentPopup from "@components/AttachmentPopup";
import { Button } from "@components/Button";
import { Popover } from "@components/Popover";
import { isValidImageUrl } from "@utilities/image";
import { getAssetLink } from "@utilities/LinkUtils";

import ImageUploaderCardImage from "./Components/ImageUploaderCardImage";

import styles from "./ImageUploaderCard.module.scss";

type Props = {
  src?: string;
  imageClassName?: string;
  containerClassName?: string;
  workspaceId?: string;
  className?: string;
  height?: number;
  closeBtnClassName?: string;
  onUploadStart?(): void;
  onSrcChange(url: string): void;
  onClose?(): void;
};

export const ImageUploaderCard = (props: Props) => {
  const { className, height, workspaceId, src, closeBtnClassName, imageClassName } = props;
  const { containerClassName, onUploadStart, onSrcChange, onClose } = props;
  const [imageError, setImageError] = useState<string>();
  const [isAttachmentPopupOpen, setIsAttachmentPopupOpen] = useState(false);
  const hasValidaImage = !!src && !imageError;

  const handleLinkChange = (link: string) => {
    setIsAttachmentPopupOpen(false);
    onSrcChange?.(link);
  };

  useEffect(() => {
    if (src) {
      const assetLink = getAssetLink(src) || "";
      isValidImageUrl(assetLink).then(valid => !valid && setImageError("Invalid image URL"));
    }
  }, [src]);

  return (
    <div
      className={classNames(styles.imageUploaderCardContainer, containerClassName, className, {
        [styles.imageUploaderCardContainerTransparent]: src && !imageError,
      })}
    >
      <Popover
        isOpen={isAttachmentPopupOpen}
        onInteraction={setIsAttachmentPopupOpen}
        content={<AttachmentPopup workspaceId={workspaceId} onUploadStart={onUploadStart} onLinkChange={handleLinkChange} fileUrl={src} />}
      >
        <Card className={classNames(styles.imageUploaderCard, { [styles.imageUploaderCardNoBorders]: src && !imageError })}>
          {onClose && !hasValidaImage && (
            <Button
              className={classNames(styles.imageUploaderCardButton, closeBtnClassName)}
              icon="cross"
              minimal
              onClick={onClose}
              e2eIdentifiers="close-image-uploader"
            />
          )}
          {hasValidaImage && (
            <Button
              className={classNames(styles.imageUploaderCardButton, styles.imageUploaderCardDeleteButton)}
              icon="trash"
              minimal
              onClick={() => onSrcChange("")}
              e2eIdentifiers="remove-uploaded-image"
            />
          )}
          <ImageUploaderCardImage height={height} className={imageClassName} src={src} error={imageError} />
        </Card>
      </Popover>
    </div>
  );
};

export default ImageUploaderCard;
