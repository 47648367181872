import { useEffect, useMemo } from "react";
import { Outlet } from "react-router-dom";
import { Divider, useHotkeys } from "@blueprintjs/core";
import useImportsCheck from "@hooks/useImportsCheck";
import { observer } from "mobx-react";

import { Bar } from "@components/Bar/Bar";
import AppWrapper from "@router/AppWrapper";
import { hotkeys } from "src/hotkeys";

import { LeftHeaderElements } from "./LeftHeaderElements";
import { RightHeaderElements } from "./RightHeaderElements";

import "./AppLayout.scss";

/** Main function. */
function AppLayout() {
  const hotkeyMemo = useMemo(() => hotkeys, []);
  const { handleKeyDown, handleKeyUp } = useHotkeys(hotkeyMemo);
  const { checkForUnsavedChanges } = useImportsCheck();

  useEffect(() => {
    window.addEventListener("beforeunload", checkForUnsavedChanges);
    return () => {
      window.removeEventListener("beforeunload", checkForUnsavedChanges);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AppWrapper>
      <div id="app" tabIndex={0} onKeyDown={handleKeyDown} onKeyUp={handleKeyUp} className="app-layout">
        <Bar className="bar-content">
          <LeftHeaderElements />
          <RightHeaderElements />
        </Bar>
        {/* 2. Main Content */}
        <Divider className="m-0" />
        <Outlet />
      </div>
    </AppWrapper>
  );
}

/** Exports. */
export default observer(AppLayout);
