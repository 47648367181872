import { Checkbox } from "@blueprintjs/core";
import { useWorkspace } from "@hooks/useWorkspace";
import { observer } from "mobx-react";

import appStore from "@store/AppStore";

import { TCellRendererProps } from "../types";

import "./CheckboxCell.scss";

const CheckboxCell = (props: TCellRendererProps) => {
  const { data, node } = props;
  const workspace = useWorkspace();
  const tableProxy = workspace.bomTablesMap.get(appStore.env.activeBomTableId || "");

  if (!data || !tableProxy) {
    return null;
  }

  const isSelected = tableProxy.selectedRows.includes(data.block.id);
  const toggleNodeSelection = () => node.setSelected(!node.isSelected());

  return <Checkbox className="checkbox-cell" checked={isSelected} onClick={toggleNodeSelection} />;
};

export default observer(CheckboxCell);
