import { Divider } from "@blueprintjs/core";
import classNames from "classnames";

import "./CommentThreadRunner.scss";

interface CommentThreadRunnerProps {
  first?: boolean;
  display?: boolean;
}

const CommentThreadRunner = ({ first, display = true }: CommentThreadRunnerProps) => {
  return (
    <div
      className={classNames("comment-thread-runner", {
        ["first"]: first,
      })}
    >
      {display && <Divider />}
    </div>
  );
};

export default CommentThreadRunner;
