export const OS = ["Windows", "Mac"] as const;

// Helper function that might be useful in case there are dynamic platform properties that might change
export const getPlatformData = () => {
  return {
    OS: OS.find(os => navigator.userAgent.includes(os)),
  } as const;
};

export const Platform = getPlatformData();

export const isMacOS = () => Platform.OS === "Mac";
