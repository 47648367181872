import { observer } from "mobx-react";

import appStore from "@store/AppStore";
import { IBomTable } from "@store/BomTable/BomTableStore";

import BomTablesSidebarItem from "./BomTablesSidebarItem";

import "./BomTablesSidebar.scss";

interface IBomTablesSidebarProps {
  minimal?: boolean;
}

function BomTablesSidebar(props: IBomTablesSidebarProps) {
  const { minimal } = props;
  const bomTables: IBomTable[] = appStore.workspaceModel?.bomTables ?? [];

  return (
    <div className="bom-tables-sidebar">
      {bomTables.toReversed().map(item => (
        <BomTablesSidebarItem key={item.id} bomTable={item} minimal={minimal} />
      ))}
    </div>
  );
}

/** Exports. */
export default observer(BomTablesSidebar);
