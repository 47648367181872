import { KeyboardEvent, useEffect } from "react";
import { useUpdateEditorContent } from "@hooks/useUpdateEditorContent";
import { observer } from "mobx-react";

import { EditorContent } from "@components/EditorContent";
import ArrowNavigation from "@components/Reports/Editor/Extentions/ArrowNavigation";
import { useDocumentEditor } from "@components/Reports/Editor/useDocumentEditor";
import { getDocumentEditorClassNames } from "@components/Reports/Editor/utils";
import { IReportBlock } from "@store/ReportBlockStore";

import PreventModEnter from "../Extentions/PreventModEnter";
import PreventShiftEnter from "../Extentions/PreventShiftEnter";
import { TEditorBlockProps } from "../types";

import "./Code.scss";

// TODO need to migrate to using IDocumentBlock. Not relevant atm, because requirements
//  don't use this block type
interface ICodeReportBlock extends Omit<TEditorBlockProps, "documentBlock"> {
  reportBlock: IReportBlock;
  reportBlocks?: IReportBlock[];
}

const CodeReportBlock = (props: ICodeReportBlock) => {
  const { reportBlock, reportBlocks, editable = true, eventHandlers, disableDashNavigation, onUpdate, onEditorReady } = props;
  const { onFocus, onBlur, onModEnterKeyDown, onShiftEnterKeyDown, onBackspaceKeyDown, onArrowUpKey } = eventHandlers;

  const arrowNavigation = reportBlocks ? [ArrowNavigation.configure({ reportBlock, reportBlocks })] : [];

  const { editor } = useDocumentEditor({
    blockId: reportBlock.id,
    content: reportBlock.label,
    editable,
    extensions: [PreventModEnter, PreventShiftEnter, ...arrowNavigation],
    disableDashNavigation,
    onUpdate,
    onFocus,
    onBlur,
    onEditorReady,
  });

  useEffect(() => {
    editor?.chain().focus().setCodeBlock().run();
  }, [editor, reportBlock.label]);

  useEffect(() => {
    onEditorReady?.(editor);
  }, [onEditorReady, editor]);

  useUpdateEditorContent({ content: reportBlock.label, editor });

  if (!editor) {
    return null;
  }

  const handleKeyDownEvents = (e: KeyboardEvent<HTMLDivElement>) => {
    onModEnterKeyDown(e);
    onBackspaceKeyDown(e);
    onShiftEnterKeyDown(e);
    onArrowUpKey(e);
  };

  return (
    <div key={reportBlock.id} className="report-editor--code">
      <EditorContent
        id={reportBlock.id}
        className={getDocumentEditorClassNames({
          annotationList: reportBlock.annotationList,
        })}
        onKeyDown={handleKeyDownEvents}
        editor={editor}
        e2eIdentifiers="report-editor"
      />
    </div>
  );
};

export default observer(CodeReportBlock);
