import { Tooltip } from "@blueprintjs/core";
import classNames from "classnames";
import { observer } from "mobx-react";

import { Button } from "@components/Button";
import { CustomIcon, ModuleMonochromeIcon } from "@components/CustomIcon";
import { TitledTooltip } from "@components/Shared";
import { EPageName } from "@router/hooks/useAppNavigate";
import NavLinkAsMenuItem from "@router/navigation/NavLinkAsMenuItem";
import appStore from "@store/AppStore";
import { openDocumentationLink } from "@utilities";
import { INTERCOM_BUTTON_ID } from "src/lib/Intercom/constants";

import styles from "./BottomMenuPaneItems.module.scss";

interface IBottomMenuPaneItemsProps {
  minimal?: boolean;
  currentPage?: EPageName;
}

const BottomMenuPaneItems = (props: IBottomMenuPaneItemsProps) => {
  const { minimal, currentPage } = props;
  const buttonClassNames = classNames(styles.bottomMenuPaneItemsButton, { [styles.bottomMenuPaneItemsButtonMinimal]: minimal });

  const renderCustomIcon = (icon: ModuleMonochromeIcon, active?: boolean) => {
    return <CustomIcon className={styles.bottomMenuPaneItemsCustomIcon} icon={icon} size={16} active={active} />;
  };

  return (
    <div className={classNames(styles.bottomMenuPaneItems, { [styles.bottomMenuPaneItemsMinimal]: minimal })}>
      <NavLinkAsMenuItem
        tooltip={minimal ? { title: "Data Connections", content: "Manage external data connections" } : undefined}
        to={`/workspaces/${appStore.workspaceModel?.id}/${EPageName.DataConnections}`}
        text={minimal ? "" : "Data Connections"}
        selected={currentPage === EPageName.DataConnections}
        icon={renderCustomIcon(ModuleMonochromeIcon.DataConnection, currentPage === EPageName.DataConnections)}
        e2eIdentifiers="data-sources"
      />
      <NavLinkAsMenuItem
        tooltip={minimal ? { title: "Imports", content: "Manage imports for this workspace" } : undefined}
        to={`/workspaces/${appStore.workspaceModel?.id}/imports`}
        text={minimal ? "" : "Imports"}
        selected={currentPage === EPageName.Imports}
        icon={renderCustomIcon(ModuleMonochromeIcon.Import, currentPage === EPageName.Imports)}
        e2eIdentifiers="imports"
      />
      <Tooltip content="Invite new users" className={styles.bottomMenuPaneItemsTooltip} disabled={!minimal}>
        <Button
          className={buttonClassNames}
          minimal
          icon={renderCustomIcon(ModuleMonochromeIcon.InviteUser)}
          onClick={appStore.inviteNewUser.show}
          e2eIdentifiers={["menu-pane", "show-invite-new-user"]}
        >
          {minimal ? "" : "Invite new user"}
        </Button>
      </Tooltip>
      <>
        <TitledTooltip title="Read documentation" className={styles.bottomMenuPaneItemsTooltip} disabled={!minimal}>
          <Button
            fill
            icon="manual"
            className={buttonClassNames}
            intent="primary"
            onClick={openDocumentationLink}
            e2eIdentifiers={["menu-pane", "show-docs-widget"]}
          >
            {minimal ? "" : "Read documentation"}
          </Button>
        </TitledTooltip>
        <Tooltip content="Live chat with us" className={styles.bottomMenuPaneItemsTooltip} disabled={!minimal}>
          <Button
            className={buttonClassNames}
            intent="primary"
            id={INTERCOM_BUTTON_ID}
            icon={renderCustomIcon(ModuleMonochromeIcon.Chat, true)}
            e2eIdentifiers={["menu-pane", "chat"]}
          >
            {minimal ? "" : "Live chat with us"}
          </Button>
        </Tooltip>
        <Tooltip content="Send feedback" className={styles.bottomMenuPaneItemsTooltip} disabled={!minimal}>
          <Button
            className={buttonClassNames}
            icon={renderCustomIcon(ModuleMonochromeIcon.Feedback, true)}
            intent="primary"
            onClick={appStore.feedback.show}
            e2eIdentifiers={["menu-pane", "show-feedback"]}
          >
            {minimal ? "" : "Send feedback"}
          </Button>
        </Tooltip>
      </>
    </div>
  );
};

export default observer(BottomMenuPaneItems);
