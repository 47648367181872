import { H3 } from "@blueprintjs/core";
import { observer } from "mobx-react-lite";

import AnchorLink from "@components/AnchorLink";
import { ListEntry } from "@components/Shared";
import { IAttachment } from "@store/AttachmentStore";
import { formatDate } from "@utilities";

import AttachmentDetailsPanel from "../AttachmentDetailsPanel";

interface IGithubTagDetailsPanelProps {
  attachment: IAttachment;
}

const GithubTagDetailsPanel = (props: IGithubTagDetailsPanelProps) => {
  const { attachment } = props;

  return (
    <AttachmentDetailsPanel className="github-issue-details-panel">
      <H3>Details</H3>
      <ListEntry label="Name" value={attachment.label} />
      {attachment.linkAttachmentUrl && <ListEntry label="URL" value={<AnchorLink href={attachment.linkAttachmentUrl} />} />}
      <ListEntry label="Type" value="Tag" />
      <ListEntry label="Created at" value={formatDate(attachment.createdAt)} />
    </AttachmentDetailsPanel>
  );
};

export default observer(GithubTagDetailsPanel);
