import { useSortable } from "@dnd-kit/sortable";
import { observer } from "mobx-react";

import Item, { TItemRendererCallBack } from "./Item";

interface SortableItemProps {
  containerId: string;
  id: string;
  index: number;
  handle: boolean;
  disabled?: boolean;
  isOver?: boolean;
  newItemAbove?: boolean;
  onSelect?: (id: string) => void;
  selected?: boolean;
  getIndex(id: string): number;
  renderItem: TItemRendererCallBack;
}

const SortableItem = (props: SortableItemProps) => {
  const { id, index, handle, renderItem, selected, onSelect, isOver, newItemAbove } = props;
  const { setNodeRef, setActivatorNodeRef, listeners, isDragging, transform, transition } = useSortable({
    id,
  });

  return (
    <Item
      isOver={isOver}
      newItemAbove={newItemAbove}
      selected={selected}
      onSelect={onSelect ? () => onSelect(id) : undefined}
      ref={setNodeRef}
      value={id}
      dragging={isDragging}
      handleProps={handle ? { ref: setActivatorNodeRef } : undefined}
      index={index}
      transition={transition}
      transform={transform}
      listeners={listeners}
      renderItem={renderItem}
    />
  );
};

export default observer(SortableItem);
