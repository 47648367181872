import { Tooltip } from "@blueprintjs/core";
import classNames from "classnames";
import { observer } from "mobx-react";
import { isAlive } from "mobx-state-tree";

import { TCellRendererProps } from "../types";

import "./MultiplicityCell.scss";

const MultiplicityCell = (props: TCellRendererProps) => {
  const block = props.data?.block;

  if (!block || !isAlive(block)) {
    return null;
  }

  const renderNumber = () => {
    const multiplicity = block.multiplicity;

    if (!multiplicity) {
      return "1";
    }

    if (multiplicity > 99) {
      return "99+";
    }

    return block.multiplicity;
  };

  return (
    <Tooltip content={`Block multiplicity (x${block.multiplicity || 1})`} className="multiplicity-tag">
      <span
        data-testid={`multiplicity_${block.label}`}
        className={classNames("ag-multiplicity-cell--placeholder", { ["muted"]: !block.multiplicity || block.multiplicity === 1 })}
      >
        x{renderNumber()}
      </span>
    </Tooltip>
  );
};

export default observer(MultiplicityCell);
