import { action, computed, observable } from "mobx";

import { DataSinkEntryDrawerStore } from "@components/DataSources/AddDataSink/stores/DataSinkEntryDrawerStore";
import { IComplexTag } from "@components/TagsContainer/TagsContainer.types";
import { ICreateDataSinkDto, IUpdateDataSinkDto } from "@rollup-api/models/data-sinks";
import { Color } from "@rollup-types/colors";
import { IIcon } from "@rollup-types/icons";
import { IDataSink } from "@store/DataConnection/DataSinkStore";
import { areIconsEqual } from "@utilities/Icon";

export class DataSinkDrawerStore {
  // it will only be available when editing an existing data sink
  public id?: string;

  @observable accessor icon: IIcon | undefined;

  @observable accessor tags: IComplexTag[] = [];

  @observable accessor label = "";

  @observable accessor description = "";

  @observable accessor entries: DataSinkEntryDrawerStore[] = [new DataSinkEntryDrawerStore()];

  @observable accessor deletedEntries: DataSinkEntryDrawerStore[] = [];

  public originalDataSink?: IDataSink;

  public visibleId?: string;

  constructor(dataSink?: IDataSink) {
    if (dataSink) {
      this.id = dataSink.id;
      this.icon = dataSink.icon ?? undefined;
      this.tags = dataSink.enhancedTags;
      this.label = dataSink.label;
      this.description = dataSink.description;
      this.entries = dataSink.entries?.map(e => new DataSinkEntryDrawerStore(e)) ?? [];
      this.originalDataSink = dataSink;
      this.visibleId = dataSink.visibleId;
    }
  }

  @action.bound
  public setIcon(value: IIcon) {
    this.icon = value;
  }

  @action.bound
  public toggleTag(tag: IComplexTag) {
    if (this.isTagSelected(tag)) {
      this.removeTag(tag);
    } else {
      this.addTag(tag);
    }
  }

  @action.bound
  public addTag(tag: IComplexTag) {
    this.tags.push(tag);
  }

  @action.bound
  public removeTag(tag: IComplexTag) {
    this.tags = this.tags.filter(t => t.value !== tag.value);
  }

  @action.bound
  public setLabel(value: string) {
    this.label = value;
  }

  @action.bound
  public setDescription(value: string) {
    this.description = value;
  }

  @action.bound
  public addEntry() {
    this.entries.push(new DataSinkEntryDrawerStore());
  }

  @action.bound
  public deleteEntry(id: string) {
    const deletedEntry = this.entries.find(e => e.id === id);
    // if the entry is existing, we need to add it to the deleted entries, so we can send the delete request
    if (deletedEntry?.isExisting) {
      this.deletedEntries.push(deletedEntry);
    }
    this.entries = this.entries.filter(p => p.id !== id);
  }

  public isTagSelected = (tag: IComplexTag) => {
    return this.tags.some(t => t.value === tag.value);
  };

  @computed
  public get hasFilledInRequiredFields(): boolean {
    return !!this.label;
  }

  @computed
  public get tagColors(): Color[] {
    return this.tags.map(t => t.color);
  }

  @computed
  public get stringifiedTags(): string {
    return this.tags.map(t => t.value).join(",");
  }

  @computed
  public get isDirty(): boolean {
    return this.isMetadataDirty || this.deletedEntries.length > 0 || this.addedEntries.length > 0 || this.updatedEntries.length > 0;
  }

  @computed
  public get isMetadataDirty(): boolean {
    if (!this.originalDataSink) {
      return false;
    }

    return (
      !areIconsEqual(this.icon, this.originalDataSink.icon) ||
      this.stringifiedTags !== this.originalDataSink.tags ||
      this.label !== this.originalDataSink.label ||
      this.description !== this.originalDataSink.description
    );
  }

  @computed
  public get addedEntries(): DataSinkEntryDrawerStore[] {
    return this.entries.filter(entry => !entry.isExisting && entry.key);
  }

  @computed
  public get updatedEntries(): DataSinkEntryDrawerStore[] {
    return this.entries.filter(entry => entry.isExisting).filter(e => e.isDirty);
  }

  @computed
  public get createDataSinkDto(): ICreateDataSinkDto {
    return {
      label: this.label,
      description: this.description,
      icon: this.icon,
      tags: this.tags.map(t => t.value).join(","),
      entries: this.entries.filter(e => e.key).map(p => p.createDto),
    };
  }

  @computed
  public get updateDataSinkDto(): IUpdateDataSinkDto {
    return {
      label: this.label,
      description: this.description,
      icon: this.icon,
      tags: this.stringifiedTags,
    };
  }
}
