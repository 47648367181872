import { Extension } from "@tiptap/core";

const PreventEnter = Extension.create({
  name: "prevent-enter",
  addKeyboardShortcuts(this) {
    return {
      Enter: () => true,
    };
  },
});

export default PreventEnter;
