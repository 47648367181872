import Text, { TextVariant } from "@ui/Text";
import { observer } from "mobx-react";

import { Button } from "@components/Button";
import { FormGroup } from "@components/FormGroup";
import { useAppNavigate } from "@router/hooks";
import { IIntegration } from "@store/IntegrationStore";
import { trackSegmentEvent } from "src/lib/Segment";

import "./IntegrationOverviewLeftPane.scss";

interface IIntegrationOverviewLeftPaneProps {
  integration: IIntegration;
}

const IntegrationOverviewLeftPane = (props: IIntegrationOverviewLeftPaneProps) => {
  const { integration } = props;
  const { navigateToIntegrationConfig } = useAppNavigate();

  const handleButtonClick = () => {
    if (integration.isConnected) {
      trackSegmentEvent("integration:configure", { integration: integration.name });
      navigateToIntegrationConfig(integration.id);
    } else {
      integration.connect();
    }
  };

  return (
    <div className="integration-overview-left-pane">
      {integration.logoUrl ? (
        <img className="integration-overview-left-pane--img" src={integration.logoUrl} alt={integration.name} width={128} height={128} />
      ) : (
        <div className="integration-overview-left-pane--img-mock" />
      )}
      <Button
        disabled={!integration.isActive}
        intent={integration.buttonIntent}
        fill
        large
        className="integration-overview-left-pane--cta"
        e2eIdentifiers="integration_cta"
        onClick={handleButtonClick}
      >
        {integration.isConnected ? "Configure" : "Connect"}
      </Button>
      {integration.publisherLink && (
        <FormGroup label="Website">
          <a href={integration.publisherLink} target="_blank" rel="noreferrer">
            {integration.publisherLink}
          </a>
        </FormGroup>
      )}
      {!!integration.categories?.length && (
        <FormGroup label="Categories">
          <div className="integration-overview-left-pane--categories">
            {integration.categories.map(category => (
              <Text key={category} variant={TextVariant.Body}>
                {category}
              </Text>
            ))}
          </div>
        </FormGroup>
      )}
      {integration.documentationLink && (
        <FormGroup label="Docs">
          <a href={integration.documentationLink} target="_blank" rel="noreferrer">
            {integration.documentationLink}
          </a>
        </FormGroup>
      )}
    </div>
  );
};

export default observer(IntegrationOverviewLeftPane);
