import axios, { AxiosInstance, AxiosRequestConfig, InternalAxiosRequestConfig } from "axios";

import appStore from "@store/AppStore";
import { rollupClient } from "src/core/api";

export interface ParentClient {
  url: string;
  apiPrefix: string;
  workspaceId?: string;
}

interface IRequestConfigCustomParams {
  skipTokenCheck?: boolean;
}

export type IAxiosRequestConfigWithCustomData = AxiosRequestConfig & IRequestConfigCustomParams;
type IInternalAxiosRequestConfig = InternalAxiosRequestConfig & IRequestConfigCustomParams;

export abstract class HttpClient {
  protected readonly instance: AxiosInstance;

  protected constructor(
    protected readonly parent: ParentClient,
    protected readonly apiPrefix?: string
  ) {
    this.instance = axios.create({
      baseURL: parent.url + (apiPrefix ?? parent.apiPrefix),
      responseType: "json",
      headers: {
        "Content-Type": "application/json",
      },
    });

    this.initializeRequestInterceptor();
  }

  private initializeRequestInterceptor = () => {
    this.instance.interceptors.request.use(async (requestConfig: IInternalAxiosRequestConfig) => {
      const { skipTokenCheck, ...restConfig } = requestConfig;
      const controller = new AbortController();

      if (!skipTokenCheck) {
        const response = await rollupClient.auth.refreshTokenIfNecessary();
        if (!response.success) {
          controller.abort("Failed to refresh token");
        }
      }

      const accessToken = rollupClient.auth.accessToken;

      if (accessToken && restConfig.headers) {
        restConfig.headers["Authorization"] = `Bearer ${accessToken}`;
      }

      const clientId = appStore.realtimeService.clientId;
      if (clientId && restConfig.headers) {
        restConfig.headers["ClientId"] = clientId;
      }

      return { ...restConfig, signal: controller.signal };
    });
  };
}
