import { useContext } from "react";
import { NonIdealState } from "@blueprintjs/core";
import { useOnMount } from "@hooks/useOnMount";
import { StiggContext } from "@stigg/react-sdk";
import { observer } from "mobx-react";

import appStore from "@store/AppStore";
import { createNewLogger } from "@utilities/LoggingUtils";

import { Checkout } from "./Checkout";
import { CustomerPortal } from "./CustomerPortal";

import "./SettingsPlanAndBilling.scss";

const logger = createNewLogger("SettingsPlanAndBilling");

const logDebugMessage = (hasStiggContext: boolean, productId: string) => {
  if (!hasStiggContext) {
    logger.debug("Stigg context is not available");
  } else if (!productId) {
    logger.debug("Stigg product id is not available");
  }
};

const SettingsPlanAndBilling = () => {
  const { selectedBillingPlan, setSelectedBillingPlan } = appStore.env;
  // Stigg context won't be available if stigg env variables were not set
  // Refer to our local StiggProvider for more details
  const hasStiggContext = !!useContext(StiggContext);

  const productId = import.meta.env.VITE_STIGG_PRODUCT_ID;

  useOnMount(() => {
    return () => setSelectedBillingPlan(undefined);
  });

  if (!hasStiggContext || !productId) {
    logDebugMessage(hasStiggContext, productId);
    return <NonIdealState title="Plan & Billing" description="Coming soon..." icon="clean" />;
  }

  return (
    <div className="settings-plan-and-billing">
      {selectedBillingPlan ? (
        <Checkout onGoBack={() => setSelectedBillingPlan(undefined)} selectedPlan={selectedBillingPlan} />
      ) : (
        <CustomerPortal productId={productId} onPlanSelected={setSelectedBillingPlan} />
      )}
    </div>
  );
};

export default observer(SettingsPlanAndBilling);
