import assignIn from "lodash/assignIn";
import { Instance, IType, SnapshotIn, SnapshotOut, types } from "mobx-state-tree";
import { Socket } from "socket.io-client";

import { PartNumberSchemaCreateDto, PartNumberSchemaUpdateDto } from "@rollup-api/models/part-number-schema";
import { updatePartNumberSchema } from "@rollup-api/utils";

import appStore from "./AppStore";

export const PartNumberSchemaStore = types
  .model("PartNumberSchema", {
    id: types.identifier,
    label: types.string,
    schema: types.string,
    currentCount: types.maybeNull(types.number),
    leadingZeroes: types.maybeNull(types.number),
  })
  .actions(self => ({
    patch(update: PartNumberSchemaUpdateDto) {
      try {
        assignIn(self, update);
        return true;
      } catch (err) {
        console.warn(err);
        return false;
      }
    },
  }))
  .actions(self => ({
    update(update: PartNumberSchemaUpdateDto) {
      updatePartNumberSchema(self.id, update);
      self.patch(update);
    },
  }));

export function subscribeToPartNumberSchemasEvents(socket: Socket) {
  socket.on(
    "createPartNumberSchema",
    (data: { workspaceId?: string; orgId?: string; createPartNumberSchemaDto: PartNumberSchemaCreateDto; userId: string }) => {
      const { label, id, schema, leadingZeroes } = data.createPartNumberSchemaDto;
      if (id) {
        if (data.workspaceId && data.workspaceId === appStore.workspaceModel?.id) {
          appStore.workspaceModel.partNumberSchemas.addPartNumberSchema(label, schema, leadingZeroes, id, false);
        } else if (data.orgId && data.orgId === appStore.orgModel.info.id) {
          appStore.orgModel.partNumberSchemas.addPartNumberSchema(label, schema, leadingZeroes, id, false);
        }
      }
    }
  );

  socket.on("deletePartNumberSchema", (data: { workspaceId?: string; orgId?: string; id: string; userId: string }) => {
    if (data.id) {
      if (data.workspaceId && data.workspaceId === appStore.workspaceModel?.id) {
        const partNumberSchema = appStore.workspaceModel.partNumberSchemas.get(data.id);
        if (partNumberSchema) {
          appStore.workspaceModel.partNumberSchemas.deletePartNumberSchema(data.id, false);
        }
      } else if (data.orgId && data.orgId === appStore.orgModel.info.id) {
        const partNumberSchema = appStore.orgModel.partNumberSchemas.get(data.id);
        if (partNumberSchema) {
          appStore.orgModel.partNumberSchemas.deletePartNumberSchema(data.id, false);
        }
      }
    }
  });

  socket.on(
    "updatePartNumberSchema",
    (data: { workspaceId?: string; orgId?: string; id: string; updatePartNumberSchemaDto: PartNumberSchemaUpdateDto; userId: string }) => {
      if (data.id && data.workspaceId && data.workspaceId === appStore.workspaceModel?.id) {
        if (data.workspaceId && data.workspaceId === appStore.workspaceModel?.id) {
          const instance = appStore.workspaceModel.partNumberSchemas.get(data.id);
          instance?.patch(data.updatePartNumberSchemaDto);
        } else if (data.orgId && data.orgId === appStore.orgModel.info.id) {
          const instance = appStore.orgModel.partNumberSchemas.get(data.id);
          instance?.patch(data.updatePartNumberSchemaDto);
        }
      }
    }
  );
}

export interface IPartNumberSchema extends Instance<typeof PartNumberSchemaStore> {}
export interface IPartNumberSchemaSnapshotIn extends SnapshotIn<typeof PartNumberSchemaStore> {}
interface IPartNumberSnapshotOut extends SnapshotOut<typeof PartNumberSchemaStore> {}
export interface IPartNumberSchemaMobxType extends IType<IPartNumberSchemaSnapshotIn, IPartNumberSnapshotOut, IPartNumberSchema> {}
