import { useEffect, useRef } from "react";
import { Menu } from "@blueprintjs/core";
import { observer } from "mobx-react";

import { Button } from "@components/Button";
import { MenuItemDelete } from "@components/MenuItems";
import { Popover } from "@components/Popover";
import { CopyLinkMenuItem, LinkableNode } from "@components/SmallComponents";

import "./FormattedTableActionsCell.scss";

interface IFormattedTableActionsCellProps {
  node: LinkableNode;
  onDelete(): void;
  registerRowDragger(ref: HTMLElement): void;
}

const FormattedTableActionsCell = (props: IFormattedTableActionsCellProps) => {
  const { node, onDelete, registerRowDragger } = props;
  const buttonRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    buttonRef.current && registerRowDragger(buttonRef.current);
  }, [buttonRef, registerRowDragger]);

  const getMenu = () => (
    <Menu>
      <CopyLinkMenuItem node={node} />
      <MenuItemDelete onDelete={onDelete} />
    </Menu>
  );

  return (
    <Popover content={getMenu()} placement="left-start" className="formatted-table-actions-cell">
      <Button
        className="formatted-table-actions-cell--button"
        buttonRef={buttonRef}
        icon="drag-handle-vertical"
        minimal
        small
        disabled={false}
        e2eIdentifiers={["actions-cell", "drag"]}
      />
    </Popover>
  );
};

export default observer(FormattedTableActionsCell);
