import { useEffect } from "react";
import { useMatch } from "react-router-dom";
import { ButtonGroup, Colors, H4, Icon, NonIdealState, Spinner } from "@blueprintjs/core";
import { observer } from "mobx-react";
import moment from "moment/moment";

import { Button } from "@components/Button";
import FileDropZone from "@components/FileDropZone";
import AttachmentIcon from "@components/Modeling/ModelingFrame/ModelBlock/Attachments/AttachmentIcon";
import { ListEntry } from "@components/Shared";
import User from "@components/User";
import { AttachmentStatus } from "@rollup-api/models/cloudStorage";
import { useAppNavigate } from "@router/hooks";
import appStore from "@store/AppStore";
import { IAttachment } from "@store/AttachmentStore";
import { FilePreviewType, formatDate, formatFileSize, getPreviewIcon, getPreviewType } from "@utilities";
import { Text, TextVariant } from "src/ui/Text";

import { useAttachmentFunctions } from "../attachmentHooks";

import AttachmentDetailsDialog from "./AttachmentDetailsDialog";
import AttachmentDetailsImagePreview from "./AttachmentDetailsImagePreview";
import AttachmentDetailsPanel from "./AttachmentDetailsPanel";

import "./AttachmentDetailsFile.scss";

interface AttachmentDetailsProps {
  attachment: IAttachment;
  isOpen: boolean;
  onClose: () => void;
}

const AttachmentDetailsFile = ({ attachment, isOpen, onClose }: AttachmentDetailsProps) => {
  const { handleDownload, handleView, handleAddVersion } = useAttachmentFunctions({ attachment });
  const { sortedVersions, fetchingVersions, previewUrl } = attachment;
  const isBlockView = useMatch("/workspaces/:id/modeling/blocks/:blockId");
  const { navigateToBlock, navigateToOrgLevelHoopsViewer } = useAppNavigate();

  useEffect(() => {
    attachment.fetchVersions();
  }, [attachment]);

  useEffect(() => {
    attachment.block?.ui.setBlockViewFileDropZoneEnabled(false);

    return () => {
      attachment.block?.ui.setBlockViewFileDropZoneEnabled(true);
    };
  }, [attachment]);

  const onView = () => {
    if (!attachment.block) {
      navigateToOrgLevelHoopsViewer(attachment.id);
    } else {
      !isBlockView && navigateToBlock(attachment.block.id);
      handleView();
    }

    onClose();
  };

  const handlePreviewDoubleClick = attachment.status === AttachmentStatus.Converted ? onView : handleDownload;

  const handleNavigateToBlock = () => {
    appStore.ui.setAttachmentDetails();
    navigateToBlock(attachment.block.id);
  };

  const handleFileDrop = (files: FileList) => files[0] && handleAddVersion(files[0]);

  const viewType = getPreviewType(attachment);
  const viewIcon = getPreviewIcon(viewType);
  const attachmentActions = (
    <ButtonGroup>
      <Button icon="download" onClick={handleDownload} e2eIdentifiers="download">
        Download
      </Button>
      {!isBlockView && attachment.block && (
        <Button icon="share" onClick={handleNavigateToBlock} e2eIdentifiers="view-block">
          View block
        </Button>
      )}
      {attachment.status === AttachmentStatus.Converted && (
        <Button icon={viewIcon} onClick={onView} e2eIdentifiers="view">
          {viewType === FilePreviewType.HoopsViewer ? "View in 3D" : "View"}
        </Button>
      )}
    </ButtonGroup>
  );

  const renderVersions = () => {
    if (fetchingVersions) {
      return <NonIdealState className="version-placeholder" icon={<Spinner size={16} />} title="Loading version history" />;
    }

    if (attachment.uploadingNewVersion && !sortedVersions?.length) {
      return <NonIdealState className="version-placeholder" icon={<Spinner size={16} />} title="Uploading a new version" />;
    }

    if (!sortedVersions?.length) {
      return <NonIdealState className="version-placeholder" icon="info-sign" title="No previous versions" />;
    }

    return (
      <>
        {attachment.uploadingNewVersion && (
          <ListEntry
            label="Uploading new version..."
            value={<User userId={appStore.userModel?.id} />}
            helperText={`${moment().format("D MMMM YY, h:mm a")}`}
          />
        )}
        {sortedVersions.map((version, index) => (
          <ListEntry
            key={version.lastModified}
            label={index === sortedVersions.length - 1 ? "Created by" : "Updated by"}
            value={<User userId={attachment.user?.id} />}
            helperText={`${moment(version.lastModified).format("D MMMM YY, h:mm a")}; Size: ${formatFileSize(version.size)}`}
          />
        ))}
      </>
    );
  };

  const renderContent = () => {
    return (
      <div className="attachment-details-file--content">
        <AttachmentDetailsImagePreview src={previewUrl} action={attachmentActions} onDoubleClick={handlePreviewDoubleClick} />
        <AttachmentDetailsPanel className="attachment-details-file">
          <div>
            <H4>Details</H4>
            <Text variant={TextVariant.Caption}>File name</Text>
            <div className="attachment-details-file--name">
              <AttachmentIcon attachment={attachment} width={20} height={22} />
              <div className="attachment-details-file--label">{attachment.name}</div>
            </div>
            <Text variant={TextVariant.Caption}>File type</Text>
            <p>{attachment.fileType}</p>
            <Text variant={TextVariant.Caption}>File size</Text>
            <p>{attachment.formattedFileSize}</p>
            <Text variant={TextVariant.Caption}>Created at</Text>
            <p>{formatDate(attachment.createdAt)}</p>
            <Text variant={TextVariant.Caption}>Added by</Text>
            <User userId={attachment.user?.id} />
          </div>
          {attachment.workspaceId && (
            <div className="attachment-details-file--versions">
              <div className="attachment-details-file--versions-header">
                <Text variant={TextVariant.H3}>Versions</Text>
                <Button onClick={() => handleAddVersion()} minimal e2eIdentifiers="add-new-version" icon="plus">
                  Add new version
                </Button>
              </div>
              <div className="attachment-details-file--versions-info">
                <Icon color={Colors.GRAY3} size={12} icon="info-sign" />
                <Text variant={TextVariant.Caption}>
                  Drag and drop file anywhere into this dialog to add a new version of this attachment
                </Text>
              </div>
              <div className="attachment-details-file--versions-list">{renderVersions()}</div>
            </div>
          )}
        </AttachmentDetailsPanel>
      </div>
    );
  };

  return (
    <AttachmentDetailsDialog label={attachment.label} isOpen={isOpen} onClose={onClose}>
      <FileDropZone onChange={handleFileDrop}>{renderContent}</FileDropZone>
    </AttachmentDetailsDialog>
  );
};

export default observer(AttachmentDetailsFile);
