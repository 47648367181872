import { useCallback, useState } from "react";
import { useElementObserver } from "@hooks/useElementObserver";

const AG_GRID_SCROLL_SELECTOR = ".ag-body-vertical-scroll";

/**
 * This hook will enable autoHeight when the grid is not overflowing, and disable it
 * when it is.
 * When the domLayout is set to 'autoHeight', two things happen:
 * 1. The grid will automatically resize to fit the number of rows in the grid. This is good
 * when the number of rows do not fill up the entire space of the container, so the grid will
 * adjust its height to the number of rows.
 * 2. The grid will automatically disable the vertical scrollbar, and we need to rely
 * on the container's scrollbar to scroll the grid. This is bad because the grid
 * header will not stay fixed when scrolling.
 */
export const useDynamicAutoHeight = (enableDynamicAutoHeight?: boolean, autoHeightDefaultValue?: boolean, className?: string) => {
  const [isScrollbarVisible, setIsScrollbarVisible] = useState(false);

  const onMutation = useCallback(
    (element: HTMLElement) => {
      const styles = getComputedStyle(element);
      if (styles.display === "none" && isScrollbarVisible) {
        setIsScrollbarVisible(false);
      }
    },
    [isScrollbarVisible]
  );

  const scrollBarSelector = className ? `.${className} ${AG_GRID_SCROLL_SELECTOR}` : AG_GRID_SCROLL_SELECTOR;

  useElementObserver({ selector: scrollBarSelector, onMutation, disabled: !enableDynamicAutoHeight });

  const handleResize = (entries: ResizeObserverEntry[]) => {
    if (!enableDynamicAutoHeight) {
      return;
    }
    const wrapper = entries[0].target;
    const isOverflowing = wrapper.scrollHeight > wrapper.clientHeight;
    if (isOverflowing && !isScrollbarVisible) {
      setIsScrollbarVisible(true);
    }
  };

  return { isAutoHeightEnabled: enableDynamicAutoHeight ? !isScrollbarVisible : autoHeightDefaultValue, handleResize };
};
