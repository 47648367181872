import BlankIcon from "@assets/icons/mimetypes/blank.svg?react";
import DassaultIcon from "@assets/icons/mimetypes/cad/3dxml.svg?react";
import CadIcon from "@assets/icons/mimetypes/cad/cad.svg?react";
import HsfIcon from "@assets/icons/mimetypes/cad/hsf.svg?react";
import KiCadIcon from "@assets/icons/mimetypes/cad/kicad.svg?react";
import ObjIcon from "@assets/icons/mimetypes/cad/obj.svg?react";
import SldDrtIcon from "@assets/icons/mimetypes/cad/slddrt.svg?react";
import SldDrwIcon from "@assets/icons/mimetypes/cad/slddrw.svg?react";
import SolidWorksIcon from "@assets/icons/mimetypes/cad/solidworks.svg?react";
import StklIcon from "@assets/icons/mimetypes/cad/stkl.svg?react";
import StpIcon from "@assets/icons/mimetypes/cad/stp.svg?react";
import DocxIcon from "@assets/icons/mimetypes/doc/docx.svg?react";
import HtmlIcon from "@assets/icons/mimetypes/doc/html.svg?react";
import MdIcon from "@assets/icons/mimetypes/doc/md.svg?react";
import PdfIcon from "@assets/icons/mimetypes/doc/pdf.svg?react";
import TxtIcon from "@assets/icons/mimetypes/doc/txt.svg?react";
import BrdIcon from "@assets/icons/mimetypes/ecad/brd.svg?react";
import GifIcon from "@assets/icons/mimetypes/image/gif.svg?react";
import JpegIcon from "@assets/icons/mimetypes/image/jpeg.svg?react";
import PngIcon from "@assets/icons/mimetypes/image/png.svg?react";
import SvgIcon from "@assets/icons/mimetypes/image/svg.svg?react";
import MatIcon from "@assets/icons/mimetypes/mat.svg?react";
import NxIcon from "@assets/icons/mimetypes/nx.svg?react";
import PptIcon from "@assets/icons/mimetypes/ppt.svg?react";
import RarIcon from "@assets/icons/mimetypes/rar.svg?react";
import ShapeIcon from "@assets/icons/mimetypes/shp.svg?react";
import VisioIcon from "@assets/icons/mimetypes/visio.svg?react";
import XlsIcon from "@assets/icons/mimetypes/xls.svg?react";
import ZipIcon from "@assets/icons/mimetypes/zip.svg?react";

export function getIconFromMimeType(mimeType?: string, fileName?: string) {
  let fileExtension = "";
  const fileExtensionMatch = fileName?.match(/\.([0-9a-z]+)$/i);
  if (fileExtensionMatch) {
    fileExtension = fileExtensionMatch[1].toLowerCase();
  }

  // Check for mime type
  switch (mimeType) {
    case "model/catia":
      return DassaultIcon;
    case "model/nx":
      return NxIcon;
    case "model/stp":
      return StpIcon;
    case "model/stkl":
      return StklIcon;
    case "model/obj":
      return ObjIcon;
    case "model/hsf":
      return HsfIcon;
    case "model/solidworks":
      if (fileExtension === "sldasm" || fileExtension === "sldprt") {
        return SolidWorksIcon;
      }
      break;
    case "text/plain":
      return TxtIcon;
    case "text/html":
      return HtmlIcon;
    case "application/msword":
    case "application/wps-office.docx":
    case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
      return DocxIcon;
    case "text/markdown":
      return MdIcon;
    case "image/png":
      return PngIcon;
    case "image/jpeg":
      return JpegIcon;
    case "image/gif":
      return GifIcon;
    case "image/svg+xml":
      return SvgIcon;
    case "application/pdf":
      return PdfIcon;
    case "application/vnd.ms-excel":
    case "text/csv":
    case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
      return XlsIcon;
    case "application/vnd.ms-powerpoint":
    case "application/vnd.openxmlformats-officedocument.presentationml.presentation":
      return PptIcon;
    case "application/vnd.visio2013":
      return VisioIcon;
    case "application/gzip":
    case "application/zip":
    case "application/x-7z-compressed":
    case "application/x-bzip":
    case "application/x-bzip2":
      return ZipIcon;
    case "application/vnd.kicad_pcb":
    case "application/vnd.kicad_sch":
    case "application/vnd.kicad_pro":
      return KiCadIcon;
    case "application/vnd.gerber":
    case "application/x-eagle-board":
      return BrdIcon;
  }

  // Check for other CAD files
  if (mimeType?.startsWith("model/")) {
    return CadIcon;
  }
  if (mimeType?.startsWith("board/")) {
    return BrdIcon;
  }

  // Check for file extension
  switch (fileExtension) {
    case "rar":
      return RarIcon;
    case "shp":
      return ShapeIcon;
    case "mat":
    case "m":
    case "mex":
      return MatIcon;
    case "slddrw":
      return SldDrwIcon;
    case "slddrt":
      return SldDrtIcon;
  }

  // Nothing matched
  return BlankIcon;
}
