import { CellClickedEvent, GridOptions, RowHeightParams } from "ag-grid-community";

import HeaderCell from "@components/CatalogItems/Cells/HeaderCell";
import StatusBar from "@components/CatalogItems/StatusBar";
import { rowHeight, thumbnailRowHeight } from "@components/CatalogItems/Table/constants";
import { autoGroupColumnDef } from "@components/CatalogItems/Table/pdmColumnDefs";
import { CatalogItemNode, CatalogItemTableColumn, versionCellColumns } from "@components/CatalogItems/Table/types";
import { getRowId } from "@components/CatalogItems/Table/utils";
import appStore from "@store/AppStore";

export const catalogItemsTableGridOptions: GridOptions<CatalogItemNode> = {
  autoGroupColumnDef,
  groupDefaultExpanded: -1,
  components: { agColumnHeader: HeaderCell },
  statusBar: { statusPanels: [{ statusPanel: StatusBar, align: "left" }] },
  getRowId: getRowIdProps => getRowId(getRowIdProps.data),
  getRowHeight: (params: RowHeightParams<CatalogItemNode>) => {
    const { catalogItem } = params.data ?? {};
    const { showPdmThumbnailForIds } = appStore.ui;
    const { showPdmThumbnail } = appStore.env;
    if (showPdmThumbnail || (catalogItem && showPdmThumbnailForIds.includes(catalogItem.id))) {
      return thumbnailRowHeight;
    }
    return rowHeight;
  },
  onCellClicked: (e: CellClickedEvent<CatalogItemNode>) => {
    const reference = e.node.data?.reference;
    const id = e.node.data?.catalogItem.id ?? "";
    const versionCellClicked = versionCellColumns.includes(e.column.getColId() as CatalogItemTableColumn);

    if (reference) {
      appStore.ui.setCatalogItemReferencePreviewId(reference.id);
      appStore.ui.resetCatalogItemPreviewId();
    } else {
      appStore.ui.setCatalogItemPreviewId(id);
      appStore.ui.resetCatalogItemReferencePreviewId();
    }

    if (versionCellClicked && appStore.ui.pdmItemVersionProgress && id !== appStore.ui.pdmItemVersionProgress.catalogItemId) {
      appStore.ui.setShowPdmNewVersionDialog();
      e.api.stopEditing();
      return false;
    }
  },
};
