import { useCallback } from "react";
import { Position, Tooltip } from "@blueprintjs/core";
import { observer } from "mobx-react";

import CaptureScreenIconDark from "@assets/icons/hoopsIcons/dark/capture_screen_dark.svg?react";
import CaptureScreenIconLight from "@assets/icons/hoopsIcons/light/capture_screen_light.svg?react";
import { Button } from "@components/Button";
import { LegacyCustomIcon } from "@components/Shared/LegacyCustomIcon/LegacyCustomIcon";
import { showApiErrorToast } from "@components/UiLayers/toaster";
import appStore from "@store/AppStore";

import styles from "../HoopsMenu.module.scss";

type HoopsMenuSnapshotButtonProps = {
  viewer: Communicator.WebViewer;
};

const HoopsMenuSnapshotButton = ({ viewer }: HoopsMenuSnapshotButtonProps) => {
  const takeSnapshot = useCallback(async () => {
    const viewElement = viewer.getViewElement();
    try {
      const imgElement = await viewer.takeSnapshot({
        // save snapshot at double resolution
        width: viewElement.offsetWidth * 2 * devicePixelRatio,
        height: viewElement.offsetHeight * 2 * devicePixelRatio,
        layers: Communicator.SnapshotLayer.All,
      });
      const save = document.createElement("a");
      save.href = imgElement.src;
      save.target = "_blank";
      save.download = "rollup_model_screenshot.png";
      save.click();
    } catch (err: any) {
      console.error(err);
      showApiErrorToast("Error saving snapshot", err);
    }
  }, [viewer]);

  return (
    <Tooltip position={Position.TOP} hoverOpenDelay={500} content="Capture screen">
      <Button
        className={styles.hoopsToolbarButtonAndButtonGroup}
        icon={<LegacyCustomIcon large icon={appStore.env.themeIsDark ? <CaptureScreenIconDark /> : <CaptureScreenIconLight />} />}
        onClick={takeSnapshot}
        e2eIdentifiers="take-snapshot"
      />
    </Tooltip>
  );
};

export default observer(HoopsMenuSnapshotButton);
