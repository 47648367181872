import { Menu } from "@blueprintjs/core";
import { observer } from "mobx-react";

import { MenuItem } from "@components/MenuItem";
import { showToast } from "@components/UiLayers/toaster";
import { RollupEventSetStatusRequestDto } from "@rollup-api/models/rollupEvents/RollupEventSetStatusRequestDto.model";
import appStore from "@store/AppStore";
import { IRollupEvent, RollupEventStatuses } from "@store/RollupEventsStore";
import { copyToClipboard } from "@utilities";

interface RollupEventContextMenuProps {
  rollupEvent: IRollupEvent;
  onMarkAs: () => void;
  onMove: () => void;
}

const RollupEventContextMenu = ({ rollupEvent, onMarkAs, onMove }: RollupEventContextMenuProps) => {
  const handleCopyLinkToClipboard = async (): Promise<void> => {
    try {
      await copyToClipboard(window.location.href);
      showToast("Copied message link to clipboard", "success", "info-sign");
    } catch (err) {
      showToast("Failed to copy message link to clipboard", "danger", "error");
    }
  };

  const toggleUnreadStatus = async () => {
    try {
      const dto: RollupEventSetStatusRequestDto = {
        ids: [rollupEvent.id],
        toStatus: rollupEvent.isRead ? RollupEventStatuses.unread : RollupEventStatuses.read,
      };
      await appStore.userModel?.rollupEvents?.setRollupEventStatus(dto);
      onMarkAs?.();
    } catch (err) {
      return;
    }
  };

  const handleArchiveEvent = async () => {
    const dto: RollupEventSetStatusRequestDto = {
      ids: [rollupEvent.id],
      toStatus: rollupEvent.isArchived ? RollupEventStatuses.unarchived : RollupEventStatuses.archived,
    };
    await appStore.userModel?.rollupEvents?.setRollupEventStatus(dto);
    onMove?.();
  };

  return (
    <Menu>
      <MenuItem text="Copy link" onClick={handleCopyLinkToClipboard} e2eIdentifiers={["copy-to-clipboard"]} />
      <MenuItem
        text={rollupEvent.isRead ? "Mark as unread" : "Mark as read"}
        onClick={toggleUnreadStatus}
        e2eIdentifiers={[rollupEvent.isRead ? "mark-as-unread" : "mark-as-read"]}
      />
      <MenuItem
        text={rollupEvent.isArchived ? "Move to inbox" : "Archive"}
        onClick={handleArchiveEvent}
        e2eIdentifiers={rollupEvent.isArchived ? "move-to-inbox" : "archive"}
      />
    </Menu>
  );
};

export default observer(RollupEventContextMenu);
