import { RefObject } from "react";
import { Editor } from "@tiptap/core";
import { CellSelection } from "@tiptap/pm/tables";
import classNames from "classnames";
import { ResolvedPos } from "prosemirror-model";

import HorizontalDrag from "@assets/icons/horizontal-drag.svg?react";
import HorizontalDragDark from "@assets/icons/horizontal-drag-dark.svg?react";
import VerticalDrag from "@assets/icons/vertical-drag.svg?react";
import VerticalDragDark from "@assets/icons/vertical-drag-dark.svg?react";
import { Button } from "@components/Button";
import { IPopoverRefType, Popover } from "@components/Popover";
import { LegacyCustomIcon } from "@components/Shared/LegacyCustomIcon/LegacyCustomIcon";
import appStore from "@store/AppStore";
import { IReportBlock } from "@store/ReportBlockStore";

import HorizontalNav from "./HorizontalNav";
import VerticalNav from "./VerticalNav";

import "./TableButton.scss";

type Props = {
  editor?: Editor | null;
  cellPosition?: ResolvedPos;
  menuRef: RefObject<IPopoverRefType>;
  reportBlock: IReportBlock;
  horizontal?: boolean;
  yPosition?: number;
  xPosition?: number;
};

const TableButton = (props: Props) => {
  const { horizontal, yPosition, xPosition, editor, menuRef, cellPosition, reportBlock } = props;
  const IconDarkComponent = horizontal ? HorizontalDrag : VerticalDrag;
  const IconLightComponent = horizontal ? HorizontalDragDark : VerticalDragDark;
  const IconComponent = appStore.env.themeIsDark || menuRef.current?.state.isOpen ? IconDarkComponent : IconLightComponent;
  const NavComponent = horizontal ? HorizontalNav : VerticalNav;

  const classNameRules = {
    "table-button--horizontal": horizontal,
    "table-button--vertical": !horizontal,
    "table-button--active": menuRef.current?.state.isOpen,
  };

  const wrapClassNameRules = {
    "table-button--wrap-horizontal": horizontal,
    "table-button--wrap-vertical": !horizontal,
  };

  const setAreaSelection = () => {
    if (cellPosition) {
      let selection;
      if (horizontal) {
        selection = CellSelection.colSelection(cellPosition);
      } else {
        selection = CellSelection.rowSelection(cellPosition);
      }
      editor?.commands.setCellSelection({ anchorCell: selection.$anchorCell.pos, headCell: selection.$headCell.pos });
    }
  };

  const onUpdate = () => {
    if (editor) {
      reportBlock.updateText(editor.getHTML());
    }
  };

  return (
    <div style={{ top: yPosition, left: xPosition }} className={classNames("table-button--wrap", wrapClassNameRules)}>
      <Popover
        onOpening={setAreaSelection}
        content={<NavComponent onUpdate={onUpdate} menuRef={menuRef} editor={editor} />}
        ref={menuRef}
        position="bottom-left"
      >
        <Button
          icon={<LegacyCustomIcon icon={<IconComponent />} />}
          className={classNames("table-button", classNameRules)}
          e2eIdentifiers="table-button"
        />
      </Popover>
    </div>
  );
};

export default TableButton;
