export const getDeepLinkUrl = (urlParams: Record<string, string | undefined>) => {
  const { protocol, host, pathname, hash } = window.location;
  // join all params into a string with & in between
  const urlParamsString = Object.keys(urlParams)
    .map(key => {
      if (!urlParams[key]) {
        return "";
      }
      return `${key}=${urlParams[key]}`;
    })
    .join("&");

  return `${protocol}//${host}${pathname}?${urlParamsString}${hash}`;
};
