import { Tooltip as BPTooltip, TooltipProps } from "@blueprintjs/core";
import classNames from "classnames";
import { observer } from "mobx-react";

import "./Tooltip.scss";

interface ITooltipProps extends TooltipProps {}

const Tooltip = (props: ITooltipProps) => {
  const { className, popoverClassName, ...restProps } = props;

  return (
    <BPTooltip
      className={classNames("tooltip", className)}
      popoverClassName={classNames("tooltip--popover", popoverClassName)}
      {...restProps}
    />
  );
};

export default observer(Tooltip);
