import { useCallback } from "react";
import { observer } from "mobx-react";
import { isAlive } from "mobx-state-tree";

import FormattedTableActionsCell from "@components/Modeling/FormattedTable/Cells/FormattedTableActionsCell";
import { TCellRendererProps } from "@components/Table";
import appStore from "@store/AppStore";
import { IPropertyDefinition } from "@store/PropertyDefinitionStore";

const PropertyDefinitionActionsCell = (props: TCellRendererProps<IPropertyDefinition>) => {
  const { data: propertyDefinition, api, registerRowDragger } = props;

  const handleDeleteClick = useCallback(() => {
    appStore.workspaceModel?.deletePropertyDefinition(propertyDefinition);
    api.applyTransaction({ remove: [{ id: propertyDefinition.id }] });
  }, [propertyDefinition, api]);

  if (!propertyDefinition || !isAlive(propertyDefinition)) {
    return null;
  }

  return <FormattedTableActionsCell node={propertyDefinition} onDelete={handleDeleteClick} registerRowDragger={registerRowDragger} />;
};

export default observer(PropertyDefinitionActionsCell);
