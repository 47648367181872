import { RefObject, useEffect, useRef, useState } from "react";
import { ContextMenu, Icon } from "@blueprintjs/core";
import { ICellRendererParams } from "ag-grid-community";
import classNames from "classnames";
import { observer } from "mobx-react";

import { Button } from "@components/Button";
import DeleteReportConfirmation from "@components/Dialog/DeleteReportConfirmation";
import DynamicLabelInput from "@components/DynamicLabelInput/DynamicLabelInput";
import { FavoriteButton } from "@components/FavoriteButton";
import { IPopoverRefType, Popover } from "@components/Popover";
import ReportsSidebarContextMenu from "@components/Reports/ReportContextMenu";
import { PADDING_PER_LEVEL } from "@components/ReportsTree/constants";
import { ReportTreeNodeInfo } from "@components/ReportsTree/types";
import { SafeTooltip } from "@components/SmallComponents";
import appStore from "@store/AppStore";
import { IReport } from "@store/ReportsStore";
import { EntityType } from "@store/types";
import { closePopover } from "@utilities/DomUtils";

import "./ReportGroupCell.scss";

const ReportGroupCell = (props: ICellRendererParams<ReportTreeNodeInfo>) => {
  const { data } = props;
  const [isEditing, setIsEditing] = useState(false);
  const [expanded, setExpanded] = useState(props.node.expanded);
  const [reportToDelete, setReportToDelete] = useState<IReport | null>(null);
  const [isContextMenuOpen, setIsContextMenuOpen] = useState(false);
  const popoverRef: RefObject<IPopoverRefType> = useRef(null);
  const paddingLeft = props.node.uiLevel * PADDING_PER_LEVEL;

  useEffect(() => {
    setExpanded(props.node.expanded);
  }, [props.node.expanded]);

  const handleExpand = () => {
    setExpanded(!expanded);
    props.node.setExpanded(!props.node.expanded);
  };

  if (data?.mockId) {
    return (
      <div className="reports-group-cell--placeholder" style={{ paddingLeft }}>
        No pages inside
      </div>
    );
  }

  if (!data?.report) {
    return null;
  }

  const { report } = data;

  const handleAddChild = () => {
    appStore.workspaceModel?.createReport({ parentReportId: report?.id });
    setExpanded(true);
    props.node.setExpanded(true);
  };

  const handleUpdate = (label: string) => {
    report.update({ label });
  };

  return (
    <ContextMenu
      style={{ paddingLeft }}
      className="reports-group-cell"
      content={<ReportsSidebarContextMenu onEnter={closePopover} onDelete={setReportToDelete} report={report} />}
      onDoubleClick={() => setIsEditing(true)}
    >
      <div className="reports-group-cell--meta">
        <Icon
          className="reports-group-cell--arrow reports-group-cell--icon"
          icon={expanded ? "caret-down" : "caret-right"}
          onClick={handleExpand}
        />
        <span className="reports-group-cell--label-icon">{report.displayedIcon}</span>
        <DynamicLabelInput
          className={classNames("reports-group-cell--name", "cell-title")}
          isEditing={isEditing}
          value={isEditing ? report.label : report?.displayedLabel}
          onUpdate={handleUpdate}
          onStopEditing={() => setIsEditing(false)}
        />
      </div>
      <div className="reports-group-cell--actions-nav">
        <FavoriteButton entityId={report.id} entityType={EntityType.Report} />
        <SafeTooltip content="Create page inside this page">
          <Button
            onClick={handleAddChild}
            minimal
            small
            icon={<Icon className="reports-group-cell--icon" icon="add" size={16} />}
            e2eIdentifiers="add-child"
          />
        </SafeTooltip>
        <Popover
          ref={popoverRef}
          isOpen={isContextMenuOpen}
          onInteraction={setIsContextMenuOpen}
          position="bottom-right"
          content={<ReportsSidebarContextMenu onEnter={() => setIsContextMenuOpen(false)} onDelete={setReportToDelete} report={report} />}
        >
          <Button minimal small icon={<Icon className="reports-group-cell--icon" icon="more" size={16} />} e2eIdentifiers="report-nav" />
        </Popover>
      </div>
      {reportToDelete && <DeleteReportConfirmation onClose={() => setReportToDelete(null)} report={reportToDelete} isOpen />}
    </ContextMenu>
  );
};

export default observer(ReportGroupCell);
