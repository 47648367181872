import { useState } from "react";
import { IRowNode, RowDragEndEvent, RowDragEvent, RowDragLeaveEvent, RowDragMoveEvent } from "ag-grid-community";

import { DANGER_DROP, ICON_WIDTH } from "@components/Modeling/ModelingFrame/Table/TableComponent/constants";
import { EMoveType } from "@components/Modeling/ModelingFrame/Table/TableComponent/types";
import { getElementPosition, getTableRowById, isValidRowMove } from "@components/Modeling/ModelingFrame/Table/TableComponent/utils";
import { reportNodeList } from "@components/ReportsTree/constants";
import { ReportTreeNodeInfo, ReportTreeRowNode } from "@components/ReportsTree/types";
import appStore from "@store/AppStore";
import { IReport } from "@store/ReportsStore";
import { isStringArraysEqual } from "@utilities";

export const getMoveDestinationReport = (source: IReport, target: IReport): IReport => {
  const children = source.parentReport ? source.parentReport.children.map((c: IReport) => c.id) : appStore.workspaceModel?.rootReportIds;

  const sourceBlockIndex = children?.indexOf(source.id) || 0;
  const targetBlockIndex = children?.indexOf(target.id) || 0;

  if (children && targetBlockIndex < sourceBlockIndex) {
    const targetId = children.at(targetBlockIndex + 1);
    return appStore.workspaceModel?.reportsMap.get(targetId) as IReport;
  }

  return target;
};

const useTreeDrag = () => {
  const [moveType, setMoveType] = useState<EMoveType>(EMoveType.RE_PARENT);
  const [potentialParent, setPotentialParent] = useState<ReportTreeRowNode>(null);

  const onRowDragMove = (moveProps: RowDragMoveEvent<ReportTreeNodeInfo>) => {
    let leftOffset = 0;
    let newMoveType = EMoveType.RE_ORDER;

    const agRowElement = (moveProps.eventPath?.at(0) as HTMLElement | null)?.closest(".ag-row") as HTMLElement | null;
    const targetNode = moveProps.api.getRowNode(agRowElement?.getAttribute("row-id") || "");
    const imageNode = agRowElement?.querySelector(".reports-group-cell--label-icon");

    if (imageNode && agRowElement) {
      const imageNodeCords = getElementPosition(imageNode);
      const rowNodeCords = getElementPosition(agRowElement);
      const pseudoElementPosition = imageNodeCords.x - rowNodeCords.x + 28;
      const isOuterHover = imageNodeCords.x + ICON_WIDTH > moveProps.event.x;

      newMoveType = isOuterHover ? EMoveType.RE_ORDER : EMoveType.RE_PARENT;
      leftOffset = newMoveType === EMoveType.RE_ORDER ? pseudoElementPosition - ICON_WIDTH : pseudoElementPosition - 5;

      if (moveType === EMoveType.RE_PARENT) {
        agRowElement.classList.add("ag-grid-re-parent");
        agRowElement.classList.remove("ag-grid-re-order");
      } else {
        agRowElement.classList.add("ag-grid-re-order");
        agRowElement.classList.remove("ag-grid-re-parent");
      }

      agRowElement.style.setProperty("--left-position", `${leftOffset}px`);
    }

    setMoveType(newMoveType);
    setPotentialParentForNode(moveProps, targetNode);
  };

  const onRowDragLeave = (event: RowDragLeaveEvent<ReportTreeNodeInfo>) =>
    setPotentialParentForNode(
      {
        ...event,
        overNode: undefined,
      },
      null
    );

  const onRowDragEnd = async (event: RowDragEndEvent<ReportTreeNodeInfo>) => {
    const targetReport = potentialParent?.data?.report;
    const selectedReport = event.node.data?.report;
    const isValidMove = isValidRowMove(event, selectedReport?.id, targetReport?.id);
    const parentElement = getTableRowById(potentialParent?.id);

    if (parentElement) {
      parentElement.classList.remove(DANGER_DROP);
    }

    if (targetReport && selectedReport && isValidMove) {
      const isSameParent = targetReport.parentReport?.id === selectedReport.parentReport?.id;
      const isReparent = moveType === EMoveType.RE_PARENT;
      const moveToParentTop = targetReport.id === selectedReport.parentReport?.id && isReparent;
      const reorderInsideParent = isSameParent && moveType === EMoveType.RE_ORDER;

      // reorder reports inside same parent
      if (moveToParentTop) {
        selectedReport.parentReport.moveReport(selectedReport.id, targetReport.children?.at(0).id);
      } else if (reorderInsideParent) {
        const destinationReport = getMoveDestinationReport(selectedReport, targetReport);
        if (!selectedReport.parentReport) {
          appStore.workspaceModel?.moveRootReport(selectedReport.id, destinationReport.id);
        } else {
          selectedReport.parentReport.moveReport(selectedReport.id, destinationReport.id);
        }
        // reparent report to the top of the new parent
      } else if (isReparent) {
        const reParentDone = await appStore.workspaceModel?.reparentReport(selectedReport, targetReport);
        if (reParentDone && targetReport.children?.length) {
          targetReport.moveReport(selectedReport.id, targetReport.children?.at(0).id);
        }
      } else {
        // reparent report and reorder it inside the new parent
        const reParentDone = await appStore.workspaceModel?.reparentReport(selectedReport, targetReport.parentReport);
        const destinationReport = getMoveDestinationReport(selectedReport, targetReport);

        if (reParentDone) {
          if (targetReport.parentReport) {
            targetReport.parentReport.moveReport(selectedReport.id, destinationReport.id);
          } else {
            appStore.workspaceModel?.moveRootReport(selectedReport.id, destinationReport.id);
          }
        }
      }
    }

    event.api.setGridOption("rowData", reportNodeList());
    potentialParent?.setSelected(false);
    setPotentialParent(null);
  };

  const setPotentialParentForNode = (
    event: RowDragEvent<ReportTreeNodeInfo>,
    targetNode: IRowNode<ReportTreeNodeInfo> | null | undefined
  ) => {
    let newPotentialParent = targetNode;
    const alreadySelected = potentialParent === newPotentialParent;
    const isValidMove = isValidRowMove(event);
    const moveToSelf = isStringArraysEqual(event.node.data?.path || [], event.overNode?.data?.path || []);

    if (moveToSelf) {
      newPotentialParent = null;
    }

    if (alreadySelected) {
      return;
    }

    if (newPotentialParent) {
      const potentialParentElement = getTableRowById(newPotentialParent.id);

      if (!isValidMove && potentialParentElement) {
        potentialParentElement.classList.add(DANGER_DROP);
      }
      newPotentialParent.setSelected(true);
    }

    if (potentialParent) {
      const parentElement = getTableRowById(potentialParent.id);

      if (parentElement) {
        parentElement.classList.remove(DANGER_DROP);
      }
      potentialParent.setSelected(false);
    }

    setPotentialParent(newPotentialParent);
  };

  return {
    onRowDragMove,
    onRowDragLeave,
    onRowDragEnd,
    moveType,
    potentialParent,
  };
};

export default useTreeDrag;
