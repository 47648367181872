import { useEffect, useRef, useState } from "react";
import { Tooltip } from "@blueprintjs/core";
import { BlueprintIcon } from "@ui/BlueprintIcon";
import { observer } from "mobx-react";

import AttachmentPopup from "@components/AttachmentPopup";
import { Button } from "@components/Button";
import ImageUploaderCard from "@components/ImageUploaderCard";
import { IPopoverRefType, Popover } from "@components/Popover";
import { IBlock } from "@store/BlockStore";
import { parentWorkspace } from "@utilities/Models";

import "./ImageCell.scss";

const ImageCell = (props: { data?: { block: IBlock } }) => {
  const [imageUrl, setImageUrl] = useState("");
  const block = props.data?.block;
  const workspaceId = parentWorkspace(block)?.id;
  const popoverRef = useRef<IPopoverRefType>(null);
  const [isImageTooltipOpen, setIsImageTooltipOpen] = useState(false);
  const [isUploadingImage, setIsUploadingImage] = useState(false);

  useEffect(() => {
    setImageUrl(block?.thumbnailUrl || "");
  }, [block?.thumbnailUrl]);

  if (!block) {
    return null;
  }

  const handleAddImage = (link: string) => {
    block.setCustomPreviewLink(link);
    popoverRef.current?.setState({ isOpen: false });
    setIsUploadingImage(false);
  };

  if (!imageUrl) {
    return (
      <Popover
        ref={popoverRef}
        className="image-cell"
        position="bottom-right"
        popoverClassName="report-cover-change-button"
        content={<AttachmentPopup workspaceId={workspaceId} hideRemove onLinkChange={handleAddImage} />}
      >
        <Button className="image-cell--add" minimal icon="add" e2eIdentifiers="add-image" />
      </Popover>
    );
  }

  const renderTooltipContent = () => {
    return (
      <ImageUploaderCard
        workspaceId={workspaceId}
        src={imageUrl}
        imageClassName="image-cell--image"
        onUploadStart={() => setIsUploadingImage(true)}
        onSrcChange={handleAddImage}
      />
    );
  };

  return (
    <Tooltip
      className="image-cell"
      popoverClassName="image-cell--popover"
      content={renderTooltipContent()}
      interactionKind="hover"
      position="right"
      hoverCloseDelay={100}
      isOpen={isImageTooltipOpen || isUploadingImage}
      onInteraction={setIsImageTooltipOpen}
      minimal
    >
      <BlueprintIcon icon="media" size={20} />
    </Tooltip>
  );
};

export default observer(ImageCell);
