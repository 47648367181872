import { JSX, KeyboardEvent, ReactNode } from "react";
import { ChangeEvent } from "react";
import { H6, InputGroup } from "@blueprintjs/core";
import { observer } from "mobx-react-lite";

import { Popover } from "@components/Popover";
import { ENTER_KEY } from "@constants/keys";

import "./AddNewDefinitionPopover.scss";

interface IAddNewDefinitionPopoverProps {
  value: string;
  isOpen: boolean;
  children: ReactNode;
  onChange(label: string): void;
  onClose(): void;
  onConfirm(): void;
}

const AddNewDefinitionPopover = (props: IAddNewDefinitionPopoverProps): JSX.Element => {
  const onKeyUp = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === ENTER_KEY && props.value) {
      props.onConfirm();
      props.onChange("");
      props.onClose();
    }
  };

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    props.onChange(e.target.value);
  };

  return (
    <Popover
      canEscapeKeyClose
      onClose={props.onClose}
      content={
        <div className="add-new-popover-content">
          <H6>Name:</H6>
          <InputGroup autoFocus placeholder="Name" value={props.value} onKeyUp={onKeyUp} onChange={onChange} />
        </div>
      }
      isOpen={props.isOpen}
      placement="bottom-start"
    >
      {props.children}
    </Popover>
  );
};

export default observer(AddNewDefinitionPopover);
