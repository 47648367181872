import { observer } from "mobx-react";

import CommentThread, { CommentThreadDisplayStyle } from "@components/Modeling/ModelingFrame/ModelBlock/Comments/CommentThread";
import ShowMoreButton from "@components/Modeling/ModelingFrame/ModelBlock/Comments/ShowMoreButton";
import { CommentLocationType } from "@components/Modeling/ModelingFrame/ModelBlock/Comments/utils";
import { TemporalDirection } from "@rollup-api/models/comments/commentGetThreadedRequestDto.model";
import { SortOrders } from "@rollup-api/SortOrders";
import { ICommentThreadList } from "@store/CommentThreadListStore";
import { ICommentThread } from "@store/CommentThreadStore";

import "./CommentThreadList.scss";

interface CommentThreadListProps {
  commentThreadList: ICommentThreadList;
  showOlderThreads?: boolean;
  showNewerThreads?: boolean;
  sortOrder?: SortOrders;
  type: CommentLocationType;
  parentId: string;
  displayStyle: CommentThreadDisplayStyle;
  onChange?: () => void;
}

const CommentThreadList = ({
  commentThreadList,
  showOlderThreads,
  showNewerThreads,
  sortOrder = SortOrders.ASC,
  type,
  parentId,
  displayStyle,
  onChange,
}: CommentThreadListProps) => {
  const handleShowPreviousThreads = () => {
    commentThreadList.fetchThreadList({ initialFetch: false, temporalDirection: TemporalDirection.Older, type, parentId });
  };

  const handleShowNextThreads = () => {
    commentThreadList.fetchThreadList({ initialFetch: false, temporalDirection: TemporalDirection.Newer, type, parentId });
  };

  const showOlderThreadsSection = () => {
    if (!showOlderThreads) {
      return null;
    }

    return (
      <ShowMoreButton
        remainingNextSetOfCommenters={commentThreadList.olderNextSetOfParentCommenters}
        remainingCommentsCount={commentThreadList.olderParentCommentsCount}
        temporalDirection={TemporalDirection.Older}
        onShowMore={handleShowPreviousThreads}
      />
    );
  };

  const showNewerThreadsSection = () => {
    if (!showNewerThreads) {
      return null;
    }

    return (
      <ShowMoreButton
        remainingNextSetOfCommenters={commentThreadList.newerNextSetOfParentCommenters}
        remainingCommentsCount={commentThreadList.newerParentCommentsCount}
        temporalDirection={TemporalDirection.Newer}
        onShowMore={handleShowNextThreads}
      />
    );
  };

  return (
    <>
      {sortOrder === SortOrders.ASC ? showOlderThreadsSection() : showNewerThreadsSection()}
      <div className="thread-list">
        {(sortOrder === SortOrders.ASC ? commentThreadList.threads : commentThreadList.threads.toReversed()).map((t: ICommentThread) => (
          <CommentThread key={t.parentComment?.id} thread={t} onChange={onChange} displayStyle={displayStyle} />
        ))}
      </div>
      {sortOrder === SortOrders.ASC ? showNewerThreadsSection() : showOlderThreadsSection()}
    </>
  );
};

export default observer(CommentThreadList);
