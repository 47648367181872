import { MouseEvent, ReactNode, useEffect, useMemo, useState } from "react";
import { Checkbox, Intent, Menu } from "@blueprintjs/core";
import { ItemListRendererProps, ItemPredicate, ItemRenderer, MultiSelect, Select } from "@blueprintjs/select";
import { observer } from "mobx-react";

import { Button } from "@components/Button";
import { MenuItem } from "@components/MenuItem";
import { LinkChannelDto, SlackChannel, SlackEventTypes, supportSlackEventTypes } from "@rollup-api/models";

import "./SlackSettingsAddNewLink.scss";

type Props = {
  link?: LinkChannelDto;
  channels: SlackChannel[];
  loading?: boolean;
  subscriptions: LinkChannelDto[];
  onCancel: () => void;
  onSave: (channelLink: LinkChannelDto) => void;
};

const SlackSettingsAddNewLink = (props: Props) => {
  const { onCancel, subscriptions, channels, link, loading, onSave } = props;
  const [channel, setChannel] = useState<SlackChannel>();
  const [entityTypes, setEntityTypes] = useState<SlackEventTypes[]>([]);

  useEffect(() => {
    setEntityTypes(link?.entityTypes || []);
    setChannel(channels.find(c => c.id === link?.id));
  }, [channels, link]);

  const filteredChannelList = useMemo(
    () => channels.filter(item => !subscriptions.find(sub => sub.id === item.id)).sort((a, b) => (a.name > b.name ? 1 : -1)),
    [channels, subscriptions]
  );

  const handleSelectEvent = (entityType: SlackEventTypes) => {
    if (entityTypes.includes(entityType)) {
      setEntityTypes(entityTypes.filter(item => item !== entityType));
    } else {
      setEntityTypes([...entityTypes, entityType]);
    }
  };

  const handleSelectAll = (e: MouseEvent) => {
    e.stopPropagation();
    setEntityTypes(supportSlackEventTypes);
  };

  const handleDeselectAll = (e: MouseEvent) => {
    e.stopPropagation();
    setEntityTypes([]);
  };

  const handleSave = () => {
    if (channel && entityTypes.length) {
      onSave({ id: channel.id, entityTypes });
    }
  };

  const handleRenderItem: ItemRenderer<SlackChannel> = (slackChannel: SlackChannel, { modifiers, handleClick }) => {
    if (!modifiers.matchesPredicate) {
      return null;
    }

    return (
      <MenuItem
        onClick={handleClick}
        active={slackChannel.id === channel?.id}
        e2eIdentifiers="menu-item"
        key={slackChannel.id}
        roleStructure="listoption"
        text={`#${slackChannel.name}`}
      />
    );
  };

  const handleRenderEventItem: ItemRenderer<SlackEventTypes> = (item: SlackEventTypes, { handleClick }) => (
    <MenuItem
      key={item}
      e2eIdentifiers="select-event"
      text={item}
      onClick={handleClick}
      leftElement={
        <Checkbox className="slack-settings-add-new-link--menu-item-checkbox" onClick={handleClick} checked={entityTypes.includes(item)} />
      }
    />
  );

  const renderRenderEventList = (params: ItemListRendererProps<SlackEventTypes>) => {
    const { renderItem } = params;
    const areAllSelected = entityTypes.length === supportSlackEventTypes.length;
    const isNoneSelected = entityTypes.length === 0;

    return (
      <Menu>
        <MenuItem
          e2eIdentifiers="select-all"
          text={areAllSelected ? "Deselect all" : "Select all"}
          onClick={areAllSelected ? handleDeselectAll : handleSelectAll}
          leftElement={
            <Checkbox
              className="slack-settings-add-new-link--menu-item-checkbox"
              indeterminate={!areAllSelected && !isNoneSelected}
              onClick={areAllSelected ? handleDeselectAll : handleSelectAll}
              checked={entityTypes.length === supportSlackEventTypes.length}
            />
          }
        />
        {params.items.map(renderItem)}
      </Menu>
    );
  };

  const handleRenderTag = (type: SlackEventTypes) => type;

  const handleTagRemove = (_node: ReactNode, index: number) => {
    setEntityTypes(entityTypes.filter((_item, i) => i !== index));
  };

  const filterItems: ItemPredicate<SlackChannel> = (query, item: SlackChannel, _index, exactMatch) => {
    const normalizedTitle = item.name.toLowerCase();
    const normalizedQuery = query.toLowerCase();

    if (exactMatch) {
      return normalizedTitle === normalizedQuery;
    } else {
      return normalizedTitle.indexOf(normalizedQuery) >= 0;
    }
  };

  const filterTypes = (query: string, item: SlackEventTypes) => item.toLowerCase().includes(query.toLowerCase());

  return (
    <div className="slack-integration--add-new">
      <Select<SlackChannel>
        onItemSelect={setChannel}
        itemPredicate={filterItems}
        className="slack-integration--select"
        disabled={loading}
        noResults={<MenuItem e2eIdentifiers="no-results" disabled text="No results" roleStructure="listoption" />}
        items={filteredChannelList}
        popoverProps={{ minimal: true, matchTargetWidth: true, popoverClassName: "slack-integration--select-popover" }}
        itemRenderer={handleRenderItem}
      >
        <Button
          disabled={!!link}
          alignText="left"
          fill
          e2eIdentifiers="button"
          text={channel ? `#${channel.name}` : "Select channel"}
          rightIcon="double-caret-vertical"
        />
      </Select>
      <MultiSelect<SlackEventTypes>
        fill
        className="slack-integration--event-select"
        onItemSelect={handleSelectEvent}
        items={supportSlackEventTypes}
        itemPredicate={filterTypes}
        noResults={<MenuItem e2eIdentifiers="no-results" disabled text="No results." roleStructure="listoption" />}
        popoverProps={{ minimal: true, matchTargetWidth: true }}
        itemRenderer={handleRenderEventItem}
        itemListRenderer={renderRenderEventList}
        selectedItems={entityTypes}
        tagRenderer={handleRenderTag}
        tagInputProps={{
          placeholder: "Select events",
          tagProps: { className: "slack-integration--tag" },
          onRemove: handleTagRemove,
        }}
      />
      <Button minimal outlined className="slack-integration--btn" onClick={onCancel} e2eIdentifiers="cancel" text="Cancel" />
      <Button
        className="slack-integration--btn"
        onClick={handleSave}
        disabled={!channel || !entityTypes.length}
        e2eIdentifiers="save"
        text="Save"
        intent={Intent.PRIMARY}
      />
    </div>
  );
};

export default observer(SlackSettingsAddNewLink);
