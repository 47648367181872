import { CreateCatalogItemVersionDto, UpdateCatalogItemVersionDto } from "@rollup-api/models/catalogItem/catalogItemDtos";
import { CatalogItemVersion } from "@rollup-api/models/catalogItem/catalogItemVersion.model";
import { getFormDataFromObject } from "@utilities/FormData";

import { HttpClient, ParentClient } from "./client";

export class CatalogItemVersions extends HttpClient {
  public constructor(parent: ParentClient) {
    super(parent);
  }

  private readonly endpoint = "/catalog-item-version";

  public retrieveList = async (catalogItemId?: string): Promise<CatalogItemVersion[]> => {
    try {
      const res = await this.instance.post<CatalogItemVersion[]>(`${this.endpoint}/list`, { catalogItemId });
      return res.data;
    } catch (err) {
      console.error(err);
      return [];
    }
  };

  public update = async (
    versionId: string,
    updateCatalogItemVersionDto: UpdateCatalogItemVersionDto
  ): Promise<CatalogItemVersion | undefined> => {
    try {
      const data = getFormDataFromObject({ ...updateCatalogItemVersionDto, leadTime: updateCatalogItemVersionDto.leadTime || undefined });
      const res = await this.instance.patch<CatalogItemVersion>(`${this.endpoint}/${versionId}`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      return res.data;
    } catch (err) {
      console.error(err);
      return;
    }
  };

  public create = async (createCatalogItemVersionDto: CreateCatalogItemVersionDto): Promise<CatalogItemVersion | undefined> => {
    try {
      const data = getFormDataFromObject({ ...createCatalogItemVersionDto, leadTime: createCatalogItemVersionDto.leadTime || undefined });
      const res = await this.instance.post<CatalogItemVersion>(this.endpoint, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      return res.data;
    } catch (err) {
      console.error(err);
      return;
    }
  };
}
