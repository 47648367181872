import { HttpClient, ParentClient } from "@rollup-api/api";
import { Invoice } from "@rollup-api/models/invoices/invoice.model";

export class Invoices extends HttpClient {
  public constructor(parent: ParentClient) {
    super(parent);
  }

  private get endpoint() {
    return "invoices";
  }

  public getAll = () => {
    return this.instance.get<Invoice[]>(`${this.endpoint}`);
  };
}
