import { Classes, Tooltip } from "@blueprintjs/core";
import classNames from "classnames";
import { observer } from "mobx-react";

import { useAppNavigate } from "@router/hooks";
import appStore from "@store/AppStore";
import { EntityType } from "@store/types";
import { getBlockById, getCodeBlockById, getPropertyInstanceById, getRandomHexColor } from "@utilities";
import { PresenceState } from "src/services";

import styles from "./PresenceAvatar.module.scss";

export interface IViewer {
  clientId: string;
  id: string;
  displayName: string;
  entityId: string;
  entityType: EntityType;
  state: PresenceState;
  picture?: string | null;
}

interface IPresenceAvatarProps {
  viewer: IViewer;
}

export const PresenceAvatar = observer(function PresenceAvatar({ viewer }: IPresenceAvatarProps) {
  const property =
    viewer.entityType === EntityType.PropertyInstance && viewer.entityId ? getPropertyInstanceById(viewer.entityId) : undefined;
  const block = viewer.entityType === EntityType.Block && viewer.entityId ? getBlockById(viewer.entityId) : undefined;
  const codeBlock = viewer.entityType === EntityType.CodeBlock && viewer.entityId ? getCodeBlockById(viewer.entityId) : undefined;
  const hashedColor = getRandomHexColor(undefined, viewer.clientId);
  const { navigateToBlock, navigateToCodeBlock } = useAppNavigate();

  const handleClick = () => {
    // TODO: handle navigating to properties rather than just parent blocks
    if (property) {
      navigateToBlock(property.parentBlockId);
    } else if (block) {
      navigateToBlock(block.id);
    } else if (codeBlock) {
      navigateToCodeBlock(codeBlock.id);
    }
  };

  return (
    <Tooltip
      key={viewer.clientId}
      placement="bottom-end"
      hoverOpenDelay={500}
      content={
        <span>
          {viewer.displayName}
          <i>
            <br />
            {property && <small>Viewing {property.path ?? "Unknown property"}</small>}
            {block && <small>Viewing {block.path ?? "Unknown block"}</small>}
            {codeBlock && <small>Viewing {codeBlock.label ?? "Unknown code block"}</small>}
          </i>
        </span>
      }
    >
      <div className={classNames(styles.presenceAvatar, { [Classes.DARK]: appStore.env.themeIsDark })} onClick={handleClick}>
        {viewer.picture ? (
          <img
            referrerPolicy="no-referrer"
            className={styles.presenceAvatarContent}
            style={{ borderColor: hashedColor }}
            src={viewer.picture ?? ""}
            alt={viewer.displayName}
          />
        ) : (
          <div
            className={classNames(styles.presenceAvatarContent, styles.avatarPlaceholder)}
            style={{ backgroundColor: hashedColor, borderColor: "white" }}
          >
            <span>{viewer.displayName?.[0] ?? "?"}</span>
          </div>
        )}
      </div>
    </Tooltip>
  );
});
