import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ContextMenu } from "@blueprintjs/core";
import classNames from "classnames";
import { observer } from "mobx-react";

import { DocumentNameInput } from "@components/DocumentNameInput";
import { FavoriteButton } from "@components/FavoriteButton";
import MoreOptionsMenu from "@components/MoreOptionsMenu/MoreOptionsMenu";
import RequirementsSidebarContextMenu from "@components/Requirements/RequirementsSidebar/RequirementsSidebarContextMenu";
import NavLinkAsMenuItem from "@router/navigation/NavLinkAsMenuItem";
import appStore from "@store/AppStore";
import { IRequirementsPage } from "@store/RequirementsStore";
import { EntityType } from "@store/types";
import { closePopover } from "@utilities/DomUtils";
import { getDocumentIcon } from "@utilities/Icons";

import styles from "./RequirementsSidebarItem.module.scss";

interface IRequirementsSidebarItemProps {
  reqPage: IRequirementsPage;
  minimal?: boolean;
}

const RequirementsSidebarItem = (props: IRequirementsSidebarItemProps) => {
  const { reqPage, minimal } = props;
  const [isEditing, setIsEditing] = useState(false);
  const navigate = useNavigate();
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  const openRequirementsPage = (reportId: string) => {
    navigate(reportId);
  };

  const renderRightElements = (className: string) => {
    return (
      <div className={classNames(className, { [styles.requirementsSidebarItemPopoverOpen]: isPopoverOpen })}>
        <FavoriteButton entityId={reqPage.id} entityType={EntityType.RequirementsDocument} />
        <MoreOptionsMenu
          content={<RequirementsSidebarContextMenu onEnter={() => setIsPopoverOpen(false)} reqPage={reqPage} />}
          popoverProps={{ isOpen: isPopoverOpen, onInteraction: setIsPopoverOpen, placement: "bottom-end" }}
          e2eIdentifiers={reqPage.label}
          small
        />
      </div>
    );
  };

  const renderContent = () => {
    if (isEditing) {
      return (
        <DocumentNameInput
          className="requirements-sidebar--document-name-input"
          inputClassName="requirements-sidebar--document-name-input-group"
          iconClassName={styles.requirementsSidebarItemIcon}
          icon={getDocumentIcon(EntityType.RequirementsDocument)}
          moreNav={minimal ? undefined : <RequirementsSidebarContextMenu onEnter={() => setIsPopoverOpen(false)} reqPage={reqPage} />}
          defaultValue={reqPage.label}
          onUpdate={reqPage.setLabel}
          onEnter={() => setIsEditing(false)}
          onEscape={() => setIsEditing(false)}
          onBlur={() => setIsEditing(false)}
          inputGroupProps={{ autoFocus: true }}
        />
      );
    }

    return (
      <NavLinkAsMenuItem
        className="requirements-sidebar--nav-link"
        textClassName={styles.requirementsSidebarItemLabel}
        to={reqPage.id}
        text={minimal ? undefined : reqPage.label}
        icon={getDocumentIcon(EntityType.RequirementsDocument)}
        selected={appStore.env.activeRequirementsDocumentId === reqPage.id}
        e2eIdentifiers={["requirements_sidebar", reqPage.label]}
        renderRightElements={minimal ? undefined : renderRightElements}
      />
    );
  };

  return (
    <ContextMenu
      key={reqPage.id}
      onContextMenu={() => openRequirementsPage(reqPage.id)}
      content={<RequirementsSidebarContextMenu onEnter={closePopover} reqPage={reqPage} />}
      onDoubleClick={() => setIsEditing(true)}
    >
      {renderContent()}
    </ContextMenu>
  );
};

export default observer(RequirementsSidebarItem);
