import { useMemo } from "react";
import { Handle, NodeProps, Position } from "reactflow";
import { Tooltip } from "@blueprintjs/core";
import { useWorkspace } from "@hooks/useWorkspace";
import { DataLink } from "@rollup-io/engineering";
import classNames from "classnames";
import { observer } from "mobx-react";

import { GoogleSheetsIcon } from "@assets/icons/thirdParty";
import CustomIcon from "@components/Shared/LegacyCustomIcon";
import { joinStrings } from "@utilities";

import { CustomNodeType } from "../types";

import "./DataLinkNode.scss";

/** Type defs. */
type DataLinkNodeData = {
  type: CustomNodeType;
  id: string;
  dataLink: DataLink;
  active: boolean;
};
type DataLinkNodeProps = NodeProps<DataLinkNodeData>;

/** Main function. */
function DataLinkNode(props: DataLinkNodeProps) {
  const {
    targetPosition,
    sourcePosition,
    data: { dataLink, type, active },
  } = props;

  const workspace = useWorkspace();

  const dataSource = useMemo(
    () => workspace.dataConnection.dataSourceMap.get(dataLink.dataSourceId),
    [dataLink?.dataSourceId, workspace.dataConnection.dataSourceMap]
  );

  return (
    <div className={classNames("data-link-node", { active })}>
      {type !== CustomNodeType.INPUT && <Handle type="target" position={targetPosition || Position.Left} className="invisible" />}
      <div className="container">
        <CustomIcon large icon={<GoogleSheetsIcon />} />
        <div className="content">
          <Tooltip content={dataSource?.description} disabled={!dataSource?.description} placement="top">
            <div className="subtitle">{dataSource?.label ?? "Unknown"}</div>
          </Tooltip>
          <div className="content--title">
            <span className="content--title--label">{dataLink.query}</span> <span>&nbsp;=&nbsp;</span>
            <span className="content--title--value">{joinStrings(dataLink.value, " ")}</span>
          </div>
        </div>
      </div>
      {type !== CustomNodeType.OUTPUT && <Handle type="source" position={sourcePosition || Position.Right} className="invisible" />}
    </div>
  );
}

/** Exports. */
export type { DataLinkNodeData, DataLinkNodeProps };
export default observer(DataLinkNode);
