import { RefObject } from "react";
import { Checkbox, Tooltip } from "@blueprintjs/core";
import classNames from "classnames";
import { observer } from "mobx-react";
import { useMap } from "usehooks-ts";

import { AnchorButton } from "@components/AnchorButton";
import SidePanelBackButton from "@components/Modeling/ModelingFrame/SidePanelBackButton";
import { useAppNavigate } from "@router/hooks";
import { IAttachment } from "@store/AttachmentStore";
import { PcbLayer } from "@utilities/BoardHelpers";

import { LayerState } from "./types";

import "./BoardViewerLayerControls.scss";

type IMap<K, V> = Omit<Map<K, V>, "set" | "clear" | "delete">;
type UseMapReturn<K, V> = ReturnType<typeof useMap<K, V>>;
type UseMapActions<K, V> = UseMapReturn<K, V>[1];

interface IBoardViewerLayerControlsProps {
  attachment: IAttachment;
  mirrored: boolean;
  layerData: PcbLayer[];
  layerStatusMap: IMap<number, LayerState>;
  layerVisibilityMap: IMap<number, boolean>;
  visibilityActions: UseMapActions<number, boolean>;
  hoveredLayer: PcbLayer | null;
  imgRef: RefObject<Map<number, HTMLImageElement>>;
  setMirrored(value: boolean): void;
  setHoveredLayer(layer: PcbLayer | null): void;
  onGoBack?(): void;
}

const BoardViewerLayerControls = (props: IBoardViewerLayerControlsProps) => {
  const { mirrored, layerData, layerStatusMap, layerVisibilityMap } = props;
  const { attachment, visibilityActions, hoveredLayer, imgRef } = props;
  const { setMirrored, setHoveredLayer, onGoBack } = props;
  const { navigateToBlock } = useAppNavigate();

  const getIsolatedLayer = () => {
    let firstVisibleLayer: number | undefined;
    for (const [ordinal, visible] of layerVisibilityMap) {
      if (visible) {
        if (firstVisibleLayer === undefined) {
          firstVisibleLayer = ordinal;
        } else {
          return undefined;
        }
      }
    }
    return firstVisibleLayer;
  };

  const handleLayerToggle = (layer: PcbLayer) => {
    const existingVisibility = layerVisibilityMap.get(layer.ordinal);
    visibilityActions.set(layer.ordinal, !existingVisibility);
  };

  const toggleLayerIsolate = (layer: PcbLayer) => {
    // Check if layer is already isolated (i.e. visible and no others are visible)
    const isLayerIsolated = getIsolatedLayer() === layer.ordinal;
    if (isLayerIsolated) {
      visibilityActions.setAll(layerData.map(l => [l.ordinal, true]));
      setHoveredLayer(null);
    } else {
      visibilityActions.setAll(layerData.map(l => [l.ordinal, l.ordinal === layer.ordinal]));
    }
  };

  const handleGoBack = () => {
    navigateToBlock(attachment.block.id);
  };

  return (
    <div className="board-viewer-layer-controls">
      <SidePanelBackButton
        className="board-viewer-layer-controls--back-button"
        label={attachment.label}
        onClick={onGoBack ?? handleGoBack}
      />
      <div className="board-viewer-layer-controls--layers-container">
        <div className="render-controls">
          <Checkbox large checked={mirrored} onChange={() => setMirrored(!mirrored)} label="Mirrored" />
        </div>
        <div className="layer-controls">
          {layerData.map(layer => {
            const status = layerStatusMap.get(layer.ordinal);
            const visible = layerVisibilityMap.get(layer.ordinal);
            return (
              <div key={layer.ordinal} onMouseEnter={() => setHoveredLayer(layer)} onMouseLeave={() => setHoveredLayer(null)}>
                <Checkbox
                  large
                  disabled={(visible && status === LayerState.Loading) || status === LayerState.Error}
                  checked={visible}
                  className={classNames("layer-checkbox", { hovered: hoveredLayer === layer })}
                  onChange={() => handleLayerToggle(layer)}
                >
                  <Tooltip content={layer.ordinal !== getIsolatedLayer() ? "Isolate layer" : "Show all"} hoverOpenDelay={500}>
                    <AnchorButton
                      minimal
                      small
                      onClick={ev => {
                        ev.stopPropagation();
                        ev.preventDefault();
                        toggleLayerIsolate(layer);
                      }}
                      e2eIdentifiers="toggle-layer-isolate"
                    >
                      {layer.name}
                    </AnchorButton>
                  </Tooltip>
                </Checkbox>
              </div>
            );
          })}
          <p>{imgRef.current?.size} layers</p>
        </div>
      </div>
    </div>
  );
};

export default observer(BoardViewerLayerControls);
