import { useState } from "react";
import classNames from "classnames";

import "./ImageGallery.scss";

type Props = {
  images: string[];
  className?: string;
};

const ImageGallery = (props: Props) => {
  const { images, className } = props;
  const [activeImg, setActiveImg] = useState(images[0] || "");

  return (
    <div className={classNames("image-gallery", className)}>
      <div className="image-gallery--main" style={{ backgroundImage: `url("${import.meta.env.VITE_STRAPI_API_URL}${activeImg}")` }} />
      {images.length > 1 && (
        <div className="image-gallery--sidebar">
          {images.map(src => (
            <div
              key={src}
              style={{ backgroundImage: `url("${import.meta.env.VITE_STRAPI_API_URL}${src}")` }}
              className={classNames("image-gallery--preview", { ["image-gallery--preview-active"]: src === activeImg })}
              onMouseUp={() => setActiveImg(src)}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default ImageGallery;
