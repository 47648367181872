import { Text, TextVariant } from "@ui/Text";
import { ICellRendererParams } from "ag-grid-community";
import { observer } from "mobx-react";

import "./GroupRowInnerRenderer.scss";

interface IGroupRowInnerRendererProps extends ICellRendererParams {}

const GroupRowInnerRenderer = (props: IGroupRowInnerRendererProps) => {
  const {
    value,
    node: { rowGroupColumn },
  } = props;

  return (
    <div className="group-row-inner-renderer">
      {rowGroupColumn && <Text variant={TextVariant.Caption}>{rowGroupColumn.getColDef().headerName} </Text>}
      <Text variant={TextVariant.H4}>{value}</Text>
    </div>
  );
};

export default observer(GroupRowInnerRenderer);
