import { useEffect, useState } from "react";
import { Callout, HTMLTable, Icon, InputGroup, Menu } from "@blueprintjs/core";
import { observer } from "mobx-react";
import { useDebounceValue } from "usehooks-ts";

import { AnchorButton } from "@components/AnchorButton";
import { Button } from "@components/Button";
import { DeleteConfirmationDialog } from "@components/DeleteConfirmationDialog";
import { MenuItem } from "@components/MenuItem";
import { MenuItemDelete } from "@components/MenuItems";
import { Popover } from "@components/Popover";
import { UserPermission } from "@rollup-api/api/authTypes";
import { ExtendedOauthAppModel } from "@rollup-api/models/oauthApp";
import { OauthAppSortKeys } from "@rollup-api/models/oauthApp/OauthAppSortKeys";
import { GetOauthAppsRequestDto } from "@rollup-api/models/oauthApp/oauthAppsRequestDto.model";
import { GetOauthAppsResponseDto } from "@rollup-api/models/oauthApp/oauthAppsResponseDto.model";
import { SortOrders } from "@rollup-api/SortOrders";
import appStore from "@store/AppStore";
import { convertTimestampToDate, getFormattedDate } from "@utilities";
import { rollupClient } from "src/core/api";
import { Text, TextVariant } from "src/ui/Text";

import SettingsAddNewOAuthApplication from "./SettingsAddNewOAuthApplication";

import "./SettingsOAuthApplications.scss";

/** Main function. */
const SettingsOAuthApplications = () => {
  const [paginatedOauthApps, setPaginatedOauthApps] = useState<ExtendedOauthAppModel[]>();
  const [searchTerm, setSearchTerm] = useState("");
  const [debouncedSearchTerm] = useDebounceValue(searchTerm, 400);
  const [isNewOAuthApplicationScreenOpen, setIsNewOAuthApplicationScreenOpen] = useState(false);
  const [existingAppId, setExistingAppId] = useState<string | undefined>(undefined);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [itemToDelete, setItemToDelete] = useState<ExtendedOauthAppModel>();

  const fetchOauthApps = async (take: number, skip: number, sortedBy: OauthAppSortKeys, sortOrder: SortOrders, sTerm?: string) => {
    const dto: GetOauthAppsRequestDto = {
      take,
      skip,
      sortedBy,
      sortOrder,
      search: sTerm || undefined,
    };
    const oauthAppsResponse: GetOauthAppsResponseDto | undefined = await rollupClient.oauthApp.retrieveList(dto);
    if (oauthAppsResponse) {
      setPaginatedOauthApps(oauthAppsResponse.oauthConnections);
    }
  };

  useEffect(() => {
    if (!isNewOAuthApplicationScreenOpen) {
      fetchOauthApps(1000, 0, OauthAppSortKeys.createdAt, SortOrders.DESC, searchTerm);
    }
  }, [isNewOAuthApplicationScreenOpen, searchTerm]);

  useEffect(() => {
    fetchOauthApps(1000, 0, OauthAppSortKeys.createdAt, SortOrders.DESC, debouncedSearchTerm);
  }, [debouncedSearchTerm]);

  const renderOAuthApplicationList = () => {
    return (
      <>
        <DeleteConfirmationDialog
          isOpen={isDeleteDialogOpen}
          titleItem="OAuth application"
          description="Are you sure you want to delete this OAuth application? This action cannot be undone."
          onCancel={() => setIsDeleteDialogOpen(false)}
          onClose={() => setIsDeleteDialogOpen(false)}
          onConfirm={async () => {
            if (itemToDelete) {
              await rollupClient.oauthApp.delete(itemToDelete.id);
              fetchOauthApps(1000, 0, OauthAppSortKeys.createdAt, SortOrders.DESC, searchTerm);
            }
            setIsDeleteDialogOpen(false);
          }}
        />
        <HTMLTable className="min-w-full">
          <thead>
            <tr>
              <th>
                <Text variant={TextVariant.H5}>Logo</Text>
              </th>
              <th>
                <Text variant={TextVariant.H5}>Application name</Text>
              </th>
              <th>
                <Text variant={TextVariant.H5}>Status</Text>
              </th>
              <th>
                <Text variant={TextVariant.H5}>Client ID</Text>
              </th>
              <th>
                <Text variant={TextVariant.H5}>Developer name</Text>
              </th>
              <th>
                <Text variant={TextVariant.H5}>Developer URL</Text>
              </th>
              <th>
                <Text variant={TextVariant.H5}>Creation date</Text>
              </th>
              <th></th>
            </tr>
          </thead>

          <tbody>
            {!paginatedOauthApps?.length ? (
              <tr>
                <td colSpan={8} className="message">
                  <Text variant={TextVariant.Caption}>You don't have any OAuth applications meeting the filter criteria</Text>
                </td>
              </tr>
            ) : (
              paginatedOauthApps?.map((oAuthApp: ExtendedOauthAppModel) => (
                <tr key={oAuthApp.id}>
                  <td>{oAuthApp.logoUrl && <img loading="lazy" src={oAuthApp.logoUrl} width={40} height={40} />}</td>
                  <td>
                    <Text variant={TextVariant.H3}>{oAuthApp.applicationName}</Text>
                  </td>
                  <td>
                    <Text variant={TextVariant.Body}>(status here)</Text>
                  </td>
                  <td>
                    <Text variant={TextVariant.Body}>{oAuthApp.clientId}</Text>
                  </td>
                  <td>
                    <Text variant={TextVariant.Body}>{oAuthApp.developerName}</Text>
                  </td>
                  <td>
                    <Text variant={TextVariant.Body}>
                      {oAuthApp.developerUrl && (
                        <a className="oauth-app-list--link" href={oAuthApp.developerUrl} target="_blank" rel="noreferrer">
                          Visit URL
                        </a>
                      )}
                    </Text>
                  </td>
                  <td>
                    <Text variant={TextVariant.Body}>{getFormattedDate(convertTimestampToDate(oAuthApp.createdAt))}</Text>
                  </td>
                  <td>
                    <Popover
                      placement="bottom-end"
                      content={
                        <Menu>
                          <MenuItem
                            text="Edit"
                            onClick={() => {
                              setExistingAppId(oAuthApp.id);
                              setIsNewOAuthApplicationScreenOpen(true);
                            }}
                            e2eIdentifiers="edit"
                          />
                          <MenuItemDelete
                            onDelete={() => {
                              setItemToDelete(oAuthApp);
                              setIsDeleteDialogOpen(true);
                            }}
                          />
                        </Menu>
                      }
                    >
                      <AnchorButton minimal rightIcon="more" e2eIdentifiers="show-menu" />
                    </Popover>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </HTMLTable>
      </>
    );
  };

  const renderOAuthApplicationSettings = () => {
    return (
      <div className="settings-layout--content">
        <div className="settings-layout--header">
          <Text variant={TextVariant.H1}>OAuth applications</Text>
          <div className="settings-layout--header--right-section">
            <InputGroup
              className="settings-layout--search"
              large
              value={searchTerm}
              onChange={e => setSearchTerm(e.target.value)}
              placeholder="Search for OAuth application..."
              leftElement={<Icon icon="search" />}
            />
            <Button
              large
              intent="primary"
              icon="plus"
              onClick={() => {
                setExistingAppId(undefined);
                setIsNewOAuthApplicationScreenOpen(true);
              }}
              className="settings-layout--cta"
              disabled={!appStore.userModel?.hasPermission(UserPermission.InviteUsers)}
              e2eIdentifiers="add-new-oauth-application"
            >
              Create OAuth Application
            </Button>
          </div>
        </div>
        <Callout icon="info-sign">
          These are OAuth applications that have been created by your organization for internal and/or external use. If you'd like to see a
          list of applications authorized to access your organization's data, please navigate to the authorized connections page.
        </Callout>
        <div className="oauth-applications-area">{renderOAuthApplicationList()}</div>
      </div>
    );
  };

  const renderAddNewOAuthApplication = () => {
    return (
      <SettingsAddNewOAuthApplication
        oAuthAppId={existingAppId}
        onClose={() => {
          setExistingAppId(undefined);
          setIsNewOAuthApplicationScreenOpen(false);
        }}
      />
    );
  };

  return <>{isNewOAuthApplicationScreenOpen ? renderAddNewOAuthApplication() : renderOAuthApplicationSettings()}</>;
};

/** Exports. */
export default observer(SettingsOAuthApplications);
