import { Fragment, ReactNode } from "react";
import classNames from "classnames";
import { observer } from "mobx-react";

import { Loading } from "@components/Loading";
import StepButtons, { IStepButton } from "@components/Shared/ColumnMultiStep/StepButtons";
import { Text, TextVariant } from "src/ui/Text";

import "./ColumnMultiStep.scss";

export interface IStep<T extends string = string> {
  id: T;
  title: string;
  renderPanel(active: boolean): ReactNode;
}

interface IMultiColumnStepProps<T extends string = string> {
  stepIndex: number;
  steps: IStep<T>[];
  title: string;
  rightButton: IStepButton;
  leftButton?: IStepButton;
  className?: string;
  isLoading?: boolean;
}

const MultiColumnStepDialog = <T extends string = string>(props: IMultiColumnStepProps<T>) => {
  const { stepIndex, title, steps, className, leftButton, rightButton, isLoading } = props;

  const renderSingleStep = (step: IStep, index = 0) => {
    const isCurrentStep = stepIndex === index;
    const isPastStep = stepIndex > index;
    const shouldRenderStep = isPastStep || isCurrentStep;

    return (
      <div className="column-multi-step--step">
        <div className="column-multi-step--step-top-container">
          <div className={classNames("column-multi-step--step-title", { ["column-multi-step--step-title-inactive"]: stepIndex < index })}>
            {step.title}
          </div>
          {shouldRenderStep && step.renderPanel(isCurrentStep)}
          {isCurrentStep && isLoading && <Loading overlay />}
        </div>
        {isCurrentStep && <StepButtons leftButton={leftButton} rightButton={rightButton} />}
      </div>
    );
  };

  const renderSteps = () => {
    if (!steps) {
      return null;
    } else if (!Array.isArray(steps)) {
      return renderSingleStep(steps);
    }

    return steps.map((step, index) => {
      return <Fragment key={step.id}>{renderSingleStep(step, index)}</Fragment>;
    });
  };

  return (
    <div className={classNames("column-multi-step", className)}>
      <Text variant={TextVariant.H1}>{title}</Text>
      <div className="column-multi-step--steps-container">{renderSteps()}</div>
    </div>
  );
};

export default observer(MultiColumnStepDialog);
