import { Intent } from "@blueprintjs/core";
import {
  DependencyEdge,
  DependencyGraph,
  DependencyNode,
  fillDependencyGraph,
  PropertyDataType,
  separateGraph,
  toArray,
} from "@rollup-io/engineering";
import { RowDataTransaction } from "ag-grid-community";
import { AxiosResponse } from "axios";
import Fuse from "fuse.js";
import { DirectedGraph } from "graphology";
import { hasCycle } from "graphology-dag";
import assignIn from "lodash/assignIn";
import isNull from "lodash/isNull";
import omitBy from "lodash/omitBy";
import { action, observable } from "mobx";
import { cast, destroy, detach, flow, getSnapshot, IAnyModelType, Instance, SnapshotIn, SnapshotOut, types } from "mobx-state-tree";
import { Socket } from "socket.io-client";
import { v4 as uuidv4 } from "uuid";

import { NodeInfo } from "@components/Modeling/ModelingFrame/Table/TableComponent/types";
import {
  defaultEmptyConfigString,
  defaultTableViewConfigTabId,
  defaultTableViewLabel,
} from "@components/Modeling/ModelingFrame/Table/TableConfigTabs/constants";
import { blockNodeList } from "@components/Modeling/ModelingFrame/Table/utils";
import { reportNodeList } from "@components/ReportsTree/constants";
import { showApiErrorToast, showMiddleToast, showToast } from "@components/UiLayers/toaster";
import { TImportsListResponse } from "@rollup-api/api/imports";
import { CreateReportBlockDto, ImportInProgressStatuses, PropertyInstance, StatusInstance } from "@rollup-api/models";
import { Block, CreateBlockDto } from "@rollup-api/models/block";
import { BomTable, CreateBomColumnDto, CreateBomTableDto } from "@rollup-api/models/bom";
import { DataLink } from "@rollup-api/models/data-sources/data-link.model";
import { DataSource } from "@rollup-api/models/data-sources/data-source.model";
import { CreateImportDto, Import, ImportExtensionType, ImportStatus } from "@rollup-api/models/import";
import { InterfaceType } from "@rollup-api/models/interface";
import { RequirementBlockType } from "@rollup-api/models/requirementBlock";
import { TableViewConfig } from "@rollup-api/models/tableViewConfig";
import { WorkspaceUpdateDto } from "@rollup-api/models/workspaces/workspaceUpdateDto.model";
import { reorderReport, updateWorkspace } from "@rollup-api/utils";
import { RollupEditorType } from "@rollup-types/editor";
import { AnalysisModuleStore } from "@store/Analysis/AnalysisModuleStore";
import { AttachmentModuleStore } from "@store/AttachmentModuleStore";
import { IAttachment } from "@store/AttachmentStore";
import { AttributeStore, IAttribute } from "@store/AttributeStore";
import { DataConnectionModuleStore, IDataConnectionModuleMobxType } from "@store/DataConnection/DataConnectionModuleStore";
import { FavoriteModuleStore } from "@store/FavoriteModuleStore";
import { IInterface, InterfaceStore } from "@store/InterfaceStore";
import { PartNumberSchemaModuleStore } from "@store/PartNumberSchemaModuleStore";
import { IPropertyDefinition, IPropertyDefinitionMobxType, PropertyDefinitionStore } from "@store/PropertyDefinitionStore";
import { IPropertyGroup, PropertyGroupStore } from "@store/PropertyGroupStore";
import {
  IPropertyInstance,
  IPropertyInstanceMobxType,
  IPropertyInstanceSnapshotIn,
  PropertyInstanceStore,
} from "@store/PropertyInstanceStore";
import { RequirementsPageModuleStore } from "@store/RequirementsPageModuleStore";
import { IStatusDefinition, StatusDefinitionStore, StatusType } from "@store/StatusDefinitionStore";
import { IStatusInstance, IStatusInstanceMobxType, IStatusInstanceSnapshotIn, StatusInstanceStore } from "@store/StatusInstanceStore";
import { IStatusOption, StatusOptionStore } from "@store/StatusOptionStore";
import { EntityType, ICreateReportData } from "@store/types";
import {
  AutoCompleteEntry,
  BlockNode,
  convertTimestamp,
  fillHierarchicalGraph,
  getBlockById,
  getBomMetaColumns,
  getMockedTreeEntity,
  getNextOrderIndex,
  isHoopsType,
  moveItemInRefArray,
  moveOrderIndexedItem,
  parentWorkspace,
  validateBlockLabel,
} from "@utilities";
import { getPath } from "@utilities/Block";
import { getRandomHexColor } from "@utilities/Color";
import { getIncrementedName } from "@utilities/Name";
import version from "src/../package.json";
import { rollupClient } from "src/core/api";

import { IBomColumn } from "./BomTable/BomColumnStore";
import { BomTableLoadingStatus, BomTableStore, IBomTable, IBomTableMobxType } from "./BomTable/BomTableStore";
import appStore from "./AppStore";
import { BlockStore, BlockType, IBlock } from "./BlockStore";
import { IImport, IImportMobxType, IImportSnapshotIn, ImportStore } from "./ImportStore";
import { PmTagsStore } from "./PmTagsStore";
import { IReportBlock, IReportBlockMobxType, ReportBlockStore } from "./ReportBlockStore";
import { IReport, IReportMobxType, ReportStore } from "./ReportsStore";
import { IRequirementBlock, IRequirementsPage, isHeading, RequirementBlockStore } from "./RequirementsStore";
import { ITableViewConfig, ITableViewConfigMobxType, TableViewConfigStore } from "./TableViewConfigStore";
import { UserStore } from "./UserStore";
import { WorkspacePermissionsStore } from "./WorkspacePermissionsStore";

const MAX_RECENT_BLOCKS = 10;

export enum SavingStatus {
  saved = "saved",
  saving = "saving",
  saveFailed = "save-failed",
  neutral = "neutral",
}

export type LabeledEntity = {
  id?: string;
  label: string;
  description?: string;
  newEntity?: boolean;
  disabled?: boolean;
};

export enum ReturnStatus {
  ok = "ok",
  error = "error",
  neutral = "neutral",
}

class WorkspaceUiStore {
  @observable accessor currentAction: SavingStatus = SavingStatus.neutral;
  @observable accessor tableColumnWidths = new Map<string, number>();
  @observable accessor tableNameColumnWidth = 250;

  @action.bound
  public setCurrentAction(action: SavingStatus) {
    this.currentAction = action;
  }

  @action.bound
  public setTableColumnWidth(propLabel: string, width: number) {
    this.tableColumnWidths.set(propLabel, width);
  }

  @action.bound
  public setTableNameColumnWidth(width: number) {
    this.tableNameColumnWidth = width;
  }

  // Consolidate map when a property is renamed from table view
  @action.bound
  public replaceTableColumnKey(oldLabel: string, newLabel: string) {
    const oldEntry = this.tableColumnWidths.get(oldLabel);
    if (oldEntry) {
      this.tableColumnWidths.set(newLabel, oldEntry);
      this.tableColumnWidths.delete(oldLabel);
      console.debug(`Removed stale column width entry ${oldLabel} replaced with ${newLabel}`);
    }
  }
}

export const WorkspaceStore = types
  .model("Workspace", {
    id: types.identifier,
    label: types.string, // NOT OPTIONAL
    // TODO add proper typing and fix errors
    blockMap: types.map(types.late((): IAnyModelType => BlockStore)),
    rootBlock: types.safeReference(types.late((): IAnyModelType => BlockStore)),
    attachments: types.optional(AttachmentModuleStore, {}),
    favorites: types.optional(FavoriteModuleStore, {}),
    propertyDefinitionMap: types.map<IPropertyDefinitionMobxType>(PropertyDefinitionStore),
    propertyInstanceMap: types.map<IPropertyInstanceMobxType>(PropertyInstanceStore),
    partNumberSchemas: types.optional(PartNumberSchemaModuleStore, {}),
    requirementsPages: types.optional(RequirementsPageModuleStore, {}),
    analysis: types.optional(AnalysisModuleStore, {}),
    dataConnection: types.optional<IDataConnectionModuleMobxType>(DataConnectionModuleStore, {}),
    reportsMap: types.map<IReportMobxType>(types.late((): IAnyModelType => ReportStore)),
    rootReportIds: types.array(types.string),
    bomTablesMap: types.map<IBomTableMobxType>(types.late((): IAnyModelType => BomTableStore)),
    importsMap: types.map<IImportMobxType>(types.late((): IAnyModelType => ImportStore)),
    hasMoreImports: types.optional(types.boolean, false),
    reportBlocksMap: types.map<IReportBlockMobxType>(ReportBlockStore),
    requirementBlockMap: types.map(RequirementBlockStore),
    interfaceMap: types.map(InterfaceStore),
    attributeMap: types.map(AttributeStore),
    statusDefinitionMap: types.map(types.late((): IAnyModelType => StatusDefinitionStore)),
    statusInstanceMap: types.map<IStatusInstanceMobxType>(StatusInstanceStore),
    statusOptionMap: types.map(StatusOptionStore),
    updatedAt: types.optional(types.number, Date.now()), //
    createdAt: types.optional(types.number, Date.now()),
    createdBy: types.maybeNull(types.string),
    logs: types.maybeNull(types.array(types.string)), //
    owners: types.array(UserStore),
    members: types.array(UserStore),
    rollupVersion: types.optional(types.string, version.version),
    isPubliclyViewable: types.optional(types.boolean, false),
    lastExecutionRun: types.maybeNull(types.Date),
    tableViewConfigs: types.map<ITableViewConfigMobxType>(TableViewConfigStore),
    lastExecutionStatus: types.optional(types.enumeration([...Object.values(ReturnStatus)]), ReturnStatus.neutral),
    lastExecutionLogs: types.optional(types.string, ""),
    lastExecutionError: types.optional(types.string, ""),
    lastExecutionResult: types.optional(types.string, ""),
    isDisabled: types.optional(types.boolean, false),
    workspacePermissions: types.optional(
      WorkspacePermissionsStore,
      getSnapshot(
        WorkspacePermissionsStore.create({
          isPubliclyViewable: false,
          ownerID: "local",
          ownerType: "user",
        })
      )
    ),
    pmTags: types.optional(PmTagsStore, {}),
    recentBlockIds: types.array(types.string),
  })
  .volatile(() => ({
    ui: new WorkspaceUiStore(),
  }))
  .views(self => ({
    get blocks(): IBlock[] {
      return toArray<IBlock>(self.blockMap);
    },
    get imports() {
      return toArray<IImport>(self.importsMap);
    },
    get rootBlockId(): string {
      return self.rootBlock?.id as string;
    },
    get statusDefinitions() {
      const definitions = new Array<IStatusDefinition>();
      for (const [, definition] of self.statusDefinitionMap) {
        if (definition) {
          definitions.push(definition);
        }
      }
      return definitions.sort((a, b) => a.orderIndex - b.orderIndex);
    },
    get propertyDefinitions() {
      const definitions = new Array<IPropertyDefinition>();
      for (const [, definition] of self.propertyDefinitionMap) {
        if (definition) {
          definitions.push(definition);
        }
      }
      return definitions.sort((a, b) => a.orderIndex - b.orderIndex);
    },
    get reports(): IReport[] {
      return toArray<IReport>(self.reportsMap);
    },
    get reportBlocks(): IReportBlock[] {
      return toArray<IReportBlock>(self.reportBlocksMap);
    },
    get requirementBlocks(): IRequirementBlock[] {
      return toArray<IRequirementBlock>(self.requirementBlockMap);
    },
    get bomTables(): IBomTable[] {
      return toArray<IBomTable>(self.bomTablesMap);
    },
    getDocument(id: string, type: EntityType) {
      switch (type) {
        case EntityType.Report:
          return self.reportsMap.get(id);
        case EntityType.RequirementsDocument:
          return self.requirementsPages.get(id);
      }
    },
    getDataLink(sourceId: string, query: string): DataLink | undefined {
      return self.dataConnection.getDataLink(sourceId, query);
    },
    get rootReports(): IReport[] {
      return self.rootReportIds.reduce((reports: IReport[], id) => {
        const report = self.reportsMap.get(id);
        if (report && !report.isTemplate) {
          return reports.concat(report);
        }
        return reports;
      }, []);
    },
  }))
  .actions(() => ({
    applyAgGridTransaction(rowDataTransaction: RowDataTransaction) {
      appStore.env.tableViewGridApi?.applyTransaction(rowDataTransaction);
      appStore.env.blocksTreeGridApi?.applyTransaction(rowDataTransaction);
    },
    setAgGridRowData(data: Array<NodeInfo>) {
      appStore.env.tableViewGridApi?.setGridOption("rowData", data);
      appStore.env.blocksTreeGridApi?.setGridOption("rowData", data);
    },
    applyReportsAgGridTransaction(rowDataTransaction: RowDataTransaction) {
      appStore.env.reportsTreeGridApi?.applyTransaction(rowDataTransaction);
    },
  }))
  .actions(self => {
    const populateBlocks = (blocks: Block[]) => {
      // Add blocks
      for (const block of blocks) {
        if (block?.id) {
          self.blockMap.put(omitBy({ ...block, icon: block.iconData?.name === "cube" ? "" : block.iconData }, isNull));
        }
      }
      // Wire up parents after all blocks are added in order to get references correct
      for (const block of blocks) {
        if (block?.id && block?.parentBlock) {
          const parentBlock = self.blockMap.get(block.parentBlock);
          if (!parentBlock) {
            console.warn(`Parent block ${block.parentBlock} not found for block ${block.id}`);
            return false;
          }
          if (parentBlock.children) {
            parentBlock.children.push(block.id);
          } else {
            parentBlock.children = cast([block.id]);
          }
        }
      }
      return true;
    };

    const populatePropertyInstances = (propertyInstances: PropertyInstance[]) => {
      for (const prop of propertyInstances) {
        if (prop?.id && prop?.parentBlock && prop.propertyDefinition) {
          const parentBlock = self.blockMap.get(prop.parentBlock);
          if (!parentBlock) {
            console.warn(`Parent block ${prop.parentBlock} not found for property instance ${prop.id}`);
            return false;
          }

          const propertyDefinition = self.propertyDefinitionMap.get(prop.propertyDefinition);
          if (!propertyDefinition) {
            console.warn(`Definition ${prop.propertyDefinition} not found for property instance ${prop.id}`);
            return false;
          }

          self.propertyInstanceMap.put(omitBy(prop, isNull) as IPropertyInstanceSnapshotIn);
          // Append to block's reference list
          if (parentBlock.propertyInstances) {
            parentBlock.propertyInstances.push(prop.id);
          } else {
            parentBlock.propertyInstances = cast([prop.id]);
          }
        }
      }
      return true;
    };

    const populateStatusInstances = (statusInstances: StatusInstance[]) => {
      for (const stat of statusInstances) {
        if (stat?.id && stat?.block && stat.statusDefinition) {
          const parentBlock = self.blockMap.get(stat.block);
          if (!parentBlock) {
            console.warn(`Parent block ${stat.block} not found for status instance ${stat.id}`);
            return false;
          }

          const statusDefinition = self.statusDefinitionMap.get(stat.statusDefinition);
          if (!statusDefinition) {
            console.warn(`Definition ${stat.statusDefinition} not found for status instance ${stat.id}`);
            return false;
          }

          self.statusInstanceMap.put(omitBy(stat, isNull) as StatusInstance);
          // Append to block's reference list
          if (parentBlock.statusInstances) {
            parentBlock.statusInstances.push(stat.id);
          } else {
            parentBlock.statusInstances = cast([stat.id]);
          }
        }
      }
      return true;
    };

    return {
      patch(update: WorkspaceUpdateDto) {
        // Prevent updating of fixed properties
        const invalidFields = [
          "id",
          "blockMap",
          "propertyMap",
          "requirementsPageMap",
          "requirementBlockMap",
          "interfaceMap",
          "attributeMap",
          "attachmentMap",
          "statusDefinitionMap",
          "statusInstanceMap",
          "statusOptionMap",
          "dataSourceMap",
          "dataSourceLinkMap",
        ];
        const updateKeys = Object.keys(update);
        for (const field of invalidFields) {
          if (updateKeys.includes(field)) {
            return false;
          }
        }

        try {
          assignIn(self, update);
          if (update.label) {
            appStore.orgModel?.updateWorkspaceLabel(self.id, update.label);
          }
          return true;
        } catch (err) {
          console.warn(err);
          return false;
        }
      },
      // Update the label
      setLabel(label: string) {
        if (label == "") {
          self.label = "Untitled Workspace";
        } else {
          self.label = label;
        }
        updateWorkspace(self.id, { label });
        appStore.orgModel?.updateWorkspaceLabel(self.id, label);
      },
      deleteBlock(block: IBlock, notify = true) {
        if (!block || block === self.rootBlock || !self.blockMap.has(block.id)) {
          return false;
        }
        const { id, parentBlock } = block;

        // Shift active block to parent if deleting
        if (block === appStore.env.activeBlock) {
          appStore.env.setActiveBlockById(parentBlock?.id ?? appStore.workspaceModel?.rootBlockId);
        }

        // Remove block from hidden & selected blocks of BOM table
        toArray<IBomTable>(self.bomTablesMap).forEach(t => {
          t.selectedRows.includes(block.id) && t.toggleRowSelection(block.id);
        });

        // Recursively delete children
        for (const b of block.validatedChildren) {
          this.deleteBlock(b, false);
        }

        for (const p of block.validatedPropertyInstances) {
          this.deletePropertyInstance(p, false);
        }

        for (const i of block.validatedInterfaces) {
          this.deleteInterface(i);
        }

        if (notify) {
          rollupClient.modelingModule.blocks.delete(id).catch((err: Error) => {
            showApiErrorToast(`Error deleting block ${block.id}`, err as Error);
          });
        }

        self.applyAgGridTransaction({ remove: [{ path: getPath(block), block }] });
        self.blockMap.delete(id);
        destroy(block);
        return true;
      },
      reparentBlock: flow(function* reparentBlock(block: IBlock, newParent?: IBlock, notify = true): Generator<any, boolean, any> {
        // Can't reparent root blocks or undefined blocks
        if (!block || block === self.rootBlock) {
          return false;
        }

        // Avoid circular references
        if (block === newParent || block.parentBlock === newParent || newParent?.parentBlock === block) {
          return false;
        }

        // Ensure both blocks have the same workspace
        if (newParent && parentWorkspace(block) !== parentWorkspace(newParent)) {
          return false;
        }

        const existingParent = block.parentBlock as IBlock;
        if (existingParent?.children) {
          existingParent.children = cast(existingParent.children.filter(b => b !== block));
        }
        if (newParent) {
          if (newParent.children) {
            newParent.children.push(block);
          } else {
            newParent.children = cast([block.id]);
          }
        }
        block.parentBlock = newParent;

        if (notify) {
          try {
            const { status } = yield rollupClient.modelingModule.blocks.reparent(block.id, block.parentBlock?.id).catch((err: Error) => {
              showApiErrorToast("Error reparenting block", err);
            });
            return status === 200;
          } catch (e) {
            return false;
          }
        } else {
          self.setAgGridRowData(blockNodeList());
        }
        return true;
      }),
      moveRootReport: flow(function* moveRootReport(sourceId: string, targetId: string, notify = true): Generator<any, void, any> {
        const srcIndex = self.rootReportIds.findIndex(id => id === sourceId);
        const destIndex = self.rootReportIds.findIndex(id => id === targetId);
        moveItemInRefArray(self.rootReportIds, srcIndex, destIndex);
        if (notify) {
          reorderReport(sourceId, targetId);
        } else {
          yield appStore.env.reportsTreeGridApi?.setGridOption("rowData", reportNodeList());
        }
      }),
      reparentReport: flow(function* reparentReport(report: IReport, newParent?: IReport, notify = true): Generator<any, boolean, any> {
        const isCircular = report === newParent || report.parentReport === newParent || newParent?.parentReport === report;
        const isDiffWorkspace = newParent && parentWorkspace(report) !== parentWorkspace(report);
        if (!report || isCircular || isDiffWorkspace) {
          return false;
        }

        const existingParent = report.parentReport as IReport;
        if (existingParent?.children) {
          existingParent.children = cast(existingParent.children.filter(b => b !== report));
        }
        if (newParent) {
          if (newParent.children) {
            newParent.children.push(report);
          } else {
            newParent.children = cast([report.id]);
          }
        } else {
          self.rootReportIds.push(report.id);
        }
        report.parentReport = newParent || null;

        if (notify) {
          try {
            const { status } = yield rollupClient.reports.reparent(report.id, report.parentReport?.id);
            return status === 200;
          } catch (e) {
            showApiErrorToast("Error re-parenting page");
            return false;
          }
        } else {
          appStore.env.reportsTreeGridApi?.setGridOption("rowData", reportNodeList());
          return true;
        }
      }),
      populateNewBlocks(blocks: Block[], propertyInstances: PropertyInstance[], statusInstances: StatusInstance[]) {
        if (!blocks?.length) {
          return false;
        }

        try {
          if (!populateBlocks(blocks)) {
            return false;
          }
          if (!populatePropertyInstances(propertyInstances)) {
            return false;
          }
          if (!populateStatusInstances(statusInstances)) {
            return false;
          }
        } catch (err) {
          console.warn(err);
          return false;
        }
        self.setAgGridRowData(blockNodeList());
        return true;
      },
      deleteReport(reportId: string, notify = true) {
        const report = self.reportsMap.get(reportId);

        if (!report) {
          return;
        }

        appStore.env.clearActiveReportBlock();
        appStore.env.clearActiveReport();

        // Recursively delete children
        for (const r of report.validatedChildren) {
          this.deleteReport(r.id, false);
        }

        if (notify) {
          rollupClient.reports.delete(report.id);
        }

        if (!report.isTemplate) {
          if (!report.children?.length) {
            self.applyReportsAgGridTransaction({ remove: [getMockedTreeEntity(report)] });
          }
          self.applyReportsAgGridTransaction({ remove: [{ path: report.path, report }] });
        }

        if (!report.parentReport) {
          self.rootReportIds = cast(self.rootReportIds.filter(id => id !== report.id));
        }

        detach(report);
      },
      deleteBomTable(bomTableId: string, notify = true) {
        appStore.env.clearActiveBomTable();
        const bomTableToDelete = self.bomTablesMap.get(bomTableId);
        try {
          self.bomTablesMap.delete(bomTableId);
        } catch (err) {
          console.error("Error deleting bom table", err);
        }
        destroy(bomTableToDelete);

        if (notify) {
          rollupClient.bomTables.delete(bomTableId);
        }
      },
      deletePropertyInstance(instance: IPropertyInstance, notify = true) {
        if (!instance?.id) {
          return false;
        }
        const { id } = instance;

        if (self.propertyInstanceMap.delete(id)) {
          if (notify) {
            rollupClient.modelingModule.propertyInstances.delete(id).catch((err: Error) => {
              showApiErrorToast("Error deleting property instance", err);
            });
            showMiddleToast("property deleted", "none");
          }

          destroy(instance);
          return true;
        } else {
          return false;
        }
      },
      deletePropertyDefinition(propertyDefinition: IPropertyDefinition, notify = true) {
        if (!propertyDefinition?.id) {
          return false;
        }
        const { id } = propertyDefinition;

        for (const [, p] of self.propertyInstanceMap) {
          if (p?.propertyDefinition?.id === id) {
            this.deletePropertyInstance(p, false);
          }
        }
        if (appStore.env.activeBomTableId) {
          const table = self.bomTablesMap.get(appStore.env.activeBomTableId);
          const column: IBomColumn | undefined = table?.columnArray.find(
            (c: IBomColumn) => c.propertyDefinition?.id === propertyDefinition.id
          );
          column && table?.removeColumn(column.id);
        }
        if (self.propertyDefinitionMap.delete(id)) {
          if (notify) {
            rollupClient.modelingModule.propertyDefinitions.delete(id).catch((err: Error) => {
              showApiErrorToast("Error deleting property definition", err);
            });
          }
          destroy(propertyDefinition);
          showMiddleToast("Property definition deleted", "none");
          return true;
        }
        return false;
      },
      deleteTableViewConfig(configToDelete: ITableViewConfig, notify = true) {
        const { id } = configToDelete;

        if (notify) {
          rollupClient.tableViewConfigs.delete(id).catch((err: Error) => {
            showApiErrorToast("Error deleting table view config", err);
          });
        }

        if (self.tableViewConfigs.delete(id)) {
          appStore.env.setActiveTableConfigId(defaultTableViewConfigTabId);
          destroy(configToDelete);
          return true;
        }

        return false;
      },
      deleteInterface(interfaceToDelete: IInterface, notify = true) {
        if (!interfaceToDelete?.id) {
          return false;
        }
        const { id } = interfaceToDelete;

        for (const att of interfaceToDelete.validatedAttributes) {
          self.interfaceMap.delete(att.id);
          destroy(att);
        }
        if (notify) {
          rollupClient.interfaces.delete(id).catch((err: Error) => {
            showApiErrorToast("Error deleting interface", err);
          });
        }
        if (self.interfaceMap.delete(id)) {
          destroy(interfaceToDelete);
          return true;
        }
        return false;
      },
      addNewAttribute(parentInterface: IInterface, label?: string, id = uuidv4(), notify = true) {
        if (self.attributeMap.has(id)) {
          return id;
        }
        if (!parentInterface?.replicated) {
          return undefined;
        }

        const attribute = self.attributeMap.put({
          id,
          label,
          parentInterface: parentInterface.id,
        });

        // Append to interfaces' reference list
        if (parentInterface.attributes) {
          parentInterface.attributes.push(attribute);
        } else {
          parentInterface.attributes = cast([attribute.id]);
        }
        if (notify) {
          rollupClient.attributes.create({ id, parentInterface: parentInterface.id, label }).catch((err: Error) => {
            showApiErrorToast("Error creating attribute", err);
          });
        }
        return attribute?.id;
      },
      deleteAttribute(attribute: IAttribute, notify = true) {
        if (!attribute?.id) {
          return false;
        }
        const { id } = attribute;

        if (notify) {
          rollupClient.attributes.delete(id).catch((err: Error) => {
            showApiErrorToast("Error deleting attribute", err);
          });
        }
        if (self.attributeMap.delete(id)) {
          destroy(attribute);
          return true;
        }
        return false;
      },
      async createReport(reportData?: ICreateReportData, params?: { notify?: boolean; awaitReportCreation?: boolean }) {
        const { id = uuidv4(), label = "New Page", icon = "", parentReportId, isTemplate = false } = reportData ?? {};
        const { awaitReportCreation, notify = true } = params ?? {};

        const report = self.reportsMap.put({
          id,
          label,
          icon,
          parentReport: parentReportId,
          isTemplate,
        });

        if (parentReportId && self.reportsMap.has(parentReportId)) {
          const parentReportInstance = self.reportsMap.get(parentReportId);
          // Append to report's reference list
          if (parentReportInstance) {
            if (parentReportInstance.children) {
              parentReportInstance.children.push(report);
            } else {
              self.applyReportsAgGridTransaction({
                remove: [getMockedTreeEntity(parentReportInstance)],
              });
              parentReportInstance.children = cast([report.id]);
            }
          }
        } else {
          self.rootReportIds.push(report.id);
        }

        if (!report.isTemplate) {
          self.applyReportsAgGridTransaction({ add: [{ path: report.path, report }] });
          self.applyReportsAgGridTransaction({ add: [getMockedTreeEntity(report)] });
        }

        if (notify) {
          const params = { id, label, icon, parentReportId, isTemplate };
          if (awaitReportCreation) {
            await rollupClient.reports.create(params);
          } else {
            rollupClient.reports.create(params);
          }
        }

        return report;
      },
      addReportBlocks(
        parentReport: IReport,
        blocks: { label: string; type: RollupEditorType }[],
        orderIndex: number | undefined = undefined,
        notify = true
      ) {
        if (!blocks.length) {
          return;
        }
        const newIndex = orderIndex ?? parentReport.reportBlocks.length;
        const destinationId = parentReport.reportBlocks.at(newIndex);
        const reportBlocks: CreateReportBlockDto[] = blocks.map(({ label, type }, index: number) => {
          const id = uuidv4();
          const item = {
            label,
            type,
            id,
            parentReport: parentReport.id,
            parentReportId: parentReport.id,
            orderIndex: newIndex + index,
          };
          parentReport.addBlock(id, newIndex + index);
          self.reportBlocksMap.put(item);
          return item;
        });

        if (notify) {
          rollupClient.reportBlocks.bulkCreate({ reportBlocks, destinationId }).catch((err: Error) => {
            showApiErrorToast("Error creating page blocks", err);
          });
        }

        // returning latest report block to allow autofocus on it
        return reportBlocks.at(-1)!.id;
      },
      addReportBlock(
        parentReport: IReport,
        type: RollupEditorType,
        label: string,
        orderIndex: number | undefined = undefined,
        id = uuidv4(),
        notify = true
      ) {
        if (self.reportBlocksMap.has(id)) {
          return id;
        }
        const newIndex = orderIndex ?? parentReport.reportBlocks.length;

        self.reportBlocksMap.put({
          id,
          label,
          type,
          orderIndex: newIndex,
          parentReport: parentReport.id,
        });

        // Append to block's reference list
        const destinationId = parentReport.addBlock(id, newIndex);

        if (notify) {
          rollupClient.reportBlocks
            .create({
              id,
              label,
              type,
              parentReportId: parentReport.id,
              destinationId,
            })
            .catch((err: Error) => {
              showApiErrorToast("Error creation page block", err);
            });
        }

        return id;
      },
      deleteReportBlocks(ids: string[], notify?: boolean) {
        ids.forEach(id => {
          const reportBlock = self.reportBlocksMap.get(id);
          if (reportBlock) {
            this.deleteReportBlock(reportBlock, notify);
          }
        });
      },
      deleteReportBlock(reportBlock: IReportBlock, notify = true) {
        if (!reportBlock?.id) {
          return false;
        }
        const { id } = reportBlock;
        const parentReport = self.reportsMap.get(reportBlock.parentReport);

        if (self.reportBlocksMap.delete(id)) {
          appStore.env.editors.has(id) && appStore.env.editors.delete(id);
          destroy(reportBlock);
          parentReport?.deleteBlock(id);
          if (notify) {
            rollupClient.reportBlocks.delete(id).catch((err: Error) => {
              showApiErrorToast("Error deleting page block", err);
            });
          }
          return true;
        }
        return false;
      },
      unloadBomTable(bomTableId?: string | null) {
        if (!bomTableId) {
          return false;
        }

        const bomTable = self.bomTablesMap.get(bomTableId);
        if (!bomTable) {
          return false;
        }
        bomTable.unload();
        console.debug(`Unloaded BOM table ${bomTableId}`);
        return true;
      },
      handleBomTableCreated(dto: CreateBomTableDto) {
        // TODO: handle createdby/etc
        self.bomTablesMap.put({ id: dto.id, label: dto.label });
      },
      createBomTable: flow(function* createBomTable(label: string, blockIds: string[]) {
        // Default meta columns
        const columns: CreateBomColumnDto[] = getBomMetaColumns().map((metaColumn, index) => ({
          id: uuidv4(),
          metaColumn,
          orderIndex: index,
        }));

        // Default property/status columns
        const manufacturerDefinition = self.statusDefinitions.find(p => p.label.toLowerCase() === "manufacturer");
        if (manufacturerDefinition) {
          columns.splice(2, 0, {
            id: uuidv4(),
            statusDefinition: manufacturerDefinition.id,
            orderIndex: columns.length,
          });
        }

        const costDefinition = self.propertyDefinitions.find(p => p.label.toLowerCase() === "cost");
        if (costDefinition) {
          columns.push({
            id: uuidv4(),
            propertyDefinition: costDefinition.id,
            orderIndex: columns.length,
          });
        }

        const bomTable = self.bomTablesMap.put({ id: uuidv4(), label });
        yield rollupClient.bomTables.create({ id: bomTable.id, label, rows: blockIds, columns });
        return bomTable.id;
      }),
      populateBomTable: flow(function* populateBomTable(bomTableId: string, forceReload = false): Generator<any, boolean, any> {
        if (!bomTableId) {
          return false;
        }

        const bomTable = self.bomTablesMap.get(bomTableId);
        if (!bomTable) {
          return false;
        }

        if (!forceReload && bomTable.loadingStatus === BomTableLoadingStatus.Loaded) {
          return true;
        }

        bomTable.loadingStatus = BomTableLoadingStatus.Loading;

        try {
          console.debug(`Populating BOM table ${bomTableId}`);
          const res = yield rollupClient.bomTables.get(bomTableId);
          const data = res.data as BomTable;
          if (data) {
            return bomTable.populate(data);
          }
        } catch (err) {
          console.error(err);
        }

        bomTable.loadingStatus = BomTableLoadingStatus.Error;
        return false;
      }),
      deleteRequirementsPage(reqPageId: string, notify = true) {
        const page = self.requirementsPages.get(reqPageId);
        if (!page) {
          console.warn(`Requirements page not found.`);
          return false;
        }

        appStore.env.requirementsTableGridApi?.applyTransaction({ remove: page.validatedBlocks });
        for (const b of page.validatedBlocks) {
          self.requirementBlockMap.delete(b.id);
          destroy(b);
        }
        return self.requirementsPages.delete(reqPageId, notify);
      },
      addRequirementsBlock(
        parentPage: IRequirementsPage,
        type: RequirementBlockType,
        label = isHeading(type) ? "" : undefined,
        orderIndex?: number,
        id = uuidv4(),
        notify = true
      ): IRequirementBlock | undefined {
        const reqBlock = self.requirementBlockMap.get(id);
        if (reqBlock) {
          return reqBlock;
        }
        if (!parentPage?.replicated) {
          return undefined;
        }
        const newIndex = orderIndex ?? parentPage.requirementBlocks?.length;

        const requirementsBlock = self.requirementBlockMap.put({
          id,
          label,
          type,
          parentPage: parentPage.id,
        });

        const destinationId = parentPage.addBlock(id, newIndex);

        if (notify) {
          rollupClient.requirementBlocks.create({ id, type, label, parentPage: parentPage.id, destinationId }).catch((err: Error) => {
            showApiErrorToast("Error creation requirements block", err);
          });
        }
        return requirementsBlock;
      },
      deleteRequirementBlocks(ids: string[], notify?: boolean) {
        ids.forEach(id => {
          const reqBlock = self.requirementBlockMap.get(id);
          if (reqBlock) {
            this.deleteRequirementBlock(reqBlock, notify);
          }
        });
      },
      deleteRequirementBlock(requirementBlock: IRequirementBlock, notify = true) {
        if (!requirementBlock?.id) {
          return false;
        }
        const { id } = requirementBlock;

        if (self.requirementBlockMap.delete(id)) {
          if (notify) {
            rollupClient.requirementBlocks.delete(id).catch((err: Error) => {
              showApiErrorToast("Error deleting requirements block", err);
            });
          }
          destroy(requirementBlock);
          return true;
        }
        return false;
      },
      deleteStatusDefinition(status: IStatusDefinition, notify = true) {
        if (!status?.id) {
          return false;
        }
        const { id } = status;

        if (self.statusDefinitionMap.has(id)) {
          if (appStore.env.activeBomTableId) {
            const table = self.bomTablesMap.get(appStore.env.activeBomTableId);
            // TODO find out a way to remove this type assertion
            const column: IBomColumn | undefined = table?.columnArray.find((c: IBomColumn) => c.statusDefinition?.id === status.id);
            column && table?.removeColumn(column.id);
          }

          for (const [, instance] of self.statusInstanceMap) {
            if (instance.statusDefinition === status) {
              self.statusDefinitionMap.delete(instance.id);
              destroy(instance);
            }
          }

          for (const [, option] of self.statusOptionMap) {
            if (option.statusDefinition === status) {
              self.statusOptionMap.delete(option.id);
              destroy(option);
            }
          }
          self.statusDefinitionMap.delete(id);

          if (notify) {
            rollupClient.statusDefinitions.delete(id);
          }

          destroy(status);
          return true;
        }
      },
      migrateStatusType(statusDefinition: IStatusDefinition, type: StatusType, notify = true) {
        if (statusDefinition?.type === type) {
          return true;
        }

        for (const [, instance] of self.statusInstanceMap) {
          if (instance.statusDefinition === statusDefinition) {
            instance.migrateType(type);
          }
        }

        statusDefinition.type = type;

        if (notify) {
          rollupClient.statusDefinitions.update(statusDefinition.id, { type });
        }
        return true;
      },
      getStatusDefinitionType(id: string): StatusType {
        const statusDefinition = self.statusDefinitionMap.get(id);
        return statusDefinition ? statusDefinition.type : StatusType.text;
      },
      addStatusInstance: flow(function* addStatusInstance(
        parentBlock: IBlock,
        statusDefinition: IStatusDefinition,
        value?: string,
        id = uuidv4(),
        notify = true
      ) {
        if (self.statusInstanceMap.has(id)) {
          return self.statusInstanceMap.get(id);
        }
        if (!parentBlock?.replicated) {
          return undefined;
        }

        if (!statusDefinition) {
          return undefined;
        }

        const instance = self.statusInstanceMap.put({
          id,
          parentBlock: parentBlock.id,
          statusDefinition: statusDefinition.id,
          value,
        });

        if (notify) {
          try {
            const res = yield rollupClient.statusInstances.create({
              id,
              statusDefinition: statusDefinition.id,
              parentBlock: parentBlock.id,
              value,
            });
            if (res.status !== 201) {
              destroy(instance);
              return undefined;
            }
          } catch (err) {
            console.warn(err);
            destroy(instance);
            return undefined;
          }
        }

        // Append to block's reference list
        if (parentBlock.statusInstances) {
          parentBlock.statusInstances.push(instance);
        } else {
          parentBlock.statusInstances = cast([instance.id]);
        }

        return instance;
      }),
      deleteStatusInstance(instance: IStatusInstance, notify = true) {
        if (!instance?.id || !self.statusInstanceMap.has(instance.id)) {
          return false;
        }
        const { id } = instance;

        self.statusInstanceMap.delete(id);

        if (notify) {
          rollupClient.statusInstances.delete(id);
        }

        destroy(instance);
        return true;
      },
      addNewStatusOption(statusDefinition: IStatusDefinition, label: string, color?: string, id = uuidv4(), notify = true) {
        if (!statusDefinition?.id || !self.statusDefinitionMap.has(statusDefinition.id)) {
          return undefined;
        }

        if (!color) {
          color = getRandomHexColor(statusDefinition.statusOptions?.map(d => d.color));
        }

        const option = self.statusOptionMap.put({
          id,
          label,
          color,
          statusDefinition: statusDefinition.id,
        });

        if (!option) {
          return undefined;
        }

        if (statusDefinition.statusOptions) {
          statusDefinition.statusOptions.push(option);
        } else {
          statusDefinition.statusOptions = cast([option.id]);
        }

        if (notify) {
          rollupClient.statusOptions.create({
            id,
            label,
            color,
            statusDefinition: statusDefinition.id,
          });
        }

        return option;
      },
      deleteUnsavedImports(imports?: Import[]) {
        try {
          const unsavedImports = imports || self.imports.filter(i => i.status === ImportStatus.PendingApproval);
          for (const imp of unsavedImports) {
            rollupClient.imports.delete(imp.id);
            !imports && self.importsMap.delete(imp.id);
          }
        } catch (err) {
          showApiErrorToast("Error deleting unsaved imports", err as Error);
          console.warn(err);
          return false;
        }
      },
      deleteStatusOption(option: IStatusOption, notify = true) {
        if (!option?.id || !self.statusOptionMap.has(option.id)) {
          return false;
        }
        const { id } = option;

        if (option.statusDefinition?.statusOptions?.includes(option)) {
          option.statusDefinition.statusOptions.remove(option);
        }

        self.statusInstanceMap.forEach(instance => {
          if (instance.statusDefinition && instance.statusDefinition === option.statusDefinition) {
            if (instance.statusDefinition.type === StatusType.singleSelect && instance.selectValue === option) {
              instance.setValue("", false);
            } else if (instance.statusDefinition.type === StatusType.singleSelect && instance.multiSelectValues?.includes(option)) {
              instance.removeOption(option, false);
            }
          }
        });

        self.statusOptionMap.delete(id);

        if (notify) {
          rollupClient.statusOptions.delete(id);
        }
        destroy(option);
      },
      setLastExecutionResult(result: string) {
        self.lastExecutionResult = result;
      },
      setLastExecutionError(error: string) {
        self.lastExecutionError = error;
      },
      setLastExecutionLog(log: string) {
        self.lastExecutionLogs = log;
      },
      clearExecutionLogs() {
        self.lastExecutionLogs = "";
        self.lastExecutionError = "";
        self.lastExecutionResult = "";
        self.lastExecutionStatus = ReturnStatus.neutral;
      },
      renameAllProperties(oldLabel: string, newLabel: string) {
        // TODO: This should use the definition rather than just searching by label

        if (!self.propertyDefinitionMap) {
          return false;
        }

        for (const [, definition] of self.propertyDefinitionMap) {
          if (definition.label === oldLabel) {
            definition.setLabel(newLabel);
            // Update the column widths and property map
            self.ui.replaceTableColumnKey(oldLabel, newLabel);
            return true;
          }
        }

        return false;
      },
      attachBlock(block: IBlock) {
        if (block && !block.parentBlock) {
          this.reparentBlock(block, self.rootBlock);
        }
      },
      detachBlock(block: IBlock) {
        const existingParentId = block.parentBlock?.id;
        // Only detach if the block is not the root block and has no children
        if (block && existingParentId && block !== self.rootBlock && !block.children?.length) {
          this.reparentBlock(block, undefined);
        }
        if (block === appStore.env.activeBlock) {
          appStore.env.setActiveBlockById(existingParentId ?? appStore.workspaceModel?.rootBlockId);
        }
        self.applyAgGridTransaction({ remove: [{ path: getPath(block), block }] });
      },
    };
  })
  .views(self => ({
    get autoAddProperties() {
      const propertyDefinitions: IPropertyDefinition[] = [];
      for (const [, definition] of self.propertyDefinitionMap) {
        if (definition?.autoAdd) {
          propertyDefinitions.push(definition);
        }
      }
      return propertyDefinitions;
    },
    get reports() {
      return toArray<IReport>(self.reportsMap).filter(r => !r.isTemplate);
    },
    get reportTemplates() {
      return toArray<IReport>(self.reportsMap).filter(r => r.isTemplate);
    },
    get statusInstances() {
      return toArray<IStatusInstance>(self.statusInstanceMap);
    },
    get ownImports(): IImport[] {
      return toArray<IImport>(self.importsMap, (_key, i: IImport) => {
        if (i.status === ImportStatus.PendingApproval) {
          return i.createdBy === appStore.userModel?.id;
        } else {
          return true;
        }
      });
    },
    get importsInProgress(): IImport[] {
      return toArray<IImport>(self.importsMap, (_key, i) => {
        if (i.status === ImportStatus.PendingApproval) {
          return i.createdBy === appStore.userModel?.id;
        } else {
          return ImportInProgressStatuses.includes(i.status);
        }
      });
    },
    get unsavedImports(): IImport[] {
      return toArray<IImport>(self.importsMap, (_key, i) => i.status === ImportStatus.PendingApproval);
    },
    get propertyInstances(): IPropertyInstance[] {
      return toArray<IPropertyInstance>(self.propertyInstanceMap);
    },
    get dataSourceLinks(): DataLink[] {
      return self.dataConnection.dataSourceLinks;
    },
    get dataSources(): DataSource[] {
      return self.dataConnection.dataSources;
    },
    get autoAddPropertyGroups() {
      const groups = this.autoAddProperties.map(d => d.defaultPropertyGroup).filter(g => g !== undefined) as string[];
      return new Set(groups);
    },
    get propertyDefinitionLabels() {
      // Maps a property definition label (lowercase) to a property definition
      const labelMap = new Map<string, IPropertyDefinition>();
      if (self.propertyDefinitionMap?.size) {
        for (const [, definition] of self.propertyDefinitionMap) {
          if (definition?.label) {
            labelMap.set(definition.label.toLowerCase(), definition);
          }
        }
      }
      return labelMap;
    },
    get tableViewConfigsLabels() {
      // Maps a table view configs label (lowercase) to a table view config
      const labelMap = new Map<string, ITableViewConfig>();
      if (self.tableViewConfigs?.size) {
        for (const [, tableConfig] of self.tableViewConfigs) {
          if (tableConfig?.label) {
            labelMap.set(tableConfig.label.toLowerCase(), tableConfig);
          }
        }
      }
      return labelMap;
    },
    get statusDefinitionLabels() {
      // Maps a status definition label (lowercase) to a status definition
      const labelMap = new Map<string, IStatusDefinition>();
      if (self.statusDefinitionMap?.size) {
        for (const [, definition] of self.statusDefinitionMap) {
          if (definition?.label) {
            labelMap.set(definition.label.toLowerCase(), definition);
          }
        }
      }
      return labelMap;
    },
    get detachedBlocks() {
      const detachedBlocks: IBlock[] = [];
      for (const [, block] of self.blockMap) {
        if (block && !block.parentBlock && block.id !== self.rootBlockId) {
          detachedBlocks.push(block);
        }
      }
      return detachedBlocks;
    },
    get modelBlocks() {
      const modelBlocks: IBlock[] = [];
      for (const [, block] of self.blockMap) {
        if (block && (block.parentBlock || block.id === self.rootBlockId)) {
          modelBlocks.push(block);
        }
      }
      return modelBlocks;
    },
  }))
  .actions(self => ({
    addPropertyInstance: flow(function* addPropertyInstance(
      parentBlock: IBlock,
      propertyDefinition: IPropertyDefinition,
      propertyGroup?: string,
      value?: string,
      id = uuidv4(),
      notify = true
    ): Generator<any, IPropertyInstance | undefined, any> {
      if (self.propertyInstanceMap.has(id)) {
        return self.propertyInstanceMap.get(id);
      }

      if (!parentBlock || !propertyDefinition) {
        return undefined;
      }

      // Prevent duplicate instances
      if (parentBlock.validatedPropertyInstances.find(i => i.propertyDefinition === propertyDefinition)) {
        return undefined;
      }

      // MST seems to have trouble with simply passing in IBlock and IPropertyDefinition typed values.
      // This is probably due to types.late
      const property = self.propertyInstanceMap.put({
        id,
        parentBlock: parentBlock.id,
        value,
        propertyDefinition: propertyDefinition.id,
      });

      if (propertyGroup === undefined && propertyDefinition.defaultPropertyGroup !== undefined) {
        const label = propertyDefinition.defaultPropertyGroup;
        const existingGroup = parentBlock.propertyGroups?.find(g => g.label === label);
        if (existingGroup) {
          property.propertyGroup = existingGroup.id;
        } else {
          try {
            const newGroup = yield parentBlock.addNewPropertyGroup(label);
            property.propertyGroup = newGroup?.id;
          } catch (err) {
            console.warn(err);
          }
        }
      } else {
        property.propertyGroup = propertyGroup;
      }

      // Append to block's reference list
      if (parentBlock.propertyInstances) {
        parentBlock.propertyInstances.push(property);
      } else {
        parentBlock.propertyInstances = cast([property.id]);
      }

      if (notify) {
        try {
          const res = yield rollupClient.modelingModule.propertyInstances.create({
            id,
            propertyDefinition: propertyDefinition.id,
            parentBlock: parentBlock.id,
            value,
            propertyGroup: property.propertyGroup,
          });
          if (res.status !== 201) {
            self.deletePropertyInstance(property, false);
            showApiErrorToast("Error creating property");
            return undefined;
          }
        } catch (err) {
          console.warn(err);
          showApiErrorToast("Error creating property", err as Error);
          self.deletePropertyInstance(property, false);
          return undefined;
        }
      }

      return property;
    }),

    async duplicateReqPage(id: string, notify = true) {
      const reqPage = self.requirementsPages.get(id);

      if (!reqPage) {
        console.error("Could not find the requirements page:", id);
        showToast("Failed to duplicate the requirements page", Intent.DANGER);
        return;
      }

      const duplicatedDto = reqPage.getCreateDto();
      const newReqPage = await self.requirementsPages.createRequirementsPage(duplicatedDto, notify);

      if (newReqPage) {
        reqPage.requirementBlocks?.forEach(requirementBlock => {
          if (requirementBlock) {
            const newReqBlock = self.addRequirementsBlock(newReqPage, requirementBlock.type, requirementBlock.label);
            const reqBlockDto = requirementBlock.getUpdateDto();
            newReqBlock?.patch(reqBlockDto);
          }
        });
      }

      return newReqPage;
    },
    async duplicateReport(reportId: string, reportData?: ICreateReportData, notify = true) {
      const report = self.reportsMap.get(reportId);

      if (!report) {
        console.error("Could not find the page");
        showToast("Failed to duplicate the page", Intent.DANGER);
        return;
      }

      const newReportDto = report.getCreateDto();
      const newReport = await self.createReport({ ...newReportDto, ...reportData }, { notify, awaitReportCreation: true });
      newReport.updateFromReport(report);

      report.reportBlocks.forEach((id: string) => {
        const reportBlock = self.reportBlocksMap.get(id);
        if (reportBlock) {
          self.addReportBlock(newReport, reportBlock?.type, reportBlock?.label, reportBlock?.orderIndex);
        }
      });

      return newReport;
    },
  }))
  .actions(self => ({
    clearStatusInstancesByDefinition(statusDefinition: IStatusDefinition) {
      for (const [, statusInstance] of self.statusInstanceMap) {
        if (statusDefinition.id === statusInstance.statusDefinition?.id) {
          self.deleteStatusInstance(statusInstance);
        }
      }
    },
    addExistingImport(dto: Import) {
      if (!dto.id || self.importsMap.has(dto.id)) {
        return undefined;
      }

      self.importsMap.put({
        ...dto,
        createdAt: convertTimestamp(dto.updatedAt),
        updatedAt: convertTimestamp(dto.createdAt),
      });
    },
    addExistingStatusInstances(statusInstances: IStatusInstance[]) {
      for (const s of statusInstances) {
        if (!s.statusDefinition?.id || !s.parentBlock?.id) {
          console.warn("Status instance missing status definition or parent block ID");
          continue;
        }

        const instanceDto = {
          ...omitBy(s, isNull),
          statusDefinition: s.statusDefinition.id,
          parentBlock: s.parentBlock.id,
        } as IStatusInstanceSnapshotIn;
        const newStatusInstance = self.statusInstanceMap.put(instanceDto);
        const parentBlock = self.blockMap.get(s.parentBlock.id);
        if (parentBlock && newStatusInstance) {
          if (parentBlock.statusInstances) {
            parentBlock.statusInstances.push(newStatusInstance);
          } else {
            parentBlock.statusInstances = cast([newStatusInstance.id]);
          }
        }
      }
    },
    addExistingPropertyInstances(propertyInstances: IPropertyInstance[]) {
      for (const p of propertyInstances) {
        if (!p.propertyDefinition?.id || !p.parentBlock?.id) {
          console.warn("Property instance missing property definition or parent block ID");
          continue;
        }

        const instanceDto = {
          ...omitBy(p, isNull),
          propertyDefinition: p.propertyDefinition.id,
          parentBlock: p.parentBlock.id,
        } as IPropertyInstanceSnapshotIn;
        const newPropertyInstance = self.propertyInstanceMap.put(instanceDto);
        const parentBlock = self.blockMap.get(p.parentBlock.id);
        if (parentBlock && newPropertyInstance) {
          if (parentBlock.propertyInstances) {
            parentBlock.propertyInstances.push(newPropertyInstance);
          } else {
            parentBlock.propertyInstances = cast([newPropertyInstance.id]);
          }
        }
      }
    },
    addExistingBlocks(blocks: IBlock[]) {
      const addExistingBlockParent = (block: IBlock) => {
        const blockDto = {
          id: block.id,
          label: block.label,
          parentBlock: block.parentBlock?.id || null,
          multiplicity: block.multiplicity || null,
          partNumber: block.partNumber || "",
          description: block.description || "",
        };

        let newBlockInstance: IBlock | undefined;
        let parentBlock: IBlock | undefined;

        if (!block.parentBlock?.id) {
          newBlockInstance = self.blockMap.put(blockDto);
        } else if (self.blockMap.has(block.parentBlock.id)) {
          parentBlock = self.blockMap.get(block.parentBlock.id);
          newBlockInstance = self.blockMap.put(blockDto);
        } else {
          addExistingBlockParent(block.parentBlock);
        }

        if (parentBlock) {
          if (parentBlock.children) {
            newBlockInstance && parentBlock.children.push(newBlockInstance);
          } else {
            parentBlock.children = cast([block.id]);
          }
        }
      };

      blocks.forEach(addExistingBlockParent);
      self.setAgGridRowData(blockNodeList());
    },
    addExistingBlock(dto: CreateBlockDto): IBlock | undefined {
      if (!dto.parentBlock || !dto.id) {
        return undefined;
      }
      const parentBlock = self.blockMap.get(dto.parentBlock);
      if (!parentBlock) {
        return undefined;
      }

      const { instances, ...blockProperties } = dto;
      const block = self.blockMap.put({ ...blockProperties, replicated: true });

      // Append to block's reference list
      if (parentBlock.children) {
        parentBlock.children.push(block);
      } else {
        parentBlock.children = cast([block.id]);
      }

      self.applyAgGridTransaction({ add: [{ path: getPath(block), block }] });

      if (instances) {
        for (const instance of instances) {
          const definition = self.propertyDefinitionMap.get(instance.propertyDefinition);
          if (!definition) {
            showApiErrorToast("Error creating block", new Error());
            console.error(`Could not find property definition with ID ${definition}`);
            self.deleteBlock(block, false);
            return undefined;
          }
          self.addPropertyInstance(block, definition, instance.propertyGroup, "", instance.id, false);
        }
      }

      return block;
    },
    addNewBlock: flow(function* addNewBlock(
      parentBlock: IBlock | undefined,
      label: string,
      icon?: string
    ): Generator<any, string | undefined, any> {
      if (parentBlock && !parentBlock.replicated) {
        return undefined;
      }

      const validatedLabel = validateBlockLabel(label, parentBlock);
      if (!validatedLabel.isValid || !validatedLabel.result) {
        showApiErrorToast(validatedLabel.message || "Error creating block");
        return undefined;
      }

      const newBlockId = uuidv4();
      const block: IBlock = self.blockMap.put({
        id: newBlockId,
        label: validatedLabel.result,
        icon,
        parentBlock: parentBlock?.id,
        replicated: false,
      });

      if (parentBlock) {
        // Append to block's reference list
        if (parentBlock.children) {
          parentBlock.children.push(block);
        } else {
          parentBlock.children = cast([block.id]);
        }
      }

      self.applyAgGridTransaction({ add: [{ path: getPath(block), block }] });

      try {
        // Add default groups if they exist (based on autoAdd)
        const defaultGroups = self.autoAddPropertyGroups;
        // Maps group labels to group ID
        const groupMap = new Map<string, string>();
        if (defaultGroups.size) {
          const groups: IPropertyGroup[] = [];
          for (const label of defaultGroups) {
            const group = PropertyGroupStore.create({
              id: uuidv4(),
              label,
            });
            groupMap.set(label, group.id);
            groups.push(group);
          }
          block.propertyGroups = cast(groups);
        }

        const instances = [];

        // Add property instances if there are any property definitions with autoAdd
        if (self.autoAddProperties) {
          for (const definition of self.autoAddProperties) {
            const groupId = definition.defaultPropertyGroup ? groupMap.get(definition.defaultPropertyGroup) : undefined;
            const instance = yield self.addPropertyInstance(block, definition, groupId, "", uuidv4(), false);
            if (instance) {
              instances.push({ id: instance.id, propertyDefinition: definition.id, propertyGroup: groupId });
            }
          }
        }

        const res = yield rollupClient.modelingModule.blocks.create({
          id: block.id,
          parentBlock: block.parentBlock?.id,
          icon,
          label,
          instances,
          propertyGroups: block.propertyGroups ? getSnapshot(block.propertyGroups) : undefined,
        });

        if (parentBlock?.type.includes(BlockType.Part)) {
          parentBlock.toggleType(BlockType.Part, false);
        }

        if (res.status === 201) {
          block.setReplicated();
        } else {
          showApiErrorToast("Error creating block", new Error());
          self.deleteBlock(block, false);
          return undefined;
        }
      } catch (err) {
        showApiErrorToast("Error creating block", err as Error);
        console.warn(err);
      }
      return block.id;
    }),
    addRecentBlock(blockId: string) {
      if (!self.recentBlockIds.includes(blockId)) {
        const filteredArray = self.recentBlockIds.filter(recentBlockId => recentBlockId !== blockId);
        self.recentBlockIds = cast([blockId, ...filteredArray].slice(0, MAX_RECENT_BLOCKS));
      }
    },
    addNewBomStatusDefinition: flow(function* addNewBomStatusDefinition(
      bomTableId: string
    ): Generator<any, IStatusDefinition | undefined, any> {
      const id = uuidv4();
      const label = getIncrementedName<IStatusDefinition>("New Status", self.statusDefinitionLabels);
      const type = StatusType.text;
      const status = self.statusDefinitionMap.put({
        id,
        label,
        type,
        bomTableId,
      });
      yield rollupClient.statusDefinitions.create({ id, type, label, bomTableId });
      return status;
    }),
    addNewStatusDefinition: flow(function* (
      label = "New Status",
      type = StatusType.text,
      description?: string,
      id = uuidv4(),
      notify = true
    ): Generator<any, IStatusDefinition | undefined, any> {
      label = label?.trim();

      if (self.statusDefinitionMap.has(id)) {
        return self.statusDefinitionMap.get(id);
      }

      if (label && self.statusDefinitionLabels.has(label.toLowerCase())) {
        // Prevent duplicate labels
        label = getIncrementedName<IStatusDefinition>(label, self.statusDefinitionLabels);
      }

      const status = self.statusDefinitionMap.put({
        id,
        label,
        type,
        description,
        orderIndex: getNextOrderIndex(self.statusDefinitions),
      });

      if (notify) {
        const res = yield rollupClient.statusDefinitions.create({ id, type, label, description });
        if (res.status !== 201) {
          showApiErrorToast("Error creating status definition", new Error());
          self.deleteStatusDefinition(status, false);
          return undefined;
        }
      }
      return status;
    }),
    addNewPropertyDefinition: flow(function* (
      label?: string,
      defaultPropertyGroup?: string,
      unit?: string,
      dataType = PropertyDataType.scalar,
      id = uuidv4(),
      notify = true
    ): Generator<any, IPropertyDefinition | undefined, any> {
      label = label?.trim();

      if (label && self.propertyDefinitionLabels.has(label.toLowerCase())) {
        // Prevent duplicate labels
        label = getIncrementedName<IPropertyDefinition>(label || "", self.propertyDefinitionLabels);
      }

      if (self.propertyDefinitionMap.has(id)) {
        return self.propertyDefinitionMap.get(id);
      }

      const propertyDefinition = self.propertyDefinitionMap.put({
        id,
        label,
        dataType,
        defaultPropertyGroup,
        unit,
        orderIndex: getNextOrderIndex(self.propertyDefinitions),
      });

      if (notify) {
        const res = yield rollupClient.modelingModule.propertyDefinitions.create({ id, label, dataType, defaultPropertyGroup, unit });
        if (res.status !== 201) {
          showApiErrorToast("Error creating property definition", new Error());
          self.deletePropertyDefinition(propertyDefinition, false);
          return undefined;
        }
      }
      return propertyDefinition;
    }),
    addImport: flow(function* addImport(dto: CreateImportDto, type = ImportExtensionType.CSV): Generator<any, Import | undefined, any> {
      const id = uuidv4();
      const importEntity: IImportSnapshotIn = {
        id,
        originalFileName: dto.originalFileName || "Untitled",
        attachmentId: dto.attachmentId,
        columnMap: dto.columnMap,
        createdBy: appStore.userModel?.id || "",
        createdAt: Date.now(),
        updatedAt: Date.now(),
        type: dto.type,
        logs: [],
        status: ImportStatus.InProgress,
      };

      self.importsMap.put(importEntity);
      const res = yield rollupClient.imports.create({ ...dto, id, workspaceId: self.id }, type);

      if (!res?.id) {
        showApiErrorToast("Error creating import", new Error());
        self.importsMap.delete(id);
        return undefined;
      }

      return res;
    }),
    fetchTableViewConfigs: flow(function* fetchTableViewConfigs() {
      if (self.tableViewConfigs.size) {
        return;
      }

      const res: { data: TableViewConfig[] } = yield rollupClient.tableViewConfigs.list();
      res.data.forEach(config => {
        self.tableViewConfigs.set(config.id, {
          ...config,
          createdAt: convertTimestamp(config.createdAt),
          updatedAt: convertTimestamp(config.updatedAt),
        });
      });
    }),
    fetchImports: flow(function* fetchImports(loadMore?: boolean) {
      if (self.importsMap.size && !loadMore) {
        return;
      }

      const res: TImportsListResponse = yield rollupClient.imports.retrieveList({
        workspaceId: self.id,
        take: 100,
        skip: self.importsMap.size,
      });
      const pendingImports: Import[] = [];

      res.imports.forEach(i => {
        if (i.status !== ImportStatus.PendingApproval) {
          self.importsMap.set(i.id, {
            ...i,
            createdAt: convertTimestamp(i.createdAt),
            updatedAt: convertTimestamp(i.updatedAt),
          });
        } else {
          pendingImports.push(i);
        }
      });

      self.deleteUnsavedImports(pendingImports.filter(i => i.createdBy === appStore.userModel?.id));
      self.hasMoreImports = res.hasMore;
    }),
    addNewTableViewConfig: flow(function* addNewTableViewConfig(
      config = defaultEmptyConfigString,
      notify = true,
      propsLabel = "",
      id = uuidv4()
    ): Generator<any, any | undefined, any> {
      const label = propsLabel || getIncrementedName(defaultTableViewLabel, self.tableViewConfigsLabels);
      const idExists = self.tableViewConfigs.get(id);

      if (idExists) {
        showApiErrorToast("Error creating table view config. Already exists.", new Error());
        return undefined;
      }

      const addedTableViewConfig = self.tableViewConfigs.put({
        id,
        label,
        config,
      });

      if (notify) {
        appStore.env.setActiveTableConfigId(id);
        const res: AxiosResponse<TableViewConfig> = yield rollupClient.tableViewConfigs.create({
          id,
          label,
          config,
        });

        if (res.status !== 201) {
          showApiErrorToast("Error creating table view config", new Error());
          self.deleteTableViewConfig(addedTableViewConfig, false);
          return undefined;
        }
      }
    }),
    addNewInterface: flow(function* addNewInterface(
      parentBlock: IBlock,
      label?: string,
      description?: string,
      interfaceType = InterfaceType.data,
      id = uuidv4(),
      notify = true
    ): Generator<any, string | undefined, any> {
      if (self.interfaceMap.has(id)) {
        return id;
      }
      if (!parentBlock?.replicated) {
        return undefined;
      }

      const addedInterface = self.interfaceMap.put({
        id,
        label,
        description,
        interfaceType,
        parentBlock: parentBlock.id,
        replicated: !notify,
      });

      // Append to block's reference list
      if (parentBlock.interfaces) {
        parentBlock.interfaces.push(addedInterface);
      } else {
        parentBlock.interfaces = cast([addedInterface.id]);
      }

      if (notify) {
        try {
          const res = yield rollupClient.interfaces.create({
            id,
            parentBlock: parentBlock.id,
            label,
            description,
            interfaceType,
          });
          if (res.status === 201) {
            addedInterface.setReplicated();
          } else {
            showApiErrorToast("Error creating interface", new Error());
            self.deleteInterface(addedInterface, false);
            return undefined;
          }
        } catch (err) {
          showApiErrorToast("Error creating interface", err as Error);
          console.warn(err);
          return undefined;
        }
      }
      return addedInterface?.id;
    }),
    addAttachmentsToBlock(blockId: string, attachmentIds: string[]) {
      const block = self.blockMap.get(blockId);
      const attachments = attachmentIds.map(id => self.attachments.get(id));
      if (!block || !attachments) {
        return;
      }
      attachments.forEach(attachment => {
        if (attachment) {
          block.addAttachmentRef(attachment.id);
        }
      });
    },
    removeAttachmentsFromBlock(blockId: string, attachmentIds: string[]) {
      const block = self.blockMap.get(blockId);
      const attachments = attachmentIds.map(id => self.attachments.get(id));
      if (!block || !attachments) {
        return;
      }
      attachments.forEach(attachment => {
        if (attachment) {
          block.removeAttachmentRef(attachment.id);
        }
      });
    },
    getAttachmentById(id: string): IAttachment | undefined {
      return self.attachments.get(id);
    },
    deleteAttachment(attachmentToDelete: IAttachment, notify?: boolean): boolean | undefined {
      return self.attachments.delete(attachmentToDelete, notify);
    },
    duplicateBlock: flow(function* duplicateBlock(srcId?: string, destId?: string): Generator<any, boolean, any> {
      // Can't copy root blocks or undefined blocks
      if (!srcId || !destId || srcId === self.rootBlock.id) {
        console.warn("Can't copy root blocks or undefined blocks");
        return false;
      }

      const srcBlock = self.blockMap.get(srcId);
      const destBlock = self.blockMap.get(destId);

      if (!srcBlock || !destBlock) {
        console.warn("Can't find source or destination");
        showApiErrorToast("Error duplicating block: Can't find source or destination block");
        return false;
      }

      // Avoid circular references
      if (srcBlock === destBlock || destBlock.parentBlock === srcBlock || destBlock.pathIds.includes(srcId)) {
        console.warn("Avoid circular references");
        showApiErrorToast("Error duplicating block: Pasting block here would create a circular reference");
        return false;
      }

      // Ensure both blocks have the same workspace
      if (parentWorkspace(srcBlock) !== parentWorkspace(destBlock)) {
        showApiErrorToast("Error duplicating block: Can't paste block to a different workspace");
        return false;
      }

      try {
        console.debug(`Duplicating block ${srcBlock.label} (${srcBlock.id}) to ${destBlock.label} (${destBlock.id})`);
        const { status, data } = yield rollupClient.modelingModule.blocks.duplicate(srcBlock.id, destBlock.id).catch((err: Error) => {
          showApiErrorToast("Error duplicating block", err);
        });
        if (status === 201 && data) {
          return self.populateNewBlocks(data.blocks, data.propertyInstances, data.statusInstances);
        } else {
          return false;
        }
      } catch (e) {
        return false;
      }
    }),
    addOrUpdateDataSource(dataSource: DataSource) {
      self.dataConnection.addOrUpdateDataSource(dataSource);
    },
    removeDataSource(id: string) {
      self.dataConnection.removeDataSource(id);
    },
    addOrUpdateDataLink(dataLink: DataLink, notify?: boolean) {
      return self.dataConnection.addOrUpdateDataLink(dataLink, notify);
    },
    deleteImport(id: string) {
      try {
        rollupClient.imports.delete(id);
        self.importsMap.delete(id);
      } catch (err) {
        showApiErrorToast("Error deleting unsaved import", err as Error);
        console.warn(err);
        return false;
      }
    },
    deleteDataLink(dataLinkId: string, notify?: boolean) {
      return self.dataConnection.deleteDataLink(dataLinkId, notify);
    },
    deleteUnusedDataLinks() {
      return self.dataConnection.deleteUnusedDataLinks();
    },
  }))
  .views(self => ({
    get listTableViewConfigs(): ITableViewConfig[] {
      return toArray<ITableViewConfig>(self.tableViewConfigs);
    },
    get autoCompleteOptions() {
      const entries: AutoCompleteEntry[] = [];
      for (const [id, p] of self.propertyInstanceMap) {
        if (!p.propertyDefinition || !p.parentBlock) {
          continue;
        }
        const path = p.parentBlock.path ?? "";
        entries.push({
          id: id,
          label: p.propertyDefinition.label,
          path,
          queryString: path?.toLowerCase() + p.propertyDefinition.label,
        });
      }

      const fuse = new Fuse(entries, {
        isCaseSensitive: false,
        findAllMatches: true,
        keys: ["path", "label"],
        threshold: 0.8,
        ignoreLocation: true,
      });

      return { entries, fuse };
    },
    get propertyDefinitionAutoComplete() {
      const entries: LabeledEntity[] = [];
      for (const [, p] of self.propertyDefinitionMap) {
        if (!p.id || !p.label) {
          continue;
        }
        entries.push({
          id: p.id,
          label: p.label,
          description: `${p.dataType} (${p.unit || "no unit"}) [${p.defaultPropertyGroup ?? "Ungrouped"}]`,
        });
      }

      return entries;
    },
    get hierarchicalGraph() {
      if (!self.rootBlock) {
        return undefined;
      }

      const graph = new DirectedGraph<BlockNode>();
      fillHierarchicalGraph(self.rootBlock, graph);
      return graph;
    },
    get dependencyGraph() {
      const graph = new DirectedGraph<DependencyNode, DependencyEdge>();
      for (const [, prop] of self.propertyInstanceMap) {
        fillDependencyGraph(self, prop, graph);
      }
      return graph;
    },
    get graphMap() {
      // Split graph into its individual components
      const subGraphs = separateGraph(this.dependencyGraph);
      const graphMap = new Map<string, { graph: DependencyGraph; isCyclic: boolean }>();
      // Map each property instance ID to its subgraph
      for (const graph of subGraphs) {
        const isCyclic = hasCycle(graph);
        graph.forEachNode(id => {
          graphMap.set(id, { graph: graph as DependencyGraph, isCyclic });
        });
      }
      return graphMap;
    },
    get modelStatusDefinitions() {
      return self.statusDefinitions.slice().filter(def => !def.bomTableId);
    },
  }))
  .actions(self => ({
    initiateFileUploadOnDrop(fileList: FileList, blockId: string) {
      if (fileList.length) {
        if (fileList.length === 1 && isHoopsType(fileList[0])) {
          appStore.ui.setCadFileUploadData({ fileList, blockId });
        } else {
          appStore.orgModel.uploads.addNewFileUpload({ blockId, files: fileList, workspaceId: self.id });
        }
      }
    },
    moveStatusDefinition(srcId: string, destId: string, notify = true) {
      if (moveOrderIndexedItem(self.statusDefinitions, srcId, destId) && notify) {
        rollupClient.statusDefinitions.reorder(srcId, destId);
      }
    },
    movePropertyDefinition(srcId: string, destId: string, notify = true) {
      if (moveOrderIndexedItem(self.propertyDefinitions, srcId, destId) && notify) {
        rollupClient.modelingModule.propertyDefinitions.reorder(srcId, destId);
      }
    },
    addDetachedRow: flow(function* (label: string, bomTable: IBomTable) {
      const blockId: string = yield self.addNewBlock(undefined, label);
      if (!blockId) {
        return undefined;
      }
      const block = getBlockById(blockId);
      if (block) {
        bomTable.addRow(block);
      }
      return blockId;
    }),
  }));

export function subscribeToWorkspaceEvents(socket: Socket) {
  socket.on("updateWorkspace", (data: { workspaceId: string; updateWorkspaceDto: WorkspaceUpdateDto }) => {
    if (data.workspaceId === appStore.workspaceModel?.id) {
      appStore.workspaceModel.patch(data.updateWorkspaceDto);
    }
  });
}

export function subscribeToDataSourceEvents(socket: Socket) {
  socket.on("createDataSource", (data: { dataSource: DataSource }) => {
    appStore.workspaceModel?.addOrUpdateDataSource(data.dataSource);
  });
  socket.on("updateDataSource", (data: { dataSource: DataSource }) => {
    appStore.workspaceModel?.addOrUpdateDataSource(data.dataSource);
  });
  socket.on("deleteDataSource", (data: { dataSourceId: string }) => {
    appStore.workspaceModel?.removeDataSource(data.dataSourceId);
  });

  socket.on("createDataLink", (data: { dataLink: DataLink }) => {
    appStore.workspaceModel?.addOrUpdateDataLink(data.dataLink, false);
  });

  socket.on("getDataLinkValue", (data: { dataLink: DataLink }) => {
    appStore.workspaceModel?.addOrUpdateDataLink(data.dataLink, false);
  });

  socket.on("deleteDataLink", (data: { dataLinkId: string }) => {
    appStore.workspaceModel?.deleteDataLink(data.dataLinkId, false);
  });

  socket.on("deleteUnusedDataLinks", (data: { ids: string[] }) => {
    if (!data.ids?.length) {
      return;
    }
    for (const id of data.ids) {
      appStore.workspaceModel?.deleteDataLink(id, false);
    }
  });
}

export interface IWorkspace extends Instance<typeof WorkspaceStore> {}

export interface IWorkspaceSnapshotIn extends SnapshotIn<typeof WorkspaceStore> {}

export interface IWorkspaceSnapshotOut extends SnapshotOut<typeof WorkspaceStore> {}
