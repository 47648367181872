import { Intent } from "@blueprintjs/core";
import { Instance, types } from "mobx-state-tree";

import { EFeedbackType } from "@components/FeedbackDialog/types";
import { showApiErrorToast, showToast } from "@components/UiLayers/toaster";

import { IBebSession, IRecordingSessionData, ISessionData } from "../lib/BirdEatsBug/types";
import { trackSegmentEvent } from "../lib/Segment";

export const FeedbackStore = types
  .model("Feedback", {
    id: types.optional(types.identifier, ""),
    screenshotUrl: types.optional(types.string, ""),
    recordingSessionData: types.optional(types.string, ""),
    collectionId: types.optional(types.string, EFeedbackType.PROBLEM),
    loading: types.optional(types.boolean, false),
    visible: types.optional(types.boolean, false),
  })
  .actions(self => {
    return {
      setLoading(loading: boolean) {
        self.loading = loading;
      },
      setScreenshotUrl(url = "") {
        self.screenshotUrl = url;
      },
      resetMediaData() {
        self.screenshotUrl = "";
        self.recordingSessionData = "";
      },
      setRecordingSessionData(recordingSessionData = "") {
        self.recordingSessionData = recordingSessionData;
      },
      getRecordingSessionData(): IRecordingSessionData {
        const recordingSessionData: IRecordingSessionData = {
          events: [],
          domEvents: [],
        };

        if (self.recordingSessionData) {
          const parsedRecordingSessionData: IRecordingSessionData = JSON.parse(self.recordingSessionData);
          recordingSessionData.domEvents = parsedRecordingSessionData.domEvents;
          recordingSessionData.events = parsedRecordingSessionData.events;
          recordingSessionData.networkRequests = parsedRecordingSessionData.networkRequests;
        }

        return recordingSessionData;
      },
      setCollectionId(collectionId: EFeedbackType) {
        self.collectionId = collectionId;
      },
      hide() {
        self.visible = false;
      },
      show() {
        trackSegmentEvent("user-feedback:open-dialog");
        self.visible = true;
      },
      startRecording() {
        if (window.birdeatsbug && window.birdeatsbug.isBrowserSupported) {
          window.birdeatsbug.startRecording();
        }
      },
    };
  })
  .actions(self => {
    return {
      uploadSessionData(uploaderEmail: string, title: string, description: string) {
        trackSegmentEvent("user-feedback:submit", { uploaderEmail, title, description });
        self.hide();
        showToast("We are uploading your feedback");
        try {
          const createdSessionPromise = window.birdeatsbug?.createSessionIfRequired();

          createdSessionPromise?.then((createdSession: IBebSession) => {
            const session = {
              ...createdSession,
              collectionId: self.collectionId,
              uploaderEmail,
              title,
              description,
            };

            const sessionData: ISessionData = {
              session,
              ...self.getRecordingSessionData(),
              screenshot: undefined,
            };

            if (self.screenshotUrl) {
              sessionData.screenshot = self.screenshotUrl;
            }

            if (self.recordingSessionData) {
              const parsedRecordingSessionData: IRecordingSessionData = JSON.parse(self.recordingSessionData);
              sessionData.domEvents = parsedRecordingSessionData.domEvents;
              sessionData.events = parsedRecordingSessionData.events;
              if (parsedRecordingSessionData.networkRequests) {
                sessionData.networkRequests = parsedRecordingSessionData.networkRequests;
              }
            }

            trackSegmentEvent("user:feedback", {
              title: sessionData.session.title,
              description: sessionData.session.description,
            });

            window.birdeatsbug?.uploadSession(sessionData).then(() => {
              showToast("Feedback successfully uploaded!", Intent.SUCCESS);
              self.resetMediaData();
            });
          });
        } catch (err: any) {
          showApiErrorToast("Error sending feedback", err);
        }
      },
    };
  });

export interface IFeedbackStore extends Instance<typeof FeedbackStore> {}
