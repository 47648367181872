import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { getLastElement } from "../../Utilities/Map";

interface IUseSelectInitialDocumentParams<T extends object = { id: string }> {
  documentUrl: string;
  documentPageMap?: Map<number | string, T>;

  setActiveDocument(id: string | number): void;
}

export const useSelectInitialDocument = (params: IUseSelectInitialDocumentParams) => {
  const { workspaceId } = useParams();
  const navigate = useNavigate();
  const { documentUrl, documentPageMap, setActiveDocument } = params;

  useEffect(() => {
    const firstDoc = documentPageMap && getLastElement(documentPageMap);
    if (firstDoc) {
      setActiveDocument(firstDoc.id);
      navigate(`/workspaces/${workspaceId}/${documentUrl}/${firstDoc.id}`);
    } else {
      navigate(`/workspaces/${workspaceId}/${documentUrl}`);
    }
  }, [documentPageMap, documentUrl, navigate, setActiveDocument, workspaceId]);
};
