import { AnalyticsBrowser } from "@segment/analytics-next";

import appStore from "@store/AppStore";

const SEGMENT_WRITE_KEY = import.meta.env.VITE_SEGMENT_WRITE_KEY;
const IGNORED_ORG_SLUGS = ["rollup-dev", "rollup-staging", "rollup", "rollup-testing"];
const IGNORED_EMAIL_SUFFIX = "@rollup.ai";

export type SegmentUser = {
  id: string;
  name?: string;
  email?: string;
  role?: string;
  orgId: string;
  orgName: string;
  domain?: string;
};

export const analytics = new AnalyticsBrowser();

export function initSegment() {
  if (import.meta.env.VITE_ENV_TYPE !== "production" && import.meta.env.VITE_ENV_TYPE !== "staging") {
    console.debug("Skipping Segment init in non-production environment");
    return;
  }

  if (!SEGMENT_WRITE_KEY) {
    console.warn("Missing VITE_SEGMENT_WRITE_KEY environment variable");
    return;
  }

  analytics.load({ writeKey: SEGMENT_WRITE_KEY });
}

export function setSegmentUser({ id, name, email, role, orgId, orgName, domain }: SegmentUser) {
  if (orgName && IGNORED_ORG_SLUGS.includes(orgName)) {
    return;
  } else if (email?.endsWith(IGNORED_EMAIL_SUFFIX)) {
    return;
  }

  analytics.identify(id, { name, role, email }).catch(console.error);
  analytics.group(orgId, { name: orgName, domain }).catch(console.error);
}

export function trackSegmentEvent(event: string, properties?: any) {
  const orgName = appStore.orgModel?.info?.slug;
  const userEmail = appStore.userModel?.email;
  if (orgName && IGNORED_ORG_SLUGS.includes(orgName)) {
    return;
  } else if (userEmail?.endsWith(IGNORED_EMAIL_SUFFIX)) {
    return;
  }

  analytics
    .track(event, properties, { context: { groupId: appStore.orgModel?.info?.id, userId: appStore.userModel?.id } })
    .catch(console.error);
}
