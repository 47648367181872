import { MouseEvent, useState } from "react";
import { Menu, NonIdealState, NonIdealStateProps, PopoverProps, TooltipProps } from "@blueprintjs/core";
import { BlueprintIcon } from "@ui/BlueprintIcon";
import classNames from "classnames";
import { observer } from "mobx-react";

import { IButtonProps } from "@components/Button/Button";
import { MenuItem } from "@components/MenuItem";
import { PopupMenu } from "@components/Shared/PopupMenu";
import { ISelectOption } from "@rollup-types/selection";
import { isComplexOption } from "@utilities";
import { IE2EIdentifiersParam } from "@utilities/E2EUtils";

import styles from "./MultiselectDropdown.module.scss";

interface IMultiselectDropdownProps<T extends ISelectOption = ISelectOption> extends IE2EIdentifiersParam {
  options: T[];
  className?: string;
  selected?: T[];
  placeholder?: string;
  popupMenuClassName?: string;
  popoverProps?: PopoverProps;
  buttonProps?: Omit<IButtonProps, "e2eIdentifiers">;
  tooltipProps?: Omit<TooltipProps, "content">;
  disabled?: boolean;
  nonIdealStateProps?: NonIdealStateProps;
  onToggle(value: T): void;
}

const MultiselectDropdown = <T extends ISelectOption>(props: IMultiselectDropdownProps<T>) => {
  const { selected = [], options, placeholder, popoverProps, tooltipProps } = props;
  const { className, buttonProps, e2eIdentifiers, disabled, popupMenuClassName, onToggle } = props;
  const { nonIdealStateProps } = props;
  const selectedValues = selected.map(opt => (isComplexOption(opt) ? opt.value : opt));
  const [isOpen, setIsOpen] = useState(false);
  const filteredOptions = options.filter(opt => !isComplexOption(opt) || !opt.isEnabled || opt.isEnabled());

  const renderMenu = () => {
    return (
      <Menu>
        {filteredOptions.map(opt => {
          const icon = typeof opt === "string" ? undefined : opt.icon;
          const tooltip = typeof opt === "string" ? undefined : opt.tooltip;
          const label = typeof opt === "string" ? opt : opt.label;
          const value = typeof opt === "string" ? opt : opt.value;

          const handleClick = (e: MouseEvent) => {
            e.stopPropagation();
            onToggle(opt);
          };

          return (
            <MenuItem
              key={value}
              icon={icon}
              text={label}
              tooltip={tooltip}
              tooltipProps={tooltipProps}
              onClick={handleClick}
              e2eIdentifiers={`dropdown-menu-item-${value}`}
              selected={selectedValues.includes(value)}
              rightElement={
                <BlueprintIcon className={classNames({ [styles.tickIconHidden]: !selectedValues.includes(value) })} icon="tick" />
              }
            />
          );
        })}
      </Menu>
    );
  };

  const renderPopupContent = () => {
    if (!filteredOptions.length) {
      return (
        <NonIdealState
          className={styles.multiselectDropdownNonIdealState}
          iconSize={20}
          icon="search-template"
          title="No options available"
          {...nonIdealStateProps}
        />
      );
    }

    return renderMenu();
  };

  const selectedOptLabel = selected.map(opt => (isComplexOption(opt) ? opt.label : opt)).join(", ");
  const buttonText = selectedOptLabel || placeholder;

  return (
    <div className={className}>
      <PopupMenu
        className={popupMenuClassName}
        popoverProps={popoverProps}
        content={renderPopupContent()}
        disabled={disabled}
        buttonText={buttonText}
        buttonProps={{
          ...buttonProps,
          ellipsizeText: true,
          rightIcon: isOpen ? "chevron-up" : "chevron-down",
          className: classNames(styles.multiselectDropdownButton, { [styles.multiselectDropdownButtonRightIconOnly]: !buttonText }),
        }}
        e2eIdentifiers={e2eIdentifiers}
        onOpening={() => setIsOpen(true)}
        onClosing={() => setIsOpen(false)}
      />
    </div>
  );
};

export default observer(MultiselectDropdown);
