import { observer } from "mobx-react";

import { EntityContextMenu } from "@components/EntityContextMenu";
import appStore from "@store/AppStore";
import { IReport } from "@store/ReportsStore";
import { EntityType } from "@store/types";

interface IReportContextMenu {
  report: IReport;
  templateNav?: boolean;
  onDelete: (report: IReport) => void;
  onEnter?: () => void;
}

const ReportContextMenu = (props: IReportContextMenu) => {
  const { report, onDelete, templateNav, onEnter } = props;

  const handleDuplicate = () => {
    appStore.workspaceModel?.duplicateReport(report.id);
  };

  const handleUpdate = (label: string) => {
    report.update({ label });
  };

  const handleDelete = () => {
    onDelete(report);
  };

  const handleCreateTemplate = () => {
    appStore.ui.setCreateReportTemplateDialog(report.id);
  };

  return (
    <EntityContextMenu
      node={report}
      entityType={EntityType.Report}
      defaultName={report.label}
      onNameChange={handleUpdate}
      onDelete={handleDelete}
      onDuplicate={templateNav ? undefined : handleDuplicate}
      onCreateTemplate={templateNav ? undefined : handleCreateTemplate}
      onEnter={onEnter}
    />
  );
};

export default observer(ReportContextMenu);
