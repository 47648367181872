import { isUniqueId } from "@rollup-io/engineering";
import short from "short-uuid";

import { showToast } from "@components/UiLayers/toaster";

export function truncate(input: string, cutoff = 10) {
  if (input.length > cutoff) {
    // Trim string by an additional 3 characters to make room for the ellipses
    return input.substring(0, cutoff - 3) + "...";
  }
  return input;
}

export function shortenId(idString?: string) {
  if (idString) {
    // Standard UUID format
    if (isUniqueId(idString)) {
      return short().fromUUID(idString);
    }

    // Auth0 format
    const separatorIndex = idString.lastIndexOf("|");
    if (separatorIndex > 0) {
      return idString.slice(separatorIndex + 1);
    }
  }
  return "";
}

export function moveCaretToEnd(element: HTMLDivElement): void {
  const range = document.createRange();
  const sel = window.getSelection();
  range.selectNodeContents(element);
  range.collapse(false);
  sel?.removeAllRanges();
  sel?.addRange(range);
}

export function maskOutText(text: string, leadingChars = 0, trailingChars = 0) {
  const maskChar = "\u2022";
  const upperLimit = text.length - trailingChars;
  return text.replace(/./g, (char, index) => (index >= leadingChars && index < upperLimit ? maskChar : char));
}

export async function copyToClipboard(value: string | object) {
  if (typeof value !== "string") {
    value = JSON.stringify(value);
  }
  // Use the new Clipboard API when available
  if (navigator.clipboard) {
    await navigator.clipboard.writeText(value);
  } else {
    const tempTextArea = document.createElement("textarea");
    tempTextArea.value = value;
    document.body.appendChild(tempTextArea);
    tempTextArea.focus();
    tempTextArea.select();
    document.execCommand("copy");
    document.body.removeChild(tempTextArea);
  }
}

export const createCopyToClipboardHandler = (data: any, name: string) => async () => {
  try {
    await copyToClipboard(data);
    showToast(`Copied ${name} to clipboard`, "success", "info-sign");
  } catch (err) {
    console.error("Error while copying to clipboard", err);
    return;
  }
};

export const isUrl = (urlString: string): boolean => {
  if (!urlString) {
    return false;
  }

  // example from https://www.freecodecamp.org/news/how-to-validate-urls-in-javascript/
  const pattern = new RegExp(
    "^(https?:\\/\\/)?" + // protocol
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
      "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
      "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
      "(\\#[-a-z\\d_]*)?\\/?$", // fragment locator
    "i"
  );

  return pattern.test(urlString);
};

export const findLongestPrefix = (stringList: string[]): string => {
  if (!stringList || stringList.length < 2) {
    return "";
  }
  // Find first and last string in sorted list. If they are equal up to i,
  // then logically all the ones in between are also equal.
  const sorted = stringList.sort();
  const first = sorted[0];
  const last = sorted[sorted.length - 1];
  const minLength = Math.min(first.length, last.length);
  let i = 0;
  while (i < minLength && first.charAt(i) === last.charAt(i)) {
    i++;
  }
  return first.substring(0, i);
};

export const getPluralOrSingularString = (amount: number, text: string): string => {
  return amount > 1 && amount !== 0 ? `${text}s` : text;
};

// Join strings with a separator, but only if the value is an array
export const joinStrings = (val: string | string[] = "", separator = ", "): string => {
  if (Array.isArray(val)) {
    return val.join(separator);
  }
  return val;
};

export const findNextAvailableName = (name: string, existingNames?: string[]): string => {
  if (!existingNames?.length) {
    return name;
  }

  let newName = name;
  let index = 1;
  while (existingNames.includes(newName)) {
    newName = `${name} (${index})`;
    index++;
  }
  return newName;
};
