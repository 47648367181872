import { useNavigate, useParams } from "react-router-dom";
import { NonIdealState } from "@blueprintjs/core";
import Text, { TextVariant } from "@ui/Text";
import { observer } from "mobx-react";

import { Button } from "@components/Button";
import { IntegrationCallout } from "@components/IntegrationCallout";
import { IntegrationProvider } from "@rollup-api/models/integrations";
import appStore from "@store/AppStore";
import { IIntegration } from "@store/IntegrationStore";

import NativeIntegrationConfig from "../internalIntegrations/NativeIntegrationConfig";

import "./IntegrationConfig.scss";

const IntegrationConfig = () => {
  const { integrationId } = useParams();
  const navigate = useNavigate();
  const integration: IIntegration | undefined = appStore.settingsModel?.integrationsMap.get(integrationId || "");
  const slug = integration?.slug as IntegrationProvider | undefined;

  if (!integrationId || !integration || !integration.isActive) {
    return (
      <NonIdealState title="Integration not found" description="We can't find an integration with the specified ID" icon="warning-sign" />
    );
  }

  if (integration.isNative && slug && Object.values(IntegrationProvider).includes(slug)) {
    return <NativeIntegrationConfig integration={integration} />;
  }

  const renderInfoCallout = () => {
    if (!integration.connection) {
      return null;
    }

    return <IntegrationCallout integration={integration.connection} />;
  };

  const renderBuildBy = () => {
    if (integration.publisherLink) {
      return (
        <a href={integration.publisherLink} target="_blank" rel="noreferrer">
          {integration.publisher}
        </a>
      );
    }

    return <span>{integration.publisher}</span>;
  };

  return (
    <div className="settings-layout--content">
      <Button
        minimal
        className="integration-config--back"
        e2eIdentifiers="integrations_back_btn"
        onClick={() => navigate("/settings/marketplace")}
        icon="arrow-left"
      >
        Back
      </Button>
      <div className="integration-config">
        <div>
          {integration.logoUrl ? (
            <img className="integration-config--img" src={integration.logoUrl} alt={integration.name} width={128} height={128} />
          ) : (
            <div className="integration-config--img-mock" />
          )}
          <Button
            disabled={!integration.isActive}
            intent={integration.buttonIntent}
            fill
            large
            className="integration-config--cta"
            e2eIdentifiers="integration_cta"
            onClick={integration.onClick}
          >
            {integration.buttonLabel}
          </Button>
        </div>
        <div className="integration-config--wrap">
          <div className="integration-config--header">
            <Text variant={TextVariant.H1}>{integration.name}</Text>
            <div>
              {integration.types?.map(type => (
                <span key={type} className="integration-config--type">
                  {type}
                </span>
              ))}
            </div>
          </div>
          {integration.publisher && (
            <div className="integration-config--publisher">
              <b>Built by: </b>
              {renderBuildBy()}
            </div>
          )}
          <div className="integration-config--content">
            {integration.connection ? <div className="integration-config--footer">{renderInfoCallout()}</div> : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default observer(IntegrationConfig);
