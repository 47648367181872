import { FormGroup as BPFormGroup, FormGroupProps } from "@blueprintjs/core";
import classNames from "classnames";
import { observer } from "mobx-react";

import styles from "./FormGroup.module.scss";

interface IFormGroupProps extends FormGroupProps {
  required?: boolean;
}

const FormGroup = (props: IFormGroupProps) => {
  const { className, required, ...restProps } = props;
  return (
    <BPFormGroup
      {...restProps}
      label={required ? <>{restProps.label}*</> : restProps.label}
      className={classNames(styles.formGroup, className)}
      contentClassName={classNames(styles.formGroupContent, restProps.contentClassName)}
    />
  );
};

export default observer(FormGroup);
