import { MaybeElement } from "@blueprintjs/core/src/common/props";
import { IconName } from "@blueprintjs/icons";

export enum EditorProperty {
  Attachment = "attachment",
  Bold = "bold",
  BulletList = "bulletList",
  Citation = "citation",
  Code = "code",
  CodeBlock = "codeBlock",
  Image = "image",
  Italic = "italic",
  Link = "link",
  OrderedList = "orderedList",
  Strike = "strike",
  Underline = "underline",
}

export interface IEditorPropertyData {
  tooltip: string;
  icon?: IconName | MaybeElement;
}

export const editorPropertyDataMap: Record<EditorProperty, IEditorPropertyData> = {
  [EditorProperty.Attachment]: {
    tooltip: "Upload attachment",
    icon: "paperclip",
  },
  [EditorProperty.Bold]: {
    tooltip: "Bold",
    icon: "bold",
  },
  [EditorProperty.BulletList]: {
    tooltip: "Bullet list",
    icon: "properties",
  },
  [EditorProperty.Citation]: {
    tooltip: "Citation",
    icon: "citation",
  },
  [EditorProperty.Code]: {
    tooltip: "Code",
    icon: "code",
  },
  [EditorProperty.CodeBlock]: {
    tooltip: "Code block",
    icon: "code-block",
  },
  [EditorProperty.Image]: {
    tooltip: "Add image",
    icon: "media",
  },
  [EditorProperty.Italic]: {
    tooltip: "Italic",
    icon: "italic",
  },
  [EditorProperty.Link]: {
    tooltip: "Add link",
    icon: "link",
  },
  [EditorProperty.OrderedList]: {
    tooltip: "Ordered list",
    icon: "numbered-list",
  },
  [EditorProperty.Strike]: {
    tooltip: "Strikethrough",
    icon: "strikethrough",
  },
  [EditorProperty.Underline]: {
    tooltip: "Underline",
    icon: "underline",
  },
};
