import appStore from "@store/AppStore";
import { IWorkspace } from "@store/WorkspaceStore";

export const getWorkspaceById = async (workspaceId?: string): Promise<IWorkspace | null> => {
  if (!workspaceId) {
    return appStore.workspaceModel;
  }

  if (workspaceId === appStore?.workspaceModel?.id) {
    return appStore.workspaceModel;
  }
  if (workspaceId === appStore?.temporaryWorkspace?.id) {
    return appStore.temporaryWorkspace;
  }
  const temporaryWorkspace = await appStore.loadTemporaryWorkspaceFromDatabase(workspaceId);
  if (temporaryWorkspace) {
    return temporaryWorkspace;
  }

  return null;
};
