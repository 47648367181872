import { ChangeEvent } from "react";
import classNames from "classnames";

type Props = {
  value?: number;
  className?: string;
  disabled?: boolean;
  onChange: (value: number) => void;
};

import "./PositiveNumberInput.scss";

const PositiveNumberInput = (props: Props) => {
  const { value, onChange, className, disabled } = props;

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    onChange(parseInt(e.target.value, 10));
  };

  return (
    <input
      disabled={disabled}
      className={classNames("positive-number-input", className)}
      pattern="^[0-9]*$"
      type="number"
      min={0}
      value={value}
      onChange={handleChange}
    />
  );
};

export default PositiveNumberInput;
