import { useState } from "react";
import { InputGroup, PopoverPosition } from "@blueprintjs/core";
import { Text, TextVariant } from "@ui/Text";
import kebabCase from "lodash/kebabCase";
import { observer } from "mobx-react";

import { Button } from "@components/Button";
import { ColorPicker } from "@components/ColorPicker";
import { customIconsList } from "@components/CustomIcon";
import { devIconNamesList } from "@components/DevIcon/DevIcon";
import { Icon } from "@components/Icon";
import { Color } from "@rollup-types/colors";
import { IconSource, IIcon, IIconSourceMap } from "@rollup-types/icons";
import appStore from "@store/AppStore";
import { IE2EIdentifiers } from "@utilities/E2EUtils";
import { isColorIcon } from "@utilities/Icon";
import { blueprintIconNameList } from "@utilities/Icons";

import styles from "./IconSelectorTab.module.scss";

interface IIconSelectorTabProps {
  iconSource: IconSource;
  color?: Color;
  hideColorPicker?: boolean;
  onColorChange(value: Color): void;
  onIconChange(icon: IIcon): void;
}

const iconListMap: IIconSourceMap = {
  [IconSource.Blueprint]: blueprintIconNameList.map(name => ({ source: IconSource.Blueprint, name })),
  [IconSource.DevIcon]: devIconNamesList.map(name => ({ source: IconSource.DevIcon, name })),
  [IconSource.Custom]: customIconsList.map(name => ({ source: IconSource.Custom, name })),
};

const IconSelectorTab = (props: IIconSelectorTabProps) => {
  const { iconSource, color, hideColorPicker, onColorChange, onIconChange } = props;
  const [filterString, setFilterString] = useState("");

  const iconList = iconListMap[iconSource] ?? [];
  const filteredIconNames = iconList.filter(icon => icon.name.includes(filterString)).slice(0, 72);
  const recentIconsList = appStore.env.getRecentIcons(iconSource);
  const filteredRecentIconNames = recentIconsList.filter(icon => icon.name.includes(filterString));

  const handleIconSelect = (icon: IIcon, disableReordering?: boolean) => {
    onIconChange?.(icon);
    appStore.env.addRecentIcon(icon, disableReordering);
  };

  const renderButton = (icon: IIcon, e2eIdentifiers: IE2EIdentifiers) => {
    return (
      <Button
        key={icon.name}
        className={styles.iconSelectorTabButton}
        icon={<Icon className={styles.iconSelectorTabIcon} icon={{ ...icon, ...(isColorIcon(icon) ? { color } : {}) }} />}
        onClick={() => handleIconSelect(icon, true)}
        e2eIdentifiers={e2eIdentifiers}
        tooltip={kebabCase(icon.name)}
        tooltipProps={{ position: PopoverPosition.TOP }}
        minimal
      />
    );
  };

  return (
    <div className={styles.iconSelectorTab}>
      {!hideColorPicker && <ColorPicker selected={color} onChange={onColorChange} />}
      <InputGroup autoFocus leftIcon="search" placeholder="Search" value={filterString} onChange={e => setFilterString(e.target.value)} />
      {!!filteredRecentIconNames.length && (
        <>
          <Text variant={TextVariant.Caption}>RECENT</Text>
          <div className={styles.iconSelectorTabGrid}>
            {filteredRecentIconNames.map(icon => renderButton(icon, ["recent-icon", icon.name]))}
          </div>
        </>
      )}
      <Text variant={TextVariant.Caption}>ALL ICONS</Text>
      <div className={styles.iconSelectorTabGrid}>{filteredIconNames.map(icon => renderButton(icon, ["icon", icon.name]))}</div>
    </div>
  );
};

export default observer(IconSelectorTab);
