export enum RollupEditorType {
  p = "P",
  h1 = "H1",
  h2 = "H2",
  h3 = "H3",
  h4 = "H4",
  h5 = "H5",
  h6 = "H6",
  image = "image",
  quote = "quote",
  code = "code",
  table = "table",
  ul = "ul",
  ol = "ol",
  hr = "hr",
  checklist = "checklist",
}
