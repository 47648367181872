import { Menu } from "@blueprintjs/core";
import classNames from "classnames";
import { observer } from "mobx-react";

import { Button } from "@components/Button";
import { CommentIcon, CustomIcon } from "@components/CustomIcon";
import { MenuItem } from "@components/MenuItem";
import { MenuItemDelete } from "@components/MenuItems";
import { Popover } from "@components/Popover";
import appStore from "@store/AppStore";
import { IComment } from "@store/CommentStore";
import { IUser } from "@store/UserStore";
import { createCopyToClipboardHandler } from "@utilities";
import { getDeepLinkUrl } from "@utilities/GetDeepLinkUrl";

import "./CommentContextMenu.scss";

interface CommentContextMenuProps {
  comment: IComment;
  isVisible?: boolean;
  mentionToInReply?: boolean;
  buttonsClassName?: string;
  hideReplyButton?: boolean;
  onEdit: () => void;
  onReply: (replyToUser: IUser, mentionTo: boolean) => void;
  onDelete?: () => void;
}

const CommentContextMenu = ({
  comment,
  isVisible = true,
  mentionToInReply = false,
  buttonsClassName,
  hideReplyButton,
  onEdit,
  onReply,
  onDelete,
}: CommentContextMenuProps) => {
  const copyCommentLinkToClipboard = createCopyToClipboardHandler(getDeepLinkUrl({ commId: comment.id }), "comment link");

  if (!isVisible || comment.pending) {
    return null;
  }

  const handleDelete = async () => {
    const result = await comment.delete();
    if (result) {
      onDelete?.();
    }
  };

  const handleReply = () => {
    const replyToUser = appStore.orgModel.info.userWithId(comment.createdBy);
    if (replyToUser) {
      onReply(replyToUser, mentionToInReply);
    }
  };

  const handleEdit = () => {
    onEdit?.();
  };

  return (
    <div className="comment-context-menu">
      {!hideReplyButton && (
        <Button
          className={classNames(buttonsClassName, "comment-context-menu--reply-button")}
          minimal
          icon={<CustomIcon icon={CommentIcon.Reply} />}
          intent="none"
          onClick={handleReply}
          e2eIdentifiers="comment-context-reply"
          tooltip="Reply"
        />
      )}
      {comment.isMyComment && (
        <Popover
          position="top-right"
          content={
            <Menu>
              <MenuItem text="Copy link" icon="link" onClick={copyCommentLinkToClipboard} e2eIdentifiers="copy-link" />
              <MenuItem onClick={handleEdit} icon="edit" text="Edit" e2eIdentifiers="edit" />
              <MenuItemDelete onDelete={handleDelete} />
            </Menu>
          }
        >
          <Button className={buttonsClassName} minimal icon="more" intent="none" e2eIdentifiers="comment-actions-menu" />
        </Popover>
      )}
    </div>
  );
};

export default observer(CommentContextMenu);
