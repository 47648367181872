import { Instance, types } from "mobx-state-tree";

export const WorkspacePermissionsStore = types
  // 1
  .model("WorkspacePermissions", {
    isPubliclyViewable: types.optional(types.boolean, false),
    ownerType: types.optional(types.enumeration(["user", "team"]), "team"),
    ownerID: types.optional(types.string, "local"),
  })
  .actions(self => ({
    // Update the label
    setIsPubliclyViewable(type: typeof self.isPubliclyViewable) {
      self.isPubliclyViewable = type;
    },
    setOwnerType(type: typeof self.ownerType) {
      self.ownerType = type;
    },
    setOwnerID(type: typeof self.ownerID) {
      self.ownerID = type;
    },
  }));

export interface IWorkspacePermissions extends Instance<typeof WorkspacePermissionsStore> {}
