import { EntityModelBase } from "@rollup-api/shared/entityModelBase";

export enum BomMetaColumn {
  Id = "id",
  Name = "name",
  PartNumber = "part-number",
  ProductGroup = "product-group",
  Level = "level",
  BlockType = "block-type",
  Notes = "notes",
  File = "file",
  Thumbnail = "thumbnail",
  UnitOfMeasure = "unit-of-measure",
  Multiplicity = "multiplicity",
  Subtotal = "subtotal",
}

export enum BomColumnType {
  Property = "property",
  Status = "status",
  Meta = "meta",
  Unknown = "unknown",
}

export class BomColumn extends EntityModelBase {
  public readonly table!: string;
  public readonly metaColumn?: BomMetaColumn;
  public readonly propertyDefinition?: string;
  public readonly statusDefinition?: string;
  public readonly propertyDefinitionId?: string;
  public readonly statusDefinitionId?: string;
  public readonly visible?: boolean;
  public readonly width?: number;
  public readonly orderIndex!: number;
  public readonly rowGroup!: boolean;
  public readonly createdBy!: string;
  public readonly updatedBy!: string;
}
