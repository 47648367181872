import { NonIdealState } from "@blueprintjs/core";
import classNames from "classnames";
import { observer } from "mobx-react";

import { RollupIcon } from "@assets/icons";

import styles from "./RouterLoading.module.scss";

interface IRouterLoading {
  disableAnimation?: boolean;
}

const RouterLoading = (props: IRouterLoading) => {
  const { disableAnimation } = props;

  return (
    <NonIdealState className={styles.routerLoading}>
      <RollupIcon className={classNames({ [styles.routerLoadingIconAnimation]: !disableAnimation })} />
      <div className={styles.routerLoadingText}>Loading...</div>
    </NonIdealState>
  );
};

export default observer(RouterLoading);
