import { ReactNode } from "react";
import classNames from "classnames";

import "../ImageUploaderCard.module.scss";
import styles from "./ImageUploaderCardOverlay.module.scss";

type Props = {
  showOnHover: boolean;
  children: ReactNode;
};

const ImageUploaderCardOverlay = (props: Props) => {
  const { showOnHover, children } = props;

  return (
    <div
      className={classNames(styles.imageUploaderCardOverlay, {
        [styles.imageUploaderCardOverlayHover]: showOnHover,
        ["backdrop-brightness-50"]: showOnHover,
      })}
    >
      {children}
    </div>
  );
};

export default ImageUploaderCardOverlay;
