import { forwardRef, ReactNode } from "react";
import { Icon, Menu } from "@blueprintjs/core";
import classNames from "classnames";
import { observer } from "mobx-react";

import { Button } from "@components/Button";
import { MenuItem } from "@components/MenuItem";
import { Popover } from "@components/Popover";
import appStore from "@store/AppStore";
import { FeatureFlag } from "@store/FeatureFlagsStore";
import { Text, TextVariant } from "src/ui/Text";

import "./HoopsOverlayWindow.scss";
import "./HoopsViewer.scss";

export interface HoopsFloatingPartWindowProps {
  children?: ReactNode;
}

const HoopsFloatingPartWindow = forwardRef<HTMLDivElement, HoopsFloatingPartWindowProps>((_props, viewerRef) => {
  const { selectedNode } = appStore.env.attachmentViewer ?? {};
  const isFeatureEnabled = appStore.env.featureFlags.enabled(FeatureFlag.HOOPS_FLOATING_PART_VIEWER);

  return (
    <div
      className={classNames("hoops-part-viewer", "hoops-overlay-window", {
        hidden: !selectedNode || !isFeatureEnabled,
      })}
    >
      <div id="HoopsWebSubViewer" className="hoops-sub-viewer" ref={viewerRef} />
      <Text variant={TextVariant.H5}>Selected component properties</Text>
      <Text variant={TextVariant.H4}>Solid</Text>
      <Text>ID: ACME Co. Teamcenter</Text>
      <Text>Last Editor: Steven Smith</Text>
      <Text>Volume: 20cm</Text>
      <Text>Material: Aluminium 1650</Text>
      <Popover
        placement="bottom"
        content={
          <Menu>
            <MenuItem text="STEP" e2eIdentifiers="step" />
            <MenuItem text="STL" e2eIdentifiers="stl" />
            <MenuItem text="SLDPRT" e2eIdentifiers="sldprt" />
            <MenuItem text="IGES" e2eIdentifiers="iges" />
          </Menu>
        }
        renderTarget={({ ref, ...targetProps }) => (
          <Button {...targetProps} ref={ref} fill rightIcon={<Icon size={16} icon="chevron-down" />} e2eIdentifiers="export-file">
            <Text variant={TextVariant.H4}>Export File...</Text>
          </Button>
        )}
      />
    </div>
  );
});

HoopsFloatingPartWindow.displayName = "HoopsFloatingPartWindow";

export default observer(HoopsFloatingPartWindow);
