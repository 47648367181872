import { useCallback, useEffect } from "react";
import { Icon, Tooltip } from "@blueprintjs/core";
import classNames from "classnames";
import { observer } from "mobx-react";

import { Button } from "@components/Button";
import { Popover } from "@components/Popover";
import appStore from "@store/AppStore";
import { ITableViewConfig } from "@store/TableViewConfigStore";

import { defaultTableViewConfigTabId, defaultTableViewLabel, untitledViewLabel } from "./constants";
import { TabNav } from "./TabNav";

import "./TableConfigTabs.scss";

type Props = {
  activeTabId?: string;
  tableViewConfigs?: Array<ITableViewConfig>;
};

const TableConfigTabs = (props: Props) => {
  const { tableViewConfigs, activeTabId } = props;

  useEffect(() => {
    appStore.workspaceModel?.fetchTableViewConfigs();
  }, []);

  const handleAddTab = () => {
    const config = appStore.env.tableView.ui.commonTableColumnsConfig;
    appStore.workspaceModel?.addNewTableViewConfig(config, true, "Untitled View");
  };

  const renderTab = useCallback(
    (tab: ITableViewConfig, isMain?: boolean) => (
      <div
        key={tab.id}
        className={classNames("table-config-tab", { "table-config-tab--active": tab.id === activeTabId })}
        onMouseDown={() => appStore.env.setActiveTableConfigId(tab.id)}
      >
        {isMain && <Icon className="table-config-tab--icon" icon="pin" />}
        <span className={classNames("table-config-tab--title", { "table-config-tab--title-muted": tab.label === untitledViewLabel })}>
          {tab.label}
        </span>
        <Popover key={tab.id} content={<TabNav tab={tab} isMain={isMain} />}>
          <Button
            onClick={e => e.preventDefault()}
            className="table-config-tab--nav"
            small
            minimal
            e2eIdentifiers={["tab_nav"]}
            icon="more"
          />
        </Popover>
      </div>
    ),
    [activeTabId]
  );

  const renderAddMore = () => (
    <div className="table-config-tabs--more-tooltip-container">
      <Tooltip content="Add new view">
        <Button className="table-config-tabs--more" minimal icon="plus" onClick={handleAddTab} e2eIdentifiers="add-tab" />
      </Tooltip>
    </div>
  );

  return (
    <div className="table-config-tabs">
      {/* @todo add reorder - table view configs iteration 3 */}
      {renderTab({ label: defaultTableViewLabel, id: defaultTableViewConfigTabId } as ITableViewConfig, true)}
      {tableViewConfigs?.map(tab => renderTab(tab))}
      {renderAddMore()}
    </div>
  );
};

export default observer(TableConfigTabs);
