import { CreateStatusInstanceDto, StatusInstance, StatusInstanceUpdateDto } from "../models/statusInstance";

import { HttpClient, ParentClient } from "./client";

export class StatusInstances extends HttpClient {
  public constructor(parent: ParentClient) {
    super(parent);
  }

  private get endpoint() {
    return `workspace/${this.parent.workspaceId}/status-instances`;
  }

  public create = (dto: CreateStatusInstanceDto) => {
    return this.instance.post<StatusInstance>(`${this.endpoint}`, dto);
  };

  public update = (id: string, dto: StatusInstanceUpdateDto) => {
    return this.instance.patch<StatusInstance>(`${this.endpoint}/${id}`, dto);
  };

  public delete = (id: string) => {
    return this.instance.delete<StatusInstance>(`${this.endpoint}/${id}`);
  };
}
