import { IDocumentBlock } from "@components/Requirements/RequirementsDocumentView/DocumentEditor/DocumentBlock.types";
import { RollupEditorType } from "@rollup-types/editor";
import appStore from "@store/AppStore";
import { IReportBlock } from "@store/ReportBlockStore";
import { EntityType } from "@store/types";

export const reportBlockToDocumentBlock = (reportBlock: IReportBlock): IDocumentBlock => ({
  id: reportBlock.id,
  type: reportBlock.type,
  label: reportBlock.label,
  parentDocument: reportBlock.parentReport,
  entityType: EntityType.ReportBlock,
  annotationList: reportBlock.annotationList,
  checked: reportBlock.checked,

  setChecked: reportBlock.setChecked,
  updateType: (type: RollupEditorType) => {
    reportBlock.updateType(type);
  },
  updateText: (text: string) => {
    reportBlock.updateText(text);
  },
  delete: () => {
    appStore.workspaceModel?.deleteReport(reportBlock.id);
  },
});
