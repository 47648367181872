import { useEffect } from "react";
import { Outlet, useMatch, useParams } from "react-router-dom";
import { NonIdealState } from "@blueprintjs/core";
import { observer } from "mobx-react";

import { FeatureLayout } from "@components/FeatureLayout";
import { WorkspaceContext } from "@providers/workspace";
import BottomMenuPaneItems from "@router/components/Panes/BottomMenuPaneItems";
import MenuPane from "@router/components/Panes/MenuPane";
import { useMenuPane } from "@router/components/Panes/useMenuPane";
import { RouterLoading } from "@router/elements/RouterLoading";
import { EPageName, useAppNavigate } from "@router/hooks/useAppNavigate";
import NavLinkAsButton from "@router/navigation/NavLinkAsButton";
import appStore, { WorkspaceSyncState } from "@store/AppStore";
import { EPane } from "@store/PaneWidthStore";
import { isDev } from "@utilities/EnvUtils";

import { getMainNavMenuItems } from "../../constants/navigation";

import "./WorkspaceLayout.scss";

function WorkspaceLayout() {
  const { isNavPaneOpen, toggleNavPane } = appStore.env;
  const { open, toggle, minSize, maxSize } = useMenuPane({ isOpen: isNavPaneOpen, toggleIsOpen: toggleNavPane, minExpandedWidth: 180 });
  const { workspaceId } = useParams();
  const { navigateToWorkspace } = useAppNavigate();
  const storeLoading = appStore.ui.syncState === WorkspaceSyncState.Loading;
  const errorLoadingWorkspace = appStore.ui.syncState === WorkspaceSyncState.Error;
  const { recentlyViewedWorkspaces } = appStore.env;

  const navMatch = useMatch(`/workspaces/:workspaceId/:view/*`);
  const currentPage = navMatch?.params.view as EPageName | undefined;
  const { workspaceModel } = appStore;
  const menuItems = getMainNavMenuItems({ workspace: workspaceModel, currentPage, isSidebarOpen: open, toggleSidebar: toggle });

  useEffect(() => {
    if (workspaceId) {
      appStore.loadWorkspaceFromDatabase(workspaceId);
    }
  }, [workspaceId]);

  useEffect(() => {
    if (errorLoadingWorkspace && isDev()) {
      const nextRecentWorkspace = recentlyViewedWorkspaces[0];
      if (nextRecentWorkspace) {
        navigateToWorkspace(nextRecentWorkspace.id);
      }
    }
  }, [errorLoadingWorkspace, navigateToWorkspace, recentlyViewedWorkspaces]);

  if (storeLoading) {
    return <RouterLoading disableAnimation />;
  }

  if (!appStore.workspaceModel?.id) {
    return (
      <NonIdealState
        title="ERROR"
        action={<NavLinkAsButton minimal intent="primary" to="/settings/workspaces" text="Back to Workspaces" end />}
        description={`Can't load workspace with ID: ${workspaceId}`}
      />
    );
  }

  const renderContent = () => {
    if (appStore.env.isSinglePaneMode) {
      return <Outlet />;
    }

    return (
      <FeatureLayout
        paneWidth={appStore.env.paneWidth.nav}
        minSize={minSize}
        maxSize={maxSize}
        isSidePanelOpen={open}
        onPaneWidthChange={(newSize: number) => appStore.env.paneWidth.updatePaneWidth(EPane.NAV, newSize)}
      >
        <MenuPane
          className="workspace-layout--side-menu"
          containerClassName="workspace-layout--side-menu-container"
          open={open}
          title="Navigation"
          toggle={toggle}
          menuItems={menuItems}
          collapsedContent={<BottomMenuPaneItems currentPage={currentPage} minimal />}
        >
          <BottomMenuPaneItems currentPage={currentPage} />
        </MenuPane>
        <Outlet />
      </FeatureLayout>
    );
  };

  return <WorkspaceContext.Provider value={appStore.workspaceModel}>{renderContent()}</WorkspaceContext.Provider>;
}

export default observer(WorkspaceLayout);
