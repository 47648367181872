import { useEffect, useState } from "react";
import { Classes } from "@blueprintjs/core";
import { NodeViewProps, NodeViewWrapper } from "@tiptap/react";
import classNames from "classnames";
import { observer } from "mobx-react";

import { getInfoFromId } from "@components/Reports/Editor/Extentions/Mention/MentionUtils";
import { MentionMode, MentionType } from "@components/Reports/Editor/Extentions/Mention/types";
import appStore from "@store/AppStore";
import { IAttachment } from "@store/AttachmentStore";
import { IWorkspace } from "@store/WorkspaceStore";
import { getAnalysisOutputById, getBlockById, getPropertyInstanceById, getReportById } from "@utilities";
import { getAttachmentById } from "@utilities/Attachment";
import { getReportLink } from "@utilities/LinkUtils";

import AnalysisOutputTag from "./AnalysisOutputTag";
import AttachmentTag from "./AttachmentTag";
import BlockTag from "./BlockTag";
import PropertyInstanceTag from "./PropertyInstanceTag";
import { ReportTag } from "./ReportTag";
import { UnknownTag } from "./UnknownTag";
import { UnknownUserTag } from "./UnknownUserTag";
import UserTag from "./UserTag";

import styles from "./MentionTag.module.scss";

// "id" and "label" attributes are implemented in "Mention" native extension
export interface IMentionTagAttributes {
  id: string;
  label: string;
  showContextMenu?: boolean;
  usePlaceholder?: boolean;
  mode?: MentionMode;
}

interface IMentionTagProps extends Omit<NodeViewProps, "node" | "extension"> {
  node: {
    attrs: IMentionTagAttributes;
  };
  extension: {
    options: {
      displayPath: boolean;
      displayIcon: boolean;
      workspace?: IWorkspace;
      onUpdate?(content: string): void;
    };
  };
}

const MentionTag = (props: IMentionTagProps) => {
  const [attachment, setAttachment] = useState<IAttachment>();
  const { type, id } = getInfoFromId(props.node?.attrs?.id);

  useEffect(() => {
    if (type === MentionType.Attachment) {
      getAttachmentById(id).then(attachment => {
        setAttachment(attachment);
      });
    }
  }, [type, id]);

  const renderMentionNode = () => {
    switch (type) {
      case MentionType.Attachment:
        return attachment ? (
          <AttachmentTag className={styles.tag} labelClassName={styles.tagLabel} attachment={attachment} />
        ) : (
          <UnknownTag />
        );
      case MentionType.User: {
        const user = appStore.orgModel?.info.orgMembers?.find(u => u.id === id);
        return user ? <UserTag className={styles.tag} labelClassName={styles.tagLabel} user={user} /> : <UnknownUserTag />;
      }
      case MentionType.Block: {
        const block = getBlockById(id);
        return block ? <BlockTag className={styles.tag} labelClassName={styles.tagLabel} block={block} /> : <UnknownTag />;
      }
      case MentionType.AnalysisOutput: {
        const analysisOutput = getAnalysisOutputById(id);
        return analysisOutput ? (
          <AnalysisOutputTag className={styles.tag} labelClassName={styles.tagLabel} analysisOutput={analysisOutput} />
        ) : (
          <UnknownTag />
        );
      }
      case MentionType.Report: {
        const report = getReportById(id);
        const workspaceId = appStore.workspaceModel?.id;
        if (!report || !workspaceId) {
          return <UnknownTag />;
        }
        return (
          <ReportTag
            report={report}
            reportLink={getReportLink(workspaceId, report.id)}
            editor={props.editor}
            showContextMenu={props.node?.attrs?.showContextMenu}
            usePlaceholder={props.node?.attrs?.usePlaceholder}
            mode={props.node?.attrs?.mode}
            updateAttributes={props.updateAttributes}
            onDelete={props.deleteNode}
            onUpdate={props.extension?.options?.onUpdate}
          />
        );
      }
      case MentionType.PropertyInstanceName: {
        const propertyInstance = getPropertyInstanceById(id);
        return propertyInstance ? (
          <PropertyInstanceTag className={styles.tag} labelClassName={styles.tagLabel} propertyInstance={propertyInstance} />
        ) : (
          <UnknownTag />
        );
      }
      case MentionType.PropertyInstanceValue: {
        const propertyInstance = getPropertyInstanceById(id);
        const displayPath = props.extension?.options?.displayPath;
        return propertyInstance ? (
          <PropertyInstanceTag
            className={styles.tag}
            labelClassName={styles.tagLabel}
            propertyInstance={propertyInstance}
            showValue={!displayPath}
          />
        ) : (
          <UnknownTag />
        );
      }
      case MentionType.Unknown:
        return <UnknownTag />;
    }
  };

  const className = classNames(styles.mentionTagComponent, { [Classes.DARK]: appStore.env.themeIsDark });
  return (
    <NodeViewWrapper className={className} as="span">
      {renderMentionNode()}
    </NodeViewWrapper>
  );
};

export default observer(MentionTag);
