import ReactDOM from "react-dom/client";
import { FocusStyleManager, HotkeysProvider } from "@blueprintjs/core";
import axios from "axios";

import gitInfo from "@assets/git-info.json";

import App from "./App";

// Unregister service workers
if ("serviceWorker" in navigator) {
  navigator.serviceWorker.getRegistrations().then(async registrations => {
    for (const r of registrations) {
      // Skip storybook service workers
      if (r.active?.scriptURL.includes("mockServiceWorker")) {
        continue;
      }
      await r.unregister();
    }
  });
}

FocusStyleManager.onlyShowFocusOnTabs();

// Axios nonsense
axios.defaults.headers.put["Content-Type"] = "application/json";

// Log commit info
console.debug(gitInfo);

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  // <React.StrictMode>
  <HotkeysProvider>
    <App />
  </HotkeysProvider>
  // </React.StrictMode>
);
