import { MouseEvent, useRef, useState } from "react";
import { observer } from "mobx-react";

import { PropertyContainer } from "@components/Modeling/ModelingFrame/ModelBlock/Properties";
import CreateNewInput from "@components/Shared/CreateNewInput/CreateNewInput";
import { Switch } from "@components/Switch";
import { showMiddleToast } from "@components/UiLayers/toaster";
import appStore from "@store/AppStore";
import { IBlock } from "@store/BlockStore";
import { IPropertyDefinition } from "@store/PropertyDefinitionStore";
import { IPropertyInstance } from "@store/PropertyInstanceStore";
import { Text } from "src/ui/Text";

import { CreatePropertySelect } from "./components/createPropertyBox/CreatePropertySelect";
import { CreatePropertyInputType } from "./components/createPropertyBox/types";
import { InputBox } from "./components/InputBox/InputBox";

import "./PropertiesTabPanel.scss";

/** Type defs. */
type PropertiesTabPanelProps = {
  block: IBlock;
};

/** Main function. */
function PropertiesTabPanel({ block }: PropertiesTabPanelProps) {
  const [inputType, setInputType] = useState<CreatePropertyInputType>("property");
  const handleNewProperty = async (label: string, id?: string, groupId?: string) => {
    if (!label || !appStore.workspaceModel) {
      return false;
    }

    try {
      let instance: IPropertyInstance | undefined;
      let definition: IPropertyDefinition | undefined;

      // Match by ID if it exists. If not, try match by label.
      if (id) {
        definition = appStore.workspaceModel.propertyDefinitionMap.get(id);
        if (!definition) {
          return false;
        }
      } else {
        definition = appStore.workspaceModel.propertyDefinitions.find(p => p.label === label);
      }

      if (definition) {
        instance = await block.addNewProperty(definition, groupId);
      } else {
        // If no label match, create a new definition and add instance to that
        definition = await appStore.workspaceModel.addNewPropertyDefinition(label);
        if (definition) {
          showMiddleToast("Automatically created new property definition", "none");
          instance = await block.addNewProperty(definition, groupId);
        } else {
          return false;
        }
      }
      return instance !== undefined;
    } catch (err) {
      console.error(err);
      return false;
    }
  };

  const handleNewGroup = async (label: string) => {
    return (await block.addNewPropertyGroup(label)) !== undefined;
  };

  const allowedProps = appStore.workspaceModel?.propertyDefinitionAutoComplete?.map(e => ({
    ...e,
    disabled: !!block.validatedPropertyInstances.find(i => i.propertyDefinition?.id === e.id),
  }));

  const createInput = () => {
    const props = {
      disabled: !block.replicated,
      inputProps: { leftIcon: null, autoFocus: true },
    };
    switch (inputType) {
      case "group":
        return <CreateNewInput placeholder="Add new group" tryCreate={handleNewGroup} {...props} />;
      default:
        return (
          <CreateNewInput
            placeholder="Add new property"
            createNewTextPrefix="Create new definition for"
            tryCreate={handleNewProperty}
            autocompleteEntries={allowedProps}
            {...props}
          />
        );
    }
  };

  return (
    <div className="properties-tab-panel">
      <div className="properties-tab-panel-header-container">
        <InputBox
          placeholder={activateInput => (
            <CompoundInputPlaceholder
              onClick={type => {
                setInputType(type);
                activateInput();
              }}
            />
          )}
          wrapperProps={{ className: "create-property-box" }}
        >
          <>
            <CreatePropertySelect activeType={inputType} onChange={setInputType} />
            <div className="input-wrapper">{createInput()}</div>
          </>
        </InputBox>
        <Switch
          checked={appStore.env.showPropertyDescription}
          onChange={appStore.env.togglePropertyDescription}
          label="Show descriptions"
          alignIndicator="right"
          e2eIdentifiers="show-descriptions"
        />
      </div>
      <PropertyContainer block={block} showDescription={appStore.env.showPropertyDescription} onGroupAddProperty={handleNewProperty} />
    </div>
  );
}

/** Exports. */
export type { PropertiesTabPanelProps };
export default observer(PropertiesTabPanel);

type CompoundInputPlaceholderProps = {
  onClick: (type: CreatePropertyInputType) => void;
};

const CompoundInputPlaceholder = ({ onClick }: CompoundInputPlaceholderProps) => {
  const groupRef = useRef<HTMLDivElement>(null);

  const onGroupClick = (e: MouseEvent) => {
    e.stopPropagation();
    onClick("group");
  };

  return (
    <Text onClick={() => onClick("property")} className="placeholder-text">
      <Text>{StaticText.inactive.placeholder.add}</Text> <Text className="interactive">{StaticText.inactive.placeholder.property}</Text>{" "}
      <Text>{StaticText.inactive.placeholder.or}</Text>{" "}
      <span ref={groupRef}>
        <Text onClick={onGroupClick} className="interactive">
          {StaticText.inactive.placeholder.group}
        </Text>
      </span>
    </Text>
  );
};

const StaticText = {
  inactive: {
    placeholder: {
      add: "Add",
      property: "property",
      or: "or",
      group: "group",
    },
  },
};
