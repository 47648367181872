import { cast, flow, Instance, SnapshotIn, types } from "mobx-state-tree";

import { showToast } from "@components/UiLayers/toaster";
import { isAdmin, UserPermission, UserRole } from "@rollup-api/api/authTypes";
import { rollupClient } from "src/core/api";

import { RollupEventsStore } from "./RollupEventsStore";
import { UserSettingsStore } from "./UserSettingsStore";

export const UserStore = types
  .model("User", {
    id: types.identifier,
    active: types.optional(types.boolean, true),
    email: types.maybe(types.string),
    name: types.maybeNull(types.string),
    avatarUrl: types.maybeNull(types.string),
    hasPassword: types.maybeNull(types.boolean),
    orgId: types.maybeNull(types.string),
    mostRecentWorkspace: types.optional(types.string, ""),
    permissions: types.array(types.enumeration("UserPermission", [...Object.values(UserPermission)])),
    roles: types.optional(types.array(types.enumeration("UserRole", [...Object.values(UserRole)])), [UserRole.User]),
    department: types.maybeNull(types.string),
    jobTitle: types.maybeNull(types.string),
    settings: types.maybeNull(UserSettingsStore),
    rollupEvents: types.maybeNull(types.late(() => RollupEventsStore)),
    lastActiveTime: types.maybeNull(types.number),
  })
  .actions(self => {
    return {
      setUserData(data: IUser) {
        self.email = data.email;
        self.avatarUrl = data.avatarUrl;
      },
    };
  })
  .actions(self => ({
    deleteProfilePhoto: flow(function* deleteProfilePhoto(): Generator<any, void, any> {
      // TODO: call backend endpoint
      return yield new Promise<void>(resolve => {
        setTimeout(() => resolve(), 500);
      });
    }),
    setRole(role: UserRole) {
      self.roles = cast([role]);
    },
    setName(name: string) {
      self.name = name;
    },
    setJobTitle(jobTitle: string) {
      self.jobTitle = jobTitle;
    },
    setDepartment(department: string) {
      self.department = department;
    },
    setAvatarUrl(avatarUrl: string) {
      self.avatarUrl = avatarUrl;
    },
  }))
  .actions(self => ({
    uploadProfilePicture: flow(function* uploadProfilePicture(files: FileList): Generator<any, void, any> {
      try {
        const res = yield rollupClient.profiles.uploadImage(files[0]);
        if (res.data?.avatarUrl) {
          showToast("Photo uploaded", "success", "info-sign");
          self.setAvatarUrl(res.data.avatarUrl);
        } else {
          showToast("Error uploading image", "warning", "info-sign");
        }
      } catch (error: any) {
        // Show an error toast
        showToast("File upload error: " + error.message, "warning", "info-sign");
        console.debug(JSON.stringify(error));
      }
    }),
  }))
  .views(self => ({
    get displayName() {
      return self.name || self.email || "";
    },
    get isAdmin() {
      return isAdmin(self);
    },
    hasPermission(perm: UserPermission) {
      return self.permissions.includes(perm);
    },
  }));

export interface IUser extends Instance<typeof UserStore> {}
export interface IUserSnapshotIn extends SnapshotIn<typeof UserStore> {}
