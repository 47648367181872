import { useCallback, useEffect, useRef, useState } from "react";
import { Position } from "@blueprintjs/core";
import { useSearchParam } from "@hooks/useSearchParam/useSearchParam";
import classNames from "classnames";
import { observer } from "mobx-react";

import { hacktivateCustomView } from "@components/Modeling/ModelingFrame/HoopsViewer/utils";
import { Popover } from "@components/Popover";
import { IAnnotation } from "@store/AnnotationStore";
import appStore from "@store/AppStore";
import { MoveDirection } from "@utilities/AnnotationUtils";

import AnnotationContainer from "./AnnotationContainer";
import SmartAnnotationMarker from "./SmartAnnotationMarker";

import styles from "./SmartAnnotation.module.scss";

export type AnnotationView = {
  uniqueId: string;
  name: string;
  camera: unknown;
  cuttingData: unknown;
  explodeMagnitude: number;
  lineVisibility: boolean;
  faceVisibility: boolean;
  markup: unknown[];
  sheetId?: number;
  defaultVisibility: boolean;
  visibilityExceptions: unknown[];
  colors: unknown[];
  imageSrc: string;
  isolatedNodeId?: number | null;
};
interface SmartAnnotationProps {
  annotation: IAnnotation;
  onMoveToAnnotation: (myId: string, direction?: MoveDirection) => void;
}

const SmartAnnotation = ({ annotation, onMoveToAnnotation }: SmartAnnotationProps) => {
  const [annotationView, setAnnotationView] = useState<AnnotationView>();

  const [annotationId] = useSearchParam("annId");

  const activeAnnotationId = appStore.env.activeAttachment?.annotationList.activeAnnotationId;

  const annotationContainerRef = useRef<HTMLDivElement>(null);

  // prepare the annotation view on mount and load annotationView to the markup manager
  useEffect(() => {
    const loadAnnotationViewToMarkupManager = async (myView: AnnotationView) => {
      // Export the current markup, the last view in the exported markup will be the annotation view since hoops creates
      // a new view when we export the markup. This approach only works for annotations created via user action.
      const exportedMarkup = await appStore.env.attachmentViewer?.viewerInstance.markupManager.exportMarkup();
      if (exportedMarkup) {
        // If the annotation is recreated, push the view to exported markup and load it to the markup manager
        // We have to do this because when recreating annotations, the view is not the initial view of the annotation
        if (annotation.locationData3D?.recreated) {
          exportedMarkup.views.push(myView);
          // Reimport all markups
          await appStore.env.attachmentViewer?.viewerInstance.markupManager.loadMarkupData(exportedMarkup);
          appStore.env.attachmentViewer?.viewerInstance.markupManager.refreshMarkup();
          setAnnotationView(myView);
          // set the annotation view id in the store so we can activate it elsewhere (like the sidebar)
          annotation.setViewId(myView.uniqueId);
        } else {
          setAnnotationView(exportedMarkup.views[exportedMarkup.views.length - 1] as AnnotationView);
          // set the annotation view id in the store so we can activate it elsewhere (like the sidebar)
          annotation.setViewId((exportedMarkup.views[exportedMarkup.views.length - 1] as AnnotationView).uniqueId);
        }
      }
    };
    if (annotation.locationData3D?.view) {
      loadAnnotationViewToMarkupManager(annotation.locationData3D.view);
    }
  }, [annotation]);

  const moveToAnnotationView = useCallback(async (): Promise<void> => {
    if (annotationView?.uniqueId) {
      await hacktivateCustomView(annotationView?.uniqueId, true, annotation.locationData3D?.view?.isolatedNodeId || undefined);
      annotation.setIsExpanded(true);
    }
  }, [annotationView, annotation]);

  useEffect(() => {
    if (activeAnnotationId === annotation.id) {
      moveToAnnotationView().then(() => {
        appStore.env.activeAttachment?.annotationList.setActiveAnnotationId(null);
      });
    }
  }, [annotation, moveToAnnotationView, activeAnnotationId]);

  // check for annotation id in the url
  useEffect(() => {
    if (annotationId === annotation.id && annotationView) {
      setTimeout(() => {
        moveToAnnotationView();
        annotation.setIsExpanded(true);
      }, 300);
    }
  }, [annotationId, annotation, annotationView, moveToAnnotationView]);

  if (!appStore.env.attachmentViewer?.annotationsVisible || appStore.env.attachmentViewer?.isExplodeActive) {
    return null;
  }

  return (
    <div
      ref={annotationContainerRef}
      className={classNames(styles.smartAnnotation, {
        [styles.onTop]: annotation.isExpanded,
        [styles.visible]: !annotation.locationData3D?.isHidden,
      })}
      style={{ top: annotation.locationData3D?.pin2dPositionY, left: annotation.locationData3D?.pin2dPositionX }}
    >
      <Popover
        content={<AnnotationContainer annotation={annotation} onMoveToAnnotation={onMoveToAnnotation} />}
        isOpen={annotation.isExpanded}
        usePortal={false}
        lazy
        position={Position.BOTTOM_LEFT}
        modifiers={{ offset: { enabled: true, options: { offset: [0, 9] } } }}
        autoFocus={false}
      >
        <SmartAnnotationMarker
          annotation={annotation}
          isExpanded={annotation.isExpanded}
          onClick={() => {
            annotation.setIsExpanded(!annotation.isExpanded);
          }}
        />
      </Popover>
    </div>
  );
};

export default observer(SmartAnnotation);
