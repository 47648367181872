import { useCallback, useEffect, useMemo, useRef } from "react";
import { NonIdealState, Spinner } from "@blueprintjs/core";
import { CellClickedEvent, CellRange, CellRangeParams, ColDef, RangeSelectionChangedEvent } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import classNames from "classnames";
import { observer } from "mobx-react";

import { DataSourceValueUtils } from "@components/DataSources/DataSourceValueUtils";
import Table from "@components/Table";
import { DataSourcesValueResult } from "@rollup-api/api/modeling";

import "./PreviewGrid.scss";

type CellData = Record<string, string>;

const MAX_SELECTION_ROWS = 2;
const MAX_SELECTION_COLS = 2;

export type PreviewGridProps = {
  result?: DataSourcesValueResult;
  viewRange: string;
  isLoading: boolean;
  size?: PreviewGridSize;
  onQueryChanged?: (query: string) => void;
};

export enum PreviewGridSize {
  Small = "small",
  Normal = "normal",
}

// Generate column definitions for the grid from a starting cell in A1 notation and a number of columns
const generateColumnDefs = (numColumns: number, startingCell?: string) =>
  Array.from({ length: numColumns }, (_, colIndex) => ({
    minWidth: 40,
    width: 60,
    rowDrag: false,
    cellClass: "grid-cell",
    resizable: true,
    headerClass: "preview-grid--column-header",
    field: startingCell ? (DataSourceValueUtils.getCellLabels(startingCell, 0, colIndex)?.column ?? "") : "",
  }));

// Get a range string in A1 notation from a given starting cell (also in A1 notation) and a range object
const getRangeString = (startingCell?: string, range?: CellRange) => {
  if (!startingCell || !range) {
    return "";
  }

  if (range.startRow?.rowIndex === undefined || range.endRow?.rowIndex === undefined || !range.columns?.length) {
    return "";
  }

  const startLabel = DataSourceValueUtils.getCellLabels(startingCell, range.startRow.rowIndex, 0);
  const endLabel = DataSourceValueUtils.getCellLabels(startingCell, range.endRow.rowIndex, 0);

  if (range.startRow.rowIndex === range.endRow.rowIndex && range.columns.length === 1) {
    return `${range.columns[0].getColId()}${startLabel?.row}`;
  } else {
    return `${range.columns[0].getColId()}${startLabel?.row}:${range.columns[range.columns.length - 1].getColId()}${endLabel?.row}`;
  }
};

export const PreviewGrid = observer(function PreviewGrid(props: PreviewGridProps) {
  const { result, viewRange, isLoading, size = PreviewGridSize.Normal, onQueryChanged } = props;
  const selectedRange = useRef<string>();
  const tableRef = useRef<AgGridReact<CellData> | null>(null);

  useEffect(() => {
    // Clears out range selection when user changes current sheet via dropdown
    tableRef.current?.api?.clearRangeSelection();
  }, [result]);

  const viewDims = DataSourceValueUtils.getRangeDimensions(viewRange);
  const dataDims = DataSourceValueUtils.getDimensions(result);
  const dataCells = dataDims ? DataSourceValueUtils.padData(result?.data?.values as string[][], dataDims) : undefined;

  const startingCell = DataSourceValueUtils.getStartingCell(result);
  const columnDefs: ColDef<CellData>[] = useMemo(
    () => generateColumnDefs(viewDims?.numColumns ?? 0, startingCell),
    [startingCell, viewDims?.numColumns]
  );

  // Generate rows for the grid as an array of CellData objects from the data array
  const rows = useMemo(() => {
    if (!viewDims?.numRows || !viewDims?.numColumns) {
      return [];
    }

    const rowData: CellData[] = [];
    for (let i = 0; i < viewDims.numRows; i++) {
      const row: CellData = {};
      for (let j = 0; j < viewDims.numColumns; j++) {
        if (columnDefs[j].field !== undefined) {
          const field = columnDefs[j].field as string;
          row[field] = dataCells?.[i]?.[j] ?? "";
        }
      }
      rowData.push(row);
    }
    return rowData;
  }, [columnDefs, dataCells, viewDims?.numColumns, viewDims?.numRows]);

  const onCellClicked = useCallback(
    (event: CellClickedEvent<CellData>) => {
      const range = event.api.getCellRanges()?.[0];
      const previousRange = selectedRange.current;
      selectedRange.current = getRangeString(startingCell, range);
      if (previousRange !== selectedRange.current) {
        onQueryChanged?.(selectedRange.current);
      }
    },
    [onQueryChanged, startingCell]
  );

  const onRangeSelectionChanged = useCallback(
    (event: RangeSelectionChangedEvent<CellData>) => {
      let range = event.api.getCellRanges()?.[0];

      // Empty ranges are ignored
      if (!range || range.startRow?.rowIndex === undefined || range.endRow?.rowIndex === undefined || !range.columns?.length) {
        return;
      }

      // Ranges can be specified in any order, so we need to get upper and lower bounds instead of start and end
      const previousRange = selectedRange.current;
      const lowerBound = Math.min(range.startRow.rowIndex, range.endRow.rowIndex);
      const upperBound = Math.max(range.startRow.rowIndex, range.endRow.rowIndex);
      const numRows = upperBound - lowerBound + 1;
      const numCols = range.columns?.length;

      if (numCols > MAX_SELECTION_COLS || numRows > MAX_SELECTION_ROWS) {
        // Limit selection to 1x2 or 2x1
        event.api.clearRangeSelection();
        const newRange: CellRangeParams = {
          rowStartIndex: lowerBound,
          rowEndIndex: Math.min(upperBound, lowerBound + MAX_SELECTION_ROWS - 1),
          columns: range.columns?.slice(0, MAX_SELECTION_COLS),
        };
        event.api.addCellRange(newRange);
        range = event.api.getCellRanges()?.[0];
      } else if (numCols === 2 && numRows === 2) {
        // If the selection is already 2x2, replace with 1x2
        event.api.clearRangeSelection();
        const newRange: CellRangeParams = {
          rowStartIndex: lowerBound,
          rowEndIndex: lowerBound,
          columns: range.columns?.slice(0, MAX_SELECTION_COLS),
        };
        event.api.addCellRange(newRange);
      }
      selectedRange.current = getRangeString(startingCell, range);
      if (previousRange !== selectedRange.current) {
        onQueryChanged?.(selectedRange.current);
      }
    },
    [onQueryChanged, startingCell]
  );

  const sizeClassNames = classNames({
    "preview-grid--small": size === PreviewGridSize.Small,
    "preview-grid--normal": size === PreviewGridSize.Normal,
  });

  if (isLoading) {
    return <NonIdealState className={sizeClassNames} icon={<Spinner />} title="Loading Preview" />;
  } else if (!result) {
    return <NonIdealState className={sizeClassNames} icon="warning-sign" title="No Data" />;
  }

  if (dataDims?.length !== 2 || !viewDims?.numRows || !viewDims?.numColumns) {
    return <NonIdealState className={sizeClassNames} icon="warning-sign" title="Invalid Data" />;
  }

  const cols: ColDef<CellData>[] = [
    {
      valueGetter: "node.rowIndex + 1",
      cellClass: "preview-grid--row-header",
      headerClass: "preview-grid--index-column-header",
      width: 40,
    },
    ...columnDefs,
  ];

  return (
    <Table<CellData>
      tableRef={tableRef}
      suppressRowDrag
      suppressMoveWhenRowDragging
      rowDragManaged
      enableRangeSelection
      suppressMultiRangeSelection
      suppressContextMenu
      suppressCellFocus
      suppressRowClickSelection
      onCellClicked={onCellClicked}
      onRangeSelectionChanged={onRangeSelectionChanged}
      className={classNames("preview-grid", sizeClassNames)}
      columnDefs={cols}
      rowData={rows}
    />
  );
});
