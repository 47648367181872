import { DATE_FILTER_OPTIONS } from "./constants";

export interface DateRangeString {
  startDateTime: string;
  endDateTime: string;
}

const dateFilterResolver = (filterChoice: (typeof DATE_FILTER_OPTIONS)[number]): DateRangeString => {
  const now = new Date();
  let startDateTime: Date;
  const endDateTime: Date = now;

  switch (filterChoice) {
    case "Last 24 hours":
      startDateTime = new Date(now.getTime() - 24 * 60 * 60 * 1000);
      break;
    case "Last 3 days":
      startDateTime = new Date(now.getTime() - 3 * 24 * 60 * 60 * 1000);
      break;
    case "Last 7 days":
      startDateTime = new Date(now.getTime() - 7 * 24 * 60 * 60 * 1000);
      break;
    case "Last month":
      startDateTime = new Date(now.getFullYear(), now.getMonth() - 1, now.getDate());
      break;
    case "Last 6 months":
      startDateTime = new Date(now.getFullYear(), now.getMonth() - 6, now.getDate());
      break;
    case "Last year":
      startDateTime = new Date(now.getFullYear() - 1, now.getMonth(), now.getDate());
      break;
    default:
      throw new Error(`Invalid date filter choice: ${filterChoice}`);
  }

  return { startDateTime: startDateTime.toISOString(), endDateTime: endDateTime.toISOString() };
};

export default dateFilterResolver;
