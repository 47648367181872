import { useEffect, useState } from "react";
import { InputGroup, Menu } from "@blueprintjs/core";
import { observer } from "mobx-react";
import { useDebounceValue } from "usehooks-ts";

import { MenuItem } from "@components/MenuItem";
import { Text, TextVariant } from "src/ui/Text";

import FilterChoice from "./FilterChoice";
import { FilterOptionList } from "./types";
import { WithCheckbox } from "./WithCheckbox";

interface FilterMenuProps<T extends Record<string, string> | string> {
  options: FilterOptionList<T>;
  onChange?: (selectedItems: T[], selectedKeys: string[]) => void;
}

const FilterMenu = <T extends Record<string, string> | string>({ options, onChange }: FilterMenuProps<T>) => {
  const [filteredList, setFilteredItemsList] = useState<T[]>([]);
  const [selected, setSelected] = useState<{ keys: string[]; items: T[] }>({
    keys: options.selectedKeys,
    items: options.selectedKeys.map(key => {
      return options.list.find(item => {
        if (typeof item === "string") {
          return item === key;
        } else {
          return item[options.keyField as keyof T] === key;
        }
      }) as T;
    }),
  });

  const [searchTerm, setSearchTerm] = useState<string>("");
  const [debouncedSearchTerm] = useDebounceValue(searchTerm, 400);

  options.searchBox = options.searchBox || false;
  options.multiSelect = options.multiSelect || false;
  options.renderer = options.renderer || ((item: T) => item as React.ReactNode);

  useEffect(() => {
    const newList = getFilteredList(options.list, searchTerm, options.filterField);
    setFilteredItemsList(newList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchTerm]);

  useEffect(() => {
    onChange?.(selected.items, selected.keys);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected]);

  const handleSelectionToggle = (item: T) => {
    let newSelectedItems: T[] = [];

    if (options.multiSelect) {
      if (selected.items.includes(item)) {
        // Remove item from list
        newSelectedItems = selected.items.filter(selectedItem => selectedItem !== item);
      } else {
        // Add item to list
        newSelectedItems = [...selected.items, item];
      }
    } else {
      // Single select
      newSelectedItems = [item];
    }

    setSelected({
      keys: newSelectedItems.map(item => (typeof item === "string" ? item : (item[options.keyField as keyof T] as string))),
      items: newSelectedItems,
    });
  };

  const isSelected = (item: T) => {
    return selected.items.includes(item);
  };

  const getFilteredList = <T extends Record<string, string> | string>(list: T[], filterTerm: string, filterField?: keyof T): T[] => {
    const filteredList = list.filter((item: T) => {
      if (typeof item === "string") {
        // If T is a string, filter the list of strings
        return item.toLowerCase().includes(filterTerm.toLowerCase());
      } else {
        // If T is an object, filter the list of objects
        if (!filterField) throw new Error("filterField is required when T is an object");
        const fieldValue = item[filterField];
        if (typeof fieldValue === "string") {
          return fieldValue.toLowerCase().includes(filterTerm.toLowerCase());
        } else {
          return false;
        }
      }
    });
    return filteredList;
  };

  return (
    <Menu>
      <Text variant={TextVariant.H5}>{options.title}</Text>
      {options.searchBox && (
        <InputGroup leftIcon="search" placeholder="Search" value={searchTerm} onChange={e => setSearchTerm(e.target.value)} />
      )}
      {filteredList.map((item, index) => (
        <MenuItem
          key={`${typeof item === "string" ? item : (item[options.filterField as keyof T] as string)}-${index}`}
          onClick={() => handleSelectionToggle(item)}
          text={
            options.multiSelect ? (
              <WithCheckbox checked={isSelected(item)}>
                <FilterChoice item={item} filterField={options.filterField} renderer={options.renderer} />
              </WithCheckbox>
            ) : (
              <FilterChoice item={item} filterField={options.filterField} renderer={options.renderer} />
            )
          }
          e2eIdentifiers={["filter-menu", "menu-item"]}
        />
      ))}
    </Menu>
  );
};

export default observer(FilterMenu);
