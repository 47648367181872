import { destroy, IAnyType, Instance, types } from "mobx-state-tree";
import { v4 as uuidv4 } from "uuid";

export enum FigureType {
  lineChart = "line-chart",
  scatterChart = "scatter-chart",
  timelineBarChart = "timeline-bar-chart",
  areaChart = "area-chart",
}

const FigureBlockUiStore = types
  .model("FigureBlockUi", {
    isExpanded: types.optional(types.boolean, true),
    selectedTab: types.optional(types.enumeration("selected-tab", ["figure", "code"]), "figure"),
  })
  .actions(self => ({
    toggleExpanded() {
      self.isExpanded = !self.isExpanded;
    },
    setCurrentTab(tab: typeof self.selectedTab) {
      self.selectedTab = tab;
    },
  }));

export const FigureBlockStore = types
  .model("FigureBlock", {
    figureID: types.optional(types.identifier, uuidv4()),
    label: types.optional(types.string, "Figure Title"),
    description: types.optional(types.string, ""),
    data: types.optional(types.string, ""),
    code: types.optional(types.string, ""),
    figureType: types.optional(types.enumeration("figure-type", [...Object.values(FigureType)]), FigureType.lineChart),
    ui: types.optional(FigureBlockUiStore, {}),
  })
  .actions(self => {
    return {
      setLabel(name: string) {
        self.label = name;
      },
      setNewDescription(description: string) {
        self.description = description;
      },
      setNewID(id: string) {
        // TODO: this should not be allowed according to MST
        self.figureID = id;
      },
      setFigureType(figureType: FigureType) {
        self.figureType = figureType;
      },
      deleteProperty(property: any) {
        destroy(property);
      },
      setCode(code: string) {
        self.code = code;
      },
      deletePropertyGroup(group: IAnyType) {
        destroy(group);
      },
    };
  });

export interface IFigureBlock extends Instance<typeof FigureBlockStore> {}
