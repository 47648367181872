import { PropertyDataType } from "@rollup-io/engineering";
import classNames from "classnames";
import { observer } from "mobx-react";

import { Button } from "@components/Button";
import { dataSinkEntryDataTypeOptions } from "@components/DataSources/AddDataSink/DataSinkUtils";
import { DataSinkEntryDrawerStore } from "@components/DataSources/AddDataSink/stores/DataSinkEntryDrawerStore";
import { FormGroup } from "@components/FormGroup";
import { InputGroup } from "@components/InputGroup";
import { Dropdown, IComplexSelectOption } from "@components/Shared/Dropdown";
import { Tooltip } from "@components/Tooltip";

import styles from "./DataSinkEntryCard.module.scss";

interface IDataSinkEntryCardProps {
  property: DataSinkEntryDrawerStore;
  onDelete(id: string): void;
}

const DataSinkEntryCard = (props: IDataSinkEntryCardProps) => {
  const { property, onDelete } = props;

  const selectedDataType = dataSinkEntryDataTypeOptions.find(opt => opt.value === property.dataType);

  return (
    <div className={styles.dataSinkEntryCard}>
      <Tooltip className={styles.dataSinkEntryCardDelete} content="Delete property">
        <Button icon="cross" onClick={() => onDelete(property.id)} e2eIdentifiers={["delete-property", property.id]} minimal />
      </Tooltip>
      <div className={styles.dataSinkEntryCardRow}>
        <FormGroup className={styles.dataSinkEntryCardFormGroup} label="Key">
          <Tooltip
            className={styles.dataSinkEntryCardInputTooltip}
            content="You cannnot modify the key of an existing entry"
            disabled={!property.isExisting}
          >
            <InputGroup
              value={property.key}
              onChange={e => property.setKey(e.target.value)}
              disabled={property.isExisting}
              e2eIdentifiers="key"
            />
          </Tooltip>
        </FormGroup>
      </div>
      <div className={styles.dataSinkEntryCardRow}>
        <FormGroup label="Data">
          <Dropdown<IComplexSelectOption<PropertyDataType>>
            className={styles.dataSinkEntryCardDropdown}
            selected={selectedDataType}
            onSelect={opt => property.setDataType(opt.value)}
            options={dataSinkEntryDataTypeOptions}
            e2eIdentifiers="data-sink-type"
            popoverProps={{ matchTargetWidth: false }}
            buttonProps={{ className: styles.dataSinkEntryCardDropdownButton, tooltip: selectedDataType?.label }}
            hideLabel
            hideArrow
          />
        </FormGroup>
        <FormGroup className={classNames(styles.dataSinkEntryCardFormGroup, styles.dataSinkEntryCardValue)} label="Value">
          <InputGroup value={property.value} onChange={e => property.setValue(e.target.value)} e2eIdentifiers="value" />
        </FormGroup>
      </div>
    </div>
  );
};

export default observer(DataSinkEntryCard);
