import { useRef, useState } from "react";
import { Icon, InputGroup, Menu, Position } from "@blueprintjs/core";
import { useClickOutside } from "@hooks/useClickOutside/useClickOutside";
import { observer } from "mobx-react";

import { Button } from "@components/Button";
import { Popover } from "@components/Popover";
import appStore from "@store/AppStore";
import { Text, TextVariant } from "src/ui/Text";

import "./HoopsIsolatedMessage.scss";

const HoopsIsolatedMessage = () => {
  const [viewName, setViewName] = useState("");
  const [isEnterViewNamePopoverOpen, setIsEnterViewNamePopoverOpen] = useState(false);
  const enterViewNamePopoverRef = useRef<HTMLElement>(null);
  const activeAttachment = appStore.env.activeAttachment;

  useClickOutside(enterViewNamePopoverRef, () => setIsEnterViewNamePopoverOpen(false));

  const handleViewNameChange = (name: string) => {
    setViewName(name);
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === "Enter") {
      activeAttachment?.saveNewView(viewName);
      setIsEnterViewNamePopoverOpen(false);
      setViewName("");
    }
  };

  if (!appStore.env.attachmentViewer?.isolatedNode) {
    return null;
  }

  return (
    <div className="hoops-message-block">
      <Icon icon="widget" />
      <span>Isolated part: {appStore.env.attachmentViewer?.isolatedNode.label ?? appStore.env.attachmentViewer?.isolatedNode.id}</span>
      {activeAttachment?.block && activeAttachment?.workspaceId && (
        <Popover
          isOpen={isEnterViewNamePopoverOpen}
          content={
            <span ref={enterViewNamePopoverRef}>
              <Menu>
                <Text variant={TextVariant.Caption}>Enter view name</Text>
                <InputGroup
                  placeholder="View name..."
                  onChange={e => handleViewNameChange(e.target.value)}
                  value={viewName}
                  onKeyDown={handleKeyDown}
                />
              </Menu>
            </span>
          }
          placement={Position.BOTTOM}
          shouldReturnFocusOnClose
        >
          <Button
            icon="floppy-disk"
            text="Save view"
            outlined
            onClick={() => setIsEnterViewNamePopoverOpen(true)}
            e2eIdentifiers="save-view"
          />
        </Popover>
      )}
      <Button icon="cross" onClick={appStore.env.attachmentViewer?.unisolateNodes} minimal e2eIdentifiers="cross" />
    </div>
  );
};

export default observer(HoopsIsolatedMessage);
