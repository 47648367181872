import { IAnyModelType, Instance, types } from "mobx-state-tree";

import { rollupClient } from "../core/api";
import { MAX_PARALLEL_UPLOADS } from "../Utilities/FileUpload";

import { UploadStore } from "./UploadStore";

export enum EFileChunkUploadStatus {
  PENDING = "pending",
  UPLOADING = "uploading",
  UPLOADED = "uploaded",
  FAILED = "failed",
}

export const FileChunkStore = types
  .model("FileChunkStore", {
    id: types.identifier,
    url: types.string,
    eTag: types.optional(types.string, ""),
    createdAt: types.optional(types.number, Date.now()),
    uploadStore: types.safeReference(types.late((): IAnyModelType => UploadStore)),
    chunkNumber: types.number,
    numParts: types.number,
    status: types.optional(
      types.enumeration("EFileChunkUploadStatus", [...Object.values(EFileChunkUploadStatus)]),
      EFileChunkUploadStatus.PENDING
    ),
  })
  .volatile(() => ({
    blob: undefined as unknown as Blob,
    abortController: undefined as unknown as AbortController,
    uploadedSize: 0,
  }))
  .actions(self => ({
    setUploadedSize(size: number) {
      self.uploadedSize = size;
    },
    onUploadSuccess(eTag: string) {
      self.eTag = eTag;
      self.status = EFileChunkUploadStatus.UPLOADED;
      const pendingChunks = self.uploadStore.pendingChunks;
      const uploadingChunks = self.uploadStore.uploadingChunks;

      if (pendingChunks.length) {
        pendingChunks[0].startUpload();
      } else if (!uploadingChunks.length) {
        self.uploadStore.uploadComplete();
      }
    },
    onUploadError() {
      self.status = EFileChunkUploadStatus.FAILED;
    },
  }))
  .actions(self => ({
    startUpload() {
      self.abortController = new AbortController();
      const maxChunks = import.meta.env.VITE_MAX_PARALLEL_UPLOADS || MAX_PARALLEL_UPLOADS;

      if (self.uploadStore.uploadingChunks.length < maxChunks) {
        const fileSize = self.uploadStore.file.size;
        const bytesPerChunk = Math.ceil(fileSize / self.numParts);
        const fileChunk: Blob = self.uploadStore.file.slice(
          (self.chunkNumber - 1) * bytesPerChunk,
          Math.min(fileSize, self.chunkNumber * bytesPerChunk)
        );

        self.status = EFileChunkUploadStatus.UPLOADING;
        rollupClient.attachments.uploadFileChunk(
          fileChunk,
          self.url,
          self.onUploadSuccess,
          self.uploadStore.type,
          self.onUploadError,
          self.abortController.signal,
          ev => self.setUploadedSize(ev.loaded)
        );
      }
    },
    cancelUpload() {
      self.abortController.abort();
    },
  }));

export interface IFileChunkStore extends Instance<typeof FileChunkStore> {}
