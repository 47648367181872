import { observer } from "mobx-react";

import { TEditorBlockProps } from "@components/Reports/Editor/types";
import BulletListItem from "@components/Reports/Editor/Types/BulletListItem";
import Checkbox from "@components/Reports/Editor/Types/Checkbox";
import DividerEditor from "@components/Reports/Editor/Types/Divider";
import HeadingsEditor from "@components/Reports/Editor/Types/Headings";
import ImageEditor from "@components/Reports/Editor/Types/Image";
import QuoteEditor from "@components/Reports/Editor/Types/Quote";
import TextReportBlock from "@components/Reports/Editor/Types/TextReportBlock";
import { RollupEditorType } from "@rollup-types/editor";

interface IDocumentEditorProps extends TEditorBlockProps {}

const DocumentEditor = (props: IDocumentEditorProps) => {
  const { documentBlock, disableDashNavigation, onEditorReady, editable = true } = props;
  const { eventHandlers, onUpdate } = props;

  const editorProps: TEditorBlockProps = {
    editable,
    onEditorReady,
    onUpdate,
    documentBlock,
    eventHandlers,
    disableDashNavigation,
  };

  // Note: still missing Table, OrderListItem and CodeEditor, which are still coupled to reportBlock
  switch (documentBlock.type) {
    case RollupEditorType.quote:
      return <QuoteEditor key={documentBlock.id} {...editorProps} />;
    case RollupEditorType.image:
      return <ImageEditor key={documentBlock.id} {...editorProps} />;
    case RollupEditorType.checklist:
      return <Checkbox key={documentBlock.id} {...editorProps} />;
    case RollupEditorType.ul:
      return <BulletListItem key={documentBlock.id} {...editorProps} />;
    case RollupEditorType.p:
      return <TextReportBlock key={documentBlock.id} {...editorProps} />;
    case RollupEditorType.hr:
      return <DividerEditor key={documentBlock.id} {...editorProps} />;
    default:
      return <HeadingsEditor key={documentBlock.id} {...editorProps} />;
  }
};

export default observer(DocumentEditor);
