import { Classes } from "@blueprintjs/core";
import { NodeViewWrapper } from "@tiptap/react";
import classNames from "classnames";
import { observer } from "mobx-react";

import DataSinkEntryTag from "@components/Shared/ScalarExpressionEditor/tags/DataSinkEntryTag";
import appStore from "@store/AppStore";
import { getAnalysisOutputById, getDataLinkById, getDataSinkEntryById, getPropertyInstanceById } from "@utilities";

import AnalysisOutputTag from "./AnalysisOutputTag";
import DataLinkTag from "./DataLinkTag";
import PropertyInstanceTag from "./PropertyInstanceTag";

import "./PropertyTag.scss";

export enum PropertyTagType {
  AnalysisOutput = "analysisOutput",
  DataLink = "dataLink",
  DataSinkEntry = "dataSinkEntry",
  PropertyInstance = "propertyInstance",
}

type PropertyTagProps = {
  node: {
    attrs: {
      id: string;
      label: string;
      type?: PropertyTagType;
      query?: string;
    };
  };
};

const tagFromType = (id: string, type?: PropertyTagType) => {
  switch (type) {
    case PropertyTagType.AnalysisOutput:
      return <AnalysisOutputTag iconClassName="tag-icon" labelClassName="tag-label" analysisOutput={getAnalysisOutputById(id)} />;
    case PropertyTagType.DataLink:
      return <DataLinkTag iconClassName="tag-icon" labelClassName="tag-label" dataLink={getDataLinkById(id)} />;
    case PropertyTagType.DataSinkEntry:
      return <DataSinkEntryTag iconClassName="tag-icon" labelClassName="tag-label" dataSinkEntry={getDataSinkEntryById(id)} />;
    case PropertyTagType.PropertyInstance:
    default:
      return <PropertyInstanceTag labelClassName="tag-label" propertyInstance={getPropertyInstanceById(id)} />;
  }
};

const PropertyTag = (props: PropertyTagProps) => {
  const className = classNames("property-tag", { [Classes.DARK]: appStore.env.themeIsDark });
  const tagElement = tagFromType(props.node.attrs.id, props.node.attrs.type);
  return (
    <NodeViewWrapper className={className} as="span">
      {tagElement}
    </NodeViewWrapper>
  );
};

export default observer(PropertyTag);
