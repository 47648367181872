export enum RevisionCodeType {
  Incremental = "incremental",
  Letters = "letters",
  Semantic = "semantic",
}

export enum SemanticRevisionCodeType {
  Major = "major",
  Minor = "minor",
  Patch = "patch",
}

export class OrganizationSettingsModel {
  public readonly catalogItemRevisionCodeType?: RevisionCodeType;
  public readonly orgId?: string;
}

export type UpdateOrganizationSettingsDto = Partial<OrganizationSettingsModel>;
