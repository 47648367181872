import { AnalysisOutput, DataLink, DataSinkEntry, PropertyInstance } from "@rollup-io/engineering";

import { GoogleSheetsIcon } from "@assets/icons/thirdParty";
import { ExecutionTypeIcon } from "@components/Analysis";
import { Icon } from "@components/Icon";
import LegacyCustomIcon from "@components/Shared/LegacyCustomIcon";
import {
  formatStringFromMathResult,
  getCodeBlockById,
  getDataSinkById,
  getDataSourceById,
  getPropertyInstanceById,
  joinStrings,
} from "@utilities";

import {
  ExpressionReferenceType,
  IAnalysisOutputExpressionReference,
  IDataLinkExpressionReference,
  IDataSinkEntryExpressionReference,
  IExpressionReference,
  IExpressionReferenceData,
  IPropertyExpressionReference,
} from "./ExpressionInfoReferences.types";

import styles from "./ExpressionInfoReferences.module.scss";

export const toAnalysisOutputExpressionReference = (item: AnalysisOutput): IAnalysisOutputExpressionReference => ({
  item,
  type: ExpressionReferenceType.AnalysisOutput,
});

export const toDataLinkExpressionReference = (item: DataLink): IDataLinkExpressionReference => ({
  item,
  type: ExpressionReferenceType.DataLink,
});

export const toDataSinkEntryExpressionReference = (item: DataSinkEntry): IDataSinkEntryExpressionReference => ({
  item,
  type: ExpressionReferenceType.DataSinkEntry,
});

export const toPropertyExpressionReference = (item: PropertyInstance): IPropertyExpressionReference => ({
  item,
  type: ExpressionReferenceType.PropertyInstance,
});

const getAnalysisOutputData = (analysisOutput: AnalysisOutput): IExpressionReferenceData => {
  const codeBlock = getCodeBlockById(analysisOutput.codeBlockId);
  const codeBlockLabel = codeBlock?.label ?? "Unknown code block";

  return {
    icon: codeBlock ? <ExecutionTypeIcon type={codeBlock.type} /> : undefined,
    label: `${codeBlockLabel}:${analysisOutput.label}`,
    value: analysisOutput.value ?? "",
    type: ExpressionReferenceType.AnalysisOutput,
  };
};

const getDataLinkData = (dataLink: DataLink): IExpressionReferenceData => {
  const dataSource = getDataSourceById(dataLink.dataSourceId);
  const dataName = dataSource?.label ?? "Unknown source";

  return {
    icon: <LegacyCustomIcon className={styles.expressionInfoReferencesIcon} icon={<GoogleSheetsIcon />} />,
    label: `${dataName}:${dataLink.query.toUpperCase()}`,
    value: joinStrings(dataLink.value, " "),
    type: ExpressionReferenceType.DataLink,
  };
};

const getDataSinkEntryData = (dataSinkEntry: DataSinkEntry): IExpressionReferenceData => {
  const dataSink = getDataSinkById(dataSinkEntry.dataSinkId);
  const dataSinkLabel = dataSink?.label ?? "Unknown data sink";
  return {
    icon: dataSink?.icon ? <Icon icon={dataSink.icon} /> : undefined,
    label: `${dataSinkLabel}:${dataSinkEntry.key}`,
    value: dataSinkEntry.value ?? "",
    type: ExpressionReferenceType.DataSinkEntry,
  };
};

const getPropertyData = (propertyInstance: PropertyInstance): IExpressionReferenceData => {
  const instance = getPropertyInstanceById(propertyInstance.id);

  if (!instance) {
    return { label: "Unknown property", value: "", type: ExpressionReferenceType.PropertyInstance };
  }

  const label = `${instance.path}`;
  const expValue = formatStringFromMathResult(instance.combinedResult, instance.propertyDefinition?.formatType);

  return { label, value: expValue, type: ExpressionReferenceType.PropertyInstance };
};

export const getReferenceData = (reference: IExpressionReference): IExpressionReferenceData | undefined => {
  switch (reference.type) {
    case ExpressionReferenceType.AnalysisOutput:
      return getAnalysisOutputData(reference.item);
    case ExpressionReferenceType.DataLink:
      return getDataLinkData(reference.item);
    case ExpressionReferenceType.DataSinkEntry:
      return getDataSinkEntryData(reference.item);
    case ExpressionReferenceType.PropertyInstance:
      return getPropertyData(reference.item);
  }
};
