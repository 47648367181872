import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { InputGroup, Intent } from "@blueprintjs/core";
import { observer } from "mobx-react";

import { Button } from "@components/Button";
import { DialogLegacy } from "@components/Dialog";
import { DialogBody } from "@components/DialogBody";
import { DialogFooter } from "@components/DialogFooter";
import appStore from "@store/AppStore";

import "./DeleteWorkspaceConfirmation.scss";

const DeleteWorkspaceConfirmation = () => {
  const navigate = useNavigate();
  const [confirmationText, setConfirmationText] = useState("");
  const [isDeleting, setIsDeleting] = useState(false);

  const handleClose = () => {
    if (isDeleting) {
      return;
    }

    appStore.ui.hideDeleteConfirmationDialog();
    setConfirmationText("");
  };

  const idList = appStore.ui.workspaceDeleteConfirmationDialog?.idList;
  const isMultiWorkspace = idList?.length && idList.length > 1;
  const requiredText = isMultiWorkspace ? `delete-${idList?.length}` : appStore.ui.workspaceDeleteConfirmationDialog?.label;

  const handleConfirm = async () => {
    if (!idList?.length) {
      return;
    }
    setIsDeleting(true);
    await appStore.deleteWorkspaces(idList);
    setIsDeleting(false);

    appStore.ui.hideDeleteConfirmationDialog();
    setConfirmationText("");
    navigate("/settings/workspaces");
  };

  if (!appStore.ui.workspaceDeleteConfirmationDialog) {
    return null;
  }

  const workspaceName = appStore.ui.workspaceDeleteConfirmationDialog.label;

  return (
    <DialogLegacy className="confirmation-dialog" isOpen title="Workspace" onClose={handleClose} lazy canEscapeKeyClose={!isDeleting}>
      <DialogBody>
        <div className="delete-workspace-confirmation--caption">
          This action will <b>delete all resources</b> associated with&nbsp;
          <b>{isMultiWorkspace ? `${idList.length} workspaces` : workspaceName}</b>
          &nbsp;and <b>cannot be undone</b>. Make sure this is something you really want to do.
        </div>
        <label htmlFor="name-confirmation" className="delete-workspace-confirmation--label">
          Type <b>{isMultiWorkspace ? requiredText : "the workspace name"}</b> to confirm
        </label>
        <InputGroup
          autoFocus
          placeholder="Enter here"
          id="name-confirmation"
          large
          disabled={isDeleting}
          value={confirmationText}
          onChange={e => setConfirmationText(e.target.value)}
          data-testid="input_name-confirmation"
        />
      </DialogBody>
      <DialogFooter className="confirmation-dialog--footer">
        <Button
          className="confirmation-dialog--button"
          onClick={handleClose}
          disabled={isDeleting}
          minimal
          outlined
          e2eIdentifiers="cancel"
          large
        >
          Cancel
        </Button>
        <Button
          className="confirmation-dialog--button"
          onClick={handleConfirm}
          intent={Intent.DANGER}
          e2eIdentifiers="delete"
          disabled={confirmationText !== requiredText}
          large
          loading={isDeleting}
        >
          Delete
        </Button>
      </DialogFooter>
    </DialogLegacy>
  );
};

export default observer(DeleteWorkspaceConfirmation);
