import { FormGroup } from "@blueprintjs/core";
import { datadogRum } from "@datadog/browser-rum";
import { observer } from "mobx-react";

import { Button } from "@components/Button";
import { Switch } from "@components/Switch";
import appStore from "@store/AppStore";
import { FEATURE_FLAG_DEFINITIONS, FeatureFlag } from "@store/FeatureFlagsStore";
import { trackSegmentEvent } from "src/lib/Segment";

import FloatingWindow from "../FloatingWindow";

import "./DebugWindow.scss";

export const DebugWindow = observer(function DebugWindow() {
  const { developerDialogEnabled, hideDeveloperDialog } = appStore.ui;

  return (
    <FloatingWindow
      className="debug-window"
      contentClassName="debug-window--content"
      defaultSize={{ width: 300, height: 500 }}
      id="debugWindow"
      title="Debug"
      resizable={false}
      open={developerDialogEnabled}
      onClose={hideDeveloperDialog}
    >
      <div className="debug-window--content">
        <FormGroup className="debug-window--flags-groups" inline={false}>
          <h4 className="m-0">Feature Flags</h4>
          <div className="debug-window--flags-container">
            {Object.entries(FEATURE_FLAG_DEFINITIONS).map(([key, value]) => (
              <Switch
                key={key}
                disabled={!appStore.env.featureFlags.canToggle(key as FeatureFlag)}
                checked={appStore.env.featureFlags.enabled(key as FeatureFlag)}
                label={value.label}
                onChange={() => appStore.env.featureFlags.toggleFlag(key as FeatureFlag)}
                e2eIdentifiers={`feature-flag-${key}`}
              />
            ))}
          </div>
        </FormGroup>
        <FormGroup inline={false}>
          <Button onClick={() => datadogRum.addError(new Error("Dialog Test Exception"))} e2eIdentifiers="ding-datadog-rum">
            Ding Datadog RUM
          </Button>
        </FormGroup>
        <FormGroup inline={false}>
          <Button onClick={() => trackSegmentEvent("dialog_test")} e2eIdentifiers="ding-segment-analytics">
            Ding Segment Analytics
          </Button>
        </FormGroup>
        <FormGroup inline={false}>
          <Button onClick={appStore.env.removeAllDismissedCallouts} e2eIdentifiers="clear-callouts">
            Clear Dismissed Callouts
          </Button>
        </FormGroup>
      </div>
    </FloatingWindow>
  );
});
