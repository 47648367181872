import { Menu } from "@blueprintjs/core";
import capitalize from "lodash/capitalize";
import { observer } from "mobx-react";

import { CellDropdown } from "@components/CellDropdown";
import { BlockIcon, CustomIcon } from "@components/CustomIcon";
import { MenuItem } from "@components/MenuItem";
import { CatalogItemType } from "@rollup-api/models/catalogItem/catalogItemDtos";

import { TCellRendererProps } from "../types";

import styles from "./PartTypeCell.module.scss";

const PartTypeCell = (props: TCellRendererProps) => {
  const catalogItem = props.data?.catalogItem;
  const type = catalogItem?.type;

  const getIcon = (type: CatalogItemType): BlockIcon => {
    switch (type) {
      case CatalogItemType.Assembly:
        return BlockIcon.Assembly;
      case CatalogItemType.Product:
        return BlockIcon.Block;
      default:
        return BlockIcon.Part;
    }
  };

  const getMenuContent = () => (
    <Menu>
      {Object.values(CatalogItemType).map(t => (
        <MenuItem
          className={styles.partTypeCellMenuItem}
          key={t}
          icon={<CustomIcon icon={getIcon(t)} />}
          text={capitalize(t)}
          active={type === t}
          onClick={() => catalogItem?.setType(t)}
          e2eIdentifiers={t}
        />
      ))}
    </Menu>
  );

  return (
    <CellDropdown
      buttonClassName={styles.partTypeCell}
      text={
        type ? (
          <div className={styles.partTypeCellLabel}>
            <CustomIcon icon={getIcon(type)} /> {capitalize(type)}
          </div>
        ) : (
          "Type"
        )
      }
      content={getMenuContent()}
      e2eIdentifiers="catalog-item-part-type"
    />
  );
};

export default observer(PartTypeCell);
