import { Instance, types } from "mobx-state-tree";

import { hacktivateCustomView } from "@components/Modeling/ModelingFrame/HoopsViewer/utils";
import { CuttingData } from "@rollup-api/models";
import appStore from "@store/AppStore";

export const CameraStore = types.model("Camera", {
  position: types.frozen<Communicator.Point3>(),
  target: types.frozen<Communicator.Point3>(),
  up: types.frozen<Communicator.Point3>(),
  width: types.number,
  height: types.number,
  projection: types.number,
  nearLimit: types.number,
  className: "Communicator.Camera",
});

export const ViewStore = types
  .model("View", {
    id: types.identifier,
    uniqueId: types.string,
    name: types.string,
    camera: CameraStore,
    cuttingData: types.frozen<CuttingData>(),
    explodeMagnitude: types.optional(types.number, 0),
    lineVisibility: types.boolean,
    faceVisibility: types.boolean,
    markup: types.array(types.string),
    sheetId: types.maybe(types.number),
    defaultVisibility: types.boolean,
    createdAt: types.string,
    updatedAt: types.string,
    visibilityExceptions: types.array(types.string),
    colors: types.array(types.string),
    imageSrc: types.optional(types.string, ""),
    recreated: types.optional(types.boolean, false), // false if annotation was created by user, true if annotation was recreated based on existing data
    cloudFileId: types.string,
    isolatedNodeId: types.maybeNull(types.number),
  })
  .actions(self => ({
    setName(name: string) {
      self.name = name;
    },
    activate() {
      hacktivateCustomView(self.uniqueId, self.recreated, self.isolatedNodeId || undefined);
      appStore.env.attachmentViewer?.setActiveSavedViewId(self.uniqueId);
    },
  }));

export interface IView extends Instance<typeof ViewStore> {}
export interface ICamera extends Instance<typeof CameraStore> {}
