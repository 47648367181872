import { CSSProperties, ForwardedRef, forwardRef, HTMLAttributes, ReactNode } from "react";
import classNames from "classnames";
import { observer } from "mobx-react";

import { TContainerProps } from "@components/Modeling/ModelingFrame/ModelBlock/Properties/PropertyContainer";

import "./SortableContainer.scss";

export interface Props {
  children: ReactNode;
  id: string;
  disabled?: boolean;
  isDragging?: boolean;
  newItemAbove?: boolean;
  isOver?: boolean;
  isOverByItem?: boolean;
  isEmpty?: boolean;
  style?: CSSProperties;
  renderContainer(containerProps: TContainerProps): ReactNode;
  handleProps?: HTMLAttributes<any>;
}

export const SortableContainer = forwardRef<HTMLDivElement, Props>((props: Props, ref: ForwardedRef<HTMLDivElement>) => {
  const {
    isEmpty,
    isOverByItem,
    isOver,
    newItemAbove,
    children,
    handleProps,
    id,
    style,
    disabled,
    renderContainer,
    isDragging,
    ...otherProps
  } = props;

  return (
    <div
      {...otherProps}
      ref={ref}
      style={style}
      className={classNames("sortable-container", {
        ["rollup-dnd-container--over-above"]: isOver && newItemAbove,
        ["rollup-dnd-container--over-below"]: isOver && !newItemAbove,
        ["rollup-dnd-container--over-by-item"]: isOverByItem && isEmpty,
      })}
    >
      {renderContainer({ id, children, handleProps, disabled, isDragging: !!isDragging })}
    </div>
  );
});

SortableContainer.displayName = "SortableContainer";
export default observer(SortableContainer);
