import Pane from "@router/components/Panes/Pane";
import CodeBlocksFrame from "src/Modules/Analysis/CodeBlocks/CodeBlocksFrame/CodeBlocksFrame";

/** Main function. */
function CodeBlockIndex() {
  return (
    <Pane className="code-block-index">
      <CodeBlocksFrame />
    </Pane>
  );
}

/** Exports. */
export default CodeBlockIndex;
