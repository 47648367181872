import { ComponentProps, FocusEvent, useState } from "react";
import { NumericInput as BPNumericInput } from "@blueprintjs/core";
import classNames from "classnames";
import { observer } from "mobx-react";

import "./NumericInput.module.scss";
import styles from "./NumericInput.module.scss";

interface INumericInputProps extends ComponentProps<typeof BPNumericInput> {}

const NumericInput = (props: INumericInputProps) => {
  const { className, onFocus, onBlur, ...restProps } = props;
  const [isFocused, setIsFocused] = useState(false);

  const handleFocus = (e: FocusEvent<HTMLInputElement>) => {
    setIsFocused(true);
    onFocus?.(e);
  };

  const handleBlur = (e: FocusEvent<HTMLInputElement>) => {
    setIsFocused(false);
    onBlur?.(e);
  };

  return (
    <BPNumericInput
      className={classNames(styles.numericInput, className, { [styles.focused]: isFocused })}
      onFocus={handleFocus}
      onBlur={handleBlur}
      {...restProps}
    />
  );
};

export default observer(NumericInput);
