import { useMemo } from "react";
import { useScrollGridToBottom } from "@hooks/useScrollGridToBottom";
import { useWorkspace } from "@hooks/useWorkspace";
import {
  ColumnMovedEvent,
  GridReadyEvent,
  IsFullWidthRowParams,
  RowClassParams,
  RowClassRules,
  RowClickedEvent,
  RowDragEndEvent,
} from "ag-grid-community";
import { observer } from "mobx-react";

import RequirementPageNewRowButton from "@components/Requirements/Page/RequirementPageNewRowButton";
import { handleReqBlockClick } from "@components/Requirements/Page/RequirementPageUtils";
import HeadingCellRenderer from "@components/Requirements/RequirementsTable/Cells/HeadingCellRenderer";
import { useReqTableColumnDefs } from "@components/Requirements/RequirementsTable/useReqTableColumnDefs";
import Table from "@components/Table";
import { RequirementBlockType } from "@rollup-api/models";
import appStore from "@store/AppStore";
import { IRequirementBlock, IRequirementsPage, isHeading } from "@store/RequirementsStore";

import { defaultColDef, ERequirementsTableColumn, HEADER_ROW_HEIGHT } from "./constants";
import { getRowDragText, getRowId, gridOptions, handleColumnResize } from "./utils";

import "./RequirementsTable.scss";
import styles from "./RequirementsTable.module.scss";

type TRequirementsProps = {
  rowData: IRequirementBlock[];
  page: IRequirementsPage;
};

const rowClassRules: RowClassRules<IRequirementBlock> = {
  "requirements-table--locked-row": (params: RowClassParams<IRequirementBlock>) => !!params.data?.locked,
};
const isFullWidthRow = (params: IsFullWidthRowParams<IRequirementBlock>) => !!params.rowNode.data && isHeading(params.rowNode.data.type);
const handleRowClick = (e: RowClickedEvent<IRequirementBlock>) => {
  const { data: reqBlock } = e;

  if (reqBlock) {
    handleReqBlockClick(reqBlock);
  }
};

const RequirementsTable = (props: TRequirementsProps) => {
  const { page } = props;
  const columnDefs = useReqTableColumnDefs(page);
  const scrollToBottom = useScrollGridToBottom(appStore.env.requirementsTableGridApi);
  const workspace = useWorkspace();

  const fullWidthCellRendererParams = useMemo(() => ({ onDeleteRequirementBlock: page.deleteRequirementBlock }), [page]);

  const handleAddRequirementsBlock = (type: RequirementBlockType) => {
    workspace.addRequirementsBlock(page, type);
    scrollToBottom();
  };

  const handleColumnMoved = (e: ColumnMovedEvent<IRequirementBlock>) => {
    const columnsOrder = e.api.getAllGridColumns().map(c => c.getColId()) as ERequirementsTableColumn[];
    appStore.env.columnsOrder.updateRequirementsTableColumnsOrder(columnsOrder);
  };

  const handleGridReady = (event: GridReadyEvent<IRequirementBlock>) => {
    appStore.env.setRequirementsTableGridApi(event.api);
  };

  const onRowDragEnd = (e: RowDragEndEvent<IRequirementBlock>) => {
    const targetIndex: number = e.overIndex;
    const nodeId: string | undefined = e.node.data?.id;
    const targetId: string | undefined = props.rowData[targetIndex]?.id;

    if (nodeId && targetId) {
      page.moveBlock(nodeId, targetId);
      e.api.refreshCells({ columns: [ERequirementsTableColumn.ID] });
    }
  };

  return (
    <div className={styles.requirementsTableContainer}>
      <Table<IRequirementBlock>
        suppressMoveWhenRowDragging
        className="requirements-table"
        getRowId={getRowId}
        onRowDragEnd={onRowDragEnd}
        rowClassRules={rowClassRules}
        onRowClicked={handleRowClick}
        rowDragManaged
        suppressAnimationFrame
        animateRows={false}
        headerHeight={HEADER_ROW_HEIGHT}
        rowDragText={getRowDragText}
        onColumnResize={handleColumnResize}
        onColumnMoved={handleColumnMoved}
        defaultColDef={defaultColDef}
        onGridReady={handleGridReady}
        columnDefs={columnDefs}
        rowData={props.rowData}
        isFullWidthRow={isFullWidthRow}
        fullWidthCellRenderer={HeadingCellRenderer}
        fullWidthCellRendererParams={fullWidthCellRendererParams}
        gridOptions={gridOptions}
        dynamicAutoHeight
      />
      <RequirementPageNewRowButton onAddRequirementsBlock={handleAddRequirementsBlock} />
    </div>
  );
};

export default observer(RequirementsTable);
