import { IconName, Intent } from "@blueprintjs/core";

import { EntityModelBase } from "../../shared/entityModelBase";

export enum EGitHubRepoStatus {
  Active = "active",
  Failed = "failed",
}

export interface IGitHubRepoStatus {
  status: EGitHubRepoStatus;
}

export enum IntegrationProvider {
  GitHub = "github",
  Slack = "slack",
  Google = "google",
  OnShape = "onshape",
}

export type IntegrationLinkData = {
  name: string;
  url?: string;
  workspaceId?: string;
  mimeType?: string;
  metadata: {
    provider: IntegrationProvider;
    type: string;
    [key: string]: any;
  };
};

export const allowedIntegrations = [
  IntegrationProvider.Google,
  IntegrationProvider.Slack,
  IntegrationProvider.GitHub,
  IntegrationProvider.OnShape,
];

export class IntegrationCommon extends EntityModelBase {
  public readonly provider!: IntegrationProvider;
  public readonly createdBy!: string;
  public readonly updatedBy!: string;
  public readonly metadata: any;
  public readonly isAuthorized?: boolean;
}

type GoogleIntegrationMetaData = {
  slug: string;
  refreshToken: string;
  teamDriveId: string;
  teamDriveBackgroundImageLink: string;
  teamDriveName: string;
};

type OnShapeIntegrationMetaData = {
  // @todo update when BE is ready
  company?: string;
};

export class GoogleIntegration extends IntegrationCommon {
  public declare readonly provider: IntegrationProvider.Google;
  public declare readonly metadata: GoogleIntegrationMetaData;
}

export class OnShapeIntegration extends IntegrationCommon {
  public declare readonly provider: IntegrationProvider.OnShape;
  public declare readonly metadata: OnShapeIntegrationMetaData;
}

export type Integration = IntegrationCommon | GoogleIntegration | OnShapeIntegration;

export const providers = Object.values(IntegrationProvider);

export type IntegrationItem<T extends { id: string }> = {
  data: T;
  group: string;
  title: string;
  label?: string;
  icon?: IconName;
  iconIntent?: Intent;
  score?: number;
};
