import { ChangeEvent, KeyboardEvent } from "react";
import { Alignment, InputGroup, Intent, Menu, MenuDivider, Tag } from "@blueprintjs/core";
import classNames from "classnames";
import { observer } from "mobx-react-lite";

import { Button } from "@components/Button";
import { MenuItem } from "@components/MenuItem";
import appStore from "@store/AppStore";
import { IStatusOption } from "@store/StatusOptionStore";
import { getIntentClassFromColor, ITagColor, TAG_COLORS } from "@utilities";

import "./EditStatusOption.scss";

interface IEditStatusOptionProps {
  statusOption: IStatusOption;
  onClose?(): void;
}

const EditStatusOption = (props: IEditStatusOptionProps) => {
  const { statusOption, onClose } = props;

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    statusOption.setLabel(event.target.value);
  };

  const onDelete = () => {
    onClose?.();
    appStore.workspaceModel?.deleteStatusOption(statusOption);
  };

  const onKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      event.stopPropagation();
      onClose?.();
    }
  };

  const renderColorTitle = (color: ITagColor, selected: boolean) => (
    <div className="edit-status-option--color-picker">
      <span
        className={classNames("edit-status-option--color-option", {
          "edit-status-option--color-option-selected": selected,
        })}
      >
        <Tag intent={Intent.NONE} className={getIntentClassFromColor(color.value)}>
          A
        </Tag>
      </span>
      <span className="edit-status-option--color-name">{color.name}</span>
    </div>
  );

  const renderColorOptions = (option: IStatusOption) =>
    TAG_COLORS.map(color => {
      const selected = option.color === color.value;
      const handleClick = () => {
        option.setColor(color.value);
        onClose?.();
      };

      return (
        <MenuItem
          key={`${option.id}${color.value}`}
          text={renderColorTitle(color, selected)}
          selected={option.color === color.value}
          onClick={handleClick}
          className="edit-status-option--menu-item"
          e2eIdentifiers={[`color${selected ? "-selected" : ""}`]}
          shouldDismissPopover={false}
        />
      );
    });

  return (
    <div className="edit-status-option">
      <MenuDivider className="edit-status-option--title" title="Edit Status" />
      <InputGroup
        className="edit-status-option--input"
        onKeyDown={onKeyDown}
        large
        intent={Intent.NONE}
        value={statusOption.label}
        onChange={onChange}
      />
      <Button
        className="edit-status-option--delete-button"
        onClick={onDelete}
        icon="trash"
        alignText={Alignment.LEFT}
        e2eIdentifiers="delete-status-option"
        minimal
      >
        Delete
      </Button>
      <MenuDivider className="edit-status-option--title" title="Colors" />
      <Menu role="listbox" className="edit-status-option--color-menu">
        {renderColorOptions(statusOption)}
      </Menu>
    </div>
  );
};

export default observer(EditStatusOption);
