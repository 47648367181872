import { resolveEntity } from "@rollup-io/engineering";

import appStore from "@store/AppStore";
import { IBlock } from "@store/BlockStore";
import { getWorkspaceById } from "@utilities/Workspace";

export const getPath = (block: IBlock): string[] => {
  if (!block.parentBlock) {
    return [block.id];
  }

  const path: string[] = [];

  const getParentBlockId = (sourceBlock: IBlock) => {
    path.unshift(sourceBlock.id);
    const parentBlock = sourceBlock.parentBlock as IBlock;

    if (!parentBlock) {
      return;
    }

    getParentBlockId(parentBlock);
  };

  getParentBlockId(block);
  return path;
};

export const getLabelsPath = (block: IBlock): string[] => {
  if (!block.parentBlock) {
    return [block.label];
  }

  const path: string[] = [];

  const getParentBlockLabel = (sourceBlock: IBlock) => {
    path.unshift(sourceBlock.label);
    const parentBlock = sourceBlock.parentBlock as IBlock;

    if (!parentBlock) {
      return;
    }

    getParentBlockLabel(parentBlock);
  };

  getParentBlockLabel(block);
  return path;
};

export const getBlockById = (id: string) => {
  return resolveEntity<IBlock>(appStore?.workspaceModel?.blockMap, id);
};

export const getBlockByIdWithWorkspace = async (id: string, workspaceId: string) => {
  const workspace = await getWorkspaceById(workspaceId);
  return resolveEntity<IBlock>(workspace?.blockMap, id);
};

export const getRecentBlocks = async (workspaceId: string): Promise<IBlock[] | undefined> => {
  const workspace = await getWorkspaceById(workspaceId);
  const recentBlocks: IBlock[] = [];
  workspace?.recentBlockIds.forEach(blockId => {
    const block = getBlockById(blockId);
    if (block) {
      recentBlocks.push(block);
    }
  });
  return recentBlocks;
};

export const getFilteredBlocks = async (workspaceId: string, searchTerm: string, limit?: number): Promise<IBlock[] | undefined> => {
  const workspace = await getWorkspaceById(workspaceId);
  if (workspace) {
    const filteredBlocks = Array.from(workspace.blockMap.values()).filter(block =>
      block.label.toLowerCase().includes(searchTerm.toLowerCase())
    );
    if (limit) {
      return filteredBlocks.slice(0, limit);
    } else {
      return filteredBlocks;
    }
  }
  return [];
};

export const getBlockHierarchyRecursion = (block: IBlock, hierarchy: string[] = []): string[] => {
  const incrementedHierarchy = [block.label, ...hierarchy];
  if (block.parentBlock) {
    return getBlockHierarchyRecursion(block.parentBlock, incrementedHierarchy);
  }
  return incrementedHierarchy;
};
