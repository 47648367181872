import { useNavigate } from "react-router-dom";
import { useWorkspace } from "@hooks/useWorkspace";

import EmptyWorkspaceMessage from "@router/components/EmptyWorkspaceMessage/EmptyWorkspaceMessage";
import { useSelectInitialDocument } from "@router/hooks/useSelectInitialDocument";
import appStore from "@store/AppStore";

function AnalysisIndex() {
  const navigate = useNavigate();
  const workspace = useWorkspace();

  useSelectInitialDocument({
    documentUrl: "analysis",
    documentPageMap: workspace.analysis.codeBlockMap,
    setActiveDocument: appStore.env.setActiveCodeBlock,
  });

  const handleNewCodeBlock = async () => {
    const newDoc = await workspace.analysis.createCodeBlock();
    if (newDoc) {
      appStore.env.setActiveCodeBlock(newDoc.id);
      navigate(newDoc.id);
    }
  };

  return (
    <EmptyWorkspaceMessage
      className="analysis-index"
      documentNameSingular="code block"
      hasDocument={!!appStore.workspaceModel?.analysis.codeBlockMap.size}
      onAddNewDocument={handleNewCodeBlock}
    />
  );
}

export default AnalysisIndex;
