import { useNavigate } from "react-router-dom";
import { Classes, Menu, MenuDivider } from "@blueprintjs/core";
import { observer } from "mobx-react";

import Avatar from "@components/Avatar/Avatar";
import { MenuItem } from "@components/MenuItem";
import appStore from "@store/AppStore";
import { Text, TextVariant } from "src/ui/Text";

import ThemeSubMenu from "./ThemeSubMenu";

import "./UserMenu.scss";

interface IUserMenuProps {
  username: string;
  userEmail?: string;
}

/** Main function. */
function UserMenu(props: IUserMenuProps) {
  const { username, userEmail } = props;
  const navigate = useNavigate();

  const shouldDisplayAvatar = username && userEmail && username !== userEmail;

  const handleSignOut = () => {
    appStore.logout();
  };

  return (
    <Menu className={Classes.RUNNING_TEXT}>
      <div className="user-menu">
        <Text className="user-menu--heading" variant={TextVariant.H5}>
          Account
        </Text>
        <div className="user-menu--avatar-container">
          {shouldDisplayAvatar && <Avatar source={appStore.userModel?.avatarUrl} size={33} />}
          <div className="user-menu--username-container">
            <Text className="user-menu--username" variant={TextVariant.Label}>
              {username}
            </Text>
            {userEmail && userEmail !== username && <Text variant={TextVariant.Caption}>{userEmail}</Text>}
          </div>
        </div>
      </div>
      <MenuItem
        className="user-menu--menu-item"
        icon="cog"
        onClick={() => {
          navigate("/settings");
        }}
        text="Settings"
        e2eIdentifiers="settings"
      />
      <ThemeSubMenu />
      <MenuDivider />
      <MenuItem className="user-menu--menu-item" icon="log-out" text="Sign Out" onClick={handleSignOut} e2eIdentifiers="sign-out" />
    </Menu>
  );
}

/** Exports. */
export default observer(UserMenu);
