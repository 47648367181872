import { ButtonGroup } from "@blueprintjs/core";
import classNames from "classnames";

import HoopsMenuAnnotationModeButton from "./HoopsMenuButtonGroups/HoopsMenuAnnotationModeButton";
import HoopsMenuSaveCustomViewButton from "./HoopsMenuButtonGroups/HoopsMenuSaveCustomViewButton";
import HoopsMenuSnapshotButton from "./HoopsMenuButtonGroups/HoopsMenuSnapshotButton";

import styles from "./HoopsMenu.module.scss";

type HoopsMenuLeftProps = {
  viewer: Communicator.WebViewer;
};

const HoopsMenuLeft = ({ viewer }: HoopsMenuLeftProps) => {
  return (
    <ButtonGroup large vertical className={classNames(styles.hoopsFloatingMenu, styles.left)}>
      <HoopsMenuSaveCustomViewButton />
      <HoopsMenuSnapshotButton viewer={viewer} />
      <HoopsMenuAnnotationModeButton viewer={viewer} />
    </ButtonGroup>
  );
};

export default HoopsMenuLeft;
