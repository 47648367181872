import { ReactElement } from "react";
import { Classes } from "@blueprintjs/core";
import { BlueprintIcon } from "@ui/BlueprintIcon";
import classNames from "classnames";

import styles from "./LegacyCustomIcon.module.scss";

export type LegacyCustomIconProps = {
  icon: ReactElement;
  large?: boolean;
  onClick?: () => void;
  className?: string;
};

export const LegacyCustomIcon = (props: LegacyCustomIconProps) => {
  const { icon, large } = props;
  const className = classNames(Classes.ICON, styles.legacyCustomIcon, props.className, { [styles.large]: large });
  return <BlueprintIcon icon={<div className={className}>{icon}</div>} />;
};
