import { observer } from "mobx-react";

import { MenuItem } from "@components/MenuItem";
import { defaultTableViewConfigTabId } from "@components/Modeling/ModelingFrame/Table/TableConfigTabs/constants";
import appStore from "@store/AppStore";
import { IPropertyDefinition } from "@store/PropertyDefinitionStore";

import { updateConfigWithAddedColumn } from "../utils";

import "./HeaderNav.scss";

const HeaderStatusNav = () => {
  const openHeaderCellNav = (colId: string) => {
    setTimeout(() => {
      const button: HTMLButtonElement | null = document.querySelector(`.ag-header-cell[col-id="${colId}"] button`);
      button?.click();
    }, 300);
  };

  const handleAddNewProperty = () => {
    appStore.workspaceModel?.addNewPropertyDefinition("New property").then((propertyDefinition: IPropertyDefinition | undefined) => {
      if (appStore.env.activeTableViewConfigId !== defaultTableViewConfigTabId && appStore.env.tableViewGridApi) {
        updateConfigWithAddedColumn(appStore.env.tableViewGridApi, propertyDefinition?.id);
      }
      propertyDefinition && openHeaderCellNav(propertyDefinition.id);
    });
  };

  const handleAddNewStatus = async () => {
    const statusDefinition = await appStore.workspaceModel?.addNewStatusDefinition("New status");
    setTimeout(() => {
      if (appStore.env.activeTableViewConfigId !== defaultTableViewConfigTabId && appStore.env.tableViewGridApi) {
        updateConfigWithAddedColumn(appStore.env.tableViewGridApi, statusDefinition?.id);
      }
    }, 100);
    statusDefinition && openHeaderCellNav(statusDefinition.id);
  };

  return (
    <>
      <MenuItem icon="new-layer" text="Add new Property" onClick={handleAddNewProperty} e2eIdentifiers="add-new-property" />
      <MenuItem icon="new-layer" text="Add new Status" onClick={handleAddNewStatus} e2eIdentifiers="add-new-status" />
    </>
  );
};

export default observer(HeaderStatusNav);
