import { observer } from "mobx-react";

import ImageUploaderCard from "@components/ImageUploaderCard";

import { RequirementDocumentBlockRow } from "../RequirementDocumentBlockRow";

import styles from "./RequirementDocumentBlockImageRow.module.scss";

interface IRequirementDocumentBlockImageRowProps {
  src: string;
  closeBtnClassName?: string;
  onSrcChange(url: string): void;
  onChangeAddingImage(value: boolean): void;
}

const RequirementDocumentBlockImageRow = (props: IRequirementDocumentBlockImageRowProps) => {
  const { src, closeBtnClassName, onSrcChange, onChangeAddingImage } = props;

  return (
    <RequirementDocumentBlockRow>
      <div className={styles.requirementDocumentBlockImageRow}>
        <ImageUploaderCard
          height={214}
          src={src}
          closeBtnClassName={closeBtnClassName}
          onSrcChange={onSrcChange}
          onClose={() => onChangeAddingImage(false)}
        />
      </div>
    </RequirementDocumentBlockRow>
  );
};

export default observer(RequirementDocumentBlockImageRow);
