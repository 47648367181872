import { ColumnState } from "ag-grid-community";
import { action, computed, observable } from "mobx";
import { Instance, types } from "mobx-state-tree";

import { getPropertyInstanceById } from "../Utilities";

import appStore from "./AppStore";

class TableViewUiStore {
  @observable accessor activeConfigId = "";

  @action.bound
  public setActiveConfigId(id: string) {
    this.activeConfigId = id;
  }

  @computed get commonTableColumnsConfig() {
    let data: ColumnState[] = appStore.env.tableViewGridApi?.getColumnState() || [];
    data.splice(0, 3); // remove multiplicity, action, tree columns
    data.splice(-1); // remove "add new" column
    data = data.map(c => ({ colId: c.colId, hide: c.hide, width: c.width, sortIndex: c.sortIndex }));
    return JSON.stringify({ data });
  }
}

export const TableViewStore = types
  .model("TableView", {
    activeConfigId: types.maybeNull(types.string),
    activePropertyInstanceId: types.optional(types.string, ""),
    activeStatusInstanceId: types.optional(types.string, ""),
  })
  .volatile(() => ({
    ui: new TableViewUiStore(),
  }))
  .actions(self => ({
    setActiveStatusInstance(instanceId: string) {
      self.activeStatusInstanceId = instanceId;
    },
    setActivePropertyInstance(instanceId: string) {
      const instance = getPropertyInstanceById(instanceId);
      if (instance) {
        appStore.env.setActivePropertyInstance(instance);
        self.activePropertyInstanceId = instanceId;
      }
    },
    deleteActiveInstance() {
      if (self.activePropertyInstanceId) {
        const instance = appStore.workspaceModel?.propertyInstanceMap.get(self.activePropertyInstanceId);
        instance && appStore.workspaceModel?.deletePropertyInstance(instance);
      }
      if (self.activeStatusInstanceId) {
        const instance = appStore.workspaceModel?.statusInstanceMap.get(self.activeStatusInstanceId);
        instance && appStore.workspaceModel?.deleteStatusInstance(instance);
      }
    },
  }));

export interface ITableView extends Instance<typeof TableViewStore> {}
