import { ReactNode } from "react";
import { observer } from "mobx-react";

interface IRequirementDocumentBlockRowProps {
  children: ReactNode;
}

const RequirementDocumentBlockRow = (props: IRequirementDocumentBlockRowProps) => {
  const { children } = props;

  return (
    <>
      <div />
      <div />
      {children}
      <div />
      <div />
    </>
  );
};

export default observer(RequirementDocumentBlockRow);
