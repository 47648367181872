import { CreateStatusOptionDto, StatusOption, StatusOptionUpdateDto } from "../models/statusOption";

import { HttpClient, ParentClient } from "./client";

export class StatusOptions extends HttpClient {
  public constructor(parent: ParentClient) {
    super(parent);
  }
  private get endpoint() {
    return `workspace/${this.parent.workspaceId}/status-options`;
  }

  public create = (dto: CreateStatusOptionDto) => {
    return this.instance.post<StatusOption>(`${this.endpoint}`, dto);
  };

  public update = (id: string, dto: StatusOptionUpdateDto) => {
    return this.instance.patch<StatusOption>(`${this.endpoint}/${id}`, dto);
  };

  public delete = (id: string) => {
    return this.instance.delete<StatusOption>(`${this.endpoint}/${id}`);
  };

  // TODO: Implement ordering
  public reorder = (id: string, destinationId: string) => {
    return this.instance.patch(`${this.endpoint}/${id}/reorder`, { destinationId });
  };
}
