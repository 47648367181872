import { useEffect, useState } from "react";

import { AttachmentStatus } from "@rollup-api/models";
import { ICatalogItem } from "@store/CatalogItem/CatalogItemStore";

import { rollupClient } from "../../core/api";

export const useCatalogItemImageUrl = (catalogItem?: ICatalogItem): [string, boolean] => {
  const attachment = catalogItem?.latestVersion?.attachment;
  const [cadThumbnail, setCadThumbnail] = useState("");
  const [loadingThumbnail, setLoadingThumbnail] = useState(attachment?.status === AttachmentStatus.ConversionInProgress);
  const [loadingNodeImage, setLoadingNodeImage] = useState(!!catalogItem?.latestVersion?.isThumbnailLoading);
  const { imageUrl, mainVersion } = catalogItem ?? {};
  const fileId = mainVersion?.attachmentId;
  const nodeId = mainVersion?.metaData?.nodeId;
  const pictureNodeId = mainVersion?.metaData?.pictureNodeId;

  const nodeImageUrl = fileId && rollupClient.attachments.getFileLink(fileId, null, false, 0, pictureNodeId || nodeId);

  useEffect(() => {
    setLoadingNodeImage(!!catalogItem?.latestVersion?.isThumbnailLoading);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [catalogItem?.latestVersion?.metaData?.thumbnailStatus]);

  useEffect(() => {
    setLoadingThumbnail(attachment?.status === AttachmentStatus.ConversionInProgress);

    if (attachment?.hasThumbnail) {
      setCadThumbnail(rollupClient.attachments.getThumbnailUrl(attachment.id, 0));
    } else {
      setCadThumbnail("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [attachment?.status, attachment?.hasThumbnail]);

  const link = imageUrl || nodeImageUrl || cadThumbnail || "";
  return [link, loadingNodeImage || loadingThumbnail];
};
