import classNames from "classnames";
import { observer } from "mobx-react";

import { Button } from "@components/Button";

import styles from "./EditorRightSideButtons.module.scss";

interface IEditorRightSideButtonsProps {
  hasComments?: boolean;
  className?: string;
  onAddComment(): void;
  onOpenDetails(): void;
}

const EditorRightSideButtons = (props: IEditorRightSideButtonsProps) => {
  const { hasComments, className, onAddComment, onOpenDetails } = props;

  return (
    <div className={classNames(styles.editorRightSideButtons, className)}>
      <Button
        className={classNames(styles.button, { [styles.hidden]: hasComments })}
        onClick={onAddComment}
        tooltip={hasComments ? "" : "Add comment"}
        icon="comment"
        e2eIdentifiers="show-comment-popover"
        minimal
      />
      <Button
        className={styles.button}
        onClick={onOpenDetails}
        tooltip="Open details panel"
        icon="detection"
        e2eIdentifiers="open-details-panel"
        minimal
      />
    </div>
  );
};

export default observer(EditorRightSideButtons);
