import { useWorkspace } from "@hooks/useWorkspace";
import { observer } from "mobx-react";

import RequirementsSidebarItem from "./RequirementsSidebarItem";

import "./RequirementsSidebar.scss";

interface IRequirementsSidebarProps {
  minimal?: boolean;
}

/** Main function. */
function RequirementsSidebar(props: IRequirementsSidebarProps) {
  const { minimal } = props;
  const workspace = useWorkspace();
  const reqPages = workspace.requirementsPages.values;

  return (
    <div className="requirements-sidebar">
      {reqPages.toReversed().map(reqPage => (
        <RequirementsSidebarItem key={reqPage.id} reqPage={reqPage} minimal={minimal} />
      ))}
    </div>
  );
}

/** Exports. */
export default observer(RequirementsSidebar);
