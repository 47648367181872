import { Instance, IType, SnapshotIn, SnapshotOut, types } from "mobx-state-tree";

import { entityTypeToPageMap } from "@router/hooks/useAppNavigate";
import { resolveAmongAllEntities, resolveEntityLabel } from "@utilities";
import { createNewLogger } from "@utilities/LoggingUtils";

import { EntityType } from "./types";

const logger = createNewLogger("FavoriteStore");
export const FavoriteStore = types
  .model("Favorite", {
    id: types.identifier,
    entityId: types.string,
    entityType: types.enumeration(Object.values(EntityType)),
    createdAt: types.optional(types.number, Date.now()),
    updatedAt: types.optional(types.number, Date.now()),
    createdBy: types.optional(types.string, ""),
    updatedBy: types.optional(types.string, ""),
  })
  .views(self => ({
    get isAlive(): boolean {
      return !!resolveAmongAllEntities(self.entityId, self.entityType);
    },
    get entityTypePage(): string | undefined {
      const entityTypePage = entityTypeToPageMap[self.entityType];
      if (!entityTypePage) {
        logger.error(`Entity type ${self.entityType} is not supported`);
      }
      return entityTypePage;
    },
    isActive(pathname: string): boolean {
      return pathname.includes(`${this.entityTypePage}/${self.entityId}`);
    },
    get label(): string {
      const label = resolveEntityLabel(self.entityId, self.entityType);
      return label || "Untitled";
    },
  }));

export interface IFavorite extends Instance<typeof FavoriteStore> {}
export interface IFavoriteSnapshotIn extends SnapshotIn<typeof FavoriteStore> {}
interface IFavoriteSnapshotOut extends SnapshotOut<typeof FavoriteStore> {}
export interface IFavoriteMobxType extends IType<IFavoriteSnapshotIn, IFavoriteSnapshotOut, IFavorite> {}
