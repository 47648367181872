import { ReactNode } from "react";
import classNames from "classnames";
import { observer } from "mobx-react-lite";

import "./AttachmentDetailsPanel.scss";

interface IAttachmentDetailsPanelProps {
  children: ReactNode;
  className?: string;
}

const AttachmentDetailsPanel = (props: IAttachmentDetailsPanelProps) => {
  return <div className={classNames("attachment-details-panel", props.className)}>{props.children}</div>;
};

export default observer(AttachmentDetailsPanel);
