import { BlueprintIcon } from "@ui/BlueprintIcon";
import classNames from "classnames";
import { observer } from "mobx-react";

import { EGitHubRepoStatus } from "@rollup-api/models";

import "./RepoStatusIcon.scss";

interface IRepoStatusIconProps {
  status: EGitHubRepoStatus;
}

const RepoStatusIcon = (props: IRepoStatusIconProps) => {
  return (
    <BlueprintIcon
      className={classNames("repo-status-icon", {
        ["repo-status-icon--active"]: props.status === EGitHubRepoStatus.Active,
        ["repo-status-icon--failed"]: props.status === EGitHubRepoStatus.Failed,
      })}
      icon="dot"
    />
  );
};

export default observer(RepoStatusIcon);
