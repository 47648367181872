import { KeyboardEvent, useEffect, useRef } from "react";
import { CellKeyDownEvent, FullWidthCellKeyDownEvent, GridApi, GridReadyEvent, IRowDragItem } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import { observer } from "mobx-react";

import { RightSidePanel } from "@components/Modeling/ModelingFrame/Table/TableComponent/Components/RightSidePanel";
import { defaultTableViewConfigTabId } from "@components/Modeling/ModelingFrame/Table/TableConfigTabs/constants";
import useTreeDrag from "@components/Table/hooks/useTreeDrag";
import Table from "@components/Table/Table";
import { BACKSPACE_KEY } from "@constants/keys";
import appStore from "@store/AppStore";
import { IBlock } from "@store/BlockStore";
import { IPropertyDefinition } from "@store/PropertyDefinitionStore";
import { IStatusDefinition } from "@store/StatusDefinitionStore";

import { blockNodeList } from "../utils";

import HeaderCell from "./Cells/HeaderCell";
import { AddNewChildRow } from "./Components/AddNewChildRow";
import getColDefs, { autoGroupColumnDef, defaultColDef } from "./columnDefs";
import { HEADER_HEIGHT, MULTIPLICITY_COL_ID, MULTIPLICITY_COLUMN_WIDTH, ROW_HEIGHT, TABLE_CLASS_NAME } from "./constants";
import { NodeInfo } from "./types";
import {
  applyActiveColumnStateConfig,
  getDataPath,
  getRowId,
  getStringActualColumnsConfig,
  handleCellFocused,
  parseGridColumnsToState,
} from "./utils";

import "./TabulatedViewTable.scss";

const TabulatedViewTable = () => {
  const tableRef = useRef<AgGridReact | null>(null);
  const { onRowDragEnd, onRowDragMove, onRowDragLeave } = useTreeDrag(
    appStore.env.tabulatedViewDefaultShowMultiplicity ? -MULTIPLICITY_COLUMN_WIDTH : 0
  );

  useEffect(() => {
    return () => {
      appStore.env.setTableViewGridApi(undefined);
    };
  }, []);

  useEffect(() => {
    if (tableRef.current) {
      tableRef.current.api?.setGridOption("columnDefs", getColDefs());
    }
    if (appStore.env.tableViewGridApi) {
      applyActiveColumnStateConfig(appStore.env.tableViewGridApi);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    appStore.workspaceModel?.propertyDefinitions.length,
    appStore.workspaceModel?.modelStatusDefinitions.length,
    appStore.workspaceModel?.tableViewConfigs.size,
  ]);

  useEffect(() => {
    tableRef.current?.api?.setColumnsVisible([MULTIPLICITY_COL_ID], appStore.env?.tabulatedViewDefaultShowMultiplicity);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appStore.env?.tabulatedViewDefaultShowMultiplicity]);

  useEffect(() => {
    const statusDefinitionsIds = appStore.workspaceModel?.modelStatusDefinitions.map((s: IStatusDefinition) => s.id) || [];
    tableRef.current?.api?.setColumnsVisible(statusDefinitionsIds, appStore.env?.tabulatedViewShowProjectStatuses);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appStore.env?.tabulatedViewShowProjectStatuses]);

  // TODO we probably don't need this effect since this is already done in a previous effect
  useEffect(() => {
    if (appStore.env.tableViewGridApi) {
      applyActiveColumnStateConfig(appStore.env.tableViewGridApi);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appStore.env.activeTableViewConfigId]);

  useEffect(() => {
    const emptyInstances = appStore.workspaceModel?.propertyDefinitions.filter((p: IPropertyDefinition) => !p.instancesLength) || [];
    if (emptyInstances.length) {
      tableRef.current?.api?.setColumnsVisible(
        emptyInstances.map((p: IPropertyDefinition) => p.id),
        appStore.env?.tabulatedViewDefaultShowEmpty
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appStore.env?.tabulatedViewDefaultShowEmpty]);

  const handleGridReady = (e: GridReadyEvent<NodeInfo>) => {
    appStore.env.setTableViewGridApi(e.api);
    e.api.setGridOption("columnDefs", getColDefs());
    applyActiveColumnStateConfig(e.api);
    e.api.setGridOption("rowData", blockNodeList());
  };

  const handleColumnResize = (_column: string, _width: number, columnApi: GridApi) => {
    const columnsState = parseGridColumnsToState(columnApi);
    const actualConfig = getStringActualColumnsConfig(columnsState);
    const activeTableViewConfig = appStore.workspaceModel?.tableViewConfigs.get(appStore.env.activeTableViewConfigId);

    if (appStore.env.activeTableViewConfigId !== defaultTableViewConfigTabId) {
      activeTableViewConfig?.setConfig(actualConfig);
    }
  };

  const handleCellKeydown = (event: CellKeyDownEvent<NodeInfo> | FullWidthCellKeyDownEvent<NodeInfo>) => {
    const isCellEditing = event.api.getEditingCells().length;
    if (event.event) {
      const keyEvent = event.event as unknown as KeyboardEvent<any>;
      if (keyEvent.key === BACKSPACE_KEY && !isCellEditing) {
        appStore.env.tableView.deleteActiveInstance();
      }
    }
  };

  const onDestroyed = () => {
    appStore.env.setTableViewGridApi();
  };

  const getRowDragText = (params: IRowDragItem) => {
    const block = params.rowNode?.data.block as IBlock | undefined;
    return block?.label || "Table row";
  };

  return (
    <div className="tabulated-view-table--row-container">
      <div className="tabulated-view-table--wrap">
        <Table<NodeInfo>
          alwaysShowHorizontalScroll
          className={TABLE_CLASS_NAME}
          components={{ agColumnHeader: HeaderCell }}
          disableAutoHeight
          defaultColDef={defaultColDef}
          rowDragText={getRowDragText}
          getDataPath={getDataPath}
          getRowId={getRowId}
          gridOptions={{
            rowHeight: ROW_HEIGHT,
            headerHeight: HEADER_HEIGHT,
            groupDefaultExpanded: -1,
            autoGroupColumnDef,
          }}
          onCellFocused={handleCellFocused}
          onCellKeyDown={handleCellKeydown}
          onColumnResize={handleColumnResize}
          onChartDestroyed={onDestroyed}
          onGridReady={handleGridReady}
          onRowDragEnd={onRowDragEnd}
          onRowDragMove={onRowDragMove}
          onRowDragLeave={onRowDragLeave}
          rowBuffer={25}
          tableRef={tableRef}
          dynamicAutoHeight
          treeData
        />
        <AddNewChildRow />
      </div>
      <RightSidePanel />
    </div>
  );
};

export default observer(TabulatedViewTable);
