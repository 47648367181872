export enum BlockIcon {
  Assembly = "Assembly",
  Block = "Block",
  ChildBlock = "ChildBlock",
  Interface = "Interface",
  Part = "Part",
  Port = "Port",
  Product = "Product",
  Signal = "Signal",
  Subsystem = "Subsystem",
}

export enum ModelingIcon {
  BlockTree = "BlockTree",
  CustomUnits = "CustomUnits",
  DigitalThreads = "DigitalThreads",
  Hierarchy = "Hierarchy",
  ModelConfiguration = "ModelConfiguration",
  PropertyDefinitions = "PropertyDefinitions",
  PropertyRelations = "PropertyRelations",
  Programmatics = "Programmatics",
  StatusDefinitions = "StatusDefinitions",
  Table = "Table",
}

export enum ModuleColorfulIcon {
  Analysis = "Analysis",
  HardwareCatalog = "HardwareCatalog",
  Inbox = "Inbox",
  Knowledgebase = "Knowledgebase",
  Modeling = "Modeling",
  Requirements = "Requirements",
}

export enum ModuleMonochromeIcon {
  Bom = "Bom",
  Chat = "Chat",
  DataConnection = "DataConnection",
  Feedback = "Feedback",
  FileStorage = "FileStorage",
  Import = "Import",
  InviteUser = "InviteUser",
  ItemCatalog = "ItemCatalog",
  ProductAndReleases = "ProductAndReleases",
  RequirementsBw = "RequirementsBw",
  Workspace = "Workspace",
}

export enum CommentIcon {
  Reply = "Reply",
  AddComment = "AddComment",
}

export enum ThreadsIcon {
  ThreadsHorizontal = "ThreadsHorizontal",
  ThreadsVertical = "ThreadsVertical",
}

export type ICustomIconName = BlockIcon | ModelingIcon | ModuleColorfulIcon | ModuleMonochromeIcon | CommentIcon | ThreadsIcon;

export const customIconsList = Object.values(BlockIcon);
