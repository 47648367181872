import { CSSProperties, MouseEvent } from "react";
import { BlueprintIcon } from "@ui/BlueprintIcon";
import { IRowNode } from "ag-grid-community";

import styles from "./CellLines.module.scss";

interface ICellLinesStyles {
  initialPaddingWithChild?: number;
  nodeLevelPaddingStep: number;
  lastChildLineNodeHeight?: CSSProperties["height"];
  straightLineTop?: CSSProperties["top"];
  straightLineBottom?: CSSProperties["bottom"];
  childLineNodeHeight?: CSSProperties["height"];
  curveLineTopPosition?: number;
  curveLineHeight?: CSSProperties["height"];
  curveLineWidth?: CSSProperties["width"];
}

interface ICellLinesProps {
  node: IRowNode;
  hasChild: boolean;
  isExpanded: boolean;
  cellLinesStyles: ICellLinesStyles;
  active?: boolean;
  onToggleExpand(e: MouseEvent): void;
}

const getParentLineNodes = (node: IRowNode, lineNodes: IRowNode[] = []): IRowNode[] => {
  if (node.parent && node.parent.uiLevel) {
    if (!node.parent.lastChild) {
      lineNodes.push(node.parent);
    }
    return getParentLineNodes(node.parent, lineNodes);
  }
  return lineNodes;
};

export const CellLines = (props: ICellLinesProps) => {
  const { node, hasChild, isExpanded, cellLinesStyles, active, onToggleExpand } = props;
  const {
    lastChildLineNodeHeight = 10,
    initialPaddingWithChild = 0,
    nodeLevelPaddingStep,
    childLineNodeHeight = "auto",
    straightLineTop = 0,
    straightLineBottom = 0,
    curveLineTopPosition = 9,
    curveLineHeight = 8,
    curveLineWidth = 10,
  } = cellLinesStyles;

  const renderParentLines = (sourceNode: IRowNode) => {
    if (!sourceNode.uiLevel) {
      return null;
    }

    const lines = getParentLineNodes(sourceNode);

    return lines.map((node: IRowNode) => (
      <div
        style={{
          left: `${node.uiLevel * nodeLevelPaddingStep - initialPaddingWithChild}px`,
          top: straightLineTop,
          bottom: straightLineBottom,
        }}
        className={styles.cellLinesStraight}
        key={node.id}
      />
    ));
  };

  const renderCurveLine = () => {
    if (!props.node.uiLevel) {
      return null;
    }

    return (
      <>
        <div
          style={{
            left: nodeLevelPaddingStep * props.node.uiLevel - initialPaddingWithChild,
            top: straightLineTop,
            bottom: straightLineBottom,
            height: props.node.lastChild ? lastChildLineNodeHeight : childLineNodeHeight,
          }}
          className={styles.cellLinesStraight}
        />
        <div
          style={{
            left: nodeLevelPaddingStep * props.node.uiLevel - initialPaddingWithChild,
            top: curveLineTopPosition,
            height: curveLineHeight,
            width: curveLineWidth,
          }}
          className={styles.cellLinesCurve}
        />
      </>
    );
  };

  const renderChildrenLine = () => {
    return (
      <div
        style={{
          left: `${(node.uiLevel + 1) * nodeLevelPaddingStep - initialPaddingWithChild}px`,
          top: curveLineTopPosition + 14,
          bottom: straightLineBottom,
        }}
        className={styles.cellLinesStraight}
        key={node.id}
      />
    );
  };

  return (
    <div className={styles.cellLines}>
      {renderParentLines(node)}
      {renderCurveLine()}
      {hasChild && (
        <BlueprintIcon
          className={styles.cellLinesIcon}
          icon={isExpanded ? "caret-down" : "caret-right"}
          highlight={active}
          onClick={onToggleExpand}
        />
      )}
      {hasChild && isExpanded && renderChildrenLine()}
    </div>
  );
};
