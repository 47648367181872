import { TransactionPropertyDefinitionEntity } from "@rollup-api/models/transactions";
import appStore from "@store/AppStore";
import { EFeedActionType, IFeed } from "@store/FeedStore";

import { getChangedString } from "../utils";

import { PropertyFeedFormatter } from "./PropertyFeedFormatter";

import styles from "../FeedPanel.module.scss";

type IPropertyDefinitionAllowedKeys = keyof Omit<TransactionPropertyDefinitionEntity, "propertyInstances">;

export abstract class PropertyDefinitionFeedFormatter {
  private static fieldToLabelMap: Partial<Record<IPropertyDefinitionAllowedKeys, string>> = {
    dataType: "data type",
    autoAdd: "block default",
    autoRollupChildren: "rollup",
    uncertaintyType: "uncertainty type",
    uncertaintySymmetry: "uncertainty symmetry",
    description: "description",
    defaultPropertyGroup: "group",
    unit: "unit",
  };

  public static getPropertyActionText(feedItem: IFeed) {
    switch (feedItem.method) {
      case EFeedActionType.UPDATE:
        return PropertyDefinitionFeedFormatter.getUpdateMessage(feedItem);
      case EFeedActionType.DELETE:
        return PropertyFeedFormatter.getDeleteMessage(feedItem);
      default: {
        const newEntity = feedItem.newEntity as TransactionPropertyDefinitionEntity;
        return (
          <span className={styles.feedPanelAction}>
            {feedItem.actionTitle} {feedItem.entityTitle}
            <span className={styles.feedPanelEntity}>
              &nbsp;
              {newEntity.label}
            </span>
          </span>
        );
      }
    }
  }

  public static getUpdateMessage(feedItem: IFeed) {
    const newEntity = feedItem.newEntity as TransactionPropertyDefinitionEntity;
    const oldEntity = feedItem.oldEntity as TransactionPropertyDefinitionEntity;
    const { label: newLabel } = newEntity;
    const { label: oldLabel } = oldEntity;

    if (newLabel && oldLabel && newLabel !== oldLabel) {
      return (
        <span className={styles.feedPanelAction}>
          renamed {feedItem.entityTitle} from <span className={styles.feedPanelEntity}>{oldLabel}</span> to
          <span className={styles.feedPanelEntity}> {newEntity.label}</span>
        </span>
      );
    }

    const changedField = PropertyDefinitionFeedFormatter.getChangedField(newEntity, oldEntity);

    if (changedField) {
      const newFieldValue = newEntity[changedField];
      const oldFieldValue = oldEntity[changedField];
      const fieldLabel = PropertyDefinitionFeedFormatter.fieldToLabelMap[changedField];

      if (newFieldValue !== undefined && fieldLabel) {
        return getChangedString({
          newValue: newFieldValue,
          changedField: fieldLabel,
          entityType: feedItem.entityTitle,
          oldValue: oldFieldValue,
          name: PropertyDefinitionFeedFormatter.getLabelById(feedItem.referenceId),
        });
      }
    }

    return PropertyFeedFormatter.getUpdateMessage(feedItem);
  }

  private static getLabelById(id: string): string {
    const propertyDefinition = appStore.workspaceModel?.propertyDefinitionMap.get(id);
    return propertyDefinition?.label ?? "";
  }

  private static getChangedField(
    newEntity: TransactionPropertyDefinitionEntity,
    oldEntity: TransactionPropertyDefinitionEntity
  ): IPropertyDefinitionAllowedKeys | undefined {
    const fields = Object.keys(PropertyDefinitionFeedFormatter.fieldToLabelMap) as Array<IPropertyDefinitionAllowedKeys>;
    return fields.find(field => newEntity[field] !== null && newEntity[field] !== oldEntity[field]);
  }
}
