import { Handle, NodeProps, Position } from "reactflow";
import { observer } from "mobx-react";

import { BlockIcon, CustomIcon } from "@components/CustomIcon";
import { CatalogItemType } from "@rollup-api/models/catalogItem/catalogItemDtos";
import appStore from "@store/AppStore";
import { ICatalogItem } from "@store/CatalogItem/CatalogItemStore";

import "./CatalogItemNode.scss";

export type CatalogItemNodeData = {
  catalogItem: ICatalogItem;
};

type CatalogItemNodeProps = NodeProps<CatalogItemNodeData>;

const CatalogItemNode = (props: CatalogItemNodeProps) => {
  const { targetPosition, sourcePosition } = props;
  const { catalogItem } = props.data;

  const getIcon = (): BlockIcon => {
    const hasRefs = !appStore.ui.showPdmFlatten && catalogItem.references.length;
    if (catalogItem.type === CatalogItemType.Assembly || hasRefs || catalogItem.hasChildren) {
      return BlockIcon.Assembly;
    }

    return BlockIcon.Part;
  };

  return (
    <div className="catalog-item-node">
      <Handle type="target" position={targetPosition || Position.Left} className="invisible" />
      <div className="catalog-item-node--content">
        <CustomIcon className="catalog-item-node--icon" icon={getIcon()} />
        <div className="catalog-item-node--label">{catalogItem.name}</div>
      </div>
      <Handle type="source" position={sourcePosition || Position.Right} className="invisible" />
    </div>
  );
};

export default observer(CatalogItemNode);
