import { HoopsView, UpdateHoopsViewDto } from "@rollup-api/models";
import { CreateHoopsViewDto } from "@rollup-api/models";

import { HttpClient, ParentClient } from "./client";

export class Views extends HttpClient {
  public constructor(parent: ParentClient) {
    super(parent);
  }

  private get endpoint() {
    return "views";
  }

  public create = (dto: CreateHoopsViewDto) => {
    return this.instance.post<HoopsView>(`${this.endpoint}`, dto);
  };

  public retrieve = (id: string) => {
    return this.instance.get<HoopsView>(`${this.endpoint}/${id}`);
  };

  public retrieveList = () => {
    return this.instance.get<HoopsView[]>(`${this.endpoint}`);
  };

  public retrieveByAttachmentId = (attachmentId: string) => {
    return this.instance.get<HoopsView[]>(`${this.endpoint}/find_by_attachment_id/${attachmentId}`, { params: { take: 9999, skip: 0 } });
  };

  public update = (id: string, dto: UpdateHoopsViewDto) => {
    return this.instance.patch<HoopsView>(`${this.endpoint}/${id}`, dto);
  };

  public delete = (id: string) => {
    return this.instance.delete<HoopsView>(`${this.endpoint}/${id}`);
  };
}
