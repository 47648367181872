import { ReactElement } from "react";
import { NavLink, NavLinkProps } from "react-router-dom";
import { Classes, IconName, MenuItemProps, Tooltip } from "@blueprintjs/core";
import { BlueprintIcon } from "@ui/BlueprintIcon";
import classNames from "classnames";

import { Icon } from "@components/Icon";
import { TitledTooltip } from "@components/Shared";
import { withDataTestId } from "@components/Shared/WithDataTestId";
import { IIcon } from "@rollup-types/icons";
import { NavLinkBackgroundColor } from "@router/navigation/NavLinkAsMenuItem.types";
import { ElementType, IE2EIdentifiersParam } from "@utilities/E2EUtils";
import { isNewIconFormat } from "@utilities/Icon";

import { getActiveBgColorClassName, getHoverBgColorClassName } from "./NavLinkAsMenuItem.utils";

import "./NavLinkAsMenuItem.scss";

export type INestedNavLinkAsMenuItem = Array<Omit<INavLinkAsMenuItem, "nestedLinks">>;

/** Type defs. */
interface INavLinkAsMenuItem extends NavLinkProps, IE2EIdentifiersParam {
  text?: MenuItemProps["text"];
  icon?: (IconName | ReactElement) | IIcon;
  activeBgColor?: NavLinkBackgroundColor;
  showInactiveBgColor?: boolean;
  label?: MenuItemProps["label"];
  tooltip?: string | { title: string; content: ReactElement | string };
  selected?: boolean;
  disabled?: boolean;
  open?: boolean;
  nestedLinks?: INestedNavLinkAsMenuItem;
  dividerBelow?: boolean;
  textClassName?: string;
  expandedInitialState?: boolean;
  collapseIfSidebarCollapsed?: boolean;
  onToggleExpand?(): void;
  renderRightElements?(className: string): ReactElement;
}

type INavLinkAsMenuItemProps = Omit<INavLinkAsMenuItem, keyof IE2EIdentifiersParam>;

/** Main function. */
function NavLinkAsMenuItem(props: INavLinkAsMenuItemProps) {
  const {
    className,
    open = true,
    activeBgColor,
    showInactiveBgColor,
    text,
    label,
    icon,
    to,
    tooltip,
    selected,
    disabled,
    textClassName,
    renderRightElements,
    ...rest
  } = props;

  const renderIcon = () => {
    if (isNewIconFormat(icon)) {
      return <Icon className="navlink-menu-item--icon" icon={icon} active={selected} />;
    }

    return icon;
  };

  const activeBgColorClassName = getActiveBgColorClassName(activeBgColor);
  const hoverBgColorClassName = getHoverBgColorClassName(activeBgColor);

  return (
    <NavLink
      to={to}
      className={classNames(
        typeof className === "string" ? className : undefined,
        Classes.MENU_ITEM,
        Classes.POPOVER_DISMISS,
        "navlink-menu-item",
        hoverBgColorClassName,
        {
          "navlink-menu-item--active": selected,
          [activeBgColorClassName]: selected,
          [Classes.DISABLED]: disabled,
          "navlink-menu-item--inactive-bg-color": showInactiveBgColor && !selected,
        }
      )}
      {...rest}
    >
      {!!icon &&
        (typeof tooltip === "string" || !tooltip ? (
          <Tooltip content={tooltip} disabled={!tooltip}>
            <span className={classNames(Classes.MENU_ITEM_ICON)}>
              <BlueprintIcon className="navlink-menu-item--icon" icon={renderIcon()} active={selected} />
            </span>
          </Tooltip>
        ) : (
          <TitledTooltip title={tooltip.title} content={tooltip.content} hoverOpenDelay={100}>
            <span className={classNames(Classes.MENU_ITEM_ICON)}>
              <BlueprintIcon className="navlink-menu-item--icon" icon={renderIcon()} active={selected} />
            </span>
          </TitledTooltip>
        ))}
      {open && (
        <>
          {!!text && <span className={classNames(Classes.FILL, Classes.TEXT_OVERFLOW_ELLIPSIS, textClassName)}>{text}</span>}
          {!!label && <span className={Classes.MENU_ITEM_LABEL}>{label}</span>}
          {renderRightElements?.("navlink-menu-item--right-element")}
        </>
      )}
    </NavLink>
  );
}

/** Exports. */
export type { INavLinkAsMenuItem };
export default withDataTestId(NavLinkAsMenuItem, ElementType.NavLink);
