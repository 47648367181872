import { useWorkspace } from "@hooks/useWorkspace";
import { observer } from "mobx-react";

import CodeBlocksSidebarItem from "../CodeBlocks/CodeBlocksSidebarItem/CodeBlocksSidebarItem";

import "./AnalysisSidebar.scss";

function AnalysisSidebar({ minimal }: { minimal?: boolean }) {
  const workspace = useWorkspace();
  const codeBlocks = workspace.analysis.codeBlocks;

  return (
    <div className="code-blocks-sidebar">
      {codeBlocks.toReversed().map(block => (
        <CodeBlocksSidebarItem key={block.id} codeBlock={block} minimal={minimal} />
      ))}
    </div>
  );
}

export default observer(AnalysisSidebar);
