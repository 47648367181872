import { useState } from "react";
import { ButtonGroup, DialogBody, InputGroup, Intent, Label, TextArea } from "@blueprintjs/core";
import { observer } from "mobx-react";
import rrwebPlayer from "rrweb-player";

import { Button } from "@components/Button";
import { DialogLegacy } from "@components/Dialog";
import appStore, { AuthStatus } from "@store/AppStore";

import { placeholdersByDialogType, supportEmail } from "./constants";
import { EFeedbackType } from "./types";

import "rrweb-player/dist/style.css";
import "./FeedbackDialog.scss";

export const FeedbackDialog = () => {
  const isLoggedIn = appStore.authStatus === AuthStatus.LoggedIn;
  const [title, setTitle] = useState("");
  const [email, setEmail] = useState<string>(appStore.userModel?.email || "");
  const [replace, setReplace] = useState(false);
  const [description, setDescription] = useState("");

  const renderEmailInput = () => {
    if (isLoggedIn) {
      return null;
    }

    return (
      <Label>
        Add an email
        <InputGroup placeholder="Enter here" large value={email} onChange={e => setEmail(e.target.value)} />
      </Label>
    );
  };

  const getSubmitDisabled = () => {
    const missingContent = !description;

    if (isLoggedIn) {
      return missingContent;
    }

    return missingContent || !email;
  };

  const resetState = () => {
    setReplace(false);
    window.birdeatsbug?.deleteSession();
    appStore.feedback.resetMediaData();
  };

  const takeScreenshot = () => {
    resetState();
    window.birdeatsbug?.takeScreenshot();
  };

  const removeMedia = () => {
    appStore.feedback.resetMediaData();
  };

  const handleClose = () => {
    resetState();
    appStore.feedback.hide();
  };

  const startRecording = () => {
    handleClose();
    window.birdeatsbug?.startRecording();
  };

  const renderPlayer = () => {
    setTimeout(() => {
      const playerContainer = document.getElementById("playerContainer") as HTMLDivElement;
      const events = appStore.feedback.getRecordingSessionData().domEvents;

      if (playerContainer.children.length || events.length < 2) {
        return;
      }

      new rrwebPlayer({
        target: playerContainer,
        props: {
          triggerFocus: false,
          height: 400,
          width: 576,
          autoPlay: true,
          inactiveColor: "#2f343c",
          showController: false,
          events,
        },
      });
    }, 50);
  };

  const renderMedia = () => {
    if (appStore.feedback.screenshotUrl) {
      return <img alt="screenshot" width="100%" src={appStore.feedback.screenshotUrl} />;
    }

    if (appStore.feedback.recordingSessionData) {
      renderPlayer();
    }
  };

  const renderVisualBlock = () => {
    if (appStore.feedback.screenshotUrl || appStore.feedback.recordingSessionData) {
      return (
        <div className="feedback-dialog--visual">
          <div id="playerContainer"></div>
          {renderMedia()}
          <ButtonGroup large fill>
            {replace ? (
              <>
                <Button
                  onClick={startRecording}
                  minimal
                  fill
                  icon="mobile-video"
                  text="Start recording"
                  e2eIdentifiers={["feedback-dialog", "start-recording"]}
                />
                <Button
                  onClick={takeScreenshot}
                  minimal
                  fill
                  icon="clip"
                  text="Take screenshot"
                  e2eIdentifiers={["feedback-dialog", "take-screenshot"]}
                />
                <Button onClick={() => setReplace(false)} minimal fill text="Cancel" e2eIdentifiers={["feedback-dialog", "cancel"]} />
              </>
            ) : (
              <>
                <Button icon="refresh" minimal fill onClick={() => setReplace(true)} e2eIdentifiers={["feedback-dialog", "replace"]}>
                  Replace
                </Button>
                <Button
                  intent={Intent.DANGER}
                  minimal
                  fill
                  onClick={removeMedia}
                  icon="trash"
                  e2eIdentifiers={["feedback-dialog", "remove"]}
                >
                  Remove
                </Button>
              </>
            )}
          </ButtonGroup>
        </div>
      );
    }

    return null;
  };

  const renderButtons = () => {
    if (appStore.feedback.screenshotUrl || appStore.feedback.recordingSessionData) {
      return null;
    }

    return (
      <div className="feedback-dialog--buttons">
        <Button
          onClick={startRecording}
          fill
          large
          outlined
          icon="mobile-video"
          text="Start recording"
          e2eIdentifiers={["feedback-dialog", "start-recording"]}
        />
        <Button
          onClick={takeScreenshot}
          fill
          large
          outlined
          icon="clip"
          text="Take screenshot"
          e2eIdentifiers={["feedback-dialog", "take-screenshot"]}
        />
      </div>
    );
  };

  const handleUploadSession = () => {
    appStore.feedback.uploadSessionData(email, title, description);
    setDescription("");
    setTitle("");
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if ((event.ctrlKey || event.metaKey) && event.key === "Enter") {
      if (!getSubmitDisabled()) {
        handleUploadSession();
        event.preventDefault();
      }
    }
  };

  const placeholders = placeholdersByDialogType[appStore.feedback.collectionId as EFeedbackType];

  return (
    <div className="feedback-dialog" onKeyDown={handleKeyDown}>
      <DialogLegacy className="feedback-dialog--wrap" isOpen={appStore.feedback.visible} onClose={handleClose} title="Send feedback">
        <DialogBody>
          {renderVisualBlock()}
          {renderButtons()}
          <div className="mb-3">
            <Button
              onClick={() => appStore.feedback.setCollectionId(EFeedbackType.PROBLEM)}
              minimal
              active={appStore.feedback.collectionId === EFeedbackType.PROBLEM}
              text="Problem"
              e2eIdentifiers={["feedback-dialog", "problem"]}
            />
            <Button
              onClick={() => appStore.feedback.setCollectionId(EFeedbackType.QUESTION)}
              minimal
              active={appStore.feedback.collectionId === EFeedbackType.QUESTION}
              text="Question"
              e2eIdentifiers={["feedback-dialog", "question"]}
            />
            <Button
              onClick={() => appStore.feedback.setCollectionId(EFeedbackType.FEEDBACK_AND_REQUEST)}
              minimal
              active={appStore.feedback.collectionId === EFeedbackType.FEEDBACK_AND_REQUEST}
              text="Feedback & Request"
              e2eIdentifiers={["feedback-dialog", "feedback-&-request"]}
            />
          </div>
          {renderEmailInput()}
          <Label>
            <p className="feedback-dialog--label mb-0 flex justify-between">
              <span>Add a title</span>
              <span>Optional</span>
            </p>
            <InputGroup
              placeholder={placeholders.title}
              large
              onChange={e => setTitle(e.target.value)}
              value={title}
              onKeyDown={handleKeyDown}
            />
          </Label>
          <Label>
            Add a description
            <TextArea
              rows={5}
              className="feedback-dialog--description"
              value={description}
              onChange={e => setDescription(e.target.value)}
              fill
              placeholder={placeholders.description}
              onKeyDown={handleKeyDown}
              autoFocus
            />
          </Label>
          <div className="flex justify-end mb-4">
            <Button
              loading={appStore.feedback.loading}
              onClick={handleUploadSession}
              disabled={getSubmitDisabled()}
              large
              intent="primary"
              e2eIdentifiers={["feedback-dialog", "submit"]}
            >
              Submit
            </Button>
          </div>
          <div>
            You can always email us at{" "}
            <a href={`mailto:${supportEmail}`} target="_blank" rel="noreferrer">
              {supportEmail}
            </a>
          </div>
        </DialogBody>
      </DialogLegacy>
    </div>
  );
};

export default observer(FeedbackDialog);
