import { type MouseEvent as ReactMouseEvent, RefObject, useEffect, useState } from "react";
import ReactFlow, { Node, useReactFlow } from "reactflow";
import { ButtonGroup } from "@blueprintjs/core";
import { observer } from "mobx-react";

import { Button } from "@components/Button";
import CollapsibleCard from "@components/CatalogItemPreview/Components/CollapsibleCard";
import { CustomIcon, ThreadsIcon } from "@components/CustomIcon";
import appStore from "@store/AppStore";
import { ICatalogItem } from "@store/CatalogItem/CatalogItemStore";
import { PdmCard } from "@store/PdmCardsVisibilityStore";

import { defaultViewport, nodeTypes } from "./constants";
import { RelationData, RelationNodeTypes, TreeNodeData } from "./types";
import { getFileRelationData, getTreeRelationData } from "./utils";

import styles from "./RelationsCard.module.scss";

type Props = {
  catalogItem: ICatalogItem;
  isVertical?: boolean;
  activeVersion: string;
  containerRef?: RefObject<HTMLDivElement>;
};

enum RelationType {
  File = "file",
  Tree = "tree",
}

const RelationsCard = (props: Props) => {
  const { catalogItem, isVertical, activeVersion, containerRef } = props;
  const [relationType, setRelationType] = useState<RelationType>(RelationType.File);
  const [relationData, setRelationData] = useState<RelationData>({ nodes: [], edges: [] });
  const version = appStore.orgModel.catalogItems.getCatalogItemVersion(activeVersion);
  const { setCenter, getNode } = useReactFlow();

  useEffect(() => {
    if (catalogItem && version) {
      if (relationType === RelationType.File) {
        setRelationData(getFileRelationData(catalogItem, version));
        updateView();
      } else {
        getTreeRelationData(catalogItem).then(result => {
          setRelationData(result);
          updateView();
        });
      }
    }

    // we listen to catalogItem.id, activeVersion and relationType changes only
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [catalogItem.id, activeVersion, relationType]);

  const updateView = () => {
    // grab node position after it was rendered
    setTimeout(() => {
      const nodePosition = getNode(catalogItem.id)?.position;
      if (nodePosition) {
        const newYPosition = relationType === RelationType.Tree ? nodePosition.y + 150 : nodePosition.y;
        setCenter(nodePosition.x, newYPosition, { zoom: 0.8 });
      }
    }, 100);
  };

  const handleNodeClicked = (event: ReactMouseEvent, node: Node<TreeNodeData>) => {
    const isDoubleClick = event.detail === 2;
    if (isDoubleClick) {
      if (node.type === RelationNodeTypes.CatalogItem) {
        appStore.ui.setCatalogItemPreviewId(node.id);
      } else if (node.type === RelationNodeTypes.Reference && relationType === RelationType.Tree) {
        const referenceId = node.data.reference?.refId;
        if (referenceId) {
          const refVersion = appStore.orgModel.catalogItems.getCatalogItemVersionByNodeId(referenceId);
          refVersion && appStore.ui.setCatalogItemPreviewId(refVersion.catalogItem.id);
          setRelationType(RelationType.File);
        }
      }
    }
    if (containerRef?.current) {
      containerRef.current.scrollTo(containerRef.current.scrollWidth, 0);
    }
  };

  return (
    <CollapsibleCard
      disabled={isVertical}
      minWidth={isVertical ? "auto" : 526}
      visible={isVertical ? true : appStore.env.pdmCardVisibility.relations}
      onToggle={() => appStore.env.pdmCardVisibility.toggleCard(PdmCard.Relations)}
      height={isVertical ? 275 : undefined}
      title="Realtions"
      rightActions={
        <ButtonGroup minimal>
          <Button
            className={styles.relationsCardButtonLeft}
            outlined
            active={relationType === RelationType.File}
            onClick={() => setRelationType(RelationType.File)}
            e2eIdentifiers="file"
            icon={<CustomIcon active={relationType === RelationType.File} icon={ThreadsIcon.ThreadsHorizontal} />}
          />
          <Button
            className={styles.relationsCardButtonRight}
            outlined
            active={relationType === RelationType.Tree}
            onClick={() => setRelationType(RelationType.Tree)}
            e2eIdentifiers="tree"
            icon={<CustomIcon active={relationType === RelationType.Tree} icon={ThreadsIcon.ThreadsVertical} />}
          />
        </ButtonGroup>
      }
    >
      <ReactFlow
        onNodeClick={handleNodeClicked}
        defaultViewport={defaultViewport}
        nodes={relationData.nodes}
        edges={relationData.edges}
        className="flow-view-properties"
        nodeTypes={nodeTypes}
        proOptions={{ hideAttribution: true }}
      />
    </CollapsibleCard>
  );
};

export default observer(RelationsCard);
