import { useCallback, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { NonIdealState } from "@blueprintjs/core";
import { observer } from "mobx-react";
import { useWindowSize } from "usehooks-ts";

import { bottomPaneMinHeight, leftPaneMinWidth, rightPaneMinWidth, topPaneMinHeight } from "@components/BomTables/Table/constants";
import SplitPaneGroup from "@router/components/Panes/SplitPaneGroup";
import appStore from "@store/AppStore";

import BomTablesBar from "./BomTablesBar/BomTablesBar";
import BomTable from "./Table/BomTable";
import BlockPreview from "./Table/Components/BlockPreview/BlockPreview";

import "./BomTablesFrame.scss";

const calcMaxSize = (container: HTMLDivElement, isVerticalLayout: boolean) => {
  return isVerticalLayout ? container.offsetWidth - rightPaneMinWidth : container.offsetHeight - bottomPaneMinHeight;
};

const calcDefaultSize = (windowSize: number, size?: number) => {
  if (!size || size > windowSize) {
    return windowSize * 0.66;
  }
  return size;
};

const BomTablesFrame = () => {
  const { tableId } = useParams();
  const { height: windowHeight, width: windowWidth } = useWindowSize();
  const ref = useRef<HTMLDivElement>(null);
  const [panel2Size, setPanel2Size] = useState<number>();
  const bomTable = appStore.workspaceModel?.bomTablesMap?.get(tableId || "");
  const { isBomTableVerticalSplit, bomTableSize, setBomTableSize } = appStore.env;

  const updatePanel2Size = useCallback(
    (newSize: number) => {
      if (ref.current) {
        const newPanel2Size = isBomTableVerticalSplit ? ref.current.offsetWidth - newSize : ref.current.offsetHeight - newSize;
        setPanel2Size(newPanel2Size);
      }
    },
    [isBomTableVerticalSplit]
  );

  useEffect(() => {
    if (bomTable) {
      appStore.env.setActiveBomTable(bomTable.id);
    }
  }, [bomTable]);

  useEffect(() => {
    if (panel2Size === undefined && bomTableSize > 0 && ref.current) {
      updatePanel2Size(bomTableSize);
    }
  }, [bomTableSize, panel2Size, updatePanel2Size]);

  useEffect(() => {
    const newSize = calcSize();
    if (newSize !== undefined) {
      setBomTableSize(newSize);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isBomTableVerticalSplit]);

  if (!tableId) {
    return null;
  } else if (!bomTable) {
    return (
      <NonIdealState title="Bill of Materials" description={`Can't open BOM with ID: ${tableId}`} className="min-h-[100%] h-[auto] p-6" />
    );
  }

  const handleDragFinished = (newSize: number) => {
    setBomTableSize(newSize);
    updatePanel2Size(newSize);
  };

  const calcSize = (): number | undefined => {
    if (ref.current && panel2Size !== undefined) {
      const containerWidth = ref.current.offsetWidth;
      const containerHeight = ref.current.offsetHeight;
      const size = isBomTableVerticalSplit ? containerWidth - panel2Size : containerHeight - panel2Size;
      const maxSize = calcMaxSize(ref.current, isBomTableVerticalSplit);
      return Math.min(size, maxSize);
    }
  };

  return (
    <div ref={ref} className="bom-tables-frame">
      <SplitPaneGroup
        onDragFinished={handleDragFinished}
        defaultSize={isBomTableVerticalSplit ? calcDefaultSize(windowWidth, bomTableSize) : calcDefaultSize(windowHeight, bomTableSize)}
        size={calcSize()}
        minSize={isBomTableVerticalSplit ? leftPaneMinWidth : topPaneMinHeight}
        maxSize={ref.current ? calcMaxSize(ref.current, isBomTableVerticalSplit) : undefined}
        split={isBomTableVerticalSplit ? "vertical" : "horizontal"}
        className="bom-pane"
        resizerClassName="bom-tables-frame--resizer"
      >
        <div className="bom-tables-frame--top-container">
          <BomTablesBar table={bomTable} />
          <BomTable key={bomTable.id} table={bomTable} />
        </div>
        <BlockPreview table={bomTable} verticalLayout={isBomTableVerticalSplit} onToggleLayout={appStore.env.toggleBomTableVerticalSplit} />
      </SplitPaneGroup>
    </div>
  );
};

export default observer(BomTablesFrame);
