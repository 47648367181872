import { PropertyDataType } from "@rollup-io/engineering";

export enum EPropertyDefinitionsColumn {
  ACTIONS = "Actions",
  LABEL = "Label",
  GROUP = "Group",
  DESCRIPTION = "Description",
  TYPE = "Type",
  UNIT = "Unit",
  AUTO_ADD = "Block Default",
  ROLLUP_CHILDREN = "Rollup Children",
  INSTANCES = "Instances",
}

export const PROPERTY_DATA_TYPE_ICONS_MAP = {
  [PropertyDataType.scalar]: "numerical",
  [PropertyDataType.array]: "array",
  [PropertyDataType.string]: "italic",
};
