import { ReactNode, useCallback, useState } from "react";
import { Collapse, Icon, IconName, MaybeElement } from "@blueprintjs/core";

import { Button } from "@components/Button";

type CollapsibleProps = {
  title: ReactNode;
  icon?: IconName | MaybeElement;
  children: ReactNode;
  open?: boolean | undefined;
};

function Collapsible({ title, children, open, icon = "label" }: CollapsibleProps) {
  const [isOpen, setIsOpen] = useState(open || false);
  const toggle = useCallback(() => setIsOpen(prev => !prev), [setIsOpen]);

  return (
    <div className="component--collapsible bg-white/[0.05] rounded p-2">
      <div className="flex items-center gap-2">
        <Icon icon={icon} intent="primary" size={14} />
        <div className="truncate flex-grow">{title}</div>
        <Button icon={isOpen ? "arrow-up" : "arrow-down"} onClick={toggle} minimal small e2eIdentifiers={["collapsible", "toggle"]} />
      </div>
      <Collapse isOpen={isOpen}>
        <div className="pt-1">{children}</div>
      </Collapse>
    </div>
  );
}

export type { CollapsibleProps };
export default Collapsible;
