import { ReactNode } from "react";
import { UniqueIdentifier } from "@dnd-kit/core";
import { useSortable } from "@dnd-kit/sortable";
import classNames from "classnames";

import styles from "./GenericDraggableItem.module.scss";

export interface SortableItem {
  id: string;
}

export type RenderFunction<T extends SortableItem> = (item: T, isDragging: boolean, dragListeners?: any) => ReactNode;

interface IGenericDraggableItemComponentProps<T extends SortableItem> {
  item: T;
  renderFunction: RenderFunction<T>;
  draggingId?: UniqueIdentifier;
  overId?: UniqueIdentifier;
}

const GenericDraggableItem = <T extends SortableItem>(props: IGenericDraggableItemComponentProps<T>) => {
  const { overId, draggingId, item, renderFunction } = props;
  const { attributes, listeners, setNodeRef } = useSortable({ id: props.item.id });

  return (
    <div
      className={classNames({ [styles.genericDraggableItemDraggingOver]: overId === item.id && draggingId !== item.id })}
      ref={setNodeRef}
      {...attributes}
      tabIndex={-1}
      key={item.id}
    >
      {renderFunction(item, false, listeners)}
    </div>
  );
};

export default GenericDraggableItem;
