import { IAttachment } from "@store/AttachmentStore";
import { IBlock } from "@store/BlockStore";
import { IUploadStore } from "@store/UploadStore";

import FileUploadCard from "../Uploads/FileUploadCard/FileUploadCard";

import AttachmentCard from "./AttachmentCard";

import "./AttachmentsGrid.scss";

export interface AttachmentsGridProps {
  block: IBlock;
  attachments: IAttachment[];
  uploadingAttachments?: Array<IUploadStore>;
}

export const AttachmentsGrid = ({ block, attachments, uploadingAttachments }: AttachmentsGridProps) => {
  return (
    <div className="attachments-grid--container">
      {uploadingAttachments?.map(store => <FileUploadCard key={store.id} uploadStore={store} />)}
      {attachments.map(att => {
        return <AttachmentCard key={att.id} block={block} attachment={att} />;
      })}
    </div>
  );
};
