import { TransactionPropertyInstanceEntity } from "@rollup-api/models/transactions";
import appStore from "@store/AppStore";
import { IFeed } from "@store/FeedStore";

import styles from "../FeedPanel.module.scss";

export abstract class PropertyFeedFormatter {
  public static getDeleteMessage(feedItem: IFeed) {
    const oldEntity = feedItem.oldEntity as TransactionPropertyInstanceEntity;
    const newEntity = feedItem.newEntity as TransactionPropertyInstanceEntity;
    const label = oldEntity.propertyDefinition?.label ?? PropertyFeedFormatter.getLabelById(newEntity.propertyDefinition);

    return (
      <span className={styles.feedPanelAction}>
        {feedItem.actionTitle} {feedItem.entityTitle}
        {label && <span className={styles.feedPanelEntity}> {label}</span>}
        {oldEntity.value && (
          <span>
            <span className="ml-1">with value</span>
            <span className={styles.feedPanelEntity}> {oldEntity.value}</span>
          </span>
        )}
      </span>
    );
  }

  private static getLabelById(id: string): string {
    const propertyDefinition = appStore.workspaceModel?.propertyDefinitionMap.get(id);
    return propertyDefinition?.label ?? "";
  }

  public static getUpdateMessage(feedItem: IFeed) {
    return (
      <span>
        <span className={styles.feedPanelAction}>
          updated
          <span className={styles.feedPanelEntity}> {feedItem.label} </span>
          {feedItem.entityTitle}
        </span>
      </span>
    );
  }
}
