import { Component } from "react";
import { observer } from "mobx-react";

import { Switch } from "@components/Switch";
import appStore from "@store/AppStore";

class DebuggerPane extends Component {
  render() {
    return (
      <div className="component--debugger-pane grid grid-cols-1 gap-2">
        <Switch
          className="m-0"
          checked={appStore.env.tabulatedViewDefaultShowMultiplicity}
          onChange={appStore.env.toggleTabulatedViewDefaultShowMultiplicity}
          label="Show multiplicity"
          alignIndicator="right"
          e2eIdentifiers="show-multiplicity"
        />
        <Switch
          className="m-0"
          checked={!appStore.env.tabulatedViewDefaultShowSingle}
          onChange={appStore.env.toggleTabulatedViewDefaultShowSingle}
          label="Only show repeating properties"
          alignIndicator="right"
          e2eIdentifiers="show-repeating-properties"
        />
        <Switch
          className="m-0"
          checked={appStore.env.tabulatedViewDefaultShowEmpty}
          onChange={appStore.env.toggleTabulatedViewDefaultShowEmpty}
          label="Show empty property definitions"
          alignIndicator="right"
          e2eIdentifiers="show-empty-property-definitions"
        />
        <Switch
          className="m-0"
          checked={appStore.env.tabulatedViewShowProjectStatuses}
          onChange={appStore.env.toggleShowProjectStatus}
          label="Show project status columns"
          alignIndicator="right"
          e2eIdentifiers="show-project-status-columns"
        />
      </div>
    );
  }
}

export default observer(DebuggerPane);
