export enum QueryKeys {
  DataSinkList = "dataSinks",
  DataSinkEntryList = "dataSinkEntries",
  DataSourceList = "dataSources",
  DataSourceValueTest = "dataSourceValueTest",
  GitHubRepoStatusCheck = "gitHubRepoStatusCheck",
  GoogleSheetList = "googleSheets",
  InvoiceList = "invoices",
  Transactions = "transactions",
}
