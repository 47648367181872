import { useUpdateEditorContent } from "@hooks/useUpdateEditorContent";
import Link from "@tiptap/extension-link";
import { Placeholder } from "@tiptap/extension-placeholder";
import Underline from "@tiptap/extension-underline";
import { useEditor } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";

import { EditorContent } from "@components/EditorContent";
import { getMentionExtensions } from "@components/Reports/Editor/Extentions/Mention/MentionSuggestion";
import appStore from "@store/AppStore";
import { IE2EIdentifiersParam, mergeE2EIdentifiers } from "@utilities/E2EUtils";

interface IRichTextCellProps extends IE2EIdentifiersParam {
  content: string;
  className?: string;
  placeholder?: string;
  disabled?: boolean;
}

const RichTextCell = (props: IRichTextCellProps) => {
  const { content, className, e2eIdentifiers, placeholder, disabled } = props;
  const workspace = appStore.workspaceModel ?? undefined;

  const editor = useEditor({
    extensions: [
      StarterKit,
      Underline,
      Link,
      ...getMentionExtensions({ workspace }),
      Placeholder.configure({
        showOnlyWhenEditable: false,
        placeholder,
      }),
    ],
    content,
    editable: false,
  });

  useUpdateEditorContent({ content, editor });

  if (!editor) {
    return null;
  }

  return (
    <EditorContent
      className={className}
      e2eIdentifiers={mergeE2EIdentifiers("rich-text-cell", e2eIdentifiers)}
      editor={editor}
      disabled={disabled}
    />
  );
};

export default RichTextCell;
