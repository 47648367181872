import { useState } from "react";
import { Checkbox, Menu } from "@blueprintjs/core";
import { observer } from "mobx-react";

import GroupByMenu from "@components/BomTables/BomTablesBar/GroupByMenu";
import { Button } from "@components/Button";
import { Popover } from "@components/Popover";
import { PopupMenu } from "@components/Shared/PopupMenu";
import { Tooltip } from "@components/Tooltip";
import appStore from "@store/AppStore";
import { IBomTable } from "@store/BomTable/BomTableStore";
import { createCopyToClipboardHandler } from "@utilities/TextUtil";

import "./BomTablesBar.scss";

/** Main function. */
const BomTablesBar = ({ table }: { table: IBomTable }) => {
  const handleCopyToClipboard = createCopyToClipboardHandler(window.location.href, "BOM table link");
  const [isGroupByMenuOpen, setIsGroupByMenuOpen] = useState(false);

  return (
    <div className="bom-tables-bar">
      <div className="bom-tables-bar--nav">
        <Button icon="layout-hierarchy" minimal onClick={() => appStore.ui.showEditBomTableDialog(table)} e2eIdentifiers="edit-selection">
          Edit selection
        </Button>
        <Popover
          position="bottom-right"
          content={
            <Menu>
              <Checkbox label="Show thumbnails" onChange={table.toggleThumbnail} checked={!!table.thumbnailColId} />
            </Menu>
          }
        >
          <Button minimal icon="cog" e2eIdentifiers="ui-options">
            UI Options
          </Button>
        </Popover>
        <PopupMenu
          buttonText="Group"
          popoverProps={{ isOpen: isGroupByMenuOpen, onInteraction: setIsGroupByMenuOpen }}
          content={<GroupByMenu table={table} onClose={() => setIsGroupByMenuOpen(false)} />}
          buttonProps={{ icon: "th-list" }}
          e2eIdentifiers="open-group-by-menu"
        />
      </div>
      <div className="bom-tables-bar--nav">
        <Button onClick={table.exportCsv} icon="export" minimal e2eIdentifiers="export">
          Export
        </Button>
        <Tooltip content="Copy link to clipboard">
          <Button icon="link" e2eIdentifiers="copy-to-clipboard" onClick={handleCopyToClipboard} minimal />
        </Tooltip>
      </div>
    </div>
  );
};

/** Exports. */
export default observer(BomTablesBar);
