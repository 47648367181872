import { PropsWithChildren, useContext, useEffect } from "react";
import { StiggQOFactory } from "@queries/StiggQOFactory";
import { StiggContext } from "@stigg/react-sdk";
import { useQuery } from "@tanstack/react-query";
import { observer } from "mobx-react";

import ExpiredFreeTrialDialog from "@components/FreeTrial/ExpiredFreeTrialDialog";
import * as Element from "@router/elements";
import { useAppNavigate } from "@router/hooks";
import { isSettingsPage } from "@utilities/LinkUtils";

interface IFreeTrialWrapperProps extends PropsWithChildren {}

const FreeTrialWrapper = (props: IFreeTrialWrapperProps) => {
  const { children } = props;
  const stiggCtx = useContext(StiggContext);
  const { data, isLoading } = useQuery(StiggQOFactory.createGetCustomerQO(stiggCtx?.stigg));
  const { customer, error } = data ?? {};
  const { navigateToPlanAndBilling } = useAppNavigate();

  const hasExpiredTrial = !isLoading && !customer?.subscriptions.length;

  useEffect(() => {
    if (hasExpiredTrial && !isSettingsPage()) {
      navigateToPlanAndBilling();
    }
  }, [hasExpiredTrial, navigateToPlanAndBilling]);

  if (!stiggCtx || isLoading) {
    return <Element.RouterLoading />;
  } else if (hasExpiredTrial && !error && isSettingsPage()) {
    return (
      <>
        <ExpiredFreeTrialDialog />
        {children}
      </>
    );
  }

  return children;
};

export default observer(FreeTrialWrapper);
