import { ReactNode } from "react";
import { Divider, Menu, MenuProps } from "@blueprintjs/core";
import classNames from "classnames";
import { observer } from "mobx-react";

import { Button } from "@components/Button";
import CollapsibleMenuItem from "@router/navigation/CollapsibleMenuItem";
import NavLinkAsMenuItem, { INavLinkAsMenuItem } from "@router/navigation/NavLinkAsMenuItem";
import { prefixIdentifiers } from "@utilities/E2EUtils";

import Pane, { PaneProps } from "./Pane";

import "./MenuPane.scss";

type MenuPaneProps = PaneProps & {
  title?: string | undefined;
  open: boolean;
  fullHeight?: boolean;
  toggle?: () => void;
  menuItems?: INavLinkAsMenuItem[];
  menuProps?: MenuProps;
  minimal?: boolean;
  hideScroll?: boolean;
  collapsedContent?: ReactNode;
  onMouseEnter?(): void;
  onMouseLeave?(): void;
};

function MenuPane({
  title,
  open,
  toggle,
  menuItems,
  topElement,
  className,
  children,
  bottomElement,
  menuProps,
  minimal,
  hideScroll,
  collapsedContent,
  onMouseEnter,
  onMouseLeave,
  ...rest
}: MenuPaneProps) {
  const elementWithWrapper = (element: ReactNode) => (
    <div
      className={classNames("menu-pane--wrapper", {
        ["menu-pane--wrapper-hidden"]: !open,
        ["overflow-hidden"]: hideScroll,
      })}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {element}
    </div>
  );

  const extendedTopElement = (
    <>
      {!minimal && (
        <>
          <Button
            className={classNames("menu-pane--toggle-button", { "menu-pane--toggle-button-expanded": open })}
            fill
            minimal
            highlight
            onClick={toggle}
            alignText="left"
            text={open ? title : undefined}
            icon={open ? "double-chevron-left" : "double-chevron-right"}
            e2eIdentifiers={["menu-pane", "toggle"]}
          />
          <Divider className="m-0" />
        </>
      )}
      {menuItems && (
        <Menu
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          {...menuProps}
          className={classNames("menu-pane-menu !bg-inherit min-w-[0] p-[6px] space-y-[4px]", !open && "is-closed", menuProps?.className)}
        >
          {menuItems.map((menuItem: INavLinkAsMenuItem) => {
            const { nestedLinks, text, dividerBelow, e2eIdentifiers, ...rest } = menuItem;
            // We cant gracefully merge <Link> and <MenuItem>
            // to keep native anchor capabilities like copy link address.
            // Related discussion on BPJS: https://github.com/palantir/blueprint/issues/185
            // One possible solution is to build a custom <MenuItem> component as per this guide:
            // https://reactrouter.com/en/main/hooks/use-link-click-handler

            return (
              <div key={`${text}`}>
                {nestedLinks ? (
                  <CollapsibleMenuItem open={open} navLink={menuItem} />
                ) : (
                  <NavLinkAsMenuItem
                    text={open ? text : undefined}
                    {...rest}
                    e2eIdentifiers={prefixIdentifiers("menu-pane", e2eIdentifiers)}
                    selected={menuItem.selected}
                  />
                )}
                {dividerBelow && <Divider className="menu-pane--divider" />}
              </div>
            );
          })}
        </Menu>
      )}
      {menuItems && <Divider className="m-0" />}
      {topElement && elementWithWrapper(topElement)}
    </>
  );

  return (
    <Pane
      topElement={extendedTopElement}
      bottomElement={bottomElement && elementWithWrapper(bottomElement)}
      className={classNames("menu-pane", open ? "w-full max-w-full" : "menu-pane--collapsed", className)}
      {...rest}
    >
      {!open && (
        <div className="menu-pane--content" onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
          {collapsedContent}
        </div>
      )}

      {children && elementWithWrapper(children)}
    </Pane>
  );
}

/** Exports. */
export type { MenuPaneProps };
export default observer(MenuPane);
