import { useState } from "react";
import { InputGroup } from "@blueprintjs/core";
import { observer } from "mobx-react";

import { ENTER_KEY, ESCAPE_KEY } from "@constants/keys";
import appStore from "@store/AppStore";

import "./AddNewChildRow.scss";

export const AddNewChildRow = () => {
  const [value, setValue] = useState("");

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === ENTER_KEY) {
      setValue("");
      appStore.workspaceModel?.addNewBlock(appStore.workspaceModel.rootBlock, value);
    } else if (event.key === ESCAPE_KEY) {
      setValue("");
    }
  };

  return (
    <div className="add-new-child-row">
      <InputGroup onKeyDown={handleKeyDown} value={value} onChange={e => setValue(e.target.value)} placeholder="New block" leftIcon="add" />
    </div>
  );
};

export default observer(AddNewChildRow);
