import { EHeaderType } from "@components/Modeling/ModelingFrame/Table/TableComponent/Cells/HeaderCell";

import ActionsCell from "./Cells/ActionsCell";
import CheckboxCell from "./Cells/CheckboxCell";

export const topPaneMinHeight = 215;
export const leftPaneMinWidth = 400;
export const bottomPaneMinHeight = 55;
export const rightPaneMinWidth = 500;
export const bomTablePrefix = "bomRow";
export const bomColumnIndexMargin = 2; // first two columns `checkbox` & `action`

export const actionsColumnDef = {
  colId: "actions",
  headerName: "",
  lockPosition: "left" as const,
  pinned: "left" as const,
  sortIndex: 0,
  width: 25,
  cellClass: "ag-actions-cell",
  suppressSizeToFit: true,
  cellRenderer: ActionsCell,
  headerComponentParams: {
    hideNav: true,
  },
};

export const createNewColumnDef = {
  width: 50,
  lockPosition: "right" as const,
  cellRenderer: () => null,
  suppressSizeToFit: true,
  headerComponentParams: {
    headerType: EHeaderType.ADD_NEW,
  },
};

export const checkboxColumnDef = {
  colId: "checkbox",
  headerName: "",
  checkboxSelection: true,
  lockPosition: "left" as const,
  pinned: "left" as const,
  width: 25,
  cellRenderer: CheckboxCell,
  suppressSizeToFit: true,
  sortIndex: 0,
  cellClass: "ag-actions-cell",
  headerComponentParams: {
    headerType: EHeaderType.CHECKBOX,
  },
};
