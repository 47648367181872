import { Extension } from "@tiptap/core";

import tableButtonPlugin from "./plugin";
import { TableButtonProps } from "./types";

const TableButtonExtension = Extension.create<TableButtonProps>({
  name: "tableButton",

  addProseMirrorPlugins() {
    return [tableButtonPlugin(this.options.onCellHover)];
  },
});

export default TableButtonExtension;
