import { useEffect, useState } from "react";
import { Classes, DialogBody, DialogFooter, InputGroup, Intent, NumericInput } from "@blueprintjs/core";
import classNames from "classnames";
import { observer } from "mobx-react";

import { Button } from "@components/Button";
import { DialogLegacy } from "@components/Dialog";
import { IPartNumberSchema } from "@store/PartNumberSchemaStore";

import "./PartNumberSchemaModal.scss";

export const INCREMENT_PLACEHOLDER = "{{increment}}";

const PLACEHOLDER = "PREFIX--{{increment}}--SUFFIX";

type Props = {
  isOpen: boolean;
  partNumberSchema?: IPartNumberSchema;
  onAddNew: (label: string, schema: string, leadingZeroes: number) => void;
  onClose: () => void;
};

const PartNumberSchemaModal = (props: Props) => {
  const { isOpen, onClose, partNumberSchema, onAddNew } = props;
  const [leadingZeroes, setLeadingZeroes] = useState(partNumberSchema?.leadingZeroes || 1);
  const [label, setLabel] = useState(partNumberSchema?.label || "");
  const [schema, setSchema] = useState(partNumberSchema?.schema || "");
  const isSchemaValid = schema && schema.includes(INCREMENT_PLACEHOLDER);
  const title = partNumberSchema ? "Update ID format" : "Create new ID format";

  useEffect(() => {
    setLeadingZeroes(partNumberSchema?.leadingZeroes || 1);
    setLabel(partNumberSchema?.label || "");
    setSchema(partNumberSchema?.schema || "");
  }, [partNumberSchema]);

  const handleClose = () => {
    setLeadingZeroes(1);
    setLabel("");
    setSchema("");
    onClose();
  };

  const handleSave = () => {
    if (partNumberSchema) {
      partNumberSchema.update({ label, schema, leadingZeroes });
    } else {
      onAddNew?.(label, schema, leadingZeroes);
    }
    handleClose();
  };

  return (
    <DialogLegacy className="part-number-schema-modal" isOpen={isOpen} isCloseButtonShown title={title} onClose={handleClose}>
      <DialogBody>
        <div className={classNames("mb-3", Classes.RUNNING_TEXT)}>
          <div className="part-number-schema-modal--label">ID format name*</div>
          <InputGroup large onChange={e => setLabel(e.target.value)} value={label} />
        </div>
        <div className={classNames("flex", Classes.RUNNING_TEXT)}>
          <div className="part-number-schema-modal--schema">
            <div className="part-number-schema-modal--label">
              Use <code>{INCREMENT_PLACEHOLDER}</code> as part of your template for the number placeholder*
            </div>
            <InputGroup
              className="part-number-schema-modal--input"
              fill
              large
              placeholder={PLACEHOLDER}
              onChange={e => setSchema(e.target.value)}
              value={schema}
            />
          </div>
          <div className="part-number-schema-modal--number">
            <div className="part-number-schema-modal--label">Leading zeroes</div>
            <NumericInput fill allowNumericCharactersOnly value={leadingZeroes} large onValueChange={setLeadingZeroes} min={1} max={5} />
          </div>
        </div>
        <div className={classNames("mt-3", Classes.RUNNING_TEXT)}>
          <div className="part-number-schema-modal--label">Part number result example:</div>
          <InputGroup large readOnly value={(schema || PLACEHOLDER).replace(INCREMENT_PLACEHOLDER, "1".padStart(leadingZeroes, "0"))} />
        </div>
      </DialogBody>
      <DialogFooter
        minimal
        actions={
          <>
            <Button large onClick={onClose} e2eIdentifiers="close">
              Cancel
            </Button>
            <Button large intent={Intent.PRIMARY} disabled={!label || !isSchemaValid} onClick={handleSave} e2eIdentifiers="save">
              Save
            </Button>
          </>
        }
      />
    </DialogLegacy>
  );
};

export default observer(PartNumberSchemaModal);
