import CommentsPanel from "@components/Modeling/ModelingFrame/ModelBlock/Comments/CommentsPanel";
import { IBlock } from "@store/BlockStore";

import "./CommentsTabPanel.scss";

type CommentsTabPanelProps = {
  block: IBlock;
};

function CommentsTabPanel({ block }: CommentsTabPanelProps) {
  return (
    <div className="comments-tab-panel">
      <CommentsPanel block={block} />
    </div>
  );
}

export type { CommentsTabPanelProps };
export default CommentsTabPanel;
