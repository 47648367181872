import { ReactElement } from "react";
import { observer } from "mobx-react";

import appStore from "@store/AppStore";

import { LegacyCustomIcon, LegacyCustomIconProps } from "./LegacyCustomIcon";

export const ThemedCustomIcon = observer(
  (
    props: Omit<LegacyCustomIconProps, "icon"> & {
      lightIcon: ReactElement;
      darkIcon: ReactElement;
    }
  ) => {
    return <LegacyCustomIcon {...props} icon={appStore.env.themeIsDark ? props.darkIcon : props.lightIcon} />;
  }
);
