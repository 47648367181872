import { Select } from "@blueprintjs/select";
import { SelectItem } from "@ui/Select/Item/SelectItem";
import { observer } from "mobx-react";

import { Button } from "@components/Button";
import { ExecutionEnvironmentType } from "@rollup-api/models/execution-environments";

import ExecutionTypeIcon from "./ExecutionTypeIcon";

import styles from "./EnvironmentTypeSelect.module.scss";

interface EnvironmentTypeSelectProps {
  value: ExecutionEnvironmentType;
  onChange: (value: ExecutionEnvironmentType) => void;
}

const EnvironmentTypeSelect = ({ value, onChange }: EnvironmentTypeSelectProps) => {
  return (
    <Select<ExecutionEnvironmentType>
      className={styles.environmentTypeSelect}
      items={Object.values(ExecutionEnvironmentType)}
      filterable={false}
      onItemSelect={onChange}
      popoverProps={{ minimal: true }}
      itemRenderer={(item, { handleClick }) => (
        <SelectItem
          icon={<ExecutionTypeIcon type={item} size={16} />}
          slug={item}
          key={item}
          label={item}
          onClick={handleClick}
          active={value === item}
        />
      )}
    >
      <Button
        icon={<ExecutionTypeIcon type={value} />}
        alignText="left"
        text={value ?? "Select language"}
        rightIcon="chevron-down"
        e2eIdentifiers="select-type-button"
      />
    </Select>
  );
};

export default observer(EnvironmentTypeSelect);
