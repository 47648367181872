import { Outlet, useLocation, useMatch, useNavigate } from "react-router-dom";
import { useBool } from "@hooks/useBool/useBool";
import { observer } from "mobx-react";

import BomTablesSidebar from "@components/BomTables/BomTablesSidebar/BomTablesSidebar";
import { FeatureLayout } from "@components/FeatureLayout";
import { BoardViewer } from "@components/Modeling/ModelingFrame/BoardViewer/BoardViewer";
import HoopsViewer from "@components/Modeling/ModelingFrame/HoopsViewer/HoopsViewer";
import { getHardwareCatalogLayoutMenuItems, TNavigationLinkConfig } from "@constants/navigation";
import MenuPane, { MenuPaneProps } from "@router/components/Panes/MenuPane";
import { useMenuPane } from "@router/components/Panes/useMenuPane";
import BomTableCreateNewPopover from "@router/elements/BomTableCreateNewPopover";
import appStore from "@store/AppStore";
import { EPane } from "@store/PaneWidthStore";

import "./HardwareCatalogLayout.scss";

function HardwareCatalogLayout() {
  const { isSubNavPaneOpen, toggleSubNavPane } = appStore.env;
  const { open, toggle, minSize, maxSize } = useMenuPane({ isOpen: isSubNavPaneOpen, toggleIsOpen: toggleSubNavPane });
  const navigate = useNavigate();
  const location = useLocation();
  const [menuHovered, { set: hoverMenu, unset: unhoverMenu }] = useBool();
  const linksMapper = (link: TNavigationLinkConfig): TNavigationLinkConfig & { selected: boolean } => ({
    ...link,
    icon: link.icon,
    ...(link.nestedLinks && { nestedLinks: link.nestedLinks.map(linksMapper) }),
    selected: navMatch?.params?.view === link.to || [nestedNavMatch?.params?.view, nestedNavMatch?.params?.subView].join("/") === link.to,
  });
  const navMatch = useMatch(`/workspaces/:workspaceId/pdm/:view/*`);
  const nestedNavMatch = useMatch(`/workspaces/:workspaceId/pdm/:view/:subView/*`);

  const hoverMenuMenuItems = getHardwareCatalogLayoutMenuItems("hover-menu").map(linksMapper) as MenuPaneProps["menuItems"];
  const menuItems = getHardwareCatalogLayoutMenuItems().map(linksMapper) as MenuPaneProps["menuItems"];

  const [_isItemCatalogView, isBomView] = hoverMenuMenuItems?.map(i => i.selected) ?? [];

  const sidePanelContent = isBomView ? (
    <div className="hardware-catalog-layout--bom-sidebar">
      <BomTableCreateNewPopover />
      <BomTablesSidebar />
    </div>
  ) : null;

  const handleGoBack = () => {
    // navigate to the current page without the hash containing the attachment id
    navigate(location.pathname);
    appStore.env.clearActiveAttachment();
  };

  if (appStore.env.activeAttachment) {
    if (appStore.env.isHoopsPreviewMode) {
      return <HoopsViewer attachment={appStore.env.activeAttachment} onGoBack={handleGoBack} />;
    } else if (appStore.env.isBoardPreviewMode) {
      return <BoardViewer attachment={appStore.env.activeAttachment} onGoBack={handleGoBack} />;
    }
  }

  return (
    <FeatureLayout
      paneWidth={appStore.env.paneWidth.subNav}
      menuHovered={menuHovered}
      dynamicPanelContent={sidePanelContent}
      hoverMenuMenuItems={hoverMenuMenuItems}
      minSize={minSize}
      maxSize={maxSize}
      onPaneWidthChange={(newSize: number) => appStore.env.paneWidth.updatePaneWidth(EPane.SUB_NAV, newSize)}
      onHoverMenu={hoverMenu}
      onUnhoverMenu={unhoverMenu}
      isSidePanelOpen={open}
    >
      <MenuPane
        hideScroll
        open={open}
        toggle={toggle}
        title="Product Data Management"
        menuItems={menuItems}
        onMouseEnter={hoverMenu}
        onMouseLeave={unhoverMenu}
      >
        {sidePanelContent}
      </MenuPane>

      <Outlet />
    </FeatureLayout>
  );
}

export default observer(HardwareCatalogLayout);
