import { useEffect, useState } from "react";
import { EditableText } from "@blueprintjs/core";
import { useOnMount } from "@hooks/useOnMount";
import classNames from "classnames";
import { observer } from "mobx-react";

import styles from "./RequirementDocumentBlockEditableText.module.scss";

interface IRequirementDocumentBlockEditableTextProps {
  text: string;
  placeholder?: string;
  className?: string;
  multiline?: boolean;
  autoFocus?: boolean;
  onConfirm(id: string): void;
  onCancel?(): void;
}

const RequirementDocumentBlockEditableText = (props: IRequirementDocumentBlockEditableTextProps) => {
  const { text, placeholder, className } = props;
  const { autoFocus, multiline, onConfirm, onCancel } = props;
  const [isEditing, setIsEditing] = useState(false);
  const [value, setValue] = useState(text);

  const handleOnConfirm = () => {
    onConfirm(value);
    setIsEditing(false);
  };

  const handleCancel = () => {
    setValue(text);
    setIsEditing(false);
    onCancel?.();
  };

  useEffect(() => {
    setValue(text);
  }, [text]);

  useOnMount(() => {
    if (autoFocus) {
      setIsEditing(true);
    }
  });

  return (
    <EditableText
      className={classNames(styles.requirementDocumentBlockEditableText, className)}
      isEditing={isEditing}
      onEdit={() => setIsEditing(true)}
      value={value}
      placeholder={placeholder}
      onChange={setValue}
      onConfirm={handleOnConfirm}
      onCancel={handleCancel}
      multiline={multiline}
    />
  );
};

export default observer(RequirementDocumentBlockEditableText);
