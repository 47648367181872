import { Position } from "@blueprintjs/core";

import { Button } from "@components/Button";
import { Popover } from "@components/Popover";
import appStore from "@store/AppStore";
import { FeatureFlag } from "@store/FeatureFlagsStore";

import IndicatorIcon from "../IndicatorIcon/IndicatorIcon";

import RollupEventsPopover from "./RollupEventsPopover";

const RollupEventsPopoverButton = () => {
  return (
    appStore.env.featureFlags.enabled(FeatureFlag.ROLLUP_EVENT_BELL) && (
      <Popover
        content={<RollupEventsPopover />}
        captureDismiss
        position={Position.BOTTOM} // Explicit positioning resolves browser compatibility. Default: "auto"
        modifiers={{ arrow: { enabled: true } }}
      >
        <Button icon={<IndicatorIcon icon="notifications" showIndicator />} minimal e2eIdentifiers="show-events-popover" />
      </Popover>
    )
  );
};

export default RollupEventsPopoverButton;
