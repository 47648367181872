import { useNavigate } from "react-router-dom";
import Text, { TextVariant } from "@ui/Text";
import { observer } from "mobx-react";

import { Button } from "@components/Button";
import { IntegrationProvider } from "@rollup-api/models";
import GoogleSettingsInternal from "@router/elements/settings/internalIntegrations/GoogleSettingsInternal";
import { IIntegration } from "@store/IntegrationStore";

import GithubSettingsInternal from "./GithubSettingsInternal";
import OnShapeSettingsInternal from "./OnShapeSettingsInternal";
import SlackSettingsInternal from "./SlackSettingsInternal";

import "./NativeIntegrationConfig.scss";

interface INativeIntegrationProps {
  integration: IIntegration;
}

const NativeIntegrationConfig = (props: INativeIntegrationProps) => {
  const { integration } = props;
  const navigate = useNavigate();

  const renderIntegrationConfig = () => {
    switch (integration.slug) {
      case IntegrationProvider.GitHub:
        return <GithubSettingsInternal />;
      case IntegrationProvider.OnShape:
        return <OnShapeSettingsInternal />;
      case IntegrationProvider.Google:
        return <GoogleSettingsInternal />;
      case IntegrationProvider.Slack:
        return <SlackSettingsInternal />;
    }
  };

  return (
    <div className="native-integration-config">
      <Button
        minimal
        className="native-integration-config--back"
        e2eIdentifiers="integrations_back_btn"
        onClick={() => navigate("/settings/marketplace")}
        icon="arrow-left"
      >
        Back
      </Button>
      <div className="native-integration-config-header">
        {integration.logoUrl && <img src={integration.logoUrl} alt={integration.name} width={32} height={32} />}
        <Text variant={TextVariant.H1}>{integration.name}</Text>
      </div>
      {renderIntegrationConfig()}
    </div>
  );
};

export default observer(NativeIntegrationConfig);
