import { useFavorite } from "@hooks/useFavorite";
import { observer } from "mobx-react";

import { FavoriteIcon } from "@components/FavoriteIcon";
import { MenuItem } from "@components/MenuItem";
import { EntityType } from "@store/types";

interface IMenuItemFavoriteProps {
  entityId: string;
  entityType: EntityType;
}

const MenuItemFavorite = (props: IMenuItemFavoriteProps) => {
  const { entityId, entityType } = props;
  const { isFavorite, e2eIdentifiers, tooltip, handleToggleFavorite } = useFavorite({ entityId, entityType });

  return (
    <MenuItem
      icon={<FavoriteIcon isFavorite={isFavorite} />}
      text="Favorite"
      onClick={handleToggleFavorite}
      e2eIdentifiers={e2eIdentifiers}
      tooltip={tooltip}
    />
  );
};

export default observer(MenuItemFavorite);
