import { useCallback, useEffect, useState } from "react";
import { GridApi } from "ag-grid-community";

/** For this to work well, it's recommended to provide the rowHeight to AgGrid **/
export const useScrollGridToBottom = (gridApi?: GridApi) => {
  const [shouldScrollToBottom, setShouldScrollToBottom] = useState<boolean>();

  useEffect(() => {
    if (shouldScrollToBottom && gridApi) {
      const lastIndex = gridApi.getDisplayedRowCount() - 1;
      gridApi.ensureIndexVisible(lastIndex, "bottom");
      setShouldScrollToBottom(false);
    }
  }, [gridApi, shouldScrollToBottom]);

  const scrollToBottom = useCallback(() => setShouldScrollToBottom(true), []);

  return scrollToBottom;
};
