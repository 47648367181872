import { useCallback, useMemo, useState } from "react";
import { BreadcrumbProps, Breadcrumbs } from "@blueprintjs/core";
import { observer } from "mobx-react";

import { Bar } from "@components/Bar/Bar";
import BlockMenu from "@components/BlocksTree/BlockMenu";
import { DialogAddNewChildBlock } from "@components/Dialog";
import MoreOptionsMenu from "@components/MoreOptionsMenu/MoreOptionsMenu";
import { useAppNavigate } from "@router/hooks";
import appStore from "@store/AppStore";
import { IBlock } from "@store/BlockStore";

import "./ModelFrameToolbar.scss";

type ModelFrameToolbarProps = {
  block: IBlock | undefined | null;
};

function ModelFrameToolbar({ block }: ModelFrameToolbarProps) {
  const { navigateToBlock } = useAppNavigate();
  const [addNewModalBlock, setAddNewModalBlock] = useState<IBlock>();

  const renderActiveBlockLabel = useCallback(
    (block: IBlock) => {
      return (
        <div className="model-frame-toolbar--active-block">
          <span>{block.label}</span>
          <MoreOptionsMenu
            content={<BlockMenu block={block} onAddNewOpen={setAddNewModalBlock} onNavigateToBlock={navigateToBlock} />}
            e2eIdentifiers="show-header-menu"
          />
        </div>
      );
    },
    [navigateToBlock]
  );

  const breadcrumbItems: BreadcrumbProps[] = useMemo(() => {
    if (!block) {
      return [];
    }

    const items = new Array<BreadcrumbProps>();

    let iterationBlock = block;
    const activeAttachment = appStore.env.activeAttachment;
    const hasActiveAttachment = activeAttachment?.block === block;

    while (iterationBlock) {
      const isCurrentBlock = iterationBlock.id === block.id;
      const { id, label } = iterationBlock;
      items.unshift({
        text: isCurrentBlock && !hasActiveAttachment ? renderActiveBlockLabel(block) : label,
        onClick: isCurrentBlock ? undefined : () => navigateToBlock(id),
        current: iterationBlock === block && !hasActiveAttachment,
      });
      iterationBlock = iterationBlock.parentBlock;
    }

    if (hasActiveAttachment) {
      items.push({
        text: activeAttachment.label,
        current: true,
      });
    }

    return items;
  }, [block, navigateToBlock, renderActiveBlockLabel]);

  if (!block) {
    return null;
  }

  return (
    <Bar className="model-frame-toolbar">
      <Breadcrumbs items={breadcrumbItems} />
      {addNewModalBlock && <DialogAddNewChildBlock block={addNewModalBlock} isOpen onClose={() => setAddNewModalBlock(undefined)} />}
    </Bar>
  );
}

export default observer(ModelFrameToolbar);
