import { useMemo } from "react";
import { ColDef, ValueGetterParams, ValueSetterParams } from "ag-grid-community";

import { ECustomUnitsColumn } from "@components/Modeling/CustomUnits/constants";
import TextCellEditor from "@components/Table/CellEditors/TextCellEditor";
import {
  DropdownCellRenderer,
  IDropdownCellRendererOwnProps,
} from "@components/Table/CellRenderers/DropdownCellRenderer/DropdownCellRenderer";
import { TagMultiSelectCellRenderer } from "@components/Table/CellRenderers/TagMultiSelectCellRenderer";
import { ITagMultiSelectCellRendererOwnProps } from "@components/Table/CellRenderers/TagMultiSelectCellRenderer/TagMultiSelectCellRenderer";
import { TextCellRenderer } from "@components/Table/CellRenderers/TextCellRenderer";
import { MathJsPrefix } from "@rollup-api/models/customUnits";
import { IComplexSelectOption } from "@rollup-types/selection";
import appStore from "@store/AppStore";
import { ICustomUnit } from "@store/CustomUnitStore";
import { enhanceStringOption, enhanceStringOptionWithCase } from "@utilities/SelectionUtils";

import CustomUnitActionsCell from "./cells/CustomUnitActionsCell";

type IValueGetterParams<T = string> = ValueGetterParams<ICustomUnit, T>;
type IValueSetterParams<T = string> = ValueSetterParams<ICustomUnit, T>;

export const useCustomUnitsTableColDef = (): ColDef<ICustomUnit>[] => {
  const { values: customUnits, isValidLabel } = appStore.orgModel.customUnits;
  const columnsOrder = appStore.env.columnsOrder.customUnitsTable;

  return useMemo(
    () =>
      (
        [
          {
            colId: ECustomUnitsColumn.Actions,
            headerName: "",
            lockPosition: "left",
            suppressMovable: true,
            width: 25,
            cellRenderer: CustomUnitActionsCell,
          },
          {
            editable: true,
            colId: ECustomUnitsColumn.Label,
            minWidth: 100,
            flex: 1,
            headerName: ECustomUnitsColumn.Label,
            cellRenderer: TextCellRenderer,
            cellEditor: TextCellEditor,
            cellEditorParams: { isValid: isValidLabel },
            valueGetter: (cellProps: IValueGetterParams) => cellProps.data?.label,
            valueSetter: (cellProps: IValueSetterParams) => {
              if (cellProps.newValue) {
                cellProps.data.setLabel(cellProps.newValue);
                return true;
              }
              return false;
            },
          },
          {
            colId: ECustomUnitsColumn.Prefix,
            minWidth: 100,
            flex: 1,
            headerName: ECustomUnitsColumn.Prefix,
            cellRenderer: DropdownCellRenderer,
            cellRendererParams: {
              options: Object.values(MathJsPrefix).map(enhanceStringOptionWithCase),
              e2eIdentifiers: "prefix",
            } satisfies IDropdownCellRendererOwnProps,
            valueGetter: (cellProps: IValueGetterParams) => cellProps.data && enhanceStringOptionWithCase(cellProps.data?.prefix),
            valueSetter: (cellProps: IValueSetterParams<IComplexSelectOption<MathJsPrefix>>): boolean => {
              if (cellProps.newValue) {
                cellProps.data.setPrefix(cellProps.newValue.value);
                return true;
              }
              return false;
            },
          },
          {
            editable: true,
            colId: ECustomUnitsColumn.Offset,
            minWidth: 100,
            flex: 1,
            headerName: ECustomUnitsColumn.Offset,
            cellRenderer: TextCellRenderer,
            cellEditor: TextCellEditor,
            valueGetter: (cellProps: IValueGetterParams) => cellProps.data?.offset,
            valueSetter: (cellProps: IValueSetterParams): boolean => {
              if (cellProps.newValue) {
                cellProps.data.setOffset(cellProps.newValue);
                return true;
              }
              return false;
            },
          },
          {
            colId: ECustomUnitsColumn.Aliases,
            minWidth: 200,
            flex: 1,
            headerName: ECustomUnitsColumn.Aliases,
            cellRenderer: TagMultiSelectCellRenderer,
            cellRendererParams: {
              getOptions: customUnit => customUnit.aliases.map(enhanceStringOption),
              onSelect: (customUnit, option) => customUnit.addAlias(option.value),
              onDelete: (customUnit, option) => customUnit.deleteAlias(option.value),
            } satisfies ITagMultiSelectCellRendererOwnProps<ICustomUnit>,
            valueGetter: (cellProps: ValueGetterParams<ICustomUnit, IComplexSelectOption[]>) => {
              return cellProps.data?.aliases.map(enhanceStringOption) ?? [];
            },
            valueSetter: (cellProps: ValueSetterParams<ICustomUnit, IComplexSelectOption>): boolean => {
              if (cellProps.newValue) {
                cellProps.data.addAlias(cellProps.newValue.value);
                return true;
              }
              return false;
            },
          },
          {
            editable: true,
            colId: ECustomUnitsColumn.Definition,
            headerName: ECustomUnitsColumn.Definition,
            minWidth: 100,
            flex: 1,
            cellRenderer: TextCellRenderer,
            cellEditor: TextCellEditor,
            valueGetter: (cellProps: ValueGetterParams<ICustomUnit>) => cellProps.data?.definition,
            valueSetter: (cellProps: ValueSetterParams<ICustomUnit>): boolean => {
              cellProps.data.setDefinition(cellProps.newValue);
              return true;
            },
          },
        ] as ColDef<ICustomUnit>[]
      ).sort((a: ColDef<ICustomUnit>, b: ColDef<ICustomUnit>) => {
        const indexA = columnsOrder.indexOf(a.colId as ECustomUnitsColumn);
        const indexB = columnsOrder.indexOf(b.colId as ECustomUnitsColumn);
        // Do not change the original column position if it's not in the columnsOrder array yet (useful for new columns)
        if (indexA === -1 || indexB === -1) {
          return 0;
        }
        return columnsOrder.indexOf(a.colId as ECustomUnitsColumn) - columnsOrder.indexOf(b.colId as ECustomUnitsColumn);
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [customUnits, columnsOrder]
  );
};
