import { ChangeEvent, useMemo, useState } from "react";
import { ButtonGroup, Icon, InputGroup, NonIdealState, Tooltip } from "@blueprintjs/core";
import { useSetActiveAttachmentFromHash } from "@hooks/useSetActiveAttachmentFromHash";
import { observer } from "mobx-react";

import AddAttachmentButton from "@components/AddAttachmentMenu/AddAttachmentButton";
import { Button } from "@components/Button";
import UploadFileButton from "@components/UploadFileButton/UploadFileButton";
import appStore from "@store/AppStore";
import { IAttachment } from "@store/AttachmentStore";
import { IBlock } from "@store/BlockStore";
import { EFileUploadStatus } from "@store/UploadStore";
import Text, { TextVariant } from "src/ui/Text";

import FileUploadList from "../Uploads/FileUploadList/FileUploadList";

import AttachmentList from "./AttachmentList";
import { AttachmentsGrid } from "./AttachmentsGrid";

import "./AttachmentsPanel.scss";

/** Type defs. */
type AttachmentsPanelProps = {
  block: IBlock | null | undefined;
};

/** Main function. */
function AttachmentsPanel({ block }: AttachmentsPanelProps) {
  const [filterString, setFilterString] = useState<string>("");

  useSetActiveAttachmentFromHash();

  const handleFilterChanged = (event: ChangeEvent<HTMLInputElement>) => setFilterString(event.target.value);

  const filteredFiles =
    appStore.orgModel.uploads.uploadFiles.filter(file => {
      return file.status !== EFileUploadStatus.UPLOADED && file.block.id === block?.id && !file.attachmentId;
    }) || [];

  const filteredAttachments = useMemo(() => {
    let items: IAttachment[] = block?.attachments ?? [];

    if (filterString) {
      const queryString = filterString.toLowerCase();
      items = items.filter(a => a.name?.toLowerCase()?.includes(queryString) || a.label?.toLowerCase()?.includes(queryString));
    }

    return items.slice().sort((a, b) => b.createdAt - a.createdAt);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [block?.attachments?.length, filterString]);

  if (!block) {
    return null;
  }

  const AttachmentView = () => {
    if (!filteredAttachments.length && !filteredFiles.length) {
      return (
        <NonIdealState
          icon="paperclip"
          title="No attachments for this block"
          description="Drag and drop files onto this pane to add an attachment or click the button below to browse for files."
          action={<UploadFileButton multiple text="Add Attachment" onChange={block.addNewFileUpload} e2eIdentifiers="upload-attachment" />}
        />
      );
    }

    if (appStore.env.showAttachmentGrid) {
      return <AttachmentsGrid block={block} attachments={filteredAttachments} uploadingAttachments={filteredFiles} />;
    }

    return (
      <>
        <FileUploadList fileList={filteredFiles} />
        <AttachmentList block={block} attachmentList={filteredAttachments} />
      </>
    );
  };

  return (
    <div className="component--attachments-panel">
      {!block.attachments?.length && (
        <div className="attachments--info">
          <Icon size={14} className="attachments--info-icon" icon="info-sign" />
          <Text variant={TextVariant.Caption}>
            You can drag and drop attachments directly to this block or add them using the button below
          </Text>
        </div>
      )}
      <div className="panel-header">
        <AddAttachmentButton
          multiple
          onFileUpload={block.addNewFileUpload}
          onAttachLink={block.addLink}
          onAttachIntegration={block.addIntegrationAttachment}
          onAttachReferenceLink={block.addReferenceLink}
        />
        <InputGroup
          leftIcon="search"
          className="search-bar"
          placeholder="Filter by attachment label or file name"
          value={filterString}
          onChange={handleFilterChanged}
        />
        <ButtonGroup className="view-group">
          <Tooltip content="List view" hoverOpenDelay={500}>
            <Button
              icon="list"
              active={!appStore.env.showAttachmentGrid}
              minimal
              onClick={() => appStore.env.setShowAttachmentGrid(false)}
              e2eIdentifiers={["attachment-view-type-switcher", "list"]}
            />
          </Tooltip>
          <Tooltip content="Grid view" hoverOpenDelay={500}>
            <Button
              icon="grid-view"
              active={appStore.env.showAttachmentGrid}
              minimal
              onClick={() => appStore.env.setShowAttachmentGrid(true)}
              e2eIdentifiers={["attachment-view-type-switcher", "grid"]}
            />
          </Tooltip>
        </ButtonGroup>
      </div>
      <div className="attachments-view-content">
        <AttachmentView />
      </div>
    </div>
  );
}

/** Exports. */
export default observer(AttachmentsPanel);
