import { PropertyDefinitionUpdateDto, PropertyInstanceUpdateDto } from "@rollup-api/models";
import { IPropertyDefinition } from "@store/PropertyDefinitionStore";
import { IPropertyInstance } from "@store/PropertyInstanceStore";

// Those are the fields that can be changed in the prop details drawer
const propertyInstanceFields: Array<keyof IPropertyInstance> = ["value", "lowerBoundUncertainty", "upperBoundUncertainty"];
const propertyDefinitionFields: Array<keyof IPropertyDefinition> = [
  "dataType",
  "autoRollupChildren",
  "autoAdd",
  "uncertaintyType",
  "uncertaintySymmetry",
  "description",
];

const hasChangedSomeField = <T>(original: T, current: T, field: Array<keyof T>): boolean => {
  return field.some(f => original[f] !== current[f]);
};

export const hasChangedPropertyInstance = (original: IPropertyInstance, current: IPropertyInstance): boolean => {
  return hasChangedSomeField(original, current, propertyInstanceFields);
};

export const hasChangedPropertyDefinition = (original?: IPropertyDefinition, current?: IPropertyDefinition): boolean => {
  return !!original && !!current && hasChangedSomeField(original, current, propertyDefinitionFields);
};

// Creates a DTO with the fields that have changed. If nothing has changed, returns an empty object
const getUpdateDto = <T>(original: T, current: T, field: Array<keyof T>): Partial<T> => {
  return field.reduce((acc, field) => {
    if (original[field] !== current[field]) {
      acc[field] = current[field];
    }
    return acc;
  }, {} as Partial<T>);
};

export const getPropertyInstanceUpdateDto = (original: IPropertyInstance, current: IPropertyInstance): PropertyInstanceUpdateDto => {
  return getUpdateDto(original, current, propertyInstanceFields);
};

export const getPropertyDefinitionUpdateDto = (
  original: IPropertyDefinition,
  current: IPropertyDefinition
): PropertyDefinitionUpdateDto => {
  return getUpdateDto(original, current, propertyDefinitionFields);
};
