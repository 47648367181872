import { AxiosResponse } from "axios";

import {
  GitHubListItem,
  GoogleDriveListItem,
  GoogleDriveMimeType,
  GoogleFileListItem,
  GoogleFileMetadata,
  IGitHubRepoStatus,
  Integration,
  IntegrationProvider,
  IssueDetails,
  LinkChannelDto,
  OnShapeCompany,
  PullRequestDetails,
  RepoDetails,
  SimpleGitHubRepoMetadata,
  SlackChannel,
} from "@rollup-api/models";
import { rollupClient } from "src/core/api";

import { HttpClient, ParentClient } from "./client";

export class Integrations extends HttpClient {
  public constructor(parent: ParentClient) {
    super(parent);
  }

  private get endpoint() {
    return "integrations";
  }

  public getProviderRedirectUrl(provider: IntegrationProvider, existingIntegrationId?: string) {
    return this.instance.get<RedirectUrlResponse>(`${this.endpoint}/${IntegrationProviderUrlMap[provider]}/get-redirect-url`, {
      params: { existingIntegrationId },
    });
  }

  public getAll() {
    return this.instance.get<Integration[]>(`${this.endpoint}`);
  }

  public get(id: string, checkStatus?: boolean) {
    return this.instance.get<Integration>(`${this.endpoint}/${id}`, { params: { checkStatus } });
  }

  public delete(id: string) {
    return this.instance.delete<Integration>(`${this.endpoint}/${id}`);
  }

  public async filterGitHubItems(filterString: string) {
    return this.instance.get<GitHubListItem[]>(`${this.endpoint}/github/filter?filterString=${filterString}`);
  }

  public async fetchGitHubRepo(id: string) {
    return this.instance.get<RepoDetails>(`${this.endpoint}/github/repos/${id}`);
  }

  public async fetchGitHubRepoStatus(repoMetadata: SimpleGitHubRepoMetadata) {
    return this.instance.get<IGitHubRepoStatus>(`${this.endpoint}/github/repos/status-check/${repoMetadata.owner}/${repoMetadata.name}`);
  }

  public async fetchGitHubIssue(id: string) {
    return this.instance.get<IssueDetails>(`${this.endpoint}/github/issues/${id}`);
  }
  public async fetchGitHubPullRequest(id: string) {
    return this.instance.get<PullRequestDetails>(`${this.endpoint}/github/pull-requests/${id}`);
  }

  public async listDrives() {
    return this.instance.get<GoogleDriveListItem[]>(`${this.endpoint}/google/drives`);
  }

  public async setTeamDrive(teamDriveId: string) {
    return this.instance.post<Integration>(`${this.endpoint}/google/drives`, { teamDriveId });
  }

  public async filterDriveItems(filterString: string, mimeTypes?: GoogleDriveMimeType[], pageSize = 10) {
    return this.instance.get<GoogleFileListItem[]>(`${this.endpoint}/google/files`, {
      params: {
        pageSize,
        filterString,
        mimeType: mimeTypes?.join(","),
      },
    });
  }

  public async fetchFileMetadata(fileId: string) {
    return this.instance.get<GoogleFileMetadata>(`${this.endpoint}/google/files/${fileId}`);
  }

  public async fetchSheetList(fileId: string) {
    return this.instance.get<string[]>(`${this.endpoint}/google/sheets/${fileId}`);
  }

  public getFilePreviewUrl(fileId: string, version: number) {
    return `${rollupClient.auth.getParentUrl()}/${this.endpoint}/google/preview/${fileId}?version=${version}?size=1024&rollup_token=${
      rollupClient.auth.accessToken
    }`;
  }

  public async listSlackChannels() {
    return this.instance.get<SlackChannel[]>(`${this.endpoint}/slack/channels`);
  }

  public async linkSlackChannel(integrationId: string, linkSlackChannelDto: LinkChannelDto) {
    return this.instance.post(`${this.endpoint}/slack/${integrationId}/link-channel`, {
      channel: linkSlackChannelDto,
    });
  }

  public async updateSlackChannelLink(integrationId: string, dto: LinkChannelDto) {
    return this.instance.patch(`${this.endpoint}/slack/${integrationId}/channel/${dto.id}`, { entityTypes: dto.entityTypes });
  }

  public async unlinkSlackChannel(integrationId: string, channelId: string) {
    return this.instance.post(`${this.endpoint}/slack/${integrationId}/unlink-channel/${channelId}`);
  }

  public async getOnShapeSessionInfo() {
    return this.instance.get(`${this.endpoint}/onshape/user-session-info`);
  }

  public async listOnShapeCompanies(): Promise<AxiosResponse<{ items: OnShapeCompany[] }>> {
    return this.instance.get(`${this.endpoint}/onshape/companies`);
  }

  public async setOnShapeCompany(_companyId: string) {
    // @todo: uncomment when BE is ready
    // return this.instance.post(`${this.endpoint}/onshape/companies`, { companyId });
    return true;
  }
}

const IntegrationProviderUrlMap: Record<IntegrationProvider, string> = {
  [IntegrationProvider.GitHub]: "github",
  [IntegrationProvider.Slack]: "slack",
  [IntegrationProvider.Google]: "google",
  [IntegrationProvider.OnShape]: "onshape",
};

type RedirectUrlResponse = { redirectUrl: string };
