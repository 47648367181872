import { CSSProperties, useMemo } from "react";
import { Callout, Code, Icon, Intent, Tag } from "@blueprintjs/core";
import { EvaluatedExpression, EvaluationError, isValidEvaluation } from "@rollup-io/engineering";
import { Text, TextVariant } from "@ui/Text";
import classNames from "classnames";
import { observer } from "mobx-react";

import { Button } from "@components/Button";
import { LatexElement } from "@components/SmallComponents";
import { IPropertyInstance } from "@store/PropertyInstanceStore";
import {
  evaluateSmartExpression,
  generateLatexForZeroValue,
  generateLatexStringForTotal,
  generateLatexStringFromExpression,
  generateLatexStringFromResult,
} from "@utilities";
import { DOCUMENTATION_HOSTNAME, DOCUMENTATION_LINK } from "@utilities/DocumentationUtils";

import { ExpressionInfoReferences } from "./ExpressionInfoReferences";

import "./ExpressionInfoOverlay.scss";

interface IExpressionInfoOverlayProps {
  propertyInstance: IPropertyInstance;
  expression: string;
  style?: CSSProperties;
  onShowDataConnectionDropdown(): void;
}

export const ExpressionInfoOverlay = observer(function ExpressionInfoOverlay(props: IExpressionInfoOverlayProps) {
  const { propertyInstance, expression, style, onShowDataConnectionDropdown } = props;
  const evaluatedExpression = useMemo(() => evaluateSmartExpression(expression, propertyInstance), [expression, propertyInstance]);

  const additionalErrorHelp = useMemo(() => {
    const errorType = evaluatedExpression?.errorType;
    if (isValidEvaluation(evaluatedExpression)) {
      return <></>;
    }

    switch (errorType) {
      case EvaluationError.IncompatibleUnits:
      case EvaluationError.InvalidUnit:
        return (
          <span>
            For more information, please visit <a href={`${DOCUMENTATION_LINK}/units`}>{DOCUMENTATION_HOSTNAME}</a>.
          </span>
        );
      // add more cases as needed
      default:
        break;
    }
    return <></>;
  }, [evaluatedExpression]);

  const renderEvaluatedExpression = (evaluatedExpression: EvaluatedExpression) => {
    const { rollupInfo } = propertyInstance;
    const defaultIntrinsicValue = generateLatexForZeroValue(propertyInstance.combinedResult?.formatUnits());
    const defaultLatexExpression = `0 = ${defaultIntrinsicValue}`;
    const latexExpression = generateLatexStringFromExpression(evaluatedExpression);
    const rollupDependencies = rollupInfo?.dependencies?.filter(v => v.value);
    const isRollup = propertyInstance.isRollup && !!rollupDependencies?.length;
    const rollupLatex = generateLatexStringFromResult(propertyInstance.rollupInfo?.calculatedResult);
    const totalValueLatex = generateLatexStringForTotal(propertyInstance);
    const showRollupValue = isRollup && rollupLatex;

    if (!latexExpression && !showRollupValue) {
      return null;
    }

    return (
      <>
        <Callout className="expression-info-overlay--frame expression-info-overlay--evaluated-equation">
          <div className="expression-info-overlay--frame-label">
            <Icon icon="function" intent="primary" size={14} />
            <div>Evaluated Equation</div>
          </div>
          <div className="expression-info-overlay--row">
            {showRollupValue && (
              <Text variant={TextVariant.Label} disableAlignmentWrapper>
                Intrinsic:
              </Text>
            )}
            <LatexElement source={latexExpression || defaultLatexExpression} />
          </div>
          {showRollupValue && (
            <div className="expression-info-overlay--row">
              <Text variant={TextVariant.Label} disableAlignmentWrapper>
                Rollup:
              </Text>
              <LatexElement source={rollupLatex} />
            </div>
          )}
          {showRollupValue && (
            <div className="expression-info-overlay--row">
              <Text variant={TextVariant.Label} disableAlignmentWrapper>
                Total:
              </Text>
              <LatexElement source={totalValueLatex} />
            </div>
          )}
        </Callout>
        <ExpressionInfoReferences
          evaluatedExpression={evaluatedExpression}
          className="expression-info-overlay--frame"
          labelClassName="expression-info-overlay--frame-label"
        />
      </>
    );
  };

  const renderHelpUnitTag = () => {
    const unit = propertyInstance.propertyDefinition?.unit;
    const isUnitError = evaluatedExpression?.errorType === EvaluationError.IncompatibleUnits;

    if (isUnitError && unit) {
      return (
        <Tag className="ml-1" intent={Intent.WARNING}>
          {unit}
        </Tag>
      );
    }

    return null;
  };

  const intent = evaluatedExpression && isValidEvaluation(evaluatedExpression) ? Intent.SUCCESS : Intent.WARNING;

  return (
    <div className="expression-info-overlay" style={style}>
      <Callout className="expression-info-overlay--frame">
        <Button
          className="expression-info-overlay--add-from-data-source-btn"
          onMouseDown={onShowDataConnectionDropdown}
          icon="add"
          e2eIdentifiers="show-data-source-overlay"
          minimal
        >
          Add parameter from Data Connection
        </Button>
      </Callout>
      {!!evaluatedExpression?.message && (
        <Callout className="expression-info-overlay--frame">
          <Icon icon={intent === Intent.WARNING ? "warning-sign" : "info-sign"} intent={intent} size={14} />
          <div className={classNames({ "expression-info-overlay--message-warning": intent === Intent.WARNING })}>
            {evaluatedExpression?.message} {additionalErrorHelp}
            {renderHelpUnitTag()}
          </div>
        </Callout>
      )}
      {evaluatedExpression && renderEvaluatedExpression(evaluatedExpression)}
      {propertyInstance.isRollup && (
        <Callout className="expression-info-overlay--frame">
          <Icon icon="info-sign" intent="primary" size={14} />
          <div>You are editing the intrinsic property. This will be added to the rollup of this block's children.</div>
        </Callout>
      )}
      <Callout className="expression-info-overlay--frame expression-info-overlay--hint">
        <Icon icon="info-sign" intent="primary" size={14} />
        <span>Use </span>
        <Code className="expression-info-overlay--expression break-all">{"{{/Parent/Child:property}}"}</Code>
        <span> to reference property values.</span>
      </Callout>
    </div>
  );
});

export default ExpressionInfoOverlay;
