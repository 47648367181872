import { Menu } from "@blueprintjs/core";
import { observer } from "mobx-react";

import { Button } from "@components/Button";
import { MenuItem } from "@components/MenuItem";
import { Popover } from "@components/Popover";
import { IStatusDefinition, StatusType } from "@store/StatusDefinitionStore";

import GlobalStatus from "../../ModelingFrame/ModelBlock/Statuses/GlobalStatus";

type Props = {
  status: IStatusDefinition;
};

const TypeCell = (props: Props) => {
  const { status } = props;

  const getMenuContent = () => (
    <Menu>
      {Object.values(StatusType).map((property: StatusType) => (
        <MenuItem
          key={property}
          text={property}
          icon={GlobalStatus.TypeIcon(property)}
          disabled={status.type === property}
          selected={status.type === property}
          onClick={() => status.setType(property)}
          e2eIdentifiers={["type-cel", "menu-item", property]}
        />
      ))}
    </Menu>
  );

  return (
    <Popover content={getMenuContent()} placement="bottom" className="type-cell">
      <Button icon={GlobalStatus.TypeIcon(status.type)} minimal e2eIdentifiers="type-cell" />
    </Popover>
  );
};

export default observer(TypeCell);
