import { ChangeEvent, Fragment, useEffect, useState } from "react";
import { Checkbox, FormGroup, InputGroup, Label } from "@blueprintjs/core";
import classNames from "classnames";
import { observer } from "mobx-react";

import { AnchorButton } from "@components/AnchorButton";
import { Button } from "@components/Button";
import { Switch } from "@components/Switch";
import { CreateWebhookDto, TWebhookHeaders, Webhook } from "@rollup-api/models/webhook";
import { isUrl } from "@utilities";
import { rollupClient } from "src/core/api";
import { Text, TextVariant } from "src/ui/Text";

import "./SettingsWebhooks.scss";

const emptyWebhook: Webhook = {
  id: "",
  label: "",
  url: "",
  headers: [{ key: "", value: "" }],
  dataEvents: [""],
  createdAt: "",
  updatedAt: "",
  signingSecret: "",
};

interface SettingsAddNewWebhookProps {
  onClose?: () => void;
  webhookId?: string;
  formOnly?: boolean;
  onChange?: (webhook: Webhook) => void;
}

/** Main function. */
const SettingsAddNewWebhook = ({ onClose, webhookId, formOnly, onChange }: SettingsAddNewWebhookProps) => {
  const [webhook, setWebhook] = useState<Webhook>(emptyWebhook);
  const [allEvents, setAllEvents] = useState<string[]>();
  const [jsonSectionOpen, setJsonSectionOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    const fetchWebhook = async (webhookId: string) => {
      const webhook = await rollupClient.webhooks.retrieveById(webhookId);
      if (webhook) {
        if (webhook.headers && webhook.headers.length > 0 && webhook.headers[0].key !== "") {
          setJsonSectionOpen(true);
          setWebhook(webhook);
        } else {
          setWebhook({ ...webhook, headers: [{ key: "", value: "" }] });
        }
      }
    };
    const fetchEvents = async () => {
      const events = await rollupClient.generalInfo.getAllEvents();
      if (events) {
        setAllEvents(events);
      }
    };
    if (webhookId) {
      fetchWebhook(webhookId);
    }
    fetchEvents();
  }, [webhookId]);

  useEffect(() => {
    onChange?.(webhook);
  }, [onChange, webhook]);

  const resetValues = () => {
    setWebhook(emptyWebhook);
  };

  const validateForm = () => {
    return webhook?.label && webhook?.url && isUrl(webhook?.url) && webhook?.dataEvents.length > 0;
  };

  const handleEventChecked = (e: ChangeEvent<HTMLInputElement>) => {
    const eventName = e.target.value;
    if (webhook.dataEvents.includes(eventName)) {
      setWebhook({ ...webhook, dataEvents: webhook.dataEvents.filter(e => e !== eventName) });
    } else {
      setWebhook({ ...webhook, dataEvents: [...webhook.dataEvents, eventName] });
    }
  };

  const handleSubmitClicked = async () => {
    if (validateForm()) {
      const dto: CreateWebhookDto = {
        label: webhook.label,
        url: webhook.url,
        headers: jsonSectionOpen ? webhook.headers : [{ key: "", value: "" }],
        dataEvents: [...webhook.dataEvents],
      };
      setIsSubmitting(true);
      if (webhookId) {
        await rollupClient.webhooks.update(webhookId, dto);
      } else {
        await rollupClient.webhooks.create(dto);
      }
      setIsSubmitting(false);
      resetValues();
      onClose?.();
    }
  };

  const handleKeyInputChange = (index: number, key: string) => {
    let newInputs: TWebhookHeaders = [];
    if (webhook.headers) {
      newInputs = [...webhook.headers];
    }
    newInputs[index].key = key;
    setWebhook({ ...webhook, headers: newInputs });
  };

  const handleValueInputChange = (index: number, value: string) => {
    let newInputs: TWebhookHeaders = [];
    if (webhook.headers) {
      newInputs = [...webhook.headers];
    }
    newInputs[index].value = value;
    setWebhook({ ...webhook, headers: newInputs });
  };

  const deleteKeyValuePair = (index: number) => {
    if (webhook.headers) {
      if (webhook.headers.length === 1) {
        setWebhook({ ...webhook, headers: [{ key: "", value: "" }] });
      } else {
        const newInputs = [...webhook.headers];
        newInputs.splice(index, 1);
        setWebhook({ ...webhook, headers: newInputs });
      }
    }
  };

  return (
    <div className={classNames("settings-layout--content", { ["form-only"]: formOnly })}>
      <div className="new-webhook">
        {!formOnly && (
          <>
            <Button
              large
              className="settings-layout--back"
              minimal
              icon="arrow-left"
              onClick={() => {
                onClose?.();
              }}
              fill={false}
              e2eIdentifiers="go-back"
            >
              Back
            </Button>
            <div className="settings-layout--header">
              <Text variant={TextVariant.H1}>{`${webhookId ? "Edit" : "Create"} webhook`}</Text>
              <span />
              <Button
                large
                intent="primary"
                className="create-button"
                onClick={handleSubmitClicked}
                loading={isSubmitting}
                e2eIdentifiers="submit"
              >
                {webhookId ? "Update" : "Create"}
              </Button>
            </div>
          </>
        )}
        <Label className="new-webhook--label">
          Webhook label
          <InputGroup
            className="settings-layout--search"
            large
            value={webhook.label ? webhook.label : ""}
            onChange={e => setWebhook({ ...webhook, label: e.target.value })}
            placeholder="Enter webhook label..."
          />
        </Label>
        <FormGroup
          className="new-webhook--label"
          helperText={
            webhook.url &&
            !isUrl(webhook.url) && (
              <Text variant={TextVariant.Caption} color="red">
                Invalid URL
              </Text>
            )
          }
        >
          <Label>
            URL
            <InputGroup
              className="settings-layout--search"
              large
              value={webhook.url ? webhook.url : ""}
              onChange={e => setWebhook({ ...webhook, url: e.target.value })}
              placeholder="Enter URL..."
            />
          </Label>
        </FormGroup>
        <div className="new-webhook--events">
          <Text variant={TextVariant.H4}>Event types</Text>
          <span />
          {webhook?.dataEvents.length &&
            allEvents?.map(event => (
              <Checkbox
                key={event}
                checked={webhook.dataEvents.includes(event)}
                value={event}
                label={event}
                onChange={handleEventChecked}
              />
            ))}
        </div>

        <div className="json-switch">
          <Switch checked={jsonSectionOpen} onChange={() => setJsonSectionOpen(state => !state)} e2eIdentifiers="show-advanced-options" />
          <Text variant={TextVariant.H3}>Advanced options</Text>
        </div>
        {jsonSectionOpen && (
          <>
            <Text variant={TextVariant.H4}>Headers</Text>
            <div className="new-webhook--JSON">
              <Label className="new-webhook--label">Key</Label>
              <Label className="new-webhook--label">Value</Label>
              <span />
              {webhook.headers?.map((header, index) => (
                <Fragment key={index}>
                  <InputGroup
                    className="settings-layout--search"
                    large
                    value={header.key}
                    onChange={e => handleKeyInputChange(index, e.target.value)}
                    placeholder="Enter key..."
                  />
                  <InputGroup
                    className="settings-layout--search"
                    large
                    value={header.value}
                    onChange={e => handleValueInputChange(index, e.target.value)}
                    placeholder="Enter value..."
                  />
                  <AnchorButton
                    minimal
                    rightIcon="trash"
                    onClick={() => deleteKeyValuePair(index)}
                    e2eIdentifiers="delete-key-value-pair"
                  />
                </Fragment>
              ))}
            </div>
            <Button
              outlined
              large
              className="add-json-button"
              icon="plus"
              onClick={() => {
                setWebhook({
                  ...webhook,
                  headers: webhook.headers ? [...webhook.headers, { key: "", value: "" }] : [{ key: "", value: "" }],
                });
              }}
              e2eIdentifiers="add-header"
            >
              Add header
            </Button>
          </>
        )}

        {!formOnly && (
          <Button
            large
            intent="primary"
            className="create-button"
            onClick={handleSubmitClicked}
            loading={isSubmitting}
            e2eIdentifiers="submit"
          >
            {webhookId ? "Update" : "Create"}
          </Button>
        )}
      </div>
    </div>
  );
};

/** Exports. */
export default observer(SettingsAddNewWebhook);
