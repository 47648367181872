import { ReportTreeNodeInfo } from "@components/ReportsTree/types";
import appStore from "@store/AppStore";
import { IReport } from "@store/ReportsStore";
import { getMockedTreeEntity } from "@utilities";

export const REPORTS_TREE_PREFIX = "reports-tree";
export const PADDING_PER_LEVEL = 20;

const fillBlockListAndMap = (report: IReport, list: Array<ReportTreeNodeInfo>, map: Map<string, IReport>) => {
  if (!report || map.has(report?.id)) {
    return;
  }

  list.push({ report, path: report.path });
  map.set(report.id, report);

  if (report.children) {
    for (const childNode of report.validatedChildren) {
      fillBlockListAndMap(childNode, list, map);
    }
  } else {
    list.push(getMockedTreeEntity(report));
  }
};

export const reportNodeList = (): ReportTreeNodeInfo[] => {
  const list = new Array<ReportTreeNodeInfo>();
  const map = new Map<string, IReport>();

  for (const reportId of appStore.workspaceModel?.rootReportIds || []) {
    const report = appStore.workspaceModel?.reportsMap.get(reportId) as IReport;
    if (report && !report.isTemplate) {
      fillBlockListAndMap(report, list, map);
    }
  }

  return list;
};
