import { Divider, Icon, Intent } from "@blueprintjs/core";
import { observer } from "mobx-react";

import appStore from "@store/AppStore";
import { rollupClient } from "src/core/api";

import { TitledTooltip } from "../Shared";

interface IPresenceIndicatorProps {
  dividerClassName?: string;
}

const PresenceIndicator = (props: IPresenceIndicatorProps) => {
  if (!rollupClient.info || appStore.ui.presenceData?.length === 0) {
    return null;
  }

  return (
    <>
      <div className="flex items-center gap-2">
        <TitledTooltip
          inheritDarkTheme={appStore.env.themeIsDark}
          placement="bottom-start"
          title={`Connected to ${rollupClient.info?.host}`}
          content={
            <span>
              Latency: {appStore.ui.latencyMeasurement.toFixed(1)} ms
              <br />
              Version: {rollupClient.info?.version ?? "dev"}
              <br />
              Database: {rollupClient.info?.database}
              <br />
              Organization: {appStore.orgModel?.info?.name}
              <br />
              Organization ID: {appStore.orgModel?.info?.id}
            </span>
          }
        >
          <div className="w-[30px] h-[30px] flex items-center justify-center">
            <Icon icon="data-connection" intent={Intent.SUCCESS} />
          </div>
        </TitledTooltip>
      </div>
      <Divider className={props.dividerClassName} />
    </>
  );
};

export default observer(PresenceIndicator);
