import { ChangeEvent, useRef } from "react";
import { Position } from "@blueprintjs/core";
import { observer } from "mobx-react";

import { Button } from "@components/Button";
import { CustomIcon, ModuleMonochromeIcon } from "@components/CustomIcon";
import WorkspaceMenu, { WORKSPACE_NAME_LIMIT } from "@components/Header/HeaderMenus/WorkspaceMenu";
import { Popover } from "@components/Popover";
import TruncatedLabel from "@components/TruncatedLabel";
import { showToast } from "@components/UiLayers/toaster";
import { useAppNavigate } from "@router/hooks";
import appStore from "@store/AppStore";

const WorkspaceDropdown = () => {
  const { navigateToWorkspace } = useAppNavigate();
  const fileInputRef = useRef<HTMLInputElement>(null);
  const handleImportFileChanged = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (!file) {
      return;
    }

    const reader = new FileReader();
    const handleImport = async () => {
      try {
        const parsed = JSON.parse(reader.result as string);
        const workspaceId = await appStore.setNewWorkspaceFromExternal(parsed);
        if (workspaceId) {
          navigateToWorkspace(workspaceId);
        }
      } catch (e) {
        showToast("Error: Invalid workspace imported", "danger", "error");
      }
    };

    reader.addEventListener("load", handleImport, false);
    reader.readAsText(file);
  };

  return (
    <>
      <input
        data-testid="workspace-import-input"
        ref={fileInputRef}
        type="file"
        style={{ display: "none" }}
        onChange={handleImportFileChanged}
      />
      <Popover
        onOpened={() => appStore.pullOrgWorkspaces()}
        content={<WorkspaceMenu fileInputRef={fileInputRef} />}
        hoverCloseDelay={700}
        position={Position.BOTTOM_LEFT}
      >
        <Button minimal icon={<CustomIcon icon={ModuleMonochromeIcon.Workspace} />} rightIcon="chevron-down" e2eIdentifiers="workspace">
          <TruncatedLabel text={appStore.workspaceModel?.label || "Workspace"} limit={WORKSPACE_NAME_LIMIT} />
        </Button>
      </Popover>
    </>
  );
};

export default observer(WorkspaceDropdown);
