import { useState } from "react";
import { FormGroup, InputGroup, Spinner } from "@blueprintjs/core";
import { DataSourceQOFactory } from "@queries/DataSourceQOFactory";
import { useQuery } from "@tanstack/react-query";
import { observer } from "mobx-react";
import { useDebounceValue } from "usehooks-ts";

import { DataSourceValueUtils } from "@components/DataSources/DataSourceValueUtils";
import { DataSource } from "@rollup-api/models/data-sources/data-source.model";
import { Text, TextVariant } from "src/ui/Text";

import "./GoogleSheetsPreviewPanel.scss";

interface IPreviewPanelProps {
  dataSource: DataSource;
}

const GoogleSheetsPreviewPanel = (props: IPreviewPanelProps) => {
  const { dataSource } = props;
  const [queryString, setQueryString] = useState("");
  const [debouncedQueryString] = useDebounceValue(queryString, 500);
  const { isLoading, data: queryResult, error } = useQuery(DataSourceQOFactory.createGetValueQO(dataSource.id, debouncedQueryString));

  const helperText = DataSourceValueUtils.getHelperText(debouncedQueryString, isLoading, queryResult, error);
  const intent = DataSourceValueUtils.getIntent(queryResult);
  const parsedResult = DataSourceValueUtils.parseQueryResult(queryResult);

  return (
    <div className="google-sheets-preview-panel">
      <div className="google-sheets-preview-panel--example-container">
        <Text variant={TextVariant.H3}>Use in Properties</Text>
        <div className="google-sheets-preview-panel--desc">To use a value from a Google Sheet cell, you can follow the examples below:</div>
        <div className="google-sheets-preview-panel--example">
          <div>A1</div>
          <div>A1:B1</div>
          <div>Sheet1!A1</div>
          <div>'My Sheet Name'!A1</div>
        </div>
      </div>
      <div>
        <div className="google-sheets-preview-panel--test-container">
          <FormGroup
            className="google-sheets-preview-panel--form-group"
            intent={intent}
            label={<Text variant={TextVariant.Caption}>Test Area</Text>}
            helperText={helperText}
          >
            <div className="google-sheets-preview-panel--form-group-content">
              <InputGroup value={queryString} intent={intent} onChange={e => setQueryString(e.target.value)} large />
              <span>=</span>
            </div>
          </FormGroup>
          <div className="google-sheets-preview-panel--result-container">
            <Text variant={TextVariant.Caption}>Evaluates to</Text>
            <div className="google-sheets-preview-panel--result">{isLoading ? <Spinner size={20} /> : parsedResult}</div>
          </div>
        </div>
        <div className="google-sheets-preview-panel--form-group-help-text">Write a data source string to test this feature</div>
      </div>
    </div>
  );
};

export default observer(GoogleSheetsPreviewPanel);
