import DataConnectionsFrame from "@components/DataSources/DataConnectionsFrame";

import styles from "./DataConnectionsIndex.module.scss";

export const DATA_SOURCES_PANE = "DATA_SOURCES_PANE";

function DataConnectionsIndex() {
  return (
    <div className={styles.dataConnectionsIndex} id={DATA_SOURCES_PANE}>
      <DataConnectionsFrame />
    </div>
  );
}

export default DataConnectionsIndex;
