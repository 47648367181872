import { observer } from "mobx-react";

import { GoogleDriveIcon } from "@assets/icons/thirdParty";
import { MenuItem } from "@components/MenuItem";
import IntegrationFilterableMenu from "@components/Shared/IntegrationFilterableMenu";
import CustomIcon from "@components/Shared/LegacyCustomIcon";
import {
  GoogleDriveMimeType,
  GoogleFileListItem,
  IntegrationItem,
  IntegrationLinkData,
  IntegrationProvider,
} from "@rollup-api/models/integrations";
import { getGoogleIntegrationItem } from "@rollup-api/utils/integrations";
import appStore from "@store/AppStore";
import { rollupClient } from "src/core/api";

const GoogleWorkspaceMenu = IntegrationFilterableMenu<GoogleFileListItem>;

export const GoogleWorkspaceMenuSection = observer(({ onAttachLink }: { onAttachLink?: (data: IntegrationLinkData) => void }) => {
  const handleSelect = (item: IntegrationItem<GoogleFileListItem>) => {
    onAttachLink?.({
      name: item.title,
      url: item.data.webViewLink,
      mimeType: item.data.mimeType,
      metadata: {
        provider: IntegrationProvider.Google,
        teamDriveId: appStore.orgModel?.integrations?.get(IntegrationProvider.Google)?.metadata?.teamDriveId,
        type: item.data.mimeType?.replace("application/vnd.google-apps.", ""),
        fileId: item.data.id,
      },
    });
  };

  const handleGoogleWorkspaceQuery = async (query: string): Promise<IntegrationItem<GoogleFileListItem>[]> => {
    const res = await rollupClient.integrations.filterDriveItems(query, [
      GoogleDriveMimeType.DOCUMENT,
      GoogleDriveMimeType.SPREADSHEET,
      GoogleDriveMimeType.PRESENTATION,
    ]);
    return res.data?.map(getGoogleIntegrationItem);
  };

  return (
    <MenuItem icon={<CustomIcon icon={<GoogleDriveIcon />} />} e2eIdentifiers="google-integration" text="Google Drive">
      <GoogleWorkspaceMenu
        defaultItems={[]}
        onSelect={handleSelect}
        placeholder="Search for documents, slides or spreadsheets"
        onQuery={handleGoogleWorkspaceQuery}
      />
    </MenuItem>
  );
});
