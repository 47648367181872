import { GetRowIdParams, GridOptions, IRowDragItem } from "ag-grid-community";

import { TCellRendererProps } from "@components/Table";
import RichTextCell from "@components/Table/CellRenderers/RichTextCell";
import appStore from "@store/AppStore";
import { IRequirementBlock, isHeading } from "@store/RequirementsStore";
import { withObservedValueGetter, withTreeNodeAlive } from "@utilities/AgGridUtils";
import { getCssVar } from "@utilities/StyleUtils";

import { LinkedPropertyInput } from "../LinkedPropertyInput";
import { VerificationLogic } from "../VerificationLogic";

import FunctionalTypeCell from "./Cells/FunctionalTypeCell";
import LevelCell from "./Cells/LevelCell";
import LinkedBlockCell from "./Cells/LinkedBlockCell";
import MethodCell from "./Cells/MethodCell";
import SuccessCriteriaCell from "./Cells/SuccessCriteriaCell";
import VerificationStatusCell from "./Cells/VerificationStatusCell";
import { ERequirementsTableColumn, RATIONALE_PLACEHOLDER } from "./constants";

export const getRowId = (row: GetRowIdParams<IRequirementBlock>) => row.data.id;

export const handleColumnResize = (column: string, width: number) => {
  appStore.env.columnsSize.updateRequirementsTableColumnSize(column as ERequirementsTableColumn, width);
};

export const getRowDragText = (params: IRowDragItem) => {
  const requirementBlock = params.rowNode?.data as IRequirementBlock | undefined;
  return requirementBlock?.computedVisibleId || "Requirement row";
};

export const idCellRenderer = withTreeNodeAlive<IRequirementBlock>(({ data: requirementBlock }) => {
  return <p className="cell-id">{requirementBlock.computedVisibleId}</p>;
});

export const titleCellRenderer = withObservedValueGetter(
  withTreeNodeAlive<IRequirementBlock>(({ data: requirementBlock }) => {
    return (
      <RichTextCell
        content={requirementBlock.label}
        disabled={requirementBlock.locked}
        placeholder="Untitled Requirement"
        e2eIdentifiers={[requirementBlock.id, "title"]}
      />
    );
  })
);

export const functionalTypeCellRenderer = withTreeNodeAlive<IRequirementBlock>(({ data: requirementBlock }) => {
  return <FunctionalTypeCell requirementBlock={requirementBlock} />;
});

export const descriptionCellRenderer = withObservedValueGetter(
  withTreeNodeAlive<IRequirementBlock>(({ data: requirementBlock }) => {
    return (
      <RichTextCell
        content={requirementBlock.description}
        disabled={requirementBlock.locked}
        e2eIdentifiers={[requirementBlock.id, "description"]}
      />
    );
  })
);

export const rationaleCellRenderer = withObservedValueGetter(
  withTreeNodeAlive<IRequirementBlock>(({ data: requirementBlock }) => {
    return (
      <RichTextCell
        content={requirementBlock.rationale}
        placeholder={RATIONALE_PLACEHOLDER}
        e2eIdentifiers={[requirementBlock.id, "rationale"]}
      />
    );
  })
);

export const linkedPropertyCellRenderer = withTreeNodeAlive<IRequirementBlock>(({ data: requirementBlock }) => {
  return <LinkedPropertyInput requirementBlock={requirementBlock} />;
});

export const linkedBlockCellRenderer = withTreeNodeAlive<IRequirementBlock>(({ data: requirementBlock }) => {
  return <LinkedBlockCell requirementBlock={requirementBlock} />;
});

export const methodCellRenderer = withTreeNodeAlive<IRequirementBlock>(({ data: requirementBlock }) => {
  return <MethodCell requirementBlock={requirementBlock} />;
});

export const verificationCellRenderer = withTreeNodeAlive<IRequirementBlock>(({ data: requirementBlock }) => {
  return <VerificationLogic requirementBlock={requirementBlock} />;
});

export const successCriteriaCellRenderer = withTreeNodeAlive<IRequirementBlock>(({ data: requirementBlock }) => {
  return <SuccessCriteriaCell requirementBlock={requirementBlock} />;
});

export const verificationStatusCellRenderer = withTreeNodeAlive<IRequirementBlock>(({ data: requirementBlock }) => {
  return <VerificationStatusCell requirementBlock={requirementBlock} />;
});

export const levelCellRenderer = withTreeNodeAlive<IRequirementBlock>(
  ({ data: requirementBlock }: TCellRendererProps<IRequirementBlock>) => {
    return <LevelCell requirementBlock={requirementBlock} />;
  }
);

export const commentCellRenderer = withObservedValueGetter(
  withTreeNodeAlive<IRequirementBlock>(({ data: requirementBlock }) => {
    return (
      <RichTextCell
        content={requirementBlock.comment}
        disabled={requirementBlock.locked}
        e2eIdentifiers={[requirementBlock.id, "comment"]}
      />
    );
  })
);

export const notesCellRenderer = withObservedValueGetter(
  withTreeNodeAlive<IRequirementBlock>(({ data: requirementBlock }) => {
    return (
      <RichTextCell content={requirementBlock.note} disabled={requirementBlock.locked} e2eIdentifiers={[requirementBlock.id, "notes"]} />
    );
  })
);

const getHeadingRowHeight = () => Number(getCssVar("--heading-row-height").slice(0, -2) || 35);
const isHeadingRow = (row?: IRequirementBlock) => !!row && isHeading(row.type);
const DEFAULT_ROW_HEIGHT = 37;

export const gridOptions: GridOptions<IRequirementBlock> = {
  getRowHeight: params => {
    if (isHeadingRow(params.data)) {
      return getHeadingRowHeight();
    }
    return DEFAULT_ROW_HEIGHT;
  },
};
