import { PopoverInteractionKind } from "@blueprintjs/core";
import { observer } from "mobx-react";
import { isAlive } from "mobx-state-tree";

import TextCell from "@components/Modeling/PropertyDefinition/Cells/TextCell";
import { Popover } from "@components/Popover";
import { PropertyInfoTable } from "@components/Shared";
import { TCellRendererProps } from "@components/Table";
import { IPropertyDefinition } from "@store/PropertyDefinitionStore";

const InstancesCell = (cellProps: TCellRendererProps<IPropertyDefinition>) => {
  const { data } = cellProps;
  const isBlockAlive = isAlive(data);

  return isBlockAlive ? (
    <Popover
      interactionKind={PopoverInteractionKind.CLICK}
      placement="bottom-start"
      content={<PropertyInfoTable properties={data.instances ?? []} />}
    >
      <TextCell e2eIdentifiers={["instance", data?.label]} label={`${data.instances.length}`} />
    </Popover>
  ) : null;
};

export default observer(InstancesCell);
