import { PropertyDataType } from "@rollup-io/engineering";

import { IDataSinkEntry } from "@store/DataConnection/DataSinkEntryStore";

export const isScalarEntry = (entry: IDataSinkEntry): boolean => {
  return entry.dataType === PropertyDataType.scalar;
};

export const isNonEmptyValueEntry = (entry: IDataSinkEntry): boolean => {
  return !!entry.value;
};

interface IDataLinkStringParams {
  sheet: string;
  query: string;
  dataSourceId: string;
}

export const getDataLinkString = (params: IDataLinkStringParams) => {
  const { sheet, query, dataSourceId } = params;

  const getSheetPrefix = () => {
    if (sheet) {
      if (sheet.includes(" ")) {
        return `'${sheet}'!`;
      } else {
        return `${sheet}!`;
      }
    }

    return "";
  };

  return `{{newDataLink:${dataSourceId}:${getSheetPrefix()}${query}}}`;
};

export const getDataSinkEntryString = (dataSinkEntryId: string) => {
  return `{{dataSinkEntry:${dataSinkEntryId}}}`;
};
