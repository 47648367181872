import { RollupEventsFindAllRto } from "@rollup-api/models/rollupEvents/rollupEventFindAllRto.model";
import { RollupEventSetStatusRequestDto } from "@rollup-api/models/rollupEvents/RollupEventSetStatusRequestDto.model";
import { RollupEventSetStatusResponseDto } from "@rollup-api/models/rollupEvents/RollupEventSetStatusResponseDto.model";
import { RollupEventStatuses } from "@store/RollupEventsStore";

import {
  RollupEvent,
  RollupEventsCountCheckRequestDto,
  RollupEventsCountCheckResponseDto,
  RollupEventsFindAllDto,
} from "../models/rollupEvents";

import { HttpClient, ParentClient } from "./client";

export class RollupEvents extends HttpClient {
  public constructor(parent: ParentClient) {
    super(parent);
  }

  private readonly endpoint: string = "/events";

  public retrieveAll = (notificationsFindAllDto: RollupEventsFindAllDto) => {
    return this.instance.get<RollupEventsFindAllRto>(`${this.endpoint}`, { params: notificationsFindAllDto });
  };

  public retrieveById = (id: string) => {
    return this.instance.get<RollupEvent>(`${this.endpoint}/${id}`);
  };

  public deleteById = (id: string) => {
    return this.instance.delete<RollupEvent>(`${this.endpoint}/${id}`);
  };

  public getRollupEventsCount = (dto: RollupEventsCountCheckRequestDto) => {
    return this.instance.get<RollupEventsCountCheckResponseDto>(`${this.endpoint}/check`, {
      params: dto,
    });
  };

  public getUnreadRollupEventCount = () => {
    return this.instance.get<RollupEventsCountCheckResponseDto>(`${this.endpoint}/check`, {
      params: { status: RollupEventStatuses.unread },
    });
  };

  // TODO: change the endpoint once BE is ready
  public getReadRollupEventCount = () => {
    return this.instance.get<RollupEventsCountCheckResponseDto>(`${this.endpoint}/check`, { params: { status: RollupEventStatuses.read } });
  };

  // TODO: change the endpoint once BE is ready
  public getArchivedRollupEventCount = () => {
    return this.instance.get<RollupEventsCountCheckResponseDto>(`${this.endpoint}/check`, {
      params: { status: RollupEventStatuses.archived },
    });
  };

  public setRollupEventsStatus = (dto: RollupEventSetStatusRequestDto) => {
    return this.instance.post<RollupEventSetStatusResponseDto>(`${this.endpoint}/set-status`, dto);
  };
}
