import isNull from "lodash/isNull";
import omitBy from "lodash/omitBy";

import { Attachment, Organization, OrganizationModuleType, PartNumberSchema } from "@rollup-api/models";
import { IOrganizationSnapshotIn } from "@store/OrganizationStore";
import { convertTimestamp } from "@utilities/Date";
import { rollupClient } from "src/core/api";

import { toMapSnapshot } from "./services.utils";

export class OrganizationService {
  static async FetchOrganization(modules?: OrganizationModuleType[]): Promise<IOrganizationSnapshotIn | undefined> {
    try {
      const res = await rollupClient.organizations.retrieve(modules?.join(","));

      if (!res.data || res.status !== 200) {
        console.error("Error fetching organization");
        return undefined;
      }
      const organization = OrganizationService.PrepareOrganization(res.data);
      if (!organization) {
        console.error("Error creating organization");
        return undefined;
      }
      return organization;
    } catch (error) {
      console.error("Error fetching organization", error);
      return undefined;
    }
  }

  private static PrepareOrganization(input: Organization): IOrganizationSnapshotIn {
    const organization = omitBy<Organization>(input, isNull);

    const organizationSnapshot: IOrganizationSnapshotIn = {
      settings: {},
      catalogItems: {},
      attachments: {},
      partNumberSchemas: {},
      info: {
        ...organization,
      },
    };

    if (organization.workspaces?.length) {
      organizationSnapshot.workspacesList = organization.workspaces;
    }

    if (organization.attachments?.length) {
      if (!organizationSnapshot.attachments) {
        organizationSnapshot.attachments = {};
      }
      organizationSnapshot.attachments.attachmentMap = {};
      for (let attachment of organization.attachments) {
        attachment = omitBy<Attachment>(attachment, isNull) as Attachment;
        organizationSnapshot.attachments.attachmentMap[attachment.id] = {
          ...attachment,
          createdAt: convertTimestamp(attachment.createdAt),
          updatedAt: convertTimestamp(attachment.updatedAt),
        };
      }
    }

    if (organization.catalogItems?.length) {
      if (!organizationSnapshot.catalogItems) {
        organizationSnapshot.catalogItems = {};
      }
      organizationSnapshot.catalogItems.catalogItemsMap = {};
      for (const catalogItem of organization.catalogItems) {
        organizationSnapshot.catalogItems.catalogItemsMap[catalogItem.id] = {
          ...omitBy(catalogItem, isNull),
          createdAt: convertTimestamp(catalogItem.createdAt),
          updatedAt: convertTimestamp(catalogItem.updatedAt),
          ...(catalogItem.parentItemId && {
            parentItem: catalogItem.parentItemId,
          }),
          versions: [],
          children: [],
          childReferences: [],
          references: [],
        };
      }

      for (const catalogItem of organization.catalogItems) {
        if (catalogItem.parentItemId) {
          const parent = organizationSnapshot.catalogItems.catalogItemsMap[catalogItem.parentItemId];
          if (parent) {
            if (!parent.children.includes(catalogItem.id)) {
              parent.children.push(catalogItem.id);
            }
          }
        }
      }
    }

    if (organization.catalogItemVersions?.length) {
      if (!organizationSnapshot.catalogItems) {
        organizationSnapshot.catalogItems = {};
      }
      organizationSnapshot.catalogItems.catalogItemVersionsMap = {};
      for (const catalogItemVersion of organization.catalogItemVersions) {
        const catalogItem = organizationSnapshot.catalogItems.catalogItemsMap?.[catalogItemVersion.catalogItemId];
        organizationSnapshot.catalogItems.catalogItemVersionsMap[catalogItemVersion.id] = {
          ...omitBy(catalogItemVersion, isNull),
          createdAt: convertTimestamp(catalogItemVersion.createdAt),
          updatedAt: convertTimestamp(catalogItemVersion.updatedAt),
        };

        if (catalogItem) {
          organizationSnapshot.catalogItems.catalogItemsMap?.[catalogItemVersion.catalogItemId].versions.push(catalogItemVersion.id);
          organizationSnapshot.catalogItems.catalogItemVersionsMap[catalogItemVersion.id].catalogItem = catalogItem.id;
        }
      }
    }

    if (organization.catalogItemReferences?.length) {
      if (!organizationSnapshot.catalogItems) {
        organizationSnapshot.catalogItems = {};
      }
      organizationSnapshot.catalogItems.catalogItemReferencesMap = {};
      for (const catalogItemReference of organization.catalogItemReferences) {
        const parentCatalogItem = organizationSnapshot.catalogItems.catalogItemsMap?.[catalogItemReference.parentCatalogItemId];
        const referencedCatalogItem = organizationSnapshot.catalogItems.catalogItemsMap?.[catalogItemReference.referencedCatalogItemId];

        organizationSnapshot.catalogItems.catalogItemReferencesMap[catalogItemReference.id] = {
          ...omitBy(catalogItemReference, isNull),
          createdAt: convertTimestamp(catalogItemReference.createdAt),
          updatedAt: convertTimestamp(catalogItemReference.updatedAt),
        };

        const reference = organizationSnapshot.catalogItems.catalogItemReferencesMap[catalogItemReference.id];
        if (parentCatalogItem) {
          parentCatalogItem.childReferences.push(catalogItemReference.id);
          reference.parentCatalogItem = parentCatalogItem.id;
        }

        if (referencedCatalogItem) {
          referencedCatalogItem.references.push(catalogItemReference.id);
          reference.referencedCatalogItem = referencedCatalogItem.id;
        }
      }
    }

    if (organization.partNumberSchemas?.length) {
      organizationSnapshot.partNumberSchemas = {};
      organizationSnapshot.partNumberSchemas.partNumberSchemasMap = {};
      for (const partNumberSchema of organization.partNumberSchemas) {
        organizationSnapshot.partNumberSchemas.partNumberSchemasMap[partNumberSchema.id] = omitBy(
          partNumberSchema,
          isNull
        ) as PartNumberSchema;
      }
    }

    organizationSnapshot.customUnits = { customUnitMap: toMapSnapshot(organization.customUnits) };

    return organizationSnapshot;
  }
}
