import { useEffect, useState } from "react";
import { DialogBody, InputGroup, Intent } from "@blueprintjs/core";
import { observer } from "mobx-react";

import { Button } from "@components/Button";
import { showToast } from "@components/UiLayers/toaster";
import appStore from "@store/AppStore";

import DialogLegacy from "../DialogLegacy";

import "./CreateReportTemplate.scss";

const CreateReportTemplate = () => {
  const report = appStore.workspaceModel?.reportsMap.get(appStore.ui.createReportTemplateDialog);
  const [templateName, setTemplateName] = useState(report?.label || "");

  useEffect(() => {
    setTemplateName(report?.label || "Untitled");
  }, [report?.label]);

  const handleCreateTemplate = () => {
    if (report) {
      appStore.workspaceModel
        ?.duplicateReport(report.id, { isTemplate: true, parentReportId: null, label: templateName })
        .then(() => {
          showToast("Successfully created template!", Intent.SUCCESS);
          appStore.ui.setCreateReportTemplateDialog();
        })
        .catch(() => showToast("Error creating Template!", Intent.DANGER));
    }
  };

  return (
    <DialogLegacy
      lazy
      canEscapeKeyClose
      title={<h3>Create new template</h3>}
      isOpen={!!appStore.ui.createReportTemplateDialog}
      onClose={() => appStore.ui.setCreateReportTemplateDialog()}
      isCloseButtonShown
    >
      <DialogBody>
        <label className="create-template-dialog--label" htmlFor="templateName">
          Template name
        </label>
        <InputGroup id="templateName" large fill value={templateName} onChange={e => setTemplateName(e.target.value)} />
        <div className="create-template-dialog--footer">
          <Button large minimal onClick={() => appStore.ui.setCreateReportTemplateDialog()} e2eIdentifiers="cancel">
            Cancel
          </Button>
          <Button large intent={Intent.PRIMARY} onClick={handleCreateTemplate} e2eIdentifiers="create-template">
            Create
          </Button>
        </div>
      </DialogBody>
    </DialogLegacy>
  );
};

export default observer(CreateReportTemplate);
