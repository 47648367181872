import { Edge, Node } from "reactflow";

import { CatalogItemChildRef } from "@rollup-api/models/catalogItem/catalogItemDtos";
import { ICatalogItem } from "@store/CatalogItem/CatalogItemStore";

export enum CustomNodeType {
  DEFAULT = "default",
  INPUT = "input",
  OUTPUT = "output",
}

export enum RelationNodeTypes {
  CatalogItem = "catalogItem",
  Reference = "reference",
  File = "file",
}

export type RelationData = { edges: Edge[]; nodes: Node[] };

export type TreeNodeData = { catalogItem: ICatalogItem; reference?: CatalogItemChildRef };
