import { useState } from "react";
import { HTMLTable } from "@blueprintjs/core";
import { observer } from "mobx-react";

import { QueryDataSourceDialog } from "@components/DataSources/QueryDataSourceDialog/QueryDataSourceDialog";
import { DataSource } from "@rollup-api/models/data-sources/data-source.model";
import { IDataConnection } from "@store/DataConnection/DataConnectionModuleStore";
import { IDataSink } from "@store/DataConnection/DataSinkStore";
import { sortByUpdated } from "@utilities";
import { Text, TextVariant } from "src/ui/Text";

import DataSourceTableRow from "./DataConnectionTableRow";

import styles from "./DataConnectionsTable.module.scss";

interface IDataConnectionsTableProps {
  dataConnections: IDataConnection[];
  dataConnectionIdToFlash?: string;
  onFlashedDataConnection(): void;
  onEditDataSink(dataSink: IDataSink): void;
}

const DataConnectionsTable = (props: IDataConnectionsTableProps) => {
  const { dataConnections, dataConnectionIdToFlash, onEditDataSink, onFlashedDataConnection } = props;

  const [testingSource, setTestingSource] = useState<DataSource>();

  const renderContent = () => {
    if (!dataConnections?.length) {
      return (
        <tr>
          <td colSpan={7} className="message">
            <div className="text-center">You don't have any data connections yet</div>
          </td>
        </tr>
      );
    }

    return dataConnections.sort(sortByUpdated).map(dataSource => {
      return (
        <DataSourceTableRow
          key={dataSource.id}
          dataConnection={dataSource}
          dataConnectionIdToFlash={dataConnectionIdToFlash}
          onFlashedDataConnection={onFlashedDataConnection}
          onTestSource={setTestingSource}
          onEditDataSink={onEditDataSink}
        />
      );
    });
  };

  return (
    <div className={styles.dataConnectionsTable}>
      {testingSource && <QueryDataSourceDialog dataSource={testingSource} onClose={() => setTestingSource(undefined)} />}
      <HTMLTable className={styles.dataConnectionsTableTable}>
        <thead>
          <tr>
            <th className={styles.dataConnectionsTableSourceNameHeader}>
              <Text variant={TextVariant.H5}>Source Name</Text>
            </th>
            <th className={styles.dataConnectionsTableDescriptionHeader}>
              <Text variant={TextVariant.H5}>Description</Text>
            </th>
            <th className={styles.dataConnectionsTableTagsHeader}>
              <Text variant={TextVariant.H5}>Tags</Text>
            </th>
            <th className={styles.dataConnectionsTableLastUpdatedHeader}>
              <Text variant={TextVariant.H5}>Last Updated</Text>
            </th>
            <th className={styles.dataConnectionsTableStatusHeader}>
              <Text variant={TextVariant.H5}>Status</Text>
            </th>
            <th className={styles.dataConnectionsTableActionHeader} />
          </tr>
        </thead>
        <tbody>{renderContent()}</tbody>
      </HTMLTable>
    </div>
  );
};

export default observer(DataConnectionsTable);
