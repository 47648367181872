import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ItemPredicate, ItemRenderer, Select } from "@blueprintjs/select";
import { observer } from "mobx-react";

import { Button } from "@components/Button";
import { GoogleWorkspaceIntegrationCallout } from "@components/IntegrationCallout/GoogleWorkspaceIntegrationCallout";
import { MenuItem } from "@components/MenuItem";
import { GoogleDriveListItem, IntegrationProvider } from "@rollup-api/models/integrations";
import appStore from "@store/AppStore";
import { IIntegration } from "@store/IntegrationStore";
import { rollupClient } from "src/core/api";

import ConnectIntegrationCard from "./ConnectIntegrationCard";

const GoogleSettingsInternal = () => {
  const { integrationId } = useParams();
  const [drivesList, setDrivesList] = useState<Array<GoogleDriveListItem>>([]);
  const [loading, setLoading] = useState(false);
  const googleIntegrationConfig: IIntegration | undefined = appStore.settingsModel?.integrationsMap.get(integrationId || "");

  useEffect(() => {
    if (googleIntegrationConfig?.connection) {
      setLoading(true);
      rollupClient.integrations
        .listDrives()
        .then(response => setDrivesList(response.data))
        .finally(() => setLoading(false));
    } else {
      setDrivesList([]);
    }
  }, [googleIntegrationConfig?.connection]);

  if (!googleIntegrationConfig) {
    return null;
  }

  const handleTeamSelect = async (item: GoogleDriveListItem) => {
    if (!item) {
      return;
    }

    const setDriveQuery = await rollupClient.integrations.setTeamDrive(item.id);
    if (setDriveQuery.data) {
      appStore.orgModel.setIntegrationData(IntegrationProvider.Google, setDriveQuery.data);
    }
  };

  const handleRenderItem: ItemRenderer<GoogleDriveListItem> = (team: GoogleDriveListItem, { modifiers, handleClick }) => {
    if (!modifiers.matchesPredicate) {
      return null;
    }

    const active = googleIntegrationConfig.connection?.metadata.teamDriveName === team.name;
    return (
      <MenuItem
        onClick={handleClick}
        active={active}
        e2eIdentifiers="menu-item"
        key={team.id}
        roleStructure="listoption"
        text={team.name}
      />
    );
  };

  const filterItems: ItemPredicate<GoogleDriveListItem> = (query, item: GoogleDriveListItem, _index, exactMatch) => {
    const normalizedTitle = item.name.toLowerCase();
    const normalizedQuery = query.toLowerCase();

    if (exactMatch) {
      return normalizedTitle === normalizedQuery;
    } else {
      return normalizedTitle.indexOf(normalizedQuery) >= 0;
    }
  };

  return (
    <>
      {googleIntegrationConfig.connection && (
        <Select<GoogleDriveListItem>
          onItemSelect={handleTeamSelect}
          fill
          itemPredicate={filterItems}
          disabled={loading}
          noResults={<MenuItem e2eIdentifiers="no-results" disabled text="No results" roleStructure="listoption" />}
          items={drivesList}
          popoverProps={{ minimal: true, matchTargetWidth: true }}
          itemRenderer={handleRenderItem}
        >
          <Button
            large
            alignText="left"
            fill
            e2eIdentifiers="button"
            text={googleIntegrationConfig.connection.metadata.teamDriveName || "Select team drive"}
            rightIcon="double-caret-vertical"
          />
        </Select>
      )}
      <ConnectIntegrationCard
        integration={googleIntegrationConfig}
        description="Connect your Google Workspace account to get access to files and be able to use them as attachments in your workspace."
      />
      {googleIntegrationConfig.connection && <GoogleWorkspaceIntegrationCallout integration={googleIntegrationConfig.connection} />}
    </>
  );
};

export default observer(GoogleSettingsInternal);
