import { useCallback, useEffect, useState } from "react";
import { useFetchFeedItems } from "@hooks/useFetchFeedItems";
import { useInfiniteScrollFetch } from "@hooks/useInfiniteScrollFetch";
import { useListenToCreateTransactionEvent } from "@hooks/useListenToCreateTransactionEvent";
import { Text, TextVariant } from "@ui/Text";
import { observer } from "mobx-react";

import { InfiniteScrollAnchor } from "@components/InfiniteScrollAnchor";
import { Loading } from "@components/Loading";
import { Transaction } from "@rollup-api/models/transactions";
import { FeedStore, IFeed } from "@store/FeedStore";
import { IPropertyInstance } from "@store/PropertyInstanceStore";
import { convertTimestamp } from "@utilities/Date";
import { htmlStringToText } from "@utilities/TipTap";
import { isDefined, isFieldDefined } from "@utilities/TypeGuards";

import { getActionText, getEditorContent, isPropertyDefinitionEntity, isValidPropertyTransaction } from "../Feed/utils";
import { FeedItem } from "../FeedItem";

import "./ActivityTimeline.scss";

interface IActivityTimelineProps {
  propertyInstance: IPropertyInstance;
}

const createFeedStoreFromTransaction = (transaction: Transaction) => {
  return FeedStore.create({ ...transaction, createdAt: convertTimestamp(transaction.createdAt) });
};

const parseFeedItemDescription = (feedItem: IFeed): IFeed => {
  const { newEntity, oldEntity, referenceType } = feedItem;

  if (isPropertyDefinitionEntity(referenceType, newEntity) && isPropertyDefinitionEntity(referenceType, oldEntity)) {
    const newDesc = newEntity.description && htmlStringToText(newEntity.description);
    const oldDesc = oldEntity.description && htmlStringToText(oldEntity.description);
    return FeedStore.create({
      ...feedItem,
      newEntity: { ...newEntity, description: newDesc },
      oldEntity: { ...oldEntity, description: oldDesc },
    });
  }

  return feedItem;
};

const ActivityTimeline = (props: IActivityTimelineProps) => {
  const { propertyInstance } = props;
  const [feedItems, setFeedItems] = useState<IFeed[]>([]);
  const [feedItemEndTime, setFeedItemEndTime] = useState<Date | undefined>();

  const commentIds = propertyInstance.annotationList.commentThreadList.threads.map(thread => thread.allIds).flat();
  const referenceIds = [propertyInstance.id, propertyInstance.propertyDefinition?.id, ...commentIds].filter(isDefined);

  const { isLoadingAllItems, isLoadingMoreItems, hasMoreItems } = useFetchFeedItems({
    referenceIds,
    endTime: feedItemEndTime,
    onFeedItems: setFeedItems,
    onChangeEndTime: setFeedItemEndTime,
  });

  const loadMoreFeedItems = () => {
    const endTimeTimestamp = feedItems.at(-1)?.createdAt;
    const endTime = endTimeTimestamp ? new Date(endTimeTimestamp) : undefined;
    setFeedItemEndTime(endTime);
  };

  const { loadMoreAnchorRef, scrollContainerRef } = useInfiniteScrollFetch(loadMoreFeedItems, isLoadingMoreItems);

  useEffect(() => {
    setFeedItemEndTime(undefined);
  }, [propertyInstance.id]);

  const handleCreateTransaction = useCallback(
    (transaction: Transaction) => {
      const propertyDefinitionId = propertyInstance.propertyDefinition?.id;
      if (isValidPropertyTransaction(transaction, propertyInstance.id, propertyDefinitionId)) {
        const feedItem = createFeedStoreFromTransaction(transaction);
        setFeedItems(state => [feedItem, ...state]);
      }
    },
    [propertyInstance.id, propertyInstance.propertyDefinition?.id]
  );

  useListenToCreateTransactionEvent(handleCreateTransaction);

  const renderContent = () => {
    if (isLoadingAllItems && !feedItems.length) {
      return <Loading />;
    }

    return (
      <div className="activity-timeline--feed" ref={scrollContainerRef}>
        {feedItems.filter(isFieldDefined("user")).map(feedItem => {
          const parsedFeedItem = parseFeedItemDescription(feedItem);
          return (
            <FeedItem
              key={feedItem.id}
              user={feedItem.user}
              actionText={getActionText(parsedFeedItem)}
              createdAt={feedItem.createdAt}
              editorContent={getEditorContent(feedItem)}
            />
          );
        })}
        <InfiniteScrollAnchor hasMoreItems={!!hasMoreItems} loadMoreAnchorRef={loadMoreAnchorRef} />
      </div>
    );
  };

  return (
    <div className="activity-timeline">
      <Text variant={TextVariant.Caption} disableAlignmentWrapper>
        ACTIVITY TIMELINE
      </Text>
      {renderContent()}
    </div>
  );
};

export default observer(ActivityTimeline);
