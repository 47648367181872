import { Menu } from "@blueprintjs/core";
import { observer } from "mobx-react";

import { disabledTooltip } from "@components/CatalogItems/constants";
import { CellDropdown } from "@components/CellDropdown";
import { MenuItem } from "@components/MenuItem";
import appStore from "@store/AppStore";
import { currencyUnits } from "@utilities";
import { withObservedValueGetter } from "@utilities/AgGridUtils";

import { TCellRendererProps } from "../types";

import styles from "./CostCurrencyCell.module.scss";

const CostCurrencyCell = (props: TCellRendererProps) => {
  const rowEditProgress = appStore.ui.pdmItemVersionProgress;
  const disabled = rowEditProgress && props.data?.catalogItem?.id !== rowEditProgress.catalogItemId;
  const catalogItem = props.data?.catalogItem;

  if (!catalogItem) {
    return null;
  }

  const valueChanged = rowEditProgress?.costCurrency && rowEditProgress.costCurrency !== catalogItem.costCurrency;
  const isEdited = !disabled && valueChanged;

  const handleChangeCurrency = (currency: string) => {
    props.setValue?.(currency);
  };

  const getMenuContent = () => (
    <Menu>
      {currencyUnits.map(c => (
        <MenuItem
          key={c.name}
          text={c.name}
          active={props.value === c.shorthand}
          onClick={() => handleChangeCurrency(c.shorthand)}
          e2eIdentifiers={c.name}
        />
      ))}
    </Menu>
  );

  return (
    <CellDropdown
      disabled={!!disabled}
      buttonClassName={styles.costCurrencyCell}
      className={isEdited ? styles.costCurrencyCellEdited : ""}
      tooltip={disabled ? disabledTooltip : undefined}
      text={props.value || "Curr."}
      content={getMenuContent()}
      e2eIdentifiers="catalog-item-cost-currency"
    />
  );
};

export default withObservedValueGetter(observer(CostCurrencyCell));
