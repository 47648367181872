import { Checkbox } from "@blueprintjs/core";
import { observer } from "mobx-react";
import { isAlive } from "mobx-state-tree";

import { TCellRendererProps } from "@components/Table";
import { IPropertyDefinition } from "@store/PropertyDefinitionStore";

const RollupChildrenCell = (cellProps: TCellRendererProps<IPropertyDefinition>) => {
  const { data } = cellProps;
  const isBlockAlive = isAlive(data);

  return isBlockAlive ? (
    <Checkbox data-testid={`rollup_${data.label}`} checked={data.autoRollupChildren} onChange={() => data.toggleAutoRollupChildren()} />
  ) : null;
};

export default observer(RollupChildrenCell);
