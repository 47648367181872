import { useEffect, useState } from "react";
import { Divider } from "@blueprintjs/core";
import Text, { TextVariant } from "@ui/Text";

import Comment from "@components/Modeling/ModelingFrame/ModelBlock/Comments/Comment";
import { IComment } from "@store/CommentStore";
import { rollupClient } from "src/core/api";

import styles from "./AnnotationThumbnailPreview.module.scss";

interface AnnotationThumbnailPreviewProps {
  annotationId: string;
  comment: IComment;
}

const AnnotationThumbnailPreview = ({ annotationId, comment }: AnnotationThumbnailPreviewProps) => {
  const [thumbnail, setThumbnail] = useState<string>();

  useEffect(() => {
    rollupClient.annotations
      .getThumbnail(annotationId)
      .then(response => {
        const url = URL.createObjectURL(response.data as unknown as Blob);
        setThumbnail(url);
      })
      .catch(() => {
        setThumbnail(undefined);
      });
  }, [annotationId]);

  if (!thumbnail) {
    return null;
  }

  return (
    <>
      <div className={styles.annotationThumbnailPreview}>
        <div className={styles.annotationThumbnailPreviewComment}>
          <Text variant={TextVariant.H4}>Comment</Text>
          <Comment comment={comment} showUser />
        </div>
        <div className={styles.annotationThumbnailPreviewThumbnail}>{thumbnail && <img src={thumbnail} alt="Annotation thumbnail" />}</div>
      </div>
      <Divider />
    </>
  );
};

export default AnnotationThumbnailPreview;
