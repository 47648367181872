import { Intent } from "@blueprintjs/core";

import { showToast } from "@components/UiLayers/toaster";

export const isValidDomain = (domain: string) => {
  if (domain.includes(" ")) {
    showToast("Domain cannot contain spaces", Intent.WARNING);
    return false;
  }

  // Regex from: https://stackoverflow.com/questions/26093545/how-to-validate-domain-name-using-regex
  const domainRegex = /^[a-zA-Z0-9][a-zA-Z0-9-]{0,61}[a-zA-Z0-9](?:\.[a-zA-Z]{2,})+$/;
  const isValid = domainRegex.test(domain);

  if (!isValid) {
    showToast("Invalid domain", Intent.WARNING);
    return false;
  }

  return true;
};
export const emailListSeparatorRegex = "[\\s,]";

export const isValidEmailList = (emailList: string) => {
  const emailRegex = "[\\w+-.%]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,64}";
  const emailListRegex = new RegExp("^(" + emailRegex + ")(" + emailListSeparatorRegex + "\\s*" + emailRegex + ")*$");
  return emailListRegex.test(emailList.trim());
};
