import { useContext } from "react";
import { StiggContext } from "@stigg/react-sdk";
import { observer } from "mobx-react";

import ChangelogButton from "@components/ChangelogButton/ChangelogButton";
import { FreeTrialIndicator } from "@components/FreeTrial/FreeTrialIndicator";
import { PresenceList } from "@components/Header/PresenceList/PresenceList";
import HelpMenuButton from "@components/HelpMenu/HelpMenuButton";
import PresenceIndicator from "@components/PresenceIndicator/PresenceIndicator";
import RollupEventsPopoverButton from "@components/RollupEventsPopover/RollupEventsPopoverButton";
import UserMenuButton from "@components/UserMenu/UserMenuButton";
import appStore from "@store/AppStore";

import EditedDaysAgo from "./EditedDaysAgo";

import "./RightHeaderElements.scss";

const RightHeaderElements = () => {
  const stiggCtx = useContext(StiggContext);

  return (
    <div className="right-header-elements flex gap-2 pr-2">
      {stiggCtx && <FreeTrialIndicator stigg={stiggCtx.stigg} />}
      <EditedDaysAgo />
      <PresenceList viewers={appStore.workspaceViewers} />
      <PresenceIndicator dividerClassName="right-header-elements--divider" />
      <RollupEventsPopoverButton />
      <HelpMenuButton dividerClassName="right-header-elements--divider" />
      <ChangelogButton />
      <UserMenuButton />
    </div>
  );
};

export default observer(RightHeaderElements);
