import { Position } from "@blueprintjs/core";
import { observer } from "mobx-react";

import { Icon } from "@components/Icon";
import { TitledTooltip } from "@components/Shared";
import { IDataSinkEntry } from "@store/DataConnection/DataSinkEntryStore";

interface IDataSinkEntryTagProps {
  dataSinkEntry?: IDataSinkEntry;
  iconClassName?: string;
  labelClassName?: string;
}

const DataSinkEntryTag = (props: IDataSinkEntryTagProps) => {
  const { dataSinkEntry, iconClassName, labelClassName } = props;

  if (!dataSinkEntry?.dataSink) {
    return <div className="data-link-tag warning">Invalid code output</div>;
  }

  const { dataSink, key, value } = dataSinkEntry;
  const tagLabel = `${dataSink.label}:${key}`;
  const tooltipMessage = `Value: ${value}`;

  return (
    <TitledTooltip placement={Position.BOTTOM} content={tooltipMessage}>
      <div className="data-sink-entry-tag">
        {dataSink.icon && <Icon className={iconClassName} icon={dataSink.icon} />}
        <span className={labelClassName}>{tagLabel}</span>
      </div>
    </TitledTooltip>
  );
};

export default observer(DataSinkEntryTag);
