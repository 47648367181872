import { ReactElement, ReactNode, useContext, useEffect, useState } from "react";
import { DraggableData, DraggableEvent } from "react-draggable";
import { Position, ResizableDelta, Rnd } from "react-rnd";
import { Classes, Tooltip } from "@blueprintjs/core";
import classNames from "classnames";
import { observer } from "mobx-react";
import { ResizeDirection } from "re-resizable";

import { Button } from "@components/Button";

import { ActiveWindowContext } from "./ActiveWindowContext";

import "./FloatingWindow.scss";

export type WindowPosition = {
  x: number;
  y: number;
};

export type WindowSize = {
  width: number;
  height: number;
};

export type FloatingWindowProps = {
  id: string;
  children: ReactNode;
  className?: string;
  contentClassName?: string;
  onActivated?: (id: string) => void;
  open?: boolean;
  title?: string;
  onClose?: (collapsed: boolean, toggleCollapsed: () => void) => void;
  onMaximise?: () => void;
  maximizeTooltipContent?: string | ReactElement;
  warningRenderer?: (toggleCollapsed: () => void) => ReactElement | null;
  resizable?: boolean;
  draggable?: boolean;
  minSize?: WindowSize;
  defaultSize?: WindowSize;
  defaultPosition?: WindowPosition;
  cancel?: string;
};

const MIN_WINDOW_HEIGHT = 60;
const MIN_WINDOW_WIDTH = 150;
export const FLOATING_WINDOW_HEADER_HEIGHT = 34;
const TOOLTIP_HOVER_DELAY = 500;

const FloatingWindow = (props: FloatingWindowProps) => {
  const [collapsed, setCollapsed] = useState<boolean>(false);
  const { draggable = true, warningRenderer, maximizeTooltipContent, onMaximise, onClose } = props;
  const { cancel } = props;

  const [position, setPosition] = useState<WindowPosition>(
    props.defaultPosition ?? {
      x: Math.floor(Math.random() * 1000),
      y: Math.floor(Math.random() * 600),
    }
  );
  const [size, setSize] = useState<WindowSize>({
    width: props.defaultSize?.width || 400,
    height: props.defaultSize?.height || 200,
  });

  useEffect(() => {
    setSize(state => ({ ...state, width: props.defaultSize?.width || 400 }));
  }, [props.defaultSize?.width]);

  useEffect(() => {
    setSize(state => ({ ...state, height: props.defaultSize?.height || 400 }));
  }, [props.defaultSize?.height]);

  const handleDragStop = (_e: DraggableEvent, data: DraggableData) => {
    const { x, y } = data;
    setPosition({ x, y });
  };

  const handleResize = (
    _e: MouseEvent | TouchEvent,
    _dir: ResizeDirection,
    elementRef: HTMLElement,
    _delta: ResizableDelta,
    position: Position
  ) => {
    setSize({
      width: +elementRef.style.width?.replace("px", ""),
      height: +elementRef.style.height?.replace("px", ""),
    });
    setPosition(position);
  };

  const toggleCollapsed = () => {
    const maxHeight = window.innerHeight;
    const isCollapsing = !collapsed;
    const effectiveHeight = Math.max(size.height, Math.max(MIN_WINDOW_HEIGHT, props.minSize?.height ?? 0));
    const isTouchingBottomBounds = position.y + effectiveHeight >= maxHeight;
    if (isTouchingBottomBounds) {
      if (isCollapsing) {
        setPosition({ x: position.x, y: maxHeight - FLOATING_WINDOW_HEADER_HEIGHT });
      } else {
        setPosition({ x: position.x, y: maxHeight - effectiveHeight });
      }
    }
    setCollapsed(isCollapsing);
  };

  const renderWarning = () => warningRenderer && <>{warningRenderer(toggleCollapsed)}</>;

  const currentSize: WindowSize = collapsed ? { width: size.width, height: FLOATING_WINDOW_HEADER_HEIGHT } : size;
  const { activeId, setActiveId } = useContext(ActiveWindowContext);

  if (props.open !== undefined && !props.open) {
    return null;
  }

  let maximiseButton = null;
  if (onMaximise) {
    maximiseButton = <Button icon="open-application" minimal small onClick={props.onMaximise} e2eIdentifiers="maximize" />;
    if (maximizeTooltipContent) {
      maximiseButton = (
        <Tooltip hoverOpenDelay={TOOLTIP_HOVER_DELAY} content={maximizeTooltipContent}>
          {maximiseButton}
        </Tooltip>
      );
    }
  }

  return (
    <Rnd
      bounds={window.document.body}
      position={position}
      className={classNames("floating-window", Classes.ELEVATION_3, props.className, { active: activeId === props.id })}
      size={currentSize}
      minHeight={collapsed ? FLOATING_WINDOW_HEADER_HEIGHT : +Math.max(MIN_WINDOW_HEIGHT, props.minSize?.height ?? 0)}
      minWidth={+Math.max(MIN_WINDOW_WIDTH, props.minSize?.width ?? 0)}
      onDragStop={handleDragStop}
      onResize={handleResize}
      disableDragging={!draggable}
      enableResizing={props.resizable !== false && !collapsed}
      dragHandleClassName="floating-window--drag-handle"
      onMouseDown={() => setActiveId(props.id)}
      cancel={cancel}
    >
      <div className="floating-window--container">
        {renderWarning()}
        <div className={classNames("floating-window--header", { ["floating-window--drag-handle"]: draggable })}>
          <Tooltip hoverOpenDelay={TOOLTIP_HOVER_DELAY} content={collapsed ? "Expand window" : "Collapse window"}>
            <Button
              icon={collapsed ? "caret-right" : "caret-down"}
              minimal
              small
              onClick={toggleCollapsed}
              e2eIdentifiers="toggle-collapsed"
            />
          </Tooltip>
          <div className="header-label" onDoubleClick={toggleCollapsed}>
            <span>{props.title ?? "Untitled window"}</span>
          </div>
          {maximiseButton}
          {onClose ? (
            <Button icon="cross" minimal small onClick={() => onClose(collapsed, toggleCollapsed)} e2eIdentifiers="close" />
          ) : null}
        </div>
        {!collapsed ? (
          <div className={classNames("floating-window--content", props.contentClassName, { ["floating-window--drag-handle"]: draggable })}>
            {props.children}
          </div>
        ) : null}
      </div>
    </Rnd>
  );
};

export default observer(FloatingWindow);
