import { Intent } from "@blueprintjs/core";

import { ImportStatus } from "@rollup-api/models/import";

export const statusIntent = (status: ImportStatus): Intent => {
  switch (status) {
    case ImportStatus.Success:
      return Intent.SUCCESS;
    case ImportStatus.Failed:
      return Intent.DANGER;
    default:
      return Intent.WARNING;
  }
};

export const statusLabel = (status: ImportStatus): string => {
  switch (status) {
    case ImportStatus.Success:
      return "Success";
    case ImportStatus.Failed:
      return "Failed";
    case ImportStatus.PendingApproval:
      return "Pending approval";
    case ImportStatus.Processing:
      return "Processing...";
    case ImportStatus.Extracting:
      return "Extracting Part Data...";
    case ImportStatus.CreatingEntities:
      return "Creating entities...";
    default:
      return "Reading...";
  }
};
