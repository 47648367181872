import { MouseEvent } from "react";
import { Menu } from "@blueprintjs/core";
import { observer } from "mobx-react";

import { MenuItem } from "@components/MenuItem";
import { Popover } from "@components/Popover";
import { IHoopsViewer } from "@store/HoopsViewerStore";

import "./HoopsContextMenu.scss";

interface ContextMenuProps {
  viewerStore: IHoopsViewer;
}

const HoopsContextMenu = ({ viewerStore }: ContextMenuProps) => {
  if (!viewerStore.isContextMenuOpen || !viewerStore.contextMenuNode) {
    return null;
  }

  const handleIsolate = () => {
    if (viewerStore.contextMenuNode) {
      viewerStore.toggleNodeIsolation(viewerStore.contextMenuNode);
      viewerStore.deactivateContextMenu();
    }
  };

  const handleZoomTo = () => {
    if (viewerStore.contextMenuNode) {
      viewerStore.zoomToNode(viewerStore.contextMenuNode);
      viewerStore.deactivateContextMenu();
    }
  };

  const preventDefaultContextMenu = (e: MouseEvent) => {
    e.preventDefault();
  };

  return (
    <div
      className="hoops-context-menu"
      style={{ top: viewerStore.contextMenuY, left: viewerStore.contextMenuX }}
      onContextMenu={preventDefaultContextMenu}
    >
      <Popover
        content={
          <span>
            <Menu>
              <MenuItem
                text="Isolate"
                icon="widget"
                onClick={handleIsolate}
                active={!!viewerStore.contextMenuNode?.isolated}
                e2eIdentifiers="isolate"
              />
              <MenuItem text="Zoom to" icon="widget" onClick={handleZoomTo} e2eIdentifiers="zoom-to" />
            </Menu>
          </span>
        }
        position="right-top"
        usePortal={false}
        isOpen={viewerStore.isContextMenuOpen}
      >
        <span />
      </Popover>
    </div>
  );
};

export default observer(HoopsContextMenu);
