import { useEffect, useState } from "react";
import { NonIdealState, Spinner } from "@blueprintjs/core";
import { BlueprintIcon } from "@ui/BlueprintIcon";
import { observer } from "mobx-react-lite";

import { Icon } from "@components/Icon";
import AttachmentIcon from "@components/Modeling/ModelingFrame/ModelBlock/Attachments/AttachmentIcon";
import ThumbnailPlaceholder from "@components/Modeling/ModelingFrame/ModelBlock/Attachments/ThumbnailPlaceholder";
import {
  getAttachmentThumbnailUrl,
  isFileAttachment,
  isIntegrationAttachment,
  isReferenceAttachment,
  isUrlAttachment,
} from "@components/Modeling/ModelingFrame/ModelBlock/Attachments/utils";
import AssetImage from "@components/Shared/AssetImage/AssetImage";
import { AttachmentStatus } from "@rollup-api/models";
import appStore from "@store/AppStore";
import { IAttachment } from "@store/AttachmentStore";
import { EntityType } from "@store/types";
import { getDocumentIcon } from "@utilities/Icons";

import "./AttachmentThumbnail.scss";

interface IAttachmentThumbnailProps {
  attachment: IAttachment;
}

const getReportCoverImage = (id: string): string | undefined => {
  return appStore.workspaceModel?.reportsMap.get(id)?.coverUrl;
};

const AttachmentThumbnail = (props: IAttachmentThumbnailProps) => {
  const { attachment } = props;
  const [thumbnailUrl, setThumbnailUrl] = useState<string>();
  const [thumbnailError, setThumbnailError] = useState(false);

  useEffect(() => {
    const getAndSetThumbnailUrl = async () => {
      const url = await getAttachmentThumbnailUrl(attachment);
      if (!url) {
        setThumbnailError(true);
        setThumbnailUrl(undefined);
      } else {
        setThumbnailError(false);
        setThumbnailUrl(url);
      }
    };
    if (attachment.hasThumbnail) {
      getAndSetThumbnailUrl();
    } else if (isReferenceAttachment(attachment)) {
      const { referenceId, referenceEntityType } = attachment;
      const coverUrl = referenceId && referenceEntityType === EntityType.Report ? getReportCoverImage(referenceId) : undefined;
      setThumbnailUrl(coverUrl);
    }
  }, [attachment, attachment?.hasThumbnail, attachment?.version]);

  const renderImage = (url?: string) => {
    if (url) {
      return (
        <AssetImage
          className="attachment-thumbnail--image"
          src={url}
          alt="thumbnail"
          loading="lazy"
          onError={() => setThumbnailError(true)}
        />
      );
    } else if (isFileAttachment(attachment) || isIntegrationAttachment(attachment)) {
      if (attachment.status === AttachmentStatus.ConversionInProgress) {
        return <ThumbnailPlaceholder icon={<Spinner />} title="Processing" />;
      } else if (attachment.status === AttachmentStatus.ConversionFailed) {
        return <ThumbnailPlaceholder icon="error" title="Conversion failed" />;
      }
      return <ThumbnailPlaceholder icon={<AttachmentIcon attachment={attachment} height={50} />} />;
    } else if (isUrlAttachment(attachment)) {
      return (
        <div className="thumbnail-placeholder">
          <BlueprintIcon icon="link" size={52} />
        </div>
      );
    } else if (isReferenceAttachment(attachment)) {
      const iconName = getDocumentIcon(attachment.referenceEntityType);
      return iconName ? (
        <div className="thumbnail-placeholder">
          <Icon icon={iconName} size={52} />
        </div>
      ) : (
        <ThumbnailPlaceholder icon={<Spinner />} title="Unknown reference document" />
      );
    }
  };

  return (
    <div className="attachment-thumbnail">
      {thumbnailError ? <NonIdealState className="thumbnail-placeholder" icon="warning-sign" /> : renderImage(thumbnailUrl)}
    </div>
  );
};

export default observer(AttachmentThumbnail);
