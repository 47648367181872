import { InputRule } from "@tiptap/core";

import { RollupEditorType } from "@rollup-types/editor";
import appStore from "@store/AppStore";

import { focusReportBlockById, getCheckboxComponent } from "../../utils";

const regExp = /^\[] |^\[ ] /g;

const checkboxListItemInputRule = (reportBlockId: string) => {
  return new InputRule({
    find: regExp,
    handler: props => {
      const { commands } = props;
      const reportBlock = appStore.workspaceModel?.reportBlocksMap.get(reportBlockId);
      commands.setContent(getCheckboxComponent());
      reportBlock?.updateType(RollupEditorType.checklist);
      focusReportBlockById(reportBlockId);
    },
  });
};

export default checkboxListItemInputRule;
