import { BlueprintIcon } from "@ui/BlueprintIcon";
import classNames from "classnames";
import { observer } from "mobx-react";

import styles from "./StarIcon.module.scss";

interface IStarIconProps {
  filled?: boolean;
  colorful?: boolean;
  className?: string;
}

const StarIcon = (props: IStarIconProps) => {
  const { filled, colorful, className } = props;

  return (
    <BlueprintIcon
      className={classNames(styles.starIcon, className, { [styles.starIconColorful]: colorful })}
      icon={filled ? "star" : "star-empty"}
    />
  );
};

export default observer(StarIcon);
