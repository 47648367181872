import { useEffect, useMemo, useState } from "react";
import { Intent } from "@blueprintjs/core";
import { Editor } from "@tiptap/core";
import Link from "@tiptap/extension-link";
import { Extension, useEditor } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import { Text, TextVariant } from "@ui/Text";
import classNames from "classnames";
import isEmpty from "lodash/isEmpty";
import { observer } from "mobx-react";
import { clone } from "mobx-state-tree";

import PropertyTypeMenuButton from "@components/Block/BlockPropertyListItem/PropertyTypeMenuButton";
import { Button } from "@components/Button";
import { EditorContent } from "@components/EditorContent";
import { FormGroup } from "@components/FormGroup";
import { getMentionExtensions } from "@components/Reports/Editor/Extentions/Mention/MentionSuggestion";
import { Tooltip } from "@components/Tooltip";
import { updatePropertyDefinition, updatePropertyInstance } from "@rollup-api/utils";
import PropertyDefinitionDetails from "@router/components/BlockView/PropertyDetailsViewer/PropertyDefinitionDetails";
import {
  getPropertyDefinitionUpdateDto,
  getPropertyInstanceUpdateDto,
  hasChangedPropertyDefinition,
  hasChangedPropertyInstance,
} from "@router/components/BlockView/PropertyDetailsViewer/utils";
import appStore from "@store/AppStore";
import { IBlock } from "@store/BlockStore";
import { IPropertyInstance } from "@store/PropertyInstanceStore";
import { getPropertyDefinitionById } from "@utilities";
import { getBlockHierarchyRecursion } from "@utilities/Block";

import ActivityTimeline from "./ActivityTimeline";
import PropertyInstanceDetails from "./PropertyInstanceDetails";

import "./PropertyDetailsViewer.scss";

interface IPropertyDetailsViewerProps {
  propertyInstance: IPropertyInstance;
  block: IBlock;
}

enum PropertyDetailsViewerPane {
  InstanceDetails = "instanceDetails",
  DefinitionDetails = "definitionDetails",
}

const PropertyDetailsViewer = (props: IPropertyDetailsViewerProps) => {
  const { propertyInstance, block } = props;
  const [originalPropertyInstance, setOriginalPropertyInstance] = useState(() => clone(propertyInstance));
  const propertyDefinition = getPropertyDefinitionById(propertyInstance.propertyDefinition?.id || "");
  const [originalPropertyDefinition, setOriginalPropertyDefinition] = useState(() => clone(propertyDefinition));
  const [pane, setPane] = useState(PropertyDetailsViewerPane.InstanceDetails);
  const [isJustificationEditorFocused, setIsJustificationEditorFocused] = useState(false);
  const workspace = appStore.workspaceModel ?? undefined;

  const blockPath = useMemo(() => {
    const hierarchy = getBlockHierarchyRecursion(block);
    return hierarchy.join(" / ");
  }, [block]);

  const hasChanges =
    hasChangedPropertyInstance(propertyInstance, originalPropertyInstance) ||
    hasChangedPropertyDefinition(propertyDefinition, originalPropertyDefinition);

  const handleCancelUpdates = ({ editor }: { editor: Editor }) => {
    if (!propertyDefinition || !originalPropertyDefinition) return false;

    const propertyInstanceUpdateDto = getPropertyInstanceUpdateDto(propertyInstance, originalPropertyInstance);
    if (!isEmpty(propertyInstanceUpdateDto)) {
      propertyInstance.patch(propertyInstanceUpdateDto);
    }

    const propertyDefinitionUpdateDto = getPropertyDefinitionUpdateDto(propertyDefinition, originalPropertyDefinition);
    if (!isEmpty(propertyDefinitionUpdateDto)) {
      propertyDefinition.patch(propertyDefinitionUpdateDto);
    }
    editor.commands.setContent("");
    return true;
  };

  const handleConfirmUpdates = ({ editor }: { editor: Editor }) => {
    if (!propertyDefinition || !originalPropertyDefinition) return false;

    const propertyInstanceUpdateDto = getPropertyInstanceUpdateDto(originalPropertyInstance, propertyInstance);
    if (!isEmpty(propertyInstanceUpdateDto)) {
      const changeJustification = editor.getText() === "" ? undefined : editor.getHTML();
      updatePropertyInstance(propertyInstance.id, { ...propertyInstanceUpdateDto, changeJustification });
      setOriginalPropertyInstance(clone(propertyInstance));
    }

    const propertyDefinitionUpdateDto = getPropertyDefinitionUpdateDto(originalPropertyDefinition, propertyDefinition);
    if (!isEmpty(propertyDefinitionUpdateDto)) {
      const changeJustification = editor.getText() === "" ? undefined : editor.getHTML();
      updatePropertyDefinition(propertyDefinition.id, { ...propertyDefinitionUpdateDto, changeJustification });
      setOriginalPropertyDefinition(clone(propertyDefinition));
    }
    editor.commands.setContent("");
    return true;
  };

  const justificationEditor = useEditor({
    extensions: [
      StarterKit,
      Link,
      ...getMentionExtensions({ workspace }),
      Extension.create({
        addKeyboardShortcuts: () => ({
          "Mod-Escape": handleCancelUpdates,
          "Mod-Enter": handleConfirmUpdates,
        }),
      }),
    ],
    content: "",
    editable: hasChanges,
    onFocus: () => setIsJustificationEditorFocused(true),
    onBlur: () => setIsJustificationEditorFocused(false),
  });

  useEffect(() => {
    if (justificationEditor) {
      justificationEditor.setOptions({ editable: hasChanges });
    }
  }, [justificationEditor, hasChanges]);

  if (!originalPropertyDefinition || !propertyDefinition || !justificationEditor) {
    return null;
  }

  const isInstanceDetailsPane = pane === PropertyDetailsViewerPane.InstanceDetails;
  const isDefinitionDetailsPane = pane === PropertyDetailsViewerPane.DefinitionDetails;

  return (
    <div className="property-details-viewer">
      <div className="property-details-viewer--header">
        <div>
          <Text variant={TextVariant.Caption}>{blockPath}</Text>
          <div className="property-details-viewer--header-label-container">
            <PropertyTypeMenuButton
              propertyInstance={propertyInstance}
              dataType={propertyDefinition.dataType}
              onDataTypeChange={value => propertyDefinition.setDataType(value, true)}
            />
            <Text variant={TextVariant.H4}>{propertyInstance.label}</Text>
          </div>
        </div>
        <Button icon="cross" onClick={appStore.env.sidePanel.hideBlockSidePanel} e2eIdentifiers="close-property-details-viewer" minimal />
      </div>
      <div className="property-details-viewer--main">
        <div className="property-details-viewer--main-buttons">
          <Button
            className="property-details-viewer--main-button"
            onClick={() => setPane(PropertyDetailsViewerPane.InstanceDetails)}
            e2eIdentifiers="instance-details"
            intent={isInstanceDetailsPane ? "primary" : "none"}
            minimal={!isInstanceDetailsPane}
          >
            Instance
          </Button>
          <Button
            className="property-details-viewer--main-button"
            onClick={() => setPane(PropertyDetailsViewerPane.DefinitionDetails)}
            e2eIdentifiers="definition-details"
            intent={isDefinitionDetailsPane ? "primary" : "none"}
            minimal={!isDefinitionDetailsPane}
            disabled={!propertyDefinition}
          >
            Definition
          </Button>
        </div>
        {isInstanceDetailsPane && <PropertyInstanceDetails propertyInstance={propertyInstance} />}
        {isDefinitionDetailsPane && propertyDefinition && <PropertyDefinitionDetails propertyDefinition={propertyDefinition} />}
        <FormGroup
          label="Change justification"
          className={classNames({ "property-details-viewer--change-justification-disabled": !hasChanges })}
        >
          <Tooltip
            className="property-details-viewer--change-justification-tooltip"
            content="No changes have been made"
            disabled={hasChanges}
          >
            <EditorContent
              className="property-details-viewer--editor"
              e2eIdentifiers="change-justification"
              editor={justificationEditor}
              isFocused={isJustificationEditorFocused}
            />
          </Tooltip>
        </FormGroup>
        <div className="property-details-viewer--buttons-container">
          <Button
            onClick={() => handleCancelUpdates({ editor: justificationEditor })}
            e2eIdentifiers="cancel-updates"
            disabled={!hasChanges}
            minimal
            outlined
          >
            Cancel updates
          </Button>
          <Button
            onClick={() => handleConfirmUpdates({ editor: justificationEditor })}
            e2eIdentifiers="confirm-updates"
            intent={Intent.PRIMARY}
            disabled={!hasChanges}
          >
            Confirm updates
          </Button>
        </div>
      </div>
      <div className="property-details-viewer--activity-timeline">
        <ActivityTimeline propertyInstance={propertyInstance} />
      </div>
    </div>
  );
};

export default observer(PropertyDetailsViewer);
