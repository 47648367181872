import { CatalogItemCsvColumn, CatalogItemTableColumn } from "@components/CatalogItems/Table/types";

export const defaultColumnsMap: Record<CatalogItemCsvColumn, string> = {
  [CatalogItemTableColumn.Id]: "",
  [CatalogItemTableColumn.PartNumber]: "",
  [CatalogItemTableColumn.Name]: "",
  [CatalogItemTableColumn.PartType]: "",
  [CatalogItemTableColumn.Index]: "",
  [CatalogItemTableColumn.Status]: "",
  [CatalogItemTableColumn.Material]: "",
  [CatalogItemTableColumn.Weight]: "",
  [CatalogItemTableColumn.Cost]: "",
  [CatalogItemTableColumn.Quantity]: "",
  [CatalogItemTableColumn.Supplier]: "",
  [CatalogItemTableColumn.Description]: "",
  [CatalogItemTableColumn.LeadTime]: "",
  [CatalogItemTableColumn.Parent]: "",
};

export const catalogItemColumnNameMap: Record<CatalogItemCsvColumn, string> = {
  [CatalogItemTableColumn.Id]: "Id",
  [CatalogItemTableColumn.PartNumber]: "Part number",
  [CatalogItemTableColumn.Name]: "Name",
  [CatalogItemTableColumn.PartType]: "Part type",
  [CatalogItemTableColumn.Index]: "Index",
  [CatalogItemTableColumn.Status]: "Status",
  [CatalogItemTableColumn.Material]: "Material",
  [CatalogItemTableColumn.Weight]: "Weight",
  [CatalogItemTableColumn.Cost]: "Cost",
  [CatalogItemTableColumn.Quantity]: "Quantity",
  [CatalogItemTableColumn.Supplier]: "Supplier",
  [CatalogItemTableColumn.Description]: "Description",
  [CatalogItemTableColumn.LeadTime]: "Lead time",
  [CatalogItemTableColumn.Parent]: "Parent id",
};

export const CELL_WIDTH = 100;
