import { memo } from "react";
import { unitIsValid } from "@rollup-io/engineering";
import { CustomCellEditorProps } from "ag-grid-react";

import { EPropertyDefinitionsColumn } from "@components/Modeling/PropertyDefinition/constants";
import RichTextCellEditor from "@components/Table/CellEditors/RichTextCellEditor";
import SimpleTextCellEditor from "@components/Table/CellEditors/SimpleTextCellEditor";
import { IPropertyDefinition } from "@store/PropertyDefinitionStore";

import "./PropertyDefinitionEditorCell.scss";

const PropertyDefinitionEditorCell = memo((props: CustomCellEditorProps<IPropertyDefinition, string>) => {
  const { eGridCell, data, api, node, onValueChange } = props;
  const cellName = eGridCell.attributes.getNamedItem("col-id")?.value as EPropertyDefinitionsColumn;

  const getDefaultValue = () => {
    switch (cellName) {
      case EPropertyDefinitionsColumn.LABEL:
        return data.label;
      case EPropertyDefinitionsColumn.UNIT:
        return data.unit;
      case EPropertyDefinitionsColumn.GROUP:
        return data.defaultPropertyGroup;
      case EPropertyDefinitionsColumn.DESCRIPTION:
        return data.description;
      default:
        return "";
    }
  };

  const getValidationStatus = (value: string) => {
    switch (cellName) {
      case EPropertyDefinitionsColumn.LABEL:
        return !!value;
      case EPropertyDefinitionsColumn.UNIT:
        return unitIsValid(value);
      default:
        return true;
    }
  };

  const handleCommit = () => {
    api.stopEditing();
  };

  const handleCancel = () => {
    api.stopEditing(true);
  };

  const renderEditor = () => {
    switch (cellName) {
      case EPropertyDefinitionsColumn.DESCRIPTION:
        return (
          <RichTextCellEditor<IPropertyDefinition>
            node={node}
            api={api}
            defaultValue={getDefaultValue()}
            onCommit={handleCommit}
            onCancel={handleCancel}
            onValueChange={onValueChange}
          />
        );
      default:
        return (
          <SimpleTextCellEditor<IPropertyDefinition>
            singleLine
            onBlur={handleCommit}
            isValid={getValidationStatus}
            defaultValue={getDefaultValue()}
            node={node}
            api={api}
            onValueChange={onValueChange}
          />
        );
    }
  };

  return <div className="property-definition-editor-cell">{renderEditor()}</div>;
});

PropertyDefinitionEditorCell.displayName = "PropertyDefinitionEditorCell";

export default PropertyDefinitionEditorCell;
