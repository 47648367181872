import { useEffect, useMemo, useState } from "react";
import { Icon, Intent, MenuDivider } from "@blueprintjs/core";
import { observer } from "mobx-react";
import { useDebounceValue } from "usehooks-ts";

import { MenuItem } from "@components/MenuItem";
import { IntegrationItem } from "@rollup-api//models/integrations";

import { FilterableMenu } from "../FilterableMenu";

interface IIntegrationFilterableMenuProps<T extends { id: string }> {
  defaultItems?: IntegrationItem<T>[];
  onQuery: (searchString: string) => Promise<IntegrationItem<T>[]>;
  onSelect: (item: IntegrationItem<T>) => void;
  minSearchChars?: number;
  debounceTime?: number;
  placeholder?: string;
}

function IntegrationFilterableMenu<T extends { id: string }>({
  defaultItems,
  debounceTime,
  onQuery,
  onSelect,
  minSearchChars,
  placeholder,
}: IIntegrationFilterableMenuProps<T>) {
  const [items, setItems] = useState<IntegrationItem<T>[]>(defaultItems ?? []);
  const [queryString, setQueryString] = useState("");
  const [debouncedQueryString] = useDebounceValue(queryString, debounceTime ?? 500);
  const [isQuerying, setIsQuerying] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
  const characterLimit = minSearchChars ?? 3;
  useEffect(() => {
    if (debouncedQueryString.length >= characterLimit) {
      setErrorMessage(undefined);
      setIsQuerying(true);
      onQuery(debouncedQueryString)
        .then(res => {
          setItems(res);
          setIsQuerying(false);
        })
        .catch(err => {
          setIsQuerying(false);
          setErrorMessage(err.message);
          setItems(defaultItems ?? []);
          console.warn(err);
        });
    } else {
      setItems(defaultItems ?? []);
    }
  }, [debouncedQueryString, onQuery, defaultItems, characterLimit]);

  const groups = useMemo(() => {
    const groupMap = new Map<string, IntegrationItem<T>[]>();
    for (const item of items) {
      if (!groupMap.has(item.group)) {
        groupMap.set(item.group, new Array<IntegrationItem<T>>());
      }
      groupMap.get(item.group)?.push(item);
    }
    return groupMap;
  }, [items]);

  const groupItems = useMemo(() => {
    const groupElements = [];
    for (const [groupName, itemList] of groups) {
      groupElements.push(
        <>
          <MenuDivider key={groupName} title={groupName} />
          {itemList.map(item => (
            <MenuItem
              e2eIdentifiers={["filter-menu-item", groupName, item.title]}
              key={item.data.id ?? `${groupName}-${item.title}`}
              text={item.title}
              label={item.label}
              onClick={() => onSelect(item)}
              icon={item.icon ? <Icon icon={item.icon} intent={item.iconIntent} /> : undefined}
            />
          ))}
        </>
      );
    }
    return groupElements;
  }, [groups, onSelect]);

  let intent: Intent = Intent.NONE;
  let helperText = "";
  const invalidInput = !!queryString && queryString.length < characterLimit;

  if (invalidInput) {
    intent = Intent.WARNING;
    helperText = `Minimum of ${characterLimit} characters`;
  } else if (isQuerying) {
    intent = Intent.NONE;
    helperText = "Searching...";
  } else if (errorMessage) {
    intent = Intent.DANGER;
    helperText = errorMessage;
  }

  return (
    <FilterableMenu
      queryString={queryString}
      intent={intent}
      helperText={helperText}
      placeholder={placeholder}
      setQueryString={setQueryString}
    >
      {!isQuerying && groupItems}
    </FilterableMenu>
  );
}

export default observer(IntegrationFilterableMenu);
