import { Icon } from "@blueprintjs/core";
import { IconName } from "@blueprintjs/icons";

import { Text } from "src/ui/Text";

import "./InfoBox.scss";

interface InfoBoxProps {
  text: string;
  icon?: IconName;
  iconPlaceholder?: false;
  intent?: "primary" | "danger" | "warning" | "success";
}

const InfoBox = ({ text, icon, iconPlaceholder, intent }: InfoBoxProps) => {
  return (
    <div className="info-box">
      {icon && <Icon icon={icon} intent={intent} size={24} />}
      {iconPlaceholder && !icon && <div className="icon-placeholder" />}
      <Text>{text}</Text>
    </div>
  );
};

export default InfoBox;
