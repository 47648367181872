import { IconNames } from "@blueprintjs/icons";

import { GitHubDarkIcon, GitHubLightIcon, GoogleIcon, OnShapeDarkIcon, OnShapeLightIcon, SlackIcon } from "@assets/icons/thirdParty";
import WebhooksIconDark from "@assets/icons/webhooks_dark.svg?react";
import WebhooksIconLight from "@assets/icons/webhooks_light.svg?react";
import { ThemedCustomIcon } from "@components/Shared/LegacyCustomIcon/ThemedCustomIcon";
import { IntegrationProvider } from "@rollup-api/models";
import { INavLinkAsMenuItem } from "@router/navigation/NavLinkAsMenuItem";
import { IAppStore } from "@store/AppStore";
import { IIntegration } from "@store/IntegrationStore";

const renderIntegrationIcon = (provider: IntegrationProvider) => {
  switch (provider) {
    case IntegrationProvider.Google:
      return <GoogleIcon />;
    case IntegrationProvider.GitHub:
      return <ThemedCustomIcon lightIcon={<GitHubLightIcon />} darkIcon={<GitHubDarkIcon />} />;
    case IntegrationProvider.Slack:
      return <SlackIcon />;
    case IntegrationProvider.OnShape:
      return <ThemedCustomIcon lightIcon={<OnShapeLightIcon />} darkIcon={<OnShapeDarkIcon />} />;
  }
};

interface ISettingsMenuSection {
  name: string;
  navLinks: INavLinkAsMenuItem[];
}

const userSection: ISettingsMenuSection = {
  name: "User",
  navLinks: [
    {
      text: "Account settings",
      icon: IconNames.Person,
      to: "/settings",
      e2eIdentifiers: ["account-settings"],
    },
    {
      text: "Notification settings",
      icon: IconNames.Notifications,
      to: "/settings/notifications",
      e2eIdentifiers: ["notifications"],
    },
  ],
};

const getOrganizationSection = (_store: IAppStore): ISettingsMenuSection => ({
  name: "Organization",
  navLinks: [
    {
      text: "Workspaces",
      icon: IconNames.Application,
      to: "/settings/workspaces",
      e2eIdentifiers: ["workspaces"],
    },
    {
      text: "General settings",
      icon: IconNames.Office,
      to: "/settings/general",
      e2eIdentifiers: ["general"],
    },
    {
      text: "Members",
      icon: IconNames.People,
      to: "/settings/members",
      e2eIdentifiers: ["members"],
    },
    {
      text: "Imports",
      icon: IconNames.Import,
      to: "/settings/imports",
      e2eIdentifiers: ["imports"],
    },
    {
      text: "Plan and billing",
      icon: IconNames.CreditCard,
      to: "/settings/plan-and-billing",
      e2eIdentifiers: ["plan-and-billing"],
    },
    {
      text: "Execution environments",
      icon: IconNames.Console,
      to: "/settings/execution-environments",
      e2eIdentifiers: ["execution-environments"],
    },
  ],
});

const getConnectedServicesSection = (store: IAppStore): ISettingsMenuSection => ({
  name: "Connected services",
  navLinks: [
    {
      text: "Marketplace",
      icon: IconNames.GlobeNetwork,
      to: "/settings/marketplace",
      e2eIdentifiers: ["marketplace"],
    },
    {
      text: "Native Integrations",
      to: "/settings/#",
      icon: IconNames.Cog,
      nestedLinks:
        store.settingsModel?.nativeIntegrations.map((integration: IIntegration) => ({
          to: `/settings/integrations/${integration.id}`,
          disabled: !integration.isActive,
          text: integration.name,
          e2eIdentifiers: [`integration-${integration.name}`],
          icon: renderIntegrationIcon(integration.slug as IntegrationProvider),
          className: "settings-layout--native-integration",
        })) || [],
      e2eIdentifiers: ["native-integrations"],
    },
    {
      text: "Active connections",
      icon: IconNames.NEW_LINK,
      to: "/settings/active-connections",
      e2eIdentifiers: ["active-connections"],
    },
  ],
});

const developerSettingsSection: ISettingsMenuSection = {
  name: "Developer settings",
  navLinks: [
    {
      to: "/settings/api-keys",
      e2eIdentifiers: [],
      text: "API keys",
      icon: "key",
    },
    {
      to: "/settings/webhooks",
      e2eIdentifiers: [],
      text: "Webhooks",
      icon: <ThemedCustomIcon darkIcon={<WebhooksIconDark />} lightIcon={<WebhooksIconLight />} />,
    },
    {
      to: "/settings/oauth-applications",
      e2eIdentifiers: [],
      text: "OAuth Applications",
      icon: "new-link",
    },
  ],
};

export const getSettingsMenuSections = (store: IAppStore): ISettingsMenuSection[] => [
  userSection,
  getOrganizationSection(store),
  getConnectedServicesSection(store),
  developerSettingsSection,
];
