import { isValidElement } from "react";
import { Spinner, Tooltip } from "@blueprintjs/core";
import { MaybeElement } from "@blueprintjs/core/src/common/props";
import { IconName } from "@blueprintjs/icons";
import classNames from "classnames";
import { observer } from "mobx-react";

import { AnchorButton } from "@components/AnchorButton";
import { AttachmentStatus } from "@rollup-api/models";
import { IAttachment } from "@store/AttachmentStore";

import "./AttachmentStatusIcon.scss";

interface IAttachmentStatusProps {
  attachment: IAttachment;
  onClick(): void;
}

export const getAttachmentStatusData = (attachment: IAttachment): { icon: IconName | MaybeElement; tooltip: string } => {
  switch (attachment.status) {
    case AttachmentStatus.Converted:
      return { icon: "cube", tooltip: "View attachment" };
    case AttachmentStatus.ConversionPending:
      return { icon: "time", tooltip: "Conversion pending" };
    case AttachmentStatus.ConversionInProgress:
      return { icon: <Spinner size={12} />, tooltip: "Conversion in progress" };
    case AttachmentStatus.ConversionFailed:
      return { icon: "error", tooltip: "Conversion failed" };
    default:
      return { icon: "blank", tooltip: "Upload complete" };
  }
};

const AttachmentStatusIcon = (props: IAttachmentStatusProps) => {
  const { attachment, onClick } = props;

  const { icon, tooltip } = getAttachmentStatusData(attachment);

  if (icon === "blank") {
    return null;
  } else if (attachment.uploadingNewVersion) {
    return (
      <Tooltip content="Uploading a new version">
        <Spinner size={16} />
      </Tooltip>
    );
  }

  return (
    <Tooltip hoverOpenDelay={500} content={tooltip}>
      <AnchorButton
        className={classNames({ "attachment-status-icon--icon-element": isValidElement(icon) })}
        onClick={attachment.status === AttachmentStatus.Converted ? onClick : undefined}
        e2eIdentifiers={attachment.status === AttachmentStatus.Converted ? "view-file" : "view-file-no-action"}
        icon={icon}
        minimal
        small
      />
    </Tooltip>
  );
};

export default observer(AttachmentStatusIcon);
