import { useEffect } from "react";
import { TransactionsQOFactory } from "@queries/TransactionsQOFactory";
import { useQuery } from "@tanstack/react-query";

import { Transaction } from "@rollup-api/models/transactions";
import { FeedStore, IFeed } from "@store/FeedStore";
import { convertTimestamp } from "@utilities";

interface IUseFetchFeedItems {
  referenceIds: string[];
  endTime?: Date;
  onFeedItems(transaction: IFeed[] | ((current: IFeed[]) => IFeed[])): void;
  onChangeEndTime(endTime?: Date): void;
}

const createFeedStoreFromTransaction = (transaction: Transaction) => {
  return FeedStore.create({ ...transaction, createdAt: convertTimestamp(transaction.createdAt) });
};

export const useFetchFeedItems = (
  params: IUseFetchFeedItems
): { isLoadingAllItems: boolean; isLoadingMoreItems: boolean; hasMoreItems?: boolean } => {
  const { referenceIds, endTime, onFeedItems, onChangeEndTime } = params;

  const { data: allFeedItems, isLoading: isLoadingAllItems } = useQuery(
    TransactionsQOFactory.createGetTransactionsQO({
      referenceIds,
    })
  );

  const {
    data: additionalFeedItems,
    isLoading: isLoadingMoreItems,
    refetch: fetchAdditionalItems,
  } = useQuery(
    TransactionsQOFactory.createGetTransactionsQO(
      {
        referenceIds,
        endTime,
      },
      { enabled: false }
    )
  );

  useEffect(() => {
    if (allFeedItems?.transactions) {
      const feedItems = allFeedItems.transactions.map(createFeedStoreFromTransaction);
      onFeedItems(feedItems);
      onChangeEndTime(undefined);
    }
  }, [allFeedItems, onChangeEndTime, onFeedItems]);

  useEffect(() => {
    if (additionalFeedItems?.transactions) {
      const feedItems = additionalFeedItems.transactions.map(createFeedStoreFromTransaction);
      onFeedItems(items => items.concat(feedItems));
    }
  }, [additionalFeedItems, onFeedItems]);

  useEffect(() => {
    if (endTime) {
      fetchAdditionalItems();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [endTime]);

  return { isLoadingAllItems, isLoadingMoreItems, hasMoreItems: additionalFeedItems?.hasMore ?? allFeedItems?.hasMore };
};
