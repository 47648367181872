import { useCallback, useEffect, useState } from "react";
import { Callout, Classes, H1, H3, InputGroup, Intent, NonIdealState, Spinner } from "@blueprintjs/core";
import classNames from "classnames";
import { observer } from "mobx-react";

import { AnchorButton } from "@components/AnchorButton";
import { Button } from "@components/Button";
import { DialogLegacy } from "@components/Dialog";
import { PublicOrgRto } from "@rollup-api/models/organizations";
import appStore from "@store/AppStore";
import { getTenantName } from "@utilities/Tenants";
import { rollupClient } from "src/core/api";

import "./SignUpDialog.scss";

enum SignUpPanelIndex {
  EnterOrganization,
  EnterEmail,
  ContactUs,
  Success,
}

export const SignUpDialog = observer(() => {
  const className = classNames("sign-up-dialog", { [Classes.DARK]: appStore.env.themeIsDark });

  const autoOrgName = getTenantName(window.location.host);
  const [panelIndex, setPanelIndex] = useState<SignUpPanelIndex>(
    autoOrgName ? SignUpPanelIndex.EnterEmail : SignUpPanelIndex.EnterOrganization
  );
  const [orgInfo, setOrgInfo] = useState<PublicOrgRto | undefined>();
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [emailInputString, setEmailInputString] = useState<string>("");
  const [orgInputString, setOrgInputString] = useState<string>("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const isValidEmail = useCallback(() => {
    if (!emailInputString) {
      return false;
    }

    // Adapted from https://stackoverflow.com/a/9204568
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailInputString.match(emailRegex)?.length;
  }, [emailInputString]);

  const getOrgInfo = async (name: string) => {
    try {
      const res = await rollupClient.auth.checkOrg(name);
      if (res.status === 200 && res.data) {
        setOrgInfo(res.data);
        setErrorMessage("");
        return;
      }
    } catch (err) {
      console.error(err);
    }
    setErrorMessage("We couldn't find your organization information");
    setPanelIndex(SignUpPanelIndex.ContactUs);
  };

  useEffect(() => {
    if (autoOrgName) {
      getOrgInfo(autoOrgName);
    }
  }, [autoOrgName]);

  const updateOrgInput = useCallback((ev: React.ChangeEvent<HTMLInputElement>) => {
    setOrgInputString(ev.currentTarget.value);
  }, []);

  const updateEmailInput = useCallback((ev: React.ChangeEvent<HTMLInputElement>) => {
    setEmailInputString(ev.currentTarget.value);
  }, []);

  const submitOrg = useCallback(() => {
    if (orgInputString) {
      getOrgInfo(orgInputString);
      setPanelIndex(SignUpPanelIndex.EnterEmail);
    }
  }, [orgInputString]);

  const submitEmail = useCallback(async () => {
    if (!emailInputString || !orgInfo) {
      return;
    }
    setIsSubmitting(true);
    try {
      const res = await rollupClient.invitations.selfInvite({ email: emailInputString, orgId: orgInfo.id });
      if (res.status === 200) {
        setErrorMessage("");
        setPanelIndex(SignUpPanelIndex.Success);
      } else {
        const reason = res.data?.message ?? "Unknown error";
        setErrorMessage(`There was a problem with your invitation: ${reason}`);
        setPanelIndex(SignUpPanelIndex.ContactUs);
      }
      setIsSubmitting(false);
    } catch (err: any) {
      const reason = err?.response?.data?.message ?? "Unknown error";
      setErrorMessage(`There was a problem with your invitation: ${reason}`);
      setPanelIndex(SignUpPanelIndex.ContactUs);
      setIsSubmitting(false);
    }
  }, [emailInputString, orgInfo]);

  // TODO move this into a separate component OrgPanel
  const renderOrgPanel = (): React.JSX.Element => (
    <>
      <div className={Classes.DIALOG_BODY}>
        <div className="panel-container org-panel">
          <>
            <p>
              Welcome to Rollup. We will need a bit more information in order to get you signed up. Please enter your organization name.
            </p>
            <InputGroup
              placeholder="Enter your organization"
              className="org-input"
              value={orgInputString}
              autoFocus
              onChange={updateOrgInput}
              onKeyDown={ev => {
                if (orgInputString && ev.key === "Enter") {
                  submitOrg();
                }
              }}
            />
          </>
        </div>
      </div>
      <div className={Classes.DIALOG_FOOTER}>
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          <Button onClick={() => setPanelIndex(SignUpPanelIndex.ContactUs)} e2eIdentifiers="contact-us">
            I don't know
          </Button>
          <Button disabled={!orgInputString} onClick={submitOrg} e2eIdentifiers="submit-org">
            Next
          </Button>
        </div>
      </div>
    </>
  );

  // TODO move this into a separate component SignUpPanel
  const renderSignUpPanel = (): React.JSX.Element => (
    <>
      <div className={Classes.DIALOG_BODY}>
        <div className="panel-container sign-up-panel">
          {isSubmitting || !orgInfo ? (
            <NonIdealState title={isSubmitting ? "Submitting invitation" : "Loading organization information"}>
              <Spinner />
            </NonIdealState>
          ) : (
            <>
              <div className="org-logo-container">{orgInfo.branding?.logo_url && <img src={orgInfo?.branding?.logo_url} />}</div>
              <p>
                Welcome to {orgInfo.displayName}'s Rollup instance. Please enter your organization email address to request an invitation.
              </p>
              <InputGroup
                className="email-input"
                placeholder="Enter your email"
                value={emailInputString}
                disabled={isSubmitting}
                autoFocus
                onChange={updateEmailInput}
                onKeyDown={ev => {
                  if (emailInputString && ev.key === "Enter") {
                    submitEmail();
                  }
                }}
              />
            </>
          )}
        </div>
      </div>
      <div className={Classes.DIALOG_FOOTER}>
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          <Button disabled={!isValidEmail() || isSubmitting} onClick={submitEmail} e2eIdentifiers="submit-email">
            Invite
          </Button>
        </div>
      </div>
    </>
  );

  // TODO move this into a separate component ContactUsPanel
  const renderContactUsPanel = () => (
    <>
      <div className={Classes.DIALOG_BODY}>
        <div className="panel-container contact-us-panel">
          <H3>Contact us</H3>
          {errorMessage && <Callout intent={Intent.WARNING}>{errorMessage}</Callout>}
          <p>
            We don't have enough information to automatically register you. If you would still like to get access to Rollup, please fill out
            our early access form by clicking on the link below.
          </p>
          <div className="actions-list">
            <AnchorButton
              large
              intent={Intent.PRIMARY}
              minimal
              href="https://rollup-hq.typeform.com/interest-form?typeform-source=app"
              e2eIdentifiers="apply-for-early-access"
            >
              Apply for early access
            </AnchorButton>
          </div>
        </div>
      </div>
      <div className={Classes.DIALOG_FOOTER}>
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          <Button onClick={appStore.ui.hideSignUpDialog} e2eIdentifiers="hide-sign-up-dialog">
            Close
          </Button>
        </div>
      </div>
    </>
  );

  // TODO move this into a separate component SuccessPanel
  const renderSuccessPanel = (): React.JSX.Element => (
    <>
      <div className={Classes.DIALOG_BODY}>
        <div className="panel-container">
          <H1>Invitation sent</H1>
          <Callout intent={Intent.SUCCESS}>{`An invitation to ${orgInfo?.displayName} has been sent to ${emailInputString}`}</Callout>
        </div>
      </div>
      <div className={Classes.DIALOG_FOOTER}>
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          <Button onClick={appStore.ui.hideSignUpDialog} e2eIdentifiers="hide-sign-up-dialog">
            Close
          </Button>
        </div>
      </div>
    </>
  );

  const renderPanelBody = (): JSX.Element => {
    switch (panelIndex) {
      case SignUpPanelIndex.EnterOrganization:
        return renderOrgPanel();
      case SignUpPanelIndex.EnterEmail:
        return renderSignUpPanel();
      case SignUpPanelIndex.Success:
        return renderSuccessPanel();
      case SignUpPanelIndex.ContactUs:
      default:
        return renderContactUsPanel();
    }
  };

  return (
    <DialogLegacy
      isCloseButtonShown
      canOutsideClickClose={false}
      canEscapeKeyClose={false}
      isOpen
      title={orgInfo?.displayName ? `Join ${orgInfo.displayName} on Rollup` : "Sign up for Rollup"}
      className={className}
      onClose={appStore.ui.hideSignUpDialog}
    >
      {renderPanelBody()}
    </DialogLegacy>
  );
});
