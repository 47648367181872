import { ChangeEvent, useRef } from "react";
import { IconName, Tooltip } from "@blueprintjs/core";
import useFileUploadMessage from "@hooks/useFileUploadMessage";
import classNames from "classnames";

import { Button } from "@components/Button";
import { ALLOWED_FILE_UPLOAD_SIZE, validateFiles } from "@utilities";
import { createDataTestId, ElementType, IE2EIdentifiersParam, mergeE2EIdentifiers } from "@utilities/E2EUtils";

import "./UploadFileButton.scss";

type TUploadFileButtonProps = IE2EIdentifiersParam & {
  onChange?: ((file: FileList) => void) | ((file: FileList) => Promise<void>);
  text?: string;
  active?: boolean;
  multiple?: boolean;
  icon?: IconName;
  className?: string;
  large?: boolean;
};

export const UploadFileButton = (props: TUploadFileButtonProps) => {
  const { text, onChange, icon, active, multiple, className, large, e2eIdentifiers } = props;
  const { showSizeErrorMessage, showFormatErrorMessage } = useFileUploadMessage();

  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleFileInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.currentTarget?.files?.length) {
      const allowedFiles = validateFiles(
        e.currentTarget?.files,
        [],
        ALLOWED_FILE_UPLOAD_SIZE,
        showSizeErrorMessage,
        showFormatErrorMessage
      );
      onChange?.(allowedFiles);
    }

    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  return (
    <Tooltip content="Upload attachment" position="top" hoverOpenDelay={400}>
      <div className="upload-file-button">
        <input
          ref={fileInputRef}
          multiple={multiple}
          className="upload-file-button--input"
          type="file"
          onChange={handleFileInputChange}
          aria-label="Upload file"
          {...createDataTestId(ElementType.Input, mergeE2EIdentifiers("upload-file-input", e2eIdentifiers ?? []))}
        />
        <Button
          large={large}
          active={active}
          className={classNames("upload-file-button--button", className)}
          minimal
          alignText="center"
          icon={icon || "plus"}
          onClick={() => fileInputRef.current?.click()}
          e2eIdentifiers={mergeE2EIdentifiers("upload-file-button", e2eIdentifiers ?? [])}
        >
          {text ?? "Add Attachment"}
        </Button>
      </div>
    </Tooltip>
  );
};

export default UploadFileButton;
