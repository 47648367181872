import { useState } from "react";
import { Position } from "@blueprintjs/core";
import { ItemRenderer, Suggest2 } from "@blueprintjs/select";
import { observer } from "mobx-react";

import { MenuItem } from "@components/MenuItem";
import { TitledTooltip } from "@components/Shared";
import appStore from "@store/AppStore";
import { IPropertyInstance } from "@store/PropertyInstanceStore";
import { IRequirementBlock } from "@store/RequirementsStore";
import { AutoCompleteEntry, getPropertyInstanceById, validateRequirement } from "@utilities";

const AutoCompleteSuggest = Suggest2<AutoCompleteEntry>;

type Props = {
  requirementBlock: IRequirementBlock;
  className?: string;
};

const MaxAutoCompleteResults = 8;

import classNames from "classnames";

import styles from "./LinkedPropertyInput.module.scss";

const LinkedPropertyInput = (props: Props) => {
  const { requirementBlock, className } = props;
  const [inputText, setInputText] = useState<string>("");
  const linkedPropertyValue = getPropertyInstanceById(requirementBlock.linkedProperty);

  const renderAutoCompleteItem: ItemRenderer<AutoCompleteEntry> = (entry: AutoCompleteEntry, { handleClick, modifiers }) => {
    if (!entry || !modifiers.matchesPredicate) {
      return null;
    }
    const text = `${entry.path}`;
    return (
      <MenuItem
        active={modifiers.active}
        disabled={modifiers.disabled}
        label={entry.label}
        key={entry.id}
        onClick={handleClick}
        text={text}
        e2eIdentifiers={[text]}
      />
    );
  };

  const formatPropertyValue = (property: IPropertyInstance) => {
    const { numericValue, effectiveUnit } = property;
    return `${numericValue ?? null}${effectiveUnit ? ` ${effectiveUnit}` : ""}`;
  };

  const handleAutoCompleteItemSelect = (entry: AutoCompleteEntry) => {
    const linkedPropertyValue = getPropertyInstanceById(entry.id);
    if (linkedPropertyValue) {
      setInputText(formatPropertyValue(linkedPropertyValue));
      requirementBlock.setLinkedPropertyID(entry.id);
      validateRequirement(requirementBlock);
    }
  };

  const queryAutoCompleteList = (query: string, items: AutoCompleteEntry[]) => {
    const fuse = appStore.workspaceModel?.autoCompleteOptions.fuse;
    if (!query.length) {
      return items.slice(0, MaxAutoCompleteResults);
    }

    return fuse?.search(query, { limit: MaxAutoCompleteResults }).map(r => r.item) ?? [];
  };

  const inputValueRenderer = (entry: AutoCompleteEntry) => {
    const linkedPropertyValue = getPropertyInstanceById(entry.id);
    return linkedPropertyValue ? formatPropertyValue(linkedPropertyValue) : (entry.queryString ?? "");
  };

  return (
    <TitledTooltip placement={Position.BOTTOM} title={linkedPropertyValue?.path} disabled={!linkedPropertyValue}>
      <AutoCompleteSuggest
        className={classNames(styles.linkedPropertyInput, className)}
        items={appStore.workspaceModel?.autoCompleteOptions.entries || []}
        itemsEqual={(a, b) => a.path === b.path && a.label === b.label}
        itemRenderer={renderAutoCompleteItem}
        onItemSelect={handleAutoCompleteItemSelect}
        itemListPredicate={queryAutoCompleteList}
        inputValueRenderer={inputValueRenderer}
        selectedItem={appStore.workspaceModel?.autoCompleteOptions.entries.find(
          (entry: AutoCompleteEntry) => entry.id === requirementBlock.linkedProperty
        )}
        noResults={<MenuItem disabled text="No results." e2eIdentifiers="no-results" />}
        fill
        disabled={requirementBlock.locked}
        popoverProps={{
          minimal: true,
          position: "bottom-left",
          popoverClassName: "max-menu-class",
        }}
        inputProps={{
          placeholder: "Link property...",
          asyncControl: true,
        }}
        query={inputText}
        resetOnQuery
        resetOnClose={false}
        resetOnSelect={false}
      />
    </TitledTooltip>
  );
};

export default observer(LinkedPropertyInput);
