import { useRef } from "react";
import { Intent } from "@blueprintjs/core";
import { SyntheticListenerMap } from "@dnd-kit/core/dist/hooks/utilities";
import { BlueprintIcon } from "@ui/BlueprintIcon";
import classNames from "classnames";
import { observer } from "mobx-react";

import { Button } from "@components/Button";
import { IPopoverRefType, Popover } from "@components/Popover";
import appStore from "@store/AppStore";
import type { IBlock } from "@store/BlockStore";
import type { IStatusDefinition } from "@store/StatusDefinitionStore";

import { STATUS_MENU_ITEMS } from "./constants";
import StatusDefinitionLabelEditor from "./StatusDefinitionLabelEditor";
import { StatusDefinitionMenu } from "./StatusDefinitionMenu";
import StatusInstanceEditor from "./StatusInstanceEditor";
import StatusInstanceNavigation from "./StatusInstanceNavigation";

import "./ProjectManagementStatusGroupItem.scss";

/** Type defs. */
type ProjectManagementStatusGroupItemProps = {
  block?: IBlock | null;
  statusDefinition?: IStatusDefinition;
  disabled?: boolean;
  isDragging?: boolean;
  dragListeners?: SyntheticListenerMap;
  readOnly?: boolean;
  gridClassName?: string;
};

/** Main function. */
function ProjectManagementStatusGroupItem({
  block,
  statusDefinition,
  disabled,
  isDragging,
  dragListeners,
  readOnly,
  gridClassName,
}: ProjectManagementStatusGroupItemProps) {
  const popoverRef = useRef<IPopoverRefType>(null);

  if (!statusDefinition || !block) {
    return null;
  }

  const statusInstance = block.validatedStatusInstances.find(i => i.statusDefinition === statusDefinition);
  const statusTypeIcon = STATUS_MENU_ITEMS.find(({ statusType }) => statusDefinition.type === statusType)?.icon;

  const renderDragHandleMenu = () => {
    if (readOnly) {
      return null;
    }

    return (
      <div>
        <Popover
          disabled={disabled}
          ref={popoverRef}
          placement="bottom-start"
          content={
            <StatusInstanceNavigation
              popoverRef={popoverRef}
              block={block}
              statusDefinition={statusDefinition}
              statusInstance={statusInstance}
            />
          }
        >
          <Button
            {...dragListeners}
            disabled={disabled}
            minimal
            intent={Intent.NONE}
            small
            icon="drag-handle-vertical"
            e2eIdentifiers="open-menu"
          />
        </Popover>
      </div>
    );
  };

  const renderStatusType = () => {
    if (readOnly) {
      return <BlueprintIcon icon={statusTypeIcon} />;
    }

    return (
      <Popover
        disabled={disabled}
        placement="bottom-start"
        content={
          <StatusDefinitionMenu
            statusDefinition={statusDefinition}
            onTypeClick={statusType => appStore.workspaceModel?.migrateStatusType(statusDefinition, statusType)}
          />
        }
      >
        <Button
          disabled={disabled}
          small
          minimal
          className="pms-group-item--icon"
          icon={statusTypeIcon}
          e2eIdentifiers="open-status-definition-menu"
        />
      </Popover>
    );
  };

  return (
    <div className={classNames("dnd-drag-handle-item status-item-wrapper", { ["pms-group-item--dragging"]: isDragging })}>
      <div className={classNames("status-item-grid", gridClassName)}>
        {/* 1. Section: Actions-buttons/menus. */}
        <div className="flex gap-0.5">
          {renderDragHandleMenu()}
          {renderStatusType()}
        </div>

        {/* 2. Section: StatusDefinition-label/editable. */}
        {readOnly ? (
          <div className="status-item--label">{statusDefinition.label}</div>
        ) : (
          <div className="status-item-label-input">
            <div className="status-item-label-effects">
              <StatusDefinitionLabelEditor disabled={disabled} statusDefinition={statusDefinition} />
            </div>
          </div>
        )}

        {/* 3. Section: StatusInstance/editable. */}
        <div className="status-item-value-input">
          <StatusInstanceEditor disabled={disabled} statusDefinition={statusDefinition} block={block} readOnly={readOnly} />
        </div>
      </div>
    </div>
  );
}

/** Exports. */
export type { ProjectManagementStatusGroupItemProps };
export default observer(ProjectManagementStatusGroupItem);
