import { MouseEventHandler, useCallback, useEffect, useMemo, useRef, useState, WheelEventHandler } from "react";
import { ButtonGroup, Colors, NonIdealState, ResizeEntry, ResizeSensor, Spinner } from "@blueprintjs/core";
import classNames from "classnames";
import { observer } from "mobx-react";
import { useMap } from "usehooks-ts";

import { Button } from "@components/Button";
import { BoardInteractionState, BoardLoadingState, LayerState } from "@components/Modeling/ModelingFrame/BoardViewer/types";
import ModelFrameToolbar from "@components/Modeling/ModelingFrame/ModelFrameToolbar";
import Pane from "@router/components/Panes/Pane";
import appStore from "@store/AppStore";
import { IAttachment } from "@store/AttachmentStore";
import { IBlock } from "@store/BlockStore";
import { FeatureFlag } from "@store/FeatureFlagsStore";
import { getColorFromOrdinal, PcbLayer } from "@utilities/BoardHelpers";

import BoardViewerLayerControls from "./BoardViewerLayerControls";

import "./BoardViewer.scss";

export interface BoardViewerProps {
  attachment: IAttachment;
  block?: IBlock;
  onGoBack?: () => void;
}

type LayerEntry = PcbLayer & { img: HTMLImageElement };

const MAX_ZOOM_LEVEL = 5;
const MIN_ZOOM_LEVEL = -2;
const ZOOM_SPEED = 0.001;
const DIMMED_OPACITY = 0.1;
const HOVERED_HIDDEN_OPACITY = 0.5;

export const BoardViewer = observer(function BoardViewer({ attachment, block }: BoardViewerProps) {
  const [center, setCenter] = useState({ x: 0, y: 0 });
  const [containerSize, setContainerSize] = useState({ width: 0, height: 0 });
  const [interactionState, setInteractionState] = useState(BoardInteractionState.Idle);
  const [logZoom, setLogZoom] = useState(0);
  const [loadingStatus, setLoadingStatus] = useState(BoardLoadingState.Loading);
  const [numLoadedLayers, setNumLoadedLayers] = useState(0);
  const [layerStatusMap, statusActions] = useMap<number, LayerState>();
  const [layerVisibilityMap, visibilityActions] = useMap<number, boolean>();
  const [hoveredLayer, setHoveredLayer] = useState<PcbLayer | null>(null);
  const [mirrored, setMirrored] = useState(false);
  const initialDragPosition = useRef<{ x: number; y: number; clientX: number; clientY: number } | null>(null);
  const timeSum = useRef<number[]>([]);

  // Offscreen canvas for rendering individual layers
  const offscreenCanvas = useMemo<HTMLCanvasElement | OffscreenCanvas>(() => {
    if (typeof OffscreenCanvas !== "undefined") {
      return new OffscreenCanvas(containerSize.width, containerSize.height);
    }
    return document.createElement("canvas");
  }, [containerSize.height, containerSize.width]);

  const layerData: PcbLayer[] = useMemo(() => {
    if (!attachment?.metadata || !Array.isArray(attachment.metadata)) {
      return [];
    }
    return (attachment.metadata.filter((l: PcbLayer) => l && l.url && l.name && l.type) ?? []).sort(
      (a: PcbLayer, b: PcbLayer) => a.ordinal - b.ordinal
    );
  }, [attachment?.metadata]);

  useEffect(() => {
    const initialLayerStates: [number, LayerState][] = layerData.map(l => [l.ordinal, LayerState.Loading]);
    const initialLayerVisibility: [number, boolean][] = layerData.map(l => [l.ordinal, !l.hidden]);
    statusActions.setAll(initialLayerStates);
    visibilityActions.setAll(initialLayerVisibility);
    // Can't add statusActions and visibilityActions to the dependency array because they are updated in the effect
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [layerData]);

  const handleLoaded = useCallback(
    (layer: PcbLayer) => {
      statusActions.set(layer.ordinal, LayerState.Loaded);
      const numLayers = layerData?.length ?? 0;
      const updatedLoadedLayerCount = numLoadedLayers + 1;
      setNumLoadedLayers(updatedLoadedLayerCount);
      setLoadingStatus(updatedLoadedLayerCount === numLayers ? BoardLoadingState.PartiallyLoaded : BoardLoadingState.FullyLoaded);
    },
    [layerData?.length, numLoadedLayers, statusActions]
  );

  const handleError = useCallback(
    (layer: PcbLayer) => {
      statusActions.set(layer.ordinal, LayerState.Error);
      setLoadingStatus(BoardLoadingState.Error);
    },
    [statusActions]
  );

  const getZoomFactor = useCallback(() => {
    return Math.pow(2.0, logZoom);
  }, [logZoom]);

  const handleDragStart: MouseEventHandler = ev => {
    initialDragPosition.current = { x: center.x, y: center.y, clientX: ev.clientX, clientY: ev.clientY };
    setInteractionState(BoardInteractionState.Dragging);
  };

  const handleDrag: MouseEventHandler = ev => {
    const initialPos = initialDragPosition.current;
    if (interactionState === BoardInteractionState.Dragging && initialPos) {
      // Improve panning by avoiding numerical errors due to additions of tiny deltas every event.
      // We calculate the delta from the initial drag position, which helps us drag the board around without drifting.
      const { clientX, clientY } = ev;
      const { x, y } = initialPos;
      const { clientX: initialClientX, clientY: initialClientY } = initialPos;
      const clientDelta = { x: clientX - initialClientX, y: clientY - initialClientY };
      const zoom = getZoomFactor();
      setCenter({ x: x + clientDelta.x / zoom, y: y + clientDelta.y / zoom });
      ev.preventDefault();
    }
  };

  const handleDragEnd = () => {
    setInteractionState(BoardInteractionState.Idle);
    initialDragPosition.current = null;
  };

  const canvasRef = useRef<HTMLCanvasElement>(null);
  const imgRef = useRef<Map<number, HTMLImageElement>>(new Map());

  // Load images once we have layer data
  useEffect(() => {
    for (const layer of layerData) {
      const img = document.createElement("img");
      if (!layer.url) {
        handleError(layer);
        continue;
      }
      img.src = layer.url;
      img.onerror = () => handleError(layer);
      img.onload = () => {
        handleLoaded(layer);
        if (!imgRef.current) {
          imgRef.current = new Map<number, HTMLImageElement>();
          imgRef.current.set(layer.ordinal, img);
        } else {
          const newMap = new Map(imgRef.current);
          newMap.set(layer.ordinal, img);
          imgRef.current = newMap;
        }
      };
    }
  }, [handleError, handleLoaded, layerData]);

  const { size: imgRefSize } = imgRef.current ?? {};

  // Get image size when first image arrives
  const imageSize = useMemo(() => {
    if (!imgRefSize) {
      return { width: 0, height: 0 };
    }
    const { width, height } = imgRef.current.values().next().value;
    return { width, height };
  }, [imgRefSize]);

  const zoomToFit = useCallback(() => {
    const fitZoom = Math.min(containerSize.width / imageSize.width, containerSize.height / imageSize.height);
    setLogZoom(Math.log2(fitZoom));
    setCenter({ x: imageSize.width / 2, y: imageSize.height / 2 });
  }, [containerSize.height, containerSize.width, imageSize.height, imageSize.width]);
  const handleZoomIn = () => {
    setLogZoom(Math.min(MAX_ZOOM_LEVEL, logZoom + 1));
  };
  const handleZoomOut = () => {
    setLogZoom(Math.max(MIN_ZOOM_LEVEL, logZoom - 1));
  };

  const handleZoom: WheelEventHandler = ev => {
    // Calculate current coordinates of the cursor in image space
    const imageCoords = clientToImageCoords(ev.clientX, ev.clientY);

    // Calculate new zoom level
    const newLogZoom = Math.min(MAX_ZOOM_LEVEL, Math.max(MIN_ZOOM_LEVEL, logZoom - ev.deltaY * ZOOM_SPEED));
    const zoom = Math.pow(2, newLogZoom);
    setLogZoom(newLogZoom);

    // Calculate new cursor coordinates in image space
    const newCoords = clientToImageCoords(ev.clientX, ev.clientY, zoom);

    // We need to shift the center of the image by the difference between the new and old cursor coordinates
    // to keep the cursor on the same center point
    const delta = { x: newCoords.x - imageCoords.x, y: newCoords.y - imageCoords.y };
    const newCenter = { x: center.x + delta.x, y: center.y + delta.y };
    setCenter(newCenter);
  };

  useEffect(() => {
    zoomToFit();
  }, [imgRef.current.size, zoomToFit]);

  const clientToImageCoords = (clientX: number, clientY: number, zoom?: number) => {
    if (!canvasRef.current) {
      return { x: 0, y: 0 };
    }

    if (zoom === undefined) {
      zoom = getZoomFactor();
    }
    const rect = canvasRef.current.getBoundingClientRect();
    return {
      x: imageSize.width / 2 + (clientX - rect.left - containerSize.width / 2) / zoom,
      y: imageSize.height / 2 + (clientY - rect.top - containerSize.height / 2) / zoom,
    };
  };

  // Transform a canvas context to the current view
  const transformContext = useCallback(
    (imageCtx: CanvasRenderingContext2D) => {
      if (!canvasRef.current) {
        return;
      }
      const scalingFactor = devicePixelRatio ?? 1;
      const { width: canvasWidth, height: canvasHeight } = canvasRef.current;
      const zoom = getZoomFactor();
      imageCtx.translate(canvasWidth / 2, canvasHeight / 2);
      imageCtx.scale(zoom * scalingFactor, zoom * scalingFactor);
      imageCtx.translate(center.x - imageSize.width / 2, center.y - imageSize.height / 2);

      if (mirrored) {
        imageCtx.scale(-1, 1);
      }
      return zoom;
    },
    [center.x, center.y, getZoomFactor, imageSize.height, imageSize.width, mirrored]
  );

  const renderBackground = useCallback(
    (imageCtx: CanvasRenderingContext2D, numStipples = 100, gap = 10) => {
      if (!canvasRef.current) {
        return;
      }
      // Do transforms
      imageCtx.save();
      transformContext(imageCtx);

      //  Render background as a series of dashed lines instead of dots
      imageCtx.strokeStyle = appStore.env.themeIsDark ? Colors.GRAY1 : Colors.GRAY5;
      const zoom = getZoomFactor();
      const dotSize = 2 / zoom;
      imageCtx.lineWidth = dotSize;
      for (let i = -numStipples / 2; i <= numStipples / 2; i++) {
        imageCtx.beginPath();
        imageCtx.setLineDash([dotSize, gap]);
        imageCtx.moveTo((-numStipples / 2) * gap - dotSize / 2, i * gap - dotSize / 2);
        imageCtx.lineTo((numStipples / 2) * gap + dotSize / 2, i * gap - dotSize / 2);
        imageCtx.stroke();
      }

      imageCtx.restore();
    },
    [canvasRef, getZoomFactor, transformContext]
  );

  const renderCanvas = useCallback(() => {
    const tStart = performance.now();
    if (!canvasRef.current || !imgRef.current?.size) {
      return;
    }

    const scalingFactor = devicePixelRatio ?? 1;

    // TODO: We could redraw at a lower res while interacting
    // if (interactionState === BoardInteractionState.Dragging) {
    //   scalingFactor /= 2;
    // }

    canvasRef.current.width = containerSize.width * scalingFactor;
    canvasRef.current.height = containerSize.height * scalingFactor;
    const { width: canvasWidth, height: canvasHeight } = canvasRef.current;

    const imageCtx = canvasRef.current.getContext("2d");
    if (!imageCtx) {
      return;
    }

    const visibleLayers: LayerEntry[] = [];
    for (const [ordinal, img] of imgRef.current) {
      const layer = layerData.find(l => l.ordinal === ordinal);
      if (layer && (hoveredLayer?.ordinal === ordinal || layerVisibilityMap.get(ordinal))) {
        visibleLayers.push({ img, ...layer });
      }
    }

    if (!visibleLayers.length) {
      imageCtx.restore();
      return;
    }

    let sortedLayers = visibleLayers.sort((a, b) => a.ordinal - b.ordinal);
    if (mirrored) {
      sortedLayers = sortedLayers.reverse();
    }

    const { width: imgWidth, height: imgHeight } = visibleLayers[0].img;

    const renderLayer = (entry: LayerEntry) => {
      const layerCtx = offscreenCanvas.getContext("2d") as CanvasRenderingContext2D;
      if (!layerCtx) {
        return;
      }

      layerCtx.canvas.width = containerSize.width * scalingFactor;
      layerCtx.canvas.height = containerSize.height * scalingFactor;
      layerCtx.clearRect(0, 0, canvasWidth, canvasHeight);
      transformContext(layerCtx);
      layerCtx.drawImage(entry.img, -imgWidth / 2, -imgHeight / 2);

      if (entry.isMonochrome) {
        layerCtx.globalCompositeOperation = "source-atop";
        layerCtx.fillStyle = getColorFromOrdinal(entry.ordinal);
        layerCtx.fillRect(-imgWidth / 2, -imgHeight / 2, imgWidth, imgHeight);
      }
      layerCtx.restore();
      imageCtx.drawImage(offscreenCanvas, 0, 0);
    };

    imageCtx.clearRect(0, 0, canvasWidth, canvasHeight);
    renderBackground(imageCtx);

    // If there is a hovered layer, we dim all other layers and render the hovered layer on top
    if (hoveredLayer) {
      // Dim all layers except the hovered one
      imageCtx.globalAlpha = DIMMED_OPACITY;
      let hoveredEntry: LayerEntry | null = null;
      for (const entry of sortedLayers) {
        if (entry.ordinal === hoveredLayer.ordinal) {
          hoveredEntry = entry;
          continue;
        }
        renderLayer(entry);
      }
      // Render the hovered layer on top
      if (hoveredEntry) {
        imageCtx.globalAlpha = layerVisibilityMap.get(hoveredLayer.ordinal) ? 1 : HOVERED_HIDDEN_OPACITY;
        renderLayer(hoveredEntry);
      }
    } else {
      // Simple ordered layer drawing
      for (const entry of sortedLayers) {
        renderLayer(entry);
      }
    }
    const tEnd = performance.now();
    const dt = tEnd - tStart;

    if (appStore.env.featureFlags.enabled(FeatureFlag.BOARD_VIEW_DEBUG)) {
      renderDebug(dt, imageCtx);
    }
  }, [
    containerSize.height,
    containerSize.width,
    hoveredLayer,
    layerData,
    layerVisibilityMap,
    mirrored,
    offscreenCanvas,
    renderBackground,
    transformContext,
  ]);

  // Debug render time, taking a moving average over 100 frames
  const renderDebug = (dt: number, imageCtx: CanvasRenderingContext2D) => {
    timeSum.current.push(dt);

    if (timeSum.current.length > 100) {
      timeSum.current.shift();
    }
    const average = timeSum.current.reduce((a, b) => a + b, 0) / timeSum.current.length;

    // Render text with background
    imageCtx.save();
    imageCtx.scale(devicePixelRatio, devicePixelRatio);
    imageCtx.font = "12px monospace";
    imageCtx.globalAlpha = 1.0;
    const text = `Render time: ${average.toFixed(2)}ms`;
    const measuredText = imageCtx.measureText(text);
    imageCtx.fillStyle = Colors.LIGHT_GRAY1;
    imageCtx.beginPath();
    imageCtx.roundRect(5, 5, measuredText.width + 10, 20, 5);
    imageCtx.fill();
    imageCtx.fillStyle = Colors.RED3;
    imageCtx.fillText(text, 10, 20);
    imageCtx.restore();
  };

  useEffect(() => {
    // Sync with display redraw
    requestAnimationFrame(renderCanvas);
  }, [logZoom, center, layerVisibilityMap, hoveredLayer, mirrored, interactionState, renderCanvas]);

  const handleResize = (entries: ResizeEntry[]) => {
    const { width, height } = entries[0].contentRect;
    if (!width || !height) {
      return;
    }

    if (canvasRef.current) {
      canvasRef.current.width = width;
      canvasRef.current.height = height;
    }
    setContainerSize({ width, height });
  };

  return (
    <div className="board-viewer">
      <BoardViewerLayerControls
        attachment={attachment}
        mirrored={mirrored}
        layerData={layerData}
        layerStatusMap={layerStatusMap}
        layerVisibilityMap={layerVisibilityMap}
        visibilityActions={visibilityActions}
        hoveredLayer={hoveredLayer}
        imgRef={imgRef}
        setMirrored={setMirrored}
        setHoveredLayer={setHoveredLayer}
      />
      <Pane
        className="hoops-viewer-pane"
        containerClassName="hoops-viewer-pane-children-container"
        topElement={<ModelFrameToolbar block={block} />}
      >
        <div
          className={classNames("board-viewer--container", { dragging: interactionState === BoardInteractionState.Dragging })}
          onWheel={handleZoom}
          onMouseDown={handleDragStart}
          onMouseMove={handleDrag}
          onMouseUp={handleDragEnd}
          onMouseLeave={handleDragEnd}
          draggable={false}
        >
          <ButtonGroup className="board-viewer--zoom-controls" vertical>
            <Button icon="zoom-in" onClick={handleZoomIn} disabled={logZoom >= MAX_ZOOM_LEVEL} e2eIdentifiers="zoom-in" />
            <Button icon="zoom-out" onClick={handleZoomOut} disabled={logZoom <= MIN_ZOOM_LEVEL} e2eIdentifiers="zoom-out" />
            <Button icon="zoom-to-fit" onClick={zoomToFit} e2eIdentifiers="zoom-to-fit" />
          </ButtonGroup>
          {loadingStatus === BoardLoadingState.Error ? (
            <NonIdealState icon="error" title="Error loading board" className="overlay" />
          ) : null}
          {loadingStatus === BoardLoadingState.Loading ? <NonIdealState icon={<Spinner />} title="Loading..." className="overlay" /> : null}
          {loadingStatus !== BoardLoadingState.Error ? (
            <ResizeSensor onResize={handleResize}>
              <div className={classNames("board-canvas", { loaded: loadingStatus !== BoardLoadingState.Loading })}>
                <canvas ref={canvasRef} style={{ width: containerSize.width, height: containerSize.height }} />
              </div>
            </ResizeSensor>
          ) : null}
        </div>
      </Pane>
    </div>
  );
});
