import { RefObject, useState } from "react";
import { Classes } from "@blueprintjs/core";
import { useEventListener } from "usehooks-ts";

type Handler = (event: MouseEvent) => void;

/**
 * Hooks.
 * This is specific to "comments". NOT FOR GENERAL USE!!!
 */

export function useOnClickOutside<T extends HTMLElement = HTMLElement>(
  ref: RefObject<T>,
  handler: Handler,
  mouseEvent: "mousedown" | "mouseup" = "mousedown"
): void {
  useEventListener(mouseEvent, event => {
    const el = ref?.current;

    // Check if click was inside a popup/modal, if yes do not invoke callback.
    const target = event.target as HTMLElement;
    const popup = target.closest(".tippy-box");
    const portal = target.closest(`.${Classes.PORTAL}`);
    const overlay = target.closest(`.${Classes.OVERLAY}`);

    // Do nothing if clicking ref's element or descendent elements
    if (!el || el.contains(event.target as Node) || popup || portal || overlay) {
      return;
    }

    handler(event);
  });
}

export function useLinkDialog() {
  const [open, setOpen] = useState(false);
  const [text, setText] = useState("");
  const [link, setLink] = useState("");
  const handleTextChange = (event: React.ChangeEvent<HTMLInputElement>) => setText(event.target.value);
  const handleLinkChange = (event: React.ChangeEvent<HTMLInputElement>) => setLink(event.target.value);
  const resetAll = () => {
    setText("");
    setLink("");
  };
  const toggleOpen = () => {
    setOpen(prev => !prev);
    resetAll();
  };

  return {
    open,
    toggleOpen,
    setOpen,
    text,
    setText,
    handleTextChange,
    link,
    setLink,
    handleLinkChange,
    resetAll,
  };
}
