export type DownloadOptions = {
  fileName?: string;
};

/**
 * Downloads resource from given URL
 */
export const downloadFromUrl = (url: string, options?: DownloadOptions) => {
  const linkElement = document.createElement("a");
  linkElement.download = options?.fileName ?? "download";
  linkElement.href = url;
  linkElement.target = "_blank";
  linkElement.click();
  linkElement.remove();
};
