import { BlueprintIcon } from "@ui/BlueprintIcon";
import classNames from "classnames";
import { observer } from "mobx-react";

import styles from "./FavoriteIcon.module.scss";

interface IFavoriteIconProps {
  isFavorite: boolean;
}

const FavoriteIcon = (props: IFavoriteIconProps) => {
  const { isFavorite } = props;

  return (
    <BlueprintIcon
      className={classNames(styles.favoriteIcon, { [styles.favoriteIconIsFavorite]: isFavorite })}
      icon={isFavorite ? "star" : "star-empty"}
    />
  );
};

export default observer(FavoriteIcon);
