import { Select } from "@blueprintjs/select";
import { SelectItem } from "@ui/Select/Item/SelectItem";
import { observer } from "mobx-react";

import { Button } from "@components/Button";
import { ExecutionEnvironment, ExecutionEnvironmentType } from "@rollup-api/models/execution-environments";

interface EnvironmentTypeSelectProps {
  environmentType: ExecutionEnvironmentType;
  availableEnvironments?: ExecutionEnvironment[];
  selectedId?: string | null;
  onChange: (id: string) => void;
}

const ExecutionEnvironmentSelect = ({ onChange, selectedId, environmentType, availableEnvironments }: EnvironmentTypeSelectProps) => {
  const filteredEnvironments: { id: string; label: string }[] = availableEnvironments?.filter(env => env.type === environmentType) ?? [];
  filteredEnvironments.push({
    id: "",
    label: "Default environment",
  });
  const selectedEnvironment = filteredEnvironments?.find(env => env.id === selectedId);

  return (
    <Select<{ id: string; label: string }>
      className="environment-type-select"
      items={filteredEnvironments ?? []}
      filterable={false}
      onItemSelect={item => onChange(item.id)}
      popoverProps={{ minimal: true }}
      itemRenderer={(item, { handleClick }) => (
        <SelectItem slug={item} key={item.id} label={item.label} onClick={handleClick} active={selectedId === item.id} />
      )}
    >
      <Button
        loading={availableEnvironments === undefined}
        alignText="left"
        text={selectedId ? (selectedEnvironment?.label ?? "Missing environment") : "Default environment"}
        rightIcon="chevron-down"
        e2eIdentifiers="select-type-button"
      />
    </Select>
  );
};

export default observer(ExecutionEnvironmentSelect);
