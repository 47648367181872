import {} from "react";
import { InputGroup } from "@blueprintjs/core";
import { observer } from "mobx-react";

import { Button } from "@components/Button";

import styles from "./HoopsTreeFilterBox.module.scss";

type Props = {
  setSearchValue: (value: string) => void;
  searchValue: string;
};

const HoopsTreeFilterBox = (props: Props) => {
  const { setSearchValue, searchValue } = props;
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => setSearchValue(event.target.value);

  const rightIcon = () => {
    if (searchValue.length > 0) {
      return <Button minimal icon="cross" onClick={() => setSearchValue("")} e2eIdentifiers="clear-search" />;
    }
    return undefined;
  };

  return (
    <div className={styles.subHeading}>
      <InputGroup rightElement={rightIcon()} value={searchValue} onChange={handleChange} fill placeholder="Start typing to filter..." />
    </div>
  );
};

export default observer(HoopsTreeFilterBox);
