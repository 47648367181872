import { useEffect, useState } from "react";
import { FormGroup, InputGroup, Label } from "@blueprintjs/core";
import { observer } from "mobx-react";

import { AnchorButton } from "@components/AnchorButton";
import { Button } from "@components/Button";
import { Switch } from "@components/Switch";
import { CreateOauthAppDto, ExtendedOauthAppModel } from "@rollup-api/models/oauthApp";
import { Webhook } from "@rollup-api/models/webhook";
import { isUrl } from "@utilities";
import { rollupClient } from "src/core/api";
import { Text, TextVariant } from "src/ui/Text";

import SettingsAddNewWebhook from "./SettingsAddNewWebhook";

import "./SettingsOAuthApplications.scss";

const emptyApp: ExtendedOauthAppModel = {
  id: "",
  label: "",
  applicationName: "",
  logoUrl: undefined,
  clientId: "",
  clientSecret: "",
  developerUrl: undefined,
  developerName: undefined,
  description: undefined,
  callbackUrls: [""],
  createdAt: "",
  updatedAt: "",
  webhook: null,
};

const emptyWebhook: Webhook = {
  id: "",
  label: "",
  url: "",
  headers: [{ key: "", value: "" }],
  dataEvents: [""],
  createdAt: "",
  updatedAt: "",
  signingSecret: "",
};

interface SettingsAddNewOAuthApplicationProps {
  onClose?: () => void;
  oAuthAppId?: string;
}

/** Main function. */
const SettingsAddNewOAuthApplication = ({ onClose, oAuthAppId }: SettingsAddNewOAuthApplicationProps) => {
  const [oAuthApp, setOAuthApp] = useState<ExtendedOauthAppModel>(emptyApp);
  const [webhook, setWebhook] = useState<Webhook>(emptyWebhook);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [createWebhook, setCreateWebhook] = useState(false);

  useEffect(() => {
    const fetchOauthApp = async (oAuthAppId: string) => {
      const oauthApp = await rollupClient.oauthApp.retrieveById(oAuthAppId);
      if (oauthApp) {
        setOAuthApp(oauthApp);
        if (oauthApp.webhook?.id) {
          setCreateWebhook(true);
        }
      }
    };
    if (oAuthAppId) {
      fetchOauthApp(oAuthAppId);
    }
  }, [oAuthAppId]);

  const resetValues = () => {
    setOAuthApp(emptyApp);
  };

  const validateForm = () => {
    return oAuthApp?.label && oAuthApp?.applicationName && oAuthApp?.callbackUrls.length > 0 && oAuthApp?.callbackUrls.every(isUrl);
  };

  const handleSubmitClicked = async () => {
    if (validateForm()) {
      const dto: CreateOauthAppDto = {
        label: oAuthApp.applicationName,
        applicationName: oAuthApp.applicationName,
        logoUrl: oAuthApp.logoUrl !== "" ? oAuthApp.logoUrl : undefined,
        developerUrl: oAuthApp.developerUrl !== "" ? oAuthApp.developerUrl : undefined,
        developerName: oAuthApp.developerName,
        description: oAuthApp.description,
        callbackUrls: [...oAuthApp.callbackUrls],
      };
      setIsSubmitting(true);
      if (oAuthAppId) {
        if (oAuthApp?.webhook) {
          if (createWebhook) {
            await rollupClient.webhooks.update(oAuthApp.webhook.id, webhook);
          } else {
            await rollupClient.webhooks.delete(oAuthApp?.webhook.id);
          }
        } else {
          if (createWebhook) {
            const newWebhook = await rollupClient.webhooks.create(webhook);
            if (newWebhook) {
              await rollupClient.oauthApp.update(oAuthAppId, { ...dto, webhookId: newWebhook.id });
            }
          } else {
            await rollupClient.oauthApp.update(oAuthAppId, dto);
          }
        }
      }
      if (!oAuthAppId) {
        if (createWebhook) {
          await rollupClient.oauthApp.extendedCreate({ application: dto, webhook: webhook });
        } else {
          await rollupClient.oauthApp.create(dto);
        }
      }
      setIsSubmitting(false);
      resetValues();
      onClose?.();
    }
  };

  const handleCallbackUrlChange = (index: number, value: string) => {
    const newUrls = [...oAuthApp.callbackUrls];
    newUrls[index] = value;
    setOAuthApp({ ...oAuthApp, callbackUrls: [...newUrls] });
  };

  const handleCallbackUrlDelete = (index: number) => {
    if (oAuthApp.callbackUrls.length === 1) {
      setOAuthApp({ ...oAuthApp, callbackUrls: [""] });
    } else {
      const newUrls = [...oAuthApp.callbackUrls];
      newUrls.splice(index, 1);
      setOAuthApp({ ...oAuthApp, callbackUrls: [...newUrls] });
    }
  };

  return (
    <div className="settings-layout--content">
      <div className="new-oauth-application">
        <Button
          large
          className="settings-layout--back"
          minimal
          icon="arrow-left"
          onClick={() => {
            onClose?.();
          }}
          fill={false}
          e2eIdentifiers="go-back"
        >
          Back
        </Button>
        <div className="settings-layout--header">
          <Text variant={TextVariant.H1}>{`${oAuthAppId ? "Edit" : "Create"} OAuth application`}</Text>
          <span />
          <Button
            large
            intent="primary"
            className="create-button"
            onClick={handleSubmitClicked}
            loading={isSubmitting}
            e2eIdentifiers="update-create"
          >
            {oAuthAppId ? "Update" : "Create"}
          </Button>
        </div>
        {oAuthAppId && (
          <>
            <Label className="new-oauth-application--label">
              Client ID
              <InputGroup large value={oAuthApp.clientId} readOnly />
            </Label>
            <Label className="new-oauth-application--label">
              Client secret
              <InputGroup large value={oAuthApp.clientSecret} readOnly />
            </Label>
          </>
        )}

        <Label className="new-oauth-application--label">
          Application name *
          <InputGroup
            large
            value={oAuthApp.applicationName}
            onChange={e => setOAuthApp({ ...oAuthApp, applicationName: e.target.value, label: e.target.value })}
            placeholder="Enter application name..."
          />
        </Label>
        <FormGroup
          className="new-oauth-application--label"
          helperText={
            oAuthApp.logoUrl &&
            !isUrl(oAuthApp.logoUrl) && (
              <Text variant={TextVariant.Caption} color="red">
                Invalid URL
              </Text>
            )
          }
        >
          <Label>
            Logo URL
            <InputGroup
              large
              value={oAuthApp.logoUrl}
              onChange={e => setOAuthApp({ ...oAuthApp, logoUrl: e.target.value })}
              placeholder="Enter logo URL..."
            />
          </Label>
        </FormGroup>
        <Label className="new-oauth-application--label">
          Developer name
          <InputGroup
            large
            value={oAuthApp.developerName}
            onChange={e => setOAuthApp({ ...oAuthApp, developerName: e.target.value })}
            placeholder="Enter developer name..."
          />
        </Label>
        <Label className="new-oauth-application--label">
          Description
          <InputGroup
            large
            value={oAuthApp.description}
            onChange={e => setOAuthApp({ ...oAuthApp, description: e.target.value })}
            placeholder="Enter description name..."
          />
        </Label>
        <FormGroup
          className="new-oauth-application--label"
          helperText={
            oAuthApp.developerUrl &&
            !isUrl(oAuthApp.developerUrl) && (
              <Text variant={TextVariant.Caption} color="red">
                Invalid URL
              </Text>
            )
          }
        >
          <Label>
            Developer URL
            <InputGroup
              large
              value={oAuthApp.developerUrl}
              onChange={e => setOAuthApp({ ...oAuthApp, developerUrl: e.target.value })}
              placeholder="Enter developer URL..."
            />
          </Label>
        </FormGroup>

        <div className="new-oauth-application--callbacks">
          <Label className="new-oauth-application--label">Callback URLs</Label>
          <span />
          {oAuthApp.callbackUrls.map((callbackUrl, index) => (
            <>
              <span>
                <InputGroup
                  large
                  value={callbackUrl}
                  onChange={e => handleCallbackUrlChange(index, e.target.value)}
                  placeholder="Enter callback URL..."
                />
                {callbackUrl && !isUrl(callbackUrl) && (
                  <Text variant={TextVariant.Caption} color="red">
                    Invalid URL
                  </Text>
                )}
              </span>
              <AnchorButton minimal rightIcon="trash" onClick={() => handleCallbackUrlDelete(index)} e2eIdentifiers="delete-url" />
            </>
          ))}
        </div>
        <Button
          outlined
          large
          className="add-callback-button"
          icon="plus"
          onClick={() => setOAuthApp({ ...oAuthApp, callbackUrls: [...oAuthApp.callbackUrls, ""] })}
          e2eIdentifiers="add-callback"
        >
          Add URL
        </Button>

        <div>
          <div className="webhook">
            <Switch checked={createWebhook} onChange={() => setCreateWebhook(state => !state)} e2eIdentifiers="webhook" />
            <Text variant={TextVariant.H3}>Webhook</Text>
          </div>
          <span>
            <Text variant={TextVariant.Body}>Webhooks allow you to receive HTTP push notifications on your specified URL. </Text>
            <Text variant={TextVariant.Body}>
              <a href="https://google.com">Webhook Documentation -{">"}</a>
            </Text>
          </span>
        </div>

        {createWebhook && <SettingsAddNewWebhook webhookId={oAuthApp.webhook?.id} formOnly onChange={setWebhook} />}

        <Button
          large
          intent="primary"
          className="create-button"
          onClick={handleSubmitClicked}
          loading={isSubmitting}
          e2eIdentifiers="update-create"
        >
          {oAuthAppId ? "Update" : "Create"}
        </Button>
      </div>
    </div>
  );
};

/** Exports. */
export default observer(SettingsAddNewOAuthApplication);
