import { useState } from "react";

import appStore from "@store/AppStore";
import { IPartNumberSchema } from "@store/PartNumberSchemaStore";

const useCatalogItemPartNumber = (defaultValue: string) => {
  const [partNumber, setPartNumber] = useState(defaultValue);
  const [partNumberSchemaId, setPartNumberSchemaId] = useState<string>();

  const handleSetFromSchema = (schema: IPartNumberSchema) => {
    const partNumberPreview = schemaToPreview(schema.schema, schema.leadingZeroes || 0, schema.currentCount || 0);
    setPartNumberSchemaId(schema.id);
    setPartNumber(partNumberPreview);
  };

  const schemaToPreview = (schema: string, leadingZeroes = 0, currentCount = 0): string => {
    return schema.replace("{{increment}}", `${currentCount + 1}`.padStart(leadingZeroes, "0"));
  };

  const handleAddNewSchema = (label: string, schema: string, leadingZeroes?: number) => {
    appStore.orgModel.partNumberSchemas.addPartNumberSchema(label, schema, leadingZeroes).then(newSchema => {
      if (newSchema) {
        const partNumberPreview = schemaToPreview(schema, leadingZeroes);
        setPartNumberSchemaId(newSchema.id);
        setPartNumber(partNumberPreview);
      }
    });
  };

  const handleSetFromString = (newPartNumber: string) => {
    setPartNumberSchemaId(undefined);
    setPartNumber(newPartNumber);
  };

  const handleDeleteSchema = (id: string) => {
    if (id === partNumberSchemaId) {
      setPartNumberSchemaId(undefined);
    }
    appStore.orgModel.partNumberSchemas.deletePartNumberSchema(id);
  };

  return {
    handleSetFromSchema,
    handleDeleteSchema,
    handleAddNewSchema,
    handleSetFromString,
    partNumber,
    setPartNumber,
    partNumberSchemaId,
  };
};

export default useCatalogItemPartNumber;
