import { NonIdealState } from "@blueprintjs/core";
import { useOnMount } from "@hooks/useOnMount";
import classNames from "classnames";
import { observer } from "mobx-react";
import { useIsMounted } from "usehooks-ts";

import { BlockPropertyListItem } from "@components/Block/BlockPropertyListItem";
import PropertyGroup from "@components/Modeling/ModelingFrame/ModelBlock/Properties/PropertyGroup";
import CreateNewInput from "@components/Shared/CreateNewInput/CreateNewInput";
import { SortableLinkedLists } from "@components/Shared/SortableLinkedLists";
import { TItemRendererArgs } from "@components/Shared/SortableLinkedLists/Components/Item";
import { InputBox } from "@router/components/BlockView/BlockViewTabs/components/InputBox/InputBox";
import appStore from "@store/AppStore";
import { IBlock, UNGROUPED_ID } from "@store/BlockStore";
import { IPropertyGroup } from "@store/PropertyGroupStore";
import { Text, TextVariant } from "src/ui/Text";

export type TContainerProps = {
  isDragging: boolean;
  handleProps: any;
  id: string;
  children: any;
  disabled?: boolean;
};

export type PropertyContainerProps = {
  block: IBlock;
  showDescription?: boolean;
  onGroupAddProperty?: (label: string, id?: string, groupId?: string) => Promise<boolean>;
};

/**
 * Contains ALL the properties for a given Model
 */
const PropertyContainer = ({ block, showDescription, onGroupAddProperty }: PropertyContainerProps) => {
  const isMounted = useIsMounted();

  useOnMount(() => {
    block.fetchPropertyComments();
  });

  const handleItemDragEnd = (sourceId: string, targetId: string, groupId: string) => {
    const propertyInstance = block.validatedPropertyInstances.find(p => p.id === sourceId);

    // move inside same group
    if ((groupId === UNGROUPED_ID && !propertyInstance?.effectivePropertyGroup) || groupId === propertyInstance?.effectivePropertyGroup) {
      block.movePropertyInstance(sourceId, targetId);
      return;
    }

    // move to another group
    // to ungrouped list
    if (groupId === UNGROUPED_ID) {
      propertyInstance?.clearGroup();

      // if ungrouped list is empty do not call reorder
      if (targetId !== UNGROUPED_ID) {
        block?.movePropertyInstance(sourceId, targetId);
      }
      return;
      // to grouped list
    } else {
      propertyInstance?.setGroup(groupId);

      // if grouped list has items
      if (groupId !== targetId) {
        block?.movePropertyInstance(sourceId, targetId, true, groupId);
      }
    }
  };

  const allowedProps = appStore.workspaceModel?.propertyDefinitionAutoComplete?.map(e => ({
    ...e,
    disabled: !!block.validatedPropertyInstances.find(i => i.propertyDefinition?.id === e.id),
  }));

  const renderInput = (propertyGroup: IPropertyGroup) => {
    if (!onGroupAddProperty) {
      return null;
    }

    return (
      <InputBox placeholder={StaticText.group.inputs.property.title}>
        <div className="input-wrapper">
          <CreateNewInput
            placeholder={StaticText.group.inputs.property.placeholder}
            tryCreate={(label, id) => onGroupAddProperty(label, id, propertyGroup.id)}
            disabled={!block.replicated}
            autocompleteEntries={allowedProps}
            inputProps={{ leftIcon: null, autoFocus: true }}
          />
        </div>
      </InputBox>
    );
  };

  const handleRenderContainer = (containerProps: TContainerProps) => {
    const { isDragging, handleProps, id, children } = containerProps;

    if (id === UNGROUPED_ID) {
      return <div className="property-list--orphan">{children}</div>;
    }

    const propertyGroup: IPropertyGroup | undefined = block.propertyGroups?.find(p => p.id === id);

    if (propertyGroup) {
      return (
        <PropertyGroup
          isDragging={isDragging}
          dragListeners={handleProps}
          block={block}
          group={propertyGroup}
          headerElement={renderInput(propertyGroup)}
        >
          <div className="property-list--nested-child">{children}</div>
        </PropertyGroup>
      );
    }

    return null;
  };

  const handleRenderItem = (args: TItemRendererArgs) => {
    const { dragging, listeners, value, handleProps } = args;
    const propertyInstance = block.validatedPropertyInstances.find(p => p.id === value);
    const newInstance = isMounted() && block.recentlyAddedProperty === propertyInstance;

    if (propertyInstance) {
      return (
        <BlockPropertyListItem
          dragListeners={{ ...listeners, ...handleProps }}
          isDragging={dragging}
          propertyInstance={propertyInstance}
          block={block}
          showDescription={showDescription}
          wrapperProps={{
            className: classNames(newInstance && "new-instance"),
          }}
          enableWrappingOnFocus
        />
      );
    }

    return null;
  };

  const renderPlaceHolder = (containerId: string, isActive?: boolean) => {
    if (containerId === UNGROUPED_ID) {
      return (
        <div className="property-list--placeholder pt-2">
          <NonIdealState title={isActive ? "Drop item here to ungroup" : ""} />
        </div>
      );
    }

    return (
      <div className="property-list--group-placeholder">
        <Text variant={TextVariant.Caption}>{StaticText.group.empty.placeholder}</Text>
      </div>
    );
  };

  if (Object.values(block.groupedProperties).every(v => !v.length)) {
    return null;
  }

  return (
    <SortableLinkedLists
      noGap
      placeholder={renderPlaceHolder}
      onContainerDragEnd={block.movePropertyGroup}
      onItemDragEnd={handleItemDragEnd}
      renderItem={handleRenderItem}
      disabledContainers={[UNGROUPED_ID]}
      renderContainer={handleRenderContainer}
      items={block.groupedProperties}
    />
  );
};

const StaticText = {
  group: {
    empty: {
      placeholder: "This group is empty",
    },
    inputs: {
      property: {
        title: "Add property",
        placeholder: "Add new property",
      },
    },
  },
};

export default observer(PropertyContainer);
