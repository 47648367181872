export enum TextVariant {
  H1 = "h1",
  H2 = "h2",
  H3 = "h3",
  H4 = "h4",
  H5 = "h5",
  Label = "label",
  Caption = "caption",
  Code = "code",
  CodeCaps = "code-caps",
  Value = "value",
  Button1 = "button-1",
  Button1Underline = "button-1-underline",
  Button2 = "button-2",
  Button2Underline = "button-2-underline",
  Body = "body",
  BodyDimmed = "body-dimmed",
  ReportTitle = "report-title",
  ReportH1 = "report-h1",
  ReportH2 = "report-h2",
  ReportH3 = "report-h3",
  ReportH4 = "report-h4",
  ReportH5 = "report-h5",
  ReportH6 = "report-h6",
  ReportBody = "report-body",
  ReportCaption = "report-caption",
}

export enum TextColor {
  Active = "var(--Tokens-text-active)",
  Disabled = "var(--Tokens-text-disable)",
  Error = "var(--Tokens-text-error)",
  Link = "var(--Tokens-text-link)",
  Mutedplaceholder = "var(--Tokens-text-mutedplaceholder)",
  Primary = "var(--Tokens-text-primary)",
  Secondary = "var(--Tokens-text-secondary)",
  StaticBlack = "var(--Tokens-text-static-black)",
  StaticWhite = "var(--Tokens-text-static-white)",
  Success = "var(--Tokens-text-success)",
  Warning = "var(--Tokens-text-warning)",
}
