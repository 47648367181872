import { isVercel } from "@rollup-api/api/auth";

export function getTenantName(host: string) {
  if (!host) {
    return "";
  }

  if (host.includes("localhost") || isVercel(host)) {
    return "";
  }
  const prefix = host?.split(".")?.[0] ?? "";
  if (prefix === "app") {
    return "";
  }
  return prefix;
}
