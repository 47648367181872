import { JSX } from "react";
import { ButtonProps, PopoverProps } from "@blueprintjs/core";
import classNames from "classnames";
import { observer } from "mobx-react";

import { PopupMenu } from "@components/Shared/PopupMenu";
import { IE2EIdentifiersParam, mergeE2EIdentifiers } from "@utilities/E2EUtils";

import styles from "./MoreOptionsMenu.module.scss";

interface IMoreOptionsMenuProps extends IE2EIdentifiersParam {
  content: string | JSX.Element;
  small?: boolean;
  className?: string;
  buttonProps?: ButtonProps;
  popoverProps?: PopoverProps;
}

const MoreOptionsMenu = (props: IMoreOptionsMenuProps) => {
  const { content, small, className, buttonProps, popoverProps, e2eIdentifiers } = props;
  return (
    <PopupMenu
      className={classNames(styles.moreOptionsMenu, className)}
      content={content}
      buttonProps={{
        minimal: true,
        icon: "more",
        ...buttonProps,
        className: classNames(styles.moreOptionsMenuBtn, buttonProps?.className, { [styles.small]: small }),
      }}
      popoverProps={popoverProps}
      e2eIdentifiers={mergeE2EIdentifiers("more-options", e2eIdentifiers)}
    />
  );
};

export default observer(MoreOptionsMenu);
