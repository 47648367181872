import { ReactFlowProvider } from "reactflow";
import { observer } from "mobx-react";
import { isAlive } from "mobx-state-tree";

import { useAppNavigate } from "@router/hooks";
import appStore from "@store/AppStore";

import FlowViewProperties from "../../Modeling/ModelingFrame/FlowView/FlowViewProperties/FlowViewProperties";
import FloatingWindow from "../FloatingWindow";

export const DependencyGraphWindow = observer(() => {
  const { activePropertyInstance, activeBlock, dependencyGraphVisible, hideDependencyGraphWindow } = appStore.env;

  const { navigateToPropertyDependency } = useAppNavigate();
  let title = "Dependency Graph";
  if (activePropertyInstance && isAlive(activePropertyInstance)) {
    title = `Dependency Graph: ${activePropertyInstance.parentBlock.label}:${activePropertyInstance.propertyDefinition?.label}`;
  } else if (activeBlock && isAlive(activeBlock)) {
    title = `Dependency Graph: ${activeBlock.path || activeBlock.label}`;
  }

  const handleMaximize = () => {
    if (activePropertyInstance) {
      navigateToPropertyDependency(activePropertyInstance.id);
      hideDependencyGraphWindow();
    }
  };

  return (
    <FloatingWindow
      minSize={{ width: 320, height: 320 }}
      defaultSize={{ width: 560, height: 560 }}
      id="dependencyGraph"
      title={title}
      className="dependency-graph-window !flex"
      open={dependencyGraphVisible}
      onMaximise={handleMaximize}
      maximizeTooltipContent="Open in digital threads view"
      onClose={hideDependencyGraphWindow}
    >
      <ReactFlowProvider>
        <FlowViewProperties />
      </ReactFlowProvider>
    </FloatingWindow>
  );
});
