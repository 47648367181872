import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ItemPredicate, ItemRenderer, Select } from "@blueprintjs/select";
import { observer } from "mobx-react";

import { Button } from "@components/Button";
import { IntegrationCallout } from "@components/IntegrationCallout";
import { MenuItem } from "@components/MenuItem";
import { IntegrationProvider, OnShapeCompany } from "@rollup-api/models/integrations";
import appStore from "@store/AppStore";
import { IIntegration } from "@store/IntegrationStore";
import { rollupClient } from "src/core/api";

import ConnectIntegrationCard from "./ConnectIntegrationCard";

import "./OnShapeSettingsInternal.scss";

const OnShapeSettingsInternal = () => {
  const { integrationId } = useParams();
  const [companiesList, setCompaniesList] = useState<Array<OnShapeCompany>>([]);
  const [loading, setLoading] = useState(false);
  const onShapeIntegrationConfig: IIntegration | undefined = appStore.settingsModel?.integrationsMap.get(integrationId || "");

  useEffect(() => {
    if (onShapeIntegrationConfig?.connection) {
      setLoading(true);
      rollupClient.integrations
        .listOnShapeCompanies()
        .then(response => setCompaniesList(response.data.items))
        .finally(() => setLoading(false));
    } else {
      setCompaniesList([]);
    }
  }, [onShapeIntegrationConfig?.connection]);

  if (!onShapeIntegrationConfig) {
    return null;
  }

  const handleCompanySelect = async (item: OnShapeCompany) => {
    if (!item) {
      return;
    }

    const setCompanyQueryResult = await rollupClient.integrations.setOnShapeCompany(item.id);
    if (setCompanyQueryResult) {
      appStore.orgModel.updateConnectionProviderMetadata(IntegrationProvider.OnShape, {
        ...onShapeIntegrationConfig.connection?.metadata,
        company: item.name,
      });
    }
  };

  const handleRenderItem: ItemRenderer<OnShapeCompany> = (company: OnShapeCompany, { modifiers, handleClick }) => {
    if (!modifiers.matchesPredicate) {
      return null;
    }

    const active = onShapeIntegrationConfig.connection?.metadata.company === company.name;
    return (
      <MenuItem
        onClick={handleClick}
        active={active}
        e2eIdentifiers="menu-item"
        key={company.id}
        roleStructure="listoption"
        text={company.name}
      />
    );
  };

  const filterItems: ItemPredicate<OnShapeCompany> = (query, item: OnShapeCompany, _index, exactMatch) => {
    const normalizedTitle = item.name.toLowerCase();
    const normalizedQuery = query.toLowerCase();

    if (exactMatch) {
      return normalizedTitle === normalizedQuery;
    } else {
      return normalizedTitle.indexOf(normalizedQuery) >= 0;
    }
  };

  return (
    <>
      {onShapeIntegrationConfig.connection && (
        <Select<OnShapeCompany>
          onItemSelect={handleCompanySelect}
          fill
          itemPredicate={filterItems}
          className="onshape-integration--select"
          disabled={loading}
          noResults={<MenuItem e2eIdentifiers="no-results" disabled text="No results" roleStructure="listoption" />}
          items={companiesList}
          popoverProps={{ minimal: true, matchTargetWidth: true }}
          itemRenderer={handleRenderItem}
        >
          <Button
            large
            alignText="left"
            fill
            e2eIdentifiers="button"
            text={onShapeIntegrationConfig.connection.metadata.company || "Select company"}
            rightIcon="double-caret-vertical"
          />
        </Select>
      )}
      <ConnectIntegrationCard
        integration={onShapeIntegrationConfig}
        description="Connect your OnShape company to get access to files and be able to use them as attachments in your workspace."
      />
      {onShapeIntegrationConfig.connection && <IntegrationCallout integration={onShapeIntegrationConfig.connection} />}
    </>
  );
};

export default observer(OnShapeSettingsInternal);
