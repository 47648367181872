export function wrapAround(value: number, min: number, max: number): number {
  const range = max - min + 1;
  return ((((value - min) % range) + range) % range) + min;
}

export const polarToCartesian = (angle: number, radius: number, size: number) => {
  const rad = (angle * Math.PI) / 180;
  return {
    x: size / 2 + radius * Math.cos(rad),
    y: size / 2 + radius * Math.sin(rad),
  };
};
