import CustomUnitsTable from "@components/Modeling/CustomUnits/CustomUnitsTable";
import Pane from "@router/components/Panes/Pane";

const CustomUnitsIndex = () => {
  return (
    <Pane>
      <CustomUnitsTable />
    </Pane>
  );
};

export default CustomUnitsIndex;
