import { EntityModelBase } from "../../shared/entityModelBase";

export enum InterfaceType {
  contact = "contact",
  fluid = "fluid",
  electrical = "electrical",
  flux = "flux",
  data = "data",
}

export class Interface extends EntityModelBase {
  public readonly parentBlock!: string;
  public readonly label?: string;
  public readonly description?: string;
  public readonly interfaceType?: InterfaceType;
}
