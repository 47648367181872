import { FocusEvent, RefObject, useEffect, useMemo } from "react";
import { Classes } from "@blueprintjs/core";
import { Classes as SelectClasses } from "@blueprintjs/select";
import { CustomCellEditorProps } from "ag-grid-react";

import { IPopoverRefType } from "@components/Popover";
import StatusTypeMultiSelectEditor from "@components/ProjectManagement/StatusTypeMultiSelectEditor";
import appStore from "@store/AppStore";
import { IBlock } from "@store/BlockStore";
import { IStatusDefinition, StatusType } from "@store/StatusDefinitionStore";

export type IStatusCellEditorParams = CustomCellEditorProps<{ block: IBlock }> & {
  projectStatusDefinition: IStatusDefinition;
};

export const EDITOR_MIN_WIDTH = 198;
export const EDITOR_MIN_HEIGHT = 48;

export const MENU_ITEM_CLASS = Classes.MENU_ITEM;
export const TAG_REMOVE = Classes.TAG_REMOVE;
export const SELECT_INPUT_CLASS = SelectClasses.MULTISELECT_TAG_INPUT_INPUT;

export const StatusTypeCellEditor = (props: IStatusCellEditorParams) => {
  const actualWidth = props.column.getActualWidth();
  const width = actualWidth < EDITOR_MIN_WIDTH ? EDITOR_MIN_WIDTH : actualWidth;
  const { node, projectStatusDefinition } = props;

  const statusDefinitionProxy = useMemo(
    () => appStore.workspaceModel?.statusDefinitions.find(i => i.id === projectStatusDefinition.id),
    [projectStatusDefinition]
  );

  useEffect(() => {
    const input = document.querySelector(`.ag-popup-editor input.${SELECT_INPUT_CLASS}`);
    (input as HTMLInputElement)?.focus();
  }, []);

  const handleBlur = (event: FocusEvent) => {
    const classList = event.relatedTarget?.classList;
    if (!classList?.contains(MENU_ITEM_CLASS) && !classList?.contains(SELECT_INPUT_CLASS) && !classList?.contains(TAG_REMOVE)) {
      props.stopEditing();
    }
  };

  const handleComponentReady = (popoverRef: RefObject<IPopoverRefType>) => {
    popoverRef.current?.setState({ isOpen: true });
  };

  return (
    <div style={{ width, minHeight: EDITOR_MIN_HEIGHT, padding: "0 8px" }} onBlur={handleBlur}>
      <StatusTypeMultiSelectEditor
        onComponentReady={handleComponentReady}
        singleSelect={statusDefinitionProxy?.type === StatusType.singleSelect}
        statusDefinition={projectStatusDefinition}
        block={node?.data?.block}
      />
    </div>
  );
};

StatusTypeCellEditor.displayName = "StatusTypeCellEditor";

export default StatusTypeCellEditor;
