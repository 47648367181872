import Text, { TextColor, TextVariant } from "@ui/Text";
import { AgGridReact } from "ag-grid-react";
import { observer } from "mobx-react";

import { Button } from "@components/Button";
import { CollapseButton } from "@components/CollapseButton";
import appStore from "@store/AppStore";
import { HoopsTreePanelState } from "@store/EnvironmentStore";
import { IHoopsNode } from "@store/HoopsViewerStore";

import styles from "./HoopsTreeExpandCollapseHeading.module.scss";

interface HoopsTreeExpandCollapseHeadingProps {
  tableRef: React.MutableRefObject<AgGridReact<IHoopsNode> | null>;
}

const HoopsTreeExpandCollapseHeading = ({ tableRef }: HoopsTreeExpandCollapseHeadingProps) => {
  const handleCollapseTreeButtonClicked = () => {
    if (appStore.env.attachmentViewer?.allCollapsed) {
      appStore.env.attachmentViewer?.setAllExpanded(true, tableRef);
    } else if (appStore.env.attachmentViewer?.allExpanded) {
      appStore.env.attachmentViewer?.setAllCollapsed(true, tableRef);
    } else {
      appStore.env.attachmentViewer?.setAllCollapsed(true, tableRef);
    }
  };

  const handleCollapseTreeSidebarButtonClicked = () => {
    appStore.env.setHoopsTreePanelState(HoopsTreePanelState.Closed);
  };

  return (
    <div className={styles.subHeading}>
      <div className={styles.treeHeadingAndCollapseButton}>
        <Button
          minimal
          icon="double-chevron-left"
          onClick={handleCollapseTreeSidebarButtonClicked}
          e2eIdentifiers="collapse-hoops-tree-button"
        />
        <Text variant={TextVariant.H5} color={TextColor.Secondary}>
          TREE
        </Text>
      </div>
      <CollapseButton onClick={handleCollapseTreeButtonClicked} isExpanded={!appStore.env.attachmentViewer?.allCollapsed} />
    </div>
  );
};

export default observer(HoopsTreeExpandCollapseHeading);
