import { Icon } from "@blueprintjs/core";
import { observer } from "mobx-react";

import { AssemblyDark, AssemblyLight, PartDark, PartLight, ProductDark, ProductLight } from "@assets/icons/blockTypes";
import { ThemedCustomIcon } from "@components/Shared/LegacyCustomIcon/ThemedCustomIcon";
import { BlockType, IBlock } from "@store/BlockStore";

type Props = {
  block: IBlock;
  className?: string;
  large?: boolean;
  onClick?: () => void;
};

const BlockTypeIcon = (props: Props) => {
  const { block, onClick, className, large } = props;

  if (!block.type.length) {
    return null;
  }

  if (block.type.includes(BlockType.Product)) {
    return (
      <ThemedCustomIcon large={large} onClick={onClick} className={className} lightIcon={<ProductLight />} darkIcon={<ProductDark />} />
    );
  }

  if (block.type.includes(BlockType.Subsystem)) {
    return <Icon size={large ? 24 : 16} onClick={onClick} className={className} icon="data-lineage" />;
  }

  if (block.type.includes(BlockType.Assembly)) {
    return (
      <ThemedCustomIcon large={large} onClick={onClick} className={className} lightIcon={<AssemblyLight />} darkIcon={<AssemblyDark />} />
    );
  }

  return <ThemedCustomIcon large={large} onClick={onClick} className={className} lightIcon={<PartLight />} darkIcon={<PartDark />} />;
};

export default observer(BlockTypeIcon);
