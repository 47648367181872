import { Checkbox } from "@blueprintjs/core";
import { observer } from "mobx-react";

import appStore from "@store/AppStore";

import styles from "./CheckboxHeaderCell.module.scss";

const CheckboxHeaderCell = () => {
  const selectedCatalogItemsAmount = appStore.ui.selectedCatalogItemIds.length;
  const itemsAmount = appStore.orgModel.catalogItems.totalItems;
  const catalogItemIds = appStore.orgModel.catalogItems.catalogItems.map(i => i.id);
  const refIds = appStore.orgModel.catalogItems.refIds;
  const allIds = appStore.ui.showPdmFlatten ? catalogItemIds : [...catalogItemIds, ...refIds];

  const toggleSelection = () => {
    if (selectedCatalogItemsAmount) {
      appStore.ui.setSelectedCatalogItemIds([]);
    } else {
      appStore.ui.setSelectedCatalogItemIds(allIds);
    }
  };

  const getIndeterminate = (): boolean => {
    return !!selectedCatalogItemsAmount && itemsAmount > selectedCatalogItemsAmount;
  };

  const getChecked = (): boolean => !!itemsAmount && itemsAmount === selectedCatalogItemsAmount;

  return (
    <Checkbox onChange={toggleSelection} className={styles.checkboxHeaderCell} indeterminate={getIndeterminate()} checked={getChecked()} />
  );
};

export default observer(CheckboxHeaderCell);
