import {
  OrganizationSettingsModel,
  UpdateOrganizationSettingsDto,
} from "@rollup-api/models/organizationSettings/organizationSettings.model";

import { HttpClient, ParentClient } from "./client";

export class OrganizationSettings extends HttpClient {
  public constructor(parent: ParentClient) {
    super(parent);
  }

  private readonly endpoint: string = "/organizations/settings";

  public retrieve = async (): Promise<OrganizationSettingsModel | undefined> => {
    try {
      const result = await this.instance.get<OrganizationSettingsModel>(this.endpoint);

      return result.data;
    } catch (error) {
      console.error("Error updating organization settings", error);
      throw error;
    }
  };

  public update = async (dto: UpdateOrganizationSettingsDto): Promise<OrganizationSettingsModel | undefined> => {
    try {
      const result = await this.instance.patch<OrganizationSettingsModel>(this.endpoint, dto);

      return result.data;
    } catch (error) {
      console.error("Error updating organization settings", error);
      throw error;
    }
  };
}
