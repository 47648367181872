import { StatusesTable } from "@components/Modeling/StatusesTable";
import Pane from "@router/components/Panes/Pane";

/** Main function. */
function StatusesIndex() {
  return (
    <Pane>
      <StatusesTable />
    </Pane>
  );
}

/** Exports. */
export default StatusesIndex;
