import { FormEvent, KeyboardEvent } from "react";
import { useRef } from "react";
import { FormGroup, InputGroup } from "@blueprintjs/core";
import { useClickOutside } from "@hooks/useClickOutside/useClickOutside";

type UrlInputFormProps = {
  onSubmit: (url: string) => void;
  defaultValue?: string;
};

export const UrlInputForm = ({ defaultValue, onSubmit }: UrlInputFormProps) => {
  const formRef = useRef<HTMLFormElement>(null);
  const urlInputRef = useRef<HTMLInputElement>(null);

  const handleSubmit = () => {
    onSubmit(urlInputRef.current?.value ?? "");
  };

  useClickOutside(formRef, handleSubmit);

  const handleFormSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    handleSubmit();
  };

  const fixReportPageEventBubbling = (e: KeyboardEvent<HTMLInputElement>) => e.stopPropagation();

  return (
    <form onSubmit={handleFormSubmit}>
      <FormGroup label="Add link" labelFor="linkInput" className="my-0 p-1">
        <InputGroup
          id="linkInput"
          leftIcon="link"
          inputRef={urlInputRef}
          defaultValue={defaultValue}
          onKeyUp={fixReportPageEventBubbling}
          placeholder="Enter a valid url..."
          autoFocus
        />
      </FormGroup>
      <input type="submit" hidden />
    </form>
  );
};
