import { Menu } from "@blueprintjs/core";
import { observer } from "mobx-react";

import { CellDropdown } from "@components/CellDropdown";
import { MenuItem } from "@components/MenuItem";
import { FunctionalType } from "@rollup-api/models/requirementBlock";
import { IRequirementBlock } from "@store/RequirementsStore";

type Props = {
  requirementBlock: IRequirementBlock;
};

const FunctionalTypeCell = (props: Props) => {
  const { requirementBlock } = props;

  const getMenuContent = () => (
    <Menu>
      {Object.values(FunctionalType).map(type => (
        <MenuItem
          key={type}
          text={type}
          active={requirementBlock.functionalType === type}
          onClick={() => requirementBlock.setFunctionalType(type)}
          e2eIdentifiers={type}
        />
      ))}
    </Menu>
  );

  return (
    <CellDropdown
      text={requirementBlock.functionalType}
      disabled={requirementBlock.locked}
      content={getMenuContent()}
      e2eIdentifiers={requirementBlock.functionalType}
    />
  );
};

export default observer(FunctionalTypeCell);
