import classNames from "classnames";
import { observer } from "mobx-react";

import "./DragHandle.scss";

interface IDragHandleProps {
  vertical?: boolean;
  className?: string;
}

const DragHandle = (props: IDragHandleProps) => {
  return <div className={classNames("drag-handle", { "drag-handle--vertical": props.vertical }, props.className)} />;
};

export default observer(DragHandle);
