import { Menu } from "@blueprintjs/core";
import { observer } from "mobx-react";

import { CellDropdown } from "@components/CellDropdown";
import { MenuItem } from "@components/MenuItem";
import { CatalogItemSource } from "@rollup-api/models/catalogItem/catalogItemDtos";

import { TCellRendererProps } from "../types";

import styles from "./SourceCell.module.scss";

const SourceCell = (props: TCellRendererProps) => {
  const catalogItem = props.data?.catalogItem;
  const source = catalogItem?.source;

  if (!catalogItem) {
    return null;
  }

  const getMenuContent = () => (
    <Menu>
      {Object.values(CatalogItemSource).map(s => (
        <MenuItem key={s} text={s} active={source === s} onClick={() => catalogItem?.setSource(s)} e2eIdentifiers={s} />
      ))}
    </Menu>
  );

  return (
    <CellDropdown
      buttonClassName={styles.sourceCell}
      text={source || CatalogItemSource.InHouse}
      content={getMenuContent()}
      e2eIdentifiers="catalog-item-source"
    />
  );
};

export default observer(SourceCell);
