import { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { observer } from "mobx-react";

import appStore from "@store/AppStore";

import ImportsTable from "./Table/ImportsTable";

import "./ImportsFrame.scss";

/** Main function. */
function ImportsFrame() {
  const imports = appStore.workspaceModel?.ownImports || [];
  const [loading, setLoading] = useState(false);

  const handleLoadImports = (loadMore?: boolean) => {
    setLoading(true);
    appStore.workspaceModel?.fetchImports(loadMore).finally(() => setLoading(false));
  };

  useEffect(() => {
    handleLoadImports();
  }, []);

  return (
    <div className="imports-frame">
      <ImportsTable
        allowCreate
        loading={loading}
        title={
          <div className="imports-frame--title">
            <h1>Workspace imports</h1>
            <NavLink className="imports-frame--link" to="/settings/imports">
              Open organization imports
            </NavLink>
          </div>
        }
        imports={imports}
        onLoadMore={() => handleLoadImports(true)}
      />
    </div>
  );
}

/** Exports. */
export default observer(ImportsFrame);
