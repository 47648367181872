import { Instance, types } from "mobx-state-tree";

export const InboxSettingsStore = types
  .model("InboxSettings", {
    selectedWorkspaceId: types.optional(types.string, ""),
  })
  .actions(self => ({
    setSelectedWorkspaceId(id: string) {
      self.selectedWorkspaceId = id;
    },
  }));

export interface IInboxSettings extends Instance<typeof InboxSettingsStore> {}
