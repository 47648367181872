import { ReactNode } from "react";
import classNames from "classnames";
import { observer } from "mobx-react";

import { createDataTestId, ElementType } from "@utilities/E2EUtils";

import "./SlidingPanel.scss";

interface ISlidingPanelProps {
  width: number;
  isOpen: boolean;
  children?: ReactNode | ReactNode[];
  className?: string;
}

const SlidingPanel = (props: ISlidingPanelProps) => {
  const { isOpen, width, children, className } = props;

  return (
    <div
      className={classNames("sliding-panel", className)}
      style={{ width: isOpen ? width : 0 }}
      {...createDataTestId(ElementType.SidePanel, "sliding-panel")}
    >
      {isOpen && children}
    </div>
  );
};

export default observer(SlidingPanel);
