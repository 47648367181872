import { ChangeEvent, FocusEvent, KeyboardEvent, useEffect, useRef, useState } from "react";

interface IUseInputHandlers {
  value: string;
  getValidationErrorMessage?(value: string): string;
  onCommit?(value: string): void;
  onChange?(value: string): void;
}

export const useInputHandlers = <T extends HTMLInputElement | HTMLTextAreaElement = HTMLInputElement>(params: IUseInputHandlers) => {
  const [value, setValue] = useState(params.value);
  const shouldDiscardChangesRef = useRef(false);
  const ref = useRef<T>(null);
  const error = params.getValidationErrorMessage?.(value);

  useEffect(() => {
    if (value !== params.value) {
      setValue(params.value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.value]);

  const handleKeyDown = (e: KeyboardEvent<T>) => {
    if (e.key === "Enter") {
      if (error) {
        return;
      }
      ref.current?.blur();
    } else if (e.key === "Escape") {
      shouldDiscardChangesRef.current = true;
      ref.current?.blur();
    }
  };

  const handleBlur = (e: FocusEvent<T>) => {
    if (shouldDiscardChangesRef.current) {
      shouldDiscardChangesRef.current = false;
      setValue(params.value);
      return;
    } else if (error) {
      return;
    }

    params.onCommit?.(e.target.value);
  };

  const handleChange = (e: ChangeEvent<T>) => {
    setValue(e.target.value);
    params.onChange?.(e.target.value);
  };

  return { value, ref, error, handleKeyDown, handleBlur, handleChange, setValue };
};
