import { Position, Tooltip } from "@blueprintjs/core";
import { observer } from "mobx-react";

import AttachmentIcon from "@components/Modeling/ModelingFrame/ModelBlock/Attachments/AttachmentIcon";
import appStore from "@store/AppStore";
import { IAttachment } from "@store/AttachmentStore";

interface IAttachmentTagProps {
  attachment: IAttachment;
  className?: string;
  labelClassName?: string;
}

const AttachmentTag = (props: IAttachmentTagProps) => {
  const { attachment, className, labelClassName } = props;

  const handleClick = () => {
    appStore.ui.setAttachmentDetails(attachment.id);
  };

  return (
    <Tooltip hoverOpenDelay={500} placement={Position.BOTTOM} content={attachment.label}>
      <div className={className} onClick={handleClick}>
        <AttachmentIcon attachment={attachment} iconSize={16} height={16} />
        <span className={labelClassName}>{attachment.label}</span>
      </div>
    </Tooltip>
  );
};

export default observer(AttachmentTag);
