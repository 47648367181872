import { Outlet, useNavigate } from "react-router-dom";
import { useBool } from "@hooks/useBool/useBool";
import { useWorkspace } from "@hooks/useWorkspace";
import { observer } from "mobx-react";

import { CreateDocumentButton } from "@components/CreateDocumentButton";
import { FeatureLayout } from "@components/FeatureLayout";
import ReportsSidebar from "@components/Reports/ReportsSidebar/ReportsSidebar";
import MenuPane from "@router/components/Panes/MenuPane";
import { useMenuPane } from "@router/components/Panes/useMenuPane";
import appStore from "@store/AppStore";
import { EPane } from "@store/PaneWidthStore";

import "./ReportsLayout.scss";

/** Main function. */
function ReportsLayout() {
  const navigate = useNavigate();
  const { isSubNavPaneOpen, toggleSubNavPane } = appStore.env;
  const { open, toggle, minSize, maxSize } = useMenuPane({ isOpen: isSubNavPaneOpen, toggleIsOpen: toggleSubNavPane });
  const [menuHovered, { set: hoverMenu, unset: unhoverMenu }] = useBool();
  const workspace = useWorkspace();

  const handleCreateNewReport = async () => {
    const newReport = await workspace.createReport();
    if (newReport) {
      appStore.env.setActiveReport(newReport.id);
      navigate(newReport.id);
    }
  };

  const renderCreateButton = (text = "New Page") => (
    <CreateDocumentButton text={text} onClick={handleCreateNewReport} e2eIdentifiers="create-report" />
  );

  const renderCollapsedMenuContent = () => (
    <div className="reports-layout--collapsed-content">
      {renderCreateButton("")}
      <ReportsSidebar minimal />
    </div>
  );

  return (
    <FeatureLayout
      paneWidth={appStore.env.paneWidth.subNav}
      menuHovered={menuHovered}
      dynamicPanelContent={<ReportsSidebar />}
      minSize={minSize}
      maxSize={maxSize}
      topElement={renderCreateButton()}
      onPaneWidthChange={(newSize: number) => appStore.env.paneWidth.updatePaneWidth(EPane.SUB_NAV, newSize)}
      onHoverMenu={hoverMenu}
      onUnhoverMenu={unhoverMenu}
      isSidePanelOpen={open}
    >
      <MenuPane
        open={open}
        toggle={toggle}
        title="Knowledgebase"
        topElement={renderCreateButton()}
        collapsedContent={renderCollapsedMenuContent()}
        onMouseEnter={hoverMenu}
        onMouseLeave={unhoverMenu}
      >
        <ReportsSidebar />
      </MenuPane>

      <Outlet />
    </FeatureLayout>
  );
}

/** Exports. */
export default observer(ReportsLayout);
