import { useState } from "react";
import { Intent } from "@blueprintjs/core";
import { useWorkspace } from "@hooks/useWorkspace";
import { DataSourceQOFactory } from "@queries/DataSourceQOFactory";
import { useMutation, useQuery } from "@tanstack/react-query";
import { observer } from "mobx-react-lite";

import { Button } from "@components/Button";
import { DataSourceIntegration, ECreateDataSource } from "@components/DataSources/AddDataSource/types";
import { IUseDataSourceStepsParams, useDataSourceSteps } from "@components/DataSources/AddDataSource/useDataSourceSteps";
import { ColumnMultiStep } from "@components/Shared/ColumnMultiStep";
import { IStepButton } from "@components/Shared/ColumnMultiStep/StepButtons";
import { IComplexSelectOption } from "@components/Shared/Dropdown";
import { GoogleIntegrationItem, IntegrationProvider } from "@rollup-api/models";
import { DataSource } from "@rollup-api/models/data-sources/data-source.model";
import { CreateDataSourceDto, IGoogleDataSourceMetadata } from "@rollup-api/models/data-sources/data-sources.dto";
import appStore from "@store/AppStore";

import styles from "./AddDataSource.module.scss";

interface IAddDataSourceModalProps {
  onGoBack(): void;
}

const AddDataSource = (props: IAddDataSourceModalProps) => {
  const { onGoBack } = props;
  const [stepIndex, setStepIndex] = useState(0);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [dataSourceIntegration, setDataSourceIntegration] = useState<IComplexSelectOption<DataSourceIntegration>>();
  const [googleSheet, setGoogleSheet] = useState<GoogleIntegrationItem>();
  const [dataSource, setDataSource] = useState<DataSource>();
  const { id: workspaceId } = useWorkspace();
  const addDataSourceMutation = useMutation(DataSourceQOFactory.createPostQO(workspaceId));
  const { data: existingDataSources } = useQuery(DataSourceQOFactory.createGetQO(workspaceId));

  const dataSourceParams: IUseDataSourceStepsParams = {
    name,
    description,
    integration: dataSourceIntegration,
    googleSheet,
    dataSource,
    setName,
    setDescription,
    setIntegration: setDataSourceIntegration,
    setGoogleSheet,
  };
  const steps = useDataSourceSteps(dataSourceParams);
  const stepId = steps[stepIndex].id;

  const resetStepFields = (step: ECreateDataSource) => {
    switch (step) {
      case ECreateDataSource.Integration:
        setDataSourceIntegration(undefined);
        break;
      case ECreateDataSource.Custom:
        setGoogleSheet(undefined);
        break;
    }
  };

  const nextButtonDisabled = () => {
    switch (stepId) {
      case ECreateDataSource.Setup:
        return !name || existingDataSources?.some(ds => ds.label === name);
      case ECreateDataSource.Integration:
        return !dataSourceIntegration;
      case ECreateDataSource.Custom:
        if (dataSourceIntegration?.value === DataSourceIntegration.GoogleSheets) {
          return !googleSheet;
        }
        return true;
      default:
        return true;
    }
  };

  const submitDisabled = () => {
    if (dataSourceIntegration?.value === DataSourceIntegration.GoogleSheets && googleSheet) {
      return false;
    }
    return true;
  };

  const resetSteps = () => {
    setStepIndex(0);
    setName("");
    setDescription("");
    setDataSourceIntegration(undefined);
    setGoogleSheet(undefined);
  };

  const handleGoBack = () => {
    onGoBack();
    resetSteps();
  };

  const goToPrevStep = () => {
    if (stepIndex > 0) {
      const newStepIndex = stepIndex - 1;
      setStepIndex(newStepIndex);
      resetStepFields(steps[stepIndex].id);
    }
  };

  const goToNextStep = () => {
    if (stepIndex < steps.length - 1) {
      const newStepIndex = stepIndex + 1;
      setStepIndex(newStepIndex);
    }
  };

  const handleSubmit = () => {
    if (dataSourceIntegration?.value === DataSourceIntegration.GoogleSheets && googleSheet) {
      const integration = appStore.orgModel.integrations.get(IntegrationProvider.Google);
      if (integration) {
        const dataSourceDto: CreateDataSourceDto<IGoogleDataSourceMetadata> = {
          label: name,
          description: description,
          integrationId: integration.id,
          provider: IntegrationProvider.Google,
          metadata: { fileId: googleSheet.data.id, mimeType: googleSheet.data.mimeType },
        };
        addDataSourceMutation.mutate(dataSourceDto, {
          onSuccess: dataSource => {
            setDataSource(dataSource);
            goToNextStep();
          },
        });
      }
    }
  };

  const backStepButton: IStepButton = {
    label: "Back",
    icon: "arrow-left",
    onClick: goToPrevStep,
    e2eIdentifiers: "back",
    minimal: true,
  };

  const showBackButton = stepId === ECreateDataSource.Integration || stepId === ECreateDataSource.Custom;

  const getRightButton = (): IStepButton => {
    switch (stepId) {
      case ECreateDataSource.Setup:
      case ECreateDataSource.Integration:
        return {
          label: "Continue",
          intent: Intent.PRIMARY,
          e2eIdentifiers: "continue",
          disabled: nextButtonDisabled(),
          onClick: goToNextStep,
        };
      case ECreateDataSource.Custom:
        return {
          label: "Submit",
          intent: Intent.PRIMARY,
          e2eIdentifiers: "submit-data-source",
          disabled: submitDisabled(),
          onClick: handleSubmit,
        };
      case ECreateDataSource.Preview:
        return {
          label: "Finish",
          intent: Intent.PRIMARY,
          e2eIdentifiers: "finish",
          onClick: handleGoBack,
        };
    }
  };

  return (
    <div className={styles.addDataSource}>
      <Button className={styles.addDataSourceBackBtn} onClick={handleGoBack} icon="arrow-left" e2eIdentifiers="go-back" minimal>
        Back
      </Button>
      <ColumnMultiStep<ECreateDataSource>
        className={styles.addDataSourceColumnMultiStep}
        stepIndex={stepIndex}
        steps={steps}
        title="Create Data Source"
        leftButton={showBackButton ? backStepButton : undefined}
        rightButton={getRightButton()}
        isLoading={addDataSourceMutation.isPending}
      />
    </div>
  );
};

export default observer(AddDataSource);
