import { Instance, types } from "mobx-state-tree";

export const InviteNewUserStore = types
  .model("InviteNewUser", {
    visible: types.optional(types.boolean, false),
  })
  .actions(self => {
    return {
      hide() {
        self.visible = false;
      },
      show() {
        self.visible = true;
      },
    };
  });

export interface IInviteNewUserStore extends Instance<typeof InviteNewUserStore> {}
