import { InvoiceQOFactory } from "@queries/InvoiceQOFactory";
import { useQuery } from "@tanstack/react-query";
import { Text, TextVariant } from "@ui/Text";
import { GridReadyEvent } from "ag-grid-community";

import { Loading } from "@components/Loading";
import Table from "@components/Table";
import { Invoice } from "@rollup-api/models/invoices/invoice.model";
import {
  invoicesTableColDefs,
  invoicesTableDefaultColDef,
} from "@router/elements/settings/SettingsPlanAndBilling/Invoices/Invoices.colDefs";

import styles from "./Invoices.module.scss";

export const Invoices = () => {
  const { data: invoices = [], isLoading } = useQuery(InvoiceQOFactory.createGetAllQO());

  const handleGridReady = (event: GridReadyEvent<Invoice>) => {
    event.api.sizeColumnsToFit();
  };

  const renderTable = () => {
    return (
      <Table<Invoice>
        rowData={invoices}
        columnDefs={invoicesTableColDefs}
        defaultColDef={invoicesTableDefaultColDef}
        onGridReady={handleGridReady}
        rowHeight={40}
        disableAutoHeight
      />
    );
  };

  return (
    <div className={styles.invoices}>
      <Text variant={TextVariant.H3}>Invoice History</Text>
      {isLoading ? <Loading /> : renderTable()}
    </div>
  );
};
