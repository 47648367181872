import { redirect } from "react-router-dom";
import { NonIdealState } from "@blueprintjs/core";

import Pane from "@router/components/Panes/Pane";
import NavLinkAsButton from "@router/navigation/NavLinkAsButton";
import appStore from "@store/AppStore";

/** Loader. */
function appIndexLoader() {
  const mostRecentWorkspace = appStore.userModel?.mostRecentWorkspace;

  return redirect(mostRecentWorkspace ? `/workspaces/${mostRecentWorkspace}` : "/settings/workspaces");
}

/** Main function. */
function AppIndex() {
  return (
    <Pane className="component--app-index">
      <NonIdealState
        title="Home"
        description="Welcome to Rollup"
        action={
          <div className="grid grid-cols-1 gap-2">
            <NavLinkAsButton to="/settings/workspaces" icon="applications" text="Workspaces" minimal />
            <NavLinkAsButton to="/settings/members" icon="new-person" text="Members" minimal />
            <NavLinkAsButton to="/settings/feature-flags" icon="flag" text="Feature flags" minimal />
          </div>
        }
      />
    </Pane>
  );
}

/** Exports. */
export { appIndexLoader };
export default AppIndex;
