import { ReactNode } from "react";
import classNames from "classnames";
import { observer } from "mobx-react";

import { Text, TextVariant } from "src/ui/Text";

import "./BlockPreviewCard.scss";

interface IBlockPreviewCardProps {
  children: ReactNode;
  title?: string;
  className?: string;
}

const BlockPreviewCard = (props: IBlockPreviewCardProps) => {
  const { title, className, children } = props;
  return (
    <div className={classNames("block-preview-card", className)}>
      {title && <Text variant={TextVariant.H4}>{title}</Text>}
      {children}
    </div>
  );
};

export default observer(BlockPreviewCard);
