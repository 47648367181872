import { useCallback, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { Classes } from "@blueprintjs/core";
import { CellClassParams, GetRowIdParams, GridReadyEvent, IRowDragItem, RowClickedEvent } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import classNames from "classnames";
import { observer } from "mobx-react";

import { ReportTreeNodeInfo } from "@components/ReportsTree/types";
import useTreeDrag from "@components/ReportsTree/useTreeDrag";
import Table from "@components/Table";
import { updateRowsClass } from "@components/Table/utils";
import { useAppNavigate } from "@router/hooks";
import appStore from "@store/AppStore";
import { IReport } from "@store/ReportsStore";

import { reportNodeList, REPORTS_TREE_PREFIX } from "./constants";
import ReportGroupCell from "./ReportGroupCell";

import "./ReportsTree.scss";

function ReportsTree() {
  const tableRef = useRef<AgGridReact<ReportTreeNodeInfo> | null>(null);
  const { reportId } = useParams();
  const { navigateToReport } = useAppNavigate();
  const { onRowDragEnd, onRowDragLeave, onRowDragMove } = useTreeDrag();

  useEffect(() => {
    return () => {
      appStore.env.setReportsTreeGridApi(undefined);
    };
  }, []);

  useEffect(() => {
    updateRowsClass(reportId, REPORTS_TREE_PREFIX);
  }, [reportId]);

  const handleTreeScroll = () => updateRowsClass(reportId, REPORTS_TREE_PREFIX);

  const handleRowDataUpdated = useCallback(() => {
    updateRowsClass(reportId, REPORTS_TREE_PREFIX);
  }, [reportId]);

  const handleGridReady = useCallback((e: GridReadyEvent<ReportTreeNodeInfo>) => {
    appStore.env.setReportsTreeGridApi(e.api);
  }, []);

  const onDestroyed = () => appStore.env.setReportsTreeGridApi(undefined);

  const handleRowClicked = useCallback(
    (event: RowClickedEvent<ReportTreeNodeInfo>) => {
      const target = event.event?.target as HTMLElement;
      const isButtonOrPopover = target.classList.contains(Classes.BUTTON) || target.classList.contains("reports-tree--actions-nav");
      const isIcon = target.dataset.icon || target.localName === "path";

      if (!isButtonOrPopover && !isIcon && event.data?.report) {
        navigateToReport(event.data?.report.id);
      }
    },
    [navigateToReport]
  );

  const getRowDragText = (params: IRowDragItem) => {
    const report = params.rowNode?.data.report as IReport | undefined;
    return report?.label || "Reports Tree row";
  };

  const cellClass = (cellClassParams: CellClassParams) => {
    return classNames("ag-custom-group-cell", { ["reports-tree--disabled-cell"]: cellClassParams.data?.mockId });
  };

  const getRowId = (row: GetRowIdParams<ReportTreeNodeInfo>) => {
    if (row.data.mockId) {
      return row.data.mockId;
    }

    return row.data.report ? `${REPORTS_TREE_PREFIX}-${row.data.report?.id}` : "";
  };

  return (
    <Table<ReportTreeNodeInfo>
      rowData={reportNodeList()}
      onBodyScroll={handleTreeScroll}
      tableRef={tableRef}
      headerHeight={0}
      className="reports-tree"
      getDataPath={data => data.path}
      getRowId={getRowId}
      rowDragText={getRowDragText}
      treeData
      onRowDataUpdated={handleRowDataUpdated}
      onRowDragEnd={onRowDragEnd}
      onRowDragLeave={onRowDragLeave}
      onRowDragMove={onRowDragMove}
      onChartDestroyed={onDestroyed}
      onGridReady={handleGridReady}
      components={{ agColumnHeader: null }}
      columnDefs={[]}
      gridOptions={{
        autoGroupColumnDef: {
          headerName: "",
          flex: 1,
          cellClass,
          cellRenderer: ReportGroupCell,
          headerComponentParams: {
            hideNav: true,
          },
        },
      }}
      rowHeight={32}
      rowDragEntireRow
      onRowClicked={handleRowClicked}
      disableAutoHeight
    />
  );
}

export default observer(ReportsTree);
