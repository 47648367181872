import { useCallback, useEffect } from "react";
import { Classes } from "@blueprintjs/core";

import appStore from "@store/AppStore";
import { Theme } from "@store/EnvironmentStore";

// Classes.DARK is the class name for the dark theme in BlueprintJS. Kept for compatibility.
const darkClasses = [Classes.DARK, "dark"];

export const useThemeSwitcher = () => {
  const { themeIsDark, setSystemTheme, setLoadingTheme } = appStore.env;

  const onSystemThemeChange = useCallback(
    ({ matches }: MediaQueryListEvent | MediaQueryList) => {
      if (matches) {
        setSystemTheme(Theme.Dark);
      } else {
        setSystemTheme(Theme.Light);
      }
    },
    [setSystemTheme]
  );

  useEffect(() => {
    const mediaQueryList = window.matchMedia("(prefers-color-scheme: dark)");
    // Initially set the System theme
    onSystemThemeChange(mediaQueryList);

    mediaQueryList.addEventListener("change", onSystemThemeChange);

    return () => mediaQueryList.removeEventListener("change", onSystemThemeChange);
  }, [onSystemThemeChange, setSystemTheme]);

  useEffect(() => {
    if (themeIsDark) {
      document.body.classList.add(...darkClasses);
    } else {
      document.body.classList.remove(...darkClasses);
    }
    setLoadingTheme(false);
  }, [setLoadingTheme, themeIsDark]);
};
