import { Spinner } from "@blueprintjs/core";
import classNames from "classnames";
import { observer } from "mobx-react";

import Avatar from "@components/Avatar/Avatar";
import { Button } from "@components/Button";
import CommentThread, { CommentThreadDisplayStyle } from "@components/Modeling/ModelingFrame/ModelBlock/Comments/CommentThread";
import SimpleCommentEditor from "@components/SimpleCommentEditor/SimpleCommentEditor";
import { IAnnotation } from "@store/AnnotationStore";
import appStore from "@store/AppStore";
import { MoveDirection } from "@utilities/AnnotationUtils";
import { Text, TextVariant } from "src/ui/Text";

import AnnotationViewButton3D from "./AnnotationViewButton3D";

import styles from "./AnnotationContainer.module.scss";

interface AnnotationContainerProps {
  annotation: IAnnotation;
  onMoveToAnnotation: (myId: string, direction?: MoveDirection) => void;
}

const AnnotationContainer = ({ annotation, onMoveToAnnotation }: AnnotationContainerProps) => {
  const parentComment = annotation.commentThread?.parentComment;
  const childComments = annotation.commentThread?.childComments;

  if (!appStore.env.attachmentViewer?.annotationsVisible || appStore.env.attachmentViewer?.isExplodeActive) {
    return null;
  }

  const handleCloseButtonClick = () => {
    annotation.setIsExpanded(false);
  };

  const handleEditorCancel = () => {
    // If no comments and no draft, delete the annotation
    if (!parentComment && !childComments) {
      deleteEmptyAnnotation();
    }
  };

  const deleteEmptyAnnotation = () => {
    appStore.env.attachmentViewer?.annotationMarkupManager?.deleteAnnotationMarkup(annotation.id);
    appStore.env.activeAttachment?.annotationList.deleteAnnotation(annotation);
  };

  // Show spinner when loading for the first time
  if (annotation.fetchingComments && annotation.firstFetch) {
    return (
      <div className={styles.threadArea}>
        <div className={styles.spinnerContainer}>
          <Spinner size={30} />
        </div>
      </div>
    );
  }

  const annotationsAmount = appStore.env.activeAttachment?.annotationList.annotations.findIndex((a: IAnnotation) => a.id === annotation.id);

  const renderHeading = () => {
    return (
      <div className={styles.heading}>
        <AnnotationViewButton3D locationData3D={annotation.locationData3D} />
        <div className={styles.navigation}>
          <Button
            minimal
            icon="chevron-left"
            onClick={() => onMoveToAnnotation(annotation.id, MoveDirection.Previous)}
            e2eIdentifiers={["smart-annotation", "previous"]}
          />
          <Text variant={TextVariant.Caption}>
            {(annotationsAmount || 0) + 1} of {appStore.env.activeAttachment?.annotationList.annotations.length}
          </Text>
          <Button
            minimal
            icon="chevron-right"
            onClick={() => onMoveToAnnotation(annotation.id, MoveDirection.Next)}
            e2eIdentifiers={["smart-annotation", "next"]}
          />
          {/* TODO: Implement context menu for the below button */}
          {/*<Button minimal icon="more" e2eIdentifiers={["smart-annotation", "context-menu"]} />*/}
          <Button minimal icon="cross" onClick={handleCloseButtonClick} e2eIdentifiers={["smart-annotation", "close"]} />
        </div>
      </div>
    );
  };

  const handleNewComment = async (value: string) => {
    await annotation.addNewComment(value);
  };

  return (
    <div className={classNames(styles.annotationContainer, { [styles.expanded]: annotation.isExpanded })}>
      {!!annotation.commentThread?.commentCount && renderHeading()}
      {annotation.commentThread && (
        <div className={styles.threadArea}>
          <CommentThread thread={annotation.commentThread!} displayStyle={CommentThreadDisplayStyle.Sidebar} isInsideAnnotationContainer />
        </div>
      )}
      <div className={styles.replyArea}>
        <Avatar source={appStore.userModel?.avatarUrl} size={20} />
        <SimpleCommentEditor
          onConfirm={handleNewComment}
          content=""
          editable
          resetOnConfirm
          showUnfocusedPlaceholder={annotation.commentCount > 0}
          showFocusedPlaceholder
          onCancel={handleEditorCancel}
        />
      </div>
    </div>
  );
};

export default observer(AnnotationContainer);
