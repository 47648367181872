import { MutableRefObject, useEffect, useMemo, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import debounce from "lodash/debounce";
import { observer } from "mobx-react";

import SearchResults from "@components/SearchResults";
import { TSearchResultItem } from "@components/SearchResults/SearchResults";
import appStore from "@store/AppStore";
import { HoopsTreePanelState } from "@store/EnvironmentStore";
import { IHoopsNode } from "@store/HoopsViewerStore";

import HoopsTree from "./UI/HoopsTree";
import HoopsTreeExpandCollapseHeading from "./HoopsTreeExpandCollapseHeading";
import HoopsTreeFilterBox from "./HoopsTreeFilterBox";

import styles from "./HoopsTreeSideBar.module.scss";

interface HoopsTreeSideBarProps {
  ready: boolean;
  tableRef: MutableRefObject<AgGridReact<IHoopsNode> | null>;
}

const HoopsTreeSideBar = ({ ready, tableRef }: HoopsTreeSideBarProps) => {
  const [searchValue, setSearchValue] = useState("");
  const [filteredItems, setFilteredItems] = useState<TSearchResultItem[]>([]);

  const navigateToNode = (id: string) => {
    // hoops viewer uses number for id
    appStore.env.attachmentViewer?.selectNodeById(+id, true);
  };

  const debouncedFilterTreeForText = useMemo(
    () =>
      debounce((filterText: string) => {
        const result = appStore.env.attachmentViewer?.filterTreeForText(filterText) || [];
        setFilteredItems(result);
      }, 400),
    []
  );

  useEffect(() => {
    debouncedFilterTreeForText.cancel();
    debouncedFilterTreeForText(searchValue);
    return () => {
      debouncedFilterTreeForText.cancel();
    };
  }, [debouncedFilterTreeForText, searchValue]);

  if (appStore.env.hoopsTreePanelState === HoopsTreePanelState.Closed) {
    return null;
  }

  return (
    <div className={styles.hoopsTreeSideBarWrapper}>
      <div className={styles.hoopsTreeSideBar}>
        <HoopsTreeExpandCollapseHeading tableRef={tableRef} />
        <HoopsTreeFilterBox setSearchValue={setSearchValue} searchValue={searchValue} />
        {searchValue ? (
          <SearchResults activeId={`${appStore.env.attachmentViewer?.selectedNode?.id}`} onClick={navigateToNode} items={filteredItems} />
        ) : (
          <HoopsTree ready={ready} tableRef={tableRef} />
        )}
      </div>
    </div>
  );
};

export default observer(HoopsTreeSideBar);
