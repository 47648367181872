import { MouseEvent } from "react";
import { ButtonGroup } from "@blueprintjs/core";
import { MaybeElement } from "@blueprintjs/core/src/common/props";
import { IconName } from "@blueprintjs/icons";
import { observer } from "mobx-react";

import { Button } from "@components/Button";
import { MenuItem } from "@components/MenuItem";
import { Tooltip } from "@components/Tooltip";
import appStore from "@store/AppStore";
import { Theme } from "@store/EnvironmentStore";

import "./ThemeSubMenu.scss";

interface IThemeMenuItems {
  text: string;
  theme: Theme;
  icon: IconName | MaybeElement;
}

const menuItems: IThemeMenuItems[] = [
  { text: "Light", theme: Theme.Light, icon: "flash" },
  { text: "Dark", theme: Theme.Dark, icon: "moon" },
  { text: "Automatic", theme: Theme.System, icon: "contrast" },
];

const ThemeSubMenu = () => {
  const { theme, setTheme } = appStore.env;

  const rotateTheme = (e: MouseEvent) => {
    e.stopPropagation();
    const index = menuItems.findIndex(item => item.theme === theme);
    const newIndex = (index + 1) % menuItems.length;
    setTheme(menuItems[newIndex].theme);
  };

  return (
    <MenuItem
      className="theme-sub-menu"
      popoverProps={{ popoverClassName: "theme-sub-menu--popover" }}
      text="Theme"
      e2eIdentifiers="theme"
      onClick={rotateTheme}
      rightElement={
        <ButtonGroup>
          {menuItems.map(item => (
            <Tooltip key={item.theme} content={item.text} position="bottom">
              <Button
                e2eIdentifiers={["theme-button", item.theme]}
                key={item.theme}
                icon={item.icon}
                active={theme === item.theme}
                onClick={() => setTheme(item.theme)}
              />
            </Tooltip>
          ))}
        </ButtonGroup>
      }
    />
  );
};

export default observer(ThemeSubMenu);
