import { EditableText } from "@blueprintjs/core";
import { observer } from "mobx-react";

import type { IStatusDefinition } from "@store/StatusDefinitionStore";

import "./StatusDefinitionLabelEditor.scss";

type StatusInstanceLabelEditorProps = {
  statusDefinition: IStatusDefinition;
  disabled?: boolean;
};

const StatusDefinitionLabelEditor = ({ statusDefinition, disabled }: StatusInstanceLabelEditorProps) => {
  return (
    <EditableText
      disabled={disabled}
      key={`${statusDefinition.id}-${statusDefinition.label}`}
      className="status-definition-label-editor"
      onConfirm={statusDefinition.setLabel}
      defaultValue={statusDefinition.label}
      multiline
    />
  );
};

export default observer(StatusDefinitionLabelEditor);
