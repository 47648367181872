import { destroy, flow, getParentOfType, types } from "mobx-state-tree";

import { CreatePmTagDto, PmTag } from "@rollup-api/models/pmTags";
import { Color } from "@rollup-types/colors";
import { convertTimestamp } from "@utilities";
import { rollupClient } from "src/core/api";

import { WorkspaceStore } from "./WorkspaceStore";

export interface PmTagEntity {
  id: string;
  label: string;
  labelColor?: string;
  icon?: string;
  iconColor?: Color;
  createdBy?: string;
  createdAt: number;
  updatedAt: number;
}

export const PmTagsStore = types
  .model("PmTags", {
    tagList: types.array(types.frozen<PmTagEntity>()),
  })
  .actions(self => ({
    populatePmTags: flow(function* populatePmTags(): Generator<any, boolean, any> {
      const workspaceId = getParentOfType(self, WorkspaceStore)?.id;
      try {
        const { status, data } = yield rollupClient.pmTags.retrieveList(workspaceId);
        if (status !== 200) {
          console.warn(`Error retrieving pm tags for workspace ${workspaceId}`);
          return false;
        }
        if (data) {
          data.forEach((tag: PmTag) => {
            if (!self.tagList.find(t => t.id === tag.id)) {
              const createdAt = convertTimestamp(tag.createdAt);
              const updatedAt = convertTimestamp(tag.updatedAt);
              const newTag = { ...tag, createdAt, updatedAt };
              self.tagList.push(newTag);
            }
          });
        }
        return true;
      } catch (error) {
        console.warn(`Error retrieving pm tags for workspace ${workspaceId}`, error);
        return false;
      }
    }),
    deletePmTag: flow(function* deletePmTag(tagId: string): Generator<any, boolean, any> {
      try {
        const { status } = yield rollupClient.pmTags.delete(tagId);
        if (status !== 200) {
          console.warn(`Error deleting pm tag ${tagId}`);
          return false;
        }
        const tagToRemove = self.tagList.find(tag => tag.id === tagId);
        if (tagToRemove) {
          destroy(tagToRemove);
        }
        return true;
      } catch (error) {
        console.warn(`Error deleting pm tag ${tagId}`, error);
        return false;
      }
    }),
    addPmTag: flow(function* addPmTag(dto: CreatePmTagDto): Generator<any, boolean, any> {
      try {
        const { status, data } = yield rollupClient.pmTags.create(dto);
        if (status !== 200) {
          console.warn(`Error creating pm tag`);
          return false;
        }
        self.tagList.push(data);
        return true;
      } catch (error) {
        console.warn(`ErError creating pm tag`, error);
        return false;
      }
    }),
  }));
