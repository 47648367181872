import appStore from "@store/AppStore";

/** Main function. */
function Login() {
  appStore.login();
  return null;
}

/** Exports. */
export default Login;
