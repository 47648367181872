import classNames from "classnames";
import { observer } from "mobx-react";

import { ExecutionTypeIcon } from "@components/Analysis";
import { CustomIcon, ModuleMonochromeIcon } from "@components/CustomIcon";
import { EmojiContainer } from "@components/EmojiContainer";
import { Icon } from "@components/Icon";
import { EntityType } from "@store/types";
import { getBlockById } from "@utilities";
import { createNewLogger } from "@utilities/LoggingUtils";
import { getCodeBlockById, getReportById } from "@utilities/Properties";

import styles from "./EntityIcon.module.scss";

interface IEntityIconProps {
  id: string;
  entityType: EntityType;
  active: boolean;
}

const logger = createNewLogger("EntityIcon");

const EntityIcon = (props: IEntityIconProps) => {
  const { id, entityType, active } = props;
  switch (entityType) {
    case EntityType.Block: {
      const block = getBlockById(id);
      return block?.iconView ? <Icon className={styles.entityIcon} icon={block.iconView} active={active} /> : null;
    }
    case EntityType.BomTable:
      return <CustomIcon className={styles.entityIcon} icon={ModuleMonochromeIcon.Bom} active={active} />;
    case EntityType.CodeBlock: {
      const codeBlock = getCodeBlockById(id);
      return codeBlock ? <ExecutionTypeIcon className={styles.entityIcon} type={codeBlock.type} /> : null;
    }
    case EntityType.Report: {
      const report = getReportById(id);
      return <EmojiContainer className={classNames(styles.entityIcon, styles.entityIconEmoji)} emoji={report?.displayedIcon} />;
    }
    case EntityType.RequirementsDocument:
      return <CustomIcon className={styles.entityIcon} icon={ModuleMonochromeIcon.RequirementsBw} active={active} />;

    default:
      logger.error(`Entity type ${entityType} is not supported`);
  }
};

export default observer(EntityIcon);
