import { JSX, ReactNode, useState } from "react";
import { Tooltip } from "@blueprintjs/core";
import { MaybeElement } from "@blueprintjs/core/src/common/props";
import { IconName } from "@blueprintjs/icons";
import classNames from "classnames";
import { observer } from "mobx-react";

import { Button } from "@components/Button";
import { Popover } from "@components/Popover";
import { IE2EIdentifiersParam, mergeE2EIdentifiers } from "@utilities/E2EUtils";

import styles from "./CellDropdown.module.scss";

interface ICellDropdownProps extends IE2EIdentifiersParam {
  text?: ReactNode;
  icon?: IconName | MaybeElement;
  content?: string | JSX.Element;
  tooltip?: string;
  disabled?: boolean;
  buttonClassName?: string;
  className?: string;
  onInteraction?(nextOpenState: boolean): void;
}

const CellDropdown = (props: ICellDropdownProps) => {
  const { text = " ", icon, tooltip, content, buttonClassName = styles.cellDropdownButton, onInteraction, className } = props;
  const { disabled } = props;
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  const handleInteraction = (nextOpenState: boolean) => {
    setIsPopoverOpen(nextOpenState);
    onInteraction?.(nextOpenState);
  };

  return (
    <div className={classNames(styles.cellDropdown, className)}>
      <Popover isOpen={isPopoverOpen} onInteraction={handleInteraction} content={content} placement="bottom-end">
        <Tooltip className={styles.cellDropdownTooltip} disabled={!tooltip} content={tooltip}>
          <Button
            className={buttonClassName}
            minimal
            text={text}
            icon={icon}
            disabled={disabled}
            rightIcon={isPopoverOpen ? "chevron-up" : "chevron-down"}
            e2eIdentifiers={mergeE2EIdentifiers("cell-dropdown", props.e2eIdentifiers)}
          />
        </Tooltip>
      </Popover>
    </div>
  );
};

export default observer(CellDropdown);
