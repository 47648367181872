import { ChangeEvent, useCallback, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Checkbox, FormGroup, InputGroup, Intent, Tree, TreeNodeInfo } from "@blueprintjs/core";
import { observer } from "mobx-react";

import { Button } from "@components/Button";
import { Icon } from "@components/Icon";
import { MultiplicityTag } from "@components/MultiplicityTag";
import appStore from "@store/AppStore";
import { IBlock } from "@store/BlockStore";
import { getBlockById, getBomTableById } from "@utilities";
import { Text, TextVariant } from "src/ui/Text";

import { DialogLegacy } from "../index";

import "./DialogEditBomTable.scss";

export type DialogBomSelectionsProps = {
  isOpen: boolean;
  existingTableId?: string;
  onClose: () => void;
};

const BlockTree = Tree.ofType<IBlock>();

const DialogEditBomTable = ({ existingTableId, isOpen, onClose }: DialogBomSelectionsProps) => {
  const navigate = useNavigate();
  const inputRef = useRef<HTMLInputElement>(null);
  const bomTable = existingTableId ? getBomTableById(existingTableId) : undefined;
  const [inputValue, setInputValue] = useState(bomTable?.label || "");
  const [selectedBlocks, setSelectedBlocks] = useState<string[]>([]);
  const [collapsedBlocks, setCollapsedBlocks] = useState<string[]>([]);
  const [nameError, setNameError] = useState(false);
  const [nodesError, setNodesError] = useState(false);

  const toggleSelection = (block: IBlock, recursive = false) => {
    if (!block || !appStore.workspaceModel) {
      return;
    }

    if (selectedBlocks.includes(block.id)) {
      if (recursive) {
        // Remove this and all its children from selection
        setSelectedBlocks(selectedBlocks.filter(id => !getBlockById(id)?.pathIds.includes(block.id)));
      } else {
        setSelectedBlocks(selectedBlocks.filter(id => id !== block.id));
      }
    } else {
      if (recursive) {
        // Add this and all its children, preserving existing selection
        setSelectedBlocks(
          appStore.workspaceModel.blocks
            .filter(b => b === block || selectedBlocks.includes(b.id) || b.pathIds.includes(block.id))
            .map(b => b.id)
        );
      } else {
        setSelectedBlocks([...selectedBlocks, block.id]);
      }
    }
  };

  const resetLabel = useCallback(() => setInputValue(bomTable?.label || ""), [bomTable?.label]);

  useEffect(() => {
    resetLabel();
  }, [isOpen, resetLabel]);

  useEffect(() => {
    if (existingTableId && bomTable?.validRows.length) {
      setSelectedBlocks(bomTable?.validRows.map(b => b.id));
    }
  }, [bomTable, existingTableId, isOpen]);

  const handleClearInput = () => {
    setInputValue("");
    inputRef.current?.focus();
  };

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
    if (nameError) {
      setNameError(false);
    }
  };

  const resetStates = () => {
    setInputValue("");
    setSelectedBlocks([]);
    setCollapsedBlocks([]);
    setNameError(false);
    setNodesError(false);
  };

  const handleClose = () => {
    onClose();
    resetStates();
  };

  const handleConfirm = async () => {
    if (!inputValue) {
      setNameError(true);
      inputRef.current?.focus();
    }

    if (!inputValue || !appStore.workspaceModel) {
      !appStore.workspaceModel && onClose();
      return;
    }

    handleClose();

    if (bomTable) {
      bomTable.label !== inputValue && bomTable.setLabel(inputValue);
      bomTable.setRows(selectedBlocks);
    } else {
      const newBomTableId = await appStore.workspaceModel.createBomTable(inputValue, selectedBlocks);
      navigate(`/workspaces/${appStore.workspaceModel.id}/pdm/bom/${newBomTableId}`);
      appStore.env.setActiveBomTable(newBomTableId);
    }
  };

  const handleNodeClicked = (node: TreeNodeInfo<IBlock>, _path: number[], ev: React.MouseEvent<HTMLElement>) => {
    if (node.nodeData) {
      toggleSelection(node.nodeData, ev.shiftKey || ev.ctrlKey || ev.metaKey);
      if (nodesError) {
        setNodesError(false);
      }
    }
  };

  const toggleExpand = (e: React.MouseEvent<HTMLElement>, id: string) => {
    e.stopPropagation();

    if (collapsedBlocks.includes(id)) {
      setCollapsedBlocks(collapsedBlocks.filter(i => i !== id));
    } else {
      setCollapsedBlocks([...collapsedBlocks, id]);
    }
  };

  const renderLabel = (block: IBlock) => {
    const path = block.pathIds.length - 1;
    const expanded = !collapsedBlocks.includes(block.id);
    const hasChild = !!block.validatedChildren.length;
    const paddingLeft = path * 30 - (hasChild ? 4 : 0);

    return (
      <div className="dialog-edit-bom-table--item-wrap">
        <Checkbox checked={selectedBlocks.includes(block.id)} />
        <div className="dialog-edit-bom-table--item" style={{ paddingLeft }}>
          {hasChild && (
            <Button
              onClick={e => toggleExpand(e, block.id)}
              small
              minimal
              icon={expanded ? "caret-down" : "caret-right"}
              e2eIdentifiers={["dialog-edit-bom-table", "toggle-expand"]}
            />
          )}
          <Icon icon={block.iconView} />
          {block.label}
          {!!block.multiplicity && <MultiplicityTag multiplicity={block.multiplicity} />}
        </div>
      </div>
    );
  };

  const convertToTreeNode = (block: IBlock) => {
    const node: TreeNodeInfo<IBlock> = {
      id: block.id,
      isExpanded: !collapsedBlocks.includes(block.id),
      hasCaret: false,
      isSelected: selectedBlocks?.includes(block.id),
      label: renderLabel(block),
      nodeData: block,
    };

    if (block.validatedChildren) {
      node.childNodes = [];
      for (const b of block.validatedChildren) {
        node.childNodes.push(convertToTreeNode(b));
      }
    }
    return node;
  };

  let nodes: TreeNodeInfo<IBlock>[];
  if (!isOpen || !appStore.workspaceModel?.rootBlock) {
    nodes = [];
  } else {
    nodes = [convertToTreeNode(appStore.workspaceModel?.rootBlock)];
  }

  const detachedBlocks = appStore.workspaceModel?.detachedBlocks.map(b => convertToTreeNode(b));

  const title = existingTableId ? "Edit BOM selection" : "Create new BOM";

  return (
    <DialogLegacy className="dialog-edit-bom-table" isOpen={isOpen} title={title} onClose={handleClose}>
      <div className="dialog-edit-bom-table--body">
        <FormGroup>
          <label className="dialog-edit-bom-table--label" htmlFor="bomTableName">
            BOM table name*
          </label>
          <InputGroup
            inputRef={inputRef}
            autoFocus
            required
            id="bomTableName"
            value={inputValue}
            onChange={handleInputChange}
            large
            placeholder="Enter table name..."
            rightElement={
              inputValue ? (
                <Button minimal icon="delete" onClick={handleClearInput} e2eIdentifiers={["dialog-edit-bom-table", "reset-label"]} />
              ) : undefined
            }
            onKeyDown={event => {
              if (event.key === "Enter") {
                handleConfirm();
              }
            }}
          />
          {nameError && <div className="dialog-edit-bom-table--error">Enter the BOM table name</div>}
        </FormGroup>
        <FormGroup className="dialog-edit-bom-table--block-tree-container">
          <span className="dialog-edit-bom-table--label">Select Blocks to import</span>
          <Text variant={TextVariant.Caption} disableAlignmentWrapper>
            Hold shift to select all child blocks underneath a selected block
          </Text>
          <BlockTree className="block-selection-tree" contents={nodes} onNodeClick={handleNodeClicked} />
        </FormGroup>
        {detachedBlocks?.length ? (
          <FormGroup className="dialog-edit-bom-table--block-tree-container">
            <Text disableAlignmentWrapper>Detached blocks:</Text>
            <BlockTree className="block-selection-tree" contents={detachedBlocks} onNodeClick={handleNodeClicked} />
          </FormGroup>
        ) : null}
      </div>
      <div className="dialog-edit-bom-table--footer">
        <Button outlined large onClick={onClose} e2eIdentifiers={["dialog-edit-bom-table", "cancel"]}>
          Cancel
        </Button>
        <Button large intent={Intent.PRIMARY} onClick={handleConfirm} e2eIdentifiers={["dialog-edit-bom-table", "confirm"]}>
          {existingTableId ? "Update" : "Create"}
        </Button>
      </div>
    </DialogLegacy>
  );
};

export default observer(DialogEditBomTable);
