import { CommentLocationType } from "@components/Modeling/ModelingFrame/ModelBlock/Comments/utils";

export enum TemporalDirection {
  Older = "older",
  Newer = "newer",
  Both = "both",
}

export class CommentGetThreadedRequestDto {
  public readonly parentId?: string;
  public readonly parentIds?: string[];
  public readonly anchorParentCommentId?: string;
  public readonly focusedParentCommentId?: string;
  public readonly parentTake: number = 5;
  public readonly childSkip: number = 0;
  public readonly childTake: number = 5;
  public readonly temporalDirection: TemporalDirection = TemporalDirection.Older;
  public readonly type?: CommentLocationType;
}
