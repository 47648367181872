import { EntityModelBase } from "../../shared/entityModelBase";

export enum AttachmentStatus {
  UploadPending = "upload-pending",
  Uploaded = "uploaded",
  ConversionPending = "conversion-pending",
  ConversionInProgress = "conversion-in-progress",
  Converted = "converted",
  UploadFailed = "upload-failed",
  ConversionFailed = "conversion-failed",
}

export type AttachmentVersionInfo = {
  id: string;
  size: number;
  lastModified: string;
  createdBy: string;
  isLatest?: boolean;
};

export enum AttachmentType {
  file = "file",
  url = "url",
  reference = "reference",
  integration = "integration",
}

export class Attachment extends EntityModelBase {
  public readonly annotations?: any[];
  public readonly block!: string;
  public readonly blockId!: string;
  public readonly convertedId?: string;
  public readonly createdBy!: string;
  public readonly fileSize!: number;
  public readonly fileType?: string;
  public readonly isUploaded?: boolean;
  public readonly label?: string;
  public readonly linkAttachmentUrl?: string;
  public readonly metadata?: any;
  public readonly name!: string;
  public readonly previewId?: string;
  public readonly reference?: string;
  public readonly status?: AttachmentStatus;
  public readonly thumbnail?: string;
  public readonly type?: AttachmentType;
  public readonly updatedBy?: string;
  public readonly uploadId?: string;
  public readonly version?: number;
  public readonly workspaceId: string = "";
}
