interface FilterChoiceProps<T extends Record<string, string> | string> {
  item: T;
  filterField?: keyof T;
  renderer?: (item: T) => React.ReactNode;
  onClick?: (item: T) => void;
}

const FilterChoice = <T extends Record<string, string> | string>({ item, filterField, renderer, onClick }: FilterChoiceProps<T>) => {
  if (typeof item === "string") {
    return <div onClick={() => onClick?.(item)}>{renderer?.(item) ?? item}</div>;
  } else {
    return <div onClick={() => onClick?.(item)}>{renderer?.(item) ?? (item[filterField as keyof T] as string)}</div>;
  }
};

export default FilterChoice;
