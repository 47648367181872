import { withDataTestId } from "@components/Shared/WithDataTestId";
import { ElementType } from "@utilities/E2EUtils";

import "./TextCell.scss";

export interface TextCellProps {
  label: string;
}

const TextCell = ({ label, ...rest }: TextCellProps) => (
  <p className="text-cell cell-title" {...rest}>
    {label}
  </p>
);

export default withDataTestId(TextCell, ElementType.AgCell);
