import { ForwardedRef, forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import { IconName, Menu } from "@blueprintjs/core";
import { Editor } from "@tiptap/react";
import { SuggestionProps } from "@tiptap/suggestion";
import { Instance } from "tippy.js";

import { MenuItem } from "@components/MenuItem";
import { ReportPageNavItem } from "@components/Reports/Editor/Extentions/DashNavigation/types";
import { RollupEditorType } from "@rollup-types/editor";

import styles from "./DashNavigationList.module.scss";

const NAV_ITEM_HEIGHT = 54;

export type DashNavigationListItemData = {
  title: string;
  icon: IconName;
  description?: string;
  keywords?: string;
  type: RollupEditorType | ReportPageNavItem;
  popup?: Instance;
};

export type DashNavigationListProps = { popup: Instance; editor: Editor } & Pick<
  SuggestionProps<DashNavigationListItemData>,
  "items" | "command" | "text"
>;

export type DashNavigationListRef = {
  onKeyDown: (props: { event: KeyboardEvent }, popup: Instance) => boolean;
};

const DashNavigationList = forwardRef<DashNavigationListRef, DashNavigationListProps>(
  (props: DashNavigationListProps, ref: ForwardedRef<DashNavigationListRef>) => {
    const menuRef = useRef<HTMLUListElement>(null);
    const [selectedIndex, setSelectedIndex] = useState(0);
    // Reset selected item every time the query changes
    useEffect(() => setSelectedIndex(0), [props.items]);
    const selectedItem = props.items.at(selectedIndex);

    const stabilizeScrollOnDown = (index: number) => {
      if (!menuRef.current) {
        return;
      }

      if (!index) {
        menuRef.current.scrollTop = 0;
      }

      if (index > 4 && menuRef.current) {
        menuRef.current.scrollTop = menuRef.current.scrollTop + NAV_ITEM_HEIGHT;
      }
    };

    const stabilizeScrollOnTop = (index: number) => {
      if (!menuRef.current) {
        return;
      }

      if (index === props.items.length - 1) {
        menuRef.current.scrollTop = menuRef.current.scrollHeight;
      }

      if (index < props.items.length - 5) {
        menuRef.current.scrollTop = menuRef.current.scrollTop - NAV_ITEM_HEIGHT;
      }
    };

    useImperativeHandle(ref, () => ({
      onKeyDown: ({ event }) => {
        event.stopPropagation();
        if (event.key === "ArrowUp") {
          const newIndex = (selectedIndex + props.items.length - 1) % props.items.length;
          setSelectedIndex(newIndex);
          stabilizeScrollOnTop(newIndex);
          return true;
        }

        if (event.key === "ArrowDown") {
          const newIndex = (selectedIndex + 1) % props.items.length;
          setSelectedIndex(newIndex);
          stabilizeScrollOnDown(newIndex);
          return true;
        }

        if (event.key === "Enter") {
          if (selectedItem) {
            props.command({ ...selectedItem, popup: props.popup });
          }

          event.stopPropagation();
          return true;
        }

        event.stopPropagation();
        return false;
      },
    }));

    if (!props.items?.length) {
      return (
        <Menu>
          <MenuItem className={styles.dashNavigationListNoResults} disabled text="No results found" e2eIdentifiers="no-results-found" />
        </Menu>
      );
    }

    return (
      <Menu ulRef={menuRef} className={styles.dashNavigationList}>
        {props.items.map((item, index) => (
          <MenuItem
            icon={item.icon}
            active={index === selectedIndex}
            key={item.title}
            onClick={() => props.command({ ...item, popup: props.popup })}
            text={item.title}
            description={item.description}
            e2eIdentifiers={[item.title]}
          />
        ))}
      </Menu>
    );
  }
);

DashNavigationList.displayName = "DashNavigationList";

export default DashNavigationList;
