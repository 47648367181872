import { observer } from "mobx-react";

import { IBlock } from "@store/BlockStore";

import { MultiplicityTag } from "../MultiplicityTag";

type Props = {
  block: IBlock;
};

const TreeNodeLabel = observer(({ block }: Props) => {
  return (
    <div>
      {block.label}
      {!!block.multiplicity && (
        <span className="ml-1">
          <MultiplicityTag multiplicity={block.multiplicity} />
        </span>
      )}
    </div>
  );
});

const getTreeNodeLabel = (block: IBlock) => {
  return <TreeNodeLabel block={block} />;
};

export default getTreeNodeLabel;
