import { toArray } from "@rollup-io/engineering";
import { destroy, flow, Instance, IType, SnapshotIn, SnapshotOut, types } from "mobx-state-tree";

import { showApiErrorToast, showToast } from "@components/UiLayers/toaster";
import { IUpdateDataSinkDto, IUpdateDataSinkEntryDto } from "@rollup-api/models/data-sinks";
import { mapDataSinkEntryToSnapshot, mapDataSinkToSnapshot } from "@rollup-api/models/data-sinks/data-sink.mappers";
import { DataSink, DataSinkEntry } from "@rollup-api/models/data-sinks/data-sink.model";
import { DataLink, DataSource } from "@rollup-api/models/data-sources";

import { rollupClient } from "../../core/api";

import { DataSinkEntryStore, IDataSinkEntry, IDataSinkEntryMobxType } from "./DataSinkEntryStore";
import { DataSinkStore, IDataSink, IDataSinkMobxType } from "./DataSinkStore";

export type IDataConnection = DataSource | IDataSink;

export const DataConnectionModuleStore = types
  .model("DataConnectionModule", {
    dataSourceMap: types.map(types.frozen<DataSource>()),
    dataSourceLinkMap: types.map(types.frozen<DataLink>()),
    dataSinkMap: types.map<IDataSinkMobxType>(DataSinkStore),
    dataSinkEntryMap: types.map<IDataSinkEntryMobxType>(DataSinkEntryStore),
  })
  .views(self => ({
    get dataSinks(): IDataSink[] {
      return toArray<IDataSink>(self.dataSinkMap);
    },
    get dataSinkEntries(): IDataSinkEntry[] {
      return toArray<IDataSinkEntry>(self.dataSinkEntryMap);
    },
    get dataSources(): DataSource[] {
      return toArray<DataSource>(self.dataSourceMap);
    },
    get dataSourceLinks(): DataLink[] {
      return toArray<DataLink>(self.dataSourceLinkMap);
    },
    get allDataConnections(): (DataSource | IDataSink)[] {
      return [...this.dataSinks, ...this.dataSources];
    },
    getDataLink(sourceId: string, query: string): DataLink | undefined {
      const links = toArray<DataLink>(self.dataSourceLinkMap, (_, link) => link.dataSourceId === sourceId && link.query === query);
      return links?.[0];
    },
  }))
  .actions(self => ({
    addOrUpdateDataSource(dataSource: DataSource) {
      self.dataSourceMap.set(dataSource.id, dataSource);
    },
    addDataSinkEntry(entry: DataSinkEntry, disableAddToParent?: boolean) {
      self.dataSinkEntryMap.set(entry.id, mapDataSinkEntryToSnapshot(entry));
      if (!disableAddToParent) {
        const dataSink = self.dataSinkMap.get(entry.dataSinkId);
        if (dataSink) {
          dataSink.addEntry(entry.id);
        }
      }
    },
    updateDataSinkEntry(id: string, dto: IUpdateDataSinkEntryDto) {
      const dataSinkEntry = self.dataSinkEntryMap.get(id);
      dataSinkEntry?.patch(dto);
    },
    updateDataSink(id: string, dto: IUpdateDataSinkDto) {
      const dataSink = self.dataSinkMap.get(id);
      dataSink?.patch(dto);
    },
    addDataSink(dataSink: DataSink) {
      self.dataSinkMap.set(dataSink.id, mapDataSinkToSnapshot(dataSink));
      dataSink.entries?.forEach(entry => {
        this.addDataSinkEntry(entry, true);
      });
    },
    addOrUpdateDataLink: flow(function* addOrUpdateDataLink(dataLink: DataLink, notify = true): Generator<any, DataLink | undefined, any> {
      self.dataSourceLinkMap.set(dataLink.id, dataLink);
      if (!notify) {
        return dataLink;
      }
      try {
        const { id, query, dataSourceId } = dataLink;
        const res = yield rollupClient.modelingModule.dataSources.createDataLink({ id, query, dataSourceId });
        if (res.status !== 201) {
          showApiErrorToast("Error creating data link", new Error());
          return undefined;
        }
        dataLink = res.data as DataLink;
        self.dataSourceLinkMap.set(dataLink.id, dataLink);
        return dataLink;
      } catch (err) {
        showApiErrorToast("Error creating data link", err as Error);
        console.warn(err);
        return undefined;
      }
    }),
    deleteDataLink: flow(function* deleteDataLink(dataLinkId: string, notify = true): Generator<any, boolean, any> {
      self.dataSourceLinkMap.delete(dataLinkId);
      if (!notify) {
        return true;
      }
      try {
        const res = yield rollupClient.modelingModule.dataSources.deleteDataLink(dataLinkId);
        if (res.status !== 204) {
          showApiErrorToast("Error deleting data link", new Error());
          return false;
        }
        return true;
      } catch (err) {
        showApiErrorToast("Error deleting data link", err as Error);
        console.warn(err);
        return false;
      }
    }),
    deleteUnusedDataLinks: flow(function* deleteUnusedDataLinks(): Generator<any, boolean, any> {
      try {
        const res = yield rollupClient.modelingModule.dataSources.deleteUnusedDataLinks();
        if (res.status !== 200) {
          showApiErrorToast("Error deleting data links", new Error());
          return false;
        }
        if (Array.isArray(res.data) && res.data?.length) {
          showToast(`Deleted ${res.data.length} unused data links`);
          for (const id of res.data) {
            self.dataSourceLinkMap.delete(id);
          }
        } else {
          showToast("No unused data links to delete");
        }
        return true;
      } catch (err) {
        showApiErrorToast("Error deleting data links", err as Error);
        console.warn(err);
        return false;
      }
    }),
    removeDataSource(id: string) {
      self.dataSourceMap.delete(id);
    },
    deleteDataSink(id: string, disableNotification?: boolean) {
      const dataSink = self.dataSinkMap.get(id);
      if (!dataSink) {
        return;
      }

      if (!disableNotification) {
        rollupClient.modelingModule.dataSinks.delete(id).catch((err: Error) => {
          showApiErrorToast(`Error deleting data sink ${id}`, err);
        });
      }

      destroy(dataSink);
      return true;
    },
    deleteDataSinkEntry(id: string, disableNotification?: boolean) {
      const entry = self.dataSinkEntryMap.get(id);

      if (!entry) {
        console.error(`DataSinkEntry with id ${id} not found`);
        return false;
      } else if (!entry.dataSink) {
        console.error(`DataSinkEntry with id ${id} has no dataSink parent`);
        return false;
      }

      entry.dataSink.removeEntry(entry.id);

      if (!disableNotification) {
        rollupClient.modelingModule.dataSinkEntries.delete(entry.dataSink.id, id).catch((err: Error) => {
          showApiErrorToast(`Error deleting data sink entry ${id}`, err);
        });
      }

      destroy(entry);
      return true;
    },
    deleteDataSinkEntries(entryIds: string[]) {
      entryIds.forEach(id => {
        this.deleteDataSinkEntry(id);
      });
    },
  }));

export interface IDataConnectionModule extends Instance<typeof DataConnectionModuleStore> {}
export interface IDataConnectionModuleSnapshotIn extends SnapshotIn<typeof DataConnectionModuleStore> {}
export interface IDataConnectionModuleSnapshotOut extends SnapshotOut<typeof DataConnectionModuleStore> {}
export interface IDataConnectionModuleMobxType
  extends IType<IDataConnectionModuleSnapshotIn, IDataConnectionModuleSnapshotOut, IDataConnectionModule> {}
