import { Intent } from "@blueprintjs/core";
import { observer } from "mobx-react";

import { Button } from "@components/Button";
import { IE2EIdentifiers } from "@utilities/E2EUtils";

import "./CreateDocumentButton.scss";

interface IFeatureCreateDocumentProps {
  text?: string;
  e2eIdentifiers?: IE2EIdentifiers;
  onClick?(): void;
}

const CreateDocumentButton = (props: IFeatureCreateDocumentProps) => {
  const { text = "Create Document", e2eIdentifiers = "create-document", onClick } = props;

  return (
    <Button
      fill
      intent={Intent.PRIMARY}
      icon="small-plus"
      text={text}
      className="create-document-button"
      onClick={onClick}
      e2eIdentifiers={e2eIdentifiers}
    />
  );
};

export default observer(CreateDocumentButton);
