import { ChangeEvent, KeyboardEvent, useEffect, useRef, useState } from "react";
import { Alignment, InputGroup, Intent, MenuDivider } from "@blueprintjs/core";
import { PropertyDataType, unitIsValid } from "@rollup-io/engineering";
import { observer } from "mobx-react";

import { MenuItem } from "@components/MenuItem";
import { MenuItemDelete } from "@components/MenuItems";
import { Switch } from "@components/Switch";
import { IPropertyDefinition } from "@store/PropertyDefinitionStore";

import "./PropertyDefinitionMenu.scss";

interface IHeaderNavParams {
  propertyDefinition: IPropertyDefinition;
  onDelete?: () => void;
  onRemove?: () => void;
  onHide?: () => void;
}

const PropertyDefinitionMenu = (props: IHeaderNavParams) => {
  const { propertyDefinition, onDelete, onRemove, onHide } = props;
  const [nameInputValue, setNameInputValue] = useState<string>("");
  const [unitInputValue, setUnitInputValue] = useState<string>("");
  const [defaultGroupInputValue, setDefaultGroupInputValue] = useState<string>("");
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    setNameInputValue(propertyDefinition.label);
    setUnitInputValue(propertyDefinition.unit);
    setDefaultGroupInputValue(propertyDefinition.defaultPropertyGroup || "");
  }, [propertyDefinition]);

  const handleNameInputChange = (event: ChangeEvent<HTMLInputElement>) => setNameInputValue(event.target.value);

  const updatePropertyName = () => {
    if (nameInputValue) {
      propertyDefinition.setLabel(nameInputValue);
    } else {
      setNameInputValue(propertyDefinition.label);
    }
  };

  const handleNameInputKeyUp = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      updatePropertyName();
    }
  };

  const handleUnitInputChange = (event: ChangeEvent<HTMLInputElement>) => setUnitInputValue(event.target.value);

  const updatePropertyDefinitionUnit = () => {
    if (unitIsValid(unitInputValue)) {
      propertyDefinition.setUnit(unitInputValue);
    } else {
      setUnitInputValue(propertyDefinition.unit);
    }
  };

  const handleUnitInputKeyUp = (event: KeyboardEvent) => {
    if (event.key === "Enter") {
      updatePropertyDefinitionUnit();
    }
  };

  const handleDefaultGroupInputChange = (event: ChangeEvent<HTMLInputElement>) => setDefaultGroupInputValue(event.target.value);

  const updatePropertyDefaultGroup = () => {
    if (defaultGroupInputValue) {
      propertyDefinition.setDefaultPropertyGroup(defaultGroupInputValue);
    } else {
      setDefaultGroupInputValue(propertyDefinition.defaultPropertyGroup || "");
    }
  };

  const handleDefaultGroupInputKeyUp = (event: KeyboardEvent) => {
    if (event.key === "Enter") {
      updatePropertyDefaultGroup();
    }
  };

  const handleInputFocus = (event: ChangeEvent<HTMLInputElement>) => event.target.select();

  return (
    <>
      <p className="header-nav--label">Name</p>
      <InputGroup
        className="header-nav--input"
        onChange={handleNameInputChange}
        onKeyUp={handleNameInputKeyUp}
        onBlur={updatePropertyName}
        value={nameInputValue}
        inputRef={inputRef}
        onFocus={handleInputFocus}
        autoFocus
      />
      {propertyDefinition.dataType === PropertyDataType.scalar && (
        <>
          <p className="header-nav--label">Unit</p>
          <InputGroup
            className="header-nav--input"
            onChange={handleUnitInputChange}
            onKeyUp={handleUnitInputKeyUp}
            onFocus={handleInputFocus}
            onBlur={updatePropertyDefinitionUnit}
            value={unitInputValue}
          />
        </>
      )}
      <p className="header-nav--label">Default group</p>
      <InputGroup
        className="header-nav--input"
        onChange={handleDefaultGroupInputChange}
        onKeyUp={handleDefaultGroupInputKeyUp}
        onBlur={updatePropertyDefaultGroup}
        onFocus={handleInputFocus}
        value={defaultGroupInputValue}
      />
      <Switch
        className="header-nav--switch"
        label="Auto-Rollup Children"
        alignIndicator={Alignment.RIGHT}
        checked={propertyDefinition.autoRollupChildren}
        onChange={() => propertyDefinition.toggleAutoRollupChildren()}
        e2eIdentifiers="auto-rollup-children"
      />
      <Switch
        className="header-nav--switch"
        label="Block default"
        alignIndicator={Alignment.RIGHT}
        checked={propertyDefinition.autoAdd}
        onChange={() => propertyDefinition.toggleAutoAdd()}
        e2eIdentifiers="block-default"
      />
      {onHide && <MenuItem icon="eye-off" text="Hide" onClick={onHide} e2eIdentifiers="hide" />}
      {(onDelete || onRemove) && <MenuDivider />}
      {onRemove && <MenuItem intent={Intent.WARNING} icon="th-disconnect" text="Remove" onClick={onRemove} e2eIdentifiers="remove" />}
      {onDelete && <MenuItemDelete onDelete={onDelete} />}
    </>
  );
};

export default observer(PropertyDefinitionMenu);
