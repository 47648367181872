import TabulatedView from "@components/Modeling/ModelingFrame/Table/TabulatedView";
import Pane from "@router/components/Panes/Pane";

/** Main function. */
function TableIndex() {
  return (
    <Pane>
      <TabulatedView />
    </Pane>
  );
}

/** Exports. */
export default TableIndex;
