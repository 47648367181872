import { Customer, StiggClient, Subscription } from "@stigg/react-sdk";
import { UseQueryOptions } from "@tanstack/react-query";

enum StiggQueryKeys {
  Customer = "Customer",
  Subscriptions = "Subscriptions",
}

export abstract class StiggQOFactory {
  public static createGetCustomerQO(stigg?: StiggClient): UseQueryOptions<{ customer?: Customer; error?: string }> {
    return {
      queryKey: [StiggQueryKeys.Customer],
      queryFn: async () => {
        if (!stigg) {
          return { error: "Stigg client is not available" };
        }
        try {
          return { customer: await stigg.getCustomer() };
        } catch (e: any) {
          return { error: e.message };
        }
      },
      enabled: !!stigg,
      retry: false,
    };
  }

  public static createGetSubscriptionsQO(stigg: StiggClient): UseQueryOptions<Subscription[]> {
    return {
      queryKey: [StiggQueryKeys.Subscriptions],
      queryFn: () => stigg.getActiveSubscriptions(),
    };
  }
}
