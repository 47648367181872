import { PopoverPosition, Tooltip } from "@blueprintjs/core";
import { observer } from "mobx-react";

import { getAbsoluteDateTime, humanReadableDateTime } from "@utilities";
import { Text, TextVariant } from "src/ui/Text";

import "./RelativeDateWithTooltip.scss";

interface IRelativeDateWithTooltipProps {
  epochTime: number | string;
  popoverPosition?: PopoverPosition;
  variant?: TextVariant;
}

export const RelativeDateWithTooltip = observer((props: IRelativeDateWithTooltipProps) => {
  return (
    <Tooltip position={props.popoverPosition} content={getAbsoluteDateTime(props.epochTime)} minimal hoverOpenDelay={500}>
      <Text className="relative-date-with-tooltip" variant={props.variant} nowrap>
        {humanReadableDateTime(props.epochTime)}
      </Text>
    </Tooltip>
  );
});
