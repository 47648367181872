import { useEffect, useState } from "react";
import { EditableText } from "@blueprintjs/core";
import { observer } from "mobx-react";

import appStore from "@store/AppStore";
import type { IBlock } from "@store/BlockStore";
import type { IStatusDefinition } from "@store/StatusDefinitionStore";

import "./StatusTypeTextEditor.scss";
/** Type defs. */
type StatusTypeTextEditorProps = {
  statusDefinition?: IStatusDefinition;
  block?: IBlock | null;
  hidePlaceholder?: boolean;
  readOnly?: boolean;
};

/** Main function. */
function StatusTypeTextEditor({ statusDefinition, block, hidePlaceholder, readOnly }: StatusTypeTextEditorProps) {
  // This should be calculated through the store.
  const statusInstance = block?.validatedStatusInstances.find(i => i.statusDefinition === statusDefinition);
  const inputTextDefaultValue = statusInstance?.value || "";

  const [inputText, setInputText] = useState(inputTextDefaultValue);

  useEffect(() => {
    setInputText(inputTextDefaultValue);
  }, [inputTextDefaultValue, statusInstance]);

  if (!statusDefinition || !block) {
    return null;
  }

  if (readOnly) {
    return statusInstance?.value;
  }

  const setValue = (value: string) => {
    if (!statusInstance) {
      if (!value) {
        return; // Don't create new instances for empty values.
      }
      appStore.workspaceModel?.addStatusInstance(block, statusDefinition, value);
    } else {
      if (!value) {
        block.deleteStatusInstance(statusInstance);
      } else {
        statusInstance.setValue(value);
      }
    }
  };

  return (
    <EditableText
      value={inputText}
      onChange={setInputText}
      onConfirm={() => setValue(inputText)}
      placeholder={hidePlaceholder ? "" : "Empty"}
      className="status-type-text-editor"
      multiline
      confirmOnEnterKey
    />
  );
}

/** Exports. */
export type { StatusTypeTextEditorProps };
export default observer(StatusTypeTextEditor);
